import { createAppAsyncThunk } from '~/Modules';
import {
  initialized,
  initializePassengerInfoThunk,
  initializeRidesThunk
} from '~/Modules/rideBooking';
import { getLastRideThunk, getPatientInfoThunk } from '~/Modules/patients';
import { initializeFundingSourceThunk } from './initializeFundingSource.thunk';
import { getMemberProfileWithoutSaga } from '~/Modules/memberProfile/actions';

/** Checks the redux store to make sure we have already loaded the patients
 * and memberProfile slice for the given passenger id. If we have not then
 * it performs the necessary fetches to load the data.
 *
 * API calls are performed in parallel. After fetching the data
 * we trigger the ridebooking prepopulation thunks. */
export const initializeRbfNewThunk = createAppAsyncThunk(
  'rideBooking/initialize',
  async (id: string, { dispatch, getState }) => {
    const { patients, memberProfile } = getState();

    const promises: Promise<unknown>[] = [];

    // Fetch patient data
    if (!patients.patientDetails || patients.patientDetails?.id?.toString() !== id) {
      promises.push(dispatch(getPatientInfoThunk({ id })));
      promises.push(dispatch(getLastRideThunk(id)));
    }

    // Only fetch if we don't already have the data
    if (
      !memberProfile?.formData?.personalInfo?.id ||
      memberProfile?.formData?.personalInfo?.id?.toString() !== id
    ) {
      promises.push(dispatch(getMemberProfileWithoutSaga({ passengerId: id })));
    }

    // Wait for all promises to resolve and then start prepopulating
    return await Promise.all(promises).then(() => {
      dispatch(initializePassengerInfoThunk());
      dispatch(initializeFundingSourceThunk());
      dispatch(initializeRidesThunk());
      dispatch(initialized());
    });
  }
);
