import React from 'react';

const SvgLiveChatIcon = props => {
  const {title, className} = props;

  return (
    <svg className={className} viewBox="0 0 64 64">
      <title>{title}</title>
      <defs>
        <rect id="b" width="52" height="52" rx="4"/>
        <filter id="a" width="140.4%" height="140.4%" x="-20.2%" y="-14.4%" filterUnits="objectBoundingBox">
          <feOffset dy="3" in="SourceAlpha" result="shadowOffsetOuter1"/>
          <feGaussianBlur in="shadowOffsetOuter1" result="shadowBlurOuter1" stdDeviation="3"/>
          <feColorMatrix in="shadowBlurOuter1" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.748075181 0"/>
        </filter>
      </defs>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(6 3)">
          <use fill="#000" filter="url(#a)" xlinkHref="#b"/>
          <use fill="#FFF" xlinkHref="#b"/>
        </g>
        <path fill="#448AFF" fillRule="nonzero" d="M43.2 15H20.8a2.796 2.796 0 0 0-2.786 2.8L18 43l5.6-5.6h19.6c1.54 0 2.8-1.26 2.8-2.8V17.8c0-1.54-1.26-2.8-2.8-2.8zm-19.6 9.8h16.8v2.8H23.6v-2.8zm11.2 7H23.6V29h11.2v2.8zm5.6-8.4H23.6v-2.8h16.8v2.8z"/>
      </g>
    </svg>
  );
};

export default SvgLiveChatIcon;
