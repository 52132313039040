import { createAppAsyncThunk } from '~/Modules';
import { getRideRouteData } from '~/Pages/RideBooking/Components/Rides/Rides.utils';

export const completeRidesThunk = createAppAsyncThunk(
  'rideBooking/completeRides',
  (_returnRideAddress: string | false, { getState }) => {
    const lastRide = getState().rideBooking.rides.at(-1);
    const date = getState().rideBooking.date.date;

    if (!lastRide) throw Error('No rides found');

    return getRideRouteData(lastRide, date)
      .then(data => ([lastRide.distance, lastRide.duration] = [data?.[0], data?.[1]]))
      .then(lastRide?.commit);
  }
);
