import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { debounce } from 'lodash-es';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LocationOffIcon from '@material-ui/icons/LocationOff';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AutoComplete from '~/Shared/Components/BookRides/FormComponents/AutoComplete';
import SavedAddressCard from '~/Shared/Components/SavedAddressDrawer/SavedAddressCard';
import { useSavedAddressDrawer } from '~/Shared/Components/SavedAddressDrawer/SavedAddressDrawer';
import SvgGooglePin from '~/Shared/Components/Svgs/SvgGooglePin';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import useModal from '~/hooks/useModal';
import { fetchAutoComplete, fetchGeocodeData } from '~/services/googleMaps.service';
import * as SavedAddessService from '~/services/savedAddress.service';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import { isString, isEmpty } from '~/utilities/guards';
import type { ErrorObject } from './SavedAddress.utils';
import { validateSavedAddressForm } from './SavedAddress.utils';
import SavedAddress from './SavedAddress.constants';
import { SavedAddressActions } from '~/Modules/savedAddress';
import { getSavedAddressesThunk, updateSavedAddresses } from '~/Modules/patients';
import { useAppDispatch, useAppSelector } from '~/Modules';
import { LAT_LNG_DISPLAY_PRECISION } from '~/constants';
import { getZipCodeFromGeocoderResult } from '~/utilities/googleMaps.helper';

export type SavedAddressColumnProps = { patientId?: number };

const SavedAddressColumn = ({ patientId }: SavedAddressColumnProps) => {
  const dispatch = useAppDispatch();

  const savedAddress = useAppSelector(state => state.savedAddress);
  const proximityCoords = useAppSelector(state => state.bookingData.hospitalCoords);
  const addressList = useAppSelector(state => state.patients?.savedAddresses ?? []);

  const [errors, setErrors] = useState<ErrorObject>({});
  const [rawAddress, setRawAddress] = useState<string>(savedAddress?.address || '');
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);
  const [autoCompleteData, setAutoCompleteData] = useState<
    google.maps.places.AutocompletePrediction[]
  >([]);

  const [drawerState, toggleDrawer] = useSavedAddressDrawer();
  const [, Modal] = useModal();

  const ref = React.useRef<HTMLDivElement>(null);

  /** The confirmation callback passed to the Modal */
  const submitSavedAddress = () => {
    Modal.setIsOpen(false);

    const { passenger_id, address, zip, name, latitude, longitude, is_default } =
      savedAddress;

    // TODO: Add error handling
    return SavedAddessService.createSavedAddress({
      passenger_id,
      address,
      zip,
      name,
      latitude,
      longitude,
      is_default
    })
      .then(res => {
        dispatch(updateSavedAddresses([...addressList, res.data]));
        dispatch(SavedAddressActions.reset());
      })
      .catch((e: Error) => {
        Modal.set({
          isOpen: true,
          title: 'Failed to Save Address',
          message: e.message,
          icon: <LocationOnIcon color="error" fontSize="large" />
        });
      });
  };

  /**
   * Handler to delete a saved address for the passenger and
   * to remove it from the displayed list of addresses
   * @param address
   */
  const onRemove = (address: MemberSavedAddress) => {
    Modal.set({
      isOpen: true,
      title: 'Remove Address',
      message: 'Are you sure you want to remove this address?',
      icon: <LocationOffIcon fontSize="large" />,
      confirmLabel: 'Remove',
      onConfirm: () => {
        // TODO: Error Handling
        SavedAddessService.deleteSavedAddress(address.passenger_id, address.id).then(
          () => {
            // eslint-disable-next-line eqeqeq
            const newAddressList = addressList.filter(entry => entry.id != address.id);

            dispatch(updateSavedAddresses(newAddressList));
          }
        );
      },
      cancelLabel: 'Cancel',
      onCancel: () => {}
    });
  };

  /**
   * Perform validation on form settings before
   * opening modal. Validates address and name input
   * as well as total existing address on list
   */
  const onSave = () => {
    const newErrors = validateSavedAddressForm(rawAddress, savedAddress, addressList);

    setErrors(newErrors);

    if (!('savedAddressInput' in newErrors) && !('savedAddressName' in newErrors)) {
      submitSavedAddress();
    }
  };

  /**
   * Once an option is selected using the AutoSelect component
   * we retrieve the formatted address, lat and long and then
   * save it in the app store for savedAddress
   * @param row
   */
  const onAutoCompleteSelect = (row: google.maps.places.AutocompletePrediction) => {
    if ('description' in row) {
      setRawAddress(row.description);
      fetchGeocodeData({ placeId: row.place_id }).then(({ results }) => {
        const record = results[0];

        dispatch(
          SavedAddressActions.set({
            ...savedAddress,
            address: record.formatted_address,
            zip: getZipCodeFromGeocoderResult(record),
            longitude: record.geometry.location.lng(),
            latitude: record.geometry.location.lat()
          })
        );
      });
    }
  };

  /**
   * Callback to handle input of savedAddressName. Enforces a 50
   * character limit on the field.
   * @param value
   */
  const onChangeName = (value: string) => {
    if (
      (isString(value) || isEmpty(value)) &&
      value.length <= SavedAddress.MAX_ADDRESS_NAME_LENGTH
    ) {
      dispatch(SavedAddressActions.setName(value));
    }
  };

  /**
   * Callback that occurs on changed default address. UI updates first
   * then performs the API call to update the backend. In the event
   * that the API call fails we'll rollback the UI changes
   * @param savedAddress
   */
  const onChangeDefaultAddress = (savedAddress: MemberSavedAddress) => {
    const newDefault = savedAddress.is_default;

    const newAddressList = addressList.map(address => {
      const isUpdatedAddress = address.id === savedAddress.id;

      if (newDefault) {
        return { ...address, is_default: isUpdatedAddress };
      }

      return {
        ...address,
        is_default: isUpdatedAddress ? false : address.is_default
      };
    });

    dispatch(updateSavedAddresses(newAddressList));

    SavedAddessService.updateSavedAddress(savedAddress).catch(() => {
      // Revert to previous address list on failure to update
      dispatch(updateSavedAddresses(addressList));
    });
  };

  /** A memoized callback function that performs the lookup
   * for the autocomplete component */
  const debounceAutoComplete = useCallback(
    debounce(
      val =>
        fetchAutoComplete(val, proximityCoords)
          .then(res => setAutoCompleteData(res?.predictions ?? []))
          .then(() => setShowAutoComplete(true))
          // eslint-disable-next-line no-console
          .catch(err => console.error(err)),
      650
    ),
    [proximityCoords]
  );

  /**
   * Callback that is executed when the user closes the drawer
   * with unsaved data in the address and/or name inputs. Checks
   * if the inputs contain valid data and opens a modal to confirm
   * if user wants to save or discard data
   */
  const onCloseDrawer = () => {
    const newErrors = validateSavedAddressForm(rawAddress, savedAddress, addressList);

    if (
      !('savedAddressInput' in newErrors) &&
      !('savedAddressName' in newErrors) &&
      savedAddress.address &&
      savedAddress.name &&
      rawAddress
    ) {
      Modal.set({
        isOpen: true,
        title: 'Save Address',
        message: 'Save changes before closing',
        icon: <BookmarkBorderIcon fontSize="large" />,
        confirmLabel: 'Save',
        onConfirm: () => submitSavedAddress().then(() => toggleDrawer.Off()),
        cancelLabel: 'Discard',
        onCancel: toggleDrawer.Off
      });
    } else {
      toggleDrawer.Off();
    }
  };

  /** Event handler for clicking outside of the Saved Address Drawer
   * @param e */
  const onExternalClick = e => {
    if (ref.current && e.clientX < ref.current.getBoundingClientRect().left) {
      onCloseDrawer();
    }
  };

  /** Event handler for the cancel buttton */
  const onClear = useCallback(() => {
    setRawAddress('');
    dispatch(SavedAddressActions.reset());
  }, [SavedAddressActions]);

  /** If we are closing the drawer then clear the saved address state.
   * This guarantees we have a fresh slate on mount */
  useEffect(() => {
    if (!drawerState) {
      dispatch(SavedAddressActions.reset());
    }
  }, [drawerState]);

  /** On receiving a new passenger id, load it into the SavedAddress store */
  useEffect(() => {
    dispatch(SavedAddressActions.reset());
    if (patientId) dispatch(SavedAddressActions.setPassengerId(patientId));
  }, [patientId]);

  /** On receiving a new passenger id, load their SavedAddresses */
  useEffect(() => {
    if (patientId && !addressList) {
      dispatch(getSavedAddressesThunk({ patientId }));
    }
  }, [patientId]);

  /** A new value has been entered in the address input.
   * Search for coordinates using mapbox */
  useEffect(() => {
    if (rawAddress.length >= 3) {
      debounceAutoComplete(rawAddress);
    } else {
      setShowAutoComplete(false);
    }
  }, [rawAddress]);

  /**
   * If we are purposefully clearing the address (such as through
   * a _reset_ event) then we want to also clear the rawAddress
   */
  useEffect(() => {
    if (savedAddress.address === '') {
      setRawAddress('');
    }
  }, [savedAddress.address]);

  /**
   * Add event handler to auto-close the drawer if the
   * user clicks outside of it
   */
  useEffect(() => {
    document.addEventListener('mousedown', onExternalClick);
    return () => document.removeEventListener('mousedown', onExternalClick);
  }, [onExternalClick]);

  /** If unmounting the SavedAddress components then reset the
   * SavedAddress state data */
  useEffect(() => {
    return () => {
      dispatch(SavedAddressActions.reset());
    };
  }, []);

  return (
    <div id="savedAddressColumn" ref={ref}>
      <div id="savedAddressClose" onClick={onCloseDrawer}>
        <SvgClose />
      </div>

      <div className="header">
        <SvgGooglePin />
        <p>Google Maps</p>
      </div>

      <div className="row">
        <SavedAddressInput
          id="savedAddressInput"
          type="text"
          autoComplete="off"
          placeholder="Type address you want to correct"
          value={rawAddress}
          onChange={e => setRawAddress(e.target.value)}
          error={'savedAddressInput' in errors ? errors.savedAddressInput : undefined}
        />

        <AutoComplete
          data={autoCompleteData}
          dataKey="description"
          showModal={showAutoComplete}
          select={onAutoCompleteSelect}
        />
      </div>

      <SavedAddressLatLng
        latitude={savedAddress.latitude}
        longitude={savedAddress.longitude}
      />

      <div className="row">
        <label htmlFor="savedAddressName">
          Custom Name<span>*</span>
        </label>
        <SavedAddressInput
          id="savedAddressName"
          name="savedAddressName"
          type="text"
          autoComplete="off"
          placeholder="ex: Correct home location"
          value={savedAddress.name}
          onChange={e => onChangeName(e.target.value)}
          error={'savedAddressName' in errors ? errors.savedAddressName : undefined}
        />
      </div>

      <div id="savedAddressFormButtons" className="row">
        <button id="saveButton" onClick={onSave}>
          + Save
        </button>

        <button id="cancelButton" onClick={onClear}>
          Cancel
        </button>
      </div>

      <div className="savedAddressCardList">
        <div className="header">Saved Addresses</div>
        <hr />

        {addressList.map((address, idx) => (
          <SavedAddressCard
            key={idx}
            index={idx}
            savedAddress={address}
            onRemove={onRemove}
            onChangeDefaultAddress={onChangeDefaultAddress}
          />
        ))}
      </div>
    </div>
  );
};

type SavedLatLngProps = {
  latitude: number;
  longitude: number;
};

const SavedAddressLatLng: React.FC<SavedLatLngProps> = ({ latitude, longitude }) => {
  let formattedText = 'lat/long';

  if (latitude && longitude) {
    formattedText =
      latitude.toFixed(4) + '/' + longitude.toFixed(LAT_LNG_DISPLAY_PRECISION);
  }

  return <div className="row savedAddressLatLng">{formattedText}</div>;
};

interface SavedAddressInputProps extends React.ComponentPropsWithoutRef<'input'> {
  error?: string;
}

const SavedAddressInput: React.FC<SavedAddressInputProps> = props => {
  const { type = 'text', autoComplete = 'off', error } = props;

  let className = props?.className ?? '';
  if (error) {
    className += ' error';
  }

  return (
    <input {...props} className={className} type={type} autoComplete={autoComplete} />
  );
};

const mapStateToProps = state => ({
  patientId: state.patients?.patientDetails?.id
});

export default connect(mapStateToProps)(SavedAddressColumn);
