import React from 'react';
import PropTypes from 'prop-types';
import { laravelUrl, formatPhoneNumber } from '~/utilities/helperFunctions';
import { adminToken, logout } from '~/utilities/auth.helper';
import { NavLink } from 'react-router-dom';
import { DAVITA_NETWORK_ID } from '~/constants';

const UserColumn = ({ user, profile, hospitalData, editProfile, showEdit }) => {
  const handleLogout = e => {
    e.preventDefault();
    logout('User logged out via clicking the Log Out button in UserColumn.js');
  };

  /**
   * determine if we're going to show HNM settings and teams text
   * @param {string} userRole - user role
   * @return {boolean} return true if show HNM options
   */
  const showHNMOptions = userRole => {
    const id = profile?.profile?.id;

    if (userRole === 'HospitalNetworkManager') {
      return id !== DAVITA_NETWORK_ID || adminToken;
    }

    return true;
  };

  /**
   * generate an array of json objects representing data used by drop down menu in header
   * @param {string} userRole - role
   * @return {array} data, an array of json representing the drop down menu values
   */
  const getDropDownData = userRole => {
    const data = [];
    let formattedRole = '';
    switch (userRole) {
      case 'HospitalManager': {
        data.push({
          url: laravelUrl('/hospital/casemanagers'),
          urlText: 'Case Managers'
        });
        data.push({ url: laravelUrl('/hospital/admins'), urlText: 'Facility Admins' });
        data.push({ url: laravelUrl('/hospital/settings'), urlText: 'Settings' });
        formattedRole = 'Facility Manager';
        break;
      }
      case 'HospitalNetworkManager': {
        if (showHNMOptions(userRole)) {
          data.push({
            url: laravelUrl('/hospital_network/hospitals'),
            urlText: 'Manage Facilities'
          });
          data.push({
            url: laravelUrl('/hospital_network/case_manager'),
            urlText: 'Case Managers'
          });
          data.push({ url: laravelUrl('/hospital/admins'), urlText: 'Facility Admins' });
          data.push({ url: laravelUrl('/billing/importers'), urlText: 'Importers' });
          data.push({
            url: laravelUrl('/hospital_network/settings'),
            urlText: 'Settings'
          });
        }
        formattedRole = 'Facility Network Manager';
        break;
      }
      case 'HospitalOwner': {
        data.push({ url: '/hospitalnetworks/managers', urlText: 'Network Managers' });
        data.push({
          url: laravelUrl('/hospital_network/hospitals'),
          urlText: 'Manage Facilities'
        });
        data.push({
          url: laravelUrl('/hospital_network/case_manager'),
          urlText: 'Case Managers'
        });
        data.push({ url: laravelUrl('/hospital/admins'), urlText: 'Facility Admins' });
        data.push({ url: laravelUrl('/billing/importers'), urlText: 'Importers' });
        data.push({ url: laravelUrl('/hospital_network/settings'), urlText: 'Settings' });
        formattedRole = 'Facility Owner';
        break;
      }
      case 'HospitalAdmin': {
        data.push({
          url: laravelUrl('/hospital/casemanagers'),
          urlText: 'Case Managers'
        });
        data.push({ url: laravelUrl('/hospital/settings'), urlText: 'Settings' });
        formattedRole = 'Facility Admin';
        break;
      }
      case 'CaseManager': {
        formattedRole = 'Case Manager';
        break;
      }
      case 'HealthPlanOwner': {
        data.push({ url: '/hp/settings', urlText: 'Settings' });
        break;
      }
      case 'HealthPlanAdmin': {
        data.push({ url: '/hp/settings', urlText: 'Settings' });
        break;
      }

      default:
        break;
    }

    return { data, formattedRole };
  };

  const userRole = user.role;
  const hideLogout = user?.sso === true;
  const { data, formattedRole } = getDropDownData(userRole);
  const hospitalGroupName = profile?.profile?.hospitalGroupName;
  const hospitalName = hospitalData?.hospitalName;
  let phone = user?.mobileNo;

  if (userRole === 'HospitalOwner') {
    phone = profile?.profile?.contactNo1 ?? null;
  }

  return (
    <section className="UserColumn">
      <div className="head">
        <div className="name">
          <p>
            {user.firstName} {user.lastName}
          </p>
        </div>
        {showEdit ? (
          <a className="edit" onClick={editProfile}>
            EDIT
          </a>
        ) : null}
      </div>
      <div className="scrollableColumn">
        <div className="userData">
          {hospitalName !== null ? (
            <div className="userProfileCont">
              <p className="title">Facility Affiliate</p>
              <p className="val">{hospitalName}</p>
            </div>
          ) : null}
          {hospitalGroupName !== null ? (
            <div className="userProfileCont">
              <p className="title">Facility Network</p>
              <p className="val">{hospitalGroupName}</p>
            </div>
          ) : null}
          <div className="userProfileCont">
            <p className="title">Role</p>
            <p className="val">{formattedRole}</p>
          </div>
          {phone !== null ? (
            <div className="userProfileCont">
              <p className="title">Phone Number</p>
              <p className="val">{formatPhoneNumber(phone)}</p>
            </div>
          ) : null}
          <div className="userProfileCont">
            <p className="title">Email</p>
            <p className="val">{user.email}</p>
          </div>
          <div className="userProfileCont pwd">
            <p className="title">Password</p>
            <p className="val">****************</p>
          </div>
        </div>
        {userRole !== 'CaseManager' && showHNMOptions(userRole) ? (
          <div className="teamsHeading">Teams</div>
        ) : null}
        <ul className="teams">
          {data.map((drop, key) => {
            if (
              userRole === 'HospitalOwner' &&
              drop.url === '/hospitalnetworks/managers'
            ) {
              return (
                <li key={key}>
                  <NavLink data-tip="Network Managers" exact to={drop.url}>
                    {drop.urlText}
                  </NavLink>
                </li>
              );
            } else {
              return (
                <li key={key}>
                  <a href={drop.url}>{drop.urlText}</a>
                </li>
              );
            }
          })}
          {!hideLogout ? (
            <li>
              <a href="#" onClick={handleLogout}>
                Logout
              </a>
            </li>
          ) : null}
          {adminToken ? (
            <li>
              <a href={laravelUrl('/login_as_user/stop')}>Back to Admin</a>
            </li>
          ) : null}
        </ul>
      </div>
    </section>
  );
};

UserColumn.propTypes = {
  user: PropTypes.object,
  editProfile: PropTypes.func,
  profile: PropTypes.object,
  status: PropTypes.number,
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  showEdit: PropTypes.bool
};

UserColumn.defaultProps = {
  user: {},
  editProfile: () => {},
  profile: {},
  status: 200,
  hospitalData: {},
  showEdit: true
};

export default UserColumn;
