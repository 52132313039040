import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import PropTypes from 'prop-types';
import { Modal } from '@SRHealth/frontend-lib';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { reset } from '~/Modules/reducer';
import { getUser, sessionChecker } from '~/Modules/user';
import { MAX_HOSPITALS_PUSHER_AUTH } from '~/constants';
import LoadingComponent from './Components/LoadingComponent';
import ErrorHandler from '../ErrorHandler';
import RideHistory from '../RideHistory';
import ScheduledRides from '../ScheduledRides';
import Plans from '../Plans';
import Settings from '../Settings';
import Importer from '../Importer';
import HospitalNetwork from '../HospitalNetwork';
import UserProfile from '../UserProfile';
import MemberProfiles from '../MemberProfiles';
import Expenses from '../Expenses';
import history from '~/history';
import SRModal from '~/Shared/Components/Modal/Modal';
import theme from '~/theme';
import { ThemeProvider } from '@material-ui/core';
import { isEmpty } from '~/utilities/guards';
import useAnalytics from '~/hooks/useAnalytics';
import { getTrackedPageName } from '~/utilities/analytics.helper';
import { RideBooking } from '~/Pages/RideBooking/RideBooking';
import { terminatePusherConnection } from '~/utilities/pusherRedux';

class App extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);

    this.state = {
      showRedirect: false,
      redirectPath: ''
    };

    this.startTime = new Date();
  }

  /**
   * load google api after dom loads, this removed the need to place a script tag in index.html
   * then we can pass as props to the child components
   * @return {undefined} returns nothing
   */
  componentDidMount() {
    const { user, getUser } = this.props;

    if (!user || !Object.keys(user)?.length) {
      getUser(true);
      // start timer to check user status every 2m (e.g. "heartbeat")
      sessionChecker();
    }

    window.addEventListener('beforeunload', terminatePusherConnection);
  }

  /**
   * load liveagentchat
   * @param {object} prevProps - previous props object
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const state = { showRedirect: false };

    const prevPageName = getTrackedPageName(prevProps.location.pathname);
    const currentPageName = getTrackedPageName(this.props.location.pathname);

    if (prevPageName !== currentPageName) {
      // If no matching tracked page, don't track the time. Otherwise, do.
      if (prevPageName) {
        const endTime = new Date();
        const TimeOnPage = endTime - this.startTime; // time spent on previous page in milliseconds

        const analytics = this.props.analytics;
        analytics?.trackEvent('Page Navigation', {
          EventTriggerTime: Date.now(),
          PageName: prevPageName,
          TimeOnPage
        });
      }

      this.startTime = new Date(); // reset the startTime for the new page
    }

    if (isEmpty(prevProps.user) && !isEmpty(this.props.user)) {
      this.setState(state);
    }
  }

  /**
   * clear redux state
   * @return {undefined} returns nada
   */
  componentWillUnmount() {
    this.props.reset();
  }

  render() {
    const user = this.props.user;
    const { showRedirect, redirectPath } = this.state;

    const exceedsMaxHospitalsForPusher =
      (this.props.user?.hospitalUsers ?? [])?.length > MAX_HOSPITALS_PUSHER_AUTH;

    return (
      <ThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
          <ErrorHandler user={user}>
            <Modal />
            <div className="App">
              {showRedirect ? <Redirect to={{ pathname: redirectPath }} /> : null}
              {!user?.userData ? (
                <LoadingComponent />
              ) : (
                <div>
                  {exceedsMaxHospitalsForPusher ? (
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/ride/reports" />
                      </Route>
                      <Route exact path="/ride/reports">
                        <RideHistory />
                      </Route>
                      <Route exact path="/new/ride/scheduled">
                        <Redirect to="/ride/reports" />
                      </Route>
                      {/* delete this "/new" route and use the pre-existing "/ride/scheduled" route once RBF 4.0 is on by default */}
                      <Route exact path="/ride/scheduled">
                        <Redirect to="/ride/reports" />
                      </Route>
                      <Route exact path="/ride/active">
                        <Redirect to="/ride/reports" />
                      </Route>
                      <Route exact path="/ride/todayswillcalls">
                        <Redirect to="/ride/reports" />
                      </Route>
                      <Route exact path="/ride/all">
                        <Redirect to="/ride/reports" />
                      </Route>
                      <Route exact path="/ride/userprofile">
                        <UserProfile />
                      </Route>
                      <Route exact path="/plans/:id?">
                        <Plans user={user} />
                      </Route>
                      <Route exact path="/hp/settings">
                        <Settings user={user} />
                      </Route>
                      <Route exact path="/import/:type">
                        <Importer user={user} />
                      </Route>
                      <Route exact path="/members/:id?">
                        <Redirect to="/member-profiles" />
                      </Route>
                      <Route exact path="/hospitalnetworks/:type">
                        <HospitalNetwork />
                      </Route>
                      <Route exact path="/member-profiles">
                        <MemberProfiles user={user} />
                      </Route>
                      <Route exact path="/member-profiles/:id/:parent/:child?">
                        <MemberProfiles user={user} />
                      </Route>
                      <Route exact path="/expenses">
                        <Expenses />
                      </Route>
                    </Switch>
                  ) : (
                    <Switch>
                      <Route exact path="/">
                        <Redirect to="/ride/scheduled" />
                      </Route>
                      <Route exact path="/ride/reports">
                        <RideHistory />
                      </Route>
                      <Route exact path="/ride/new/:id">
                        <RideBooking mode="new" />
                      </Route>
                      <Route exact path="/ride/scheduled">
                        <ScheduledRides user={user} page="scheduled" />
                      </Route>
                      <Route exact path="/ride/todayswillcalls">
                        <ScheduledRides user={user} page="willcalls" />
                      </Route>
                      <Route exact path="/ride/active">
                        <ScheduledRides user={user} page="active" />
                      </Route>
                      <Route exact path="/ride/ride-share-alerts">
                        <ScheduledRides user={user} page="ride-share-alerts" />
                      </Route>
                      <Route exact path="/ride/ride-nemt-alerts">
                        <ScheduledRides user={user} page="ride-nemt-alerts" />
                      </Route>
                      <Route exact path="/ride/all">
                        <ScheduledRides user={user} page="all" />
                      </Route>
                      <Route exact path="/ride/userprofile">
                        <UserProfile />
                      </Route>
                      <Route exact path="/plans/:id?">
                        <Plans user={user} />
                      </Route>
                      <Route exact path="/hp/settings">
                        <Settings user={user} />
                      </Route>
                      <Route exact path="/import/:type">
                        <Importer user={user} />
                      </Route>
                      <Route exact path="/members/:id?">
                        <Redirect to="/member-profiles" />
                      </Route>
                      <Route exact path="/hospitalnetworks/:type">
                        <HospitalNetwork />
                      </Route>
                      <Route exact path="/member-profiles">
                        <MemberProfiles user={user} />
                      </Route>
                      <Route exact path="/member-profiles/:id/:parent/:child?">
                        <MemberProfiles user={user} />
                      </Route>
                      <Route exact path="/expenses">
                        <Expenses />
                      </Route>
                    </Switch>
                  )}

                  <SRModal />
                </div>
              )}
            </div>
          </ErrorHandler>
        </ConnectedRouter>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  user: PropTypes.object,
  getUser: PropTypes.func,
  reset: PropTypes.func,
  analytics: PropTypes.object
};

App.defaultProps = {
  user: {},
  getUser: () => {},
  reset: () => {},
  analytics: {}
};

const mapStateToProps = state => ({
  user: state?.user,
  location: state.router.location
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getUser: sendRedux => getUser(sendRedux),
      reset: () => reset()
    },
    dispatch
  );

// wrapping App with useAnalytics.HOC allows us to use the analytics hook in
// this component without needing to convert it into a functional component.
// Also allows us to pass analytics as a prop to any subcomponent to avoid re-wrapping them.
export default connect(mapStateToProps, mapDispatchToProps)(useAnalytics.HOC(App));
