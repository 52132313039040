import { TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';

export type RideLayoutProps = {
  label: string;
};

export default function RideLayout({
  label,
  children
}: React.PropsWithChildren<RideLayoutProps>) {
  return (
    <section
      className={
        'reset-div w-full flex flex-col items-center rounded-[8px] shadow-b-wrap overflow-visible' +
        ` bg-${TOKENS.SURFACE.PRIMARY.WHITE} border-1 border-${TOKENS.BORDER.NEUTRAL_LT}`
      }
      style={{ width: '620px' }}
    >
      <header
        className={
          'py-[16px] w-full heading-lg text-center ' +
          ` bg-${TOKENS.SURFACE.PRIMARY.XDK} text-${TOKENS.FONT.INVERSE}`
        }
      >
        {label}
      </header>
      {children}
    </section>
  );
}
