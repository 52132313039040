import React from 'react';
import PropTypes from 'prop-types';
import SvgArrow from '../Svgs/SvgArrow.js';
import SvgMagnifyingGlass from '../Svgs/SvgMagnifyingGlass.js';
import Select from 'react-select';

const SearchDropdown = props => {
  const { showSearch, items, error, value, dropDownCallback, placeholder, handleClick, name, customClassName } = props;
  let className = 'CustomDropDown';
  className = customClassName !== '' ? `${className} ${customClassName}` : className;

  let dropDownField = value !== 0 && value !== '' ? 'dropDownField highlight' : 'dropDownField';
  dropDownField = error ? `${dropDownField} error` : dropDownField;

  const handleBlur = () => { 
    handleClick({}, false);
  };

  const clicked = e => {
    e.preventDefault();
    handleClick(e, true);
  };

  const handleChange = opt => {
    dropDownCallback(opt);
  };

  return (

    <div className={className}>
      <div className={dropDownField} onClick={clicked}>
        <span className="transportContent">{placeholder}</span>
        <SvgArrow className="whiteDownArrow" />
      </div>
      {showSearch ?
        <>
          {
            <Select
              options={items}
              onChange={opt => handleChange(opt)}
              onBlur={handleBlur}
              className="select-search-box"
              classNamePrefix="select-search-box"
              menuIsOpen={true}
              autoFocus={true}
              id={name}
            />
          }
          <SvgMagnifyingGlass className="black" />
        </>
        : null
      }
    </div>
  );
};

SearchDropdown.defaultProps = {
  items: [],
  showSearch: false,
  error: false,
  value: 0,
  dropDownCallback: () => {},
  placeholder: '',
  handleClick: () => {},
  name: 'hospitalSelect',
  customClassName: 'SearchDropdown'
};

SearchDropdown.propTypes = {
  items: PropTypes.array.isRequired,
  showSearch: PropTypes.bool,
  error: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  dropDownCallback: PropTypes.func,
  placeholder: PropTypes.string,
  handleClick: PropTypes.func,
  name: PropTypes.string.isRequired,
  customClassName: PropTypes.string
};

export default SearchDropdown;
