import moment from 'moment';
import { cloneDeep, isEmpty } from 'lodash-es';
import Button from '../Button';
import SlidingPane from 'react-sliding-pane';
import SvgDownload from '~/Shared/Components/Svgs/SvgDownload';
import LoadingModal from '~/Shared/Components/LoadingModal';
import ClipboardButton from '~/Shared/Components/ClipboardButton';
import { ReportPdf } from './ReportPdf';
import ExpensesService from '~/services/expenses.service';
import { getTableData } from '~/Modules/expenses/actions';
import ExpenseReportForm from './ExpenseReportForm';
import { getMemberProfile } from '~/Modules/memberProfile';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';

import {
  setStatus,
  getReportById,
  setExpenseModal,
  setExpenseField,
  setExpenseOptions,
  setExpenseSlideout,
  updateExpenseReport,
  createExpenseReport,
  getReportActivityLog,
  setReportPersonalInfo,
  setRideIdOnExpenseReport
} from '~/Modules/memberExpenses/actions';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import ExpensesList from './ExpensesList';
import ConfirmModal from '../Modal/ConfirmModal';
import ExpenseModal from '../Modal/ExpenseModal';
import { generateExpenseId } from '~/utilities/helperFunctions';
import { generateRepeatingExpenses } from '~/services/recurrence.service';
import ExpenseReportActivityLog from './ExpenseReportActivityLog';
import { EditRepeatingExpensesEnum } from '../RepeatingExpenses/constants';
import ConfirmDuplicatesModal from '../Modal/ConfirmDuplicatesModal';

const {
  expenseTotals,
  showMerchantAddress,
  showDistanceMileageFields,
  checkDuplicates,
  showNumberOfNightsDropdown,
  showNumberOfDaysDropdown
} = new ExpensesService();

const Divider = () => <div className="divider"></div>;

const ExpenseSlideout = () => {
  const dispatch = useDispatch();
  const [showPdf, setShowPdf] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);
  const [shouldGetMemberData, setShouldGetMemberData] = useState(false);
  const [duplicateExpenses, setDuplicateExpenses] = useState(null);
  const [overlapParentExpenses, setOverlapParentExpenses] = useState(null);
  const [disableSaveButtonWhileDuplicateCheck, setDisableSaveButtonWhileDuplicateCheck] =
    useState(false);

  // Organized props from redux.
  const {
    modal,
    report,
    status,
    options,
    slideout,
    tableQuery,
    filterParams,
    healthPlans,
    activityLog,
    hospitalData,
    personalInfo,
    vehicle_type,
    vehicle_types,
    complianceInfo,
    dependentFields,
    expenseSettings,
    vehicle_sub_type,
    user,
    hospitalGroupId,
    ridesInWindow,
    rideId
  } = selectState();

  // Wrapper for setting redux expense fields
  const setField = x => dispatch(setExpenseField(x));
  const setRideId = x => dispatch(setRideIdOnExpenseReport(x));

  // If job id is passed to slideout, fetch that expense.
  useEffect(() => {
    if (slideout.id) {
      batch(() => {
        dispatch(getReportById(slideout.id));
        dispatch(getReportActivityLog(slideout.id));
      });
      setShouldGetMemberData(true);
    }
  }, [slideout.id]);

  // If edit mode, and report data fetched, get member data.
  useEffect(() => {
    if (shouldGetMemberData) {
      const params = { passengerId: report.memberId };

      if (report.client_unique_id && report.client_unique_id !== 'NULL') {
        params['passengerId'] = report.client_unique_id;
        params['subPlanId'] = report.health_sub_plan_id;
      }

      dispatch(getMemberProfile(params));
      setShouldGetMemberData(false);
    }
  }, [report.memberId]);

  // Load the form field listeners for the expense report form.
  // And set default fields for some values.
  (function formFieldListeners() {
    // If report creation is success, close modal.
    useEffect(() => {
      if (status.create === 'success') {
        batch(() => {
          dispatch(setStatus({ name: 'create', value: null }));
          dispatch(setExpenseSlideout({ type: null, show: false, id: null }));
        });
      }
      // On success edit, update status, get table data, and close slideout.
      if (status.update === 'success') {
        const { page, limit } = tableQuery;

        batch(() => {
          dispatch(setStatus({ name: 'update', value: null }));
          dispatch(getTableData({ page, limit, query: filterParams }));
          dispatch(setExpenseSlideout({ type: null, show: false, id: null }));
        });
      }
    }, [status]);

    // On load set personal info for expenses
    useEffect(() => {
      if (personalInfo.id && slideout.mode !== 'edit') {
        dispatch(setReportPersonalInfo(personalInfo));
      }
    }, [personalInfo.id]);

    // On memeberData, load set options for expense fields
    useEffect(() => {
      if (!options.vehicle_types[0] && vehicle_types[0]) {
        dispatch(
          setExpenseOptions([
            {
              name: 'vehicle_types',
              value: vehicle_types
            }
          ])
        );
      }
    }, [vehicle_types]);

    // On user data load, set dependent options.
    // These are mainly used to populate dropdown options.
    useEffect(() => {
      if (!options.expenseSettings.paidBy.length && typeof expenseSettings === 'object') {
        dispatch(
          setExpenseOptions([
            { name: 'healthPlans', value: healthPlans },
            { name: 'hospitalData', value: hospitalData },
            { name: 'complianceInfo', value: complianceInfo },
            { name: 'expenseSettings', value: expenseSettings },
            { name: 'dependentFields', value: dependentFields }
          ])
        );
      }
    }, [expenseSettings]);

    // When mobiltyType updates, reset subtype.
    useEffect(() => {
      if (firstLoad) {
        setFirstLoad(false);
      }
      if (report.memberId && !firstLoad) {
        setField({ name: 'subMobilityType', value: null });
      }
    }, [report.mobilityType]);

    // On load, set default mobility if member has them.
    useEffect(() => {
      if (vehicle_type && !report.mobilityType && slideout.mode !== 'edit') {
        batch(() => {
          setField({ name: 'mobilityType', value: vehicle_type });
          setField({
            name: 'subMobilityType',
            value: vehicle_sub_type
          });
        });
      }
      setFirstLoad(true);
    }, [vehicle_type]);

    // If status is Reimbursed, set first option as default
    useEffect(() => {
      if (report.status === 'Reimbursed' && !report.reimbursementMethod) {
        if (expenseSettings.reimbursementMethod[0]) {
          setField({
            name: 'reimbursementMethod',
            value: expenseSettings.reimbursementMethod[0].value
          });
        }
      }
    }, [report.status]);

    // Listen for change in fromDate, it should update toDate
    useEffect(() => {
      if (report.fromDate && !report.toDate) {
        setField({ name: 'toDate', value: report.fromDate });
      }
    }, [report.fromDate]);

    // Set default value for hospitalId
    useEffect(() => {
      if (slideout.mode !== 'edit' && !report.hospitalId && hospitalData[0]) {
        setField({ name: 'hospitalId', value: hospitalData[0].id });
      }
    }, []);
  })();

  const PDFReport = () =>
    useMemo(() => {
      return showPdf ? (
        <div style={{ display: 'inline' }}>
          <ReportPdf report={report} options={options} activityLog={activityLog} />
        </div>
      ) : null;
    }, [showPdf, report]);

  // Validate required expense fields
  const validate = () => {
    try {
      ['toDate', 'fromDate'].map(field => {
        if (!report[field]) throw new Error('Missing Field');
      });
      return true;
    } catch {
      return false;
    }
  };

  // If report status is rejected/cancelled, make sure user enters in reason.
  const validateStatus = () => {
    try {
      if (report.status === 'Rejected' || report.status === 'Cancelled') {
        if (!report.statusReason) {
          alert('Please enter a reason for the expense status');
          throw new Error('Missing Field');
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  };

  const isMultiNightLodgingExpense = expense => {
    if (!expense.category) return false;

    const isLodgingExpense = !!['Advanced Funds — Lodging', 'Lodging'].filter(
      field => !!expense.category.match(field)
    ).length;

    const multiNightStay = expense.numberOfNights > 1 ? true : false;

    return isLodgingExpense && multiNightStay;
  };

  const isMultiDaysMealsExpense = expense => {
    if (!expense.category) return false;

    const isMealsExpense = !!['Advanced Funds — Meals', 'Meals'].filter(
      field => !!expense.category.match(field)
    ).length;

    const multiDayExpense = expense.numberOfDays > 1 ? true : false;

    return isMealsExpense && multiDayExpense;
  };

  const generateMultiDayOrNighExpenses = ({
    existingExpenses,
    expense,
    hospitalGroupId,
    dayOrNightExpenseType = 'night'
  }) => {
    let numberOfDayOrNightType = 'numberOfNights';
    if (dayOrNightExpenseType === 'day') {
      numberOfDayOrNightType = 'numberOfDays';
    }

    const lastEligibleDate = moment(new Date(convertDate(report.toDate)));

    expense = {
      ...expense,
      id: generateExpenseId(hospitalGroupId)
    };

    const multiDayOrNightOccurrenceInfo = {
      form: {
        endOn: 'END_ON_OCCURRENCE',
        occurrence: `${expense[numberOfDayOrNightType] - 1}`,
        repeat: 1
      },
      frequency: 'DAILY'
    };

    const repeatingExpenses = generateRepeatingExpenses({
      selectedExpense: expense,
      state: multiDayOrNightOccurrenceInfo,
      hospitalGroupId,
      lastEligibleDate
    });

    expense = {
      ...expense,
      ...(repeatingExpenses[0]?.repeatingExpenseId && {
        repeatingExpenseId: repeatingExpenses[0].repeatingExpenseId
      })
    };

    return [...existingExpenses, expense, ...repeatingExpenses].map(e =>
      formatExpense({ expense: e })
    );
  };

  // Save individual expenses to report
  const saveExpense = ({ expense, index = undefined }) => {
    let expenses = [...report.expenses];
    //
    if (!isNaN(index)) {
      // If index is passed in with expense
      // update that specific expense.
      expenses[index] = expense;
    } else if (isMultiNightLodgingExpense(expense)) {
      // add new multi night lodging expenses
      expenses = generateMultiDayOrNighExpenses({
        existingExpenses: expenses,
        expense,
        hospitalGroupId
      });
    } else if (isMultiDaysMealsExpense(expense)) {
      expenses = generateMultiDayOrNighExpenses({
        existingExpenses: expenses,
        expense,
        hospitalGroupId,
        dayOrNightExpenseType: 'day'
      });
    } else {
      // else add single new expense to expenses
      expense = { ...expense, id: generateExpenseId(hospitalGroupId) };
      expenses = [...expenses, expense];
    }
    setField({
      name: 'expenses',
      value: expenses
    });
  };

  const formatExpense = ({ expense }) => {
    const { category } = expense;
    const exp = cloneDeep(expense);
    exp.expenseDate = convertDate(exp.expenseDate);

    if (!showMerchantAddress({ category })) {
      exp.merchantAddress = '';
    } else {
      exp.toAddress = { address: null, lngLat: null };
      exp.fromAddress = { address: null, lngLat: null };
    }
    if (!showDistanceMileageFields({ category })) {
      exp.distance = 0;
      exp.ratePerMile = 0;
    }
    if (!exp.amount) exp.amount = '0.00';

    if (!showNumberOfNightsDropdown({ category })) {
      exp.numberOfNights = 0;
    }
    if (!showNumberOfDaysDropdown({ category })) {
      exp.numberOfDays = 0;
    }
    return exp;
  };

  const createRepeatingExpenses = ({ repeatingExpenses, expense }) => {
    const expenses = [...report.expenses, ...repeatingExpenses];
    const e = expenses
      .map(e => {
        if (e.id === expense.id && repeatingExpenses.length) {
          return { ...e, repeatingExpenseId: repeatingExpenses[0].repeatingExpenseId };
        }
        return { ...e };
      })
      .map(e => formatExpense({ expense: e }));

    setField({
      name: 'expenses',
      value: e
    });
  };

  const saveRepeatingExpense = ({
    options = [],
    expense,
    oldExpense,
    index,
    mode,
    repeatingExpenses
  }) => {
    if (mode === 'create') {
      createRepeatingExpenses({ repeatingExpenses, expense });
    } else {
      const option = options.find(o => o.checked);
      const diff = moment(expense.expenseDate, 'YYYY-MM-DD').diff(
        moment(oldExpense.expenseDate, 'YYYY-MM-DD'),
        'days'
      );
      const generateDate = e => {
        if (diff === 0) {
          if (e.id === expense.id) return expense.expenseDate;
          return e.expenseDate;
        } else {
          return moment(e.expenseDate, 'YYYY-MM-DD')
            .add(diff, 'days')
            .format('YYYY-MM-DD');
        }
      };
      if (option.id === EditRepeatingExpensesEnum.THIS_EXPENSE) {
        saveExpense({ expense: formatExpense({ expense }), index });
      }
      if (option.id === EditRepeatingExpensesEnum.THIS_AND_FOLLOWING) {
        const changedExpenses = [...report.expenses]
          .filter(
            e =>
              e.repeatingExpenseId === expense.repeatingExpenseId &&
              moment(e.expenseDate, 'YYYY-MM-DD') >=
                moment(oldExpense.expenseDate, 'YYYY-MM-DD')
          )
          .map(e => ({ ...expense, id: e.id, expenseDate: generateDate(e) }))
          .map(e => formatExpense({ expense: e }));
        const reportExpenses = [...report.expenses].filter(
          e => !changedExpenses.map(e => e.id).includes(e.id)
        );
        setField({
          name: 'expenses',
          value: [...reportExpenses, ...changedExpenses]
        });
      }

      if (option.id === EditRepeatingExpensesEnum.ALL_EXPENSES) {
        const changedExpenses = [...report.expenses]
          .filter(e => e.repeatingExpenseId === expense.repeatingExpenseId)
          .map(e => ({ ...expense, id: e.id, expenseDate: generateDate(e) }))
          .map(e => formatExpense({ expense: e }));
        const reportExpenses = [...report.expenses].filter(
          e => !changedExpenses.map(e => e.id).includes(e.id)
        );
        setField({
          name: 'expenses',
          value: [...reportExpenses, ...changedExpenses]
        });
      }
    }
  };

  // Helper function for date conversion
  const convertDate = d => {
    try {
      const date = moment(d).format('YYYY-MM-DD');

      if (isNaN(Date.parse(date))) throw new Error();

      return `${date} 12:00:00`;
    } catch (error) {
      return d;
    }
  };

  // Before sending to report to api, format some req properties.
  const formatReport = report => {
    const { difference, totalSum } = expenseTotals({
      expenses: report.expenses
    });
    report.totalExpenseAmt = totalSum;
    report.totalReimbursableAmt = difference;
    report.status = slideout.mode === 'edit' ? report.status : 'To Be Reviewed';
    report.toDate = convertDate(report.toDate);
    if (report.status !== 'Reimbursed') {
      report.reimbursementMethod = null;
    }
    if (report.status !== 'Cancelled' && report.status !== 'Rejected') {
      report.statusReason = null;
    }
    report.fromDate = convertDate(report.fromDate);
    report.memberId = personalInfo.id;

    if ('client_unique_id' in personalInfo) {
      report.client_unique_id = personalInfo.client_unique_id;
    }

    delete report.previousStatus;
    return report;
  };

  // Depending on slideout mode, either create or update existing expense report
  const submitExpenseReport = () => {
    const newReport = formatReport(cloneDeep(report));

    const options = {
      hasDuplicates: async () => {
        const { memberId, expenses, _id, fromDate, toDate } = report;

        const formattedFromDate = convertDate(fromDate);
        const formattedToDate = convertDate(toDate);

        const { duplicates, overlaps } = await checkDuplicates({
          memberId,
          expenses,
          expenseReportId: _id,
          fromDate: formattedFromDate,
          toDate: formattedToDate
        });

        if (!isEmpty(duplicates) || !isEmpty(overlaps)) {
          setDuplicateExpenses(duplicates);
          setOverlapParentExpenses(overlaps);
          return true;
        } else return false;
      },
      execute: () => {
        if (slideout.mode === 'edit') {
          dispatch(
            updateExpenseReport({ report: newReport, id: slideout.id, hospitalGroupId })
          );
        } else {
          dispatch(createExpenseReport({ newReport, hospitalGroupId }));
        }
      }
    };
    return {
      ...options,
      all: async () => {
        //diable/enable
        setDisableSaveButtonWhileDuplicateCheck(true);
        const hasDuplicates = await options.hasDuplicates();
        if (!hasDuplicates) options.execute();
        setDisableSaveButtonWhileDuplicateCheck(false);
      }
    };
  };

  const handleDuplicates = async accepted => {
    if (accepted) submitExpenseReport().execute();
    else {
      setDuplicateExpenses(null);
      setOverlapParentExpenses(null);
    }
  };

  const SlideoutHeader = () => (
    <div className="expense-header">
      <div className="expense-title">
        {slideout.mode === 'edit' ? (
          <>
            <span>Expense Report {slideout.id}</span>
            <span className="spacer"></span>
            <ClipboardButton textToCopy={slideout.id} svgsize="large" />
          </>
        ) : (
          'New Expense Report'
        )}
      </div>
      <div className="button-wrapper">
        <Button
          className="grey-btn"
          onClick={() => dispatch(setExpenseModal({ type: 'confirmReport', show: true }))}
        >
          Cancel
        </Button>
        <div className="spacer"></div>
        <Button
          className="green-btn"
          onClick={() => {
            if (!validateStatus()) return null;
            submitExpenseReport().all();
          }}
          disabled={
            !validate() ||
            disableSaveButtonWhileDuplicateCheck ||
            status.create === 'running' ||
            status.update === 'running'
          }
          running={
            disableSaveButtonWhileDuplicateCheck ||
            status.create === 'running' ||
            status.update === 'running'
          }
        >
          Save
        </Button>
        {slideout.mode === 'edit' ? (
          <>
            <div className="pdf-btn" onClick={() => setShowPdf(!showPdf)}>
              <div className="pdf-text cursor">
                <span>
                  <SvgDownload />
                </span>{' '}
                PDF
              </div>
            </div>
            <PDFReport />
          </>
        ) : null}
      </div>
    </div>
  );

  // Show loader if still fetching report data
  if (Object.keys(status).some(key => status[key] === 'running')) {
    return <LoadingModal isOpen={true} label={'Loading...'} />;
  }

  // Dont load until member data is fetched and set in redux.
  if (!personalInfo.id) return null;
  return (
    <div>
      <SlidingPane
        width="976px"
        isOpen={true}
        hideHeader={true}
        className="some-custom-class"
        onRequestClose={() => {}}
        overlayClassName="overlay-slideout"
      >
        <div>
          <SlideoutHeader />
          <Divider />
          <div className="expense-body">
            <ExpenseReportForm
              mode={slideout.mode}
              report={report}
              options={options}
              setField={setField}
              user={user}
              ridesInWindow={ridesInWindow}
              rideId={rideId}
              setRideId={setRideId}
            />
            <ExpensesList
              report={report}
              hospitalGroupId={hospitalGroupId}
              saveRepeatingExpense={saveRepeatingExpense}
              disableAddExpense={!report.fromDate}
            />
          </div>
        </div>
        {slideout.mode === 'edit' ? (
          <ExpenseReportActivityLog activityLog={activityLog} />
        ) : null}
      </SlidingPane>
      {modal.show && modal.type === 'expense' ? (
        <ExpenseModal
          modal={modal}
          report={report}
          saveExpense={({ expense, index }) => saveExpense({ expense, index })}
          saveRepeatingExpense={saveRepeatingExpense}
          ridesInWindow={ridesInWindow}
        />
      ) : null}
      <ConfirmModal modal={modal} />
      <ConfirmDuplicatesModal
        duplicates={duplicateExpenses}
        overlaps={overlapParentExpenses}
        onClose={handleDuplicates}
      />
    </div>
  );
};

/* Helper function to organize what's selected from state;
   Pretty much equivalent to mapStateToProps */
const selectState = () => {
  const { modal, report, options, status, slideout, activityLog } = useSelector(
    state => state.memberExpenses
  );
  const { tableQuery, filterParams } = useSelector(state => state.expenses);
  const memberProfile = useSelector(state => state.memberProfile);
  const { user } = useSelector(state => state);
  const { formData = {}, ridesInWindow } = memberProfile;
  const {
    healthPlans,
    complianceInfo,
    dependentFields,
    hospitalData = [],
    expenseSettings = []
  } = useSelector(state => state.user);

  const { personalInfo = {}, mobility = {}, ride_id: rideId = null } = formData;
  const { vehicle_types = [], memberData = {} } = mobility;
  const { vehicle_type = null, vehicle_sub_type = null } = memberData;
  const hospitalGroupId = useSelector(state => state.user.hospitalGroupId);
  return {
    modal,
    report,
    status,
    options,
    slideout,
    tableQuery,
    filterParams,
    memberData,
    activityLog,
    healthPlans,
    hospitalData,
    personalInfo,
    vehicle_type,
    vehicle_types,
    complianceInfo,
    expenseSettings,
    vehicle_sub_type,
    dependentFields,
    user,
    hospitalGroupId,
    ridesInWindow,
    rideId
  };
};

const arePropsEqual = (prevProps, nextProps) => {
  return cloneDeep(prevProps) !== cloneDeep(nextProps);
};

export default React.memo(ExpenseSlideout, arePropsEqual);
