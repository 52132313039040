import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import SvgMagnifyingGlass from '../Svgs/SvgMagnifyingGlass.js';
import SvgClear from '../Svgs/SvgClear.js';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCloseIcon: '',
      searchVal: ''
    };
  }

  componentDidMount() {
    this.initializeSearchValue();
  }

  componentDidUpdate(prevProps) {
    const searchVal = _.get(this.props, 'defaultFields.query', '');
    const prevSearchVal = _.get(prevProps, 'defaultFields.query', '');

    if (searchVal !== prevSearchVal) {
      this.initializeSearchValue();
    }
  }

  /**
   * search submission
   * @param {event} e - event
   * @return {undefined}
   */
  searchSubmit = e => {
    e.preventDefault();

    const { searchVal } = this.state;
    const params = _.clone(this.props.defaultFields);

    if (searchVal.length >= this.props.charactersMin && searchVal !== params.query) {
      params.query = searchVal;
      params.start = 0;
      params.startLimit = 0;
      this.props.getRows(params);
    } else if (searchVal === '' && params.query !== '') {
      this.clearSubmit();
    }
  };

  /**
   * clears search submission
   * @return {undefined}
   */
  clearSubmit = () => {
    const params = _.clone(this.props.defaultFields);
    if (_.has(params, 'query')) {
      _.unset(params, 'query');
      _.unset(params, 'search');
      params.start = 0;
      this.props.getRows(params);
    }
    this.setState({ showCloseIcon: '', searchVal: '' });
  };

  /**
   * sets searchVal when entering text
   * @param {event} e - event
   * @return {undefined}
   */
  handleChange = e => {
    let showCloseIcon = '';
    if (e.target.value.length > 0) {
      showCloseIcon = ' show';
    }
    this.setState({ showCloseIcon, searchVal: e.target.value });
  };

  /**
   * initialize search value
   * @return {undefined}
   */
  initializeSearchValue() {
    const params = _.clone(this.props.defaultFields);
    if (!_.isNil(params.query) && params.query !== '') {
      this.setState({ searchVal: params.query, showCloseIcon: ' show' });
    } else {
      this.setState({ searchVal: '', showCloseIcon: '' });
    }
  }

  render() {
    return (
      <div className={this.props.className}>
        <form onSubmit={this.searchSubmit} method="post">
          <input type="submit" value="Search" className="inputSubmit" />
          <div className="icon">
            <SvgMagnifyingGlass className="svgMagnifyingGlass" />
          </div>
          <input
            type="text"
            placeholder={this.props.placeHolder}
            className="inputSearch"
            onChange={this.handleChange}
            value={this.state.searchVal}
          />
          <div
            className={`iconClear${this.state.showCloseIcon}`}
            onClick={this.clearSubmit}
          >
            <SvgClear className="svgClear" />
          </div>
        </form>
      </div>
    );
  }
}

Search.propTypes = {
  placeHolder: PropTypes.string,
  getRows: PropTypes.func,
  defaultFields: PropTypes.object,
  charactersMin: PropTypes.number,
  className: PropTypes.string
};

Search.defaultProps = {
  placeHolder: '',
  getRows: () => {},
  defaultFields: {},
  charactersMin: 3,
  className: 'Search'
};

export default Search;
