import { Card, TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';
import TagEligibility from '~/Shared/Components/TagEligibility';
import BenefitDisplay from './BenefitDisplay';
import BenefitUnlimited from './BenefitUnlimited';
import type {
  BenefitType,
  RideBenefitsRecord,
  RideBenefitsUnlimitedRecord
} from '~/Modules/rideBooking';
import { useAppSelector } from '~/Modules';
import { selectEligibility, selectEligibilityDates } from '~/Modules/memberProfile';

const inlineCSS = `
  #fundingSource {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
`;

type HealthPlanProps = {
  benefitsRecord: RideBenefitsRecord | RideBenefitsUnlimitedRecord | undefined;
};

export default function HealthPlan({ benefitsRecord }: HealthPlanProps) {
  /** Determine eligibility status and pass on to <HealthPlan/> component. */
  const isEligible = useAppSelector(s => selectEligibility(s));
  const { startDate, endDate } = useAppSelector(s => selectEligibilityDates(s));

  const healthPlanName = useAppSelector(
    s => s.memberProfile.formData.benefits.healthPlanName
  );
  const healthSubPlanName = useAppSelector(
    s => s.memberProfile.formData.benefits.healthSubPlanName
  );

  const benefitType = getBenefitType(benefitsRecord);
  const eligibilityStatus = isEligible ? 'eligible' : 'ineligible';

  return (
    <>
      <style>{inlineCSS}</style>
      {healthPlanName ? (
        <Card label="Health Plan">
          <Card.Content>
            <div className="flex flex-col gap-[4px]">
              <div
                className={`reset-div text-base border-b-1 border-${TOKENS.BORDER.NEUTRAL_MD}`}
              >
                {healthPlanName} | {healthSubPlanName}
              </div>
              <TagEligibility status={eligibilityStatus} />
              <div className="flex-1"></div>
            </div>
          </Card.Content>
          <Card.Content label="Plan Dates">
            <div className="flex flex-row gap-[4px] py-[4px] items-center">
              <div className="heading-sm">Start Date:</div>
              <div className="text-base">{startDate}</div>
            </div>
            <div className="flex flex-row gap-[4px] py-[4px] items-center">
              <div className="heading-sm">End Date:</div>
              <div className="text-base">{endDate}</div>
            </div>
          </Card.Content>
          <Card.Content label="Plan Benefits">
            {
              {
                none: null,
                unlimited: <BenefitUnlimited />,
                limited: <BenefitDisplay record={benefitsRecord as RideBenefitsRecord} />
              }[benefitType]
            }
          </Card.Content>
        </Card>
      ) : (
        <Card label="Health Plan">This is a self-funded Health Plan.</Card>
      )}
    </>
  );
}

function getBenefitType(
  benefitsRecord?: RideBenefitsRecord | RideBenefitsUnlimitedRecord
): BenefitType {
  if (!benefitsRecord) return 'none';

  return benefitsRecord.limit === 'Unlimited' ? 'unlimited' : 'limited';
}
