import React, { useMemo, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useSavedAddressDrawer } from '~/Shared/Components/SavedAddressDrawer/SavedAddressDrawer';
import { Switch, Tooltip } from '@material-ui/core';
import BookmarkBorderOutlinedIcon from '@material-ui/icons/BookmarkBorderOutlined';
import BookmarkAddedOutlinedIcon from '~/icons/BookmarkAddedOutlined';
import SvgClose from 'Common/Components/Svgs/SvgClose';
import { useSavedAddress } from '~/Shared/Components/BookRides/FormComponents/SavedAddressSelect/useSavedAddress';
import { clearAddressData } from '~/Modules/bookingData';
import SvgSwapVert from '~/Shared/Components/Svgs/SvgSwapVert';

type SwapContainerProps = {
  onSwitchAddresses: () => void;
  updateRide: (key: string | Partial<RideData>, val?: any) => void;
  showSavedAddress: boolean;
  rideDetails: BookingDataStore | RideData;
  editMode?: boolean;
  onClose?: () => void;
};

export const SwapContainer: React.FC<SwapContainerProps> = ({
  onSwitchAddresses,
  updateRide,
  rideDetails,
  showSavedAddress,
  editMode = false,
  onClose = () => {}
}) => {
  if (!showSavedAddress) {
    return (
      <div className="right" onClick={onSwitchAddresses}>
        <SvgSwapVert className="swap" />
      </div>
    );
  }

  return (
    <div
      className={`right ${
        !rideDetails.pickupProviderNotFound && rideDetails.dropoffProviderNotFound
          ? 'dropoffProviderNotFound'
          : ''
      }`}
    >
      <CloseTransportButton onClose={onClose} editRide={editMode} />

      <SavedAddressUtility
        type="Pickup"
        rideDetails={rideDetails}
        updateRide={updateRide}
      />

      <div onClick={onSwitchAddresses}>
        <SvgSwapVert className="swap" />
      </div>

      <SavedAddressUtility
        type="Dropoff"
        rideDetails={rideDetails}
        updateRide={updateRide}
      />
    </div>
  );
};

interface SavedAddressUtilityProps {
  type: 'Pickup' | 'Dropoff';
  rideDetails: BookingDataStore | RideData;
  updateRide: (key: string | Partial<RideData>, val?: any) => void;
}

export const SavedAddressUtility: React.FC<SavedAddressUtilityProps> = ({
  type,
  rideDetails,
  updateRide
}) => {
  const dispatch = useDispatch();
  const [addressState, addressActions] = useSavedAddress();
  const [drawerState, drawerToggle] = useSavedAddressDrawer();

  const typeLower = type.toLowerCase() as 'pickup' | 'dropoff';
  const typeAlt = type === 'Pickup' ? 'from' : 'to';

  const isEnabled = addressState[typeLower];

  const isApprovedProvider =
    `show${type}ApprovedProvider` in rideDetails &&
    rideDetails[`show${type}ApprovedProvider`];

  const isVenue = `show${type}Venue` in rideDetails && rideDetails[`show${type}Venue`];

  const classTerms = ['savedAddressUtility'];

  if (!isEnabled) classTerms.push('disabled');

  if (isProviderNotFound(type, rideDetails)) {
    classTerms.push('dropoffProviderNotFound');
  }

  /** Determine which Icon to display */
  const icon = useMemo(() => {
    return isEnabled && rideDetails[`${typeAlt}MemberSavedAddressId`] ? (
      <BookmarkAddedOutlinedIcon />
    ) : (
      <BookmarkBorderOutlinedIcon />
    );
  }, [rideDetails[`${typeAlt}MemberSavedAddressId`], isEnabled]);

  /** Toggles the saved address */
  const setToggleState = useCallback(() => {
    addressActions.Toggle(typeLower);

    // If we're toggling off the saved address then reset
    // the address data in the booking data store
    if (addressState[typeLower]) {
      dispatch(clearAddressData(typeLower));
    } else {
      // If we're toggling on the saved address then
      // toggle off the other container features
      updateRide({
        [`show${type}ApprovedProvider`]: false,
        [`${typeLower}ProviderNotFound`]: false,
        [`show${type}Venues`]: false
      });
    }
  }, [addressActions, addressState]);

  // Prevent enabling with ApprovedProviders or Venues
  useEffect(() => {
    if (isApprovedProvider || isVenue) {
      addressActions.Toggle(typeLower, false);
    }
  }, [isApprovedProvider, isVenue]);

  /** Prevent the SavedAdressDrawer from remaining open if the
   * SavedAddress feature is turned off */
  useEffect(() => {
    if (drawerState && !isEnabled) {
      drawerToggle.Off();
    }
  }, [isEnabled]);

  /** If we have a member saved address id saved in booking data then make sure
   * we're toggled on */
  useEffect(() => {
    if (rideDetails[`${typeAlt}MemberSavedAddressId`] && !addressState[typeLower]) {
      addressActions.Toggle(typeLower, true);
    }
  }, []);

  return (
    <div className={classTerms.join(' ')}>
      <Switch size="small" checked={isEnabled} onChange={setToggleState} />

      <div style={{ textDecoration: 'underline', pointerEvents: 'none' }}>Save</div>

      <Tooltip
        placement="right"
        arrow
        title={isEnabled && !drawerState ? 'Create a saved address' : ''}
      >
        <div onClick={isEnabled ? drawerToggle.Toggle : () => {}}>{icon}</div>
      </Tooltip>
    </div>
  );
};

/**
 * Displays Missing Fields errors
 * @param props
 * @returns
 */
export const MissingFieldsError: React.FC<{ error?: string }> = ({ error }) => {
  return error ? <div className="transportError">{error}</div> : null;
};

export const CloseTransportButton: React.FC<{
  editRide: boolean;
  onClose: () => void;
}> = ({ editRide, onClose }) => {
  if (!editRide) {
    return null;
  }

  return (
    <div className="close" onClick={onClose}>
      <SvgClose />
    </div>
  );
};

/**
 * Determine if we need to add 'dropoffProviderNotFound' to the class name
 * because our element compositon is wonky
 * @param type
 * @param rideDetails
 * @returns
 */
function isProviderNotFound(
  type: 'Pickup' | 'Dropoff',
  rideDetails: BookingDataStore | RideData
) {
  if (
    rideDetails?.pickupProviderNotFound &&
    rideDetails?.dropoffProviderNotFound &&
    type === 'Dropoff'
  ) {
    return true;
  }

  return false;
}
