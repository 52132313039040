import _ from 'lodash-es';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { timeWithTimezone } from '~/utilities/helperFunctions';

const ExpenseReportActivityLog = ({ activityLog: { data = [] } }) => {
  if (!Array.isArray(data)) return null;
  const { timezone_format } = useSelector(state => state.user.userData);
  const [showDetails, setShowDetails] = useState(data.map(() => false));

  const _setShowDetails = (index, val) => {
    const _showDetails = _.cloneDeep(showDetails);
    _showDetails[index] = val;
    setShowDetails(_showDetails);
  };

  const tableRows = data.map(({ text = '', subText = '', time = null, details }, i) => {
    return (
      <tr key={i}>
        <td width="160">{timeWithTimezone(time, timezone_format, 'MM/DD/YY h:mm A')}</td>
        <td>
          {text}
          <br />
          <span className="subtext">{subText}</span>
          {details ? (
            <>
              {!showDetails[i] ? (
                <div
                  className="blue-link cursor bold fs-10"
                  onClick={() => _setShowDetails(i, true)}
                >
                  More Details
                </div>
              ) : (
                <div>
                  <div
                    className="blue-link cursor bold fs-10"
                    onClick={() => _setShowDetails(i, false)}
                  >
                    Hide Details
                  </div>
                  {!Array.isArray(details) && typeof details === 'object'
                    ? Object.entries(details).map(([eventType, descriptions], i) => {
                      if (!descriptions.length) return null;
                      return (
                        <div key={i} className="subtext">
                          <span className="bold">{eventType}: </span>
                          {descriptions.map(d => {
                            return <div key={d}>{d}</div>;
                          })}
                        </div>
                      );
                    })
                    : null}
                  {Array.isArray(details)
                    ? details.map((d, i) => (
                      <div className="subtext" key={i}>
                        {d}
                      </div>
                    ))
                    : null}
                </div>
              )}
            </>
          ) : null}
        </td>
      </tr>
    );
  });
  return (
    <div className="ExpenseReportActivityLog">
      <table>
        <thead>
          <tr>
            <td className="bold">Activity</td>
          </tr>
        </thead>
        <tbody>{tableRows}</tbody>
      </table>
    </div>
  );
};

export default ExpenseReportActivityLog;
