import React from 'react';

const SvgClipboard = props => {
  const { svgclassname, svgsize } = props;

  const svgSizes = {
    'xsmall': 4,
    'small': 8,
    'medium': 16,
    'large': 24
  };
  const size = svgSizes[`${svgsize}`];

  return (
    <svg
      className={svgclassname}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -1.5 24 24"
      preserveAspectRatio="xMidYMid meet"
      fill="none"
      stroke="#000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M16 4h2a2 2 0 012 2v14a2 2 0 01-2 2H6a2 2 0 01-2-2V6a2 2 0 012-2h2" />
      <rect x={8} y={2} width={8} height={4} rx={1} ry={1} />
    </svg>
  );
};

export default SvgClipboard;
