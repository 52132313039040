import React from 'react';
import { TOKENS } from '@SRHealth/frontend-lib';

// type NotesProps = {};

const Notes = () => {
  return (
    <div
      className={
        `flex flex-col w-[260px] h-full border-l-1 border-solid ` +
        `border-${TOKENS.BORDER.NEUTRAL_MD} bg-${TOKENS.SURFACE.PRIMARY.XLT}`
      }
    >
      <div
        className={
          `heading-lg p-[16px] pb-[12px] text-center text-${TOKENS.FONT.INVERSE} ` +
          `bg-${TOKENS.SURFACE.PRIMARY.XDK} `
        }
      >
        Notes
      </div>
    </div>
  );
};

export default Notes;
