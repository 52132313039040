import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const DeleteModal = ({handleSubmit, isOpen, closeModal, status}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={{
        base: 'newModalBaseClass uploadModal',
        afterOpen: 'modalBaseClassOpen',
        beforeClose: 'modalBaseClassClose'
      }}
      overlayClassName={{
        base: 'overlayBaseClass BookAnywayModalOverlay',
        afterOpen: 'overlayBaseClassOpen',
        beforeClose: 'overlayBaseClassClose'
      }}
      closeTimeoutMS={500}
    >
      {status === 'success' ?
        <>
          <div className="uploadModalTitle">
            <p>Delete Successful!</p>
          </div>
          <div className="uploadModalButtons">
            <button onClick={closeModal} className="createButton">Ok</button>
          </div>
        </>
        : status === 'error' ?
          <>
            <div className="uploadModalTitle">
              <p>Error</p>
            </div>
            <div className="uploadModalButtons">
              <button onClick={closeModal} className="createButton">Close</button>
            </div>
          </>
          :
          <>
            <div className="uploadModalTitle">
              <p>Are you sure you want to delete?</p>
            </div>
            <div className="uploadModalButtons">
              <button onClick={closeModal} className="cancelButton">Cancel</button>
              <button onClick={handleSubmit} className="createButton">Delete</button>
            </div>
          </>
      }      

    </Modal>
  );
};

DeleteModal.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  uploadData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  status: PropTypes.string
};

DeleteModal.defaultProps = {
  handleSubmit: () => {},
  closeModal: () => {},
  uploadData: {},
  isOpen: false,
  status: ''
};

export default DeleteModal;
