import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import VehicleCompany from './VehicleCompany';
import _ from 'lodash-es';
import SvgAddCircle from '../../../Svgs/SvgAddCircle';
import SvgRemoveCircle from '../../../Svgs/SvgRemoveCircle';

class NemtOptions extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      showButton: false,
      vehiclesSelected: [],
      accordion: []
    };
    this.modalParams = {};
    this.bookedRides = [];
    this.submittedData = _.get(props, 'submittedData.submittedData', {});
  }

  componentDidMount() {
    const { bookingData } = this.props;
    this.setVehicles();
    if (bookingData.bookingType === 'multileg') {
      let { accordion } = this.state;
      accordion = bookingData.rides.map(() => {
        return {
          selected: false
        };
      });
      this.setState({ accordion });
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.vehicles.length === 0 && this.props.vehicles.length > 0) {
      this.submittedData = _.get(this.props, 'submittedData.submittedData', {});
      this.setVehicles();
    }
  }

  /**
   * handles when you click on an nemt to assign a vehicle
   * @param {integer} rideIndex - index for ride
   * @param {integer} companyId - ID for NEMT vehicle company
   * @param {integer} vehicleType - id for vehicle type (ambulatory, wheelchair)
   * @param {integer} vehicleInfoId - id for vehicle
   * @param {integer} rideType - rideType
   * @return {undefined}
   */
  handleVehicleSelect = (rideIndex, companyId, vehicleType, vehicleInfoId, rideType) => {
    const { bookingData } = this.props;
    const vehiclesSelected = _.cloneDeep(this.state.vehiclesSelected);
    let showButton = true;

    vehiclesSelected[rideIndex].selected = true;
    vehiclesSelected[rideIndex].companyId = companyId;
    vehiclesSelected[rideIndex].vehicleType = vehicleType;
    vehiclesSelected[rideIndex].vehicleInfoId = vehicleInfoId;
    vehiclesSelected[rideIndex].rideType = rideType;

    for (let i = 0; i < vehiclesSelected.length; i++) {
      if (!vehiclesSelected[i].selected) {
        showButton = false;
        break;
      }
    }
    this.submittedData.rides[rideIndex].companyId = companyId;
    this.submittedData.rides[rideIndex].vehicleId = vehicleInfoId;

    if (bookingData.bookingType === 'roundtrip') {
      this.submittedData.rides[1].companyId = companyId;
      this.submittedData.rides[1].vehicleId = vehicleInfoId;
    }

    this.setState({ vehiclesSelected, showButton }, () => {
      if (
        bookingData.bookingType !== 'multileg' ||
        this.submittedData.rides.length === 1
      ) {
        // single ride, round trip or multileg with 1 ride no need to click confirm rides,
        // just go straight to the benefits and restrictions page
        this.confirmOptions();
      }
    });
  };

  /**
   * handles when you click on an nemt to assign a vehicle
   * @param {integer} companyId - ID for NEMT vehicle company
   * @param {integer} vehicleType - id for vehicle type (ambulatory, wheelchair)
   * @param {integer} id - id for vehicle
   * @param {integer} rideType - rideType
   * @return {undefined}
   */
  handleVehicleClick = (companyId, vehicleType, id, rideType) => {
    this.props.chooseVehicleId(companyId, vehicleType, id, rideType);
    this.props.toggleLoading(true, 'Authorizing...');
  };

  /**
   * confirm choices for multiple legs
   * @return {undefined}
   */
  confirmOptions = () => {
    if (this.state.showButton) {
      this.props.chooseVehiclesMultipleRides(this.submittedData);
      this.props.toggleLoading(true, 'Authorizing...');
    }
  };

  /**
   * open or close accordion
   * @param {event} e - event
   * @param {integer} legIndex - index for the leg selected
   * @return {undefined}
   */
  openAccordion = (e, legIndex) => {
    this.setState(prevState => {
      const { accordion } = prevState;
      accordion[legIndex].selected = !prevState.accordion[legIndex].selected;
      return {
        accordion
      };
    });
  };

  /**
   * set up the list of selected vehicle companies and set up vehicle list
   * and whether it's a multileg or not
   * @return {undefined}
   */
  setVehicles() {
    const bookingData = this.props?.bookingData;
    let vehicles = this.props?.vehicles;

    if (bookingData.bookingType === 'roundtrip' && vehicles.length > 0) {
      // for round trip api return 2 nemt so remove 1
      vehicles = vehicles.slice(1);
    }
    let vehiclesSelected = [];
    if (_.has(bookingData, 'currentRideIndex')) {
      this.bookedRides = _.get(this.props, 'submittedData.submittedData.rides', []);
      if (!_.isEmpty(vehicles)) {
        vehiclesSelected = vehicles.map(vehicle => {
          // if lyft select it automatically since we dont select vehicle companies in that instance
          const selected = !_.has(vehicle, 'reqVehicleType') ? true : false;
          return {
            selected
          };
        });
      }
    }
    this.setState({ vehicles, vehiclesSelected });
  }

  render() {
    const buttonClass = this.state.showButton
      ? 'confirmOptionsButton'
      : 'confirmOptionsButton inactive';
    const { errorClass, errorMessage, bookingData, submittedData } = this.props;
    const { vehicles, accordion, vehiclesSelected } = this.state;
    const showAccordion =
      bookingData.bookingType === 'multileg' &&
      _.has(submittedData, 'submittedData.rides') &&
      submittedData.submittedData.rides.length > 1;

    /**
     * This line below is messy.
     * We're iterating through the legs, but the vehicleCompany options and ride details are in
     * separate arrays from different sources.
     * The rides array below will be called with the "vehicles" index, i, as we iterate throught the legs,
     * a variable misnamed as "vehicles".
     *
     * TODO: Rename this variable in the props definition and parent components, of which there are 3.
     * - ReassignNemt
     * - BookingConfirmation
     * - LiveChatReassign
     */

    const rides = _.get(submittedData, 'submittedData.rides', []);

    return (
      <div className="nemtOptions">
        {vehicles.length > 0 ? (
          <div>
            {vehicles.map((vehicle, i) => {
              let showRides = true;
              if (_.has(accordion, `${i}.selected`)) {
                showRides =
                  (accordion[i].selected && showAccordion) || !showAccordion
                    ? true
                    : false;
              }

              // const showRides = (accordion[i].selected && showAccordion) || !showAccordion ? true : false;
              let selectedText = '';
              let selectedTextClass = '';
              if (showAccordion) {
                selectedText = vehiclesSelected[i].selected ? 'Selected' : 'Unselected';
                selectedTextClass = vehiclesSelected[i].selected
                  ? 'selectedText'
                  : 'selectedText unselected';
              }

              return (
                <div key={i} className="transportLegOptions clearfix">
                  {showAccordion ? (
                    <div className="accordSelect" onClick={e => this.openAccordion(e, i)}>
                      <span className="vehicleLegText">Leg {vehicle.leg}</span>
                      <span className={selectedTextClass}>{selectedText}</span>
                      {accordion[i].selected ? <SvgRemoveCircle /> : <SvgAddCircle />}
                    </div>
                  ) : (
                    <p className="legText">
                      {this.props.bookingData.bookingType === 'roundtrip'
                        ? 'Round Trip'
                        : `Leg ${vehicle.leg}`}
                    </p>
                  )}
                  {_.has(vehicle, 'rides') && showRides
                    ? vehicle.rides.map((vehicleLeg, j) => {
                      const companyId = vehicleLeg.companyId;
                      const selected =
                          companyId === _.get(vehiclesSelected, `${i}.companyId`, null)
                            ? true
                            : false;
                      const makeClickable = vehicle.transportType !== 8 ? true : false;
                      return (
                        <VehicleCompany
                          bookRideCallback={this.handleVehicleSelect}
                          vehicleInfo={vehicleLeg}
                          key={j}
                          index={i}
                          multileg={this.state.multileg}
                          selected={selected}
                          makeClickable={makeClickable}
                          bookingData={bookingData}
                          relevantLeg={rides[i]}
                          rides={vehicle.rides}
                        />
                      );
                    })
                    : null}
                  {_.has(vehicle, 'blockedCompanies') && showRides
                    ? vehicle.blockedCompanies.map((vehicleCompany, index) => {
                      return (
                        <VehicleCompany
                          bookRideCallback={this.handleVehicleSelect}
                          vehicleInfo={vehicleCompany}
                          key={index}
                          index={i}
                          multileg={this.state.multileg}
                          selected={false}
                          makeClickable={false}
                          isBlocked={true}
                          bookingData={bookingData}
                          relevantLeg={rides[i]}
                        />
                      );
                    })
                    : null}
                </div>
              );
            })}
            {showAccordion ? (
              <div className="confirmOptions clearfix">
                <button className={buttonClass} onClick={this.confirmOptions}>
                  Confirm Options
                </button>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={errorClass}>
            {errorMessage.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </div>
        )}
      </div>
    );
  }
}

NemtOptions.propTypes = {
  handleVehicleClick: PropTypes.func,
  errorClass: PropTypes.string,
  errorMessage: PropTypes.array,
  vehicles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  chooseVehicleId: PropTypes.func,
  toggleLoading: PropTypes.func,
  multileg: PropTypes.bool,
  submittedData: PropTypes.object,
  bookingData: PropTypes.object,
  chooseVehiclesMultipleRides: PropTypes.func
};

NemtOptions.defaultProps = {
  handleVehicleClick: () => {},
  errorClass: '',
  errorMessage: [],
  vehicles: [],
  chooseVehicleId: () => {},
  toggleLoading: () => {},
  multileg: false,
  submittedData: {},
  bookingData: {},
  chooseVehiclesMultipleRides: () => {}
};

const mapStateToProps = state => ({
  submittedData: state.scheduleRides.ride
});

export default connect(mapStateToProps)(NemtOptions);
