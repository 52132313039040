import React, { useMemo } from 'react';
import type { SelectItemRenderer } from 'react-dropdown-select';
import Select from 'react-dropdown-select';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import './SavedAddressSelect.css';
import { useAppSelector } from '~/Modules';
import type { AddressSavedAddress } from '~/types';
import { getSavedAddressById } from '~/utilities/patients';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

interface SavedAddressSelectProps {
  type: 'pickup' | 'dropoff';
  value?: AddressSavedAddress['id'];
  onChange?: (value: MemberSavedAddress) => void;
  onReset?: () => void;
}

const SavedAddressSelect: React.FC<SavedAddressSelectProps> = ({
  value,
  onChange = () => {},
  onReset = () => {}
}) => {
  const savedAddresses = useAppSelector(({ patients }) => patients?.savedAddresses ?? []);

  const handleSelectChange = (values: MemberSavedAddress[]) => {
    if (values.length > 0) {
      onChange(values[0]);
    }
  };

  const _value = useMemo(
    () => getSavedAddressById(value, savedAddresses),
    [value, savedAddresses]
  );

  const className = _value.length
    ? 'saved-address-select active'
    : 'saved-address-select';

  return (
    <div className="saved-address-container">
      <Select<MemberSavedAddress>
        className={className}
        placeholder="Select saved address"
        onChange={handleSelectChange}
        options={savedAddresses}
        values={_value}
        searchable={false}
        closeOnSelect={true}
        labelField="address"
        valueField="address"
        contentRenderer={({ state }) => (
          <span className="saved-address-selection">
            {state?.values[0]?.address || 'Select a saved address'}
          </span>
        )}
        noDataLabel={'No saved addresses'}
        itemRenderer={SavedAddressOption}
      />
      <IconButton onClick={onReset}>
        <CloseIcon />
      </IconButton>
    </div>
  );
};

const SavedAddressOption = ({
  item,
  itemIndex,
  methods
}: SelectItemRenderer<MemberSavedAddress>) => {
  return (
    <div
      key={itemIndex}
      className="saved-address-option"
      onClick={() => methods.addItem(item)}
    >
      <div className="saved-address-label">{item.name}</div>
      <div className="saved-address-address">{item.address}</div>
    </div>
  );
};

export default SavedAddressSelect;
