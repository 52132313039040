import 'date-fns';
import React from 'react';
import moment from 'moment';
import Select from 'react-dropdown-select';
import DateFnsUtils from '@date-io/date-fns';
import Tooltip from '@material-ui/core/Tooltip';
import {
  parentFieldHasValue,
  getOptionsForComplianceDropdown,
  getDependentFieldOptions,
  getDependentFieldValues,
  onDependentFieldsChange,
  setDependentFieldSelectionTree
} from '~/services/compliance.service';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import _, { isEmpty } from 'lodash-es';
import { DEPENDENT_FIELDS } from '~/constants';
import {
  AGE_GROUP_OPTIONS,
  CATEGORY_OPTIONS,
  EXPENSE_STATUS_OPTIONS
} from '~/Pages/ExpenseSlideout/ExpenseSlideout.constants';
import {
  paidByOptions,
  hospitalOptions,
  complianceOptions,
  mobilityTypeOptions,
  subMobilityTypeOptions,
  reimbursementMethodOptions,
  numberOfDaysOrNightsOptions
} from '~/utilities/expenses';

export const InputField = ({
  name,
  value,
  setField,
  disabled = false,
  className = '',
  maxLength = '',
  placeholder = ''
}) => (
  <div>
    <input
      maxLength={maxLength}
      disabled={disabled}
      className={`${className} exp-input ${disabled ? 'input-disabled ' : ''}`}
      placeholder={placeholder}
      onChange={({ target: { value } }) => setField({ name, value })}
      value={value}
    />
  </div>
);

export const TextField = ({ name, value }) => (
  <div className="pb-12" key={name}>
    {value}
  </div>
);

export const ChildExpenseStatus = ({ expenseStatuses = [], setField, status = '' }) => {
  return (
    <Select
      key="status"
      values={expenseStatuses?.filter(({ value }) => value === status)}
      options={expenseStatuses}
      searchable={false}
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'status',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const CategoryDropdown = ({ category, setField, disabled = false }) => {
  return (
    <Select
      key="category"
      disabled={disabled}
      values={CATEGORY_OPTIONS.filter(({ value }) => value === category)}
      options={CATEGORY_OPTIONS}
      searchable={false}
      addPlaceholder=""
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'category',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const HospitalDropdown = ({ setField, disabled, hospitalId, hospitalData }) => {
  return (
    <Select
      key="hospitalId"
      disabled={disabled}
      values={hospitalOptions(hospitalData).filter(({ value }) => value === hospitalId)}
      options={hospitalOptions(hospitalData)}
      searchable={false}
      addPlaceholder=""
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'hospitalId',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const ExpenseStatusDropdown = ({ status, setField, disabled = false }) => {
  return (
    <Select
      key="status"
      disabled={disabled}
      values={EXPENSE_STATUS_OPTIONS.filter(({ value }) => value === status)}
      options={EXPENSE_STATUS_OPTIONS}
      searchable={false}
      addPlaceholder=""
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({ name: 'previousStatus', value: status });
        setField({
          name: 'status',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const ReimbursementMethodDropdown = ({
  setField,
  reimbursementMethod,
  reimbursementMethods
}) => {
  const options = reimbursementMethodOptions(reimbursementMethods);
  return (
    <Select
      key="status"
      values={options.filter(({ value }) => value === reimbursementMethod)}
      options={options}
      searchable={false}
      addPlaceholder=""
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'reimbursementMethod',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const MobilityTypeInput = ({
  setField,
  disabled = false,
  placeholder = '',
  mobilityType,
  vehicle_types
}) => (
  <Select
    key="mobilityType"
    values={mobilityTypeOptions(vehicle_types).filter(
      ({ value }) => value === mobilityType
    )}
    disabled={disabled}
    options={mobilityTypeOptions(vehicle_types)}
    clearable={false}
    searchable={false}
    addPlaceholder={placeholder}
    closeOnSelect={true}
    onChange={values => {
      setField({
        name: 'mobilityType',
        value: values[0] ? values[0].value : null
      });
    }}
  />
);

export const SubMobilityTypeInput = ({
  setField,
  disabled = false,
  placeholder = '',
  mobilityType,
  vehicle_types,
  subMobilityType
}) => (
  <Select
    values={subMobilityTypeOptions(vehicle_types, mobilityType).filter(
      ({ value }) => value === subMobilityType
    )}
    disabled={!mobilityType || disabled}
    options={subMobilityTypeOptions(vehicle_types, mobilityType)}
    onChange={(values = []) =>
      setField({
        name: 'subMobilityType',
        value: values[0] ? values[0].value : null
      })
    }
    clearable={true}
    searchable={false}
    closeOnSelect={true}
    placeholder={placeholder}
    addPlaceholder=""
  />
);

export const ComplianceFieldInput = ({ setField, selectedValues, complianceField }) => {
  const resetChildren = (child, selectedValues) => {
    if (!child) return selectedValues;
    delete selectedValues[child.input_name];
    return resetChildren(child.child, selectedValues);
  };
  const parentmostComplianceField = _.cloneDeep(complianceField);
  // dependent fields v1
  const DependentFields = ({ isFirstField, fields, complianceField }) => {
    const { child, input_name } = complianceField;
    const fieldObj = {
      name: `compliance-${input_name}`,
      show: parentFieldHasValue({
        input_name,
        isFirstField,
        selectedValues,
        parentHasValue: false,
        foundChildInput: false,
        complianceField: parentmostComplianceField
      }),
      label: input_name,
      value: selectedValues[input_name],
      required: false,
      Component: () => (
        <div key={`compliance-${input_name}`}>
          <Select
            values={[
              {
                value: selectedValues[input_name],
                label: selectedValues[input_name]
              }
            ]}
            options={complianceOptions(
              getOptionsForComplianceDropdown({
                isFirstField,
                selectedValues,
                filteredOptions: null,
                complianceField,
                parentmostComplianceField
              })
            )}
            clearable={true}
            onChange={values => {
              const val = values[0] ? values[0].value : null;
              let _selectedValues = _.cloneDeep(selectedValues);
              _selectedValues = resetChildren(child, _selectedValues);
              if (!val) delete _selectedValues[input_name];
              else _selectedValues[input_name] = val;

              setField({
                name: 'complianceFields',
                value: _selectedValues
              });
            }}
            searchable={false}
            closeOnSelect={true}
            addPlaceholder=""
          />
        </div>
      )
    };

    if (input_name) {
      fields.push(fieldObj);
    }
    if (child) {
      return DependentFields({
        fields,
        isFirstField: false,
        complianceField: child
      });
    }
    return fields;
  };
  return DependentFields({ isFirstField: true, complianceField, fields: [] });
};

// dependent fields v2
export const renderDependentFields = ({
  dependentFields,
  selectedValues,
  setField,
  user
}) => {
  if (!user.features[DEPENDENT_FIELDS]) return [{ name: '', show: false }];
  if (isEmpty(dependentFields)) return [{ name: '', show: false }];
  const selections = {};
  const DependentFields = ({ fields, dependentFields, level }) => {
    const {
      input_name: inputName,
      compliance_options: complianceOptions,
      dependent_field: childField
    } = dependentFields;
    if (selectedValues[inputName]) {
      setDependentFieldSelectionTree({
        complianceOptions,
        level,
        inputName,
        selectedValues,
        selections
      });
    }
    const fieldObj = {
      name: `compliance-${inputName}`,
      show: level === 0 || selections[level - 1],
      label: `${inputName}`,
      required: false,
      value: selectedValues[inputName],
      Component: () => {
        return (
          <div key={`compliance-${inputName}`}>
            <Select
              values={getDependentFieldValues({ selectedValues, inputName })}
              options={getDependentFieldOptions({
                level,
                complianceOptions,
                selections
              })}
              closeOnSelect={true}
              clearable={true}
              searchable={false}
              onChange={values =>
                onDependentFieldsChange({
                  setField,
                  selectedValues,
                  selections,
                  values,
                  inputName
                })
              }
            />
          </div>
        );
      }
    };

    if (inputName) {
      fields.push(fieldObj);
    }
    if (childField) {
      return DependentFields({
        fields,
        dependentFields: childField,
        level: level + 1
      });
    }

    return fields;
  };
  return DependentFields({ level: 0, fields: [], dependentFields });
};

export const DateInput = ({
  name,
  value,
  minDate = null,
  maxDate = null,
  setField,
  disabled = false
}) => {
  const dates = {};
  if (minDate) dates.minDate = minDate;
  if (maxDate) dates.maxDate = maxDate;

  if (typeof value === 'string' && value.match('-')) {
    value = moment(value).format('MM/DD/YYYY').toString();
  }

  return (
    <Tooltip
      title={
        disabled ? 'To update parent expense dates, remove all itemized expenses.' : ''
      }
    >
      <div key={name} className="dropdown-container">
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDatePicker
            id={`date-picker-${name}`}
            value={value}
            autoOk={true}
            margin="normal"
            format="MM/dd/yyyy"
            {...dates}
            variant="inline"
            onChange={val => {
              setField({
                name,
                value: val
              });
            }}
            orientation="landscape"
            inputVariant="standard"
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
            disabled={disabled}
            disablePast={false}
          />
        </MuiPickersUtilsProvider>
      </div>
    </Tooltip>
  );
};

export const AgeGroupInput = ({ ageGroup, setField, disabled = false }) => {
  return (
    <Select
      key="ageGroup"
      values={AGE_GROUP_OPTIONS.filter(({ value }) => value === ageGroup)}
      options={AGE_GROUP_OPTIONS}
      disabled={disabled}
      clearable={false}
      searchable={false}
      addPlaceholder=""
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'ageGroup',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const PaidByDropdown = ({ paidBy, paid_by_options, setField }) => {
  return (
    <Select
      key="paidBy"
      values={paidByOptions(paid_by_options).filter(({ value }) => value === paidBy)}
      options={paidByOptions(paid_by_options)}
      clearable={true}
      searchable={false}
      addPlaceholder=""
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'paidBy',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const ExpenseReportRideDropdown = ({
  ridesInWindow = [],
  setField,
  setRideId,
  rideId
}) => {
  const pastRideOptions = ridesInWindow.map(ride => ({
    ...ride,
    label: ride.id,
    value: ride.id
  }));

  return (
    <Select
      key="ride_id"
      values={pastRideOptions.filter(({ id }) => id === rideId)}
      options={pastRideOptions}
      clearable={true}
      onChange={selections => {
        if (selections && selections.length) {
          setField({ name: 'ride_id', value: selections[0] && selections[0].id });
          setRideId(selections[0]);
        } else {
          // resets expense form
          setField({ name: 'ride_id', value: null });
          setRideId({});
        }
      }}
    />
  );
};

export const NumberOfNightsDropdown = ({
  numberOfNights,
  setField,
  disabled = false
}) => {
  return (
    <Select
      key="numberOfNights"
      disabled={disabled}
      values={numberOfDaysOrNightsOptions().filter(
        ({ value }) => value === numberOfNights
      )}
      options={numberOfDaysOrNightsOptions()}
      searchable={false}
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'numberOfNights',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};

export const NumberOfDaysDropdown = ({ numberOfDays, setField, disabled = false }) => {
  return (
    <Select
      key="numberOfNights"
      disabled={disabled}
      values={numberOfDaysOrNightsOptions().filter(({ value }) => value === numberOfDays)}
      options={numberOfDaysOrNightsOptions()}
      searchable={false}
      clearable={false}
      closeOnSelect={true}
      onChange={values => {
        setField({
          name: 'numberOfDays',
          value: values[0] ? values[0].value : null
        });
      }}
    />
  );
};
