import React from 'react';

const SvgArrow = props => {
  const {title, className} = props;

  return (
    <svg className={className} xlinkHref="#si-bootstrap-triangle-bottom" viewBox="0 0 1110.3333740234375 579" aria-labelledby="jisi-bootstrap-triangle-bottom-title" id="si-bootstrap-triangle-bottom" width="100%" height="100%">
      <title id="jisi-bootstrap-triangle-bottom-title">{title}</title>
      <path d="M55.167 0h1000q41 0 49.5 21t-20.5 50l-494 494q-14 14-35 14t-35-14l-494-494q-29-29-20.5-50t49.5-21z"></path>
    </svg>
  );
};

export default SvgArrow;
