import { makeStyles} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  parentTab: {
    display: 'flex',
    flexFlow: 'column',
    gap: '6px'
  },
  requiredFields: {
    color: theme.palette.error.dark,
    margin: 'auto'
  },
  cancelButton: {
    color: theme.palette.error.dark,
    borderRadius: '16px',
    width: 96,
    height: 32,
    fontWeight: 500,
    fontStyle: 'normal',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  submitButton: {
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    borderColor: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '16px',
    width: 96,
    height: 32,
    fontWeight: 500,
    fontStyle: 'normal',
    '&:hover': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      borderColor: `2px solid ${theme.palette.primary.main}`
    },
    '&:disabled': {
      color: 'white',
      backgroundColor: theme.palette.primary.light,
      opacity: '0.65'
    }
  }
}));

export default useStyles;