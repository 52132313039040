import React from 'react';

const PlanRates = () => {
  return (
    <div className="PlanRates">
      <h5>Rate: $10 / Ride</h5>
    </div>
  );
};

export default PlanRates;
