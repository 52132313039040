import { createAppAsyncThunk } from '~/Modules';
import { updateSavedAddresses } from '~/Modules/patients';
import { type MemberSavedAddress } from '~/services/savedAddress.service';
import * as SavedAddressService from '~/services/savedAddress.service';

interface CreateSavedLocationParams {
  savedLocation: Omit<MemberSavedAddress, 'id' | 'passenger_id'>;
}

/** Create a new saved location for a member. */
export const createSavedLocationThunk = createAppAsyncThunk(
  'savedAddress/createSavedLocation',
  async ({ savedLocation }: CreateSavedLocationParams, { getState, dispatch }) => {
    const { savedAddresses } = getState().patients;

    const _savedLocation = {
      ...savedLocation,
      passenger_id: getState().rideBooking.passengerInfo.passengerId
    };

    try {
      const res = await SavedAddressService.createSavedAddress(_savedLocation);

      dispatch(updateSavedAddresses([...savedAddresses, res.data]));
    } catch (error) {
      // Revert to previous address list on failure to update
      dispatch(updateSavedAddresses(savedAddresses));
      throw error;
    }
  }
);
