import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import Modal from 'react-modal';

import history from '~/history';

import AddNewMemberModalForm from './Components/AddNewMemberModalForm';
import Button from '~/Shared/Components/Common/Button';
import { useStyles } from './Components/styles';

import {
  setAddMemberForm,
  validateAddNewMemberForm,
  clearNewMemberForm,
  createMember
} from '~/Modules/members/reducer';
import { isHealthPlanRole } from '~/utilities/helperFunctions';
import { isEligibleHealthPlans } from './Components/AddNewMember.utils';
import { modalStyles, cancelModalStyles } from './styles';
import { NEW_MEMBERS } from '~/constants';

const PAGES = {
  MEMBERS: 'MEMBERS',
  RIDES_SCHEDULED: 'RIDES_SCHEDULED'
};

const AddNewMember = ({ page, addPatientInfo }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const members = useSelector(state => state.members);
  const user = useSelector(state => state.user);

  const [displayModal, setModalDisplay] = useState(false);
  const [cancelModalDisplay, setCancelModalDisplay] = useState(false);

  const toggleModal = () => setModalDisplay(state => !state);
  const validateForm = () => dispatch(validateAddNewMemberForm());

  const allowAddNewMember = useMemo(
    () =>
      user.features[NEW_MEMBERS] ||
      (isHealthPlanRole(user) && isEligibleHealthPlans(members)),
    [user, members]
  );

  /** Event handler for change events */
  const onChange = useCallback(
    ({ target: { name, value } }) => {
      batch(() => {
        dispatch(setAddMemberForm({ [name]: value, key: name, value }));
        validateForm();
      });
    },
    [dispatch]
  );

  /** Event handler for selecting a new member */
  const onSelectChange = useCallback(
    ({ key, name, id, label, value }) => {
      if (key === 'fundingSource') dispatch(clearNewMemberForm());
      batch(() => {
        dispatch(setAddMemberForm({ [key]: value, key, name, id, label, value }));
        validateForm();
      });
    },
    [dispatch, validateForm]
  );

  /** Callback to cancel changes */
  const cancelChange = useCallback(() => {
    displayModal && setCancelModalDisplay(s => !s);
    if (cancelModalDisplay) {
      dispatch(clearNewMemberForm(members.fundingOptions));
      toggleModal();
    }
  }, [dispatch, toggleModal, members, displayModal]);

  /** Callback to redirect to the member profile */
  const redirectNewMember = useCallback(
    members => {
      setModalDisplay(false);
      dispatch(clearNewMemberForm(members.fundingOptions));
      history.push(`/member-profiles/${members.data.id}/general/personal-info`);
    },
    [dispatch, setModalDisplay]
  );

  /** Callback to create a member */
  const addNewMember = useCallback(async () => {
    dispatch(createMember());
  }, [dispatch]);

  useEffect(() => {
    if (members.success && page === PAGES.MEMBERS) {
      redirectNewMember(members);
    } else if (members.success && page === PAGES.RIDES_SCHEDULED) {
      setCancelModalDisplay(false);
      addPatientInfo();
    }
  }, [members]);

  const renderCancelModal = () => (
    <Modal isOpen={cancelModalDisplay} style={cancelModalStyles}>
      <div className={classes.cancelModalRoot}>
        <div className={classes.cancelModalHeader}>
          <h1>Warning</h1>
          <div className="divider" />
        </div>
        <div className={classes.cancelModalBody}>
          Are you sure you want to cancel? All your updates will be discarded.
        </div>
        <div className="divider" />
        <div className="modal-buttons flex">
          <Button
            className={classes.buttonRed}
            variant="contained"
            onClick={cancelChange}
            label="Yes"
          />
          <Button
            className={classes.buttonCancel}
            variant="contained"
            onClick={() => setCancelModalDisplay(false)}
            label="No"
          />
        </div>
      </div>
    </Modal>
  );

  // Return empty div if adding new members is disabled or
  // if we're missing required data
  if (
    !allowAddNewMember ||
    !(members && members.fundingOptions && members.addNewMemberFormData)
  ) {
    return <div />;
  }

  return (
    <div className={classes.addNewMemberRoot}>
      <div className={classes.newMemberTitle} onClick={toggleModal}>
        + Add new member
      </div>

      <Modal isOpen={displayModal} onRequestClose={cancelChange} style={modalStyles}>
        <AddNewMemberModalForm
          fundingOptions={members.fundingOptions}
          members={members}
          onChange={onChange}
          onSelectChange={onSelectChange}
          formData={members.addNewMemberFormData}
          cancelChange={cancelChange}
          addNewMember={addNewMember}
          isDisabled={members.addNewMemberFormData.isValid}
        />
        {renderCancelModal()}
      </Modal>
    </div>
  );
};

export default AddNewMember;
