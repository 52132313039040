import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { get, isEqual } from 'lodash-es';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import Header from './Components/Header';
import ImportMembers from './Components/ImportMembers';
import MembersList from './Components/MembersList';
import ExpenseSlideout from '../ExpenseSlideout/Components/Slideout/ExpenseSlideout';
import { getMembers, getMember, clearMembers } from '~/Modules/members';
import { getMemberPermissions, clearNewMemberForm } from '~/Modules/members/reducer';
import {
  getMemberProfile,
  clearMember,
  clearAll,
  clearMemberAuditLogs,
  putMemberProfile,
  getMemberPortalInfoFromApi
} from '~/Modules/memberProfile/actions';
import type { UserStore } from '~/Modules/user';
import {
  getNavOptions,
  initialMemberProfilesState,
  resolveMemberProfileForm
} from './MemberProfiles.helper';
import MemberNav from '~/Pages/MemberProfiles/MemberNav';
import { adminToken } from '~/utilities/auth.helper';
import moment from 'moment-timezone';
import type { AppStore } from '~/types';
import type { PERMISSIONS } from './MemberProfiles.constants';
import { ASSESSMENT_HARD_BLOCK_ERROR } from './MemberProfiles.constants';
import { MOBILITY_ASSESSMENT_HARD_BLOCK } from '~/constants';
import { Container } from '@material-ui/core';
import useModal, { type ModalActions } from '~/hooks/useModal';
import withRouter from '~/hooks/withRouter';
import useAnalytics, {
  type AnalyticsInterface,
  type MemberDeeplinkDestination
} from '~/hooks/useAnalytics';

type AllowRedirectResponse = [false, string | undefined] | [true, undefined];

export type MemberProfileForm =
  | 'general'
  | 'benefits'
  | 'custom-fields'
  | 'notes'
  | 'uploads'
  | 'audit-log';

export type MemberProfilesProps = {
  user: UserStore;
  analytics: AnalyticsInterface;
  match: { params: { parent?: MemberProfileForm; child?: string; id?: string } };
  members: AppStore['members'];
  memberProfile: AppStore['memberProfile'];
  history: { [x: string]: any; push: (str: string) => void };
  isLoading: boolean;
  permission: (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
  getMember: typeof getMember;
  getMembers: typeof getMembers;
  getMemberProfile: typeof getMemberProfile;
  getMemberPermissions: typeof getMemberPermissions;
  clearAll: typeof clearAll;
  clearMember: typeof clearMember;
  clearMembers: typeof clearMembers;
  clearMemberAuditLogs: typeof clearMemberAuditLogs;
  clearNewMemberForm: typeof clearNewMemberForm;
  putMemberProfile: typeof putMemberProfile;
  expenseSlideout: typeof ExpenseSlideout;
  location: any;
  modal: ModalActions;
  getMemberPortalInfoFromApi: (passengerId: string) => void;
};

export type MemberProfilesState = {
  parentForm: MemberProfileForm;
  childForm: string;
  selectedId: string;
  showLoading: boolean;
  searchQuery: string;
  fromQuery: string;
  isEventFired: boolean;
  page: number;
  enableRedirect: boolean;
  urlParams: URLSearchParams;
  editButton?: JSX.Element;
  errorMsg?: string;
  successMsg?: string;
  handleSelectionCallback?: () => boolean;
};

class MemberProfiles extends Component<MemberProfilesProps, MemberProfilesState> {
  constructor(props) {
    super(props);
    this.state = initialMemberProfilesState();
  }

  componentDidMount() {
    this.props.clearAll();
    this.props.clearNewMemberForm();
    this.props.getMemberPermissions();

    const urlParams = this.getSearchParams() || new URLSearchParams();

    const state: Partial<MemberProfilesState> = {
      ...this.extractRouteParams(),
      urlParams: urlParams,
      searchQuery: urlParams.get('q') ?? '',
      fromQuery: urlParams.get('from') ?? '',
      showLoading: this.state.urlParams.has('q')
    };

    this.setState(state as MemberProfilesState, () => {
      if (state.selectedId) {
        this.props.clearMember();
        this.props.clearMemberAuditLogs();
        this.props.getMemberProfile({
          ...Object.fromEntries(urlParams.entries()),
          passengerId: state.selectedId
        });
        this.props.getMemberPortalInfoFromApi(state.selectedId);
      } else if (state.searchQuery) {
        this.props.getMembers({ query: state.searchQuery, page: 1 });
      }
    });
  }

  componentWillUnmount() {
    this.props.clearMembers();
    this.props.clearMemberAuditLogs();
  }

  componentDidUpdate(prevProps) {
    const { match, members, memberProfile, location } = this.props;

    // Check if the user has navigated to the base path "/member-profiles" (while already on the
    // member-profiles page e.g. clicking the Members link the menu dropdown). If so, reset state and redux slice.
    if (
      location.pathname === '/member-profiles' &&
      prevProps.location.pathname !== '/member-profiles'
    ) {
      // reset redux slice and component state back to initial values
      this.resetToInitialState();
      return;
    }

    const id = parseInt(match?.params?.id ?? '');

    const state: Partial<MemberProfilesState> = {};

    if (!isEqual(match, prevProps.match) || !isEqual(location, prevProps.location)) {
      Object.assign(state, this.extractRouteParams());
      state.urlParams = this.getSearchParams() || new URLSearchParams();
      if (this.state.parentForm && state.parentForm !== this.state.parentForm) {
        state.editButton = undefined;
        state.handleSelectionCallback = undefined;
      }
    }

    // We've loaded members
    if (!isEqual(members?.rows, prevProps.members?.rows)) {
      state.showLoading = false;
    }

    const selectionDidUpdate = this.selectionDidUpdate(prevProps);
    const memberDidChange = !isEqual(memberProfile, prevProps.memberProfile);
    const memberDidLoad = this.memberDidLoad(prevProps);

    const memberData = this.props.memberProfile?.formData ?? {};
    const deeplinkDestination = this.decodeDeeplink(this.state.fromQuery);
    const isValidMemberDeeplink = this.state.fromQuery && deeplinkDestination;

    if (!this.state.selectedId && isValidMemberDeeplink && !this.state.isEventFired) {
      this.setProperty('isEventFired', true);

      const deepLinkAnalyticsEvent = {
        deeplinkDestination,
        authenticated: deeplinkDestination === 'MemberProfile',
        memberId: this.state.selectedId || '',
        healthPlanId: memberData?.personalInfo?.healthPlanId || 0,
        timestamp: Date.now()
      };

      this.props.analytics.trackEvent(
        'Member Deeplink Activated',
        deepLinkAnalyticsEvent
      );
    }

    if (memberDidChange || memberDidLoad) {
      state.enableRedirect = this.allowRedirect()[0];

      if (memberDidLoad && isValidMemberDeeplink) {
        const deepLinkAnalyticsEvent = {
          deeplinkDestination,
          authenticated: deeplinkDestination === 'MemberProfile',
          memberId: this.state.selectedId || '',
          healthPlanId: memberData?.personalInfo?.healthPlanId || 0,
          timestamp: Date.now()
        };

        this.props.analytics.trackEvent(
          'Member Deeplink Activated',
          deepLinkAnalyticsEvent
        );
      }

      if (
        (memberDidLoad || selectionDidUpdate) &&
        memberProfile?.formData?.personalInfo?.dsnpMedicareAvailable
      ) {
        this.props.modal.set({
          isOpen: true,
          title: 'Check for Medicare Coverage',
          message:
            'This member has both Medicare and Medicaid coverage. You have chosen the Medicaid plan, which should only be used when Medicare rides have been exhausted or if a ride exceeds the Medicare mileage limitation.',
          confirmLabel: 'Close',
          onConfirm: () => {}
        });
      }
    }

    if (
      id &&
      !this.state.searchQuery &&
      memberProfile?.formData?.personalInfo?.medicalId &&
      this.props.members?.endOfList === undefined
    ) {
      state.searchQuery = memberProfile?.formData?.personalInfo.medicalId;
    }

    // Check for hard blocks
    const mobilityAssessmentHardBlock = this.getMobilityAssessmentHardBlock();
    if (!this.state.errorMsg && mobilityAssessmentHardBlock) {
      state.errorMsg = ASSESSMENT_HARD_BLOCK_ERROR;
    } else if (this.state.errorMsg && !mobilityAssessmentHardBlock) {
      state.errorMsg = '';
    }

    // Only update if we have something to update
    if (Object.keys(state).length) {
      this.setState(state as MemberProfilesState, () => {
        // Side-effects of changing member
        if (selectionDidUpdate) {
          const params: Record<string, string | number | boolean | null | undefined> = {
            passengerId: state.selectedId
          };

          if (state.urlParams) {
            if (state.urlParams.has('subPlanId')) {
              params.subPlanId = state.urlParams.get('subPlanId');
            }

            if (state.urlParams.has('externalSubPlanId')) {
              params.externalSubPlanId = state.urlParams.get('externalSubPlanId');
            }
          }

          this.props.clearMember();
          this.props.clearMemberAuditLogs();
          this.props.getMemberProfile(params);
          this.props.getMemberPortalInfoFromApi(this.state.selectedId);
        }

        if (state.searchQuery) this.searchAction();
      });
    }
  }

  /**
   * Reset the memberProfiles redux slice and component state back to its initial values
   */
  resetToInitialState = () => {
    // Reset the memberProfiles redux slice back to its initial values
    this.props.clearAll();
    this.props.clearNewMemberForm();

    // resetting to initial component state
    this.setState(initialMemberProfilesState());
  };

  extractRouteParams() {
    const match = this.props.match;

    return {
      parentForm: match?.params?.parent,
      childForm: match?.params?.child,
      selectedId: match?.params?.id ?? `${this.state.selectedId}`
    };
  }

  searchAction = (params?: Record<string, unknown>) => {
    this.setState({ showLoading: true }, () => {
      this.props.getMembers({
        query: this.state.searchQuery,
        page: this.state.page,
        ...params
      });
    });
  };

  incrementPage = () => {
    this.setState({ page: this.state.page + 1 }, this.searchAction);
  };

  /**
   * Determines if the member has a hardblock
   * for an incomplete mobility assessment
   * @returns {boolean}
   */
  getMobilityAssessmentHardBlock = () => {
    const { user, memberProfile } = this.props;

    const assessmentHardBlock = user.features[MOBILITY_ASSESSMENT_HARD_BLOCK];

    const mobilityAssessmentData =
      memberProfile?.formData?.mobility?.memberData?.vehicle_type !== undefined;

    if (assessmentHardBlock && !mobilityAssessmentData) {
      return true;
    }
    return false;
  };

  allowRedirect = (): AllowRedirectResponse => {
    const { benefits, personalInfo } = this.props.memberProfile.formData;

    if (this.getMobilityAssessmentHardBlock()) {
      return [false, ASSESSMENT_HARD_BLOCK_ERROR];
    }

    if (benefits?.disableRequestRide) {
      return [false, benefits.disableRequestRideReason || ''];
    }

    if (!personalInfo?.isEligible) {
      return [false, 'Member is not eligible for rides.'];
    }

    if (!this?.state?.selectedId) {
      return [false, undefined];
    }

    return [true, undefined];
  };

  /**
   * turn redirect button off and on
   * this gets turned off if form is in edit state
   * it is also off by default until member is loaded from redux
   * @param {bool} show - show or hide
   * @return {undefined}
   */
  toggleEnableRedirect = (show = false) => {
    this.setState({ enableRedirect: show });
  };

  /**
   * Get last updated date as a moment object
   * @returns {Object} Moment date object
   */
  getLastUpdatedDate() {
    const { members, user } = this.props;

    if (members.meta && members.meta.lastUpdated) {
      const timezoneFormat = get(user, 'userData.timezone_format', '');

      return moment
        .utc(members.meta.lastUpdated, 'YYYY-MM-DD HH:mm:ss')
        .tz(timezoneFormat);
    }

    return null;
  }

  /**
   * Handles member selection
   * @param {string} selectedId Selected ID
   * @param {int} subPlanId Optional health plan sub id
   * @param {int} externalSubPlanId Optional external health plan sub id
   * @returns {undefined}
   */
  handleSelection = (selectedId: string, subPlanId = null, externalSubPlanId = null) => {
    // Check if the child pages have a navigation safety check to prevent losing unsaved data
    if (
      this.state.handleSelectionCallback &&
      this.state.handleSelectionCallback() === false
    ) {
      return;
    }

    const newPath = `/member-profiles/${selectedId}/general/personal-info?`;

    const urlParams = new URLSearchParams();

    if (subPlanId) urlParams.set('subPlanId', subPlanId);
    if (externalSubPlanId) urlParams.set('externalSubPlanId', externalSubPlanId);

    this.setState(
      {
        urlParams,
        editButton: undefined,
        handleSelectionCallback: undefined
      },
      () => {
        this.props.history.push(newPath + urlParams.toString());
      }
    );
  };

  /**
   * The event handler for navigation requests from sub-components. Instead
   * of letting arbitrary sub components control routing it's centralized in
   * this component
   * @param {string} parentForm
   * @param {string} childForm
   */
  handleChildSelection = (parentForm, childForm) => {
    // Check if the child pages have a navigation safety check to prevent losing unsaved data
    if (
      this.state.handleSelectionCallback &&
      this.state.handleSelectionCallback() === false
    ) {
      return;
    }

    const { selectedId, urlParams } = this.state;

    const parentFormWillChange = parentForm !== this.state.parentForm;
    const childFormWillChange = childForm !== this.state.childForm;

    // Only remove the edit button if we're navigating to new page
    let editButton = this.state.editButton;
    let handleSelectionCallback = this.state.handleSelectionCallback;

    if (parentFormWillChange || childFormWillChange) {
      editButton = undefined;
      handleSelectionCallback = undefined;
    }

    this.setState({ parentForm, childForm, editButton, handleSelectionCallback }, () => {
      childForm &&= `/${childForm}`;

      const route = `/member-profiles/${selectedId}/${parentForm}${childForm}?${urlParams.toString()}`;

      this.props.history.push(route);
    });
  };

  /**
   * Handles list view selection
   * @returns {void}
   */
  handleListView = () => {
    this.props.history.push(`/members`);
  };

  /**
   * Handle request to go to member import page
   * @returns {void}
   */
  handleGotoMembersImport = () => {
    this.props.history.push('/import/member');
  };

  /**
   * Utility to extract active URL Params from the
   * navigator search data
   * @param {object} props
   * @returns
   */
  getSearchParams = (props: MemberProfilesProps | null = null) => {
    props ??= this.props;

    const search = props?.location?.search;

    if (!search) return null;

    return new URLSearchParams(search);
  };

  /**
   * Utility to decode deeplink query param if it exists
   * @param {string} encodedStr
   * @returns {string | null}
   */
  decodeDeeplink = (encodedStr: string): MemberDeeplinkDestination | null => {
    if (!encodedStr) return null;
    const decoded = atob(encodedStr).split('.');
    if (decoded.length !== 2) return null;

    const type = decoded[1] === 'memberProfile' ? 'MemberProfile' : 'MemberSearch';
    return type;
  };

  /**
   * Utility to quickly determine if state has shifted from no loaded
   * member to a loaded member
   * @param prevProps
   * @returns
   */
  memberDidLoad = (prevProps: MemberProfilesProps) => {
    const prevId = prevProps?.memberProfile?.formData?.personalInfo?.id;
    const id = this.props?.memberProfile?.formData?.personalInfo?.id;

    return id && !prevId;
  };

  /**
   * Utility to detect if any member specific data points
   * have changed in the url in between updates
   * @param {object} prevProps
   * @returns
   */
  selectionDidUpdate = prevProps => {
    const prevParams = this.getSearchParams(prevProps);
    const params = this.getSearchParams();

    const prevId = prevProps?.match?.params?.id ?? '';
    const id = this.props?.match?.params?.id ?? '';

    return (
      id &&
      (prevId !== id ||
        !!prevParams !== !!params ||
        prevParams?.toString() !== params?.toString())
    );
  };

  setProperty = (
    prop: keyof MemberProfilesState,
    val: MemberProfilesState[typeof prop]
  ) => {
    this.setState({ [prop]: val } as MemberProfilesState);
  };

  render() {
    const {
      members,
      memberProfile,
      user,
      permission,
      expenseSlideout,
      putMemberProfile
    } = this.props;

    const {
      parentForm,
      childForm,
      selectedId,
      searchQuery,
      showLoading,
      enableRedirect,
      urlParams,
      errorMsg,
      successMsg,
      editButton
    } = this.state;

    const memberData = memberProfile?.formData ?? {};
    const memberPortalInfo = memberProfile?.memberPortalInfo;
    const isLoading = memberProfile.inHttpRequest ?? false;
    const passengerId = memberProfile?.formData?.personalInfo?.id;
    const lastUpdated = this.getLastUpdatedDate();
    const PageForm = resolveMemberProfileForm(parentForm);

    const allowRedirect = this.allowRedirect();
    allowRedirect[0] &&= enableRedirect;

    return (
      <main className="MemberProfiles">
        <PageFrame>
          <section className="MembersListWrapper">
            <MembersList
              user={user}
              members={members}
              showLoading={showLoading}
              searchQuery={searchQuery}
              searchParams={urlParams}
              searchAction={this.searchAction}
              setSearchQuery={searchQuery => this.setState({ searchQuery })}
              selectedId={selectedId}
              setSelectedId={this.handleSelection}
              gotoMembersImport={this.handleGotoMembersImport}
              incrementPage={this.incrementPage}
              endOfList={members.endOfList}
            />
          </section>

          {!selectedId || !memberProfile.permission ? (
            <ImportMembers
              adminToken={adminToken}
              userRole={this.props.user.userData.role}
              lastUpdated={lastUpdated}
              handleGotoMembersImport={this.handleGotoMembersImport}
            />
          ) : (
            <section className="MemberProfilesSection">
              <Header
                parentForm={parentForm}
                childForm={childForm}
                selectedId={selectedId}
                allowRedirect={allowRedirect}
                urlParams={urlParams.toString()}
                user={user}
                memberPortalInfo={memberPortalInfo}
                personalInfo={memberData.personalInfo}
                benefits={memberData.benefits}
                mobility={memberData.mobility}
                navOptions={getNavOptions(selectedId, urlParams.toString())}
              />

              <PageForm
                childForm={childForm}
                selectedId={selectedId}
                passengerId={passengerId}
                permission={permission}
                isLoading={isLoading}
                user={user}
                memberProfile={memberProfile}
                updateMember={putMemberProfile}
                toggleEnableRedirect={this.toggleEnableRedirect}
                handleChildSelection={(newChild: string) =>
                  this.handleChildSelection(parentForm, newChild)
                }
                setEditButton={(v: MemberProfilesState['editButton']) =>
                  this.setProperty('editButton', v)
                }
                setErrorMsg={(v: MemberProfilesState['errorMsg']) =>
                  this.setProperty('errorMsg', v)
                }
                setSelectionCallback={(
                  v: MemberProfilesState['handleSelectionCallback']
                ) => this.setProperty('handleSelectionCallback', v)}
              >
                <div className="member-navigation-wrapper">
                  <Container id="member-navigation" className="parentTab">
                    <MemberNav
                      navOptions={PageForm?.getNavOptions(selectedId) ?? []}
                      handleChildSelection={this.handleChildSelection}
                    >
                      <MemberNav.SuccessMsg msg={successMsg} />
                      <MemberNav.ErrorMsg msg={errorMsg} />

                      <MemberNav.EditButton>{editButton}</MemberNav.EditButton>
                    </MemberNav>
                  </Container>
                </div>
              </PageForm>

              {/** @ts-expect-error because */}
              {expenseSlideout.show && <ExpenseSlideout mode="create" />}
            </section>
          )}
        </PageFrame>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  members: state.members,
  permission: state?.memberProfile?.permission ?? 'view',
  memberProfile: state?.memberProfile ?? {},
  expenseSlideout: state.memberExpenses.slideout
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearAll: () => clearAll(),
      clearMember: () => clearMember(),
      clearMemberAuditLogs: () => clearMemberAuditLogs(),
      clearMembers: () => clearMembers(),
      clearNewMemberForm: () => clearNewMemberForm(),
      getMember: data => getMember(data),
      getMemberPermissions: () => getMemberPermissions(),
      getMemberProfile: data => getMemberProfile(data),
      getMembers: data => getMembers(data),
      putMemberProfile: data => putMemberProfile(data),
      getMemberPortalInfoFromApi: passengerId => getMemberPortalInfoFromApi(passengerId)
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(useModal.HOC(useAnalytics.HOC(withRouter(MemberProfiles))));
