import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { clearData } from '~/Modules/memberProfile/actions';

/**
 * Why does this even exists?!? - Burt
 */
class Program extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillUnmount () {
    this.props.dispatch(clearData());
    this.props.cancel('editProgram');
  }

  /**
   * render program form
   * @return {jsx} returns jsx.
   */
  render () {
    return (
      <div className="childTab custom">
        <div className="memberRow">
          <p className="noUploads">There are no uploads.</p>
        </div>
      </div>
    );
  }
}

Program.propTypes = {
  selectedId: PropTypes.string,
  handleCancel: PropTypes.func,
  dispatch: PropTypes.func
};

Program.defaultProps = {
  selectedId: null,
  handleCancel: () => {},
  dispatch: () => {}
};

export default Program;
