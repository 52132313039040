import React from 'react';
import PropTypes from 'prop-types';
import { Portal } from 'react-portal';

class PortalDraggable extends React.Component {
  render() {
    const { isDraggable, children } = this.props;
    if (isDraggable) {
      return (
        <Portal>
          {children}
        </Portal>
      );
    } else {
      return this.props.children;
    }
  }
}

PortalDraggable.propTypes = {
  isDraggable: PropTypes.bool,
  children: PropTypes.object
};

PortalDraggable.defaultProps = {
  isDraggable: false,
  children: {}
};

export default PortalDraggable;
