import axios from 'axios';
import { logout } from '~/utilities/auth.helper';

axios.interceptors.response.use(
  response => response,
  function (error) {
    if (error?.response?.status === 401) {
      const additionalRelevantData = {
        error: error.message,
        response: error.response,
        currentLocation: window.location.href,
        requestUrlThatJustFailed: error.config?.url
      };

      logout(
        'User logged out due to 401 error in the axios.interceptors.response.use() method in safeAxios.js',
        additionalRelevantData
      );
    }

    return Promise.reject(error);
  }
);

export default axios;
