import React from 'react';
import SvgRadioSelected from '~/Shared/Components/Svgs/SvgRadioSelected';
import SvgRadioUnselected from '~/Shared/Components/Svgs/SvgRadioUnselected';

// eslint-disable-next-line no-undef
export interface RadioOptions {
  selected: boolean;
  className: string;
}

export default function Radio(
  { selected = false, className = '' }: RadioOptions
) {
  return selected
    ? <SvgRadioSelected className={className} />
    : <SvgRadioUnselected className={className} />;
}
