import React from 'react';
import type { RideBenefitsRecord } from '../FundingSource.types';
import { DisplayCounter } from '@SRHealth/frontend-lib';

export type BenefitDisplayProps = {
  record: RideBenefitsRecord;
};

export default function BenefitDisplay({ record }: BenefitDisplayProps) {
  const benefitStatus = record.limit - record.usage <= 0 ? 'alert' : 'active';

  return (
    <div className="flex flex-col">
      <DisplayCounter
        id="benefits-remaining"
        type={benefitStatus}
        label="Rides Remaining"
        value={record.limit - record.usage}
      />
      <DisplayCounter
        id="benefits-limit"
        type="neutral"
        label={record.label}
        value={record.limit}
      />
    </div>
  );
}
