import {type NavOption} from '../../MemberProfiles.helper';

/**
 * Generates the navigational options based on selected Id
 * @param {number} selectedId 
 * @returns {NavOption[]}
 */
export function generateNavOptions(selectedId: number): NavOption[] {
  return [
    {
      dataTip: 'program',
      label: 'Program',
      parentForm: 'uploads',
      childForm: 'program',
      route: `/member-profiles/${selectedId}/uploads/program`
    },
    {
      dataTip: 'custom',
      label: 'Custom',
      parentForm: 'uploads',
      childForm: 'custom',
      route: `/member-profiles/${selectedId}/uploads/custom`
    }
  ];
}
