import React, { useEffect, useState } from 'react';
import type { FormDependency, FormInputOption, FormInputProps } from './FormInput';
import FormInput from './FormInput';
import useStyles from './Form.theme';
import {
  checkDependencies,
  createDependencyMap,
  createInitialState,
  extractFormData,
  validateForm
} from './Form.helpers';
import { type GridSpacing, Grid } from '@material-ui/core';

export type FormEventHandler = () =>
  | Record<FormInputProps['name'], FormInputOption['value']>
  | FormState
  | false;

export type FormProps = {
  /** The name of the form */
  name: string;
  /** The form template used by the engine. The form input fields will
   * be automatically generated using this template. Any pre-existing selections
   * will be applied */
  template: FormInputProps[];
  /** Disable the entire form to prevent editing */
  disabled?: boolean;
  /** Spacing between form inputs */
  spacing?: GridSpacing;
  /** Controls whether inputs should display a divder between the label and the
   * input. Default = true */
  divider?: boolean;
  /** This controls the data structure when submitting or cancelling the form.
   * If true the entire form template will be returned, along with the user's
   * selections.
   *
   * If false then only an object of input fields and their selections
   * will be returned by the submitEventHook and cancelEventHook. */
  submitFull?: boolean;
  /**
   * Provide a callback if you are using a custom cancel button. The
   * callback will be passed a function that should be invoked whenever
   * your custom callback is clicked. If submitFull is true on the props then
   * the full form data structure is returned.
   * @param handler
   * @returns {void}
   */
  cancelEventHook?: (handler: FormEventHandler) => void;
  /**
   * Provide a callback if you are using a custom submit button. The
   * callback will be passed a function that should be invoked whenever
   * your custom callback is clicked. If submitFull is true on the props then
   * the full form data structure is returned.
   *
   * Returns false if validation fails.
   * @param handler
   * @returns {void}
   */
  submitEventHook?: (handler: FormEventHandler) => void;
  /**
   * A callback that is executed whenever the value of the Input['selection']
   * key changes. The full FormInputProps for the question will be passed,
   * including the updated selection id.
   * @param {FormInputProps} input
   * @returns {void}
   */
  onChange?: (input: FormInputProps, index: number) => void;
};

export type FormState = FormInputProps[];
export type DependencyMap = Map<number, number[]>;

const Form: React.FC<FormProps> = ({
  name,
  template,
  disabled = false,
  spacing = 2,
  divider = true,
  submitFull,
  cancelEventHook,
  submitEventHook,
  onChange
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState<string[]>([]);
  const [state, setState] = useState<FormState>(createInitialState(template));
  const [deps, setDeps] = useState<DependencyMap>(createDependencyMap(template));

  useEffect(() => {
    setState(createInitialState(template));
    setDeps(createDependencyMap(template));
  }, [template]);

  useEffect(() => {
    if (cancelEventHook) cancelEventHook(handleCancel);
    if (submitEventHook) submitEventHook(handleSubmit);
  }, [state]);

  /**
   * Callback for child inputs of form to check dependencies
   * on their options
   * @param {FormDependency[]} deps
   * @returns {boolean}
   */
  function inputCheckDependencies(deps?: FormDependency[]) {
    return checkDependencies(state, deps);
  }

  /**
   * Utility to determine if the input is disabled or not
   * @param {FormInputProps} input
   * @returns {boolean}
   */
  function inputIsDisabled(input: FormInputProps) {
    return disabled || !checkDependencies(state, input.dependencies);
  }

  /**
   * Utility to determine if the input has an error
   * @param {FormInputProps} input
   * @returns {boolean}
   */
  function inputIsErrored(input: FormInputProps) {
    return !!errors.includes(input.name);
  }

  /**
   * Updates the selected option for an input
   * @param inputId The id of the input to be updated
   * @param optionId The id of the new selected option
   * @param _newState Should not be passed unless this is a recursed call
   * @returns
   */
  function handleChange<T extends FormInputProps>(
    inputId: number,
    optionId?: T['selection'],
    _newState?: FormState
  ) {
    if (!(inputId in state)) return;

    let newState = _newState ?? [...state];
    newState[inputId] = { ...state[inputId]!, selection: optionId } as T;

    /** Iterate through each dependent input and make sure their current values
     * are still valid. */
    const dependentInputs = deps.get(inputId);
    if (dependentInputs) {
      for (const depInputId of dependentInputs) {
        const { selection: value, options } = newState[depInputId];

        // Dependent fields aren't set! No need to update
        if (!value) continue;

        /** If the current selected option is invalid find the first option
         * that passes the dependency check */
        if (!Array.isArray(value)) {
          if (value && !checkDependencies(newState, options[value].dependencies)) {
            newState = handleChange(depInputId, undefined, newState)!;
          }
        } else {
          // Multi-selects/Checkboxes
          for (const val of value) {
            if (!checkDependencies(newState, options[val].dependencies)) {
              newState = handleChange(depInputId, undefined, newState)!;
            }
          }
        }
      }
    }

    // Only save the state if _newState was not initially
    // passed in. This prevents us from unnecessary re-rendering by
    // accumulating state changes into one state call
    if (!_newState) {
      setState(newState!);
      handleValidate(newState);
    }

    /** Pass the updated value to the onChange event callback */
    if (onChange) onChange(newState[inputId], inputId);

    return newState;
  }

  /**
   * Passes form state or extracted data to the submitEventHook.
   * If validation fails then returns false.
   * @returns
   */
  function handleSubmit() {
    if (handleValidate()) return false;

    return submitFull ? extractFormData(state) : state;
  }

  /**
   * Resets form and error states to their initial states.
   * @returns
   */
  function handleCancel() {
    setState(createInitialState(template));
    setErrors([]);

    return submitFull ? extractFormData(state) : state;
  }

  /**
   * Validates the required fields and updates
   * the errors state. Returns boolean indicating if
   * validation failed or not.
   * @returns {boolean}
   */
  function handleValidate(formState: FormState = state) {
    const errors = validateForm(formState);
    setErrors(errors);

    return !!errors.length;
  }

  return (
    <form className={classes.root} name={name}>
      <Grid container item spacing={spacing}>
        {state.map((input, i) => (
          <Grid item xs={4} key={i}>
            <FormInput
              {...input}
              inputId={i}
              classes={classes}
              divider={divider}
              disabled={inputIsDisabled(input)}
              error={inputIsErrored(input)}
              onChange={handleChange}
              onCheckDependencies={inputCheckDependencies}
            />
          </Grid>
        ))}
      </Grid>
    </form>
  );
};

export default Form;
