import React from 'react';
import _ from 'lodash-es';

const MemberEligible = props => {
  return props?.eligible ? (
    <span className={'eligible on'}>
      Eligible<i style={{ backgroundColor: '#007A87' }}>&nbsp;</i>
    </span>
  ) : (
    <span className={'eligible on'}>
      Ineligible<i style={{ backgroundColor: '#F75A61' }}>&nbsp;</i>
    </span>
  );
};

export { MemberEligible as default };
