import React, { useState, useReducer, useEffect } from 'react';
import { useSelector } from 'react-redux';
import immutable from 'immutability-helper';
import { uniqBy, isEqual } from 'lodash-es';
import Dropdown from '../DropDown/DropDown';

const UPDATE_SELECTION = 'UPDATE_SELECTION';
const GET_DEPENDENT_FIELDS = 'GET_DEPENDENT_FIELDS';
const SET_DEPENDENT_FIELD_ERROR = 'SET_DEPENDENT_FIELD_ERROR';
const INITIALIZE = 'INITIALIZE';

function reducer(state, action) {
  switch (action.type) {
    case INITIALIZE:
      return immutable(state, {
        selections: { $set: [...state.selections, action.payload] }
      });
    case UPDATE_SELECTION:
      return immutable(state, {
        selections: {
          $set: [...state.selections]
            .filter(i => i.level < action.payload.level)
            .concat([action.payload])
        },
        error: { $set: null }
      });
    case GET_DEPENDENT_FIELDS:
      return immutable(state, {
        dependentFields: { $set: [...state.dependentFields, action.payload] }
      });

    case SET_DEPENDENT_FIELD_ERROR: {
      return immutable(state, {
        error: { $set: action.payload }
      });
    }
    default:
      throw new Error();
  }
}

const DependentFieldsContainer = ({
  handleDependentFieldsSelect,
  fields = {},
  getDependentFields,
  error,
  updateDependentFieldsSelection,
  touched
}) => {
  const { dependentFields } = useSelector(state => state.user);
  if (!(dependentFields && dependentFields.compliance_options)) return <div />;
  const [state, dispatch] = useReducer(reducer, {
    selections: [],
    dependentFields: [],
    error: null
  });

  useEffect(() => {
    initializeDependentFields(dependentFields);
    fetchDependentFieldNames(dependentFields);
  }, []);

  useEffect(() => {
    getDependentFields(state.dependentFields, state.selections);
  }, [state.dependentFields]);

  useEffect(() => {
    if (error && error.fieldLevel) {
      const { fieldLevel } = error;
      dispatch({ type: SET_DEPENDENT_FIELD_ERROR, payload: fieldLevel });
    }
  }, [error]);

  useEffect(() => {
    updateDependentFieldsSelection(state.selections);
  }, [state.selections]);

  const fetchDependentFieldNames = (options, level = 0) => {
    if (options) {
      dispatch({
        type: GET_DEPENDENT_FIELDS,
        payload: { name: options.input_name, level }
      });
      if (options && options.dependent_field) {
        return fetchDependentFieldNames(options.dependent_field, level + 1);
      }
    }
  };

  const initializeDependentFields = (options, level = 0) => {
    if (
      options &&
      fields[options.input_name] &&
      options.compliance_options &&
      options.compliance_options.length
    ) {
      const option = options.compliance_options.find(
        o => o.option_value === fields[options.input_name]
      );
      dispatch({
        type: INITIALIZE,
        payload: { ...option, level, name: options.input_name }
      });
      return initializeDependentFields(options.dependent_field, level + 1);
    }
  };

  //handlers
  const onSelectChange = selectedItem => {
    const { name, option_value: value } = selectedItem;
    handleDependentFieldsSelect({ key: name, value, selectedItem });
    dispatch({ type: UPDATE_SELECTION, payload: selectedItem });
  };

  // components
  const List = ({ options, onSelectChangeHandler, level, id }) => {
    const [select, setSelect] = useState(null);
    if (!options) return <div />;
    const mappedItems = uniqBy(options.compliance_options, 'id').map(o => ({
      ...o,
      name: o.option_value
    }));
    const items =
      state.selections[level - 1] && mappedItems.length
        ? mappedItems.filter(
          item => item.parent_option_id === state.selections[level - 1].id
        )
        : mappedItems;
    if (!items.length) return <div />;
    const onDropdownCallback = (_, item) => {
      setSelect(item);
      onSelectChangeHandler({
        ...item,
        name: options.input_name,
        level
      });
    };
    const isError = error && error.fieldLevel === level && touched;

    return (
      <div className="inputHolder clearfix">
        <Dropdown
          items={items}
          placeholder={options.input_name}
          dropDownHeading={options.input_name}
          error={isError && !id}
          black={false}
          sendBackObject={true}
          dropDownCallback={onDropdownCallback}
          id={id || (select && select.id)}
          customClassName="patientDropDown"
        />
      </div>
    );
  };

  const initialList = (options, level = 0) => {
    if (!options) return;
    if (state.selections[level] || (options && options.compliance_options)) {
      const id =
        state.selections[level] && state.selections[level].id
          ? state.selections[level].id
          : null;
      const handleChange = selectedItem => {
        onSelectChange(selectedItem);
      };
      return (
        <React.Fragment>
          <List
            options={options}
            onSelectChangeHandler={handleChange}
            level={level}
            id={id}
            parentId={state.selections[level] && state.selections[level].parent_option_id}
          />
          {state.selections[level] && initialList(options.dependent_field, level + 1)}
        </React.Fragment>
      );
    }
    return <div />;
  };

  if (state.selections && state.selections.length) return initialList(dependentFields);
  return (
    <div>
      <List
        options={dependentFields}
        onSelectChangeHandler={selectedItem => onSelectChange(selectedItem)}
        level={0}
        id={
          state.selections &&
          state.selections.find(s => s.level === 0) &&
          state.selections.find(s => s.level === 0).id
        }
      />
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return isEqual(prevProps, nextProps);
};
export default React.memo(DependentFieldsContainer, arePropsEqual);
