import React from 'react';
import _ from 'lodash-es';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const ComplianceHOC = WrappedComponent => {
  class ComplianceComponent extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        complianceInfo: _.cloneDeep(props.complianceInfo),
        complianceSelect: {}
      };
    }

    componentDidMount() {
      if (this.props.editRide && !_.isEmpty(this.props.bookingData)) {
        const complianceSelect = this.setComplianceSelect();

        if (!_.isEmpty(complianceSelect)) this.setState({ complianceSelect });
      }
    }

    componentDidUpdate(prevProps) {
      const { bookingData, editRide } = this.props;
      const prevEdit = prevProps?.bookingData?.editPatientInfo ?? false;
      const edit = bookingData?.editPatientInfo ?? false;

      if (
        (prevEdit !== edit && prevEdit === false && !editRide) ||
        (!_.isEqual(this.props.bookingData, prevProps.bookingData) && editRide)
      ) {
        const complianceSelect = this.setComplianceSelect();

        if (!_.isEmpty(complianceSelect)) {
          this.setState({ complianceSelect });
        }
      }
    }

    /**
     * seed compliance select data
     * @return {object} return seeded complianceSelect data
     */
    setComplianceSelect() {
      const bookingData = this.props.bookingData;
      const complianceInfo = this.state.complianceInfo;

      if (_.isEmpty(bookingData) || !('compliance' in bookingData)) {
        return {};
      }

      let complianceSelect = {};
      for (let i = 0; i < complianceInfo.length; i++) {
        const compliance = complianceInfo[i];

        const { id, input_name: name } = compliance;

        const val = bookingData?.compliance?.[name] ?? null;
        const childData = compliance?.child ?? null;

        if (!_.isNil(childData)) {
          const childId = childData.id;
          const nextId = complianceInfo[i + 1]?.id ?? null;

          if (childId !== nextId && val !== null) {
            const option = _.find(compliance.compliance_options, { option_value: val });
            const optionId = typeof option !== 'undefined' ? option.id : null;

            complianceSelect = this.complianceInsert(
              compliance,
              complianceSelect,
              optionId,
              i,
              id
            );
          }
        }

        if (val !== null) complianceSelect[id] = val;
      }

      return complianceSelect;
    }

    /**
     * helper function for creating extra drop down for dependent fields
     * @param {object} selectedComplianceInfo - compliance info record
     * @param {object} complianceSelect - values for compliance fields
     * @param {integer} id - option id
     * @param {integer} key - index for compliance field array
     * @param {integer} fieldId - id for compliance select
     * @return {object} return updated complianceSelect
     */
    complianceInsert = (selectedComplianceInfo, complianceSelect, id, key, fieldId) => {
      const complianceData = selectedComplianceInfo?.child ?? null;
      const optionMapping = selectedComplianceInfo?.option_mapping ?? [];

      if (_.isNil(complianceData) || optionMapping.length <= 0) return complianceSelect;

      // before we insert, we have to modify the options by removing options that don't match
      // check to see if compliance_options_original exists
      const complianceOptionsOriginal = complianceData?.complianceOptionsOriginal ?? [];
      const complianceOptions = !_.isEmpty(complianceOptionsOriginal)
        ? complianceOptionsOriginal
        : (complianceData?.compliance_options ?? []);

      const childIds = optionMapping
        .filter(({ parent_option_id }) => parent_option_id === id)
        .map(({ child_option_id }) => child_option_id);

      const complianceOptionsFiltered = complianceOptions.filter(({ id }) =>
        childIds.includes(id)
      );

      const newComplianceObject = complianceData;
      newComplianceObject['complianceOptionsOriginal'] = complianceOptions;
      newComplianceObject['compliance_options'] = complianceOptionsFiltered;

      // check to see if we've selected a value for our created dropdown or not
      // if so then replace instead of insert
      if (complianceOptionsFiltered.length > 0) {
        if (typeof complianceSelect[fieldId] !== 'undefined') {
          this.setComplianceInfo(newComplianceObject, key + 1, 'update');
          delete complianceSelect[complianceData.id];
        } else {
          this.setComplianceInfo(newComplianceObject, key + 1, 'insert');
        }
      } else {
        // no child elements so if there are child elements from a previous selection, remove.
        const complianceInfo = this.childCheck(this.state.complianceInfo, key);
        this.setState({ complianceInfo });
        delete complianceSelect[complianceData.id];
      }

      return complianceSelect;
    };

    /**
     * callback for properly setting compliance info, needed for parent child relations
     * @param {object} newComplianceInfo - new compliance info data
     * @param {integer} key - index in complianceInfo array
     * @param {string} insertUpdate - insert or update?
     * @return {undefined} - returns nothing
     * @memberof EditRide
     */
    setComplianceInfo = (newComplianceInfo, key, insertUpdate) => {
      const complianceInfo = this.updateCompliance(
        this.state.complianceInfo,
        newComplianceInfo,
        key,
        insertUpdate
      );
      this.setState({ complianceInfo });
    };

    /**
     * update the array of compliance data before setting to state
     * @param {array} complianceArray - old compliance array
     * @param {object} newComplianceInfo - new complance object that will be updated or inserted
     * @param {integer} key - index for complianceArray where new compliance object will be inserted
     * @param {string} insertUpdate - do we insert or update?
     * @return {array} return updated compliance array
     */
    updateCompliance(complianceArray, newComplianceInfo, key, insertUpdate) {
      switch (insertUpdate) {
        case 'update':
          complianceArray[key] = newComplianceInfo;
          complianceArray = this.childCheck(complianceArray, key);
          break;

        case 'insert':
          if (_.find(complianceArray, { id: newComplianceInfo.id }) === undefined) {
            complianceArray.splice(key, 0, newComplianceInfo);
          }
          break;
      }

      return complianceArray;
    }

    /**
     * recursively iterate through child objects to collect ids to clear
     * when someone changes drop down selection
     * @param {object} compliance - compliance object
     * @param {array} ids - array of compliance ids
     * @return {array} array of ids to clear
     */
    recursiveIdsToClear(compliance, ids = []) {
      if (compliance?.child) return ids;

      ids.push(compliance.child.id);
      return this.recursiveIdsToClear(compliance.child, ids);
    }

    /**
     * remove child compliance objects from array
     * @param {array} complianceArray - array of compliance fields
     * @param {integer} key - key to start recursive check
     * @return {array} return array with child fields removed
     */
    childCheck(complianceArray, key) {
      const indexesToRemove = [];

      // retrieve keys which match compliance id
      this.recursiveIdsToClear(complianceArray[key]).forEach(id => {
        const index = _.findIndex(complianceArray, { id });

        if (index > -1) indexesToRemove.push(index);
      });

      // iterate through keys in reverse since higher keys are first in array
      for (let i = indexesToRemove.length - 1; i >= 0; i--) {
        complianceArray.splice(indexesToRemove[i], 1);
      }

      return complianceArray;
    }

    render() {
      return (
        <WrappedComponent
          complianceInfo={this.state.complianceInfo}
          complianceSelect={this.state.complianceSelect}
          setComplianceInfo={this.complianceInsert}
          {...this.props}
        />
      );
    }
  }

  ComplianceComponent.defaultProps = {
    editRide: false, // booking ride, this is false, edit ride, this is true
    bookingData: {},
    complianceInfo: []
  };

  ComplianceComponent.propTypes = {
    editRide: PropTypes.bool,
    bookingData: PropTypes.object,
    complianceInfo: PropTypes.array
  };

  const mapStateToProps = state => ({
    bookingData: state.bookingData
  });

  return connect(mapStateToProps)(ComplianceComponent);
};

export default ComplianceHOC;
