/* eslint-disable dot-notation */
import React from 'react';
import type { FormDependency, FormInputInternalProps, FormInputProps } from './FormInput';
import FormInputRadio from './FormInputRadio';
import FormInputSelect from './FormInputSelect';
import FormInputCheckbox from './FormInputCheckbox';
import type { FormState } from './Form';

/**
 * Matches the "type" key in the input props to
 * the corresponding Input component and returns the instance
 * @param {FormInputProps} props
 * @returns {JSX.Element}
 */
export function resolveInputType(props: FormInputProps & FormInputInternalProps) {
  switch (props.type) {
    case 'radio':
      return <FormInputRadio {...props} />;

    case 'select':
      return <FormInputSelect {...props} />;

    case 'checkbox':
      return <FormInputCheckbox {...props} />;

    default:
      throw Error(`Failed to resolve form input type ${props}`);
  }
}

/**
 * Creates a Map of question numbers and the questions that
 * are dependent on them.
 * @param {FormInputProps[]} props
 * @returns {Map}
 */
export function createDependencyMap(props: FormInputProps[]) {
  const map = new Map<number, number[]>();

  for (let inputIndex = 0; inputIndex < props.length; inputIndex++) {
    const input = props[inputIndex];

    if ('dependencies' in input && input.dependencies?.length) {
      input.dependencies.forEach(dep => mapDependency(map, inputIndex, dep));
    }

    input.options.forEach(opt => {
      if ('dependencies' in opt && opt.dependencies?.length) {
        opt.dependencies?.forEach(dep => mapDependency(map, inputIndex, dep));
      }
    });
  }

  return map;
}

/**
 * Adds a question dependency to the dependency map. The returned map
 * is keyed by input id and points to an array of inputs dependent on that question.
 * @param map
 * @param srcInputId
 * @param dependency
 */
function mapDependency(
  map: Map<number, number[]>,
  srcInputId: number,
  dependency: FormDependency
) {
  if (!map.has(dependency.inputId) || !Array.isArray(map.get(dependency.inputId))) {
    map.set(dependency.inputId, [srcInputId]);
  } else {
    const dependentInputs = map.get(dependency.inputId)!;

    if (!dependentInputs.includes(srcInputId)) {
      dependentInputs.push(srcInputId);
      map.set(dependency.inputId, dependentInputs);
    }
  }
}

/**
 * Creates the initial state of the form using values on
 * the template or default options
 * @param {array} props
 * @returns {Record<FormInputProps['id'], FormInputProps>}
 */
export function createInitialState(props: FormInputProps[]) {
  const initialState = props.reduce(
    (state, c, i) => {
      if (i in state) {
        throw Error(
          `<Form/> - Attempted to assign an input to already occupied position: ${i}`
        );
      }

      state[i] = c;
      state[i]['selection'] ??= undefined;

      return state;
    },
    {} as Record<number, FormInputProps>
  );

  return Object.values(initialState);
}

/**
 * Validates a FormDependency against a state map
 * @param state
 * @param dep
 * @returns {boolean}
 */
export function checkDependency(
  state: ReturnType<typeof createInitialState>,
  dep?: FormDependency
) {
  if (!dep) return true;

  const { inputId, valueIs, and } = dep;

  // Dependent question doesn't exist on form
  if (!(inputId in state)) return false;

  // Dependent question has not been answered
  if (state[inputId]['selection'] === undefined) return false;

  // Dependent question does not include selected value
  if (valueIs) {
    const stateValue = state[inputId]['selection']!;

    if (Array.isArray(stateValue)) {
      if (!stateValue.some(val => valueIs.includes(val))) return false;
    } else if (!valueIs.includes(stateValue)) {
      return false;
    }
  }

  if (and && Array.isArray(and)) {
    for (const stmnt of and) {
      if (!checkDependency(state, stmnt)) return false;
    }
  }

  return true;
}

/**
 * Iterates through each dependency and returns true once it
 * processes a valid relation
 * @param state
 * @param dependencies
 * @returns {boolean}
 */
export function checkDependencies(
  state: ReturnType<typeof createInitialState>,
  dependencies?: FormDependency[]
) {
  if (!dependencies || !dependencies.length) return true;

  for (const dep of dependencies) {
    if (checkDependency(state, dep)) return true;
  }

  return false;
}

/**
 * Consistent formatting for data-testid properties
 * @param {object} inputProps
 * @returns {string}
 */
export function getTestId(inputProps: Pick<FormInputProps, 'type' | 'name'>) {
  const { type, name } = inputProps;

  return `${type}-${name}`;
}

/**
 * Converts the form state into JSON of { [field]: value }
 * @param state
 * @returns
 */
export function extractFormData(state: FormState) {
  const formData = {};

  for (const index in state) {
    const option = state[index];
    formData[option.name] = extractInputData(option);
  }

  return formData;
}

/**
 * Returns the formatted input value for a
 * given input.
 * @param {FormInputProps} props
 * @returns {FormInputOption['value'] | FormInputOption['value'][]}
 */
export function extractInputData(props: FormInputProps) {
  if (props?.selection === undefined) return undefined;

  switch (props.type) {
    case 'radio':
    case 'select':
      return props.options[props.selection].value;

    case 'checkbox':
      return props.selection.map(val => props.options[val].value);
  }
}

/**
 * Returns a record containing an array of input['field'] values
 * @param {FormState} state
 * @returns {string[]}
 */
export function validateForm(state: FormState) {
  return state.filter(v => validateInput(v)).map(v => v.name);
}

/**
 * Determines if the current form input is valid
 * @param {FormInputProps} input
 * @returns {boolean}
 */
export function validateInput(input: FormInputProps): boolean {
  return !!(input?.selection === undefined && input?.required);
}
