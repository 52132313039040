import axios from '../safeAxios';
import { baseRequestConfig } from '~/utilities/auth.helper';
import { ActionTypes } from './constants';
import { v4 as uuidv4 } from 'uuid';
import DownloadJs from 'downloadjs';
import { all, put, takeEvery } from 'redux-saga/effects';
import { generateExpenseId } from '~/utilities/helperFunctions';
import _ from 'lodash-es';

const BASE_URL = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1`;
const API_URL = `${BASE_URL}/expense`;

function* getReportById({ payload: id }) {
  try {
    const {
      data: { data }
    } = yield axios({
      ...baseRequestConfig(),
      url: `${API_URL}/${id}`,
      method: 'GET'
    });

    if (!data.memberId) throw new Error();

    yield put({
      data,
      type: ActionTypes.GET_REPORT_BY_ID_SUCCESS
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Get Report By ID Error: ', error);
    yield put({ type: ActionTypes.GET_REPORT_BY_ID_ERROR, error });
  }
}

function* getReportActivityLog({ payload: id }) {
  try {
    const {
      data: { data }
    } = yield axios({
      ...baseRequestConfig(),
      url: `${API_URL}/logs/${id}?format=plain`,
      method: 'GET'
    });

    yield put({
      data,
      type: ActionTypes.GET_REPORT_ACTIVITY_LOG_SUCCESS
    });
  } catch (error) {
    yield put({ type: ActionTypes.GET_REPORT_ACTIVITY_LOG_ERROR, error });
  }
}

// Helper formatting fn becuase BE changed.
const addIdsToReportExpenses = ({ report, hospitalGroupId }) => {
  const r = _.cloneDeep(report);
  let key = '';
  if (Array.isArray(report.expenses)) {
    report.expenses = r.expenses.reduce((acc, cur) => {
      key = cur.id ? cur.id : generateExpenseId(hospitalGroupId);
      acc[key] = cur;
      return acc;
    }, {});
  } else report.expenses = {};
  return report;
};

// Helper formatting fn becuase BE changed.
const addIdsToReportPassengers = ({ report }) => {
  const r = _.cloneDeep(report);
  let key = '';
  if (Array.isArray(report.passengers)) {
    report.passengers = r.passengers.reduce((acc, cur) => {
      key = cur.id ? cur.id : uuidv4();
      acc[key] = cur;
      return acc;
    }, {});
  } else report.passengers = {};
  return report;
};

function* createExpenseReport({ payload: { newReport, hospitalGroupId } }) {
  let report = addIdsToReportExpenses({ report: newReport, hospitalGroupId });
  report = addIdsToReportPassengers({ report: newReport });
  try {
    const {
      data: { data }
    } = yield axios({
      ...baseRequestConfig(),
      url: `${API_URL}`,
      data: report,
      method: 'POST'
    });
    yield put({
      data,
      type: ActionTypes.CREATE_EXPENSE_REPORT_SUCCESS
    });
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_EXPENSE_REPORT_ERROR, error });
  }
}

function* updateExpenseReport({ payload: { report, id, hospitalGroupId } }) {
  report = addIdsToReportExpenses({ report, hospitalGroupId });
  report = addIdsToReportPassengers({ report });

  try {
    const {
      data: { data }
    } = yield axios({
      ...baseRequestConfig(),
      url: `${API_URL}/${id}`,
      data: report,
      method: 'PUT'
    });

    yield put({
      data,
      type: ActionTypes.UPDATE_EXPENSE_REPORT_SUCCESS
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('UPDATE REPORT ERROR: ', error);
    alert('There was an error updating your report.');
    yield put({
      type: ActionTypes.UPDATE_EXPENSE_REPORT_ERROR,
      error
    });
  }
}

function* checkDuplicateExpenses({ payload: { memberId, expense } }) {
  const { expenseDate, category, amount } = expense;
  try {
    const {
      data: { data }
    } = yield axios({
      ...baseRequestConfig(),
      url: `${BASE_URL}/check/duplicate-expense`,
      data: {
        memberId,
        expense: { expenseDate, category, amount }
      },
      method: 'POST'
    });
    const { isDuplicate, expenses } = data;
    yield put({
      data: { isDuplicate, expenses },
      type: ActionTypes.CHECK_DUPLICATE_EXPENSES_SUCCESS
    });
  } catch (error) {
    yield put({ type: ActionTypes.CHECK_DUPLICATE_EXPENSES_ERROR, error });
  }
}

function* uploadAttachment({ payload }) {
  try {
    const {
      data: { data }
    } = yield axios({
      url: `${API_URL}/attachments`,
      data: payload,
      method: 'POST',
      headers: {
        ...baseRequestConfig().headers,
        'Content-Type': 'multipart/form-data'
      },
      redirect: 'follow'
    });
    if (!data.filename) throw new Error(`Upload Error: ${data}`);
    yield put({
      data,
      type: ActionTypes.UPLOAD_ATTACHMENT_SUCCESS
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('upload error: ', error);
    yield put({ type: ActionTypes.UPLOAD_ATTACHMENT_ERROR, error });
  }
}

function* downloadAttachment({ payload: { id, filename } }) {
  try {
    const data = yield axios({
      ...baseRequestConfig(),
      url: `${API_URL}/attachments/${id}`,
      method: 'GET',
      responseType: 'blob'
    });
    DownloadJs(data.data, filename, data.type);
    yield put({
      data,
      type: ActionTypes.DOWNLOAD_ATTACHMENT_SUCCESS
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Download Error: ', error);
    yield put({ type: ActionTypes.DOWNLOAD_ATTACHMENT_ERROR, error });
  }
}

export function* memberExpensesSaga() {
  yield all([
    takeEvery(ActionTypes.GET_REPORT_BY_ID, getReportById),
    takeEvery(ActionTypes.UPLOAD_ATTACHMENT, uploadAttachment),
    takeEvery(ActionTypes.DOWNLOAD_ATTACHMENT, downloadAttachment),
    takeEvery(ActionTypes.UPDATE_EXPENSE_REPORT, updateExpenseReport),
    takeEvery(ActionTypes.CREATE_EXPENSE_REPORT, createExpenseReport),
    takeEvery(ActionTypes.GET_REPORT_ACTIVITY_LOG, getReportActivityLog),
    takeEvery(ActionTypes.CHECK_DUPLICATE_EXPENSES, checkDuplicateExpenses)
  ]);
}
