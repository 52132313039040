import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import WizardStepsDisplay from '~/Shared/Components/WizardStepsDisplay';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import PlanBenefits from '../PlanBenefits/PlanBenefits';

class PlanCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [
        {
          index: 0,
          label: 'Plan Info',
          isValid: false
        },
        {
          index: 1,
          label: 'Plan Benefits',
          isValid: false
        },
        {
          index: 2,
          label: 'Plan Added',
          isValidBack: false
        }
      ],
      currentStepIndex: 0,
      planName: '',
      planId: '',
      benefits: []
    };
  }

  /**
   * Handle cancel request
   * @returns {undefined}
   */
  handleCancel() {
    const confirmMessage =
      'Are you sure you want to cancel? All of your changes will be lost.';
    const isConfirmed =
      this.state.currentStepIndex === this.state.steps.length - 1
        ? true
        : window.confirm(confirmMessage);
    if (isConfirmed) {
      this.props.handleCancel();
    }
  }

  /**
   * Handle next button click
   * @returns {undefined}
   */
  handleNext() {
    // post saved data on completing step 2
    if (this.state.currentStepIndex === 1) {
      this.props.createPlan({
        planName: this.state.planName,
        planId: this.state.planId,
        benefits: this.state.benefits
      });
    }

    this.setState({
      currentStepIndex: this.state.currentStepIndex + 1
    });
  }

  /**
   * Handle prev button click
   * @returns {undefined}
   */
  handlePrev() {
    this.setState({
      currentStepIndex: this.state.currentStepIndex - 1
    });
  }

  /**
   * Handle input field changes for step 1
   * @param {SyntheticEvent} e React event object
   * @param {String} field Field name
   * @returns {undefined}
   */
  handleInputChange(e, field) {
    this.setState(
      {
        [field]: e.target.value
      },
      () => {
        // check for valid form and enable next button
        const steps = _.cloneDeep(this.state.steps);
        steps[0].isValid = this.state.planName !== '' && this.state.planId !== '';
        this.setState({ steps });
      }
    );
  }

  /**
   * Handle benefits changes for step 2
   * @param {Array} benefits List of benefits
   * @returns {undefined}
   */
  handleBenefitsChange(benefits) {
    const steps = _.cloneDeep(this.state.steps);
    // check if step is complete to enable next button
    steps[1].isValid = benefits.length > 0;
    this.setState({
      benefits,
      steps
    });
  }

  render() {
    return (
      <div className="PlanCreate">
        <header>Create a Plan</header>
        <div className="closeCreatePlan" onClick={() => this.handleCancel()}>
          <SvgClose />
        </div>
        <WizardStepsDisplay
          steps={this.state.steps}
          currentStepIndex={this.state.currentStepIndex}
          handleNext={() => this.handleNext()}
          handlePrev={() => this.handlePrev()}
        />
        {
          {
            0: (
              <div className="entryForm">
                <h2>1. Plan Info</h2>
                <div className="formField">
                  <label htmlFor="planName">Plan Name</label>
                  <input
                    id="planName"
                    type="text"
                    value={this.state.planName}
                    placeholder="Plan Name"
                    onChange={e => this.handleInputChange(e, 'planName')}
                    autoFocus
                  />
                </div>
                <div className="formField">
                  <label htmlFor="planId">Plan ID</label>
                  <input
                    id="planId"
                    type="text"
                    value={this.state.planId}
                    placeholder="Plan ID"
                    onChange={e => this.handleInputChange(e, 'planId')}
                  />
                </div>
              </div>
            ),
            1: (
              <div className="entryForm">
                <h2>2. Plan Benefits</h2>
                <PlanBenefits
                  interactiveMode={true}
                  interactiveBenefits={this.state.benefits}
                  setInteractiveBenefits={benefits => this.handleBenefitsChange(benefits)}
                />
              </div>
            ),
            2: (
              <div className="entryForm">
                <h2>3. Plan Added</h2>
                <p>
                  <span>Your new plan has been added. </span>
                  <a onClick={() => this.props.handleCancel()}>
                    Click here to view all plans
                  </a>
                </p>
              </div>
            )
          }[this.state.currentStepIndex]
        }
      </div>
    );
  }
}

PlanCreate.propTypes = {
  handleCancel: PropTypes.func,
  createPlan: PropTypes.func
};

PlanCreate.defaultProps = {
  handleCancel: () => {},
  createPlan: () => {}
};

export default PlanCreate;
