import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { updateProfile } from '~/Modules/profile';
import PropTypes from 'prop-types';
import { statusNotifications } from '~/utilities/helperFunctions';
import DropDown from '~/Shared/Components/DropDown';
import _ from 'lodash-es';
import { PASSWORD_REGEX } from '~/constants';

class EditProfile extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      firstName: '',
      lastName: '',
      timezone: '',
      userMobileNo: '',
      contactNo1: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      hospitalGroupName: '',
      hospitalName: '',
      password1: '',
      password2: '',
      password1Error: false,
      password2Error: false,
      errorMessage: []
    };
  }

  componentDidMount() {
    // user object and profile object
    const profile = _.get(this.props, 'profile.profile', {});
    const profileUser = _.get(this.props, 'profile.user', {});

    // get individual values, some user role wont have all the values so default to '' in that instance
    const firstName = _.get(profileUser, 'firstName', '');
    const lastName = _.get(profileUser, 'lastName', '');
    const timezoneFormat = _.get(profileUser, 'timezone_format', '');
    const timezones = _.get(this.props, 'profile.timezones', []);
    const timeZoneObj = _.find(timezones, { time_zone_format: timezoneFormat });
    let timezone = '';
    if (!_.isNil(timeZoneObj)) {
      timezone = timeZoneObj.id;
    }

    let userMobileNo = _.get(profileUser, 'userMobileNo', '');
    if (userMobileNo === null) {
      userMobileNo = '';
    }
    const hospitalGroupName = _.get(profile, 'hospitalGroupName', '');
    const hospitalName = _.get(profile, 'hospitalName', '');
    const contactNo1 = _.get(profile, 'contactNo1', '');
    const street1 = _.get(profile, 'street1', '');
    const street2 = _.get(profile, 'street2', '');
    const city = _.get(profile, 'city', '');
    const state = _.get(profile, 'state', '');
    const zipcode = _.get(profile, 'zipcode', '');
    const country = _.get(profile, 'country', '');

    this.setState({
      firstName,
      lastName,
      timezone,
      userMobileNo,
      contactNo1,
      street1,
      street2,
      city,
      state,
      zipcode,
      country,
      hospitalGroupName,
      hospitalName
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEmpty(this.props.error) && this.props.timestamp !== prevProps.timestamp) {
      this.setState({
        errorMessage: _.get(this.props, 'error.data.errors', [
          'There was an issue with the submission.'
        ])
      });
    }

    if (
      !_.isEmpty(_.get(this.props, 'updatedProfile', {})) &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      statusNotifications('Profile has been updated.', 'success', 3000);
    }
  }

  /**
   * @param {event} e - event
   * @return {any} returns false or undefiend, false if it doesnt pass validation
   */
  submitEditInfo = e => {
    e.preventDefault();
    if (!this.checkFormsValid()) {
      return false;
    }
    const submittedFields = {};
    // build up submission
    if (this.state.password1 !== '' && this.state.password2 !== '') {
      submittedFields.password = this.state.password1;
    }

    submittedFields.firstName = this.state.firstName;
    submittedFields.lastName = this.state.lastName;
    submittedFields.userMobileNo = this.state.userMobileNo;
    submittedFields.timezone = parseInt(this.state.timezone, 10);

    const userRole = _.get(this.props, 'user.role');
    const showAllFields = ['HospitalManager', 'HospitalOwner'].indexOf(userRole) > -1;

    if (showAllFields) {
      submittedFields.street1 = this.state.street1;
      submittedFields.street2 = this.state.street2;
      submittedFields.city = parseInt(this.state.city, 10);
      submittedFields.state = parseInt(this.state.state, 10);
      submittedFields.zipcode = this.state.zipcode;
      submittedFields.country = parseInt(this.state.country, 10);
      submittedFields.contactNo1 = this.state.contactNo1;
      if (userRole === 'HospitalManager') {
        submittedFields.hospitalName = this.state.hospitalName;
      }

      if (userRole === 'HospitalOwner') {
        submittedFields.hospitalGroupName = this.state.hospitalGroupName;
      }
    } else {
      submittedFields.userMobileNo = this.state.userMobileNo;
    }

    this.props.updateProfile(submittedFields);
  };

  /**
   * Set selected value to compliance field
   * @param {object} e - events
   * @return {undefined} - returns nothing
   */
  handleStateSelect = e => {
    const id = parseInt(e.target.getAttribute('id'), 10);

    this.setState({ state: id });
  };

  /**
   * Set selected value to compliance field
   * @param {object} e - events
   * @return {undefined} - returns nothing
   */
  handleCitySelect = e => {
    const id = parseInt(e.target.getAttribute('id'), 10);

    this.setState({ city: id });
  };

  /**
   * Set selected value to compliance field
   * @param {object} e - events
   * @return {undefined} - returns nothing
   */
  handleCountrySelect = e => {
    const id = parseInt(e.target.getAttribute('id'), 10);

    this.setState({ country: id });
  };

  /**
   * Set selected value to compliance field
   * @param {object} e - events
   * @return {undefined} - returns nothing
   */
  handleTimezoneSelect = e => {
    const id = e.target.getAttribute('id');

    this.setState({ timezone: id });
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changeText = e => {
    const id = e.target.id;
    const val = e.target.value;
    const stateObj = {};

    stateObj[id] = val;
    this.setState(stateObj);
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changePassword = e => {
    const id = e.target.id;
    const val = e.target.value;
    const stateObj = { [id]: val };

    this.setState(stateObj, this.checkFormsValid);
  };

  /**
   * receives field name and field values in array and returns record
   * @param {integer} fieldVal - value of field stored in profile
   * @param {array} allFields - array of all of the location fields
   * @return {object} - returns marching object
   */
  getLocaleVal(fieldVal, allFields) {
    const match = _.find(allFields, { id: parseInt(fieldVal, 10) });

    if (typeof match === 'undefined') {
      return false;
    }

    return match;
  }

  /**
   * searches timezone and returns matching record
   * @param {array} allFields - array of all timezones
   * @param {integer} timezone - timezone id selected from drop down
   * @return {object} match
   */
  getTimezoneVal(allFields, timezone) {
    const match = _.find(allFields, { id: parseInt(timezone, 10) });

    if (typeof match === 'undefined') {
      return false;
    }

    return match;
  }

  /**
   * searches array of either city, state, or country
   * @param {string} fieldName - name of fields
   * @param {array} allFields - array of all states, countries, cities, etc
   * @return {object} match
   */
  formatLocaleArrays(fieldName, allFields) {
    if (_.isEmpty(allFields)) {
      return [];
    }
    return allFields.map(field => {
      const name = field[`${fieldName}_name`];

      return {
        id: field.id,
        name
      };
    });
  }

  /**
   * formats array of timezones to be used in dropdown component
   * @param {array} allFields - array of timezones
   * @return {array} returns formatted array of timezones
   */
  formatTimezones(allFields) {
    if (_.isEmpty(allFields)) {
      return [];
    }
    return allFields.map(field => {
      return {
        id: field.id,
        name: field.timezone
      };
    });
  }

  /**
   * client side validation
   * @returns {boolean} returns true if it validates, false if it doesn't
   */
  checkFormsValid() {
    let status = true;
    const errorMessage = [];
    const state = {};

    const isValid1 = PASSWORD_REGEX.test(this.state.password1);
    const isValid2 = PASSWORD_REGEX.test(this.state.password2);

    if (this.state.password1 !== '' || this.state.password2 !== '') {
      state.password1Error = !isValid1;
      state.password2Error = !isValid2;
      if (isValid1 === false || isValid2 === false) {
        errorMessage.push('Make sure your passwords haves the correct format.');
        status = false;
      }
      if (this.state.password1 !== this.state.password2) {
        state.password1Error = true;
        state.password2Error = true;
        errorMessage.push('Make sure your passwords match.');
        status = false;
      }
    } else {
      state.password1Error = false;
      state.password2Error = false;
    }
    state.isValidated = status;
    state.errorMessage = errorMessage;
    this.setState(state);
    return status;
  }

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const cities = this.formatLocaleArrays(
      'city',
      _.get(this.props, 'profile.cities', [])
    );
    const states = this.formatLocaleArrays(
      'state',
      _.get(this.props, 'profile.states', [])
    );
    const countries = this.formatLocaleArrays(
      'country',
      _.get(this.props, 'profile.countries', [])
    );
    const timezones = this.formatTimezones(_.get(this.props, 'profile.timezones', []));
    const stateObj = this.getLocaleVal(this.state.state, states);
    const cityObj = this.getLocaleVal(this.state.city, cities);
    const countryObj = this.getLocaleVal(this.state.country, countries);
    const timezonesObj = this.getTimezoneVal(timezones, this.state.timezone);
    const userRole = _.get(this.props, 'user.role');
    const passwordClass1 = this.state.password1Error
      ? 'passwordClass error'
      : 'passwordClass';
    const passwordClass2 = this.state.password2Error
      ? 'passwordClass error'
      : 'passwordClass';
    const showAllFields = ['HospitalManager', 'HospitalOwner'].indexOf(userRole) > -1;

    return (
      <div className="passengerEdit userEdit">
        <form onSubmit={this.submitEditInfo}>
          <div className="hed">
            <div className="pageTitle">Edit Basic Info</div>
            <ul className="right">
              <li>
                <a className="cancel" onClick={this.props.closeEditProfile}>
                  Cancel
                </a>
              </li>
              <li>
                <input type="submit" className="submitButton" value="Save" />
              </li>
            </ul>
          </div>
          <div className="passengerForm">
            <div className="row clearfix">
              <div className="column">
                <label htmlFor="firstName">First Name</label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder="Enter first name"
                  value={this.state.firstName}
                  onChange={this.changeText}
                  required
                />
              </div>
              <div className="column">
                <label htmlFor="lastName">Last Name</label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder="Enter last name"
                  value={this.state.lastName}
                  onChange={this.changeText}
                  required
                />
              </div>
            </div>

            <div className="row clearfix">
              {this.state.hospitalGroupName !== '' ? (
                <div className="column">
                  <label htmlFor="hospitalGroupName">Facility Group Name</label>
                  <input
                    type="text"
                    id="hospitalGroupName"
                    name="hospitalGroupName"
                    placeholder="Enter hospital group name"
                    value={this.state.hospitalGroupName}
                    onChange={this.changeText}
                    required
                  />
                </div>
              ) : null}
              {this.state.hospitalName !== '' ? (
                <div className="column">
                  <label htmlFor="hospitalName">Facility Group Name</label>
                  <input
                    type="text"
                    id="hospitalName"
                    name="hospitalName"
                    placeholder="Enter hospital group name"
                    value={this.state.hospitalName}
                    onChange={this.changeText}
                    required
                  />
                </div>
              ) : null}
              {!showAllFields ? (
                <div className="column">
                  <label htmlFor="street1">{`Phone`}</label>
                  <input
                    type="text"
                    id="userMobileNo"
                    name="userMobileNo"
                    placeholder="Enter phone number"
                    value={this.state.userMobileNo}
                    onChange={this.changeText}
                  />
                </div>
              ) : null}
              <div className="column">
                <label htmlFor="city">{`Timezones`}</label>
                <DropDown
                  items={timezones}
                  placeholder={
                    timezonesObj === false ? 'Enter time zone' : timezonesObj.name
                  }
                  dropDownCallback={this.handleTimezoneSelect}
                  error={this.state.cityError}
                  customClassName="profileDropDown"
                  black={true}
                />
              </div>
            </div>
            {showAllFields ? (
              <div className="row clearfix">
                <div className="column">
                  <label htmlFor="street1">{`Address 1`}</label>
                  <input
                    type="text"
                    id="street1"
                    name="street1"
                    placeholder="Address 1"
                    value={this.state.street1}
                    onChange={this.changeText}
                  />
                </div>
                <div className="column">
                  <label htmlFor="street2">{`Address 2 (optional)`}</label>
                  <input
                    type="text"
                    id="street2"
                    name="street2"
                    placeholder="Address 2"
                    value={this.state.street2}
                    onChange={this.changeText}
                  />
                </div>
              </div>
            ) : null}
            {showAllFields ? (
              <div className="row clearfix">
                <div className="column">
                  <label htmlFor="city">{`City`}</label>
                  <DropDown
                    items={cities}
                    placeholder={cityObj === false ? 'Enter city' : cityObj.name}
                    dropDownCallback={this.handleCitySelect}
                    error={this.state.cityError}
                    customClassName="profileDropDown"
                    black={true}
                  />
                </div>
                <div className="column">
                  <div className="halfColumn">
                    <label htmlFor="zipcode">{`Zip`}</label>
                    <input
                      type="text"
                      id="zipcode"
                      name="zipcode"
                      placeholder="Enter zip"
                      value={this.state.zipcode}
                      onChange={this.changeText}
                    />
                  </div>
                  <div className="halfColumn">
                    <label htmlFor="state">{`State`}</label>
                    <DropDown
                      items={states}
                      placeholder={stateObj === false ? 'Enter state' : stateObj.name}
                      dropDownCallback={this.handleStateSelect}
                      error={this.state.stateError}
                      customClassName="profileDropDown"
                      black={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {showAllFields ? (
              <div className="row clearfix">
                <div className="column">
                  <label htmlFor="country">{`Country`}</label>
                  <DropDown
                    items={countries}
                    placeholder={countryObj === false ? 'Enter city' : countryObj.name}
                    dropDownCallback={this.handleCountrySelect}
                    error={this.state.countryError}
                    customClassName="profileDropDown"
                    black={true}
                  />
                </div>
                <div className="column">
                  <label htmlFor="street1">{`Phone`}</label>
                  <input
                    type="text"
                    id="contactNo1"
                    name="contactNo1"
                    placeholder="Enter phone number"
                    value={this.state.contactNo1}
                    onChange={this.changeText}
                  />
                </div>
              </div>
            ) : null}
            <div className="row clearfix">
              <div className="column">
                <label htmlFor="password1">{`* Password`}</label>
                <input
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d\s]).{10,}$"
                  className={passwordClass1}
                  type="password"
                  id="password1"
                  name="password1"
                  placeholder="Enter new password"
                  value={this.state.password1}
                  onChange={this.changePassword}
                />
              </div>
              <div className="column">
                <label htmlFor="password2">{`* Repeat Password`}</label>
                <input
                  pattern="^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z\d\s]).{10,}$"
                  className={passwordClass2}
                  type="password"
                  id="password2"
                  name="password2"
                  placeholder="Re-enter new password"
                  value={this.state.password2}
                  onChange={this.changePassword}
                />
              </div>
            </div>
            <div className="row clearfix">
              <div className="passwordRules">
                <p>{`* Please leave it blank if you don't want to change password.`}</p>
                <ul>
                  <li>
                    At least <strong>one uppercase letter</strong>
                  </li>
                  <li>
                    At least <strong>one lowercase letter</strong>
                  </li>
                  <li>
                    At least <strong>one number</strong>
                  </li>
                  <li>
                    At least <strong>one special character</strong>
                  </li>
                  <li>
                    Be at least <strong>10 characters</strong>
                  </li>
                </ul>
              </div>
            </div>
            {this.state.errorMessage.length > 0 ? (
              <div className="row clearfix">
                <div className="errors">
                  <ul>
                    {this.state.errorMessage.map((error, key) => {
                      return <li key={key}>{error}</li>;
                    })}
                  </ul>
                </div>
              </div>
            ) : null}
          </div>
        </form>
      </div>
    );
  }
}

EditProfile.propTypes = {
  user: PropTypes.object,
  updateProfile: PropTypes.func,
  closeEditProfile: PropTypes.func,
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hospitalUsers: PropTypes.array,
  nodeUserType: PropTypes.string,
  profile: PropTypes.object,
  status: PropTypes.number,
  error: PropTypes.object,
  updatedProfile: PropTypes.object,
  timestamp: PropTypes.number
};

EditProfile.defaultProps = {
  updateProfile: () => {},
  closeEditProfile: () => {},
  user: {},
  hospitalData: {},
  hospitalUsers: [],
  nodeUserType: '',
  profile: {},
  status: 200,
  error: {},
  updatedProfile: {},
  timestamp: 0
};

const mapStateToProps = state => ({
  user: state.user.userData,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  nodeUserType: state.user.nodeUserType,
  profile: state.profile.userProfile,
  status: state.profile.status,
  error: state.profile.error,
  updatedProfile: state.profile.updatedProfile,
  timestamp: state.profile.timestamp
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateProfile: data => updateProfile(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
