import { createTypeGuard } from '@SRHealth/frontend-lib';

/** Converts a phone number string into a hyphen delimited format. */
export function formatPhoneNumber(phoneNumber: string) {
  phoneNumber = phoneNumber.replace(/\D/g, '');

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(
    6
  )}`;
}

/** Validates if the phone number is a string and at least 10 digits
 * in length.
 *
 * This can be extended if we need to.
 */
export const isPhoneNumberValid = createTypeGuard((t: unknown) =>
  typeof t === 'string' && /\d{10,}/.test(t) ? t : null
);
