import { formatPhoneNumber, getTimeDelayed, generateRideDuration } from '~/utilities/helperFunctions';
const LYFT_DEFAULT_AVATAR = 
  'https://public-api.lyft.com/static/images/user.png';
const DEFAULT_AVATAR =
  'https://app.saferidehealth.com/images/avatar_default.jpg';

/**
 * The info window displayed on pin hover
 * @param {object} rideObj - Driver Object
 * @return {html} returns the car marker info window in html format
 */
const MapInfoWindowCar = rideObj => {
  const {
    avatar = '',
    driverFirstName = '',
    driverLastName = '',
    dmobileNo = '',
    dmobilePin = '',
    companyName: vehicleCompanyName = '',
    nickName: vehicleName = '',
    vehiclePlateNo = '',
    vehiclePlateState = '',
    status = ''
  } = rideObj;

  const lyftVehicleContent = vehicleCompanyName === 'Lyft' 
    ? `Vehicle: ${rideObj?.vehicleColor ?? ''} ${rideObj?.vehicleYear ?? ''} ${rideObj?.vehicleName ?? ''}<br/>` 
    : '';

  const driverInfo = populateDriverInfo({
    avatar: !avatar || avatar === LYFT_DEFAULT_AVATAR ? DEFAULT_AVATAR : avatar,
    driverFirstName,
    driverLastName,
    dmobileNo: dmobileNo ? `<br/>Phone: ${formatPhoneNumber(dmobileNo)}` : '',
    dmobilePin: dmobilePin ? `<br/>Pin: ${dmobilePin}` : '',
    vehicleCompanyName: vehicleCompanyName ? `Company: ${vehicleCompanyName}<br/>` : '',
    vehicleName: vehicleName ? `Vehicle: ${vehicleName}<br/>` : '',
    vehiclePlateNo: vehiclePlateNo ? `Plate: ${vehiclePlateNo}` : '',
    vehiclePlateState: vehiclePlateState ? ` ${vehiclePlateState}<br/>` : '<br/>',
    certificates: rideObj?.driverDetails?.certificates ? `Certs: ${rideObj.driverDetails.certificates}` : '',
    lyftVehicleContent
  });

  let statusBox = '';
  if (['Processing', 'Inbound', 'Arrived', 'Started'].includes(status)) {
    const {
      id = '',
      passengerFirstname = '',
      passengerLastname = '',
      medicalId = '',
      rideStartTime,
      passengerTimezone,
      locations
    } = rideObj;

    const duration = locations ? generateRideDuration(rideObj) : undefined;

    const delayedTime = status !== 'Started'
      ? getTimeDelayed(rideStartTime, passengerTimezone, 'h:mm A', 'ago')
      : '';

    statusBox = populateStatusBox({
      status,
      id,
      passengerFirstname,
      passengerLastname,
      medicalId: medicalId ? `Medical ID: ${medicalId}<br/>` : '',
      duration: duration ? `Arrival: ${duration}<br/>` : '',
      delayedTime: delayedTime ? `<span class='delayed'>${delayedTime}</span>` : ''
    });
  }

  return `<div class='infoWindow'>${driverInfo}${statusBox}</div>`;
};


const populateDriverInfo = (
  {
    avatar = '',
    driverFirstName = '',
    driverLastName = '',
    dmobileNo = '',
    dmobilePin = '',
    vehicleCompanyName = '',
    vehicleName = '',
    vehiclePlateNo = '',
    vehiclePlateState,
    lyftVehicleContent = '',
    certificates = ''
  }
) => {
  return `<div class='driver-info clearfix'>
    <div class='left avatar'>
      <img src='${avatar}'/>
      <div class='driverContent'>
        ${driverFirstName} ${driverLastName}
        ${dmobileNo}
        ${dmobilePin}
      </div>
    </div>
    <div class='right'>
      ${vehicleCompanyName}
      ${vehicleName}
      ${lyftVehicleContent}
      ${vehiclePlateNo}${vehiclePlateState}
      ${certificates}
    </div>
  </div>`;
};

const populateStatusBox = (
  {
    status = '',
    id = '',
    passengerFirstname = '',
    passengerLastname = '',
    medicalId = '',
    duration = '',
    delayedTime = ''
  }
) => {
  return `<div class='statusBoxCont clearfix'>
    <div class='statusBox ${status}'>
      <div class='statustext'>${id} ${status}</div>
      Passenger: ${passengerFirstname} ${passengerLastname}<br/>
      ${medicalId}
      ${duration}
      ${delayedTime}
    </div>
  </div>`;
};

export { MapInfoWindowCar as default };
