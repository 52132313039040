import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';

const ErrorModal = props => {
  const okGotIt = () => {
    props.closeModal();
  };
  const callback = () => {
    props.callback();
    props.closeModal();
  };
  const modalBody = props.centerBody ? 'modalBody center' : 'modalBody';
  const cancelClass = props.showSubmit ? 'modalFoot cancel' : 'modalFoot';

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      className={{
        base: 'newModalBaseClass newErrorModal',
        afterOpen: 'modalBaseClassOpen',
        beforeClose: 'modalBaseClassClose'
      }}
      overlayClassName={{
        base: 'overlayBaseClass BookAnywayModalOverlay',
        afterOpen: 'overlayBaseClassOpen',
        beforeClose: 'overlayBaseClassClose'
      }}
      closeTimeoutMS={500}
    >
      <div className="modalHead">
        <p>{props.title}</p>
      </div>
      <div className={modalBody}>
        {props.hasMarkup ?
          <div dangerouslySetInnerHTML={{__html: props.modalContent}} />
          : props.modalContent
        }
      </div>
      <div className={cancelClass}>
        {props.showSubmit ?
          <button onClick={callback}>{props.submitText}</button>
          : null
        }
        <button onClick={okGotIt} className='cancelButton'>{props.cancelButtonText}</button>
      </div>
    </Modal>
  );
};

ErrorModal.propTypes = {
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  modalContent: PropTypes.string,
  title: PropTypes.string,
  showSubmit: PropTypes.bool,
  showCancel: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  hasMarkup: PropTypes.bool,
  status: PropTypes.string,
  callback: PropTypes.func,
  centerBody: PropTypes.bool
};

ErrorModal.defaultProps = {
  closeModal: () => {},
  isOpen: false,
  modalContent: '',
  title: 'Error',
  showSubmit: true,
  showCancel: true,
  submitText: 'OK',
  cancelText: 'Cancel',
  hasMarkup: false,
  status: '',
  callback: () => {},
  centerBody: false,
  cancelButtonText: 'Cancel'
};

export default ErrorModal;
