import React, { useMemo } from 'react';
import { standardizeAddresses } from '~/utilities/helperFunctions';
import ApprovedProviders from '../../ApprovedProviders/ApprovedProviders';
import type { AutoCompleteSource } from '../TransportType';

export type ApprovedProvidersContainerProps = {
  missingFields: any;
  showSavedAddress: boolean;
  bookingData: BookingDataStore;
  selectAutoComplete: (value: any, type: string, source?: AutoCompleteSource) => void;
  resetAutoComplete: (showOrHide, location) => void;
  onChange: (e: string, type: 'pickup' | 'dropoff') => void;
  toggleLoading: (toggle: boolean, text: string, timeout?: number | undefined) => void;
  updateRideDetails: (data: Record<string, any>) => void;
};

export const ApprovedProvidersContainer: React.FC<ApprovedProvidersContainerProps> = ({
  missingFields,
  showSavedAddress,
  bookingData,
  selectAutoComplete,
  resetAutoComplete,
  onChange,
  toggleLoading,
  updateRideDetails
}) => {
  let rideDetails: BookingDataStore | RideData = bookingData;
  if (bookingData.bookingType === 'multileg') {
    rideDetails = bookingData.rides[bookingData?.currentRideIndex ?? 0];
  }

  const addresses = useMemo(() => {
    return standardizeAddresses(rideDetails);
  }, [bookingData]);

  return (
    <div className="left">
      <ApprovedProviders
        type="pickup"
        rideDetails={rideDetails}
        address={addresses.pickup}
        showSavedAddress={showSavedAddress}
        toggleLoading={toggleLoading}
        missingFields={missingFields}
        onSelectAutoComplete={selectAutoComplete}
        onChange={e => onChange(e.target.value, 'pickup')}
        onResetAutoComplete={resetAutoComplete}
        updateRideDetails={updateRideDetails}
      />
      <ApprovedProviders
        type="dropoff"
        rideDetails={rideDetails}
        address={addresses.dropoff}
        showSavedAddress={showSavedAddress}
        toggleLoading={toggleLoading}
        missingFields={missingFields}
        onSelectAutoComplete={selectAutoComplete}
        onChange={e => onChange(e.target.value, 'dropoff')}
        onResetAutoComplete={resetAutoComplete}
        updateRideDetails={updateRideDetails}
      />
    </div>
  );
};
