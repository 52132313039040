import React from 'react';
import PropTypes from 'prop-types';
import SvgCheckbox from '../Svgs/SvgCheckbox';
import SvgCheckboxChecked from '../Svgs/SvgCheckboxChecked';

const CheckBox = props => {
  const {
    onChangeCallback,
    label,
    checked,
    customClassName,
    black,
    blue,
    error,
    required,
    fieldName,
    disabled,
    errorText
  } = props;

  let className = 'CustomCheckBoxText';
  className = black ? `${className} black` : className;
  className = blue ? `${className} blue` : className;
  className = customClassName !== '' ? `${className} ${customClassName}` : className;
  className = error ? `${className} error` : className;
  className = disabled ? `${className} disabled` : className;

  return (
    <label className={className} htmlFor={fieldName}>
      {checked ?
        <SvgCheckboxChecked className='svgCheck' />
        :
        <SvgCheckbox className='svgCheck' />
      }
      <input
        type="checkbox"
        name={fieldName}
        id={fieldName}
        onChange={onChangeCallback}
        checked={checked}
        disabled={disabled}
        required={required}
      />
      <span className='repeatRideText'>{label}</span>
      {error ? <span className="errorText">{errorText}</span>: null}
    </label>
  );

};

CheckBox.propTypes = {
  onChangeCallback: PropTypes.func,
  label: PropTypes.string,
  checked: PropTypes.bool,
  customClassName: PropTypes.string,
  black: PropTypes.bool, // if this is true, dropDownField will be black instead of white
  blue: PropTypes.bool,
  error: PropTypes.bool, // show error styling if it's true
  required: PropTypes.bool,
  fieldName: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errorText: PropTypes.string
};

CheckBox.defaultProps = {
  onChangeCallback: () => {},
  label: '',
  checked: false,
  customClassName: '',
  black: false,
  blue: false,
  error: false,
  required: false,
  fieldName: PropTypes.string.isRequired,
  disabled: false,
  errorText: ''
};

export default CheckBox;
