import moment from 'moment';

/**
 * @summary - get health plan name for expense report slide out and pdf
 * @description - /v1/expense/{id} does not return a consistent response. this helper
 * extracts until we can standardize the api to always return `health_plan_name`
 * @param {object} report - expense report detail
 * @param {object} options - member options
 * @returns {string|null} the name, if it exists, of the expense health plan
 */
export const mapExpenseReportHealthPlanName = (report, options) => {
  const { fundingSource } = report;
  const { healthPlans } = options;
  if(report && report.health_plan_name){
    return report.health_plan_name;
  } else {
    let result;
    if (fundingSource === 0) {
      result = 'Self Funded';
    } else {
      const healthPlan = healthPlans.find(({ healthPlanId }) => healthPlanId === fundingSource);
      result = healthPlan ? `${healthPlan.healthPlanName} | ${healthPlan.healthSubPlanName}` : null;
    }
    
    return result;
  }  
};

/**
 * @summary - Helper function for date conversion
 * @param {*} d — date object
 * @returns string of date formatted to midday time
 */
export const convertDate = d => {
  try {
    const date = moment(d).format('YYYY-MM-DD');
    if (isNaN(Date.parse(date))) throw new Error();
    return `${date} 12:00:00`;
  } catch (error) {
    return d;
  }
};
