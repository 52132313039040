import { createSelector } from '@reduxjs/toolkit';
import { selectTreatments } from '~/Modules/patients';
import type { AppStore } from '~/types';

const selectFundingSource = (s: AppStore) => s.rideBooking.fundingSource;

export const selectActiveTreatment = createSelector(
  [selectTreatments, selectFundingSource],
  (treatments, fundingSource) => treatments?.find(t => t.id === fundingSource.treatmentId)
);
