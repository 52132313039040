import React, {useEffect} from 'react';
import {Transition} from 'react-transition-group';
import {useSavedAddressDrawer} from '~/hooks/useSavedAddressDrawer';
import SavedAddressColumn from './SavedAddressColumn';

const duration = 300;

const defaultStyle = {
  transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in`,
  transform: 'translateX(-100%)',
  opacity: 0
};

const transitionStyles = {
  entering: {opacity: 0, transform: 'translateX(-100%)'},
  entered: {opacity: 1, transform: 'translateX(0)'}
};

const SavedAddressDrawer = () => {
  const [state, Toggle] = useSavedAddressDrawer();

  const ref = React.useRef(null);

  /**
   * Automatically change the state to toggle off if
   * the component is ever unmounted
   */
  useEffect(() => {
    if (state) return Toggle.Off;
  }, [state]);

  return (
    <Transition nodeRef={ref} in={state} timeout={duration} unmountOnExit={true}>
      {transitionState => (
        <div
          id="savedAddressDrawer"
          ref={ref}
          style={{
            ...defaultStyle,
            ...transitionStyles[transitionState]
          }}
        >
          {state && <SavedAddressColumn />}
        </div>
      )}
    </Transition>
  );
};

export default SavedAddressDrawer;
export {useSavedAddressDrawer} from '~/hooks/useSavedAddressDrawer';
