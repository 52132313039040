import React from 'react';
import SimpleTooltip from 'react-simple-tooltip';

const Tooltip = ({children, text, width, className = '', placement = 'bottom'}) => {
  if (!text) return <>{children}</>;

  const props = {
    color: '#000000',
    radius: 4,
    border: '#505256',
    zIndex: 100000,
    content: (
      <div style={{zIndex: 1000, width, textAlign: 'left'}}>{text}</div>
    ),
    padding: 5,
    fontSize: '10px',
    placement,
    background: '#FFFFFF'
    
  };

  return (
    <SimpleTooltip className={className} {...props}>
      {children}
    </SimpleTooltip>
  );
};

export default Tooltip;
