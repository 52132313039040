import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  isNil,
  isEqual,
  clone,
  cloneDeep,
  get,
  find,
  isEmpty,
  merge,
  has,
  forEach,
  pick,
  assign,
  compact,
  difference,
  unset
} from 'lodash-es';
import moment from 'moment';
import { updateBookingData, updateRideData } from '~/Modules/bookingData';
import RepeatAppointments from '../RepeatAppointments';
import {
  LYFT_VEHICLE_ID,
  UBER_VEHICLE_ID,
  MULTIPLE_LEG_LIMIT,
  COMPONENT_STRINGS,
  PUBLIC_TRANSIT_ROUTE_PREFERENCES,
  PUBLIC_TRANSIT_MODE_PREFERENCES,
  RIDE_MODE,
  ASSISTANCE_NEEDS,
  DISABLE_RECURRING_RIDES,
  PUBLIC_TRANSIT,
  VEHICLE_SUB_TYPE
} from '~/constants';
import SvgClose from '../../../Svgs/SvgClose';
import DropDown from '../../../DropDown';
import MultiSelect from '../../../MultiSelect';
import TextAreaField from '../../../TextAreaField';
import InputText from '../../../InputText';
import { isNetworkRole, isRideShareVehicleId, NOOP } from '~/utilities/helperFunctions';
import SvgCheckbox from '~/Shared/Components/Svgs/SvgCheckbox';
import PublicRoute from '~/Shared/Components/BookingConfirmation/Components/TransportOptions/PublicRoute';
import SvgRadioSelected from '~/Shared/Components/Svgs/SvgRadioSelected';
import SvgRadioUnselected from '~/Shared/Components/Svgs/SvgRadioUnselected';
import SvgCheckboxChecked from '~/Shared/Components/Svgs/SvgCheckboxChecked';
import PublicConfirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation/PublicConfirmation';

const { PUBLIC, PRIVATE } = RIDE_MODE;

class RideNotes extends React.Component {
  /**
   * constructor
   * @param {object} props Parent properties
   * @return {void} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      showRepeatAppts: false,
      repeatApptsChecked: false,
      isValidated: false,
      hospitalId: 0,
      additionalNotes: '',
      payWithCard: false,
      transportMode: 1,
      transportTypeError: false,
      transportType: 0,
      transportTypeName: '',
      showRequestRide: false,
      showCheckBoxes: true,
      showCheckBoxPay: true,
      activeSubTypes: [],
      transportSubType: 0,
      transportSubTypeName: '',
      transportSubTypeError: false,
      assistanceNeeds: {}, // selected assistance needs
      assistanceNeedsError: false,
      vehicleTypes: [],
      publicTransitPreferences: [],
      publicTransitRoutePreferences: [''],
      hasWillCallLeg: false
    };
    this.networkRoles = ['HospitalOwner', 'HospitalNetworkManager'];
    this.currentRideIndex = -1;
    this.continueClicked = false;
    this.showVehicleSubType = this.props.user.features[VEHICLE_SUB_TYPE];
    this.showAssistanceNeeds = this.props.user.features[ASSISTANCE_NEEDS];
  }

  /**
   * Quick lookup for public transit feature
   * @returns {boolean} T/F if user has public transit
   */
  hasPublicTransit() {
    return this.props.user.features[PUBLIC_TRANSIT];
  }

  /**
   * Lookup to determine if public transit option is available
   * @returns {boolean} T/F
   */
  showPublicTransitOption() {
    const { bookingData } = this.props;
    const { bookingType, apptTime } = bookingData;

    if (bookingType === 'multileg') {
      return false;
    }

    if (this.state.hasWillCallLeg) {
      return false;
    }

    if (apptTime === 'WillCall') {
      return false;
    }

    return true;
  }

  componentDidMount() {
    let state = {};
    const {
      user,
      legIndex,
      editRide,
      bookingData,
      editRideNotesForm,
      updateRideData,
      updateBookingData,
      assistanceNeedsChecked,
      editBookedRide,
      patientDetails
    } = this.props;

    this.currentRideIndex = bookingData.currentRideIndex ?? -1;

    const index = legIndex > -1 ? legIndex : this.currentRideIndex;

    // showCheckBoxPay
    state.showCheckBoxPay = this.showPrivatePayOption(user.userData, editRide);
    const mobilityType = bookingData?.mobilityType ?? null;
    let memberDefaultMode =
      this.props.patientDetails?.default_mobility_mode === 'Public' &&
      this.hasPublicTransit()
        ? 0
        : 1;

    let transportType = bookingData?.transportType ?? 0;
    let transportMode = bookingData?.mode === PUBLIC ? 0 : 1;

    if (!isNil(mobilityType)) {
      transportType = mobilityType;
    }

    if (
      bookingData?.apptTime === 'Will Call' ||
      bookingData?.apptEnds === 'Will Call' ||
      bookingData?.rides?.[index]?.apptTime === 'Will Call' ||
      bookingData?.rides?.[index]?.apptEnd === 'Will Call'
    ) {
      memberDefaultMode = 1;
      this.setState({
        hasWillCallLeg: true,
        transportMode: memberDefaultMode, // Always make Will Call rides private
        selectedMode: memberDefaultMode
      });
    }

    let customFieldVal = bookingData?.rideCustomFields ?? '';
    let additionalNotes = bookingData.additionalNotes;
    if (!editRide) {
      additionalNotes = bookingData.additionalNotes || patientDetails.otherDetails;
    }
    let assistanceNeeds = bookingData?.assistanceNeeds ?? {};

    // new ride, then default assitance needs to assistanceNeedsChecked frm patients api
    if (!editBookedRide && !editRide && !editRideNotesForm && isEmpty(assistanceNeeds)) {
      assistanceNeeds = clone(assistanceNeedsChecked);
    }
    state.showRequestRide = false;
    if (this.currentRideIndex > -1) {
      customFieldVal = bookingData?.rides?.[index]?.rideCustomFields ?? '';
      transportType = bookingData?.rides?.[index]?.transportType ?? 0;
      transportMode = bookingData?.rides?.[index]?.mode === PUBLIC ? 0 : 1;
      additionalNotes =
        bookingData.rides[index].additionalNotes || patientDetails.otherDetails;

      // we dont want to do this while doing a new booking
      if (editBookedRide || editRide || editRideNotesForm) {
        assistanceNeeds = bookingData?.rides?.[index]?.assistanceNeeds ?? {};
      } else if (index > 0) {
        // prepopulate transport type with previous ride's transport type
        transportType = bookingData?.rides?.[index - 1]?.transportMode ?? PRIVATE;
      } else {
        // if there is a mobility type, set it to that instead
        transportType = !isNil(mobilityType) ? mobilityType : 0;
      }

      if (index === MULTIPLE_LEG_LIMIT - 1) {
        state.showRequestRide = true;
        if (bookingData.bookingType !== 'multileg') {
          state.showCheckBoxes = true;
        }
      }
    } else if (transportType !== LYFT_VEHICLE_ID || transportType !== UBER_VEHICLE_ID) {
      state.showCheckBoxes = true;
      state.showAssistanceNeeds = true;
    } else {
      state.showCheckBoxes = false;
      state.showAssistanceNeeds = false;
    }

    if (editRide || editRideNotesForm || bookingData?.bookingType !== 'multileg') {
      state.showRequestRide = true;
    }

    if (editRide) {
      state.showCheckBoxes = false;
    }

    let hasCustomField = false;
    let hasTransport = false;

    const rideData =
      this.currentRideIndex > -1 ? cloneDeep(bookingData.rides[index]) : {};

    const vehicleTypes = this.getTransportationType();
    const selectedTransport = find(vehicleTypes, { id: transportType });

    if (
      transportType !== 0 &&
      (memberDefaultMode === 1 || bookingData.mode === PRIVATE)
    ) {
      if (!isNil(selectedTransport)) {
        selectedTransport.activeSubTypes = [];
        if (!isEmpty(selectedTransport.subTypes)) {
          selectedTransport.activeSubTypes = Object.keys(selectedTransport.subTypes).map(
            key => ({
              name: selectedTransport.subTypes[key].name,
              id: selectedTransport.subTypes[key].id
            })
          );
          // check to see if we have a selected subtransport
          state.transportSubType =
            bookingData?.transportSubType ?? bookingData?.mobilitySubType ?? 0;
          state.transportSubTypeName = bookingData?.transportSubTypeName ?? '';
        }

        if (this.currentRideIndex > -1) {
          state.transportSubType =
            bookingData?.rides?.[index - 1]?.transportSubType ??
            bookingData?.mobilitySubType ??
            0;

          state.transportSubTypeName =
            bookingData?.rides?.[index - 1]?.transportSubTypeName ?? '';
          rideData.transportTypeName = selectedTransport.name;
          updateRideData(rideData, index);
        } else {
          state.transportSubType =
            bookingData?.transportSubType ?? bookingData?.mobilitySubType ?? 0;
          state.transportSubTypeName = bookingData?.transportSubTypeName ?? '';
          updateBookingData({ transportTypeName: selectedTransport.name });
        }
        state = merge(state, {
          transportMode,
          transportType,
          transportTypeName: selectedTransport.name,
          transportTypeError: false,
          activeSubTypes: selectedTransport.activeSubTypes
        });
        hasTransport = true;
      }
      /**
       * -if there is no selected transport member and the memberDefaultMode is public,
       * preselect the public transit vehicle
       */
    } else if (memberDefaultMode === 0 && bookingData.mode !== PRIVATE) {
      const transportMode =
        (this.props?.bookingData?.transportMode ?? 'Public') === 'Public' ? 0 : 1;

      this.setState({ selectedMode: transportMode, transportMode });

      /**
       *
       * I apologize for what I'm about to do.
       *
       * Apology rejected - Burt
       *
       */
      if (!isNil(selectedTransport)) {
        selectedTransport.activeSubTypes = [];
        if (!isEmpty(selectedTransport.subTypes)) {
          selectedTransport.activeSubTypes = Object.keys(selectedTransport.subTypes).map(
            key => ({
              name: selectedTransport.subTypes[key].name,
              id: selectedTransport.subTypes[key].id
            })
          );
          // check to see fi we have a selected subtransport
          state.transportSubType = get(
            bookingData,
            'transportSubType',
            get(bookingData, 'mobilitySubType', 0)
          );
          state.transportSubTypeName = get(bookingData, 'transportSubTypeName', '');
        }

        if (this.currentRideIndex > -1) {
          state.transportSubType = get(
            bookingData,
            `rides.${index - 1}.transportSubType`,
            get(bookingData, 'mobilitySubType', 0)
          );
          state.transportSubTypeName = get(
            bookingData,
            `rides.${index - 1}.transportSubTypeName`,
            ''
          );
          rideData.transportTypeName = selectedTransport.name;
          updateRideData(rideData, index);
        } else {
          state.transportSubType = get(
            bookingData,
            'transportSubType',
            get(bookingData, 'mobilitySubType', 0)
          );
          state.transportSubTypeName = get(bookingData, 'transportSubTypeName', '');
          updateBookingData({ transportTypeName: selectedTransport.name });
        }
        state = merge(state, {
          transportType: selectedTransport.id,
          transportTypeName: selectedTransport.name,
          transportTypeError: false,
          activeSubTypes: selectedTransport.activeSubTypes
        });
        hasTransport = true;
      }
    }

    state.additionalNotes = additionalNotes;

    if (!isEmpty(assistanceNeeds)) {
      state.assistanceNeeds = assistanceNeeds;
    }

    // for multi legged trips, no need to enter this info multiple times
    if (has(bookingData, 'payWithCard')) {
      state.payWithCard = bookingData.payWithCard;
    }

    if (has(bookingData, 'repeatAppointmentData')) {
      state.repeatApptsChecked = true;
      state.showCheckBoxes = true;
    }

    if (customFieldVal !== '') {
      forEach(customFieldVal, (value, key) => {
        state[`ride_custom_fields[${key}]`] = value;
      });
      hasCustomField = true;
    }

    // we want to check to see if custom field or transportation is there, then we run checkFormsValid which
    // will enable button if the fields are already filled out.
    if (!isEmpty(state) && (hasCustomField || hasTransport)) {
      this.setState(state, () => {
        this.checkFormsValid(hasCustomField);
      });
    } else {
      this.setState({
        showCheckBoxes: state.showCheckBoxes,
        showRequestRide: state.showRequestRide,
        payWithCard: state.payWithCard,
        repeatApptsChecked: state.repeatApptsChecked,
        showCheckBoxPay: state.showCheckBoxPay,
        additionalNotes: state.additionalNotes,
        assistanceNeeds: get(state, 'assistanceNeeds', {})
      });
    }
  }

  componentDidUpdate(prevProps) {
    const prevBookingData = prevProps.bookingData;
    const bookingData = this.props.bookingData;

    if (
      has(bookingData, 'currentRideIndex') &&
      bookingData.currentRideIndex !== prevBookingData.currentRideIndex
    ) {
      this.currentRideIndex = bookingData.currentRideIndex;
    }
  }

  /**
   * Creates an object of the available dropdown options
   *
   * If edit mode and ride is private, remove the public option.
   * They should not be able to update a ride from private to public.
   * @returns {object} Options to use when rendering dropdown
   */
  getDropdownOptions = () => {
    const { editRide } = this.props;

    let options = [
      { name: PUBLIC, id: 0 },
      { name: PRIVATE, id: 1 }
    ];
    if (editRide) {
      options = options.filter(({ name }) => {
        return name === PRIVATE;
      });
    }
    return options;
  };

  /**
   * Retrieve a formatted list of vehicle types to populate dropdown
   * @param {array} vehicleTypes The array of available vehicle types
   * @returns {array} Formatted array
   */
  getDropdownItems = vehicleTypes => {
    const { user, bookingData } = this.props;
    const publicVehicleTypes = user?.publicVehicleTypes ?? [];
    const transportMode = this.state.transportMode;

    const allVehicleTypes = Object.keys(user.vehicleTypes).map(id => {
      const vt = user.vehicleTypes[id];
      return {
        ...vt,
        id: vt.id,
        name: vt.modelName,
        subTypes: vt.vehicle_sub_types
      };
    });

    if (transportMode === 0 && bookingData?.bookingType !== 'multileg') {
      const availablePublicVehicleTypeIds = publicVehicleTypes.map(({ id }) => id);
      return allVehicleTypes.filter(({ id }) =>
        availablePublicVehicleTypeIds.includes(id)
      );
    }
    const optionsList = compact(
      vehicleTypes.map(vehicleType => {
        if (
          this.state.transportMode === 0 &&
          vehicleType.name.indexOf('Lyft') > -1 &&
          bookingData?.bookingType !== 'multileg'
        ) {
          return null;
        }
        return vehicleType;
      })
    );
    return optionsList;
  };

  /**
   * Easy lookup for user role
   * @returns {string} The user role
   */
  getUserRole = () => this.props?.userData?.role ?? '';

  /**
   * Easy lookup for hospital id
   * @returns {string} The hospital id
   */
  getHospitalId = () => this.props?.bookingData?.hospitalId ?? '';

  /**
   * Returns button class name for requestRideButton
   * @returns {string} The button class
   */
  getButtonClass = () =>
    this.state.isValidated === true
      ? 'editOrRequestRideButton active'
      : 'editOrRequestRideButton';

  /**
   * Easy lookup for ride route data
   * @returns {object} the route data
   */
  getPublicRouteData = () =>
    this.props.rideDetails?.ride?.data?.ride?.publicData?.[0]?.route_data;

  /**
   * Returns class name for rideNotesBottom
   * @returns {string} The class name
   */
  getRideNotesClass = () =>
    this.state.repeatApptsChecked === true
      ? 'rideNotesBottom'
      : 'rideNotesBottom noMargin';

  /**
   * Flag to control if recurring rides are enabled
   * @returns {boolean} T/F flag
   */
  getRecurringRidesEnabled = () => {
    const { bookingData, user } = this.props;

    let enabled = true;

    if (user.features[DISABLE_RECURRING_RIDES]) {
      enabled = false;
    }

    // Override the above disable?
    if (
      this.hasPublicTransit() &&
      this.showPublicTransitOption() &&
      this.state?.transportMode === 0
    ) {
      enabled = true;
    }

    if (
      bookingData.bookingType === 'multileg' ||
      (bookingData.pastRide &&
        moment(bookingData.selectedDate).isSameOrAfter(moment().format('MM/DD/YYYY')))
    ) {
      enabled = false;
    }

    return enabled;
  };

  /**
   * Attempts to identify the required vehicle type for the ride
   * @returns {number|null} returns vehicleType if found
   */
  getReqVehicle = () => {
    const { user, ride } = this.props;

    return find(user.vehicleTypes, type => {
      return ride ? type.id === parseInt(ride?.reqVehicleType) : null;
    });
  };

  /**
   * Determines the selected transport using either props.bookingData
   * or state.transportType
   * @param {*} vehicleTypes
   * @returns {object} Returns object with keys _id_ and _name_
   */
  getSelectedTransport = vehicleTypes => {
    const { transportType, transportTypeName } = this.state;

    let selection = {};
    if (this.props.bookingData?.transportType === 0) {
      selection = find(vehicleTypes, { id: this.props.bookingData.transportType });
    } else if (transportType !== 0) {
      selection = { id: transportType, name: transportTypeName };
    }

    return selection;
  };

  /**
   * Flag indicating if the dropdown should be shown
   * @returns {boolean} T/F
   */
  showDropdown = () =>
    !(
      this.state.editRide &&
      (this.props.bookingData?.reqVehicleType ||
        this.state.transportType === UBER_VEHICLE_ID)
    );

  /**
   * Flag to indicate if repeat appointments should be
   * displayed
   * @returns {boolean} T/F
   */
  showRepeatAppts = () => {
    const showRequestRide = this.state.showRequestRide;
    const legIndex = this.props.legIndex;

    // for multiple appts only show repeat appts info in last ride note
    if (
      !showRequestRide ||
      (this.currentRideIndex > -1 && legIndex > -1 && legIndex !== this.currentRideIndex)
    ) {
      return false;
    }

    return this.state.repeatApptsChecked && !this.props?.editRide;
  };

  /**
   * Flag indicating if ride is a past ride
   * @returns {boolean} T/F
   */
  isPastRide = () =>
    this.props.bookingData?.pastRide || this.props.bookingData?.isPastRide;

  /**
   * Flag to indicate that the ride is a public ride type
   * @returns {boolean} T if public ride
   */
  isPublicRide = () =>
    this.props.bookingData?.mode === PUBLIC &&
    this.props.bookingData?.id &&
    this.hasPublicTransit();

  /**
   * handles text input
   * @param {object} e - event
   * @param {integer} custom - custom field
   * @returns {undefined} returns nothing
   */
  changeText = ({ target }, custom = 0) => {
    const id = target.id;
    const val = target.value;
    const stateObj = { [id]: val };

    if (custom !== 0) {
      stateObj[`${custom}_invalid`] = val === '';
      this.setState(stateObj, this.checkFormsValid);
    } else {
      this.setState(stateObj);
    }
  };

  /**
   * Event handler for user transport mode selction
   * @param {event} e The event
   * @returns {void}
   */
  selectTransportMode = e => {
    const transportMode = e.target.innerHTML === 'Public' ? 0 : 1;
    this.setState({ transportMode });

    if (e.target.innerHTML === 'Public') {
      this.setState({ assistanceNeeds: {}, payWithCard: false });
    }
  };

  /**
   * Event handler for selecting transportation
   * @param {event} e - event
   * @return {void}
   */
  selectTransportType = e => {
    const bookingData = this.props.bookingData;
    const vehicleTypes = this.getTransportationType();
    const id = parseInt(e.target.getAttribute('id'), 10);
    const vehicle = e.target.innerHTML;
    const transportType = {
      transportType: id,
      transportTypeName: vehicle,
      transportTypeError: false
    };
    const vehicleObj = find(vehicleTypes, { id });

    if (typeof vehicleObj !== 'undefined' && !isEmpty(vehicleObj.subTypes)) {
      transportType.activeSubTypes = Object.keys(vehicleObj.subTypes).map(key => {
        return {
          name: vehicleObj.subTypes[key].name,
          id: vehicleObj.subTypes[key].id
        };
      });
    } else {
      transportType.activeSubTypes = [];
      transportType.transportSubTypeError = false;
    }
    transportType.transportSubType = 0;
    transportType.transportSubTypeName = '';
    if (!has(bookingData, 'currentRideIndex')) {
      transportType.showCheckBoxes = true;

      if (id === LYFT_VEHICLE_ID || id === UBER_VEHICLE_ID) {
        this.showAssistanceNeeds = false;
      } else {
        this.showAssistanceNeeds = true;
      }
    }

    this.setState(transportType, this.checkFormsValid);
  };

  /**
   * Event handler for select transportation subtype
   * @param {event} e - event
   * @return {void}
   */
  selectTransportSubType = e => {
    const id = parseInt(e.target.getAttribute('id'), 10);
    const vehicle = e.target.innerHTML;
    const transportType = {
      transportSubType: id,
      transportSubTypeName: vehicle,
      transportSubTypeError: false
    };
    this.setState(transportType);
  };

  /**
   * Show repeat appointment component
   * @param {event} e - synthetic event
   * @return {void}
   */
  toggleRepeatAppt = () => {
    let repeatApptsChecked;
    this.setState(prevState => {
      repeatApptsChecked = !prevState.repeatApptsChecked;
      return {
        repeatApptsChecked
      };
    });
    if (!repeatApptsChecked) {
      const { bookingData } = this.props;
      unset(bookingData, 'repeatAppointmentData');
      this.props.updateBookingData(bookingData);
    }
  };

  /**
   * Handle Ride Notes submissions, call BookRides callbacks to request ride booking
   * @param {event} e - synthetic event
   * @param {boolean} submitRequest - book or don't book
   * @return {void}
   */
  requestRide = (e, submitRequest = true) => {
    e.preventDefault();

    const {
      legIndex,
      userData,
      bookingData: _bookingData,
      updateBookingData,
      handleRequestBooking,
      handleEditNotes,
      editRide,
      editRideNotesForm
    } = this.props;

    if (!this.checkFormsValid()) return false;

    const state = cloneDeep(this.state);
    const index = legIndex > -1 ? legIndex : this.currentRideIndex;

    const rideCustomFields = this.getRideCustomFields(
      userData.role,
      _bookingData.hospitalId
    );

    if (rideCustomFields.length > 0) {
      const rideCust = {};

      for (let i = 0; i < rideCustomFields.length; i++) {
        const rideCustomField = rideCustomFields[i];
        const rideId = rideCustomField.id;

        if (!this[`ride_custom_fields[${rideCustomField.id}]`]) {
          rideCust[rideId] = this.state[`ride_custom_fields[${rideCustomField.id}]`];
        }
      }

      state.rideCustomFields = rideCust;
    }

    this.setState(state);

    let rideData = {};
    let bookingData = cloneDeep(_bookingData);

    const transportData = pick(state, [
      'transportType',
      'transportTypeName',
      'transportTypeError',
      'rideCustomFields',
      'additionalNotes',
      'transportSubTypeName',
      'transportSubType',
      'assistanceNeeds'
    ]);

    if (state.transportMode === 0) {
      transportData.transportMode = 'Public';
      transportData.publicTransitPreferences = state.publicTransitPreferences;
      transportData.publicTransitRoutePreferences = state.publicTransitRoutePreferences;
    } else {
      transportData.transportMode = PRIVATE;
    }

    if (this.currentRideIndex > -1 && bookingData.bookingType === 'multileg') {
      rideData = cloneDeep(this.props.bookingData.rides[index]);
      rideData.legCompleted = true;
      rideData = assign(rideData, transportData);
      bookingData.rides[index] = rideData;
    } else {
      bookingData = assign(bookingData, transportData);
    }

    bookingData.payWithCard = this.state.payWithCard;

    if (submitRequest) {
      bookingData.status = 'Processing';

      if (this.currentRideIndex > -1) {
        bookingData.rides[index] = rideData;
        if (this.continueClicked) {
          bookingData.tripCompleted = true;
        }
      }
      updateBookingData(bookingData);
      if (!editRide && !editRideNotesForm) {
        handleRequestBooking(bookingData);
      } else {
        handleEditNotes(bookingData);
      }
    }

    return bookingData;
  };

  /**
   * handles toggling pay with credit card checkbox
   * @return {void}
   */
  togglePayWithCard = () => {
    this.setState(
      prevState => ({
        payWithCard: !prevState.payWithCard
      }),
      this.checkFormsValid
    );
  };

  /**
   * for multi legged rides, clicking this button restarts the booking process for the next leg.
   * @param {event} e - synthetic event
   * @return {void}
   */
  addAnotherTrip = e => {
    e.preventDefault();
    const valid = this.checkFormsValid();
    if (valid) {
      const bookingData = this.requestRide(e, false);
      this.props.addAnotherTrip(bookingData);
    }
  };

  /**
   * button that shows request ride booking for multiple leg ride booking
   * @param {event} e - synthetic event
   * @return {void}
   */
  continue = e => {
    e.preventDefault();
    const valid = this.checkFormsValid();
    if (valid) {
      this.continueClicked = true;
      this.setState({
        showRequestRide: true
      });
    }
  };

  /**
   * Flag to indicate if private pay option should be rendered
   * @param {object} userData
   * @param {boolean} editRide
   * @returns {boolean} T/F
   */
  showPrivatePayOption(userData, editRide) {
    return (
      userData?.passengerRate !== 1 && userData?.showPrivatePay === true && !editRide
    );
  }

  /**
   * Retrieve the assistance needs string
   * @returns {string} String to display
   */
  getAssistanceNeeds = () => {
    const assistance_needs = this.props.ride?.ride_options?.assistanceNeeds;

    if (isEmpty(assistance_needs)) return '';

    let assistanceNeedsText = '';

    Object.keys(assistance_needs).map(key => {
      assistanceNeedsText = assistanceNeedsText
        ? `${assistanceNeedsText}, ${assistance_needs[key]}`
        : assistance_needs[key];
    });

    return assistanceNeedsText;
  };

  /**
   * client side validation
   * @param {boolean} checkRideCustomField - check custom ride field for validation
   * @returns {boolean} returns true if it validates, false if it doesn't
   */
  checkFormsValid(checkRideCustomField = true) {
    let status = true;
    const stateObj = {};
    if (this.props.rideCustomFields.length === 0) {
      status = true;
    } else if (checkRideCustomField) {
      const rideCustomFields = this.getRideCustomFields(
        this.props.userData.role,
        this.props.bookingData.hospitalId
      );

      if (rideCustomFields.length > 0) {
        for (let i = 0; i < rideCustomFields.length; i++) {
          if (
            typeof this.state[`ride_custom_fields[${rideCustomFields[i].id}]`] ===
              'undefined' ||
            this.state[`ride_custom_fields[${rideCustomFields[i].id}]`] === ''
          ) {
            status = false;
            stateObj[`${rideCustomFields[i].id}_invalid`] = true;
          }
        }
      }
    }

    if (this.state.transportType === 0) {
      status = false;
      stateObj.transportTypeError = true;
    }

    stateObj.isValidated = status;
    this.setState(stateObj);

    return status;
  }

  /**
   * return ride custom fields
   * @param {string} userRole - user role
   * @param {integer} hospitalId - hospital id
   * @return {array} - returns custom fields
   */
  getRideCustomFields(userRole, hospitalId) {
    if (userRole === 'HospitalOwner' || userRole === 'HospitalNetworkManager') {
      if (!isNil(this.props.rideCustomFields[hospitalId])) {
        return this.props.rideCustomFields[hospitalId];
      }
      return [];
    }
    return this.props.rideCustomFields;
  }

  /**
   * return transportation type fields
   * @param {boolean} removeLyft - remove lyft or not
   * @return {array} - returns custom fields
   */
  getTransportationType(removeLyft = false) {
    const { userData, bookingData } = this.props;
    let vehicleTypes = cloneDeep(this.props.vehicleTypes);
    if (isNetworkRole(userData.role) && !Array.isArray(vehicleTypes)) {
      vehicleTypes = vehicleTypes[bookingData.hospitalId];
    }
    if (removeLyft) {
      vehicleTypes = this.removeLyftRide(vehicleTypes);
    }
    if (this.state.hasWillCallLeg) {
      //Remove public transit option for will calls
      vehicleTypes = compact(
        vehicleTypes.map(vehicleType => {
          if (vehicleType.id === 30) {
            return null;
          }
          return vehicleType;
        })
      );
    }
    return vehicleTypes;
  }

  /**
   * Filters public transit values from vehicleTypes array
   * @param {array} vehicleTypes
   * @returns {array} The filtered array
   */
  removePublicTransit(vehicleTypes) {
    const result = vehicleTypes.filter(
      vehicleType => vehicleType.name.toLowerCase() !== 'public transit'
    );
    return result;
  }

  /**
   * remove lyft ride from dropdown when you're editing an nemt ride
   * @param {array} vehicleTypes - array of vehicle types
   * @return {array} return vehicleTypes with lyft ride removed
   */
  removeLyftRide(vehicleTypes) {
    if (typeof vehicleTypes === 'undefined') {
      return [];
    }
    return vehicleTypes.filter(({ id }) => !isRideShareVehicleId(id));
  }

  render() {
    const {
      bookingData,
      editRide,
      editRideNotesForm,
      closeComponent,
      hideArrow,
      strKey,
      assistanceNeeds
    } = this.props;

    const {
      repeatApptsChecked,
      showRequestRide,
      transportTypeError,
      activeSubTypes,
      additionalNotes,
      transportMode,
      transportSubTypeError,
      showCheckBoxPay,
      payWithCard,
      assistanceNeedsError,
      showCheckBoxes
    } = this.state;

    const selectedMode = {};
    const {
      defaultTransportTypeLabel,
      defaultTransportSubTypeLabel,
      rideNotesPlaceholder,
      rideNoteLabel,
      invalidInputLabel,
      repeatRideLabel,
      editRideLabel,
      requestRideLabel,
      addAnotherTripLabel,
      payWithCardLabel
    } = COMPONENT_STRINGS[strKey];

    const userRole = this.getUserRole();
    const hospitalId = this.getHospitalId();
    const reqVehicle = this.getReqVehicle();
    const publicRouteData = this.getPublicRouteData();
    // if ride was booked as an nemt and you're editing, you're not allowed to change to a lyft ride
    const vehicleTypes = this.getTransportationType(this.isPastRide());
    const dropdownItems = this.getDropdownItems(vehicleTypes);
    const selectedTransport = this.getSelectedTransport(vehicleTypes);
    const rideCustomFields = this.getRideCustomFields(userRole, hospitalId);
    const recurringRidesEnabled = this.getRecurringRidesEnabled();
    const assistanceNeedsString = this.getAssistanceNeeds();

    const classButton = this.getButtonClass();
    const classRideNotesBottom = this.getRideNotesClass();

    const showDropdown = this.showDropdown();
    const showRepeatAppts = this.showRepeatAppts();

    if (this.isPublicRide()) {
      return (
        <div>
          <div className="publicEditScheduled">
            <div className="close cursor" onClick={closeComponent}>
              <SvgClose />
            </div>
            {get(this.props.ride, 'mode', PRIVATE) !== PRIVATE ||
            get(this.props, 'bookingData.transportMode', PRIVATE) !== PRIVATE ? (
                <div>
                  <p
                    className="name transportType"
                    style={assistanceNeedsString ? { borderBottom: 'none' } : {}}
                  >
                  PUBLIC [{reqVehicle.nickName}]
                  </p>
                  {get(this.props.ride, 'transportSubTypeName', '') !== '' ? (
                    <p className="name transportType">
                      {this.props.ride.transportSubTypeName}
                    </p>
                  ) : null}
                  {/* TODO:UBER */}
                  {assistanceNeedsString &&
                this.props.ride.transportType !== LYFT_VEHICLE_ID ? (
                      <p className="name transportType secondaryTransportType">
                        {assistanceNeedsString}
                      </p>
                    ) : null}
                </div>
              ) : (
                <p className="name transportType">
                  {get(this.props.ride, 'mode', PRIVATE) !== PRIVATE
                    ? `Public [${reqVehicle.nickName}]`
                    : this.props.ride.transportTypeName}
                  {!isNil(this.props.ride.originalReqVehicleType) &&
                this.props.ride.originalReqVehicleType !==
                  this.props.ride.reqVehicleType ? (
                      <i> (Edited)</i>
                    ) : null}
                </p>
              )}
            {get(this.props.ride, 'transportSubTypeName', '') !== '' ? (
              <p className="name transportType">{this.props.ride.transportSubTypeName}</p>
            ) : null}
            <div>
              {this.props.bookingData.mode === 'Public' && publicRouteData && (
                <div
                  className="TransportOptions"
                  style={{
                    borderTop: '1px solid #e0e0e0',
                    borderBottom: '1px solid #e0e0e0',
                    padding: '12px',
                    cursor: 'unset'
                  }}
                >
                  <PublicRoute
                    leg={0}
                    key={`rd-0`}
                    fare={get(publicRouteData, 'fare.text', '')}
                    transit={null}
                    selected={0}
                    hideLeg={true}
                    routeDataKey={0}
                    defaultOpen={true}
                    handler={() => {}}
                    routeData={publicRouteData}
                  />
                </div>
              )}
            </div>
            <PublicConfirmation
              confirmPublic={this.props.confirmPublic}
              ride={this.props.ride}
              trip={this.props.trip}
              bookingData={this.props.bookingData}
              selectedLegs={this.props.publicSelectedLegs}
            />
          </div>
        </div>
      );
    }

    return (
      <form className="rideNotesCont">
        <div className="rideNotesTop">
          {editRide || editRideNotesForm ? (
            <div className="close" onClick={closeComponent}>
              <SvgClose />
            </div>
          ) : null}
          {hideArrow === true ? <div className="arrow"></div> : null}
          {(this.hasPublicTransit() && this.showPublicTransitOption()) ||
          this.state.hasWillCallLeg ? (
              <DropDown
                dropDownCallback={this.selectTransportMode}
                items={this.getDropdownOptions()}
                placeholder={get(selectedMode, '', PRIVATE)}
                error={transportTypeError}
                customClassName="vehicleDropDown"
                id={this.state.transportMode}
                disabled={this.state.hasWillCallLeg}
              />
            ) : null}
          {showDropdown ? (
            <DropDown
              dropDownCallback={this.selectTransportType}
              items={dropdownItems}
              placeholder={get(selectedTransport, 'name', defaultTransportTypeLabel)}
              error={transportTypeError}
              customClassName="vehicleDropDown"
            />
          ) : null}
          {this.showVehicleSubType && activeSubTypes.length ? (
            <DropDown
              dropDownCallback={this.selectTransportSubType}
              items={activeSubTypes}
              placeholder={defaultTransportSubTypeLabel}
              error={transportSubTypeError}
              customClassName="vehicleDropDown"
              id={this.state.transportSubType}
            />
          ) : null}
          {/* TODO:UBER */}
          {this.state.transportMode &&
          this.showAssistanceNeeds &&
          !isRideShareVehicleId(this.state.transportType) &&
          assistanceNeeds.length ? (
              <MultiSelect
                dropDownCallback={assistanceNeeds => this.setState({ assistanceNeeds })}
                items={assistanceNeeds}
                placeholder="Assistance Needs"
                error={assistanceNeedsError}
                customClassName="vehicleDropDown"
                sendBackObject={true}
                defaultChecked={this.state.assistanceNeeds}
              />
            ) : null}
          <TextAreaField
            value={additionalNotes || ''}
            onChangeCallback={this.changeText}
            fieldName="additionalNotes"
            placeholder={rideNotesPlaceholder}
            label={rideNoteLabel}
          />
          {rideCustomFields.map((customField, key) => {
            const customFieldKey = `ride_custom_fields[${customField.id}]`;
            const customFieldVal = this?.state[customFieldKey] ?? '';
            const invalid = this.state?.[`${customField.id}_invalid`] ?? false;

            return (
              <div key={key}>
                <InputText
                  value={customFieldVal}
                  fieldName={customFieldKey}
                  label={customField.field_label_desc}
                  placeholder={customField.field_label_desc}
                  onChange={e => this.changeText(e, customField.id)}
                  error={invalid}
                  errorText={invalidInputLabel(customField.field_label)}
                  autoComplete="off"
                />
              </div>
            );
          })}
          {this.state.transportMode === 0 &&
            this.hasPublicTransit() &&
            this.showPublicTransitOption() && (
            <div className={'publicTransitPreferences'}>
              <div className={'publicTransitModePreferences'}>
                <p className="labelText">Prefer</p>
                {PUBLIC_TRANSIT_MODE_PREFERENCES.map((mode, i) => {
                  return (
                    <label key={i} className="rideTypes" htmlFor={mode.name}>
                      {!difference(
                        mode.values.sort(),
                        this.state.publicTransitPreferences.sort()
                      ).length ? (
                          <SvgCheckboxChecked className="svgCheck" />
                        ) : (
                          <SvgCheckbox className="svgCheck" />
                        )}

                      <input
                        type="checkbox"
                        id={mode.name}
                        name={mode.name}
                        defaultChecked={isEqual(
                          mode.values.sort(),
                          this.state.publicTransitPreferences.sort()
                        )}
                        onClick={e => {
                          e.preventDefault();
                          let publicTransitPreferences;
                          const preferenceIsSelected = !difference(
                            mode.values,
                            this.state.publicTransitPreferences
                          ).length;

                          if (preferenceIsSelected) {
                            publicTransitPreferences =
                                this.state.publicTransitPreferences.filter(
                                  preference => mode.values.indexOf(preference) === -1
                                );
                          } else {
                            publicTransitPreferences =
                                this.state.publicTransitPreferences;
                            publicTransitPreferences = publicTransitPreferences.concat(
                              mode.values
                            );
                          }
                          this.setState({ publicTransitPreferences });
                        }}
                        value={mode.values}
                        required
                      />

                      <span className="labelText">{mode.text}</span>
                    </label>
                  );
                })}
              </div>

              <div className="publicTransitRoutePreferences">
                <p className="labelText">Routes</p>
                {PUBLIC_TRANSIT_ROUTE_PREFERENCES.map((routePreference, i) => {
                  const preferenceIsSelected = !difference(
                    routePreference.values,
                    this.state.publicTransitRoutePreferences
                  ).length;

                  return (
                    <label key={i} className="rideTypes" htmlFor={routePreference.name}>
                      {preferenceIsSelected ? (
                        <SvgRadioSelected className="radioButton" />
                      ) : (
                        <SvgRadioUnselected className="radioButton" />
                      )}
                      <input
                        type="radio"
                        name={routePreference.name}
                        id={routePreference.name}
                        onChange={NOOP}
                        onClick={() => {
                          const publicTransitRoutePreferences = isEqual(
                            routePreference,
                            this.state.publicTransitRoutePreferences
                          )
                            ? []
                            : routePreference.values;
                          this.setState({ publicTransitRoutePreferences });
                        }}
                        value={routePreference.values}
                      />
                      <span className="labelText">{routePreference.text}</span>
                    </label>
                  );
                })}
              </div>
            </div>
          )}
          {transportMode && showCheckBoxPay && showRequestRide ? (
            <label className="repeatRideCheck" htmlFor="payWithCard">
              {payWithCard ? (
                <SvgCheckboxChecked className="svgCheck" />
              ) : (
                <SvgCheckbox className="svgCheck" />
              )}
              <input
                value="checkboxPayWithCard"
                type="checkbox"
                name="payWithCard"
                id="payWithCard"
                checked={!!payWithCard}
                onChange={this.togglePayWithCard}
              />
              <span className="repeatRideText">{payWithCardLabel}</span>
            </label>
          ) : null}

          {showCheckBoxes && showRequestRide && recurringRidesEnabled ? (
            <label className="repeatRideCheck" htmlFor="repeatAppointments">
              {repeatApptsChecked === true ? (
                <SvgCheckboxChecked className="svgCheck" />
              ) : (
                <SvgCheckbox className="svgCheck" />
              )}

              <input
                value="checkboxRepeatAppt"
                type="checkbox"
                name="repeatAppointments"
                id="repeatAppointments"
                onChange={this.toggleRepeatAppt}
                checked={!!repeatApptsChecked}
              />
              <span className="repeatRideText">
                {repeatApptsChecked === true
                  ? get(
                    bookingData,
                    'repeatAppointmentData.repeatApptText',
                    repeatRideLabel
                  )
                  : repeatRideLabel}
              </span>
            </label>
          ) : null}
        </div>
        {showRepeatAppts ? (
          <RepeatAppointments
            daySelected={bookingData.selectedDate}
            pastBookingDays={this.props.user.pastBookingDays}
            pastEditDays={this.props.user.pastEditDays}
          />
        ) : null}
        <div className={classRideNotesBottom}>
          {showRequestRide ? (
            <button className={classButton} onClick={this.requestRide}>
              {editRide || editRideNotesForm ? editRideLabel : requestRideLabel}
            </button>
          ) : (
            <div className="bookRideButtons clearfix">
              {this.currentRideIndex < MULTIPLE_LEG_LIMIT - 1 ? (
                <button className={classButton} onClick={this.addAnotherTrip}>
                  {addAnotherTripLabel}
                </button>
              ) : null}
              <button className={classButton} onClick={this.continue}>
                {requestRideLabel}
              </button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

RideNotes.defaultProps = {
  strKey: 'RideNotes',
  updateBookingData: () => {},
  updateRideData: () => {},
  bookingData: {},
  rideCustomFields: [],
  user: {},
  userData: {},
  hideArrow: false,
  handleRequestBooking: () => {},
  handleEditNotes: () => {},
  editRideNotesForm: false, // edit form during booking process
  editRide: false, // edit ride that is already booked
  editBookedRide: false,
  closeComponent: () => {},
  rideDetails: {},
  vehicleTypes: [],
  addAnotherTrip: () => {},
  legIndex: -1,
  assistanceNeeds: [],
  assistanceNeedsChecked: {},
  patientDetails: {}
};

RideNotes.propTypes = {
  strKey: PropTypes.string,
  updateBookingData: PropTypes.func,
  updateRideData: PropTypes.func,
  bookingData: PropTypes.object,
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rideDetails: PropTypes.object,
  user: PropTypes.object,
  userData: PropTypes.object,
  hideArrow: PropTypes.bool,
  handleRequestBooking: PropTypes.func,
  handleEditNotes: PropTypes.func,
  editRideNotesForm: PropTypes.bool,
  editRide: PropTypes.bool,
  editBookedRide: PropTypes.bool,
  vehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  addAnotherTrip: PropTypes.func,
  legIndex: PropTypes.number,
  closeComponent: PropTypes.func,
  assistanceNeeds: PropTypes.array,
  assistanceNeedsChecked: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  patientDetails: PropTypes.object
};

const mapStateToProps = state => ({
  user: state.user,
  userData: state.user.userData,
  rideDetails: state.rideDetails,
  bookingData: state.bookingData,
  rideCustomFields: state.user.rideCustomFields,
  assistanceNeeds: state?.patients?.assistanceNeeds ?? [],
  assistanceNeedsChecked: state?.patients?.assistanceNeedsChecked ?? [],
  patientDetails: state?.patients?.patientDetails ?? []
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData: bookRideData => updateBookingData(bookRideData),
      updateRideData: (rideData, index) => updateRideData(rideData, index)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RideNotes);
