import React, { useEffect, useReducer, useRef } from 'react';
import { Button, Input, Is, Section } from '@SRHealth/frontend-lib';
import { useAppDispatch, useAppSelector } from '~/Modules';
import {
  completeFundingSourceThunk,
  RIDE_SECTION_ORDER,
  selectIsActive,
  selectIsBlocked,
  selectRideBenefits
} from '~/Modules/rideBooking';
import useModelSelector from '~/hooks/useModelSelector';
import Loader from '~/Pages/RideBooking/Loader';
import { NOOP } from '~/utilities/helperFunctions';
import { getHospitalCustomFields, scrollToFooter } from './FundingSource.utils';
import HealthPlan from './subcomponents/HealthPlan';
import TripQuestions from './subcomponents/TripQuestions';
import Summary from './subcomponents/Summary';
import { selectFacilities } from '~/Modules/user';
import { selectTreatments } from '~/Modules/patients';
import { genericCancelBookingHandler } from '../../RideBooking.utils';

type FundingSourceState = {
  isFocused: boolean;
  isLoading: boolean;
};

type ReducerAction =
  | { type: 'focusin' }
  | { type: 'focusout' }
  | { type: 'loading'; isLoading: boolean };

const createInitialState = (): FundingSourceState => ({
  isFocused: false,
  isLoading: false
});

const reducer: React.Reducer<FundingSourceState, ReducerAction> = (state, action) => {
  switch (action.type) {
    case 'focusin':
      return state.isFocused ? state : { ...state, isFocused: true };
    case 'focusout':
      return !state.isFocused ? state : { ...state, isFocused: false };
    case 'loading':
      return { ...state, isLoading: action.isLoading };
  }
};

const FundingSource = () => {
  const appDispatch = useAppDispatch();

  const footerRef = useRef<HTMLDivElement>(null);
  const { model } = useModelSelector(s => s.rideBooking.fundingSource);
  const [state, localDispatch] = useReducer(
    reducer,
    createInitialState(),
    createInitialState
  );

  /** Retrieve data from the Redux Store. Note for Firefox users:
   * The React dev tools currently have a bug preventing component lookup
   * if you are reading from the Redux store more than once per component.
   * The fix is released for Chrome but has not been for Firefox.
   * See [Link](https://github.com/facebook/react/issues/28960) */
  const userStore = useAppSelector(s => s.user);
  const benefitsRecord = useAppSelector(s => selectRideBenefits(s));
  const isActive = useAppSelector(s => selectIsActive(s, 'funding-source'));

  /** Retrieve specific data for the components and format it. */
  const facilities = useAppSelector(s => selectFacilities(s));
  const treatments = useAppSelector(s => selectTreatments(s));

  const hospitalCustomFields = getHospitalCustomFields(userStore, model.facilityId);

  const [isBlocked, blockType] = useAppSelector(s => selectIsBlocked(s));

  /** Validate and commit any outstanding changes to the model. */
  const handleSave = () => {
    if (!model.isDirty && Object.keys(model.ruleErrors).length) return;

    localDispatch({ type: 'loading', isLoading: true });

    appDispatch(completeFundingSourceThunk())
      .unwrap()
      .catch(NOOP)
      .finally(() => localDispatch({ type: 'loading', isLoading: false }));
  };

  /**
   * The event handler attached to the RBF parent element. It detects if the user
   * clicks anywhere inside of the RBF element and then determines if the target is
   * within Funding Source. If it is, it triggers the focusin event. Otherwise it
   * triggers the focusout event.
   *
   * **Note:** This handler is only refreshed when the formRef changes. Logging state
   * will display data at the time that the handler was created. It will potentially be
   * outdated.
   */
  const handleFocus = (e: PointerEvent) => {
    const newTarget = e.target;
    const oldTarget = document.activeElement;
    const fundingSrcElement = document.getElementById('funding-source')!;

    if (newTarget === oldTarget) return;

    if (fundingSrcElement.contains(newTarget as Node)) {
      localDispatch({ type: 'focusin' });
    } else if (fundingSrcElement.contains(oldTarget as Node)) {
      localDispatch({ type: 'focusout' });
    }
  };

  /** Attach the focus event listener to the RBF element so that we can determine
   * when a user is actively editing the section. */
  useEffect(() => {
    const rbfElement = document.querySelector('[data-testid="rbf"]');

    if (!rbfElement) {
      throw Error(
        'The data-testid attribute has been altered or removed on the RideBooking component.'
      );
    }

    rbfElement.addEventListener('pointerdown', handleFocus);
    return () => rbfElement.removeEventListener('pointerdown', handleFocus);
  }, []);

  /** Scroll to the footer if facility has been selected. */
  useEffect(() => {
    if (model.facilityId && state.isFocused) scrollToFooter(footerRef);
  }, [model.facilityId]);

  /** Scroll to the footer if treatment has been selected. */
  useEffect(() => {
    if (model.treatmentId && state.isFocused) scrollToFooter(footerRef);
  }, [model.treatmentId]);

  return (
    <Section id="funding-source" icon="Healthplan" label="Funding Source">
      <HealthPlan benefitsRecord={benefitsRecord} />

      <Loader isLoading={state.isLoading} />
      {isActive ? (
        <>
          {facilities.length > 1 && (
            <div
              className="flex flex-col justify-center items-center m-auto"
              style={{ width: '320px', marginBottom: '-18px' }}
            >
              <Input
                inputId={0}
                type="select"
                name="facility"
                label="Facility"
                placeholder="Select One"
                className="w-full p-[8px]"
                required
                options={facilities}
                value={model.facilityId}
                error={'facilityId' in model.ruleErrors}
                onChange={(_id, v: (typeof model)['facilityId']) => {
                  model.facilityId = v;
                }}
              />
            </div>
          )}

          {!Is.Undefined(model.facilityId) && (
            <TripQuestions
              model={model}
              treatments={treatments}
              complianceFields={userStore.complianceInfo}
              dependentFields={userStore.dependentFields}
              hospitalCustomFields={hospitalCustomFields}
              isBlocked={isBlocked}
              blockType={blockType}
            />
          )}

          <Section.Footer>
            <div ref={footerRef} className="flex flex-row items-center justify-center">
              {isBlocked && (
                <Button
                  alt
                  label="Cancel Booking"
                  onClick={genericCancelBookingHandler}
                />
              )}
              {blockType !== 'Hard' && (
                <Button
                  label={blockType === 'Soft' ? 'Save & Allow' : 'Save & Continue'}
                  onClick={handleSave}
                />
              )}
            </div>
          </Section.Footer>
        </>
      ) : (
        <Summary
          modelData={model.toJSON()}
          treatments={treatments}
          complianceFields={userStore.complianceInfo}
          dependentFields={userStore.dependentFields}
          hospitalCustomFields={hospitalCustomFields}
        />
      )}
    </Section>
  );
};

FundingSource.sectionIndex = RIDE_SECTION_ORDER['funding-source'];

export { FundingSource };
