import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import ReactLoading from 'react-loading';

const SUBPLAN_FIELD = 'HealthSubPlan';

class ImporterVerify extends Component {
  constructor(props) {
    super(props);
    const stepData = props.step.data || {};
    this.state = {
      fieldMapping: {},
      errorMessages: [],
      selectedTemplateId: stepData.selectedTemplateId || 0,
      templateName: stepData.templateName || ''
    };
  }

  componentDidMount() {
    const fieldMapping = _.get(this.props, 'step.data.fieldMapping', {});
    if (_.isEmpty(fieldMapping)) {
      this.initializeFieldMapping();
    } else {
      this.setState({ fieldMapping });
    }
  }

  componentDidUpdate(prevProps) {
    const jobId = _.get(this.props, 'job.importerJobID', 0);
    const prevJobId = _.get(prevProps, 'job.importerJobID', 0);
    if (jobId !== prevJobId) {
      const fieldMapping = _.get(this.props, 'step.data.fieldMapping', {});
      if (_.isEmpty(fieldMapping)) {
        this.initializeFieldMapping();
      } else {
        this.setState({ fieldMapping });
      }
    }
  }

  /**
   * Handle field mapping selection
   * @param {SyntheticEvent} e React synthetic event object
   * @param {String} field Selected field
   * @returns {undefined}
   */
  handleFieldSelection(e, field, subField) {
    const newValues = [];
    if (field === SUBPLAN_FIELD) {
      let existingData = _.get(this.state.fieldMapping, SUBPLAN_FIELD, {});
      if (typeof existingData !== 'object') {
        existingData = {};
      }
      delete existingData[subField];
      if (parseInt(e.target.value, 10) !== -1) {
        existingData[subField] = e.target.value;
      }
      newValues.push({ [SUBPLAN_FIELD]: existingData });
    } else {
      newValues.push({ [field]: e.target.value });
    }
    this.updateFieldMapping(newValues);
  }

  /**
   * Handle template selection
   * @param {Object} e React SyntheticEvent
   * @returns {undefined}
   */
  handleTemplateSelection(e) {
    const { fieldMapping } = this.state;
    const selectedTemplateId = parseInt(e.target.value, 10);
    const template = _.find(
      this.props.templates,
      template => template.id === selectedTemplateId
    );
    const newStep = _.cloneDeep(this.props.step);
    newStep.data = Object.assign(newStep.data, { templateName: template.name });
    this.props.setStep(newStep);
    this.setState({ selectedTemplateId, templateName: template.name }, () => {
      if (selectedTemplateId > 0) {
        const fieldMappingKeys = Object.keys(fieldMapping);
        const newValues = [];
        if (template) {
          const templateMap = JSON.parse(template.keymap);
          if (template.import_type === 'member') {
            const hybrid = Object.assign(
              {},
              templateMap.passenger,
              templateMap.passengerMetaData
            );
            _.forOwn(hybrid, (recordValue, recordKey) => {
              if (fieldMappingKeys.indexOf(recordValue) > -1) {
                newValues.push({
                  [recordValue]: recordKey
                });
              }
            });
          } else {
            // add ignored columns to newValues
            const templateValues = Object.values(templateMap);
            Object.entries(fieldMapping).forEach(arr => {
              if (templateValues.indexOf(arr[0]) === -1) {
                newValues.push({
                  [arr[0]]: 'ignore'
                });
              }
            });
            Object.entries(templateMap).forEach(arr => {
              // check if template field matches one of existing fields
              // if not, don't add
              if (fieldMappingKeys.indexOf(arr[1]) !== -1) {
                newValues.push({
                  [arr[1]]: arr[0]
                });
              } else {
                newValues.push({
                  [arr[1]]: 'ignore'
                });
              }
            });
          }
        }
        this.updateFieldMapping(newValues);
      } else {
        // reset to initial mapping based on sample data
        this.initializeFieldMapping();
      }
    });
  }

  /**
   * Handle template name entry
   * @param {Object} e React SyntheticEvent
   * @returns {undefined}
   */
  handleTemplateNameChange(e) {
    const { step } = this.props;
    const newTemplateName = e.target.value;
    this.setState({ templateName: newTemplateName }, () => {
      // update step state
      const newStep = _.cloneDeep(step);
      newStep.data = Object.assign(newStep.data, { templateName: newTemplateName });
      this.props.setStep(newStep);
    });
  }

  /**
   * Initialize field mapping from sample data
   * @returns {undefined}
   */
  initializeFieldMapping() {
    const sampleData = _.get(this.props, 'job.sampleData.rows', {});
    if (Object.keys(sampleData).length > 0) {
      const uploadedFields = _.get(this.props, 'job.sampleData.header', {});
      const newValues = [];

      // initially assign each importer field to each field mapping in order
      // (might get lucky and they match as expected)
      uploadedFields.forEach((uploadedField, idx) => {
        newValues.push({
          [uploadedField]: this.props.fields[idx] ? this.props.fields[idx].value : ''
        });
      });

      this.updateFieldMapping(newValues);
    }
  }

  /**
   * Update field mapping with list of key/value pairs and store in state
   * Note: field mapping and validity is stored in the Step object on the Importer level
   * @param {Array} newValues List of key/value pairs
   * @returns {undefined}
   */
  updateFieldMapping(newValues) {
    const { step, setStep } = this.props;
    const { fieldMapping } = this.state;
    const newFieldMapping = _.cloneDeep(fieldMapping);
    const newStep = _.cloneDeep(step);

    // update field mapping with new values
    newValues.forEach(newValue => {
      const key = Object.keys(newValue)[0];
      const value = newValue[key];
      newFieldMapping[key] = value;
    });

    // check against validation rules
    const { isValid, errorMessages } = this.checkValidity(newFieldMapping);

    this.setState(
      {
        fieldMapping: newFieldMapping,
        errorMessages
      },
      () => {
        // update importer step state too
        newStep.data = Object.assign(newStep.data, { fieldMapping: newFieldMapping });
        newStep.isValid = isValid;
        newStep.hasSubmitted = false; // always reevaluate
        setStep(newStep);
      }
    );
  }

  /**
   * Check if field mapping is valid
   * @param {Object} fieldMapping Field mapping (k/v)
   * @returns {Object} isValid, errorMessages
   */
  checkValidity(fieldMapping) {
    const { fields } = this.props;
    let isValid = true;
    const errorMessages = [];

    // check for required fields
    fields.forEach(field => {
      if (field.multi === true) {
        if (field.value === SUBPLAN_FIELD) {
          if (
            Object.keys(_.get(fieldMapping, field.value, {})).length !==
            Object.keys(_.get(this.props.job, 'subplans', {})).length
          ) {
            isValid = false;
            errorMessages.push('Please map all health sub plans.');
          }
        }
      }
      if (field.required) {
        const fieldMappingEntry = _.find(Object.keys(fieldMapping), key => {
          return field.value === fieldMapping[key];
        });
        if (!fieldMappingEntry) {
          isValid = false;
          errorMessages.push(`Required field is not mapped: ${field.label}`);
        }
      }
    });

    // check for duplicate fields
    const assignedFieldValues = [];
    Object.keys(fieldMapping).forEach(key => {
      const fieldValue = fieldMapping[key];
      // check if selection is made and if not already in assigned fields list
      if (
        fieldValue !== '' &&
        fieldValue !== 'ignore' &&
        assignedFieldValues.indexOf(fieldValue) !== -1
      ) {
        isValid = false;
        errorMessages.push('Duplicate fields are mapped');
      }
      assignedFieldValues.push(fieldValue);
    });
    return { isValid, errorMessages: _.uniq(errorMessages) };
  }

  render() {
    const { step, job, fields, templates, error } = this.props;
    const { fieldMapping, selectedTemplateId, templateName, errorMessages } = this.state;
    const uploadedFields = Object.keys(fieldMapping);
    const sampleData = _.get(job, 'sampleData.rows[1]', {});
    const subplans = Object.keys(_.get(job, 'subplans', {}));
    return (
      <div className="ImporterVerify">
        {_.isEmpty(job) ? (
          !_.isEmpty(error) ? (
            <ul className="errors">
              <li>Errors:</li>
              <li key={'uploadError'}>{error}</li>
            </ul>
          ) : (
            <div className="spinnerStatus">
              <ReactLoading type="spin" className="importerLoading" />
              Uploading...
            </div>
          )
        ) : (
          <div className="wrapper">
            <h2>{step.index + 1}. Verify Fields</h2>
            <p>
              We&apos;ve scanned your file and found the following fields. It&apos;s
              important you verify that your patient detail is assigned to appropriate
              field in SafeRide.
            </p>
            {errorMessages.length > 0 ? (
              <ul className="errors">
                <li>Errors:</li>
                {errorMessages.map((error, idx) => (
                  <li key={idx}>{error}</li>
                ))}
              </ul>
            ) : null}
            <div className="templates">
              {templates.length > 0 ? (
                <div className="selectWrapper">
                  <select
                    value={selectedTemplateId}
                    onChange={e => this.handleTemplateSelection(e)}
                  >
                    <option value={0}>Select a template</option>
                    {_.compact(
                      templates.map(template => {
                        if (template.import_type !== this.props.importType) {
                          return null;
                        }
                        return (
                          <option key={template.id} value={template.id}>
                            {template.name}
                          </option>
                        );
                      })
                    )}
                  </select>
                </div>
              ) : null}
              <div className="newTemplate">
                <label>Create a new template</label>
                <input
                  type="text"
                  value={templateName}
                  placeholder="template name"
                  onChange={e => this.handleTemplateNameChange(e)}
                />
              </div>
            </div>
            <table>
              <thead>
                <tr>
                  <th>Uploaded Fields</th>
                  <th>SafeRide Fields</th>
                  <th>Uploaded CSV Data</th>
                </tr>
              </thead>
              <tbody>
                {_.compact(
                  uploadedFields.map(uploadedField => {
                    if (uploadedField === SUBPLAN_FIELD) {
                      return null;
                    }
                    return (
                      <tr key={uploadedField}>
                        <td>{uploadedField}</td>
                        <td>
                          <div className="selectWrapper">
                            <select
                              value={fieldMapping[uploadedField]}
                              onChange={e => this.handleFieldSelection(e, uploadedField)}
                            >
                              <option value="">Select a field</option>
                              {_.compact(
                                fields.map(field => {
                                  if (field.name === SUBPLAN_FIELD) {
                                    return null;
                                  }
                                  return (
                                    <option key={field.name} value={field.value}>
                                      {field.label}
                                    </option>
                                  );
                                })
                              )}
                            </select>
                          </div>
                        </td>
                        <td>{sampleData[uploadedField]}</td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
            <div></div>
            {subplans.length > 0 ? (
              <table className="subplanMapping">
                <thead>
                  <tr>
                    <td>Uploaded SubPlan Name</td>
                    <td>SafeRide SubPlan</td>
                  </tr>
                </thead>
                <tbody>
                  {subplans.map((subplan, index) => {
                    const subplanKey = `subplanMap[${subplan}]`;
                    const preSelected = _.get(
                      this.state.fieldMapping,
                      `${SUBPLAN_FIELD}[${subplan}]`,
                      null
                    );
                    return (
                      <tr key={index}>
                        <td>{subplan}</td>
                        <td>
                          <div className="selectWrapper">
                            <select
                              name={subplanKey}
                              onChange={e =>
                                this.handleFieldSelection(e, SUBPLAN_FIELD, subplan)
                              }
                            >
                              <option value={-1} style={{ fontStyle: 'italic' }}>
                                - Assign a Sub Plan -
                              </option>
                              {_.get(this.props.user, 'healthPlan.sub_plans', []).map(
                                (userSubplan, i) => {
                                  if (
                                    parseInt(preSelected, 10) ===
                                    parseInt(userSubplan.id, 10)
                                  ) {
                                    return (
                                      <option key={i} selected value={userSubplan.id}>
                                        {userSubplan.name}
                                      </option>
                                    );
                                  } else {
                                    return (
                                      <option key={i} value={userSubplan.id}>
                                        {userSubplan.name}
                                      </option>
                                    );
                                  }
                                }
                              )}
                            </select>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

ImporterVerify.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  job: PropTypes.object,
  fields: PropTypes.array,
  templates: PropTypes.array
};

ImporterVerify.defaultProps = {
  step: {},
  setStep: () => {},
  job: {},
  fields: [],
  templates: []
};

export default ImporterVerify;
