import { isHealthPlanRole } from '~/utilities/helperFunctions';
import React, { useEffect, useState } from 'react';
import { Header, MenuDropdown, Link, Icon } from '@SRHealth/frontend-lib';
import { maToken, adminToken, logout } from '~/utilities/auth.helper';
import { activateSupport, goBackToAccountDashboard } from './MainHeader.utils';
import history from '~/history';
import { EXPENSE_REIMBURSEMENT, MAX_HOSPITALS_PUSHER_AUTH } from '~/constants';
import { getEntityName } from '~/utilities/users';
import { useAppSelector } from '~/Modules';

const MainHeader = () => {
  const user = useAppSelector(state => state.user);
  const userData = user?.userData;

  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  // sets up zendesk close event listener once on initial mount, so it closes fully instead of minimizing
  useEffect(() => {
    window.zE &&
      window.zE('webWidget:on', 'close', function () {
        window.zE && window.zE('webWidget', 'hide');
      });
  }, []);

  const goToPath = (path: string) => {
    setIsMenuExpanded(false);
    history.push(path);
  };

  // Sends a rollbar and mixpanel event on logout
  const handleLogout = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logout('User logged out via clicking the Log Out button in MainHeader.tsx');
  };

  return (
    <nav>
      <Header label={getEntityName(user)}>
        {!userData?.sso ? (
          <Link label="Log Out" alt iconLeft="SignOut" onClick={handleLogout} />
        ) : null}

        <MenuDropdown
          expanded={isMenuExpanded}
          setIsExpanded={setIsMenuExpanded}
          align="right"
        >
          <MenuDropdown.Anchor>
            <Icon
              type="Menu"
              className={`fill-current w-[32px] h-[32px] cursor-pointer`}
              onClick={() => setIsMenuExpanded(!isMenuExpanded)}
            />
          </MenuDropdown.Anchor>

          {/* Menu items */}

          {isHealthPlanRole(user) ? (
            <MenuDropdown.Item
              key="Plans"
              label="Plans"
              icon="Healthplan"
              onClick={() => goToPath('/plans')}
            />
          ) : null}

          {maToken ? (
            <MenuDropdown.Item
              key="Multi-Accounts"
              label="Multi-Accounts"
              icon="MultiAccount"
              onClick={goBackToAccountDashboard}
            />
          ) : null}

          <MenuDropdown.Item
            key="Members"
            label="Members"
            icon="Members"
            onClick={() => goToPath('/member-profiles')}
          />

          {!isHealthPlanRole(user) &&
          user?.hospitalUsers &&
          user?.hospitalUsers?.length <= MAX_HOSPITALS_PUSHER_AUTH ? (
              <MenuDropdown.Item
                key="Schedule"
                label="Schedule"
                icon="Schedule"
                onClick={() => goToPath('/ride/scheduled')}
              />
            ) : null}

          <MenuDropdown.Item
            key="History"
            label="History"
            icon="History"
            onClick={() => goToPath('/ride/reports')}
          />

          {user?.features[EXPENSE_REIMBURSEMENT] ? (
            <MenuDropdown.Item
              key="Expenses"
              label="Expenses"
              icon="Expense"
              onClick={() => goToPath('/expenses')}
            />
          ) : null}

          <MenuDropdown.Item
            key="Profile"
            label="Profile"
            icon="UserProfile"
            onClick={() => goToPath('/ride/userprofile')}
          />

          {adminToken ? (
            <MenuDropdown.Item
              key="Back to Admin"
              label="Back to Admin"
              icon="Reply"
              onClick={goBackToAccountDashboard}
            />
          ) : null}

          <MenuDropdown.Item
            key="Support"
            label="Support"
            icon="Faqs"
            onClick={activateSupport}
          />
        </MenuDropdown>
      </Header>
    </nav>
  );
};

export default MainHeader;
