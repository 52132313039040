import {useEffect} from 'react';

/** How often the idle timer checks the last activity time to see
 * if it has fallen out of bounds */
const LAST_ACTIVE_CHECK_INTERVAL = 5 * 1000;

/** This holds the inverse value of the isIdle app state. Used to track
 * when the hook should execute the callback function. */
let activeEventHandler = false;

/** Singleton event handler for the focus event. Initalized in the hook */
let activeFocusHandler: EventListener;

/** Singleton timeout handler for the timeout loop. Intialized in the hook  */
let activeTimeoutHandler: NodeJS.Timeout;

/**
 * This function determines if the user is idle or not based on the lastActiveTime variable that is stored in localStorage
 * @param {int} timeout Amount of time in milliseconds before a user is considered idle
 * @param {function} callback Function to be called upon timeout. The parameter for this function is a Boolean where
 * true means the user should be set to Idle, and false means the Idle designation should be removed.
 * @return {boolean} Boolean
 */
const checkIdleActivity = (timeout: number, callback: (isIdle: boolean) => void) => {
  const _activityTime = localStorage.getItem('lastActive');
  const activityTime = _activityTime ? parseInt(_activityTime) : 0;

  if (activityTime && Date.now() - activityTime > timeout) {
    callback(true);
  } else if (activityTime && Date.now() - activityTime <= timeout) {
    callback(false);
  }
};

/**
 * Used to generate a callback for the focus event listener so that we
 * have a named function with the timeout and callback bound to it.
 * @param {number} timeout 
 * @param {function} callback 
 * @returns {function}
 */
function focusHandler(timeout: number, callback: (isIdle: boolean) => void) {
  return () => checkIdleActivity(timeout, callback);
}

/**
 * This function handles the amount of time that is designated before timeout, and what function is called upon timeout.
 * @param {number} timeout Amount of time in milliseconds before a user is considered idle
 * @param {function} callback Function to be called upon timeout. The parameter for this function is a Boolean where
 * true means the user should be set to Idle, and false means the Idle designation should be removed.
 * @return {undefined}
 */
function timeoutHandler(timeout: number, callback: (isIdle: boolean) => void) {
  checkIdleActivity(timeout, callback);
  setTimeout(() => timeoutHandler(timeout, callback), LAST_ACTIVE_CHECK_INTERVAL);
}

/**
 * This function updates the timestamp stored in local storage. The update is rate limited so that a timestamp can only
 * be added every half a second to avoid race conditions, primarily caused by mouse movement.
 * @return {undefined}
 */
function handleUserActivity() {
  const _lastStorageUpdate = localStorage.getItem('lastActive');
  const lastStorageUpdate = _lastStorageUpdate ? parseInt(_lastStorageUpdate) : 0;
  
  if (Date.now() - lastStorageUpdate > 500) {
    localStorage.setItem('lastActive', Date.now().toString());
  }
}

/**
 * Custom hook for implementing an idle timer. Implements event listeners for mousemove and keydown to determine user
 * activity. In the event that an event listener is triggered, the time is stored as a variable in local storage to be
 * used as marker of last activity to check against when determining if the user has been idle for the number of seconds
 * specified by the timeoutInSeconds parameter.
 * @name useIdleTimer
 * @param {number} timeout Amount of time in milliseconds before a user is considered idle
 * @param {function} callback Function to be called upon timeout. The parameter for this function is a Boolean where
 * true means the user should be set to Idle, and false means the Idle designation should be removed.
 * @return {[function, function]} Returns an array of functions. One to add the event listeners for mouse movement
 * and key presses, and another to remove the event listeners. These functions should be implemented within a useEffect
 * hook, added as dependencies, along with whatever state variable they should be triggered by.
 * Instantiation: const [idleTimerMount, idleTimerDismount] = useIdleTimer(timeoutInSeconds, value => setSomeState(value));
 */
const useIdleTimer = (timeout: number, callback: (isIdle: boolean) => void) => {

  useEffect(() => {
    localStorage.setItem('lastActive', Date.now().toString());

    if (!activeFocusHandler) {
      activeFocusHandler = focusHandler(timeout, callback);
    }

    if (!activeTimeoutHandler) {
      activeTimeoutHandler = setTimeout(() => timeoutHandler(timeout, callback), LAST_ACTIVE_CHECK_INTERVAL);
    }
  }, []);

  return [
    function() {
      if (!activeEventHandler) {
        document.addEventListener('mousemove', handleUserActivity);
        document.addEventListener('keydown', handleUserActivity);
        document.removeEventListener('focus', activeFocusHandler);
        activeEventHandler = true;
      }
    },
    function() {
      if (activeEventHandler) {
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keydown', handleUserActivity);
        document.addEventListener('focus', activeFocusHandler);
        activeEventHandler = false;
      }
    }];
};

export default useIdleTimer;
