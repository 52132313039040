import { laravelUrl } from '~/utilities/helperFunctions';

/**
 * Redirects to the account dashboard (be that the multi-account dashboard or super admin dashboard).
 */
export const goBackToAccountDashboard = () => {
  window.location.href = laravelUrl('/login_as_user/stop');
};

/**
 * Shows and opens the Zendesk support widget
 */
export const activateSupport = () => {
  window.zE && window.zE('webWidget', 'show');
  window.zE && window.zE('webWidget', 'open');
};