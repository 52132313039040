//actions file
import _ from 'lodash-es';
import { baseRequestConfig, logout } from '~/utilities/auth.helper';
import { subscribe } from '~/utilities/pusherRedux';
import history from '~/history';
import {
  CHANNEL_PREFIX_USER,
  CHANNEL_PREFIX_RIDES,
  CHANNEL_PREFIX_DRIVERS,
  CHANNEL_EVENT_TYPE,
  MAX_HOSPITALS_PUSHER_AUTH,
  type HospitalGroupFeature
} from '~/constants';
import {
  rideCardActions,
  newRideThunk,
  rideCreatedThunk,
  rideInfoUpdateThunk
} from '~/Modules/rideCards/rideCards.actions';
import axios from '~/Modules/safeAxios';
import { select, take, race, delay } from 'redux-saga/effects';
import { statusNotifications } from '~/utilities/helperFunctions';
import Cookies from 'js-cookie';
import { getFeatureFlags } from '~/utilities/users';
import type {
  AdjustmentTypeOrParty,
  CancellationReason,
  ComplianceRecord,
  ComplianceRecordBase,
  CustomTextConfig,
  HealthPlan,
  HealthSubPlan,
  Hospital,
  HospitalGroupFeatureRecord,
  HospitalUser,
  PublicCardData,
  UserRole,
  VehicleCompany
} from '~/types';
import type { UserLogoutEventProperties } from '~/analytics';

const ERROR_GET_VEHICLE_TYPES =
  'There was an error retrieving the vehicle types. If the issue persists, contact support.';

/** The base URL for user endpoints */
const url = `${process.env.REACT_APP_API_URL}/user`;

/** The base URL for user status login */
const loginCheckUrl = `${process.env.REACT_APP_API_URL}/user-status`;

let _user;
let sessionCheckTimer;

export const GET_USER = 'user/GET_USER';
export const GET_USER_ERROR = 'user/GET_USER_ERROR';
export const SET_USER_UI = 'user/SET_USER_UI';
export const CHANNEL_EVENT_LOGOUT_REQUEST = 'user/event/LOGOUT_REQUEST';
export const GET_VEHICLES = 'user/GET_VEHICLES';
export const GET_VEHICLES_ERROR = 'user/GET_VEHICLES_ERROR';
export const NO_SUBSCRIPTION_PATH_SEGMENTS = [
  '/member-profiles',
  '/ride/reports',
  '/ride/new/'
];

export const NOT_SET = 'not set'; // default
export const ATTEMPTED_TO_SET_BUT_FALSEY = 'attempted to set but falsey';
/** Do not use for anything else except debugging issues. Made in July 2024 for mid-summer intermittent logout issues. Ideally delete if intermittent logout issues are no longer a thing. */
export const globalDebugOnlyUserData = {
  userId: NOT_SET,
  role: NOT_SET,
  hospitalGroupName: NOT_SET,
  timezone_format: NOT_SET,
  userTimezone: NOT_SET,
  firstName: NOT_SET,
  lastName: NOT_SET
} as Omit<UserLogoutEventProperties, 'eventTriggerTime' | 'eventSource'> & {
  timezone_format: string;
  firstName: string;
  lastName: string;
};

export type UserData = {
  id: number;
  superId: number;
  firstName: string;
  lastName: string;
  timezone_format: string;
  role: UserRole;
  passengerRate: number;
  showCost: boolean;
  useIdForLiveRidesdata: number;
  showPrivatePay: boolean;
  sso: boolean;
  hospitalGroupName?: string;
  fundingSource?: string;
  email?: string;
  phone?: string;
};

/** **NOTE**: Namespace clash with a RideCustomField
 * using the the bookingData redux store. Will have to address
 * in future. */
export type RideCustomField = {
  id: number;
  createdBy: number;
  field_label: string;
  field_label_desc: string;
  hospitalId: number;
  vehicleTypeId: number | null;
  visible_to: string;
  related_to: 'ride';
  status: 'Active' | 'Inactive';
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
};

export type DependentFieldOption = {
  id: number;
  compliance_data_id: number;
  option_value: string;
  parent_option_id: number | null;
  created_at: string;
  updated_at: string;
  deleted_at: string | null;
  child_options: DependentFieldOption[];
};

export interface DependentField extends ComplianceRecordBase {
  input_type: 'select';
  dependent_field: DependentField | null;
  compliance_options: DependentFieldOption[];
}

export type UserStore = {
  userData: UserData;
  status: number;
  nodeUserType: string; // TODO: add string union of possible values
  /** A list of vehicle types available to the Hospital Network */
  vehicleTypes: Record<number, BE.VehicleRecord>;
  vehicleCompanies: VehicleCompany[];
  cancellationReasons?: CancellationReason[];
  /** Used in RBF 4.0 to generate the compliance fields. */
  complianceInfo?: ComplianceRecord[];
  customTextConfig?: CustomTextConfig;
  expenseStatuses?: string[];
  hospitals?: unknown;
  hospitalUsers?: HospitalUser[];
  hospitalData?: Hospital[];
  hospitalGroups?: unknown;
  hospitalGroupName?: string;
  hospitalGroupId?: number;
  hospitalGroupFeatures?: HospitalGroupFeatureRecord[];
  healthPlan?: HealthPlan[];
  healthPlans?: HealthSubPlan[];
  pastBookingDays?: number;
  pastEditDays?: number;
  fundingHealthPlans?: HealthPlan[];
  publicVehicleTypes?: BE.VehicleRecord[];
  availableVehicleTypes?: Record<string, BE.VehicleRecord[]>;
  publicCardData?: PublicCardData;
  /** Used in RBF 4.0 to generate hospital custom fields */
  patientCustomFields: Record<number, BE.HospitalCustomField[]>;
  /** Used in RBF 4.0 to generate hospital custom fields */
  rideCustomFields: Record<number, BE.HospitalCustomField[]>;
  /** Not used? */
  timeCustomFields?: Record<number, BE.HospitalCustomField[]>;
  /** Used in RBF 4.0 to generate dependent fields */
  dependentFields: DependentField;
  features: { [x in HospitalGroupFeature]: boolean };
  adjustmentSubTypes: AdjustmentTypeOrParty[];
  adjustmentRequestingParties: AdjustmentTypeOrParty[];
};

/**
 * Starts timer to check user's status every 2 minutes
 * Note: that this function is handled outside of redux and does not update state
 * @return {undefined}
 */
export const sessionChecker = () => {
  if (!sessionCheckTimer) {
    sessionCheckTimer = window.setTimeout(
      () => {
        window.clearTimeout(sessionCheckTimer);
        sessionCheckTimer = null;
        handleSessionCheck();
      },
      2 * 60 * 1000
    ); // 2 minutes
  }
};

/**
 * dispatch for getting vehicle information for ride booking
 * for network roles only
 * @param {number} hospitalId - hospital id for retrieving vehicles
 * @param {number} subPlanId - OPTIONAL sub health plan id
 * @return {dispatch} returns axios call which dispatches a reducer
 */
export const getVehicles = (hospitalId, subPlanId = null) => {
  return dispatch => {
    let urlVehicles = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/vehicle-types/${hospitalId}`;

    if (subPlanId) {
      urlVehicles += `/${subPlanId}`;
    }

    return axios
      .get(urlVehicles, baseRequestConfig())
      .then(response => {
        const status = response?.data?.status ?? false;
        const availableVehicleTypes = response?.data?.data?.availableTypes ?? [];
        const timestamp = response?.data?.timestamp ?? {};
        const errors = response?.data?.errors ?? [];

        if (status && availableVehicleTypes.length > 0) {
          dispatch({
            type: GET_VEHICLES,
            hospitalId,
            availableVehicleTypes,
            timestamp,
            errors: []
          });
        } else {
          if (availableVehicleTypes.length === 0) {
            statusNotifications('No vehicle types available...', 'error', 3000);
            errors.push({
              errorMessage: ERROR_GET_VEHICLE_TYPES,
              httpCode: 400
            });
          }

          dispatch({
            type: GET_VEHICLES_ERROR,
            hospitalId,
            errors,
            timestamp
          });
        }
      })
      .catch(error => {
        const errors = error?.response?.data?.errors ?? '';
        const timestamp = error?.response?.data?.timestamp ?? {};
        const httpCode = error?.response?.data?.httpCode ?? 500;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const errorList: Record<string, any>[] = [];

        if (errors !== '') {
          _.forOwn(errors, (singleError, errorCode) => {
            const errorMessage = singleError?.message ?? ERROR_GET_VEHICLE_TYPES;
            const errorData = singleError?.data;

            errorList.push({ errorMessage, errorData, errorCode, httpCode });
          });
        } else {
          errorList.push({
            errorMessage: ERROR_GET_VEHICLE_TYPES,
            httpCode: 500
          });
        }
        statusNotifications('Error retrieving vehicle types...', 'error', 3000);
        dispatch({
          type: GET_VEHICLES_ERROR,
          hospitalId,
          errors: errorList,
          timestamp
        });
      });
  };
};

/**
 * Handle session check request
 * Note: this will restart the timer if a valid response is received
 * @return {undefined}
 */
const handleSessionCheck = () => {
  axios
    .get(loginCheckUrl, baseRequestConfig())
    .then(response => {
      const hasValidStatus = response?.data?.data?.status ?? false;
      const ttl = response?.data?.data?.ttl ?? 0;

      if (response.status === 200 && hasValidStatus && ttl > 0) {
        // update TTL value if available (i.e. seconds until logout)
        setTtlCookie(ttl);

        // restart session timer
        sessionChecker();
      } else {
        throw new Error('Invalid user status response');
      }
    })
    .catch(() => {
      statusNotifications(
        'Unable to check user status. Please contact support',
        'error',
        8000,
        () => {
          logout('Session Check Error');
        }
      );
    });
};

/**
 * Wrapper for API request to extend user's session (TTL)
 * Note: We don't actually dispatch to a reducer action. We either update session
 * cookies or logout the user (no state change)
 * @param  {Function} callback  Callback function
 * @return {Function}           Dispatch function
 */
export const extendSession = callback => {
  return axios
    .get(loginCheckUrl, baseRequestConfig())
    .then(() => {
      // set ttl cookie to 2 hours (will be updated by status check)
      setTtlCookie(2 * 60 * 60);

      if (typeof callback === 'function') {
        callback();
      } else {
        // force reload as function that checks ttl fires only every 60s
        statusNotifications(
          'Session extended. Reloading page...',
          'success',
          3000,
          () => {
            window.location.reload();
          }
        );
      }
    })
    .catch(() => {
      statusNotifications(
        'Unable to extend session. Please login again',
        'error',
        8000,
        () => {
          logout('Extend Session Error');
        }
      );
    });
};

export const getUser = updateRedux => {
  if (typeof updateRedux === 'undefined') {
    updateRedux = false;
  }
  return dispatch => {
    return axios
      .get(url, baseRequestConfig())
      .then(response => {
        dispatch({
          type: GET_USER,
          data: response.data,
          status: response.status,
          updateRedux
        });

        const userResponse = response?.data?.data?.user ?? {};
        Object.keys(globalDebugOnlyUserData).forEach(_key => {
          const debugObjKey = _key;
          const responseKey = _key === 'userId' ? 'id' : _key; // for userId, use id from the response object, but keep it userId in the debug object for clarity and consistency in debugging
          globalDebugOnlyUserData[debugObjKey] =
            userResponse[responseKey] ?? ATTEMPTED_TO_SET_BUT_FALSEY;
        });
      })
      .catch(error => {
        dispatch({
          type: GET_USER_ERROR,
          error: error
        });
      });
  };
};

/**
 * Get user data saga
 */
export function* AuthSaga() {
  const state = yield select();

  if (!state?.user?.userData) {
    //User info is not available
    const { user } = yield race({
      user: take(GET_USER),
      timeout: delay(2 * 60 * 1000)
    });
    const hospitalUsers = user?.data?.data?.hospitalUsers ?? [];
    if (!user && process.env.REACT_APP_ENVIRONMENT !== 'local') {
      statusNotifications(
        'We are unable to authenticate. Please contact support',
        'error',
        8000,
        () => {
          logout('Get User Saga Error');
        }
      );
    } else {
      // set ttl cookie to 2 hours (will be updated by status check)
      setTtlCookie(2 * 60 * 60);
      if (hospitalUsers.length <= MAX_HOSPITALS_PUSHER_AUTH) {
        subscribeToEvents(user);
      }
    }
  }
}

/**
 * Subscribe to user events
 * @param  {Object}     [user={}] User data
 * @return {undefined}
 */
const subscribeToEvents = (user = {}) => {
  const userId = user?.data?.data?.user?.id ?? 0;
  const hospitalUsers = user?.data?.data?.hospitalUsers ?? [];
  const location = history.location;
  const currentPath = location.pathname;
  const noSubscriptionPaths = [/\/member-profiles(.*)/, /\/ride\/reports/];

  if (!noSubscriptionPaths.some(rx => rx.test(currentPath))) {
    if (userId !== 0) {
      // subscribe to user logout events on user channel
      subscribe(
        `${CHANNEL_PREFIX_USER}-${userId}`,
        CHANNEL_EVENT_TYPE.USER_LOGOUT,
        CHANNEL_EVENT_LOGOUT_REQUEST
      );
    }
    if (hospitalUsers.length <= MAX_HOSPITALS_PUSHER_AUTH) {
      hospitalUsers.forEach(({ userId }) => {
        // subscribe to bulk edit events on hospital channels
        const ridesChannel = `${CHANNEL_PREFIX_RIDES}-${userId}`;
        subscribe(ridesChannel, 'rideBulkUpdate', rideCardActions.BULK_EDIT_EVENT);
      });
    }
  }
};

/**
 * Set TTL value to a cookie
 * Note: we don't want to store in redux store as it will update components unintentionally
 * @param  {Number}     ttl TTL in seconds
 * @return {undefined}
 */
const setTtlCookie = ttl => {
  const path = '/';
  const domain = '.saferidehealth.com';
  Cookies.set(`${process.env.REACT_APP_ENVIRONMENT}_auth_ttl`, ttl, { path, domain });
};

export default (state = {}, action) => {
  const newState = _.cloneDeep(state);

  const { data } = action?.data ?? {};

  if (!_user && Object.keys(state).length) {
    _user = state;
  }

  switch (action.type) {
    case GET_USER: {
      if (action.updateRedux === false) {
        return state;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const userData: Partial<UserStore> = {
        userData: data.user,
        status: action.status,
        nodeUserType: data.userType,
        vehicleTypes: data.vehicleTypes,
        vehicleCompanies: data?.vehicleCompanies ?? [],
        customTextConfig: data.customTextConfig,
        expenseStatuses: data?.expenseStatuses ?? [],
        features: getFeatureFlags(data?.hospitalGroupFeatures ?? []),
        adjustmentSubTypes: data?.billingMetaData?.adjustmentSubTypes ?? [],
        adjustmentRequestingParties:
          data?.billingMetaData?.adjustmentRequestingParties ?? []
      };

      // `thisObject` is just a massive chunk of unused data. Don't store it.
      if ('thisObject' in data.user) {
        userData.userData!.phone = data.user.thisObject?.userMobileNo;
        userData.userData!.email = data.user.thisObject?.email;
        // @ts-expect-error - thisObject is not a valid property which is why we are deleting it
        delete userData.userData!.thisObject;
      }

      userData.vehicleCompanies ??= [];
      if (userData.vehicleCompanies.length > 1) {
        userData.vehicleCompanies.sort((a, b) =>
          a.companyName > b.companyName ? 1 : b.companyName > a.companyName ? -1 : 0
        );
      }

      switch (data.user.role) {
        case 'HealthPlanOwner':
        case 'HealthPlanAdmin': {
          userData.healthPlan = data.healthPlan;
          userData.hospitalGroups = data.hospitalGroups;
          userData.hospitals = data.hospitals;
          break;
        }
        default: {
          userData.hospitalNames = data.hospitalNames;
          userData.hospitalUsers = data.hospitalUsers;
          userData.hospitalData = data.hospitals[0];

          // order compliance fields so that fields with no children are listed first
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const complianceInfoDependendent: any[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const complianceInfo: any[] = [];
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const complianceData: any[] = data?.complianceInfo?.complianceData ?? [];

          complianceData.forEach(compliance => {
            if (_.isNil(compliance.child)) {
              complianceInfo.push(compliance);
            } else {
              complianceInfoDependendent.push(compliance);
            }
          });
          userData.cancellationReasons = [];
          const cancellationReasons = _.get(action, 'data.data.cancelReasons', {});
          Object.keys(cancellationReasons).forEach(key => {
            userData.cancellationReasons.push(cancellationReasons[key]);
          });
          userData.expenseSettings = data.expenseSettings;
          userData.complianceInfo = complianceInfo.concat(complianceInfoDependendent);
          userData.dependentFields = _.get(
            action,
            'data.data.complianceInfo.dependentFields',
            {}
          );
          userData.hospitalGroupFeatures = data.hospitalGroupFeatures;
          userData.fundingHealthPlans = data.fundingHealthPlans;
          userData.hospitalGroupName = data.hospitalGroupName;
          userData.hospitalGroupId = data.hospitalGroupId;
          userData.healthPlan = _.get(action, 'data.data.healthPlan', []);
          userData.healthPlans = _.get(action, 'data.data.healthPlans', []);

          if (_.has(action.data, 'data.pastBookingDays')) {
            userData.pastBookingDays = data.pastBookingDays;
          }

          if (_.has(action.data, 'data.pastEditDays')) {
            userData.pastEditDays = data.pastEditDays;
          }
          if (_.has(action.data, 'data.publicCardData')) {
            userData.publicCardData = data.publicCardData;
          }

          if (_.has(action.data, 'data.publicVehicleTypes')) {
            const publicVehicleTypes = _.get(action, 'data.data.publicVehicleTypes', {});
            userData.publicVehicleTypes = Object.keys(publicVehicleTypes).map(id => ({
              ...publicVehicleTypes[id]
            }));
          }

          if (
            userData.nodeUserType === 'HospitalOwner' ||
            userData.nodeUserType === 'HospitalNetworkManager'
          ) {
            userData.hospitalData = data.hospitals;
            userData.availableVehicleTypes = data.availableVehicleTypes;
            userData.patientCustomFields = data.patientCustomFields;
            userData.rideCustomFields = data.rideCustomFields;
            userData.timeCustomFields = data.timeCustomFields;
          } else {
            const hospitalId = data.hospitals[0].id;
            userData.availableVehicleTypes = data.availableVehicleTypes[hospitalId];
            userData.patientCustomFields = data.patientCustomFields[hospitalId];
            userData.rideCustomFields = data.rideCustomFields[hospitalId];
            userData.timeCustomFields = data.timeCustomFields[hospitalId];
          }
        }
      }

      const hospitalUsers = userData?.hospitalUsers ?? [];
      const location = history.location;
      const currentPath = location.pathname;

      if (
        !NO_SUBSCRIPTION_PATH_SEGMENTS.some(pathSegment =>
          currentPath.includes(pathSegment)
        )
      ) {
        hospitalUsers.forEach(hospital => {
          const ridesChannel = `${CHANNEL_PREFIX_RIDES}-${hospital.userId}`;
          subscribe(ridesChannel, 'rideStatusUpdate', rideCardActions.RIDE_STATUS_UPDATE);
          subscribe(ridesChannel, 'newRide', 'newRideThunk', newRideThunk);
          subscribe(
            ridesChannel,
            'rideInfoUpdate',
            'rideInfoUpdateThunk',
            rideInfoUpdateThunk
          );
          subscribe(
            ridesChannel,
            'rideLocationUpdate',
            rideCardActions.RIDE_LOCATION_UPDATE
          );
          subscribe(
            ridesChannel,
            'rideAlertClaimStatusUpdate',
            rideCardActions.RIDE_CLAIM_STATUS_UPDATE
          );
          subscribe(ridesChannel, 'rideCreated', 'rideCreatedThunk', rideCreatedThunk);

          const driversChannel = `${CHANNEL_PREFIX_DRIVERS}-${hospital.userId}`;
          subscribe(
            driversChannel,
            'rideLocationUpdate',
            rideCardActions.RIDE_LOCATION_UPDATE
          );
        });
      }

      return userData;
    }
    case GET_USER_ERROR: {
      if (action?.error) logout('Get User Error');

      return state;
    }
    case SET_USER_UI: {
      const newState = _.clone(state);
      newState.newUI = action.newUI;
      return newState;
    }
    case CHANNEL_EVENT_LOGOUT_REQUEST: {
      logout('Pusher');

      return state;
    }
    case GET_VEHICLES: {
      if (
        newState.nodeUserType === 'HospitalOwner' ||
        newState.nodeUserType === 'HospitalNetworkManager' ||
        newState.nodeUserType === 'hospital' ||
        newState.nodeUserType === 'HospitalAdmin' ||
        newState.nodeUserType === 'caseManager'
      ) {
        newState.availableVehicleTypesForSelection = action.availableVehicleTypes;
      }
      return newState;
    }
    case GET_VEHICLES_ERROR: {
      return newState;
    }
    default: {
      if ('rows' in state) {
        const newState = _.clone(state);
        newState.errorMessage = '';
        newState.error = {};
        return newState;
      }
      return state;
    }
  }
};

export * from './selectors';
