import { DATE_API_FORMAT, RIDE_CARDS_LIMIT, ALERT_PAGES } from '~/constants/general';
import { isNil, isEmpty, isNumber } from '~/utilities/guards';
import type {
  RideCardsStore,
  RideLocationUpdateAction,
  RideCardsRide,
  RideCreatedAction,
  RideStatusUpdateAction,
  RideInfoUpdateAction,
  RideInfoUpdateParsedPayload,
  AddRideAction,
  AddLyftOnDemandAction,
  NewRideAction
} from './rideCards.types';
import moment from 'moment';
import immutable from 'immutability-helper';
import {
  mapPusherData,
  mapRideShareRide,
  mapSubmitData,
  mapSubmitDataMultiLeg,
  orderScheduledRides,
  setRideCardsCanReload
} from './utils/rideCards.utils';
import { cloneDeep } from '~/utilities/helperFunctions';

/**
 * Handles the fetching of the ride cancellation cost
 */
export function handleCancelRideCost(
  state: RideCardsStore,
  action,
  updateState: (newState: Partial<RideCardsStore>) => RideCardsStore
) {
  if ('error' in action) {
    return {
      ...state,
      params: action.params,
      error: action.error
    };
  }

  return updateState({
    timestampMicro: Math.floor(Date.now()),
    cancelRideCostData: {
      [action.params.rideId]: {
        ...action.data,
        leg: action.params.leg,
        params: action.params
      }
    }
  });
}

/**
 * Handles the canceling of a ride
 */
export function handleCancelRide(state: RideCardsStore, action) {
  if ('error' in action) {
    return {
      ...state,
      params: action.params,
      error: action.error,
      timestampMicro: Math.floor(Date.now())
    };
  }
  return {
    ...state,
    params: action.params,
    cancelRideData: {
      rides: action.data,
      status: action.status,
      message: action.message
    },
    timestampMicro: Math.floor(Date.now())
  };
}

/**
 * Handles the updating of a ride's date
 */
export function handleUpdateDate(state: RideCardsStore, action) {
  if ('error' in action) {
    return {
      ...state,
      status: false,
      message: action.error,
      params: action.params,
      showUpdateDateError: true,
      error: {
        errorMessage: action?.error?.response?.data?.message ?? 'Unknown error',
        errorStatus: ''
      },
      timestampMicro: Math.floor(Date.now()),
      editRideLoading: false
    };
  }

  return {
    ...state,
    params: action.params,
    updateDateData: action.data,
    timestampMicro: Math.floor(Date.now()),
    loadingEditRide: false,
    ...(action.data?.warning === 'willCallNoLiveChat'
      ? { showWillCallReadyNowError: true }
      : {})
  };
}

/**
 * Handles New Ride Created Event from Pusher
 */
export function handleRideCreatedEvent(state: RideCardsStore, action: RideCreatedAction) {
  const message = JSON.parse(action.data?.msgobj ?? '""');
  const page = state?.scheduledRides?.rideCardParams?.page;
  const userId = action.userStore?.userData?.id;

  if (
    !message ||
    page === 'ride-share-alerts' ||
    page === 'ride-nemt-alerts' ||
    userId === message.bookedBy
  ) {
    return state;
  }

  return setRideCardsCanReload(state);
}

/**
 * Handles checking if a date can be updated to begin with
 */
export function handleUpdateDateCheck(state: RideCardsStore, action) {
  if ('error' in action) {
    return immutable(state, {
      $merge: {
        timestampMicro: Math.floor(Date.now()),
        loadingEditRide: false,
        updateDateData: {},
        error: { errorMessage: action.error, errorStatus: '' },
        scheduledRides: immutable(state.scheduledRides, {
          rideCardParams: { $merge: action.params }
        })
      }
    });
  }

  return immutable(state, {
    $merge: {
      timestampMicro: Math.floor(Date.now()),
      loadingEditRide: false,
      updateDateData: {},
      updateDateCheckData: action.data,
      scheduledRides: immutable(state.scheduledRides, {
        rideCardParams: { $merge: action.params }
      })
    }
  });
}

/**
 * Handles the setting of the loading state for the edit ride component
 */
export function handleSetLoadingComponent(state: RideCardsStore, action) {
  return { ...state, loadingEditRide: action.data.status };
}

/**
 * Handles the updating of a ride
 */
export function handleUpdateRide(state: RideCardsStore, action) {
  if ('error' in action) {
    return {
      params: action.params,
      error: action.error,
      timestampMicro: Math.floor(Date.now()),
      loadingEditRide: false
    };
  }

  return {
    ...state,
    params: action.params,
    updateRideData: action.data,
    timestampMicro: Math.floor(Date.now()),
    loadingEditRide: false
  };
}

/**
 * Handles checking if a ride can be updated
 */
export function handleUpdateRideCheck(state: RideCardsStore, action) {
  const update = {
    timestampMicro: Math.floor(Date.now()),
    loadingEditRide: false
  };

  if ('error' in action) {
    return {
      ...state,
      ...update,
      params: action.params,
      error: action.error
    };
  }

  return {
    ...state,
    ...update,
    params: action.params,
    updateRideCheckData: action.data,
    updateRideCheckDataParams: action.params
  };
}

/**
 * Generalized error handler for updating ride cards
 */
export function handleGeneralUpdateRideError(state: RideCardsStore, action) {
  return {
    ...state,
    error: {
      errorMessage: action?.error?.response?.data?.message ?? 'There was an error',
      errorStatus: action?.error?.response?.status ?? ''
    },
    timestampMicro: Math.floor(Date.now()),
    loadingEditRide: false
  };
}

/**
 * Handles errors with cancelling a ride
 */
export function handleCancelRideError(state: RideCardsStore, action) {
  return {
    ...state,
    error: {
      errorMessage: action?.error?.message ?? 'There was an error',
      errorStatus: action?.status ?? 500
    },
    timestampMicro: Math.floor(Date.now())
  };
}

/**
 * Handles flipping a will call with live chat
 */
export function handleUpdateWillCallWithChat(state: RideCardsStore, action) {
  return {
    ...state,
    updateDateData: {
      status: true,
      message: action?.data?.message ?? 'Opening chat to dispatcher'
    }
  };
}

/**
 * Handles the error when flipping a will call with live chat
 */
export function handleUpdateWillCallWithChatError(state: RideCardsStore, action) {
  return {
    ...state,
    updateDateData: {
      status: false,
      message: action.error.message
    }
  };
}

/**
 * Handles the request for a pickup
 */
export function handleRequestPickup(state: RideCardsStore, action) {
  return action.data?.warning === 'willCallNoLiveChat'
    ? { ...state, showWillCallReadyNowError: true }
    : state;
}

/**
 * Handles the error when requesting a pickup
 */
export function handleRequestPickupError(state: RideCardsStore, action) {
  return {
    ...state,
    updateDateData: { status: false, message: action.error.message }
  };
}

/**
 * Handles resetting the ride cards state
 */
export function handleResetCards(state: RideCardsStore) {
  return {
    ...state,
    updateRideCheckData: {},
    updateRideCheckDataParams: {},
    updateDateCheckData: {},
    cancelRideCostData: {},
    error: {},
    updateRideData: {}
  };
}

/**
 * Handles resetting the update ride cards state
 */
export function handleResetUpdatedCards(state: RideCardsStore) {
  return {
    ...state,
    updateRideData: {},
    updateDateData: {},
    cancelRideData: {},
    archiveRideData: {},
    error: {}
  };
}

/**
 * Handles resetting the will call ready now error state back to no error
 */
export function handleResetShowWillCallReadyNowError(state: RideCardsStore) {
  return { ...state, showWillCallReadyNowError: false };
}

/**
 * Handle the fetching of rides, which ultimately populates the ride cards shown in the UI
 */
export function handleRidesFetchSuccess(state: RideCardsStore, action, updateState) {
  if (!action?.scheduledRides) {
    return immutable(state, {
      $merge: { loadingRideCards: false, scheduledRides: undefined }
    });
  }

  const rides = state?.scheduledRides?.cards?.rides ?? [];
  const prevRidesList = state?.scheduledRides?.cards?.prevRidesList ?? '';

  const oldParams = state?.scheduledRides?.rideCardParams
    ? { ...state.scheduledRides.rideCardParams }
    : { query: '' };

  const scheduledRides: Pick<
    RideCardsStore['scheduledRides'],
    'cards' | 'hasMore' | 'rideCardParams'
  > = {
    cards: action.scheduledRides ?? {},
    hasMore: false,
    rideCardParams: { ...action.params }
  };

  scheduledRides.cards.reloadPage = false;
  scheduledRides.cards.timestamp = Math.floor(Date.now() / 1000);

  if (state?.scheduledRides?.rideCardParams?.limit !== RIDE_CARDS_LIMIT) {
    state.scheduledRides.rideCardParams.limit = RIDE_CARDS_LIMIT;
  }
  if (action.scheduledRides?.rides.length === state.scheduledRides.rideCardParams.limit) {
    scheduledRides.hasMore = true;
  }

  const ridesList = JSON.stringify(action.scheduledRides.rides.map(ride => ride.id));

  if (
    rides.length &&
    oldParams.query === state.scheduledRides.rideCardParams.query &&
    state.scheduledRides.rideCardParams.lazyLoad === true &&
    ridesList !== prevRidesList
  ) {
    scheduledRides.cards.rides = rides.concat(action.scheduledRides.rides);
    scheduledRides.cards.prevRidesList = ridesList;
  }

  scheduledRides.cards.rides = scheduledRides.cards.rides.map(r => ({
    ...mapRideShareRide(r),
    alertClaimStatus: r?.alert_claim_status,
    alertClaimByUser: {
      userId: r?.alert_claim_user_id,
      name: r?.alert_claim_status_name
    }
  }));

  const update = { loadingRideCards: false, scheduledRides };

  return updateState(update);
}

/**
 * Handle the updating of ride card info based on push service events
 */
export function handleRideInfoUpdate(
  state: RideCardsStore,
  action: RideInfoUpdateAction
) {
  // No message? No update!
  if (!action.data?.msgobj) return state;

  // No user data? No update!
  const userStore = action.userStore;
  if (!userStore.userData.id) return state;

  // If we don't have a page then we don't care about the ride card update
  const page = state?.scheduledRides?.rideCardParams?.page ?? '';
  if (!page) return state;

  // No message or ride to update? Believe it or not, no update!
  const message: RideInfoUpdateParsedPayload = JSON.parse(action.data.msgobj);
  if (!message || !message?.ride) return state;

  const msgType = message?.msg_type || '';
  const msgRide = message.ride;
  const msgRideDetails = message.ride?.rideDetails;
  const rides = state.scheduledRides.cards?.rides;

  // Determine if we are on a Scheduled Rides page by checking the rideCardParams.page against
  // an array of pages that display ride cards
  if (
    ![
      'scheduled',
      'all',
      'willcalls',
      'active',
      'ride-share-alerts',
      'ride-nemt-alerts'
    ].includes(page) ||
    !Array.isArray(rides)
  ) {
    return state;
  }

  // We don't care about new rides in the alerts page
  if (
    msgType === 'new_ride_added' &&
    ['ride-share-alerts', 'ride-nemt-alerts'].includes(page)
  ) {
    return state;
    // Not a new ride and we don't have a ride id? User can choose if they update.
  } else if (!msgRideDetails) {
    return setRideCardsCanReload(state);
  }

  const index = msgRideDetails?.id
    ? rides.findIndex(({ id }) => id?.toString() === msgRide.rideDetails.id.toString())
    : -1;

  // Ride is not in loaded cards? User can choose if they update
  if (index === -1) return setRideCardsCanReload(state);

  // update state ridecard with new ride data.
  return immutable(state, {
    scheduledRides: {
      cards: {
        rides: {
          [index]: {
            $merge: {
              toAddress: msgRideDetails.toAddress,
              fromAddress: msgRideDetails.fromAddress,
              pickupProviderAddress: msgRideDetails.pickupProviderAddress,
              dropoffProviderAddress: msgRideDetails.dropoffProviderAddress,
              appointmentTime: msgRideDetails.appointmentTime,
              rideStartTime: msgRideDetails.startTime,
              estimatedFinishTime: msgRideDetails.estimatedFinishTime,
              extraTime: msgRideDetails.extraTime,
              confirmation_status: msgRideDetails.confirmationStatus,
              vehicleId: msgRideDetails.vehicleId,
              driverId: msgRideDetails.driverId,
              eta: msgRideDetails.eta,
              status: msgRideDetails.status,
              pmobileNo:
                msgRideDetails?.pmobileNo ||
                state.scheduledRides.cards.rides[index]?.pmobileNo,
              reqVehicleType: msgRideDetails.reqVehicleType,
              dispatcher_managed: msgRideDetails.dispatcher_managed,
              modelName: msgRideDetails.reqVehicleType
                ? userStore.vehicleTypes?.[msgRideDetails.reqVehicleType]?.modelName
                : '',
              lyftBookingType:
                state.scheduledRides.cards.rides[index]?.lyftBookingType ?? null
            }
          }
        }
      }
    }
  });
}

/**
 * Handle the updating of ride claim status
 */
export function handleRideClaimStatusUpdate(state: RideCardsStore, action) {
  if (!state?.scheduledRides?.cards?.rides) return state;

  const { status: alertClaimStatus, rideId, user: alertClaimByUser } = action.data;
  const rideIndex = state?.scheduledRides?.cards?.rides?.findIndex(r => r?.id === rideId);
  if (rideIndex < 0) return state;

  if (alertClaimStatus === 'Resolved') {
    return immutable(state, {
      scheduledRides: {
        cards: {
          rides: { $splice: [[rideIndex, 1]] }
        }
      }
    });
  }

  return immutable(state, {
    scheduledRides: {
      cards: {
        rides: {
          [rideIndex]: {
            $merge: {
              alertClaimStatus,
              alertClaimByUser
            }
          }
        }
      }
    }
  });
}

/**
 * Handles the updating of driver location data (e.g. the driver svg and X past locations on the map)
 */
export function handleRideLocationUpdate(
  state: RideCardsStore,
  action: RideLocationUpdateAction
) {
  if (!state?.scheduledRides?.cards?.rides) return state;

  const page = state?.scheduledRides?.rideCardParams?.page;
  if (!page || !['scheduled', 'all', 'active'].includes(page)) return state;

  const { rideId, latitude, longitude, heading, eta, rideStatus } = action.data;

  const rides = state.scheduledRides.cards.rides;

  const rideIndex = rides.findIndex(r => r.id === rideId);
  // if the rideId from the push message doesn't match any of the ride cards
  // in the state, don't even bother updating the state
  if (rideIndex === -1) return state;

  const locations = rides[rideIndex]?.locations
    ? cloneDeep(rides[rideIndex].locations)
    : {};

  // Update the ride's location object by adding the new location to the location array for the current ride status.
  locations[rideStatus] ??= [];
  locations[rideStatus].push({ latitude, longitude, heading, eta, rideId });

  return immutable(state, {
    scheduledRides: {
      cards: { rides: { [rideIndex]: { $merge: { locations } } } }
    }
  });
}

/**
 * Handles the updating of ride status
 */
export function handleRideStatusUpdate(
  state: RideCardsStore,
  action: RideStatusUpdateAction
) {
  // exit because we're not on a rides page or we have no rides
  if (!state?.scheduledRides?.cards?.rides?.length || isEmpty(action.data)) {
    return state;
  }

  const scheduledRides = state.scheduledRides;
  const page = state?.scheduledRides?.rideCardParams?.page || '';

  const { rideId, status, confirmationStatus, eta, cancelledReason, driverPhoneNumber } =
    action.data;

  if (!['scheduled', 'all', 'active', ...ALERT_PAGES].includes(page)) {
    return state;
  }

  // finding the index of the ride card to update the status of
  const index = scheduledRides.cards.rides.findIndex(({ id }) => id === rideId);

  if (index === -1) {
    return setRideCardsCanReload(state);
  }

  // update the status of the ride
  const update: Partial<RideCardsRide> = {
    status,
    confirmation_status: confirmationStatus,
    eta,
    driverPhone: driverPhoneNumber
  };

  if (cancelledReason) update.reject_reason_msg = cancelledReason;

  return immutable(state, {
    scheduledRides: {
      $merge: {
        cards: immutable(scheduledRides.cards, { rides: { [index]: { $merge: update } } })
      }
    }
  });
}

/**
 * Handles adding a new ride or updating a ride from the push service
 */
export function handleNewRide(state: RideCardsStore, action: NewRideAction, updateState) {
  // exit because we're not on a rides page
  if (!state?.scheduledRides?.cards?.rides) {
    return state;
  }

  const page = state?.scheduledRides?.rideCardParams?.page || '';
  const newRide = action.data;
  const rideId = action.data?.rideDetails?.id;

  if (!newRide || !newRide?.rideDetails) return state;

  if (!['scheduled', 'all'].includes(page)) return state;

  const userStore = action.userStore;

  // we want to ensure that search results will have rides updated
  const index = state.scheduledRides.cards.rides.findIndex(({ id }) => id === rideId);

  if (index > -1) {
    const rides = [...state.scheduledRides.cards.rides];
    rides[index] = mapPusherData(newRide, userStore?.vehicleTypes, {
      ...cloneDeep(state.scheduledRides.cards.rides[index]),
      lyftBookingType: state.scheduledRides.cards.rides[index]?.lyftBookingType ?? null,
      tripRides: state.scheduledRides.cards?.rides[index]?.tripRides ?? null
    });

    return updateState({
      scheduledRides: {
        ...state.scheduledRides,
        cards: { ...state.scheduledRides.cards, rides }
      }
    });
  }

  const userId = userStore?.userData?.id;
  if (userId && userId === newRide.rideDetails.bookedBy) {
    let rides = state.scheduledRides.cards.rides;

    if (state?.scheduledRides?.rideCardParams?.query) {
      rides = [...state.scheduledRides.cards.rides];
      rides.unshift(mapPusherData(newRide, userStore?.vehicleTypes, {}));
    }

    return updateState({
      scheduledRides: {
        ...state.scheduledRides,
        cards: { ...state.scheduledRides.cards, rides }
      }
    });
  }

  // add something to state to tell scheduled rides to reload
  return setRideCardsCanReload(state);
}

/**
 * Handle the adding of a new Lyft on demand ride
 */
export function handleAddLyftOnDemand(
  state: RideCardsStore,
  { userStore, data, submittedRide }: AddLyftOnDemandAction
) {
  const rideId = data.id;

  // check to see if rideInfoUpdate already updated the ride
  const index = state?.scheduledRides?.cards?.rides.findIndex(({ id }) => id === rideId);

  if (index !== -1) return state;

  const userData = userStore.userData;
  const vehicleTypes = userStore?.vehicleTypes;
  const bookedByName = `${userData.firstName} ${userData.lastName}`;

  // new ride so add
  const ride = mapSubmitData(data, submittedRide, bookedByName);

  ride.modelName = isNumber(ride.reqVehicleType)
    ? (vehicleTypes?.[ride.reqVehicleType]?.modelName ?? '')
    : '';

  return immutable(state, {
    scheduledRides: {
      cards: { rides: { $unshift: [ride] } }
    }
  });
}

/**
 * Handle adding a new ride to the ride cards
 */
export function handleAddRide(state: RideCardsStore, action: AddRideAction, updateState) {
  if (!action?.data?.rides || isEmpty(action?.data?.rides)) {
    return state;
  }

  let rides = state.scheduledRides.cards.rides;

  const submittedRide = action.submittedRide;

  const userStore = action.userStore;
  const bookedByName = `${userStore.userData.firstName} ${userStore.userData.lastName}`;

  // check to see if rideInfoUpdate already updated the ride
  const vehicleTypes = userStore?.vehicleTypes;

  action.data.rides.forEach(ride => {
    const rideId = ride.id;
    const index = rides.findIndex(({ id }) => id === rideId);

    if (index === -1) {
      ride = mapSubmitDataMultiLeg(ride, submittedRide);
      ride.bookedByName = bookedByName;
      ride.modelName = vehicleTypes?.[ride.reqVehicleType]?.modelName;

      rides = immutable(rides, { $unshift: [ride] });
    } else if (rides[index].confirmation_status !== 'nemt_unable_to_fulfill') {
      return ride;
    } else {
      rides = immutable(rides, {
        [index]: {
          $merge: {
            confirmation_status: ride.confirmation_status,
            vehiclOwnerId: ride.vehiclOwnerId,
            vehicleOwnerId: ride.vehiclOwnerId
          }
        }
      });
    }
  });

  return updateState({
    scheduledRides: {
      ...state.scheduledRides,
      cards: { ...state.scheduledRides.cards, rides }
    }
  });
}

/**
 * Handle the updating of a ride card.
 * Used by several different actions (updating a date, updating a ride, cancelling a ride, etc)
 */
export function handleUpdateRideCard(state: RideCardsStore, action, updateState) {
  const data = action.data;
  const type = action.updateType;

  const rideId = action?.data?.id ?? action?.data?.rideId;
  if (!rideId) return state;

  if (!state?.scheduledRides?.cards?.rides?.length) return state;

  const index = state.scheduledRides.cards.rides.indexOf(rideId);
  if (index === -1) return state;

  const cards = { ...state.scheduledRides.cards };
  let rides = cards.rides;

  // MATCH RIDE WE'RE MODIFYING WITH RIDE IN SCHEDULED RIDES ARRAY
  switch (type) {
    case 'ride': {
      const ride = state.scheduledRides.cards.rides[index];

      const rideUpdate: Partial<RideCardsRide> = {
        vehiclOwnerId: (ride.vehicleOwnerId = data.companyId),
        fromAddress: data.ride_edit_pickupLoc || data.rideEditPickupLoc,
        fromLatitude: data.pickup_latitude || data.pickupLatitude,
        fromLongitude: data.pickup_longitude || data.pickupLongitude,
        fromZipcode: data.pickup_zipcode || data.pickupZipcode,
        toAddress: data.ride_edit_dropoffLoc || data.rideEditDropoffLoc,
        toLatitude: data.dropoff_latitude || data.dropoffLatitude,
        toLongitude: data.dropoff_longitude || data.dropoffLongitude,
        toZipcode: data.dropoff_zipcode || data.dropoffZipcode,
        pmobileNo: data.pPhoneno,
        additionalNotes: data.rideinfo,
        reqVehicleType: data.transport,
        pastRide: data.pastRide || false,
        isPastRide: data.isPastRide || false,
        additionalPassengers: data.additionalPassengers || [],
        dropoff_venue_id: data.dropoff_venue_id || data.dropoffVenueId,
        pickup_venue_id: data.pickup_venue_id || data.pickupVenueId,
        pickupProviderId: data.pickupProviderId || 0,
        pickupProviderName: data.pickupProviderName || '',
        pickupProviderNpi: data.pickupProviderNpi || 0,
        dropoffProviderId: data.dropoffProviderId || 0,
        dropoffProviderName: data.dropoffProviderName || '',
        dropoffProviderNpi: data.dropoffProviderNpi || 0,
        pickupProviderPhone: data.pickupProviderPhone || '',
        dropoffProviderPhone: data.dropoffProviderPhone || '',

        ride_options: {}
      };

      if (!isNil(data.dropoff_venue_id)) {
        rideUpdate.dropoffVenueName = data.dropoffVenueName;
        rideUpdate.dropoffEntranceName = data.dropoffEntranceName;
      }

      if (!isNil(data.pickup_venue_id)) {
        rideUpdate.pickupVenueName = data.pickupVenueName;
        rideUpdate.pickupEntranceName = data.pickupEntranceName;
      }

      if (!isNil(data.patientCustomFields)) {
        rideUpdate.patientCustomFieldValues = data.patientCustomFields;
      }

      if (!isNil(data.compliance)) {
        rideUpdate.complianceValues = data.compliance;
      }

      if (!isNil(data.ride_custom_fields)) {
        rideUpdate.rideCustomFieldValues = data.ride_custom_fields;
      }

      if (!isNil(data.transportSubType)) {
        rideUpdate.ride_options ??= {};
        rideUpdate.ride_options.vehicle_sub_type = data.transportSubType;
      }
      if (!isNil(data.assistanceNeeds)) {
        rideUpdate.ride_options ??= {};
        rideUpdate.ride_options.assistance_needs = data.assistanceNeeds;
      }
      if (!isNil(data.phone2)) {
        rideUpdate.ride_options ??= {};
        rideUpdate.ride_options.phone2 = data.phone2;
      }

      if (!isNil(data.treatmentId)) {
        rideUpdate.treatment_id = data.treatmentId;
        rideUpdate.treatment_name = data.treatmentName;
      }

      rides = immutable(rides, { [index]: { $merge: rideUpdate } });
      break;
    }
    case 'date': {
      const updatedRides = data.updatedRides;

      for (let i = 0; i < data.updatedRides.length; i++) {
        // FOR SCHEDULED RIDES, CANCELLED RIDES AND ARCHIVE RIDES, WE GET A LIST OF UPDATED RIDEIDS.
        // WE HAVE TO MATCH THEM UP WITH RIDES IN THE SCHEDULED RIDES ARRAY IN REDUX.
        const rideId = data.updatedRides[i].id;
        const index = rides.findIndex(({ id }) => id === rideId);

        if (index === -1) continue;

        const updatedRide = rides[index];
        updatedRide.extraTime = data.extraTime;

        let appointmentTime = '';
        let rideStartTime = '';

        if (i === 0) {
          appointmentTime = updatedRides[i].appointmentTime;
          rideStartTime = updatedRides[i].newPickupTime;
        } else {
          appointmentTime = moment(updatedRides[i].appointmentTime.date).format(
            DATE_API_FORMAT
          );
          rideStartTime = moment(updatedRides[i].newPickupTime.date).format(
            DATE_API_FORMAT
          );
        }

        if (!isNil(updatedRide.appointmentTime)) {
          updatedRide.appointmentTime = appointmentTime; // a leg
          updatedRide.rideStartTime = rideStartTime;
        } else if (data.updated_time === 'Will Call') {
          updatedRide.status = 'WillCall';
        } else {
          if (updatedRide.status === 'WillCall') {
            updatedRide.status = 'Scheduled';
          }
          updatedRide.rideStartTime = rideStartTime; // b leg
        }

        rides = immutable(rides, { [index]: { $set: updatedRide } });
        orderScheduledRides(rides);

        // component needs to know the id of updated rides for repositioning
        if (rides[index].status !== 'WillCall') {
          cards.rideIdUpdatedDate = rideId;
        }
      }

      break;
    }
    case 'cancel': {
      const cancelledRides = data.cancelledRides;
      const user = action.userStore.userData;

      for (let i = 0; i < cancelledRides.length; i++) {
        const index = cards.rides.findIndex(({ id }) => id === cancelledRides[i]);

        if (index === -1) continue;

        const reasonId = !isNil(data.reasonId) ? data.reasonId : null;
        const reasonComment = !isNil(data.reasonComment) ? data.reasonComment : '';
        const rejectReasonObj =
          reasonId !== null
            ? data.cancelReasons.find(reason => reason.id === reasonId)
            : {};
        const rejectReason = rejectReasonObj?.reason_desc ?? '';

        rides = immutable(rides, {
          [index]: {
            $merge: {
              reject_reasonId: reasonId,
              reject_reason_msg: reasonComment,
              reject_reason: rejectReason,
              cancelText: `${rejectReason} ${reasonComment}`,
              cancelledByName: `${user?.firstName ?? ''} ${action?.user?.lastName ?? ''}`,
              cancelledBy: user.id,
              status: 'Cancelled'
            }
          }
        });
      }
    }
  }

  return updateState({
    scheduledRides: {
      ...state.scheduledRides,
      cards: { ...cards, rides }
    }
  });
}

/**
 * Handles resetting the scheduled rides state
 */
export function handleResetScheduledRides(state: RideCardsStore, updateState) {
  if (!state?.scheduledRides?.cards) return state;

  return updateState({
    scheduledRides: {
      rideCardParams: state?.scheduledRides?.rideCardParams ?? {}
    }
  });
}

/**
 * Handles the updating of multiple ride cards
 */
export function handleBulkUpdateEvent(state: RideCardsStore) {
  const cards = state?.scheduledRides?.cards;

  if (!cards || cards?.reloadPage) return state;

  return setRideCardsCanReload(state);
}
