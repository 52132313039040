import React from 'react';
import { type PassengerInfoProps } from '~/models';
import { type PassengerInfoSection } from '../PassengerInfo.types';
import {
  Card,
  Is,
  TOKENS,
  TagTransport,
  capitalize,
  type TagTransportProps
} from '@SRHealth/frontend-lib';
import { LANDLINE_WARNING, TRIP_TYPE_OPTIONS } from '../PassengerInfo.constants';
import type { MemberProfileStore, VehicleTypeRecord } from '~/types';
import { useAppSelector } from '~/Modules';
import { formatPhoneNumber } from '~/utilities/helperFunctions';

type SummaryProps = {
  modelData: Readonly<PassengerInfoProps>;
  editState: PassengerInfoSection;
  onEdit: (editState: PassengerInfoSection) => void;
  onCancel: () => void;
};

/** Get the summary label for the subTransportType field. If there is
 * no subTransportType, return an empty string. */
function getSubTransportTypeSummaryLabel(
  tripType: PassengerInfoProps['tripType'] | undefined,
  subTransportType: PassengerInfoProps['subTransportType']
) {
  if (Is.Nil(subTransportType)) return '';

  switch (tripType) {
    case 'private':
      return 'Sub Transport Type';
    case 'public':
      return '';
    case 'mileage-reimbursement':
      return 'Sub Mileage Type';
    case 'alternate':
      return 'Sub Service Type';
    default:
      return '';
  }
}

/** Determine what to display as the sub transport type. */
function getSubTransportType(
  transportType?: VehicleTypeRecord,
  subTransportType?: number
) {
  if (!transportType || !transportType.subTypes) return null;

  if (subTransportType === undefined) return null;

  return (
    transportType.subTypes.find(({ id }) => id === subTransportType)?.['label'] ?? null
  );
}

/** Determine the active transport type record. */
function getActiveTransportType(
  transportTypes: MemberProfileStore['transportTypes'],
  model: Readonly<PassengerInfoProps>
) {
  return model.transportType
    ? transportTypes[model.tripType]?.[model.transportType]
    : undefined;
}

/** Retrieve the trip type label to display. */
function getTripTypeLabel(tripType: PassengerInfoProps['tripType']) {
  return TRIP_TYPE_OPTIONS.find(({ value }) => value === tripType)?.label ?? '';
}

export default function Summary({
  modelData,
  editState,
  onEdit,
  onCancel
}: SummaryProps) {
  const transportTypes = useAppSelector(
    ({ memberProfile }) => memberProfile.transportTypes
  );

  const tripTypeLabel = getTripTypeLabel(modelData.tripType);
  const transportType = getActiveTransportType(transportTypes, modelData);
  const subTransportTypeLabel = getSubTransportTypeSummaryLabel(
    modelData.tripType,
    modelData.subTransportType
  );

  const subTransportTypeVal = getSubTransportType(
    transportType,
    modelData.subTransportType
  );

  /** Display a sub transport type  */
  const showSubTransportType = subTransportTypeLabel && subTransportTypeVal;
  /** Display a phone number and phone number type if we have both. */
  const showTripPhone = modelData.phoneNumberType && modelData.phoneNumber;
  /** If the phone type is landline, display warning that we cannot send SMS. */
  const showLandlineWarning =
    modelData.phoneNumberType === 'landline' && modelData.phoneNumber;

  return (
    <Card label="Summary">
      <Card.Content
        label="Trip Type"
        editable={editState === 'TripType'}
        onEdit={() => onEdit('TripType')}
        onCancel={onCancel}
      >
        {transportType && (
          <div className="flex flex-col gap-[8px] py-[4px]">
            <div className="text-base">{tripTypeLabel}</div>

            <TagTransport
              type={transportType.abbreviation as TagTransportProps['type']}
            />

            {showSubTransportType && (
              <div className="flex flex-row gap-[4px] py-[4px] items-center">
                <div className="heading-sm">{subTransportTypeLabel}:</div>
                <div className="text-base">{subTransportTypeVal}</div>
              </div>
            )}

            {modelData.serviceLevel && (
              <div className="flex flex-row gap-[4px] py-[4px] items-center">
                <div className="heading-sm">Service Level:</div>
                <div className="text-base">{modelData.serviceLevel}</div>
              </div>
            )}
          </div>
        )}
      </Card.Content>
      <Card.Content
        label="Trip Phone Number"
        editable={editState === 'TripPhoneNumber'}
        onEdit={() => onEdit('TripPhoneNumber')}
        onCancel={onCancel}
      >
        {showTripPhone && (
          <div className="flex flex-row items-center">
            <div className="flex-1 heading-sm">
              {capitalize(modelData.phoneNumberType!)}:
            </div>
            <div>{formatPhoneNumber(modelData.phoneNumber!)}</div>
          </div>
        )}

        {showLandlineWarning && (
          <div className={`mt-[8px] text-sm-italic text-${TOKENS.FONT.NEUTRAL}`}>
            {LANDLINE_WARNING}
          </div>
        )}
      </Card.Content>
      <Card.Content
        label="Additional Passengers"
        editable={editState === 'AdditionalPassengers'}
        onEdit={() => onEdit('AdditionalPassengers')}
        onCancel={onCancel}
      >
        {modelData.additionalPassengers.map((passenger, i) => (
          <div key={i} className="flex flex-row">
            <div className="flex flex-row gap-[4px] py-[4px] items-center">
              <div className="heading-sm">Passenger {i + 1}:</div>
              <div className="text-base">{capitalize(passenger.type)}</div>
            </div>
          </div>
        ))}
      </Card.Content>
    </Card>
  );
}
