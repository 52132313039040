import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { IMPORTER } from '~/constants';
import { isEmpty } from '~/utilities/guards';

const ImporterConfirm = props => {
  const { job, step, gotoPlans, gotoMembers, error, importType } = props;

  return (
    <div className="ImporterConfirm">
      {!isEmpty(error) ? (
        <ul className="errors">
          <li>Errors:</li>
          <li key={'uploadError'}>{error}</li>
        </ul>
      ) : null }
      {job.status !== 'pending' ? (
        <ReactLoading
          type="spin"
          className="importerLoading"
        />
      ) : (
        <div className="wrapper">
          <h2>
            {step.index + 1}. Import Confirmed
          </h2>
          <p>
            We will process the file in the background and send you an email when it completes.
            This may take awhile depending on the file size.
          </p>
          {( () => {
            switch(importType){
              case IMPORTER.MEMBER:
                return <p>
                  <a onClick={() => gotoMembers()}>Back to Members.</a>
                </p>;
              case IMPORTER.TREATMENT:
              case IMPORTER.LOCATION:
                return <p>
                  <a onClick={() => gotoPlans()}>Back to Plans.</a>
                </p>;
              case IMPORTER.EXPENSE:
              case IMPORTER.RIDESHARE:
              case IMPORTER.PUBLICTRANSIT:
                return <p>
                  <a href={`//${process.env.REACT_APP_API}/billing/importers/`}>Back to Importers.</a>
                </p>;
              default:
                return <p>
                  <a onClick={() => gotoPlans()}>Back to Plans.</a>
                </p>;
            }
          })()}
        </div>
      )}

    </div>
  );
};

ImporterConfirm.propTypes = {
  step: PropTypes.object,
  job: PropTypes.object,
  error: PropTypes.object,
  gotoPlans: PropTypes.func,
  gotoMembers: PropTypes.func
};

ImporterConfirm.defaultProps = {
  step: {},
  job: {},
  error: {},
  gotoPlans: () => {},
  gotoMembers: () => {}
};

export default ImporterConfirm;
