import axios from '../safeAxios';
import { getRide } from '../rideDetails';
import {
  DATE_API_FORMAT,
  RIDE_HAS_BEEN_SCHEDULED_MESSAGE,
  RIDE_CONVERTED_TO_WILL_CALL_MESSAGE,
  LUMEN_API_BASE_URL
} from '~/constants';
import { token } from '~/utilities/auth.helper';
import moment from 'moment';
import { batch } from 'react-redux';
import type { AppThunk } from '~/Modules';

export const rideCardActions = {
  // ride card actions merged in from pusher.js (and renamed)
  ACTIVE_RIDE: 'rideCards/ACTIVE_RIDE',
  ADD_LYFT_ON_DEMAND: 'rideCards/ADD_LYFT_ON_DEMAND',
  ADD_RIDE: 'rideCards/ADD_RIDE',
  BULK_EDIT_EVENT: 'rideCards/BULK_EDIT_EVENT',
  RIDE_LOCATION_UPDATE: 'rideCards/RIDE_LOCATION_UPDATE',
  RIDE_CARDS_FETCH_START: 'rideCards/RIDE_CARDS_FETCH_START',
  RIDE_CARDS_FETCH_FAIL: 'rideCards/RIDE_CARDS_FETCH_FAIL',
  RIDE_CARDS_FETCH_SUCCESS: 'rideCards/RIDE_CARDS_FETCH_SUCCESS',
  NEW_RIDE: 'rideCards/NEW_RIDE',
  PUSHER_PULL: 'rideCards/PUSHER_PULL',
  RESET_SCHEDULED_RIDES: 'rideCards/RESET_SCHEDULED_RIDES',
  RIDE_CLAIM_STATUS_UPDATE: 'rideCards/RIDE_CLAIM_STATUS_UPDATE',
  RIDE_INFO_UPDATE: 'rideCards/RIDE_INFO_UPDATE',
  RIDE_STATUS_UPDATE: 'rideCards/RIDE_STATUS_UPDATE',
  UPDATE_RIDE_CARD: 'rideCards/UPDATE_RIDE',
  // original ride card actions (some renamed)
  CANCEL_RIDE_COST: 'rideCards/CANCEL_RIDE_COST',
  CANCEL_RIDE_COST_ERROR: 'rideCards/CANCEL_RIDE_COST_ERROR',
  CANCEL_RIDE: 'rideCards/CANCEL_RIDE',
  CANCEL_RIDE_ERROR: 'rideCards/CANCEL_RIDE_ERROR',
  UPDATE_DATE_CHECK: 'rideCards/UPDATE_DATE_CHECK',
  UPDATE_DATE_CHECK_ERROR: 'rideCards/UPDATE_DATE_CHECK',
  UPDATE_DATE: 'rideCards/UPDATE_DATE',
  UPDATE_DATE_ERROR: 'rideCards/UPDATE_DATE',
  UPDATE_RIDE_CHECK: 'rideCards/UPDATE_RIDE_CHECK',
  SET_LOADING_COMPONENT: 'rideCards/SET_LOADING_COMPONENT',
  UPDATE_RIDE_CHECK_ERROR: 'rideCards/UPDATE_RIDE_CHECK_ERROR',
  UPDATE_RIDE: 'rideCards/UPDATE_RIDE',
  RIDE_CREATED: 'rideCards/RIDE_CREATED',
  UPDATE_RIDE_ERROR: 'rideCards/UPDATE_RIDE_ERROR',
  REQUEST_PICKUP: 'rideCards/REQUEST_PICKUP',
  REQUEST_PICKUP_ERROR: 'rideCards/REQUEST_PICKUP_ERROR',
  UPDATE_WILL_CALL_WITH_CHAT: 'rideCards/UPDATE_WILL_CALL_WITH_CHAT',
  UPDATE_WILL_CALL_WITH_CHAT_ERROR: 'rideCards/UPDATE_WILL_CALL_WITH_CHAT_ERROR',
  RESET_CARDS: 'rideCards/RESET_CARDS',
  RESET_UPDATED_CARDS: 'rideCards/RESET_UPDATED_CARDS',
  RESET_SHOW_WILL_CALL_READY_NOW_ERROR: 'rideCards/RESET_SHOW_WILL_CALL_READY_NOW_ERROR',
  RESET_SHOW_UPDATE_DATE_ERROR: 'rideCards/RESET_SHOW_UPDATE_DATE_ERROR'
} as const;

const config = {
  headers: {
    'Authorization': `Bearer ${token()}`,
    'Content-Type': 'application/json'
  }
};

/**
 * Action creator function to fetch ride cards
 */
export const fetchRideCards = params => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RIDE_CARDS_FETCH_START,
      data: params
    });
  };
};

/**
 * Action creator function to reset scheduled rides
 */
export const resetScheduledRides = () => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RESET_SCHEDULED_RIDES
    });
  };
};

/**
 * This function updates the displayed ride card after a ride has been edited, cancelled, or any other update has been made
 * @param {object} params - parameters from updated ride, from rideCards actions
 * @param {string} updateType - date, ride, or cancel update
 */
export const updateRideCard = (params, updateType) => {
  if ('ride_id' in params) params.id = params.ride_id;

  return (dispatch, getState) => {
    const user = getState().user.userData;
    dispatch({
      type: rideCardActions.UPDATE_RIDE_CARD,
      data: params,
      updateType,
      user
    });
  };
};

/**
 * Action creator function to update ride alert claim status
 */
export const updateRideAlertClaimStatus = payload => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RIDE_CLAIM_STATUS_UPDATE,
      data: payload
    });
  };
};

/**
 * When someone clicks on a ride in scheduled rides, that ride is in focus. We don't want to have rides
 * that are in focus fall out of the rides array on busy hospitals or networks, so we must indicate
 * which ride is in focus so that it remains in the rides array and doesn't get removed.
 * @param {object} ride - ride currently in focus
 * @param {boolean} addRide - add or remove ride in focus from redux
 */
export const addActiveRide = (ride, addRide = true) => {
  return dispatch => {
    dispatch({
      type: rideCardActions.ACTIVE_RIDE,
      ride,
      addRide
    });
  };
};

/**
 * Action creator function to add a new Lyft ride to the top of the scheduled rides array
 */
export const addScheduledLyftRideThunk = (response, submittedRide) => {
  return (dispatch, getState) => {
    const userStore = getState().user;

    dispatch({
      type: rideCardActions.ADD_LYFT_ON_DEMAND,
      data: response,
      userStore,
      submittedRide
    });
  };
};

/**
 * Add new ride to the top of the scheduled rides array for non livechat rides
 * @param {object} response - parameters from new ride, from ride booking process
 * @param {object} submittedRide - parameters from new ride, from ride booking process
 * @param {boolean} isOnDemand - on demand?
 */
export const addScheduledRide = (response, submittedRide, isOnDemand = false) => {
  if (isOnDemand) {
    return addScheduledLyftRideThunk(response, submittedRide);
  }

  return (dispatch, getState) => {
    const userStore = getState().user;

    dispatch({
      type: rideCardActions.ADD_RIDE,
      data: response,
      userStore,
      submittedRide
    });
  };
};

/**
 * Action creator function to reset ride cards
 */
export const resetCards = () => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RESET_CARDS
    });
  };
};

/**
 * Action creator function to reset updated ride cards
 */
export const resetUpdatedCards = () => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RESET_UPDATED_CARDS
    });
  };
};

/**
 * Action creator function to reset show will call ready now error
 */
export const resetShowWillCallReadyNowError = () => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RESET_SHOW_WILL_CALL_READY_NOW_ERROR
    });
  };
};

/**
 * Action creator function to reset update date error
 *
 */
export const resetShowUpdateDateError = () => {
  return dispatch => {
    dispatch({
      type: rideCardActions.RESET_SHOW_UPDATE_DATE_ERROR
    });
  };
};
/**
 * Action creator function to get ride cancellation cost
 */
export const cancelRideCost = params => {
  return dispatch => {
    return axios
      .post(`${process.env.REACT_APP_API_URL}/rides/getCancellationCost`, params, config)
      .then(response => {
        dispatch({
          type: rideCardActions.CANCEL_RIDE_COST,
          data: response.data.data,
          params
        });
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.CANCEL_RIDE_COST_ERROR,
          params,
          error
        });
      });
  };
};

/**
 * Action creator function to cancel a ride
 */
export const cancelRide = (id, params, cancelReasons) => {
  const url = `${LUMEN_API_BASE_URL}/api/v1/rides/cancel`;
  return dispatch => {
    return axios
      .post(url, params, config)
      .then(response => {
        batch(() => {
          dispatch(resetCards());
          dispatch(resetUpdatedCards());
          dispatch({
            type: rideCardActions.CANCEL_RIDE,
            data: response.data.data,
            status: response.data.status,
            message: response.data.message,
            params
          });
        });
        if (!response?.data?.data?.error && response?.data?.data?.status !== false) {
          params.cancelledRides = response.data.data.cancelled_rides;
          params.rideId = id;
          params.cancelReasons = cancelReasons;
          dispatch(updateRideCard(params, 'cancel'));
        }
      })
      .catch(error => {
        const response = error.response;
        dispatch({
          type: rideCardActions.CANCEL_RIDE_ERROR,
          params,
          error: response.data,
          status: response.status,
          message: response.statusText
        });
      });
  };
};

/**
 * Action creator function to check if a ride's date can be updated
 */
export const updateDateCheck = params => {
  return dispatch => {
    dispatch({ type: rideCardActions.SET_LOADING_COMPONENT, data: { status: true } });
    return axios
      .put(`${LUMEN_API_BASE_URL}/api/v1/rides/rideDateCheck`, params, config)
      .then(response => {
        dispatch({
          type: rideCardActions.UPDATE_DATE_CHECK,
          data: response?.data?.data ?? {},
          params
        });
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.UPDATE_DATE_CHECK_ERROR,
          params,
          error
        });
      });
  };
};

/**
 * Action creator function to update a ride's date
 */
export const updateDate = params => {
  return dispatch => {
    if (params?.sendChatRequest === true) {
      return dispatch(updateWillCallWithChat(params.ride_id, params.updated_time));
    }

    dispatch({ type: rideCardActions.SET_LOADING_COMPONENT, data: { status: true } });

    return axios
      .put(`${LUMEN_API_BASE_URL}/api/v1/${params.rideType}/updateTime`, params, config)
      .then(response => {
        dispatch(resetCards());

        const responseData = response?.data?.data?.length ? response.data.data : {};
        responseData.status = response.data.status;
        responseData.message = response.data.message;

        dispatch({ type: rideCardActions.UPDATE_DATE, data: responseData, params });

        if (
          responseData?.updated_ride_details &&
          responseData?.error &&
          response?.data?.data?.status
        ) {
          params.updatedRides = responseData.updated_ride_details;
          params.response = responseData;

          dispatch(updateRideCard(params, 'date'));
        } else if (response?.data?.status) {
          if (responseData?.message === RIDE_HAS_BEEN_SCHEDULED_MESSAGE) {
            params.status = 'Scheduled';
            params.rideStartTime = moment
              .unix(responseData?.pickupTime)
              .utc()
              .format(`${DATE_API_FORMAT}[Z]`);
            dispatch(updateRideCard(params, 'date'));
          } else if (responseData?.message === RIDE_CONVERTED_TO_WILL_CALL_MESSAGE) {
            params.status = 'WillCall';
            params.rideStartTime = responseData.rideStartTime;
            dispatch(updateRideCard(params, 'date'));
          }
        }
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.UPDATE_DATE_ERROR,
          params,
          error
        });
      });
  };
};

/**
 * Action creator function to check if a ride can be updated
 */
export const updateRideCheck = params => {
  return dispatch => {
    dispatch({ type: rideCardActions.SET_LOADING_COMPONENT, data: { status: true } });

    const url = `${LUMEN_API_BASE_URL}/api/v1/rides/editCheck`;

    return axios
      .put(url, params, config)
      .then(response => {
        const responseData = response?.data?.data ? response.data.data : {};

        dispatch({
          type: rideCardActions.UPDATE_RIDE_CHECK,
          data: responseData,
          params
        });
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.UPDATE_RIDE_CHECK_ERROR,
          params,
          error
        });
      });
  };
};

/**
 * Action creator function to update a ride
 */
export const updateRide = params => {
  return dispatch => {
    dispatch({ type: rideCardActions.SET_LOADING_COMPONENT, data: { status: true } });

    const url = `${LUMEN_API_BASE_URL}/api/v1/rides/edit`;

    return axios
      .put(url, params, config)
      .then(response => {
        dispatch(resetCards());

        const responseData = response?.data?.data?.length ? response.data.data : {};

        responseData.status = response.data.status;
        responseData.message = response.data.message;
        dispatch(getRide(params.rideId));

        dispatch({
          type: rideCardActions.UPDATE_RIDE,
          data: responseData,
          params
        });

        if (!response?.data?.error?.length && response?.data?.status) {
          dispatch(updateRideCard(params, 'ride'));
        }
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.UPDATE_RIDE_ERROR,
          params,
          error
        });
      });
  };
};

/**
 * Action creator function to flip a will call ride (with chat as well)
 */
export const updateWillCallWithChat = (rideId, updatedTime) => {
  return dispatch => {
    const url = `${process.env.REACT_APP_API_URL}/benbot/reschedule`;

    return axios
      .post(url, { rideId, updated_time: updatedTime }, config)
      .then(response => {
        dispatch({
          type: rideCardActions.UPDATE_WILL_CALL_WITH_CHAT,
          data: response.data.data,
          params: { rideId, updatedTime }
        });
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.UPDATE_WILL_CALL_WITH_CHAT_ERROR,
          params: { rideId, updatedTime },
          error
        });
      });
  };
};

/**
 * Request patient pickup by auto-scheduling an existing ride (e.g. will call)
 * @param  {object}   ride Ride data
 * @returns {Function}      Dispatch function
 */
export const requestPickup = ride => {
  return dispatch => {
    const url = `${process.env.REACT_APP_API_URL}/benbot/reschedule?autoschedule=1`;
    const rideId = ride.id;
    const updatedTime = moment().tz(ride.timezone_format).format('MM/DD/YYYY hh:mm a');

    return axios
      .post(url, { rideId, updated_time: updatedTime }, config)
      .then(response => {
        dispatch({
          type: rideCardActions.REQUEST_PICKUP,
          data: response.data.data,
          params: { rideId, updatedTime }
        });

        const params = {
          ride_id: rideId,
          status: 'Scheduled',
          rideStartTime: response.data.data.newPickupTime
        };
        dispatch(updateRideCard(params, 'date'));
      })
      .catch(error => {
        dispatch({
          type: rideCardActions.REQUEST_PICKUP_ERROR,
          params: { rideId, updatedTime },
          error
        });
      });
  };
};

/**
 * Thunk action for updating ride information.
 */
export const rideInfoUpdateThunk = (data): AppThunk => {
  return function (dispatch, getState) {
    const userStore = getState().user;

    dispatch({
      type: rideCardActions.RIDE_INFO_UPDATE,
      data,
      userStore
    });
  };
};

/**
 * Thunk action for a new ride.
 */
export const newRideThunk = (data): AppThunk => {
  return function (dispatch, getState) {
    const userStore = getState().user;

    dispatch({
      type: rideCardActions.NEW_RIDE,
      data,
      userStore
    });
  };
};

/**
 * Thunk action for handling a created ride.
 */
export const rideCreatedThunk = (data): AppThunk => {
  return function (dispatch, getState) {
    const userStore = getState().user;

    dispatch({
      type: rideCardActions.RIDE_CREATED,
      data,
      userStore
    });
  };
};
