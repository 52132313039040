import React from 'react';
import PropTypes from 'prop-types';
import SvgArrow from '../Svgs/SvgArrow';
import _ from 'lodash-es';
import { Tooltip } from '@material-ui/core';

class DropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      highlightLabel: false,
      placeholder: ''
    };
  }

  componentDidMount() {
    const { id } = this.props;
    if (id !== null) {
      this.findVal();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.disabled && !prevProps.disabled && this.state.showModal) {
      this.setState({ showModal: false });
    }
    if (
      (prevProps.id === null && this.props.id !== null) ||
      prevProps.id !== this.props.id ||
      (!_.isEmpty(this.props.items) && _.isEmpty(prevProps.items))
    ) {
      this.findVal();
    }
  }

  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleClick = () => {
    if (this.props.disabled) {
      return false;
    }
    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {object} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideClick = e => {
    if (this.props.disabled) {
      return false;
    }
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  /**
   * handles select and fires callback
   * @param {event} e - event
   * @param {string} item - action item value
   * @return {undefined}
   */
  selectHandler = (e, item) => {
    this.props.dropDownCallback(e, item);
    this.handleClick();
    this.setState({ highlightLabel: true });
  };

  /**
   * helper funtion to set the default drop down values if it is sent instead of placeholder
   * @return {undefined}
   */
  findVal() {
    const { items, id } = this.props;
    const val = _.find(items, { id: parseInt(id, 10) });
    let placeholder = '';
    if (typeof val !== 'undefined') {
      placeholder = val.name;
    }
    this.setState({
      placeholder
    });
  }

  render() {
    let customClassName = 'CustomDropDown';
    customClassName = this.props.black ? `${customClassName} black` : customClassName;
    customClassName =
      this.props.customClassName !== ''
        ? `${customClassName} ${this.props.customClassName}`
        : customClassName;
    customClassName = this.props.error ? `${customClassName} error` : customClassName;
    customClassName = this.props.disabled
      ? `${customClassName} disabled`
      : customClassName;

    let dropDownField = this.state.highlightLabel
      ? 'dropDownField highlight'
      : 'dropDownField';
    dropDownField = this.props.error ? `${dropDownField} error` : dropDownField;
    dropDownField = this.props.disabled ? `${dropDownField} disabled` : dropDownField;
    const placeholder =
      this.state.placeholder !== '' ? this.state.placeholder : this.props.placeholder;

    return (
      <div className={customClassName}>
        {this.props.showLabel && this.props.label !== '' ? (
          <label className="inputLabel" onClick={this.handleClick}>
            {this.props.required ? <span className="showAsterisk">*</span> : null}
            {this.props.label}
          </label>
        ) : null}
        <div className={dropDownField} onClick={this.handleClick}>
          <span className="transportContent">{placeholder}</span>
          {this.props.disabled ? null : <SvgArrow className="whiteDownArrow" />}
        </div>
        {this.state.showModal ? (
          <div
            className="dropDownModal"
            onBlur={this.handleClickOutside}
            ref={node => (this.node = node)}
          >
            <ul>
              {this.props.dropDownHeading !== '' ? (
                <li className="dropDownHeading">{this.props.dropDownHeading}</li>
              ) : null}
              {this.props.items.map((item, key) => {
                if (_.has(item, 'id') && _.has(item, 'name')) {
                  const itemShow = this.props.sendBackObject ? item : item.name;
                  if (this.props.tooltips && _.has(item, 'tooltip')) {
                    return (
                      <Tooltip key={item.id} title={item.tooltip} arrow placement="right">
                        <li key={key}>
                          <a id={item.id} onClick={e => this.selectHandler(e, itemShow)}>
                            {item.name}
                          </a>
                        </li>
                      </Tooltip>
                    );
                  } else {
                    return (
                      <li key={key}>
                        <a id={item.id} onClick={e => this.selectHandler(e, itemShow)}>
                          {item.name}
                        </a>
                      </li>
                    );
                  }
                } else {
                  return (
                    <li key={key}>
                      <a onClick={e => this.selectHandler(e, item)}>{item}</a>
                    </li>
                  );
                }
              })}
            </ul>
          </div>
        ) : null}
        {this.props.errorText !== '' && this.props.error ? (
          <p className="errorText">{this.props.errorText}</p>
        ) : null}
      </div>
    );
  }
}

DropDown.propTypes = {
  items: PropTypes.array,
  dropDownCallback: PropTypes.func,
  placeholder: PropTypes.string,
  customClassName: PropTypes.string,
  black: PropTypes.bool, // if this is true, dropDownField will be black instead of white
  error: PropTypes.bool, // show error styling if it's true
  errorText: PropTypes.string,
  sendBackObject: PropTypes.bool, // send back object instead of items.name for second selectHandler parameter
  dropDownHeading: PropTypes.string, // unclickable list item displayed at top
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.number,
  tooltips: PropTypes.bool
};

DropDown.defaultProps = {
  items: [],
  dropDownCallback: () => {},
  placeholder: '',
  customClassName: '',
  black: false,
  error: false,
  errorText: '',
  sendBackObject: false,
  dropDownHeading: '',
  showLabel: false,
  label: '',
  disabled: false,
  required: false,
  id: null,
  tooltips: false
};

export default DropDown;
