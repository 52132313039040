import { TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';
import ReactLoading from 'react-loading';

export type LoaderProps = { isLoading: boolean };

export default function Loader({ isLoading }: LoaderProps) {
  return isLoading ? (
    <div
      style={{ zIndex: 10 }}
      className={
        'flex flex-row justify-center items-center w-full h-full ' +
        `absolute bg-${TOKENS.SURFACE.PRIMARY.WHITE}`
      }
    >
      <ReactLoading type="spin" color="#0072D6" width="128px" height="128px" />
    </div>
  ) : null;
}
