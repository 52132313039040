const claimedStatus = {
  CLAIMED: 'Claimed',
  UNCLAIMED: 'Unclaimed',
  RESOLVED: 'Resolved'
};

const { UNCLAIMED, CLAIMED, RESOLVED } = claimedStatus;

const getAbbreviatedUserName = user => `${user?.firstName} ${user?.lastName?.charAt(0)}.`;

export {
  UNCLAIMED, 
  CLAIMED,
  RESOLVED,
  getAbbreviatedUserName
};