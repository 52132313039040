import React from 'react';

const SvgRefresh = props => {
  const {title, className} = props;

  return (
    <svg className={className}>
      <use xlinkHref="#baseline-refresh-icon">
        <svg viewBox="0 0 28 29" id="baseline-refresh-icon">
          <title id="baseline-refresh-icon">{title}</title>
          <path fillRule="nonzero" d="M21.533 6.967L17.78 10.72l9.954-.054.055-9.955-3.754 3.754c-5.47-5.47-14.378-5.42-19.908.11a14.146 14.146 0 0 0-3.795 6.863l3.633-.02A10.478 10.478 0 0 1 6.602 7.05c4.141-4.141 10.835-4.178 14.931-.082zm6.135 10.595l-3.633.02a10.742 10.742 0 0 1-2.637 4.369c-4.141 4.141-10.835 4.178-14.931.082l3.753-3.753-9.954.054-.055 9.955 3.754-3.754c5.47 5.47 14.378 5.42 19.908-.11a14.146 14.146 0 0 0 3.795-6.863z"/>
        </svg>
      </use>
    </svg>
  );
};

export default SvgRefresh;
