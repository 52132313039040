import { cloneDeep } from 'lodash-es';

// constants for route data
const INNER_LAYER = {
  color: '#03b3fd',
  width: 5,
  opacity: 1,
  id: 'bookingRoute'
};

const OUTER_LAYER = {
  color: '#0084bb',
  width: 7,
  opacity: 1,
  id: 'bookingRouteOuter'
};

export const ROUTES = {
  bookingRoute: {
    innerLayer: cloneDeep(INNER_LAYER),
    outerLayer: cloneDeep(OUTER_LAYER)
  },
  startedRoute: {
    innerLayer: { ...cloneDeep(INNER_LAYER), id: 'startedRoute' },
    outerLayer: { ...cloneDeep(OUTER_LAYER), id: 'startedRouteOuter' }
  },
  inboundRoute: {
    innerLayer: {
      color: '#222',
      width: 6,
      opacity: 0.8,
      id: 'inboundRoute'
    },
    outerLayer: {}
  }
};
