import type { FormInputProps } from '~/Shared/Components/Form/FormInput';
import { token } from '~/utilities/auth.helper';
import type { App } from 'contracts/mod';
import type { AppLayerErrorResponse, RidesharePreferenceNickName } from '~/types';

const MEMBER_URL_V1 = `${process.env.APP_LAYER_URL}/member/v1` as const;

/**
 * Retrieves the mobility form template
 * @returns {GetMobilityFormResponse}
 */
export async function getMobilityForm() {
  return fetch(`${MEMBER_URL_V1}/forms/mobility`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(res => res.json() as Promise<App.Member.V1.Forms.GetMobilityFormResponse>);
}

/**
 * Submits a members completed mobility assessment. The endpoint
 * will return the assessed recommendation for the user
 * @param {number} passengerId
 * @param {string} formId
 * @param {object} form
 * @param {string|undefined} ridesharePreference
 * @param {string|undefined} defaultMode
 * @returns {object}
 */
export async function submitMobilityAssessment(
  passengerId: number,
  formId: string,
  form: FormInputProps[],
  ridesharePreference: RidesharePreferenceNickName,
  defaultMode: App.Member.V1.Forms.SubmitMobilityFormRequest['defaultMode']
): Promise<App.Member.V1.Forms.SubmitMobilityFormResponse | AppLayerErrorResponse> {
  const body: App.Member.V1.Forms.SubmitMobilityFormRequest = {
    passengerId,
    formId,
    submission: form as App.Member.V1.Shared.Forms.ISubmissionField[],
    ridesharePreference,
    defaultMode
  };

  return fetch(`${MEMBER_URL_V1}/forms/mobility`, {
    method: 'post',
    headers: { Authorization: `Bearer ${token()}` },
    body: JSON.stringify(body)
  }).then(res => res.json());
}

/**
 * Retrieves the most recent completed mobility assessment for the member
 * @param {number} passengerId
 * @returns {object}
 */
export async function getMobilityAssessment(
  passengerId: number
): Promise<App.Member.V1.Passengers.GetMobilityAssessmentResponse> {
  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/mobility-assessment`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(res => res.json());
}

/**
 * Retrieve the valid rideshare preferences for the member
 * @param {number} passengerId
 * @returns {object}
 */
export async function getRidesharePreferences(
  passengerId: number
): Promise<App.Member.V1.Passengers.GetRidesharePreferenceOptionsResponse> {
  return fetch(
    `${MEMBER_URL_V1}/passengers/${passengerId}/preference-options/rideshare`,
    { headers: { Authorization: `Bearer ${token()}` } }
  ).then(res => res.json());
}

/** Check the phone type. */
export async function getPhoneType(
  passengerId: string,
  phoneNumber: string
): Promise<App.Member.V1.Passengers.GetPhoneTypeResponse> {
  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/phone/type/${phoneNumber}`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(res => res.json());
}

/** Retrieve the most recently booked ride for a passenger id. */
export async function getPassengerLastRide(
  passengerId: string
): Promise<App.Member.V1.Passengers.GetLastRideResponse> {
  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/ride-history/last`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(res => res.json());
}

/** Updates passengers opt out value for a given phone number. The
 * phone number does not need to be their primary number. */
export async function putSmsPermissionsUpdate(
  passengerId: string | number,
  phoneNumber: string,
  phoneType: string,
  phoneOptOut: boolean
): Promise<App.Member.V1.Passengers.UpdateSmsPermissionsResponse> {
  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/phone/sms-permissions`, {
    method: 'put',
    headers: { Authorization: `Bearer ${token()}` },
    body: JSON.stringify({ phoneNumber, phoneType, phoneOptOut })
  }).then(res => res.json());
}

/**
 * Retrieves all the required member portal support tab information for the
 * passenger
 * @param passengerId '
 * @returns
 */
export async function getMemberPortalSupportInfo(
  passengerId: string
): Promise<App.Member.V1.Passengers.GetMemberPortalInfoResponse> {
  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/member-portal-info`, {
    headers: { Authorization: `Bearer ${token()}` }
  }).then(res => res.json());
}

/**
 * Creates a new member portal status entry for the member portal support page
 * @param passengerId
 * @param isActive
 * @param deactivationReason
 * @returns {object}
 */
export async function createMemberPortalStatus(
  passengerId: number,
  isActive: number,
  deactivatedReason: string
): Promise<
  App.Member.V1.Passengers.CreateMemberPortalStatusResponse | AppLayerErrorResponse
> {
  const body: App.Member.V1.Passengers.CreateMemberPortalStatusRequest = {
    passengerId,
    isActive,
    deactivatedReason
  };

  return fetch(`${MEMBER_URL_V1}/passengers/${passengerId}/member-portal-status`, {
    method: 'post',
    headers: { Authorization: `Bearer ${token()}` },
    body: JSON.stringify(body)
  }).then(res => res.json());
}

/**
 * Sends an email to the one provided in the member portal tab
 * @param passengerId
 * @param email
 * @returns {object}
 */
export async function sendMemberPortalEmail(
  passengerId: number,
  email: string
): Promise<
  App.Member.V1.Passengers.SendMemberPortalWelcomeEmailResponse | AppLayerErrorResponse
> {
  const body: App.Member.V1.Passengers.SendMemberPortalWelcomeEmailRequest = {
    passengerId,
    email
  };

  return fetch(
    `${MEMBER_URL_V1}/passengers/${passengerId}/send-member-portal-welcome-email`,
    {
      method: 'post',
      headers: { Authorization: `Bearer ${token()}` },
      body: JSON.stringify(body)
    }
  ).then(res => res.json());
}
