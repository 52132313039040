import React from 'react';
import { Button as MUIButton } from '@material-ui/core';

const Button = props => {
  const { label = '' } = props;
  const onClick = () => props.onClick && props.onClick();
  return (
    <MUIButton {...props} className={props.className} onClick={onClick}>
      {' '}
      {label}
    </MUIButton>
  );
};

export default Button;
