import { type TripType } from '~/models/PassengerInfo.model';

/**
 * A breakdown of the trip types associated with the abbreviations of
 * each vehicle transport type.
 *
 * If a transport type is classified as private then it is also valid
 * as a public trip type.
 *
 * This is being _temporarily_ hardcoded for RBF 4.0. Once the backend
 * is ready to provide this information, this will be removed.
 */
export const TRANSPORT_TYPE_CLASSIFICATIONS: Record<string, TripType> = Object.freeze({
  STR: 'private',
  WCV: 'private',
  BLS: 'private',
  AMB: 'private',
  WCX: 'private',
  SDN: 'private',
  LFT: 'private',
  ALS: 'private',
  MRA: 'mileage-reimbursement',
  MRV: 'mileage-reimbursement',
  MRX: 'mileage-reimbursement',
  AIR: 'alternate',
  GND: 'alternate',
  BUS: 'alternate',
  MAL: 'alternate',
  AFM: 'alternate',
  UBR: 'private'
});
