/* eslint-disable react/display-name */
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { camelCase } from 'lodash-es';
import CurrencyInput from 'react-currency-input-field';
import { AutoComplete } from 'mapbox-components';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import {
  DateInput,
  PaidByDropdown,
  CategoryDropdown,
  NumberOfNightsDropdown,
  NumberOfDaysDropdown,
  ChildExpenseStatus
} from '../Slideout/ExpenseInputs';

import ExpensesService from '~/services/expenses.service';

const {
  showAddressFields,
  showMerchantAddress,
  showDistanceMileageFields,
  showNumberOfNightsDropdown,
  showNumberOfDaysDropdown
} = new ExpensesService();

/* 
  All the data/components for expense form inputs
*/
const expenseFormInputs = (
  {
    amount,
    paidBy,
    category,
    merchant,
    distance,
    toAddress,
    description,
    fromAddress,
    expenseDate,
    ratePerMile,
    reimbursable,
    merchantAddress,
    paidDate,
    numberOfNights,
    numberOfDays,
    status,
    cancelledReason,
    rejectedReason
  },
  setField,
  setFields,
  options = { expenseSettings: {} },
  mode
) => {
  const report = useSelector(state => state.memberExpenses.report);
  const { expenseStatuses } = useSelector(state => state.user);
  const toAddressRef = useRef();
  const fromAddressRef = useRef();

  return [
    {
      name: 'status',
      label: 'Expense Status',
      show: true,
      value: '',
      required: true,
      Component: () => (
        <ChildExpenseStatus
          key="status"
          expenseStatuses={expenseStatuses.map(v => ({ value: v, label: v }))}
          setField={setField}
          status={status}
        />
      )
    },
    {
      name: `${camelCase(status)}Reason`,
      label: `${status} Reason`,
      show: ['Cancelled', 'Rejected'].includes(status),
      value: status === 'Cancelled' ? cancelledReason : rejectedReason,
      required: ['Cancelled', 'Rejected'].includes(status),
      Component: [
        <textarea
          key="description"
          value={status === 'Cancelled' ? cancelledReason : rejectedReason}
          onChange={({ target: { value } }) =>
            setField({ name: `${camelCase(status)}Reason`, value })
          }
          maxLength="255"
        ></textarea>
      ]
    },
    {
      name: 'expenseDate',
      label: 'Expense Date',
      show: true,
      value: expenseDate,
      required: true,
      Component: () => (
        <DateInput
          name="expenseDate"
          value={expenseDate}
          setField={setField}
          minDate={report.fromDate}
          maxDate={report.toDate}
        />
      )
    },
    {
      name: 'paidDate',
      label: 'Paid Date',
      show: true,
      value: paidDate,
      required: false,
      Component: () => <DateInput name="paidDate" value={paidDate} setField={setField} />
    },
    {
      name: 'category',
      label: 'Category',
      show: true,
      value: category,
      required: true,
      Component: props => (
        <CategoryDropdown
          key="category"
          category={category}
          setField={setField}
          disabled={props.disabled}
        />
      )
    },
    {
      name: 'merchant',
      label: 'Merchant',
      show: true,
      value: merchant,
      required: true,
      Component: [
        <input
          key="merchant"
          className="exp-input"
          value={merchant}
          onChange={({ target: { value } }) =>
            setField({
              name: 'merchant',
              value
            })
          }
        />
      ]
    },
    {
      name: 'merchantAddress',
      label: 'Merchant Address',
      show: showMerchantAddress({ category, merchant }),
      value: merchantAddress,
      required: showMerchantAddress({ category, merchant }),
      Component: () => (
        <AutoComplete
          query={merchantAddress}
          inputId="merchant-address"
          disabled={false}
          onSuggestionSelect={suggestion => {
            setField({
              name: 'merchantAddress',
              value: suggestion
            });
          }}
          autocomplete={true}
          country="us"
          fuzzyMatch={true}
          routing={true}
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        />
      )
    },
    {
      name: 'fromAddress',
      label: 'From Address',
      show: showAddressFields({ category }),
      value: fromAddress,
      required: showAddressFields({ category }),
      Component: () => (
        <AutoComplete
          ref={fromAddressRef}
          query={fromAddress.address}
          inputId="fromAddress"
          disabled={false}
          autocomplete="off"
          onSuggestionSelect={(suggestion, lat, lng) => {
            setField({
              name: 'fromAddress',
              value: { address: suggestion, lngLat: `${lng},${lat}` }
            });
          }}
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        />
      )
    },
    {
      name: 'swapAddress',
      label: '',
      show: showAddressFields({ category }),
      required: false,
      Component: () => (
        <a
          className="swap-right"
          onClick={() => {
            const toAddressQuery = toAddressRef.current?.state.query;
            const fromAddressQuery = fromAddressRef.current?.state.query;
            setFields({
              fromAddress: toAddressQuery ? toAddress : { address: null, lngLat: null },
              toAddress: fromAddressQuery ? fromAddress : { address: null, lngLat: null }
            });
          }}
        >
          <SwapVertIcon />
        </a>
      )
    },
    {
      name: 'toAddress',
      label: 'To Address',
      show: showAddressFields({ category }),
      value: toAddress,
      required: showAddressFields({ category }),
      Component: () => (
        <AutoComplete
          ref={toAddressRef}
          query={toAddress.address}
          inputId="toAddress"
          disabled={false}
          autocomplete="off"
          onSuggestionSelect={(suggestion, lat, lng) => {
            setField({
              name: 'toAddress',
              value: { address: suggestion, lngLat: `${lng},${lat}` }
            });
          }}
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        />
      )
    },
    {
      name: 'distance',
      label: 'Distance',
      show: showDistanceMileageFields({ category }),
      value: distance,
      required: false,
      Component: () => (
        <div className="exp-input bg-disabled ta-right">
          {Number(distance).toFixed(1) || 0} Miles
        </div>
      )
    },
    {
      name: 'ratePerMile',
      label: 'Rate Per Mile',
      show: showDistanceMileageFields({ category }),
      value: distance,
      required: showDistanceMileageFields({ category }),
      Component: [
        <div key="rate-per-mile-input">
          <CurrencyInput
            id="rate-per-mile-input"
            name="ratePerMile"
            prefix="$"
            value={ratePerMile}
            className="exp-input currency-input"
            precision={3}
            placeholder="0.000"
            allowDecimals={true}
            decimalsLimit={3}
            onChange={value => {
              setField({
                name: 'ratePerMile',
                value
              });
            }}
            turnOffSeparators={true}
          />
          {ratePerMile > 999.999 ? (
            <div className="tar fc-red">Max value allowed $999.999</div>
          ) : null}
          {amount < 0 ? (
            <div className="tar fc-red">Negative numbers not allowed</div>
          ) : null}
          {isNaN(ratePerMile) ? <div className="tar fc-red">Invalid number</div> : null}
        </div>
      ]
    },
    {
      name: 'description',
      label: 'Description',
      show: true,
      value: description,
      required: false,
      Component: [
        <textarea
          key="description"
          value={description}
          onChange={({ target: { value } }) => setField({ name: 'description', value })}
          maxLength="250"
        ></textarea>
      ]
    },
    {
      name: 'amount',
      label: 'Amount',
      show: true,
      value: amount,
      required: false,
      Component: [
        <div key="amount-input">
          <CurrencyInput
            name="amount"
            prefix="$"
            value={amount}
            className={`exp-input currency-input ${
              showDistanceMileageFields({ category }) ? 'bg-disabled' : ''
            }`}
            disabled={showDistanceMileageFields({ category })}
            precision={2}
            placeholder="0.00"
            allowDecimals={true}
            decimalsLimit={2}
            onChange={value => {
              setField({
                name: 'amount',
                value
              });
            }}
            turnOffSeparators={true}
          />
          {amount > 999999.99 ? (
            <div className="tar fc-red">Max value allowed $999,999.99</div>
          ) : null}
          {amount < 0 ? (
            <div className="tar fc-red">Negative numbers not allowed</div>
          ) : null}
          {isNaN(amount) ? <div className="tar fc-red">Invalid number</div> : null}
        </div>
      ]
    },
    {
      name: 'numberOfNights',
      label: 'Number of Nights',
      value: numberOfNights,
      required: false,
      show: showNumberOfNightsDropdown({ category, mode }),
      Component: () => (
        <NumberOfNightsDropdown
          name="numberOfNights"
          numberOfNights={numberOfNights}
          setField={setField}
        />
      )
    },
    {
      name: 'numberOfDays',
      label: 'Number of Days',
      value: numberOfDays,
      required: false,
      show: showNumberOfDaysDropdown({ category, mode }),
      Component: () => (
        <NumberOfDaysDropdown
          name="numberOfDays"
          numberOfDays={numberOfDays}
          setField={setField}
        />
      )
    },
    {
      name: 'paidBy',
      label: 'Paid By',
      value: paidBy,
      show: !reimbursable,
      required: !reimbursable,
      Component: () => {
        return (
          <PaidByDropdown
            paidBy={paidBy}
            setField={setField}
            paid_by_options={options.expenseSettings.paidBy}
          />
        );
      }
    },
    {
      name: 'reimbursable',
      label: '',
      show: true,
      value: reimbursable,
      required: false,
      Component: () => (
        <div>
          <input
            onChange={({ target: { checked } }) =>
              setField({ name: 'reimbursable', value: checked })
            }
            type="checkbox"
            checked={reimbursable}
          />
          &nbsp;
          <div className="label">Reimbursable</div>
        </div>
      )
    }
  ];
};

export default expenseFormInputs;
