import { createAppAsyncThunk } from '~/Modules';
import { getAvailableTimes } from '~/services/rideBooking.service';
import { isPastBooking } from '../RideBooking.utils';

/** Thunk to get the time restrictions for the ride booking flow */
export const getTimeRestrictionsThunk = createAppAsyncThunk(
  'rideBooking/getTimeRestrictions',
  (_, { rejectWithValue, getState }) => {
    const rideBookingStore = getState().rideBooking;

    if (isPastBooking(rideBookingStore.date.date)) {
      return undefined;
    }

    try {
      const { tripType, transportType } = rideBookingStore.passengerInfo;
      const currentDate = rideBookingStore.date.date;

      if (!tripType || !transportType || !currentDate) {
        throw Error('tripType, transportType and currentDate are required');
      }

      return getAvailableTimes(transportType, tripType, currentDate).then(
        ({ data }) => data
      );
    } catch (error) {
      return rejectWithValue({ error: error.response });
    }
  }
);
