/** This is an obnoxious shim made necessary because React Router has decided
 * that they don't want to support anyone using class based components anymore. */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
  type RouteComponentProps
} from 'react-router-dom';

type WithRouterProps<C extends React.ComponentType<any>> = C extends React.ComponentClass
  ? { wrappedComponentRef?: React.Ref<InstanceType<C>> | undefined }
  : Record<string, never>;

interface WithRouterStatics<C extends React.ComponentType<any>> {
  WrappedComponent: C;
}

export default function withRouter<
  P extends RouteComponentProps<any>,
  C extends React.ComponentType<P>
>(
  Component: C & React.ComponentType<P>
): React.ComponentClass<Omit<P, keyof RouteComponentProps<any>> & WithRouterProps<C>> &
  WithRouterStatics<C> {
  const ComponentWithRouter = props => {
    const params = useParams();
    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    return (
      <Component
        {...props}
        params={params}
        match={match}
        history={history}
        location={location}
      />
    );
  };

  return ComponentWithRouter as unknown as React.ComponentClass<
    Omit<P, keyof RouteComponentProps<any>> & WithRouterProps<C>
  > &
    WithRouterStatics<C>;
}
