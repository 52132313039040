import React, { useMemo } from 'react';
import Venues from '../../Venues/Venues';
import { standardizeAddresses } from '~/utilities/helperFunctions';

export const VenuesContainer = ({
  showSavedAddress,
  selectAutoComplete,
  resetAutoComplete,
  onChange,
  toggleLoading,
  bookingData,
  updateRideDetails
}) => {
  let rideDetails: BookingDataStore | RideData = bookingData;
  if (bookingData.bookingType === 'multileg') {
    rideDetails = bookingData.rides[bookingData?.currentRideIndex ?? 0];
  }
  const addresses = useMemo(() => standardizeAddresses(rideDetails), [rideDetails]);

  // TODO: ERROR HANDLING
  return (
    <div className="left">
      <Venues
        type="pickup"
        address={addresses.pickup}
        rideDetails={rideDetails}
        showSavedAddress={showSavedAddress}
        onChange={e => onChange(e.target.value, 'pickup')}
        updateRideDetails={updateRideDetails}
        onSelectAutoComplete={selectAutoComplete}
        onResetAutoComplete={resetAutoComplete}
        toggleLoading={toggleLoading}
      />
      <Venues
        type="dropoff"
        address={addresses.dropoff}
        rideDetails={rideDetails}
        showSavedAddress={showSavedAddress}
        onChange={e => onChange(e.target.value, 'dropoff')}
        updateRideDetails={updateRideDetails}
        onSelectAutoComplete={selectAutoComplete}
        onResetAutoComplete={resetAutoComplete}
        toggleLoading={toggleLoading}
      />
    </div>
  );
};
