import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgMagnifyingGlass from '~/Shared/Components/Svgs/SvgMagnifyingGlass.js';
import PlanListItem from './PlanListItem';
import Scroller from '~/Shared/Components/Scroller/Scroller';

const FILTER = {
  TOTAL: 'total',
  ENABLED: 'enabled',
  DISABLED: 'disabled'
};

class PlansList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      selectedFilter: FILTER.TOTAL
    };
  }

  /**
   * Handle form submit
   * @param {Object} e SyntheticEvent
   * @returns {undefined}
   */
  handleSubmit(e) {
    e.preventDefault();
  }

  /**
   * Handle filter selection
   * @param {String} selectedFilter FILTER value
   * @returns {undefined}
   */
  handleFilterSelection(selectedFilter) {
    this.setState({ selectedFilter });
  }

  /**
   * Get plans by search query and filter type
   * @param {String} searchQuery search query
   * @param {String} selectedFilter FILTER value
   * @returns {Array} List of filtered plans
   */
  getFilteredPlans(searchQuery, selectedFilter) {
    const { plans } = this.props;
    let filteredPlans = Object.keys(plans).map(id => plans[id]);

    filteredPlans = filteredPlans.filter(plan => {
      const planName = plan.planName.toLowerCase();
      const isSearchMatch = planName.indexOf(searchQuery.toLowerCase()) !== -1;
      const isFilterMatch =
        selectedFilter === FILTER.ENABLED
          ? plan.isEnabled
          : selectedFilter === FILTER.DISABLED
            ? !plan.isEnabled
            : true;
      return isSearchMatch && isFilterMatch;
    });

    return filteredPlans;
  }

  handleLazyLoad = () => {
    if (this.props.totalPlanCount > Object.keys(this.props.plans).length) {
      this.props.getMorePlans({ incrementPage: true });
    }
  };

  /**
   * Get counts for filtered plans
   * @param {Object} plans Plans
   * @returns {Object} totalCount, enabledCount, disabledCount
   */
  getCounts(plans) {
    const plansList = Object.keys(plans).map(id => plans[id]);
    const totalCount = this.props.totalPlanCount;
    let enabledCount = 0;
    let disabledCount = 0;

    plansList.forEach(plan => {
      if (plan.isEnabled) {
        enabledCount++;
      } else {
        disabledCount++;
      }
    });

    return { totalCount, enabledCount, disabledCount };
  }

  render() {
    const { plans, isSuperAdmin, addNewPlan, selectedId, setSelectedId } = this.props;
    const { searchQuery, selectedFilter } = this.state;
    const filteredPlans = this.getFilteredPlans(searchQuery, selectedFilter);
    const { totalCount, enabledCount, disabledCount } = this.getCounts(plans);

    return (
      <div className="PlansList">
        <div className="listWrapper">
          <form className="search" onSubmit={this.handleSubmit}>
            <input
              type="text"
              placeholder="Search"
              value={searchQuery}
              onChange={e => this.setState({ searchQuery: e.target.value })}
            />
            <SvgMagnifyingGlass />
            <div className="filters">
              <span
                className={selectedFilter === FILTER.TOTAL ? 'selected' : null}
                onClick={() => this.handleFilterSelection(FILTER.TOTAL)}
              >
                Total: {totalCount}
              </span>
              <span
                className={selectedFilter === FILTER.ENABLED ? 'selected' : null}
                onClick={() => this.handleFilterSelection(FILTER.ENABLED)}
              >
                Enabled: {enabledCount}
              </span>
              <span
                className={selectedFilter === FILTER.DISABLED ? 'selected' : null}
                onClick={() => this.handleFilterSelection(FILTER.DISABLED)}
              >
                Disabled: {disabledCount}
              </span>
            </div>
          </form>
          {isSuperAdmin ? (
            <div className="addPlan" onClick={() => addNewPlan()}>
              +
            </div>
          ) : null}
          <div className="innerListWrapper">
            {isSuperAdmin && filteredPlans.length === 0 ? (
              <div className="noPlans">
                <span>You don&apos;t have any plans.</span>
                <button className="" onClick={() => addNewPlan()}>
                  Add a Plan
                </button>
              </div>
            ) : (
              <Scroller
                pageStart={0}
                loadMore={this.handleLazyLoad}
                hasMore={true}
                initialLoad={false}
                useWindow={false}
              >
                <ul>
                  {filteredPlans.map(plan => (
                    <PlanListItem
                      key={plan.id}
                      plan={plan}
                      selectedId={selectedId}
                      setSelectedId={setSelectedId}
                      isSuperAdmin={isSuperAdmin}
                    />
                  ))}
                </ul>
              </Scroller>
            )}
          </div>
        </div>
        <div className="noPlanSelected">Select a Plan to view more details.</div>
      </div>
    );
  }
}

PlansList.propTypes = {
  plans: PropTypes.object,
  totalPlanCount: PropTypes.number,
  getMorePlans: PropTypes.func,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  addNewPlan: PropTypes.func
};

PlansList.defaultProps = {
  plans: {},
  selectedId: {},
  totalPlanCount: 0,
  getMorePlans: () => {},
  setSelectedId: () => {},
  isSuperAdmin: false,
  addNewPlan: () => {}
};

export default PlansList;
