/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * A simple library to add named slots to React components. Wrapping your
 * component export in the `withSlots()` method will automatically add
 * (and effectively namespace) the slot components to the parent.
 *
 * _e.g._ export default withSlots(MemberNav, {
 *      ErrorMsg: MemberNavErrorMsg,
 *      SuccessMsg: MemberNavSuccessMsg,
 *      EditButton: MemberNavEditButton
 *    });
 *
 * Any component importing the MemberNav can then pass the slots using
 * <MemberNav>
 *  <MemberNav.ErrorMsg></MemberNav.ErrorMsg>
 *  <MemberNav.SuccessMsg></MemberNav.SuccessMsg>
 *  <MemberNav.EditButton></MemberNav.EditButton>
 * </MemberNav>
 *
 * Named slotted children can be retrieved by calling `getSlots()` in the
 * parent component and passing in the children prop and the same schema used
 * in the export statement.
 *
 * The returned object will map the slot name to the JSX Element. From the
 * above example slots.ErrorMsg would link to the MemberNav.ErrorMsg child.
 *
 * You can allow for repeat slots by wrapping the component type in your schema,
 * in square brackets.
 *
 * _e.g._ export default withSlots(MemberNav, {
 *      ...
 *      SuccessMsg: [MemberNavSuccessMsg],
 *    });
 *
 * This indicates that multiple Success message components can be slotted and
 * returned
 */
import type { JSXElementConstructor } from 'react';
import React, { useMemo } from 'react';

export type SlotConstructor<P = any> = (props: P) => JSX.Element | JSX.Element[] | null;

export type SlotDefinition<P = { [x: string]: any }> = {
  [x: string]: SlotConstructor<P> | readonly [SlotConstructor<P>];
};

type Slots<T extends SlotDefinition> = {
  [Property in keyof T]: T[Property] extends [SlotConstructor]
    ? ReturnType<T[Property]['0']> | ReturnType<T[Property]['0']>[]
    : T[Property] extends SlotConstructor
      ? ReturnType<T[Property]> | ReturnType<T[Property]>[]
      : any;
};

export type withSlotProps<P, T extends SlotDefinition> = P & {
  slots: Slots<T>;
};

/**
 * Creates a record containing the JSX elements
 * passed as children on the component
 * @param children
 * @param schema
 * @returns
 */
export function getSlots(
  children: undefined | JSX.Element | JSX.Element[],
  schema: SlotDefinition
) {
  const sortedChildren: (undefined | JSX.Element | JSX.Element[])[] = [];
  const sortedSlots: Partial<Slots<typeof schema>> = {};
  const constructorMap = new WeakMap();

  for (const slot in schema) {
    if (Array.isArray(schema[slot])) {
      constructorMap.set(schema[slot][0], slot);
    } else {
      constructorMap.set(schema[slot], slot);
    }
  }

  if (!children) {
    children = [];
  } else if (!Array.isArray(children)) {
    children = [children];
  }

  return children.reduce(
    (p, cv) => {
      const childType = cv.type;

      if (constructorMap.has(childType)) {
        const key = constructorMap.get(childType);
        const allowMultiple = Array.isArray(schema[key]);

        // Only one instance of element. Insert
        if (!allowMultiple) {
          p.slots[key] = cv;
        } else if (!(key in p.slots)) {
          // Multiple allowed - first ecounter. Treat as single
          p.slots[key] = cv;
        } else if (Array.isArray(p.slots[key])) {
          // Multiple allowed - stack 'em
          (p.slots[key] as JSX.Element[]).push(cv);
        } else {
          // Multiple allowed - encounter second instance of element
          p.slots[key] = [p.slots[key] as JSX.Element, cv];
        }
      } else {
        p.children.push(cv);
      }

      return p;
    },
    { children: sortedChildren, slots: sortedSlots as Slots<typeof schema> }
  );
}

/**
 * Automatically applies a Slot Signature to the
 * parent component
 * @param parent
 * @param schema
 * @returns
 */
export function withSlots<P, T extends SlotDefinition>(
  Parent: JSXElementConstructor<P>,
  schema: T
) {
  function SlotProvider(props: P & { children?: JSX.Element }) {
    const { slots, children } = useMemo(
      () => getSlots(props?.children, schema),
      [props?.children]
    );
    return (
      <Parent {...props} slots={slots}>
        {children}
      </Parent>
    );
  }

  const slots = Object.fromEntries(
    Object.entries(schema).map(([key, val]) => [key, Array.isArray(val) ? val[0] : val])
  ) as {
    readonly [Property in keyof T]: T[Property] extends readonly [SlotConstructor]
      ? T[Property]['0']
      : T[Property];
  };

  return Object.assign(SlotProvider as JSXElementConstructor<Omit<P, 'slots'>>, slots);
}
