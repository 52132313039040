import type { MemberSavedAddress } from '~/services/savedAddress.service';
import type { AddressSavedAddress } from '~/types';
import { isMemberSavedAddress } from './guards';
import { sortStrings } from './helperFunctions';

export const formatSavedAddresses = (addresses: MemberSavedAddress[]) => {
  return (
    addresses
      // Force only valid saved addresses
      .filter(address => isMemberSavedAddress(address))
      .map(add => ({ ...add, label: add?.name }))
      // Sort alphabetically
      .sort(sortStrings('name'))
      // Sort to keep default address at index 0
      .sort((a: MemberSavedAddress, b: MemberSavedAddress) => {
        if (a.is_default === b.is_default) return 0;

        return a.is_default ? -1 : 1;
      }) as MemberSavedAddress[]
  );
};

export const getSavedAddressById = (
  id: AddressSavedAddress['id'],
  addresses: MemberSavedAddress[]
) => {
  if (!id || !addresses.length) return [];

  // eslint-disable-next-line eqeqeq
  const match = addresses.find(a => a?.id == id);

  return match ? [match] : [];
};
