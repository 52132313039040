import React, { createContext, useState, useContext } from 'react';

type SavedLocationsDrawerMode = 'list' | 'create' | 'closed';

interface SavedLocationsContextType {
  savedLocationsDrawerMode: SavedLocationsDrawerMode;
  setSavedLocationsDrawerMode: (mode: SavedLocationsDrawerMode) => void;
}

export const SavedLocationsContext = createContext<SavedLocationsContextType | undefined>(
  undefined
);

export const SavedLocationsProvider = ({ children }) => {
  const [savedLocationsDrawerMode, setSavedLocationsDrawerMode] =
    useState<SavedLocationsDrawerMode>('closed');

  return (
    <SavedLocationsContext.Provider
      value={{
        savedLocationsDrawerMode,
        setSavedLocationsDrawerMode
      }}
    >
      {children}
    </SavedLocationsContext.Provider>
  );
};

export const useSavedLocations = () => {
  const context = useContext(SavedLocationsContext);
  if (context === undefined) {
    throw new Error('useSavedLocations must be used within a SavedLocationsProvider');
  }
  return context;
};
