import React from 'react';
import PropTypes from 'prop-types';
import SvgCheck from '../../../Svgs/SvgCheck';
import SvgClose from '../../../Svgs/SvgClose';
import SvgExclamationOrange from '../../../Svgs/SvgExclamationOrange';
import SvgWarningFillOrange from '../../../Svgs/SvgWarningFillOrange';
import _ from 'lodash-es';
import moment from 'moment';
import {
  errorParse,
  flattenMessage,
  joinMessage,
  possessive,
  isRideShareVehicleId
} from '~/utilities/helperFunctions';
import { fixUTCDate } from '~/utilities/timesAndDates';
import { alertTypes } from '~/Modules/scheduleRides/scheduleRides.helper';
import { NavLink } from 'react-router-dom';

const TRIP_HEADING = {
  hed: 'USED TO DATE',
  subhead: '(Trip Included)'
};
const RIDE_HEADING = {
  hed: 'THIS TRIP TOTAL',
  subhead: ''
};
const RESTRICTION_HEADING = {
  to_care_miles_per_ride: RIDE_HEADING,
  from_care_miles_per_ride: RIDE_HEADING,
  rides_per_year: TRIP_HEADING,
  cost_per_year: TRIP_HEADING,
  rides_per_month: TRIP_HEADING
};

// TODO: make component stateless
class Authorization extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {};
    this.modalParams = {};
  }

  componentDidMount() {
    this.props.toggleLoading(false);
  }
  /**
   * confirm a ride or if a stop close the request ride box
   * @param {object} e - event
   * @param {string} status - status
   * @return {undefined}
   */
  confirm = (e, status) => {
    e.preventDefault();
    const { isPublicRoutelessRides = null } = _.get(this.props, 'auth', {});

    const lyftBlock = _.get(this.props, 'auth.showTransportation', false);
    const isLocationError = _.get(this.props, 'auth.locationError', false);
    if (status === 'stop') {
      this.props.closeRequestNewRide();
    } else if (lyftBlock) {
      this.props.toggleLoading(true, 'Retrieving transportation options...');
      this.props.availableCards(this.props.ride.submittedData);
    } else if (status === 'alert' && isLocationError) {
      this.props.toggleLoading(true, 'Retrieving transportation options...');
      this.props.availableCards(this.props.ride.submittedData);
    } else {
      this.props.toggleLoading(true, 'Confirming...');
      this.props.confirm({ isPublicRoutelessRides });
    }
  };

  /**
   * @param {string} status - authorization status
   * @param authErrorType
   * @return {object} - returns an object with button css class value and button text
   */
  getButtonProperties(status, authErrorType) {
    let buttonText = 'Continue';
    let buttonClass = 'bookButton';

    if (status === 'alert' && authErrorType === alertTypes.DUPLICATE_RIDE) {
      buttonText = 'Cancel Request';
      buttonClass = `${buttonClass} duplicate`;
    } else if (
      (status === 'allow' || status === 'alert') &&
      !(authErrorType === alertTypes.DUPLICATE_RIDE)
    ) {
      buttonText = 'Continue';
    } else if (status === 'stop') {
      buttonText = 'Cancel Request';
      buttonClass = `${buttonClass} stop`;
    } else if (status === 'block') {
      buttonText = 'Continue';
      buttonClass = `${buttonClass} block`;
    }

    return { buttonText, buttonClass };
  }

  /**
   * TODO: Add function description
   * @param messageStatus
   * @param buttonProperties
   * @param authProp
   * @return {JSX.Element}
   */
  confirmationActionButtons(messageStatus, buttonProperties, authProp) {
    if (
      messageStatus === 'alert' &&
      authProp?.error?.type === alertTypes.DUPLICATE_RIDE
    ) {
      return (
        <>
          <button
            onClick={this.props.closeRequestNewRide}
            className={buttonProperties.buttonClass}
          >
            {buttonProperties.buttonText}
          </button>
          <a onClick={e => this.confirm(e, messageStatus)} className="cancelRide">
            Continue Ride
          </a>
        </>
      );
    } else if (messageStatus === 'stop') {
      return (
        <button
          onClick={e => this.confirm(e, messageStatus)}
          className={buttonProperties.buttonClass}
        >
          {buttonProperties.buttonText}
        </button>
      );
    } else if (messageStatus !== 'stop') {
      return (
        <>
          <button
            onClick={e => this.confirm(e, messageStatus)}
            className={buttonProperties.buttonClass}
          >
            {buttonProperties.buttonText}
          </button>
          <a onClick={this.props.closeRequestNewRide} className="cancelRide">
            Cancel Ride
          </a>
        </>
      );
    }
  }

  /**
   * return error messaging
   * @return {array} - returns array of error messaging
   */
  getErrorMessaging() {
    let errorMessage = [];
    const { auth } = this.props;
    if (!_.isEmpty(this.props.error)) {
      errorMessage = errorParse(
        this.props.error,
        'Something went wrong. Try again or contact support.'
      );
    }
    if (_.get(this.props, 'auth.bookingAlert', '') !== '') {
      errorMessage.push(this.props.auth.bookingAlert);
    }
    if (_.get(this.props, 'auth.alert', '') !== '') {
      errorMessage.push(this.props.auth.alert);
    }
    if (auth?.error?.type === alertTypes.DUPLICATE_RIDE) {
      errorMessage = [];
    }
    return errorMessage;
  }

  /**
   * parse through benefits and restrictions for multi leg trips
   * @return {object} - returns object of 3 arrays and a string
   */
  getMultiLegBenefits() {
    // collection of both benefits and restrictions
    const authCollection = _.get(this.props, 'auth.collection', []);
    let allStatus = '';
    let allMessages = '';

    // store ride restrictions
    const allRestrictions = [];

    // use this array to hold benefits data for the last leg and set last index
    const allBenefits = [];
    const lastIndex = authCollection.length - 1;

    // loop through collection
    for (let authKey = 0; authKey < authCollection.length; authKey++) {
      const rideAuthorization = authCollection[authKey];
      const statuses = ['allow', 'alert', 'block', 'stop'];
      allStatus = this.getLegStatus(rideAuthorization.status, allStatus);

      const restrictionType = [];
      const benefitsType = [];
      const flattennedMessage = flattenMessage(rideAuthorization['show-message']);

      if (flattennedMessage.length > 0) {
        allMessages = `${allMessages} Leg ${authKey + 1}: ${joinMessage(flattennedMessage)}`;
      }

      for (let i = 0; i < statuses.length; i++) {
        const itemValues = rideAuthorization[`${statuses[i]}Values`];
        const itemTypes = rideAuthorization[`${statuses[i]}Types`];

        if (itemValues) {
          if (itemValues.length > 0) {
            const restrictionsValues = [];
            const restrictionsTypes = [];
            const benefitsValues = [];
            const benefitsTypes = [];
            for (let j = 0; j < itemValues.length; j++) {
              // handle restrictions. only display for stop and block
              if (
                itemTypes[j].restriction_text === 'to_care_miles_per_ride' ||
                itemTypes[j].restriction_text === 'from_care_miles_per_ride'
              ) {
                if (statuses[i] === 'block' || statuses[i] === 'stop') {
                  restrictionsValues.push(itemValues[j]);
                  restrictionsTypes.push(itemTypes[j]);
                }
              } else if (authKey === lastIndex) {
                // benefits information from last ride
                benefitsValues.push(itemValues[j]);
                benefitsTypes.push(itemTypes[j]);
              }
            }
            if (restrictionsValues.length > 0) {
              restrictionType.push({
                values: restrictionsValues,
                types: restrictionsTypes,
                status: statuses[i]
              });
            }

            if (benefitsValues.length > 0) {
              benefitsType.push({
                values: benefitsValues,
                types: benefitsTypes,
                status: statuses[i]
              });
            }

            allStatus = this.getLegStatus(statuses[i], allStatus);
          }
        }
      }

      // only add to restrictions array if there are restrictions
      if (restrictionType.length > 0) {
        allRestrictions.push({ restrictionType, leg: authKey + 1 });
      }

      // only add benefits for last ride
      if (benefitsType.length > 0) {
        allBenefits.push(benefitsType);
      }
    }

    return { allRestrictions, allBenefits, allMessages, allStatus };
  }

  /**
   * return status map
   * @return {object} return object showing status
   */
  getAuthStatusMap() {
    return {
      allow: {
        color: 'green',
        SvgName: SvgCheck
      },
      alert: {
        color: 'green',
        SvgName: SvgCheck
      },
      block: {
        color: 'orange',
        SvgName: SvgExclamationOrange
      },
      stop: {
        color: 'red',
        SvgName: SvgClose
      },
      warning: {
        color: 'orange',
        SvgName: SvgWarningFillOrange
      }
    };
  }

  getIsWillcallAndLyft(ridesArray) {
    if (ridesArray) {
      return ridesArray.some(
        ({ apptEnds, apptTime, transportType }) =>
          isRideShareVehicleId(transportType) &&
          (apptTime === 'Will Call' || apptEnds === 'Will Call')
      );
    }
    return false;
  }

  /**
   * since multiple different status levels can happen for any ride, most sever status level takes precedence.
   * @param {string} currentStatus - previous status type
   * @param {string} newStatus - current status type
   * @return {string} - returns status stype of most precendence
   */
  getLegStatus(currentStatus, newStatus) {
    if (currentStatus === 'stop') {
      newStatus = currentStatus;
    }
    if (currentStatus === 'block' && newStatus !== 'stop') {
      newStatus = currentStatus;
    }
    if (currentStatus === 'alert' && newStatus !== 'block' && newStatus !== 'stop') {
      newStatus = currentStatus;
    }
    return newStatus;
  }

  renderDuplicateRidesList = () => {
    const { auth, ride } = this.props;
    const { error } = auth;
    const { duplicateRidesGroup } = error;
    const submittedData = _.get(ride, 'submittedData', {});
    const { patientName = '', patientLastName = '' } = submittedData;

    const formatDate = ride =>
      ride?.status === 'WillCall'
        ? 'Will Call '
        : fixUTCDate(ride?.rideStartTime, ride?.timezone, 'h:mm A z');

    return Object.keys(duplicateRidesGroup).map(k => {
      return (
        <React.Fragment key={k}>
          <div className="title duplicates">
            {`${patientName} ${patientLastName} has one or more rides booked for ${k}`}
          </div>
          {_.sortBy(duplicateRidesGroup[k], ['rideStartTime']).map(ride => {
            const rideReportUrl = `/ride/reports?id=${ride?.id}&toDate=${ride?.rideStartTime}`;

            return (
              <div className="rideListItem" key={ride?.id}>
                <p>
                  <NavLink to={rideReportUrl} target="_blank">
                    {`${ride?.id}`}
                  </NavLink>
                  {` pick up at `}
                  <span className="rideListItemStrong">{` ${formatDate(ride)}`}</span>
                  <span className="rideListItemStrong">{` ${ride?.fromAddress}`}</span>
                </p>
              </div>
            );
          })}
        </React.Fragment>
      );
    });
  };

  renderAuthorizationTitle = () => {
    const submittedData = _.get(this.props, 'ride.submittedData', {});
    const patientNamePossessive = possessive(
      submittedData.patientName,
      submittedData.patientLastName
    );
    const { auth } = this.props;
    const { error = {} } = auth;
    if (error?.type === alertTypes.DUPLICATE_RIDE) {
      return `${submittedData.patientName} ${submittedData.patientLastName} has one or more rides booked for ${moment(error.duplicateRides[0]?.rideStartTime).format('M[/]D[/]YYYY')}`;
    } else {
      return `${patientNamePossessive} Benefits`;
    }
  };

  render() {
    const authStatusMap = this.getAuthStatusMap();
    const showMessage = _.get(this.props, 'auth.showMessage', []);
    const { auth } = this.props;

    //calculate if any of the rides are willcall and lyft
    const isWillCallLyftBooking = this.getIsWillcallAndLyft(this.props.bookingData.rides);
    let message = '';

    if (showMessage.length > 0) {
      const messages = flattenMessage(showMessage);
      message = joinMessage(messages);
    } else {
      message = _.get(this.props, 'auth.message', '');
    }

    const errorMessage = this.getErrorMessaging();
    let messageStatus = _.get(this.props, 'auth.status', '');

    if (_.has(this.props, 'auth.nemtAuthStatus')) {
      messageStatus = _.get(this.props, 'auth.nemtAuthStatus', '');
      message = _.get(this.props, 'auth.nemtAuthMessage', '');
    }

    const { allRestrictions, allBenefits, allMessages, allStatus } =
      this.getMultiLegBenefits();

    if (_.has(this.props, 'auth.collection') && messageStatus !== 'stop') {
      messageStatus = allStatus;
      message = allMessages;
    }

    const authErrorType = auth?.error?.type;
    const buttonProperties = this.getButtonProperties(messageStatus, authErrorType);
    const confirmationButtons = this.confirmationActionButtons(
      messageStatus,
      buttonProperties,
      this.props.auth
    );

    // past booking
    const isPastRide = _.get(this.props, 'bookingData.pastRide', false);

    const warningColor = authStatusMap.warning.color;
    const SvgWarning = authStatusMap.warning.SvgName;
    const { isPublicRoutelessRides = null } = _.get(this.props, 'auth', {});
    return (
      <div className="RideConfirmation">
        {authErrorType === alertTypes.DUPLICATE_RIDE ? null : (
          <div className="title">{this.renderAuthorizationTitle()}</div>
        )}
        {authErrorType === alertTypes.DUPLICATE_RIDE
          ? this.renderDuplicateRidesList()
          : allRestrictions.concat(allBenefits).map((allType, k) => {
            const leg = _.has(allType, 'leg') ? allType.leg : null;
            allType = _.has(allType, 'restrictionType')
              ? allType.restrictionType
              : allType;

            return (
              <div key={k}>
                {!_.isNil(leg) ? <span className="leg">Leg {leg}</span> : null}
                {allType.map(allTypeRest => {
                  // benefits or restrictions authorization level
                  const values = allTypeRest.values;
                  const types = allTypeRest.types;
                  const status = allTypeRest.status;
                  return values.map((value, key) => {
                    // list of each benefit or restriction type in an authorization level
                    const type = types[key];
                    const restrictionText = type.restriction_text;
                    const restrictionName = type.restriction_name;
                    const color = authStatusMap[status].color;
                    const SvgName = authStatusMap[status].SvgName;
                    let unit = type.unit;
                    if (unit === 'miles') {
                      unit = 'mi';
                    }
                    const valueBenefit =
                        unit === 'dollars' ? `${value.value.toFixed(2)}` : value.value;

                    return (
                      <div key={key} className="rideRestrictionsBlock">
                        <div className="leftRestrictions">
                          <p className="row1">{restrictionName}</p>
                          <p className="numberGroup">
                            <span className="numbers">
                              {unit === 'dollars' ? <sup>$</sup> : null}
                              {value.limit}
                            </span>
                            {unit !== 'dollars' ? (
                              <span className="smallLetters">{` ${unit}`}</span>
                            ) : null}
                          </p>
                        </div>
                        <div className="rightRestrictions">
                          <p className="row1">
                            {leg === null
                              ? RESTRICTION_HEADING[restrictionText].hed
                              : `Leg [${leg}] Total`}
                            <br />
                            {leg === null ? (
                              <span className="row1Subhed">
                                {RESTRICTION_HEADING[restrictionText].subhead}
                              </span>
                            ) : null}
                          </p>
                          <p className="numberGroup">
                            <span className={`numbers ${color}`}>
                              {unit === 'dollars' ? <sup>$</sup> : null}
                              {valueBenefit}
                            </span>
                            <span className={`smallLetters ${color}`}>
                              {unit !== 'dollars' ? ` ${unit}` : ''}
                            </span>
                            <SvgName className={`${color}Svg`} />
                          </p>
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
            );
          })}
        {message !== '' && auth?.error?.type !== alertTypes.DUPLICATE_RIDE ? (
          <div className={`messageBlock ${messageStatus}`}>{message}</div>
        ) : null}
        {isPastRide ? (
          <div className={'warningBlock'}>
            <SvgWarning className={`${warningColor}Svg`} />
            You are about to book a past ride.
          </div>
        ) : null}
        {isWillCallLyftBooking ? (
          <div className={'warningBlock'}>
            <SvgWarning className={`${warningColor}Svg`} />
            You are about to book a Flex Ride.
          </div>
        ) : null}

        <form onSubmit={this.confirm}>
          {isPublicRoutelessRides ? (
            <button className={'bookButton'}>CONTINUE WITHOUT ROUTES</button>
          ) : null}
          {this.props?.auth !== false && confirmationButtons}
        </form>
        {!_.isEmpty(errorMessage) ? (
          <div className="errorList">
            {errorMessage.map((message, key) => {
              return <p key={key}>{message}</p>;
            })}
          </div>
        ) : null}
      </div>
    );
  }
}

Authorization.defaultProps = {
  page: '',
  google: {},
  status: 'allow',
  auth: {},
  ride: {},
  user: {},
  confirm: () => {},
  closeRequestNewRide: () => {},
  error: {},
  toggleLoading: () => {},
  bookingData: {},
  availableCards: () => {}
};

Authorization.propTypes = {
  page: PropTypes.string,
  google: PropTypes.object,
  status: PropTypes.string,
  auth: PropTypes.object,
  ride: PropTypes.object,
  user: PropTypes.object,
  confirm: PropTypes.func,
  closeRequestNewRide: PropTypes.func,
  error: PropTypes.object,
  toggleLoading: PropTypes.func,
  bookingData: PropTypes.object,
  availableCards: PropTypes.func
};

export default Authorization;
