import { createAppAsyncThunk } from '~/Modules';
import { getDateRestrictions } from '~/services/rideBooking.service';

/**
 *  Thunk to get the date restrictions for the ride booking flow
 */
export const initializeDateThunk = createAppAsyncThunk(
  'rideBooking/initializeDate',
  async (_, { getState, rejectWithValue }) => {
    const passengerInfo = getState().rideBooking.passengerInfo;

    try {
      const { passengerId, tripType, transportType } = passengerInfo;

      if (!passengerId || !tripType || !transportType) {
        throw Error('passengerId, tripType, and transportType are required');
      }

      return (await getDateRestrictions(passengerId, tripType, transportType))?.data;
    } catch (error) {
      return rejectWithValue({ error: error.response });
    }
  }
);
