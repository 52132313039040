import _, { cloneDeep } from 'lodash-es';
import { extendedRequestConfig, logout } from '~/utilities/auth.helper';
import axios from '~/Modules/safeAxios';
import {
  LYFT_VEHICLE_ID,
  NEMT_ERROR_MESSAGE,
  PUBLIC_ROUTELESS_RIDES_MESSAGE,
  LYFT_RETRY_MESSAGE,
  UBER_VEHICLE_ID,
  RIDESHARE_RETRY_MESSAGE
} from '~/constants';
import {
  flattenMessage,
  joinMessage,
  isRideShareVehicleId,
  isEmpty
} from '~/utilities/helperFunctions';
import { addScheduledRide } from '~/Modules/rideCards/rideCards.actions';
import { CLEAR_RIDE } from '~/Modules/rideDetails';
import {
  sanitizeDuplicateRidesList,
  validateRideLegList,
  alertTypes
} from './scheduleRides.helper';
export * from './scheduleRides.helper';

const additionalHeaderValues = {
  ...(process.env.REACT_APP_ENVIRONMENT === 'sandbox' && { timeout: 5000000 })
};

export const PREAUTHORIZE_RIDES = 'scheduleRides/PREAUTHORIZE_RIDES';
export const PREAUTHORIZE_ERROR = 'scheduleRides/PREAUTHORIZE_ERROR';
export const LYFT_ON_DEMAND_SEARCH_TIMEOUT =
  'scheduleRides/LYFT_ON_DEMAND_SEARCH_TIMEOUT';
export const RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT =
  'scheduleRides/RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT';
export const PREAUTHORIZE_RIDES_VEHICLE_MULTI =
  'scheduleRides/PREAUTHORIZE_RIDES_VEHICLE_MULTI';
export const PREAUTHORIZE_LYFT_RIDES_ON_DEMAND =
  'scheduleRides/PREAUTHORIZE_LYFT_RIDES_ON_DEMAND';
export const LYFT_RIDES_ON_DEMAND_OPTIONS = 'scheduleRides/LYFT_RIDES_ON_DEMAND_OPTIONS';
export const LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL =
  'scheduleRides/LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL';
export const LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR =
  'scheduleRides/LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR';

export const AVAILABLE_CARDS = 'scheduleRides/AVAILABLE_CARDS';
export const AVAILABLE_CARDS_ERROR = 'scheduleRides/AVAILABLE_CARDS_ERROR';

export const PREAUTHORIZE_RIDES_VEHICLE = 'scheduleRides/PREAUTHORIZE_RIDES_VEHICLE';
export const PREAUTHORIZE_RIDES_VEHICLE_ERROR =
  'scheduleRides/PREAUTHORIZE_RIDES_VEHICLE_ERROR';

export const ROUTE_SELECTED = 'scheduleRides/ROUTE_SELECTED';

export const CONFIRM = 'scheduleRides/CONFIRM';
export const CONFIRM_PUBLIC = 'scheduleRides/CONFIRM_PUBLIC';
export const CONFIRM_ERROR = 'scheduleRides/CONFIRM_ERROR';

export const SUBMIT_RIDE = 'scheduleRides/SUBMIT_RIDE';
export const SUBMIT_RIDE_ERROR = 'scheduleRides/SUBMIT_RIDE_ERROR';
export const SUBMIT_RIDE_LYFT_ON_DEMAND = 'scheduleRides/SUBMIT_RIDE_LYFT_ON_DEMAND';
export const SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR =
  'scheduleRides/SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR';

export const RESET_FORM = 'scheduleRides/RESET_FORM';
export const RESET_FORM_STATE = 'scheduleRides/RESET_FORM_STATE';

export const reset = () => {
  return dispatch => {
    dispatch({
      type: RESET_FORM
    });
  };
};

export const resetModals = () => {
  return dispatch => {
    dispatch({
      type: RESET_FORM_STATE
    });
  };
};

export const routeSelected = routeData => {
  return dispatch => {
    dispatch({
      type: ROUTE_SELECTED,
      data: routeData
    });
  };
};

// LUMEN ENDPOINTS FOR MULTILEG LYFT AND NEMT

/**
 * ridesPreAuthorize initial pre auth
 * @param {object} params - params for hitting endpoint
 * @return {dispatch} return dispatch which sends a post request
 */
export const ridesPreAuthorize = params => {
  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/preauthorize/all`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        const { data: responseData } = response;
        const { data, status, message } = responseData;
        const { rideShareOnDemand } = params;
        let allow = true;
        let isLyft = true; // is trip lyft
        let lyftBlock = false; // is it a block for a lyft ride

        if (status) {
          ({ allow, lyftBlock, isLyft, params } = validateRideLegList(
            data,
            allow,
            lyftBlock,
            params,
            isLyft
          ));
        } else {
          allow = status;
        }
        // if entire trip is lyft, go straight to this reducer.
        // no need to call available cards endpoint.

        if (isLyft && !rideShareOnDemand) {
          dispatch({
            type: PREAUTHORIZE_RIDES_VEHICLE_MULTI,
            params,
            data,
            isLyft
          });
        } else if (rideShareOnDemand) {
          dispatch({
            type: PREAUTHORIZE_LYFT_RIDES_ON_DEMAND,
            params,
            data: response.data.data
          });
        } else if (allow) {
          dispatch(availableCards(params));
        } else {
          dispatch({
            type: PREAUTHORIZE_RIDES,
            params,
            data,
            status,
            message,
            lyftBlock
          });
        }
      })
      .catch(error => {
        if (isRideShareVehicleId(params.transport)) {
          return dispatch({
            type: RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT,
            params,
            error: error.response
          });
        }

        return dispatch({
          type: PREAUTHORIZE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

/**
 * vehicle selection authorization for multi leg rides
 * @param {object} params - params for hitting endpoint
 * @return {dispatch} return dispatch which sends a post request
 */
export const ridesPreAuthorizeVehicleMulti = params => {
  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/preauthorize/all`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        const benefitsList = response.data.data;
        let sendToPreauth = false;

        for (let i = 0; i < benefitsList.length; i++) {
          if (benefitsList[i].status !== 'allow') {
            sendToPreauth = true;
            break;
          }
        }

        if (sendToPreauth) {
          dispatch({
            type: PREAUTHORIZE_RIDES_VEHICLE_MULTI,
            params,
            data: response.data.data
          });
        } else {
          dispatch(confirm({ ...response?.data?.data, ...params }));
        }
      })
      .catch(error => {
        dispatch({
          type: PREAUTHORIZE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

export const ridesPreAuthorizeVehicle = (companyId, vehicleType, params) => {
  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/preauthorize/all/${companyId}/${vehicleType}`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        dispatch({
          type: PREAUTHORIZE_RIDES_VEHICLE,
          params,
          data: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: PREAUTHORIZE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

export const ridesPreAuthorizePublic = params => {
  //Same as Multi, but namespaced for clarity
  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/preauthorize/all`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        dispatch({
          type: CLEAR_RIDE
        });
        dispatch({
          type: PREAUTHORIZE_RIDES_VEHICLE,
          params,
          data: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: PREAUTHORIZE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

export const availableCards = params => {
  return dispatch => {
    const newParams = _.cloneDeep(params);

    if ('transportMode' in newParams) {
      newParams.mode = newParams.transportMode;
      newParams.transitMode = newParams?.publicTransitPreferences ?? [];
      newParams.transitRoutingPreference = newParams?.publicTransitRoutePreferences ?? [];
    }

    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/available`,
        newParams,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        // if it's a multileg lyft ride only call the preauth endpoint again
        const isRideShare = transportID =>
          [LYFT_VEHICLE_ID, UBER_VEHICLE_ID].includes(transportID);
        const isRideShareLeg = params.rides.every(
          ({ transportType, transportTypeName }) =>
            isRideShare(transportType || transportTypeName)
        );

        // For scheduled rideshare only rides no need for vehicle selection so go straight to the preauth page.
        if (isRideShareLeg && params.bookingType !== 'ondemand') {
          dispatch(ridesPreAuthorizeVehicleMulti(params));
        } else if (params.rideShareOnDemand) {
          dispatch({
            type: LYFT_RIDES_ON_DEMAND_OPTIONS,
            params,
            data: response.data.data,
            status: response.data.status,
            message: response.data.message
          });
        } else {
          dispatch({
            type: AVAILABLE_CARDS,
            params,
            data: response.data.data,
            status: response.data.status,
            message: response.data.message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: AVAILABLE_CARDS_ERROR,
          params,
          error: error.response
        });
      });
  };
};

export const confirm = params => {
  // check to see if all legs are lyft
  let isLyft = true;

  // Force rides to an array
  params.rides = params?.rides ?? [];

  params.rides.map(ride => {
    if (!isRideShareVehicleId(ride.transportType)) {
      isLyft = false;
    }
    return ride;
  });

  if (isLyft) {
    return dispatch => dispatch({ type: CONFIRM, params, isLyft });
  }

  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/confirm`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => dispatch({ type: CONFIRM, params, data: response.data.data }))
      .catch(error => dispatch({ type: CONFIRM_ERROR, params, error: error.response }));
  };
};

export const confirmPublic = params => {
  return dispatch => {
    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/book`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        if (response.data.status === true) {
          dispatch({
            type: CONFIRM_PUBLIC,
            params,
            data: response.data.data.rides
          });
        } else {
          dispatch({
            type: CONFIRM_ERROR,
            params,
            error: {
              data: {
                error: response.data.message
              }
            }
          });
        }
      })
      .catch(error => {
        dispatch({
          type: CONFIRM_ERROR,
          params,
          error: error
        });
      });
  };
};

export const submitRide = params => {
  return (dispatch, getState) => {
    const state = getState();
    const page = state?.rideCards?.scheduledRides?.rideCardParams?.page ?? '';
    const query = state?.rideCards?.scheduledRides?.rideCardParams?.query ?? '';
    const addRide = page === 'scheduled' || page === 'all' ? true : false;

    return axios
      .post(
        `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/book?livechat=1`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        dispatch({
          type: SUBMIT_RIDE,
          params,
          data: response.data.data,
          status: response.data.status,
          message: response.data.message
        });
        const triggerBenbot = _.get(response, 'data.data.triggerBenbot', false);
        if (
          !_.has(response, 'data.data.error') &&
          _.get(response, 'data.status', '') !== false &&
          addRide &&
          triggerBenbot === false &&
          query === ''
        ) {
          dispatch(addScheduledRide(response.data.data, params, false));
        }
      })
      .catch(error => {
        dispatch({
          type: SUBMIT_RIDE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

// LYFT ON DEMAND ENDPOINTS

// TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
/**
 * on demand lyft preauth
 * @param {object} params - params for lyft rides preauth api
 * @return {dispatch} - returns proper action function
 */
export const lyftRidesPreAuthorize = params => {
  const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/lyft/onDemandPreAuth`;

  return dispatch => {
    return axios
      .post(url, params, extendedRequestConfig(null, additionalHeaderValues))
      .then(response => {
        const status = _.get(response, 'data.status', '');
        const errors = _.get(response, 'data.errors', []);
        if (!status) {
          const error = {
            message: _.get(response, 'data.message', ''),
            errorList: errors
          };
          dispatch({
            type: PREAUTHORIZE_ERROR,
            params,
            error
          });
        } else {
          dispatch({
            type: PREAUTHORIZE_LYFT_RIDES_ON_DEMAND,
            params,
            data: response.data.data
          });
        }
      })
      .catch(error => {
        dispatch({
          type: PREAUTHORIZE_ERROR,
          params,
          error: error.response
        });
      });
  };
};

// TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
/**
 * gets options for on demand lyft rides
 * @param {object} params - params
 * @return {dispatch} - returns proper action function
 */
export const lyftRidesOnDemandOptions = params => {
  return dispatch => {
    return axios
      .post(
        `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/lyft/onDemandOptions`,
        params,
        extendedRequestConfig(null, additionalHeaderValues)
      )
      .then(response => {
        const status = _.get(response, 'data.status', '');
        const errors = _.get(response, 'data.errors', []);
        if (!status) {
          const error = {
            message: _.get(response, 'data.message', ''),
            errorList: errors
          };
          dispatch({
            type: PREAUTHORIZE_ERROR,
            params,
            error
          });
        } else {
          dispatch({
            type: LYFT_RIDES_ON_DEMAND_OPTIONS,
            params,
            data: response.data.data,
            status: response.data.status,
            message: response.data.message
          });
        }
      })
      .catch(error => {
        dispatch({
          type: LYFT_ON_DEMAND_SEARCH_TIMEOUT,
          params,
          error: error.response
        });
      });
  };
};

// TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
/**
 * @param {object} params - params for lyft rides submit api
 * @param {string} submitUrl - url
 * @param {string} reducerType - reducer name
 * @return {dispatch} - returns proper action function
 */
export const submitRideLyft = params => {
  return (dispatch, getState) => {
    const state = getState();
    const page = state?.rideCards?.scheduledRides?.rideCardParams?.page ?? '';
    const query = state?.rideCards?.scheduledRides?.rideCardParams?.query ?? '';
    const addRide = page === 'scheduled' || page === 'all' ? true : false;
    const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/lyft/onDemandBook`;

    return axios
      .post(url, params, extendedRequestConfig(null, additionalHeaderValues))
      .then(response => {
        const status = _.get(response, 'data.status', '');
        const errors = _.get(response, 'data.errors', []);
        if (!status) {
          const error = {
            message: _.get(response, 'data.message', ''),
            errorList: errors
          };
          dispatch({
            type: SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR,
            params,
            error
          });
        } else {
          dispatch({
            type: SUBMIT_RIDE_LYFT_ON_DEMAND,
            params,
            data: response.data
          });
          if (isEmpty(errors) && addRide && query === '') {
            dispatch(addScheduledRide(response.data.data, params, true));
          }
        }
      })
      .catch(error => {
        dispatch({
          type: SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR,
          params,
          error: error.response
        });
      });
  };
};

/**
 * on demand will call
 * @param {object} params - params for lyft rides submit api
 * @return {dispatch} - returns proper action function
 */
export const lyftOnDemandWillCall = params => {
  return dispatch => {
    const url =
      params.rideType === 'flex'
        ? `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/dispatchFlexRide`
        : `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/lyft/onDemandWillCall`;

    return axios
      .post(url, params, extendedRequestConfig(null, additionalHeaderValues))
      .then(response => {
        if (_.get(response, 'data.status', false) === true) {
          dispatch({
            type: LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL,
            params,
            data: response.data.data
          });
        } else {
          const errorMessage = _.get(response, 'data.message');
          dispatch({
            type: LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR,
            params,
            error: errorMessage
          });
        }
      })
      .catch(error => {
        dispatch({
          type: LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR,
          params,
          error: _.get(error, 'data.message')
        });
      });
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    // TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
    case PREAUTHORIZE_LYFT_RIDES_ON_DEMAND: {
      const newState = cloneDeep(state);

      newState.auth = {};
      newState.confirmationCardData = {};
      if (_.has(action, 'data.booking_alert')) {
        newState.auth.bookingAlert = action.data.booking_alert;
        newState.auth.alert = action.data.alert_msg;
      }

      newState.ride = {};

      if (_.has(action, 'data.passenger')) {
        newState.passenger = action.data.passenger;
        newState.ride = action.data.ride;
        newState.ride.showPrice = action.data.showPrice;
        newState.ride.showReason = action.data.showReason;
        if (_.has(action, 'data.lyftRideType')) {
          newState.ride.lyftRideType = action.params.lyftRideType;
        }
      }

      newState.ride.submittedData = action.params;
      newState.auth.message = action.data.message;
      newState.auth.showMessage = _.get(action, 'data.show-message', []);
      newState.auth.status = action.data.status;

      // auth status (allow, alert, block, stop)
      // consistency with multi leg trip data structure
      const collection = {};
      collection.stopValues = action.data.stopValues;
      collection.stopTypes = action.data.stopTypes;

      collection.blockValues = action.data.blockValues;
      collection.blockTypes = action.data.blockTypes;

      collection.alertValues = action.data.alertValues;
      collection.alertTypes = action.data.alertTypes;

      collection.allowValues = action.data.allowValues;
      collection.allowTypes = action.data.allowTypes;

      collection['show-message'] = newState.auth.showMessage;
      newState.auth.collection = [collection];
      newState.timestamp = Math.floor(Date.now() / 1000);
      newState.type = action.type;
      _.unset(newState, 'errorObj');

      return newState;
    }

    case PREAUTHORIZE_RIDES: {
      const newState = cloneDeep(state);

      newState.auth = {};
      newState.confirmationCardData = {};
      newState.trip = {};
      // multiple leg array

      if (Array.isArray(action.data)) {
        newState.auth.nemtAuthMessage = [];
        for (let i = 0; i < action.data.length; i++) {
          const anAction = action.data[i];
          if (
            anAction.status === 'stop' ||
            anAction.status === 'block' ||
            anAction.status === 'alert'
          ) {
            newState.auth.nemtAuthStatus = anAction.status;
            if (anAction.type === 'location_error') {
              newState.auth.locationError = true;
            }
            if (anAction.type === alertTypes.DUPLICATE_RIDE && anAction.duplicate_rides) {
              const { duplicateRides, duplicateRidesGroup } =
                sanitizeDuplicateRidesList(action);
              newState.auth.locationError = true;
              newState.auth.error = {
                type: alertTypes.DUPLICATE_RIDE,
                data: action.data,
                duplicateRides,
                duplicateRidesGroup
              };
            }
            let showMessage = _.get(anAction, 'show-message', []);
            if (showMessage.length > 0) {
              showMessage = joinMessage(flattenMessage(showMessage));
            } else {
              showMessage = anAction.message;
            }
            if (showMessage === '') {
              showMessage =
                'There was an issue booking your ride. Please try again or contact support.';
            }
            showMessage = `Leg ${anAction.leg}: ${showMessage}`;
            newState.auth.nemtAuthMessage.push(showMessage);
            const lyftBlock = _.get(action, 'lyftBlock', false);
            if (lyftBlock && anAction.status === 'block') {
              newState.auth.showTransportation = true;
            }
          }
        }
        if (newState.auth.nemtAuthMessage.length > 0) {
          newState.auth.nemtAuthMessage = joinMessage(
            flattenMessage(newState.auth.nemtAuthMessage)
          );
        } else {
          newState.auth.nemtAuthMessage = '';
        }

        // if a false status comes back then something is wrong with the request.
        if (!action.status) {
          if (_.isNil(newState.auth.nemtAuthMessage)) {
            newState.auth.nemtAuthMessage = action.message;
          } else {
            newState.auth.nemtAuthMessage += ' ' + action.message;
          }
          if (_.get(newState, 'auth.nemtAuthStatus', '') !== 'stop') {
            newState.auth.nemtAuthStatus = 'stop';
          }
        }
      } else {
        newState.auth.nemtAuthStatus = action.data.status;
        newState.auth.nemtAuthMessage = action.data.message;
      }
      newState.ride = {
        submittedData: action.params
      };

      // remove below when we switch over to new UI
      newState.nemtParams = action.params;
      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');
      return newState;
    }

    case PREAUTHORIZE_RIDES_VEHICLE_MULTI: {
      const newState = cloneDeep(state);

      newState.auth = {};
      newState.trip = {};
      // collection of authorization statuses for multi leg trip
      newState.auth.collection = action.data;
      newState.confirmationCardData = {};
      newState.ride = {
        submittedData: action.params
      };

      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');

      // if lyft trip
      if (_.get(action, 'isLyft', false) === true) {
        const rides = newState.auth.collection.map(auth => {
          const ride = auth.ride;
          ride.showPrice = _.get(auth, 'showPrice', false);
          ride.showReason = _.get(auth, 'showPrice', false);
          return ride;
        });
        newState.trip.rides = rides;
      }

      if (Array.isArray(action.data)) {
        action.data.map(item => {
          if (item?.type === alertTypes.DUPLICATE_RIDE && item?.status === 'alert') {
            const { duplicateRides, duplicateRidesGroup } =
              sanitizeDuplicateRidesList(action);
            newState.auth.error = {
              type: alertTypes.DUPLICATE_RIDE,
              data: action.data,
              duplicateRides,
              duplicateRidesGroup
            };
          }
        });
      }

      return newState;
    }

    case PREAUTHORIZE_ERROR: {
      const newState = cloneDeep(state);
      newState.trip = {};
      //remove below when we go to new redesign
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(
        action,
        'error.data.error',
        'There was an issue booking your ride. Please try again or contact support.'
      );
      newState.error = _.get(action, 'error.status', 500);

      //new design error handling
      newState.errorObj = _.get(action, 'error', {});
      if (isEmpty(newState.errorObj)) {
        newState.errorObj.status = 500;
        newState.errorObj.message =
          'There was an issue booking your ride. Please try again or contact support.';
      }
      /**
       * TODO:UBER — RETRY - OLIVER
       */

      if (isRideShareVehicleId(action.params.transport)) {
        newState.errorObj.clientMessage = RIDESHARE_RETRY_MESSAGE;
        newState.errorObj.withRetry = true;
      }

      newState.errorObj.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);
      return newState;
    }

    case LYFT_ON_DEMAND_SEARCH_TIMEOUT: {
      const newState = cloneDeep(state);

      newState.trip = {};
      //remove below when we go to new redesign
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(
        action,
        'No drivers found. Please try again shortly.'
      );
      newState.error = _.get(action, 'error.status', 500);

      //new design error handling
      newState.errorObj = _.get(action, 'error', {});
      if (isEmpty(newState.errorObj)) {
        newState.errorObj.status = 500;
        newState.errorObj.message = 'No drivers found. Please try again shortly.';
      }
      newState.errorObj.type = action.type;

      /**
       * TODO:UBER — RETRY - OLIVER
       */
      if (action.params.transport === LYFT_VEHICLE_ID) {
        newState.errorObj.clientMessage = LYFT_RETRY_MESSAGE;
        newState.errorObj.withRetry = true;
      }
      newState.timestamp = Math.floor(Date.now() / 1000);
      return newState;
    }

    case RIDESHARE_ON_DEMAND_SEARCH_TIMEOUT: {
      const newState = cloneDeep(state);

      newState.trip = {};
      //remove below when we go to new redesign
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(
        action,
        'No drivers found. Please try again shortly.'
      );
      newState.error = _.get(action, 'error.status', 500);

      //new design error handling
      newState.errorObj = _.get(action, 'error', {});
      if (isEmpty(newState.errorObj)) {
        newState.errorObj.status = 500;
        newState.errorObj.message = 'No drivers found. Please try again shortly.';
      }
      newState.errorObj.type = action.type;

      if (isRideShareVehicleId(action.params.transport)) {
        newState.errorObj.clientMessage = RIDESHARE_RETRY_MESSAGE;
        newState.errorObj.withRetry = true;
      }

      newState.timestamp = Math.floor(Date.now() / 1000);
      return newState;
    }

    case AVAILABLE_CARDS: {
      const newState = cloneDeep(state);

      newState.auth = {};
      newState.trip = {};
      newState.ride = {
        submittedData: action.params
      };

      if (_.has(action, 'data')) {
        newState.confirmationCardData = action.data.map(confirmationData => {
          if (_.has(confirmationData, 'routes.routes')) {
            confirmationData.routes.routes = _.sortBy(
              cloneDeep(confirmationData.routes.routes),
              route => {
                return route.legs[0].duration.value;
              }
            );
          }
          return confirmationData;
        });
      }

      if (_.has(action, 'data.booking_alert')) {
        newState.auth.bookingAlert = action.data.booking_alert;
        newState.auth.alert = action.data.alert_msg;
      }

      // get rideType value from array of nemts and vehicle types
      // multileg rides won't support livechat yet
      if (newState.ride.submittedData.bookingType !== 'multileg') {
        for (let i = 0; i < newState.confirmationCardData.length; i++) {
          const vehicleData = newState.confirmationCardData[i];
          if (vehicleData.rideType === 'benbot') {
            newState.ride.submittedData.rideType = vehicleData.rideType;
            break;
          }
        }
      }

      // catch errors
      for (let i = 0; i < newState.confirmationCardData.length; i++) {
        const vehicle = newState.confirmationCardData[i];
        const defaultError = `Leg ${i + 1}: ${NEMT_ERROR_MESSAGE}. `;

        if (_.get(vehicle, 'status', '') === 'stop') {
          if (!_.has(newState.auth, 'nemtAuthMessage')) {
            newState.auth.nemtAuthMessage = '';
          }
          newState.auth.nemtAuthStatus = 'stop';
          const message = _.get(vehicle, 'message', defaultError);
          newState.auth.nemtAuthMessage += `${message} `;
        } else if (_.get(vehicle, 'status', '') === 'allow') {
          // Public Transit
          //NOOP
        } else {
          // hack: check for empty array
          const rides = _.get(vehicle, 'rides', []);

          // hack: empty array for rides object so catch it.
          if (rides === null || rides.length === 0) {
            if (!_.has(newState.auth, 'nemtAuthMessage')) {
              newState.auth.nemtAuthMessage = '';
            }
            newState.auth.nemtAuthStatus = 'stop';
            newState.auth.nemtAuthMessage += defaultError;
          }
          if (
            action.message === PUBLIC_ROUTELESS_RIDES_MESSAGE &&
            action.params.mode === 'Public'
          ) {
            newState.auth.nemtAuthMessage = PUBLIC_ROUTELESS_RIDES_MESSAGE;
            newState.auth.isPublicRoutelessRides = true;
          }
        }
      }

      newState.auth.message = action.data.message;
      newState.auth.status = action.data.status;

      // if a false status comes back then something is wrong with the request.
      if (!action.status) {
        newState.auth.nemtAuthStatus = 'stop';
        newState.auth.nemtAuthMessage = newState.auth.nemtAuthMessage || action.message;
      }

      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');
      return newState;
    }

    case AVAILABLE_CARDS_ERROR: {
      if (action?.error?.status === 401) {
        logout('Available Cards Error scheduleRides.js redux', {
          error: action.error,
          state
        });
        return state;
      }

      const newState = cloneDeep(state);
      newState.trip = {};
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(action, 'error.data.error');
      newState.error = _.get(action, 'error.status');

      newState.errorObj = _.get(action, 'error', {});
      newState.errorObj.type = action.type;

      if (isRideShareVehicleId(action.params.transport)) {
        newState.errorObj.clientMessage = RIDESHARE_RETRY_MESSAGE;
        newState.errorObj.withRetry = true;
      }

      newState.timestamp = Math.floor(Date.now() / 1000);

      return newState;
    }

    case PREAUTHORIZE_RIDES_VEHICLE: {
      const newState = cloneDeep(state);

      newState.auth = {};
      newState.confirmationCardData = {};
      if (_.has(action, 'data.booking_alert')) {
        newState.auth.bookingAlert = action.data.booking_alert;
        newState.auth.alert = action.data.alert_msg;
      }

      if (_.has(action, 'data.passenger')) {
        newState.passenger = action.data.passenger;
        newState.ride = action.data.ride;
        newState.ride.showPrice = action.data.showPrice;
        newState.ride.showReason = action.data.showReason;
      }

      newState.ride.submittedData = action.params;

      // auth status (allow, alert, block, stop)
      newState.auth.message = action.data.message;
      newState.auth.showMessage = _.get(action, 'data.show-message', []);
      newState.auth.status = action.data.status;

      // consistency with multi leg trip data structure
      const collection = {};
      collection.stopValues = action.data.stopValues;
      collection.stopTypes = action.data.stopTypes;

      collection.blockValues = action.data.blockValues;
      collection.blockTypes = action.data.blockTypes;

      collection.alertValues = action.data.alertValues;
      collection.alertTypes = action.data.alertTypes;

      collection.allowValues = action.data.allowValues;
      collection.allowTypes = action.data.allowTypes;

      collection['show-message'] = newState.auth.showMessage;
      newState.auth.collection = [collection];

      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');
      return newState;
    }

    // TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
    case LYFT_RIDES_ON_DEMAND_OPTIONS: {
      const newState = _.cloneDeep(state);
      newState.ride = {
        submittedData: action.params
      };

      if (action.status) {
        newState.confirmationCardData = {
          rides: action?.data[0]?.rides
        };
      }

      newState.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');
      // _.unset(newState, 'confirmationCardData');
      return newState;
    }

    case PREAUTHORIZE_RIDES_VEHICLE_ERROR: {
      if (action?.error?.status === 401) {
        logout('Preauthorize Rides Vehicle Error scheduleRides.js redux', {
          error: action.error,
          state
        });
        return state;
      }

      const newState = cloneDeep(state);
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(action, 'error.data.error');
      newState.error = _.get(action, 'error.status');

      newState.errorObj = _.get(action, 'error', {});
      newState.errorObj.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);

      return newState;
    }

    case CONFIRM: {
      let newState = cloneDeep(state);
      let rides = null;
      if (_.has(action, 'isLyft') && action.isLyft === true) {
        _.unset(newState, 'auth');
        _.unset(newState, 'ride');
        newState.confirmationCardData = {};
        newState.trip.submittedData = action.params;
        newState.timestamp = Math.floor(Date.now() / 1000);
        return newState;
      }
      newState = {};
      newState.confirmationCardData = {};
      if (_.has(action, 'data.ride')) {
        const ride = action.data.ride;
        ride.showPrice = action.data.showPrice;
        ride.showReason = action.data.showReason;
        rides = [ride];
      } else {
        // multi
        rides = action.data.map(leg => {
          const ride = leg.ride;
          ride.showPrice = leg.showPrice;
          ride.showReason = leg.showReason;
          return ride;
        });
      }

      newState.trip = {
        rides,
        submittedData: action.params
      };
      newState.timestamp = Math.floor(Date.now() / 1000);
      _.unset(newState, 'errorObj');
      return newState;
    }

    case CONFIRM_PUBLIC: {
      const newState = _.cloneDeep(state);
      let rides = null;
      if (_.has(action, 'isLyft') && action.isLyft === true) {
        _.unset(newState, 'auth');
        _.unset(newState, 'ride');
        newState.confirmationCardData = {};
        newState.trip.submittedData = action.params;
        newState.timestamp = Math.floor(Date.now() / 1000);
        return newState;
      }
      newState.confirmationCardData = {};
      if (_.has(action, 'data.ride')) {
        const ride = action.data.ride;
        rides = [ride];
      } else {
        // multi
        rides = action.data.map(leg => {
          const ride = leg.ride;
          return ride;
        });
      }

      newState.trip = {
        rides,
        submittedData: action.params
      };
      newState.timestamp = Math.floor(Date.now() / 1000);
      newState.data = {};
      _.unset(newState, 'errorObj');
      const rideId = action.data[0].id;
      window.location = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/ride/scheduled?ride=${rideId}#ride${rideId}`;
      newState.data.rideId = rideId;
      return state;
    }

    case CONFIRM_ERROR: {
      if (action?.error?.status === 401) {
        logout('Confirm Error in scheduleRides.js redux', {
          error: action.error,
          state
        });
        return state;
      }
      const newState = cloneDeep(state);
      newState.confirmationCardData = {};
      newState.errorMessage = _.get(action, 'error.data.error');
      newState.error = _.get(action, 'error.status');

      newState.errorObj = _.get(action, 'error', {});
      newState.errorObj.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);

      return newState;
    }

    case SUBMIT_RIDE: {
      const newState = {};

      newState.status = action.status;
      newState.data = {};
      newState.type = action.type;
      newState.data.message = _.get(action, 'message', '');
      newState.data.status = _.get(action, 'status', '');
      newState.data.rideResponse = _.get(action, 'data.rides', []);

      if (newState.data.status === true) {
        if (_.has(action, 'data.rides')) {
          const rides = action.data.rides;
          const rideId = rides[0].id;
          newState.data.redirectUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/ride/scheduled?ride=${rideId}#ride${rideId}`;
          newState.data.rideId = rideId;
        }

        // the legacy chat application was called benbot and is returned from API
        if (_.get(action, 'data.triggerBenbot', false) === true) {
          newState.chatRequestData = cloneDeep(action.data);
        }
      }
      newState.timestamp = Math.floor(Date.now() / 1000);
      return newState;
    }

    // TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
    case SUBMIT_RIDE_LYFT_ON_DEMAND_ERROR: {
      if (action?.error?.status === 401) {
        logout('Submit Ride Lyft On Demand Error scheduleRides.js redux', {
          error: action.error,
          state
        });
        return state;
      }

      const newState = cloneDeep(state);
      newState.error = _.get(action, 'error', {});
      newState.error.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);

      // make consistent with other error msgs
      newState.errorObj = _.get(action, 'error', {});
      newState.errorObj.type = action.type;
      return newState;
    }

    case SUBMIT_RIDE_ERROR: {
      if (action?.error?.status === 401) {
        logout('Submit Ride Error scheduleRides.js redux', {
          error: action.error,
          state
        });
        return state;
      }

      const newState = cloneDeep(state);
      newState.error = _.get(action, 'error', {});
      newState.error.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);

      // make consistent with other error msgs
      newState.errorObj = _.get(action, 'error', {});
      newState.errorObj.type = action.type;
      return newState;
    }

    // TODO:UBER — REMOVE AFTER UBER INTEGRATION IS COMPLETE
    case SUBMIT_RIDE_LYFT_ON_DEMAND: {
      const newState = {};

      newState.data = {};
      newState.ride = cloneDeep(state.ride);
      newState.data.message = _.get(action, 'data.message', '');
      newState.data.status = _.get(action, 'data.status', '');
      newState.data.rideResponse = _.get(action, 'data.data', {});

      if (
        _.get(action, 'data.status', '') === true &&
        _.get(action, 'data.data.id', '') !== ''
      ) {
        newState.data.redirectUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_HOST}/ride/scheduled?ride=${action.data.data.id}#ride${action.data.data.id}`;
        newState.data.rideId = action.data.data.id;
      }

      newState.timestamp = Math.floor(Date.now() / 1000);
      newState.type = action.type;
      return newState;
    }

    case LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL: {
      const newState = _.cloneDeep(state);
      newState.data = action.data;
      newState.timestamp = Math.floor(Date.now() / 1000);
      newState.type = action.type;
      if (action.data.status === false) {
        newState.errorObj = {};
        newState.errorObj.status = 500;
        newState.errorObj.message = action.data.message;
      }
      return newState;
    }

    case LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR: {
      const newState = _.cloneDeep(state);
      newState.errorObj = {};
      newState.errorObj.message = _.get(action, 'error', '');
      if (isEmpty(newState.errorObj.message)) {
        newState.errorObj.status = 500;
        newState.errorObj.message =
          'There was an error updating your ride. Please try again or contact support.';
      }
      newState.errorObj.type = action.type;
      newState.timestamp = Math.floor(Date.now() / 1000);
      return newState;
    }

    case ROUTE_SELECTED: {
      const newState = _.cloneDeep(state);
      newState.routeSelected = action.data;
      return newState;
    }

    //clears out error modal states to prevent repeat popups
    case RESET_FORM_STATE: {
      const newState = cloneDeep(state);

      newState.auth = {};
      return newState;
    }

    case RESET_FORM: {
      return {};
    }

    default: {
      if (_.has(state, 'rows')) {
        const newState = cloneDeep(state);

        newState.errorMessage = '';
        newState.error = {};
        newState.timestamp = Math.floor(Date.now() / 1000);
        _.unset(newState, 'errorObj');
        return newState;
      }
      return state;
    }
  }
};
