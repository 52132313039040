import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import ReactLoading from 'react-loading';
import { RESTRICTION_TYPE } from '~/constants';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import SvgDelete from '~/Shared/Components/Svgs/SvgDelete';
import BenefitsModal from '../BenefitsModal';

class PlanBenefits extends Component {
  constructor(props) {
    super(props);

    const defaults = this.getFormDefaults();
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.state = {
      showModal: false,
      selectedBenefitId: 0,
      page: 1,
      pageSize: 100,
      count: 0,
      totalPages: 1,
      ...defaults
    };
  }

  componentDidMount() {
    if (!this.props.interactiveMode) {
      // load benefits if selected plan available (i.e. from another tab)
      const id = _.get(this.props, 'selectedPlan.id', 0);
      if (id > 0 && !this.props.selectedPlan.benefits) {
        this.props.getBenefits({ id });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // add escape key event handler
    if (this.state.showModal && !prevState.showModal) {
      window.addEventListener('keydown', this.handleKeyDown);
    }

    // remove escape key event handler
    if (!this.state.showModal && prevState.showModal) {
      window.removeEventListener('keydown', this.handleKeyDown);
    }
  }

  /**
   * Handle keydown events
   * @param {Event} e Event object
   * @returns {undefined}
   */
  handleKeyDown(e) {
    const ESC_KEY = 27;
    if (e.keyCode === ESC_KEY) {
      this.setState({ showModal: false });
    }
  }

  /**
   * Handle add benefit
   * @returns {undefined}
   */
  handleAddBenefit() {
    const defaults = this.getFormDefaults();

    this.setState({
      showModal: true,
      selectedBenefitId: 0,
      ...defaults
    });
  }

  /**
   * Handle request to close modal
   * @param {SyntheticEvent} e React event object
   * @returns {undefined}
   */
  handleCloseModal = e => {
    e.stopPropagation();
    this.setState({ showModal: false });
  };

  /**
   * Handle request to edit existing benefit
   * @param {Number} id benefit ID
   * @returns {undefined}
   */
  handleEditBenefit(id) {
    const benefits = this.props.interactiveMode
      ? this.props.interactiveBenefits
      : this.props.selectedPlan.benefits.rows;
    const benefit = benefits.find(r => r.id === id);

    if (benefit) {
      this.setState(
        {
          showModal: true,
          selectedBenefitId: id,
          restrictionTypeId: benefit.restrictionTypeId,
          alertLimit: benefit.alertLimit,
          blockLimit: benefit.blockLimit,
          bookAfterBlock: benefit.bookAfterBlock
        },
        () => {
          this.setState({
            isValidForm: this.checkFormValidity()
          });
        }
      );
    }
  }

  /**
   * Handle request to delete benefit
   * @param {Number} id Benefit ID
   * @returns {undefined}
   */
  handleDeleteBenefit(id) {
    if (this.props.interactiveMode) {
      const interactiveBenefits = this.props.interactiveBenefits.filter(r => r.id !== id);
      this.props.setInteractiveBenefits(interactiveBenefits);
    } else {
      const hasRequestedDelete = window.confirm(
        `Are you sure you want to delete this benefit?`
      );
      if (hasRequestedDelete) {
        this.props.deleteBenefit({
          id,
          subPlanId: this.props.selectedPlan.id
        });
      }
    }
  }

  /**
   * Handle request to add benefit
   * @returns {undefined}
   */
  handleSubmitBenefit = () => {
    const newbenefit = {
      restrictionTypeId: this.state.restrictionTypeId,
      alertLimit: this.state.alertLimit,
      blockLimit: this.state.blockLimit,
      bookAfterBlock: this.state.bookAfterBlock
    };

    // in interactive mode, benefits are saved via a props function instead of to the
    // DB via an API
    if (this.props.interactiveMode) {
      const interactiveBenefits = _.cloneDeep(this.props.interactiveBenefits);

      // check if editing an existing benefit or creating a new one
      if (this.state.selectedBenefitId > 0) {
        // update existing benefit
        interactiveBenefits.forEach(benefit => {
          if (benefit.id === this.state.selectedBenefitId) {
            benefit = Object.assign(benefit, newbenefit);
          }
        });
      } else {
        // add new benefit
        interactiveBenefits.push({
          id: interactiveBenefits.length + 1,
          ...newbenefit
        });
      }

      this.setState(
        {
          interactiveBenefits,
          showModal: false
        },
        () => {
          this.props.setInteractiveBenefits(interactiveBenefits);
        }
      );
    } else {
      // check if editing an existing benefit or creating a new one
      if (this.state.selectedBenefitId > 0) {
        this.props.updateBenefit({
          id: this.state.selectedBenefitId,
          subPlanId: this.props.selectedPlan.id,
          ...newbenefit
        });
      } else {
        this.props.createBenefit({
          subPlanId: this.props.selectedPlan.id,
          ...newbenefit
        });
      }

      this.setState({ showModal: false });
    }
  };

  /**
   * Get form defaults
   * @returns {Object} Default state variables
   */
  getFormDefaults() {
    return {
      restrictionTypeId: RESTRICTION_TYPE.NONE.id,
      alertLimit: '',
      blockLimit: '',
      bookAfterBlock: false,
      isValidForm: false
    };
  }

  /**
   * Set the form field in state
   * @param {String} field Field name
   * @param {String|Number} value Field value to save in state
   * @returns {undefined}
   */
  setFormField(field, value) {
    this.setState(
      prevState => (prevState[field] = value),
      () => {
        this.setState({
          isValidForm: this.checkFormValidity()
        });
      }
    );
  }

  /**
   * Check modal form validity
   * @returns {Boolean} true/false
   */
  checkFormValidity() {
    if (
      this.state.restrictionTypeId > 0 &&
      !isNaN(parseInt(this.state.alertLimit, 10)) &&
      !isNaN(parseInt(this.state.blockLimit, 10))
    ) {
      return true;
    } else {
      return false;
    }
  }

  getRestrictionType(restrictionTypeId) {
    const typeKey = Object.keys(RESTRICTION_TYPE).find(
      key => RESTRICTION_TYPE[key].id === restrictionTypeId
    );
    if (typeKey) {
      return RESTRICTION_TYPE[typeKey];
    } else {
      return RESTRICTION_TYPE.NONE;
    }
  }

  render() {
    const benefits = this.props.interactiveMode
      ? this.props.interactiveBenefits
      : this.props.selectedPlan.benefits;
    const selectedRestrictionType = this.getRestrictionType(this.state.restrictionTypeId);

    return (
      <div className="PlanBenefits">
        {!benefits ? (
          <ReactLoading type="spin" className="tabLoading" />
        ) : (
          <>
            <table>
              <thead>
                <tr>
                  <th>Benefit Type</th>
                  <th>Alert After</th>
                  <th>Block After</th>
                  <th className="bookAfterBlock">Book After Block</th>
                  {this.props.isSuperAdmin || this.props.interactiveMode ? (
                    <th className="addBenefit">
                      <div onClick={() => this.handleAddBenefit()}>Add New Benefit</div>
                    </th>
                  ) : null}
                </tr>
              </thead>
              <tbody>
                {!this.props.interactiveMode &&
                  benefits.rows.map((benefit, idx) => {
                    const restrictionType = this.getRestrictionType(
                      benefit.restrictionTypeId
                    );
                    return (
                      <tr key={idx}>
                        <td>{restrictionType.label}</td>
                        <td>
                          {benefit.alertLimit} {restrictionType.unit}
                        </td>
                        <td>
                          {benefit.blockLimit} {restrictionType.unit}
                        </td>
                        <td>{benefit.bookAfterBlock ? 'Yes' : 'No'}</td>
                        {this.props.isSuperAdmin || this.props.interactiveMode ? (
                          <td className="actions">
                            <div
                              className="edit"
                              onClick={() => this.handleEditBenefit(benefit.id)}
                            >
                              <SvgEdit />
                            </div>
                            <div
                              className="delete"
                              onClick={() => this.handleDeleteBenefit(benefit.id)}
                            >
                              <SvgDelete />
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
                {this.props.interactiveMode &&
                  benefits.map((benefit, idx) => {
                    const restrictionType = this.getRestrictionType(
                      benefit.restrictionTypeId
                    );
                    return (
                      <tr key={idx}>
                        <td>{restrictionType.label}</td>
                        <td>
                          {benefit.alertLimit} {restrictionType.unit}
                        </td>
                        <td>
                          {benefit.blockLimit} {restrictionType.unit}
                        </td>
                        <td>{benefit.bookAfterBlock ? 'Yes' : 'No'}</td>
                        {this.props.isSuperAdmin || this.props.interactiveMode ? (
                          <td className="actions">
                            <div
                              className="edit"
                              onClick={() => this.handleEditBenefit(benefit.id)}
                            >
                              <SvgEdit />
                            </div>
                            <div
                              className="delete"
                              onClick={() => this.handleDeleteBenefit(benefit.id)}
                            >
                              <SvgDelete />
                            </div>
                          </td>
                        ) : null}
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
        <BenefitsModal
          showModal={this.state.showModal}
          handleCloseModal={this.handleCloseModal}
          title="Benefit"
        >
          <form onSubmit={e => e.preventDefault()}>
            <div className="formElement">
              <label>Benefit Type</label>
              <div className="selectWrapper">
                <select
                  value={this.state.restrictionTypeId}
                  onChange={e =>
                    this.setFormField('restrictionTypeId', parseInt(e.target.value, 10))
                  }
                >
                  {Object.keys(RESTRICTION_TYPE).map(key => (
                    <option key={key} value={RESTRICTION_TYPE[key].id}>
                      {RESTRICTION_TYPE[key].label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="formElement">
              <label>Alert After</label>
              <input
                type="number"
                value={this.state.alertLimit}
                onChange={e => this.setFormField('alertLimit', e.target.value)}
              />
              <sub>{selectedRestrictionType.unit}</sub>
            </div>
            <div className="formElement">
              <label>Block After</label>
              <input
                type="number"
                value={this.state.blockLimit}
                onChange={e => this.setFormField('blockLimit', e.target.value)}
              />
              <sub>{selectedRestrictionType.unit}</sub>
            </div>
            <div className="formElement">
              <label>Book After Block</label>
              <input
                type="checkbox"
                checked={this.state.bookAfterBlock}
                onChange={e => this.setFormField('bookAfterBlock', e.target.checked)}
              />
            </div>
          </form>
          <div className="buttons">
            <button className="cancel" onClick={this.handleCloseModal}>
              Cancel
            </button>
            <button
              className="submit"
              disabled={!this.state.isValidForm}
              onClick={this.handleSubmitBenefit}
            >
              Submit
            </button>
          </div>
        </BenefitsModal>
      </div>
    );
  }
}

PlanBenefits.propTypes = {
  interactiveMode: PropTypes.bool,
  selectedPlan: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  getBenefits: PropTypes.func,
  createBenefit: PropTypes.func,
  updateBenefit: PropTypes.func,
  deleteBenefit: PropTypes.func,
  interactiveBenefits: PropTypes.array,
  setInteractiveBenefits: PropTypes.func
};

PlanBenefits.defaultProps = {
  interactiveMode: false,
  selectedPlan: {},
  isSuperAdmin: false,
  getBenefits: () => {},
  createBenefit: () => {},
  updateBenefit: () => {},
  deleteBenefit: () => {},
  interactiveBenefits: [],
  setInteractiveBenefits: () => {}
};

export default PlanBenefits;
