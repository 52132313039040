import React from 'react';

//components
import SimpleCheckbox from 'Common/Components/SimpleCheckbox';

export type ApprovedProviderFoundBoxProps = {
  display: boolean;
  value: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
  className?: string;
};

const ApprovedProviderFoundBox: React.FC<ApprovedProviderFoundBoxProps> = ({
  display,
  value,
  onClick,
  className = 'approved-provider-checkbox'
}) => {
  // eslint-disable-next-line no-extra-boolean-cast
  const isChecked = !!value ? '1' : '0';

  if (!display) {
    return null;
  }

  return (
    <div className={className} onClick={onClick} is-checked={isChecked}>
      <SimpleCheckbox checked={value} />
      <div className="approved-provider-text">Provider Info Not Found</div>
    </div>
  );
};

export default ApprovedProviderFoundBox;
