import immutable from 'immutability-helper';
import { ActionTypes } from './constants';
import { handleActions } from 'redux-actions';
import { mapRideDetailsToExpenseReport } from './helpers';
import _ from 'lodash-es';

// INITIAL STATE
const memExpState = {
  modal: { mode: 'create', type: null, show: false, expense: null },
  slideout: { type: null, show: false, id: null },
  options: {
    healthPlans: [],
    hospitalData: [],
    vehicle_types: [],
    complianceInfo: [],
    dependentFields: {},
    expenseSettings: {
      paidBy: [],
      defaultMileageRate: 0,
      reimbursementMethod: []
    }
  },
  activityLog: {
    data: null,
    query: '',
    reportId: ''
  },
  // NOTE: Upon create/edit a couple more properties
  //       are added to the report for expense totals.
  report: {
    toDate: null,
    status: 'To be reviewed',
    purpose: '',
    duration: 0,
    expenses: [],
    expensesDeleted: [],
    fromDate: null,
    memberId: null,
    lastName: '',
    medicalId: '',
    firstName: '',
    passengers: [],
    hospitalId: null,
    statusReason: null,
    mobilityType: null,
    fundingSource: null,
    previousStatus: null,
    subMobilityType: '',
    complianceFields: {},
    reimbursementMethod: null,
    ride_id: null
  },
  status: {
    get: null,
    update: null,
    delete: null,
    create: null
  },
  currentAttachmentUpload: { status: null, name: null }
};

// MEMBER EXPENSES REDUCER
export const memberExpensesReducer = handleActions(
  {
    [ActionTypes.SET_STATUS]: (state, { payload: { name, value } }) => {
      return immutable(state, {
        status: { [name]: { $set: value } }
      });
    },
    [ActionTypes.GET_REPORT_BY_ID]: state => {
      return immutable(state, {
        status: { get: { $set: 'running' } }
      });
    },
    [ActionTypes.GET_REPORT_BY_ID_SUCCESS]: (state, { data }) => {
      const { status, expenses = {}, passengers = {}, complianceFields = {} } = data;
      const report = {
        ...data,
        previousStatus: status,
        complianceFields: Array.isArray(complianceFields) ? {} : complianceFields,
        // Normalize expenses for easier use. Will be converted back upon edit.
        expenses: Object.entries(expenses).reduce((acc, [key, value]) => {
          acc.push({ id: key, ...value });
          return acc;
        }, []),
        passengers: Object.entries(passengers).reduce((acc, [key, value]) => {
          acc.push({ id: key, ...value });
          return acc;
        }, [])
      };
      return immutable(state, {
        report: { $set: report },
        status: { get: { $set: null } }
      });
    },
    [ActionTypes.GET_REPORT_ACTIVITY_LOG_SUCCESS]: (state, { data, id, query }) => {
      return immutable(state, {
        activityLog: {
          data: { $set: data.data },
          query: { $set: query },
          reportId: { $set: id }
        }
      });
    },
    [ActionTypes.REMOVE_EXPENSE]: (state, { payload: { index } }) => {
      return immutable(state, {
        report: {
          expenses: {
            $set: state.report.expenses.filter((x, i) => index !== i)
          },
          expensesDeleted: {
            $set: state.report.expensesDeleted
              ? state.report.expensesDeleted.concat(
                state.report.expenses.filter((x, i) => index === i)
              )
              : state.report.expenses.filter((x, i) => index === i)
          }
        }
      });
    },
    [ActionTypes.REMOVE_REPEATING_EXPENSES]: (state, { payload: { ids } }) => {
      return immutable(state, {
        report: {
          expenses: {
            $set: state.report.expenses.filter(e => !ids.includes(e.id))
          },
          expensesDeleted: {
            $set: state.report.expensesDeleted
              ? state.report.expensesDeleted.concat(
                state.report.expenses.filter(e => ids.includes(e.id))
              )
              : state.report.expenses.filter(e => ids.includes(e.id))
          }
        }
      });
    },
    [ActionTypes.SET_EXPENSE_MODAL]: (
      state,
      {
        payload: {
          type,
          show,
          mode = 'create',
          index = undefined,
          expense = null,
          isDuplicate = null,
          duplicates = []
        }
      }
    ) => {
      return immutable(state, {
        modal: { $set: { mode, type, show, expense, index, isDuplicate, duplicates } }
      });
    },

    [ActionTypes.SET_EXPENSE_FIELD]: (state, { payload: { name, value } }) => {
      return immutable(state, {
        report: { [name]: { $set: value } }
      });
    },
    [ActionTypes.UPLOAD_ATTACHMENT]: state => {
      return immutable(state, {
        currentAttachmentUpload: { status: { $set: 'running' } }
      });
    },
    [ActionTypes.UPLOAD_ATTACHMENT_SUCCESS]: (state, { data }) => {
      return immutable(state, {
        currentAttachmentUpload: { $set: { file: data, status: 'success' } }
      });
    },
    [ActionTypes.UPLOAD_ATTACHMENT_ERROR]: state => {
      return immutable(state, {
        currentAttachmentUpload: { status: { $set: 'failed' } }
      });
    },
    [ActionTypes.SET_CURRENT_ATTACHMENT_UPLOAD_STATE]: (state, { payload }) =>
      immutable(state, {
        currentAttachmentUpload: { $set: payload }
      }),
    [ActionTypes.SET_EXPENSE_OPTIONS]: (state, { payload }) =>
      immutable(state, {
        options: payload.reduce((accumulator, { name, value }) => {
          // sort paidby options in asc order
          if (name === 'expenseSettings') {
            value.paidBy = value.paidBy ? _.sortBy(value.paidBy, 'value') : [];

            value.reimbursementMethod = value.reimbursementMethod
              ? _.sortBy(value.reimbursementMethod, 'value')
              : [];
          } else if (typeof value === 'object') {
            value.paidBy = [];
          }

          accumulator[name] = { $set: value };

          return accumulator;
        }, {})
      }),
    [ActionTypes.SET_EXPENSE_SLIDEOUT]: (state, { payload }) =>
      immutable(state, {
        modal: { $set: memExpState.modal },
        report: { $set: memExpState.report },
        slideout: { $set: payload }
      }),
    [ActionTypes.CREATE_EXPENSE_REPORT]: state =>
      immutable(state, {
        status: { create: { $set: 'running' } }
      }),
    [ActionTypes.CREATE_EXPENSE_REPORT_ERROR]: state =>
      immutable(state, {
        status: { create: { $set: 'error' } }
      }),
    [ActionTypes.CREATE_EXPENSE_REPORT_SUCCESS]: state =>
      immutable(state, {
        status: { create: { $set: 'success' } }
      }),
    [ActionTypes.UPDATE_EXPENSE_REPORT]: state =>
      immutable(state, {
        status: { update: { $set: 'running' } }
      }),
    [ActionTypes.UPDATE_EXPENSE_REPORT_ERROR]: state =>
      immutable(state, {
        status: { update: { $set: 'error' } }
      }),
    [ActionTypes.UPDATE_EXPENSE_REPORT_SUCCESS]: state =>
      immutable(state, {
        status: { update: { $set: 'success' } }
      }),
    [ActionTypes.CHECK_DUPLICATE_EXPENSES]: state => {
      return immutable(state, {
        modal: { $merge: { isDuplicate: null, duplicates: [] } }
      });
    },
    [ActionTypes.CHECK_DUPLICATE_EXPENSES_SUCCESS]: (
      state,
      { data: { isDuplicate, expenses } }
    ) => {
      return immutable(state, {
        modal: { $merge: { isDuplicate, duplicates: expenses } }
      });
    },
    [ActionTypes.CHECK_DUPLICATE_EXPENSES_ERROR]: state =>
      immutable(state, {
        modal: { $merge: { isDuplicate: 'error' } }
      }),
    [ActionTypes.RESET_EXPENSE_REPORT]: state =>
      immutable(state, {
        report: { $set: memExpState.report }
      }),
    [ActionTypes.SET_REPORT_PERSONAL_INFO]: (
      state,
      { payload: { id, medicalId, firstName, lastName, healthPlanId } }
    ) =>
      immutable(state, {
        report: {
          memberId: { $set: id },
          lastName: { $set: lastName },
          firstName: { $set: firstName },
          medicalId: { $set: medicalId },
          fundingSource: { $set: healthPlanId }
        }
      }),
    [ActionTypes.SET_RIDE_ID_ON_EXPENSE_REPORT]: (state, { payload }) => {
      const { complianceFields, mobilityType, subMobilityType, tripPurpose, passengers } =
        mapRideDetailsToExpenseReport(payload);
      return immutable(state, {
        report: {
          complianceFields: { $set: complianceFields },
          mobilityType: { $set: mobilityType },
          subMobilityType: { $set: subMobilityType },
          purpose: { $set: tripPurpose || '' },
          passengers: { $set: passengers }
        }
      });
    }
  },
  memExpState
);
