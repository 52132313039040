import React, { useState } from 'react';
import { Button, Input, Link, TOKENS } from '@SRHealth/frontend-lib';
import Divider from './Divider';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import { addressSavedFactory, type AddressSaved, type AddressSavedModel } from '~/models';
import { deleteSavedLocationThunk } from '~/Modules/savedAddress/thunks/deleteSavedLocation.thunk';
import { useAppDispatch } from '~/Modules';
import { changeDefaultAddressThunk } from '~/Modules/savedAddress/thunks/updateSavedLocation.thunk';
import { useSavedLocations } from './SavedLocations.context';

interface SavedLocationCardProps {
  savedLocation: MemberSavedAddress;
  onAddressChange: (type: 'depart' | 'arrive', address: AddressSavedModel) => void;
}

export const SavedLocationCard = ({
  savedLocation,
  onAddressChange
}: SavedLocationCardProps) => {
  const dispatch = useAppDispatch();
  const { setSavedLocationsDrawerMode } = useSavedLocations();

  const [type, setType] = useState<'depart' | 'arrive'>('depart');

  function handleAddressChange(savedLocation: MemberSavedAddress) {
    const newModelProps: AddressSaved = {
      type: 'saved',
      value: savedLocation.address,
      id: savedLocation.id,
      name: savedLocation.name,
      latitude: savedLocation.latitude,
      longitude: savedLocation.longitude
    };

    setSavedLocationsDrawerMode('closed');
    onAddressChange(type, addressSavedFactory(newModelProps));
  }

  return (
    <div
      className={`reset-div flex flex-col w-full rounded-[8px] shadow-b-wrap border-1 border-${TOKENS.BORDER.NEUTRAL_MD} bg-${TOKENS.SURFACE.PRIMARY.WHITE}`}
      style={{ padding: '16px 24px' }}
    >
      <div className="flex flex-row justify-between">
        <div className="text-xl">{savedLocation.name}</div>
        <Link
          label="Remove"
          onClick={() =>
            dispatch(
              deleteSavedLocationThunk({
                passengerId: savedLocation.passenger_id,
                locationId: savedLocation.id
              })
            )
          }
        />
      </div>
      <Divider />
      <div className="flex flex-col" style={{ paddingTop: '8px' }}>
        <div className="text-md">{savedLocation.address}</div>
        <Input
          type="checkbox"
          name="set-as-default"
          inputId={1}
          options={[{ label: 'Set as Default', value: 'default' }]}
          onChange={() => dispatch(changeDefaultAddressThunk({ savedLocation }))}
          style={{ paddingTop: '16px', marginBottom: '8px' }}
          value={savedLocation.is_default ? ['default'] : []}
        />
        <div className="flex flex-col w-full justify-center items-center gap-[8px]">
          <div style={{ width: '185px' }}>
            <Input
              inputId={2}
              type="radio"
              name="type"
              options={[
                { label: 'Depart', value: 'depart' },
                { label: 'Arrive', value: 'arrive' }
              ]}
              value={'depart'}
              onChange={(_, v: 'arrive' | 'depart') => setType(v)}
              style={{ marginBottom: '2px' }}
            />
          </div>
          <Divider />
          <Button
            label="Apply"
            size="sm"
            onClick={() => handleAddressChange(savedLocation)}
            minWidth="124px"
          />
        </div>
      </div>
    </div>
  );
};
