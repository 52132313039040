import _ from 'lodash-es';
import axios from './safeAxios';
import { baseRequestConfig } from '~/utilities/auth.helper';
import { put, takeEvery, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
export const GET_NETWORK_MANAGERS = 'hospitalNetwork/GET_NETWORK_MANAGERS';
export const GET_NETWORK_MANAGERS_SUCCESS =
  'hospitalNetwork/GET_NETWORK_MANAGERS_SUCCESS';
export const GET_NETWORK_MANAGERS_ERROR = 'hospitalNetwork/GET_NETWORK_MANAGERS_ERROR';
export const GET_NETWORK_MANAGER = 'hospitalNetwork/GET_NETWORK_MANAGER';
export const GET_NETWORK_MANAGER_SUCCESS = 'hospitalNetwork/GET_NETWORK_MANAGER_SUCCESS';
export const GET_NETWORK_MANAGER_ERROR = 'hospitalNetwork/GET_NETWORK_MANAGER_ERROR';
export const UPDATE_MANAGER_STATUS = 'hospitalNetwork/UPDATE_MANAGER_STATUS';
export const UPDATE_MANAGER_STATUS_SUCCESS =
  'hospitalNetwork/UPDATE_MANAGER_STATUS_SUCCESS';
export const CREATE_NETWORK_MANAGER_SUCCESS =
  'hospitalNetwork/CREATE_NETWORK_MANAGER_SUCCESS';
export const UPDATE_MANAGER_STATUS_ERROR = 'hospitalNetwork/UPDATE_MANAGER_STATUS_ERROR';
export const ADD_NETWORK_MANAGER = 'hospitalNetwork/ADD_NETWORK_MANAGER';
export const UPDATE_NETWORK_MANAGER = 'hospitalNetwork/UPDATE_NETWORK_MANAGER';
export const UPDATE_NETWORK_MANAGER_SUCCESS =
  'hospitalNetwork/UPDATE_NETWORK_MANAGER_SUCCESS';
export const UPDATE_NETWORK_MANAGER_ERROR =
  'hospitalNetwork/UPDATE_NETWORK_MANAGER_ERROR';
export const UPDATE_NETWORK_ERROR = 'hospitalNetwork/UPDATE_NETWORK_ERROR';
export const CLEAR_NETWORK_DATA = 'hospitalNetwork/CLEAR_NETWORK_DATA';
export const CLEAR_MANAGER_DATA = 'hospitalNetwork/CLEAR_MANAGER_DATA';
const ERROR_GET_MANAGERS =
  'There was an error retrieving the network managers. If the issue persists, contact support.';
const ERROR_GET_MANAGER =
  'There was an error retrieving the network manager. If the issue persists, contact support.';
const ERROR_MESSAGE_STATUS =
  'There was an error updating status. If the issue persists, contact support.';
const ERROR_MESSAGE_UPDATE_MANAGER =
  'There was an error adding or updating the network manager. If the issue persists, contact support.';

const generateTimestamp = () => {
  return {
    date: moment().utc().format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
    timezone: 'UTC',
    timezone_type: 3
  };
};

/**
 * removes all hospital network data
 * @return {dispatch} - returns dispatch
 */
export const clearNetworkData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_NETWORK_DATA
    });
  };
};

/**
 * removes manager data
 * @param {interger} id - manager id
 * @return {dispatch} - returns dispatch
 */
export const clearManagerData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_MANAGER_DATA
    });
  };
};

export const getNetworkManagers = params => {
  return dispatch => {
    dispatch({
      type: GET_NETWORK_MANAGERS,
      data: params
    });
  };
};

export const getNetworkManager = id => {
  return dispatch => {
    dispatch({
      type: GET_NETWORK_MANAGER,
      data: { id }
    });
  };
};

export const updateManagerStatus = params => {
  return dispatch => {
    dispatch({
      type: UPDATE_MANAGER_STATUS,
      data: params
    });
  };
};

export const updateManager = (params, assignedFacilities, unassignedFacilities) => {
  return dispatch => {
    dispatch({
      type: UPDATE_NETWORK_MANAGER,
      data: params,
      assignedFacilities,
      unassignedFacilities
    });
  };
};

/**
 * generator for getting all of the managers
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* getManagersFromApi(action = {}) {
  const params = action.data;
  const { offset, limit, sort, sortType } = params;
  const config = baseRequestConfig();
  config.method = 'GET';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/hospital-network/list-managers?offset=${offset}&limit=${limit}&sort=${sort}&sortType=${sortType}`;
  if (_.has(params, 'search') && !_.isNil(params.search)) {
    config.url += `&search=${params.search}`;
  }
  try {
    const results = yield axios(config);
    const networkManagers = _.get(results, 'data.data.network_managers', []);
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true && Array.isArray(networkManagers)) {
      const totalCount = _.get(results, 'data.data.total_count', 0);
      yield put({
        type: GET_NETWORK_MANAGERS_SUCCESS,
        networkManagers,
        params,
        totalCount,
        timestamp
      });
    } else {
      const error = [{ errorMessage: results.data.message }];
      yield put({ type: GET_NETWORK_MANAGERS_ERROR, error, timestamp });
    }
  } catch (error) {
    const timestamp = _.get(error, 'response.data.timestamp', {});
    const httpCode = _.get(error, 'response.data.httpCode', 500);
    if (_.get(error, 'response.data.errors', '') !== '') {
      const errorList = [];
      _.forOwn(error.response.data.errors, (singleError, errorCode) => {
        const errorMessage = _.get(singleError, 'message', ERROR_GET_MANAGERS);
        const errorData = _.get(singleError, 'data');
        errorList.push({
          errorMessage,
          errorData,
          errorCode,
          httpCode
        });
      });
      yield put({ type: GET_NETWORK_MANAGERS_ERROR, error: errorList, timestamp });
    } else {
      const errorInfo = [
        {
          errorMessage: ERROR_GET_MANAGERS,
          httpCode: 500
        }
      ];
      yield put({
        type: GET_NETWORK_MANAGERS_ERROR,
        error: errorInfo,
        timestamp: generateTimestamp()
      });
    }
  }
}

/**
 * generator for getting a specific manager
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* getManagerFromApi(action = {}) {
  const params = action.data;
  const { id } = params;
  const config = baseRequestConfig();
  config.method = 'GET';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/hospital-network/network-manager?id=${id}`;

  try {
    const results = yield axios(config);
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true) {
      const networkManager = _.get(results, 'data.data.networkManager', {});
      const userOptions = _.get(results, 'data.data.userOptions', {});
      const unassignedFacilities = _.get(results, 'data.data.hospitals', {});
      const assignedFacilities = _.get(results, 'data.data.userHospitals', {});
      yield put({
        type: GET_NETWORK_MANAGER_SUCCESS,
        networkManager,
        userOptions,
        unassignedFacilities,
        assignedFacilities,
        timestamp
      });
    } else {
      const error = [{ errorMessage: results.data.message }];
      yield put({ type: GET_NETWORK_MANAGER_ERROR, error, timestamp });
    }
  } catch (error) {
    const timestamp = _.get(error, 'response.data.timestamp', {});
    const httpCode = _.get(error, 'response.data.httpCode', 500);
    if (_.get(error, 'response.data.errors', '') !== '') {
      const errorList = [];
      _.forOwn(error.response.data.errors, (singleError, errorCode) => {
        const errorMessage = _.get(singleError, 'message', ERROR_GET_MANAGER);
        const errorData = _.get(singleError, 'data');
        errorList.push({
          errorMessage,
          errorData,
          errorCode,
          httpCode
        });
      });
      yield put({ type: GET_NETWORK_MANAGER_ERROR, error: errorList, timestamp });
    } else {
      const errorInfo = [
        {
          errorMessage: ERROR_GET_MANAGER,
          httpCode: 500
        }
      ];
      yield put({
        type: GET_NETWORK_MANAGER_ERROR,
        error: errorInfo,
        timestamp: generateTimestamp()
      });
    }
  }
}

/**
 * generator for updating status
 * @param {object} action - object from action
 * @return {saga} - yield saga for reducer
 */
function* putChangeStatus(action = {}) {
  const params = action.data;
  const config = baseRequestConfig();
  config.method = 'PUT';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/hospital-network/update-manager-status`;
  config.data = { id: params.id, accountStatus: params.accountStatus };

  try {
    const results = yield axios(config);
    const networkManager = _.get(results, 'data.data.networkManager', {});
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true && !_.isNil(networkManager)) {
      yield put({
        type: UPDATE_MANAGER_STATUS_SUCCESS,
        networkManager,
        params,
        timestamp
      });
    } else {
      const error = [{ errorMessage: results.data.message }];
      yield put({ type: UPDATE_MANAGER_STATUS_ERROR, error, timestamp });
    }
  } catch (error) {
    const timestamp = _.get(error, 'response.data.timestamp', {});
    const httpCode = _.get(error, 'response.data.httpCode', 500);
    if (_.get(error, 'response.data.errors', '') !== '') {
      const errorList = [];
      _.forOwn(error.response.data.errors, (singleError, errorCode) => {
        const errorMessage = _.get(singleError, 'message', ERROR_MESSAGE_STATUS);
        const errorData = _.get(singleError, 'data');
        errorList.push({
          errorMessage,
          errorData,
          errorCode,
          httpCode
        });
      });
      yield put({ type: UPDATE_MANAGER_STATUS_ERROR, error: errorList, timestamp });
    } else {
      const errorInfo = [
        {
          errorMessage: ERROR_MESSAGE_STATUS,
          httpCode: 500
        }
      ];
      yield put({
        type: UPDATE_MANAGER_STATUS_ERROR,
        error: errorInfo,
        timestamp: generateTimestamp()
      });
    }
  }
}

/**
 * generator for updating status
 * @param {object} action - object from action
 * @return {saga} - yield saga for reducer
 */
function* postUpdateManager(action = {}) {
  const params = action.data;
  const state = yield select();
  const { hospitalNetwork } = state;
  const config = baseRequestConfig();
  config.method = 'POST';
  config.url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/hospital-network/network-manager`;
  config.data = params;

  try {
    const results = yield axios(config);
    const networkManager = _.get(results, 'data.data.networkManager', {});
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true && !_.isNil(networkManager)) {
      // const newManager = !_.has(params, 'id') || _.isNil(params.id);
      const type =
        !_.has(params, 'id') || _.isNil(params.id)
          ? CREATE_NETWORK_MANAGER_SUCCESS
          : UPDATE_NETWORK_MANAGER_SUCCESS;
      const { assignedFacilities, unassignedFacilities } = action;
      yield put({
        type,
        params,
        timestamp,
        assignedFacilities,
        unassignedFacilities,
        networkManager
      });
      if (type === CREATE_NETWORK_MANAGER_SUCCESS) {
        if (_.has(hospitalNetwork, 'params.query')) {
          yield* getManagersFromApi({
            data: {
              offset: 0,
              limit: 25,
              sort: 'firstName',
              sortType: 'asc'
            }
          });
        }
      }
    } else {
      const error = [{ errorMessage: results.data.message }];
      yield put({ type: UPDATE_NETWORK_MANAGER_ERROR, error, timestamp });
    }
  } catch (error) {
    const timestamp = _.get(error, 'response.data.timestamp', {});
    const httpCode = _.get(error, 'response.data.httpCode', 500);
    if (_.get(error, 'response.data.errors', '') !== '') {
      const errorList = [];
      _.forOwn(error.response.data.errors, (singleError, errorCode) => {
        const errorMessage = _.get(singleError, 'message', ERROR_MESSAGE_UPDATE_MANAGER);
        const errorData = _.get(singleError, 'data', {});
        errorList.push({
          errorMessage,
          errorData,
          errorCode,
          httpCode
        });
      });
      yield put({ type: UPDATE_NETWORK_MANAGER_ERROR, error: errorList, timestamp });
    } else {
      const errorInfo = [
        {
          errorMessage: ERROR_MESSAGE_UPDATE_MANAGER,
          httpCode: 500
        }
      ];
      yield put({
        type: UPDATE_NETWORK_MANAGER_ERROR,
        error: errorInfo,
        timestamp: generateTimestamp()
      });
    }
  }
}

/**
 * Saga function wrapper
 * @returns {undefined}
 */
export function* hospitalNetworkSaga() {
  yield takeEvery(GET_NETWORK_MANAGERS, getManagersFromApi);
  yield takeEvery(GET_NETWORK_MANAGER, getManagerFromApi);
  yield takeEvery(UPDATE_MANAGER_STATUS, putChangeStatus);
  yield takeEvery(UPDATE_NETWORK_MANAGER, postUpdateManager);
}

/**
 * reducer for hospital network managers
 * @param {object} state - previous reducer state
 * @param {object} action - action data placed by saga
 * @return {object} newState - updated application state for reducer
 */
export const hospitalNetworkReducer = (state = {}, action = {}) => {
  const newState = _.cloneDeep(state);
  switch (action.type) {
    case CLEAR_NETWORK_DATA: {
      return {};
    }
    case CLEAR_MANAGER_DATA: {
      if (!_.has(newState, 'networkManager')) {
        return newState;
      }
      newState.type = action.type;
      _.unset(newState, 'networkManager');
      return newState;
    }
    case GET_NETWORK_MANAGER_SUCCESS: {
      newState.networkManager = action.networkManager;
      newState.networkManager.hospitals = action.hospitals;
      newState.networkManager.nextDayEmail = action.userOptions.next_day_email;
      newState.networkManager.timestamp = action.timestamp;
      if (action.assignedFacilities.length > 0) {
        newState.networkManager.assignedFacilities = action.assignedFacilities;

        // filter out assigned facilities since unassigned facilityies initially has all facilities
        newState.networkManager.unassignedFacilities = action.unassignedFacilities.filter(
          facility => {
            const index = _.findIndex(action.assignedFacilities, facility);
            if (index === -1) {
              return facility;
            }
          }
        );
      } else {
        // if network manager does not have any facilities assiged to them, then
        // give them all of the facilities. then HO can remove facilites as neeeded.
        newState.networkManager.unassignedFacilities = [];
        newState.networkManager.assignedFacilities = action.unassignedFacilities;
      }

      newState.timestamp = action.timestamp;
      newState.type = action.type;

      return newState;
    }
    case GET_NETWORK_MANAGERS_SUCCESS: {
      newState.params = action.params;
      newState.sortParams = {
        start: action.params.offset,
        limit: action.params.limit,
        orderBy: action.params.sort,
        orderByType: action.params.sortType
      };
      newState.networkManagers = action.networkManagers;
      newState.type = action.type;
      newState.totalCount = action.totalCount;
      newState.managersTimestamp = action.timestamp;

      return newState;
    }
    case UPDATE_MANAGER_STATUS_SUCCESS: {
      const key = action.params.key;
      newState.type = action.type;
      newState.networkManagers[key] = action.networkManager;
      newState.managersTimestamp = action.timestamp;
      return newState;
    }
    case GET_NETWORK_MANAGERS_ERROR:
    case UPDATE_MANAGER_STATUS_ERROR: {
      newState.error = action.error;
      newState.type = action.type;
      newState.managersTimestamp = action.timestamp;
      return newState;
    }
    case GET_NETWORK_MANAGER_ERROR:
    case UPDATE_NETWORK_MANAGER_ERROR: {
      newState.error = action.error;
      newState.type = action.type;
      newState.timestamp = action.timestamp;
      return newState;
    }
    case CREATE_NETWORK_MANAGER_SUCCESS: {
      newState.networkManager = action.networkManager;
      newState.networkManager.assignedFacilities = action.assignedFacilities;
      newState.networkManager.unassignedFacilities = action.unassignedFacilities;
      newState.networkManager.timestamp = action.timestamp;
      newState.type = action.type;
      // add new network manager to the top of array
      newState.networkManagers.unshift(newState.networkManager);
      if (newState.networkManager.length > 25) {
        newState.networkManagers.pop();
      }
      newState.totalCount++;
      newState.timestamp = action.timestamp;
      return newState;
    }
    case UPDATE_NETWORK_MANAGER_SUCCESS: {
      newState.networkManager.firstName = action.networkManager.firstName;
      newState.networkManager.lastName = action.networkManager.lastName;
      newState.networkManager.mobileNo = action.networkManager.mobileNo;
      newState.networkManager.email = action.networkManager.email;
      newState.networkManager.nextDayEmail = action.params.nextDayEmail;
      newState.networkManager.assignedFacilities = action.assignedFacilities;
      newState.networkManager.unassignedFacilities = action.unassignedFacilities;
      newState.networkManager.timestamp = action.timestamp;
      newState.type = action.type;

      // modifiy existing network maanger
      newState.networkManagers = newState.networkManagers.map(manager => {
        if (manager.id === newState.networkManager.id) {
          manager.firstName = newState.networkManager.firstName;
          manager.lastName = newState.networkManager.lastName;
          manager.mobileNo = newState.networkManager.mobileNo;
          manager.email = newState.networkManager.email;
        }
        return manager;
      });

      newState.timestamp = action.timestamp;
      return newState;
    }
    default: {
      return newState;
    }
  }
};
