import React, { useState, useEffect } from 'react';
import { Icon, Input, MultiSelectButton, TOKENS } from '@SRHealth/frontend-lib';
import { type AddressModel, addressSavedFactory, type AddressSavedModel } from '~/models';
import type { AddressFieldType } from '../Rides.types';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import { useAppSelector } from '~/Modules';
import type { AddressFormAddressType } from './AddressForm';
import { useAddressChange } from './Address.utils';
import { useSavedLocations } from './SavedLocations.context';

const ADD_NEW_SAVED_LOCATION = 'add_new_saved_location';

export type AddressFormSavedLocationProps = {
  type: AddressFieldType;
  address: AddressModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  /** Optional alternative address types available to the user. */
  addressTypes?: AddressFormAddressType[];
};

export default function AddressFormSavedLocation({
  address,
  type,
  onAddressChange,
  addressTypes = []
}: AddressFormSavedLocationProps) {
  const savedAddresses = useAppSelector(state => state.patients?.savedAddresses ?? []);
  const [selectedAddressId, setSelectedAddressId] = useState<string | undefined>();
  const handleAddressTypeChange = useAddressChange(address.type, type, onAddressChange);
  const { setSavedLocationsDrawerMode } = useSavedLocations();

  const hasAddressTypes = addressTypes.length > 1;
  const activeAddressType = addressTypes.findIndex(t => t.value === address.type);

  useEffect(() => {
    setSelectedAddressId((address as AddressSavedModel).id.toString());
  }, [address]);

  function handleAddressChange(_id: number, val: string) {
    if (val === ADD_NEW_SAVED_LOCATION) {
      setSavedLocationsDrawerMode('create');
      return;
    }

    const selectedAddress = savedAddresses.find(({ id }) => id.toString() === val);
    if (selectedAddress) {
      const addressSavedModel = addressSavedFactory({
        type: 'saved',
        value: selectedAddress.address,
        id: selectedAddress.id,
        name: selectedAddress.name,
        latitude: selectedAddress.latitude,
        longitude: selectedAddress.longitude
      });
      setSelectedAddressId(val);
      onAddressChange(type, addressSavedModel);
    }
  }

  const savedAddressOptions = [
    ...savedAddresses.map((savedAddress: MemberSavedAddress) => ({
      value: savedAddress.id.toString(),
      label: (
        <span>
          <span className="font-bold">{savedAddress.name}: </span> {savedAddress.address}
        </span>
      )
    })),
    {
      value: ADD_NEW_SAVED_LOCATION,
      label: (
        <div className="flex items-center gap-[4px]" style={{ marginLeft: '-2px' }}>
          <Icon
            type="AddCircleOutline"
            className={`fill-current text-${TOKENS.COMPONENT.BUTTON.DEFAULT} w-[24px] h-[24px]`}
          />
          <span className={`text-${TOKENS.COMPONENT.BUTTON.DEFAULT}`}>
            Add New Saved Location
          </span>
        </div>
      )
    }
  ];

  return (
    <>
      <div className="flex-1 min-w-0">
        <Input
          inputId={0}
          type="select"
          name={type}
          label={`${type === 'depart' ? 'Depart' : 'Arrive'} - Saved Location`}
          options={savedAddressOptions}
          placeholder="Select Saved Location"
          onChange={handleAddressChange}
          value={selectedAddressId}
          error={!!Object.keys(address.ruleErrors).length}
          required
        />
      </div>
      {hasAddressTypes ? (
        <div className="flex items-center h-[40px]" style={{ paddingBottom: '16px' }}>
          <MultiSelectButton
            buttons={addressTypes}
            activeButton={activeAddressType}
            onClick={handleAddressTypeChange}
          />
        </div>
      ) : null}
    </>
  );
}
