import React, { useState } from 'react';
import { Badge, Drawer, Link, type ButtonConfig } from '@SRHealth/frontend-lib';
import Divider from './Divider';
import { useAppSelector } from '~/Modules';
import CareProviderDrawerBody from './CareProviderDrawerBody';
import type { AddressFieldType } from '../Rides.types';
import type { AddressModel } from '~/models';
import SavedLocationsDrawer from './SavedLocationsDrawer';
import { useSavedLocations } from './SavedLocations.context';

export type AddressTrayProps = {
  addressTypes?: ButtonConfig[];
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
};

export default function AddressTray({
  addressTypes = [],
  onAddressChange
}: AddressTrayProps) {
  const [approvedProviderDrawerIsOpen, setApprovedProviderDrawerIsOpen] = useState(false);
  const savedLocations = useAppSelector(state => state.patients.savedAddresses);
  const { setSavedLocationsDrawerMode } = useSavedLocations();

  const hasApprovedProvider = addressTypes?.find(
    ({ label }) => label === 'Care Provider'
  );

  const hasSavedAddress = addressTypes?.find(({ label }) => label === 'Saved Location');

  // If there's no saved address or approved provider support
  // then don't display the tray.
  if (!hasApprovedProvider && !hasSavedAddress) return null;

  return (
    <>
      <Divider />
      <div className="flex flex-row" style={{ marginTop: '-2px', marginBottom: '-2px' }}>
        <div className="flex flex-1">
          {hasApprovedProvider && (
            <Link
              label="Find Care Provider"
              iconLeft="CareProvider"
              size="sm"
              onClick={() => setApprovedProviderDrawerIsOpen(true)}
            />
          )}
        </div>
        <div className="flex flex-1" style={{ justifyContent: 'flex-end' }}>
          {hasSavedAddress && (
            <>
              <Link
                label="Saved Locations"
                iconLeft="AddCircleOutline"
                size="sm"
                onClick={() => setSavedLocationsDrawerMode('list')}
              />
              {savedLocations.length > 0 && (
                <Badge type="number" value={savedLocations.length} />
              )}
            </>
          )}
        </div>
      </div>

      <Drawer
        width="1100px"
        isOpen={approvedProviderDrawerIsOpen}
        onClose={() => setApprovedProviderDrawerIsOpen(false)}
        fixed
      >
        <Drawer.Header label="Find Card Provider" icon="CareProvider">
          <Link
            alt
            label="Close"
            iconRight="Close"
            onClick={() => setApprovedProviderDrawerIsOpen(false)}
            preventDefault
          />
        </Drawer.Header>
        <CareProviderDrawerBody
          onAddressChange={(type, address) => {
            setApprovedProviderDrawerIsOpen(false);
            onAddressChange(type, address);
          }}
        />
      </Drawer>

      <SavedLocationsDrawer
        savedLocations={savedLocations}
        onAddressChange={onAddressChange}
      />
    </>
  );
}
