export const FrequencyEnum = Object.freeze({
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY'
});

export const DaysEnum = Object.freeze({
  MO: 'MO',
  TU: 'TU',
  WE: 'WE',
  TH: 'TH',
  FR: 'FR',
  SA: 'SA',
  SU: 'SU'
});

export const MonthlyRepeatENUM = Object.freeze({
  MONTHLY_BY_DAY: 'MONTHLY_BY_DAY',
  MONTHLY_BY_WEEK_NUMBER: 'MONTHLY_BY_WEEK_NUMBER'
});

export const EditRepeatingExpensesEnum = Object.freeze({
  THIS_EXPENSE: 'THIS_EXPENSE',
  THIS_AND_FOLLOWING: 'THIS_AND_FOLLOWING',
  ALL_EXPENSES: 'ALL_EXPENSES'
});