import React from 'react';
import { AmbulatoryTag } from './AmbulatoryTag';
import { BasicLifeSupportTag } from './BasicLifeSupportTag';
import { LyftTag } from './LyftTag';
import { MileageReimbursementTag } from './MileageReimbursementTag';
import { PrivateTransportTag } from './PrivateTransportTag';
import { PublicTransportTag } from './PublicTransportTag';
import { StretcherTag } from './StretcherTag';
import { UberTag } from './UberTag';
import { UndefinedTransportTypeTag } from './UndefinedTransportTypeTag';
import { WheelchairTag } from './WheelchairTag';
import { XLWheelchairTag } from './XLWheelchairTag';
import type { VehicleTypeNickName } from '~/types';

export const TRANSPORT_TYPE_COMPONENT_MAP = {
  BLS: BasicLifeSupportTag,
  LFT: LyftTag,
  MRA: MileageReimbursementTag,
  MRV: MileageReimbursementTag,
  MRX: MileageReimbursementTag,
  ALS: PrivateTransportTag,
  AMB: AmbulatoryTag,
  Public: PublicTransportTag,
  STR: StretcherTag,
  UBR: UberTag,
  undefined: UndefinedTransportTypeTag,
  WCV: WheelchairTag,
  WCX: XLWheelchairTag
} as const;
export interface TagProps {
  width?: string;
  height?: string;
}

export type TransportTypeTagProps = {
  nickName: VehicleTypeNickName;
  width?: string;
  height?: string;
};

function TransportTypeTag({ nickName, width, height }: TransportTypeTagProps) {
  const TagComponent = TRANSPORT_TYPE_COMPONENT_MAP[`${nickName}`] ?? null;

  return TagComponent ? <TagComponent width={width} height={height} /> : null;
}

export default TransportTypeTag;
