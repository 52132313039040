import { createAppAsyncThunk } from '~/Modules';
import { initializeDateThunk } from './initializeDate.thunk';

export const completeFundingSourceThunk = createAppAsyncThunk(
  'rideBooking/completeFundingSource',
  (_, { getState, dispatch }) =>
    getState()
      .rideBooking.fundingSource.commit()
      .then(() => dispatch(initializeDateThunk()))
);
