import type { MemberSavedAddress } from '~/services/savedAddress.service';
import SavedAddress from './SavedAddress.constants';

export type ErrorObject = { [inputId: string]: string };

/**
 * Validates the Saved Address inputs from the SavedAddressColumn
 * component
 * @param rawAddress
 * @param savedAddress
 * @param addressList
 * @returns
 */
export const validateSavedAddressForm = (
  rawAddress: string,
  savedAddress: MemberSavedAddress,
  addressList: MemberSavedAddress[]
) => {
  const newErrors: ErrorObject = {};

  if (!rawAddress) {
    newErrors.savedAddressInput = SavedAddress.Errors.INVALID_ADDRESS_INPUT;
  }

  if (!savedAddress.name) {
    newErrors.savedAddressName = SavedAddress.Errors.INVALID_ADDRESS_NAME;
  } else {
    const isDuplicateName = addressList
      .map(({ name }) => name.toLowerCase())
      .includes(savedAddress.name.toLowerCase());

    if (isDuplicateName) {
      newErrors.savedAddressName = SavedAddress.Errors.DUPLICATE_ADDRESS_NAME;
    }

    const isDuplicateAddress = addressList
      .map(({ address }) => address.toLowerCase())
      .includes(savedAddress.address.toLowerCase());

    if (isDuplicateAddress) {
      newErrors.savedAddressInput = SavedAddress.Errors.DUPLICATE_ADDRESS;
    }
  }

  if (addressList.length >= SavedAddress.MAX_SAVED_ADDRESSES) {
    newErrors.modal = SavedAddress.Errors.SAVED_ADDRESS_LIMIT;
  }

  return newErrors;
};
