import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import ReactModal from 'react-modal';
import moment from 'moment';
import Button from '@material-ui/core/Button';

import { setExportModal, exportTableData } from '~/Modules/expenses/actions';
import { DateInput } from '../TableFilter/TableFilter';

// required React Modal style object
const style = {
  overlay: {
    zIndex: 1000,
    backgroundColor: `rgba(0, 0, 0, 0.71)`
  },
  content: {
    zIndex: 100,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const ExportModal = ({ columns, filterParams }) => {
  const dispatch = useDispatch();
  const [exportParams, setExportParams] = useState({ ...filterParams, fromDate: null, toDate: null});
  const [disabled, setDisable] = useState(true);
  const { fromDate, toDate } = exportParams;

  // adds 'toDate' if 'fromDate' is selected by default
  useEffect(() => {
    if(exportParams.fromDate && !exportParams.toDate) {
      setExportDate({ name: 'toDate', value: exportParams.fromDate });
      setDisable(false);
    }
  });

  // clears 'toDate if 'fromDate' is empty
  useEffect(() => {
    if (exportParams.fromDate === '' && exportParams.toDate) {
      setExportDate({ name: 'toDate', value: exportParams.fromDate });
      setDisable(true);
    }
  }, [exportParams]);

  // enables 'toDate' if 'fromDate' has value
  useEffect(() => {
    if (exportParams.fromDate) {
      setDisable(false);
    }
  }, [exportParams]);

  // action to close modal
  const closeModal = () => {
    dispatch(setExportModal({ show: false }));
  };

  // action to set date field 
  const setExportDate = ({ name, value }) => {
    setExportParams({ ...exportParams, [name]: value });
  };

  //action to export table
  const handleExportTable = () => {
    dispatch(exportTableData({ query: exportParams, filename: 'expense-reports.csv', columns }));
    closeModal();
  };

  const disable = () => exportParams.toDate && exportParams.fromDate;

  const Divider = () => <div className="divider"></div>;

  const ModalButtons = () => (
    <div className="modal-buttons flex">
      <Button 
        className="grey-btn" 
        onClick={closeModal}
      >
                Cancel
      </Button>
      <div className="spacer-8"></div>
      <Button
        className="green-btn"
        disabled={!disable()}
        onClick={() => handleExportTable()}
      >
                Select
      </Button>
    </div>
  );

  const modalProps = {
    style: style,
    isOpen: true,
    ariaHideApp: false,
    shouldCloseOnOverlayClick: true
  };

  return (
    <ReactModal {...modalProps}>
      <div className='ExportModal'>
        <div className='modal-header'>
          <div>Select Date Range</div>
          <div 
            className='modal-btn-close' 
            onClick={closeModal}
          >
                        X
          </div>
        </div>
        <Divider />
        <div className='modal-body'>
          <DateInput 
            label='Start Date'
            key='fromDate'
            name='fromDate'
            title='fromDate'
            value={fromDate}
            maxDate={moment(toDate).format('MM/DD/YYYY')}
            setField={value => setExportDate({ name: 'fromDate', value })}
          />
          <DateInput 
            label='End Date'
            key='toDate'
            name='toDate'
            title='toDate'
            value={toDate}
            disable={disabled}
            minDate={moment(fromDate).format('MM/DD/YYYY')}
            setField={value => setExportDate({ name: 'toDate', value })}
          />
        </div>
        <Divider />
        <ModalButtons />
      </div>
    </ReactModal>
  );
};

export default ExportModal;