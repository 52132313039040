import React from 'react';
import PropTypes from 'prop-types';
import {
  formatPhoneNumber,
  extendLodashGet,
  isRideShareVehicleId
} from '~/utilities/helperFunctions';
import { LYFT_VEHICLE_ID, COMPLETED_OR_CANCELLED } from '~/constants';
import { get, has, isNil } from 'lodash-es';

// note, location pings count is missing from the api
const TripSummary = props => {
  const { rideDetails, dataToggles, ride } = props;
  const rideData = ride;
  const cancelledOrCompleted =
    COMPLETED_OR_CANCELLED.indexOf(rideData.status) > -1 ? true : false;
  let passengerCost =
    !isNil(rideData.passengerCost) &&
    rideData.passengerCost !== '' &&
    !cancelledOrCompleted
      ? `$${parseFloat(rideData.passengerCost, 10).toFixed(2)}`
      : '';

  if (rideData.modelName === 'Lyft') {
    passengerCost = `$${Math.round(get(rideData, 'cost', passengerCost) * 1000) / 1000}`;
  }

  const renderRideShareId = vehicleId => {
    if (vehicleId === LYFT_VEHICLE_ID) {
      const rideId = rideDetails.lyftDetails
        ? rideDetails.lyftDetails.flex_ride_id
          ? rideDetails.lyftDetails.flex_ride_id
          : rideDetails.lyftDetails.lyft_ride_id
        : '';
      const referenceCode = rideDetails.lyftDetails
        ? rideDetails.lyftDetails.referenceCode
          ? rideDetails.lyftDetails.referenceCode
          : rideDetails.lyftDetails.reference_code
        : '';

      return (
        <>
          <li>
            <p>Lyft Ride Id</p>
            <p>{rideId}</p>
          </li>
          <li>
            <p>Lyft Reference Code</p>
            <p>{referenceCode}</p>
          </li>
          <li>
            <p>Transportation Type</p>
            <p>
              {get(rideDetails, 'lyftDetails.ride_type', '') === 'lyft_plus'
                ? 'Lyft SUV'
                : 'Lyft Sedan'}
            </p>
          </li>
        </>
      );
    } else {
      const rideId = rideDetails.uberDetails
        ? rideDetails.uberDetails.flex_ride_id
          ? rideDetails.uberDetails.flex_ride_id
          : rideDetails.uberDetails.uber_ride_id
        : '';
      const referenceCode = rideDetails.uberDetails
        ? rideDetails.uberDetails.referenceCode
          ? rideDetails.uberDetails.referenceCode
          : rideDetails.uberDetails.reference_code
        : '';
      return (
        <>
          <li>
            <p>Uber Ride Id</p>
            <p>{rideId}</p>
          </li>
          <li>
            <p>Uber Reference Code</p>
            <p>{referenceCode}</p>
          </li>
          <li>
            <p>Transportation Type</p>
            <p>{get(rideDetails, 'uberDetails.ride_type', '')}</p>
          </li>
        </>
      );
    }
  };

  const renderRideShareVehicleInformation = vehicleId => {
    if (vehicleId === LYFT_VEHICLE_ID) {
      return (
        <>
          {has(rideDetails, 'lyftDetails.ride_data.vehicle.color') ? (
            <li>
              <p>Vehicle Color</p>
              <p>{get(rideDetails, 'lyftDetails.ride_data.vehicle.color', '')}</p>
            </li>
          ) : null}
          {has(rideDetails, 'lyftDetails.ride_data.vehicle.make') ||
          has(rideDetails, 'lyftDetails.ride_data.vehicle.model') ? (
              <li>
                <p>Vehicle Make and Model</p>
                <p>
                  {get(rideDetails, 'lyftDetails.ride_data.vehicle.make', '')}{' '}
                  {get(rideDetails, 'lyftDetails.ride_data.vehicle.model', '')}
                </p>
              </li>
            ) : null}
          {has(rideDetails, 'lyftDetails.ride_data.vehicle.license_plate') ? (
            <li>
              <p>License Plate</p>
              <p>
                {get(rideDetails, 'lyftDetails.ride_data.vehicle.license_state', '')}{' '}
                {get(rideDetails, 'lyftDetails.ride_data.vehicle.license_plate', '')}
              </p>
            </li>
          ) : null}
        </>
      );
    } else {
      return (
        <>
          {has(rideDetails, 'uberDetails.ride_data.vehicle.vehicle_color_name') ? (
            <li>
              <p>Vehicle Color</p>
              <p>
                {get(rideDetails, 'uberDetails.ride_data.vehicle.vehicle_color_name', '')}
              </p>
            </li>
          ) : null}
          {has(rideDetails, 'uberDetails.ride_data.vehicle.make') ||
          has(rideDetails, 'uberDetails.ride_data.vehicle.model') ? (
              <li>
                <p>Vehicle Make and Model</p>
                <p>
                  {get(rideDetails, 'uberDetails.ride_data.vehicle.make', '')}{' '}
                  {get(rideDetails, 'uberDetails.ride_data.vehicle.model', '')}
                </p>
              </li>
            ) : null}
          {has(rideDetails, 'uberDetails.ride_data.vehicle.license_plate') ? (
            <li>
              <p>License Plate</p>
              <p>
                {get(rideDetails, 'uberDetails.ride_data.vehicle.license_state', '')}{' '}
                {get(rideDetails, 'uberDetails.ride_data.vehicle.license_plate', '')}
              </p>
            </li>
          ) : null}
        </>
      );
    }
  };
  return (
    <div className="tripSummary">
      <h2>Trip Summary</h2>
      {isRideShareVehicleId(rideDetails.reqVehicleType) ? (
        <ul className="tripSummaryList">
          <li>
            <p>Ride Id</p>
            <p>{rideDetails.id}</p>
          </li>
          {renderRideShareId(rideDetails.reqVehicleType)}
          {renderRideShareVehicleInformation(rideDetails.reqVehicleType)}
          {dataToggles.hospital_name ? (
            <li>
              <p>Hospital Name</p>
              <p>{get(rideDetails, 'hospital.hospitalName', '')}</p>
            </li>
          ) : null}

          {has(rideDetails, 'booking_method') &&
          rideDetails.booking_method === 'member-portal' ? (
              <li>
                <p>Booked By</p>
                <p>Member</p>
              </li>
            ) : has(rideDetails, 'booked') ? (
              <li>
                <p>Booked By</p>
                <p>
                  {rideDetails.booked.firstName} {rideDetails.booked.lastName}
                </p>
              </li>
            ) : null}
          {has(rideDetails, 'booked') ? (
            <li>
              <p>Booked Contact</p>
              <p>{formatPhoneNumber(rideDetails.booked.userMobileNo)}</p>
            </li>
          ) : null}
          {!isNil(rideDetails.late_time) && rideDetails.rideType !== 'ondemand' ? (
            <li>
              <p>Driver Punctuality</p>
              <p>{rideDetails.late_time}</p>
            </li>
          ) : null}
          {!isNil(rideDetails.distance) ? (
            <li>
              <p>Billable Distance</p>
              <p>{Number(rideDetails.distance).toFixed(2)} miles</p>
            </li>
          ) : null}
          <li>
            <p>Trip Duration</p>
            <p>{rideDetails.trip_duration}</p>
          </li>
          <li>
            <p>Estimated Cost</p>
            <p>{passengerCost}</p>
          </li>
          <li>
            <p>Ride Cost</p>
            {cancelledOrCompleted ? (
              <p>${Number(rideDetails.totalCost).toFixed(2)}</p>
            ) : (
              <p>To be finalized</p>
            )}
          </li>
          {dataToggles.trip_fee && !isNil(rideDetails.lastPayment) ? (
            <li>
              <p>Txn ID</p>
              <p>{rideDetails.lastPayment.txnno}</p>
            </li>
          ) : null}
          {has(rideDetails, 'pickupVenueName') ? (
            <li>
              <p>Venue Pickup Location</p>
              <p>{rideDetails.pickupVenueName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'pickupEntranceName') ? (
            <li>
              <p>Venue Pickup Entrance</p>
              <p>{rideDetails.pickupEntranceName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'dropoffVenueName') ? (
            <li>
              <p>Venue Dropoff Location</p>
              <p>{rideDetails.dropoffVenueName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'dropoffEntranceName') ? (
            <li>
              <p>Venue Dropoff Entrance</p>
              <p>{rideDetails.dropoffEntranceName}</p>
            </li>
          ) : null}
        </ul>
      ) : (
        <ul className="tripSummaryList">
          <li>
            <p>Ride Id</p>
            <p>{rideDetails.id}</p>
          </li>
          <li>
            <p>Mobility Type</p>
            <p>{rideData?.modelName}</p>
          </li>
          {!isNil(rideDetails.vehicleOdometerReading) ? (
            <li>
              <p>Vehicle Odometer (completed)</p>
              <p>{rideDetails.vehicleOdometerReading}</p>
            </li>
          ) : null}
          {dataToggles.transport_provider ? (
            <li>
              <p>Transport Provider</p>
              <p>{rideDetails.vehicleCompanyName}</p>
            </li>
          ) : null}
          {dataToggles.transport_provider && has(rideDetails.vehicle_owner) ? (
            <li>
              <p>Transport Contact</p>
              <p>{formatPhoneNumber(rideDetails.vehicle_owner.contactNo2)}</p>
            </li>
          ) : null}
          {dataToggles.hospital_name ? (
            <li>
              <p>Hospital Name</p>
              <p>{get(rideDetails, 'hospital.hospitalName', '')}</p>
            </li>
          ) : null}
          {has(rideDetails, 'booking_method') &&
          rideDetails.booking_method === 'member-portal' ? (
              <li>
                <p>Booked By</p>
                <p>Member</p>
              </li>
            ) : has(rideDetails, 'booked') ? (
              <li>
                <p>Booked By</p>
                <p>
                  {rideDetails.booked.firstName} {rideDetails.booked.lastName}
                </p>
              </li>
            ) : null}
          {has(rideDetails, 'booked') ? (
            <li>
              <p>Booked Contact</p>
              <p>{formatPhoneNumber(rideDetails.booked.userMobileNo)}</p>
            </li>
          ) : null}
          {!isNil(rideDetails.late_time) && rideDetails.rideType !== 'ondemand' ? (
            <li>
              <p>Driver Punctuality</p>
              <p>
                {rideDetails.dispatcher_managed
                  ? 'Dispatcher Managed'
                  : `${rideDetails.late_time}${
                    rideDetails.ShowOrigApptPickupTime ? '*' : ''
                  }`}
              </p>
            </li>
          ) : null}
          {rideDetails.rideType !== 'ondemand' &&
          rideDetails.ShowOrigApptPickupTime !== '' &&
          !rideDetails.dispatcher_managed ? (
              <li>
                <p>
                  {rideDetails.appointmentTime === null
                    ? 'Orig. Pickup Time'
                    : 'Orig. Appt. Time'}
                </p>
                <p>{rideDetails.ShowOrigApptPickupTime}</p>
              </li>
            ) : null}
          {!isNil(rideDetails.distance) ? (
            <li>
              <p>Billable Distance (Miles)</p>
              <p>
                {!rideDetails.dispatcher_managed
                  ? `${Number(rideDetails.distance).toFixed(2)} miles`
                  : 'Dispatcher Managed'}
              </p>
            </li>
          ) : null}
          <li>
            <p>Trip Duration</p>
            <p>{rideDetails.trip_duration}</p>
          </li>
          {extendLodashGet(rideDetails, 'cost', '') !== '' ? (
            <li>
              <p>Estimated Cost</p>
              <p>${parseFloat(rideDetails.cost).toFixed(2)}</p>
            </li>
          ) : null}
          {dataToggles.trip_fee ? (
            <li>
              <p>Ride Cost</p>
              <p>${Number(rideDetails.totalCost).toFixed(2)}</p>
            </li>
          ) : null}
          <li>
            <p>Claim Status</p>
            <p>{rideDetails?.claim_status}</p>
          </li>
          {dataToggles.trip_fee && !isNil(rideDetails.lastPayment) ? (
            <li>
              <p>Booking Fee</p>
              <p>
                {rideDetails.lastPayment.saferide_fee +
                  rideDetails.lastPayment.stripe_fee}
              </p>
            </li>
          ) : null}
          {dataToggles.trip_fee && !isNil(rideDetails.lastPayment) ? (
            <li>
              <p>Stripe Transaction ID</p>
              <p>
                {rideDetails.admin_flag ? (
                  <a href={rideDetails.lastPayment.transaction_link}>
                    {rideDetails.lastPayment.txnno}
                  </a>
                ) : (
                  rideDetails.lastPayment.txnno
                )}
              </p>
            </li>
          ) : null}
          {dataToggles.trip_fee && !isNil(rideDetails.lastPayment) ? (
            <li>
              <p>Stripe Transaction ID</p>
              <p>{rideDetails.lastPayment.pay_status}</p>
            </li>
          ) : null}
          {has(rideDetails, 'pickupVenueName') ? (
            <li>
              <p>Venue Pickup Location</p>
              <p>{rideDetails.pickupVenueName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'pickupEntranceName') ? (
            <li>
              <p>Venue Pickup Entrance</p>
              <p>{rideDetails.pickupEntranceName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'dropoffVenueName') ? (
            <li>
              <p>Venue Dropoff Location</p>
              <p>{rideDetails.dropoffVenueName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'dropoffEntranceName') ? (
            <li>
              <p>Venue Dropoff Entrance</p>
              <p>{rideDetails.dropoffEntranceName}</p>
            </li>
          ) : null}
          {has(rideDetails, 'alertClaimStatus') ? (
            <li>
              <p>Alert Claim Status: </p>
              <p>{rideDetails?.alertClaimStatus}</p>
            </li>
          ) : null}
        </ul>
      )}
    </div>
  );
};

TripSummary.defaultProps = {
  vehicleTypes: [],
  rideDetails: {},
  ride: {},
  dataToggles: {}
};

TripSummary.propTypes = {
  vehicleTypes: PropTypes.array,
  rideDetails: PropTypes.object,
  ride: PropTypes.object,
  dataToggles: PropTypes.object
};

export default TripSummary;
