import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import { fetchApprovedProviders } from '~/Modules/search';
import { NOOP } from '~/utilities/helperFunctions';

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
    width: '264px'
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: 30,
    padding: '2px 4px'
  },
  input: {
    'marginLeft': theme.spacing(1),
    'flex': 1,
    '& ::-webkit-input-placeholder': {
      color: 'black !important'
    }
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
}));

export default function CustomizedInputBase({
  value,
  id,
  disabled,
  onFocus,
  onClick,
  onChange
}) {
  onFocus ??= NOOP;
  onClick ??= NOOP;
  onChange ??= NOOP;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [_value, setValue] = useState(value);
  const hospitalId = useSelector(state => state.bookingData.hospitalId);

  const debounceAutoComplete = useCallback(
    debounce(q => dispatch(fetchApprovedProviders(q)), 500),
    []
  );

  /** Middleware onChange handler to allow the event to bubble
   * up to the parent component */
  const _onChange = e => {
    setValue(e.target.value.replace(/\s+/g, ' '));

    if (onChange) onChange(e);
  };

  /** Update the raw input value if props value is changed */
  useEffect(() => {
    if (value !== _value) {
      setValue(value);
    }
  }, [value]);

  /** Update the autocomplete search results */
  useEffect(() => {
    // Don't search if disabled, if the value from props matches current value
    // or if value is fewer than three characters
    if (!disabled && value !== _value && _value?.length >= 3) {
      debounceAutoComplete({ search: _value, hospitalId });
    }
  }, [_value]);

  return (
    <div className={classes.root} disabled={disabled}>
      <Paper className={classes.paper}>
        <InputBase
          className={classes.input}
          autoComplete="off"
          value={_value}
          id={id ? id : ''}
          disabled={disabled}
          onChange={_onChange}
          onFocus={onFocus}
          onClick={onClick}
          placeholder="Search for an approved provider"
          inputProps={{
            'autoComplete': 'off',
            'aria-label': 'search approved providers',
            'style': {
              backgroundColor: 'white',
              color: 'black',
              width: '100%',
              margin: 0,
              lineHeight: 0
            }
          }}
        />
        <IconButton type="submit" className={classes.iconButton} aria-label="search">
          <SearchIcon />
        </IconButton>
      </Paper>
    </div>
  );
}

CustomizedInputBase.propTypes = {
  value: PropTypes.string,
  id: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClick: PropTypes.func
};

CustomizedInputBase.defaultProps = {
  value: '',
  id: 0,
  disabled: false,
  onChange: () => {},
  onFocus: () => {},
  onClick: () => {}
};
