/** All possible user roles */
export const USER_ROLES = {
  healthPlanOwner: 'HealthPlanOwner',
  healthPlanAdmin: 'HealthPlanAdmin',
  hospitalOwner: 'HospitalOwner',
  hospitalNetworkManager: 'HospitalNetworkManager',
  hospitalManager: 'HospitalManager',
  hospitalAdmin: 'HospitalAdmin',
  caseManager: 'CaseManager',
  multiAccount: 'MultiAccount'
} as const;

export type UserRole = (typeof USER_ROLES)[keyof typeof USER_ROLES];

/** Potential roles for users operating at hospital network level */
export const HOSPITAL_NETWORK_ROLES: UserRole[] = [
  USER_ROLES.hospitalNetworkManager,
  USER_ROLES.hospitalOwner
] as const;

export type HospitalNetworkRole = (typeof HOSPITAL_NETWORK_ROLES)[number];

/** Roles allowed to interact with ride adjustments */
export const ADJUSTMENT_USER_ROLES: UserRole[] = [
  USER_ROLES.hospitalAdmin,
  USER_ROLES.hospitalOwner,
  USER_ROLES.hospitalNetworkManager,
  USER_ROLES.hospitalManager
];

export type AdjustmentUserRole = (typeof ADJUSTMENT_USER_ROLES)[number];
