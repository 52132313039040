import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import { fixUTCDate } from '~/utilities/timesAndDates';

export function sanitizeDuplicateRidesList(action) {
  const duplicateRidesList = action?.data
    ?.map(l => l?.duplicate_rides)
    .flat()
    .filter(Boolean);
  const duplicateRides = uniqBy(duplicateRidesList, 'id').map(ride => {
    return {
      ...ride,
      rideDate: fixUTCDate(ride?.rideStartTime, ride?.timezone, 'MM/DD/YYYY')
    };
  });
  return {
    duplicateRidesGroup: groupBy(duplicateRides, 'rideDate'),
    duplicateRides
  };
}

const RIDE_LEG_STATUS = {
  STOP: 'stop',
  BLOCK: 'block',
  ALERT: 'alert'
};

export const RIDE_LEG_STATUS_PRIORITIES = {
  stop: 0,
  block: 1,
  alert: 2
};

const transportTypes = {
  UBER: 'Uber',
  LYFT: 'Lyft'
};

export const alertTypes = {
  LOCATION_ERROR: 'locatin_error',
  DUPLICATE_RIDE: 'duplicate_ride'
};

/**
 * Iterates through the response list to identify ride leg
 * authorization.
 * @param {*} data
 * @param {boolean} allow
 * @param {*} lyftBlock
 * @param {*} params
 * @param {boolean} isLyft
 */

export function validateRideLegList(data, allow, lyftBlock, params, isLyft) {
  const { STOP, BLOCK, ALERT } = RIDE_LEG_STATUS;
  const { UBER, LYFT } = transportTypes;
  const { LOCATION_ERROR, DUPLICATE_RIDE } = alertTypes;

  const isRideShareLeg = type => [UBER, LYFT].includes(type);

  if (params.rides.length > 0 && !Object.isExtensible(params.rides[0])) {
    params.rides = cloneDeep(params.rides);
  }

  data.map(({ status, type, ride = {} }, index) => {
    const legTransportType = (ride && ride?.transportType) || '';
    if (status === STOP) {
      allow = false;
    }
    if (status === BLOCK && isRideShareLeg(legTransportType)) {
      allow = false;
      lyftBlock = true;
    }
    if (status === ALERT && type === LOCATION_ERROR) {
      allow = false;
    }
    if (status === ALERT && type === DUPLICATE_RIDE) {
      allow = false;
    }
    params.rides[index].product_id ??= null;
    params.rides[index].run_id ??= null;
    params.rides[index].fare_id ??= null;

    if (isRideShareLeg(legTransportType)) {
      params.rides[index].estimatedCost = ride?.cost;
      params.rides[index].cost_token = ride?.cost_token;
      params.rides[index].eta = ride?.eta;
      params.rides[index].distance = ride?.distance;

      if (legTransportType === UBER) {
        // I have no idea what the original intent was here...

        // eslint-disable-next-line no-self-assign
        params.rides[index].product_id = params?.rides[index]?.product_id;
        // eslint-disable-next-line no-self-assign
        params.rides[index].run_id = params?.rides[index]?.run_id;
        // eslint-disable-next-line no-self-assign
        params.rides[index].fare_id = params?.rides[index]?.fare_id;
      }

      if (params.bookingType === 'ondemand') {
        params.rides[index].duration = ride?.duration;
        params.rides[index].rideTimeUTC = ride?.rideTimeUTC;
        params.rides[index].rideShareType = params.rides[0].rideShareType;
      }
    } else {
      isLyft = false;
    }
  });

  return { allow, lyftBlock, isLyft, params };
}
