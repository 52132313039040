import React, { useCallback, useEffect, useState } from 'react';
import type { ApprovedProvidersProps } from '../ApprovedProviders/ApprovedProviders';

import ApprovedProviderInput from '../ApprovedProviderInput/ApprovedProviderInput';
import type { Address } from '~/types';
import { AddressAutoComplete } from '../ApprovedProviders/AddressAutoComplete';
import { NOOP } from '~/utilities/helperFunctions';

export type ApprovedProviderNotesProps = {
  display: boolean;
  type: ApprovedProvidersProps['type'];
  address: Address;
  onSelect: (row, type: ApprovedProvidersProps['type'], source?: string) => void;
  onReset: ApprovedProvidersProps['onResetAutoComplete'];
  updateRideDetails: (data: Record<string, unknown>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ApprovedProviderNotes: React.FC<ApprovedProviderNotesProps> = ({
  display,
  type,
  address,
  onSelect,
  onReset,
  onChange = NOOP,
  updateRideDetails
}) => {
  if (!display) {
    return null;
  }

  const [value, setValue] = useState<string>('');
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);

  const setField = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string & keyof Address
  ) => {
    const formattedField = type + field[0].toUpperCase() + field.substring(1);

    updateRideDetails({ [formattedField]: e.target.value });
  };

  /** Middleware to control the display of Autocomplete elements */
  const _onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);

    setShowAutoComplete(e.target.value.length >= 3);

    onChange(e);
  }, []);

  /** Allow the input to internally manage the value until an
   * autocomplete option is selected */
  useEffect(() => {
    if (address.address !== value) setValue(address.address);
  }, [address.address]);

  return (
    <div id={`${type}ProviderNotes`}>
      <ApprovedProviderInput
        label="Provider Name"
        value={address?.providerName?.toString() ?? ''}
        // error={this.getFieldError(`${type}ProviderName`)}
        onChange={e => setField(e, `providerName`)}
      />
      <ApprovedProviderInput
        label="Facility Name"
        value={address?.facilityName?.toString() ?? ''}
        // error={this.getFieldError(`${type}FacilityName`)}
        onChange={e => setField(e, `facilityName`)}
      />
      <ApprovedProviderInput
        label="Provider Address"
        value={value}
        // error={this.getFieldError(`${type}ProviderAddr`)}
        onChange={_onChange}
      />
      <AddressAutoComplete
        type={type}
        showAutoComplete={showAutoComplete}
        onSelect={row => onSelect(row, type)}
        onReset={onReset}
      />
      <ApprovedProviderInput
        label="Provider Phone Number"
        value={address?.providerPhone?.toString() ?? ''}
        // error={this.getFieldError(`${type}ProviderPhone`)}
        onChange={e => setField(e, `providerPhone`)}
        inputProps={{ maxLength: 13 }}
      />
      <ApprovedProviderInput
        label="Additional Notes (optional)"
        value={address?.additionalNotes?.toString() ?? ''}
        onChange={e => setField(e, `additionalNotes`)}
        placeholder={'Add NPI, building name, floor number, etc..'}
      />
    </div>
  );
};

export default ApprovedProviderNotes;
