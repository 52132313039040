import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';

const selectFeatures = (state: AppStore) => state.user.features;
const selectPatientApprovedProvider = (state: AppStore) =>
  state.patients.patientDetails?.approved_providers ?? 0;

export const selectAllowProvider = createSelector(
  [selectFeatures, selectPatientApprovedProvider],
  (features, patientApprovedProvider) =>
    features.approved_providers && !!patientApprovedProvider
);
