import React, { useRef, useEffect, useCallback } from 'react';
import ScheduledRide from './ScheduledRide/ScheduledRide';
import { VariableSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import type { UserStore } from '~/Modules/user';

export interface VirtualLoaderInterface {
  _listRef: List;
}

export type ScheduleCardFunctionsProp = {
  cancelRide: () => void;
  editRide: () => void;
  getChatRequestForRide: () => void;
  getUnreadMessagesCount: () => void;
  openNemtReassign: () => void;
  rebookRide: () => void;
  requestPatientPickup: () => void;
  selectChat: (id: number) => void;
  setOffset: () => void;
  setRideDetails: (obj: { rideId: number; ride: RideData }) => void;
  showEditRide: () => void;
  showReorderedDetails: () => void;
};

export type WindowInfiniteScrollProps = {
  hasNextPage: boolean;
  isNextPageLoading: boolean;
  items: RideData[];
  loadNextPage: boolean;
  user: UserStore;
  vehicleTypes: BE.VehicleRecord[];
  editRideIndex: number;
  page: string;
  medicalId: string | null;
  hospitalId: number | null;
  rideIdUpdatedDate: number | undefined;
  setRideDetails: (obj: { rideId: number; ride: RideData }) => void;
  showReorderedDetails: () => void;
  editRide: () => void;
  showEditRide: () => void;
  cancelRide: () => void;
  selectChat: (id: number) => void;
  requestPatientPickup: () => void;
  rebookRide: () => void;
  setOffset: () => void;
  openNemtReassign: () => void;
  getChatRequestForRide: () => void;
  getUnreadMessagesCount: () => void;
};

function WindowInfiniteScroll({
  hasNextPage,
  isNextPageLoading,
  items,
  loadNextPage,
  user,
  vehicleTypes,
  editRideIndex,
  page,
  medicalId,
  hospitalId,
  rideIdUpdatedDate,
  setRideDetails,
  showReorderedDetails,
  editRide,
  showEditRide,
  cancelRide,
  selectChat,
  requestPatientPickup,
  rebookRide,
  setOffset,
  openNemtReassign,
  getChatRequestForRide,
  getUnreadMessagesCount
}: WindowInfiniteScrollProps) {
  const height =
    document.querySelector('.showRideOverflow')?.getBoundingClientRect().height ?? 400;
  const virtualLoaderRef = useRef<VirtualLoaderInterface>(null);
  const defaultRideCardHeight = ['ride-share-alerts', 'ride-nemt-alerts'].includes(page)
    ? 250
    : 210;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? items.length + 1 : items.length;

  // Store a reference for the ride card heights
  const rideCardHeights = useRef(Array(itemCount).fill(defaultRideCardHeight));

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = isNextPageLoading ? () => {} : loadNextPage;

  const getRideCardHeight = index => {
    const isAlertTab = ['ride-share-alerts', 'ride-nemt-alerts'].includes(page);
    const lastCardPadding = index === itemCount - 1 && isAlertTab ? 50 : 0;
    const extraPadding = isAlertTab ? 50 : 20;
    const totalPadding = lastCardPadding + extraPadding;

    return rideCardHeights.current[index] + totalPadding || defaultRideCardHeight;
  };

  const setRideCardHeight = (index, size) => {
    rideCardHeights.current = { ...rideCardHeights.current, [index]: size };
    if (virtualLoaderRef.current) {
      virtualLoaderRef.current._listRef.resetAfterIndex(0, true);
    }
  };

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < items.length;

  useEffect(() => {
    if (virtualLoaderRef?.current) {
      virtualLoaderRef.current._listRef.scrollToItem(editRideIndex, 'center');
    }
  }, [virtualLoaderRef.current]);

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      ref={virtualLoaderRef}
    >
      {({ onItemsRendered, ref }) => (
        <List
          itemCount={itemCount}
          onItemsRendered={onItemsRendered}
          height={height}
          width="100%"
          ref={ref}
          itemSize={getRideCardHeight}
        >
          {({ style, index }) => (
            <ListItem
              setRideCardHeight={setRideCardHeight}
              style={style}
              user={user}
              vehicleTypes={vehicleTypes}
              index={index}
              item={items[index]}
              page={page}
              medicalId={medicalId}
              hospitalId={hospitalId}
              rideIdUpdatedDate={rideIdUpdatedDate}
              setRideDetails={setRideDetails}
              showReorderedDetails={showReorderedDetails}
              editRide={editRide}
              showEditRide={showEditRide}
              cancelRide={cancelRide}
              getChatRequestForRide={getChatRequestForRide}
              selectChat={selectChat}
              requestPatientPickup={requestPatientPickup}
              rebookRide={rebookRide}
              setOffset={setOffset}
              openNemtReassign={openNemtReassign}
              getUnreadMessagesCount={getUnreadMessagesCount}
              isItemLoaded={isItemLoaded}
            />
          )}
        </List>
      )}
    </InfiniteLoader>
  );
}

function ListItem({
  style,
  setRideCardHeight,
  user,
  vehicleTypes,
  index,
  item,
  page,
  medicalId,
  hospitalId,
  rideIdUpdatedDate,
  setRideDetails,
  showReorderedDetails,
  editRide,
  showEditRide,
  cancelRide,
  selectChat,
  requestPatientPickup,
  rebookRide,
  setOffset,
  openNemtReassign,
  getChatRequestForRide,
  getUnreadMessagesCount,
  isItemLoaded
}) {
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      const cardContainer = ref.current.querySelector('.cardContainer');
      const cardContainerHeight = cardContainer?.clientHeight;

      setRideCardHeight(index, cardContainerHeight);
    }
  }, [ref, index]);

  if (!isItemLoaded(index)) {
    return <div style={style}>Loading...</div>;
  }

  const chatRequest = getChatRequestForRide(item.id);
  const chatCount = getUnreadMessagesCount(chatRequest?.requestId);

  const _selectChat = useCallback(() => selectChat(chatRequest.requestId), [chatRequest]);

  const _showRideDetails = useCallback(
    () => setRideDetails({ rideId: item.id, item }),
    [item]
  );

  return (
    <div style={style} ref={ref}>
      <ScheduledRide
        ride={item}
        key={index}
        user={user}
        page={page}
        vehicleTypes={vehicleTypes}
        rideIdUpdatedDate={rideIdUpdatedDate}
        chatCount={chatCount}
        chatRequest={chatRequest}
        medicalId={medicalId}
        hospitalId={hospitalId}
        showRideDetails={_showRideDetails}
        showReorderedDetails={showReorderedDetails}
        editRide={editRide}
        showEditRide={showEditRide}
        cancelRide={cancelRide}
        selectChat={_selectChat}
        requestPatientPickup={requestPatientPickup}
        rebookRide={rebookRide}
        setOffset={setOffset}
        openNemtReassign={openNemtReassign}
      />
    </div>
  );
}

export default WindowInfiniteScroll;
