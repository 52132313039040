
export const GET_MEMBER_PERMISSIONS = 'GET_MEMBER_PERMISSIONS';
export const GET_MEMBER_PERMISSIONS_SUCCESS = 'GET_MEMBER_PERMISSIONS_SUCCESS';
export const GET_MEMBER_PERMISSIONS_ERROR = 'GET_MEMBER_PERMISSIONS_ERROR';

export const CREATE_NEW_MEMBER = 'CREATE_NEW_MEMBER';
export const CREATE_NEW_MEMBER_SUCCESS = 'CREATE_NEW_MEMBER_SUCCESS';
export const CREATE_NEW_MEMBER_ERROR = 'CREATE_NEW_MEMBER_ERROR';

export const SET_NEW_MEMBER_FORM = 'SET_NEW_MEMBER_FORM';
export const VALIDATE_NEW_MEMBER_FORM = 'VALIDATE_NEW_MEMBER_FORM';
export const CLEAR_NEW_MEMBER_FORM = 'CLEAR_NEW_MEMBER_FORM';