import _ from 'lodash-es';
import moment from 'moment';
import immutable from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { ActionTypes, INITIAL_STATE } from './constants';
import { PASSENGER_EDIT, PUBLIC_TRANSIT } from '~/constants';
import {
  isNetworkRole,
  isHealthPlanRole,
  statusNotifications
} from '~/utilities/helperFunctions';
import { extendVehicleRecords, getVehicleTripType } from '~/utilities/vehicles';
import type {
  MemberProfileStore,
  RideBenefit,
  PersonalInfoForm,
  MobilityForm,
  BenefitsForm,
  AttendantInfo
} from '~/types';
import { formatAssistanceNeeds } from './memberProfile.helper';
import { isNil } from '@SRHealth/frontend-lib';

export const memberProfileReducer = handleActions(
  {
    // on success of fetching table data
    [ActionTypes.CLEAR_FORM_DATA]: state => {
      return immutable(state, {
        error: { $set: undefined },
        success: { $set: undefined }
      });
    },
    [ActionTypes.CLEAR_MEMBER_DATA]: state => {
      return immutable(state, {
        formData: { $set: {} },
        mileageReimbursementDrivers: { $set: [] },
        memberPortalInfo: { $set: {} }
      });
    },
    [ActionTypes.CLEAR_ALL_DATA]: state => {
      return immutable(state, { $set: INITIAL_STATE });
    },
    [ActionTypes.LOAD_MEMBER_FORM]: state => {
      return immutable(state, { inHttpRequest: { $set: true } });
    },
    [ActionTypes.UPDATE_MEMBER_FORM]: state => {
      return immutable(state, { inHttpRequest: { $set: true } });
    },
    [ActionTypes.UPDATE_MEMBER_FORM_SUCCESS]: (state, action) => {
      const newState = {
        error: { $set: undefined },
        success: {
          $set: {
            formType: action.data.formType,
            message: 'You have successfully updated the profile!'
          }
        }
      };
      statusNotifications(newState.success.$set.message, 'success', 3000);
      return immutable(state, newState);
    },
    [ActionTypes.UPDATE_MEMBER_FORM_ERROR]: (state, action) => {
      statusNotifications('An error occurred.', 'error', 3000);
      return immutable(state, {
        inHttpRequest: { $set: false },
        error: { $set: action.data.errors },
        success: { $set: undefined }
      });
    },
    [ActionTypes.LOAD_MEMBER_FORM_SUCCESS]: (state, action) => {
      return immutable(state, { $set: { ...loadMemberFormSuccess(state, action) } });
    },
    [ActionTypes.LOAD_MEMBER_FORM_ERROR]: state => {
      return immutable(state, {
        inHttpRequest: { $set: false },
        mobilityAssessment: { $set: null }
      });
    },
    [ActionTypes.SET_MEMBER_RIDESHARE_PREFERENCES]: (state, action) => {
      const memberRidesharePreference = {
        name: 'Rideshare Preferences',
        slug: 'member_rideshare_preference',
        default: action.data?.default,
        values: action.data.options.map(opt => {
          // We're mapping these so that the response is actually
          // useful and can be matched to vehicle types
          let nickName;

          switch (opt.name) {
            case 'Lyft':
              nickName = 'LFT';
              break;

            case 'Uber':
              nickName = 'UBR';
              break;

            case 'No Rideshare (NEMT Only)':
              nickName = 'NMT';
              break;

            default:
              nickName = null;
          }

          return { ...opt, nickName };
        })
      };

      return immutable(state, {
        formData: {
          mobility: { member_rideshare_preference: { $set: memberRidesharePreference } }
        }
      });
    },
    [ActionTypes.SET_MEMBER_MOBILITY_ASSESSMENT]: (state, action) => {
      return immutable(state, { mobilityAssessment: { $set: action.data } });
    },
    [ActionTypes.GET_MILEAGE_REIMBURSEMENT_DRIVERS_SUCCESS]: (state, action) => {
      return immutable(state, {
        mileageReimbursementDrivers: { $set: action.data },
        isLoadingMileageReimbursementDrivers: { $set: false }
      });
    },
    [ActionTypes.GET_MILEAGE_REIMBURSEMENT_DRIVERS_ERROR]: state => {
      return immutable(state, {
        mileageReimbursementDrivers: { $set: [] },
        isLoadingMileageReimbursementDrivers: { $set: false }
      });
    },
    [ActionTypes.UPDATE_MEMBER_PORTAL_STATUS]: (state, action) => {
      return immutable(state, {
        memberPortalInfo: {
          isActive: { $set: action.payload }
        }
      });
    },
    [ActionTypes.GET_MEMBER_PORTAL_INFO_START]: state => {
      return immutable(state, {
        isLoadingMemberPortalInfo: { $set: true }
      });
    },
    [ActionTypes.GET_MEMBER_PORTAL_INFO_SUCCESS]: (state, action) => {
      return immutable(state, {
        memberPortalInfo: { $set: action.data },
        isLoadingMemberPortalInfo: { $set: false }
      });
    },
    [ActionTypes.GET_MEMBER_PORTAL_INFO_ERROR]: (state, action) => {
      return immutable(state, {
        memberPortalInfo: { $set: action.data },
        isLoadingMemberPortalInfo: { $set: false }
      });
    }
  },
  INITIAL_STATE
);

const initialMemberAuditLogsState = {
  error: undefined,
  success: undefined,
  inHttpRequest: false,
  activities: []
};

export const memberAuditLogsReducer = handleActions(
  {
    [ActionTypes.LOAD_MEMBER_AUDIT_LOGS]: state => {
      return immutable(state, {
        inHttpRequest: { $set: true }
      });
    },
    [ActionTypes.LOAD_MEMBER_AUDIT_LOGS_SUCCESS]: (state, action) => {
      return immutable(state, {
        inHttpRequest: { $set: false },
        activities: { $set: action.data.activities }
      });
    },
    [ActionTypes.LOAD_MEMBER_AUDIT_LOGS_ERROR]: (state, action) => {
      return immutable(state, {
        error: { $set: action.data.errors },
        success: { $set: undefined },
        inHttpRequest: { $set: false }
      });
    },
    [ActionTypes.CLEAR_MEMBER_AUDIT_LOGS]: state => {
      return immutable(state, { $set: initialMemberAuditLogsState });
    }
  },
  initialMemberAuditLogsState
);

/*
 * Below - Not impossible to understand but...
 * really poor & complicated implementation from previous devs.
 * This code is a misuse of redux. This reducer is doing too much.
 *
 * All the other code pertaining to member profile redux has been refactored
 * except for this function LOAD_MEMBER_FORM_SUCCESS.
 *
 * This code is too involved and will need dedicated attention.
 *
 */
const loadMemberFormSuccess = (state: MemberProfileStore, action = {}) => {
  const newState = _.cloneDeep(state);
  const {
    permission = '',
    member_data: memberData,
    benefits: benefitsData = {},
    benefitLimits = {},
    settings = {},
    ridesInWindow = [],
    dsnpMedicareAvailable,
    benefit_addons_metadata,
    benefit_addons_usage
  } = action.data;

  const {
    health_plan_name = null,
    health_sub_plan_name = null,
    health_sub_plan_id = null,
    external_sub_plan_id = null,
    member_portal_slug = null,
    health_plan_member_portal = null,
    health_sub_plan_member_portal = null,
    benefitCategories = []
  } = benefitsData;

  const validationTables = _.sortBy(action.data.mapping.validation_tables, [
    table => table.name.toLowerCase()
  ]);

  const vehicleTypes = action.data.mapping.vehicle_types;

  const customFields = _.sortBy(action.data.mapping.custom_fields, [
    field => field.label.toLowerCase()
  ]);

  // initialize 7 forms
  //general
  const personalInfo: Partial<MemberProfileStore['formData']['personalInfo']> = {};
  const mobility: Partial<MemberProfileStore['formData']['mobility']> = {};
  const attendantInfo: Partial<MemberProfileStore['formData']['attendantInfo']> = {};
  const benefits: Partial<MemberProfileStore['formData']['benefits']> = {};

  //custom fields
  const freeForm = {};
  const validation = {};

  const notes = {};

  // iterate through validation tables
  const notInPersonal = [
    'member_default_mode',
    'member_default_assistance_needs',
    'member_default_attendant_count',
    'member_rideshare_preference',
    'member_ride_alone',
    'member_manual_schedule',
    'member_flag',
    'attendant_default_mode',
    'attendant_vehicle_type',
    'attendant_sub_vehicle_type'
  ];

  for (let i = 0; i < validationTables.length; i++) {
    const validationTable = validationTables[i];
    if (notInPersonal.indexOf(validationTable.slug) === -1) {
      personalInfo[validationTable.slug] = validationTable;
    } else {
      mobility[validationTable.slug] = validationTable;
      attendantInfo[validationTable.slug] = validationTable;
    }
  }

  for (let i = 0; i < customFields.length; i++) {
    const customField = customFields[i];
    let customFieldValue = null;
    if (_.has(memberData, customField.slug)) {
      customFieldValue = memberData[customField.slug];
    }
    if (customField.field_type === 'select') {
      validation[customField.slug] = customField;
      if (!isNil(customFieldValue)) {
        validation[customField.slug].value = customFieldValue;
      }
    }
    if (customField.field_type === 'string') {
      freeForm[customField.slug] = customField;
      if (!isNil(customFieldValue)) {
        freeForm[customField.slug].value = customFieldValue;
      }
    }
    if (customField.field_type === 'text') {
      notes[customField.slug] = customField;
      if (!isNil(customFieldValue)) {
        notes[customField.slug].value = customFieldValue;
      }
    }
  }

  //mobility & attendant info vehicle types
  mobility.vehicle_types = vehicleTypes;
  attendantInfo.vehicle_types = vehicleTypes;

  personalInfo.dateOfBirth = !isNil(memberData.dateOfBirth)
    ? moment(memberData.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY')
    : '';
  personalInfo.firstName = memberData.firstName;
  personalInfo.lastName = memberData.lastName;
  personalInfo.email = memberData.email;
  personalInfo.homeAddress = memberData.homeAddress || memberData.street1 || '';
  personalInfo.billingAddress = memberData.billingAddress;
  personalInfo.billingStreet2 = memberData.billingStreet2;
  personalInfo.isEligible = memberData.isEligible;
  personalInfo.medicalId = memberData.medicalId;
  personalInfo.internalNotes = memberData.internalNotes;
  personalInfo.otherDetails = memberData.otherDetails;
  personalInfo.hospitalId = memberData.hospitalId;
  personalInfo.mobileNo = memberData.mobileNo;
  personalInfo.phone2 = memberData.phone2;
  personalInfo.street2 = memberData.street2;
  personalInfo.healthPlanId = memberData.health_plan_id;
  personalInfo.id = memberData.id;
  personalInfo.client_unique_id = memberData?.client_unique_id;
  personalInfo.external_sub_plan_id = memberData?.external_sub_plan_id;

  // data
  personalInfo.should_prompt_opt_in = memberData.should_prompt_opt_in;
  personalInfo.defaultOptOutTextOrAll = memberData.defaultOptOutTextOrAll;
  personalInfo.memberPhoneNumbers = memberData.member_phone_numbers ?? {};
  personalInfo.primaryOptOutValues = personalInfo?.member_primary_phone_opt_out?.values
    ?.filter(v => v?.value === 'All' || v?.value === 'Text')
    .map(v => v.id);
  personalInfo.secondaryOptOutValues =
    personalInfo?.member_secondary_phone_opt_out?.values
      ?.filter(v => v?.value === 'All' || v?.value === 'Text')
      .map(v => v.id);
  personalInfo.member_ethnicityId = !isNil(memberData.member_ethnicity)
    ? parseInt(memberData.member_ethnicity, 10)
    : null;
  personalInfo.member_genderId = !isNil(memberData.member_gender)
    ? parseInt(memberData.member_gender, 10)
    : null;
  personalInfo.member_primary_phone_typeId = !isNil(memberData.member_primary_phone_type)
    ? parseInt(memberData.member_primary_phone_type)
    : null;
  personalInfo.member_secondary_phone_typeId = !isNil(
    memberData.member_secondary_phone_type
  )
    ? parseInt(memberData.member_secondary_phone_type)
    : null;
  personalInfo.member_primary_phone_opt_outId = !isNil(
    memberData.member_primary_phone_opt_out
  )
    ? parseInt(memberData.member_primary_phone_opt_out)
    : null;
  personalInfo.member_primary_phone_preferenceId = !isNil(
    memberData.member_primary_phone_preference
  )
    ? parseInt(memberData.member_primary_phone_preference)
    : null;
  personalInfo.member_secondary_phone_opt_outId = !isNil(
    memberData.member_secondary_phone_opt_out
  )
    ? parseInt(memberData.member_secondary_phone_opt_out)
    : null;
  personalInfo.member_secondary_phone_preferenceId = !isNil(
    memberData.member_secondary_phone_preference
  )
    ? parseInt(memberData.member_secondary_phone_preference)
    : null;
  personalInfo.member_spoken_languageId = !isNil(memberData.member_spoken_language)
    ? parseInt(memberData.member_spoken_language)
    : null;
  personalInfo.member_written_languageId = !isNil(memberData.member_written_language)
    ? parseInt(memberData.member_written_language)
    : null;

  // hospital name if not a health plan user role
  if (!isHealthPlanRole(action.user)) {
    if (isNetworkRole(action.user.userData.role)) {
      const hospital = _.find(action.user.hospitalData, {
        id: parseInt(personalInfo.hospitalId, 10)
      });
      if (!isNil(hospital)) {
        personalInfo.hospitalName = hospital.hospitalName;
      }
    } else {
      personalInfo.hospitalName = action.user.hospitalData.hospitalName;
    }
  }

  personalInfo.displayMemberPortal = settings.memberPortalEnabled;
  personalInfo.memberPortalActive = memberData.member_portal_active;
  personalInfo.isMemberPortalSetupComplete = !memberData.member_portal_setup_incomplete;
  personalInfo.dsnpMedicareAvailable = dsnpMedicareAvailable;

  benefits.eligibleEndDate = memberData.eligibleEndDate;
  benefits.eligibleStartDate = memberData.eligibleStartDate;
  benefits.treatmentsEnabled = settings.treatmentsEnabled;

  const benefitsArr: RideBenefit[] = [];

  // create a collection of benefits so we can iterate over it on the front end easier
  if (!isNil(benefitLimits.rides_per_year)) {
    benefitsArr.push({
      memberRideBenefit: `${benefitLimits.rides_per_year.blockLimits.value} Total Rides / Year`,
      usageMonth: '--',
      usageYear: `${
        benefitsData.rideUsageYear !== null ? benefitsData.rideUsageYear : 0
      } rides`,
      balanceMonth: '--',
      balanceYear: `${benefitsData.rideBalanceYear} rides`,
      rideUsage: Number(benefitsData.rideUsageYear),
      rideUsageText: `${benefitsData.rideUsageYear} rides`,
      rideLimit: benefitLimits.rides_per_year.blockLimits.value,
      alertAfter: benefitLimits.rides_per_year.alertLimits.value,
      hardBlock: !benefitLimits.rides_per_year.blockLimits.bookAfterBlock
    });
  }

  if (!isNil(benefitCategories)) {
    for (let i = 0; i < benefitCategories.length; i++) {
      benefitsArr.push({
        memberRideBenefit: `${benefitCategories[i].block_limit} ${benefitCategories[i].name} Rides / Year`,
        usageMonth: '--',
        usageYear: `${benefitCategories[i].usageCountYear} Rides`,
        balanceMonth: '--',
        balanceYear: `${
          benefitCategories[i].block_limit - benefitCategories[i].usageCountYear
        } rides`
      });
    }
  }

  if (!isNil(benefitLimits.rides_per_month)) {
    benefitsArr.push({
      memberRideBenefit: `${benefitLimits.rides_per_month.blockLimits.value} Rides / Month`,
      usageMonth: `${
        benefitsData.rideUsageMonth !== null ? benefitsData.rideUsageMonth : 0
      } rides`,
      usageYear: '--',
      balanceMonth: `${benefitsData.rideBalanceMonth} rides`,
      balanceYear: '--',
      rideUsage: Number(benefitsData.rideUsageMonth),
      rideUsageText: `${benefitsData.rideUsageMonth} rides`,
      rideLimit: benefitLimits.rides_per_month.blockLimits.value,
      alertAfter: benefitLimits.rides_per_month.alertLimits.value,
      hardBlock: !benefitLimits.rides_per_month.blockLimits.bookAfterBlock
    });
  }

  if (!isNil(benefitLimits.cost_per_year)) {
    benefitsArr.push({
      memberRideBenefit: `$${parseFloat(
        benefitLimits.cost_per_year.blockLimits.value
      ).toFixed(2)} / Year`,
      usageMonth: '--',
      usageYear: `$${
        benefitsData.costUsageYear !== null
          ? parseFloat(benefitsData.costUsageYear).toFixed(2)
          : 0
      }`,
      balanceMonth: '--',
      balanceYear: `$${parseFloat(benefitsData.costBalanceYear).toFixed(2)}`,
      rideUsage: parseFloat(benefitsData.costUsageYear).toFixed(2),
      rideUsageText: `$${parseFloat(benefitsData.costUsageYear).toFixed(2)}`,
      rideLimit: benefitLimits.cost_per_year.blockLimits.value,
      alertAfter: benefitLimits.cost_per_year.alertLimits.value,
      hardBlock: !benefitLimits.cost_per_year.blockLimits.bookAfterBlock
    });
  }

  benefits.rideBenefit = benefitsArr;
  benefits.healthPlanName = health_plan_name;
  benefits.healthSubPlanName = health_sub_plan_name;
  benefits.memberPortalSlug = member_portal_slug;
  benefits.healthPlanMemberPortal = health_plan_member_portal;
  benefits.healthSubPlanMemberPortal = health_sub_plan_member_portal;
  benefits.healthSubPlanId = health_sub_plan_id;
  benefits.externalSubPlanId = external_sub_plan_id;
  benefits.disableRequestRide = benefitsData.disableRequestRide;
  benefits.disableRequestRideReason = benefitsData.disableRequestRideReason;
  benefits.allowBenefitOverride = benefitsData.allowBenefitOverride;

  benefits.addonsMetadata = benefit_addons_metadata;
  benefits.memberAddons = memberData.benefit_addons;
  benefits.addonsUsage = benefit_addons_usage;

  const memberDataForMobility = [
    'member_default_mode',
    'member_default_assistance_needs',
    'member_default_attendant_count',
    'member_rideshare_preference',
    'member_ride_alone',
    'member_manual_schedule',
    'member_flag',
    'vehicle_type',
    'vehicle_sub_type',
    'primary_preferred_nemts',
    'secondary_preferred_nemts',
    'flagged_nemts'
  ];

  mobility.memberData = {};

  for (const memberProperty of memberDataForMobility) {
    if (_.has(memberData, memberProperty)) {
      mobility.memberData[memberProperty] = memberData[memberProperty];
    }
  }

  attendantInfo.memberData = {};

  const memberDataForAttendantInfo = [
    'additionalPassengers',
    'attendant_vehicle_type',
    'attendant_vehicle_sub_type',
    'attendant_default_mode'
  ];

  for (const memberProperty of memberDataForAttendantInfo) {
    if (_.has(memberData, memberProperty)) {
      attendantInfo.memberData[memberProperty] = memberData[memberProperty];
    }
  }

  newState.formData = {
    personalInfo: personalInfo as PersonalInfoForm,
    mobility: mobility as MobilityForm,
    freeForm,
    notes,
    validation,
    benefits: benefits as BenefitsForm,
    attendantInfo: attendantInfo as AttendantInfo
  };
  newState.ridesInWindow = ridesInWindow;

  newState.permission =
    action.user.userData.role === 'CaseManager' && !action.user?.features[PASSENGER_EDIT]
      ? 'view'
      : permission;
  newState.inHttpRequest = false;

  /** Extracts transport types and formats, separates based on classification. */
  const transportTypes = extendVehicleRecords(action.data.mapping.vehicle_types);
  for (const transport in transportTypes) {
    const record = transportTypes[transport];
    const tripType = getVehicleTripType(record.abbreviation);

    newState.transportTypes[tripType] ??= {};
    newState.transportTypes[tripType]![record.abbreviation] = record;
  }

  /** Any private transport types are valid for public trips as well... because well,
   * the transport type doesn't really matter on public but we have to track it anyhow
   * because Hopelink or something. */
  if (action.user?.features[PUBLIC_TRANSIT]) {
    newState.transportTypes['public'] = extendVehicleRecords(
      action.data.mapping.public_vehicle_types
    );
  }

  const { assistanceNeeds, serviceLevels, mobilityDevices, driverProvisions } =
    formatAssistanceNeeds(mobility.member_default_assistance_needs!.values);

  newState.assistanceNeeds = assistanceNeeds;
  newState.serviceLevels = serviceLevels;
  newState.mobilityDevices = mobilityDevices;
  newState.driverProvisions = driverProvisions;

  // TODO: Remove this. Vehicle companies are now passed in the user
  // instead of looked up with each member. - BTS 2023-12-12
  newState.vehicleCompanies = [];

  _.unset(newState, 'error');
  return newState;
};
