import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    width: 206,
    height: 24,
    fontSize: 20,
    whiteSpace: 'nowrap',
    color: '#485c6f',
    fontWeight: 'normal',
    fontStretch: 'normal',
    lineHeight: 'normal',
    letterSpacing: 'normal'

  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: 700,
    color: '#2c3e50'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  paper: {
    height: 140,
    width: 100
  },
  control: {
    padding: theme.spacing(2)
  },
  dropdown: {
    width: 73,
    borderRadius: 2,
    fontSize: 12,
    color: '2c3e50',
    border: 'solid 1px #979797',
    textAlignLast: 'center'
  },
  selectMenu: {
    width: 180,
    height: 36,
    borderRadius: 2,
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    color: '2c3e50'
  },

  optionItem: {
    fontSize: 12,
    fontFamily: 'Helvetica Neue'
  },
  repeat: {
    width: 59,
    height: 36,
    marginRight: 5,
    textAlign: 'center',
    '&::-webkit-inner-spin-button': {
      opacity: 1
    }
  },
  occurenceBox: {
    display: 'flex',
    height: 36,
    width: 135,
    border: 'solid 1px #979797',
    borderRadius: 2,
    justifyContent: 'flex-start',
    alignItems: 'center',
    '& input': {
      border: 'none',
      width: 50,
      textAlign: 'center',
      '&::-webkit-inner-spin-button': {
        opacity: 1
      }
    }
  },
  occurrence: {
    width: 124,
    height: 36,
    textAlign: 'center',
    '&::-webkit-inner-spin-button': {
      opacity: 1
    }
  },
  formText: {
    fontSize: 14,
    fontWeight: 300,
    letterSpacing: 'normal'
  },
  endOnDate: {
    fontSize: 12,
    fontFamily: 'Helvetica Neue',
    border: 'solid 1px #979797',
    borderRadius: 2,
    height: 36,
    textAlign: 'center',
    width: 135,
    '& .MuiInputBase-input': {
      height: 14,
      fontSize: 12,
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: 'normal',
      color: '#2c3e50'
    },
    '& .MuiInput-root': {
      marginTop: 5,
      marginLeft: 15
    }
  },
  buttonCancel: {
    width: 78,
    backgroundColor: '#fff',
    border: '1px solid #979797'
  },
  buttonAdd: {
    width: 78,
    color: '#fff',
    marginLeft: 5,
    backgroundColor: '#56b99d',
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#56b99d',
      color: '#fff',
      opacity: 0.5,
      pointerEvents: 'auto'

    }
  }

}));

export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 150
  },
  content: {
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    width: 362,
    height: 465,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

export const editExpenseModalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 151
  },
  content: {
    borderRadius: '4px',
    outline: 'none',
    padding: '20px',
    width: 362,
    height: 230,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


export const selectCustomStyles = {

  menu: () => ({
    width: 200,
    height: 36,
    borderRadius: 2,
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    borderColor: 'black',
    backgroundColor: 'black'
  }),
  menuList: () => ({
    zIndex: 10,
    borderColor: 'black',

    backgroundColor: 'white'
  }),
  menuPortal: () => ({
    zIndex: 10,
    borderColor: 'black',

    backgroundColor: 'white'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'black'
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  container: () => ({
    width: 200,
    height: 36,
    borderRadius: 2,
    fontFamily: 'Helvetica Neue',
    fontSize: 12,
    borderColor: 'black',

    backgroundColor: 'white',
    zIndex: 10

  }),
  control: provided => ({
    ...provided,
    borderColor: 'black',
    borderRadius: 2

  }),
  option: provided => ({
    ...provided
  })
};