import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withRouter from '~/hooks/withRouter';
import _ from 'lodash-es';
import { parse } from 'querystring';
import { IMPORTER, IMPORTER_STEP } from '~/constants';
import { getImporter } from '~/utilities/configureImporter';
import { getJob, uploadFile, updateFieldMapping } from '~/Modules/importerJob';
import { getTemplates } from '~/Modules/importerTemplate';
import ReactLoading from 'react-loading';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import ImporterHeader from './Components/ImporterHeader';
import WizardStepsDisplay from '~/Shared/Components/WizardStepsDisplay';
import ImporterUpload from './Components/ImporterUpload';
import ImporterVerify from './Components/ImporterVerify';
import ImporterPreview from './Components/ImporterPreview';
import ImporterConfirm from './Components/ImporterConfirm';

class Importer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      importer: getImporter(IMPORTER.NONE),
      subPlanId: 0,
      currentStepIndex: 0,
      showLoader: false,
      templateName: ''
    };
  }

  componentDidMount() {
    const queryParams = parse(window.location.search.substr(1));

    // infer importer type from URL
    const importerType = this.getImporterType();

    // get importer configuration based on importer type
    const importer = getImporter(importerType);

    // get subplan id from query params
    const subPlanId = queryParams.subPlanId || 0;

    // debug only
    // get step index and job ID from query params
    const stepIndex = queryParams.stepIndex || 0;
    const currentStepIndex = Number(stepIndex);
    if (currentStepIndex > 0) {
      const jobId = queryParams.jobId || 0;
      this.props.getJob({ jobId });
    }

    this.setState({
      importer,
      subPlanId,
      currentStepIndex
    });

    // get templates
    this.props.getTemplates({
      importerType: importer.type,
      userId: this.props?.user?.userData?.id ?? 0
    });
  }

  /**
   * Handle request for previous step
   * @returns {undefined}
   */
  handlePrev() {
    const importer = this.state.importer;
    importer.steps[this.state.currentStepIndex - 1].hasSubmitted = false;
    this.setState({
      currentStepIndex: this.state.currentStepIndex - 1,
      importer
    });
  }

  /**
   * Handle request for next step
   * @param {Object} data Step data
   * @returns {undefined}
   */
  handleNext() {
    const { uploadFile, importerJob, updateFieldMapping } = this.props;
    const { importer, currentStepIndex, subPlanId, templateName } = this.state;
    const newImporter = _.cloneDeep(importer);
    const step = newImporter.steps[currentStepIndex];
    const newStepIndex = currentStepIndex + 1;

    if (!step.hasSubmitted) {
      // show loader
      this.setState({ showLoader: true }, () => {
        switch (step.type) {
          case IMPORTER_STEP.UPLOAD: {
            // reset field mapping as new field is available
            // in case user uses back button
            newImporter.fieldMapping = {};
            newImporter.steps.forEach(step => {
              if (step.data && step.data.fieldMapping) {
                step.data.fieldMapping = {};
              }
            });
            const file = step.data.files[0];
            uploadFile({
              file,
              importerType: newImporter.type,
              subPlanId
            });
            break;
          }
          case IMPORTER_STEP.VERIFY: {
            newImporter.fieldMapping = step.data.fieldMapping;
            this.setState({ templateName: step.data.templateName });
            break;
          }
          case IMPORTER_STEP.PREVIEW: {
            const jobId = _.get(importerJob, 'job.importerJobID', 0);
            updateFieldMapping({
              importType: newImporter.type,
              jobId,
              fieldMapping: newImporter.fieldMapping,
              templateName
            });
            break;
          }
          case IMPORTER_STEP.CONFIRM: {
            this.setState({ showLoader: false });
            break;
          }
          default: {
            break;
          }
        }
        // update current step state and advance to next step
        newImporter.steps[currentStepIndex] = Object.assign(step, { hasSubmitted: true });

        this.setState({
          importer: newImporter,
          currentStepIndex: newStepIndex,
          showLoader: false
        });
      });
    } else {
      // move to next step immediately
      this.setState({ currentStepIndex: newStepIndex });
    }
  }

  /**
   * Handle request to go back to subplan
   * @returns {undefined}
   */
  gotoPlans() {
    switch (this.state.importer.type) {
      case IMPORTER.TREATMENT:
      case IMPORTER.LOCATION: {
        this.props.history.push(`/plans/${this.state.subPlanId}`);
        break;
      }
      default: {
        this.props.history.push('/plans');
        break;
      }
    }
  }

  /**
   * Handle request to go back to members page
   * @returns {undefined}
   */
  gotoMembers() {
    this.props.history.push('/members');
  }

  /**
   * Get importer type from URL
   * @returns {Number} Importer type from IMPORTER
   */
  getImporterType() {
    const typeValue = this.props?.match?.params?.type ?? '';
    return Object.keys(IMPORTER).reduce(
      (type, key) => (IMPORTER[key] === typeValue ? IMPORTER[key] : type),
      IMPORTER.NONE
    );
  }

  /**
   * Set step object state
   * @param {Object} step Importer Step object
   * @returns {undefined}
   */
  setStep(step) {
    const importer = _.cloneDeep(this.state.importer);
    importer.steps[this.state.currentStepIndex] = step;
    this.setState({ importer });
  }

  render() {
    const { importerJob, importerTemplate } = this.props;
    const { importer, currentStepIndex, showLoader } = this.state;
    const step = importer.steps[currentStepIndex];

    return (
      <content className="ImporterContainer">
        <PageFrame>
          {step && !showLoader ? (
            <section className="Importer">
              <ImporterHeader importerType={importer.type} />
              <WizardStepsDisplay
                steps={importer.steps}
                currentStepIndex={currentStepIndex}
                handleNext={() => this.handleNext()}
                handlePrev={() => this.handlePrev()}
              />
              {
                {
                  [IMPORTER_STEP.UPLOAD]: (
                    <ImporterUpload step={step} setStep={step => this.setStep(step)} />
                  ),
                  [IMPORTER_STEP.VERIFY]: (
                    <ImporterVerify
                      step={step}
                      user={this.props.user}
                      setStep={step => this.setStep(step)}
                      importType={importer.type}
                      error={importerJob.error}
                      job={importerJob.job}
                      fields={importer.fields}
                      templates={importerTemplate.templates}
                    />
                  ),
                  [IMPORTER_STEP.PREVIEW]: (
                    <ImporterPreview
                      step={step}
                      setStep={step => this.setStep(step)}
                      error={importerJob.error}
                      job={importerJob.job}
                      fields={importer.fields}
                      fieldMapping={importer.fieldMapping}
                    />
                  ),
                  [IMPORTER_STEP.CONFIRM]: (
                    <ImporterConfirm
                      step={step}
                      importType={importer.type}
                      job={importerJob.job}
                      error={importerJob.error}
                      gotoPlans={() => this.gotoPlans()}
                      gotoMembers={() => this.gotoMembers()}
                    />
                  )
                }[step.type]
              }
            </section>
          ) : (
            <ReactLoading type="spin" className="importerLoading" />
          )}
        </PageFrame>
      </content>
    );
  }
}

Importer.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
  importerJob: PropTypes.object,
  getJob: PropTypes.func,
  uploadFile: PropTypes.func,
  updateFieldMapping: PropTypes.func,
  importerTemplate: PropTypes.object,
  getTemplates: PropTypes.func
};

Importer.defaultProps = {
  user: {},
  history: {},
  match: {},
  importerJob: {},
  importerTemplate: {},
  getJob: () => {},
  uploadFile: () => {},
  updateFieldMapping: () => {},
  getTemplates: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  importerJob: state.importerJob,
  importerTemplate: state.importerTemplate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getJob: data => getJob(data),
      uploadFile: data => uploadFile(data),
      updateFieldMapping: data => updateFieldMapping(data),
      getTemplates: data => getTemplates(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Importer));
