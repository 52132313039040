import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { Button, Grid, Typography } from '@material-ui/core';
import { EditRepeatingExpensesEnum } from './constants';
import { useStyles, editExpenseModalStyles } from './styles';



const EditRepeatingExpenseForm = ({ title = '', closeModalHandler, submitHandler }) => {
  const repeatingOptions = [
    { id: EditRepeatingExpensesEnum.THIS_EXPENSE, label: 'This expense', checked: true },
    { id: EditRepeatingExpensesEnum.THIS_AND_FOLLOWING, label: 'This and following expenses', checked: false },
    { id: EditRepeatingExpensesEnum.ALL_EXPENSES, label: 'All expenses', checked: false }
  ];
  const classes = useStyles();
  const [options, setOptions] = useState(repeatingOptions);

  // handlers
  const onOptionsChange = e => {
    setOptions(options.map(o => o.checked ? ({ ...o, checked: !o.checked }) : o).map(o => o.id === e.target.name ? { ...o, checked: !o.checked } : o));
  };

  

  //components
  const renderEditExpenseOption = () => {
    return options.map((option, i) => {
      return (
        <div key={i} style={{ display: 'flex', height: 29 }}>
          <input
            type="radio"
            id="edit-expense"
            name={option.id}
            checked={option.checked}
            onChange={onOptionsChange}
          />
          <label>
            <Typography className={classes.formText}>
              {option.label}
            </Typography>
          </label>
        </div>
      );
    });
  };
  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Typography variant="h6" className={classes.title}>{title}</Typography>
        <Typography onClick={closeModalHandler} className={classes.closeIcon} >X</Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {renderEditExpenseOption()}
      </div>
      <Grid
        container
        direction="row"
        alignItems="flex-end"
        justify="flex-end"
      >
        <Button
          className={classes.buttonCancel}
          onClick={closeModalHandler}>Cancel</Button>
        <Button
          className={classes.buttonAdd}
          onClick={() => submitHandler(options)}>Ok</Button>
      </Grid>
    </div>
  );
};

export const RepeatingExpenseModal = ({ title = '', displayModal, saveRepeatingExpenseHandler, mode, removeExpenses, cancelEditModal }) => {
  const [isModalOpen, setModalDisplay] = useState(() => displayModal);
  useEffect(() => {
    setModalDisplay(displayModal);
  }, [displayModal]);

  // handlers
  const closeModalHandler = () => {
    setModalDisplay(false);
    cancelEditModal();
  };
  const submitHandler = options => {
    if (mode === 'delete') {
      removeExpenses(options);
    } else {
      saveRepeatingExpenseHandler(options);
      closeModalHandler();
    }
  };

  return (
    <div>
      <Modal
        style={editExpenseModalStyles}
        isOpen={isModalOpen}

      >
        <EditRepeatingExpenseForm
          title={title}
          closeModalHandler={closeModalHandler}
          submitHandler={submitHandler}
        />
      </Modal>
    </div>
  );
};
