/**
 * camelCase - Converts a string to camel casing
 * @param {string} str Input string to convert
 * @returns {string} Converted string
 */
export const camelCase = (str = '') => {
  return str
    .replace(/(?:^\w|[A-Z]|_[a-z]|\b\w)/g, (letter, index) => {
      return index === 0 ? letter.toLowerCase() : letter.toUpperCase();
    })
    .replace(/[\s+_-]/g, '');
};

/**
 * formats number with 2 digit format and comma
 * @param {string} str - string to capitalize
 * @return {string} - return capitalized string
 */
export const capitalize = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** Formats strings as proper names. If there are multiple words in
 * the string then each word is capitalized. e.g. LOS ANGELES will be
 * formatted to Los Angeles. */
export const formatProperName = (str: string) => {
  return str
    .split(' ')
    .map(s => capitalize(s.toLowerCase()))
    .join(' ');
};

/**
 * formats number with 2 digit format and comma
 * @param {number} number - number (int, float)
 * @param {int} decimal - decimal places to format
 * @return {string} - return formatted number
 */
export const numberFormat = (number: number, decimal = 2) => {
  if (decimal === 0) return number.toLocaleString();

  return number.toLocaleString(undefined, {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal
  });
};

type Position = 'end' | 'start' | 'middle';

/**
 * truncate
 * @param {string} inString - string to truncate
 * @param {integer} desiredLength - length to truncate
 * @param {string} elipse - default to ...
 * @param {string} pos - end, start, middle
 * @return {string} - return modified string
 */
export const truncateElipse = (
  inString: string,
  desiredLength = 8,
  elipse = '...',
  pos: Position = 'end'
) => {
  let outString = inString;

  if (inString.length <= desiredLength) return inString;

  switch (pos) {
    case 'end':
      outString = inString.substr(0, desiredLength - elipse.length) + elipse;
      break;

    case 'middle': {
      const desiredLengthHalf = Math.floor(desiredLength / 2);
      const firstHalf = inString.substr(0, desiredLengthHalf);
      const secondHalf = inString.substr(
        inString.length - desiredLengthHalf,
        inString.length
      );
      outString = firstHalf + elipse + secondHalf;
      break;
    }
    case 'start':
    default:
      outString =
        elipse + inString.substr(inString.length - desiredLength, inString.length);
  }

  return outString;
};
