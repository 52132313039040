import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import type { BlockType } from '../RideBooking.types';
import type { AddressModel } from '~/models';

const receiveActiveRideDepartAddressType = (
  _: AppStore,
  departAddressType: AddressModel['type']
) => departAddressType;

const receiveActiveRideArriveAddressType = (
  _: AppStore,
  _d,
  arriveAddressType: AddressModel['type']
) => arriveAddressType;

const selectPatientApprovedProvidersEnabled = (s: AppStore) =>
  s.patients.patientDetails?.approved_providers ?? 0;

const selectPatientApprovedProvidersHardBlock = (s: AppStore) =>
  s.patients.patientDetails?.approved_providers_hard_block ?? 0;

export const selectIsProviderBlocked = createSelector(
  [
    selectPatientApprovedProvidersEnabled,
    selectPatientApprovedProvidersHardBlock,
    receiveActiveRideDepartAddressType,
    receiveActiveRideArriveAddressType
  ],
  (
    providersEnabled,
    approvedProvidersHardBlock,
    departAddressType,
    arriveAddressType
  ): Exclude<BlockType, 'Past'> => {
    // Approved provider is not enabled for the sub plan -> no block.
    if (providersEnabled === 0) return undefined;

    // At least one address is an approved provider -> no block.
    if (
      departAddressType === 'approved-provider' ||
      departAddressType === 'provider-not-found' ||
      arriveAddressType === 'approved-provider' ||
      arriveAddressType === 'provider-not-found'
    ) {
      return undefined;
    }

    return approvedProvidersHardBlock === 1 ? 'Hard' : 'Soft';
  }
);
