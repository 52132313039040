import keyMirror from '../../utilities/keyMirror';

/**
 * @constant {Object} ActionTypes
 *  @desc APP Constants
 */

export const ActionTypes = keyMirror({
  SET_STATUS: undefined,
  REMOVE_EXPENSE: undefined,
  SET_EXPENSE_MODAL: undefined,
  SET_EXPENSE_FIELD: undefined,
  SET_EXPENSE_OPTIONS: undefined,
  SET_EXPENSE_SLIDEOUT: undefined,
  RESET_EXPENSE_REPORT: undefined,
  SET_REPORT_PERSONAL_INFO: undefined,

  GET_REPORT_ACTIVITY_LOG: undefined,
  GET_REPORT_ACTIVITY_LOG_SUCCESS: undefined,
  GET_REPORT_ACTIVITY_LOG_ERROR: undefined,

  GET_REPORT_BY_ID: undefined,
  GET_REPORT_BY_ID_ERROR: undefined,
  GET_REPORT_BY_ID_SUCCESS: undefined,

  DOWNLOAD_ATTACHMENT: undefined,
  DOWNLOAD_ATTACHMENT_ERROR: undefined,
  DOWNLOAD_ATTACHMENT_SUCCESS: undefined,

  CREATE_EXPENSE_REPORT: undefined,
  CREATE_EXPENSE_REPORT_ERROR: undefined,
  CREATE_EXPENSE_REPORT_SUCCESS: undefined,

  UPLOAD_ATTACHMENT: undefined,
  UPLOAD_ATTACHMENT_ERROR: undefined,
  UPLOAD_ATTACHMENT_SUCCESS: undefined,

  UPDATE_EXPENSE_REPORT: undefined,
  UPDATE_EXPENSE_REPORT_ERROR: undefined,
  UPDATE_EXPENSE_REPORT_SUCCESS: undefined,

  CHECK_DUPLICATE_EXPENSES: undefined,
  CHECK_DUPLICATE_EXPENSES_ERROR: undefined,
  CHECK_DUPLICATE_EXPENSES_SUCCESS: undefined,

  SET_CURRENT_ATTACHMENT_UPLOAD_STATE: undefined,
  REMOVE_REPEATING_EXPENSES: undefined,

  SET_RIDE_ID_ON_EXPENSE_REPORT: undefined

});
