import { Switch } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import DropDown from '~/Shared/Components/DropDown/DropDown';
import { useAppSelector } from '~/Modules';
import type { Address } from '~/types';
import { AddressAutoComplete } from '../ApprovedProviders/AddressAutoComplete';
import SavedAddressSelect from '../SavedAddressSelect/SavedAddressSelect';
import { useSavedAddress } from '../SavedAddressSelect/useSavedAddress';
import type { AutoCompleteSource } from '../TransportType/TransportType';
import type { VenueRecord, VenueStore } from '~/Modules/venues';

export type VenuesProps = {
  type: 'pickup' | 'dropoff';
  address: Address;
  rideDetails: BookingDataStore | RideData;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelectAutoComplete: (
    row,
    type: VenuesProps['type'],
    source?: AutoCompleteSource
  ) => void;
  onResetAutoComplete: (showOrHide, location) => void;
  toggleLoading: (toggle: boolean, text: string, timeout?: number | undefined) => void;
  updateRideDetails: (data: Record<string, any>) => void;
  showSavedAddress?: boolean;
  missingFields?: unknown;
};

const Venues: React.FC<VenuesProps> = ({
  type,
  address,
  rideDetails,
  showSavedAddress,
  onChange,
  onSelectAutoComplete,
  onResetAutoComplete,
  updateRideDetails
}) => {
  const venues = useAppSelector(state => state.venues);

  const [localAddress, setLocalAddress] = useState<string>('');
  const [savedAddressState, savedAddressActions] = useSavedAddress();
  const [showAutoComplete, setShowAutoComplete] = useState<boolean>(false);

  const altType = type === 'pickup' ? 'from' : 'to';
  const typeUpper = type[0].toUpperCase() + type.substring(1);

  const label = type === 'pickup' ? 'Depart' : 'Arrive';

  const isVenue = !!(rideDetails[`show${typeUpper}Venue`] ?? false);
  const venueName = rideDetails[`${type}VenueName`] ?? undefined;
  const entranceName = rideDetails[`${type}EntranceName`] ?? undefined;
  const entranceList = venueName && venues?.venues ? venues.venues[venueName] : undefined;

  /**
   * Middleware to control the display of Autocomplete elements
   */
  const _onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const inputType = e.target.getAttribute('data-autocomplete-type');

      setLocalAddress(e.target.value);

      // Input Type is from generic address search
      if (val.length >= 3 && inputType === 'default' && !isVenue) {
        setShowAutoComplete(true);
      } else {
        setShowAutoComplete(false);
      }

      if (inputType === 'default' && !isVenue) onChange(e);
    },
    [isVenue]
  );

  /**
   * Change the local address to empty string so that selection can
   * filter back down after updating app store. Also set show
   * autocomplete to false so we don't get duplicate autocomplete windows
   */
  const _onSelectAutoComplete = useCallback(
    (
      row: Record<string, unknown>,
      type: 'pickup' | 'dropoff',
      source?: 'approvedProvider' | 'venue' | 'savedAddress'
    ) => {
      if (localAddress) setLocalAddress('');

      if (showAutoComplete) setShowAutoComplete(false);

      onSelectAutoComplete(row, type, source);
    },
    [localAddress, setLocalAddress]
  );

  /** Update the booking data when venue is toggled on */
  const onToggleVenues = useCallback(() => {
    const newData: Partial<BookingDataStore> = {
      [`show${typeUpper}Venue`]: !isVenue
    };

    if (!isVenue) {
      newData[`${altType}MemberSavedAddressId`] = undefined;
    } else {
      newData[`${type}VenueName`] = '';
      newData[`${type}EntranceName`] = '';
      newData[`${type}VenueId`] = null;
    }

    updateRideDetails(newData);
  }, [isVenue]);

  /**
   * If the props.address has a member saved address ID then automatically toggle on
   */
  useEffect(() => {
    if (address?.memberSavedAddressId && !savedAddressState[type]) {
      savedAddressActions.Toggle(type, true);
    }
  }, [address.memberSavedAddressId, savedAddressState[type]]);

  /**
   * If an address value is passed down then prioritize that
   * over the local component state.
   */
  useEffect(() => {
    if (address.address !== localAddress) {
      setLocalAddress(address.address);
    }
  }, [address.address]);

  let formElement = (
    <input
      type="text"
      name={type}
      id={type}
      autoComplete="off"
      placeholder={`Type ${type} address...`}
      onChange={_onChange}
      value={localAddress}
      disabled={isVenue}
      data-autocomplete-type="default"
    />
  );

  if (showSavedAddress && savedAddressState[type]) {
    formElement = (
      <SavedAddressSelect
        type={type}
        value={address?.memberSavedAddressId}
        onChange={row => onSelectAutoComplete(row, type, 'savedAddress')}
      />
    );
  }

  if (isVenue) {
    formElement = (
      <VenueSelect
        venueName={venueName}
        venueList={venues.venueList}
        onSelect={row => onSelectAutoComplete(row, type, 'venue')}
      />
    );
  }

  return (
    <>
      <div className="row venues">
        <div className="venueLabel">
          <label htmlFor={type}>{label}</label>
        </div>

        {formElement}
      </div>

      <AddressAutoComplete
        type={type}
        showAutoComplete={showAutoComplete}
        onSelect={_onSelectAutoComplete}
        onReset={onResetAutoComplete}
        value={localAddress}
      />

      <div className="row venues">
        <div className="selectSvg" onClick={onToggleVenues}>
          <Switch size="small" checked={isVenue} />
          <span className="svgText">Venues</span>
        </div>

        {isVenue && entranceList ? (
          <EntranceSelect
            entranceName={entranceName}
            entranceList={entranceList}
            onSelect={row => onSelectAutoComplete(row, type, 'entrance')}
          />
        ) : (
          <div className="spacer" />
        )}
      </div>
    </>
  );
};

export type VenueSelectProps = {
  venueList: VenueStore['venueList'];
  onSelect: (row: Record<string, unknown>) => void;
  venueName?: string;
};

export const VenueSelect: React.FC<VenueSelectProps> = ({
  venueList,
  onSelect,
  venueName: name = 'Select a saved location'
}) => {
  return (
    <>
      <div className="dropdown-container">
        <DropDown
          dropDownCallback={(_e, row) => onSelect(row)}
          items={venueList}
          placeholder={name}
          // error={state.selectedPickupError}
          customClassName="venueDropdown"
        />
      </div>
    </>
  );
};

export type EntranceSelectProps = {
  entranceList: VenueRecord[];
  onSelect: (row: Record<string, unknown>) => void;
  entranceName?: string;
};

export const EntranceSelect: React.FC<EntranceSelectProps> = ({
  entranceList,
  onSelect,
  entranceName: entranceName = 'Select a building entrance'
}) => {
  return (
    <DropDown
      dropDownCallback={(_e: React.MouseEvent, row) => onSelect(row)}
      items={entranceList}
      placeholder={entranceName}
      sendBackObject={true}
      // error={state.selectedPickupError}
      customClassName="venueDropdown"
    />
  );
};

export default Venues;
