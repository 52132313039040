import React from 'react';

const SvgPassengerIcon = props => {
  const { title, className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <title>{title}</title>
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <g>
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
      </g>
    </svg>
  );
};

export default SvgPassengerIcon;
