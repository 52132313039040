/* eslint-disable no-unused-vars */
import React from 'react';
import { useAppSelector } from '~/Modules';
import {
  analyticsService,
  type AnalyticEvent,
  type LEGACY__RideBookingFlowEventProperties,
  type MobilityAssessmentEventProperties,
  type PageVisitEventProperties,
  type PageNavigationEventProperties,
  type UserIdleEventProperties,
  type UserLogoutEventProperties,
  type RbfPassengerInfoEventProperties,
  type MemberDeeplinkActivatedProperties,
  type RbfFundingSourceEventProperties,
  type RbfDateEventProperties,
  type RbfRidesEvent,
  type EventProperties,
  type EventWithUserProperties
} from '~/analytics';

/**
 * Defines the type for the useAnalytics hook's return object,
 * detailing methods for event tracking, page tracking, etc..
 */
export type AnalyticsInterface = ReturnType<typeof useAnalytics>;

type WithoutUserProps<T extends EventWithUserProperties> = Omit<
  T,
  keyof EventWithUserProperties
>;

/**
 * Custom React hook for interacting with the analytics service.
 * The hook automatically includes user details from the Redux store in every event.
 *
 * @returns An object with functions for tracking events and pages, and for identifying users.
 */
const useAnalytics = () => {
  const user = useAppSelector(state => state.user);
  const userEventProperties = analyticsService.mapUserProps(user);

  /**
   * Tracks an event in the analytics service.
   * Includes user details from the Redux store in the event data.
   *
   * @param eventName - The name of the event.
   * @param eventProperties - Additional properties of the event.
   */
  function trackEvent(
    eventName: 'RBF Funding Source',
    eventProperties: WithoutUserProps<RbfFundingSourceEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Passenger Info',
    eventProperties: WithoutUserProps<RbfPassengerInfoEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Date',
    eventProperties: WithoutUserProps<RbfDateEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'RBF Rides',
    eventProperties: WithoutUserProps<RbfRidesEvent>
  ): Promise<void>;
  function trackEvent(
    eventName: 'User Logout',
    eventProperties: {
      eventTriggerTime: UserLogoutEventProperties['eventTriggerTime'];
      eventSource: UserLogoutEventProperties['eventSource'];
    }
  ): Promise<void>;
  function trackEvent(
    eventName: 'User Idle',
    eventProperties: WithoutUserProps<UserIdleEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'Ride Booking Flow',
    eventProperties: WithoutUserProps<LEGACY__RideBookingFlowEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'Mobility Assessment Time',
    eventProperties: WithoutUserProps<MobilityAssessmentEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'Page Visit',
    eventProperties: WithoutUserProps<PageVisitEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'Page Navigation',
    eventProperties: WithoutUserProps<PageNavigationEventProperties>
  ): Promise<void>;
  function trackEvent(
    eventName: 'Button Clicked',
    eventProperties: EventProperties
  ): Promise<void>;
  function trackEvent(
    eventName: 'Member Deeplink Activated',
    eventProperties: WithoutUserProps<MemberDeeplinkActivatedProperties>
  ): Promise<void>;
  function trackEvent<T extends EventWithUserProperties>(
    eventName: AnalyticEvent,
    eventProperties: WithoutUserProps<T>
  ): Promise<void> {
    // @ts-expect-error Function overloads can't be elevated up
    return analyticsService.trackEvent(eventName, {
      ...eventProperties,
      ...userEventProperties
    });
  }

  /**
   * Tracks a page view in the analytics service.
   * This function does not include user details.
   *
   * @param pageData - Data about the page that was viewed.
   */

  function trackPage(pageData) {
    analyticsService.trackPage(pageData);
  }

  /**
   * Identifies a user in the analytics service.
   *
   * @param userId - The ID of the user.
   * @param userTraits - Traits of the user.
   */

  function identify(userId, userTraits) {
    analyticsService.identify(userId, userTraits);
  }

  // Return the tracking functions so they can be used elsewhere.
  return {
    trackEvent,
    trackPage,
    identify
  };
};

/**
 * useAnalytics HOC:
 * This higher-order component is designed to wrap another component and enhance it
 * with the useAnalytics hook. It's a reusable way of adding analytics functionality
 * to any class-based component without modifying its internal logic and refactoring class-based components to FCs.
 *
 * @param WrappedComponent - The component to be wrapped and enhanced.
 * @returns {React.Component} - The enhanced component with analytics capabilities.
 */
useAnalytics.HOC = Component => {
  return function WrappedComponent(props) {
    const analyticsTools = useAnalytics();
    return <Component {...props} analytics={analyticsTools} />;
  };
};

export default useAnalytics;
