import React from 'react';
import PropTypes from 'prop-types';

const SvgClose = props => {
  const { title, className, customStyle = {}, width = 24, height = 24 } = props;

  return (
    <svg className={className} width={width.toString()} height={height.toString()} viewBox="0 0 24 24" style={customStyle}>
      <title>{title}</title>
      <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
};

SvgClose.propTypes = {
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  className: PropTypes.string
};
SvgClose.defaultProps = {
  title: '',
  className: '',
  width: 24,
  height: 24
};

export default SvgClose;
