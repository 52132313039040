import { createSlice } from '@reduxjs/toolkit';
import { getVenues } from './thunks';
import type { VenueStore } from './Venues.types';

const initialState: VenueStore = {
  venues: {},
  venueList: [],
  status: undefined,
  message: undefined
};

const venuesSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVenues.fulfilled, (state, action) => {
        state.venues = action.payload.venues;
        state.venueList = Object.keys(state.venues);
        state.status = action.payload.status;
      })
      .addCase(getVenues.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          state.status = action.payload!.error.status;
          state.message = action.payload!.error.statusText;
        } else {
          state.status = 500;
          state.message = 'An unknown error occurred.';
        }
      });
  }
});

export const venuesReducer = venuesSlice.reducer;
