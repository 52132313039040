import React from 'react';
import PropTypes from 'prop-types';
import { isNil, get, has } from 'lodash-es';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { COMPLETED_OR_CANCELLED } from '~/constants';
import { convertCentsToDollars } from '~/utilities/currency';
import { useAppSelector } from '~/Modules';

const LyftTripSummary = ({ rideData, dataToggles }) => {
  const lyftDetails = rideData.lyftDetails || {};
  let payor;

  if (!rideData.health_sub_plan_id) {
    payor = 'Patient';
    if (!rideData.stripeTokenId) {
      payor = 'Self Funded';
    }
  } else {
    payor = {
      healthSubPlanName: rideData.health_sub_plan_name,
      healthPlanName: rideData.health_plan_name
    };
  }

  const cancelledOrCompleted =
    COMPLETED_OR_CANCELLED.indexOf(rideData.status) > -1 ? true : false;
  let passengerCost =
    !isNil(rideData.passengerCost) &&
    rideData.passengerCost !== '' &&
    !cancelledOrCompleted
      ? `$${parseFloat(rideData.passengerCost, 10).toFixed(2)}`
      : '';

  if (rideData.vehicleTypeName === 'Lyft') {
    passengerCost = `$${Math.round(get(rideData, 'cost', passengerCost) * 1000) / 1000}`;
  }

  const rideId = lyftDetails
    ? lyftDetails.flex_ride_id
      ? lyftDetails.flex_ride_id
      : lyftDetails.lyft_ride_id
    : '';
  const referenceCode = lyftDetails
    ? lyftDetails.referenceCode
      ? lyftDetails.referenceCode
      : lyftDetails.reference_code
    : '';
  const billingData = useAppSelector(state => state.rideDetails.ride.data.billingData);

  let benefitAdjustmentCost = `${convertCentsToDollars(billingData.benefitAdjustmentCredit ?? 0)}`;
  if (benefitAdjustmentCost !== '$0.00') {
    benefitAdjustmentCost = `-${benefitAdjustmentCost}`;
  }

  return (
    <ul className="LyftTripSummary">
      <li>
        <p>Ride Id</p>
        <p>{rideData.id}</p>
      </li>
      <li>
        <p>Lyft Ride Id</p>
        <p>{rideId}</p>
      </li>
      <li>
        <p>Lyft Reference Code</p>
        <p>{referenceCode}</p>
      </li>
      <li>
        <p>Transportation Type</p>
        <p>{rideData.vehicleTypeName}</p>
      </li>
      {has(lyftDetails, 'ride_data.vehicle.color') ? (
        <li>
          <p>Vehicle Color</p>
          <p>{get(lyftDetails, 'ride_data.vehicle.color', '')}</p>
        </li>
      ) : null}
      {has(lyftDetails, 'ride_data.vehicle.make') &&
      has(lyftDetails, 'ride_data.vehicle.model') ? (
          <li>
            <p>Vehicle Make and Model</p>
            <p>
              {get(lyftDetails, 'ride_data.vehicle.make', '')}{' '}
              {get(lyftDetails, 'ride_data.vehicle.model', '')}
            </p>
          </li>
        ) : null}
      {has(lyftDetails, 'ride_data.vehicle.license_plate') ? (
        <li>
          <p>License Plate</p>
          <p>
            {get(lyftDetails, 'ride_data.vehicle.license_state', '')}{' '}
            {get(lyftDetails, 'ride_data.vehicle.license_plate', '')}
          </p>
        </li>
      ) : null}
      {dataToggles.hospital_name ? (
        <li>
          <p>Hospital Name</p>
          <p>{rideData.hospital.hospitalName}</p>
        </li>
      ) : null}
      {typeof payor === 'string' ? (
        <li>
          <p>Payor</p>
          <p>{payor}</p>
        </li>
      ) : (
        <>
          <li>
            <p>Payor</p>
            <p>{payor.healthPlanName}</p>
          </li>
          <li>
            <p> </p>
            <p>{payor.healthSubPlanName}</p>
          </li>
        </>
      )}
      {rideData.booking_method && rideData.booking_method === 'member-portal' ? (
        <li>
          <p>Booked By</p>
          <p>Member</p>
        </li>
      ) : rideData.booked ? (
        <li>
          <p>Booked By</p>
          <p>
            {rideData.booked.firstName} {rideData.booked.lastName}
          </p>
        </li>
      ) : null}
      {rideData.booked ? (
        <li>
          <p>Booked Contact</p>
          <p>{formatPhoneNumber(rideData.booked.userMobileNo)}</p>
        </li>
      ) : null}
      {rideData.rideType !== 'ondemand' ? (
        <li>
          <p>Driver Punctuality</p>
          <p>{rideData.late_time}</p>
        </li>
      ) : null}
      <li>
        <p>Billable Distance</p>
        <p>{parseFloat(rideData.distance).toFixed(1)} miles</p>
      </li>
      <li>
        <p>Trip Duration</p>
        <p>{rideData.trip_duration}</p>
      </li>
      <li>
        <p>Estimated Cost</p>
        <p>{passengerCost}</p>
      </li>
      {dataToggles.trip_fee ? (
        <li>
          <p>Ride Cost</p>
          {cancelledOrCompleted ? (
            <p>${parseFloat(rideData.totalCost).toFixed(2)}</p>
          ) : (
            <p>To be finalized</p>
          )}
        </li>
      ) : null}
      <li>
        <p>Benefit Adjustment Cost</p>
        <p>
          <span className={benefitAdjustmentCost !== '$0.00' ? 'redIfNonZero' : ''}>
            {benefitAdjustmentCost}
          </span>
        </p>
      </li>
      <li>
        <p>Total Benefit Cost</p>
        <p>{convertCentsToDollars(billingData.totalBenefitCost ?? 0)}</p>
      </li>
      {dataToggles.trip_fee && rideData.lastPayment ? (
        <li>
          <p>Txn ID</p>
          <p>{rideData.lastPayment.txnno}</p>
        </li>
      ) : null}
      {rideData.pickupVenueName ? (
        <li>
          <p>Venue Pickup Location</p>
          <p>{rideData.pickupVenueName}</p>
        </li>
      ) : null}
      {rideData.pickupEntranceName ? (
        <li>
          <p>Venue Pickup Entrance</p>
          <p>{rideData.pickupEntranceName}</p>
        </li>
      ) : null}
      {rideData.dropoffVenueName ? (
        <li>
          <p>Venue Dropoff Location</p>
          <p>{rideData.dropoffVenueName}</p>
        </li>
      ) : null}
      {rideData.dropoffEntranceName ? (
        <li>
          <p>Venue Dropoff Entrance</p>
          <p>{rideData.dropoffEntranceName}</p>
        </li>
      ) : null}
      <li>
        <p>Claim Status</p>
        <p>{rideData?.claim_status}</p>
      </li>
      <li>
        <p>Alert Claim Status</p>
        <p>{rideData?.alert_claim_status}</p>
      </li>
    </ul>
  );
};

LyftTripSummary.propTypes = {
  rideData: PropTypes.object,
  dataToggles: PropTypes.object,
  assistanceNeeds: PropTypes.string
};

LyftTripSummary.defaultProps = {
  rideData: {},
  dataToggles: {},
  assistanceNeeds: ''
};

export default LyftTripSummary;
