import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';

const selectMemberFirstName = (state: AppStore) =>
  state.memberProfile.formData.personalInfo.firstName;
const selectMemberLastName = (state: AppStore) =>
  state.memberProfile.formData.personalInfo.lastName;

export const selectMemberName = createSelector(
  [selectMemberFirstName, selectMemberLastName],
  (firstName, lastName) => ({ firstName, lastName })
);
