import * as React from 'react';

const SvgLogoLarge = props => (
  <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 255.84 54"
    width={255.84}
    height={54}
    {...props}
  >
    <defs>
      <style>{'.svgLogoLarge-cls-1{fill:#111147;}'}</style>
    </defs>
    <path
      className="svgLogoLarge-cls-1"
      d="M243.56 35.11c-3.79 0 -7.02 2.43 -8.23 5.82h-46.21v5.85h46.21c1.21 3.38 4.43 5.82 8.23 5.82 4.82 0 8.74 -3.92 8.74 -8.74s-3.92 -8.74 -8.74 -8.74Zm0 11.64c-1.6 0 -2.9 -1.3 -2.9 -2.9s1.3 -2.9 2.9 -2.9 2.9 1.3 2.9 2.9 -1.3 2.9 -2.9 2.9Z"
    />
    <g>
      <path
        className="svgLogoLarge-cls-1"
        d="M18.35 16.43c-5.34 -1.42 -6.57 -2.15 -6.57 -3.9s1.41 -2.72 3.87 -2.72c3.3 0 5.03 1.64 5.46 5.17l0.03 0.29 6.37 -0.84 -0.02 -0.27c-0.5 -6.87 -4.5 -10.35 -11.89 -10.35 -6.7 0 -11.03 3.6 -11.03 9.18s3.87 7.8 10.12 9.33c4.36 1.08 5.82 1.74 5.82 3.52s-1.36 2.74 -4.05 2.74c-3.79 0 -5.65 -1.67 -5.84 -5.24l-0.02 -0.31 -6.79 0.74v0.27c0.28 6.72 4.89 10.57 12.67 10.57 6.85 0 11.28 -3.63 11.28 -9.24 0 -5.31 -3.6 -7.35 -9.42 -8.92Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M54.36 28.06v5.96c-1.14 0.27 -2.19 0.37 -3.56 0.37 -3.54 0 -4.91 -0.87 -5.28 -3.61 -1.12 2.54 -3.75 3.84 -7.63 3.84 -5.05 0 -7.67 -3.15 -7.67 -7.15 0 -4.7 4.27 -6.51 10.57 -6.51 1.53 0 2.72 0.11 3.88 0.32v-0.73c0 -2.08 -0.71 -3.61 -3.33 -3.61 -2.08 0 -3.2 0.96 -3.27 3.11l-7.06 -0.59c0.07 -5.48 4.32 -8.36 10.32 -8.36 8.47 0 10.46 3.97 10.46 9.55v6.07c0 0.94 0.23 1.44 1.3 1.44 0.41 0 0.82 -0.02 1.26 -0.09Zm-9.68 -0.46v-2.19c-0.82 -0.18 -1.67 -0.3 -2.7 -0.3 -2.72 0 -3.93 0.69 -3.93 2.06 0 1.23 0.8 2.06 2.69 2.06s3.17 -0.59 3.93 -1.62Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M94.22 24.58h-15c0.46 2.79 1.9 4.07 4.27 4.07s3.88 -0.82 4.29 -2.7l6.17 0.78c-0.94 5.16 -4.93 7.79 -10.46 7.79 -7.29 0 -11.44 -4.77 -11.44 -11.72s4.41 -11.81 11.37 -11.81 10.94 4.43 10.94 11.47c0 0.64 -0.02 1.3 -0.14 2.12Zm-14.96 -4.13h7.95c-0.16 -2.53 -1.74 -3.65 -3.79 -3.65s-3.63 1.14 -4.16 3.65Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M185.48 24.58h-15c0.46 2.79 1.9 4.07 4.27 4.07s3.88 -0.82 4.29 -2.7l6.17 0.78c-0.94 5.16 -4.93 7.79 -10.46 7.79 -7.29 0 -11.44 -4.77 -11.44 -11.72s4.41 -11.81 11.37 -11.81 10.94 4.43 10.94 11.47c0 0.64 -0.02 1.3 -0.14 2.12Zm-14.96 -4.13h7.95c-0.16 -2.53 -1.74 -3.65 -3.79 -3.65s-3.63 1.14 -4.16 3.65Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M68.06 7.18c1.06 0 2.11 0.29 3.14 0.87l0.3 0.17 1.78 -5.15 -0.22 -0.12c-1.74 -0.95 -3.51 -1.37 -5.72 -1.37 -5.76 0 -8.68 2.95 -8.68 8.77v1.16h-3.57v6.15h3.57v16.52h7.24V17.66h5.39v-6.15H65.9v-1.32c0 -1.18 0.15 -3.01 2.16 -3.01Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M122.06 13.77c0 -6.17 -3.76 -9.56 -10.58 -9.56H97.7v29.98h8.03V23.15h2.67l6.72 11.04h8.54l-7.53 -11.8c3.77 -1.36 5.93 -4.47 5.93 -8.61Zm-16.33 -3.69h5.21c2.73 0 3.89 1.08 3.89 3.62 0 2.72 -1.21 3.94 -3.94 3.94h-5.16v-7.56Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M130.07 1.83c-2.17 0 -4 1.78 -4 3.89s1.79 3.91 4 3.91 4.04 -1.75 4.04 -3.91 -1.81 -3.89 -4.04 -3.89Z"
      />
      <path
        className="svgLogoLarge-cls-1"
        x={126.47}
        y={11.33}
        width={7.26}
        height={22.85}
        d="M126.47 11.33H133.73V34.18H126.47V11.33z"
      />
      <path
        className="svgLogoLarge-cls-1"
        d="M153.45 13.63c-1.42 -1.84 -3.51 -2.74 -6.36 -2.74 -6.1 0 -10.19 4.75 -10.19 11.82S141 34.6 147.09 34.6c3.25 0 5.61 -1.29 7.02 -3.84l0.22 3.41h6.36V2.03h-7.24v11.6Zm0.18 9.09c0 3.56 -2.09 5.95 -5.2 5.95s-4.87 -2.22 -4.87 -5.95 1.82 -5.82 4.87 -5.82 5.2 2.34 5.2 5.82Z"
      />
    </g>
  </svg>
);

export default SvgLogoLarge;
