import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment-timezone';
import { filterTableData } from '~/Modules/expenses/actions';
import { statusOptions, getKeyByValue } from '~/services/expenses.service';

export const TextInput = ({
  id = '',
  name = '',
  value = '',
  title = '',
  setField = () => {}
}) => [
  <TextField
    id={id}
    key={name}
    name={name}
    type="search"
    value={value}
    placeholder={''}
    onChange={({ target: { value } }) => setField(value)}
    inputProps={{ 'aria-label': `Filter data by ${title}` }}
  />
];

export const DateInput = ({
  label = '',
  name = '',
  value = null,
  minDate = null,
  maxDate = null,
  setField = () => {}
}) => {
  // action to set date field
  const handleDateChange = value => {
    if (value) {
      setField(moment(value).format('YYYY-MM-DD'));
    } else {
      setField('');
    }
  };

  // never clearing or disabling the toDate or fromDate
  const clearable = true;
  const disabled = false;

  // set minimum and maximum date
  const dates = {};
  if (minDate) dates.minDate = moment(minDate).format('MM/DD/YYYY');
  if (maxDate) dates.maxDate = moment(maxDate).format('MM/DD/YYYY');

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        id={`date-picker-${name}`}
        disabled={disabled}
        label={label}
        value={value ? moment(value).format('MM/DD/YYYY') : null}
        {...dates}
        onChange={value => handleDateChange(value)}
        margin="normal"
        placeholder={'Select Date'}
        clearable={clearable}
        format={'MM/dd/yyyy'}
        orientation="landscape"
        inputVariant="standard"
        KeyboardButtonProps={{
          'aria-label': 'change date'
        }}
        disablePast={false}
      />
    </MuiPickersUtilsProvider>
  );
};

const SelectInput = ({ columnDef = {}, filterParams = {}, setField = () => {} }) => {
  // action to set select field
  const handleSelectChange = value => {
    if (value) setField(columnDef.lookup[value]);
    else setField(null);
  };

  return (
    <Select
      style={{ marginTop: 0 }}
      value={getKeyByValue(statusOptions, filterParams[columnDef.field]) || ''}
      onChange={event => handleSelectChange(event.target.value)}
    >
      <MenuItem value="">
        <em style={{ fontSize: '12px' }}>None</em>
      </MenuItem>
      {Object.keys(columnDef.lookup).map(key => (
        <MenuItem key={key} value={key} style={{ fontSize: '12px' }}>
          {columnDef.lookup[key]}
        </MenuItem>
      ))}
    </Select>
  );
};

const ComponentForColumn = ({ columnDef, setField, filterParams, disable }) => {
  if (columnDef.filtering === false) return null;
  if (columnDef.field) {
    if (columnDef.type === 'date') {
      const minDate = columnDef.field === 'toDate' ? filterParams.fromDate : null;
      const maxDate = columnDef.field === 'fromDate' ? filterParams.toDate : null;
      if (columnDef.field === 'toDate') {
        return [
          <DateInput
            key={columnDef.field}
            name={columnDef.field}
            title={columnDef.title}
            value={filterParams[columnDef.field]}
            disable={disable}
            minDate={minDate}
            setField={value => setField({ name: columnDef.field, value })}
          />
        ];
      }
      if (columnDef.field === 'fromDate') {
        return [
          <DateInput
            key={columnDef.field}
            name={columnDef.field}
            title={columnDef.title}
            value={filterParams[columnDef.field]}
            disable={disable}
            maxDate={maxDate}
            setField={value => setField({ name: columnDef.field, value })}
          />
        ];
      }
      return [
        <DateInput
          key={columnDef.field}
          name={columnDef.field}
          title={columnDef.title}
          value={filterParams[columnDef.field]}
          disable={disable}
          setField={value => setField({ name: columnDef.field, value })}
        />
      ];
    } else if (columnDef.lookup) {
      return (
        <SelectInput
          key={columnDef.field}
          columnDef={columnDef}
          filterParams={filterParams}
          setField={value => setField({ name: columnDef.field, value })}
        />
      );
    } else {
      return [
        <TextInput
          key={columnDef.field}
          id={columnDef.field}
          name={columnDef.field}
          title={columnDef.title}
          value={filterParams[columnDef.field]}
          setField={value => setField({ name: columnDef.field, value })}
        />
      ];
    }
  }
};

const TableFilter = ({ columns, params = {}, query = {} }) => {
  const dispatch = useDispatch();
  const [firstLoad, setFirstLoad] = useState(true);
  const [filterParams, setFilterParams] = useState(params);
  const [disable, setDisable] = useState(true);
  const { limit } = query;

  // listen to filter input fields
  useEffect(() => {
    if (!firstLoad) {
      let filterObj = Object.entries(filterParams).reduce((acc, [key, val]) => {
        if (val !== null && val !== '') {
          acc[key] = val;
        }
        return acc;
      }, {});

      //placing fromDate and toDate as keys at beginning of Object so that when it goes to BE the right query structure is formed
      if (filterObj.fromDate && filterObj.toDate) {
        filterObj = Object.assign(
          { fromDate: filterObj.fromDate, toDate: filterObj.toDate },
          filterObj
        );
      }
      if (
        filterObj.fromDate ||
        filterObj.toDate ||
        (!filterObj.fromDate && !filterObj.toDate)
      ) {
        dispatch(filterTableData({ query: filterObj, limit }));
      }
    } else {
      setFirstLoad(false);
    }
  }, [filterParams]);

  // enables 'toDate' if 'fromDate' has value
  useEffect(() => {
    if (filterParams.fromDate) {
      setDisable(false);
    }
  }, [filterParams]);

  const setFilterParam = ({ name, value }) => {
    setFilterParams({ ...filterParams, [name]: value });
  };

  return (
    <TableRow style={{ height: 10 }}>
      {columns.map(columnDef => (
        <TableCell key={columnDef.field}>
          <ComponentForColumn
            columnDef={columnDef}
            filterParams={filterParams}
            disable={disable}
            setField={x => setFilterParam(x)}
          />
        </TableCell>
      ))}
    </TableRow>
  );
};

export default TableFilter;
