import React from 'react';
import SvgLogoLarge from '~/Shared/Components/Svgs/SvgLogoLarge';
import SvgUpload from '~/Shared/Components/Svgs/SvgUpload';
import {type UserData} from '~/types';

export type ImportMembersProps = {
  adminToken: unknown;
  userRole: UserData['role'];
  lastUpdated: LastUpdatedNoticeProps['lastUpdated'];
  handleGotoMembersImport: () => void;
};

const ImportMembers: React.FC<ImportMembersProps> = ({
  adminToken,
  userRole,
  lastUpdated,
  handleGotoMembersImport
}) => {
  if (!adminToken) return null;

  return (
    <div className="importWrapper">
      {userRole !== 'CaseManager' ? (
        <div className="import">
          <SvgLogoLarge className="logo" />
          <div className="importBox" onClick={handleGotoMembersImport}>
            <span>Upload Members</span>
            <SvgUpload />
          </div>

          <LastUpdatedNotice lastUpdated={lastUpdated} />
        </div>
      ) : null}
    </div>
  );
};

export type LastUpdatedNoticeProps = {
  lastUpdated: moment.Moment | null;
};

const LastUpdatedNotice: React.FC<LastUpdatedNoticeProps> = ({lastUpdated}) => {
  if (!lastUpdated) return null;

  return (
    <div className="importUpdate">
      Last updated on {lastUpdated.format('M/D/YYYY')} at{' '}
      {lastUpdated.format('h:mm A')}
    </div>
  );
};

export default ImportMembers;
