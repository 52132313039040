import {
  createTypeGuard,
  isBoolean,
  isString,
  isNumber,
  isBinary,
  isNumeric,
  isFunction,
  isObject,
  isUndefined,
  isJsonArray,
  isJsonObject,
  isNull,
  isNil,
  isEmpty
} from '@SRHealth/frontend-lib';

import type {
  AddressApprovedProvider,
  AddressProviderNotFound,
  AddressSavedAddress,
  PhoneNumberType
} from '~/types';
import type {
  MemberSavedAddress,
  MemberSavedAddressRaw
} from '~/services/savedAddress.service';

export {
  isBoolean,
  isString,
  isNumber,
  isBinary,
  isNumeric,
  isFunction,
  isObject,
  isUndefined,
  isJsonArray,
  isJsonObject,
  isNull,
  isNil,
  isEmpty
};

export const isArray = createTypeGuard((v): unknown[] | null =>
  Array.isArray(v) ? v : null
);

export const isStringOrNumber = createTypeGuard((v: unknown): string | number | null =>
  isString(v) || isNumber(v) ? v : null
);

export const isAddressApprovedProvider = createTypeGuard(
  (t, has): AddressApprovedProvider | null => {
    if (
      t &&
      typeof t === 'object' &&
      !Array.isArray(t) &&
      has(t, 'address', isString) &&
      has(t, 'providerId', isStringOrNumber) &&
      has(t, 'providerName', isString) &&
      has(t, 'providerPhone', isString) &&
      has(t, 'providerNpi', isStringOrNumber)
    ) {
      return t;
    }

    return null;
  }
);

export const isAddressProviderNotFound = createTypeGuard(
  (t, has): AddressProviderNotFound | null => {
    if (
      t &&
      typeof t === 'object' &&
      !Array.isArray(t) &&
      has(t, 'address', isString) &&
      has(t, 'facilityName', isString) &&
      has(t, 'providerName', isString) &&
      has(t, 'providerPhone', isString)
    ) {
      if (has(t, 'additionalNotes', isEmpty)) {
        return null;
      }

      return t;
    }

    return null;
  }
);

export const isAddressSavedAddress = createTypeGuard(
  (t, has): AddressSavedAddress | null => {
    if (
      t &&
      typeof t === 'object' &&
      !Array.isArray(t) &&
      has(t, 'memberSavedAddressId', isNumber) &&
      has(t, 'addressName', isString) &&
      has(t, 'address', isString) &&
      has(t, 'zipcode', isString) &&
      has(t, 'latitude', isNumber) &&
      has(t, 'longitude', isNumber)
    ) {
      return t;
    }

    return null;
  }
);

export const isMemberSavedAddressRaw = createTypeGuard(
  (t, has): MemberSavedAddressRaw | null => {
    if (
      t &&
      typeof t === 'object' &&
      !Array.isArray(t) &&
      has(t, 'passenger_id', isNumber) &&
      has(t, 'address', isString) &&
      has(t, 'zip', isString) &&
      has(t, 'name', isString) &&
      has(t, 'latitude', isNumeric) &&
      has(t, 'longitude', isNumeric) &&
      has(t, 'is_default', isBoolean)
    ) {
      return t;
    }

    return null;
  }
);

export const isMemberSavedAddress = createTypeGuard(
  (t, has): MemberSavedAddress | null => {
    if (t && isMemberSavedAddressRaw(t) && has(t, 'id', isNumber)) {
      return t;
    }

    return null;
  }
);

export const isPhoneNumberType = createTypeGuard((t): PhoneNumberType | null => {
  if (t === 'landline' || t === 'mobile' || t === 'other') {
    return t;
  }

  return null;
});
