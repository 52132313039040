import React from 'react';

const SvgSwapVert = props => {
  const {title, className} = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>{title}</title>
      <path d="M16 17.01V10h-2v7.01h-3L15 21l4-3.99h-3zM9 3L5 6.99h3V14h2V6.99h3L9 3z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
};

export default SvgSwapVert;
