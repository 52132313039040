import { useModel } from '@SRHealth/frontend-lib';
import type { ModelInterface } from '@SRHealth/frontend-lib/dist/lib/model/Model';
import { useAppSelector, type RootState } from '~/Modules';

/** Simplified hook so that you don't have to manually call
 * `useAppSelector` and `useModel` for a model. */
export default function useModelSelector<
  M extends ModelInterface<P>,
  P extends Record<string | number | symbol, unknown>
>(selector: (state: RootState) => M) {
  const _model = useAppSelector(selector);
  const { model } = _model ? useModel(_model) : { model: _model };

  return { model };
}
