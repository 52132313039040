import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { fixUTCDate } from '~/utilities/timesAndDates';
import { convertCentsToDollars } from '~/utilities/currency';
import { TERMINAL_RIDE_STATUSES } from '~/constants';

const Row = props => {
  const statusShow = [
    'Scheduled',
    'WillCall',
    'Cancelled',
    'Inbound',
    'Completed',
    'DriverCancelled',
    'Started',
    'Arrived',
    'Dispatcher Managed - Scheduled',
    'Dispatcher Managed - WillCall',
    'Dispatcher Managed - Completed',
    'Dispatcher Managed - Cancelled',
    'Incomplete',
    'Dispatcher Managed - Incomplete'
  ];
  const { rowData, showRideDetails } = props;

  const formattedRegular = fixUTCDate(
    rowData.pickupDate,
    rowData.timezone,
    'MM/DD h:mm A z'
  );

  const showLink = () => _.includes(statusShow, rowData.status);

  const status =
    rowData.status === 'DriverCancelled' ? 'Driver Cancelled' : rowData.status;
  const rowStatus = rowData.status.replace(/\s/g, '');

  // TODO:UBER — Generalize for all rideshare types
  let transportType = rowData.transportType;
  if (transportType === 'Lyft') {
    transportType = 'Lyft Sedan';
    if (
      _.has(rowData, 'lyftRideType') &&
      !_.isNil(rowData.lyftRideType) &&
      rowData.lyftRideType === 'lyft_plus'
    ) {
      transportType = 'Lyft SUV';
    }
  }

  if (rowData.mode === 'Public') {
    transportType = `Public Transit [${rowData.mobilityTypeNickName}]`;
  }

  let multiLegText = rowData.id;
  if (!_.isNil(rowData.trip_id) && !_.isNil(rowData.tripRides)) {
    const alpha = 'abcdefghijklmnopqrstuvwxyz';

    const trips = rowData.tripRides
      .substr(1, rowData.tripRides.length - 2)
      .split(',')
      .map(id => parseInt(id, 10));

    const letter = alpha[trips.indexOf(rowData.id)];

    multiLegText = `${rowData.id}-${letter}`;
  }

  const showCost = TERMINAL_RIDE_STATUSES.includes(rowData.status);
  return (
    <tr className="historyRow">
      <td>
        {showLink() ? (
          <a className="rowLink" onClick={() => showRideDetails(rowData)}>
            {multiLegText}
          </a>
        ) : (
          <span className="rowLinkSpan">{multiLegText}</span>
        )}
      </td>
      <td>{rowData.healthSubPlanName}</td>
      <td>{formattedRegular}</td>
      <td>
        {showLink() ? (
          <a className={`rowLink ${rowStatus}`} onClick={() => showRideDetails(rowData)}>
            {status}
          </a>
        ) : (
          <span className={`rowLinkSpan ${rowStatus}`}>{status}</span>
        )}
      </td>
      <td>{rowData.passengerName}</td>
      <td>{rowData.medicalId}</td>
      <td>{transportType}</td>
      {showCost ? (
        <td>{convertCentsToDollars(rowData.totalSupplierCost ?? 0)}</td>
      ) : (
        <td>To be finalized</td>
      )}
      <td>{rowData.distance}</td>
      <td>{rowData.pickupAddress}</td>
      <td>{rowData.dropoffAddress}</td>
    </tr>
  );
};

Row.propTypes = {
  showCost: PropTypes.bool,
  rowData: PropTypes.object,
  showRideDetails: PropTypes.func
};

Row.defaultProps = {
  showCost: false,
  rowData: {},
  showRideDetails: () => {}
};

export default Row;
