import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';

/** Retrieve the list of hospitals/facilities available to the passenger.
 * This is largely defined by the user store, and user's role. */
export const selectFacilities = createSelector(
  [(s: AppStore) => s.user?.hospitalData || []],
  hospitals => {
    // Standardize this because Hospital Owner and Hospital Network Manager
    // have different data structures.
    if (!Array.isArray(hospitals)) hospitals = [hospitals];

    return hospitals
      .map(hospital => ({
        id: hospital.id,
        label: hospital.hospitalName,
        value: hospital.id
      }))
      .sort((a, b) => a.label.localeCompare(b.label));
  }
);
