import { type AppDispatch, type RootState } from '~/Modules';
import { baseRequestConfig } from '~/utilities/auth.helper';
import axios from '~/Modules/safeAxios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import type { JsonObject } from '~/types';

const url = `${process.env.REACT_APP_API_URL}/user-profile`;

/** Retrieve the profile for the active logged in user. */
export const getProfile = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: { error: { status: number; message: string } };
}>()('profile/getProfile', async (_, { rejectWithValue }) => {
  try {
    return axios
      .get(url, baseRequestConfig())
      .then(({ data, status }) => ({ data, status }));
  } catch (error) {
    return rejectWithValue(error);
  }
});

/** Update the user profile. */
export const updateProfile = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: { error: { status: number; message: string } };
}>()('profile/updateProfile', async (payload: JsonObject, { rejectWithValue }) => {
  try {
    return axios
      .post(url, payload, baseRequestConfig())
      .then(({ data, status }) => ({ data, status }));
  } catch (error) {
    return rejectWithValue(error);
  }
});
