import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { fixUTCDate } from '~/utilities/timesAndDates';

const TripDetails = props => {
  const { rideDetails } = props;

  return (
    <div className="tripSummary">
      <h2>Trip Details</h2>
      <ul className="tripSummaryList">
        <li>
          <p>Scheduled On</p>
          <p>{rideDetails.ShowCreated_on}</p>
        </li>
        <li>
          <p>Inbound Time</p>
          <p>
            {rideDetails.ShowAcceptedAtTime !== ''
              ? rideDetails.ShowAcceptedAtTime
              : '--'}
          </p>
        </li>
        {!_.isNil(rideDetails.nemtRunPickup) ? (
          <li>
            <p>Run Pickup Time</p>
            <p>
              {fixUTCDate(
                rideDetails.nemtRunPickup,
                rideDetails.passenger.timezone_format,
                'MM-DD-YYYY h:mm A z',
                'YYYY-MM-DD HH:mm:ss'
              )}
            </p>
          </li>
        ) : null}
        {
          // 2019-08-17 00:00:00
          // 08-16-2019 2:00 PM PDT
          !_.isNil(rideDetails.nemtRunDropoff) ? (
            <li>
              <p>Run Dropoff Time</p>
              <p>
                {fixUTCDate(
                  rideDetails.nemtRunDropoff,
                  rideDetails.passenger.timezone_format,
                  'MM-DD-YYYY h:mm A z',
                  'YYYY-MM-DD HH:mm:ss'
                )}
              </p>
            </li>
          ) : null
        }
        <li>
          <p>Arrival Time</p>
          <p>{rideDetails.ShowArrivedAt !== '' ? rideDetails.ShowArrivedAt : '--'}</p>
        </li>
        {rideDetails.rideType === 'ondemand' ? (
          <li>
            <p>Waiting Time</p>
            <p>{rideDetails.patient_waiting_time}</p>
          </li>
        ) : null}
        <li>
          <p>Start Time</p>
          <p>{rideDetails.ShowStartedAt !== '' ? rideDetails.ShowStartedAt : '--'} </p>
        </li>
        <li>
          <p>Completed Time</p>
          <p>
            {rideDetails.ShowCompletedAt !== '' ? rideDetails.ShowCompletedAt : '--'}{' '}
          </p>
        </li>
        <li>
          <p>Scheduled Pickup Time</p>
          <p>
            {rideDetails.ShowRideStartTime !== '' ? rideDetails.ShowRideStartTime : '--'}{' '}
            {!_.isNil(rideDetails.originalRideStartTime) &&
            rideDetails.originalRideStartTime !== rideDetails.rideStartTime ? (
                <i> (Edited)</i>
              ) : null}
          </p>
        </li>
        {!_.isNil(rideDetails.appointmentTime) ? (
          <li>
            <p>Scheduled Appt. Time</p>
            <p>
              {rideDetails.ShowAppointmentTime !== ''
                ? rideDetails.ShowAppointmentTime
                : '--'}{' '}
              {!_.isNil(rideDetails.original_appt_pickup_time) &&
              rideDetails.original_appt_pickup_time !==
                rideDetails.ShowAppointmentTime ? (
                  <i> (Edited)</i>
                ) : null}
            </p>
          </li>
        ) : null}
        {!_.isNil(rideDetails.extraTime) ? (
          <li>
            <p>Extra Drive Time Requested</p>
            <p>{rideDetails.extraTime} Minutes</p>
          </li>
        ) : null}
      </ul>
    </div>
  );
};

TripDetails.defaultProps = {
  ride: {},
  rideDetails: {}
};

TripDetails.propTypes = {
  ride: PropTypes.object,
  rideDetails: PropTypes.object
};

export default TripDetails;
