/**
 * Component used to display a steps UI for a Wizard-type flow
 *
 * The _steps_ property is an array of objects. Each object should have at least a _label_
 * property for display.
 */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const WizardStepsDisplay = props => {
  const currentStep = props.steps[props.currentStepIndex] || {};
  const isValid = Object.hasOwn(currentStep, 'isValid') ? currentStep.isValid : true;
  const isValidBack = Object.hasOwn(currentStep, 'isValidBack')
    ? currentStep.isValidBack
    : true;

  return (
    <div className="WizardStepsDisplay">
      <div className="stepsWrapper">
        <button
          className="prev"
          disabled={!isValidBack || props.currentStepIndex === 0}
          onClick={props.handlePrev}
        >
          back
        </button>
        {props.steps.map((step, idx) => (
          <label
            key={idx}
            className={classNames({
              active: idx === props.currentStepIndex
            })}
          >
            {idx + 1}
            <sub>{step.label}</sub>
          </label>
        ))}
        <button
          className="next"
          disabled={!isValid || props.currentStepIndex === props.steps.length - 1}
          onClick={props.handleNext}
        >
          next
        </button>
      </div>
    </div>
  );
};

WizardStepsDisplay.propTypes = {
  steps: PropTypes.array,
  currentStepIndex: PropTypes.number,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func
};

WizardStepsDisplay.defaultProps = {
  steps: [],
  currentStepIndex: 0,
  handleNext: () => {},
  handlePrev: () => {}
};

export default WizardStepsDisplay;
