import { createAsyncThunk } from '@reduxjs/toolkit';
import { type AppDispatch, type RootState } from '~/Modules';
import axios from '~/Modules/safeAxios';
import { baseRequestConfig } from '~/utilities/auth.helper';

/** Retrieve a list of venues from the backend. */
export const getVenues = createAsyncThunk.withTypes<{
  state: RootState;
  dispatch: AppDispatch;
  rejectValue: { error: { status: number; statusText: string } };
  extra: { s: string; n: number };
}>()('venues/getVenues', async (_arg, { rejectWithValue }) => {
  try {
    // API call to get venues
    const response = await axios.get(`${process.env.REACT_APP_API_URL}/rides/venues`, {
      headers: baseRequestConfig().headers
    });

    return {
      venues: response.data.data.venues,
      status: response.status
    };
  } catch (error) {
    return rejectWithValue({ error: error.response });
  }
});
