import React from 'react';
import { type FormInputInternalProps, type FormInputPropsBase } from './FormInput';
import { getTestId } from './Form.helpers';
import { Checkbox, FormControl, FormControlLabel, FormGroup } from '@material-ui/core';

export interface FormInputCheckboxProps extends FormInputPropsBase {
  readonly type: 'checkbox';
  selection?: number[];
}

const FormInputCheckbox: React.FC<FormInputCheckboxProps & FormInputInternalProps> = ({
  inputId,
  type,
  name,
  selection = [],
  options,
  required,
  error,
  disabled,
  classes,
  onChange = () => {},
  onCheckDependencies = () => true
}) => {
  const testId = getTestId({ type, name: name });

  function _onChange(id: number) {
    const newValue = [...selection];
    const index = newValue.findIndex(v => v === id);

    if (index === -1) {
      newValue.push(id);
    } else {
      newValue.splice(index, 1);
    }

    onChange(inputId, newValue);
  }

  return (
    <FormControl variant="outlined" required={required} error={error}>
      <FormGroup className={classes.checkbox}>
        {options.map((opt, id) =>
          onCheckDependencies(opt.dependencies) ? (
            <FormControlLabel
              key={id}
              label={opt.label}
              disabled={disabled}
              onChange={() => _onChange(id)}
              control={
                <Checkbox
                  size="small"
                  color="primary"
                  value={opt.value}
                  name={`${opt.value}`}
                  checked={selection.includes(id)}
                  data-testid={`${testId}-${opt.value}`}
                />
              }
            />
          ) : null
        )}
      </FormGroup>
    </FormControl>
  );
};

export default FormInputCheckbox;
