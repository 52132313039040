import React from 'react';
import PropTypes from 'prop-types';

const SvgPassengerIcon = props => {
  return (
    <svg className={props.className} width="20" height="20" viewBox="0 0 20 20">
      <path d="M0 0h24v24H0V0z" fill="none"/>
      <g>
        <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"/>
      </g>
    </svg>
  );
};

SvgPassengerIcon.propTypes = {
  className: PropTypes.string
};

SvgPassengerIcon.defaultProps = {
  className: ''
};

export default SvgPassengerIcon;
