/** This slice should be removed but is currently required to support
 * RBF legacy. On deprecation of RBF legacy we should remove this slice
 * from the redux store entirely. */
import { createSlice } from '@reduxjs/toolkit';
import type { SearchStore } from './Search.types';
import { fetchApprovedProviders } from './thunks';

const initialState = (): SearchStore => ({
  approvedProviders: {
    data: [],
    hasMore: true,
    page: 0,
    offset: 0,
    limit: 20
  }
});

const searchSlice = createSlice({
  name: 'search',
  initialState: initialState(),
  reducers: {
    clearSearchResults: () => initialState()
  },
  extraReducers: builder => {
    // Add extra reducers here
    builder
      .addCase(fetchApprovedProviders.fulfilled, (state, action) => {
        const { data, offset, limit, page } = action.payload!;

        state.approvedProviders.data =
          offset > 0 ? state.approvedProviders.data.concat(data) : data;

        state.approvedProviders.hasMore = data.length >= 20;
        state.approvedProviders.page = page;
        state.approvedProviders.offset = offset;
        state.approvedProviders.limit = limit;
      })
      .addCase(fetchApprovedProviders.rejected, () => {
        // I guess we don't do anything if it fails?
      });
  }
});

export const searchReducer = searchSlice.reducer;
export const { clearSearchResults } = searchSlice.actions;
