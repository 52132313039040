import { all, put, select, take, takeEvery } from 'redux-saga/effects';
import _ from 'lodash-es';
import { parseMembers, parseAdditionalMemberData } from '~/utilities/members';
import axios from './safeAxios';
import { token, baseRequestConfig } from '~/utilities/auth.helper';

import { fetchMemberPermissions, createMember } from '~/Modules/members/saga';
import {
  GET_MEMBER_PERMISSIONS,
  SET_NEW_MEMBER_FORM,
  VALIDATE_NEW_MEMBER_FORM
} from '~/Modules/members/constants';
import {
  GET_MEMBER_PERMISSIONS_ERROR,
  GET_MEMBER_PERMISSIONS_SUCCESS,
  CREATE_NEW_MEMBER,
  CREATE_NEW_MEMBER_SUCCESS,
  CREATE_NEW_MEMBER_ERROR,
  CLEAR_NEW_MEMBER_FORM
} from './members/constants';

import {
  generateFundingSourceOptions,
  parsePlanMemberPermissions,
  parseAllPlanMemberPermissions,
  isFormValid
} from './members/helpers';

import immutable from 'immutability-helper';

export const MEMBERS_GET_LIST = 'MEMBERS/list/get';
export const MEMBERS_GET_LIST_SUCCESS = 'MEMBERS/list/get/success';
export const MEMBERS_GET_LIST_ERROR = 'MEMBERS/list/get/error';
export const MEMBERS_GET = 'MEMBERS/get';
export const MEMBERS_CLEAR = 'MEMBERS/clear';
export const MEMBERS_GET_SUCCESS = 'MEMBERS/get/success';
export const MEMBERS_GET_ERROR = 'MEMBERS/get/error';
export const MEMBERS_SET = 'MEMBERS/set';

/**
 * Dispatch action to get members
 * @param   {Object}    params Action dispatch params
 * @returns {Function}         Dispatch action
 */
export const getMembers = (params = {}) => {
  return dispatch => dispatch({ type: MEMBERS_GET_LIST, data: params });
};

export const getMember = (params = {}) => {
  return dispatch => dispatch({ type: MEMBERS_GET, data: params });
};

export const clearMembers = () => {
  return dispatch => dispatch({ type: MEMBERS_CLEAR });
};

export const setMemberEligible = (data = {}) => {
  return dispatch => dispatch({ type: MEMBERS_SET, data });
};

/**
 * Generator function for getting members from API
 * @param   {Object}    action Action dispatch params
 * @returns {undefined}
 */
function* getMembersFromApi(action = {}) {
  try {
    const increment = 50;
    const params = action.data;

    const start = ((params?.page ?? 1) - 1) * increment;
    const url = `//${
      process.env.REACT_APP_ANALYTICS_API_HOST
    }/api/v1/members/list/${start}/${increment}?search=${params?.query ?? ''}`;

    const config = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token()}` }
    };

    const membersData = yield axios.get(url, config);
    const data = parseMembers(membersData.data.data);
    yield put({ type: MEMBERS_GET_LIST_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: MEMBERS_GET_LIST_ERROR, error });
  }
}

function* getMemberFromApi(action = {}) {
  try {
    const params = action.data;
    const members = yield select(state => state.members);
    if (Object.keys(members).length === 0) {
      // Members not loaded yet, lets wait for them to loaded
      yield take(MEMBERS_GET_LIST_SUCCESS);
    }
    const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/${params.planId}/members/${params.id}?mode=advanced`;
    const memberData = yield axios.get(url, baseRequestConfig());
    const data = parseAdditionalMemberData(memberData.data.data.passenger);
    yield put({ type: MEMBERS_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: MEMBERS_GET_ERROR, error });
  }
}

export function* membersSaga() {
  yield all([
    yield takeEvery(MEMBERS_GET_LIST, getMembersFromApi),
    yield takeEvery(MEMBERS_GET, getMemberFromApi),
    yield takeEvery(GET_MEMBER_PERMISSIONS, fetchMemberPermissions),
    yield takeEvery(CREATE_NEW_MEMBER, createMember)
  ]);
}

const initialState = {
  error: null,
  success: null,
  rows: [],
  addNewMemberFormData: {
    firstName: '',
    lastName: '',
    dateOfBirth: '',
    healthPlan: '',
    phone: '',
    fundingSource: '',
    eligibilityStartDate: '',
    eligibilityEndDate: '',
    isValid: false
  }
};

export const membersReducer = (state = initialState, action = {}) => {
  let newState = _.cloneDeep(state);
  switch (action.type) {
    case MEMBERS_GET_LIST_SUCCESS: {
      if (action?.params?.refresh === true) {
        // state should be immutable. overrides old state
        newState = action.data;

        newState.endOfList = false;
        if ((action?.data?.rows ?? []).length !== 50) {
          newState.endOfList = true;
        }

        return {
          ...newState,
          ...(state && state.fundingOptions && { fundingOptions: state.fundingOptions }),
          ...(state && state.healthPlans && { healthPlans: state.healthPlans }),
          ...(state &&
            state.membershipPermissions && {
            membershipPermissions: state.membershipPermissions
          }),
          ...(state &&
            state.addNewMemberFormData && {
            addNewMemberFormData: state.addNewMemberFormData
          })
        };
      } else if ((action?.data?.rows ?? []).length === 0) {
        //Nothing new to add
        newState.parsed = action.data.parsed;
        newState.endOfList = true;

        return {
          ...newState,
          ...(state && state.fundingOptions && { fundingOptions: state.fundingOptions }),
          ...(state && state.healthPlans && { healthPlans: state.healthPlans }),
          ...(state &&
            state.membershipPermissions && {
            membershipPermissions: state.membershipPermissions
          }),
          ...(state &&
            state.addNewMemberFormData && {
            addNewMemberFormData: state.addNewMemberFormData
          })
        };
      } else {
        if ((action?.data?.rows ?? []).length !== 50) {
          newState.endOfList = true;
        } else {
          newState.endOfList = false;
        }

        newState.rows = newState.rows.concat(action.data.rows);

        return {
          ...newState,
          ...(state && state.fundingOptions && { fundingOptions: state.fundingOptions }),
          ...(state && state.healthPlans && { healthPlans: state.healthPlans }),
          ...(state &&
            state.membershipPermissions && {
            membershipPermissions: state.membershipPermissions
          })
        };
      }
    }
    case MEMBERS_GET_LIST_ERROR: {
      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        // eslint-disable-next-line no-console
        console.error(action.error);
      }
      return {
        status: false,
        error: action.error,
        ...newState
      };
    }
    case MEMBERS_CLEAR: {
      return { ...newState };
    }
    case MEMBERS_GET_SUCCESS: {
      const newState = _.cloneDeep(state);
      if ('rows' in newState && action?.params && 'id' in action.params) {
        const memberIndex = newState.rows.findIndex(row => row.id === action.params.id);
        if (typeof memberIndex !== 'undefined') {
          newState.rows[memberIndex].additionalData = action.data;
        }
      }
      return {
        ...newState,
        ...(state && state.fundingOptions && { fundingOptions: state.fundingOptions }),
        ...(state && state.healthPlans && { healthPlans: state.healthPlans }),
        ...(state &&
          state.membershipPermissions && {
          membershipPermissions: state.membershipPermissions
        }),
        ...(state &&
          state.addNewMemberFormData && {
          addNewMemberFormData: state.addNewMemberFormData
        })
      };
    }
    case MEMBERS_SET: {
      const newState = _.cloneDeep(state);
      if (action?.data && 'id' in action.data && 'rows' in newState) {
        const memberIndex = newState.rows.findIndex(row => row.id === action.data.id);

        if (memberIndex > -1) {
          newState.rows[memberIndex] = Object.assign(
            newState.rows[memberIndex],
            action.data
          );
        }
      }
      return {
        ...newState,
        ...(state && state.fundingOptions && { fundingOptions: state.fundingOptions }),
        ...(state && state.healthPlans && { healthPlans: state.healthPlans }),
        ...(state &&
          state.membershipPermissions && {
          membershipPermissions: state.membershipPermissions
        }),
        ...(state &&
          state.addNewMemberFormData && {
          addNewMemberFormData: state.addNewMemberFormData
        }),
        inHttpRequest: false
      };
    }

    case GET_MEMBER_PERMISSIONS_SUCCESS: {
      const membershipData = action.payload.data;
      const plans = parsePlanMemberPermissions(membershipData);
      const allHealthPlans = parseAllPlanMemberPermissions(membershipData);
      const fundingOptions = generateFundingSourceOptions(action.payload.user);
      return immutable(newState, {
        membershipPermissions: { $set: action.payload.data },
        healthPlans: { $set: plans },
        allHealthPlans: { $set: allHealthPlans },
        fundingOptions: { $set: fundingOptions },
        ...(fundingOptions.disabled && {
          addNewMemberFormData: {
            fundingSource: { $set: fundingOptions.options[0] }
          }
        })
      });
    }
    case GET_MEMBER_PERMISSIONS_ERROR: {
      return { ...newState, memberPermissions: [] };
    }
    case SET_NEW_MEMBER_FORM: {
      const memberState = newState;
      if (action.params.key === 'healthPlan') {
        delete newState.addNewMemberFormData.subPlan;
        delete newState.addNewMemberFormData.subPlanId;
      }
      return immutable(memberState, {
        addNewMemberFormData: {
          [action.params.key]: { $set: action.params.value },
          ...(action.params.id && {
            [`${action.params.key}Id`]: { $set: action.params.id }
          })
        }
      });
    }
    case VALIDATE_NEW_MEMBER_FORM: {
      return immutable(newState, {
        addNewMemberFormData: { isValid: { $set: isFormValid(newState) } }
      });
    }
    case CREATE_NEW_MEMBER_SUCCESS: {
      return immutable(newState, {
        $set: {
          ...initialState,
          membershipPermissions: newState.membershipPermissions,
          healthPlans: newState.healthPlans,
          fundingOptions: newState.fundingOptions,
          success: true,
          memberProfile: { ...action.payload.memberProfile },
          data: {
            ...action.payload.data.data,
            medicalId: newState.addNewMemberFormData.medicalId,
            phone: newState.addNewMemberFormData.phone,
            dateOfBirth: newState.addNewMemberFormData.dateOfBirth,
            ...(action.payload.data.data.health_plan_id && {
              healthPlanId: action.payload.data.data.health_plan_id
            })
          }
        }
      });
    }
    case CREATE_NEW_MEMBER_ERROR: {
      return immutable(newState, {
        addNewMemberFormData: {
          error: { $set: action.payload.error }
        }
      });
    }
    case CLEAR_NEW_MEMBER_FORM: {
      return immutable(newState, {
        $set: {
          ...initialState,
          membershipPermissions: newState.memberPermissions,
          healthPlans: newState.healthPlans,
          fundingOptions: newState.fundingOptions,
          ...(action.params && {
            addNewMemberFormData: {
              ...initialState.addNewMemberFormData,
              fundingSource: action.params.disabled ? action.params.options[0] : ''
            }
          })
        }
      });
    }
    default: {
      return state;
    }
  }
};
