import { put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash-es';
import axios from './safeAxios';
import { token } from '~/utilities/auth.helper';

export const IMPORTER_TEMPLATE_LIST_GET = 'IMPORTER/template/list/get';
export const IMPORTER_TEMPLATE_LIST_GET_SUCCESS = 'IMPORTER/template/list/get/success';
export const IMPORTER_TEMPLATE_LIST_GET_ERROR = 'IMPORTER/template/list/get/error';
export const IMPORTER_TEMPLATE_CREATE = 'IMPORTER/template/create';
export const IMPORTER_TEMPLATE_CREATE_SUCCESS = 'IMPORTER/template/create/success';
export const IMPORTER_TEMPLATE_CREATE_ERROR = 'IMPORTER/template/create/error';

/**
 * Dispatch action to get importer templates
 * @param {Object} params Params from component
 * @returns {Function} Action dispatch function
 */
export const getTemplates = (params = {}) => {
  return dispatch => {
    dispatch({
      type: IMPORTER_TEMPLATE_LIST_GET,
      data: params
    });
  };
};

/**
 * Dispatch action to create a new template
 * @param {Object} params Params from component
 * @returns {Function} Action dispatch function
 */
export const createTemplate = (params = {}) => {
  return dispatch => {
    dispatch({
      type: IMPORTER_TEMPLATE_CREATE,
      data: params
    });
  };
};

/**
 * Get importer templates from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* getTemplatesFromApi(action = {}) {
  try {
    const params = action.data;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/import/importer-templates/user-id/${params.userId}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = _.get(results.data, 'data.ImporterTemplate', []);

    yield put({ type: IMPORTER_TEMPLATE_LIST_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: IMPORTER_TEMPLATE_LIST_GET_ERROR, error });
  }
}

/**
 * Post new importer template to API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* postTemplateToApi(action = {}) {
  try {
    const params = action.data;
    const importerJob = yield select(state => state.importerJob);
    const importerJobData = importerJob.job;
    const config = {
      method: 'POST',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/importer/templates`,
      headers: {
        Authorization: `Bearer ${token()}`
      },
      data: {
        importType: importerJobData.importType,
        fieldMappingId: importerJobData.fieldMappingId,
        templateName: params.templateName
      }
    };

    const results = yield axios(config);
    const data = results.data.data;

    yield put({ type: IMPORTER_TEMPLATE_CREATE_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: IMPORTER_TEMPLATE_CREATE_ERROR, error });
  }
}

export function* importerTemplateSaga() {
  yield takeEvery(IMPORTER_TEMPLATE_LIST_GET, getTemplatesFromApi);
  yield takeEvery(IMPORTER_TEMPLATE_CREATE, postTemplateToApi);
}

export const importerTemplateReducer = (state = {}, action = {}) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case IMPORTER_TEMPLATE_LIST_GET_SUCCESS: {
      newState.templates = action.data;
      return newState;
    }
    case IMPORTER_TEMPLATE_CREATE_SUCCESS: {
      newState.templates = newState.templates || [];
      newState.templates.push(action.data);
      return newState;
    }
    case IMPORTER_TEMPLATE_CREATE_ERROR: {
      newState.error = action.error;
      return newState;
    }
    default: {
      return state;
    }
  }
};
