import _, { cloneDeep } from 'lodash-es';
import axios from './safeAxios';
import { extendedRequestConfig } from '~/utilities/auth.helper';
import { MEMBER_PROFILE_UPLOAD_LIMIT, DATE_FORMAT_INPUT } from '~/constants';
import { put, takeEvery } from 'redux-saga/effects';
import { generateTimestamp } from '~/utilities/timesAndDates';
import moment from 'moment';
import DownloadJs from 'downloadjs';

// list all uploads
export const LIST_MEMBER_UPLOADS = 'memberProfileUploads/LIST_MEMBER_UPLOADS';
export const LIST_MEMBER_UPLOADS_SUCCESS =
  'memberProfileUploads/LIST_MEMBER_UPLOADS_SUCCESS';
export const LIST_MEMBER_UPLOADS_ERROR = 'memberProfileUploads/LIST_MEMBER_UPLOADS_ERROR';

// crud
export const CREATE_MEMBER_UPLOAD = 'memberProfileUploads/CREATE_MEMBER_UPLOAD';
export const CREATE_MEMBER_UPLOAD_SUCCESS =
  'memberProfileUploads/CREATE_MEMBER_UPLOAD_SUCCESS';
export const CREATE_MEMBER_UPLOAD_ERROR =
  'memberProfileUploads/CREATE_MEMBER_UPLOAD_ERROR';
export const READ_MEMBER_UPLOAD = 'memberProfileUploads/READ_MEMBER_UPLOAD';
export const READ_MEMBER_UPLOAD_SUCCESS =
  'memberProfileUploads/READ_MEMBER_UPLOAD_SUCCESS';
export const READ_MEMBER_UPLOAD_ERROR = 'memberProfileUploads/READ_MEMBER_UPLOAD_ERROR';
export const UPDATE_MEMBER_UPLOAD = 'memberProfileUploads/UPDATE_MEMBER_UPLOAD';
export const UPDATE_MEMBER_UPLOAD_SUCCESS =
  'memberProfileUploads/UPDATE_MEMBER_UPLOAD_SUCCESS';
export const UPDATE_MEMBER_UPLOAD_ERROR =
  'memberProfileUploads/UPDATE_MEMBER_UPLOAD_ERROR';
export const DELETE_MEMBER_UPLOAD = 'memberProfileUploads/DELETE_MEMBER_UPLOAD';
export const DELETE_MEMBER_UPLOAD_SUCCESS =
  'memberProfileUploads/DELETE_MEMBER_UPLOAD_SUCCESS';
export const DELETE_MEMBER_UPLOAD_ERROR =
  'memberProfileUploads/DELETE_MEMBER_UPLOAD_ERROR';

// clear
export const CLEAR_ALL_DATA = 'memberProfileUploads/CLEAR_ALL_DATA';
export const CLEAR_STATUS_DATA = 'memberProfileUploads/CLEAR_STATUS_DATA';

// ACTION DISPATCHERS //

/**
 * removes all member data
 * @return {dispatch} - returns dispatch
 */
export const clearMemberUploads = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ALL_DATA
    });
  };
};

/**
 * removes success and error data
 * @return {dispatch} - returns dispatch
 */
export const clearStatusData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_STATUS_DATA
    });
  };
};

/**
 * dispatch for listing member uploads
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const listMemberUploads = params => {
  return dispatch => {
    return dispatch({
      type: LIST_MEMBER_UPLOADS,
      data: params
    });
  };
};

/**
 * retrieve a single member upload action dispatch
 * @param {object} params - parameters object
 * @param {integer} passengerId - passenger id
 * @param {string} fileType - file type
 * @return {dispatch} doesn't return anything but yield
 */
export const createMemberUpload = (params, passengerId) => {
  return dispatch => {
    return dispatch({
      type: CREATE_MEMBER_UPLOAD,
      data: params,
      passengerId
    });
  };
};

/**
 * retrieve a single member upload action dispatch
 * @param {string} fileId - file id string
 * @param {integer} passengerId - passenger id
 * @param {string} fileName - name of file
 * @return {dispatch} doesn't return anything but yield
 */
export const readMemberUpload = (fileId, passengerId, fileName) => {
  return dispatch => {
    return dispatch({
      type: READ_MEMBER_UPLOAD,
      fileId,
      passengerId,
      fileName
    });
  };
};

/**
 * update a single member upload action dispatch
 * @param {object} params - params
 * @param {integer} passengerId - passenger id
 * @param {string} fileId - file id string
 * @return {dispatch} doesn't return anything but yield
 */
export const updateMemberUpload = (params, passengerId, fileId) => {
  return dispatch => {
    return dispatch({
      type: UPDATE_MEMBER_UPLOAD,
      data: params,
      passengerId,
      fileId
    });
  };
};

/**
 * dispatch for deleting member uploads information
 * @param {integer} passengerId - passenger id
 * @param {string} fileId - file id string
 * @return {dispatch} doesn't return anything but yield
 */
export const deleteMemberUpload = (passengerId, fileId) => {
  return dispatch => {
    return dispatch({
      type: DELETE_MEMBER_UPLOAD,
      passengerId,
      fileId
    });
  };
};

// SAGAS //

/**
 * Saga function wrapper
 * @returns {undefined}
 */
export function* memberProfileUploadSaga() {
  yield takeEvery(LIST_MEMBER_UPLOADS, listMemberUploadsFromApi);
  yield takeEvery(CREATE_MEMBER_UPLOAD, createMemberUploadsFromApi);
  yield takeEvery(READ_MEMBER_UPLOAD, readMemberUploadsFromApi);
  yield takeEvery(UPDATE_MEMBER_UPLOAD, createMemberUploadsFromApi);
  yield takeEvery(DELETE_MEMBER_UPLOAD, deleteMemberUploadsFromApi);
}

/**
 * generator for getting list of member uploads
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* listMemberUploadsFromApi(action = {}) {
  const { data } = action;
  const { passengerId, offset, limit } = data;

  const config = extendedRequestConfig({
    method: 'GET',
    url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${passengerId}/custom-uploads?offset=${offset}&limit=${limit}`
  });

  try {
    const results = yield axios(config);
    const responseData = _.get(results, 'data.data', []);
    const timestamp = _.get(results, 'data.timestamp', {});
    if (_.get(results, 'data.status', false) === true) {
      yield put({
        type: LIST_MEMBER_UPLOADS_SUCCESS,
        data: responseData,
        timestamp,
        passengerId,
        message: 'You have successfully retrieved all of the files.',
        error: {}
      });
    } else {
      const errors = [_.get(results, 'data.message')];
      yield put({
        type: LIST_MEMBER_UPLOADS_ERROR,
        data: {
          errors
        }
      });
    }
  } catch (error) {
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error getting member profile.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }

    yield put({
      type: LIST_MEMBER_UPLOADS_ERROR,
      data: {
        errors
      }
    });
  }
}

/**
 * generator for creating and updating member profile upload
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* createMemberUploadsFromApi(action = {}) {
  const { data, type, passengerId } = action;

  const errorType =
    type === UPDATE_MEMBER_UPLOAD
      ? UPDATE_MEMBER_UPLOAD_ERROR
      : CREATE_MEMBER_UPLOAD_ERROR;
  const successType =
    type === UPDATE_MEMBER_UPLOAD
      ? UPDATE_MEMBER_UPLOAD_SUCCESS
      : CREATE_MEMBER_UPLOAD_SUCCESS;
  const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${passengerId}/custom-uploads`;

  const additionalConfigParams = {
    method: type === UPDATE_MEMBER_UPLOAD ? 'PATCH' : 'POST',
    url: type === UPDATE_MEMBER_UPLOAD ? `${url}/${action.fileId}` : url,
    data: data
  };

  const additionalHeaderParams = {
    ...(type === CREATE_MEMBER_UPLOAD && {
      'Content-Type': 'application/x-www-form-urlencoded'
    })
  };

  const config = extendedRequestConfig(additionalConfigParams, additionalHeaderParams);

  try {
    const results = yield axios(config);
    const responseData = _.get(results, 'data.data', []);
    let timestamp = _.get(results, 'data.timestamp', {});
    if (_.isEmpty(timestamp)) {
      timestamp = generateTimestamp();
    }
    if (_.get(results, 'data.status', false) === true) {
      yield put({
        type: successType,
        data: responseData,
        timestamp,
        passengerId,
        message: 'You have successfully uploaded the file.',
        error: {}
      });
      const listAction = {
        data: {
          passengerId,
          offset: 0,
          limit: MEMBER_PROFILE_UPLOAD_LIMIT
        }
      };
      yield* listMemberUploadsFromApi(listAction);
    } else {
      const error = [_.get(results, 'data.message')];
      yield put({
        type: errorType,
        error,
        data: {},
        message: 'Error saving file information.',
        timestamp
      });
    }
  } catch (error) {
    let errors = [];
    let message = '';
    let timestamp = _.get(error, 'data.timestamp', {});
    if (_.isEmpty(timestamp)) {
      timestamp = generateTimestamp();
    }
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error saving file information.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }
    if (Array.isArray(errors)) {
      message = errors.join(' ');
    } else {
      message = 'Error saving file information.';
    }
    yield put({
      type: errorType,
      error: errors,
      timestamp,
      message,
      data: {}
    });
  }
}

/**
 * generator for retrieving a single profile upload
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* readMemberUploadsFromApi(action = {}) {
  const { fileId, passengerId, fileName } = action;

  const config = extendedRequestConfig({
    method: 'GET',
    url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${passengerId}/custom-uploads/${fileId}`,
    responseType: 'blob' // binary download will not work without this line!
  });

  const timestamp = generateTimestamp();
  try {
    const results = yield axios(config);
    const status = _.get(results, 'status', 500);
    if (status === 200) {
      // retrieve blob
      yield put({
        type: READ_MEMBER_UPLOAD_SUCCESS,
        file: results.data,
        fileType: results.data.type,
        fileName,
        passengerId,
        timestamp,
        message: 'You have successfully downloaded the file.',
        error: {}
      });
    } else {
      const errors = [_.get(results, 'data.message')];
      yield put({
        type: READ_MEMBER_UPLOAD_ERROR,
        data: {},
        error: errors,
        message: 'File could not be read.',
        timestamp
      });
    }
  } catch (error) {
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error getting member profile.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }

    yield put({
      type: READ_MEMBER_UPLOAD_ERROR,
      data: {},
      error: errors,
      message: 'File could not be read.',
      timestamp
    });
  }
}

/**
 * generator for creating and updating member profile upload
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* deleteMemberUploadsFromApi(action = {}) {
  const { fileId, passengerId } = action;

  const config = extendedRequestConfig({
    method: 'DELETE',
    url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${passengerId}/custom-uploads/${fileId}`
  });

  try {
    const results = yield axios(config);
    const responseData = _.get(results, 'data.data', []);
    let timestamp = _.get(results, 'data.timestamp', {});
    if (_.isEmpty(timestamp)) {
      timestamp = generateTimestamp();
    }
    if (_.get(results, 'data.status', false) === true) {
      yield put({
        type: DELETE_MEMBER_UPLOAD_SUCCESS,
        data: responseData,
        timestamp,
        passengerId,
        message: 'You have successfully deleted the file.',
        error: {}
      });
      const listAction = {
        data: {
          passengerId,
          offset: 0,
          limit: MEMBER_PROFILE_UPLOAD_LIMIT
        }
      };
      yield* listMemberUploadsFromApi(listAction);
    } else {
      const errors = [_.get(results, 'data.message')];
      yield put({
        type: DELETE_MEMBER_UPLOAD_ERROR,
        data: {},
        error: errors,
        message: 'File could not be deleted',
        timestamp
      });
    }
  } catch (error) {
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error getting member profile.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }

    let timestamp = _.get(error, 'data.timestamp', {});
    if (_.isEmpty(timestamp)) {
      timestamp = generateTimestamp();
    }

    yield put({
      type: DELETE_MEMBER_UPLOAD_ERROR,
      error: errors,
      data: {},
      message: 'File could not be deleted',
      timestamp
    });
  }
}

// REDUCER //

/**
 * Initial shape of the member profile state
 * @param {*} state
 * @param {*} action
 */
const initialState = {
  inHttpRequest: false
};

/**
 * memberProfileUploadsReducer
 * @param {*} state previous or initial state
 * @param {*} action action dispatched
 * @returns {*} newState
 */
export const memberProfileUploadReducer = (state = initialState, action = {}) => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case CLEAR_ALL_DATA: {
      return {};
    }
    case CLEAR_STATUS_DATA: {
      _.unset(newState, 'results');
      return newState;
    }
    case CREATE_MEMBER_UPLOAD_SUCCESS:
    case READ_MEMBER_UPLOAD_SUCCESS:
    case UPDATE_MEMBER_UPLOAD_SUCCESS:
    case DELETE_MEMBER_UPLOAD_SUCCESS: {
      newState.results = action;
      newState.results.status = 'success';
      _.unset(newState, 'error');
      if (newState.results.type === READ_MEMBER_UPLOAD_SUCCESS) {
        DownloadJs(
          newState.results.file,
          newState.results.fileName,
          newState.results.fileType
        );
        newState.results.file = {};
      }
      return newState;
    }
    case LIST_MEMBER_UPLOADS_SUCCESS: {
      newState.list = {
        total: action.data.total,
        limit: parseInt(action.data.limit, 10),
        offset: parseInt(action.data.offset, 10),
        items: action.data.items,
        status: 'success',
        message: 'Retrieved list of files.'
      };
      newState.list.items = newState.list.items.map(fileData => {
        const exp = getExpiration(fileData.expiration);
        fileData.expiration = exp.expiration;
        fileData.expirationShow = exp.expirationShow;
        return fileData;
      });
      return newState;
    }
    case CREATE_MEMBER_UPLOAD_ERROR:
    case READ_MEMBER_UPLOAD_ERROR:
    case DELETE_MEMBER_UPLOAD_ERROR:
    case UPDATE_MEMBER_UPLOAD_ERROR: {
      newState.results = action;
      newState.results.status = 'error';
      return newState;
    }
    case LIST_MEMBER_UPLOADS_ERROR: {
      return (newState.list = {
        items: [],
        total: 0,
        status: 'error',
        message: 'Unable to retrieve list of files.'
      });
    }
    default: {
      return newState;
    }
  }
};

const getExpiration = expire => {
  let expiration = '';
  let expirationShow = '-';
  if (expire !== '0000-00-00 00:00:00' && !_.isNil(expire)) {
    expiration = expirationShow = moment(expire).format(DATE_FORMAT_INPUT);
  }
  return { expiration, expirationShow };
};
