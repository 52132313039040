import _ from 'lodash-es';
import React from 'react';
import moment from 'moment-timezone';
import Tooltip from '@material-ui/core/Tooltip';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import SvgPeople from '~/Shared/Components/Svgs/SvgPeople';
import SvgRefresh from '~/Shared/Components/Svgs/SvgRefresh';
import SvgChatIcon from '~/Shared/Components/Svgs/SvgChatIcon';
import SvgPassengerIcon from '~/Shared/Components/Svgs/SvgPassengerIcon';
import { addActiveRide } from '~/Modules/rideCards/rideCards.actions';
import { updateBookingData } from '~/Modules/bookingData';
import { PassengerSummary } from '~/Shared/Components/AdditionalPassengers/PassengerSummary';
import { bindActionCreators } from 'redux';
import {
  COMPLETED_OR_CANCELLED,
  LYFT_VEHICLE_ID,
  TIME_FORMAT_TIMEZONE
} from '~/constants';
import {
  rideCardStatus,
  formatPhoneNumber,
  generateRideDuration,
  isPastPublicRide,
  isRideShareVehicleId,
  getStartDate,
  getPickupTime
} from '~/utilities/helperFunctions';
import { fixUTCDate } from '~/utilities/timesAndDates';
import cx from 'classnames';
import ClaimedRideFooter from './ScheduleRideFooter/ClaimedRideFooter';
import { isSameDayMonitoringPageEnabled } from './ScheduledRide.utils';
import { isNil } from '~/utilities/guards';
import { getReBookingDataFromAPreviousRide } from '~/utilities/booking.helper';
import { getApprovedProviderDataForRide } from '~/services/rides.service';

const DISPATCH_MANAGED_TEXT =
  'No real-time alerts available. This ride has a driver internally scheduled but is being managed remotely by the dispatcher.';

class ScheduledRide extends React.Component {
  constructor(props) {
    super(props);
    this.rideCard = {};
  }

  componentDidUpdate() {
    // retrieve card offset if ride is rescheduled
    if (
      this.props.rideIdUpdatedDate &&
      this.props.ride.id === this.props.rideIdUpdatedDate
    ) {
      this.props.setOffset(this.rideCard.offsetTop);
    }
  }

  /**
   * Formats the appointmentTime for display, if present. Else
   * returns blank string
   * @returns {string|null} The formatted string
   */
  getArrivalTime = () => {
    const ride = this.props.ride;

    return ride.appointmentTime !== null
      ? fixUTCDate(ride.appointmentTime, ride.timezone_format, TIME_FORMAT_TIMEZONE)
      : null;
  };

  /**
   * Determines if ride is auto scheduled and returns flag
   * @returns {bool} Flag
   */
  isAutoScheduled = () => this.props?.chatRequest?.rideDetails?.isAutoScheduled ?? false;

  /**
   * Determines if the ride was booked by a member and returns msg to display if so
   * @returns {string|null} Flag
   */
  getBookedByMember = () =>
    this.props?.ride?.booking_method === 'member-portal' ? 'Booked By Member' : null;

  /**
   * Determines if patient should be called and returns text to be displayed
   * @param {object} rideStatus The ride status object
   * @returns {string|null} Returns text to be displayed if patient needs to be called
   */
  getCallPatient = rideStatus => {
    if (
      !isNil(rideStatus.cancelText) &&
      rideStatus.cancelText.indexOf('Patient was a no-show') > -1
    ) {
      return 'Call patient';
    }

    return null;
  };

  /**
   * Determines what string, if any, should be displayed as the cancellation reason
   * @param {object} rideStatus The ride status object
   * @returns {string|null} The reason for cancellation
   */
  getCancelReason = rideStatus => {
    if (rideStatus.statusClassName === 'cancelled' && !isNil(rideStatus.cancelText)) {
      return rideStatus.cancelText;
    }

    return null;
  };

  /**
   * Creates and formats the banner display text
   * @param {object} rideStatus The ride status object
   * @returns {string} A formatted string to display in the banner
   */
  getBannerStatus = rideStatus => {
    const startDate = getStartDate(this.props.ride);

    let bannerStatus = rideStatus.status.toUpperCase();
    bannerStatus =
      bannerStatus === 'WILL CALL' ? `${startDate} ${bannerStatus}` : bannerStatus;
    if (rideStatus.confirmationStatus !== '') {
      bannerStatus = rideStatus.confirmationStatus;
      if (bannerStatus === 'SCHEDULED - CONFIRMING NEMT') {
        bannerStatus = 'SCHED - CONFIRMING NEMT';
      }
    }

    if (
      this.getReorderId() &&
      (bannerStatus === 'CANCELLED' || bannerStatus === 'DRIVER CANCELLED')
    ) {
      bannerStatus = `${bannerStatus} (REORDERED)`;
    }

    return bannerStatus;
  };

  /**
   * Determines the drive name to display using ride data.
   * @returns {string} The driver name
   */
  getDriverName = () => {
    const ride = this.props.ride;
    const vehicleType = this.props.vehicleTypes[ride.reqVehicleType];

    let driverName = '';

    if (ride.modelName === 'Lyft') {
      driverName = 'Lyft';
    } else if (ride.modelName === 'Uber') {
      driverName = 'Uber';
    } else {
      if (!isNil(ride.driverFirstName)) {
        driverName = ride.driverFirstName;
      }
      const driverLastName =
        !isNil(ride.driverLastName) && ride.driverLastName !== ''
          ? ride.driverLastName.substring(0, 1)
          : '';
      if (!isNil(ride.driverLastName)) {
        driverName = `${driverName} ${driverLastName}.`.trim();
      }
      if (driverName === '') {
        if (ride.mode === 'Public') {
          driverName = 'PUBLIC';
        } else {
          driverName = 'NEMT';
        }
      }
    }
    return (driverName = `${driverName} [${_.get(vehicleType, 'nickName', '')}]`);
  };

  /**
   * Adjusts the edit text based on if ride is ondemand or not
   * @returns {string} The text to be displayed
   */
  getEditText = () =>
    this.props?.ride?.lyftBookingType === 'ondemand' ? 'View Details' : 'Edit';

  /**
   * Determines if the ride is for a flagged member
   * @returns {string|null} Text to be displayed if flagged
   */
  getFlaggedMsg = () =>
    this.props?.ride?.ride_options?.member_flag ? 'Flagged Member' : null;

  /**
   * Returns first letter of passenger's last name
   * @returns {string} The first letter of last name or empty string
   */
  getLastNameInitial = () => {
    const ride = this.props.ride;

    return ride.passengerLastname ? ride.passengerLastname.substr(0, 1) : '';
  };

  /**
   * Dermines if ride was reordered and returns formatted string if true
   * @param {string} bannerStatus The banner status
   * @returns {string|null} Formatted text or empty string;
   */
  getReorderAsText = bannerStatus =>
    bannerStatus.substring(bannerStatus.length - 11) === '(REORDERED)'
      ? 'Reordered as '
      : null;

  /**
   * Determines if a ride is a new iteration of a previous ride
   * @returns {string|null} The formatted text
   */
  getReorderFromId = () => this.props.ride?.original_ride_id ?? null;

  /**
   * Determines if ride is at risk of no-show and returns text
   * to display in true
   * @returns {string|null} Text to be displayed on no-show risk
   */
  getNoShowRisk = () => (this.props.ride?.noshow_flag === 1 ? '"No-Show" Risk' : null);

  /**
   * Determines if the ride is a past ride
   * @returns {string|null} Returns formatted message if true, null if false
   */
  getPastRide = () => (this.props.ride?.pastRide ? 'Past Ride Booking' : null);

  /**
   * Flag controlling if the chat element should render
   * @param {object} rideStatus The ride status
   * @returns {bool} T if status is not cancelled or completed
   */
  showChat = rideStatus => {
    if (
      _.isEmpty(this.props.chatRequest) ||
      rideStatus.statusClassName === 'cancelled' ||
      this.props.ride.status === 'Completed'
    ) {
      return false;
    }

    return true;
  };

  /**
   * Flag controlling if the delayed element should render
   * @param {object} rideStatus The ride status
   * @returns {bool} T if ride is delayed
   */
  showDelayed = rideStatus => {
    const ride = this.props.ride;

    let showDelayed = false;
    if (
      rideStatus.delayedTime !== '' &&
      ride.dispatcher_managed === 0 &&
      ride.mode !== 'Public'
    ) {
      showDelayed = true;
      if (ride.status === 'Started') {
        // b leg rides are not delayed ever
        if (ride.appointmentTime === '' || isNil(ride.appointmentTime)) {
          showDelayed = false;
        }
      }
    }

    return showDelayed;
  };

  /**
   * Flag controlling if the refresh element should render
   * @param {object} rideStatus The ride status
   * @param {string} reorderId The ride id of the reordered ride
   * @returns {bool} T if ride is cancelled
   */
  showRefresh = (rideStatus, reorderId) =>
    rideStatus.statusClassName === 'cancelled' && reorderId === null;

  /**
   * generates cancel ride popup
   * @return {undefined}
   */
  cancelRideHandler = () => this.props.cancelRide(this.props.ride);

  /**
   * you either edit ride or see ride details report based on ride status
   * @param {event} e - event
   * @return {undefined}
   */
  rideCallback = e => {
    if (e.target.parentNode.className === 'willCallSelect') {
      return false;
    }

    const isCanceledOrComplete = this.isCanceledOrComplete(this.props.ride);

    if (isCanceledOrComplete) {
      this.showRideDetails();
    } else if (!this.isRideDisabled()) {
      this.showEditRide();
    }
  };

  /**
   * Flag controlling if Cancel Ride button should be shown
   * @returns {bool} T/F
   */
  allowCancelRide = () => {
    const ride = this.props.ride;

    if (!this.isCanceledOrComplete() && !this.isRideDisabled()) {
      return true;
    }

    if (ride.rideStatus === 'Incomplete' && ride.mode !== 'Public') {
      return true;
    }

    if (isPastPublicRide({ ride })) {
      return true;
    }

    if (
      ride.isPastRide &&
      isRideShareVehicleId(ride.reqVehicleType) &&
      !this.isCanceledOrComplete()
    ) {
      return true;
    }

    return false;
  };

  /**
   * Handler to show ride details
   * @return {void}
   */
  showRideDetails = () => this.props.showRideDetails();

  /**
   * Handler to show ride details for the newer ride
   * @param {string} reorderId The id of the new ride
   * @returns {void}
   */
  showReorderedDetails = reorderId => this.props.showReorderedDetails(reorderId);

  /**
   * Handler to show edit ride and update redux with the active ride
   * @return {void}
   */
  showEditRide = () => {
    this.props.addActiveRide(this.props.ride.id, true);
    this.props.showEditRide(this.props.ride, this.rideCard.offsetTop);
  };

  /**
   * Handler to start the rebooking process for a cancelled ride
   * @return {void}
   */
  rebookCancelled = async () => {
    if (this.props.ride.confirmation_status === 'nemt_unable_to_fulfill') {
      this.props.openNemtReassign(this.props.ride);
    } else {
      let dateOfBirth = _.clone(this.props.ride.dateOfBirth);
      if (!isNil(dateOfBirth)) {
        dateOfBirth = moment(dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY');
      }

      // The Scheduled Rides page doesn't fetch the approved provider data on load,
      // so we need to manually fetch it here for the ride we want to rebook.
      const approvedProviderData = await getApprovedProviderDataForRide(
        this.props.ride.id
      );

      const bookingDataFromPreviousRide = getReBookingDataFromAPreviousRide(
        this.props.ride
      );
      const rideRebookingData = {
        ...bookingDataFromPreviousRide,
        ...approvedProviderData?.data
      };

      this.props.updateBookingData(rideRebookingData);

      this.props.rebookRide(
        this.props.ride.medicalId,
        this.props.ride.hospitalId,
        this.props.ride.id,
        this.props.ride.health_plan_id ?? null,
        dateOfBirth,
        false,
        this.props.ride.health_sub_plan_id ?? null,
        {},
        null,
        null,
        this.props.ride.passengerId
      );
    }
  };

  /**
   * Determines if a ride is cancelled/complete
   * @return {boolean} - True if ride status is completed or cancelled
   */
  isCanceledOrComplete = () => {
    const ride = this.props.ride;

    let isCanceledOrComplete = false;

    if (COMPLETED_OR_CANCELLED.indexOf(ride.status) > -1) {
      isCanceledOrComplete = true;
    }

    if (ride.pastRide) {
      isCanceledOrComplete = true;
    }

    if (ride.reqVehicleType === LYFT_VEHICLE_ID && ride.isPastRide) {
      isCanceledOrComplete = true;
    }

    return isCanceledOrComplete;
  };

  /**
   * Retrieves the reordered_ride_id from props.ride
   * @returns {string|null} Null if not found
   */
  getReorderId() {
    const reordered = this.props.ride?.reordered_ride_id ?? null;

    return !isNil(reordered) ? null : reordered;
  }

  /**
   * Overview - helper method to determine if we should display the edit button on the ride card
   * @returns {boolean} - boolean value to determine if we should display the edit button
   */
  allowEditRide = () => {
    const ride = this.props.ride;

    if (this.isRideDisabled()) {
      return false;
    }

    if (!this.isCanceledOrComplete()) {
      return true;
    }

    if (ride.rideStatus === 'Incomplete' && ride.mode !== 'Public') {
      return true;
    }

    if (ride.isPastRide && !isRideShareVehicleId(ride.reqVehicleType)) {
      return true;
    }

    return false;
  };

  /**
   * Overview - checks to see if the current ride is claimed, and if the claimed ride
   * is the current user on the monitoring page.
   * @returns {boolean} boolean value to determin if ride card is disabled
   */
  isRideDisabled = () => {
    const { page, ride, user } = this.props;
    const claimedByUserId = ride?.alertClaimByUser?.userId;
    const isRideClaimed = ride?.alertClaimStatus === 'Claimed';
    const currentUserId = user.userData.id;
    const isCurrentClaimedUser = currentUserId !== claimedByUserId;

    return [
      isSameDayMonitoringPageEnabled(page),
      isRideClaimed,
      isCurrentClaimedUser
    ].every(Boolean);
  };

  render() {
    const { ride, chatCount, selectChat } = this.props;
    const rideStatus = rideCardStatus(ride, true, 'ago');

    /** Values to be displayed */
    const driverName = this.getDriverName();
    const reorderId = this.getReorderId();
    const cancelReason = this.getCancelReason(rideStatus);
    const bannerStatus = this.getBannerStatus(rideStatus);

    /** Boolean flags to determine what should be rendered */
    const showChat = this.showChat(rideStatus);
    const showDelayed = this.showDelayed(rideStatus);
    const showRefresh = this.showRefresh(rideStatus, reorderId);

    const isPatientReady =
      moment().tz(this.props.user.userData.timezone_format).format('MM/DD') ===
      getStartDate(ride);
    return (
      <div
        className={cx('ScheduledRide clearfix', {
          disabled: this.isRideDisabled()
        })}
        ref={ride => (this.rideCard = ride)}
      >
        <div className="cardContainer">
          <div className="cardLeft">
            <div className="passengerData">
              <p className="name">
                <SvgPassengerIcon className="svgPassengerIcon" />
                {ride.passengerFirstname} {this.getLastNameInitial()}.
              </p>

              <ScheduledRidePhoneNum phoneNum={ride.pmobileNo} />

              <MessageBox className="fc-yellow fs-12" msg={this.getBookedByMember()} />
              <MessageBox className="noShow" msg={this.getNoShowRisk()} />

              <MessageBox className="callPatient" msg={this.getCallPatient(rideStatus)} />
              <MessageBox className="callPatient" msg={this.getFlaggedMsg()} />

              {ride.additionalPassengers && ride.additionalPassengers.length ? (
                <>
                  <Tooltip
                    className="ap-tooltip"
                    arrow={true}
                    title={
                      <PassengerSummary
                        hideAddBtn={true}
                        passengers={ride.additionalPassengers}
                      />
                    }
                    placement="top"
                    interactive={true}
                  >
                    <div>
                      <SvgPeople className="va-bottom" />
                      &nbsp;
                      <span className="fc-yellow fs-12">
                        {ride.additionalPassengers.length} Additional Passengers
                      </span>
                    </div>
                  </Tooltip>
                </>
              ) : null}

              <MessageBox msg={this.getPastRide()} />
            </div>
            <div className="arriveTime">
              <ScheduledRideArrivalTime arrivalTime={this.getArrivalTime()} />

              <ScheduledRideId rideId={ride.id} onShowDetails={this.showRideDetails} />

              <ScheduledRideCancelRide
                editText={this.getEditText()}
                allowCancel={this.allowCancelRide()}
                allowEdit={this.allowEditRide()}
                onEdit={this.showEditRide}
                onCancel={this.cancelRideHandler}
              />
            </div>
          </div>
          <div className="cardRight">
            <div className="driverData">
              <p className="name">{driverName}</p>

              <ScheduledRideReorderFrom id={this.getReorderFromId()} />

              <ScheduledRideReorderAsLink
                id={reorderId}
                text={this.getReorderAsText(bannerStatus)}
                onShowDetails={() => this.showReorderedDetails(reorderId)}
              />

              <ScheduledRideRefresh
                showRefresh={showRefresh}
                disableRefresh={this.isRideDisabled()}
                phoneNum={ride?.dmobileNo}
                pin={ride?.dmobilePin}
                text={rideStatus.reorderText}
                onClick={this.rebookCancelled}
              />

              <ScheduledRideDispatcherManaged
                isDispatchManaged={
                  ride.dispatcher_managed === 1 &&
                  !isRideShareVehicleId(ride.reqVehicleType)
                }
              />
            </div>

            <ScheduledRideShowChat
              displayChat={showChat}
              chatCount={chatCount}
              onClick={selectChat}
            />

            <ScheduleRideRefreshIcon
              showRefresh={showRefresh}
              disableRefresh={this.isRideDisabled()}
              onClick={this.rebookCancelled}
            />

            <div
              className={`banner ${ride.confirmation_status === 'nemt_unable_to_fulfill' ? 'nemtunable' : rideStatus.statusClassName}`}
              onClick={this.rideCallback}
            >
              <ScheduleRideBannerCont
                bannerStatus={bannerStatus}
                duration={generateRideDuration(ride)}
                rideStatus={rideStatus}
              />

              <ScheduledRideWillCall
                status={ride.status}
                pickupTime={getPickupTime(ride)}
                isWillCall={rideStatus.status === 'Will Call'}
                isPatientReady={isPatientReady}
                isAutoScheduled={this.isAutoScheduled()}
                onReschedule={this.rideCallback}
                onPatientReady={() => this.props.requestPatientPickup(ride)}
              />

              <div className="triangle"></div>
            </div>

            {showDelayed ? (
              <div className="delayedText">{rideStatus.delayedTime}</div>
            ) : null}
            <MessageBox msg={cancelReason} className="cancelReason" />
          </div>{' '}
        </div>
        {isSameDayMonitoringPageEnabled(this.props.page) ? (
          <ClaimedRideFooter ride={ride} />
        ) : null}
      </div>
    );
  }
}

/**
 * Displays a label indicating the ride has been reordered
 * from a previous ride
 * @param {string} props.id The ride id of the original ride
 * @returns {JSX.Element|null} The element to display
 */
function ScheduledRideReorderFrom({ id }) {
  if (!id) {
    return null;
  }

  return <p className="phoneNumber reorderId">Reordered from {id}</p>;
}

/**
 * Renders the lower half of the ride banner
 * @param {string} props.rideStatus The ride.status
 * @param {string} props.pickupTime The ride pickup time
 * @param {boolean} props.isWillCall T/F if ride is will call
 * @param {boolean} props.isPatientReady T/F if patient is ready
 * @param {boolean} props.isAutoScheduled T/F if the ride was autoscheduled
 * @param {function} props.onReschedule Callback to use if ride is will call and user reschedules
 * @param {function} props.onPatientReady Callback to use if ride is will call and patient is ready
 * @returns {JSX.Element|null} The element to display
 */
function ScheduledRideWillCall({
  rideStatus,
  pickupTime,
  isWillCall = false,
  isPatientReady = false,
  isAutoScheduled = false,
  onReschedule = () => {},
  onPatientReady = () => {}
}) {
  if (isWillCall) {
    let content = null;
    if (isPatientReady && isAutoScheduled) {
      content = <span className="willCallSelect">|Requesting...</span>;
    } else if (isPatientReady) {
      content = (
        <span className="willCallSelect">
          |<a onClick={onPatientReady}>Ready Now</a>
        </span>
      );
    }

    return (
      <p className="willCallText">
        <a onClick={onReschedule}>Reschedule</a>
        {content}
      </p>
    );
  } else {
    return (
      <p>
        Rec {rideStatus === 'Started' ? 'Arrival' : 'Pickup'} {pickupTime}
      </p>
    );
  }
}

/**
 * Renders the banner sub elements including ETA and banner status
 * @param {string} props.bannerStatus The status of the ride
 * @param {string} duration The estimated duration of the ride
 * @param {string} props.rideStatus The status of the ride
 * @returns {JSX.Element} The element to render
 */
function ScheduleRideBannerCont({ bannerStatus, duration, rideStatus }) {
  const { statusClassName: statusClass } = rideStatus;

  const eta = statusClass.includes('delayed') ? (
    <p>{duration}</p>
  ) : (
    <span> | ETA {duration}</span>
  );

  return (
    <div className="bannerCont">
      {bannerStatus}
      {duration ? <span>{eta}</span> : null}
    </div>
  );
}

/**
 * Conditionally renders the refresh/reorder SVG Icon
 * @param {boolean} props.showRefresh T/F to display element
 * @param {function} props.onClick The callback if the refresh icon is clicked by the user
 * @returns {JSX.Element|null} The element to render
 */
function ScheduleRideRefreshIcon({
  showRefresh = false,
  disableRefresh = false,
  onClick = () => {}
}) {
  const handleClick = () => {
    if (disableRefresh) {
      return;
    }

    onClick();
  };

  const className = disableRefresh ? 'svgRefreshDisabled' : 'svgRefresh';

  if (!showRefresh) {
    return null;
  }

  return (
    <a className="rebookRideLink" onClick={handleClick}>
      <SvgRefresh className={className} />
    </a>
  );
}

/**
 * Displays a modal with ride details
 * @param {string} props.rideId The ride id
 * @param {string} props.onShowDetails The callback if the ride id is clicked by the user
 * @returns {JSX.Element} The element to render
 */
function ScheduledRideId({ rideId, onShowDetails = () => {} }) {
  return (
    <p className="rideIdInfo">
      Ride ID:{' '}
      <a className="rideId" onClick={onShowDetails}>
        {rideId}
      </a>
    </p>
  );
}

/**
 * Displays the Edit and Cancel ride options
 * @param {boolean} props.allowCancel T/F to allow ride cancelling
 * @param {boolean} props.allowEdit T/F to allow ride editing
 * @param {function} props.onEdit Callback if user selects edit
 * @param {function} props.onCancel Callback if user selects cancel
 * @param {string} props.editText Text to display for edit button
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideCancelRide({
  allowCancel = false,
  allowEdit = false,
  onEdit = () => {},
  onCancel = () => {},
  editText = ''
}) {
  if (!allowEdit && !allowCancel) {
    return null;
  }

  const spacer = allowEdit && allowCancel ? ' | ' : '';

  return (
    <p className="cancelRide">
      {allowEdit ? <a onClick={onEdit}>{editText}</a> : null} {spacer}
      {allowCancel ? <a onClick={onCancel}>Cancel</a> : null}
    </p>
  );
}

/**
 * Selectively renders button to display the Show Chat button. Requires displayChat = true and chatCount > 0 to render
 * @param {boolean} props.displayChat T/F to display the chat icon
 * @param {number} props.chatCount Current number of available chats
 * @param {function} props.onClick Callback to execute if user selects the chat button
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideShowChat({ displayChat, chatCount, onClick = () => {} }) {
  if (!displayChat) {
    return null;
  }

  let content = null;
  if (chatCount > 0) {
    content = <div className="chatCount">{chatCount}</div>;
  }

  return (
    <span onClick={onClick}>
      <SvgChatIcon className="svgChatIcon" />
      {content}
    </span>
  );
}

/**
 * Selectively renders display to indicate that the ride is dispatch managed
 * @param {boolean} props.isDispatchManaged T/F to indicate if ride is dispatch managed
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideDispatcherManaged({ isDispatchManaged = false }) {
  if (!isDispatchManaged) {
    return null;
  }

  return (
    <p className="dispatchManaged">
      <a>
        Dispatcher Managed
        <span className="tooltiptext right">{DISPATCH_MANAGED_TEXT}</span>
      </a>
    </p>
  );
}

/**
 * Selectively renders the reorder element or displays the driver phone number
 * @param {boolean} props.showRefresh T/F to indicate if order can be reordered
 * @param {boolean} props.disableRefresh
 * @param {string|number} props.pin Optional pin to display with the Phone number
 * @param {string} props.phoneNum The passenger phone number
 * @param {string} props.text The text to display if reorder is allowed
 * @param {function} props.onClick The callback to execute if reorder is clicked
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideRefresh({
  showRefresh = false,
  disableRefresh = false,
  pin = false,
  phoneNum: _phoneNum,
  text,
  onClick = () => {}
}) {
  const phoneNum = _phoneNum ? `P: ${formatPhoneNumber(_phoneNum)}` : null;

  const handleClick = () => {
    if (disableRefresh) {
      return;
    }

    onClick();
  };

  const className = disableRefresh ? 'reorderDisabled' : 'reorder';

  if (!showRefresh) {
    if (phoneNum) {
      return (
        <p className="phoneNumber">
          {phoneNum}
          <ScheduledRidePhonePin pin={pin} />
        </p>
      );
    } else {
      return null;
    }
  }

  return (
    <p className="phoneNumber">
      {phoneNum}
      <ScheduledRidePhonePin pin={pin} />
      <span className={className} onClick={handleClick}>
        {text}
      </span>
    </p>
  );
}

function ScheduledRidePhonePin({ pin }) {
  if (!pin) {
    return null;
  }

  return (
    <>
      |{' '}
      <Tooltip
        disableFocusListener
        disableTouchListener
        title={pin}
        placement="top"
        arrow={true}
      >
        <span>PIN</span>
      </Tooltip>
    </>
  );
}

/**
 * Element to display the reorder link
 * @param {string} props.id The Ride id
 * @param {string} props.text The text to display
 * @param {function} props.onShowDetails The callback to execute if the user clicks the reordered as ID
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideReorderAsLink({ id, text, onShowDetails }) {
  if (!text) {
    return null;
  }

  return (
    <p className="phoneNumber reorderId">
      {text}
      <a onClick={onShowDetails} className="reorderLink">
        {id}
      </a>
    </p>
  );
}

/**
 * Element to display the Arrive By time
 * @param {string} props.arrivalTime The requested arrival time
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRideArrivalTime({ arrivalTime }) {
  let contents = null;

  if (arrivalTime) {
    contents = (
      <span>
        Arrive by <span className="eta">{arrivalTime}</span>
      </span>
    );
  }

  return <p className="arrivalInfo">{contents}</p>;
}

/**
 * Element to display the passenger phone number
 * @param {string} props.phoneNum The passenger phone number
 * @returns {JSX.Element|null} The element to render
 */
function ScheduledRidePhoneNum({ phoneNum }) {
  phoneNum = formatPhoneNumber(phoneNum);

  return <p className="phoneNumber">P: {phoneNum}</p>;
}

/**
 * Conditionally display an element as long as msg is truthy
 * @param {string} props.msg The message to display or null
 * @param {string} props.className The custom class name to apply
 * @returns {JSX.Element|null} Returns null if msg is falsey
 */
function MessageBox({ msg, className = '' }) {
  if (!msg) {
    return null;
  }

  return <p className={className}>{msg}</p>;
}

ScheduledRide.propTypes = {
  ride: PropTypes.object,
  chatCount: PropTypes.number,
  page: PropTypes.string,
  setOffset: PropTypes.func,
  medicalId: PropTypes.string,
  cancelRide: PropTypes.func,
  selectChat: PropTypes.func,
  rebookRide: PropTypes.func,
  hospitalId: PropTypes.number,
  chatRequest: PropTypes.object,
  showEditRide: PropTypes.func,
  vehicleTypes: PropTypes.object,
  showRideDetails: PropTypes.func,
  openNemtReassign: PropTypes.func,
  rideIdUpdatedDate: PropTypes.number,
  requestPatientPickup: PropTypes.func,
  showReorderedDetails: PropTypes.func,
  editRide: PropTypes.func,
  user: PropTypes.object
};

ScheduledRide.defaultProps = {
  ride: {},
  chatCount: 0,
  medicalId: null,
  page: '',
  setOffset: () => {},
  editRide: () => {},
  cancelRide: () => {},
  selectChat: () => {},
  rebookRide: () => {},
  hospitalId: null,
  chatRequest: {},
  showEditRide: () => {},
  vehicleTypes: {},
  addActiveRide: () => {},
  showRideDetails: () => {},
  openNemtReassign: () => {},
  rideIdUpdatedDate: 0,
  requestPatientPickup: () => {},
  showReorderedDetails: () => {},
  user: {}
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      addActiveRide: (rideId, addOrRemove) => addActiveRide(rideId, addOrRemove),
      updateBookingData: data => updateBookingData(data)
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(ScheduledRide);
