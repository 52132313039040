import {
  createModelFactory,
  createTypeGuard,
  Is,
  type ModelType
} from '@SRHealth/frontend-lib';
import type { PassengerInfoProps } from './PassengerInfo.model';

export type PassengerType = 'adult' | 'child';

export const isPassengerType = createTypeGuard((t: unknown): PassengerType | null => {
  if (t === 'adult' || t === 'child') return t;

  return null;
});

export type AdditionalPassenger = {
  type: PassengerType;
  isAttendant: boolean;
  firstName: string | undefined;
  lastName: string | undefined;
  transportType: PassengerInfoProps['transportType'];
  subTransportType?: PassengerInfoProps['subTransportType'];
};

export type AdditionalPassengerModel = ModelType<AdditionalPassenger>;

export const additionalPassengerFactory = createModelFactory<AdditionalPassengerModel>(
  {
    type: 'adult',
    isAttendant: false,
    firstName: undefined,
    lastName: undefined,
    transportType: undefined,
    subTransportType: undefined
  },
  {
    properties: [
      ['is-type', 'type', isPassengerType.strict],
      ['is-boolean', 'isAttendant', Is.Boolean.strict],
      ['is-string', 'firstName', v => Is.Undefined(v) || Is.String.strict(v)],
      ['is-string', 'lastName', v => Is.Undefined(v) || Is.String.strict(v)],
      ['is-string', 'transportType', Is.String.strict],
      ['type-is-valid', 'subTransportType', v => Is.Undefined(v) || Is.Number.strict(v)],

      /** Prevent a child attendant. */
      [
        'no-child-attendant',
        'type',
        (v, m) => {
          if (v === 'child' && m.isAttendant) m.isAttendant = false;

          return true;
        }
      ],
      /** Prevent accidentally selecting a sub transport type that isn't
       * valid for the selected transport type. */
      [
        'reset-sub-transport-type',
        'transportType',
        (v, m) => {
          if (v !== m.transportType && !Is.Undefined(m.subTransportType)) {
            m.subTransportType = undefined;
          }

          return true;
        }
      ]
    ],
    model: []
  }
);
