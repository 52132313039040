import { hasBecomeActive, rideBookingMiddleware } from '.';
import type { AppStore } from '~/types';
import type { AppListenerEffectAPI } from '~/Modules';
import { analyticsService } from '~/analytics';
import { completeRidesThunk, initializeRbfNewThunk } from '../thunks';

const EVENT_NAME = 'RBF Rides' as const;

let sectionTime = 0;
let startPoint = 0;

/** Reset the section time and start time to 0 when
 * loading new RBF. */
rideBookingMiddleware.startListening({
  actionCreator: initializeRbfNewThunk.fulfilled,
  effect: () => {
    sectionTime = 0;
    startPoint = 0;
  }
});

/** Starting State */
rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBecomeActive('rides', currentState, oldState),
  effect: (_, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    startPoint = Date.now();
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Started',
      TimeOnSection: sectionTime,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Completed State */
rideBookingMiddleware.startListening({
  actionCreator: completeRidesThunk.fulfilled,
  effect: (action, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;
    const rides = listenerApi.getState().rideBooking.rides;

    const lastRide = rides.at(-1);
    const returnRideAddress = action.meta.arg;

    sectionTime += Date.now() - startPoint;

    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Completed',
      TimeOnSection: sectionTime,
      ReturnTripAddressChange: returnRideAddress
        ? returnRideAddress !== lastRide?.departAddress.value
        : false,
      ...analyticsService.mapUserProps(user)
    });
  }
});
