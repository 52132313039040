import { ActionTypes } from './constants';
import { createActions } from 'redux-actions';

export const { 
  getTableData,
  filterTableData,
  exportTableData,
  setExportModal,
  setLoading
} = createActions({
  [ActionTypes.GET_TABLE_DATA]: data => data,
  [ActionTypes.FILTER_TABLE_DATA]: data => data,
  [ActionTypes.EXPORT_TABLE_DATA]: data => data,
  [ActionTypes.SET_EXPORT_MODAL]: modal => modal,
  [ActionTypes.SET_LOADING]: string => string
});