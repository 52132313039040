import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import LiveChatMessage from './LiveChatMessage';
import LiveChatSuggestion from './LiveChatSuggestion';
import LiveChatTextInput from './LiveChatTextInput';

const MESSAGE_TYPE = {
  NEMT_SUGGEST_RIDE: 'nemtSuggestRide',
  CARE_ACCEPT_SUGGESTION: 'careAcceptSuggestion',
  CARE_REJECT_SUGGESTION: 'careRejectSuggestion'
};

const REQUEST_STATUS = {
  ACTIVE: 'active'
};

class LiveChatConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedChat.messages.length !== this.props.selectedChat.messages.length
    ) {
      this.props.onNewMessage();
    }
    // keep scroll at bottom on state change (i.e. message update)
    if (this.scrollableContainer) {
      this.scrollableContainer.scrollTop = this.scrollableContainer.scrollHeight;
    }
  }

  /**
   * Determine whether to render the NEMT suggestion confirmation dialog
   * @param  {Object}  [message={}] Message
   * @return {Boolean}              true/false
   */
  hasSuggestionDisplay = (message = {}) => {
    const requestStatus = _.get(this.props, 'selectedChat.requestStatus', '');
    const messages = _.get(this.props, 'selectedChat.messages', []);
    const suggestedAppointmentTime = _.get(
      this.props,
      'selectedChat.rideDetails.suggestedAppointmentTime',
      ''
    );
    const suggestedPickupTime = _.get(
      this.props,
      'selectedChat.rideDetails.suggestedPickupTime',
      ''
    );
    let mostRecentId = 0;
    let mostRecentRejectId = 0;

    // return quickly if no suggested times found
    if (
      (!suggestedAppointmentTime || suggestedAppointmentTime === '') &&
      (!suggestedPickupTime || suggestedPickupTime === '')
    ) {
      return false;
    }

    // get most recent suggestion message ID
    // and most recent reject message ID
    messages.forEach(msg => {
      if (msg.type === MESSAGE_TYPE.NEMT_SUGGEST_RIDE && msg.id > mostRecentId) {
        mostRecentId = msg.id;
      }
      if (
        msg.type === MESSAGE_TYPE.CARE_REJECT_SUGGESTION &&
        msg.id > mostRecentRejectId
      ) {
        mostRecentRejectId = msg.id;
      }
    });

    // evaluate if message is right type, request is active, the request has
    // suggested times, the suggestion is the most recent suggestion and the
    // suggestion is after the most recent rejection
    if (
      message.type === MESSAGE_TYPE.NEMT_SUGGEST_RIDE &&
      requestStatus === REQUEST_STATUS.ACTIVE &&
      message.id === mostRecentId &&
      mostRecentId > mostRecentRejectId
    ) {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const messages = _.get(this.props, 'selectedChat.messages', []);

    return (
      <div className="liveChatConversation">
        <div
          className="liveChatMessageListWrapper"
          ref={el => (this.scrollableContainer = el)}
        >
          <div className="liveChatMessageList">
            {messages.map(message =>
              this.hasSuggestionDisplay(message) ? (
                <LiveChatSuggestion
                  key={message.id + '-s-' + (Math.random() * 1000).toString()}
                  selectedChat={this.props.selectedChat}
                  message={message}
                  handleConfirmRide={this.props.handleConfirmRide}
                  handleRejectRide={this.props.handleRejectRide}
                />
              ) : (
                <LiveChatMessage
                  key={message.id + '-m-' + (Math.random() * 1000).toString()}
                  user={this.props.user}
                  selectedChat={this.props.selectedChat}
                  message={message}
                />
              )
            )}
          </div>
        </div>
        <LiveChatTextInput
          selectedRequestId={this.props.selectedChat.requestId}
          sendMessage={this.props.sendMessage}
        />
      </div>
    );
  }
}

LiveChatConversation.propTypes = {
  user: PropTypes.object,
  selectedChat: PropTypes.object,
  sendMessage: PropTypes.func,
  handleConfirmRide: PropTypes.func,
  handleRejectRide: PropTypes.func
};

LiveChatConversation.defaultProps = {
  user: {},
  selectedChat: {},
  sendMessage: () => {},
  handleConfirmRide: () => {},
  handleRejectRide: () => {}
};

export default LiveChatConversation;
