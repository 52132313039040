import React from 'react';
import PropTypes from 'prop-types';

import SvgCheckbox from 'Common/Components/Svgs/SvgCheckbox';
import SvgCheckboxChecked from 'Common/Components/Svgs/SvgCheckboxChecked';

export default function Checkbox({checked}) {
  return checked 
    ? <SvgCheckboxChecked className="svgCheck" /> 
    : <SvgCheckbox className="svgCheck" />;
}

Checkbox.propTypes = {
  checked: PropTypes.bool
};

Checkbox.defaultProps = {
  checked: false
};