import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';

const LoadingComponent = props => {
  const { width, height, color } = props;
  return (
    <ReactLoading type='spin' color={color} width={width} height={height} className='spinner'/>
  );
};

LoadingComponent.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string
};

LoadingComponent.defaultProps = {
  width: '128px',
  height: '128px',
  color: '#0072D6'
};

export default LoadingComponent;
