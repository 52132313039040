import React from 'react';

const SvgExclamationOrange = props => {
  const { title, className } = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="7" height="22" viewBox="0 0 7 22">
      <title>{title}</title>
      <path fill="#FE6F00" fillRule="evenodd" d="M5.636 14H.758L0 0h6.394l-.758 14zM.061 19.182c0-1.05.308-1.788.924-2.212.616-.425 1.348-.637 2.197-.637.828 0 1.545.212 2.151.637.606.424.91 1.161.91 2.212 0 .99-.304 1.707-.91 2.151-.606.445-1.323.667-2.151.667-.849 0-1.581-.222-2.197-.667C.369 20.89.06 20.172.06 19.182z"/>
    </svg>
  );
};

export default SvgExclamationOrange;
