import React from 'react';
import { unset, isEqual, isEmpty, isNil } from 'lodash-es';
import MapInfoWindow from './MapInfoWindow';
import MapInfoWindowCar from './MapInfoWindowCar';
import SvgDriver from '../Svgs/SvgDriver';
import SvgDriverX from '../Svgs/SvgDriverX';
import { connect } from 'react-redux';
import type { Dispatch } from 'redux';
import { bindActionCreators } from 'redux';
import { MapboxMap, svgs } from 'mapbox-components';
import { getDirections, clearAll } from '~/Modules/mapbox';
import { getStartEndTime } from '~/utilities/timesAndDates';
import { capitalize } from '~/utilities/strings';
import { ACTIVE_RIDES, RIDE_MODE } from '~/constants';
import { getCurrentRideDataFromBookingData } from '~/utilities/editRide.helper';
import type { RideCardsRide } from '~/Modules/rideCards/rideCards.types';
import { ROUTES } from './Map.constants';

const { PUBLIC } = RIDE_MODE;

interface MapProps {
  activeRide: RideCardsRide; // comes from this.state.rideCards.scheduledRides
  bookingData: BookingDataStore;
  directions: any;
  isPublicRoutelessRides: any;
  clearAll: () => Dispatch;
  getDirections: (
    pickupLongitude: number,
    pickupLatitude: number,
    dropoffLongitude: number,
    dropoffLatitude: number
  ) => Dispatch;
}

/**
 * MapView Page
 * @extends React
 */
class Map extends React.Component<MapProps> {
  static defaultProps = {
    bookingData: {},
    getDirections: () => {},
    directions: {},
    clearAll: () => {},
    activeRide: {}
  };

  map: any;
  markers: Record<string, any>;
  routes: unknown[];
  polylineInbound: string;
  polylineStarted: string;
  polylineBookingRoute: string;
  booked: boolean;

  constructor(props) {
    super(props);
    this.map = {};
    this.markers = {};
    this.routes = [];
    this.polylineInbound = '';
    this.polylineStarted = '';
    this.polylineBookingRoute = '';
    this.booked = false;
  }

  /**
   * Get the in progress status of the currently selected ride (if any)
   */
  get isRideInProgress() {
    if (isEmpty(this.props.activeRide)) return false;

    const status = this.props.activeRide.status;
    return ACTIVE_RIDES.includes(status || '');
  }

  componentDidMount = () => {
    const { bookingData } = this.props;
    let markerSource: BookingDataStore | RideData;

    if (bookingData.bookingType === 'multileg') {
      if (bookingData?.currentRideIndex === undefined) return;
      markerSource = bookingData.rides?.[bookingData.currentRideIndex];
    } else {
      markerSource = bookingData;
    }

    if (!markerSource) return;

    const {
      pickupAddress,
      pickupLatitude,
      pickupLongitude,
      dropoffAddress,
      dropoffLatitude,
      dropoffLongitude
    } = markerSource;

    if (pickupLatitude && pickupLongitude) {
      this.createMarker(pickupLongitude, pickupLatitude, 'pickup', pickupAddress, '');
    }

    if (dropoffLatitude && dropoffLongitude) {
      this.createMarker(dropoffLongitude, dropoffLatitude, 'dropoff', dropoffAddress, '');
    }

    this.map.fitBoundsAll();
  };

  componentDidUpdate(prevProps) {
    const { directions, activeRide, bookingData } = this.props;

    if (!isEqual(bookingData.id, prevProps.bookingData?.id)) {
      this.clearMap();
    } else if (isEmpty(bookingData) && !isEmpty(prevProps?.bookingData)) {
      this.clearMap();
    }

    const bookingDataIsEmpty = Object.keys(bookingData).length <= 0;

    const rideData = getCurrentRideDataFromBookingData(bookingData);
    const prevRideData = getCurrentRideDataFromBookingData(prevProps?.bookingData);

    const activeRideDidChange = !isEqual(activeRide, prevProps.activeRide);
    const directionsDidChange = !isEqual(directions, prevProps.directions);
    const statusDidChange = bookingData?.status !== prevProps.bookingData?.status;
    const rideDataDidchange = !isEqual(rideData, prevRideData);

    // Check for empty booking data to prevent a slow zoom out
    // after clearing the map of markers
    if (!bookingDataIsEmpty) {
      if (rideDataDidchange) {
        this.handleRideDataUpdate(rideData, prevRideData);
      }

      if (statusDidChange) {
        this.handleStatusUpdate();
      }
    }

    if (directionsDidChange) {
      this.handleDirectionsUpdate();
    }

    // handle in progress and not in progress ride map updates
    if (
      this.isRideInProgress &&
      (activeRideDidChange || isEmpty(this.markers.driverMarker))
    ) {
      this.handleRideInProgressMapUpdate();
    } else if (!this.isRideInProgress && !isEmpty(activeRide)) {
      this.handleRideNotInProgressMapUpdate();
    }

    if (prevProps.activeRide && !activeRide) {
      const prevStatus = prevProps.activeRide?.status;

      if (prevStatus === 'Started' || prevStatus === 'Inbound') {
        const locations = prevProps?.activeRide ?? {};

        if (locations) {
          const routeLocationsLists = locations[prevProps.activeRide.status];
          if (routeLocationsLists && routeLocationsLists.length) {
            routeLocationsLists.map((_, i) =>
              this.removeMarker(`driverRouteMarker-${i}`)
            );
          }
        }
      }
    }

    if (Object.keys(this.markers).length > 0) {
      // Define max zoom level
      if (this.getZoom() > 18) {
        this.setZoom(18);
      }
    }
  }

  /**
   * Detects if the pickup or dropoff latitudes and longitudes
   * have been changed and redraws (or removes) the markers, if so.
   * @param {object} newRide
   * @param {object} oldRide
   * @returns
   */
  handleRideDataUpdate = (newRide, oldRide) => {
    if (
      newRide.pickupLatitude === oldRide?.pickupLatitude &&
      newRide.pickupLongitude === oldRide?.pickupLongitude &&
      newRide.dropoffLatitude === oldRide?.dropoffLatitude &&
      newRide.dropoffLongitude === oldRide?.dropoffLongitude
    ) {
      return;
    }

    const {
      pickupAddress,
      pickupLatitude,
      pickupLongitude,
      dropoffAddress,
      dropoffLatitude,
      dropoffLongitude
    } = newRide;

    const { startTime, endTime } = getStartEndTime(this.props.activeRide);

    if (
      pickupLatitude &&
      pickupLongitude &&
      (pickupLatitude !== oldRide?.pickupLatitude ||
        pickupLongitude !== oldRide?.pickupLongitude)
    ) {
      this.createMarker(
        pickupLongitude,
        pickupLatitude,
        'pickup',
        pickupAddress,
        startTime
      );
    } else if (this.markers?.pickupMarker && !pickupLatitude && !pickupLongitude) {
      this.removeMarker('pickupMarker');
    }

    if (
      dropoffLatitude &&
      dropoffLongitude &&
      (dropoffLatitude !== oldRide?.dropoffLatitude ||
        dropoffLongitude !== oldRide?.dropoffLongitude)
    ) {
      this.createMarker(
        dropoffLongitude,
        dropoffLatitude,
        'dropoff',
        dropoffAddress,
        endTime
      );
    } else if (this.markers?.dropoffMarker && !dropoffLatitude && !dropoffLongitude) {
      this.removeMarker('dropoffMarker');
    }
  };

  /**
   * Updates the directions using the pick and dropoff markers
   * as long as the bookignData is in a status other than "Incomplete"
   * @returns
   */
  handleStatusUpdate = () => {
    if (
      !this.props.bookingData?.status ||
      this.props.bookingData.status === 'Incomplete'
    ) {
      return;
    }

    const { lng: pickupLng, lat: pickupLat } = this.markers.pickupMarker.point;
    const { lng: dropoffLng, lat: dropoffLat } = this.markers.dropoffMarker.point;

    this.booked = true;
    this.getDirections(pickupLng, pickupLat, dropoffLng, dropoffLat);

    if (
      this.isRideInProgress &&
      !this.props.isPublicRoutelessRides &&
      this.props?.activeRide?.mode !== PUBLIC
    ) {
      this.createRoute(ROUTES.bookingRoute);
    }
  };

  /**
   * Handles an update to an in progress ride by updating the map accordingly.
   * Adds markers for the driver's past locations, updates the current driver car marker location,
   * and adjusts map bounds to fit the route polyline.
   */
  handleRideInProgressMapUpdate = () => {
    this.booked = false;

    const rideStatusLocations =
      this.props.activeRide.locations?.[`${this.props.activeRide.status}`];

    if (rideStatusLocations) {
      // Part 1: Append past ride location X markers to map
      for (let i = 0; i < rideStatusLocations.length - 1; i++) {
        // Because we're not looping over the last location in the array (which is the driver's current location)
        // all of the locations in this loop and SvgDriverX's on the map are previous driver locations
        const pastLocation = rideStatusLocations[i];
        this.map.markerRemove(`driverRouteMarker-${i}Marker`);

        const driverRouteMarker = {
          id: `driverRouteMarker-${i}Marker`,
          svg: <SvgDriverX />,
          point: {
            lng: pastLocation.longitude,
            lat: pastLocation.latitude
          }
        };
        this.map.markerAdd(driverRouteMarker);
        this.markers[`driverRouteMarker-${i}Marker`] = driverRouteMarker;
      }

      // Part 2: Append current driver location SVG car marker to map

      const latitude = rideStatusLocations.at(-1)?.latitude || 0;
      const longitude = rideStatusLocations.at(-1)?.longitude || 0;
      const heading = rideStatusLocations.at(-1)?.heading || 0;

      if (latitude !== 0 && longitude !== 0) {
        const svgCar = (
          <SvgDriver
            heading={heading}
            status={this.props.activeRide.status.toLowerCase()}
          />
        );

        // retrieve info window data
        const carInfoWindow = MapInfoWindowCar(this.props.activeRide);

        // create driver marker object
        const marker = {
          id: 'driverMarker',
          svg: svgCar,
          point: {
            lng: longitude,
            lat: latitude
          },
          popup: {
            html: carInfoWindow,
            toggleType: 'hover',
            maxWidth: 'none'
          }
        };

        if (isEmpty(this.markers.driverMarker)) {
          this.map.markerAdd(marker);
        } else {
          this.map.markerUpdate('driverMarker', marker);
        }
        this.markers.driverMarker = marker;
        this.map.fitBoundsAll();
      }
    }
  };

  /**
   * Handles an update to a not in progress ride (e.g. status is not Inbound', 'Arrived', 'Started')
   * by updating the map accordingly -- removing the driver marker and startedRoute polyline.
   * @param {object} rideData
   */
  handleRideNotInProgressMapUpdate = () => {
    this.removeMarker('driver');
    this.removePolyline('startedRoute');
  };

  /**
   * Creates and plots route assuming directions have
   * loaded without errors.
   */
  handleDirectionsUpdate = () => {
    if (this.props.isPublicRoutelessRides) {
      return;
    }

    if (this.props.directions?.error) {
      return;
    }

    if (isEmpty(this.props.directions.routes)) {
      return;
    }

    // if (this.props.bookingData.status === 'Incomplete') {
    //   return;
    // }

    if (this.props.activeRide?.mode === PUBLIC) {
      this.map.fitBoundsAll();
    } else {
      this.createRoute(ROUTES.bookingRoute);
    }
  };

  /**
   * Get the current map zoom
   * @returns {number}
   */
  getZoom = () => this.map.map.getZoom();

  /**
   * Set the map zoom scale. Zoom in with higher values
   * and zoom out with smaller values
   * @param {number} zoom
   * @returns
   */
  setZoom = zoom => this.map.map.setZoom(zoom);

  /**
   * create a marker
   * @param {double} longitude - long
   * @param {double} latitude - lat
   * @param {string} pickupOrDropoff - pickup or dropoff?
   * @param {string} address - pikcup or dropoff address
   * @param {string} time - pickup or dropoff time for already booked rides
   * @return {undefined}
   */
  createMarker(longitude, latitude, pickupOrDropoff, address, time = '') {
    this.map.markerRemove(`${pickupOrDropoff}Marker`);
    let svg = svgs.SvgMapPinRed({
      title: capitalize(pickupOrDropoff),
      className: 'marker'
    });
    if (pickupOrDropoff === 'pickup') {
      svg = svgs.SvgMapPinBlue({
        title: capitalize(pickupOrDropoff),
        className: 'marker'
      });
    }
    const marker = {
      id: `${pickupOrDropoff}Marker`,
      svg,
      point: {
        lng: parseFloat(longitude),
        lat: parseFloat(latitude)
      },
      popup: {
        html: MapInfoWindow(pickupOrDropoff, address, time),
        toggleType: 'hover',
        maxWidth: 'none'
      }
    };

    this.removePolyline('startedRoute');
    this.removePolyline('inboundRoute');
    this.removePolyline('bookingRoute');

    this.map.markerAdd(marker);
    this.markers[`${pickupOrDropoff}Marker`] = marker;

    this.map.fitBounds([
      [parseFloat(longitude), parseFloat(latitude)],
      [parseFloat(longitude), parseFloat(latitude)]
    ]);
    this.setZoom(18);
  }

  /**
   * Removes all current markers
   */
  clearAllMarkers() {
    Object.keys(this.markers).forEach(marker => {
      this.removeMarker(marker);
    });
  }

  /**
   * Remove all clearable items from map
   * @return {[type]} [description]
   */
  clearMap = () => {
    this.removePolyline('startedRoute');
    this.removePolyline('inboundRoute');
    this.removePolyline('bookingRoute');
    this.clearAllMarkers();
    this.props.clearAll();
  };

  /**
   * retrieves direction data route based on pickup and dropoff
   * @param  {double} pickupLongitude lng
   * @param  {double} pickupLatitude lat
   * @param  {double} dropoffLongitude lng
   * @param  {double} dropoffLatitude lat
   * @return {any} nothing returned or false returned
   */
  getDirections(pickupLongitude, pickupLatitude, dropoffLongitude, dropoffLatitude) {
    if (
      isNil(pickupLatitude) ||
      isNil(pickupLongitude) ||
      isNil(dropoffLatitude) ||
      isNil(dropoffLongitude)
    ) {
      return false;
    }

    this.props.getDirections(
      pickupLongitude,
      pickupLatitude,
      dropoffLongitude,
      dropoffLatitude
    );
  }

  /**
   * @param {object} lineParams - data for determining what line looks like
   * @param {string} outerLayerRoute - if there's a border layer
   * @return {undefined}
   */
  createRoute(lineParams) {
    try {
      const { routes } = this.props.directions;
      if (routes) {
        const coordinates = routes[0].geometry.coordinates;
        const { innerLayer, outerLayer } = lineParams;
        const polyParams = this.generatePolyLineParams(innerLayer, coordinates);
        if (!isEmpty(outerLayer)) {
          const outerPolyParams = this.generatePolyLineParams(outerLayer, coordinates);
          this.map.polylineRemove(outerLayer.id);
          this.map.polylineAdd(outerPolyParams);
        }
        this.map.polylineRemove(innerLayer.id);
        this.map.polylineAdd(polyParams);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Mapbox render error: ', error);
    }

    this.polylineBookingRoute = 'bookingPolyline';
    this.map.fitBoundsAll();
  }

  /**
   * generate paramaters for polyline
   * @param {object} layerParams - routeId for the layer
   * @param {array} coordinates - coordinates
   * @return {object} layer - returns a layer object
   */
  generatePolyLineParams(layerParams, coordinates = []) {
    const geoJsonData = {
      id: layerParams.id,
      paint: {
        'line-color': layerParams.color,
        'line-width': layerParams.width,
        'line-opacity': layerParams.opacity
      }
    };

    if (coordinates.length > 0) {
      geoJsonData['points'] = coordinates;
    }

    return geoJsonData;
  }

  /**
   * function for removing active ride polylines
   * @param {string} polylineType - type of polyline
   */
  removePolyline(polylineType) {
    if (polylineType === 'inboundRoute' && this.polylineInbound !== '') {
      this.polylineInbound = '';
      this.map.polylineRemove('inboundRoute');
    }
    if (polylineType === 'startedRoute' && this.polylineStarted !== '') {
      this.polylineStarted = '';
      this.map.polylineRemove('startedRoute');
      this.map.polylineRemove('startedRouteOuter');
    }
    if (polylineType === 'bookingRoute' && this.polylineBookingRoute !== '') {
      this.polylineBookingRoute = '';
      this.map.polylineRemove('bookingRoute');
      this.map.polylineRemove('bookingRouteOuter');
    }
  }

  /**
   * Removes a marker with the given name
   * @param {string} markerType
   */
  removeMarker(markerType) {
    const markerName = `${markerType}Marker`;
    const markerTarget = this.markers[markerName] ?? undefined;

    if (markerTarget) {
      this.map.markerRemove(markerName);
      unset(this.markers, markerName);
    }

    if (!isEmpty(this.markers[markerType])) {
      this.map.markerRemove(markerType);
      unset(this.markers, markerType);
    }
  }

  /**
   * Render the mapview
   * @return {JSX} [description]
   */
  render() {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <MapboxMap
          accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
          ref={map => (this.map = map)}
          controls={['navigation', 'scale', 'bounds']}
          className="mapboxMap"
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  bookingData: state.bookingData,
  directions: state.mapbox.directions
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getDirections: (
        pickupLongitude,
        pickupLatitude,
        dropoffLongitude,
        dropoffLatitude
      ) =>
        getDirections(pickupLongitude, pickupLatitude, dropoffLongitude, dropoffLatitude),
      clearAll: () => clearAll()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Map);
