const AppPages = {
  NEMT_ALERTS: 'ride-nemt-alerts',
  RIDESHARE_ALERTS: 'ride-share-alerts', 
  ALL: 'all'
};

const isSameDayMonitoringPageEnabled = (page = '') => [AppPages.RIDESHARE_ALERTS, AppPages.NEMT_ALERTS].includes(page);

const isRideCardDisabled = () => [isSameDayMonitoringPageEnabled()].every(Boolean);

export {
  AppPages, 
  isSameDayMonitoringPageEnabled, 
  isRideCardDisabled
};