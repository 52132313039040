//actions file
import _ from 'lodash-es';
import { baseRequestConfig } from '~/utilities/auth.helper';
import axios from './safeAxios';

/**
 * @param {object} params - params for hitting api
 * @return {string} - returns api
 */
const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/report`;

export const GET_RIDES = 'rideHistory/GET_RIDES';
export const GET_RIDES_PASSENGER = 'rideHistory/GET_RIDES_PASSENGER';
export const GET_ALL_RIDES_PASSENGER = 'rideHistory/GET_ALL_RIDES_PASSENGER';
export const CLEAR_RIDES = 'rideHistory/CLEAR_RIDES';
export const GET_ALL_RIDES = 'rideHistory/GET_ALL_RIDES';
export const GET_RIDES_ERROR = 'rideHistory/GET_RIDES_ERROR';
export const RESET_CSV = 'rideHistory/RESET_CSV';

/**
 * clear csv data after retrieving it
 * @return {dispatch} - dispatch for clearing csv data
 */
export const resetCsv = () => {
  return dispatch => {
    dispatch({
      type: RESET_CSV
    });
  };
};

/**
 * clear ride data after retrivieng it
 * @return {dispatch} - dispatch for clearing ride data
 */
export const clearRides = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_RIDES
    });
  };
};

/**
 * get rides based on start, limit and possible search parameter
 * @param {object} params - params for hitting the get rides api for ride history page
 * @return {dispatch} - dispatch for retrieving ride data
 */
export const getRides = params => {
  return dispatch => {
    return axios
      .post(url, params, baseRequestConfig())
      .then(response => {
        dispatch({
          type: GET_RIDES,
          params,
          data: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_RIDES_ERROR,
          params,
          error: error.response
        });
      });
  };
};

/**
 * get rides based on start, limit and possible search parameter
 * @param {object} params - params for hitting the get rides api for ride history page
 * @return {dispatch} - dispatch for retrieving ride data
 */
export const getRidesPassenger = params => {
  return dispatch => {
    const url = `${process.env.REACT_APP_API_URL}/passenger/rides/report`;
    return axios
      .post(url, { data: params }, baseRequestConfig())
      .then(response => {
        dispatch({
          type: GET_RIDES_PASSENGER,
          params,
          data: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_RIDES_ERROR,
          params,
          error: error.response
        });
      });
  };
};

/**
 * get all rides for csv download
 * @param {object} params - params for hitting the get rides api for ride history page
 * @return {dispatch} - dispatch for retrieving ride data
 */
export const getAllRidesPassenger = params => {
  return dispatch => {
    const url = `${process.env.REACT_APP_API_URL}/passenger/balance-history/report`;
    return axios
      .post(url, params, baseRequestConfig())
      .then(response => {
        dispatch({
          type: GET_ALL_RIDES_PASSENGER,
          data: response.data.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_RIDES_ERROR,
          error: error.response
        });
      });
  };
};

/**
 * get all of the rides, for csv download
 * @param {object} params - params for hitting the get rides api for ride history page
 * @return {dispatch} - dispatch for retrieving ride data
 */
export const getAllRides = params => {
  return dispatch => {
    return axios
      .post(url, params, baseRequestConfig())
      .then(response => {
        dispatch({
          type: GET_ALL_RIDES,
          data: response.data.data
        });
      })
      .catch(error => {
        dispatch({
          type: GET_RIDES_ERROR,
          error: error.response
        });
      });
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case GET_RIDES: {
      return {
        reportType: 'Ride History',
        rows: action.data.rows,
        maxRowsReturned: action.data.rowsReturned,
        begin: action.params.startLimit,
        rowsReturned: 50,
        totalCount: action.data.totalCount,
        params: action.params,
        timestamp: Math.floor(Date.now() / 1000)
      };
    }
    case GET_RIDES_PASSENGER: {
      const ridesPassenger = {
        reportType: 'Ride History',
        rows: action.data.rows,
        maxRowsReturned: action.data.rowsReturned,
        begin: action.params.startLimit,
        rowsReturned: 50,
        totalCount: action.data.totalCount,
        params: action.params,
        timestamp: Math.floor(Date.now() / 1000)
      };
      if ('csvRows' in state) {
        ridesPassenger.csvRows = state.csvRows;
      }
      return ridesPassenger;
    }
    case GET_ALL_RIDES_PASSENGER: {
      const headers = [];
      _.forEach(action.data.header, value => {
        headers.push(value);
      });
      const rows = action.data.rows.map(row => {
        const newRow = [];
        _.forEach(row, value => {
          newRow.push(value);
        });
        return newRow;
      });
      return {
        ...state,
        csvRows: rows,
        csvHeaders: headers
      };
    }
    case GET_RIDES_ERROR: {
      const newState = { ...state };

      newState.errorMessage = action?.error?.data?.error;
      newState.error = action?.error?.status;

      return newState;
    }

    case GET_ALL_RIDES: {
      return {
        ...state,
        csvRows: action.data.rows
      };
    }
    case RESET_CSV: {
      const newState = { ...state };
      newState.csvRows = [];
      return newState;
    }
    case CLEAR_RIDES: {
      return {};
    }
    default: {
      if ('rows' in state) {
        const newState = { ...state };
        newState.errorMessage = '';
        newState.error = {};
        return newState;
      }
      return state;
    }
  }
};
