import { createTypeGuard } from '@SRHealth/frontend-lib';
import {
  type AddressApprovedProvider,
  type AddressApprovedProviderModel,
  addressApprovedProviderFactory
} from './AddressApprovedProvider.model';
import {
  type AddressApprovedProviderNotFound,
  type AddressApprovedProviderNotFoundModel,
  addressApprovedProviderNotFoundFactory
} from './AddressApprovedProviderNotFound.model';
import {
  type AddressMapbox,
  type AddressMapboxModel,
  addressMapboxFactory
} from './AddressMapbox.model';
import {
  type AddressSaved,
  type AddressSavedModel,
  addressSavedFactory
} from './AddressSaved.model';
import {
  type AddressVenue,
  type AddressVenueModel,
  addressVenueFactory
} from './AddressVenue.model';

export type AddressModel =
  | AddressApprovedProviderModel
  | AddressApprovedProviderNotFoundModel
  | AddressMapboxModel
  | AddressSavedModel
  | AddressVenueModel;

export type AddressProps =
  | AddressApprovedProvider
  | AddressApprovedProviderNotFound
  | AddressMapbox
  | AddressSaved
  | AddressVenue;

export const isAddressModel = createTypeGuard((t): AddressModel | null => {
  if (
    addressMapboxFactory.createdModel(t) ||
    addressApprovedProviderFactory.createdModel(t) ||
    addressApprovedProviderNotFoundFactory.createdModel(t) ||
    addressSavedFactory.createdModel(t) ||
    addressVenueFactory.createdModel(t)
  ) {
    return t;
  }

  return null;
});

/** Factory to create an address model. This isn't strictly a model factory but
 * acts as a factory for multiple models. It routes the creation of the model
 * based on the "type" property of the initial properties. If no initial properties
 * are provided, it will default to creating a mapbox address. */

function addressFactory(
  initialProperties: Partial<AddressMapbox> | undefined
): AddressMapboxModel;
function addressFactory(
  initialProperties: Partial<AddressApprovedProvider> & {
    type: AddressApprovedProvider['type'];
  }
): AddressApprovedProviderModel;
function addressFactory(
  initialProperties: Partial<AddressApprovedProviderNotFound> & {
    type: AddressApprovedProviderNotFound['type'];
  }
): AddressApprovedProviderNotFoundModel;
function addressFactory(
  initialProperties: Partial<
    AddressApprovedProvider | AddressApprovedProviderNotFound
  > & {
    type: AddressApprovedProvider['type'] | AddressApprovedProviderNotFound['type'];
  }
): AddressApprovedProviderModel | AddressApprovedProviderNotFoundModel;
function addressFactory(
  initialProperties: Partial<AddressSaved> & {
    type: AddressSaved['type'];
  }
): AddressSavedModel;
function addressFactory(
  initialProperties: Partial<AddressVenue> & {
    type: AddressVenue['type'];
  }
): AddressVenueModel;
function addressFactory(initialProperties?: Partial<AddressProps>): AddressModel;
/**
 *
 */
function addressFactory<P extends Partial<AddressProps>>(
  initialProperties?: P
): AddressModel {
  switch (initialProperties?.type) {
    case 'approved-provider':
      return addressApprovedProviderFactory(initialProperties);
    case 'provider-not-found':
      return addressApprovedProviderNotFoundFactory(initialProperties);
    case 'saved':
      return addressSavedFactory(initialProperties);
    case 'venue':
      return addressVenueFactory(initialProperties);
    case 'mapbox':
    default:
      return addressMapboxFactory(initialProperties as Partial<AddressMapbox>);
  }
}

addressFactory.createdModel = isAddressModel;

export { addressFactory };
