import { useEffect, useState } from 'react';
import type { AddressProps } from '~/models';
import { useAppSelector } from '~/Modules';
import { selectHospitalCoords } from '~/Modules/user';
import { getMapboxAddressOptions, type MapboxFeature } from '~/services/mapbox.service';

type AddressOption = {
  label: string;
  value: AddressProps;
};

function genAddressMapboxOptions(
  mapboxAutoCompleteData: MapboxFeature[]
): AddressOption[] {
  return mapboxAutoCompleteData.map(feature => ({
    label: feature.place_name,
    value: {
      value: feature.place_name,
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      type: 'mapbox'
    }
  }));
}

export type MapboxSearchHook = [
  // Indicates if the search is currently in progress.
  boolean,
  // Array of mapbox address options for the dropdown.
  AddressOption[],
  // Event handler for mapbox input search.
  (inputId: number | undefined, search: string) => void
];

/** A webhook to add mapbox search functionality into a component. Accepts a
 * default address option and returns the search status, options, and search handler.
 *
 * The search status can be used to indicate if we are actively querying and awaiting
 * results for mapbox.
 *
 * The options and search handler are the current set of results and the callback
 * to use when performing a new query. There is no default debounce functionality
 * added to the callback so be sure to include it in your component. */
export default function useMapboxSearch(defaultOptions: AddressOption[] = []) {
  const [isSearching, setIsSearching] = useState(false);
  const [options, setOptions] = useState<AddressOption[]>(defaultOptions);
  const hospitalCoords = useAppSelector(state => selectHospitalCoords(state));

  /** Event handler for mapbox input search. */
  function handleMapboxInput(_, search: string) {
    // Minimum of 3 character search
    if (search.length < 3) return;

    setIsSearching(true);

    return getMapboxAddressOptions(search, hospitalCoords)
      .then(({ features }) => features && setOptions(genAddressMapboxOptions(features)))
      .then(() => setIsSearching(false));
  }

  useEffect(() => setOptions(defaultOptions), [defaultOptions]);

  return [isSearching, options, handleMapboxInput] as const;
}
