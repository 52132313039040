import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  GoogleMap,
  Icon,
  Input,
  Link,
  type LatLng,
  type MarkerData
} from '@SRHealth/frontend-lib';
import Divider from './Divider';
import { fetchGeocodeData } from '~/services/googleMaps.service';
import { getZipCodeFromGeocoderResult } from '~/utilities/googleMaps.helper';
import { createSavedLocationThunk } from '~/Modules/savedAddress/thunks/createSavedLocation.thunk';
import { useAppDispatch } from '~/Modules';
import MapPlaceholder from './MapPlaceholder';
import { useGoogleMapSearch } from '~/hooks/useGoogleMapSearch';
import { useSavedLocations } from './SavedLocations.context';
import type { MemberSavedAddress } from '~/services/savedAddress.service';

interface SavedLocationCreationDrawerBodyProps {
  savedLocations: MemberSavedAddress[];
}

const SavedLocationCreationDrawerBody = ({
  savedLocations
}: SavedLocationCreationDrawerBodyProps) => {
  const dispatch = useAppDispatch();
  const { autocompleteAddressOptions, handleFetchAutoComplete, isSearching } =
    useGoogleMapSearch();

  const { setSavedLocationsDrawerMode } = useSavedLocations();

  const [selectedPlaceId, setSelectedPlaceId] = useState<string | null>(null);
  const [markerData, setMarkerData] = useState<MarkerData[]>([]);

  const [emptyAddressError, setEmptyAddressError] = useState(false);
  const [emptyNameError, setEmptyNameError] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState<string | null>(null);
  const [nameErrorMessage, setNameErrorMessage] = useState<string | null>(null);

  const selectedLocationRef = useRef<LocalMemberSavedAddress>({
    name: '',
    address: '',
    zip: '',
    latitude: 0,
    longitude: 0,
    is_default: false
  });

  useEffect(() => {
    if (!selectedPlaceId) return;
    fetchGeocodeData({ placeId: selectedPlaceId }).then(({ results }) => {
      const record = results[0];

      Object.assign(selectedLocationRef.current, {
        address: record.formatted_address,
        zip: getZipCodeFromGeocoderResult(record),
        longitude: record.geometry.location.lng(),
        latitude: record.geometry.location.lat()
      });

      setMarkerData([
        {
          lat: selectedLocationRef.current.latitude,
          lng: selectedLocationRef.current.longitude,
          popupContent: 'Drag the Pin',
          preOpenedPopup: true,
          draggable: true
        }
      ]);
    });
  }, [selectedPlaceId]);

  function checkForDuplicates(
    name: string,
    address: string
  ): { isDuplicateName: boolean; isDuplicateAddress: boolean } {
    const isDuplicateName = savedLocations
      .map(({ name }) => name.toLowerCase())
      .includes(name.toLowerCase());

    if (isDuplicateName) {
      setNameErrorMessage('A saved location with this name already exists.');
    } else {
      setNameErrorMessage(null);
    }

    const isDuplicateAddress = savedLocations
      .map(({ address }) => address.toLowerCase())
      .includes(address.toLowerCase());

    if (isDuplicateAddress) {
      setAddressErrorMessage('This address is already saved.');
    } else {
      setAddressErrorMessage(null);
    }

    return {
      isDuplicateName,
      isDuplicateAddress
    };
  }

  function handleSave() {
    const { name, address } = selectedLocationRef.current;

    if (!name || !address) {
      if (!name) setEmptyNameError(true);
      if (!address) setEmptyAddressError(true);
      return;
    }

    const { isDuplicateName, isDuplicateAddress } = checkForDuplicates(name, address);

    if (isDuplicateName || isDuplicateAddress) {
      return;
    }

    dispatch(createSavedLocationThunk({ savedLocation: selectedLocationRef.current }));
    setSavedLocationsDrawerMode('list');
  }

  return (
    <div className="flex h-full">
      <div className="h-full bg-white" style={{ width: '460px' }}>
        <div style={{ padding: '32px 32px 16px' }}>
          <div
            className="flex justify-start items-end gap-[8px]"
            style={{ paddingBottom: '8px' }}
          >
            <Icon type="GooglePin" className="w-[32px] h-[32px]" />
            <div className="text-sm font-bold">Google Maps</div>
          </div>
          <Divider />
          <Input
            inputId={0}
            type="search-dropdown"
            label="Location"
            placeholder="Search address"
            onChange={(_, value: string) => {
              setSelectedPlaceId(value);
              setEmptyAddressError(false);
              setAddressErrorMessage(null);
            }}
            loading={isSearching}
            options={autocompleteAddressOptions}
            onSearch={(_, value) => handleFetchAutoComplete(value)}
            debounce={650}
            onInput={(_, value) => handleFetchAutoComplete(value)}
            name="location"
            style={{ margin: '8px 0 12px' }}
            error={emptyAddressError || !!addressErrorMessage}
            caption={addressErrorMessage || ''}
          />
          <Divider />
          <Input
            inputId={1}
            type="single"
            required
            label="Custom Name"
            placeholder="Name"
            onChange={(_, value: string) => {
              selectedLocationRef.current.name = value;
              setEmptyNameError(false);
              setNameErrorMessage(null);
            }}
            name="location"
            style={{ margin: '12px 0 8px' }}
            error={emptyNameError || !!nameErrorMessage}
            caption={nameErrorMessage || 'To save an address enter an identifiable name'}
          />
          <Divider />
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              alignItems: 'center',
              margin: '8px 0'
            }}
          >
            <div />
            <div style={{ justifySelf: 'center' }}>
              <Button label="Save" onClick={handleSave} size="sm" minWidth="124px" />
            </div>
            <div style={{ justifySelf: 'end' }}>
              <Link label="Cancel" onClick={() => setSavedLocationsDrawerMode('list')} />
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 h-full">
        {markerData.length ? (
          <GoogleMap
            apiKey={process.env.REACT_APP_GMAP_KEY ?? ''}
            pickupMarkers={markerData}
            onDragEnd={(latLng: LatLng) => {
              Object.assign(selectedLocationRef.current, {
                latitude: latLng.lat,
                longitude: latLng.lng
              });
            }}
            disableNavigation
            defaultZoom={17}
          />
        ) : (
          <MapPlaceholder />
        )}
      </div>
    </div>
  );
};

export default SavedLocationCreationDrawerBody;
