import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import moment from 'moment';
import {
  updateRideCheck,
  updateRide,
  resetCards,
  resetUpdatedCards,
  updateDateCheck,
  updateDate,
  addActiveRide
} from '~/Modules/rideCards/rideCards.actions';
import { clearBookingRide, updateBookingData } from '~/Modules/bookingData';
import { getRide } from '~/Modules/rideDetails';
import SvgClose from '../Svgs/SvgClose';
import PatientInfo from '../BookRides/FormComponents/PatientInfo';
import PatientInfoComplete from '../BookRides/DisplayComponents/PatientInfoComplete';
import TransportType from '../BookRides/FormComponents/TransportType';
import AppointmentTime from '../BookRides/FormComponents/AppointmentTime';
import TransportComplete from '../BookRides/DisplayComponents/TransportComplete';
import RideNotes from '../BookRides/FormComponents/RideNotes';
import RideNotesComplete from '../BookRides/DisplayComponents/RideNotesComplete';
import VehicleInfo from './Components/VehicleInfo';
import TripSummary from './Components/TripSummary';
import TripDetails from './Components/TripSummary/TripDetails';
import ErrorModal from '../ErrorModal';
import RepeatAppointments from './Components/RepeatAppointments';
import {
  statusNotifications,
  isNetworkRole,
  getHospitalCoords,
  rideCardStatus,
  generateRideDuration,
  isRideShareVehicleId
} from '~/utilities/helperFunctions';
import {
  getSubmittedData,
  getBookingDataFromScheduledRide,
  retrieveHealthPlanId
} from '~/utilities/editRide.helper';
import {
  COMPLETED_OR_CANCELLED,
  RIDESHARE_MOBILITY_ID_ARRAY,
  LYFT_VEHICLE_ID,
  NEMT_AMBULATORY_ID,
  UBER_VEHICLE_ID
} from '~/constants';
import LoadingModal from '../LoadingModal';
import ExtraFields from './Components/TripSummary/ExtraFields';
import { FundingSourceDropdown } from './Components/FundingSourceDropdown/FundingSourceDropdown';
import { getPatientInfoThunk, reset as resetPatient } from '~/Modules/patients';

const repeatingUpdateType = {
  RIDE_LOCATION_UPDATE: 'RIDE_LOCATION_UPDATE',
  TRANSPORT_TYPE_UPDATE: 'TRANSPORT_TYPE_UPDATE',
  PUBLIC_CARDS_UPDATE: 'PUBLIC_CARDS_UPDATE',
  THIS_RIDE: 0,
  THIS_AND_FOLLOWING_RIDES: 1
};

class EditRide extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    (this.state = {
      patientParams: {},
      patientCustomFields: [],

      roundTrip: false,
      isTransportFormBleg: false,

      // Show initial form components
      showPatientForm: false,
      showTransportForm: false,
      showAppointmentTime: false,
      showRideNotes: false,
      showErrorModal: false,
      showAllNotes: false,

      // Show completed form components
      showPatientInfoComplete: true,
      showTransportComplete: true,
      showRideNotesComplete: true,

      // Edit previously completed form components
      editPatientForm: false,
      editRideNotesForm: false,
      editAppointmentForm: false,

      missingFields: [],
      isOpenRepeatAppt: false,
      nemtToLyftRecurring: false,
      isOpenNemtToLyftConfirmation: false,

      // Error modal fields
      errorText: '',
      errorTitle: '',
      errorSubmitText: '',
      errorShowSubmit: false,
      hasMarkup: false,
      errorCallback: () => {},

      legIndex: 1,

      complianceInfo: _.cloneDeep(props.complianceInfo),
      complianceSelect: {}
    }),
    // these 3 properties are for when we submit to both date and ride endpoints simultaneously
    (this.submitTimeDataWithEditRide = false);

    this.paramsUpdate = {};
    this.paramsCheck = {};
  }

  /**
   * get ride data from props and assign to bookRides to enable editing
   * @returns {undefined} returns nothing
   */
  componentDidMount() {
    const ride = this.props.ride;

    const bookingData = this.handleComponentRender({
      updateTransport: true,
      updateRideDetails: true,
      updateMapBookingData: true
    });

    const patientParams = {
      medicalId: bookingData.medicalId ?? ride.medicalId,
      hospitalId: bookingData.hospitalId ?? ride.hospitalId,
      dateOfBirth: bookingData.dateOfBirth ?? ride.dateOfBirth
    };

    const healthPlanId = bookingData.healthPlanId ?? ride.healthPlanId;
    const healthSubPlanId = bookingData.healthSubPlanId ?? ride.healthSubPlanId;

    if (!_.isNil(healthPlanId)) {
      patientParams.healthPlanId = healthPlanId;
    }
    if (!_.isNil(healthSubPlanId)) {
      patientParams.healthSubPlanId = healthSubPlanId;
    }

    this.props.getPatientInfo(patientParams);
    this.getTripData();
  }

  /**
   * retrieve information for UPDATE_RIDE_CHECK
   * @param {object} prevProps - previous props
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const {
      availableVehicleTypes,
      error,
      ride,
      rideDetails,
      updateRideCheckData,
      updateRideData,
      updateDateCheckData,
      updateDateData
    } = this.props;

    const timestampDiff = this.props.timestampMicro - prevProps.timestampMicro;

    const updateTransport = !_.isEqual(
      prevProps.availableVehicleTypes,
      availableVehicleTypes
    );
    const updateRideDetails = !_.isEqual(prevProps.rideDetails, rideDetails);
    const updateMapBookingData =
      !_.isEqual(prevProps.ride, ride) &&
      (_.isEmpty(prevProps.ride) || prevProps.ride.id === ride.id);

    let showUpdateRideModal = false;
    let showUpdateDateModal = false;

    this.handleComponentRender({
      updateTransport,
      updateRideDetails,
      updateMapBookingData
    });

    ////////////////
    // EDIT RIDE  //
    ////////////////
    if (
      updateRideCheckData?.status === 'allow' &&
      updateRideCheckData.status !== prevProps.updateRideCheckData.status
    ) {
      this.editRideCallback();
      // eslint-disable-next-line eqeqeq
    } else if (updateRideCheckData?.status != undefined && timestampDiff) {
      showUpdateRideModal =
        updateRideCheckData.status !== prevProps?.updateRideCheckData?.status;
    } else if (updateRideData?.status === false && timestampDiff) {
      return this.openErrorModal({
        title: updateRideData?.alert_msg,
        text: updateRideData?.message
      });
    }

    ///////////////
    // EDIT DATE //
    ///////////////
    if (
      updateDateCheckData?.status === 'allow' &&
      updateDateCheckData?.status !== prevProps.updateDateCheckData.status
    ) {
      this.editDateCallback();
    } else if (
      updateDateCheckData?.status &&
      updateDateCheckData?.message &&
      timestampDiff
    ) {
      showUpdateDateModal =
        updateDateCheckData.status !== prevProps?.updateDateCheckData?.status;
    } else if (updateDateData?.status === false && timestampDiff) {
      return this.openErrorModal({ text: updateDateData?.message ?? 'Error' });
    }

    if (
      updateRideCheckData?.status === 'allow' &&
      updateRideCheckData.status !== prevProps.updateRideCheckData.status
    ) {
      this.setState({
        showRideNotes: false,
        showRideNotesComplete: true
      });
    }

    // Reset the state to display the completed ride info and send a notification
    if (
      this.state.showAppointmentTime &&
      ((updateRideData?.status &&
        updateRideData.status !== prevProps?.updateRideData?.status) ||
        (updateDateData?.status &&
          updateDateData.status !== prevProps?.updateDateData?.status))
    ) {
      statusNotifications(
        updateRideData?.message ?? 'Ride details updated successfully.',
        'success',
        200
      );
      this.resetComponents();
      document.querySelector('.showRideBookingForm.editRide').scrollTop = 0;
      return;
    }

    if (updateDateCheckData?.status === false && timestampDiff) {
      return this.openErrorModal({ text: updateDateCheckData?.message ?? 'Error' });
    }

    if (error?.errorStatus && timestampDiff) {
      return this.openErrorModal({
        text: `${this.props.error.errorStatus} ${this.props.error.errorMessage}`
      });
    }

    if (showUpdateRideModal || showUpdateDateModal) {
      return this.showUpdateModal(showUpdateRideModal, showUpdateDateModal);
    }
  }

  /**
   * Lifecycle function, clear booking data and patient data
   * @returns {undefined} returns nothing
   */
  componentWillUnmount() {
    this.props.clearBookingRide();
    this.props.resetCards();
    this.props.resetUpdatedCards();
    this.props.resetPatient();
  }

  /**
   * Whenever the component is first mounted or on update
   * the current logic is to push an update to the state store
   * to guarantee that it matches.
   *
   * This method provides more fine-grained control over what
   * portion of the bookingData we match on each update
   * @param {bool} param.updateTransport Update the selected transport in bookingData
   * @param {bool} param.updateRideDetails Update ALL ride details and provide default values if missing fields
   * @param {bool} param.updateMapBookingData Update the coordinates and health plan id (if applicable)
   * @returns {object} Returns the bookingData object used to update the redux state
   */
  handleComponentRender({
    updateTransport = false,
    updateRideDetails = false,
    updateMapBookingData = false
  }) {
    const { ride, rideDetails } = this.props;

    if (updateTransport || updateRideDetails || updateMapBookingData) {
      let bookData = {};

      if (updateRideDetails) {
        if (Object.keys(rideDetails).length > 0) {
          bookData = getBookingDataFromScheduledRide(rideDetails);
        } else {
          bookData = getBookingDataFromScheduledRide(ride);
        }

        bookData.isModal = this.props.isModal;
      }

      if (updateTransport) {
        const availableVehicleTypes = this.props.availableVehicleTypes;

        bookData.selectedTransport = availableVehicleTypes.find(
          veh => veh?.id === ride.reqVehicleType
        ) ?? { name: '' };
      }

      if (updateMapBookingData) {
        const isRole = isNetworkRole(this.props.userData.role);
        bookData.hospitalCoords = getHospitalCoords(
          ride.hospitalId,
          this.props.hospitalData,
          isRole
        );
        bookData.isModal = this.props.isModal;

        const healthPlanId = retrieveHealthPlanId(ride, this.props.user);
        if (healthPlanId) {
          bookData.healthPlanId = healthPlanId;
        }
      }

      this.props.updateBookingData(bookData);

      return bookData;
    }

    return {};
  }

  /**
   * @param {object} patientParams - params back from patient submission
   * @return {undefined}
   */
  handlePatientSubmit = patientParams => {
    const state = _.clone(this.state);
    _.assign(state, patientParams);
    state.showPatientForm = false;
    state.showPatientInfoComplete = true;
    state.editPatientForm = false;
    let patientCustomFields = [];

    if (
      this.props.userData.role === 'HospitalOwner' ||
      this.props.userData.role === 'HospitalNetworkManager'
    ) {
      patientCustomFields = this.props.patientCustomFields[state.hospitalId];
    } else {
      patientCustomFields = this.props.patientCustomFields;
    }

    state.patientCustomFields = patientCustomFields;
    this.setState(state);
    const submittedData = getSubmittedData(
      this.props.bookingData,
      this.props.ride,
      this.submitTimeDataWithEditRide,
      this.props.ride.recurring_rideId
    );

    this.submitRideCheck(submittedData);
  };

  appendHealthPlanDataToRequest(submittedData) {
    if (
      _.has(this.props.rideDetails, 'health_sub_plan_id') &&
      !_.isNull(this.props.rideDetails.health_sub_plan)
    ) {
      submittedData.healthSubPlanId = this.props.rideDetails.health_sub_plan_id;
      submittedData.healthPlanId = this.props.rideDetails.health_plan_id;
      submittedData.health_sub_plan_id = this.props.rideDetails.health_sub_plan_id;
      submittedData.health_plan_id = this.props.rideDetails.health_plan_id;
    }
  }

  updateAdditionalPassengerMobilityWhenEditingFromLyftToNotLyft(submittedData) {
    // if submitted data mobility type is not 8, check if any additionl passengers are 8
    // if so replace with 5

    if (!RIDESHARE_MOBILITY_ID_ARRAY.includes(submittedData.transport)) {
      submittedData.additionalPassengers.forEach(additionalPassenger => {
        if (RIDESHARE_MOBILITY_ID_ARRAY.includes(additionalPassenger.mobilityType)) {
          additionalPassenger.mobilityType = NEMT_AMBULATORY_ID;
          additionalPassenger.subMobilityType = null;
        }
      });
    }

    //if going from nemt -> Rideshare, update additional passengers to be new rideShare mobility type of ride
    if (RIDESHARE_MOBILITY_ID_ARRAY.includes(submittedData.transport)) {
      submittedData.additionalPassengers.forEach(additionalPassenger => {
        if (additionalPassenger.mobilityType !== submittedData.transport) {
          additionalPassenger.mobilityType = submittedData.transport;
          additionalPassenger.subMobilityType = null;
        }
      });
    }
  }

  /**
   * displays edit patient form
   * @return {undefined}
   */
  editPatientInfo = () => {
    const state = this.hideAllFormComponents();

    state.showPatientForm = true;
    state.showPatientInfoComplete = false;
    state.editPatientForm = true;
    state.showRideNotesComplete = true;
    state.showTransportComplete = true;

    this.setState(state);
  };

  /**
   * toggles display of patient notes between more and less
   * @return {undefined}
   */
  toggleNotes = () => {
    this.setState(prevState => ({
      showAllNotes: !prevState.showAllNotes
    }));
  };

  /**
   * hide transport form and show appointment time form
   * @return {undefined} - nothing returned
   */
  handleTransportationUpdate = () => {
    this.setState({
      showAppointmentTime: true,
      showTransportForm: true
    });
  };

  /**
   * update appointment location information
   * @param {object} bookingData - bookingData passed from TransportType and AppointmentTime component
   * @param {boolean} submitTimeDataWithEditRide - if we want to submit both time and appointmentInfo in one shot
   * @return {undefined}
   */
  updateAppointmentInfo = (bookingData, submitTimeDataWithEditRide = false) => {
    const { RIDE_LOCATION_UPDATE } = repeatingUpdateType;

    this.submitTimeDataWithEditRide = submitTimeDataWithEditRide;
    const submittedData = getSubmittedData(
      bookingData,
      this.props.ride,
      this.submitTimeDataWithEditRide,
      this.props.ride.recurring_rideId
    );

    if (this.containsRecurringRideId({ ride: this.props.ride })) {
      this.paramsCheck = {
        ...this.paramsCheck,
        ...submittedData
      };
      this.openRepeatAppt({ type: RIDE_LOCATION_UPDATE });
    } else {
      this.submitRideCheck(submittedData);
    }
  };

  /**
   * update appointment date and time
   * @param {object} bookingData - booking data from AppointmentTime component
   * @return {undefined}
   */
  updateDate = bookingData => {
    const rideType = this.props.ride.modelName === 'Lyft' ? 'lyft' : 'rides';
    const toOrFromCare = this.props.ride.appointmentTime === null ? 'fromcare' : 'tocare';
    if (rideType === 'rides') {
      this.paramsCheck = {
        rideId: this.props.ride.id,
        medicalId: this.props.ride.medicalId,
        hospitalId: this.props.ride.hospitalId,
        companyId: this.props.ride.vehicleOwnerId,
        vehicleTypeId: this.props.ride.reqVehicleType,
        status: this.props.ride.status,
        bookingType: toOrFromCare,
        pickupZipcode: bookingData.pickupZip,
        pickupAddress: bookingData.pickupAddress,
        pickupLatitude: this.props.ride.fromLatitude,
        pickupLongitude: this.props.ride.fromLongitude,
        dropoffZipcode: bookingData.dropoffZip,
        dropoffAddress: bookingData.dropoffAddress,
        dropoffLatitude: this.props.ride.toLatitude,
        dropoffLongitude: this.props.ride.toLongitude,
        datepick: bookingData.selectedDate,
        time: bookingData.apptTime,
        additionalNotes: '',
        transportType: this.props.ride.reqVehicleType,
        recurringid:
          this.props.ride.recurring_rideId === null
            ? 0
            : this.props.ride.recurring_rideId,
        rideApplyonall: 0,
        passengerTimezone: this.props.ride.passengerTimezone,
        rideType,
        extraTime: _.get(bookingData, 'extraTime', 0)
      };
      if (this.paramsCheck.time !== 'Will Call') {
        this.paramsCheck.newtime = moment(
          `${bookingData.selectedDate} ${bookingData.apptTime}`
        )
          .utc()
          .format('YYYY-MM-DD HH:mm:ss');
      }
    } else {
      this.paramsCheck = {
        rideId: this.props.ride.id,
        hospitalId: this.props.ride.hospitalId,
        status: this.props.ride.status,
        bookingType: toOrFromCare,
        pickupZipcode: bookingData.pickupZip,
        pickupAddress: bookingData.pickupAddress,
        pickupLatitude: this.props.ride.fromLatitude,
        pickupLongitude: this.props.ride.fromLongitude,
        dropoffZipcode: bookingData.dropoffZip,
        dropoffAddress: bookingData.dropoffAddress,
        dropoffLatitude: this.props.ride.toLatitude,
        dropoffLongitude: this.props.ride.toLongitude,
        datepick: bookingData.selectedDate,
        time: bookingData.apptTime,
        newtime:
          bookingData.apptTime === 'Will Call'
            ? 'Will Call'
            : moment(`${bookingData.selectedDate} ${bookingData.apptTime}`)
              .utc()
              .format('YYYY-MM-DD HH:mm:ss'),
        cost: this.props.ride.cost,
        passengerTimezone: this.props.ride.passengerTimezone,
        rideType,
        transportType: this.props.ride.reqVehicleType,
        extraTime: _.get(bookingData, 'extraTime', 0)
      };
    }

    this.paramsUpdate = {
      rideId: this.props.ride.id,
      // updated_time: newDate,
      additionalNotes: '',
      recurringid:
        this.props.ride.recurring_rideId === null ? 0 : this.props.ride.recurring_rideId,
      hospitalId: _.get(this.props, 'ride.hospitalId', 0),
      rideApplyonall: 0,
      rideType,
      extraTime: _.get(bookingData, 'extraTime', 0),
      isPastRide: this.props.ride.isPastRide,
      transportType: this.props.ride.reqVehicleType
    };
    if (_.isNil(this.paramsUpdate.extraTime)) {
      this.paramsUpdate.extraTime = 0;
    }

    if (bookingData.apptTime === 'Will Call') {
      this.paramsUpdate.scheduledTime = 'Will Call';
      this.paramsUpdate.scheduledDate = bookingData.selectedDate;
    } else {
      this.paramsUpdate.scheduledDate = bookingData.selectedDate;
      this.paramsUpdate.scheduledTime = bookingData.apptTime;
      if (
        _.get(this.props, 'rideData.status', '') === 'WillCall' &&
        rideType === 'rides' &&
        bookingData.selectedDate === moment().format('MM/DD/YYYY')
      ) {
        this.paramsUpdate.sendBenbot = true;
      }
    }

    this.submitTimeDataWithEditRide = false;

    if (
      _.has(this.props, 'ride.recurring_rideId') &&
      !_.isNil(this.props.ride.recurring_rideId) &&
      rideType === 'rides' &&
      !this.props.ride.isPastRide
    ) {
      this.openRepeatAppt({ type: '' });
    } else {
      this.submitDateCheck(this.paramsCheck);
    }
  };

  /**
   * edit transportation info, pickup and dropoff
   * if you need to update it
   * @return {undefined}
   */
  editTransportInfo = () => {
    const state = this.hideAllFormComponents();

    state.showTransportForm = true;
    state.showAppointmentTime = true;
    state.showTransportComplete = false;
    state.editAppointmentForm = true;
    state.showRideNotesComplete = true;
    state.showPatientInfoComplete = true;
    this.setState(state);
  };

  /**
   * if one way ride, take you to ride notes and custom fields form
   * @return {undefined}
   */
  oneWayTrip = () => {
    this.setState({
      showRideNotes: true
    });
  };

  /**
   * displays edit patient form
   * @return {undefined}
   */
  editRideNotesInfo = () => {
    const state = this.hideAllFormComponents();

    state.showRideNotes = true;
    state.showRideNotesComplete = false;
    state.editRideNotesForm = true;
    state.showPatientInfoComplete = true;
    state.showTransportComplete = true;

    this.setState(state);
  };

  /**
   * validate
   * @param {object} missingFields - missing fields
   * @param {string} missingFieldsError - error
   * @return {undefined}
   */
  transportTypeValidate = (missingFields, missingFieldsError = '') => {
    this.setState({ missingFields, missingFieldsError });
  };

  /**
   * @param {object} bookingData - booking data
   * @return {undefined}
   */
  handleEditNotes = bookingData => {
    const { TRANSPORT_TYPE_UPDATE } = repeatingUpdateType;
    const submittedData = getSubmittedData(
      bookingData,
      this.props.ride,
      this.submitTimeDataWithEditRide,
      this.props.ride.recurring_rideId
    );
    const isLyft =
      submittedData.transport === LYFT_VEHICLE_ID &&
      this.props.ride.reqVehicleType !== LYFT_VEHICLE_ID;
    const isUber =
      submittedData.transport === UBER_VEHICLE_ID &&
      this.props.ride.reqVehicleType !== UBER_VEHICLE_ID;

    if (
      this.containsRecurringRideId({ ride: this.props.ride }) &&
      submittedData.transport !== LYFT_VEHICLE_ID
    ) {
      this.paramsCheck = {
        ...this.paramsCheck,
        ...submittedData
      };
      this.openRepeatAppt({ type: TRANSPORT_TYPE_UPDATE });
    } else if (isLyft || isUber) {
      this.paramsCheck = { ...this.paramsCheck, ...submittedData };
      this.openNemtToLyftConfirmationModal({ type: TRANSPORT_TYPE_UPDATE });
    } else {
      this.submitRideCheck(submittedData);
    }
  };

  /**
   * open repeat appointment modal
   * if you edit ride time for a repeat appointment ride, this modal shows up
   * @return {undefined} - returns nothing
   */
  openRepeatAppt({ type = '', nemtToLyftRecurring = false }) {
    this.setState({
      isOpenRepeatAppt: true,
      nemtToLyftRecurring: nemtToLyftRecurring,
      type
    });
  }

  /**
   * @deprecated - 08/31/2023 Burt
   * open NEMT -> Lyft confirmation modal
   * if you edit ride nemt to Lyft, this confirmation modal shows up
   * @return {undefined} - returns nothing
   */
  openNemtToLyftConfirmationModal({ type = '' }) {
    this.setState({
      errorText:
        'You are about to switch the current Transport Provider to Rideshare. Please note that only ambulatory passengers can be serviced by Rideshare.',
      errorTitle: 'Warning',
      hasMarkup: true,
      status: this.props.ride.status,
      errorSubmitText: 'Proceed',
      errorShowSubmit: true,
      errorCallback: () => {
        this.bookAnywayRideCallback();
      },
      isOpenNemtToLyftConfirmation: true,
      type
    });
  }

  closeNemtToLyftConfirmationModal = () => {
    this.setState({
      isOpenNemtToLyftConfirmation: false
    });
  };

  /**
   * submits updating dates / locations for repeat appointments
   * @param {string} ids - all or 1 appointment
   *
   * @return {undefined} - returns nothing
   */
  submitRepeatAppt = ids => {
    const { type } = this.state;
    const { RIDE_LOCATION_UPDATE, TRANSPORT_TYPE_UPDATE } = repeatingUpdateType;
    if (ids === 'all') {
      this.paramsUpdate.ride_applyonall = 1;
      this.paramsCheck.ride_applyonall = 1;
    }

    if (ids === undefined) {
      this.paramsUpdate.ride_applyonall = 0;
      this.paramsCheck.ride_applyonall = 0;
    }

    if ([RIDE_LOCATION_UPDATE, TRANSPORT_TYPE_UPDATE].includes(type)) {
      this.submitRideCheck(this.paramsCheck);
    } else {
      this.submitDateCheck(this.paramsCheck);
    }
    this.closeRepeatAppt();
  };

  proceedToChangeNemtToLyft = () => {
    const { TRANSPORT_TYPE_UPDATE } = repeatingUpdateType;

    // if this has recurring rides, show that modal
    if (this.containsRecurringRideId({ ride: this.props.ride })) {
      this.openRepeatAppt({ type: TRANSPORT_TYPE_UPDATE, nemtToLyftRecurring: true });
    } else {
      this.submitRideCheck(this.paramsCheck);
    }
  };

  /**
   * close repeat appointment modal
   * @return {undefined} - returns nothing
   */
  closeRepeatAppt = () => {
    this.setState({
      isOpenRepeatAppt: false,
      type: null
    });
  };

  closeFormComponents = () => {
    const state = this.showAllCompletedComponents();
    this.setState(state);
  };

  /**
   * Checks if there's a recurring ride id key
   * @param {object} - ride object
   * @return {boolean} - if ride object contains `recurring_rideId` key
   */
  containsRecurringRideId = ({ ride }) => {
    return _.has(ride, 'recurring_rideId') && !_.isNil(ride.recurring_rideId);
  };

  bookDataTransport(ride) {
    let selectedTransport = _.find(this.props.availableVehicleTypes, {
      id: parseInt(ride.reqVehicleType, 10)
    });
    if (typeof selectedTransport === 'undefined') {
      selectedTransport = {
        name: ''
      };
    }
    const bookData = getBookingDataFromScheduledRide(ride, selectedTransport);
    return bookData;
  }

  /**
   * take scheduled ride data and map to bookingData for the booking components
   * Occurs during componentDidMount
   * @param {object} ride - ride data from scheduled rides
   * @return {object} - returns bookingData for the booking components
   */
  mapBookingData(ride) {
    const bookData = this.bookDataTransport(ride);
    const isRole = isNetworkRole(this.props.userData.role);
    bookData.hospitalCoords = getHospitalCoords(
      ride.hospitalId,
      this.props.hospitalData,
      isRole
    );

    const healthPlanId = retrieveHealthPlanId(ride, this.props.user);
    if (!_.isNil(healthPlanId)) {
      bookData.healthPlanId = healthPlanId;
    }

    return bookData;
  }

  /**
   * for submitting ride check
   * @param {object} params = parameters for checking ride update
   * @return {undefined}
   */
  submitRideCheck(params) {
    this.appendHealthPlanDataToRequest(params);
    this.updateAdditionalPassengerMobilityWhenEditingFromLyftToNotLyft(params);
    this.props.updateRideCheck(params);
  }

  /**
   * for submitting date check
   * @param {object} params = parameters for checking date update
   * @return {undefined}
   */
  submitDateCheck(params) {
    this.appendHealthPlanDataToRequest(params);

    // Rideshare rides don't support updating time through a separate
    // endpoint. They should all be passed via updateRideCheck to Lumen
    if (isRideShareVehicleId(params?.transportType)) {
      this.props.updateRideCheck(params);
    } else {
      this.props.updateDateCheck(params);
    }
  }

  /**
   * Causes a modal to pop up after following results of the editDateCheck
   * or editRideCheck api calls
   * @param {*} updateRide
   */
  showUpdateModal = (updateRide = false) => {
    const { updateRideCheckData, updateDateCheckData } = this.props;

    const status =
      updateRide && updateRideCheckData?.status
        ? updateRideCheckData.status
        : updateDateCheckData?.status;

    const newState = {
      showErrorModal: true,
      hasMarkup: Boolean(status),
      status,
      errorShowSubmit: false
    };

    if (!status && updateRide) {
      newState.errorText = updateRideCheckData?.alert_msg;
      newState.errorTitle = updateRideCheckData?.message;
    } else {
      newState.errorText =
        updateRide && updateRideCheckData?.message
          ? updateRideCheckData.message
          : updateDateCheckData?.message;
      newState.errorTitle = 'Update Ride Notice';
      newState.errorSubmitText = 'Book Anyway';
      newState.errorShowSubmit = status !== 'stop';
      newState.errorCallback = () => {
        const { updateRideCheckData, updateDateCheckData } = this.props;

        if (updateRideCheckData?.status && updateRideCheckData?.status !== 'allow') {
          this.editRideCallback();
        }

        if (updateDateCheckData?.status && updateDateCheckData?.status !== 'allow') {
          this.editDateCallback();
        }
      };
    }

    this.setState(newState);
  };

  /**
   * handles book anyway modal submission for edit ride
   * @returns {undefined} returns nothing
   */
  editRideCallback = () => {
    const { bookingData, ride, updateRideCheckData, updateRideCheckDataParams } =
      this.props;

    const submittedData = getSubmittedData(
      bookingData,
      ride,
      this.submitTimeDataWithEditRide,
      ride.recurring_rideId
    );

    if (this.containsRecurringRideId({ ride })) {
      submittedData.ride_applyonall =
        updateRideCheckDataParams?.ride_applyonall === 1
          ? repeatingUpdateType.THIS_AND_FOLLOWING_RIDES
          : repeatingUpdateType.THIS_RIDE;
    }

    if (updateRideCheckData?.rideTime) {
      submittedData.rideTime = updateRideCheckData.rideTime;
    }
    if (updateRideCheckData?.timeData) {
      submittedData.localPickupTime = updateRideCheckData.timeData?.localPickupTime;
      submittedData.rideTimeUTC = updateRideCheckData.timeData?.rideTimeUTC;
      submittedData.localStartTime = updateRideCheckData.timeData?.localStartTime;
      submittedData.distance = updateRideCheckData.timeData?.distance;
      submittedData.duration = updateRideCheckData.timeData?.duration;
      submittedData.eta = updateRideCheckData.timeData?.eta;
      submittedData.cost = updateRideCheckData.timeData?.cost;
    }

    this.appendHealthPlanDataToRequest(submittedData);
    this.updateAdditionalPassengerMobilityWhenEditingFromLyftToNotLyft(submittedData);
    this.props.updateRide(submittedData);
  };

  /**
   * handles book anyway modal submission for reschedule ride
   * @returns {undefined} returns nothing
   */
  editDateCallback = () => {
    const { updateDateCheckData, ride } = this.props;

    if (updateDateCheckData?.status === 'allow') {
      this.props.resetCards();
    }

    const rideType = this.paramsUpdate?.rideType;
    const newtime = this.paramsCheck?.newtime;

    this.appendHealthPlanDataToRequest(this.paramsUpdate);

    if ((rideType === 'lyft' || rideType === 'uber') && newtime !== 'Will Call') {
      this.paramsUpdate.recurringid = 0;
      this.paramsUpdate.rideTimeUTC = updateDateCheckData?.rideTimeUTC ?? '';
      this.paramsUpdate.rideTime = updateDateCheckData?.rideTime ?? '';
      this.paramsUpdate.localPickupTime = updateDateCheckData?.localPickupTime ?? '';
      this.paramsUpdate.cost = updateDateCheckData?.cost ?? 0;
      this.paramsUpdate.booking_type = updateDateCheckData?.booking_type ?? '';
      this.paramsUpdate.duration = updateDateCheckData?.duration ?? '';
      this.paramsUpdate.hospitalId = ride?.hospitalId ?? 0;

      this.props.updateRide(this.paramsUpdate);
    } else {
      this.props.updateDate(this.paramsUpdate);
    }
  };

  /**
   * Wrapper to simplifying generating error modals
   * @param {*} param0
   */
  openErrorModal = ({
    title = 'Error',
    text = 'Error',
    showSubmit = false,
    hasMarkup = false
  }) => {
    if (typeof title !== 'string') {
      title = 'Error';
    }

    if (typeof text !== 'string') {
      text = 'Error';
    }

    this.setState({
      showErrorModal: true,
      errorTitle: title,
      errorText: text,
      errorShowSubmit: showSubmit,
      hasMarkup
    });
  };

  closeErrorModal = () => {
    this.setState({ showErrorModal: false });
  };

  closeRequestNewRide = e => {
    e.preventDefault();
    this.props.addActiveRide(this.props.ride.id, false);
    this.props.closeRequestNewRide(true);
  };

  /**
   * grab trip data from scheduled rides and populate the data
   * @return {undefined}
   */
  getTripData() {
    const { ride } = this.props;
    const tripRides = ride.tripRides;
    const rideId = ride.id;

    if (!_.isNil(tripRides)) {
      let legIndex = 1;
      for (let i = 0; i < tripRides.length; i++) {
        if (tripRides[i].id === rideId) {
          legIndex = i + 1;
        }
      }
      this.setState({ legIndex });
    }
  }

  /**
   * Resets ride edit components to their "completed" states
   * @return {undefiend}
   */
  resetComponents() {
    this.closeFormComponents();
    this.props.resetCards();
  }

  /**
   * helper function, turn off all active form elements, this makes editing portions of the form more intuitive
   * @return {object} - returns json of form parameters
   */
  showAllCompletedComponents() {
    const state = this.hideAllFormComponents();
    const formState = {
      showPatientInfoComplete: true,
      showTransportComplete: true,
      showRideNotesComplete: true
    };
    return _.merge(state, formState);
  }

  /**
   * helper function, turn on all completed form components
   * @return {object} - returns json of form parameters
   */
  hideAllFormComponents() {
    return {
      showPatientForm: false,
      showTransportForm: false,
      showAppointmentTime: false,
      showRideNotes: false
    };
  }

  /**
   * get status for edit ride
   * @return {string} - return status
   */
  generateStatus() {
    const { ride } = this.props;
    const { status, confirmationStatus } = rideCardStatus(ride, true, 'ago');
    const statusDuration = generateRideDuration(ride);
    if (statusDuration !== '') {
      return `${status} | ETA ${statusDuration}`;
    }
    if (confirmationStatus !== '') {
      return confirmationStatus;
    }
    return status;
  }

  /**
   * get the correct disable edit status of components that modify
   * the 'bookingData' portion of the Redux state tree
   * @return {object} - returns boolean status for each component
   */
  getDisableEditStatusOfComponents() {
    let [disablePatientInfoEdit, disableTransportEdit, disableRideNotesEdit] = [
      false,
      false,
      false
    ];

    if (this.props.disableEdit === true) {
      disablePatientInfoEdit = disableTransportEdit = disableRideNotesEdit = true;
    } else if (this.state.showPatientForm === true) {
      disableTransportEdit = disableRideNotesEdit = true;
    } else if (this.state.showTransportForm === true) {
      disablePatientInfoEdit = disableRideNotesEdit = true;
    } else if (this.state.showRideNotes === true) {
      disablePatientInfoEdit = disableTransportEdit = true;
    }

    return {
      disablePatientInfoEdit,
      disableTransportEdit,
      disableRideNotesEdit
    };
  }

  render() {
    const userRole = this.props?.userData?.role ?? '';
    const status = this.generateStatus();
    const { disablePatientInfoEdit, disableTransportEdit, disableRideNotesEdit } =
      this.getDisableEditStatusOfComponents();

    return (
      <div className="showRideBookingForm editRide">
        <div className="bookingHead">
          <div className="headerLeft">
            <h1>Ride Details</h1>
            <p>Ride ID: {this.props.bookingData.id}</p>
          </div>
          <div className="close" onClick={this.closeRequestNewRide}>
            <span>Close</span>
            <SvgClose />
          </div>
        </div>
        <div className="bookingSubhed">
          <p className="status">{status}</p>
        </div>
        <div className="scrollDiv">
          {this.state.showPatientForm === true ? (
            <PatientInfo
              handlePatientSubmit={this.handlePatientSubmit}
              editForm={this.state.editPatientForm}
              complianceInfo={this.props.complianceInfo}
              editRide={true}
              editBookedRide={true}
              closeComponent={this.closeFormComponents}
              patientCustomFields={this.props.patientCustomFields}
              setComplianceInfo={this.props.setComplianceInfo}
              complianceSelect={this.props.complianceSelect}
            />
          ) : null}
          {this.state.showPatientInfoComplete === true ? (
            <PatientInfoComplete
              editPatientInfo={this.editPatientInfo}
              toggleNotes={this.toggleNotes}
              showAllNotes={this.state.showAllNotes}
              bookingData={this.props.bookingData}
              showDownArrow={this.state.showTransportComplete}
              patientCustomFields={this.props.patientCustomFields}
              userRole={userRole}
              disableEdit={disablePatientInfoEdit}
              status={this.props.ride.status}
              complianceInfo={this.props.complianceInfo}
            />
          ) : null}
          {/* funding source dropdown */}
          <FundingSourceDropdown user={this.props.user} ride={this.props.ride} />
          {this.state.showTransportForm === true ? (
            <TransportType
              vehicleTypes={this.props.availableVehicleTypes}
              rideDetails={this.state.rideData}
              updateRide={this.handleTransportationUpdate}
              isTransportFormBleg={this.state.isTransportFormBleg}
              transportTypeValidate={this.transportTypeValidate}
              missingFields={this.state.missingFields}
              missingFieldsError={this.state.missingFieldsError}
              editRide={true}
              editBookedRide={true}
              closeComponent={this.closeFormComponents}
              toggleLoading={this.props.toggleLoading}
            />
          ) : null}
          {this.state.showAppointmentTime === true ? (
            <AppointmentTime
              updateAppointmentInfo={this.updateAppointmentInfo}
              isTransportFormBleg={this.state.isTransportFormBleg}
              editForm={this.state.editAppointmentForm}
              roundTrip={false}
              transportTypeValidate={this.transportTypeValidate}
              editRide={true}
              updateDate={this.updateDate}
              savedRideData={this.props.ride}
              closeAppointmentForm={this.closeAppointmentInfo}
              editBookedRide={true}
              toggleLoading={this.props.toggleLoading}
            />
          ) : null}
          {/* sending individual properties since we can have multiple <transportcomplete> components */}
          {this.state.showTransportComplete === true ? (
            <TransportComplete
              bookingData={this.props.bookingData}
              editTransportInfo={this.editTransportInfo}
              tripNumber={this.state.legIndex}
              recommendedPickup={this.props.bookingData?.recommendedPickup ?? ''}
              userRole={userRole}
              ride={this.props.ride}
              timeCustomFields={this.props.timeCustomFields}
              disableEdit={disableTransportEdit}
              extraTime={parseInt(this.props?.bookingData?.extraTime ?? 0)}
              status={this.props.ride.status}
            />
          ) : null}
          {this.state.showRideNotes === true ? (
            <RideNotes
              requestRide={this.requestRide}
              hideArrow={this.state.showAppointmentTime}
              handleRequestBooking={this.handleRequestBooking}
              handleEditNotes={this.handleEditNotes}
              editRideNotesForm={this.state.editRideNotesForm}
              editRide={true}
              addColumn={this.props.addColumn}
              editBookedRide={true}
              closeComponent={this.closeFormComponents}
              vehicleTypes={this.props.availableVehicleTypes}
              ride={this.props.ride}
            />
          ) : null}
          {this.state.showRideNotesComplete === true ? (
            <RideNotesComplete
              editRideNotesInfo={this.editRideNotesInfo}
              toggleNotes={this.toggleNotes}
              showAllNotes={this.state.showAllNotes}
              bookingData={this.props.bookingData}
              timeCustomFields={this.props.timeCustomFields}
              rideCustomFields={this.props.rideCustomFields}
              userRole={userRole}
              ride={this.props.ride}
              disableEdit={disableRideNotesEdit}
              status={this.props.ride.status}
              rideDetails={this.props.rideDetails}
            />
          ) : null}
          <VehicleInfo
            ride={this.props.ride}
            isModal={this.props.isModal}
            bookingData={this.props.bookingData}
            rideDetails={this.props.rideDetails}
            showCost={this.props.showCost}
          />
          <div>
            <TripSummary
              vehicleTypes={this.props.availableVehicleTypes}
              rideDetails={this.props.rideDetails}
              ride={this.props.ride}
              dataToggles={this.props.dataToggles}
              user={this.props.user}
            />
            {this.props.ride.modelName !== 'Lyft' ||
            COMPLETED_OR_CANCELLED.indexOf(this.props.ride.status) === -1 ? (
                <TripDetails rideDetails={this.props.rideDetails} />
              ) : null}
          </div>
          <RepeatAppointments
            heading="Reschedule Recurring Ride"
            action="updated"
            isOpen={this.state.isOpenRepeatAppt}
            closeModal={this.closeRepeatAppt}
            cancelRide={this.submitRepeatAppt}
            nemtToLyftRecurring={this.state.nemtToLyftRecurring}
          />
          {!this.props.isModal && this.props.extraFields ? (
            <ExtraFields extraFields={this.props.extraFields} />
          ) : null}
          {this.props.loadingEditRide ? (
            <LoadingModal
              isOpen={this.props.loadingEditRide}
              label={'Loading...'}
              closeTimeout={420000}
            />
          ) : null}
          {this.state.showErrorModal ? (
            <ErrorModal
              closeModal={this.closeErrorModal}
              isOpen={this.state.showErrorModal}
              modalContent={this.state.errorText}
              title={this.state.errorTitle}
              hasMarkup={this.state.hasMarkup}
              submitText={this.state.errorSubmitText}
              showSubmit={this.state.errorShowSubmit}
              callback={this.state.errorCallback}
            />
          ) : null}
          {this.state.isOpenNemtToLyftConfirmation ? (
            <ErrorModal
              closeModal={this.closeNemtToLyftConfirmationModal}
              isOpen={this.state.isOpenNemtToLyftConfirmation}
              modalContent={this.state.errorText}
              title={this.state.errorTitle}
              hasMarkup={this.state.hasMarkup}
              submitText={this.state.errorSubmitText}
              showSubmit={this.state.errorShowSubmit}
              callback={this.proceedToChangeNemtToLyft}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

EditRide.defaultProps = {
  updateDateCheck: () => {},
  updateDate: () => {},
  updateRideCheck: () => {},
  updateRide: () => {},
  resetCards: () => {},
  resetUpdatedCards: () => {},

  availableVehicleTypes: [],
  hospitalData: {},
  patientCustomFields: [],
  timeCustomFields: [],
  rideCustomFields: [],
  vehicleTypes: [],

  closeRequestNewRide: () => {},
  updateBookingData: () => {},
  clearBookingRide: () => {},

  getPatientInfo: () => {},
  resetPatient: () => {},

  ride: {},
  bookingData: {},
  rideDetails: {},

  updateRideData: {},
  updateRideCheckData: {},
  updateRideCheckDataParams: {},
  updateDateData: {},
  updateDateCheckData: {},

  page: 'scheduled',
  start: 0,
  limit: 50,
  timestampMicro: 0,
  user: {},
  error: {},
  loadingEditRide: false,

  disableEdit: false,
  isModal: false,
  dataToggles: {},
  extraFields: {},
  addActiveRide: () => {},
  showCost: true,
  toggleLoading: () => {},
  complianceInfo: [],
  complianceSelect: {},
  setComplianceInfo: () => {}
};

EditRide.propTypes = {
  updateDateCheck: PropTypes.func,
  updateDate: PropTypes.func,
  updateRideCheck: PropTypes.func,
  updateRide: PropTypes.func,
  resetCards: PropTypes.func,
  resetUpdatedCards: PropTypes.func,
  loadingEditRide: PropTypes.bool,

  availableVehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  patientCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  timeCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  vehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  closeRequestNewRide: PropTypes.func,
  updateBookingData: PropTypes.func,
  clearBookingRide: PropTypes.func,

  getPatientInfo: PropTypes.func,
  resetPatient: PropTypes.func,

  ride: PropTypes.object,
  rideDetails: PropTypes.object,
  bookingData: PropTypes.object,

  updateRideData: PropTypes.object,
  updateRideCheckData: PropTypes.object,
  updateRideCheckDataParams: PropTypes.object,
  updateDateData: PropTypes.object,
  updateDateCheckData: PropTypes.object,

  page: PropTypes.string,
  start: PropTypes.number,
  limit: PropTypes.number,
  timestampMicro: PropTypes.number,
  user: PropTypes.object,
  error: PropTypes.object,

  disableEdit: PropTypes.bool,
  isModal: PropTypes.bool,
  dataToggles: PropTypes.object,
  extraFields: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  addActiveRide: PropTypes.func,
  showCost: PropTypes.bool,
  toggleLoading: PropTypes.func,
  complianceInfo: PropTypes.array,
  complianceSelect: PropTypes.object,
  setComplianceInfo: PropTypes.func
};

const mapStateToProps = state => ({
  userData: state.user.userData,
  user: state.user,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  hospitalNames: state.user.hospitalNames,
  nodeUserType: state.user.nodeUserType,
  availableVehicleTypes: state.user.availableVehicleTypesForSelection,
  bookingData: state.bookingData,
  patientCustomFields: state.user.patientCustomFields,
  timeCustomFields: state.user.timeCustomFields,
  rideCustomFields: state.user.rideCustomFields,
  updateRideCheckData: state.rideCards.updateRideCheckData,
  updateRideCheckDataParams: state.rideCards.updateRideCheckDataParams,
  updateRideData: state.rideCards.updateRideData,
  updateDateCheckData: state.rideCards.updateDateCheckData,
  updateDateData: state.rideCards.updateDateData,
  timestampMicro: state.rideCards.timestampMicro,
  vehicleTypes: state.user.vehicleTypes,
  error: state.rideCards.error,
  loadingEditRide: state.rideCards.loadingEditRide,
  rideDetailsV2: state.rideDetails
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateDateCheck: data => updateDateCheck(data),
      updateDate: data => updateDate(data),
      updateRideCheck: data => updateRideCheck(data),
      updateRide: data => updateRide(data),
      resetCards: () => resetCards(),
      updateBookingData: bookRide => updateBookingData(bookRide),
      clearBookingRide: () => clearBookingRide(),
      resetUpdatedCards: () => resetUpdatedCards(),
      addActiveRide: (rideId, addOrRemove) => addActiveRide(rideId, addOrRemove),
      getRide: rideId => getRide(rideId),
      getPatientInfo: data => getPatientInfoThunk(data),
      resetPatient: () => resetPatient()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(EditRide);
