import React, { useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { cloneDeep } from 'lodash-es';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import ExpenseTable from './Components/ExpenseTable';
import ExpenseSlideout from '~/Pages/ExpenseSlideout/Components/Slideout/ExpenseSlideout';
import ExportModal from './Components/ExportModal';

import { exportTableData } from '~/Modules/expenses/actions';
import { setExpenseSlideout } from '~/Modules/memberExpenses/actions';
import { statusOptions } from '~/services/expenses.service';

const Expenses = () => {
  const dispatch = useDispatch();
  const { slideout } = useSelector(state => state.memberExpenses);
  const { modal, filterParams } = useSelector(state => state.expenses);
  const ref = useRef(null);

  const [exportType, setExportType] = useState('');
  const [showFilter, setShowFilter] = useState(false);

  const color = key => {
    switch (key) {
      case '1':
        return '#FFB000';
      case '2':
        return '#00CFFF';
      case '3':
        return '#00F500';
      case '4':
        return '#9CA7AE';
      case '5':
        return '#FF0027';
      default:
        return '#000000';
    }
  };

  // initial columns
  const columns = [
    {
      field: 'id',
      title: 'Expense Report ID',
      render: rowData => (
        <div
          className="blue-link cursor"
          onClick={() =>
            dispatch(
              setExpenseSlideout({
                id: rowData.id,
                show: true,
                mode: 'edit'
              })
            )
          }
        >
          {rowData.id}
        </div>
      )
    },
    { field: 'ride_id', title: 'Ride ID' },
    { field: 'fromDate', title: 'Start Date', type: 'date' },
    { field: 'toDate', title: 'End Date', type: 'date' },
    { field: 'medicalId', title: 'Member ID' },
    { field: 'firstName', title: 'First Name' },
    { field: 'lastName', title: 'Last Name' },
    {
      field: 'status',
      title: 'Status',
      lookup: statusOptions,
      render: rowData => (
        <span style={{ color: color(rowData.status) }}>
          {statusOptions[rowData.status]}
        </span>
      )
    },
    { field: 'created_at', title: 'Date Submitted', type: 'date' },
    { field: 'totalExpenseAmt', title: 'Total Expense Amt.' },
    { field: 'totalReimbursableAmt', title: 'Total Reimbursable Amt.' }
  ];

  useEffect(() => {
    if (exportType) {
      dispatch(
        exportTableData({
          query: { ...filterParams },
          filename: 'expense-reports.csv',
          columns
        })
      );
      setExportType('');
    }
  }, [exportType]);

  useEffect(() => {
    if (!modal.show) setExportType('');
  }, [modal.show]);

  // action to toggle show/hide filters
  const toggleShowFilter = () => setShowFilter(!showFilter);

  // action to set export type
  const onExportChange = value => {
    if (value) {
      setExportType(value);
    } else {
      setExportType('');
    }
  };

  // action to scroll to top of page
  const scrollToTop = () => {
    ref.current.scrollIntoView();
  };

  return (
    <div className="ExpensesPage">
      <PageFrame>
        <div className="ExpenseReport">
          <div ref={ref} className="expenseHeadingContainer">
            Expense Reports
          </div>
          <div className="expenseTableContainer">
            <ExpenseTable
              exportType={exportType}
              showFilter={showFilter}
              onExportChange={onExportChange}
              toggleShowFilter={toggleShowFilter}
              scrollToTop={scrollToTop}
              columns={columns}
            />
          </div>
        </div>
        {modal.show ? (
          <ExportModal columns={columns} filterParams={filterParams} />
        ) : null}
        {slideout.show ? <ExpenseSlideout /> : null}
      </PageFrame>
    </div>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return cloneDeep(prevProps) !== cloneDeep(nextProps);
};

export default React.memo(Expenses, arePropsEqual);
