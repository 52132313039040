import React, { useState, useEffect } from 'react';
import { Box, Button, Divider, Typography } from '@material-ui/core';
import FormInput from '~/Shared/Components/Form/FormInput';
import useStyles from '../Forms/formsTheme';
import TransportTypeTag from '~/Shared/Components/TransportTypeTag/TransportTypeTag';

type ConfirmMobilityModalContentProps = {
  /** Loaded from the vehicleTypes redux MemberProfile store */
  allowedVehicleTypes: BE.VehicleRecord[];
  /** The recommended vehicle type nickname */
  recommendedVehicleTypeNickName: BE.VehicleTypeNickName;
  /** Returns the vehicle record for the recommended or selected transport type */
  onAccept: (selection: number) => void;
};

const ConfirmMobilityModalContent: React.FC<ConfirmMobilityModalContentProps> = ({
  allowedVehicleTypes,
  recommendedVehicleTypeNickName,
  onAccept
}) => {
  const classes = useStyles();
  const [selectionIndex, setSelectionIndex] = useState(-1);

  /** Sort and format the vehicle types for rendering in the dropdown selection list component */
  const sortedAllowedVehicleTypes = allowedVehicleTypes
    .map(({ id, modelName, nickName }, i) => ({
      index: i, // Simplify mapping the formatted option to allowedVehicleTypes
      label: modelName,
      value: id,
      nickName
    }))
    .sort(({ label: labelA }, { label: labelB }) => (labelA < labelB ? -1 : 1));

  //Determine the index (in the sorted allowed vehicle types array) of the recommended vehicle type.
  useEffect(() => {
    const sortedRecommendationIndex = sortedAllowedVehicleTypes.findIndex(
      ({ nickName }) => nickName === recommendedVehicleTypeNickName
    );

    if (sortedRecommendationIndex > -1 && sortedRecommendationIndex !== selectionIndex) {
      setSelectionIndex(sortedRecommendationIndex);
    }
  }, [recommendedVehicleTypeNickName]);

  /**
   * If a vehicle type other than recommended has been
   * chosen, we'll return the vehicle type id. Otherwise we'll
   * return the vehicle type id for the recommended value
   */
  function handleClick() {
    const vehicleTypeId = sortedAllowedVehicleTypes?.[selectionIndex]?.value;
    if (vehicleTypeId) {
      return onAccept(vehicleTypeId);
    }

    // eslint-disable-next-line no-console
    console.error(
      'Mobility Assessment Modal: Attempted to submit non-existent transport type'
    );
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      style={{ gap: '12px', width: '100%' }}
    >
      <Box width="100%">
        <Typography variant="h6" className="alignCenter">
          Recommended Transport Type
        </Typography>
        <Divider />
      </Box>
      <TransportTypeTag
        nickName={sortedAllowedVehicleTypes?.[selectionIndex]?.nickName}
      />

      <Box width={'100%'}>
        <Typography variant="h6">Change Transport Type</Typography>
        <Divider />
      </Box>

      <Box style={{ minWidth: '304px', textAlign: 'start' }}>
        <FormInput
          inputId={0}
          type="select"
          name="member_transport_type"
          label=""
          divider={false}
          placeholder="Select a Transport Type"
          selection={selectionIndex > -1 ? selectionIndex : undefined}
          options={sortedAllowedVehicleTypes}
          onChange={(_inputId, optionId) => setSelectionIndex(optionId as number)}
        />
      </Box>

      <Button
        className={classes.submitButton}
        disabled={selectionIndex === -1}
        onClick={handleClick}
        data-testid="accept-transport-type"
        style={{ height: 28, width: 120, margin: '0 0 8px 0' }}
      >
        Accept
      </Button>
    </Box>
  );
};

export default ConfirmMobilityModalContent;
