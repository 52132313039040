import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';

const selectMemberProfileStore = (s: AppStore) => s.memberProfile;
const selectUserStore = (s: AppStore) => s.user;

export const selectSubPlan = createSelector(
  [selectMemberProfileStore, selectUserStore],
  (memberProfile, user) =>
    user?.healthPlans?.find(
      ({ healthSubPlanId }) =>
        healthSubPlanId === memberProfile.formData.benefits.healthSubPlanId
    )
);
