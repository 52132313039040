import keyMirror from '../../utilities/keyMirror';

export const ActionTypes = keyMirror({
  GET_TABLE_DATA: undefined,
  GET_TABLE_DATA_SUCCESS: undefined,
  GET_TABLE_DATA_ERROR: undefined,

  FILTER_TABLE_DATA: undefined,
  FILTER_TABLE_DATA_SUCCESS: undefined,
  FILTER_TABLE_DATA_ERROR: undefined,

  SET_EXPORT_MODAL: undefined,
  SET_LOADING: undefined,
  IS_LOADING: undefined,

  EXPORT_TABLE_DATA: undefined,
  EXPORT_TABLE_DATA_SUCCESS: undefined,
  EXPORT_TABLE_DATA_ERROR: undefined
});
