import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import InputText from '~/Shared/Components/InputText';
import { truncateElipse } from '~/utilities/strings';
import ReactTooltip from 'react-tooltip';

const CreateModal = ({
  actionType,
  handleSubmit,
  isOpen,
  closeModal,
  handleDescription,
  handleDate,
  description,
  expiration,
  status,
  descriptionError,
  expirationError,
  filesError,
  filesErrorText,
  filename,
  uploadSizeLimit
}) => {
  // So... the code further down is directly modifying files which
  // it definitely should not be doing. No idea how/if this works
  // so I'm not touching it.
  // eslint-disable-next-line prefer-const
  let [files, handleFiles] = useState(0);
  const submit = e => {
    e.preventDefault();

    let fileData = {};
    if (actionType === 'create') {
      fileData = files;
    }

    handleSubmit(fileData, description, expiration, actionType);
  };

  const valideUpload = e => {
    if (typeof e.target.files[0] !== 'undefined') {
      files = handleFiles(e.target.files[0]);
    }
  };

  let title = actionType === 'edit' ? 'Edit' : 'Upload';

  if (status !== '') {
    if (status === 'success') {
      if (actionType === 'create') {
        title = 'Upload Successful!';
      } else {
        title = 'Update Successful!';
      }
    } else {
      title = 'Error';
    }
  }

  let disabled = false;
  if (actionType === 'create') {
    disabled = typeof files.name === 'undefined' ? true : false;
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      className={{
        base: 'newModalBaseClass uploadModal',
        afterOpen: 'modalBaseClassOpen',
        beforeClose: 'modalBaseClassClose'
      }}
      overlayClassName={{
        base: 'overlayBaseClass BookAnywayModalOverlay',
        afterOpen: 'overlayBaseClassOpen',
        beforeClose: 'overlayBaseClassClose'
      }}
      closeTimeoutMS={500}
    >
      <div className="uploadModalTitle">
        <p>{title}</p>
      </div>
      {status === '' ? (
        <form onSubmit={submit} method="post">
          <div className="uploadModalBody">
            <div className="row">
              {actionType === 'edit' ? (
                <div className="CustomInputText memberModalInput">
                  <label htmlFor="fileDescription" className="inputLabel">
                    <span className="showAsterisk">*</span>
                    File
                  </label>
                  {filename.length > 80 ? (
                    <ReactTooltip id="formtipInput" place="top" />
                  ) : null}
                  <p
                    id="fileDescription"
                    name="fileDescription"
                    placeholder="Sample description of a file."
                    data-tip={filename}
                    data-for="formtipInput"
                    className="input"
                  >
                    {truncateElipse(filename, 80, '...', 'middle')}
                  </p>
                </div>
              ) : (
                <>
                  <label
                    htmlFor="fileUpload"
                    className={
                      filesError || files.size > uploadSizeLimit
                        ? 'customFileUpload error'
                        : 'customFileUpload'
                    }
                  >
                    <span className="inputLabel">
                      <span className="showAsterisk">*</span>File
                    </span>
                    {files.name ? (
                      <>
                        {files.name.length > 50 ? (
                          <ReactTooltip id="formtip" place="top" />
                        ) : null}
                        <span
                          className="grayFormCircle selected"
                          data-tip={files.name}
                          data-for="formtip"
                        >
                          {truncateElipse(files.name, 50, '...', 'middle')}
                        </span>
                      </>
                    ) : (
                      <span className="grayFormCircle">Select a file to upload</span>
                    )}
                    <span className="grayButton">Choose File</span>
                    {filesError || files.size > uploadSizeLimit ? (
                      <span className="fileErrorText">{filesErrorText}</span>
                    ) : null}
                  </label>
                  <input
                    type="file"
                    id="fileUpload"
                    name="fileUpload"
                    onChange={e => valideUpload(e)}
                  />
                </>
              )}
            </div>
            <div className="row">
              <InputText
                placeholder="Sample description of a file."
                fieldName="description"
                label="Description (Optional)"
                showLabel={true}
                customClassName="memberModalInput"
                onChange={handleDescription}
                value={description}
                error={descriptionError}
                errorText={descriptionError ? '64 characters max.' : ''}
              />
              <p className="alternateLabel">64 characters max.</p>
            </div>
            <div className="row">
              <InputText
                placeholder="##/##/####"
                fieldName="expiration"
                label="Expiration Date (Optional)"
                showLabel={true}
                mask="_"
                format="##/##/####"
                customClassName="memberModalInput"
                onChange={handleDate}
                value={expiration}
                error={expirationError}
                errorText={expirationError ? 'Date format must be in mm/dd/yyyy.' : ''}
              />
            </div>
          </div>
          <div className="uploadModalButtons">
            <button onClick={closeModal} className="cancelButton">
              Cancel
            </button>
            <input
              type="submit"
              value={title}
              className={disabled ? 'createButton disabled' : 'createButton'}
              disabled={disabled}
            />
          </div>
        </form>
      ) : (
        <div className="uploadModalButtons">
          <button onClick={closeModal} className="createButton">
            {status === 'success' ? 'Ok' : 'Close'}
          </button>
        </div>
      )}
    </Modal>
  );
};

CreateModal.propTypes = {
  actionType: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  uploadData: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  handleDescription: PropTypes.func.isRequired,
  description: PropTypes.string,
  handleDate: PropTypes.func,
  expiration: PropTypes.string,
  status: PropTypes.string,
  expirationError: PropTypes.bool,
  descriptionError: PropTypes.bool,
  filename: PropTypes.string,
  filesError: PropTypes.bool,
  filesErrorText: PropTypes.string,
  uploadSizeLimit: PropTypes.number
};

CreateModal.defaultProps = {
  actionType: 'edit',
  handleSubmit: () => {},
  closeModal: () => {},
  uploadData: {},
  isOpen: false,
  handleDescription: () => {},
  description: '',
  handleDate: () => {},
  expiration: '',
  status: '',
  expirationError: false,
  descriptionError: false,
  filename: '',
  filesError: false,
  filesErrorText: '',
  uploadSizeLimit: 0
};

export default CreateModal;
