/**
 * @summary - sanitizes expense form fields for reducer
 * @param {object} ride - ride details from `pastRides`
 * @return {object} returns fields for expense form
 */
export const mapRideDetailsToExpenseReport = ride => {
  const mobilityType = ride?.reqVehicleType ?? null;
  const subMobilityType = ride?.meta_data?.ride_options?.vehicle_sub_type ?? null;
  const complianceFields = ride?.additional_columns?.[0]?.additionalFields ?? {};
  const tripPurpose = ride?.additionalNotes || '';
  const passengers = (ride?.additional_passengers ?? []).map(passenger => ({
    ageGroup: passenger.type,
    firstName: passenger.first_name,
    lastName: passenger.last_name,
    mobilityType: passenger.mobility_type_id,
    subMobilityType: passenger.sub_mobility_type_id,
    isAttendant: Boolean(passenger.attendant)
  }));

  return {
    mobilityType, // number?
    subMobilityType, // number?
    complianceFields, // { [key] : value }
    tripPurpose, // string
    passengers // Passenger[]: { ageGroup: string, firstName: string, lastName: string, mobilityType: number, subMobilityType: number, isAttendant: bool }
  };
};
