import { token } from '~/utilities/auth.helper';

export type BenefitOverwrite = {
  id: number;
  roleName: string;
  selected: 1 | 0;
};

export type GetBenefitsOverwriteResponse = {
  data: BenefitOverwrite[];
  status: boolean;
  message: string;
  httpCode: number;
  errors: string[];
  timestamp: string;
  environment: string;
};

export type PostBenefitsOverwriteResponse = {
  data: [];
  status: boolean;
  message: string;
  httpCode: number;
  errors: string[];
  timestamp: string;
  environment: string;
};

/**
 * Get Benefits Overwrite
 * @returns Promise<GetBenefitsOverwriteResponse>
 */
export const getBenefitsOverwrite = async (): Promise<GetBenefitsOverwriteResponse> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/benefits-overwrite`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};

/**
 * Post Benefits Overwrite
 * @param roleIds
 * @returns Promise<GetSubPlanMobilityTypeResponse>
 */
export const postBenefitsOverwrite = async (
  roleIds: number[]
): Promise<PostBenefitsOverwriteResponse> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/benefits-overwrite`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ roleIds })
    }
  ).then(res => res.json());
};
