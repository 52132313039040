import _ from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import SvgAddCircle from '~/Shared/Components/Svgs/SvgAddCircle';

const formatTimeAmPm = time => {
  if (typeof time !== 'string') {
    return '';
  }
  return `${time.replace(/am|pm/gi, '')} ${time.slice(-2).toUpperCase()}`;
};

const renderPublicRouteItem = (item, itemIndex) => {
  return (
    <div key={`lsk-${itemIndex}`} className="publicRouteItem">
      {itemIndex === 0 ? null : <span className="divider">&gt;</span>}
      {item.travel_mode === 'WALKING' ? (
        <div className="itemWalking">
          <img src={'//maps.gstatic.com/mapfiles/transit/iw2/6/walk.png'} />
        </div>
      ) : (
        <div className="itemTransit">
          <img src={item.transit_details.line.vehicle.icon} />
          <div
            className="transitLine"
            style={{
              backgroundColor: item.transit_details.line.color,
              color: item.transit_details.line.text_color
            }}
          >
            {item.transit_details.line.short_name}
          </div>
        </div>
      )}
    </div>
  );
};

const RouteInformationCard = ({
  leg,
  fare,
  action,
  selected,
  className,
  headwayString,
  transitDetails,
  withoutDivider
}) => {
  const metersToMiles = meters => {
    return Math.round(meters * 0.00062137 * 100) / 100;
  };

  const metersWalkingDistance = leg => {
    const meters = leg.steps.reduce((acc, step) => {
      if (step.travel_mode === 'WALKING') {
        return acc + parseInt(step.distance.value);
      }
      return acc;
    }, 0);
    return parseInt(meters);
  };

  const RouteTimes = () => {
    return _.has(leg, 'departure_time') ? (
      <div className="publicRouteStats">
        <span className="time">
          {`
              ${formatTimeAmPm(leg.departure_time.text)}
              - 
              ${formatTimeAmPm(leg.arrival_time.text)}
           `}
        </span>
        <span className="duration">{leg.duration.text}</span>
      </div>
    ) : null;
  };

  const RouteList = () => {
    return (
      <div className="itemList">
        {leg.steps.map((item, itemIndex) => {
          return renderPublicRouteItem(item, itemIndex);
        })}
      </div>
    );
  };

  const RouteInfo = () => {
    return (
      <div className="itemList">
        <div className="publicRouteItem">{fare}</div>
        <div className="publicRouteItem">
          <div className="itemWalking">
            <img src={'//maps.gstatic.com/mapfiles/transit/iw2/6/walk.png'} />
          </div>
          {metersToMiles(metersWalkingDistance(leg))} mi.
        </div>
        <div className="publicRouteItem">{headwayString}</div>
        {selected ? <div className="selected">✓</div> : ''}
      </div>
    );
  };

  const PickupFrom = ({ transitDetails }) => {
    //Extract transit details
    return (
      <div className="pickupFrom">
        <span className="time">
          {formatTimeAmPm(_.get(transitDetails, 'arrival_time.text', ''))}
        </span>{' '}
        from {_.get(transitDetails, 'arrival_stop.name', '')}
      </div>
    );
  };

  const RouteDetails = ({ leg }) => {
    if (leg && leg.start_address && leg.end_address) {
      const googleMapUrl = `https://www.google.com/maps/dir/?api=1&`;
      return (
        <div>
          <a
            style={{
              color: '#0072D6',
              textDecoration: 'none',
              textTransform: 'uppercase'
            }}
            target="_blank"
            href={`${googleMapUrl}&origin=${leg.start_address}&destination=${leg.end_address}&travelmode=transit`}
            rel="noreferrer"
          >
            Route Details
          </a>
        </div>
      );
    }
    return <div />;
  };

  return (
    <div onClick={action} className={className}>
      <div className="routeOptionInner">
        <RouteTimes />
        <RouteList />
        <PickupFrom transitDetails={transitDetails} />
        <RouteInfo />
        <RouteDetails leg={leg} />
      </div>
      {withoutDivider ? null : <div className="divider"></div>}
    </div>
  );
};

class PublicRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setTranitDetails = leg => {
    let transitDetails = {};
    _.forEach(_.get(leg, 'steps', []), step => {
      if (_.has(step, 'transit_details') && Object.keys(transitDetails).length === 0) {
        transitDetails = step.transit_details;
      }
    });
    return transitDetails;
  };

  setHeadwayString = transitDetails => {
    let headwayString = '';
    if (_.has(transitDetails, 'headway')) {
      headwayString = `every ${Math.floor(transitDetails.headway / 60)} mintutes`;
    }
    return headwayString;
  };

  setClassName = (legKey, props, selected) => {
    const className = `${
      _.get(props, 'transit.leg', null) === legKey
        ? 'routeOption selected'
        : 'routeOption'
    } ${selected ? ' highlight-blue' : ''}`;
    return className;
  };

  render() {
    const legKey = -1;
    return (
      <div className="publicRoute">
        {!this.props.hideLeg && this.props.routeDataKey === 0 ? (
          <p className="routeLeg">
            <a onClick={this.props.handler(this.props.leg)}>
              Leg {this.props.leg + 1}
              {this.props.defaultOpen ? null : <SvgAddCircle />}
            </a>
          </p>
        ) : null}
        {this.props.defaultOpen === false
          ? null
          : this.props.routeData.legs.map(leg => {
            const transitDetails = this.setTranitDetails(leg);
            return (
              <RouteInformationCard
                key={`lk-${legKey}`}
                leg={leg}
                fare={this.props.fare}
                action={this.props.handler(this.props.leg, this.props.routeDataKey)}
                legKey={legKey}
                selected={this.props.selected}
                className={this.setClassName(legKey, this.props, this.props.selected)}
                routeDataKey={this.props.routeDataKey}
                headwayString={this.setHeadwayString(transitDetails)}
                transitDetails={transitDetails}
                withoutDivider={this.props.withoutDivider}
              />
            );
          })}
      </div>
    );
  }
}

PublicRoute.propTypes = {
  hideLeg: PropTypes.bool,
  routeData: PropTypes.object,
  defaultOpen: PropTypes.any,
  handler: PropTypes.func,
  fare: PropTypes.string,
  leg: PropTypes.number
};

PublicRoute.defaultProps = {
  hideLeg: false,
  routeData: {},
  defaultOpen: false,
  handler: () => {},
  fare: '$ N/A',
  leg: null
};

export { PublicRoute as default };
