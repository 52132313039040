import React from 'react';
import Button from '../Button';
import ReactModal from 'react-modal';
import { isEmpty } from 'lodash-es';

// required React Modal style object
const style = {
  overlay: {
    zIndex: 150
  },
  content: {
    zIndex: 100,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

/**
 * @name ConfirmModal
 * @param {Object} modal Object with all the modal properties.
 * @returns {Component} ConfirmModal component
 */
const CheckDuplicatesModal = ({ duplicates, overlaps, onClose }) => {
  if (isEmpty(overlaps) && isEmpty(duplicates)) return null;

  const getFields = duplicate => {
    const { expenseDate, category, amount, expenseReportId } = duplicate;

    const date = new Date(expenseDate);
    const dateString = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
    return { category, amount, date: dateString, expenseReportId };
  };

  const ModalButtons = () => {
    return (
      <div className="modal-buttons flex">
        <Button
          onClick={() => {
            onClose(true);
          }}
          className="red-btn"
        >
          Yes
        </Button>
        <div className="spacer-8"></div>
        <Button
          disabled={false}
          onClick={() => {
            onClose(false);
          }}
          className="grey-btn"
        >
          No
        </Button>
      </div>
    );
  };

  const Divider = () => <div className="divider"></div>;

  const renderOverlappingWarning = overlaps => {
    if (isEmpty(overlaps)) {
      return null;
    }
    return (
      <>
        <p>
          This member already has one or more existing parent expenses that share some or
          all of the selected dates of this expense:
        </p>
        <br />
        {overlaps.map((parentExpenseId, index) => {
          return <p key={index}>{`\u2022 ${parentExpenseId}`}</p>;
        })}
        <br />
      </>
    );
  };

  const renderDuplicatesWarning = duplicates => {
    if (isEmpty(duplicates)) {
      return null;
    }
    return (
      <>
        <p>The following child expenses already exist for this member:</p>
        <br />
        {duplicates.map((duplicate, index) => {
          const { category, amount, date, expenseReportId } = getFields(duplicate);
          return (
            <p
              key={index}
            >{`\u2022 ${date} for ${category} in the amount of $${amount} in ${expenseReportId}.`}</p>
          );
        })}
        <br />
      </>
    );
  };

  const modalProps = {
    style: style,
    isOpen: true,
    ariaHideApp: false,
    overlayClassName: 'infoOverlay',
    shouldCloseOnOverlayClick: true
  };
  return (
    <ReactModal {...modalProps}>
      <div className="ConfirmDuplicatesModal">
        <div className="modal-header">
          <div>Warning</div>
        </div>
        <Divider />
        <div className="modal-body">
          {renderOverlappingWarning(overlaps)}
          {renderDuplicatesWarning(duplicates)}
          <p>Would you like to proceed?</p>
          <br />
        </div>
        <Divider />
        <ModalButtons />
      </div>
    </ReactModal>
  );
};

export default CheckDuplicatesModal;
