import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';

class ImporterUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: []
    };
  }

  componentDidMount() {
    const files = _.get(this.props, 'step.data.files', []);
    if (files.length > 0) {
      this.setState({ files });
    }
  }

  /**
   * Handle file selection
   * @param {SyntheticEvent} e React synthetic event object
   * @returns {undefined}
   */
  handleFiles(e) {
    const files = _.toArray(e.target.files);
    if (files.length > 0) {
      const step = _.cloneDeep(this.props.step);
      step.data = { files };
      step.isValid = true;
      step.hasSubmitted = false;
      this.props.setStep(step);
      this.setState({ files });
    }
  }

  render() {
    const fileLabels = this.state.files.map(file => file.name);

    if (fileLabels.length === 0) {
      fileLabels.push('No file chosen');
    }

    return (
      <div className="ImporterUpload">
        <h2>{this.props.step.index + 1}. Upload your file</h2>
        <p>
          Other CSV file formats may be acceptable. You will get an opportunity to verify
          if the import looks correct before committing. The CSV file must include a
          single header row to enable mapping of the columns.
        </p>
        <div className="fileUpload">
          <input
            id="fileInput"
            type="file"
            onChange={e => this.handleFiles(e)}
            accept="text/csv"
          />
          <label htmlFor="fileInput">Choose File</label>
          <span>{fileLabels.join(', ')}</span>
        </div>
      </div>
    );
  }
}

ImporterUpload.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func
};

ImporterUpload.defaultProps = {
  step: {},
  setStep: () => {}
};

export default ImporterUpload;
