import moment from 'moment';
import { Parser } from 'json2csv';
import axios from 'axios';
import { baseRequestConfig } from '~/utilities/auth.helper';
import { calculateDistance } from '~/utilities/map';

export const statusOptions = {
  1: 'To Be Reviewed',
  2: 'Approved',
  3: 'Reimbursed',
  4: 'Cancelled',
  5: 'Rejected',
  6: 'Paid By Broker',
  7: 'Pending Reimbursement',
  8: 'Invoiced',
  9: 'Out Of Compliance'
};

export const exportOptions = { 1: 'CSV' };

export const getKeyByValue = (object = {}, value = '') => {
  return Object.keys(object).find(key => object[key] === value);
};

/**
   * @name expenses.service.js
   * @description Functions for showing/validating various expense fields
   * @summary Supported:
                - showing merchant address check
                - showing distance/mileage fields
                - showing address fields
                - calculate distance mapbox api
   * @usage new ExpenseService();   // service name is arbitrary
*/

export default function () {
  return {
    showMerchantAddress: ({ category }) => {
      if (!category) return false;
      return (
        !!category &&
        [
          'IT & Internet',
          'Lodging',
          'Advanced Funds — Lodging',
          'Meals',
          'Advanced Funds — Meals',
          'Miscellaneous',
          'Parking',
          'Reinburesement - Lodging',
          'Reinburesement - Meals',
          'Toll Fees'
        ].filter(field => !!category.match(field)).length > 0
      );
    },

    showDistanceMileageFields: ({ category }) => {
      if (!category) return false;
      return !!['Fuel/Mileage', 'Mileage', 'Gas Card'].filter(
        field => !!category.match(field)
      ).length;
    },

    showAddressFields: ({ category }) => {
      if (!category) return false;

      return !![
        'Advanced Funds — Mileage',
        'Bus Ticket',
        'Facility Reimbursement',
        'Fuel/Mileage',
        'Mileage',
        'Gas Card',
        'Travel - Airfare',
        'Travel - Commercial Bus',
        'Travel - Ferry or water',
        'Travel - Train'
      ].filter(field => !!category.match(field)).length;
    },
    showNumberOfNightsDropdown: ({ category, mode }) => {
      if (!category || mode === 'edit') return false;

      return !!['Advanced Funds — Lodging', 'Lodging'].filter(
        field => !!category.match(field)
      ).length;
    },
    showNumberOfDaysDropdown: ({ category, mode }) => {
      if (!category || mode === 'edit') return false;

      return !!['Advanced Funds — Meals', 'Meals'].filter(
        field => !!category.match(field)
      ).length;
    },

    /** Warning - please use `calculateDistance` in ~/utilities/map */
    calcDistance: calculateDistance,
    isTravelCategory: ({ category }) => {
      if (!category) return false;
      return !![
        'Travel - Airfare',
        'Travel - Commercial Bus',
        'Travel - Ferry or water',
        'Travel - Train',
        'Facility Reimbursement',
        'Bus Ticket'
      ].filter(field => !!category.match(field)).length;
    },
    /**
     * @name expenseTotals
     * @param {Array} expenses
     * @returns {object} {totalSum: int, nonreimbursable: int, difference: int}
     */
    // Singleton for generating expense totals;
    expenseTotals: expenseTotals,

    getPaginationText: (total, limit, page) => {
      const start = (page - 1) * limit + 1;
      const end = Math.min(start + limit - 1, total);

      return {
        start,
        end,
        total
      };
    },
    mapExpenseTableData: (data = []) => {
      const formatDate = d => moment.utc(d).format('MM/DD/YYYY');
      return (
        Array.isArray(data) &&
        data
          .filter(d => typeof d._id === 'string') // handle malformed _id field
          .map(d => {
            const { difference, totalSum } = expenseTotals({
              expenses: d.expenses
            });
            return {
              id: d._id,
              ride_id: d.ride_id,
              status: getKeyByValue(statusOptions, d.status),
              toDate: formatDate(d.toDate),
              memberId: d.memberId,
              medicalId: d.medicalId,
              fromDate: formatDate(d.fromDate),
              lastName: d.lastName,
              firstName: d.firstName,
              created_at: formatDate(d.created_at),
              totalExpenseAmt: `$${totalSum}`,
              totalReimbursableAmt: `$${difference}`
            };
          })
      );
    },

    exportJsonAsCSV: request => {
      const serialize = records => {
        const fields = Object.keys(records[0]);
        const parser = new Parser({ fields });
        return parser.parse(records);
      };

      const mapRecords = records => {
        return records.map(record => ({
          id: record.id,
          fromDate: record.fromDate,
          toDate: record.toDate,
          memberId: record.medicalId,
          firstName: record.firstName,
          lastName: record.lastName,
          status: statusOptions[record.status],
          dateSubmitted: record.created_at,
          totalExpenseAmt: record.totalExpenseAmt,
          totalReimbursableAmt: record.totalReimbursableAmt
        }));
      };

      const createHeaders = fileName => {
        return {
          metaString: 'data:text/csv;charset=utf-8',
          fileName: `${fileName}.csv`
        };
      };

      const convertToCSVExport = request => {
        const { records, fileName } = request;
        const serialized = serialize(mapRecords(records));
        const header = createHeaders(fileName);
        return {
          header,
          serialized
        };
      };

      try {
        const data = convertToCSVExport(request);
        const dataString = data.serialized;
        const csvBlob = new Blob([dataString], { type: 'text/csv' });

        const a = document.createElement('a');
        a.setAttribute('href', URL.createObjectURL(csvBlob));
        a.setAttribute('download', data.header.fileName);
        a.click();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error exporting CSV: ', error);
      }
    },
    checkDuplicates: async ({
      memberId,
      expenses,
      expenseReportId = null,
      fromDate,
      toDate
    }) => {
      let reqBodyParams = {};
      if (expenses.length >= 1) {
        reqBodyParams = { memberId, expenses, expenseReportId, fromDate, toDate };
      } else {
        reqBodyParams = { memberId, expenseReportId, fromDate, toDate };
      }
      const URL = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/expenses/check-duplicates`;

      const result = await axios.post(URL, reqBodyParams, baseRequestConfig());

      const { data: res } = result;
      if (res && res.data && (res.data.isDuplicate || res.data.overlaps.length)) {
        return {
          duplicates: res.data.expenses,
          overlaps: res.data.overlaps
        };
      } else {
        return {
          duplicates: [],
          overlaps: []
        };
      }
    }
  };
}

const expenseTotals = ({ expenses = [] }) => {
  if (!Array.isArray(expenses)) {
    expenses = Object.entries(expenses).reduce((acc, [key, value]) => {
      acc.push({ id: key, ...value });
      return acc;
    }, []);
  }

  const totalSum = expenses
    .reduce((sum, { amount }) => sum + Number(amount), 0)
    .toFixed(2);

  const nonreimbursable = expenses
    .reduce(
      (sum, { amount, reimbursable }) => (reimbursable ? sum : sum + Number(amount)),
      0
    )
    .toFixed(2);

  return {
    totalSum,
    nonreimbursable,
    difference: Number(totalSum - nonreimbursable).toFixed(2)
  };
};
