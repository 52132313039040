import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import _ from 'lodash-es';
import InputText from '~/Shared/Components/InputText';
import SvgLabel from '~/Shared/Components/Svgs/SvgLabel';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import {
  formatPhoneNumber,
  validateEmail,
  statusNotifications
} from '~/utilities/helperFunctions';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PortalDraggable from './PortalDraggable';

Modal.setAppElement('#app_container');

const getItemStyle = (_isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  fontFamily: `'Noto Sans', sans-serif`,
  fontSize: '12px',
  padding: '2px',
  cursor: 'grab',
  // styles we need to apply on draggables
  ...draggableStyle
});

class Details extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showStatus: false,
      isValidated: false,
      errorMessage: [],
      checked: false,
      nextDayEmail: false,
      firstName: '',
      lastname: '',
      mobileNo: '',
      email: '',
      firstNameError: false,
      lastNameError: false,
      mobileNoError: false,
      emailError: false,
      assignedFacilities: [],
      unassignedFacilities: [],
      message: [],
      loading: props.update ? true : false
    };
    this.hasSecondRideCost = false;
    this.cancelCount = 0;
    this.rides = [];
    this.ridesCost = [];
    this.cancelCount = 0;
    this.viewList = {};
    this.unviewList = {};
    this.timeout = '';
    this.id2List = {
      droppable: 'unassignedFacilities',
      droppable2: 'assignedFacilities'
    };
  }

  componentDidMount() {
    const { manager, hospitals, update } = this.props;

    if (!_.isEmpty(manager)) {
      this.setManagerData(manager);
    }
    // initialize unassigned facilities with hospital data from user api in case new user
    if (!update) {
      this.setState({
        assignedFacilities: hospitals.map(hospital => {
          return {
            hospitalId: hospital.id,
            hospitalName: hospital.hospitalName
          };
        })
      });
    }
  }

  /**
   * @param {object} prevProps - prevProps
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const { manager, type, successTypes, errorType, timestamp } = this.props;

    if (!_.isEqual(prevProps.manager, manager)) {
      // this is either a newly opened modal or it's a new manager created
      if (_.isEmpty(prevProps.manager) && !_.isEmpty(manager)) {
        // new manager created
        if (type === successTypes.create) {
          statusNotifications('the manager was created.', 'success', 3000);
          this.setState(
            { message: ['the manager was created.'], loading: false },
            this.closeModal
          );
        } else {
          this.setManagerData(manager);
        }
      } else if (type === successTypes.update) {
        statusNotifications('the manager was updated.', 'success', 3000);
        this.setState(
          { message: ['the manager was updated.'], loading: false },
          this.closeModal
        );
      }
    }
    if (type === errorType && !_.isEqual(timestamp, prevProps.timestamp)) {
      const errorMessage = this.parseError(this.props.error);

      this.setState(
        {
          errorMessage: [errorMessage],
          loading: false
        },
        () => {
          statusNotifications(errorMessage, 'error', 5000);
        }
      );
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeout);
  }

  /**
   * handles toggle for next day email
   * @return {undefined} - nothing returned
   */
  updateNextDayEmail = () => {
    this.setState(prevState => ({
      nextDayEmail: !prevState.nextDayEmail
    }));
  };

  /**
   * submit
   * @param {object} e - event
   * @return {undefined} - returns nothing
   */
  handleSubmit = e => {
    e.preventDefault();
    const valid = this.checkFormsValid();
    if (valid === false) {
      return false;
    }
    const params = {};

    if (!_.isEmpty(this.props.manager)) {
      params.id = this.props.manager.id;
    }
    params.firstName = this.state.firstName;
    params.lastName = this.state.lastName;
    params.email = this.state.email;
    params.mobileNo = this.state.mobileNo;
    params.nextDayEmail = this.state.nextDayEmail;
    if (this.state.assignedFacilities.length > 0) {
      params.attachedHospitals = this.state.assignedFacilities.map(facility => {
        return facility.hospitalId;
      });
    }
    this.setState(
      {
        loading: true
      },
      () => {
        this.props.updateManager(
          params,
          this.state.assignedFacilities,
          this.state.unassignedFacilities
        );
      }
    );
  };

  /**
   * close the modal
   * @return {undefined}
   */
  closeModal = () => {
    this.props.closeModal();
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changeText = e => {
    const id = e.target.id;
    const val = e.target.value;
    const stateObj = {};
    stateObj[id] = val;
    this.setState(stateObj);
  };

  /**
   * handler for dragging event
   * @param {object} result - object with source and destination data
   * @return {undefined} - returns nothing
   */
  onDragEnd = result => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const unassignedFacilities = this.reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = { unassignedFacilities };

      if (source.droppableId === 'droppable2') {
        state = { assignedFacilities: unassignedFacilities };
      }

      this.setState(state);
    } else {
      const result = this.move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({
        unassignedFacilities: result.droppable,
        assignedFacilities: result.droppable2
      });
    }
  };

  /**
   * callback for facilities fron drag and drop
   * @param {array} facilities - facilities updated from drag and drop
   * @param {string} key - assigned or unassigned facilities
   * @return {undefined} - returns nothing
   */
  updatefacilities(facilities, key) {
    this.setState({
      [key]: facilities
    });
  }

  /**
   * checks if form values are valid prior to submission
   * @return {boolean} - returns true or false if form is valid
   */
  checkFormsValid() {
    let valid = true;
    const state = {
      firstNameError: false,
      lastNameError: false,
      mobileNoError: false,
      emailError: false,
      errorMessage: [],
      message: []
    };

    if (this.state.firstName === '') {
      valid = false;
      state.firstNameError = true;
      state.errorMessage.push('Please enter a first name.');
    }

    if (this.state.lastName === '') {
      valid = false;
      state.firstNameError = true;
      state.errorMessage.push('Please enter a last name.');
    }

    if (formatPhoneNumber(this.state.mobileNo) === '') {
      valid = false;
      state.mobileNoError = true;
      state.errorMessage.push('Please enter a valid phone number.');
    }

    if (!validateEmail(this.state.email)) {
      valid = false;
      state.emailError = true;
      state.errorMessage.push('Please enter a valid email address.');
    }

    this.setState(state);

    return valid;
  }

  /**
   * get modal title
   * @param {boolean} update - adding an admin or editing an admin
   * @return {string} title - modal title
   */
  getTitle(update) {
    const updateText = 'Update Hospital Network Admin';
    const addText = 'Add Hospital Network Admin';
    if (update) {
      return updateText;
    }
    return addText;
  }

  /**
   * take manager data and convert it into state for form
   * @param {object} manager - manager prop
   * @return {undefined} - nothing returned
   */
  setManagerData(manager) {
    this.setState({
      firstName: manager.firstName,
      lastName: manager.lastName,
      email: manager.email,
      mobileNo: manager.mobileNo,
      nextDayEmail: manager.nextDayEmail,
      unassignedFacilities: manager.unassignedFacilities,
      assignedFacilities: manager.assignedFacilities,
      loading: false
    });
  }

  /**
   * parse error messages
   * @param {array} error - error from redux
   * @return {string} return error message as a string
   */
  parseError(error) {
    const errors = error.map(err => {
      return err.errorMessage;
    });
    if (Array.isArray(errors)) {
      return errors.join('. ');
    }
    return errors;
  }

  /**
   * Moves an item from one list to another list.
   * @param {object} source - data from source for dragged item
   * @param {object} destination - data from destination for dragged item
   * @param {object} droppableSource - location of where dragged item came from
   * @param {object} droppableDestination - location of where dragged item is going
   * @return {object} - object with source and destination data
   */
  move(source, destination, droppableSource, droppableDestination) {
    const sourceClone = _.cloneDeep(source);
    const destClone = _.cloneDeep(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
  }

  /**
   * reorder list
   * @param {array} list - array of facilities
   * @param {integer} startIndex - beggining of array
   * @param {interger} endIndex - end of array
   * @return {array} - returned reordered array
   */
  reorder(list, startIndex, endIndex) {
    const result = _.cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }

  /**
   * helper function for retrieving list from state
   * @param {string} id - key for list value in state
   * @return {array} - return list, whether assigned or unassigned
   */
  getList(id) {
    return this.state[this.id2List[id]];
  }

  render() {
    const { update } = this.props;
    const title = this.getTitle(update);
    const buttonClass = this.state.nextDayEmail ? 'toggleBtn on' : 'toggleBtn';
    const circleClass = this.state.nextDayEmail ? 'toggleCircle on' : 'toggleCircle';

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeModal}
        className={{
          base: 'newModalBaseClass newErrorModal networkDetails',
          afterOpen: 'modalBaseClassOpen',
          beforeClose: 'modalBaseClassClose'
        }}
        overlayClassName={{
          base: 'overlayBaseClass',
          afterOpen: 'overlayBaseClassOpen',
          beforeClose: 'overlayBaseClassClose'
        }}
        closeTimeoutMS={500}
      >
        <div className="modalHead">
          <p>{title}</p>
        </div>
        {this.state.loading ? (
          <div style={{ marginBottom: '40px' }}>
            <LoadingComponent />
          </div>
        ) : (
          <form onSubmit={this.handleSubmit} method="post">
            <div className="modalBody clearfix">
              <div className="adminRow clearfix">
                <label className="labelLeft" htmlFor="firstName">
                  First Name
                </label>
                <div className="selectComponent">
                  <InputText
                    onChange={this.changeText}
                    fieldName="firstName"
                    label=""
                    value={this.state.firstName || ''}
                    error={this.state.firstNameError}
                    disabled={false}
                    customClassName="networkTextField"
                    black={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="adminRow clearfix">
                <label className="labelLeft" htmlFor="lastName">
                  Last Name
                </label>
                <div className="selectComponent">
                  <InputText
                    onChange={this.changeText}
                    fieldName="lastName"
                    label=""
                    value={this.state.lastName || ''}
                    error={this.state.lastNameError}
                    disabled={false}
                    customClassName="networkTextField"
                    black={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="adminRow clearfix">
                <label className="labelLeft" htmlFor="mobileNo">
                  Mobile No
                </label>
                <div className="selectComponent">
                  <InputText
                    onChange={this.changeText}
                    fieldName="mobileNo"
                    label=""
                    value={this.state.mobileNo || ''}
                    error={this.state.mobileNoError}
                    disabled={false}
                    customClassName="networkTextField"
                    black={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="adminRow clearfix">
                <label className="labelLeft" htmlFor="email">
                  Email
                </label>
                <div className="selectComponent">
                  <InputText
                    onChange={this.changeText}
                    fieldName="email"
                    label=""
                    value={this.state.email || ''}
                    error={this.state.emailError}
                    disabled={false}
                    customClassName="networkTextField"
                    black={true}
                    required={true}
                  />
                </div>
              </div>
              <div className="adminRow clearfix nextDayEmail">
                <p className="nextDayLabel">Get Next Day Rides Email?</p>
                <span className={buttonClass} onClick={this.updateNextDayEmail}>
                  <span className={circleClass}></span>
                </span>
              </div>
              <div className="adminRow clearfix facilities">
                <div className="arrows">
                  <SvgLabel className="arrowRight" />
                  <SvgLabel className="arrowLeft" />
                </div>

                <DragDropContext onDragEnd={this.onDragEnd}>
                  <div className="viewCont">
                    <div className="head">Unviewable Facilities</div>
                    <Droppable droppableId="droppable">
                      {provided => (
                        <div className="listContainer" ref={provided.innerRef}>
                          {this.state.unassignedFacilities.map((item, index) => (
                            <Draggable
                              key={item.hospitalId}
                              draggableId={item.hospitalId}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <PortalDraggable isDraggable={snapshot.isDragging}>
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {item.hospitalName}
                                  </div>
                                </PortalDraggable>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                  <div className="viewCont">
                    <div className="head">Viewable Facilities</div>
                    <Droppable droppableId="droppable2">
                      {provided => (
                        <div className="listContainer" ref={provided.innerRef}>
                          {this.state.assignedFacilities.map((item, index) => (
                            <Draggable
                              key={item.hospitalId}
                              draggableId={item.hospitalId}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <PortalDraggable isDraggable={snapshot.isDragging}>
                                  <div
                                    className="listItem"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(
                                      snapshot.isDragging,
                                      provided.draggableProps.style
                                    )}
                                  >
                                    {item.hospitalName}
                                  </div>
                                </PortalDraggable>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              </div>
              {this.state.message.length > 0 ? (
                <div className="adminRow clearfix messageSuccess">
                  {this.state.message.map((message, key) => {
                    return <p key={key}>{message}</p>;
                  })}
                </div>
              ) : null}
              {this.state.errorMessage.length > 0 ? (
                <div className="adminRow clearfix messageError">
                  {this.state.errorMessage.map((message, key) => {
                    return <p key={key}>{message}</p>;
                  })}
                </div>
              ) : null}
            </div>
            <div className="modalFoot">
              <input type="submit" value="Update" />
              <a onClick={this.closeModal} className="btnClose">
                Close
              </a>
            </div>
          </form>
        )}
      </Modal>
    );
  }
}

Details.propTypes = {
  update: PropTypes.bool,
  isOpen: PropTypes.bool,
  rideData: PropTypes.object,
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  updateRideData: PropTypes.object,
  timestamp: PropTypes.object,
  manager: PropTypes.object,
  updateManager: PropTypes.func,
  type: PropTypes.string,
  successTypes: PropTypes.object,
  errorType: PropTypes.string,
  hospitals: PropTypes.array
};

Details.defaultProps = {
  update: false,
  isOpen: false,
  rideData: {},
  handleSubmit: () => {},
  closeModal: () => {},
  error: [],
  updateRideData: {},
  timestamp: {},
  manager: {},
  updateManager: () => {},
  type: '',
  successTypes: {},
  errorType: '',
  hospitals: []
};

export default Details;
