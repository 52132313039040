import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import CheckBox from '../../../CheckBox';

const RepeatAppointments = props => {
  const cancelOneRide = () => {
    props.cancelRide();
  };
  const cancelAllRides = () => {
    props.cancelRide('all');
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.closeModal}
      className={{
        base: 'newModalBaseClass newErrorModal modalRepeatAppt',
        afterOpen: 'modalBaseClassOpen',
        beforeClose: 'modalBaseClassClose'
      }}
      overlayClassName={{
        base: 'overlayBaseClass',
        afterOpen: 'overlayBaseClassOpen',
        beforeClose: 'overlayBaseClassClose'
      }}
      closeTimeoutMS={500}
    >
      <div className="modalHead"><p>{props.heading}</p>
      </div>
      <div className="modalBody">
        <div className="newTime">
          {props.nemtToLyftRecurring && <div><p className="nemtToLyftWarning">WARNING: Any Ride currently further than 6 weeks in the future will not automatically convert to Lyft.</p>
            <p>Would you like to only change this ride or all future rides in the series?</p></div>}
          <p><a className="cancelRideButtons" onClick={cancelOneRide}>Only this ride</a> All other rides in the series will remain the same.</p>
          <p><a className="cancelRideButtons" onClick={cancelAllRides}>Following rides</a>This and all the following rides will be {props.action}.</p>
          <p>
            {props.showRoundTrip ?
              <CheckBox
                fieldName="repeatApptRoundTripCheck"
                onChangeCallback={props.rideTypeChange}
                checked={props.roundTrip}
                disabled={false}
                required={false}
                black={true}
                label="Include round trip rides."
              />
              : null
            }
          </p>
        </div>
      </div>
      <div className="modalFoot">
        <button onClick={props.closeModal}>Close</button>
      </div>
    </Modal>
  );
};

RepeatAppointments.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  cancelRide: PropTypes.func,
  heading: PropTypes.string,
  action: PropTypes.string,
  roundTrip: PropTypes.bool,
  rideTypeChange: PropTypes.func,
  showRoundTrip: PropTypes.bool
};

RepeatAppointments.defaultProps = {
  isOpen: false,
  closeModal: () => {},
  cancelRide: () => {},
  heading: 'Repeat Appointments',
  action: 'cancelled',
  roundTrip: false,
  rideTypeChange: () => {},
  showRoundTrip: false
};

export default RepeatAppointments;
