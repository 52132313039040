import immutable from 'immutability-helper';
import { isNil } from '~/utilities/guards';
import { type JsonObject } from '~/types';
import { type RideCardsRide, type RideCardsStore } from '../rideCards.types';
import moment from 'moment-timezone';
import { LYFT_VEHICLE_ID, UBER_VEHICLE_ID } from '~/constants';
import { fixUTCDate } from '~/utilities/timesAndDates';

/**
 * Creates a new object by assigning the values of oldObject
 * and newValues
 * @param {object} oldObject
 * @param {object} newValues
 * @returns
 */
export function _updateState(oldObject, newValues) {
  return Object.assign({}, oldObject, newValues);
}

/**
 * Sets the reloadPage to true on the ScheduledRides cards and
 * changes the timestamp to now
 * @param {object} cards The state object to modify
 * @returns {object} Modified new state
 */
export function setRideCardsCanReload(state: RideCardsStore) {
  if (!state.scheduledRides?.cards) {
    return state;
  }

  // Already flagged for reload
  if (state.scheduledRides.cards.reloadPage) {
    return state;
  }

  return immutable(state, {
    scheduledRides: {
      cards: {
        $merge: {
          reloadPage: true,
          timestamp: Math.floor(Date.now() / 1000)
        }
      }
    }
  });
}

/**
 * map submission data for multi leg rides
 * @param {object} rideData - ride object from api response
 * @param {object} submittedData - data submitted to ride submission endpoint
 * @return {object} - returned ride object which will be added to scheduled rides array
 */
export const mapSubmitDataMultiLeg = (rideData, submittedData) => {
  const ride = Object.assign({}, rideData, {
    fromLatitude: roundMapCoordinates(rideData.fromLatitude),
    fromLongitude: roundMapCoordinates(rideData.fromLongitude),
    toLatitude: roundMapCoordinates(rideData.toLatitude),
    toLongitude: roundMapCoordinates(rideData.toLongitude),
    blegRideId: rideData?.blegRideId ?? null,
    alegRideId: rideData?.alegRideId ?? null,
    vehicleOwnerId: rideData?.vehiclOwnerId ?? null,
    vehiclOwnerId: rideData?.vehiclOwnerId ?? null,
    nemtRunPickup: rideData?.nemtRunPickup ?? null,

    dateOfBirth: rideData?.passenger?.dateOfBirth ?? null,
    passengerFirstname: submittedData.patientName,
    passengerLastname: submittedData.patientLastName,
    passengerName: `${submittedData.patientName} ${submittedData.patientLastName}`.trim(),
    medicalId: submittedData.medicalId,
    additionalNotes: submittedData.additionalNotes,
    passengerTimezone: submittedData.passengerTz, // backwards compatibility with mongo
    passengersTimezone: submittedData.passengerTz,
    pmobileNo: submittedData.phoneNo,
    pastRide: rideData.pastRide || submittedData.rideData,
    additionalPassengers:
      rideData.additionalPassengers || submittedData.additionalPassengers,
    mode: submittedData.mode,
    lyftBookingType: null
  });

  if (submittedData?.time_custom_fields) {
    const keys = Object.keys(submittedData?.time_custom_fields);
    if (keys.length === 1) {
      ride.extraTime = submittedData?.time_custom_fields[keys[0]];
    }
  }

  if (ride.status === 'scheduled') ride.status = 'Scheduled';

  if (submittedData.lyftBookingType === 'ondemand') {
    ride.lyftBookingType = 'ondemand';
  }

  if ('phone2' in submittedData) {
    ride.ride_options ??= {};
    ride.ride_options.phone2 = submittedData.phone2;
  }

  if ('assistanceNeeds' in submittedData) {
    ride.ride_options ??= {};
    ride.ride_options.assistance_needs = submittedData.assistanceNeeds;
  }

  if ('treatmentId' in submittedData) {
    ride.treatment_id = submittedData.treatmentId;
  }

  if ('treatmentName' in submittedData) {
    ride.treatment_name = submittedData.treatmentName;
  }

  return ride;
};

/**
 * Map submit data
 *
 * @param {object} responseData - ride data from submit api response
 * @param {object} submittedData - data from ride booking
 * @param {string} bookedByName - user name of logged in care provider
 * @return {object} ride - returns ride data
 */
export const mapSubmitData = (responseData, submittedData, bookedByName = '') => {
  const ride: RideCardsRide = {
    appointmentTime: responseData.appointmentTime,
    additionalPassengers: submittedData.additionalPassengers || [],
    additionalNotes: responseData.additionalNotes,
    bookedBy: responseData.bookedBy,
    cost: responseData.cost,
    created_at: responseData.created_at,
    distance: responseData.distance,
    estimatedFinishTime: responseData.estimatedFinishTime,
    fromAddress: responseData.fromAddress,
    fromLatitude: roundMapCoordinates(responseData.fromLatitude),
    fromLongitude: roundMapCoordinates(responseData.fromLongitude),
    fromZipcode: responseData.fromZipcode,
    hospitalId: responseData.hospitalId,
    id: responseData.id,
    pastRide: responseData.pastRide || false,
    passengerId: responseData.passengerId,
    recurring_rideId: responseData.recurring_rideId,
    recurringsummary: submittedData.recurring_summary,
    reqVehicleType: responseData.reqVehicleType,
    rideStartTime: responseData.rideStartTime,
    rideType: responseData.rideType,
    status: responseData.status,
    stripeTokenId: responseData.stripeTokenId,
    toAddress: responseData.toAddress,
    toLatitude: roundMapCoordinates(responseData.toLatitude),
    toLongitude: roundMapCoordinates(responseData.toLongitude),
    toZipcode: responseData.toZipcode,
    updated_at: responseData.updated_at,
    pickup_venue_id: responseData.pickup_venue_id,
    pickupVenueName: responseData.pickupVenueName,
    pickupEntranceName: responseData.pickupEntranceName,
    dropoff_venue_id: responseData.dropoff_venue_id,
    dropoffVenueName: responseData.dropoffVenueName,
    dropoffEntranceName: responseData.dropoffEntranceName,
    blegRideId: responseData?.blegRideId ?? null,
    alegRideId: responseData?.alegRideId ?? null,
    vehicleOwnerId: responseData?.vehiclOwnerId ?? null,
    vehiclOwnerId: responseData?.vehiclOwnerId ?? null,
    rideCustomFieldValues: submittedData?.ride_custom_fields ?? null,
    patientCustomFieldValues: submittedData?.patient_custom_fields ?? null,
    complianceValues: submittedData?.compliance ?? null,
    nemtRunPickup: responseData?.nemtRunPickup ?? null,
    original_ride_id: submittedData?.originalRideId ?? null,

    rideSentTime: responseData.rideSentTime,
    rideSentToDriver: responseData.rideSentToDriver,
    passengerFirstname: submittedData.patientName,
    passengerLastname: submittedData.patientLastName,
    passengerName: `${submittedData.patientName} ${submittedData.patientLastName}`.trim(),
    medicalId: submittedData.medicalId,
    passengerTimezone: submittedData.passengerTz, // backwards compatibility with mong,
    passengersTimezone: submittedData.passengerTz,
    pmobileNo: submittedData.phoneNo,
    lyftBookingType: null
  };

  if (submittedData?.time_custom_fields) {
    const keys = Object.keys(submittedData.time_custom_fields);
    if (keys.length === 1) {
      ride.extraTime = submittedData.time_custom_fields[keys[0]];
    }
  }

  if (isNil(responseData.passengerCost)) {
    ride.passengerCost = responseData.passengerCost;
  }

  if (ride.status === 'scheduled') ride.status = 'Scheduled';

  if (isNil(bookedByName) && bookedByName) {
    ride.bookedByName = bookedByName;
  }

  if (submittedData?.dateOfBirth) {
    ride.dateOfBirth = moment(submittedData?.dateOfBirth, 'MM/DD/YYYY').format(
      'YYYY-MM-DD'
    );
  }

  if (submittedData.lyftBookingType === 'ondemand') {
    ride.lyftBookingType = 'ondemand';
  }

  return ride;
};

/**
 * mapPusherData - transform new pusher data to the ride card format
 * @param {object} rideData - pusher data
 * @param {object} vehicleTypes - ambulance, sedan, wheel chair van, etc
 * @param {object} oldRideState - appointmentTime, rideStartTime, additionalNotes
 * @return {object} - returns mapped pusher data
 */
export const mapPusherData = (
  rideData,
  vehicleTypes = {},
  replaceRideState: Partial<RideCardsRide> = {}
) => {
  const rideDetails = rideData?.rideDetails;
  const rideId = rideDetails?.id ?? rideData.rideId;

  let driverName: string | null = null;
  let dmobileNo: string | null | undefined = null;
  let dmobilePin: string | undefined;
  let driverId: number | null | undefined = null;
  let driver: {
    pin_based_phone_number?: JsonObject;
    firstName?: string;
    lastName?: string;
    id?: number;
    dmobileNo?: string;
    avatar?: string | null;
    rating?: number;
    mobileNo?: string;
    companyId?: number | null;
  } = {};
  let driverFirstName: string | null = null;
  let driverLastName: string | null = null;
  let avatar: string | null | undefined = null;
  let vehicleOwnerRating: number | null | undefined = null;

  if (rideDetails?.driver?.firstName) {
    driver = rideData.rideDetails.driver;

    driverId = driver.id;

    driverFirstName = driver?.firstName as string | null;
    driverLastName = driver?.lastName as string | null;
    driverName = `${driverFirstName} ${driverLastName ?? ''}`;
    dmobileNo = driver?.dmobileNo;
    avatar = driver?.avatar;
    vehicleOwnerRating = driver?.rating;

    if (driver?.pin_based_phone_number) {
      dmobileNo = driver?.pin_based_phone_number?.phone_number as string | undefined;
      dmobilePin = driver?.pin_based_phone_number?.pin as string | undefined;
    }
  } else if (rideData?.driverDetails?.firstName) {
    driver = rideData.driverDetails;

    driverId = rideData.driverDetails.id;

    driverFirstName = driver?.firstName as string | null;
    driverLastName = driver?.lastName as string | null;
    driverName = `${driverFirstName} ${driverLastName ?? ''}`;

    dmobileNo = driver?.mobileNo;
    avatar = driver?.avatar;
    vehicleOwnerRating = driver?.rating;
  } else if (rideDetails?.driverId) {
    driverId = rideData.rideDetails.driverId;
  }

  const passenger = rideDetails?.passenger ?? rideDetails?.patientDetails ?? {};

  let companyName = null;
  if (rideDetails?.vehicle_owner?.companyName) {
    companyName = rideDetails?.vehicle_owner?.companyName;
  } else if (rideData?.vehicleDetails?.vehicleCompanyName) {
    companyName = rideData?.vehicleDetails?.vehicleCompanyName;
  } else if (rideData?.vehicleDetails?.companyName) {
    companyName = rideData?.vehicleDetails?.companyName;
  }

  let vehicleInUse: Record<string, unknown> | null = {};
  if (rideData?.vehicleDetails?.id) {
    vehicleInUse = rideData?.vehicleDetails;
  } else if (rideDetails?.driver?.current_vehicle?.id) {
    vehicleInUse = rideDetails?.driver?.current_vehicle;
  }

  // appointment time data
  let appointmentTime = replaceRideState?.appointmentTime
    ? replaceRideState.appointmentTime
    : rideData?.rideDetails?.appointmentTime;

  const rideAppointmentTime = rideDetails?.rideAppointmentTime
    ? fixUTCDate(rideDetails.rideAppointmentTime, 'UTC', 'hh:mm:ss A', 'hh:mm A')
    : null;

  appointmentTime = appointmentTime ?? rideAppointmentTime;

  let tripRides = replaceRideState?.tripRides ?? rideData?.rideDetails?.tripRides;
  tripRides ??= [];

  const reqVehicleType = rideDetails?.reqVehicleType ?? 0;
  const reqVehicleModel = reqVehicleType ? vehicleTypes?.[reqVehicleType]?.modelName : '';

  // properties for cancellation
  const cancelledByUser = rideData?.cancelledByUser ?? rideDetails?.cancelledByUser;
  const cancelledByUserRole =
    rideData?.cancelledByUserRole ?? rideDetails?.cancelledByUserRole;

  const reject_reason = rideData?.reject_reason ?? rideDetails?.reject_reason;
  const reject_reason_txt = rideData?.reject_reason_txt ?? rideDetails?.reject_reason_txt;

  let hospitalId =
    rideData?.rideDetails?.hospitalId ?? rideData?.rideDetails?.hospital?.id;

  if (!hospitalId) {
    hospitalId = passenger?.hospitalId;
  }

  let vehicleOwnerId: number | null | undefined;
  if (rideData?.rideDetails?.vehiclOwnerId) {
    vehicleOwnerId = rideData?.rideDetails?.vehiclOwnerId;
  } else if (rideData?.rideDetails?.vehicle_owner?.id) {
    vehicleOwnerId = rideData?.rideDetails?.vehicle_owner?.id;
  } else {
    vehicleOwnerId = driver?.companyId;
  }

  const route = rideDetails?.status === 'Started' ? 'startedRoute' : 'inboundRoute';

  const voContactNo =
    rideData?.driverDetails?.vehicle_owner?.contactNo2 ??
    rideData?.rideDetails?.vehicle_owner?.contactNo2 ??
    '';

  const editedRide: RideCardsRide = {
    id: rideId,

    status: rideDetails?.status ?? '',
    distance: rideDetails?.distance ?? 0,
    duration: rideData?.rideDetails?.rideETA?.[route]?.duration,
    pastRide: rideDetails?.pastRide ? true : false,
    mode: rideData?.mode,
    alegRideId: rideDetails?.alegRideId,
    blegRideId: rideDetails?.blegRideId,
    trip_id: rideDetails?.trip_id,
    tripRides,
    additionalPassengers: rideDetails?.additionalPassengers ?? [],
    timezone_format: rideDetails?.timezone_format,
    /** Recurring Rides */
    recurring_rideId: rideDetails?.recurring_rideId,
    recurringsummary: rideDetails?.recurring_summary,

    /** Medical data */
    medicalId: passenger?.medicalId ?? rideDetails?.medicalId,
    hospitalId,
    health_sub_plan_id: passenger?.health_sub_plan_id,
    health_plan_id: passenger?.health_plan_id,

    /** Treatment data */
    treatment_id: rideDetails?.treatment_id,
    treatment_name: rideDetails?.treatment_name,

    /** Passenger data */
    dateOfBirth: passenger?.dateOfBirth,
    pmobileNo: passenger?.mobileNo,
    passengerName: `${passenger?.firstName?.trim() ?? ''} ${
      passenger?.lastName?.trim() ?? ''
    }`,
    passengerFirstname: passenger?.firstName ?? '',
    passengerLastname: passenger?.lastName ?? '',
    passengerTimezone: passenger?.timezone_format,
    passengerCost: rideDetails?.passengerCost,
    internalNotes: passenger?.internalNotes,

    /** Driver data */
    driverId,
    driverName,
    driverFirstName,
    driverLastName,
    dmobileNo,
    dmobilePin,
    avatar,

    /** Vehicle data */
    companyName,
    vehicleOwnerId,
    vehiclOwnerId: vehicleOwnerId,
    vehicleOwnerRating,
    vehiclePlateNo: vehicleInUse?.vehiclePlateNo as string | null,
    timestamp: (vehicleInUse?.timestamp as string) ?? '',
    operating_hours_end: rideDetails?.vehicle_owner?.regulator_operating_hours_end ?? '',
    voMainBusinessLine: voContactNo,

    /** Address data */
    toAddress: rideDetails?.toAddress ?? '',
    toZipcode: rideDetails?.toZipCode ?? '',
    fromAddress: rideDetails?.fromAddress ?? '',
    fromZipcode: rideDetails?.fromZipcode ?? '',
    // round latitude and longitude to 6 digits to prevent comparison bugs.
    // the database stores lat/lng with a 6 digit accuracy while google gives us accuracy of 7 digits
    // this causes comparison issues in edit ride
    toLatitude: roundMapCoordinates(rideData?.rideDetails?.toLatitude),
    toLongitude: roundMapCoordinates(rideData?.rideDetails?.toLongitude),
    fromLatitude: roundMapCoordinates(rideData?.rideDetails?.fromLatitude),
    fromLongitude: roundMapCoordinates(rideData?.rideDetails?.fromLongitude),

    /** Venues */
    pickup_venue_id: rideDetails?.pickup_venue_id,
    pickupEntranceName: rideDetails?.pickupEntranceName,
    pickupVenueName: rideDetails?.pickupVenueName,

    dropoff_venue_id: rideDetails?.dropoff_venue_id,
    dropoffEntranceName: rideDetails?.dropoffEntranceName,
    dropoffVenueName: rideDetails?.dropoffVenueName,

    /** Approved Providers */
    pickupProviderId: rideDetails?.pickup_provider_id ?? 0,
    pickupProviderName: rideDetails?.pickupProviderName ?? '',
    pickupProviderAddress: rideDetails?.pickupProviderAddress ?? '',
    pickupProviderNpi: rideDetails?.pickupProviderNpi ?? 0,
    pickupProviderPhone: rideDetails?.pickupProviderPhone ?? '',
    dropoffProviderId: rideDetails?.dropoff_provider_id ?? 0,
    dropoffProviderName: rideDetails?.dropoffProviderName ?? '',
    dropoffProviderAddress: rideDetails?.dropoffProviderAddress ?? '',
    dropoffProviderNpi: rideDetails?.dropoffProviderNpi ?? 0,
    dropoffProviderPhone: rideDetails?.dropoffProviderPhone ?? 0,

    /** Times */
    appointmentTime,
    pickupTime: rideDetails?.ridePickupTime,
    rideStartTime: rideDetails?.rideStartTime ?? replaceRideState?.rideStartTime ?? null,
    estimatedFinishTime: rideDetails?.estimatedFinishTime,
    extraTime: rideDetails?.extraTime,

    /** Rejected Data */
    cancelText: reject_reason ?? '',
    reject_reason_txt: reject_reason_txt ?? '',
    reject_reason_msg: rideDetails?.reject_reason_msg,

    /** Cancellation Data */
    cancelledByUser,
    cancelledByUserRole,
    cancelledBy: rideDetails?.cancelledBy ?? '',

    /** Custom fields */
    rideCustomFieldValues: rideDetails?.rideCustomFieldValues,
    patientCustomFieldValues: rideDetails?.patientCustomFieldValues,
    complianceValues: rideDetails?.complianceValues,

    /** "Original" fields */
    originalAppointmentTime: rideDetails?.originalAppointmentTime,
    originalRideStartTime: rideDetails?.originalRideStartTime,
    originalToAddress: rideDetails?.originalToAddress,
    originalFromAddress: rideDetails?.originalFromAddress,
    originalReqVehicleType: rideDetails?.originalReqVehicleType,

    /** Reordered rides */
    original_ride_id: rideDetails?.original_ride_id,
    reordered_ride_id: rideDetails?.reordered_ride_id,

    cost: rideDetails?.cost ?? 0,
    bookedByName: rideDetails?.bookedByName ?? '',
    dispatcher_managed: rideDetails?.dispatcher_managed ?? 0,
    noshow_flag: passenger?.noshow_flag,
    confirmation_status: rideDetails?.confirmation_status,
    nemtRunPickup: rideDetails?.nemtRunPickup,
    ride_options: rideDetails?.ride_options,
    modelName: reqVehicleModel,
    additionalNotes: replaceRideState?.additionalNotes ?? passenger?.otherDetails ?? null,
    reqVehicleType
  };

  if (replaceRideState?.lyftBookingType) {
    editedRide.lyftBookingType = replaceRideState.lyftBookingType;
  }

  if (rideData.rideDetails.lyft_flex_ride) {
    editedRide.lyft_flex_ride = rideData.rideDetails.lyft_flex_ride;
  }

  return editedRide;
};

/**
 * Order scheduled rides by rideStartTime
 *
 * @param {array} rides - array of scheduled rides
 * @return {object} object of array of rides and array of ride ids sorted
 */
export const orderScheduledRides = rides => {
  return rides.sort((a, b) => {
    if (!('rideStartTime' in a) || !('rideStartTime' in b)) {
      return 0;
    }

    if (a['rideStartTime'] === b['rideStartTime']) return 0;

    const rideA = moment(a['rideStartTime']);
    const rideB = moment(b['rideStartTime']);

    return rideA.isBefore(rideB) ? -1 : 1;
  });
};

/**
 * takes 7 decimal lat/lng and rounds it to 6 digits
 * @param {string} coord - string coordinate from pusher
 * @return {string} - returns lat/lng
 */
export const roundMapCoordinates = coord => {
  if (isNil(coord) || coord === '') {
    return coord;
  }
  const floatCoord = parseFloat(coord);

  return floatCoord.toFixed(6);
};

export const mapRideShareRide = (ride: RideCardsRide) => {
  const reqVehicleType = ride?.reqVehicleType;

  if (!reqVehicleType && reqVehicleType !== 0) {
    return ride;
  }

  if (![LYFT_VEHICLE_ID, UBER_VEHICLE_ID].includes(reqVehicleType)) {
    return ride;
  }

  if (reqVehicleType === LYFT_VEHICLE_ID) {
    const lyftRideData = ride.lyftRideData ?? {};

    ride.companyName = 'Lyft';
    ride.vehicleYear = lyftRideData?.vehicle?.year ?? '';
    ride.vehiclePlateNo = lyftRideData?.vehicle?.license_plate ?? '';
    ride.vehiclePlateState = lyftRideData?.vehicle?.license_plate_state ?? '';
    ride.vehicleColor = lyftRideData?.vehicle?.color ?? '';
    ride.driverFirstName = lyftRideData?.driver?.first_name ?? '';
    ride.driverLastName = '';
    ride.dmobileNo = lyftRideData?.driver?.phone_number ?? '';
    ride.vehicleName = [
      lyftRideData?.vehicle?.make ?? '',
      lyftRideData?.vehicle?.model ?? ''
    ].join(' ');
    ride.avatar = lyftRideData?.driver?.image_url ?? '';
  } else {
    const uberRideData = ride.uberRideData ?? {};

    ride.companyName = 'Uber';
    ride.vehicleYear = uberRideData?.vehicle?.year ?? '';
    ride.vehiclePlateNo = uberRideData?.vehicle?.license_plate ?? '';
    ride.vehiclePlateState = uberRideData?.vehicle?.license_plate_state ?? '';
    ride.vehicleColor = uberRideData?.vehicle?.color ?? '';
    ride.driverFirstName = uberRideData?.driver?.name ?? '';
    ride.driverLastName = '';
    ride.dmobileNo = uberRideData?.driver?.phone_number ?? '';
    ride.vehicleName = [
      uberRideData?.vehicle?.make ?? '',
      uberRideData?.vehicle?.model ?? ''
    ].join(' ');
    ride.avatar = uberRideData?.driver?.picture_url ?? '';

    // Uber provides a special pin extension so CSRs can call the driver
    if (uberRideData?.driver?.pin_based_phone_number) {
      ride.dmobileNo =
        uberRideData.driver?.pin_based_phone_number?.phone_number ?? ride.dmobileNo;
      ride.dmobilePin = uberRideData.driver?.pin_based_phone_number?.pin ?? null;
    }
  }

  return ride;
};
