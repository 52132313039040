import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ridesPreAuthorize, availableCards } from '~/Modules/scheduleRides';
import { mapMultiLegBooking } from '~/utilities/booking.helper';
import {
  BOOKING_TYPES,
  LYFT_VEHICLE_ID as LYFT_VEHICLE_TYPE,
  UBER_VEHICLE_ID as UBER_VEHICLE_TYPE
} from '~/constants';
import { cloneDeep, get } from 'lodash-es';

const Retry = ({ toggleLoading, closeRequestNewRide, error }) => {
  const dispatch = useDispatch();
  const { bookingData, user } = useSelector(state => state);
  const { rides } = bookingData;

  const handleRetry = () => {
    const { bookingType, transportType } = bookingData;
    toggleLoading(true, 'Requesting Ride...', 80);

    const isRideShare =
      transportType === LYFT_VEHICLE_TYPE || transportType === UBER_VEHICLE_TYPE;
    /**
     * TODO:UBER — ONDEMAND
     */
    const requestBody = mapMultiLegBooking(
      cloneDeep(bookingData),
      get(user, 'userData.role', '')
    );
    if (bookingType === BOOKING_TYPES.ON_DEMAND && isRideShare) {
      dispatch(availableCards({ ...requestBody, rides }));
    } else {
      dispatch(ridesPreAuthorize({ ...requestBody, rides }));
    }
  };
  return (
    <div className="RideConfirmation">
      <div className={`messageBlock stop`}>
        {error.clientMessage ||
          error.message ||
          'Something went wrong. You can retry your request, or cancel this ride.'}
      </div>
      <button className="bookButton" onClick={handleRetry}>
        Retry Request
      </button>
      <button onClick={closeRequestNewRide} className="bookButton stop">
        Cancel Request
      </button>
    </div>
  );
};

export default Retry;
