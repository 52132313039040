import React from 'react';

import {
  InputField,
  AgeGroupInput,
  MobilityTypeInput,
  SubMobilityTypeInput
} from '~/Pages/ExpenseSlideout/Components/Slideout/ExpenseInputs';

/**
 * @name passengerFormInputs
 * @param {Object} np new passenger
 * @param {func} setPassenger state setter for passenger
 * @param {Array} vehicle_types vehicle data to popoulate vehicle type dropdowns
 * @param {boolean} shouldUseSubMobilityType
 * @returns {Array<Object>} structured collection of components for the the form
 */
const passengerFormInputs = ({
  np,
  setPassenger,
  vehicle_types,
  shouldUseSubMobilityType
}) => {
  const formInputsArr = [
    [
      {
        label: 'Adult/Child',
        name: 'ageGroup',
        value: np.ageGroup,
        required: true,
        Component: () => <AgeGroupInput ageGroup={np.ageGroup} setField={setPassenger} />
      },
      {
        label: 'Mobility Type',
        name: 'mobilityType',
        value: np.mobilityType,
        required: true,
        Component: () => (
          <MobilityTypeInput
            setField={setPassenger}
            vehicle_types={vehicle_types}
            mobilityType={np.mobilityType}
          />
        )
      }
    ],
    [
      {
        label: 'First Name',
        name: 'firstName',
        value: np.firstName,
        required: false,
        Component: [
          <InputField
            key="firstName"
            name="firstName"
            value={np.firstName}
            setField={setPassenger}
            maxLength={50}
          />
        ]
      },
      {
        label: 'Last Name',
        name: 'lastName',
        value: np.lastName,
        required: false,
        Component: [
          <div key="lastName">
            <input
              className="exp-input"
              maxLength={50}
              onChange={({ target: { value } }) =>
                setPassenger({
                  name: 'lastName',
                  value
                })
              }
              value={np.lastName}
            />
          </div>
        ]
      }
    ],
    [
      {
        name: 'isAttendant',
        show: true,
        label: '',
        value: np.isAttendant,
        required: false,
        Component: [
          np.ageGroup === 'child' ? (
            <div key="isAttendant" style={{ minHeight: '32px' }}></div>
          ) : (
            <div key="isAttendant">
              <input
                type="checkbox"
                checked={np.isAttendant}
                onChange={({ target: { checked } }) =>
                  setPassenger({ name: 'isAttendant', value: !!checked })
                }
              />{' '}
              <div className="label">Attendant</div>
            </div>
          )
        ]
      }
    ]
  ];
  if (shouldUseSubMobilityType) {
    formInputsArr[0].push({
      label: 'Sub Mobility Type',
      name: 'subMobilityType',
      value: np.subMobilityType,
      required: false,
      Component: () => (
        <SubMobilityTypeInput
          setField={setPassenger}
          vehicle_types={vehicle_types}
          mobilityType={np.mobilityType}
          subMobilityType={np.subMobilityType}
        />
      )
    });
  }
  return formInputsArr;
};

export default passengerFormInputs;
