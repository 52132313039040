import { hasBecomeActive, hasBeenAbandoned, rideBookingMiddleware } from '.';
import type { AppListenerEffectAPI } from '../..';
import { analyticsService } from '~/analytics';
import { completeFundingSourceThunk, initializeRbfNewThunk } from '../thunks';
import type { AppStore } from '~/types';
import { selectActiveTreatment, selectIsBlocked } from '../selectors';

const EVENT_NAME = 'RBF Funding Source';

let sectionTime = 0;
let startPoint = 0;

/** Reset the section time and start time to 0 when
 * loading new RBF. */
rideBookingMiddleware.startListening({
  actionCreator: initializeRbfNewThunk.fulfilled,
  effect: () => {
    sectionTime = 0;
    startPoint = 0;
  }
});

rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBecomeActive('funding-source', currentState, oldState),
  effect: (_, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    startPoint = Date.now();
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Started',
      BlockType: 'None',
      TimeOnSection: sectionTime,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Dispatch Funding Source completed event. */
rideBookingMiddleware.startListening({
  actionCreator: completeFundingSourceThunk.fulfilled,
  effect: (_, { getState }: AppListenerEffectAPI) => {
    const user = getState().user;

    const activeTreatment = selectActiveTreatment(getState());
    const [_isBlocked, blockType] = selectIsBlocked(getState());

    sectionTime += Date.now() - startPoint;

    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Completed',
      TimeOnSection: sectionTime,
      BlockType: blockType ?? 'None',
      TreatmentSelected: activeTreatment?.treatment_name,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Abandoned ride. */
rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBeenAbandoned('funding-source', currentState, oldState),
  effect: (_, { getState }: AppListenerEffectAPI) => {
    const user = getState().user;

    const activeTreatment = selectActiveTreatment(getState());
    const [_isBlocked, blockType] = selectIsBlocked(getState());

    sectionTime += Date.now() - startPoint;

    // Dispatch RBF Passenger Info Abandoned event
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Abandoned',
      TimeOnSection: sectionTime,
      BlockType: blockType ?? 'None',
      TreatmentSelected: activeTreatment?.treatment_name,
      ...analyticsService.mapUserProps(user)
    });

    startPoint = 0;
  }
});
