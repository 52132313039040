import { INITIAL_STATE, scheduledRidesPages } from './rideCards.constants';
import { rideCardActions } from './rideCards.actions';
import {
  handleUpdateRideCard,
  handleResetScheduledRides,
  handleRideInfoUpdate,
  handleRidesFetchSuccess,
  handleBulkUpdateEvent,
  handleAddRide,
  handleCancelRideCost,
  handleCancelRide,
  handleUpdateDate,
  handleUpdateDateCheck,
  handleSetLoadingComponent,
  handleUpdateRide,
  handleUpdateRideCheck,
  handleGeneralUpdateRideError,
  handleCancelRideError,
  handleUpdateWillCallWithChat,
  handleUpdateWillCallWithChatError,
  handleRequestPickup,
  handleRequestPickupError,
  handleResetCards,
  handleResetUpdatedCards,
  handleResetShowWillCallReadyNowError,
  handleRideClaimStatusUpdate,
  handleRideLocationUpdate,
  handleRideStatusUpdate,
  handleNewRide,
  handleAddLyftOnDemand,
  handleRideCreatedEvent
} from './rideCards.handlers';
import { _updateState } from './utils/rideCards.utils';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from '../safeAxios';
import { token } from '~/utilities/auth.helper';
import { injectPusher } from '~/utilities/pusherRedux';

export default (state = INITIAL_STATE, action) => {
  const updateState = _updateState.bind(null, state);

  switch (action.type) {
    case rideCardActions.CANCEL_RIDE_COST:
      return handleCancelRideCost(state, action, updateState);

    case rideCardActions.CANCEL_RIDE:
      return handleCancelRide(state, action);

    case rideCardActions.UPDATE_DATE:
      return handleUpdateDate(state, action);

    case rideCardActions.UPDATE_DATE_CHECK:
      return handleUpdateDateCheck(state, action);

    case rideCardActions.SET_LOADING_COMPONENT:
      return handleSetLoadingComponent(state, action);

    case rideCardActions.UPDATE_RIDE:
      return handleUpdateRide(state, action);

    case rideCardActions.UPDATE_RIDE_CHECK:
      return handleUpdateRideCheck(state, action);

    case rideCardActions.UPDATE_DATE_CHECK_ERROR:
    case rideCardActions.UPDATE_DATE_ERROR:
    case rideCardActions.CANCEL_RIDE_COST_ERROR:
    case rideCardActions.UPDATE_RIDE_CHECK_ERROR:
    case rideCardActions.UPDATE_RIDE_ERROR:
      return handleGeneralUpdateRideError(state, action);

    case rideCardActions.CANCEL_RIDE_ERROR:
      return handleCancelRideError(state, action);

    case rideCardActions.UPDATE_WILL_CALL_WITH_CHAT:
      return handleUpdateWillCallWithChat(state, action);

    case rideCardActions.UPDATE_WILL_CALL_WITH_CHAT_ERROR:
      return handleUpdateWillCallWithChatError(state, action);

    case rideCardActions.REQUEST_PICKUP:
      return handleRequestPickup(state, action);

    case rideCardActions.REQUEST_PICKUP_ERROR:
      return handleRequestPickupError(state, action);

    case rideCardActions.RESET_CARDS:
      return handleResetCards(state);

    case rideCardActions.RESET_UPDATED_CARDS:
      return handleResetUpdatedCards(state);

    case rideCardActions.RESET_SHOW_WILL_CALL_READY_NOW_ERROR:
      return handleResetShowWillCallReadyNowError(state);

    case rideCardActions.RESET_SHOW_UPDATE_DATE_ERROR:
      return { ...state, showUpdateDateError: false, loadingEditRide: false };

    case rideCardActions.BULK_EDIT_EVENT:
      return handleBulkUpdateEvent(state);

    case rideCardActions.RIDE_CARDS_FETCH_START:
      return updateState({ loadingRideCards: true });

    case rideCardActions.RIDE_CARDS_FETCH_SUCCESS:
      return handleRidesFetchSuccess(state, action, updateState);

    case rideCardActions.RIDE_CARDS_FETCH_FAIL:
      return updateState({ loadingRideCards: false });

    case rideCardActions.RIDE_INFO_UPDATE:
      return handleRideInfoUpdate(state, action);

    case rideCardActions.RIDE_CREATED:
      return handleRideCreatedEvent(state, action);

    case rideCardActions.RIDE_CLAIM_STATUS_UPDATE:
      return handleRideClaimStatusUpdate(state, action);

    case rideCardActions.RIDE_LOCATION_UPDATE:
      return handleRideLocationUpdate(state, action);

    case rideCardActions.RIDE_STATUS_UPDATE:
      return handleRideStatusUpdate(state, action, updateState);

    case rideCardActions.NEW_RIDE:
      return handleNewRide(state, action, updateState);

    case rideCardActions.ADD_LYFT_ON_DEMAND:
      return handleAddLyftOnDemand(state, action);

    case rideCardActions.ADD_RIDE:
      return handleAddRide(state, action, updateState);

    case rideCardActions.UPDATE_RIDE_CARD:
      return handleUpdateRideCard(state, action, updateState);

    case rideCardActions.RESET_SCHEDULED_RIDES:
      return handleResetScheduledRides(state, updateState);

    default:
      return state;
  }
};

/**
 * rideCards watcher saga
 */
export function* rideCardsSaga() {
  // worker sagas
  yield takeEvery(rideCardActions.RIDE_CARDS_FETCH_START, fetchRideCardsWorkerSaga);
  yield takeEvery(rideCardActions.PUSHER_PULL, pusherPullWorkerSaga);
}

/**
 * Worker saga for fetching ride cards data based on action parameters. Constructs an API call to fetch scheduled rides, handling
 * both success and failure scenarios by updating the state with fetched data or error respectively.
 */
function* fetchRideCardsWorkerSaga(action) {
  try {
    const state = yield select();
    const user = state.user;

    // if we're on scheduled rides page add that call
    if (scheduledRidesPages.indexOf(action?.data?.page ?? '') > -1) {
      let scheduledRidesUrl = `${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/list/${action.data.page}/${action.data.start}/${action.data.limit}`;
      let searchByDate = true;

      if (action?.data?.query) {
        scheduledRidesUrl = `${scheduledRidesUrl}?query=${action.data.query}`;

        // we want to pull any rideId regardless of date.
        if (/^\d+$/.test(action.data.query)) searchByDate = false;
      }

      if (action?.data?.searchByDateForce) searchByDate = true;

      if (action?.data?.fromDate && action?.data?.toDate && searchByDate) {
        const char = scheduledRidesUrl.indexOf('?') > -1 ? '&' : '?';
        scheduledRidesUrl = `${scheduledRidesUrl}${char}fromDate=${action.data.fromDate}&toDate=${action.data.toDate}`;
      }

      const scheduledRides = yield fetch(scheduledRidesUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token()}`,
          'Content-Type': 'application/json'
        }
      }).then(res => res.json());

      yield put({
        type: rideCardActions.RIDE_CARDS_FETCH_SUCCESS,
        scheduledRides: scheduledRides?.data ?? null,
        params: action.data,
        user
      });
    }
  } catch (error) {
    yield put({
      type: rideCardActions.RIDE_CARDS_FETCH_FAIL,
      error
    });
  }
}

/**
 * Worker saga for handling pusher liverides api events
 */
function* pusherPullWorkerSaga(action) {
  if (action.data.toString().match(/[^\w\d\s]/)) return;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload = yield call(axios as (...args: any[]) => any, {
    method: 'GET',
    url: `//${process.env.REACT_APP_LIVERIDES_API}/pusher/${action.data}`,
    headers: {
      'Accept-Version': process.env.REACT_APP_LIVERIDES_API_VERSION,
      'Token': token()
    }
  });
  const payloadData = JSON.parse(payload.data.data);
  injectPusher(action.channel, payloadData.name, payloadData.data);
}
