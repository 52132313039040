import { put, select, takeEvery } from 'redux-saga/effects';
import _ from 'lodash-es';
import axios from './safeAxios';
import { token } from '~/utilities/auth.helper';
import { cloneDeep } from '~/utilities/helperFunctions';
import { getApiEndpointUri } from '~/utilities/configureImporter';

export const IMPORTER_JOB_GET = 'IMPORTER/job/get';
export const IMPORTER_JOB_GET_SUCCESS = 'IMPORTER/job/get/success';
export const IMPORTER_JOB_GET_ERROR = 'IMPORTER/job/get/error';
export const IMPORTER_POST_UPLOAD = 'IMPORTER/post/upload';
export const IMPORTER_POST_UPLOAD_SUCCESS = 'IMPORTER/post/upload/success';
export const IMPORTER_POST_UPLOAD_ERROR = 'IMPORTER/post/upload/error';
export const IMPORTER_POST_FIELD_MAPPING = 'IMPORTER/post/fieldMapping';
export const IMPORTER_POST_FIELD_MAPPING_SUCCESS = 'IMPORTER/post/fieldMapping/success';
export const IMPORTER_JOB_RESET = 'IMPORTER/job/reset';

/**
 * Dispatch action to get an importer job
 * @param {Object} params Params from component
 * @returns {Function} Action dispatch function
 */
export const getJob = (params = {}) => {
  return dispatch => {
    dispatch({
      type: IMPORTER_JOB_GET,
      data: params
    });
  };
};

/**
 * Dispatch action to upload a file
 * @param {Object} params Params from component
 * @returns {Function} Action distach function
 */
export const uploadFile = (params = {}) => {
  return dispatch => {
    dispatch({
      type: IMPORTER_POST_UPLOAD,
      data: params
    });
  };
};

/**
 * Dispatch action to update a field mapping
 * @param {Object} params Params from component
 * @returns {Function} Action distach function
 */
export const updateFieldMapping = (params = {}) => {
  return dispatch => {
    dispatch({
      type: IMPORTER_POST_FIELD_MAPPING,
      data: params
    });
  };
};

/**
 * Get importer job from API
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* getImporterJobFromApi(action = {}) {
  try {
    const params = action.data;
    const user = yield select(state => state.user);
    const planId = user?.healthPlan?.id ?? 0;
    const config = {
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/plan/${planId}/importer/job/${params.jobId}`,
      headers: {
        Authorization: `Bearer ${token()}`
      }
    };

    const results = yield axios(config);
    const data = results.data.data[0];

    yield put({ type: IMPORTER_JOB_GET_SUCCESS, data, params });
  } catch (error) {
    yield put({ type: IMPORTER_JOB_GET_ERROR, error });
  }
}

/**
 * Upload file to S3 via API and create new importer job
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* uploadFileToApi(action = {}) {
  let results = {};
  try {
    const params = action.data;
    const user = yield select(state => state.user);
    const userId = user?.userData?.id ?? 0;
    const healthPlanId = user?.healthPlan?.id ?? 0;

    const formData = new FormData();
    formData.append('file', params.file);
    formData.append('userId', userId);

    if (params.subPlanId > 0) formData.append('subPlanId', params.subPlanId);
    if (healthPlanId > 0) formData.append('healthPlanId', healthPlanId);

    const config = {
      method: 'POST',
      url: getApiEndpointUri(params.importerType, true),
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      data: formData
    };
    // reset
    yield put({ type: IMPORTER_JOB_RESET, data: {} });
    results = yield axios(config);
    if (results.data.status === true) {
      const data = results.data.data;
      params.file = {
        name: params.file.name,
        lastModified: params.file.lastModified,
        size: params.file.size,
        type: params.file.type
      };
      yield put({ type: IMPORTER_POST_UPLOAD_SUCCESS, data, params });
    } else {
      yield put({ type: IMPORTER_POST_UPLOAD_ERROR, error: results.data.error });
    }
  } catch (error) {
    if (error?.response?.data?.errors) {
      let errorList = '';
      _.forOwn(error.response.data.errors, singleError => {
        errorList = errorList + singleError.message + '\n';
      });
      yield put({ type: IMPORTER_POST_UPLOAD_ERROR, error: errorList });
    } else {
      yield put({ type: IMPORTER_POST_UPLOAD_ERROR, error });
    }
  }
}

/**
 * Update importer job with new field mapping (key/value object)
 * @param {Object} action Action dispatch params
 * @returns {undefined}
 */
function* updateFieldMappingInApi(action = {}) {
  const metaData = [
    'member_id',
    'can_receive_texts',
    'can_receive_calls',
    'can_receive_emails',
    'attendee',
    'needs_service_animal',
    'groupable',
    'min_acceptable_mode',
    'service_level',
    'preferred_driver_gender',
    'preferred_driver_language',
    'preferred_driver_skills',
    'preferred_vehicle_capability',
    'leave_alone'
  ];
  try {
    const params = action.data;
    const fieldMapping = {};
    let subplanMap = {};
    let requestPath = params.importType;
    if (requestPath === 'member') {
      requestPath = 'patient';
      fieldMapping.passenger = { healthSubPlanId: 'HealthSubPlan' };
      fieldMapping.passengerMetaData = {};
    }
    _.forOwn(params.fieldMapping, (mappingValue, mappingKey) => {
      if (params.importType === 'member') {
        if (mappingKey === 'HealthSubPlan') {
          subplanMap = mappingValue;
        } else if (metaData.indexOf(mappingValue) > -1) {
          fieldMapping.passengerMetaData[mappingValue] = mappingKey;
        } else {
          fieldMapping.passenger[mappingValue] = mappingKey;
        }
      } else if (mappingValue !== 'ignore') {
        fieldMapping[mappingValue] = mappingKey;
      }
    });

    const postData = {
      importerJobId: params.jobId,
      templateName: params?.templateName ?? '',
      templateKeyMap: fieldMapping,
      userSort: 1
    };

    if (Object.keys(subplanMap).length > 0) {
      postData.subplanMap = subplanMap;
    }

    const config = {
      method: 'POST',
      url: getApiEndpointUri(params.importType),
      headers: { Authorization: `Bearer ${token()}` },
      data: postData
    };

    let data = null;
    const results = yield axios(config);

    if (results.data.status === false) {
      yield put({ type: IMPORTER_POST_UPLOAD_ERROR, error: results.data.message });
    } else {
      data = cloneDeep(postData);
      data.status = 'pending';
      yield put({ type: IMPORTER_POST_UPLOAD_SUCCESS, data, params });
    }
  } catch (error) {
    yield put({ type: IMPORTER_POST_UPLOAD_ERROR, error });
  }
}

/**
 * Saga function wrapper
 * @returns {undefined}
 */
export function* importerJobSaga() {
  yield takeEvery(IMPORTER_JOB_GET, getImporterJobFromApi);
  yield takeEvery(IMPORTER_POST_UPLOAD, uploadFileToApi);
  yield takeEvery(IMPORTER_POST_FIELD_MAPPING, updateFieldMappingInApi);
}

/**
 * Redux reducer function
 * @param {Object} state Redux state
 * @param {Action} action Params from action dispatch
 * @returns {undefined}
 */
export const importerJobReducer = (state = {}, action = {}) => {
  const newState = cloneDeep(state);

  switch (action.type) {
    case IMPORTER_JOB_GET_SUCCESS:
    case IMPORTER_POST_UPLOAD_SUCCESS:
    case IMPORTER_POST_FIELD_MAPPING_SUCCESS: {
      newState.job = action.data;
      if (action.params && action.params.file) {
        newState.file = action.params.file;
      }
      return newState;
    }

    case IMPORTER_POST_UPLOAD_ERROR: {
      newState.error = action.error;
      return newState;
    }
    case IMPORTER_JOB_RESET: {
      delete newState.job;
      delete newState.error;
      delete newState.file;
      return newState;
    }
    default: {
      return state;
    }
  }
};
