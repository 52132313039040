import moment from 'moment';
import { NavLink } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Search from '~/Shared/Components/Search/Search';
import SvgArrow from '~/Shared/Components/Svgs/SvgArrow';
import SvgInsertInvitation from '~/Shared/Components/Svgs/SvgInsertInvitation';
import {
  DATE_API_FORMAT,
  DATE_TEXT_FORMAT,
  TOMORROW_END,
  DAY_START,
  HIDE_NEW_RIDE,
  EDIT_PAST_RIDE
} from '~/constants';
import CalendarRange from '~/Shared/Components/Calendar/CalendarRange';

export type ScheduledRidesHeaderProps = {
  params: Record<string, any>;
  isAlertPage: boolean;
  showLyftAlertSubtab: boolean;
  totalRideShareAlerts: number;
  totalNemtAlerts: number;
  totalAlerts: number;
  user: Record<string, any>;
  requestNewRide: () => void;
  handleCalendarSearch: (params: Record<string, any>) => void;
};

const ScheduledRidesHeader: React.FC<ScheduledRidesHeaderProps> = ({
  params,
  isAlertPage,
  showLyftAlertSubtab,
  totalRideShareAlerts,
  totalNemtAlerts,
  totalAlerts,
  user,
  requestNewRide,
  handleCalendarSearch
}) => {
  const [showCalendar, setShowCalendar] = useState(false);
  const [fromDate, setFromDate] = useState<Date>(DAY_START);
  const [toDate, setToDate] = useState<Date>(TOMORROW_END);

  const alertTabClass = isAlertPage ? 'pageLink active' : 'pageLink';

  const hideNewRide = user.features[HIDE_NEW_RIDE];
  const allowPastRideEdit = user.features[EDIT_PAST_RIDE];

  const headerClass = isAlertPage
    ? 'ScheduledRidesHeader hasUNFTabs'
    : 'ScheduledRidesHeader';

  const cancelLink = showLyftAlertSubtab
    ? '/ride/ride-share-alerts'
    : '/ride/ride-nemt-alerts';

  const calendarDayFrom = allowPastRideEdit
    ? moment().subtract(user.pastEditDays, 'day').startOf('day').toDate()
    : DAY_START;

  /**
   * This callback handles when users select a new to
   * and from date in the calendar component. It updates local
   * state and triggers the handleCalendarSearch() prop method
   */
  const submitModal = dates => {
    const newParams = params;

    params.fromDate = moment(dates.startDate).format(DATE_API_FORMAT);
    params.toDate = moment(dates.endDate).format(DATE_API_FORMAT);

    setFromDate(params.fromDate);
    setToDate(params.toDate);

    handleCalendarSearch(newParams);
  };

  useEffect(() => {
    if (params.fromDate && params.toDate) {
      setFromDate(moment(params.fromDate).toDate());
      setToDate(moment(params.toDate).toDate());
    }
  }, [params.fromDate, params.toDate]);

  return (
    <div className={headerClass}>
      <h1>Scheduled Rides</h1>

      <div className="calendarField" onClick={() => setShowCalendar(true)}>
        <SvgInsertInvitation className="whiteCalendar" />

        <span className="dateContent">
          {moment(fromDate).format(DATE_TEXT_FORMAT)} to {moment(toDate).format('MMM D')}
        </span>

        <SvgArrow className="whiteDownArrow" />
      </div>

      {!hideNewRide && (
        <div className="btnText" onClick={() => requestNewRide()}>
          <span className="btnNewRide">+</span>
          <span className="textNewRide">New Ride</span>
        </div>
      )}
      <Search
        getRows={handleCalendarSearch}
        placeHolder="Search"
        className="ridesSearch"
        defaultFields={params}
        charactersMin={3}
      />

      <div className="tabs alertTab">
        <ul className="rideStatusTabs">
          <li>
            <NavLink
              data-tip="Active and upcoming rides"
              className="pageLink"
              exact
              to="/ride/scheduled"
            >
              Upcoming
            </NavLink>
          </li>
          <li>
            <NavLink data-tip="Active rides" className="pageLink" exact to="/ride/active">
              Active
            </NavLink>
          </li>
          <li>
            <NavLink
              data-tip="Will Call holds the ride for the day if the set pickup time is unknown"
              className="pageLink"
              exact
              to="/ride/todayswillcalls"
            >
              Will Call
            </NavLink>
          </li>
          <li>
            <NavLink
              data-tip="All rides for today"
              className="pageLink"
              exact
              to="/ride/all"
            >
              All
            </NavLink>
          </li>
          <li>
            <NavLink data-tip="Alerts" className={alertTabClass} exact to={cancelLink}>
              Alerts
            </NavLink>

            {totalAlerts > 0 && <span className="count">{totalAlerts}</span>}
          </li>
        </ul>

        {isAlertPage && (
          <AlertsPageSubTab
            showLyftAlertSubtab={showLyftAlertSubtab}
            totalRideShareAlerts={totalRideShareAlerts}
            totalNemtAlerts={totalNemtAlerts}
          />
        )}
      </div>

      {showCalendar && (
        <CalendarRange
          closeCalendar={() => setShowCalendar(false)}
          openCalendar={showCalendar}
          startDate={fromDate}
          endDate={toDate}
          disabledDays={[{ before: calendarDayFrom }]}
          submitModal={submitModal}
          fromMonth={calendarDayFrom}
        />
      )}
    </div>
  );
};

type AlertsPageSubTabProps = {
  showLyftAlertSubtab: boolean;
  totalRideShareAlerts: number;
  totalNemtAlerts: number;
};

const AlertsPageSubTab: React.FC<AlertsPageSubTabProps> = ({
  showLyftAlertSubtab,
  totalRideShareAlerts,
  totalNemtAlerts
}) => {
  return (
    <ul className="unableToFulfillTabs">
      {showLyftAlertSubtab && (
        <li>
          <NavLink
            data-tip="RIDESHARE"
            className="pageLink"
            exact
            to="/ride/ride-share-alerts"
          >
            RIDESHARE
          </NavLink>
          {totalRideShareAlerts > 0 && (
            <span className="count">{totalRideShareAlerts}</span>
          )}
        </li>
      )}
      <li>
        <NavLink data-tip="NEMT" className="pageLink" exact to="/ride/ride-nemt-alerts">
          NEMT
        </NavLink>
        {totalNemtAlerts > 0 && <span className="count">{totalNemtAlerts}</span>}
      </li>
    </ul>
  );
};

export default ScheduledRidesHeader;
