import React from 'react';
import PropTypes from 'prop-types';

const signalStatuses = {
  inbound: '#0072D6',
  arrived: '#FFDC4A',
  started: '#3eb200',
  delayed: '#fe8c00',
  cancelled: '#ff1a00',
  drivercancelled: '#ff1a00',
  rejected: '#ff1a00',
  completed: '#9cb0ba',
  scheduled: '#eceff1',
  empty: ''
};

const SvgDriver = ({ heading, status }) => {
  const color = signalStatuses[status];
  const rotate = ` rotate(${heading}, 12, 18)`;

  return (
    <svg width="60" height="60" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="30"
        cy="30"
        r="27"
        fill="url(#circleAroundCar)"
        stroke={color}
        strokeWidth="3"
      />
      <svg viewBox="-7 -10 39 57" className="circle">
        <defs>
          <filter
            x="-27.5%"
            y="-10.9%"
            width="155%"
            height="134.4%"
            filterUnits="objectBoundingBox"
            id="actualCar"
          >
            <feOffset dy="2" in="SourceAlpha" result="shadowOffsetOuter1" />
            <feGaussianBlur
              stdDeviation="1.5"
              in="shadowOffsetOuter1"
              result="shadowBlurOuter1"
            />
            <feColorMatrix
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
              in="shadowBlurOuter1"
              result="shadowMatrixOuter1"
            />
            <feMerge>
              <feMergeNode in="shadowMatrixOuter1" />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
          <radialGradient id="circleAroundCar">
            <stop offset="10%" stopColor={color} />
            <stop offset="95%" stopColor="#fff" stopOpacity="0" />
          </radialGradient>
        </defs>
        <g
          filter="url(#actualCar)"
          transform={`translate(3 1) ${rotate}`}
          fill="none"
          fillRule="evenodd"
        >
          <path fill="#FFF" d="M3.333 5.833h13.333v22.5H3.333z" />
          <path
            d="M2.695 10.429V4.286C2.695 1.919 4.6 0 6.95 0h6.241c2.35 0 4.256 1.919 4.256 4.286v6.143h.283A2.278 2.278 0 0 1 20 12.714v.429c0 .237-.19.428-.426.428h-2.127v13.572c0 2.367-1.905 4.286-4.256 4.286h-6.24c-2.35 0-4.256-1.92-4.256-4.286V13.57H.425A.427.427 0 0 1 0 13.143v-.429a2.278 2.278 0 0 1 2.27-2.285h.425zm1.56-.715l1.419 4c1.48-.19 2.959-.285 4.438-.285 1.479 0 2.93.095 4.356.285l1.419-4c-1.918-.952-3.84-1.428-5.765-1.428-1.925 0-3.88.476-5.867 1.428zm0 16.572c1.986.952 3.942 1.428 5.867 1.428 1.925 0 3.847-.476 5.765-1.428l-1.419-4c-1.425.19-2.877.285-4.356.285-1.48 0-2.959-.095-4.438-.285l-1.419 4z"
            fill="#121212"
          />
        </g>
      </svg>
    </svg>
  );
};

SvgDriver.propTypes = {
  status: PropTypes.string,
  heading: PropTypes.number
};

SvgDriver.defaultProps = {
  status: '',
  heading: 0
};

export default SvgDriver;
