import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
const ExtraFields = props => {
  const { extraFields } = props;

  return (
    <div className="tripSummary extraFields">
      <h2>Extra Details</h2>
      {_.has(extraFields.additionalNotes) ? <p>{extraFields.additionalNotes}</p> : null}
      <ul className="tripSummaryList">
        {_.has(extraFields, 'customFields') && extraFields.customFields.length > 0 ? (
          <ul className="tripSummaryList">
            {extraFields.customFields.map((field, k) => {
              return (
                <li key={k}>
                  <p>{field.field.field_label}</p>
                  <p>{_.get(field, 'value.field_value', '')}</p>
                </li>
              );
            })}
          </ul>
        ) : null}
      </ul>
    </div>
  );
};

ExtraFields.defaultProps = {
  extraFields: {}
};

ExtraFields.propTypes = {
  extraFields: PropTypes.object
};

export default ExtraFields;
