/* eslint-disable no-console */
import {
  type ErrorResponse,
  type PusherAction,
  type PusherSubscriberMessage,
  type SimulateMessageAction,
  type StartSimulationActionWithMocks
} from '~/workers/pusher/types';
import { PUSHER_KEY } from '~/constants';
import { token } from '~/utilities/auth.helper';
import { isObject } from './guards';
import type { AppThunk } from '~/Modules';
import SafeRideWorker from '~/workers/pusher/worker';

export const CONNECTED = 'PUSHER-REDUX/CONNECTED';
export const DISCONNECTED = 'PUSHER-REDUX/DISCONNECTED';

// Controls if pusher worker is in debug mode. If you put it in
// debug mode and cannot see the logs, you may be filtering out
// debug lines in your console.
const DEBUG_MODE = false;

// Singleton reference to the redux store.
let reduxStore;

// Instance of the pusher shared worker.
let pusherWorker: SafeRideWorker;

/** Singleton registry of the event bindings. */
export const EVENT_BINDING_REGISTRY: PusherEventBindingsRegistry = {};

/**
 * Event to call when pusher connects
 * @param {object} data The data passed to event upon successful connection
 * @returns {void}
 */
const connected = data => reduxStore.dispatch({ type: CONNECTED, data });

/**
 * Event to call when pusher becomes disconnected
 * @returns {void}
 */
const disconnected = () => reduxStore.dispatch({ type: DISCONNECTED });

/**
 * Initializes pusher-redux, used for passing redux store
 * @param {store} reduxStores
 * @returns {void}
 */
export const configurePusher = _reduxStore => {
  reduxStore = _reduxStore;

  // According to MixPanel, our users will all support SharedWorker
  // eslint-disable-next-line compat/compat
  pusherWorker = new SafeRideWorker();

  pusherWorker.onmessage = handleMessage;
  pusherWorker.postMessage({
    type: 'config',
    apiKey: PUSHER_KEY,
    debug: DEBUG_MODE,
    unsubscribeEndpoint: `${process.env.APP_LAYER_URL}/push/v1/auth/unsubscribe`,
    options: {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      disableStats: true,
      authEndpoint: `${process.env.APP_LAYER_URL}/push/v1/auth/channels`,
      authDelay: 200,

      auth: {
        headers: {
          Authorization: `Bearer ${token()}`,
          Token: token()
        }
      }
    }
  });
};

type PusherEventHandler<D = unknown> = (data: D) => void;

type PusherEventBindingsRegistry = {
  [channelName: string]: {
    [eventName: string]: {
      [actionType: string]: PusherEventHandler;
    };
  };
};

/**
 * Subscribes to a pusher channel/event. Links it to a Redux Action
 * @param {string} channel          The name of the pusher channel to subscribe to
 * @param {string} event            The name of the pusher event to subscribe to
 * @param {string} type             The name of the redux action or thunk name to call upon pusher event
 * @param {function} thunk          The optional thunk to call upon pusher event
 * @param {array} additionalParams  Any additional parameters to pass to redux action
 * @returns {void}
 */
export const subscribe = (
  channel: string,
  event: string,
  type: string,
  thunk?: (data) => AppThunk,
  additionalParams = []
) => {
  if (!reduxStore) {
    throw Error(
      'Pusher-redux must be configured with a redux store before subscribing to events'
    );
  }

  if (!pusherWorker) {
    throw Error(
      'Pusher-redux must be configured with a shared worker before subscribing to events'
    );
  }

  if (!channel || !event || !type) {
    throw Error('Channel, event, and type are required to subscribe to pusher events');
  }

  if (DEBUG_MODE) {
    console.debug('Subscribing to pusher event:', {
      channel,
      event,
      type,
      additionalParams
    });
  }

  EVENT_BINDING_REGISTRY[channel] ??= {};
  EVENT_BINDING_REGISTRY[channel][event] ??= {};
  EVENT_BINDING_REGISTRY[channel][event][type] = data => {
    if (typeof thunk === 'function') {
      reduxStore.dispatch(thunk(data));
    } else {
      reduxStore.dispatch({
        type,
        channel,
        event,
        data,
        additionalParams
      });
    }
  };

  pusherWorker.postMessage({
    type: 'subscribe',
    channels: { [channel]: [event] }
  });
};

type ChannelsData = {
  [channel: string]: {
    [events: string]: {
      [key: string]: string[];
    };
  };
};

/**
 * Removes listeners to pusher events.
 * @param {string} ChannelsData     An array of channels and events to unsubscribe from.
 * @returns {null}
 */
export const unsubscribe = (channelsData: ChannelsData) => {
  if (!reduxStore || !pusherWorker) return;

  const collection = {};
  for (const channel in channelsData) {
    for (const event in channelsData[channel].events) {
      if (!(channel in EVENT_BINDING_REGISTRY)) return;

      // Unsubscribe from all events on a channel
      if (!event) {
        const channelEvents = EVENT_BINDING_REGISTRY[channel];

        pusherWorker.postMessage({
          type: 'unsubscribe',
          channels: { [channel]: Object.keys(channelEvents) }
        });

        delete EVENT_BINDING_REGISTRY[channel];
      }

      channelsData[channel].events[event].forEach(type => {
        // Unsubscribe from a specific channel event type
        if (EVENT_BINDING_REGISTRY?.[channel]?.[event]?.[type]) {
          delete EVENT_BINDING_REGISTRY[channel][event][type];
        }
      });

      // Unsubscribe from all events on a channel or if the event registry
      // is empty after removing the type.
      if (
        event &&
        event in EVENT_BINDING_REGISTRY[channel] &&
        !Object.keys(EVENT_BINDING_REGISTRY[channel][event]).length
      ) {
        delete EVENT_BINDING_REGISTRY[channel][event];
        collection[channel] ??= [];
        collection[channel].push(event);
      }
    }
  }

  pusherWorker.postMessage({
    type: 'unsubscribe',
    channels: collection
  });
};

/** Terminate all active listeners for pusher events. */
export const terminatePusherConnection = () => {
  if (!pusherWorker) return;

  for (const channel in EVENT_BINDING_REGISTRY) {
    delete EVENT_BINDING_REGISTRY[channel];
  }

  pusherWorker.postMessage({ type: 'terminate' });
};

/**
 * Inject pusher data into pusherRedux w/o using sockets and pusher subscription bindings. Used for mocking pusher calls
 * @param {String} channelName Name of the pusher channel
 * @param {String} eventName Name of the pusher event
 * @param {Object} data The object containing the pusher data
 * @returns {void}
 */
export const injectPusher = (channelName, eventName, data) => {
  const eventBindings = EVENT_BINDING_REGISTRY?.[channelName]?.[eventName];
  if (!eventBindings) return;

  for (const actionType in eventBindings) {
    EVENT_BINDING_REGISTRY[channelName][eventName][actionType](data);
  }
};

/**
 * Type guard for Pusher Actions
 * @param v
 * @returns
 */
function isPusherActionResponse(v: unknown): v is PusherAction['response'] {
  return isObject(v) && 'type' in v && !!v.type && v.type !== 'error';
}

/**
 * Type guard for Pusher Errors
 * @param v
 * @returns
 */
function isPusherError(v: unknown): v is ErrorResponse {
  return isObject(v) && 'type' in v && !!v.type && v.type === 'error';
}

/**
 * The main switchboard for handling messages from the pusher worker.
 * @param e
 */
function handleMessage(e: MessageEvent<PusherSubscriberMessage>) {
  if (DEBUG_MODE) {
    console.debug('Message received from worker:', e.data);
  }

  const data = e.data;
  // Pusher action response
  if (isPusherActionResponse(data)) {
    switch (data.type) {
      case 'connected':
        connected(e.data);
        break;
      case 'disconnected':
        disconnected();
        break;
      case 'config':
        if (data.status !== 'success') {
          throw Error(`Failed to configure pusher: ${data.message}`);
        }
        break;
      case 'subscribe':
        if (data.status !== 'success') {
          throw Error(`Failed to subscribe to pusher events: ${data.message}`);
        }
        break;
      case 'unsubscribe':
        break;
      case 'ping':
        pusherWorker.postMessage({ type: 'ping', message: 'polo' });
        break;
      default:
        if (DEBUG_MODE) {
          console.warn('Unrecognized message received from worker:', e.data);
        }
    }
  } else if (isPusherError(data)) {
    console.error('Error received from pusher:', e.data);
  } else if ('channel' in e.data) {
    // Pusher Notification
    const { channel, event, body } = e.data;

    const bindings = EVENT_BINDING_REGISTRY[channel]?.[event];

    for (const actionType in bindings) {
      bindings[actionType](body);
    }
  } else if (DEBUG_MODE) {
    // I dunno, errors?
    console.warn('Unrecognized message received from worker:', e.data);
  }
}

declare global {
  interface Window {
    /** This is only true if we are not in production envionment. */
    startPusherSimulation: (
      rate: number,
      mocks?: StartSimulationActionWithMocks['request']['mocks']
    ) => void;
    stopPusherSimulation: () => void;
    simulatePusherEvent: (event: SimulateMessageAction['request']['message']) => void;
  }
}

if (process.env.REACT_APP_ENVIRONMENT !== 'production') {
  window.startPusherSimulation = (
    rate: number,
    mocks?: StartSimulationActionWithMocks['request']['mocks']
  ) => pusherWorker.postMessage({ type: 'start-simulation', rate, mocks });
  window.stopPusherSimulation = () =>
    pusherWorker.postMessage({ type: 'stop-simulation' });
  window.simulatePusherEvent = (event: SimulateMessageAction['request']['message']) =>
    pusherWorker.postMessage({ type: 'simulate-message', message: event });
}
