import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { CHAT_CANCELLATION_REASON } from '~/constants';

const LiveChatConversationListItem = props => {
  const { chat, user, selectedRequestId, setSelectedRequest } = props;
  const rideDetails = chat.rideDetails || {};
  const messages = chat.messages || [];
  const userId = _.get(user, 'userData.id', 0);
  const messageCount = messages.reduce((count, message) => {
    const reader = _.find(message.readers, reader => reader.id === userId);

    // increment if not a listed reader and not the original sender
    if (!reader && message.senderId !== userId) {
      return count + 1;
    } else {
      return count;
    }
  }, 0);
  const classNames = [];

  classNames.push(chat.requestStatus.toLowerCase());
  if (chat.requestId === selectedRequestId) {
    classNames.push('selected');
  }

  // add unableToFulfill
  if (
    chat.requestStatus === 'cancelled' &&
    chat.cancellationReason === CHAT_CANCELLATION_REASON.BY_NEMT_RESPONDER
  ) {
    classNames.push('unableToFulfill');
  }

  return (
    <li
      className={classNames.join(' ')}
      onClick={() => setSelectedRequest({ requestId: chat.requestId })}
    >
      {rideDetails.patientName} {rideDetails.patientLastName}
      {messageCount > 0 ? <sup>{messageCount}</sup> : null}
    </li>
  );
};

LiveChatConversationListItem.propTypes = {
  chat: PropTypes.object,
  user: PropTypes.object,
  selectedRequestId: PropTypes.string,
  setSelectedRequest: PropTypes.func
};

LiveChatConversationListItem.defaultProps = {
  chat: {},
  user: {},
  selectedRequestId: '',
  setSelectedRequest: () => {}
};

export default LiveChatConversationListItem;
