import React from 'react';
import { get, isEmpty } from 'lodash-es';
import {
  Grid,
  FormControl,
  TextField,
  Divider,
  Button,
  Tooltip
} from '@material-ui/core';
import PersonalInformation from './PersonalInformation';
import { useStyles } from './styles';

import ReactSelect from 'react-select';

const AddNewMemberModalForm = props => {
  const classes = useStyles();
  const {
    fundingOptions,
    members,
    onChange,
    formData,
    cancelChange,
    addNewMember,
    onSelectChange
  } = props;
  const isSelfFunded = formData.fundingSource === 'Self Funded';
  const isHealthPlan = formData.fundingSource === 'Health Plan';
  const isDisabled = fundingOptions && fundingOptions.disabled;

  const handleFormChange = e => {
    onSelectChange({ key: e.key, name: e.value, value: e.value, label: e.value });
  };

  const fundingSourceSelect = () => {
    const fundingSourceValue = isEmpty(formData.fundingSource)
      ? { label: 'Select Funding Source', value: '' }
      : {
        value: formData.fundingSource,
        label: formData.fundingSource,
        key: 'fundingSource'
      };
    return (
      <FormControl style={{ display: 'flex' }}>
        <Grid container item xs={12} spacing={3} className={classes.gridRoot}>
          <Grid container item xs={4}>
            <div className={classes.selectContainer}>
              <span>
                <label className={classes.textField}>Funding Source</label>
              </span>
              <ReactSelect
                placeholder={'Select a Funding Source'}
                className={classes.textField}
                options={fundingOptions.options.map(m => ({
                  value: m,
                  label: m,
                  key: 'fundingSource'
                }))}
                isDisabled={isDisabled}
                onChange={handleFormChange}
                value={fundingSourceValue}
              />
            </div>
          </Grid>
          <Grid item xs={4}>
            {selfFundedForm()}
          </Grid>
        </Grid>
      </FormControl>
    );
  };

  const healthPlanForm = () => {
    const { subplans = [] } = formData.healthPlan
      ? members.healthPlans.find(m => m.name === formData.healthPlan)
      : [];
    const subPlanValue =
      formData && formData.subPlan ? { label: formData.subPlan } : null;
    return (
      isHealthPlan && (
        <React.Fragment>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <span>
                <label className={classes.textField}>Health Plan</label>
              </span>

              <ReactSelect
                placeholder={'Select a Health Plan'}
                className={classes.textField}
                options={members.healthPlans.map(m => ({
                  value: m.name,
                  label: m.name,
                  id: m.id,
                  key: 'healthPlan'
                }))}
                onChange={onSelectChange}
              />
            </Grid>
            <Grid item xs={4}>
              <span>
                <label className={classes.textField}>Sub Plan</label>
              </span>

              <ReactSelect
                placeholder={'Select a Sub Plan'}
                className={classes.textField}
                value={subPlanValue}
                options={subplans.map(s => ({
                  value: s.name,
                  label: s.name,
                  id: s.id,
                  key: 'subPlan'
                }))}
                onChange={onSelectChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="medicalId"
                name="medicalId"
                className={classes.textField}
                required
                label="Medical ID"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={onChange}
                value={formData.medicalId}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={4}>
              <TextField
                id="eligibilityStartDate"
                name="eligibilityStartDate"
                type="date"
                className={classes.textField}
                required
                label="Eligibility Start Date"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={onChange}
                value={formData.eligibilityStartDate}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="eligibilityEndDate"
                name="eligibilityEndDate"
                type="date"
                className={classes.textField}
                required
                label="Eligibility End Date"
                InputLabelProps={{
                  shrink: true
                }}
                onChange={onChange}
                value={formData.eligibilityEndDate}
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )
    );
  };

  const selfFundedForm = () => {
    return (
      isSelfFunded && (
        <TextField
          id="medicalId"
          name="medicalId"
          className={classes.textField}
          required
          label="Medical ID"
          InputLabelProps={{
            shrink: true
          }}
          onChange={onChange}
          value={formData.medicalId}
        />
      )
    );
  };
  return (
    <div className={classes.root}>
      <FormControl className={classes.formControl}>
        <div style={{ height: 50 }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1 className={classes.header}>Add New Member</h1>
            <span className={classes.errorText}>
              {get(members, 'addNewMemberFormData.error', '')}
            </span>
            <span className={classes.closeIcon} onClick={cancelChange}>
              X
            </span>
          </div>
          <Divider />
        </div>
        <div>
          <h2 className={classes.header2}>Benefits Information</h2>
          {fundingSourceSelect()}
          {healthPlanForm()}
          <Divider style={{ marginTop: '20px', marginBottom: '10px' }} />
        </div>
        <div style={{ marginTop: 10 }}>
          <h2 className={classes.header2}>Personal Information</h2>
          <PersonalInformation
            onChange={onChange}
            formData={formData}
            classes={classes}
            isSelfFunded={isSelfFunded}
          />
        </div>
        <Grid className={classes.buttonRoot} container spacing={1}>
          <Button
            className={classes.buttonCancel}
            variant="contained"
            onClick={cancelChange}
          >
            Cancel
          </Button>
          <Tooltip
            title={
              formData.isValid
                ? ''
                : 'Please fill all required fields / address any errors'
            }
            disableFocusListener={true}
          >
            <span>
              <Button
                className={classes.buttonAdd}
                variant="contained"
                onClick={addNewMember}
                disabled={!formData.isValid}
              >
                Add
              </Button>
            </span>
          </Tooltip>
        </Grid>
      </FormControl>
    </div>
  );
};

export default AddNewMemberModalForm;
