import React, { useState, useEffect } from 'react';
import ClipboardJS from 'clipboard';

import Tooltip from '../Tooltip/Tooltip';
import SvgClipboard from '../Svgs/SvgClipboard';

const ClipboardButton = props => {
  const [isCopied, setIsCopied] = useState(false);

  const { 
    textToCopy, 
    successDuration = 1000, 
    svgclassname, 
    svgsize = 'medium' 
  } = props;

  useEffect(() => {
    if (isCopied && successDuration) {
      const id = window.setTimeout(() => {
        setIsCopied(false);
      }, successDuration);

      return () => {
        window.clearTimeout(id);
      };
    }
  }, [isCopied, successDuration]);

  const tooltipText = isCopied ? 
    'Copied!'
    :
    'Copy';

  new ClipboardJS('.clipboard-button');

  return (
    <>
      { ClipboardJS.isSupported() &&
        <Tooltip
          text={tooltipText} >  
          <div>
            <button
              className="clipboard-button"
              data-clipboard-text={textToCopy}
              onClick={() => setIsCopied(true)}
            >
              <SvgClipboard 
                svgclassname={svgclassname}
                svgsize={svgsize} />
            </button>
          </div>
        </Tooltip>         
      }
    </>
  );
};

export default ClipboardButton;
