import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { get } from 'lodash-es';
import moment from 'moment-timezone';
import { reset } from '~/Modules/scheduleRides';
import { updateBookingData } from '~/Modules/bookingData';
import TransportType from './FormComponents/TransportType';
import AppointmentTime from './FormComponents/AppointmentTime';
import TransportComplete from './DisplayComponents/TransportComplete';
import RideNotes from './FormComponents/RideNotes';
import RideNotesComplete from './DisplayComponents/RideNotesComplete';
import { MULTIPLE_LEG_LIMIT, DATE_FORMAT_INPUT } from '~/constants';
import { pluralize, flipAddresses } from '~/utilities/helperFunctions';

// handle multiple leg booking
class BookRidesMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showAllNotes: false,
      editRideNotesForm: false,
      editTransportForm: false,
      editAppointmentForm: false,
      showAddLegButton: false,
      addingRide: false, // are we addign a ride or editing? dont remove or edit other fields where adding a ride
      rides: [], // store state of multi legged components, both display components and form components
      missingFieldsError: '',
      missingFields: []
    };
  }

  componentDidMount() {
    const { rides } = this.state;
    const getRideProperties = this.getRideProperties();
    const addingRide = true;

    getRideProperties.showTransportForm = true;
    getRideProperties.showAppointmentTime = true;
    rides.push(getRideProperties);

    this.setState({ rides, addingRide }, () => {
      this.props.addingRide(addingRide);
    });
  }

  /**
   * toggles display of patient notes between more and less
   * @return {undefined}
   */
  toggleNotes = () => {
    this.setState(prevState => ({
      showAllNotes: !prevState.showAllNotes
    }));
  };

  /**
   * hide transport form and show appointment time form
   * @param {integer} i - index
   * @return {undefined} - nothing returned
   */
  handleTransportationUpdate = i => {
    if (i === 0) {
      const rides = this.state.rides;

      rides[i].showTransportForm = true;
      rides[i].showAppointmentTime = true;
      this.setState({ rides });
    }
  };

  /**
   * edit transportation info, pickup and dropoff and appointment time
   * for multiple leg trips
   * @param {object} bookingData - bookingData
   * @param {object} ride - ride to be edited
   * @param {integer} i - index of ride that is being edited
   * @return {undefined}
   */
  editTransportInfo = (bookingData, ride, i) => {
    let { rides } = this.state;
    const editTransportForm = true;
    const editAppointmentForm = true;
    const showAddLegButton = false;
    const tripCompleted = _.get(bookingData, 'tripCompleted', false);
    const transportEditState = {
      showRideNotes: false,
      showRideNotesComplete: true,
      showTransportForm: true,
      showAppointmentTime: true,
      showTransportComplete: false,
      completedLeg: false
    };
    // you're still going through the booking process at this point.
    if (tripCompleted) {
      // Scenario-- normal trip "completed" (but not booked) and you're editing a leg of a multi-leg ride.
      // This resets form components and makes sure you're editing the correct ride at the correct currentRideIndex
      bookingData.currentRideIndex = i;
      updateBookingData(bookingData);
      rides = this.hideAllForms(rides, true);
      rides[i] = transportEditState;
      this.props.hideMiddleColumn();
    } else if (i === bookingData.currentRideIndex) {
      rides[i] = transportEditState;
      rides[i].showRideNotesComplete = false;
    } else {
      return false;
    }

    this.setState({
      rides,
      editTransportForm,
      showAddLegButton,
      editAppointmentForm
    });
  };

  /**
   * add appointment date and time
   * if bookingType is from care (bleg) do not show round trip button
   * instead show ride notes component
   * @param {object} bookingData - bookingData passed from component
   * @param {integer} i - order ride is in for multi leg
   * @return {undefined}
   */
  updateAppointmentInfo = (bookingData, i) => {
    const { rides } = this.state;
    const tripCompleted = _.get(bookingData, 'tripCompleted', false);
    const editTransportForm = false;
    const editAppointmentForm = false;

    rides[i].showTransportForm = false;
    rides[i].showAppointmentTime = false;
    rides[i].showRideNotes = true;
    rides[i].showTransportComplete = true;
    rides[i].showRideNotesComplete = false;

    if (tripCompleted) {
      const showAddLegButton = true;

      rides[i].showRideNotes = false;
      rides[i].showRideNotesComplete = true;
      this.setState({ rides, editTransportForm, showAddLegButton }, () => {
        this.handleRequestBooking(bookingData);
      });
    } else {
      this.setState({ rides, editTransportForm, editAppointmentForm });
    }
  };

  /**
   * edit ride notes info for multi legged rides
   * @param {object} bookingData - bookingData
   * @param {object} ride - ride to be edited
   * @param {integer} i - index of ride that is being edited
   * @return {undefined}
   */
  editRideNotesInfo = (bookingData, ride, i) => {
    let { rides } = this.state;
    const editRideNotesForm = true;
    const showAddLegButton = false;
    const tripCompleted = _.get(bookingData, 'tripCompleted', false);
    const rideNoteEditState = {
      showRideNotes: true,
      showRideNotesComplete: false,
      showTransportForm: false,
      showAppointmentTime: false,
      showTransportComplete: true,
      completedLeg: false
    };

    if (tripCompleted) {
      rides = this.hideAllForms(rides, true);
      // multiple rides from component state
      rides[i] = rideNoteEditState;
      this.props.hideMiddleColumn();
    } else if (i === this.props.bookingData.currentRideIndex) {
      rides[i] = rideNoteEditState;
      rides[i].showRideNotesComplete = false;
    } else {
      return false;
    }

    this.setState({ rides, editRideNotesForm, showAddLegButton });
  };

  /**
   * editing ride notes
   * @param {object} bookingData - booking data
   * @param {integer} i - ride index
   * @return {undefined}
   */
  handleEditNotes = (bookingData, i) => {
    this.props.updateBookingData(bookingData, true);
    const { rides } = this.state;
    const editRideNotesForm = false;
    const showAddLegButton = true;

    // multiple rides from component state
    rides[i].showRideNotes = false;
    rides[i].showRideNotesComplete = true;
    rides[i].showTransportForm = false;
    rides[i].showAppointmentTime = false;
    rides[i].showTransportComplete = true;

    this.setState({ rides, editRideNotesForm, showAddLegButton }, () => {
      // if trip is not completed do not send to request booking
      if (_.get(bookingData, 'tripCompleted', false)) {
        this.handleRequestBooking(bookingData);
      }
    });
  };

  /**
   * handles ride request for multiple legs, calls request booking callback
   * @param {object} bookingData - booking data passed from component
   * @return {undefined}
   */
  handleRequestBooking = bookingData => {
    const { rides } = this.state;
    const showAddLegButton = true;

    rides[bookingData.currentRideIndex].showRideNotes = false;
    rides[bookingData.currentRideIndex].showRideNotesComplete = true;
    rides[bookingData.currentRideIndex].completedLeg = true;

    this.setState({ rides, showAddLegButton, addingRide: false }, () => {
      this.props.addingRide(false);
      this.props.handleRequestBooking(bookingData);
    });
  };

  /**
   * add another leg (ride) to a multi legged trip
   * @param {object} bookingData - bookingData
   * @return {undefined}
   */
  addAnotherTrip = (bookingData = {}) => {
    const data = !_.isEmpty(bookingData)
      ? bookingData
      : _.cloneDeep(this.props.bookingData);

    // create component state for completed and new leg
    const completedRideState = this.getRideProperties();
    completedRideState.showTransportComplete = true;
    completedRideState.showRideNotesComplete = true;
    completedRideState.completedLeg = true;

    const newRideState = this.getRideProperties();
    newRideState.showTransportForm = true;
    newRideState.showAppointmentTime = true;

    const newRide = this.prePopulateMultilegPickupAddress();
    Object.assign(newRide, this.prePopulateMultilegTimes());

    data.tripCompleted = false;
    data.currentRideIndex++;
    data.rides.push(newRide);
    this.props.updateBookingData(data);

    const rides = this.state.rides;
    // store component show or hide state in array based on created legs
    rides[data.currentRideIndex - 1] = completedRideState;
    rides[data.currentRideIndex] = newRideState;

    this.setState(
      { rides, showAddLegButton: false, addingRide: true, missingFieldsError: '' },
      () => {
        this.props.hideMiddleColumn();
        this.props.addingRide(true);
      }
    );
  };

  /**
   * Creates a new ride entry using the dropoff data of the previous ride
   * @returns {object} The new ride
   */
  prePopulateMultilegPickupAddress = () => {
    const bookingData = this.props.bookingData;

    const lastRideIndex = bookingData?.rides.length - 1;
    const lastRide = get(bookingData, `rides[${lastRideIndex}]`, null);

    if (!lastRide) {
      return {};
    }

    const {
      pickupAddress,
      pickupAddressName,
      pickupLatitude,
      pickupLongitude,
      pickupZipcode,
      showPickupEntrance,
      pickupEntranceName,
      pickupVenueId,
      pickupVenueName,
      pickupProviderId,
      pickupProviderName,
      pickupProviderNpi,
      pickupProviderPhone,
      pickupFacilityName,
      pickupProviderAddr,
      pickupAdditionalNotes,
      pickupProviderNotFound,
      showPickupApprovedProvider,
      fromMemberSavedAddressId
    } = flipAddresses(lastRide);

    const newRide = {
      pickupAddress,
      pickupAddressName,
      pickupLatitude,
      pickupLongitude,
      pickupZipcode
    };

    // Last ride was for a venue -> Populate venue fields
    if (lastRide?.dropoffVenueId > 0) {
      Object.assign(newRide, {
        showPickupEntrance,
        pickupEntranceName,
        pickupVenueId,
        pickupVenueName
      });
    }

    if (lastRide?.isApprovedProviders) {
      Object.assign(newRide, {
        pickupProviderId,
        pickupProviderName,
        pickupProviderNpi,
        pickupProviderPhone,
        pickupFacilityName,
        pickupProviderAddr,
        pickupAdditionalNotes,
        pickupProviderNotFound,
        showPickupApprovedProvider,
        isApprovedProviders: true,
        displayApprovedProviders: true
      });
    }

    newRide.fromMemberSavedAddressId = fromMemberSavedAddressId ?? undefined;
    newRide.toMemberSavedAddressId = undefined;

    return newRide;
  };

  /**
   * Creates an object with the timefields pre-populated for the next leg
   * @returns {object} An object contained the keys selectedTime, selectedTimeFormat, selectedDate
   */
  prePopulateMultilegTimes = () => {
    const bookingData = this.props.bookingData;

    const lastRideIndex = bookingData?.rides.length - 1;
    const lastRide = get(bookingData, `rides[${lastRideIndex}]`, null);

    if (!lastRide) {
      return {};
    }

    let prevApptTime;
    if (lastRideIndex === 0) {
      prevApptTime =
        lastRide.apptTime === 'Will Call' ? moment().format('h:mm a') : lastRide.apptTime;
    } else {
      prevApptTime = lastRide.selectedTime;
    }

    const selectedTime = moment(prevApptTime, 'h:mm a').add(1, 'hour');
    const selectedTimeFormat = selectedTime.format('h:mm a');
    const selectedDate = moment(lastRide.selectedDate, DATE_FORMAT_INPUT).toDate();

    return { selectedTime, selectedTimeFormat, selectedDate };
  };

  /**
   * close a form component during edit process
   * @param {string} formType - PatientInfo, TransportType, RideNotes
   * @param {integer} i - index
   * @return {undefined} - returns nothing
   */
  closeFormComponents = (formType, i) => {
    const formTypes = ['TransportType', 'RideNotes'];
    const { rides } = this.state;
    const showAddLegButton = _.get(this.props.bookingData, 'tripCompleted', false);

    if (formTypes.indexOf(formType) > -1) {
      if (formType === 'TransportType') {
        rides[i].showTransportComplete = true;
        rides[i].showTransportForm = false;
        rides[i].showAppointmentTime = false;
      }
      if (formType === 'RideNotes') {
        rides[i].showRideNotesComplete = true;
        rides[i].showRideNotes = false;
      }
      this.setState({ rides, showAddLegButton });
    }
  };

  /**
   * remove a leg for multi legged rides
   * @param {integer} index - index for the leg to be removed
   * @return {undefined} - returns nothing
   */
  removeLeg = index => {
    const bookingData = _.cloneDeep(this.props.bookingData);
    const tripCompleted = bookingData.tripCompleted;

    if (!tripCompleted) {
      return false;
    }
    this.props.hideMiddleColumn();
    const bookRides = bookingData.rides;
    bookRides.splice(index, 1);
    bookingData.rides = bookRides;
    bookingData.currentRideIndex--;

    // modify form component state due to removed ride
    const { rides } = this.state;

    rides.splice(index, 1);
    this.props.updateBookingData(bookingData, true);
    this.handleRequestBooking(bookingData);
    this.setState({ rides });
  };

  /**
   * @param {object} missingFields - missing fields
   * @param {string} missingFieldsError - error message
   * @return {undefined}
   */
  transportTypeValidate = (missingFields, missingFieldsError = '') => {
    this.setState({ missingFields, missingFieldsError });
  };

  /**
   * utility function, sets all form and display components for a single ride to false
   * @return {object} ride
   */
  getRideProperties() {
    return {
      showTransportComplete: false,
      showRideNotesComplete: false,
      showTransportForm: false,
      showRideNotes: false,
      showAppointmentTime: false,
      completedLeg: false
    };
  }

  /**
   * utility function, sets all form components in ride array
   * this function will hide the form components.
   * @param {array} rides - array of ride state
   * @param {boolean} showDisplayComponents - show or hide display components
   * @return {array} rides
   */
  hideAllForms(rides, showDisplayComponents) {
    rides = rides.map(ride => {
      ride.showRideNotes = false;
      ride.showTransportForm = false;
      ride.showAppointmentTime = false;
      if (showDisplayComponents) {
        ride.showTransportComplete = true;
        ride.showRideNotesComplete = true;
      } else {
        ride.showTransportComplete = false;
        ride.showRideNotesComplete = false;
      }
      return ride;
    });

    return rides;
  }

  /**
   * making rides left messaging more grammatically correct
   * @return {string} message text
   */
  ridesLeftMessage() {
    const currentRideIndex = _.get(this.props, 'bookingData.currentRideIndex', -1);
    const ridesLeft = MULTIPLE_LEG_LIMIT - (currentRideIndex + 1);
    const legText = pluralize('leg', 'legs', ridesLeft);
    if (ridesLeft === 0) {
      return `You have no ${legText} left.`;
    } else if (ridesLeft === 1) {
      return `You have 1 ${legText} left.`;
    } else {
      return `You have ${ridesLeft} ${legText} left.`;
    }
  }

  render() {
    const userRole = this.props?.user?.role ?? '';
    const currentRideIndex = this.props?.bookingData?.currentRideIndex ?? -1;
    const ridesLeftMessage = this.ridesLeftMessage();

    let addingRide = this.state.addingRide;

    return (
      <div className="multipleRideBooking">
        {!_.isEmpty(this.state.rides) &&
        this.state.rides.length === this.props.bookingData.rides.length
          ? this.props.bookingData.rides.map((ride, i) => {
            if (i === currentRideIndex) {
              addingRide = false;
            }
            return (
              <div key={i}>
                {this.state.rides[i].showTransportForm && (
                  <TransportType
                    rideDetails={this.state.rideData}
                    updateRide={() => this.handleTransportationUpdate(i)}
                    isTransportFormBleg={this.state.isTransportFormBleg}
                    missingFields={this.state.missingFields}
                    missingFieldsError={this.state.missingFieldsError}
                    editRide={this.state.editTransportForm}
                    legIndex={i}
                    toggleLoading={this.props.toggleLoading}
                    closeComponent={() => this.closeFormComponents('TransportType', i)}
                  />
                )}
                {this.state.rides[i].showAppointmentTime && (
                  <AppointmentTime
                    bookingData={this.props.bookingData}
                    updateAppointmentInfo={bookingData =>
                      this.updateAppointmentInfo(bookingData, i)
                    }
                    isTransportFormBleg={this.state.isTransportFormBleg}
                    editForm={this.state.editAppointmentForm}
                    roundTrip={this.state.roundTrip}
                    transportTypeValidate={this.transportTypeValidate}
                    editBookedRide={false}
                    legIndex={i}
                    toggleLoading={this.props.toggleLoading}
                  />
                )}
                {this.state.rides[i].showTransportComplete && (
                  <TransportComplete
                    bookingData={this.props.bookingData}
                    editTransportInfo={() =>
                      this.editTransportInfo(this.props.bookingData, ride, i)
                    }
                    tripNumber={i}
                    userRole={userRole}
                    legIndex={i}
                    ride={ride}
                    removeLeg={() => this.removeLeg(i)}
                    disableEdit={addingRide}
                  />
                )}
                {this.state.rides[i].showRideNotes && (
                  <RideNotes
                    bookingData={this.props.bookingData}
                    requestRide={this.requestRide}
                    hideArrow={this.state.rides[i].showAppointmentTime}
                    handleRequestBooking={this.handleRequestBooking}
                    editRideNotesForm={this.state.editRideNotesForm}
                    editRide={false}
                    editBookedRide={false}
                    vehicleTypes={this.props.availableVehicleTypes}
                    legIndex={i}
                    handleEditNotes={bookingData =>
                      this.handleEditNotes(bookingData, i)
                    }
                    closeComponent={() => this.closeFormComponents('RideNotes', i)}
                    addAnotherTrip={this.addAnotherTrip}
                  />
                )}
                {this.state.rides[i].showRideNotesComplete && (
                  <RideNotesComplete
                    editRideNotesInfo={() =>
                      this.editRideNotesInfo(this.props.bookingData, ride, i)
                    }
                    toggleNotes={this.toggleNotes}
                    showAllNotes={this.state.showAllNotes}
                    bookingData={this.props.bookingData}
                    rideCustomFields={this.props.rideCustomFields}
                    userRole={userRole}
                    multiLeggedTrip={this.state.rides[i].showRideNotesComplete}
                    legIndex={i}
                    ride={ride}
                    disableEdit={addingRide}
                  />
                )}
              </div>
            );
          })
          : null}
        {this.state.showAddLegButton && currentRideIndex < MULTIPLE_LEG_LIMIT - 1 ? (
          <div className="addLeg clearfix">
            <button
              className="addLegButton"
              onClick={() => this.addAnotherTrip(this.props.bookingData)}
            >
              Add Another Leg
            </button>
          </div>
        ) : null}
        {currentRideIndex > -1 ? (
          <div className="remainder">
            <p>{ridesLeftMessage}</p>
          </div>
        ) : null}
      </div>
    );
  }
}

BookRidesMulti.defaultProps = {
  page: '',
  handleRequestBooking: () => {},
  toggleLoading: () => {},
  hideMiddleColumn: () => {},
  reset: () => {},
  updateBookingData: () => {},
  availableVehicleTypes: [],
  bookingData: {},
  rideCustomFields: {},
  user: {},
  addingRide: () => {}
};

BookRidesMulti.propTypes = {
  page: PropTypes.string,
  handleRequestBooking: PropTypes.func,
  toggleLoading: PropTypes.func,
  hideMiddleColumn: PropTypes.func,
  reset: PropTypes.func,
  updateBookingData: PropTypes.func,
  availableVehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bookingData: PropTypes.object,
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  user: PropTypes.object,
  addingRide: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user.userData,
  availableVehicleTypes: state.user.availableVehicleTypesForSelection,
  availableVehicleTypesNetwork: state.vehicles,
  bookingData: state.bookingData,
  patientCustomFields: state.user.patientCustomFields,
  rideCustomFields: state.user.rideCustomFields
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData: (data, replace) => updateBookingData(data, replace),
      reset: () => reset()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookRidesMulti);
