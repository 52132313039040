import { token } from '~/utilities/auth.helper';
import { parseSafeRideResponse } from '~/utilities/services';
import type { ApiResponseGeneric, ApiScheme } from '~/types';

export type MemberSavedAddress = {
  id: number;
  passenger_id: number;
  address: string;
  zip: string;
  name: string;
  latitude: number;
  longitude: number;
  is_default: boolean;
};

export type MemberSavedAddressRaw = Omit<MemberSavedAddress, 'id'>;

/**
 * Defines the request and response data structures for API calls to
 * the MemberSavedAddress endpoints
 */
export interface ApiSchemeMemberSavedAddress extends ApiScheme {
  Create: {
    Method: 'POST';
    Request: MemberSavedAddress;
    Response: ApiResponseGeneric;
  };

  Read: {
    Method: 'GET';
    Request: MemberSavedAddress['passenger_id'];
    Response: MemberSavedAddress;
  };

  Update: {
    Method: 'PUT';
    Request: MemberSavedAddress;
    Response: ApiResponseGeneric;
  };

  Delete: {
    Method: 'DELETE';
    Request: MemberSavedAddress['id'];
    Response: ApiResponseGeneric;
  };
}

export const getSavedAddress = async (
  memberId: MemberSavedAddress['passenger_id'],
  savedAddressId: MemberSavedAddress['id']
): Promise<ApiResponseGeneric & { data: MemberSavedAddress }> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${memberId}/saved-addresses/${savedAddressId}`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};

export const getSavedAddresses = async (memberId: MemberSavedAddress['passenger_id']) => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${memberId}/saved-addresses`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};

export const createSavedAddress = async (
  savedAddress: Omit<MemberSavedAddress, 'id'>
) => {
  const memberId = savedAddress.passenger_id;

  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${memberId}/saved-addresses`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(savedAddress)
    }
  )
    .then(res => res.json())
    .then(res =>
      parseSafeRideResponse<MemberSavedAddress>(res, 'creating a new saved address')
    );
};

export const updateSavedAddress = async (savedAddress: MemberSavedAddress) => {
  const memberId = savedAddress.passenger_id;

  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${memberId}/saved-addresses`,
    {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(savedAddress)
    }
  ).then(res => res.json());
};

export const deleteSavedAddress = async (
  memberId: MemberSavedAddress['passenger_id'],
  addressId: MemberSavedAddress['id']
) => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/${memberId}/saved-addresses/${addressId}`,
    {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};
