import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import type { DayModifiers, DayPickerProps } from 'react-day-picker';
import DayPicker, { DateUtils } from 'react-day-picker';

const INITIAL_STATE: Readonly<CalendarRangeState> = Object.freeze({
  startDate: undefined,
  endDate: undefined,
  enteredTo: undefined, // Keep track of the last day for mouseEnter.
  showError: false,
  errorMessage: undefined,
  openCalendar: false
});

type CalendarRangeState = {
  startDate?: Date;
  endDate?: Date;
  enteredTo?: Date;
  showError: boolean;
  errorMessage?: string;
  openCalendar: boolean;
};

type CalendarRangeProps = {
  openCalendar: boolean;
  startDate: Date;
  endDate: Date;
  disabledDays: { before: Date }[];
  fromMonth: Date;
  closeCalendar: () => void;
  submitModal: (dates: DateSubmissionParams) => void;
};

type DateSubmissionParams = { startDate: Date; endDate: Date };

const CalendarRange: React.FC<CalendarRangeProps> = ({
  openCalendar,
  startDate,
  endDate,
  disabledDays,
  fromMonth,
  closeCalendar,
  submitModal
}) => {
  const [state, setState] = useState<CalendarRangeState>(INITIAL_STATE);

  /** Computes the props passed to DayPicker element */
  const calendarProps = useMemo(() => {
    const { startDate, enteredTo } = state;

    const props = {
      className: 'Range',
      numberOfMonths: 1,
      disabledDays,
      selectedDays: [startDate, { from: startDate, to: enteredTo }],
      modifiers: { start: startDate, end: enteredTo }
    };

    if (fromMonth) {
      props.fromMonth = fromMonth;
    }

    return props;
  }, [state.startDate, state.endDate]);

  /**
   * @param {date} startDate - startDate
   * @param {date} endDate - endDate
   * @param {date} day - date selected
   * @return {boolean} returns whether if first day is selected
   */
  const isSelectingFirstDay = (startDate, endDate, day) => {
    const isBeforeFirstDay = startDate && DateUtils.isDayBefore(day, startDate);
    const isRangeSelected = startDate && endDate;

    return !startDate || isBeforeFirstDay || isRangeSelected;
  };

  /**
   * Event handler for clicking on date
   * @param {date} day - date object from DayPicker when you select a date on the calendar
   * @param {object} modifiers - make sure disabled days arent entered
   * @return {undefined} - nothing returned but state updated with ranges from DateUtils
   */
  const handleDayClick = (day: Date, { disabled }: DayModifiers) => {
    if (disabled) return;

    const { startDate, endDate } = state;

    if (startDate && endDate && day >= startDate && day <= endDate) {
      return setState(INITIAL_STATE);
    }

    return isSelectingFirstDay(startDate, endDate, day)
      ? setState({
        ...INITIAL_STATE,
        startDate: moment(day).startOf('day').toDate()
      })
      : setState({
        ...state,
        endDate: moment(day).endOf('day').toDate(),
        enteredTo: day,
        showError: false
      });
  };

  /**
   * Event handler for mouseover range
   * @param {date} day - date object from DayPicker when you select a date on the calendar
   * @return {undefined} - nothing returned
   */
  const handleDayMouseEnter = day => {
    if (!isSelectingFirstDay(state.startDate, state.endDate, day)) {
      setState({ ...state, enteredTo: day });
    }
  };

  /**
   * event handler for clicking submit button
   * both component state and redux app state are updated
   * @return {undefined}
   */
  const _submitModal = () => {
    const { startDate, endDate } = state;

    const validStartDate = startDate && moment(startDate).isValid();
    const validEndDate = endDate && moment(endDate).isValid();

    if (validStartDate) {
      if (validEndDate) {
        submitModal({ startDate, endDate } as DateSubmissionParams);
      } else {
        submitModal({
          startDate: moment(startDate).startOf('day').toDate(),
          endDate: moment(startDate).endOf('day').toDate()
        });
      }

      return closeCalendar();
    }

    return setState({
      ...state,
      showError: true,
      errorMessage: 'Please select a date range.'
    });
  };

  /**
   * format the year
   * @param {date} startDate - the start date
   * @return {string} formatted date - the date in year format
   */
  const formatYear = (startDate: Date) =>
    moment(startDate).isValid() ? moment(startDate).format('YYYY') : '--';

  /**
   * format the date display on left hand side
   * @param {date} startDate - startDate
   * @param {date} endDate - endDate
   * @return {string} formatted date - the date range formatted
   */
  const formatDate = (startDate, endDate) => {
    if (
      moment(startDate).isSame(moment(endDate), 'd') ||
      !endDate ||
      !moment(endDate).isValid()
    ) {
      return moment(startDate).isValid() ? moment(startDate).format('ddd, MMM D') : '--';
    }
    return `${moment(startDate).format('ddd, MMM D')} to ${moment(endDate).format(
      'MMM D'
    )}`;
  };

  useEffect(() => {
    const newState = { ...state };

    if (startDate) newState.startDate = startDate;

    if (endDate) {
      newState.endDate = endDate;
      newState.enteredTo = endDate;
    }
  }, [startDate, endDate]);

  return (
    <Modal
      isOpen={openCalendar}
      onRequestClose={closeCalendar}
      className={{
        base: 'newModalBaseClass newCalendar calendarRange',
        afterOpen: 'modalBaseClassOpen',
        beforeClose: 'modalBaseClassClose'
      }}
      overlayClassName={{
        base: 'overlayBaseClass',
        afterOpen: 'overlayBaseClassOpen',
        beforeClose: 'overlayBaseClassClose'
      }}
      closeTimeoutMS={500}
    >
      <div className="left">
        <p className="yearText">{formatYear(startDate)}</p>
        <p className="dateText">{formatDate(startDate, endDate)}</p>
      </div>
      <div className="right">
        <DayPicker
          {...(calendarProps as DayPickerProps)}
          onDayClick={handleDayClick}
          onDayMouseEnter={handleDayMouseEnter}
        />
        <div className="buttons">
          {state.showError ? <div className="errorMsg">{state.errorMessage}</div> : null}
          <button onClick={closeCalendar}>Cancel</button>
          <button onClick={_submitModal}>OK</button>
        </div>
      </div>
    </Modal>
  );
};

export default CalendarRange;
