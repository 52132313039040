import React from 'react';
import PropTypes from 'prop-types';
import SvgArrow from '../Svgs/SvgArrow';
import { isEqual, isEmpty, cloneDeep, has, get } from 'lodash-es';
import CheckBox from '../CheckBox';

class MultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      highlightLabel: false,
      label: '',
      placeholder: '',
      checked: {}
    };
  }

  componentDidMount() {
    const { items, defaultChecked } = this.props;
    const state = {};

    state.checked = this.getCheckedItems(items, defaultChecked);
    state.placeholder = this.getPlaceholder(defaultChecked);
    this.setState(state);
  }

  componentDidUpdate(prevProps) {
    const state = {};

    if (this.props.disabled && !prevProps.disabled && this.state.showModal) {
      state.showModal = false;
    }
    if (
      !isEqual(this.props.defaultChecked, prevProps.defaultChecked) ||
      !isEqual(this.props.items, prevProps.items)
    ) {
      state.placeholder = this.getPlaceholder(this.props.defaultChecked);
      state.checked = this.getCheckedItems(this.props.items, this.props.defaultChecked);
    }
    if (!isEmpty(state)) {
      this.setState(state);
    }
  }

  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleClick = () => {
    if (this.props.disabled) {
      return false;
    }
    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {object} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideClick = e => {
    if (this.props.disabled) {
      return false;
    }
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  /**
   * handles select and fires callback
   * @param {string} item - action item value
   * @return {undefined}
   */
  selectHandler = item => {
    const state = cloneDeep(this.state);
    state.checked[item.id] = {
      name: item.name,
      value: state.checked[item.id] ? !state.checked[item.id].value : false
    };
    const selected = {};
    state.placeholder = '';
    Object.keys(state.checked).forEach(key => {
      if (state.checked[key].value) {
        selected[key] = state.checked[key].name;
        if (state.placeholder === '') {
          state.placeholder = state.checked[key].name;
        } else {
          state.placeholder = `${state.placeholder}, ${state.checked[key].name}`;
        }
      }
    });
    this.setState(state, () => {
      this.props.dropDownCallback(selected);
    });
  };

  /**
   * Clears all selections
   */
  clearAll() {
    const state = cloneDeep(this.state);
    state.checked = {};
    state.placeholder = '';
    this.setState(state, () => {
      this.props.dropDownCallback(state.checked);
    });
  }

  /**
   * generate placeholder
   * @param {object} defaultChecked - default checked value from props
   * @return {string} return placeholder string
   */
  getPlaceholder(defaultChecked = {}) {
    if (isEmpty(defaultChecked)) {
      return '';
    }
    let placeholder = '';
    Object.keys(defaultChecked).forEach(key => {
      if (placeholder === '') {
        placeholder = defaultChecked[key];
      } else {
        placeholder = `${placeholder}, ${defaultChecked[key]}`;
      }
    });
    return placeholder;
  }

  /**
   * return checked drop down multiselect
   * @param {array} items - array of possible values
   * @param {object} defaultChecked - values that are default checked by external source
   * @return {object} returned object of checked multiselect items
   */
  getCheckedItems(items, defaultChecked) {
    const checked = {};
    if (items.length > 0) {
      items.forEach(item => {
        checked[item.id] = {
          name: item.name,
          value: has(defaultChecked, item.id) ? true : false
        };
      });
    }
    return checked;
  }

  render() {
    let customClassName = 'CustomDropDown';
    customClassName = this.props.black ? `${customClassName} black` : customClassName;
    customClassName =
      this.props.customClassName !== ''
        ? `${customClassName} ${this.props.customClassName}`
        : customClassName;
    customClassName = this.props.error ? `${customClassName} error` : customClassName;

    let dropDownField = this.state.highlightLabel
      ? 'dropDownField highlight'
      : 'dropDownField';
    dropDownField = this.props.error ? `${dropDownField} error` : dropDownField;
    dropDownField = this.props.disabled ? `${dropDownField} disabled` : dropDownField;
    const placeholder =
      this.state.placeholder !== '' ? this.state.placeholder : this.props.placeholder;
    const { checked } = this.state;

    return (
      <div className={customClassName}>
        {this.props.showLabel && this.props.label !== '' ? (
          <label className="inputLabel" onClick={this.handleClick}>
            {this.props.required ? <span className="showAsterisk">*</span> : null}
            {this.props.label}
          </label>
        ) : null}
        <div className={dropDownField} onClick={this.handleClick}>
          <span className="transportContent">{placeholder}</span>
          <SvgArrow className="whiteDownArrow" />
        </div>
        {this.state.showModal ? (
          <div
            className="dropDownModal"
            onBlur={this.handleClickOutside}
            ref={node => (this.node = node)}
          >
            <ul>
              {this.props.dropDownHeading !== '' ? (
                <li className="dropDownHeading">{this.props.dropDownHeading}</li>
              ) : null}
              {this.props.clearAll !== '' ? (
                <li className="dropDownHeading" onClick={() => this.clearAll()}>
                  {this.props.clearAll}
                </li>
              ) : null}
              {this.props.items.map((items, key) => {
                let itemShow = items;
                if (has(items, 'id') && has(items, 'name')) {
                  itemShow = this.props.sendBackObject ? itemShow : items.name;
                }
                const isChecked = get(checked, `${items.id}.value`, false);

                return (
                  <li key={key} className={isChecked ? 'highlighted' : null}>
                    <CheckBox
                      fieldName={`multiSelectCheck${key}`}
                      onChangeCallback={() => this.selectHandler(itemShow)}
                      checked={isChecked}
                      disabled={false}
                      required={false}
                      black={true}
                      label={items.name}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
        {this.props.errorText !== '' && this.props.error ? (
          <p className="errorText">{this.props.errorText}</p>
        ) : null}
      </div>
    );
  }
}

MultiSelect.propTypes = {
  items: PropTypes.array,
  dropDownCallback: PropTypes.func,
  placeholder: PropTypes.string,
  customClassName: PropTypes.string,
  black: PropTypes.bool, // if this is true, dropDownField will be black instead of white
  error: PropTypes.bool, // show error styling if it's true
  errorText: PropTypes.string,
  sendBackObject: PropTypes.bool, // send back object instead of items.name for second selectHandler parameter
  dropDownHeading: PropTypes.string, // unclickable list item displayed at top
  showLabel: PropTypes.bool,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  id: PropTypes.number,
  defaultChecked: PropTypes.object
};

MultiSelect.defaultProps = {
  items: [],
  dropDownCallback: () => {},
  placeholder: '',
  customClassName: '',
  black: false,
  error: false,
  errorText: '',
  sendBackObject: false,
  dropDownHeading: '',
  showLabel: false,
  label: '',
  disabled: false,
  required: false,
  id: null,
  defaultChecked: {} // for edit multi select and set default "checked" values
};

export default MultiSelect;
