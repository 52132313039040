import React from 'react';
import PastRideInput from '../PastRide/PastRideInput';

const RoundTripButton = ({
  pastRide = false,
  roundTrip = () => {},
  oneWayTrip = () => {},
  pastRideEnabled = false,
  setIsPastRide = () => {},
  multiLegEnabled = false,
  multiLeggedTrip = () => {},
  roundTripEnabled = true
}) => {
  return (
    <div className="roundTrip">
      <div className="arrow"></div>
      <button className="oneWayButton" onClick={oneWayTrip}>
        One-Way
      </button>
      {roundTripEnabled ? (
        <button className="roundTripButton" onClick={roundTrip}>
          Round Trip
        </button>
      ) : null}
      {multiLegEnabled ? (
        <button className="multiTripButton" onClick={multiLeggedTrip}>
          Multileg
        </button>
      ) : null}

      {pastRideEnabled ? (
        <PastRideInput pastRide={pastRide} setIsPastRide={setIsPastRide} />
      ) : null}
    </div>
  );
};

export default RoundTripButton;
