import React, {useState, useEffect} from 'react';
import Modal from 'react-modal';
import CreateRepeatingExpenseForm from './CreateRepeatingExpenseForm';
import {generateRepeatingExpenses} from '~/services/recurrence.service';
import {modalStyles} from '../styles';

const CreateRepeatingExpenseModal = ({
  displayModal = false,
  selectedExpense = null,
  saveExpense,
  cancelEditModal,
  hospitalGroupId,
  lastEligibleDate
}) => {
  const [modalDisplay, setModalDisplay] = useState(displayModal);
  useEffect(() => {
    setModalDisplay(displayModal);
  }, [displayModal]);

  const closeModal = () => {
    setModalDisplay(false);
    cancelEditModal();
  };

  const createRepeatingExpense = state => {
    const repeatingExpenses = generateRepeatingExpenses({
      selectedExpense,
      state,
      hospitalGroupId,
      lastEligibleDate
    });
    saveExpense({repeatingExpenses, mode: 'create', expense: selectedExpense});
    closeModal();
  };

  return (
    <Modal
      isOpen={modalDisplay}
      style={modalStyles}
      onRequestClose={closeModal}
    >
      <CreateRepeatingExpenseForm
        createRepeatingExpense={createRepeatingExpense}
        expense={selectedExpense}
        closeModal={closeModal}
      />
    </Modal>
  );
};

export default CreateRepeatingExpenseModal;
