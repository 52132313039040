import React from 'react';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { COMPLETED_OR_CANCELLED } from '~/constants';
import { convertCentsToDollars } from '~/utilities/currency';
import { useAppSelector } from '~/Modules';

const UberTripSummary = ({ rideData, dataToggles }) => {
  const getCancelledOrCompleted = () => {
    return COMPLETED_OR_CANCELLED.indexOf(rideData.status) > -1 ? true : false;
  };

  /**
   * Determine the pay source
   * @returns {string|object}
   */
  const getPayor = () => {
    if (!rideData.health_sub_plan_id) {
      if (!rideData.stripeTokenId) {
        return 'Self Funded';
      }

      return 'Patient';
    }

    return {
      healthSubPlanName: rideData.health_sub_plan_name,
      healthPlanName: rideData.health_plan_name
    };
  };

  /**
   * Determines the passenger cost to display
   * @returns {string}
   */
  const getPassengerCost = () => {
    let cost = '';

    if (rideData?.passengerCost) {
      cost = `$${parseFloat(rideData.passengerCost, 10).toFixed(2)}`;
    }

    if (rideData.vehicleTypeName === 'Uber' && rideData?.cost) {
      cost = `$${Math.round(rideData.cost * 1000) / 1000}`;
    }

    return cost;
  };

  const uberDetails = rideData.uberDetails;

  const driverDetails = uberDetails?.ride_data?.driver ?? {};
  const vehicleDetails = uberDetails?.ride_data?.vehicle ?? {};

  const { phone_number } = driverDetails;
  const { vehicle_color_name, make, model, license_plate, license_state } =
    vehicleDetails;

  const rideId = uberDetails?.flex_ride_id ?? uberDetails?.uber_ride_id ?? '';
  const referenceCode = uberDetails?.referenceCode ?? uberDetails?.reference_code ?? '';

  const payor = getPayor();
  const passengerCost = getPassengerCost();
  const cancelledOrCompleted = getCancelledOrCompleted();
  const billingData = useAppSelector(state => state.rideDetails.ride.data.billingData);
  let benefitAdjustmentCost = `${convertCentsToDollars(billingData.benefitAdjustmentCredit ?? 0)}`;

  if (benefitAdjustmentCost !== '$0.00') {
    benefitAdjustmentCost = `-${benefitAdjustmentCost}`;
  }

  return (
    <ul className="LyftTripSummary">
      <li>
        <p>Ride Id</p>
        <p>{rideData.id}</p>
      </li>
      <li>
        <p>Uber Ride Id</p>
        <p>{rideId}</p>
      </li>
      <li>
        <p>Uber Reference Code</p>
        <p>{referenceCode}</p>
      </li>
      <li>
        <p>Transportation Type</p>
        <p>{rideData.vehicleTypeName}</p>
      </li>
      {vehicle_color_name ? (
        <li>
          <p>Vehicle Color</p>
          <p>{vehicle_color_name}</p>
        </li>
      ) : null}
      {make && model ? (
        <li>
          <p>Vehicle Make and Model</p>
          <p>
            {make} {model}
          </p>
        </li>
      ) : null}
      {license_plate ? (
        <li>
          <p>License Plate</p>
          <p>
            {license_state ?? ''} {license_plate}
          </p>
        </li>
      ) : null}
      {phone_number ? (
        <li>
          <p>Member Facing Driver Phone Number</p>
          <p>{formatPhoneNumber(phone_number)}</p>
        </li>
      ) : null}
      {dataToggles.hospital_name ? (
        <li>
          <p>Hospital Name</p>
          <p>{rideData?.hospital?.hospitalName}</p>
        </li>
      ) : null}
      {typeof payor === 'string' ? (
        <li>
          <p>Payor</p>
          <p>{payor}</p>
        </li>
      ) : (
        <>
          <li>
            <p>Payor</p>
            <p>{payor.healthPlanName}</p>
          </li>
          <li>
            <p> </p>
            <p>{payor.healthSubPlanName}</p>
          </li>
        </>
      )}
      {rideData.booking_method && rideData.booking_method === 'member-portal' ? (
        <li>
          <p>Booked By</p>
          <p>Member</p>
        </li>
      ) : rideData.booked ? (
        <li>
          <p>Booked By</p>
          <p>
            {rideData.booked.firstName} {rideData.booked.lastName}
          </p>
        </li>
      ) : null}
      {rideData.booked ? (
        <li>
          <p>Booked Contact</p>
          <p>{formatPhoneNumber(rideData.booked.userMobileNo)}</p>
        </li>
      ) : null}
      {rideData.rideType !== 'ondemand' ? (
        <li>
          <p>Driver Punctuality</p>
          <p>{rideData.late_time}</p>
        </li>
      ) : null}
      <li>
        <p>Billable Distance</p>
        <p>{parseFloat(rideData.distance).toFixed(1)} miles</p>
      </li>
      <li>
        <p>Trip Duration</p>
        <p>{rideData.trip_duration}</p>
      </li>
      <li>
        <p>Estimated Cost</p>
        <p>{passengerCost}</p>
      </li>
      {dataToggles.trip_fee ? (
        <li>
          <p>Ride Cost</p>
          {cancelledOrCompleted ? (
            <p>${parseFloat(rideData.totalCost).toFixed(2)}</p>
          ) : (
            <p>To be finalized</p>
          )}
        </li>
      ) : null}
      <li>
        <p>Benefit Adjustment Cost</p>
        <p>
          <span className={benefitAdjustmentCost !== '$0.00' ? 'redIfNonZero' : ''}>
            {benefitAdjustmentCost}
          </span>
        </p>
      </li>
      <li>
        <p>Total Benefit Cost</p>
        <p>{convertCentsToDollars(billingData.totalBenefitCost ?? 0)}</p>
      </li>
      {dataToggles.trip_fee && rideData.lastPayment ? (
        <li>
          <p>Txn ID</p>
          <p>{rideData.lastPayment.txnno}</p>
        </li>
      ) : null}
      {rideData.pickupVenueName ? (
        <li>
          <p>Venue Pickup Location</p>
          <p>{rideData.pickupVenueName}</p>
        </li>
      ) : null}
      {rideData.pickupEntranceName ? (
        <li>
          <p>Venue Pickup Entrance</p>
          <p>{rideData.pickupEntranceName}</p>
        </li>
      ) : null}
      {rideData.dropoffVenueName ? (
        <li>
          <p>Venue Dropoff Location</p>
          <p>{rideData.dropoffVenueName}</p>
        </li>
      ) : null}
      {rideData.dropoffEntranceName ? (
        <li>
          <p>Venue Dropoff Entrance</p>
          <p>{rideData.dropoffEntranceName}</p>
        </li>
      ) : null}
      <li>
        <p>Claim Status</p>
        <p>{rideData?.claim_status}</p>
      </li>
      <li>
        <p>Alert Claim Status</p>
        <p>{rideData?.alert_claim_status}</p>
      </li>
    </ul>
  );
};

UberTripSummary.propTypes = {
  rideData: PropTypes.object,
  dataToggles: PropTypes.object,
  assistanceNeeds: PropTypes.string
};

UberTripSummary.defaultProps = {
  rideData: {},
  dataToggles: {},
  assistanceNeeds: ''
};

export default UberTripSummary;
