import { type NavOption } from '../../MemberProfiles.helper';
import { store } from '~/store';

/**
 * Generates the navigational options based on selected Id
 * @param {number} selectedId
 * @returns {NavOption[]}
 */
export function generateNavOptions(selectedId: number): NavOption[] {
  const appStore = store.getState();
  const healthPlanMemberPortal =
    appStore.memberProfile?.formData?.benefits?.healthPlanMemberPortal === 1;
  const healthSubPlanMemberPortal =
    appStore.memberProfile?.formData?.benefits?.healthSubPlanMemberPortal === 1;

  const displayMemberPortal = healthPlanMemberPortal && healthSubPlanMemberPortal;

  const navOptions = [
    {
      dataTip: 'Personal Info',
      label: 'Personal Info',
      parentForm: 'general',
      childForm: 'personal-info',
      route: `/member-profiles/${selectedId}/general/personal-info`
    },
    {
      dataTip: 'Mobility Assessment',
      label: 'Mobility Assessment',
      parentForm: 'general',
      childForm: 'member-mobility-assessment',
      route: `/member-profiles/${selectedId}/general/member-mobility-assessment`
    },
    {
      dataTip: 'Additional Passengers',
      label: 'Additional Passengers',
      parentForm: 'general',
      childForm: 'attendant-info',
      route: `/member-profiles/${selectedId}/general/attendant-info`
    },
    {
      dataTip: 'Mileage Reimbursement Drivers',
      label: 'Mileage Reimbursement Drivers',
      parentForm: 'general',
      childForm: 'mileage-reimbursement-drivers',
      route: `/member-profiles/${selectedId}/general/mileage-reimbursement-drivers`
    }
  ];

  if (displayMemberPortal) {
    navOptions.push({
      dataTip: 'Member Portal Support Tab',
      label: 'Member Portal',
      parentForm: 'general',
      childForm: 'member-portal-support',
      route: `/member-profiles/${selectedId}/general/member-portal-support`
    });
  }

  return navOptions;
}
