import React from 'react';
import SvgChatBubble from '../../../Svgs/SvgChatBubble';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { LYFT_VEHICLE_ID } from '~/constants';
import { connect } from 'react-redux';
import PublicRoute from '../../../BookingConfirmation/Components/TransportOptions/PublicRoute';
import PublicConfirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation/PublicConfirmation';

const NOTES_LENGTH_TRUNCATE = 80;
const noEditStatus = ['Inbound', 'Started', 'Arrived'];
const RideNotesComplete = props => {
  const {
    bookingData,
    editRideNotesInfo,
    toggleNotes,
    showAllNotes,
    rideCustomFields,
    userRole,
    ride,
    rideDetails
  } = props;

  const publicCards = rideDetails?.ride?.data?.ride?.publicCards;

  const rideData = _.isEmpty(ride) ? bookingData : Object.assign({}, ride, bookingData);
  const getAssistanceNeeds = () => {
    if (_.isEmpty(assistanceNeeds)) {
      return '';
    }

    let assistanceNeedsText = '';

    Object.keys(assistanceNeeds).map(key => {
      if (assistanceNeedsText === '') {
        assistanceNeedsText = assistanceNeeds[key];
      } else {
        assistanceNeedsText = `${assistanceNeedsText}, ${assistanceNeeds[key]}`;
      }
    });
    return assistanceNeedsText;
  };

  const assistanceNeeds = _.get(rideData, 'assistanceNeeds', {});
  let rideCustomData = [];

  if (userRole === 'HospitalOwner' || userRole === 'HospitalNetworkManager') {
    if (!_.isEmpty(bookingData)) {
      rideCustomData = _.get(rideCustomFields, 'bookingData.hospitalId', []);
    } else {
      rideCustomData = [];
    }
  } else if (userRole !== '') {
    rideCustomData = rideCustomFields;
  }
  if (_.isNil(rideCustomData)) {
    rideCustomData = [];
  }

  let bookingPatientNotes = _.get(rideData, 'additionalNotes', '');
  if (_.isNil(bookingPatientNotes)) {
    bookingPatientNotes = '';
  }

  const vehicleType = _.find(
    props.vehicleTypes,
    vehicleTypeRecord => vehicleTypeRecord.id === rideData.transportType
  );

  const hasBookingData =
    _.has(rideData, 'rideCustomFields') && !_.isNil(rideData.rideCustomFields)
      ? true
      : false;
  const publicRouteData =
    props.routeSelected ||
    _.get(props.rideDetails, 'ride.data.ride.publicData[0].route_data');

  return (
    <div>
      <div className="patientInfoComplete rideNotesComplete">
        <div className="left">
          {_.get(rideData, 'mode', 'Private') !== 'Private' ||
          _.get(props, 'bookingData.mode', 'Private') !== 'Private' ? (
              <p className="name transportType">
              PUBLIC [{_.get(vehicleType, 'nickName', '')}]
              </p>
            ) : (
              <p className="name transportType">
                {_.get(rideData, 'mode', 'Private') !== 'Private'
                  ? `Public [${_.get(vehicleType, 'nickName', '')}]`
                  : vehicleType?.modelName}
                {!_.isNil(rideData.originalReqVehicleType) &&
              rideData.originalReqVehicleType !== rideData.reqVehicleType ? (
                    <i> (Edited)</i>
                  ) : null}
              </p>
            )}
          {_.get(rideData, 'transportSubTypeName', '') !== '' ? (
            <p className="name transportType">{rideData.transportSubTypeName}</p>
          ) : null}
          {getAssistanceNeeds() !== '' && rideData.transportType !== LYFT_VEHICLE_ID ? (
            <p className="name transportType secondaryTransportType">
              {getAssistanceNeeds()}
            </p>
          ) : null}
          {bookingPatientNotes !== '' ? (
            <p className="notes">
              <SvgChatBubble className="chatIcon" />
              {bookingPatientNotes.length > NOTES_LENGTH_TRUNCATE ? (
                <span>
                  {showAllNotes === true ? (
                    <span>
                      {bookingPatientNotes}{' '}
                      <span className="more" onClick={toggleNotes}>
                        less
                      </span>
                    </span>
                  ) : (
                    <span>
                      {_.truncate(bookingPatientNotes, {
                        length: NOTES_LENGTH_TRUNCATE,
                        separator: ' '
                      })}{' '}
                      <span className="more" onClick={toggleNotes}>
                        more
                      </span>
                    </span>
                  )}
                </span>
              ) : (
                <span>{bookingPatientNotes}</span>
              )}
            </p>
          ) : null}
          {hasBookingData &&
            rideCustomData.map((field, key) => {
              const val = rideData.rideCustomFields[field.id];
              return (
                <p key={key} className="phone">
                  {field.field_label_desc}: {val}
                </p>
              );
            })}
        </div>
        {!props.disableEdit && noEditStatus.indexOf(props.status) === -1 ? (
          <div className="right" onClick={editRideNotesInfo}>
            <p className="edit">Edit</p>
          </div>
        ) : null}
      </div>
      {props.bookingData.mode === 'Public' && publicRouteData && (
        <div
          className="TransportOptions"
          style={{
            borderTop: '1px solid #e0e0e0',
            borderBottom: '1px solid #e0e0e0',
            padding: '12px',
            cursor: 'unset'
          }}
        >
          <PublicRoute
            leg={0}
            key={`rd-0`}
            fare={_.get(publicRouteData, 'fare.text', '')}
            transit={null}
            selected={0}
            hideLeg={true}
            routeDataKey={0}
            defaultOpen={true}
            handler={() => {}}
            routeData={publicRouteData}
          />
        </div>
      )}
      {props.bookingData.mode === 'Public' && publicCards && (
        <PublicConfirmation
          confirmPublic={null}
          ride={ride}
          trip={null}
          bookingData={bookingData}
          selectedLegs={null}
          disabled={true}
          rideDetails={publicCards}
          publicCards={publicCards}
        />
      )}
    </div>
  );
};

RideNotesComplete.defaultProps = {
  rideDetails: {},
  bookingData: {},
  editRideNotesInfo: () => {},
  toggleNotes: () => {},
  showAllNotes: false,
  rideCustomFields: [],
  userRole: '',
  disableEdit: false,
  status: 'Scheduled',
  multiLeggedTrip: false,
  ride: {},
  hospitalId: 0
};

RideNotesComplete.propTypes = {
  rideDetails: PropTypes.object,
  bookingData: PropTypes.object,
  editRideNotesInfo: PropTypes.func,
  toggleNotes: PropTypes.func,
  showAllNotes: PropTypes.bool,
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userRole: PropTypes.string,
  disableEdit: PropTypes.bool,
  status: PropTypes.string,
  multiLeggedTrip: PropTypes.bool,
  ride: PropTypes.object,
  hospitalId: PropTypes.number
};

const mapStateToProps = state => ({
  rideDetails: state.rideDetails,
  vehicleTypes: state.user.vehicleTypes
});

export default connect(mapStateToProps)(RideNotesComplete);
