import type { FormDropdownDataOption, MemberProfileStore } from '~/types';
import { ELIGIBILITY_API } from '~/constants/features';
import { Environments } from '~/constants/environments';
import type { UserStore } from '~/Modules/user';
import { type InputOption } from '@SRHealth/frontend-lib';
import { MOBILITY_DEVICES } from './constants';

const isEligibilityAPITestingEnvironment = () =>
  // @ts-expect-error - TS doesn't like that we're checking a string against a list of specific strings
  [Environments.LOCAL, Environments.SANDBOX].includes(process.env.ENVIRONMENT);

export const setMemberAccountParams = (
  params: Record<string, unknown>,
  user: UserStore,
  memberProfile: MemberProfileStore
) => {
  const isEligibilityApi = user?.features[ELIGIBILITY_API];

  const personalInfo = memberProfile.formData.personalInfo;

  if (personalInfo) {
    const { client_unique_id, external_sub_plan_id } = personalInfo;
    const healthSubPlanId = memberProfile.formData?.benefits?.healthSubPlanId;

    if (isEligibilityApi && client_unique_id && !isEligibilityAPITestingEnvironment()) {
      params.passengerId = client_unique_id;
    }

    if (external_sub_plan_id) {
      params.externalSubPlanId = external_sub_plan_id;
    }

    if (healthSubPlanId) {
      params.subPlanId = healthSubPlanId;
    }
  }

  return params;
};

/** Naively determine if an assistance need label is for a service level. */
function isServiceLevel(value: string) {
  return value.toLowerCase().includes('assistance level');
}

/** Naively determine if an assistance need label is for a mobility device. */
function isMobilityDevice(value: string) {
  return MOBILITY_DEVICES.includes(value);
}

/** Naively determine if an assistance need label is for a driver provision. */
function isDriverProvision(value: string) {
  return value.toLowerCase().includes('provide');
}

/** Splits off the assistance needs into their various categories. This separation
 * does not yet exist in the BE but __should__ be added after the initial RBF 4.0
 * release. */
export function formatAssistanceNeeds(assistanceNeeds: FormDropdownDataOption[]) {
  const _serviceLevels: (InputOption & { id: number })[] = [];
  const _driverProvisions: (InputOption & { id: number })[] = [];
  const _assistanceNeeds: (InputOption & { id: number })[] = [];
  const _mobilityDevices: (InputOption & { id: number })[] = [
    { id: 0, label: 'None', value: 'None' }
  ];

  for (const need of assistanceNeeds) {
    const label = need.value;

    if (isServiceLevel(label)) {
      // This apparently isn't a thing. We're just going to ignore it.
      if (label.includes('Bariatric')) continue;

      const _label = label.substring('Assistance Level - '.length);

      _serviceLevels.push({
        id: need.id,
        label: _label,
        value: _label
      });
    } else if (isMobilityDevice(label)) {
      _mobilityDevices.push({
        id: need.id,
        label,
        value: label
      });
    } else if (isDriverProvision(label)) {
      _driverProvisions.push({
        id: need.id,
        label,
        value: label
      });
    } else {
      _assistanceNeeds.push({
        id: need.id,
        label,
        value: label
      });
    }
  }

  return {
    assistanceNeeds: _assistanceNeeds,
    serviceLevels: _serviceLevels,
    mobilityDevices: _mobilityDevices,
    driverProvisions: _driverProvisions
  };
}
