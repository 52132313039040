import { RRule } from 'rrule';
import moment from 'moment';
import { FrequencyEnum, MonthlyRepeatENUM } from '~/constants';
import { generateExpenseId } from '~/utilities/helperFunctions';

/**
 * 
 * Overview - Generates an array of recurrence dates
 * 
 * @param {Object} recurrence
 * @param {number} recurrence.interval
 * @param {number} recurrence.frequency
 * @param {Object=} recurrence.monthlyRepeat
 * @param {Array=} recurrence.weeklyRepeat
 * @param {Object} recurrence.end
 * @param {string=} recurrence.end.endDate
 * @param {number=} recurrence.end.occurrence
 * 
 * @return {Array}  
 */

export function generateRecurrenceDates({ interval, frequency, monthlyRepeat = null, weeklyRepeat = null, endDate = null, count = null, dtstart, lastEligibleDate }) {

  if (endDate && endDate < lastEligibleDate) {
    lastEligibleDate = endDate;
  }

  const rule = new RRule({
    freq: RRule[frequency],
    interval,
    dtstart,
    /** So apparently the RRule table has trouble determining start
     * and end of date ranges if the time stamps on the the full datetimes
     * match. Specifically if you pass in dtstart 2023-03-05 12:00:00
     * and until with 2023-03-12 12:00:00 it does not include 2023-03-12 
     * in the final product range. Change it to end on 2023-03-11 and now
     * the end IS inclusive. This sets the time on end to always use 23:59:00
     * to hopefully avoid this in the future. See ENG-3527 in Jira */
    until: lastEligibleDate.hours(23).minutes(59),
    ...(count && { count: count + 1 }),

    ...(monthlyRepeat && {
      ...monthlyRepeat
    }),

    ...(weeklyRepeat && weeklyRepeat.length && {
      byweekday: [...weeklyRepeat]
    })

  });
  return rule && rule.all();

}

const endOnEnum = { END_ON_DATE: 'END_ON_DATE', END_ON_OCCURRENCE: 'END_ON_OCCURRENCE' };


export function generateRepeatingExpenses({ selectedExpense, state, hospitalGroupId, lastEligibleDate }) {

  const repeatingExpenseId = generateExpenseId(hospitalGroupId);
  const { expenseDate } = selectedExpense;
  const expenseDateFormatted = moment(expenseDate).format('YYYY/MM/DD hh:mm:ss');
  const { frequency, form } = state;
  const { repeat: interval, endOn } = form;
  
  const generateMonthlyKey = ({ monthlyRepeat = {} }) => {
    const { value } = monthlyRepeat;

    if (value === MonthlyRepeatENUM.MONTHLY_BY_DAY) {
      return null;
    } else {
      const weekNo = Math.ceil(new Date(expenseDateFormatted).getDate() / 7);
      const lastDateOfTheMonth = new Date(new Date(expenseDateFormatted).getFullYear(), new Date(expenseDateFormatted).getMonth() + 1, 0).getDate();

      const position = () => {
        if (weekNo > 4) return -1;
        if (weekNo === 4) {
          if (lastDateOfTheMonth - new Date(expenseDateFormatted).getDate() < 7) {
            return -1;
          }
        }
        return weekNo;
      };
      const weekday = new Date(expenseDateFormatted).getDay() - 1;
      const byWeekDay = weekday === -1 ? 6 : weekday;
      return {
        monthlyRepeat: {
          byweekday: byWeekDay,
          bysetpos: [position()]
        }

      };
    }
  };

  const body = {
    frequency,
    interval: Number(interval),
    dtstart: new Date(moment(expenseDate).format('YYYY/MM/DD hh:mm:ss')),
    lastEligibleDate,
    ...(endOn === endOnEnum.END_ON_DATE ? { endDate: moment(new Date(form.endOnDate)).add(1, 'day') } : { count: Number(form.occurrence) }),
    ...(frequency === FrequencyEnum.WEEKLY && { weeklyRepeat: state.weeklyRepeat.filter(d => d.checked).map(d => d.pos) }),
    ...(frequency === FrequencyEnum.MONTHLY && generateMonthlyKey(state))
  };

  const recurrenceDates = generateRecurrenceDates(body);

  if (!recurrenceDates.length) {
    return [];
  }

  return recurrenceDates
    .slice(1)
    .map(d => ({
      ...selectedExpense,
      repeatingExpenseId,
      id: generateExpenseId(hospitalGroupId),
      expenseDate: d.toISOString()
    }));
}
