import React from 'react';

const SvgInfo = props => {
  const {title, className} = props;

  return (
    <svg className={className}>
      <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#si-elusive-info-sign">
        <svg viewBox="0 0 1200 1200" aria-labelledby="fpsi-elusive-info-sign-title" id="si-elusive-info-sign" width="100%" height="100%">
          <title id="ibsi-awesome-exclamation-circle-title">{title}</title>
          <path d="M600 0C268.63 0 0 268.63 0 600s268.63 600 600 600 600-268.63 600-600S931.37 0 600 0zm-18.823 205.884c27.493 0 49.88 7.414 67.163 22.339 17.281 14.926 25.927 34.609 25.928 58.96 0 24.352-8.647 43.962-25.928 58.886-17.282 14.926-39.67 22.413-67.163 22.413-27.494 0-49.882-7.487-67.163-22.413-16.497-14.924-24.756-34.534-24.756-58.887 0-24.35 8.26-44.033 24.756-58.96 17.281-14.924 39.669-22.337 67.163-22.338zm85.986 262.72l23.584 27.1c-12.569 32.992-18.897 69.507-18.897 109.57v288.72c0 10.212.846 17.24 2.418 21.167 2.356 3.928 7.452 7.864 15.307 11.792 14.14 8.641 34.908 16.9 62.403 24.756l-8.203 42.407c-67.557-7.07-115.11-10.547-142.603-10.547-27.494 0-74.587 3.477-141.358 10.547l-9.448-42.407c28.28-6.284 49.122-14.157 62.476-23.584 11.783-7.07 17.651-18.42 17.651-34.13v-294.58c0-27.494-10.65-41.236-31.86-41.236l-41.236 8.203-9.375-47.095c28.28-11.782 65.568-23.206 111.914-34.204 46.347-10.997 82.09-16.479 107.227-16.48z"></path>
        </svg>
      </use>
    </svg>
  );
};

export default SvgInfo;
