export const initState = {
  isEligible: true,
  memberPortal: false,
  member_gender: '',
  member_genderId: null,
  member_genderError: false,
  member_primary_phone_type: '',
  member_primary_phone_typeId: null,
  member_primary_phone_typeError: false,
  member_primary_phone_preference: '',
  member_primary_phone_preferenceId: null,
  member_primary_phone_preferenceError: false,
  member_primary_phone_opt_out: '',
  member_primary_phone_opt_outId: null,
  member_primary_phone_opt_outError: false,
  member_secondary_phone_type: '',
  member_secondary_phone_typeId: null,
  member_secondary_phone_typeError: false,
  member_secondary_phone_preference: '',
  member_secondary_phone_preferenceId: null,
  member_secondary_phone_preferenceError: false,
  member_secondary_phone_opt_out: '',
  member_secondary_phone_opt_outId: null,
  member_secondary_phone_opt_outError: false,
  member_spoken_language: '',
  member_spoken_languageId: null,
  member_spoken_languageError: false,
  member_written_language: '',
  member_written_languageId: null,
  member_written_languageError: false,
  member_ethnicity: '',
  member_ethnicityId: null,
  member_ethnicityError: false,
  firstName: '',
  lastName: '',
  firstNameError: false,
  lastNameError: false,
  medicalId: '',
  medicalIdError: false,
  mobileNo: '',
  mobileNoError: false,
  phone2: '',
  phone2Error: false,
  dateOfBirth: '',
  dateOfBirthError: false,
  dateOfBirthEntered: false,
  viewStatus: 'editable',
  homeAddress: '',
  homeAddressLatitude: 0,
  homeAddressLongitude: 0,
  homeAddressError: false,
  billingAddress: '',
  billingAddressLatitude: 0,
  billingAddressLongitude: 0,
  billingAddressError: false,
  billingStreet2: '',
  email: '',
  emailError: false,
  coords: {
    lat: 0,
    lng: 0
  },
  formDisabled: true,
  internalNotes: '',
  otherDetails: '',
  showLoader: false,
  success: '',
  error: '',
  street2: '',
  isDirty: false,
  member_genderErrorText: '',
  member_spoken_languageErrorText: '',
  member_written_languageErrorText: '',
  member_primary_phone_typeErrorText: '',
  member_primary_phone_preferenceErrorText: '',
  member_primary_phone_opt_outErrorText: '',
  member_secondary_phone_typeErrorText: '',
  member_secondary_phone_preferenceErrorText: '',
  member_secondary_phone_opt_outErrorText: '',
  member_ethnicityErrorText: ''
};