import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { SAVED_ADDRESS, VENUES } from '~/constants';
import type { ButtonConfigWithValue, IconType } from '@SRHealth/frontend-lib';
import { selectAllowProvider } from './selectAllowProvider';
import type { AddressType } from '~/models';

const selectFeatures = (state: AppStore) => state.user.features;

/** Retrieve the different address types available to the passenger during RBF. This
 * is dependent on the features available to the user as well as the health plan
 * and/or health sub plan of the passenger */
export const selectAddressTypes = createSelector(
  [selectFeatures, selectAllowProvider],
  (features, allowsApprovedProvider) => {
    const addressTypes: ButtonConfigWithValue<string, IconType, AddressType>[] = [
      { label: 'Map', icon: 'Location', value: 'mapbox' }
    ];

    if (allowsApprovedProvider) {
      addressTypes.push({
        label: 'Care Provider',
        icon: 'CareProvider',
        value: 'approved-provider'
      });
    }

    if (features[VENUES]) {
      addressTypes.push({ label: 'Venues', icon: 'LocationCity', value: 'venue' });
    }

    if (features[SAVED_ADDRESS]) {
      addressTypes.push({ label: 'Saved Location', icon: 'Bookmark', value: 'saved' });
    }

    return addressTypes;
  }
);
