import { type FormState } from '~/Shared/Components/Form/Form';
import { type FormInputProps } from '~/Shared/Components/Form/FormInput';
import { type FormInputCheckboxProps } from '~/Shared/Components/Form/FormInputCheckbox';
import type { FormInputSelectProps } from '~/Shared/Components/Form/FormInputSelect';
import type { FormDropdownDataOption, MemberProfileStore, MobilityForm } from '~/types';

/**
 * Creates a map of assistance need names (values) to ids
 * @param {array} needs
 * @returns {Map}
 */
export function mapAssistanceNeedNamesToIds(needs: FormDropdownDataOption[]) {
  return new Map(needs.map(({ value, id }) => [value, id]));
}

/**
 * Creates a map of assistance need ids to name (values)
 * @param {array} needs
 * @returns {Map}
 */
export function mapAssistanceNeedIdsToNames(needs: FormDropdownDataOption[]) {
  return new Map(needs.map(({ value, id }) => [id, value]));
}

/**
 * Maps the assistance needs generated by the mobility assessment form
 * template to the format required by the updated member endpoint.
 *
 * Returns an object with the assistance need id as the key and a 1 or 0
 * @param {object} submission
 * @param {object} mobilityData
 * @returns {object}
 */
export function getAssistanceNeeds(
  submission: FormState,
  mobilityData: Partial<MobilityForm>
) {
  const mobilityDevices = (submission.find(({ name }) => name === 'mobility_devices') ??
    {}) as FormInputSelectProps;
  const driverAssistance = (submission.find(({ name }) => name === 'driver_assistance') ??
    {}) as FormInputSelectProps;
  const specialCircumstances = (submission.find(
    ({ name }) => name === 'special_circumstances'
  ) ?? {}) as FormInputCheckboxProps;
  const specialProvisions = (submission.find(
    ({ name }) => name === 'special_provisions'
  ) ?? {}) as FormInputCheckboxProps;

  const formattedMap = new Map<string, 1 | 0>();

  const length = Math.max(
    driverAssistance.options.length,
    specialCircumstances.options.length,
    specialProvisions.options.length
  );

  for (let i = 0; i < length; i++) {
    const mOpt = mobilityDevices.options[i] ?? undefined;
    const dOpt = driverAssistance.options[i] ?? undefined;
    const cOpt = specialCircumstances.options[i] ?? undefined;
    const pOpt = specialProvisions.options[i] ?? undefined;

    if (mOpt) {
      formattedMap.set(`${mOpt.value}`, mobilityDevices.selection === i ? 1 : 0);
    }

    if (dOpt) {
      formattedMap.set(`${dOpt.value}`, driverAssistance.selection === i ? 1 : 0);
    }

    if (cOpt) {
      formattedMap.set(
        `${cOpt.value}`,
        specialCircumstances.selection?.includes(i) ? 1 : 0
      );
    }

    if (pOpt) {
      formattedMap.set(`${pOpt.value}`, specialProvisions.selection?.includes(i) ? 1 : 0);
    }
  }

  const needs: Record<number, 1 | 0> = {};

  const assistanceNeeds = mobilityData.member_default_assistance_needs?.values ?? [];
  const mappedNeeds = mapAssistanceNeedNamesToIds(assistanceNeeds);
  for (const [name, id] of mappedNeeds.entries()) {
    needs[id] = formattedMap.get(name) ?? 0;
  }

  return needs;
}

/**
 * Iterates through each option from the form template and checks if
 * the value maps to an id in the Map. If it does then it retrieves the
 * id, checks if the id is in the default needs object and if it is equal
 * to 1. Assistance Needs with a value of 1 are set as the selected
 * value for the input.
 *
 * For multiselect inputs all needs with a value of 1 are selected.
 * @param {object} input
 * @param {map} map
 * @param {object} defaultNeeds
 * @param {boolean} multiselect
 * @returns {object}
 */
function fillAssistanceNeed(
  input: FormInputProps,
  map: Map<string, number>,
  defaultNeeds: Record<number, 1 | 0>,
  multiselect: boolean
) {
  input.options.forEach((opt, i) => {
    const value = `${opt.value}`;
    const id = map.has(value) ? map.get(value) : undefined;

    if (id && defaultNeeds[id] === 1) {
      if (multiselect) {
        input.selection ??= [];
        (input.selection as number[]).push(i);
      } else {
        input.selection = i;
      }
    }
  });

  return input;
}

/**
 * Autofills an empty mobility assessment template using any existing
 * assistance needs from the member profile.
 * @param {array} formTemplate
 * @param {object} member
 * @returns {array}
 */
export function autofillFirstAssessment(
  formTemplate: FormState,
  member: MemberProfileStore
) {
  if (!Array.isArray(formTemplate) || formTemplate.length < 5) return [];

  const assistanceNeeds =
    member?.formData?.mobility?.member_default_assistance_needs?.values ?? [];

  if (!assistanceNeeds.length) return formTemplate;

  const mappedNeeds = mapAssistanceNeedNamesToIds(assistanceNeeds);
  const memberDefaultNeeds =
    member?.formData?.mobility?.memberData?.member_default_assistance_needs ?? {};

  fillAssistanceNeed(formTemplate[1], mappedNeeds, memberDefaultNeeds, false);
  fillAssistanceNeed(formTemplate[2], mappedNeeds, memberDefaultNeeds, false);
  fillAssistanceNeed(formTemplate[3], mappedNeeds, memberDefaultNeeds, true);
  fillAssistanceNeed(formTemplate[4], mappedNeeds, memberDefaultNeeds, true);

  return formTemplate;
}
