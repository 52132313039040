import React from 'react';
import PropTypes from 'prop-types';

const SvgPencil = props => {
  const {title, className} = props;

  return (
    <svg className={className}>
      <use xlinkHref="#si-awesome-pencil">
        <svg viewBox="0 0 1515 1515" aria-labelledby="qhsi-awesome-pencil-title" id="si-awesome-pencil" width="100%" height="100%">
          <title id="qhsi-awesome-pencil-title">{title}</title>
          <path d="M363 1387l91-91-235-235-91 91v107h128v128h107zm523-928q0-22-22-22-10 0-17 7L305 986q-7 7-7 17 0 22 22 22 10 0 17-7l542-542q7-7 7-17zm-54-192l416 416-832 832H0v-416zm683 96q0 53-37 90l-166 166-416-416 166-165q36-38 90-38 53 0 91 38l235 234q37 39 37 91z"></path>
        </svg>
      </use>
    </svg>
  );
};

SvgPencil.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

SvgPencil.defaultProps = {
  title: 'Edit',
  className: ''
};

export default SvgPencil;
