import { hasBeenAbandoned, rideBookingMiddleware } from '.';
import { completePassengerInfoThunk, initializeRbfNewThunk } from '../thunks';
import { editPassengerInfo } from '../RideBooking.slice';
import type { PassengerInfoSection } from '../RideBooking.types';
import { analyticsService } from '~/analytics';
import type { AppListenerEffectAPI } from '~/Modules';
import { RIDE_SECTION_ORDER } from '../RideBooking.constants';
import type { AppStore } from '~/types';

const EVENT_NAME = 'RBF Passenger Info' as const;

let section: PassengerInfoSection = 'none';
let sectionTime = 0;
let sectionStartPoint = 0;
let subSectionStartPoint = 0;

const hasActiveSection = () => section !== 'none';

/** Reset the section and subsection times to 0 when
 * loading new RBF. */
rideBookingMiddleware.startListening({
  actionCreator: initializeRbfNewThunk.fulfilled,
  effect: () => {
    section = 'none';
    sectionTime = 0;
    sectionStartPoint = 0;
    subSectionStartPoint = 0;
  }
});

/** User has started editing a passenger info section of RBF. */
rideBookingMiddleware.startListening({
  actionCreator: editPassengerInfo,
  effect: (action, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    if (
      listenerApi.getState().rideBooking.meta.previousActiveSection !==
      RIDE_SECTION_ORDER['ride']
    ) {
      sectionStartPoint = Date.now();
    }

    sectionTime += Date.now() - sectionStartPoint;

    if (hasActiveSection()) {
      // Dispatch Abandoned event to mixpanel
      analyticsService.trackEvent(EVENT_NAME, {
        EditStatus: 'Abandoned',
        PassengerInfoSection: section,
        TimeOnSection: sectionTime,
        TimeOnSubSection: Date.now() - subSectionStartPoint,
        ...analyticsService.mapUserProps(user)
      });
    }

    section = action.payload;
    subSectionStartPoint = Date.now();

    // Dispatch RBF Passenger Info Started event
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Started',
      PassengerInfoSection: section,
      TimeOnSection: sectionTime,
      TimeOnSubSection: 0,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Completed section. */
rideBookingMiddleware.startListening({
  actionCreator: completePassengerInfoThunk.fulfilled,
  effect: (_, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    sectionTime += Date.now() - sectionStartPoint;

    // Dispatch RBF Passenger Info Completed event
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Completed',
      PassengerInfoSection: section,
      TimeOnSection: sectionTime,
      TimeOnSubSection: Date.now() - subSectionStartPoint,
      ...analyticsService.mapUserProps(user)
    });

    section = 'none';
    sectionStartPoint = 0;
  }
});

/** Abandoned ride. */
rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBeenAbandoned('passenger-info', currentState, oldState),
  effect: (_, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    sectionTime += Date.now() - sectionStartPoint;

    // Dispatch RBF Passenger Info Abandoned event
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Abandoned',
      PassengerInfoSection: section,
      TimeOnSection: sectionTime,
      TimeOnSubSection: Date.now() - subSectionStartPoint,
      ...analyticsService.mapUserProps(user)
    });

    section = 'none';
    sectionStartPoint = 0;
    subSectionStartPoint = 0;
  }
});
