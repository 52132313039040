import React from 'react';
import PropTypes from 'prop-types';

import {makeStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';

const useStyles = makeStyles(theme => ({
  paper: {
    flex: 1,
    width: '100%',
    height: 30,
    padding: '2px 4px'
  },
  label: {
    fontSize: '12px',
    lineHeight: '18px',
    margin: '6px 0 0 0'
  },
  input: {
    width: '100%',
    margin: `0 ${theme.spacing(1)}`
  },
  error: {
    outline: '3px solid #fa0'
  }
}));

export default function ApprovedProviderInput({
  label,
  value,
  className,
  placeholder,
  onChange,
  error,
  inputProps = {}
}) {
  const classes = useStyles();

  const handleChange = _onChange.bind({onChange});

  const paperClass = error
    ? `${classes.paper} ${classes.error}`
    : classes.paper;

  inputProps.style = inputProps.style ?? {};
  inputProps.style.backgroundColor = inputProps.style.backgroundColor ?? 'unset';

  return (
    <div className={className}>
      <div className={classes.label}>{label}</div>
      <Paper className={paperClass}>
        <InputBase
          className={classes.input}
          autoComplete="off"
          value={value}
          inputProps={inputProps}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </Paper>
    </div>
  );
}

/**
 * An event handler wrapper that automatically trims any input to
 * the 256 character limit.
 *
 * If an onChange method is bound then it will be called
 * @param {Event} e The event
 * @returns {void}
 */
function _onChange(e) {
  if (e?.target?.value && typeof e.target.value === 'string') {
    e.target.value = e.target.value.substring(0, 255);
  }

  if (this.onChange) {
    this.onChange(e);
  }
}

ApprovedProviderInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool
};

ApprovedProviderInput.defaultProps = {
  label: '',
  value: '',
  className: '',
  placeholder: '',
  onChange: () => {},
  error: false
};
