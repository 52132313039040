
export const mapFlexRidePayload = ({ submittedData, rideData}) => {
  return {
    transport: rideData.reqVehicleType, // number transport id number (lyft, uber, nemt)
    ride_id: rideData.id, // number, 11111,
    id: rideData.id, // number, 11111,
    transportType: rideData.reqVehicleType, // number
    cost: submittedData.cost, // number 8.92,
    eta: submittedData.eta, // number 780,
    distance: submittedData.distance, // number 1.96,
    duration: submittedData.duration, // number 780,
    ride_type: submittedData.rideShareType, // string 'UberX',
    rideType: 'flex',
    rideShareType: submittedData.rideShareType, // string 'UberX',
    ...(submittedData.product_id && { product_id: submittedData.product_id }), // string uuid
    ...(submittedData.cost_token && { cost_token: submittedData.cost_token }), // string uuid
    ...(submittedData.fare_id && { fare_id: submittedData.fare_id }), // string uuid
    ...(submittedData.run_id && { run_id: submittedData.run_id }), // string uuid
    estimatedCost: submittedData?.rides[0]?.estimatedCost || 0 // number
    
  };
};