import React, { useReducer, useState } from 'react';
import immutable from 'immutability-helper';
import moment from 'moment';
import { Button, Grid, Typography } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import Select from 'react-select';

import { useStyles, selectCustomStyles } from '../styles';
import { MonthlyRepeatENUM, FrequencyEnum } from '../constants';

const endOnEnum = { END_ON_DATE: 'END_ON_DATE', END_ON_OCCURRENCE: 'END_ON_OCCURRENCE' };
const dayOfTheWeek = dn => {
  const d = moment(dn).format('YYYY/MM/DD');
  return moment(d).day();
};

const FREQUENCY_ACTION = 'FREQUENCY_ACTION';
const SET_FREQUENCY_FORM = 'SET_FREQUENCY_FORM';
const SET_WEEKLY_REPEAT_DAYS = 'SET_WEEKLY_REPEAT_DAYS';
const SET_MONTHLY_REPEAT = 'SET_MONTHLY_REPEAT';
const SET_END_ON_OCCURRENCE = 'SET_END_ON_OCCURRENCE';

const generateEndOnDate = (date, freq) => {
  const d = moment(date).format('YYYY/MM/DD hh:mm:ss');

  switch (freq) {
    case FrequencyEnum.DAILY:
      return moment(d).add(30, 'days').format('YYYY/MM/DD');

    case FrequencyEnum.WEEKLY:
      return moment(d).add(91, 'days').format('YYYY/MM/DD');

    case FrequencyEnum.MONTHLY:
      return moment(d).add(1, 'years').format('YYYY/MM/DD');

    default:
  }
};

const generateEndOnOccurence = freq => {
  switch (freq) {
    case FrequencyEnum.DAILY:
      return '30';

    case FrequencyEnum.WEEKLY:
      return '12';

    case FrequencyEnum.MONTHLY:
      return '12';

    default:
  }
};

function reducer(state, action) {
  switch (action.type) {
    case FREQUENCY_ACTION: {
      const { payload } = action;
      const { expense, value } = payload;
      return immutable(state, {
        frequency: {
          $set: action.payload.value
        },
        form: {
          endOnDate: { $set: generateEndOnDate(expense.expenseDate, value) },
          occurrence: { $set: generateEndOnOccurence(value) }
        }
      });
    }
    case SET_FREQUENCY_FORM:
      return {
        ...state,
        form: { ...state.form, [action.payload.id]: action.payload.value }
      };
    case SET_WEEKLY_REPEAT_DAYS:
      return {
        ...state,
        weeklyRepeat: state.weeklyRepeat.map(d => {
          if (d.day === action.payload.name) {
            return { ...d, checked: !d.checked };
          }
          return { ...d };
        })
      };
    case SET_MONTHLY_REPEAT: {
      return { ...state, monthlyRepeat: action.payload };
    }
    case SET_END_ON_OCCURRENCE: {
      return { ...state, form: { ...state.form, endOn: action.payload.value } };
    }
  }
}

const CreateRepeatingExpenseForm = ({ closeModal, createRepeatingExpense, expense }) => {
  const initialDate = moment(expense.expenseDate).format('YYYY/MM/DD');
  const initialState = {
    frequency: FrequencyEnum.WEEKLY,
    weeklyRepeat: [
      {
        day: 'Sunday',
        value: 'SU',
        pos: 6,
        checked: dayOfTheWeek(expense.expenseDate) === 0 || false
      },
      {
        day: 'Monday',
        value: 'MO',
        pos: 0,
        checked: dayOfTheWeek(expense.expenseDate) === 1 || false
      },
      {
        day: 'Tuesday',
        value: 'TU',
        pos: 1,
        checked: dayOfTheWeek(expense.expenseDate) === 2 || false
      },
      {
        day: 'Wednesday',
        value: 'WE',
        pos: 2,
        checked: dayOfTheWeek(expense.expenseDate) === 3 || false
      },
      {
        day: 'Thursday',
        value: 'TH',
        pos: 3,
        checked: dayOfTheWeek(expense.expenseDate) === 4 || false
      },
      {
        day: 'Friday',
        value: 'FR',
        pos: 4,
        checked: dayOfTheWeek(expense.expenseDate) === 5 || false
      },
      {
        day: 'Saturday',
        value: 'SA',
        pos: 5,
        checked: dayOfTheWeek(expense.expenseDate) === 6 || false
      }
    ],
    form: {
      repeat: 1,
      endOn: endOnEnum.END_ON_DATE,
      occurrence: '12',
      endOnDate: moment(initialDate).add(91, 'days').format('YYYY/MM/DD')
    },
    monthlyRepeat: {
      label: `Monthly On Day ${new Date(moment(expense.expenseDate).format('YYYY/MM/DD')).getDate()}`,
      value: MonthlyRepeatENUM.MONTHLY_BY_DAY,
      id: 'monthlyRepeat'
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const [endRepeat, setEndRepeat] = useState(true);
  const [endRepeatOn, setEndRepeatOn] = useState(false);
  const classes = useStyles();

  //change handlers
  const onChange = e => {
    const { id, name, checked } = e.target;

    let value = e.target.value;
    if (['occurrence', 'frequency'].includes(id) && Number(value) < 1) {
      value = '1';
    }

    dispatch({
      type: SET_FREQUENCY_FORM,
      payload: {
        id,
        value,
        name,
        checked
      }
    });
  };

  const onMonthlyChange = e => {
    const { id, value, label } = e;
    dispatch({ type: SET_MONTHLY_REPEAT, payload: { id, value, label } });
  };

  const onWeeklyChange = e => {
    dispatch({
      type: SET_WEEKLY_REPEAT_DAYS,
      payload: { id: e.target.id, value: e.target.value, name: e.target.name }
    });
  };

  const onExpenseSelectChange = e => {
    dispatch({
      type: FREQUENCY_ACTION,
      payload: { id: e.target.id, value: e.target.value, expense }
    });
  };

  const onEndDateChange = e => {
    setEndRepeat(s => !s);
    setEndRepeatOn(s => !s);
    dispatch({ type: SET_END_ON_OCCURRENCE, payload: { value: e.target.value } });
  };

  //components

  const weeklyExpenseForm = () => {
    return (
      <div>
        <Typography className={classes.sectionTitle}>Repeat On</Typography>
        {state.weeklyRepeat.map((d, i) => {
          return (
            <div key={i} style={{ display: 'flex' }}>
              <input
                type="checkbox"
                id="expense-weekday"
                name={d.day}
                value={d.checked}
                checked={d.checked}
                onChange={onWeeklyChange}
              />
              <label htmlFor="expense-weekday">
                <Typography className={classes.formText}>{d.day}</Typography>
              </label>
            </div>
          );
        })}
      </div>
    );
  };

  const monthlyExpenseForm = () => {
    const { expenseDate } = expense;
    const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const prefixes = ['', 'first', 'second', 'third', 'fourth', 'last'];
    const date = moment(expenseDate).format('YYYY/MM/DD hh:mm:ss');
    const lastDateOfTheMonth = new Date(
      new Date(expenseDate).getFullYear(),
      new Date(expenseDate).getMonth() + 1,
      0
    ).getDate();
    const weekNo = Math.ceil(new Date(date).getDate() / 7);
    let weekOfMonth = prefixes[weekNo];
    if (weekOfMonth === 'fourth') {
      if (lastDateOfTheMonth - new Date(date).getDate() < 7) {
        weekOfMonth = 'last';
      }
    }

    const displayDate = new Date(
      moment(expense.expenseDate).format('YYYY/MM/DD')
    ).getDate();
    return (
      <Select
        onChange={onMonthlyChange}
        styles={selectCustomStyles}
        value={state.monthlyRepeat}
        options={[
          {
            label: `Monthly On Day ${displayDate}`,
            value: MonthlyRepeatENUM.MONTHLY_BY_DAY,
            id: 'monthlyRepeat'
          },
          {
            label: `Monthly on the ${weekOfMonth} ${days[new Date(moment(expenseDate).format('YYYY/MM/DD hh:mm:ss')).getDay()]}`,
            value: MonthlyRepeatENUM.MONTHLY_BY_WEEK_NUMBER,
            id: 'monthlyRepeat'
          }
        ]}
      />
    );
  };

  const renderEndOnForm = () => {
    return (
      <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" className={classes.sectionTitle}>
          Ends
        </Typography>
        <Grid>
          <div style={{ display: 'flex', marginBottom: 10, alignItems: 'center' }}>
            <input
              type="radio"
              id="endOn"
              name={endOnEnum.END_ON_DATE}
              value={endOnEnum.END_ON_DATE}
              checked={endRepeat}
              onChange={onEndDateChange}
            />
            <label htmlFor="expense-end" style={{ marginRight: 20 }}>
              <Typography className={classes.formText}>On</Typography>
            </label>

            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                id="endOnDate"
                name="endOnDate"
                format="MM/dd/yyyy"
                className={classes.endOnDate}
                value={state.form.endOnDate}
                onChange={value => {
                  onChange({
                    target: {
                      value: value,
                      id: 'endOnDate',
                      name: 'endOnDate'
                    }
                  });
                }}
                disabled={!endRepeat}
                InputProps={{
                  disableUnderline: true
                }}
              />
            </MuiPickersUtilsProvider>
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="radio"
              id="endOn"
              name={endOnEnum.END_ON_OCCURRENCE}
              value={endOnEnum.END_ON_OCCURRENCE}
              checked={endRepeatOn}
              onChange={onEndDateChange}
            />
            <label htmlFor="expense-end" style={{ marginRight: 11 }}>
              <Typography className={classes.formText}>After</Typography>
            </label>
            <div
              className={classes.occurenceBox}
              style={!endRepeatOn ? { backgroundColor: 'rgba(239, 239, 239, 0.3)' } : {}}
            >
              <input
                type="number"
                id="occurrence"
                name="occurrence"
                defaultValue={state.form.occurrence}
                value={state.form.occurrence}
                min={1}
                onChange={onChange}
                disabled={!endRepeatOn}
              />
              <div>
                <span>
                  <Typography className={classes.formText}>
                    {state.form.occurrence > 1 ? `occurrences` : `occurrence`}
                  </Typography>
                </span>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    );
  };

  const renderExpenseForm = () => {
    if (state.frequency === FrequencyEnum.DAILY) return <div />;
    if (state.frequency === FrequencyEnum.WEEKLY) return weeklyExpenseForm();
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <Typography variant="h6" className={classes.title}>
            Repeat Expense
          </Typography>
          <Typography onClick={closeModal} className={classes.closeIcon}>
            X
          </Typography>
        </div>
        <div>
          <Typography
            variant="h6"
            className={classes.sectionTitle}
            style={{ marginTop: 10 }}
          >
            Repeat Every
          </Typography>
          <Grid container>
            <input
              className={classes.repeat}
              type="number"
              name="expense-repeat"
              id="repeat"
              min={1}
              defaultValue={1}
              onChange={onChange}
            />
            <select
              className={classes.dropdown}
              name="expense-frequency"
              id="frequency"
              onChange={onExpenseSelectChange}
            >
              <option className={classes.optionItem} value={FrequencyEnum.DAILY}>
                {state.form.repeat > 1 ? `Days` : 'Day'}
              </option>
              <option
                className={classes.optionItem}
                value={FrequencyEnum.WEEKLY}
                selected
              >
                {state.form.repeat > 1 ? `Weeks` : 'Week'}
              </option>
              <option className={classes.optionItem} value={FrequencyEnum.MONTHLY}>
                {state.form.repeat > 1 ? `Months` : 'Month'}
              </option>
            </select>
          </Grid>
          <Grid style={{ marginTop: 10 }}>
            {state.frequency === FrequencyEnum.MONTHLY && monthlyExpenseForm()}
          </Grid>
        </div>
        <Grid container>{renderExpenseForm()}</Grid>
      </div>
      {renderEndOnForm()}
      <Grid container direction="row" alignItems="flex-end" justify="flex-end">
        <Button className={classes.buttonCancel} onClick={closeModal}>
          Cancel
        </Button>
        <Button
          className={classes.buttonAdd}
          onClick={() => createRepeatingExpense(state)}
        >
          Add
        </Button>
      </Grid>
    </div>
  );
};

export default CreateRepeatingExpenseForm;
