export const submitBenefitCreditStyles = {
  overlay: {
    zIndex: 100
  },
  content: {
    zIndex: 100,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};