import React, { useCallback, useMemo } from 'react';
import { Input, isEmpty, MultiSelectButton } from '@SRHealth/frontend-lib';
import { type AddressApprovedProviderModel, type AddressModel } from '~/models';
import type { AddressFieldType } from '../Rides.types';
import {
  genDefaultAddressOptions,
  useAddressChange,
  useProviderAddressChangeHandler,
  useProviderCoordUpdateHandler
} from './Address.utils';
import type { AddressFormAddressType } from './AddressForm';
import { useAppSelector } from '~/Modules';
import useApprovedProviders from '~/hooks/useApprovedProviders';
import useMapboxSearch from '~/hooks/useMapboxSearch';

export type AddressFormApprovedProviderProps = {
  type: AddressFieldType;
  address: AddressApprovedProviderModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  /** Optional alternative address types available to the user. */
  addressTypes?: AddressFormAddressType[];
};

export default function AddressFormApprovedProvider({
  type,
  address,
  onAddressChange,
  addressTypes = []
}: AddressFormApprovedProviderProps) {
  const hospitalId = useAppSelector(s => s.rideBooking.fundingSource.facilityId!);

  const defaultMapOpt = useMemo(() => genDefaultAddressOptions(address), [address]);
  const [_, mapboxOptions, handleMapboxInput] = useMapboxSearch(defaultMapOpt);
  const [isLoadingProviders, providerOptions, onProviderInput, onLoadMore] =
    useApprovedProviders(hospitalId, address);

  const label = type === 'depart' ? 'Depart' : 'Arrive';

  const hasAddressTypes = addressTypes.length > 1;
  const activeAddressType = addressTypes.findIndex(t => t.value === address.type);
  const hasError = !!Object.keys(address.ruleErrors).length;

  const currentProvider = providerOptions.find(({ value }) => value.id === address.id);
  const currentMapbox = mapboxOptions.find(({ value }) => value.value === address.value);

  const _onAddressChange = useCallback(
    (newAddr: AddressModel) => onAddressChange(type, newAddr),
    [onAddressChange, type]
  );

  /** Event handler for changes to the address type. */
  const handleAddressTypeChange = useAddressChange(address.type, type, onAddressChange);
  /** Event handler for changes to the care provider input. */
  const handleProviderChange = useProviderAddressChangeHandler(_onAddressChange);
  /** Event handler for changes to the mapbox input. */
  const handleMapboxChange = useProviderCoordUpdateHandler(address, _onAddressChange);

  return (
    <div className="flex flex-col w-full gap-[8px] min-w-0">
      <div className="flex flex-row no-wrap w-full items-center gap-[4px]">
        <div className="flex-1 min-w-0">
          <Input
            inputId={0}
            type="search-dropdown"
            name={type}
            label={`${label} - Care Provider`}
            options={providerOptions}
            loading={isLoadingProviders}
            dropdownSize={5}
            placeholder="Search Care Provider"
            value={currentProvider?.value}
            debounce={650}
            onSearch={onProviderInput}
            onInput={onProviderInput}
            onChange={handleProviderChange}
            onLoadMore={onLoadMore}
            error={'name' in address.ruleErrors}
            required
          />
        </div>
        {hasAddressTypes ? (
          <div className="flex items-center h-[40px]" style={{ paddingBottom: '16px' }}>
            <MultiSelectButton
              buttons={addressTypes}
              activeButton={activeAddressType}
              onClick={handleAddressTypeChange}
            />
          </div>
        ) : null}
      </div>
      {!isEmpty(address.npi) && (
        <Input
          inputId={1}
          type="search-dropdown"
          name={`${type}-mapbox`}
          options={mapboxOptions}
          loading={false}
          placeholder="Search for an address"
          value={currentMapbox?.value}
          debounce={650}
          icon={undefined}
          onSearch={handleMapboxInput}
          onInput={handleMapboxInput}
          onChange={handleMapboxChange}
          error={hasError}
          required
        />
      )}
    </div>
  );
}
