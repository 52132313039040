import React from 'react';

const SvgMagnifyingGlass = props => {
  const {className} = props;

  return (
    <svg className={className}>
      <use xlinkHref="#si-ant-search">
        <svg viewBox="0 0 1024 1024" aria-labelledby="fusi-ant-search-title" id="si-ant-search" width="100%" height="100%">
          <title id="fusi-ant-search-title">icon search</title>
          <path d="M1015 969L732 687q100-117 100-271 0-172-122-294T416 0 122 122 0 416t122 294 294 122q154 0 271-100l282 283q10 9 23 9t23-9q9-10 9-23t-9-23zM553 740q-65 28-137 28t-137-28q-63-26-112-75T92 553q-28-65-28-137t28-137q26-63 75-112t112-75q65-28 137-28t137 28q63 26 112 75t75 112q28 65 28 137t-28 137q-26 63-75 112t-112 75z"></path>
        </svg>
      </use>
    </svg>
  );
};

export default SvgMagnifyingGlass;