import { token } from '~/utilities/auth.helper';
import type { ApiResponseGeneric } from '../types';

type GetApprovedProviderDataForRideResponse = ApiResponseGeneric & {
  data: {
    // pickup approved provider values
    pickupProviderId: number;
    pickupProviderName: string;
    pickupProviderAddr: string;
    pickupProviderPhone: string;
    pickupProviderNpi: string;
    pickupProviderNotFound: boolean;
    pickupFacilityName: string;
    pickupAdditionalNotes: string;
    // dropoff approved provider values
    dropoffProviderId: number;
    dropoffProviderName: string;
    dropoffProviderAddr: string;
    dropoffProviderPhone: string;
    dropoffProviderNpi: string;
    dropoffProviderNotFound: boolean;
    dropoffFacilityName: string;
    dropoffAdditionalNotes: string;
  };
};

/**
 * Fetches approved provider data from a specified booked ride ID.
 *
 */
export const getApprovedProviderDataForRide = (
  bookedRideId: number
): Promise<GetApprovedProviderDataForRideResponse> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/approved-providers-from-booked-ride/${bookedRideId}`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};
