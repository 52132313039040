import { cloneDeep, pickBy, isEmpty } from 'lodash-es';
/**
   * @name compliance.service.js
   *  * @description complianceInfo data is structure poorly on BE, it's recursive. Below are helpers to
   *                assist in determining dependencies between fields. We store compliance data on
   *                individual rides and in expenses but it is stored in a different structure than
   *                received. It gets stored as a flat object, which removes the ability to know relationships.
   * 
   *                Long story short, lots of recursive lookups, use the functions below to operate with complianceInfo.
   *                (Reference usage in ExpenseInputs.js for examples)
   * 
   * @summary Supported Functions:
                - parentFieldHasValue
                - getParentValue
                - getParentField
                - getOptionsForComplianceDropdown 
*/
/**
 * @name parentFieldHasValue
 */
export const parentFieldHasValue = ({
  previous = {},
  input_name, // string
  isFirstField, // bool
  selectedValues, //obj
  parentHasValue, // bool
  foundChildInput, // bool
  complianceField // obj
}) => {
  if (isFirstField) return true;

  if (complianceField === null) return false;

  if (selectedValues[previous.input_name]) {
    parentHasValue = true;
  }

  if (complianceField.input_name === input_name) {
    foundChildInput = true;
  }

  if (foundChildInput) {
    return !!parentHasValue;
  }

  return parentFieldHasValue({
    input_name,
    isFirstField: false,
    selectedValues,
    parentHasValue: false,
    foundChildInput: false,
    previous: complianceField,
    complianceField: complianceField.child
  });
};

/**
 *
 * @name getParentValue
 */
export const getParentValue = ({
  input_name,
  previous = {},
  isFirstField, // bool
  parentValue, // obj
  selectedValues, // obj
  foundChildInput, // bool
  complianceField // obj
}) => {
  if (isFirstField) return true;

  if (complianceField === null) return false;

  if (selectedValues[previous.input_name]) {
    parentValue = selectedValues[previous.input_name];
  }

  if (complianceField.input_name === input_name) {
    foundChildInput = true;
  }

  if (foundChildInput) {
    return parentValue ? parentValue : false;
  }
  return getParentValue({
    input_name,
    isFirstField: false,
    parentValue: undefined,
    selectedValues,
    foundChildInput: false,
    previous: complianceField,
    complianceField: complianceField.child
  });
};

/**
 * @name getParentField
 *
 */
export const getParentField = ({
  previous = null, // obj
  input_name, // string
  isFirstField, // bool
  foundChildInput = false, // bool
  complianceField // obj
}) => {
  if (isFirstField) return null;
  if (complianceField === null) return previous;

  if (complianceField.input_name === input_name) {
    foundChildInput = true;
  }
  if (previous && foundChildInput) {
    return previous;
  }

  return getParentField({
    input_name,
    isFirstField: false,
    foundChildInput: false,
    previous: complianceField,
    complianceField: complianceField.child
  });
};

export const getOptionsForComplianceDropdown = ({
  isFirstField, // bool
  selectedValues, // obj
  filteredOptions, // obj
  complianceField, // obj
  parentmostComplianceField // obj
}) => {
  if (complianceField === null) return [];

  if (isFirstField) return complianceField.compliance_options;

  const { input_name, compliance_options } = complianceField;

  let selectedOptionId = null;

  const parentValue = getParentValue({
    input_name,
    isFirstField,
    selectedValues,
    parentHasValue: false,
    foundChildInput: false,
    complianceField: parentmostComplianceField
  });
  const parentField = getParentField({
    input_name,
    isFirstField,
    complianceField: parentmostComplianceField
  });

  if (parentValue) {
    parentField.compliance_options.map(({ id, option_value }) => {
      if (option_value === parentValue) {
        selectedOptionId = id;
      }
    });
  }

  if (selectedOptionId && !filteredOptions) {
    filteredOptions = parentField.option_mapping.reduce((acc, cur) => {
      if (cur.parent_option_id === selectedOptionId) {
        acc[cur.child_option_id] = cur.child_option_id;
      }
      return acc;
    }, {});
  }

  if (filteredOptions) {
    return compliance_options.filter(({ id }) => {
      return !!filteredOptions[id];
    });
  }

  return getOptionsForComplianceDropdown({
    selectedValues,
    isFirstField: false,
    filteredOptions,
    complianceField: complianceField.child,
    parentmostComplianceField
  });
};

/**
 * @name getDependentFieldOptions
 *
 */
export const getDependentFieldOptions = ({ level, complianceOptions, selections }) => {
  if (level === 0) {
    return complianceOptions.map(({ option_value }) => ({
      label: option_value,
      value: option_value
    }));
  }

  return complianceOptions
    .filter(({ parent_option_id }) => parent_option_id === selections[level - 1].id)
    .map(({ option_value }) => ({
      label: option_value,
      value: option_value
    }));
};

/**
 * @name getDependentFieldValues
 *
 */
export const getDependentFieldValues = ({ selectedValues, inputName }) => {
  if (selectedValues[inputName]) {
    return [
      {
        value: selectedValues[inputName],
        label: selectedValues[inputName]
      }
    ];
  }

  return [];
};

/**
 * @name onDependentFieldsChange
 *
 */
export const onDependentFieldsChange = ({
  setField,
  selectedValues,
  selections,
  values,
  inputName
}) => {
  const val = values[0] ? values[0].value : null;
  const selectedFieldKey = Object.keys(selections).find(
    key => selections[key].inputName === inputName
  );

  const childFields = isEmpty(selectedFieldKey)
    ? {}
    : pickBy(
      selections,
      o => Number(o.level) > Number(selections[selectedFieldKey].level)
    );
  const _selectedValues = cloneDeep(selectedValues);
  if (!val) {
    if (isEmpty(childFields)) {
      delete selections[selectedFieldKey];
      delete _selectedValues[inputName];
    } else {
      Object.keys(childFields).map(key => {
        delete selections[childFields[key].level];
        delete _selectedValues[childFields[key].inputName];
      });
      delete selections[selectedFieldKey];
      delete _selectedValues[inputName];
    }
  } else {
    _selectedValues[inputName] = val;
    if (childFields) {
      Object.keys(childFields).map(key => {
        delete selections[childFields[key].level];
        delete _selectedValues[childFields[key].inputName];
      });
    }
  }
  setField({
    name: 'complianceFields',
    value: _selectedValues
  });
};

/**
 * @name setDependentFieldSelectionTree
 *
 */
export const setDependentFieldSelectionTree = ({
  complianceOptions,
  level,
  inputName,
  selections,
  selectedValues
}) => {
  if (level > 0) {
    selections[level] = {
      ...complianceOptions.find(
        o =>
          o.parent_option_id === selections[level - 1].id &&
          o.option_value === selectedValues[inputName]
      ),
      level,
      inputName
    };
  } else {
    selections[level] = {
      ...complianceOptions.find(o => o.option_value === selectedValues[inputName]),
      level,
      inputName
    };
  }
};
