import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { fixUTCDate } from '~/utilities/timesAndDates';

const CONFIRMATION_STRING = {
  unconfirmed: 'Unconfirmed',
  confirmed: 'Confirmed',
  driver_unable_to_fulfill: 'Driver Unable to Fulfill',
  nemt_unable_to_fulfill: 'NEMT Unable to Fulfill'
};

const TripDetails = props => {
  const { rideData } = props;
  return (
    <ul className="TripDetails">
      <li>
        <p>Scheduled On</p>
        <p>{rideData.ShowCreated_on}</p>
      </li>
      <li>
        <p>
          {_.isNil(rideData.confirmation_status)
            ? 'Unconfirmed'
            : CONFIRMATION_STRING[rideData.confirmation_status]}
        </p>
        <p>
          {rideData.ShowConfirmationTime !== '' ? rideData.ShowConfirmationTime : '--'}
        </p>
      </li>
      <li>
        <p>Inbound Time</p>
        <p>{rideData.ShowAcceptedAtTime !== '' ? rideData.ShowAcceptedAtTime : '--'}</p>
      </li>
      {!_.isNil(rideData.nemtRunPickup) ? (
        <li>
          <p>Run Pickup Time</p>
          <p>
            {fixUTCDate(
              rideData.nemtRunPickup,
              rideData.passenger.timezone_format,
              'MM-DD-YYYY h:mm A z',
              'YYYY-MM-DD HH:mm:ss'
            )}
          </p>
        </li>
      ) : null}
      {!_.isNil(rideData.nemtRunDropoff) ? (
        <li>
          <p>Run Dropoff Time</p>
          <p>
            {fixUTCDate(
              rideData.nemtRunDropoff,
              rideData.passenger.timezone_format,
              'MM-DD-YYYY h:mm A z',
              'YYYY-MM-DD HH:mm:ss'
            )}
          </p>
        </li>
      ) : null}
      <li>
        <p>Arrival Time</p>
        <p>{rideData.ShowArrivedAt !== '' ? rideData.ShowArrivedAt : '--'}</p>
      </li>
      {rideData.rideType === 'ondemand' ? (
        <li>
          <p>Waiting Time</p>
          <p>{rideData.patient_waiting_time}</p>
        </li>
      ) : null}
      <li>
        <p>Start Time</p>
        <p>{rideData.ShowStartedAt !== '' ? rideData.ShowStartedAt : '--'}</p>
      </li>
      <li>
        <p>Completed Time</p>
        <p>{rideData.ShowCompletedAt !== '' ? rideData.ShowCompletedAt : '--'}</p>
      </li>
      <li>
        <p>
          Schedule Pickup{' '}
          {!_.isNil(rideData.originalRideStartTime) &&
          rideData.originalRideStartTime !== rideData.rideStartTime ? (
              <i>(Edited)</i>
            ) : null}
        </p>
        <p>{rideData.ShowRideStartTime !== '' ? rideData.ShowRideStartTime : '--'}</p>
      </li>
      {!_.isNil(rideData.appointmentTime) ? (
        <li>
          <p>
            Schedule Appt.{' '}
            {!_.isNil(rideData.originalAppointmentTime) &&
            rideData.originalAppointmentTime !== rideData.appointmentTime ? (
                <i>(Edited)</i>
              ) : null}
          </p>
          <p>
            {rideData.ShowAppointmentTime !== '' ? rideData.ShowAppointmentTime : '--'}
          </p>
        </li>
      ) : null}
      {!_.isNil(rideData.extraTime) ? (
        <li>
          <p>Extra Drive Time Requested</p>
          <p>{rideData.extraTime} Minutes</p>
        </li>
      ) : null}
    </ul>
  );
};

TripDetails.propTypes = {
  rideData: PropTypes.object
};

TripDetails.defaultProps = {
  rideData: {}
};

export default TripDetails;
