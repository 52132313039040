import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  resetUpdatedCards,
  requestPickup,
  resetCards,
  resetShowWillCallReadyNowError,
  resetShowUpdateDateError,
  fetchRideCards,
  resetScheduledRides
} from '~/Modules/rideCards/rideCards.actions';
import { reset } from '~/Modules/scheduleRides';
import { getRide, clearRide } from '~/Modules/rideDetails';
import { getVehicles } from '~/Modules/user';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import ScheduledRidesHeader from './Components/ScheduledRidesHeader/ScheduledRidesHeader.tsx';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import BookRides from '~/Shared/Components/BookRides';
import EditRide from '~/Shared/Components/EditRide';
import ErrorModal from '~/Shared/Components/ErrorModal';
import BookingConfirmation from '~/Shared/Components/BookingConfirmation';
import WindowInfiniteScroll from './Components/WindowInfiniteScroll';
import { selectRequest } from '~/Modules/chats';
import {
  DAY_START,
  TOMORROW_END,
  DATE_API_FORMAT,
  RIDE_CARDS_LIMIT,
  LYFT_VEHICLE_ID,
  COMPONENT_STRINGS,
  UBER_VEHICLE_ID,
  ALERT_PAGES
} from '~/constants';
import moment from 'moment';
import CancelRide from './Components/CancelRide';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { showExtraDetails, isNetworkRole, NOOP } from '~/utilities/helperFunctions';
import RideDetails from '~/Pages/RideDetails/RideDetails';
import Loading from 'react-loading';
import { MapboxMap, svgs } from 'mapbox-components';
import LyftOnDemand from './Components/LyftOnDemand';
import ReassignNemt from './Components/ReassignNemt';
import ComplianceHOC from '~/Shared/Components/ComplianceHOC';
import { calculateTzOffset } from '~/utilities/users';
import { clearNewMemberForm } from '~/Modules/members/reducer';
import ActiveMap from '~/Shared/Components/ActiveMap/ActiveMap';
import SavedAddressDrawer, {
  useSavedAddressDrawer
} from '~/Shared/Components/SavedAddressDrawer/SavedAddressDrawer';
import { DISABLE_UNABLE_TO_FULFILL } from '~/constants/features';
import { RIDE_BOOKING_STATUSES } from '~/constants/analytics';
import { bookingFlowStarted } from '~/Modules/analytics';
import { isEmpty } from '~/utilities/guards';
import useAnalytics from '~/hooks/useAnalytics';
import withRouter from '~/hooks/withRouter';

const BookRidesHOC = ComplianceHOC(BookRides);
const EditRideHOC = ComplianceHOC(EditRide);

const RELOADABLE_PAGES = [
  'scheduled',
  'all',
  'willcalls',
  'active',
  'ride-nemt-alerts',
  'ride-share-alerts'
];

class ScheduledRides extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);

    this.state = {
      isOpenCancelRide: false,
      transit: {
        selectedLeg: 0,
        legs: {}
      },
      showRideCards: true,
      showRideBookingForm: false,
      showRideDetails: false,
      activeRide: {},
      showMiddleColumn: false,
      showEditBookingForm: false,
      disableEdit: false,
      showErrorModal: false,
      showLoading: false,
      showLoadingText: COMPONENT_STRINGS[this.props.strKey].loadingModalText,
      showLoadingTimeout: 10,
      medicalId: null,
      hospitalId: null,
      healthSubPlanId: null,
      showReloadBanner: false,
      editRideIndex: -1,
      editRideId: 0,
      openRideNotes: false,
      showLyftWillCall: false,
      showNemtReassign: false,
      originalRideId: null,
      rideId: null,
      dateOfBirth: '',
      healthPlanId: null,
      displayTransportationOptions: false,
      closeErrorModalCallback: NOOP
    };

    this.start = 0;
    this.limit = RIDE_CARDS_LIMIT;
    this.query = '';
    this.isLoading = false;
    this.refRideColumn = {};
    this.offset = 0;

    this.getCanBookRide = this.getCanBookRide();
  }

  /**
   * initialize app with data, now we no longer have to squeeze in intial state
   * if someone clicks on tabs in scheduled ride header, componentDidMount runs again
   * in that case use the prop for rideCardParams
   * handles functionality for booking a ride outside of the ScheduledRides page
   * @return {undefined} returns nada
   */
  componentDidMount() {
    window.addEventListener('keydown', this.handleKeyDown.bind(this));

    this.displayRideCards();

    const {
      medicalId,
      hospitalId,
      originalRideId,
      healthPlanId,
      healthSubPlanId,
      dateOfBirth,
      isRedirect,
      personalInfo,
      bookingPhone,
      clientUniqueId
    } = this.props?.location?.state ?? {};

    if (medicalId) {
      this.props.history.replace({ state: {} });

      this.requestNewRide(
        medicalId,
        hospitalId,
        originalRideId,
        healthPlanId,
        dateOfBirth,
        isRedirect,
        healthSubPlanId,
        personalInfo,
        bookingPhone,
        clientUniqueId
      );
    }
  }

  /**
   * update and check previous props next to current props
   * update scheduled rides when page changes in App route
   * @param {object} prevProps - previous props
   * @return {undefined} - nothing returned
   */
  componentDidUpdate(prevProps) {
    const state = {};

    if (prevProps.page !== this.props.page) {
      this.props.resetScheduledRides();
      this.displayRideCards();
    }

    const timestamp = this.props?.scheduledRides?.cards?.timestamp ?? 0;
    const prevTimestamp = prevProps?.scheduledRides?.cards?.timestamp ?? 0;
    const timestampDidChange = timestamp !== prevTimestamp;

    if (timestampDidChange) {
      this.isLoading = false;

      if (!this.props.loadingRideCards) {
        this.toggleLoading(false);
      }
    }

    if (this.props?.scheduledRides?.cards?.reloadPage && !this.state.showReloadBanner) {
      state.showReloadBanner = true;
    }

    if (this.state.editRideIndex !== -1) {
      const index = this.props.scheduledRides.cards.rides.findIndex(
        ({ id }) => id === this.state.editRideId
      );

      const activeRide = this.props.scheduledRides.cards.rides[this.state.editRideIndex];
      const prevActiveRide =
        prevProps.scheduledRides.cards.rides[this.state.editRideIndex];

      if (activeRide && prevActiveRide && !_.isEqual(activeRide, prevActiveRide)) {
        // there are situations where rides get reordered.
        // when that happens and we can find the reordered activeRide in the list of rides, set the activeRide
        state.activeRide =
          index === -1 ? activeRide : this.props.scheduledRides.cards.rides[index];
      }
    }
    if (this.props.showUpdateDateError && !prevProps.showUpdateDateError) {
      const { updateDateErrorTitle, updateDateErrorButtonText } =
        COMPONENT_STRINGS[this.props.strKey];

      Object.assign(state, {
        showErrorModal: true,
        errorText: this.props.updateDateErrorText,
        errorTitle: updateDateErrorTitle,
        cancelButtonText: updateDateErrorButtonText,
        errorCallback: NOOP,
        errorShowSubmit: false,
        hasMarkup: true,
        closeErrorModalCallback: () => this.props.resetShowUpdateDateError()
      });
    }

    if (this.props.showWillCallReadyNowError && !prevProps.showWillCallReadyNowError) {
      // show will call ready now error modal if showWillCallReadyNowError flag is set true in redux state
      const {
        willCallReadyNowErrorText,
        willCallReadyNowErrorTitle,
        willCallReadyNowErrorButtonText
      } = COMPONENT_STRINGS[this.props.strKey];

      Object.assign(state, {
        showErrorModal: true,
        errorText: willCallReadyNowErrorText,
        errorTitle: willCallReadyNowErrorTitle,
        errorCallback: NOOP,
        errorShowSubmit: false,
        cancelButtonText: willCallReadyNowErrorButtonText,
        hasMarkup: true,
        closeErrorModalCallback: () => this.props.resetShowWillCallReadyNowError()
      });
    }

    if (
      this.props.bookingEventId !== prevProps.bookingEventId &&
      this.props.bookingStatus === 'Started'
    ) {
      this.trackRideBooking('Started');
    }

    if (Object.keys(state).length > 0) {
      this.setState(state);
    }
  }

  /**
   * reset cards data
   * @return {undefined} returns nada
   */
  componentWillUnmount() {
    this.props.resetScheduledRides();
    this.props.clearRide();
    this.props.clearNewMemberForm();

    window.removeEventListener('keydown', this.handleKeyDown.bind(this));
  }

  /**
   * Handles key down events
   * @param {Object} e SyntheticEvent
   * @returns {undefined}
   */
  handleKeyDown = e => e.keyCode === 27 && this.hideRideDetails();

  /**
   * handleLazyLoad - search handler
   * @return {undefined} - returns nothing
   */
  handleLazyLoad = () => {
    const scheduledRides = this.props.scheduledRides;

    if (!scheduledRides.hasMore || this.isLoading) {
      return;
    }

    const params = scheduledRides?.rideCardParams ?? {};

    params.lazyLoad = true;
    params.limit = this.limit;
    params.start = params.start + params.limit;

    this.isLoading = true;
    this.displayRideCards(params);
  };

  /**
   * handleSearch - search handler and calendar handler
   * @param {object} params - params for search
   * @return {undefined} - returns nothing
   */
  handleCalendarSearch = params => {
    this.props.resetScheduledRides();
    this.query = params.query;
    this.setState({ editRideIndex: -1 });
    this.displayRideCards({
      ...params,
      start: 0,
      limit: this.limit,
      searchByDateForce: true
    });
  };

  /**
   * trackRideBooking - analytics event tracker for ride booking
   * @param {*} bookingStatus - 'Started' | 'Abandoned' | 'Completed
   * @returns {void}
   */
  trackRideBooking = bookingStatus => {
    const { bookingStartTime, bookingEventId, analytics } = this.props;
    const bookingHasStarted = bookingStatus === 'Started';
    const seconds = Math.round(Date.now() / 1000);
    const bookingDuration = bookingHasStarted ? 0 : seconds - bookingStartTime;
    if (!RIDE_BOOKING_STATUSES.includes(bookingStatus)) return;

    analytics?.trackEvent('Ride Booking Flow', {
      eventTriggerTime: Date.now(),
      bookingEventId,
      bookingDuration,
      bookingStatus
    });
  };

  handleTransitSelect = (leg = 0, route) => {
    return () => {
      const legs = this.state.transit.legs;

      if (route !== undefined) {
        legs[leg] = route;
      }

      this.setState({ transit: { legs, selectedLeg: leg } });
    };
  };

  /**
   * starts ride booking process. Hide scheduled rides and header and replace with ride booking form
   * @param {string} medicalId - medicalId, only passed if rebook
   * @param {integer} hospitalId - hospitalId, only passed if rebook
   * @param {integer} originalRideId - rideId for the ride that was cancelled, in rebook scenario
   * @param {integer} healthPlanId - health plan ID, optional
   * @param {string} dateOfBirth - date of birth
   * @param isRedirect
   * @param healthSubPlanId
   * @param personalInfo
   * @param bookingPhone
   * @param clientUniqueId
   * @return {undefined} - return nothing
   */

  requestNewRide = (
    medicalId = '',
    hospitalId = 0,
    originalRideId = 0,
    healthPlanId = null,
    dateOfBirth = '',
    isRedirect = false,
    healthSubPlanId = null,
    personalInfo = {},
    bookingPhone,
    clientUniqueId,
    passengerId = null
  ) => {
    const {
      actionRequiredModalTitle,
      actionRequiredModalBody,
      actionRequiredModalButtonText
    } = COMPONENT_STRINGS[this.props.strKey];

    // If there are no availableVehicleTypes, it means that the
    // rateCard isn't set up and we stop the booking flow
    if (!this.getCanBookRide) {
      return this.setState({
        showErrorModal: true,
        errorText: actionRequiredModalBody,
        errorTitle: actionRequiredModalTitle,
        errorCallback: NOOP,
        errorShowSubmit: false,
        cancelButtonText: actionRequiredModalButtonText,
        hasMarkup: false,
        closeErrorModalCallback: NOOP
      });
    }

    if (passengerId && this.props.user.features['new_ride_booking_flow']) {
      return this.props.history.push(`/ride/new/${passengerId}`);
    }

    const state = {
      showRideBookingForm: true,
      showRideCards: false,
      showRideDetails: false
    };

    if (medicalId !== '') {
      state.showLoading = false;
      state.showLoadingText = undefined;
      state.showLoadingTimeout = 10;

      state.hospitalId = hospitalId ? hospitalId : null;
      state.originalRideId = originalRideId ? originalRideId : null;
      state.healthPlanId = healthPlanId ? healthPlanId : null;
      state.dateOfBirth = dateOfBirth ? dateOfBirth : '';
      state.medicalId = medicalId;

      if (healthSubPlanId) {
        state.healthSubPlanId = healthSubPlanId;
      }
    }
    state.personalInfo = personalInfo;
    state.isRedirect = isRedirect;
    state.bookingPhone = bookingPhone;
    state.clientUniqueId = clientUniqueId;

    this.props.bookingFlowStarted();
    this.setState(state);
  };

  /**
   * ends ride booking process. Hide scheduled rides and header and replace with ride booking form
   * @return {undefined} - return nothing
   */
  closeRequestNewRide = () => {
    const bookingStatus = this.props.scheduledRidesRedux.status
      ? 'Completed'
      : 'Abandoned';
    // track if a user abandoned or compeleted the ride booking flow
    this.trackRideBooking(bookingStatus);

    // this is empty when a ride is abandoned at any stage
    this.props.reset();
    this.props.clearRide();

    const state = {
      activeRide: {},
      showRideBookingForm: false,
      showRideCards: true,
      showRideDetails: false,
      showMiddleColumn: false,
      showEditBookingForm: false,
      editRideId: 0,
      editRideIndex: -1,
      medicalId: null,
      disableEdit: false,
      originalRideId: null
    };

    const rideIdUpdatedDate = this.props?.scheduledRides?.cards?.rideIdUpdatedDate ?? 0;

    if (rideIdUpdatedDate) {
      state.rideIdUpdatedDate = rideIdUpdatedDate;
    }

    this.setState(state, this.scrollToRide);
  };

  /**
   * function for scrolling ride column to edited ride
   * @return {undefined}
   */
  scrollToRide() {
    if (this.offset > 0) {
      this.refRideColumn.scrollTop = this.offset - this.refRideColumn.offsetTop;
      this.offset = 0;
    }
  }

  /**
   * during ride booking process show middle column
   * for authorization process
   * @return {undefined}
   */
  addColumn = () => this.setState({ showMiddleColumn: true });

  /**
   * during ride booking process hide middle column
   * when ride is edited during the confirmation process
   * @return {undefined}
   */
  hideMiddleColumn = () => this.setState({ showMiddleColumn: false });

  /**
   * shows edit ride component
   * @param {object} ride - ride
   * @param {int} offset - distance from ride card to top of the page
   * @return {undefined}
   */
  showEditRide = (ride, offset) => {
    this.offset = offset;
    this.props.getRide(ride.id);

    const user = this.props.user;
    const isRole = isNetworkRole(user.userData.role);

    if (
      isRole ||
      ['HospitalManager', 'HospitalAdmin', 'CaseManager'].includes(user.userData.role)
    ) {
      this.props.getVehicles(ride.hospitalId, ride.health_sub_plan_id);
    }

    const editRideIndex =
      this.props?.scheduledRides?.cards?.rides?.findIndex(({ id }) => id === ride.id) ??
      -1;

    this.setState({
      showEditBookingForm: true,
      showRideCards: false,
      showRideBookingForm: false,
      activeRide: ride,
      editRideIndex,
      editRideId: ride.id,
      disableEdit:
        ride.isPastRide && ride.reqVehicleType === LYFT_VEHICLE_ID ? true : false
    });
  };

  /**
   * retrieve offset from scheduled ride card
   * @param {int} offset - offset from top of scheduled ride div
   * @return {undefined}
   */
  setOffset = offset => {
    this.offset = offset;
  };

  /**
   * opens cancel ride modal
   * @param {object} ride - object with ride id and other properties
   * @return {undefined} - nothing
   */
  openCancelRide = ride => this.setState({ activeRide: ride, isOpenCancelRide: true });

  /**
   * opens cancel ride modal
   * @param {object} ride - object with ride id and other properties
   * @return {undefined} - nothing
   */
  openNemtReassign = ride => this.setState({ activeRide: ride, showNemtReassign: true });

  /**
   * close the cancel ride modal, passed into RideCard as prop
   * @return {undefined} - nothing
   */
  submitCancelRide = () => {
    this.props.resetCards();
    this.props.resetUpdatedCards();
    this.closeModal('isOpenCancelRide');
  };

  /**
   * close modal and clear out ride data
   * @param {string} modal - name of modal to turn off
   * @return {undefined} - nothing
   */
  closeModal = modal => this.setState({ [modal]: false, activeRide: {}, rideId: null });

  /**
   * shows ride details component for reordered ride
   * @param {integer} reorderedRideId - reordered ride id from nemt cancelled rides
   * @return {undefined}
   */
  showReorderedDetails = reorderedRideId =>
    this.setRideDetails({ rideId: reorderedRideId }, reorderedRideId);

  /**
   * @param {object} state - initial state required for showing ride details
   * @return {undefined}
   */
  setRideDetails = state => {
    this.setState(
      {
        ...state,
        showEditBookingForm: false,
        showRideCards: true,
        showRideBookingForm: false,
        disableEdit: true,
        showRideDetails: true
      },
      () => this.props.getRide(state.rideId)
    );
  };

  /**
   * Hide ride details modal
   * @returns {undefined}
   */
  hideRideDetails = () =>
    this.setState({ showRideDetails: false }, () => {
      this.props.clearRide();
    });

  /**
   * @param {boolean} toggle - turn loading module on or off
   * @param {string} text - loading modal text
   * @param {integer} timeout - timeout for closing modal
   * @return {undefined}
   */
  toggleLoading = (toggle, text, timeout = 10) =>
    this.setState({
      showLoading: toggle,
      showLoadingText: text,
      showLoadingTimeout: timeout
    });

  /**
   * switch will call to scheduled, bypass will call
   * @param {object} ride - ride object
   * @return {undefined}
   */
  requestPatientPickup = ride => {
    const reqVehicleType = ride?.reqVehicleType;
    if (reqVehicleType === LYFT_VEHICLE_ID || reqVehicleType === UBER_VEHICLE_ID) {
      this.setState({ showLyftWillCall: true, activeRide: ride });
    } else {
      this.props.requestForPatientPickup(ride);
    }
  };

  /**
   * rebooks cancelled ride
   * @param {string} medicalId - passenger id
   * @param {integer} hospitalId - hospital id
   * @return {undefined}
   */
  rebookCancelledRide = (medicalId, hospitalId) =>
    this.setState({ medicalId, hospitalId });

  /**
   * Get most recent chat request for a corresponding ride ID
   * @param  {Number} rideId Ride ID
   * @return {Object}        Chat request data
   */
  getChatRequestForRide = rideId => {
    const chatRequestIds = _.filter(Object.keys(this.props.chats), chatRequestId => {
      const rideDetails = this.props?.chats?.[chatRequestId]?.rideDetails ?? 0;
      return rideDetails.rideId === rideId || rideDetails.blegRideId === rideId;
    });

    // may be multiple so return the most recent one
    let mostRecentDate = 0;

    const chatRequest = chatRequestIds.reduce((recent, chatRequestId) => {
      const chatRequest = this.props?.chats?.[chatRequestId] ?? {};

      if (chatRequest.requestedAt > mostRecentDate) {
        mostRecentDate = chatRequest.requestedAt;
        return chatRequest;
      } else {
        return recent;
      }
    }, {});

    return Object.keys(chatRequest).length ? chatRequest : undefined;
  };

  /**
   * Get count of unread messages
   * @param  {String} [requestId=''] Chat request ID
   * @return {Number}                Count of unread messages
   */
  getUnreadMessagesCount = (requestId = '') => {
    if (requestId === '') return 0;

    const userId = this.props?.user?.userData?.id ?? 0;
    const messages = this.props?.chats?.[requestId]?.messages ?? [];

    return messages.reduce((count, message) => {
      const reader = _.find(message.readers, reader => reader.id === userId);
      // increment if not a listed reader or not the original sender

      return (!reader && message.senderId) !== userId ? count + 1 : count;
    }, 0);
  };

  /**
   * Select chat request for ride
   * @param  {String} [requestId=''] Chat request ID
   * @return {undefined}
   */
  selectChat = (requestId = '') => {
    if (requestId !== '') {
      // clear session storage value in case we select a chat that is already selected
      window.sessionStorage.setItem(
        `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`,
        ''
      );

      this.props.setSelectedRequest({ requestId });
    }
  };

  showLyftAlertSubtab = () => {
    const user = this.props.user;

    const rideshareCheck = ({ id }) => [LYFT_VEHICLE_ID, UBER_VEHICLE_ID].includes(id);

    if (['hospital', 'HospitalAdmin', 'caseManager'].includes(user?.nodeUserType)) {
      return !!user.availableVehicleTypes.find(rideshareCheck);
    }

    if (typeof user.availableVehicleTypes === 'object') {
      for (const val of Object.values(user.availableVehicleTypes)) {
        if (val.find(rideshareCheck)) return true;
      }
    }

    return false;
  };

  /**
   * reload scheduled rides
   * @return {undefined}
   */
  reloadRides = () => {
    const params = this.props?.scheduledRides?.rideCardParams ?? {};
    params.start = this.start;
    params.query = '';

    this.setState({ showReloadBanner: false });

    this.props.resetScheduledRides();
    this.displayRideCards(params);
  };

  /**
   * function passed down to components to open ride notes
   * @param {boolean} open - open or close
   * @return {undefined}
   */
  openRideNotes = (open = true) => this.setState({ openRideNotes: open });

  /**
   * Encapsulate the logic that tells whether the user can book a ride or not.
   * The logic is based on the availableVehicleTypes, assuming that if you have no availableVehicleTypes you can't book a ride.
   * For HospitalNetworkManager, as the availableVehicleTypes is a lookup map, you have to access the values of the map
   * @returns {boolean} returns if the user can book a ride
   */
  getCanBookRide = () => {
    const availableVehicleTypes = this.props.user.availableVehicleTypes;

    if (Array.isArray(availableVehicleTypes)) {
      return !!availableVehicleTypes.length;
    }

    if (
      typeof availableVehicleTypes === 'object' &&
      Object.keys(availableVehicleTypes).length > 0
    ) {
      for (const key in availableVehicleTypes) {
        // We check that at least one facility has availableVehicleTypes
        if (!isEmpty(availableVehicleTypes[key])) return true;
      }
    }

    return false;
  };

  /**
   * for componentDidMount and componentDidUpdate check scheduled ride params
   * @return {object} params
   */
  paramsCheck() {
    const params = _.clone(_.get(this.props, 'scheduledRides.rideCardParams', {}));

    if (isEmpty(params)) {
      params.limit = this.limit;
      params.lazyLoad = false;
    } else {
      _.unset(params, 'query');
    }

    params.page = this.props.page;
    params.start = this.start;
    params.limit ??= this.limit;

    const timezoneFormat = this.props?.user?.userData?.timezone_format;
    if (!('fromDate' in params)) {
      params.fromDate = moment
        .tz(DAY_START, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .add(calculateTzOffset(DAY_START, timezoneFormat))
        .startOf('day')
        .format(DATE_API_FORMAT);
    }

    if (!('toDate' in params)) {
      params.toDate = moment
        .tz(TOMORROW_END, Intl.DateTimeFormat().resolvedOptions().timeZone)
        .add(calculateTzOffset(TOMORROW_END, timezoneFormat))
        .endOf('day')
        .format(DATE_API_FORMAT);
    }

    return params;
  }

  /**
   * helper function for loading scheduled rides api and turning on toggle
   * @param {object} params - parameters for call scheduling rides endpoint
   * @return {undefined} returns nothing
   */
  displayRideCards(params = null) {
    if (!params) params = this.paramsCheck();

    this.props.fetchRideCards(params);
    this.toggleLoading(true, 'Loading Rides...', 10);
  }

  editPublicRouteOptions = () => {
    this.setState({
      displayTransportationOptions: !this.state.displayTransportationOptions
    });
  };

  render() {
    const {
      showLyftWillCall,
      showNemtReassign,
      isOpenCancelRide,
      showReloadBanner,
      showRideBookingForm,
      showRideCards,
      showRideDetails,
      showEditBookingForm,
      showMiddleColumn,
      showErrorModal,
      hasMarkup,
      errorText,
      errorTitle,
      errorSubmitText,
      errorShowSubmit,
      errorCallback,
      cancelButtonText,
      closeErrorModalCallback,
      dateOfBirth,
      disableEdit,
      displayTransportationOptions,
      medicalId,
      healthPlanId,
      healthSubPlanId,
      hospitalId,
      activeRide,
      rideId,
      rideIdUpdatedDate,
      isRedirect,
      openRideNotes,
      originalRideId,
      transit,
      showLoadingText,
      showLoadingTimeout,
      bookingPhone,
      clientUniqueId
    } = this.state;

    const {
      complianceInfo,
      directions,
      scheduledRides,
      page,
      rideDetails: _rideDetails,
      user,
      rideCardEditStatus
    } = this.props;

    const userData = user.userData;
    const rides = scheduledRides?.cards?.rides ?? [];
    const params = scheduledRides?.rideCardParams
      ? _.clone(scheduledRides.rideCardParams)
      : {};

    const utfToggle = !user.features[DISABLE_UNABLE_TO_FULFILL];
    const cancellationReasons = user.cancellationReasons;
    const rideDetails =
      showEditBookingForm && _rideDetails?.data?.ride ? _rideDetails.data.ride : {};
    const extraFields = showEditBookingForm ? showExtraDetails(rideDetails) : {};

    const totalRideShareAlerts = utfToggle
      ? (scheduledRides?.cards?.alertCounts?.alertLyftRides ?? 0)
      : null;
    const totalNemtAlerts = utfToggle
      ? (scheduledRides?.cards?.alertCounts?.alertNemtRides ?? 0)
      : null;
    const totalAlerts = utfToggle ? totalRideShareAlerts + totalNemtAlerts : null;

    const showAlerts = ALERT_PAGES.includes(page);
    const showLyftAlertSubtab = this.showLyftAlertSubtab();
    const showReload =
      showReloadBanner && RELOADABLE_PAGES.includes(page)
        ? 'showReload show'
        : 'showReload';
    const showOverflow = showReloadBanner
      ? 'showRideOverflow reload'
      : 'showRideOverflow';

    return (
      <div className="ScheduledRides">
        {showErrorModal && (
          <ErrorModal
            closeModal={() => {
              this.setState({ showErrorModal: false });
              closeErrorModalCallback();
            }}
            isOpen={showErrorModal}
            modalContent={errorText}
            title={errorTitle}
            hasMarkup={hasMarkup}
            submitText={errorSubmitText}
            showSubmit={errorShowSubmit}
            callback={errorCallback}
            cancelButtonText={cancelButtonText}
          />
        )}
        <PageFrame>
          <LeftColumn extend={showMiddleColumn === true}>
            {showRideCards && (
              <div className="showRidesCont">
                <ScheduledRidesHeader
                  params={params}
                  isAlertPage={showAlerts}
                  showLyftAlertSubtab={showLyftAlertSubtab}
                  handleCalendarSearch={this.handleCalendarSearch}
                  requestNewRide={this.requestNewRide}
                  totalRideShareAlerts={totalRideShareAlerts}
                  totalNemtAlerts={totalNemtAlerts}
                  totalAlerts={totalAlerts}
                  user={user}
                />

                <div className="showRideCards">
                  <div className={showReload}>
                    <a onClick={this.reloadRides}>
                      Your rides have been updated. Click to reload.
                    </a>
                  </div>
                  <div
                    className={showOverflow}
                    ref={rideColumn => (this.refRideColumn = rideColumn)}
                  >
                    <WindowInfiniteScroll
                      hasNextPage={scheduledRides.hasMore}
                      items={rides}
                      isNextPageLoading={false}
                      loadNextPage={this.handleLazyLoad}
                      user={user}
                      vehicleTypes={user.vehicleTypes}
                      editRideIndex={this.state.editRideIndex}
                      page={page}
                      medicalId={medicalId}
                      hospitalId={hospitalId}
                      rideIdUpdatedDate={rideIdUpdatedDate}
                      setRideDetails={this.setRideDetails}
                      showReorderedDetails={this.showReorderedDetails}
                      editRide={this.editRide}
                      showEditRide={this.showEditRide}
                      cancelRide={this.openCancelRide}
                      selectChat={this.selectChat}
                      requestPatientPickup={this.requestPatientPickup}
                      rebookRide={this.requestNewRide}
                      setOffset={this.setOffset}
                      openNemtReassign={this.openNemtReassign}
                      getChatRequestForRide={this.getChatRequestForRide}
                      getUnreadMessagesCount={this.getUnreadMessagesCount}
                    />
                  </div>
                </div>
                {showRideDetails ? (
                  <div className="rideDetailsContainer" onClick={this.hideRideDetails}>
                    <div
                      className="rideDetailsWrapper"
                      style={{ width: activeRide.mode === 'Public' ? '67%' : '80%' }}
                      onClick={e => e.stopPropagation()}
                    >
                      {_rideDetails?.status ? (
                        <RideDetails
                          rideData={_rideDetails.data}
                          vehicleTypes={user.vehicleTypes}
                          mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
                          downloadUrl={`//${process.env.REACT_APP_API}/ride/report/${rideId}?download=pdf`}
                          showColumnHeader={true}
                          handleClose={this.hideRideDetails}
                          directions={directions}
                          canRebook={true}
                          MapboxMap={MapboxMap}
                          svgs={svgs}
                          features={this.props.user.features}
                          rebookCallback={this.requestNewRide}
                        />
                      ) : (
                        <Loading type="spin" className="rideDetailsLoading" />
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            )}
            {showRideBookingForm ? (
              <BookRidesHOC
                isRedirect={isRedirect}
                bookingPhone={bookingPhone}
                clientUniqueId={clientUniqueId}
                closeRequestNewRide={this.closeRequestNewRide}
                showDirections={this.showDirections}
                updateMap={this.updateMap}
                addColumn={this.addColumn}
                editPublicRouteOptions={this.editPublicRouteOptions}
                toggleLoading={this.toggleLoading}
                bookWithMedicalId={medicalId}
                bookWithHospitalId={hospitalId}
                bookWithHealthPlanId={healthPlanId}
                bookWithHealthSubPlanId={healthSubPlanId}
                bookWithDateOfBirth={dateOfBirth}
                hideMiddleColumn={this.hideMiddleColumn}
                openRideNotes={openRideNotes}
                closeRideNotes={this.openRideNotes}
                originalRideId={originalRideId}
                editRide={false}
                complianceInfo={complianceInfo}
              />
            ) : null}

            {showEditBookingForm ? (
              <EditRideHOC
                closeRequestNewRide={this.closeRequestNewRide}
                showDirections={this.showDirections}
                updateMap={this.updateMap}
                ride={activeRide}
                page={page}
                start={this.start}
                limit={this.limit}
                addColumn={this.addColumn}
                disableEdit={disableEdit}
                isModal={false}
                rideDetails={rideDetails}
                extraFields={extraFields}
                showCost={userData.showCost}
                toggleLoading={this.toggleLoading}
                editRide={true}
                complianceInfo={complianceInfo}
              />
            ) : null}

            {showMiddleColumn ? (
              <BookingConfirmation
                displayTransportationOptions={displayTransportationOptions}
                closeRequestNewRide={this.closeRequestNewRide}
                toggleLoading={this.toggleLoading}
                closeAndShowMap={() => this.closeRequestNewRide(true)}
                page={page}
                transit={transit}
                handleTransitSelect={this.handleTransitSelect}
                query={this.query}
                reloadRides={this.reloadRides}
                changeTransport={this.openRideNotes}
                user={user}
              />
            ) : null}
          </LeftColumn>
          <SavedAddressDrawer />
          <div className="careAppRight">
            <div className="mainContentMap">
              <ActiveMap transit={transit} activeRide={activeRide} />
            </div>
          </div>
        </PageFrame>

        {isOpenCancelRide ? (
          <CancelRide
            isOpen={isOpenCancelRide}
            cancellationReasons={cancellationReasons}
            page={this.props?.scheduledRides?.rideCardParams?.page ?? 'scheduled'}
            start={this.start}
            limit={this.limit}
            rideData={activeRide}
            handleSubmit={this.submitCancelRide}
            closeModal={() => this.closeModal('isOpenCancelRide')}
            toggleLoading={this.toggleLoading}
          />
        ) : null}

        {showLyftWillCall ? (
          <LyftOnDemand
            isOpen={showLyftWillCall}
            rideData={activeRide}
            handleSubmit={this.submitLyftWillCall}
            closeModal={() => this.closeModal('showLyftWillCall')}
            toggleLoading={this.toggleLoading}
            user={user}
          />
        ) : null}

        {showNemtReassign && !isEmpty(activeRide) ? (
          <ReassignNemt
            isOpen={showNemtReassign}
            rideData={activeRide}
            handleSubmit={this.submitReassignNemt}
            closeModal={() => this.closeModal('showNemtReassign')}
            toggleLoading={this.toggleLoading}
            user={user}
            assignmentSuccess={rideCardEditStatus}
          />
        ) : null}

        {this.state.showLoading ? (
          <LoadingModal
            isOpen={this.props.loadingRideCards || this.state.showLoading}
            label={showLoadingText}
            closeTimeout={showLoadingTimeout * 1000}
          />
        ) : null}
      </div>
    );
  }
}

const LeftColumn = ({ children, extend }) => {
  const [drawerState] = useSavedAddressDrawer();

  const className = extend ? 'careAppLeft extend' : 'careAppLeft';
  const disabled = drawerState ? 'disabled' : '';

  return <div className={`${className} ${disabled}`}>{children}</div>;
};

const mapStateToProps = state => ({
  scheduledRides: state.rideCards.scheduledRides,
  loadingRideCards: state.rideCards.loadingRideCards,
  bookingData: state.bookingData,
  chats: state.chats,
  bulkEdit: state.bulkEdit,
  rideDetails: state.rideDetails.ride,
  directions: state.directions,
  complianceInfo: state.user.complianceInfo,
  scheduledRidesRedux: state?.scheduleRides ?? {},
  rideCardEditStatus: state?.rideCards?.updateRideData?.status ?? false,
  bookingStartTime: state.analytics.bookingStartTime,
  bookingEventId: state.analytics.bookingEventId,
  showWillCallReadyNowError: state?.rideCards?.showWillCallReadyNowError ?? false,
  showUpdateDateError: state?.rideCards?.showUpdateDateError ?? false,
  updateDateErrorText: state?.rideCards?.error?.errorMessage ?? 'Unknown Error'
});

// pusher functions for newRide, rideInfoUpdate, and rideStatusUpdate to be added
const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      resetScheduledRides: () => resetScheduledRides(),
      resetUpdatedCards: () => resetUpdatedCards(),
      resetShowWillCallReadyNowError: () => resetShowWillCallReadyNowError(),
      resetShowUpdateDateError: () => resetShowUpdateDateError(),
      fetchRideCards: params => fetchRideCards(params),
      reset: () => reset(),
      setSelectedRequest: data => selectRequest(data),
      requestForPatientPickup: data => requestPickup(data),
      getRide: rideId => getRide(rideId),
      clearRide: () => clearRide(),
      resetCards: () => resetCards(),
      getVehicles: (hospitalId, subPlanId) => getVehicles(hospitalId, subPlanId),
      clearNewMemberForm: () => clearNewMemberForm(),
      bookingFlowStarted: () => bookingFlowStarted()
    },
    dispatch
  );

ScheduledRides.defaultProps = {
  strKey: 'ScheduledRides',
  errorMessage: '',
  page: 'scheduled',
  cards: { rides: [] },
  cardsParams: {},
  user: {},
  userData: {},
  pageNumber: 0,
  rideCardsSearch: NOOP,
  fetchRideCards: NOOP,
  resetUpdatedCards: NOOP,
  scheduledRides: {},
  reset: NOOP,
  loadingRideCards: false,
  showLoadingBooking: false,
  chats: {},
  bookingData: {},
  setSelectedRequest: NOOP,
  requestForPatientPickup: NOOP,
  resetScheduledRides: NOOP,
  bulkEdit: {},
  vehicleTypes: {},
  getRide: NOOP,
  rideDetails: {},
  clearRide: NOOP,
  resetCards: NOOP,
  directions: {},
  getVehicles: NOOP,
  scheduledRidesRedux: [],
  rideCardEditStatus: false,
  showWillCallReadyNowError: false,
  showUpdateDateError: false,
  analytics: {},
  location: {},
  history: {}
};

ScheduledRides.propTypes = {
  strKey: PropTypes.string,
  errorMessage: PropTypes.string,
  page: PropTypes.string,
  fetchRideCards: PropTypes.func,
  resetScheduledRides: PropTypes.func,
  cardsParams: PropTypes.object,
  cards: PropTypes.object,
  user: PropTypes.object,
  rideCardsSearch: PropTypes.func,
  scheduledRides: PropTypes.object,
  resetUpdatedCards: PropTypes.func,
  reset: PropTypes.func,
  loadingRideCards: PropTypes.bool,
  showLoadingBooking: PropTypes.bool,
  chats: PropTypes.object,
  setSelectedRequest: PropTypes.func,
  requestForPatientPickup: PropTypes.func,
  bulkEdit: PropTypes.object,
  getRide: PropTypes.func,
  clearRide: PropTypes.func,
  resetCards: PropTypes.func,
  directions: PropTypes.object,
  bookingData: PropTypes.object,
  scheduledRidesRedux: PropTypes.object,
  rideCardEditStatus: PropTypes.bool,
  getVehicles: PropTypes.func,
  showWillCallReadyNowError: PropTypes.bool,
  showUpdateDateError: PropTypes.bool,
  analytics: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

export default useAnalytics.HOC(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduledRides))
);
