import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import GoogleMap from '../GoogleMap';
import SvgDriverX from 'Common/Components/Svgs/SvgDriverX';
import moment from 'moment-timezone';

// constants for route data
const INNER_LAYER = {
  color: '#03b3fd',
  width: 5,
  opacity: 1,
  id: 'bookingRoute'
};

const OUTER_LAYER = {
  color: '#0084bb',
  width: 7,
  opacity: 1,
  id: 'bookingRouteOuter'
};

const ROUTES = {
  bookingRoute: {
    innerLayer: _.clone(INNER_LAYER),
    outerLayer: _.clone(OUTER_LAYER)
  },
  startedRoute: {
    innerLayer: _.clone(INNER_LAYER),
    outerLayer: _.clone(OUTER_LAYER)
  },
  inboundRoute: {
    innerLayer: {
      color: '#222',
      width: 6,
      opacity: 0.8,
      id: 'inboundRoute'
    },
    outerLayer: {}
  }
};

class RideRoute extends Component {
  constructor(props) {
    super(props);
    this.map = null;
  }

  componentDidUpdate(prevProps) {
    const { locationData } = this.props;
    if (
      !_.isEqual(prevProps.rideData.fromLongitude, this.props.rideData.fromLongitude) &&
      this.map
    ) {
      this.getPolyline().then(polyline => {
        this.drawRoute(polyline);
      });
    }

    if (!_.isEqual(prevProps.locationData, locationData) && this.map) {
      this.createLocationDataRoute();
    }
  }

  mapboxLoaded() {
    this.getPolyline().then(polyline => {
      this.drawRoute(polyline);
      this.createLocationDataRoute();
      this.map.fitBoundsAll();
    });
    const { rideData } = this.props;
    const { locations } = rideData;
    if (locations) {
      const routeLocationList = locations.Started;
      if (routeLocationList && routeLocationList.length) {
        routeLocationList.map((r, i) => {
          this.map.markerRemove(`driverRouteMarker-${i}Marker`);
          const timestamp = this.getLocationTimestamp(r);
          const driverRouteMarker = {
            id: `driverRouteMarker-${i}Marker`,
            svg: <SvgDriverX />,
            point: {
              lng: parseFloat(r.longitude, 10),
              lat: parseFloat(r.latitude, 10)
            },
            popup: {
              html: `<b>Timestamp: </b>${timestamp}<br/><b>Lat: </b>${r.latitude}<br/><b>Lng: </b>${r.longitude}`,
              toggleType: 'hover',
              maxWidth: 'none'
            }
          };
          this.map.markerAdd(driverRouteMarker);
          this[`driverRouteMarker-${i}Marker`] = driverRouteMarker;
        });
      }
    }
  }

  getLocationTimestamp(locationObj) {
    const { generatedAt } = locationObj;
    const timezone = this.props.rideData.passenger.timezone_format;
    const timestamp =
      generatedAt !== null
        ? typeof generatedAt === 'object'
          ? +generatedAt.$date.$numberLong
          : new Date(generatedAt).toString()
        : undefined;
    const momentDate = moment.tz(timestamp, timezone).format('MM/DD/YYYY hh:mm A');
    const dateString = `${momentDate.toString()} ${moment.tz(timezone).zoneAbbr()}`;
    return dateString;
  }

  getCoords(coords) {
    return coords.map(coord => {
      return [coord.longitude, coord.latitude];
    });
  }

  createLocationDataRoute() {
    const { locationData } = this.props;
    let innerParams,
      outerParams = {};
    if (!_.isNil(locationData.rideInbound) && !_.isEmpty(locationData.rideInbound)) {
      innerParams = this.generatePolyLineParams(
        ROUTES.inboundRoute.innerLayer,
        this.getCoords(locationData.rideInbound)
      );
      this.map.polylineAdd(innerParams);
    }
    if (!_.isNil(locationData.rideStarted) && !_.isEmpty(locationData.rideStarted)) {
      innerParams = this.generatePolyLineParams(
        ROUTES.startedRoute.innerLayer,
        this.getCoords(locationData.rideStarted)
      );
      outerParams = this.generatePolyLineParams(
        ROUTES.startedRoute.outerLayer,
        this.getCoords(locationData.rideStarted)
      );
      this.map.polylineAdd(innerParams);
      this.map.polylineAdd(outerParams);
    }
    if (!_.isEmpty(innerParams) || !_.isEmpty(outerParams)) {
      this.generateRoute();
      this.map.fitBoundsAll();
    }
  }

  getPolyline() {
    const routeObj = {
      origin: [this.props.rideData.fromLongitude, this.props.rideData.fromLatitude],
      destination: [this.props.rideData.toLongitude, this.props.rideData.toLatitude]
    };
    return this.map.polylineLookup(routeObj);
  }

  /**
   * Dray route based on coordinates
   * @param {array} coords - array of coordinates for drawing route
   * @param {string} rideStatus - incoming or started, poly line is different colors, blue for incoming, green for started
   * @return {undefined}
   */
  drawRouteCoords(coords, rideStatus) {
    const coordinates = coords.map(coord => {
      return [coord.longitude, coord.latitude];
    });

    this.map.polylineAdd({
      id: rideStatus,
      points: coordinates
    });

    this.generateRoute();
    this.map.fitBoundsAll();
  }

  /**
   * Draw ride route based on polyline
   * @param {string} polyline - polyline string
   * @returns {undefined}
   */
  drawRoute(polyline) {
    this.map.polylineAdd({
      id: 'route',
      encoded: polyline
    });
    this.generateRoute();
  }

  /**
   * function for generating route
   * @return {undefined}
   */
  generateRoute() {
    const { rideData } = this.props;
    const fromAddress = this.parseAddress(rideData.fromAddress);
    const toAddress = this.parseAddress(rideData.toAddress);
    this.map.markerAdd({
      id: 'markerPickup',
      svg: this.props.svgs.SvgMapPinBlue({ title: 'Pickup', className: 'marker' }),
      point: {
        lng: parseFloat(rideData.fromLongitude),
        lat: parseFloat(rideData.fromLatitude)
      },
      popup: {
        html: `Pickup: #${rideData.id} <address>${fromAddress.street}<br>${fromAddress.city}, ${fromAddress.state} ${fromAddress.zipCode}</address>`,
        toggleType: 'hover',
        maxWidth: 'none'
      }
    });
    this.map.markerAdd({
      id: 'markerDropoff',
      svg: this.props.svgs.SvgMapPinRed({ title: 'Dropoff', className: 'marker' }),
      point: {
        lng: parseFloat(rideData.toLongitude),
        lat: parseFloat(rideData.toLatitude)
      },
      popup: {
        html: `Dropoff: #${rideData.id} <address>${toAddress.street}<br>${toAddress.city}, ${toAddress.state} ${toAddress.zipCode}</address>`,
        toggleType: 'hover',
        maxWidth: 'none'
      }
    });
  }

  /**
   * Parse address details from address string
   * @param {String} address Address string
   * @returns {Object} Parsed address object
   */
  parseAddress(address) {
    return address.split(',').reduce((addressObj, item, idx) => {
      switch (idx) {
        case 0: {
          return { ...addressObj, street: item.trim() };
        }
        case 1: {
          return { ...addressObj, city: item.trim() };
        }
        case 2: {
          const stateZip = item.trim().split(' ');
          return { ...addressObj, state: stateZip[0], zipCode: stateZip[1] };
        }
        case 3: {
          return { ...addressObj, country: item.trim() };
        }
        default: {
          return addressObj;
        }
      }
    }, {});
  }

  /**
   * generate paramaters for polyline
   * @param {object} layerParams - routeId for the layer
   * @param {array} coordinates - coordinates
   * @param {string} polylineEncode - polylineData
   * @return {object} layer - returns a layer object
   */
  generatePolyLineParams(layerParams, coordinates = [], polylineEncode = '') {
    const geoJsonData = {
      id: layerParams.id,
      paint: {
        'line-color': layerParams.color,
        'line-width': layerParams.width,
        'line-opacity': layerParams.opacity,
        'line-dasharray': [1, 3]
      }
    };

    if (coordinates.length > 0) {
      geoJsonData.points = coordinates;
    }

    if (polylineEncode !== '') {
      geoJsonData.encoded = polylineEncode;
    }

    return geoJsonData;
  }

  render() {
    const { mapboxAccessToken, MapboxMap, rideData } = this.props;
    if (rideData.mode === 'Public') {
      return <GoogleMap confirmationCardData={rideData.publicData} rideData={rideData} />;
    }
    return (
      <div className="RideRoute">
        <MapboxMap
          accessToken={mapboxAccessToken}
          ref={map => (this.map = map)}
          controls={['navigation', 'bounds']}
          className={'mapboxMap'}
          onLoad={() => this.mapboxLoaded()}
          {...this.props}
        />
      </div>
    );
  }
}

RideRoute.propTypes = {
  rideData: PropTypes.object,
  mapboxAccessToken: PropTypes.string,
  svgs: PropTypes.object,
  MapboxMap: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  locationData: PropTypes.object
};

RideRoute.defaultProps = {
  rideData: {},
  mapboxAccessToken: '',
  svgs: {},
  MapboxMap: {},
  locationData: {}
};

export default RideRoute;
