import { RIDE_SECTION_ORDER } from './RideBooking.constants';
import type { RideBookingSection, RideBookingStore } from './RideBooking.types';

/**
 * Determine if the date selected is in the past.
 * @param date
 * @returns
 */
export function isPastBooking(date: RideBookingStore['date']['date']): boolean {
  if (!date) return false;

  const dateArr = date.split('-');

  if (Number(date[0]) > new Date().getFullYear()) return false;

  if (Number(dateArr[1]) > new Date().getMonth()) return false;

  return (
    Number(date[0]) <= new Date().getFullYear() &&
    Number(dateArr[1]) <= new Date().getMonth() + 1 &&
    Number(dateArr[2]) < new Date().getDate()
  );
}

/** Move on to the next section in the RBF, relative to current active section. */
export function toNextSection(meta: RideBookingStore['meta']) {
  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = meta.activeSection + 1;
}

/** Change the RBF to a specific section. */
export function toSection(meta: RideBookingStore['meta'], section: RideBookingSection) {
  meta.previousActiveSection = meta.activeSection;
  meta.activeSection = RIDE_SECTION_ORDER[section];
}

/** Utility to attempt to scroll a section of the RBF into view. */
export function scrollToSection(section: RideBookingSection) {
  return document.querySelector(`[data-testid="section-${section}"]`)?.scrollIntoView();
}

/** Default behavior to execute whenever completing a section of the RBF. */
export function completeSection(section: RideBookingSection) {
  return (state: RideBookingStore) => {
    toNextSection(state.meta);
    scrollToSection(section);
  };
}
