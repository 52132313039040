import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';

export type AddressVenue = {
  type: 'venue';
  value: string;
  id: number;
  venue: string;
  entrance: string;
  latitude: number;
  longitude: number;
};

export type AddressVenueModel = ModelType<AddressVenue>;

export type AddressVenuePropertyRule = PropertyRuleSignature<AddressVenue>;

export const ADDRESS_VENUE_DEFAULT = (): AddressVenue => ({
  type: 'venue',
  value: '',
  id: 0,
  venue: '',
  entrance: '',
  latitude: 0,
  longitude: 0
});

export const addressVenueFactory = createModelFactory<AddressVenueModel>(
  ADDRESS_VENUE_DEFAULT(),
  {
    properties: [
      ['is-address-type', 'type', v => v === 'venue'],
      ['is-string', 'value', Is.String.strict],
      [
        'is-not-empty',
        'value',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Address cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'id', Is.Number.strict],
      [
        'is-not-empty',
        'id',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Id cannot be empty.');

          return true;
        }
      ],
      ['is-string', 'venue', Is.String.strict],
      [
        'is-not-empty',
        'venue',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Venue cannot be empty.');

          return true;
        }
      ],
      ['is-string', 'entrance', Is.String.strict],
      [
        'is-not-empty',
        'entrance',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Entrance cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'latitude', Is.Number.strict],
      [
        'is-not-empty',
        'latitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Latitude cannot be empty.');

          return true;
        }
      ],
      ['is-number', 'longitude', Is.Number.strict],
      [
        'is-not-empty',
        'longitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Longitude cannot be empty.');

          return true;
        }
      ]
    ],
    model: []
  }
);
