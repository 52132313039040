/**
 * @module services/phone
 * Service for phone number related requests.
 * @preferred
 * @example import { getOptOutStatus } from '~/services/phone.service';
 *
 */
import isEmpty from 'lodash/isEmpty';
import axios from '~/Modules/safeAxios';
import { ANALYTICS_API } from '~/constants';
import { baseRequestConfig } from '~/utilities/auth.helper';

/** Types */
export type OptOutStatus = {
  phone_number: string;
  opt_out: boolean;
  opt_out_prompt_timestamp: string;
};

type OptOutStatusPayload = {
  phone_number: string;
  opt_out: boolean;
  opt_out_prompt_timestamp: string;
  memberId: number;
  passenger_phone_number_id: number;
  member_secondary_phone_opt_out?: number;
};

type UpdateMemberPhoneNumberPayload = {
  memberId: number;
  phone: string;
  isPrimary: 0 | 1;
  opt_out_prompt_timestamp?: string | undefined;
  opt_out: 0 | 1;
};

/** Functions */

/**
 * @function updateMemberPhoneNumber
 * @description Update the phone number of a member.
 * @param {UpdateMemberPhoneNumberPayload} payload - The phone number payload.
 * @returns {Promise<MemberPhoneNumber>} Member Phone Number.
 *
 */
export const updateMemberPhoneNumber = async (
  payload: UpdateMemberPhoneNumberPayload
) => {
  const { memberId, phone: phone_number, isPrimary: is_primary } = payload;
  return axios
    .put(
      `${ANALYTICS_API}/api/v1/members/${memberId}/phone-numbers`,
      {
        phone_number,
        is_primary,
        opt_out: payload.opt_out,
        ...(payload?.opt_out_prompt_timestamp && {
          opt_out_prompt_timestamp: payload.opt_out_prompt_timestamp
        })
      },
      baseRequestConfig()
    )
    .then(res => res.data);
};

/**
 * @function updateOptOutStatus
 * @description Update the opt out status of a phone number.
 * @param {OptOutStatusPayload} payload - The opt out status payload.
 * @returns {Promise<OptOutStatus>} The opt out status of the phone number.
 */
export const updateOptOutStatus = async ({
  phone_number,
  opt_out,
  opt_out_prompt_timestamp,
  memberId,
  passenger_phone_number_id
}: OptOutStatusPayload) => {
  return axios
    .put(
      `${ANALYTICS_API}/api/v1/members/update/${memberId}`,
      {
        member_phone_numbers: [
          {
            id: passenger_phone_number_id,
            phone_number,
            opt_out,
            is_primary: 0,
            opt_out_prompt_timestamp
          }
        ],
        from_rbf: 1,
        opt_out_prompt_timestamp,
        passenger_phone_number_id,
        prompted_sms_opt_in: 1,
        opt_out
      },
      baseRequestConfig()
    )
    .then(res => res.data)
    .catch(e => e);
};

export const handleOptIn = async ({
  phone,
  memberId
}: {
  phone: string;
  memberId: number;
}) => {
  try {
    const response = await updateMemberPhoneNumber({
      phone,
      memberId,
      isPrimary: 0,
      opt_out: 0
    });
    if (!response.data) throw new Error('handleOptIn failed');
    return response.data?.find(
      phone => phone?.phone_type === 'mobile' && isEmpty(phone.opt_out_prompt_timestamp)
    );
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    return false;
  }
};
