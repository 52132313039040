import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { RIDE_SECTION_ORDER } from '../RideBooking.constants';

const selectActiveSection = (state: AppStore) => state.rideBooking.meta.activeSection;
const selectSectionKey = (
  _state: AppStore,
  sectionKey: keyof typeof RIDE_SECTION_ORDER
) => sectionKey;

/** Select if a given section of the RBF is active or not. */
export const selectIsActive = createSelector(
  [selectActiveSection, selectSectionKey],
  (activeSection, sectionKey) => activeSection === RIDE_SECTION_ORDER[sectionKey]
);
