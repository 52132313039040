import type { RideCardsStore } from './rideCards.types';

export const INITIAL_STATE: RideCardsStore = {
  loadingRideCards: false,
  loadingEditRide: false,
  scheduledRides: {
    hasMore: false,
    cards: {
      rideIdUpdatedDate: 0,
      showLyft: false,
      showHNCost: false,
      reloadPage: false,
      rides: [],
      vehicleList: [],
      driversList: [],
      prevRidesList: '',
      timestamp: 0,
      alertCounts: {
        alertNemtRides: 0,
        alertLyftRides: 0
      }
    },
    rideCardParams: {
      query: ''
    }
  },
  showWillCallReadyNowError: false,
  showUpdateDateError: false,
  updateRideCheckData: {},
  updateRideCheckDataParams: {},
  updateDateCheckData: {},
  cancelRideCostData: {},
  error: {},
  updateRideData: {},
  updateDateData: {},
  timestampMicro: 0
};

export const scheduledRidesPages = [
  'scheduled',
  'willcalls',
  'cancelled',
  'active',
  'completed',
  'all',
  'ride-share-alerts',
  'ride-nemt-alerts'
] as const;
