import { useState } from 'react';
import { fetchAutoComplete } from '~/services/googleMaps.service';
import type { DropdownOption } from '~/types';

export const useGoogleMapSearch = () => {
  const [autocompleteAddressOptions, setAutocompleteAddressOptions] = useState<
    DropdownOption[]
  >([]);
  const [isSearching, setIsSearching] = useState(false);

  const handleFetchAutoComplete = async searchQuery => {
    if (!searchQuery) return;
    setIsSearching(true);
    const response = await fetchAutoComplete(searchQuery);
    const formattedOptions = response?.predictions?.map(place => ({
      label: place.description,
      value: place.place_id
    }));

    setAutocompleteAddressOptions(formattedOptions ?? []);
    setIsSearching(false);
  };

  return {
    isSearching,
    autocompleteAddressOptions,
    handleFetchAutoComplete
  };
};
