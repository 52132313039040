/** For some reason our memberProfile slice was split into two separate
 * structures. One usings a simple redux slice and another using sagas. This
 * is the simpler redux slice copied into the same directory as the saga. */
import _ from 'lodash-es';
import { put, takeEvery } from 'redux-saga/effects';
import { isEmpty } from '~/utilities/helperFunctions';
import { MEMBERS_SET } from '~/Modules/members';
import axios from '~/Modules/safeAxios';
import { extendedRequestConfig } from '~/utilities/auth.helper';
import { ActionTypes, INITIAL_STATE } from './constants';
export * from './selectors';

const {
  CLEAR_ALL_DATA,
  CLEAR_FORM_DATA,
  LOAD_MEMBER_FORM,
  CLEAR_MEMBER_DATA,
  UPDATE_MEMBER_FORM,
  LOAD_MEMBER_FORM_ERROR,
  LOAD_MEMBER_FORM_SUCCESS,
  UPDATE_MEMBER_FORM_ERROR,
  UPDATE_MEMBER_FORM_SUCCESS
} = ActionTypes;

/**
 * removes success and error states from redux
 * @return {dispatch} - returns dispatch
 */
export const clearData = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_FORM_DATA
    });
  };
};

/**
 * clear all
 * @return {dispatch} - returns dispatch
 */
export const clearAll = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_ALL_DATA
    });
  };
};

/**
 * removes all member data
 * @return {dispatch} - returns dispatch
 */
export const clearMember = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_MEMBER_DATA
    });
  };
};

/**
 * dispatch for retrieving profile information
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const getMemberProfile = params => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    return dispatch({
      type: LOAD_MEMBER_FORM,
      data: params,
      user
    });
  };
};

/**
 * dispatch for saving profile information
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const putMemberProfile = params => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    return dispatch({
      type: UPDATE_MEMBER_FORM,
      data: params,
      user
    });
  };
};

/**
 * generator for getting member profile
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* getMemberProfileFromApi(action = {}) {
  const passengerId = action.data.passengerId;

  try {
    const results = yield axios({
      ...extendedRequestConfig(),
      method: 'GET',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/view/${passengerId}`
    });
    const responseData = results?.data?.data ?? [];
    const timestamp = results?.data?.timestamp ?? {};

    if (results?.data?.status === true) {
      yield put({
        type: LOAD_MEMBER_FORM_SUCCESS,
        data: responseData,
        timestamp,
        passengerId,
        user: action.user
      });
    } else {
      yield put({
        type: LOAD_MEMBER_FORM_ERROR,
        data: { errors: [results?.data?.message] }
      });
    }
  } catch (error) {
    yield put({
      type: LOAD_MEMBER_FORM_ERROR,
      data: {
        errors: isEmpty(error?.response?.data?.errors ?? [])
          ? ['Error getting member profile.']
          : error.response.data.errors
      }
    });
  }
}

/**
 * generator for getting editing member profile
 * @param {object} action - action data
 * @return {undefined} doesn't return anythint but yield
 */
function* putMemberProfileToApi(action = {}) {
  const params = action.data;
  const { passengerId, memberProfile, formType } = params;
  try {
    const results = yield axios({
      ...extendedRequestConfig(),
      method: 'PUT',
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/update/${passengerId}`,
      data: memberProfile
    });

    if (_.get(results, 'data.status', false) === true) {
      yield put({
        type: UPDATE_MEMBER_FORM_SUCCESS,
        data: {
          passengerId,
          memberProfile,
          formType
        }
      });
      //Update search listings
      yield put({
        type: MEMBERS_SET,
        data: {
          id: passengerId,
          ...memberProfile
        }
      });

      yield* getMemberProfileFromApi({
        data: { passengerId },
        user: action.user
      });
    } else {
      const errors = [_.get(results, 'data.message')];
      yield put({
        type: UPDATE_MEMBER_FORM_ERROR,
        data: { errors },
        formType
      });
    }
  } catch (error) {
    let errors;
    if (_.isEmpty(_.get(error, 'response.data.errors'), [])) {
      errors = ['Error editing member profile.'];
    } else {
      errors = _.get(error, 'response.data.errors');
    }
    if (error.message.indexOf('403') > -1) {
      //Access restriction error
      errors = ['You no longer have edit/view rights.'];
    }

    yield put({
      type: UPDATE_MEMBER_FORM_ERROR,
      data: {
        errors,
        formType
      }
    });
  }
}

/**
 * Saga function wrapper
 * @returns {undefined}
 */
export function* memberProfileSaga() {
  yield takeEvery(LOAD_MEMBER_FORM, getMemberProfileFromApi);
  yield takeEvery(UPDATE_MEMBER_FORM, putMemberProfileToApi);
}

/**
 * memberProfileReducer
 * @param {*} state previous or initial state
 * @param {*} action action dispatched
 * @returns {*} newState
 */

export const memberProfileReducer = (state = INITIAL_STATE, action = {}) => {
  const newState = _.cloneDeep(state);

  switch (action.type) {
    case CLEAR_FORM_DATA: {
      _.unset(newState, 'error');
      _.unset(newState, 'success');
      return newState;
    }
    case CLEAR_MEMBER_DATA: {
      _.unset(newState, 'formData');
      return newState;
    }
    case CLEAR_ALL_DATA: {
      return {};
    }
    case LOAD_MEMBER_FORM: {
      newState.inHttpRequest = true;
      return newState;
    }

    case LOAD_MEMBER_FORM_ERROR: {
      newState.inHttpRequest = false;
      return newState;
    }
    case UPDATE_MEMBER_FORM: {
      newState.inHttpRequest = true;
      return newState;
    }
    case UPDATE_MEMBER_FORM_SUCCESS: {
      newState.inHttpRequest = false;
      _.unset(newState, 'error');
      newState.success = {
        formType: action.data.formType,
        message: 'You have successfully updated the profile!'
      };
      return newState;
    }
    case UPDATE_MEMBER_FORM_ERROR: {
      newState.inHttpRequest = false;
      newState.error = action.data.errors;
      _.unset(newState, 'success');
      return newState;
    }
    default: {
      return newState;
    }
  }
};
