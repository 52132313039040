import { ActionTypes } from './constants';
import { createActions } from 'redux-actions';

export const {
  setStatus,
  getReportById,
  removeExpense,
  setExpenseModal,
  setExpenseField,
  uploadAttachment,
  setExpenseOptions,
  setExpenseSlideout,
  downloadAttachment,
  resetExpenseReport,
  createExpenseReport,
  updateExpenseReport,
  checkDuplicateExpenses,
  getReportActivityLog,
  setReportPersonalInfo,
  setCurrentAttachmentUploadState,
  removeRepeatingExpenses,
  setRideIdOnExpenseReport

} = createActions({
  [ActionTypes.SET_STATUS]: status => status,
  [ActionTypes.REMOVE_EXPENSE]: index => index,
  [ActionTypes.GET_REPORT_BY_ID]: id => id,
  [ActionTypes.SET_EXPENSE_MODAL]: modal => modal,
  [ActionTypes.SET_EXPENSE_FIELD]: field => field,
  [ActionTypes.UPLOAD_ATTACHMENT]: formData => formData,
  [ActionTypes.SET_EXPENSE_OPTIONS]: options => options,
  [ActionTypes.DOWNLOAD_ATTACHMENT]: id => id,
  [ActionTypes.SET_EXPENSE_SLIDEOUT]: toggle => toggle,
  [ActionTypes.RESET_EXPENSE_REPORT]: () => {},
  [ActionTypes.GET_REPORT_ACTIVITY_LOG]: id => id,
  [ActionTypes.UPDATE_EXPENSE_REPORT]: ({ report, id, hospitalGroupId }) => ({ report, id, hospitalGroupId }),
  [ActionTypes.CREATE_EXPENSE_REPORT]: report => report,
  [ActionTypes.CHECK_DUPLICATE_EXPENSES]: ({ memberId, expense, index }) => ({ memberId, expense, index }),
  [ActionTypes.SET_REPORT_PERSONAL_INFO]: info => info,
  [ActionTypes.SET_CURRENT_ATTACHMENT_UPLOAD_STATE]: state => state,
  [ActionTypes.REMOVE_REPEATING_EXPENSES]: option => option,
  [ActionTypes.SET_RIDE_ID_ON_EXPENSE_REPORT]: options => options
});
