import React, { useState, useEffect, useMemo, isValidElement } from 'react';
import { isBoolean, isFunction, isString } from '~/utilities/guards';
import { Observable } from '~/utilities/observable';
import type { ModalProps } from '../Shared/Components/Modal/Modal';

const INITIAL_STATE: ModalProps = {
  title: '',
  message: '',
  isOpen: false
};

const store = new Observable<ModalProps>(INITIAL_STATE);

export interface ModalActions {
  setTitle: (title: ModalProps['title']) => void;
  setMessage: (message: ModalProps['message']) => void;
  setContent: (content: ModalProps['content']) => void;
  setIsOpen: (isOpen: ModalProps['isOpen']) => void;
  setIcon: (icon: ModalProps['icon']) => void;
  setAutoclose: (autoclose: ModalProps['autoclose']) => void;
  setConfirmLabel: (confirmLabel: ModalProps['confirmLabel']) => void;
  setCancelLabel: (cancelLabel: ModalProps['cancelLabel']) => void;
  setOnConfirm: (onConfirm: ModalProps['onConfirm']) => void;
  setOnCancel: (onCancel: ModalProps['onCancel']) => void;
  setOnClose: (onClose: ModalProps['onClose']) => void;
  set: (newState: ModalProps) => void;
  reset: () => void;
}

/**
 * A Custom hook used to toggle display of the SavedAddressDrawer
 * component.
 * @returns
 */
const useModal = () => {
  const [state, setState] = useState(store.get());

  useEffect(() => {
    return store.subscribe(setState);
  }, []);

  const actions: ModalActions = useMemo(() => {
    return {
      setTitle: (title: ModalProps['title']) => {
        if (isString(title)) store.set({ ...state, title });
      },
      setMessage: (message: ModalProps['message']) => {
        if (isString(message)) store.set({ ...state, message });
      },
      setContent: (content: ModalProps['content']) => {
        if (isValidElement(content)) store.set({ ...state, content });
      },
      setIsOpen: (isOpen: ModalProps['isOpen']) => {
        if (isBoolean(isOpen)) store.set({ ...state, isOpen });
      },
      setIcon: (icon: ModalProps['icon']) => {
        if (isValidElement(icon)) store.set({ ...state, icon });
      },
      setAutoclose: (autoclose: ModalProps['autoclose']) => {
        if (isBoolean(autoclose)) store.set({ ...state, autoclose });
      },
      setConfirmLabel: (confirmLabel: ModalProps['confirmLabel']) => {
        if (isString(confirmLabel) || confirmLabel === undefined) {
          store.set({ ...state, confirmLabel });
        }
      },
      setCancelLabel: (cancelLabel: ModalProps['cancelLabel']) => {
        if (isString(cancelLabel) || cancelLabel === undefined) {
          store.set({ ...state, cancelLabel });
        }
      },
      setOnConfirm: (onConfirm: ModalProps['onConfirm']) => {
        if (isFunction(onConfirm) || onConfirm === undefined) {
          store.set({ ...state, onConfirm });
        }
      },
      setOnCancel: (onCancel: ModalProps['onCancel']) => {
        if (isFunction(onCancel) || onCancel === undefined) {
          store.set({ ...state, onCancel });
        }
      },
      setOnClose: (onClose: ModalProps['onClose']) => {
        if (isFunction(onClose) || onClose === undefined) {
          store.set({ ...state, onClose });
        }
      },
      set: (newState: ModalProps) => store.set(newState),
      reset: () => store.set(INITIAL_STATE)
    };
  }, [state]);

  return [state, actions] as const;
};

/**
 * useModal HOC:
 * This higher-order component is designed to wrap another component and enhance it
 * with the useModal hook. This allows the wrapped component to access the modal state
 * and actions without needing to import the hook itself.
 * @param Component
 * @returns {React.Component}
 */
useModal.HOC = Component => {
  return function WrappedComponent(props) {
    const [state, actions] = useModal();

    return <Component {...props} modal={actions} modalState={state} />;
  };
};

export default useModal;
