import {
  GET_MEMBER_PERMISSIONS,
  CREATE_NEW_MEMBER,
  SET_NEW_MEMBER_FORM,
  VALIDATE_NEW_MEMBER_FORM,
  CLEAR_NEW_MEMBER_FORM
} from './constants';

export const getMemberPermissions = () => {
  return (dispatch, getState) => {
    const { user } = getState();
    dispatch({
      type: GET_MEMBER_PERMISSIONS,
      user
    });
  };
};

export const createMember = () => {
  return (dispatch, getState) => {
    const { members } = getState();
    dispatch({
      type: CREATE_NEW_MEMBER,
      payload: members.addNewMemberFormData
    });
  };
};

export const setAddMemberForm = params => {
  return dispatch => {
    dispatch({
      type: SET_NEW_MEMBER_FORM,
      params
    });
  };
};


export const validateAddNewMemberForm = () => {
  return dispatch => {
    dispatch({
      type: VALIDATE_NEW_MEMBER_FORM
    });
  };
};

export const clearNewMemberForm = params => {
  return dispatch => {
    dispatch({
      type: CLEAR_NEW_MEMBER_FORM,
      ...(params && { params })
    });
  };
};
