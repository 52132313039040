import immutable from 'immutability-helper';
import { handleActions } from 'redux-actions';
import { ActionTypes } from './constants';

import ExpensesServices from '~/services/expenses.service';
const { mapExpenseTableData, exportJsonAsCSV, getPaginationText } =
  new ExpensesServices();

// INITIAL STATE
const expenseState = {
  status: {
    get: null
  },
  tableQuery: {
    totalCount: 1,
    totalPages: 1,
    page: 1,
    limit: 50
  },
  tableData: [],
  tablePagination: {
    start: 1,
    end: 1,
    total: 1
  },
  filterParams: {},
  exportData: [],
  modal: { show: false }
};

export const expenseReducer = handleActions(
  {
    // on success of fetching table data
    [ActionTypes.GET_TABLE_DATA_SUCCESS]: (
      state,
      {
        payload: {
          data: { data = [], query = {} }
        },
        filterParams = {}
      }
    ) => {
      try {
        if (!data || !query) throw 'No data or query';
        const { totalCount, limit, page } = query;

        const mapTableData = mapExpenseTableData(data);
        const paginationData = getPaginationText(totalCount, limit, page);

        return immutable(state, {
          status: { get: { $set: 'success' } },
          tableData: { $set: mapTableData },
          tableQuery: { $set: query },
          filterParams: { $set: filterParams },
          tablePagination: { $set: paginationData }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error fetching expense data: ', error);
        return immutable(state, {
          status: { get: { $set: 'error' } }
        });
      }
    },
    [ActionTypes.GET_TABLE_DATA_ERROR]: state => {
      try {
        return immutable(state, {
          tableData: { $set: state.tableData },
          tableQuery: { $set: state.tableQuery },
          filterParams: { $set: state.filterParams },
          tablePagination: { $set: state.tablePagination },
          status: { get: { $set: 'error' } }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    },
    [ActionTypes.FILTER_TABLE_DATA_SUCCESS]: state => {
      try {
        return immutable(state, {
          status: { get: { $set: 'success' } }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('Error filtering expense data: ', error);
        return immutable(state, {
          status: { get: { $set: 'error' } }
        });
      }
    },
    [ActionTypes.FILTER_TABLE_DATA_ERROR]: state => {
      try {
        return immutable(state, {
          filterParms: { $set: state.filterParams },
          status: { get: { $set: 'error' } }
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      }
    },
    [ActionTypes.EXPORT_TABLE_DATA_SUCCESS]: (
      state,
      {
        payload: {
          data: { data = [] }
        }
      }
    ) => {
      const tableData = mapExpenseTableData(data);
      if (tableData.length) {
        exportJsonAsCSV({ records: tableData, fileName: 'expense-reports' });
      } else {
        alert('No records returned. Please try different dates.');
      }
      return immutable(state, {
        modal: { show: { $set: false } }
      });
    },
    [ActionTypes.SET_EXPORT_MODAL]: (state, { payload: { show } }) => {
      return immutable(state, {
        modal: { $set: { show } }
      });
    },
    [ActionTypes.SET_LOADING]: (state, { payload: { get } }) => {
      return immutable(state, {
        status: { get: { $set: get } }
      });
    }
  },
  expenseState
);
