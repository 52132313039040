import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import moment from 'moment';

const LiveChatMessageSeen = props => {
  if (parseInt(props.senderId) === 0) {
    return (
      <sub>
        <div>SafeRide Bot</div>
        <div>Sent {props.sentAt}</div>
      </sub>
    );
  }
  if (props.senderId === props.userId) {
    if (props.wasRead) {
      return (
        <sub>
          <div>Me</div>
          <div>
            Sent {props.sentAt} | Read <span>&#10004; &#10004;</span>
          </div>
        </sub>
      );
    } else {
      return (
        <sub>
          <div>Waiting for dispatcher.</div>
          <div>Not seen yet.</div>
        </sub>
      );
    }
  } else {
    return (
      <sub>
        <div>
          {props.sender.firstName} {props.sender.lastName}
        </div>
        <div>Sent {props.sentAt}</div>
      </sub>
    );
  }
};

const LiveChatMessage = props => {
  const userId = _.get(props, 'user.userData.id', 0);
  const senderId = _.get(props, 'message.senderId', 0);
  const sender = _.get(props, `selectedChat.users.${senderId}`, {});
  const sentAt = moment(props.message.sentAt).format('h:mm a, MMM Do');
  const classNames = ['liveChatMessage'];

  if (senderId === userId) {
    classNames.push('sent');
  } else {
    classNames.push('received');
  }

  classNames.push(props.message.type);

  const wasRead = props.message.readers.length > 0;

  return (
    <div className={classNames.join(' ')}>
      <div className="liveChatMessageText">{props.message.text}</div>
      <LiveChatMessageSeen
        wasRead={wasRead}
        sentAt={sentAt}
        senderId={senderId}
        sender={sender}
        userId={userId}
      />
    </div>
  );
};

LiveChatMessage.propTypes = {
  user: PropTypes.object,
  selectedChat: PropTypes.object,
  message: PropTypes.object
};

LiveChatMessage.defaultProps = {
  user: {},
  selectedChat: {},
  message: {}
};

export default LiveChatMessage;
