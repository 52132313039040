import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { COMPLETED_OR_CANCELLED, TRANSPORT_TYPES_DICTIONARY } from '~/constants';
import _ from 'lodash-es';

const VehicleInfo = ({ ride, isModal, bookingData, showCost, rideDetails }) => {
  const { LYFT, UBER } = TRANSPORT_TYPES_DICTIONARY;

  const getDriverAvatar = () => {
    const modelName = rideData?.modelName;
    let avatar = rideData?.avatar;

    if (modelName === LYFT) {
      const rideshareData = rideData.lyftRideData;

      avatar = rideshareData?.driver?.image_url ?? avatar;
    } else if (modelName === UBER) {
      const rideshareData = rideData.uberRideData;

      avatar = rideshareData?.driver?.picture_url ?? avatar;
    } else if (!avatar) {
      avatar = rideDetails?.driver?.avatar;
    }

    return avatar;
  };

  const getDriverName = () => {
    const { driverName = '', modelName } = rideData;

    let name = driverName;

    if (modelName === LYFT) {
      const rideshareData = rideData.lyftRideData;

      name = rideshareData?.driver?.first_name ?? name;
    } else if (modelName === UBER) {
      const rideshareData = rideData.uberRideData;

      name = rideshareData?.driver?.name ?? name;
    }

    return name;
  };

  const getDriverPhone = () => {
    const { driverPhone, dmobileNo, modelName } = rideData;

    let phone = driverPhone ?? dmobileNo;

    if (modelName === LYFT || modelName === UBER) {
      const rideshareData =
        modelName === LYFT ? rideData.lyftRideData : rideData.uberRideData;

      const driverDetails = rideshareData?.driver ?? {};

      if (driverDetails?.pin_based_phone_number) {
        phone = driverDetails?.pin_based_phone_number?.phone_number;
      } else {
        phone = driverDetails?.phone_number ?? phone;
      }
    }

    return formatPhoneNumber(phone);
  };

  const getDriverPin = () => {
    const { modelName } = rideData;

    let pin = '';

    if (modelName === UBER) {
      const pinDetails = rideData?.uberRideData?.driver?.pin_based_phone_number;

      pin = pinDetails?.pin ?? pin;
    }

    return pin;
  };

  const getVehicleRating = () => {
    const modelName = rideData?.modelName;
    let { vehicleOwnerRating = 0 } = rideData;

    if (modelName === LYFT || modelName === UBER) {
      const rideshareData =
        modelName === LYFT ? rideData.lyftRideData : rideData.uberRideData;

      vehicleOwnerRating = rideshareData?.driver?.rating ?? vehicleOwnerRating;
    }

    return vehicleOwnerRating;
  };

  const getPassengerCost = () => {
    const { passengerCost, modelName, cost } = rideData;

    const cancelledCompleted = COMPLETED_OR_CANCELLED.includes(rideData.status);

    let ret = '';
    if (modelName === LYFT || modelName === UBER) {
      ret = `$${Math.round((cost ?? passengerCost) * 1000) / 1000}`;
    } else if (passengerCost && showCost && !cancelledCompleted) {
      ret = `Est. Passenger Cost: $${parseFloat(passengerCost, 10).toFixed(2)}`;
    }

    return ret;
  };

  const rideData = isModal ? bookingData : ride;

  if (rideData.mode === 'Public') return null;

  const companyName =
    rideDetails.vehicleCompanyName ?? ride.companyName ?? ride.modelName;

  const passengerPhone = formatPhoneNumber(rideData.voMainBusinessLine);
  const passengerCost = getPassengerCost();

  const driverAvatar = getDriverAvatar();
  const driverName = getDriverName();
  const driverPhone = getDriverPhone();
  const driverPin = getDriverPin();
  const driverRating = getVehicleRating();

  return (
    <div className="vehicleInfoComplete">
      <div className="vehicle">
        <div className="driver">
          {driverAvatar ? (
            <div className="thumb">
              <img src={driverAvatar} className="avatar" alt="avatar" />
            </div>
          ) : (
            <div className="thumb empty"></div>
          )}
          <div className="name">
            {driverName} <br />
            {driverPhone} {driverPin ? `| PIN: ${driverPin}` : null}
          </div>
        </div>

        <p className="vehicleOwner">{companyName}</p>
        <p className="name">{passengerPhone}</p>
        <div className="stars">
          <ReactStars
            count={5}
            color1={'gray'}
            color2={'#FF931E'}
            edit={false}
            className="stars"
            value={Number(driverRating)}
          />
        </div>
        {rideData.modelName !== LYFT ? (
          <div className="cost">
            {passengerCost !== '' ? <p className="costVal">{passengerCost}</p> : null}
          </div>
        ) : null}
      </div>
    </div>
  );
};

VehicleInfo.defaultProps = {
  ride: {},
  isModal: false,
  bookingData: {},
  rideDetails: {},
  showCost: true
};

VehicleInfo.propTypes = {
  ride: PropTypes.object,
  isModal: PropTypes.bool,
  bookingData: PropTypes.object,
  rideDetails: PropTypes.object,
  showCost: PropTypes.bool
};

export default VehicleInfo;
