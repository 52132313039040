export const modalStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0,0,0,0.3)',
    zIndex: 150,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    justifyItems: 'center',
    alignItems: 'center',
    alignContent: 'center'
  },
  content: {
    inset: 'unset',
    maxWidth: 775,
    maxHeight: 684,
    border: '1px solid #ccc',
    background: '#fff',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '4px',
    outline: 'none',
    padding: '20px'
  }
};


export const cancelModalStyles = {
  overlay: {
    zIndex: 150
  },
  content: {
    zIndex: 150,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'

  }
};