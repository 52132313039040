import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { CHAT_CANCELLATION_REASON } from '~/constants';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import SvgClose from '../../../Svgs/SvgClose';

const LiveChatHeader = props => {
  const companyName = _.get(
    props.selectedChat,
    'rideDetails.vehicleOwnerInfo.companyName',
    ''
  );
  const companyPhone = _.get(
    props.selectedChat,
    'rideDetails.vehicleOwnerInfo.mobileNo',
    ''
  );
  const nemtResponderId = _.get(props.selectedChat, 'nemtResponderId', 0);
  const nemtUser = _.get(props.selectedChat, `users.${nemtResponderId}`, {});
  const isNemtUser = !_.isEmpty(nemtUser);
  const status = _.get(props.selectedChat, 'requestStatus', '');
  const validStatus = ['open', 'active'];
  const cancellationReason = _.get(props.selectedChat, 'cancellationReason', 0);
  const rideId = _.get(props.selectedChat, 'rideDetails.rideId', 0);
  const isCancelEnabled =
    props.isCancelEnabled && validStatus.indexOf(status) !== -1 && rideId === 0;
  let isReassignEnabled =
    props.isReassignEnabled && validStatus.indexOf(status) !== -1 && rideId === 0;

  // check if cancelled by NEMT (e.g. unable to fulfill) and enable
  if (
    status === 'cancelled' &&
    cancellationReason === CHAT_CANCELLATION_REASON.BY_NEMT_RESPONDER
  ) {
    isReassignEnabled = true;
  }

  return (
    <div className="liveChatHeader">
      <div className="blueBar">
        <div className="left">
          <h3>{companyName}</h3>
          <h4>
            {isNemtUser ? (
              <span>
                {nemtUser.firstName} {nemtUser.lastName}
              </span>
            ) : null}
            {companyPhone !== '' ? (
              <sub>
                {isNemtUser ? ' - ' : null}
                {formatPhoneNumber(companyPhone)}
              </sub>
            ) : null}
          </h4>
        </div>
        <button className="minimizeChat" onClick={props.handleWindowToggle}>
          Close <SvgClose />
        </button>
      </div>
      <div className="actionBar">
        <button
          className="cancelChat"
          onClick={() => props.handleCancelRequest(props.selectedChat.requestId)}
          disabled={!isCancelEnabled}
        >
          Cancel Request
        </button>
        <button
          className="reassignChat"
          onClick={() => props.handleReassignRequest(props.selectedChat.requestId)}
          disabled={!isReassignEnabled}
        >
          Select Another NEMT
        </button>
      </div>
    </div>
  );
};

LiveChatHeader.propTypes = {
  selectedChat: PropTypes.object,
  handleWindowToggle: PropTypes.func,
  handleCancelRequest: PropTypes.func,
  handleReassignRequest: PropTypes.func,
  isCancelEnabled: PropTypes.bool,
  isReassignEnabled: PropTypes.bool
};

LiveChatHeader.defaultProps = {
  selectedChat: {},
  handleWindowToggle: () => {},
  handleCancelRequest: () => {},
  handleReassignRequest: () => {},
  isCancelEnabled: false,
  isReassignEnabled: false
};

export default LiveChatHeader;
