import { createAppAsyncThunk } from '~/Modules';
import * as SavedAddressService from '~/services/savedAddress.service';
import { updateSavedAddresses } from '~/Modules/patients/Patients.slice';

interface DeleteSavedLocationParams {
  passengerId: number;
  locationId: number;
}

/** Delete a saved location for a member. */
export const deleteSavedLocationThunk = createAppAsyncThunk(
  'savedAddress/deleteSavedLocation',
  async (
    { passengerId, locationId }: DeleteSavedLocationParams,
    { getState, dispatch }
  ) => {
    const { savedAddresses } = getState().patients;

    await SavedAddressService.deleteSavedAddress(passengerId, locationId);
    const newAddressList = savedAddresses.filter(entry => entry.id !== locationId);
    dispatch(updateSavedAddresses(newAddressList));
  }
);
