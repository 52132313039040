import _ from 'lodash-es';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { convertCentsToDollars } from '~/utilities/currency';
import { TERMINAL_RIDE_STATUSES } from '~/constants';

const NemtTripSummary = props => {
  const {
    rideData,
    billingData,
    dataToggles,
    assistanceNeeds,
    memberIsRideAlone,
    memberIsManualScheduled
  } = props;
  let payor;

  if (!rideData.health_sub_plan_id) {
    payor = 'Patient';
    if (!rideData.stripeTokenId) {
      payor = 'Self Funded';
    }
  } else {
    payor = {
      healthSubPlanName: rideData.health_sub_plan_name,
      healthPlanName: rideData.health_plan_name
    };
  }

  const showCost = TERMINAL_RIDE_STATUSES.includes(rideData.status);
  let benefitAdjustmentCost = `${convertCentsToDollars(
    billingData.benefitAdjustmentCredit ?? 0
  )}`;
  if (benefitAdjustmentCost !== '$0.00') {
    benefitAdjustmentCost = `-${benefitAdjustmentCost}`;
  }
  return (
    <ul className="NemtTripSummary">
      <li>
        <p>Ride Id</p>
        <p>{rideData.id}</p>
      </li>
      <li>
        <p>Transportation Provider</p>
        <p>{rideData.vehicleCompanyName}</p>
      </li>
      <li>
        <p>Vehicle</p>
        <p>{rideData.vehicleName}</p>
      </li>
      <li>
        <p>Mobility Type</p>
        <p>{rideData?.reqVehicleTypeName}</p>
      </li>
      {(rideData?.vehicleSubTypeName ?? '') ? (
        <li>
          <p>Sub Mobility Type</p>
          <p>{rideData.vehicleSubTypeName}</p>
        </li>
      ) : null}
      {assistanceNeeds !== '' && rideData.reqVehicleType !== 8 ? (
        <li>
          <p>Assistance Needs</p>
          <p>{assistanceNeeds}</p>
        </li>
      ) : null}
      {memberIsRideAlone !== '' ? (
        <li>
          <p>Ride Alone</p>
          <p>{memberIsRideAlone}</p>
        </li>
      ) : null}
      {memberIsManualScheduled !== '' ? (
        <li>
          <p>Manual Schedule</p>
          <p>{memberIsManualScheduled}</p>
        </li>
      ) : null}
      {rideData.vehicleOdometerReading ? (
        <li>
          <p>Vehicle Odometer (completed)</p>
          <p>{rideData.vehicleOdometerReading}</p>
        </li>
      ) : null}
      {dataToggles.transport_provider ? (
        <li>
          <p>Transport Provider</p>
          <p>{rideData.vehicleCompanyName}</p>
        </li>
      ) : null}
      {dataToggles.transport_provider &&
      rideData.vehicleOwner &&
      rideData.vehicleOwner.contactNo2 ? (
          <li>
            <p>Transport Contact</p>
            <p>{formatPhoneNumber(rideData.vehicleOwner.contactNo2)}</p>
          </li>
        ) : null}
      {dataToggles.hospital_name ? (
        <li>
          <p>Hospital Name</p>
          <p>{rideData.hospital.hospitalName}</p>
        </li>
      ) : null}
      {typeof payor === 'string' ? (
        <li>
          <p>Payor</p>
          <p>{payor}</p>
        </li>
      ) : (
        <>
          <li>
            <p>Payor</p>
            <p>{payor.healthPlanName}</p>
          </li>
          <li>
            <p> </p>
            <p>{payor.healthSubPlanName}</p>
          </li>
        </>
      )}
      {rideData.booking_method && rideData.booking_method === 'member-portal' ? (
        <li>
          <p>Booked By</p>
          <p>Member</p>
        </li>
      ) : rideData.booked ? (
        <li>
          <p>Booked By</p>
          <p>
            {rideData.booked.firstName} {rideData.booked.lastName}
          </p>
        </li>
      ) : null}
      {rideData.booked ? (
        <li>
          <p>Booked Contact</p>
          <p>{formatPhoneNumber(rideData.booked.userMobileNo)}</p>
        </li>
      ) : null}
      {rideData.rideType !== 'ondemand' ? (
        <li>
          <p>Driver Punctuality</p>
          <p>
            {rideData.dispatcher_managed
              ? 'Dispatcher Managed'
              : `${rideData.late_time}${rideData.ShowOrigApptPickupTime ? '*' : ''}`}
          </p>
        </li>
      ) : null}

      {rideData.rideType !== 'ondemand' &&
      rideData.ShowOrigApptPickupTime !== '' &&
      !rideData.dispatcher_managed ? (
          <li>
            <p>
              {rideData.appointmentTime === null ? 'Orig. Pickup Time' : 'Orig. Appt. Time'}
            </p>
            <p>{rideData.ShowOrigApptPickupTime}</p>
          </li>
        ) : null}
      <li>
        <p>Billable Distance (Miles)</p>
        <p>
          {!rideData.dispatcher_managed
            ? `${parseFloat(rideData.distance).toFixed(1)} miles`
            : ''}
        </p>
      </li>
      <li>
        <p>Trip Duration</p>
        <p>{rideData.trip_duration}</p>
      </li>
      {rideData.customCharges.map((charge, key) => {
        return (
          <li key={key}>
            <p>Extra Charge: {charge.description}</p>
            <p>${(charge.quantity * parseFloat(charge.amount)).toFixed(2)}</p>
          </li>
        );
      })}
      <li>
        <p>Estimated Cost</p>
        <p>${parseFloat(rideData.cost).toFixed(2)}</p>
      </li>
      {dataToggles.trip_fee ? (
        <li>
          <p>Ride Cost</p>
          <p>
            {showCost
              ? '$' + parseFloat(rideData.totalCost).toFixed(2)
              : 'To be finalized'}
          </p>
        </li>
      ) : null}
      <li>
        <p>Supplier Adjustment Cost</p>
        <p>{convertCentsToDollars(billingData.supplierAdjustmentCost ?? 0)}</p>
      </li>
      <li>
        <p>Benefit Adjustment Cost</p>
        <p>
          <span className={benefitAdjustmentCost !== '$0.00' ? 'redIfNonZero' : ''}>
            {benefitAdjustmentCost}
          </span>
        </p>
      </li>
      <li>
        <p>Total Supplier Cost</p>
        <p>
          {showCost
            ? convertCentsToDollars(billingData.totalSupplierCost ?? 0)
            : 'To be finalized'}
        </p>
      </li>
      <li>
        <p>Total Benefit Cost</p>
        <p>{convertCentsToDollars(billingData.totalBenefitCost ?? 0)}</p>
      </li>
      <li>
        <p>Claim Status</p>
        <p>{rideData?.claim_status}</p>
      </li>
      {dataToggles.trip_fee && rideData.lastPayment ? (
        <li>
          <p>Booking Fee</p>
          <p>{rideData.lastPayment.saferide_fee + rideData.lastPayment.stripe_fee}</p>
        </li>
      ) : null}
      {dataToggles.trip_fee && rideData.lastPayment ? (
        <li>
          <p>Stripe Transaction ID</p>
          <p>
            {rideData.admin_flag ? (
              <a href={rideData.lastPayment.transaction_link}>
                {rideData.lastPayment.txnno}
              </a>
            ) : (
              rideData.lastPayment.txnno
            )}
          </p>
        </li>
      ) : null}
      {dataToggles.trip_fee && rideData.lastPayment ? (
        <li>
          <p>Stripe Transaction Status</p>
          <p>{rideData.lastPayment.pay_status}</p>
        </li>
      ) : null}
      {rideData.pickupVenueName ? (
        <li>
          <p>Venue Pickup Location</p>
          <p>{rideData.pickupVenueName}</p>
        </li>
      ) : null}
      {rideData.pickupEntranceName ? (
        <li>
          <p>Venue Pickup Entrance</p>
          <p>{rideData.pickupEntranceName}</p>
        </li>
      ) : null}
      {rideData.dropoffVenueName ? (
        <li>
          <p>Venue Dropoff Location</p>
          <p>{rideData.dropoffVenueName}</p>
        </li>
      ) : null}
      {rideData.dropoffEntranceName ? (
        <li>
          <p>Venue Dropoff Entrance</p>
          <p>{rideData.dropoffEntranceName}</p>
        </li>
      ) : null}
      {!_.isNil(rideData.original_ride_id) ? (
        <li>
          <p>Original Ride Id</p>
          <p>{rideData.original_ride_id}</p>
        </li>
      ) : null}
      <li>
        <p>Alert Claim Status:</p>
        <p>{rideData?.alert_claim_status}</p>
      </li>
    </ul>
  );
};

NemtTripSummary.propTypes = {
  vehicleTypes: PropTypes.object,
  rideData: PropTypes.object,
  billingData: PropTypes.object,
  dataToggles: PropTypes.object,
  assistanceNeeds: PropTypes.string,
  user: PropTypes.object
};

NemtTripSummary.defaultProps = {
  vehicleTypes: {},
  rideData: {},
  billingData: {},
  dataToggles: {},
  assistanceNeeds: '',
  user: {}
};

const mapStateToProps = state => ({
  user: state.user,
  billingData: state.rideDetails.ride.data.billingData
});

export default connect(mapStateToProps, null)(NemtTripSummary);
