import React from 'react';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import { LAT_LNG_DISPLAY_PRECISION } from '~/constants';

interface SavedAddressCardProps {
  index: number;
  savedAddress: MemberSavedAddress;
  onRemove: (address: MemberSavedAddress) => void;
  onChangeDefaultAddress: (address: MemberSavedAddress) => void;
}

const SavedAddressCard: React.FC<SavedAddressCardProps> = ({
  index,
  savedAddress,
  onRemove = () => {},
  onChangeDefaultAddress = () => {}
}) => {
  const { name, id, address, latitude, longitude, is_default } = savedAddress;

  const letter = String.fromCharCode(index + 65);

  /**
   * onChange handler for the Default Address checkbox.
   * When triggered it will call onChangeDefaultAddress prop
   * and pass it the savedAddress prop with the
   * is_default = !is_default
   */
  const onChange = () =>
    onChangeDefaultAddress({
      ...savedAddress,
      is_default: !is_default
    });

  return (
    <div className="savedAddressCard">
      <div className="label">{`${letter}. ${name}`}</div>
      <div className="address">{address}</div>
      <div className="latLng">lat/lng</div>
      <div className="latLng">
        {`${latitude.toFixed(LAT_LNG_DISPLAY_PRECISION)}, ${longitude.toFixed(LAT_LNG_DISPLAY_PRECISION)}`}
      </div>
      <div className="buttonContainer">
        <div className="defaultAddressCheckbox">
          <input
            type="checkbox"
            name="defaultAddress"
            value={id}
            checked={is_default}
            onChange={onChange}
          />
          <label htmlFor="defaultAddress">Set as Default Address</label>
        </div>

        <div>
          <button className="removeButton" onClick={() => onRemove(savedAddress)}>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

export default SavedAddressCard;
