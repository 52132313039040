// profile
import axios from '~/Modules/safeAxios';
import { ActionTypes } from './constants';
import { setMemberAccountParams } from './memberProfile.helper';
import { baseRequestConfig } from '~/utilities/auth.helper';
import {
  getMobilityAssessment,
  getMemberPortalSupportInfo
} from '~/services/member.service';
import { isEmpty } from '@SRHealth/frontend-lib';

const {
  CLEAR_ALL_DATA,
  CLEAR_FORM_DATA,
  LOAD_MEMBER_FORM,
  CLEAR_MEMBER_DATA,
  UPDATE_MEMBER_FORM,
  LOAD_MEMBER_AUDIT_LOGS,
  CLEAR_MEMBER_AUDIT_LOGS,
  GET_MEMBER_RIDESHARE_PREFERENCES,
  GET_MILEAGE_REIMBURSEMENT_DRIVERS_START,
  UPDATE_MEMBER_PORTAL_STATUS,
  GET_MEMBER_PORTAL_INFO_START,
  GET_MEMBER_PORTAL_INFO_SUCCESS,
  GET_MEMBER_PORTAL_INFO_ERROR,
  CREATE_MEMBER_PORTAL_STATUS_SUCCESS,
  CREATE_MEMBER_PORTAL_STATUS_ERROR,
  SEND_MEMBER_PORTAL_EMAIL_SUCCESS,
  SEND_MEMBER_PORTAL_EMAIL_ERROR
} = ActionTypes;

/**
 * removes success and error states from redux
 * @return {dispatch} - returns dispatch
 */
export const clearData = () => {
  return dispatch => dispatch({ type: CLEAR_FORM_DATA });
};

/**
 * clear all
 * @return {dispatch} - returns dispatch
 */
export const clearAll = () => {
  return dispatch => dispatch({ type: CLEAR_ALL_DATA });
};

/**
 * removes all member data
 * @return {dispatch} - returns dispatch
 */
export const clearMember = () => {
  return dispatch => dispatch({ type: CLEAR_MEMBER_DATA });
};

/**
 * dispatch for retrieving profile information
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const getMemberProfile = params => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    return dispatch({
      type: LOAD_MEMBER_FORM,
      data: params,
      user
    });
  };
};

export const getMemberProfileWithoutSaga = ({
  passengerId,
  subPlanId = null,
  externalSubPlanId = null
}) => {
  return async (dispatch, getState) => {
    const user = getState().user;

    const config = {
      ...baseRequestConfig(),
      method: 'GET',
      params: {},
      url: `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/view/${passengerId}`
    };

    if (subPlanId) config.params.subPlanId = subPlanId;

    if (externalSubPlanId) config.params.externalSubPlanId = externalSubPlanId;

    try {
      const results = await axios(config);

      if (results?.data?.status === true) {
        // We are unfortunately passing either the passenger id or the client unique id to the
        // getMemberProfileFromApi function. Pull the _passengerId_ directly from the
        // member response payload
        const mobilityAssessment = await getMobilityAssessment(
          results.data.data.member_data._id
        );

        if ('data' in mobilityAssessment) {
          dispatch({
            type: ActionTypes.SET_MEMBER_MOBILITY_ASSESSMENT,
            data: mobilityAssessment?.data
          });
        }

        dispatch({
          type: ActionTypes.LOAD_MEMBER_FORM_SUCCESS,
          passengerId: passengerId,
          data: results.data?.data ?? [],
          user,
          timestamp: results.data?.timestamp ?? {}
        });
      } else {
        dispatch({
          type: ActionTypes.LOAD_MEMBER_FORM_ERROR,
          data: { errors: [results?.data?.message] }
        });
      }
    } catch (error) {
      const errors = isEmpty(error?.response?.data?.errors)
        ? ['Error getting member profile.']
        : error?.response?.data?.errors;

      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        // eslint-disable-next-line no-console
        console.error('Failed to load member profile', error);
      }

      dispatch({ type: ActionTypes.LOAD_MEMBER_FORM_ERROR, data: { errors } });
    }
  };
};

/**
 * dispatch for saving profile information
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const putMemberProfile = params => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;
    const memberProfile = state.memberProfile;

    return dispatch({
      type: UPDATE_MEMBER_FORM,
      data: setMemberAccountParams(params, user, memberProfile),
      user
    });
  };
};

/**
 * dispatch for saving retrieving member's audit logs information
 * @param {object} params - action data
 * @return {dispatch} doesn't return anything but yield
 */
export const getMemberAuditLogs = params => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state.user;

    return dispatch({
      type: LOAD_MEMBER_AUDIT_LOGS,
      data: params,
      user
    });
  };
};

/**
 * clear memberAuditLogs
 * @return {dispatch} - returns dispatch
 */
export const clearMemberAuditLogs = () => {
  return dispatch => dispatch({ type: CLEAR_MEMBER_AUDIT_LOGS });
};

/**
 * Load member rideshare preferences
 * @returns {dispatch}
 */
export const getMemberRidesharePreferences = passengerId => {
  return dispatch => {
    return passengerId
      ? dispatch({
        type: GET_MEMBER_RIDESHARE_PREFERENCES,
        data: passengerId
      })
      : null;
  };
};

/**
 * Load mileage reimbursement drivers
 * @returns {dispatch}
 */
export const getMileageReimbursementDrivers = passengerId => {
  return dispatch => {
    return passengerId
      ? dispatch({
        type: GET_MILEAGE_REIMBURSEMENT_DRIVERS_START,
        data: passengerId
      })
      : null;
  };
};

/**
 * Loads member portal info from the api
 * @param {*} passengerId
 * @returns {dispatch}
 */
export const getMemberPortalInfoFromApi = passengerId => {
  return async dispatch => {
    try {
      dispatch({ type: GET_MEMBER_PORTAL_INFO_START, data: passengerId });
      const results = await getMemberPortalSupportInfo(passengerId);

      if (results?.status) {
        dispatch({
          type: GET_MEMBER_PORTAL_INFO_SUCCESS,
          data: results.data
        });
      } else {
        const error = results?.message ?? 'API Error getting member portal settings.';
        console.error(error);
        dispatch({
          type: GET_MEMBER_PORTAL_INFO_ERROR,
          data: { error }
        });
      }
    } catch (e) {
      const error = e?.message ?? 'Frontend error getting member portal settings.';
      console.error(error);
      dispatch({
        type: GET_MEMBER_PORTAL_INFO_ERROR,
        data: { error }
      });
    }
  };
};

export const updateMemberPortalStatus = isActive => ({
  type: UPDATE_MEMBER_PORTAL_STATUS,
  payload: isActive
});

export const createMemberPortalStatus = params => {
  return async dispatch => {
    try {
      const body = {
        passengerId: params.passengerId,
        isActive: params.isActive,
        deactivationReason: params.deactivationReason
      };

      const results = await createMemberPortalStatus(body);

      if (results?.status) {
        dispatch({
          type: CREATE_MEMBER_PORTAL_STATUS_SUCCESS,
          data: results.data
        });
      } else {
        const error = results?.message ?? 'API Error creating member portal status.';
        console.error(error);
        dispatch({
          type: CREATE_MEMBER_PORTAL_STATUS_ERROR,
          data: { error }
        });
      }
    } catch (e) {
      const error = e?.message ?? 'Frontend error creating member portal status.';
      console.error(error);
      dispatch({
        type: CREATE_MEMBER_PORTAL_STATUS_ERROR,
        data: { error }
      });
    }
  };
};

export const sendMemberPortalEmail = params => {
  return async dispatch => {
    try {
      const body = {
        passengerId: params.passengerId,
        email: params.email
      };

      const results = await sendMemberPortalEmail(body);

      if (results?.status) {
        dispatch({
          type: SEND_MEMBER_PORTAL_EMAIL_SUCCESS,
          data: results.data
        });
      } else {
        const error = results?.message ?? 'API Error sending member portal email.';
        console.error(error);
        dispatch({
          type: SEND_MEMBER_PORTAL_EMAIL_ERROR,
          data: { error }
        });
      }
    } catch (e) {
      const error = e?.message ?? 'Frontend error sending member portal email.';
      console.error(error);
      dispatch({
        type: SEND_MEMBER_PORTAL_EMAIL_ERROR,
        data: { error }
      });
    }
  };
};
