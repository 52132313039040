import { isEmpty } from '@SRHealth/frontend-lib';
import moment from 'moment';
import { FUNDING_SOURCES } from '~/constants';
import {
  formatPhoneNumberToE164Standard,
  isHealthPlan
} from '~/utilities/helperFunctions';

export const generateFundingSourceOptions = user => {
  const fundingSource = user?.userData?.fundingSource ?? null;
  if (!isEmpty(fundingSource)) return FUNDING_SOURCES[fundingSource];

  const userRole = user?.userData?.role ?? null;
  if (isEmpty(userRole)) return [];

  return isHealthPlan(userRole) ? FUNDING_SOURCES.HP : [];
};

export const parsePlanMemberPermissions = memberPermissions =>
  Array.isArray(memberPermissions)
    ? memberPermissions
      .filter(m => m.id !== 0)
      .map(p => ({
        ...p,
        subplans: p.subplans.filter(s => s.permission_type === 'create-edit')
      }))
      .filter(p => p.subplans.length >= 1)
    : [].concat({
      ...memberPermissions,
      subplans: Object.keys(memberPermissions.subplans).map(s => ({
        id: memberPermissions.subplans[s],
        name: s,
        label: s
      }))
    });

export const parseAllPlanMemberPermissions = memberPermissions =>
  Array.isArray(memberPermissions)
    ? memberPermissions
      .filter(m => m.id !== 0)
      .map(p => ({ ...p, subplans: p.subplans }))
      .filter(p => p.subplans.length >= 1)
    : [].concat({
      ...memberPermissions,
      subplans: Object.keys(memberPermissions.subplans).map(s => ({
        id: memberPermissions.subplans[s],
        name: s,
        label: s
      }))
    });

const isPhoneNumber = str =>
  /^\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/.test(str);

export const isFormValid = state => {
  const commonRequiredFields = ['medicalId', 'firstName', 'lastName', 'phone'];
  const addNewMemberFormData = state.addNewMemberFormData;

  if (addNewMemberFormData.fundingSource === FUNDING_SOURCES.SF.label) {
    return commonRequiredFields.every(k =>
      k === 'phone'
        ? isPhoneNumber(addNewMemberFormData[k])
        : !isEmpty(addNewMemberFormData[k])
    );
  }

  if (addNewMemberFormData.fundingSource === FUNDING_SOURCES.HP.label) {
    return commonRequiredFields
      .concat([
        'dateOfBirth',
        'subPlan',
        'healthPlan',
        'eligibilityStartDate',
        'eligibilityEndDate'
      ])
      .every(k => {
        if (k === 'phone') return isPhoneNumber(addNewMemberFormData[k]);

        if (
          k === 'dateOfBirth' ||
          k === 'eligibilityStartDate' ||
          k === 'eligibilityEndDate'
        ) {
          return moment(addNewMemberFormData[k], 'YYYY-MM-DD', true).isValid();
        }

        return !isEmpty(addNewMemberFormData[k]);
      });
  }

  return false;
};

const formatDate = date => new Date(date).toISOString().split('T')[0];

export const mapCreateMemberBody = params => ({
  firstName: params.firstName,
  lastName: params.lastName,
  medicalId: params.medicalId,
  mobileNo: params.phone,
  ...(params.healthPlan && { isEligible: 1 }),
  ...(params.dateOfBirth && {
    dateOfBirth: formatDate(params.dateOfBirth)
  }),
  ...(params.healthPlan && { health_plan_id: params.healthPlanId }), //need id
  ...(params.subPlan && { health_sub_plan_id: params.subPlanId }), // need id
  ...(params.address && { street1: params.address }),
  ...(params.address2 && { street2: params.address2 }),
  ...(params.eligibilityStartDate && {
    eligibleStartDate: params.eligibilityStartDate
  }),
  ...(params.eligibilityEndDate && { eligibleEndDate: params.eligibilityEndDate }),
  member_phone_numbers: [
    {
      id: null,
      phone_number: formatPhoneNumberToE164Standard(params.phone),
      is_primary: 1,
      opt_out: 0,
      opt_out_prompt_timestamp: null
    }
  ]
});
