//actions file for accessing the ride details endpoint
import { cloneDeep } from '~/utilities/helperFunctions';
import immutable from 'immutability-helper';
import { adminToken, baseRequestConfig } from '~/utilities/auth.helper';
import { rideCardActions } from './rideCards/rideCards.actions';
import axios from './safeAxios';
import { standardizeRideAddressFieldPrefixes } from '~/utilities/editRide.helper';

export const GET_RIDE = 'rideDetails/GET_RIDE';
export const GET_RIDE_ERROR = 'rideDetails/GET_RIDE_ERROR';
export const CLEAR_RIDE = 'rideDetails/CLEAR_RIDE';
export const UPDATE_RIDE_FUNDING_SOURCE = 'UPDATE_RIDE_FUNDING_SOURCE';
export const UPDATE_RIDE_FUNDING_SUCCESS = 'UPDATE_RIDE_FUNDING_SUCCESS';
export const UPDATE_RIDE_FUNDING_SOURCE_ERROR = 'UPDATE_RIDE_FUNDING_SOURCE_ERROR';
export const CLEAR_RIDE_FUNDING_SOURCE_FORM = 'CLEAR_RIDE_FUNDING_SOURCE_FORM';
export const UPDATE_EXISTING_ADJUSTMENT = 'UPDATE_EXISTING_ADJUSTMENT';

/**
 * dispatch for accessign ride details endpoint
 * @param {integer} rideId - rideId for ride details report
 * @return {dispatch} returns dispatch for GET_RIDE reducer
 */
export const getRide = rideId => {
  const params = {
    admin: false
  };
  if (adminToken !== null) {
    params.admin = true;
  }
  return (dispatch, getState) => {
    const state = getState();
    return axios
      .get(
        `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/report/${rideId}`,
        baseRequestConfig()
      )
      .then(response => {
        dispatch({
          type: GET_RIDE,
          data: response.data.data,
          rideId,
          state
        });
      })
      .catch(error => {
        dispatch({
          type: GET_RIDE_ERROR,
          rideId,
          error
        });
      });
  };
};

export const clearRide = () => {
  return dispatch => {
    return dispatch({
      type: CLEAR_RIDE
    });
  };
};

export const updateRideFundingSource = params => {
  return dispatch => {
    const { rideId, healthPlanId, healthSubPlanId, hospitalGroupId } = params;

    let body = {};
    if (hospitalGroupId) {
      body = { hospitalGroupId };
    } else {
      body = { healthPlanId, healthSubPlanId };
    }
    const url = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/rides/${rideId}/update-funding-source`;
    return axios
      .patch(url, body, baseRequestConfig())
      .then(response => {
        dispatch({
          type: UPDATE_RIDE_FUNDING_SUCCESS,
          data: response.data,
          params
        });
      })
      .catch(error => {
        dispatch({
          type: UPDATE_RIDE_FUNDING_SOURCE_ERROR,
          error,
          message: error.response.data.message,
          params
        });
      });
  };
};

export const clearFundingSource = () => {
  return dispatch => {
    return dispatch({
      type: CLEAR_RIDE_FUNDING_SOURCE_FORM
    });
  };
};

export const updateExistingAdjustment = payload => {
  return dispatch => {
    return dispatch({
      type: UPDATE_EXISTING_ADJUSTMENT,
      payload
    });
  };
};

/**
 * Creates a new object by assigning the values of oldObject
 * and newValues
 * @param {object} oldObject
 * @param {object} newValues
 * @returns
 */
function _updateState(oldObject, newValues) {
  return Object.assign({}, oldObject, newValues);
}

export default (state = {}, action) => {
  const updateState = _updateState.bind(null, state);

  switch (action.type) {
    case GET_RIDE: {
      const payload = action.data;
      const rideData = payload?.data?.ride;

      const update = {};

      update.ride = payload;
      update.rideId = action.rideId;
      update.healthSubPlanLocationsRestriction =
        rideData?.healthSubPlanLocationsRestriction ?? 0;
      update.healthSubPlanApprovedProviders =
        rideData?.healthSubPlanApprovedProviders ?? 0;
      update.hospitalGroupId = rideData?.hosptial?.hospitalGroupId ?? null;
      update.healthSubPlanId = rideData?.health_sub_plan_id ?? null;
      update.alertClaimStatus = action?.data?.data?.ride?.alert_claim_status;

      update.approvedProviderRideHistory = (rideData?.provider_history ?? []).map(r => ({
        name: r.providerName,
        address: r.providerAddress,
        phone: r.providerPhone,
        id: r.providerId,
        npiId: r.providerNpi
      }));

      update.ride.data.ride = standardizeRideAddressFieldPrefixes(rideData);

      return updateState(update);
    }
    case GET_RIDE_ERROR: {
      const update = {};
      update.rideId = action.rideId;

      if ('error' in action) {
        update.error = action.error;
      } else {
        update.error = {
          status: 'Error',
          message: 'There was an errror'
        };
      }
      return updateState(update);
    }
    case CLEAR_RIDE: {
      return {};
    }
    case rideCardActions.UPDATE_RIDE: {
      if (!Array.isArray(action?.data?.publicCards)) return state;

      const update = cloneDeep(state);

      update.ride.data.ride.publicCards = action.data.publicCards.map(publicCard => ({
        qty: publicCard.qty,
        cost: publicCard.cost,
        type_id: publicCard.typeId,
        status_id: publicCard.statusId
      }));

      return updateState(update);
    }
    case UPDATE_RIDE_FUNDING_SUCCESS: {
      return immutable(state, {
        updateRideFundingSource: {
          $set: {
            success: true
          }
        }
      });
    }
    case UPDATE_RIDE_FUNDING_SOURCE_ERROR: {
      return immutable(state, {
        updateRideFundingSource: {
          $set: {
            error: { error: action.error, message: action.message }
          }
        }
      });
    }
    case CLEAR_RIDE_FUNDING_SOURCE_FORM: {
      return immutable(state, {
        updateRideFundingSource: {
          $set: {}
        }
      });
    }
    case UPDATE_EXISTING_ADJUSTMENT: {
      const billingData = state?.ride?.data?.billingData
        ? cloneDeep(state.ride.data.billingData)
        : {};
      billingData.openAdjustment = action.payload;
      const update = {
        ...state,
        ride: { ...state.ride, data: { ...state.ride.data, billingData: billingData } }
      };
      return updateState(update);
    }
    default: {
      return state;
    }
  }
};
