import { createSlice } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

export interface AnalyticsStore {
  bookingStartTime: number;
  bookingEventId: string;
}

const initialState: AnalyticsStore = {
  bookingStartTime: 0,
  bookingEventId: ''
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    bookingFlowStarted: state => {
      state.bookingStartTime = Math.round(Date.now() / 1000);
      state.bookingEventId = uuidv4();
    }
  }
});

export const analyticsReducer = analyticsSlice.reducer;
export const AnalyticsActions = analyticsSlice.actions;
export const { bookingFlowStarted } = AnalyticsActions;
