/**
 * This module exports the configuration for the Analytics service with Mixpanel plugin.
 * It is responsible for initializing Mixpanel with the correct project token and
 * defining the properties that can be tracked with each event.
 *
 * IMPORTANT: This file should not include any sensitive information directly,
 * such as the Mixpanel project token. Such data should be fetched from a secure
 * environment variable or similar.
 *
 * For more information on how to use Mixpanel, please
 * refer to Mixpanel's official documentation at https://developer.mixpanel.com/.
 *
 */
import type { PageData } from 'analytics';
import { init } from 'analytics';
import mixpanelPlugin from '@analytics/mixpanel';
import type { UserStore } from '~/Modules/user';
import type { AnalyticEvent } from './analytics.types';
import type {
  LEGACY__RideBookingFlowEventProperties,
  MobilityAssessmentEventProperties,
  PageVisitEventProperties,
  PageNavigationEventProperties,
  UserIdleEventProperties,
  UserLogoutEventProperties,
  RbfPassengerInfoEventProperties,
  MemberDeeplinkActivatedProperties,
  RbfFundingSourceEventProperties,
  RbfDateEventProperties,
  RbfRidesEvent,
  EventProperties,
  WebVitalEventProperties
} from './analytics.events';

const MIXPANEL_TOKEN = process.env.REACT_APP_MIXPANEL_TOKEN;

const analytics = init({
  app: 'care',
  plugins: [mixpanelPlugin({ token: MIXPANEL_TOKEN })]
});

/**
 * Maps user details from the Redux store to a format compatible with the analytics service.
 *
 * @param user - The user object fetched from the Redux store.
 * @returns An object containing the user details, formatted for the analytics service.
 */
const mapUserProps = (user: Partial<UserStore>) => ({
  userId: user?.userData?.id,
  role: user.userData?.role,
  hospitalGroupName: user?.userData?.hospitalGroupName,
  userTimezone: user?.userData?.timezone_format
});

/**
 * Tracks an event in the analytics service.
 * Includes user details from the Redux store in the event data.
 *
 * @param eventName - The name of the event.
 * @param eventProperties - Additional properties of the event.
 */
function trackEvent(
  eventName: 'RBF Funding Source',
  eventProperties: RbfFundingSourceEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'RBF Passenger Info',
  eventProperties: RbfPassengerInfoEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'RBF Date',
  eventProperties: RbfDateEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'RBF Rides',
  eventProperties: RbfRidesEvent
): Promise<void>;
function trackEvent(
  eventName: 'User Logout',
  eventProperties: UserLogoutEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'User Idle',
  eventProperties: UserIdleEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Ride Booking Flow',
  eventProperties: LEGACY__RideBookingFlowEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Mobility Assessment Time',
  eventProperties: MobilityAssessmentEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Page Visit',
  eventProperties: PageVisitEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Page Navigation',
  eventProperties: PageNavigationEventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Button Clicked',
  eventProperties: EventProperties
): Promise<void>;
function trackEvent(
  eventName: 'Member Deeplink Activated',
  eventProperties: MemberDeeplinkActivatedProperties
): Promise<void>;
function trackEvent(
  eventName: 'Web Vitals',
  eventProperties: WebVitalEventProperties
): Promise<void>;
/**
 *
 */
function trackEvent(
  event: AnalyticEvent,
  eventProperties: EventProperties
): Promise<void> {
  return MIXPANEL_TOKEN ? analytics.track(event, eventProperties) : Promise.resolve();
}

/** Track a page view.
 *
 * ```
 * analyticsService.page();
 * ```
 */
const trackPage = (pageData: PageData): Promise<void> => {
  return MIXPANEL_TOKEN ? analytics.page(pageData) : Promise.resolve();
};

/** Identify a visitor.
 *
 * ```
 * analyticsService.identify('user-id-xyz', {
 *   firstName: 'bill',
 *   lastName: 'murray'
 * });
 * ```
 */
const identify = (userId: string, userTraits): Promise<void> => {
  return MIXPANEL_TOKEN ? analytics.identify(userId, userTraits) : Promise.resolve();
};

export const analyticsService = {
  mapUserProps,
  trackEvent,
  trackPage,
  identify
};
