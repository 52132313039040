/* eslint-disable react/display-name */
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import FilterList from '@material-ui/icons/FilterList';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import MaterialTable from 'material-table';
import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import TableFilter from '../TableFilter';
import TablePagination from '../TablePagination';
import { getTableData, setLoading } from '~/Modules/expenses/actions';
import { exportOptions, getKeyByValue } from '~/services/expenses.service';
import moment from 'moment-timezone';

const ExpenseTable = ({
  columns,
  showFilter,
  exportType,
  scrollToTop,
  onExportChange,
  toggleShowFilter
}) => {
  const dispatch = useDispatch();
  const {
    status,
    tableData,
    tableQuery,
    filterParams,
    tablePagination
  } = useSelector(state => state.expenses);
  const { page, totalPages, limit } = tableQuery;
  const { start, end, total } = tablePagination;

  // Initial table data fetch
  useEffect(() => {
    dispatch(getTableData({ page, limit, query: { timezone: moment.tz.guess() } }));
  }, []);

  // action to change page
  const onChangePage = newPage => {
    dispatch(setLoading({ get: 'loading' }));
    dispatch(getTableData({ page: newPage, limit, query: filterParams }));
    scrollToTop();
  };

  const pageSize =
    page === totalPages ? tableData.length : totalPages === 0 ? 1 : limit;

  return !status.get ? (
    <div className="loader">
      <CircularProgress />
    </div>
  ) : (
    <div>
      <MaterialTable
        key={tableData.length}
        data={tableData}
        columns={columns}
        options={{
          search: false,
          sorting: false,
          pageSize: pageSize,
          selection: false,
          showTitle: false,
          filtering: showFilter,
          exportButton: false,
          pageSizeOptions: [limit],
          showFirstLastPageButtons: false,
          showEmptyDataSourceMessage: true
        }}
        isLoading={status.get === 'loading'}
        onChangePage={page => onChangePage(page)}
        localization={{
          body: {
            emptyDataSourceMessage:
              status.get === 'error'
                ? 'Error retrieving expense reports'
                : 'No reports to display'
          }
        }}
        components={{
          // override default toolbar to add toggle show/hide filter
          Toolbar: () => (
            <div className="toolbar">
              <Button
                variant="outlined"
                className="toolbarButton"
                onClick={toggleShowFilter}
              >
                {showFilter ? 'Hide Filters' : 'Show Filters'}
                <FilterList />
              </Button>
              {tableData.length && Object.keys(filterParams).length > 1 ? (
                <FormControl>
                  <Select
                    className="toolbarSelect"
                    value={
                      getKeyByValue(exportOptions, exportOptions[exportType]) ||
                      ''
                    }
                    displayEmpty
                    onChange={event => onExportChange(event.target.value)}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="">
                      <em style={{ fontSize: '12px' }}>Export</em>
                    </MenuItem>
                    {Object.keys(exportOptions).map(key => (
                      <MenuItem key={key} value={key}>
                        <ListItemText
                          disableTypography
                          primary={
                            <Typography
                              variant="body1"
                              style={{ fontSize: '12px' }}
                            >
                              {exportOptions[key]}
                            </Typography>
                          }
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : null }
            </div>
          ),
          FilterRow: () => (
            <TableFilter
              columns={columns}
              params={filterParams}
              query={tableQuery}
            />
          ),
          Pagination: () => (
            <TablePagination
              end={end}
              page={page}
              start={start}
              total={total}
              limit={limit}
              onChangePage={onChangePage}
            />
          )
        }}
      />
    </div>
  );
};

export default ExpenseTable;
