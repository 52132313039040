import React from 'react';
import RideInfo from './components/RideInfo';
import RideRoute from './components/RideRoute';
import RideActivity from './components/RideActivity';
import './index.styl';
import { updateBookingData } from '../../Modules/bookingData';
import { useDispatch } from 'react-redux';
import { getReBookingDataFromAPreviousRide } from '~/utilities/booking.helper';
import { isEmpty } from '~/utilities/helperFunctions';
import { NEW_RIDE_BOOKING_FLOW } from '~/constants';
import { useHistory } from 'react-router-dom';

const RideDetails = ({
  svgs = {},
  features = {},
  rideData = {},
  canRebook = false,
  MapboxMap = {},
  downloadUrl = '',
  handleClose = () => {},
  vehicleTypes = {},
  rebookCallback = () => {},
  showColumnHeader = false,
  mapboxAccessToken = ''
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const activities = rideData?.activities?.activityObject ?? [];
  const smsActivities = rideData?.smsActivities ?? [];

  const rebookCallbackExtended = (...rebookParams) => {
    const ride = rideData?.ride;

    if (isEmpty(ride)) {
      return;
    }

    if (features[NEW_RIDE_BOOKING_FLOW]) {
      return history.push(`/ride/new/${ride.passengerId}`);
    }

    const rideRebookingData = getReBookingDataFromAPreviousRide(ride);
    dispatch(updateBookingData(rideRebookingData));

    rebookCallback(...rebookParams);
  };

  return (
    <div className="RideDetails">
      <RideInfo
        history={history}
        rideData={rideData.ride}
        canRebook={canRebook}
        dataToggles={rideData.dataToggles}
        handleClose={handleClose}
        vehicleTypes={vehicleTypes}
        rebookCallback={rebookCallbackExtended}
        showColumnHeader={showColumnHeader}
      />
      <RideActivity
        rideData={rideData.ride}
        activities={activities}
        downloadUrl={downloadUrl}
        smsActivities={smsActivities}
        showColumnHeader={showColumnHeader}
      />
      <RideRoute
        svgs={svgs}
        rideData={rideData.ride}
        MapboxMap={MapboxMap}
        mapboxAccessToken={mapboxAccessToken}
        locationData={rideData.locationData}
      />
    </div>
  );
};

export default RideDetails;
