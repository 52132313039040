import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getProfile, updateProfile } from '~/Modules/profile';
import PropTypes from 'prop-types';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import UserColumn from '~/Shared/Components/UserColumn';
import SvgLogoLarge from '~/Shared/Components/Svgs/SvgLogoLarge';
import EditProfile from './Components/EditProfile';

class UserProfile extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      showEditProfile: false
    };
  }

  componentDidMount () {
    this.props.getProfile();
  }

  /**
   * show edit profile
   * @return {undefined}
   */
  editProfile = () => {
    this.setState({
      showEditProfile: true
    });
  };

  /**
   * close edit profile
   * @return {undefined}
   */
  closeEditProfile = () => {
    this.setState({
      showEditProfile: false
    });
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render () {
    return (
      <content className='UserProfile'>
        <PageFrame>
          <UserColumn
            user={this.props.user}
            editProfile={this.editProfile}
            profile={this.props.profile}
            hospitalData={this.props.hospitalData}
            showEdit={true}
          />
          <section className='userColumnRight'>
            {this.state.showEditProfile ?
              <EditProfile
                closeEditProfile={this.closeEditProfile}
              />
              :
              <div id='userProfileLogoContainer'>
                <SvgLogoLarge className='logoSvg' />
              </div>
            }
          </section>
        </PageFrame>
      </content>

    );
  }
}

UserProfile.propTypes = {
  user: PropTypes.object,
  getProfile: PropTypes.func,
  updateProfile: PropTypes.func,
  hospitalData: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  hospitalUsers: PropTypes.array,
  nodeUserType: PropTypes.string,
  profile: PropTypes.object
};

UserProfile.defaultProps = {
  getProfile: () => {},
  updateProfile: () => {},
  user: {},
  hospitalData: {},
  hospitalUsers: [],
  nodeUserType: '',
  profile: {}
};

const mapStateToProps = state => ({
  user: state.user.userData,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  nodeUserType: state.user.nodeUserType,
  profile: state.profile.userProfile
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getProfile: () => getProfile(),
  updateProfile: data => updateProfile(data)
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
