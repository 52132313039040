import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';

const TablePagination = ({ onChangePage, page, start, end, total, limit }) => {
  // action to go back page
  const handleBackButtonClick = () => {
    onChangePage(page - 1);
  };

  // action to go next page
  const handleNextButtonClick = () => {
    onChangePage(page + 1);
  };
  return (
    <td
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '60px'
      }}
    >
      <Tooltip title="back">
        <span>
          <IconButton onClick={handleBackButtonClick} disabled={page === 1}>
            <ChevronLeft />
          </IconButton>
        </span>
      </Tooltip>
      <Typography
        variant="caption"
        style={{
          textAlign: 'center',
          alignSelf: 'center',
          flexBasis: 'inherit'
        }}
      >
        {`${start}-${end} of ${total}`}
      </Typography>
      <Tooltip title="next">
        <span>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(total / limit)}
          >
            <ChevronRight />
          </IconButton>
        </span>
      </Tooltip>
    </td>
  );
};

export default TablePagination;
