import React, { useState, useEffect, useRef } from 'react';
import PassengerForm from './PassengerForm';
import { isEqual } from 'lodash-es';
import { useSelector } from 'react-redux';
import { PassengerSummary } from './PassengerSummary';
import { cloneDeep, NOOP } from '~/utilities/helperFunctions';

const AMB_ID = 5;

const PassengerDropdown = ({
  isOpen = false,
  disabled = false,
  setField,
  passengers = [],
  mobilityType = null,
  vehicle_types = [],
  subMobilityType = null
}) => {
  setField ??= NOOP;

  // Passenger object constructor.
  const Passenger = function (mobilityType, subMobilityType) {
    return {
      ageGroup: 'adult',
      lastName: '',
      firstName: '',
      isAttendant: false,
      mobilityType,
      subMobilityType
    };
  };

  const [allPassengers, setPassengers] = useState(passengers);
  const [showDropdown, setShowDropdown] = useState(isOpen);

  const myRef = useRef();

  const passengerCount = allPassengers.length;

  const handleClickOutside = e => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      if (
        typeof e.target.className === 'string' &&
        e.target.className.match('passenger-input')
      ) {
        return;
      }
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  // If local state is updated, update redux.
  useEffect(() => {
    setField({ name: 'passengers', value: allPassengers });
  }, [allPassengers]);

  // Little hook to ensure redux and local state are in sync.
  useEffect(() => {
    if (!isEqual(allPassengers, passengers)) {
      setField({ name: 'passengers', value: passengers });
      setPassengers(passengers);
    }
  }, [passengers]);

  const { formData = {} } = useSelector(state => state.memberProfile);

  const { attendantInfo = {} } = formData;
  const { memberData } = attendantInfo;

  if (!memberData) return null;

  const _mobilityType = cloneDeep(mobilityType || AMB_ID);
  const _subMobilityType = cloneDeep(subMobilityType);

  return (
    <div className="AdditionalPassengers PassengerDropdown" key="passengers" ref={myRef}>
      <div
        className={`passenger-input ${disabled ? 'bg-disabled pe-none' : ''}`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        <PassengerSummary passengers={passengers} />
      </div>
      {showDropdown ? (
        <div ref={myRef} onClick={handleClickOutside} className="passenger-dropdown">
          {passengerCount > 0
            ? allPassengers.map((x, i) => (
              <div key={i}>
                <PassengerForm
                  index={i}
                  formType="double-row"
                  passengers={allPassengers}
                  setPassengers={setPassengers}
                  vehicle_types={vehicle_types}
                />
              </div>
            ))
            : null}
          <div>
            <div
              onClick={() => {
                if (passengerCount === 5) return null;
                setPassengers([
                  ...allPassengers,
                  new Passenger(_mobilityType, _subMobilityType)
                ]);
                setTimeout(() => {
                  const e = document.getElementsByClassName('passenger-dropdown')[0];
                  e.scrollTop = e.scrollHeight;
                }, 150);
              }}
              id="AddPassengers"
              className="fc-blue cursor"
            >
              {passengerCount < 5 && !disabled ? '+ Add Passenger' : ''}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const arePropsEqual = (prev, next) => {
  if (!isEqual(prev.passengers, next.passengers)) {
    return false;
  }
  if (!isEqual(prev.disabled, next.disabled)) {
    return false;
  }
  return true;
};

export default React.memo(PassengerDropdown, arePropsEqual);
