import React from 'react';
import { Input } from '@SRHealth/frontend-lib';
import type {
  DependentField as DependentFieldRecord,
  DependentFieldOption
} from '~/Modules/user';
import type { FundingSourceProps } from '~/models';
import type { RuleErrorRecord } from '@SRHealth/frontend-lib/dist/lib/model/ModelValidationError';
import { isEmpty } from '~/utilities/helperFunctions';

type DependentFieldProps = {
  field: DependentFieldRecord;
  dependentFieldValues: FundingSourceProps['dependentFields'];
  errors: RuleErrorRecord;
  onChange: (id: number, v: string) => void;
  options?: DependentFieldOption[];
};

/** This is a recursive component that is designed in response
 * to the DependentField data structures concocted in the backend.
 * Please don't judge me on this; and to anyone reading this thinking
 * "Oh, that's not that bad". It is. Don't do this. This is bad. */
export default function DependentField({
  field,
  dependentFieldValues,
  errors,
  onChange,
  options = field.compliance_options
}: DependentFieldProps) {
  /** If there is a child-parent option dependency then filter out all invalid options. */
  if (field?.parent_id && dependentFieldValues[field.parent_id]) {
    options = options.filter(
      opt => opt.parent_option_id === dependentFieldValues[field.parent_id!]
    );
  }

  if (isEmpty(options)) {
    return null;
  }

  const _options = options.map(opt => ({
    id: opt.id,
    value: opt.id,
    label: opt.option_value,
    childOptions: opt.child_options
  }));

  return (
    <>
      <Input
        inputId={field.id}
        type="select"
        name={`dependentFields-${field.id}`}
        label={field.input_name}
        placeholder={field.input_description}
        value={dependentFieldValues?.[field.id]}
        options={_options}
        error={`dependent-field-${field.id}` in errors}
        onChange={onChange}
        className="w-full"
        required
      />
      {field.dependent_field && dependentFieldValues?.[field.id] && (
        <DependentField
          field={field.dependent_field}
          dependentFieldValues={dependentFieldValues}
          options={field.dependent_field.compliance_options}
          errors={errors}
          onChange={onChange}
        />
      )}
    </>
  );
}
