'use strict';

/**
 * Constructs an enumeration with keys equal to their value.
 *
 * For example:
 *
 *   var COLORS = keyMirror({blue: null, red: null});
 *   var myColor = COLORS.blue;
 *   var isColorValid = !!COLORS[myColor];
 *
 * The last line could not be performed if the values of the generated enum were
 * not equal to their keys.
 *
 *   Input:  {key1: val1, key2: val2}
 *   Output: {key1: key1, key2: key2}
 *
 * @param {object} obj
 * @return
 */
export default function keyMirror<T>(obj: T) {
  const ret: Record<string, string> = {};

  let key: string;

  if (!(obj instanceof Object && !Array.isArray(obj))) {
    throw new Error('keyMirror(...): Argument must be an object.');
  }

  for (key in obj) {
    if (!Object.hasOwn(obj, key)) {
      continue;
    }

    ret[key] = key;
  }

  return ret as Record<keyof T, keyof T>;
}
