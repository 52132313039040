import React, { useState } from 'react';

const Button = props => {
  const [showValidate, showValidateMsg] = useState(false);
  const { children, disabled = false, running = false } = props;
  let className = props?.className || '';
  let onClick = props?.onClick;

  if (disabled) {
    className += ' disable-btn';
    onClick = () => {};
  }

  const ValidateMsg = () => {
    return showValidate && !running && disabled ? (
      <div className="validate-msg">Please fill all required fields</div>
    ) : null;
  };

  return (
    <div
      {...props}
      onClick={onClick}
      onMouseOver={() => showValidateMsg(true)}
      onMouseLeave={() => showValidateMsg(false)}
      className={`exp-button ${className}`}
    >
      {children}
      <ValidateMsg />
    </div>
  );
};

export default Button;
