import { createSlice } from '@reduxjs/toolkit';
import { getProfile, updateProfile } from './thunks';

const initialState = (): ProfileStore => ({
  status: 0,
  timestamp: 0,
  userProfile: {
    user: {
      id: 0,
      firstName: '',
      lastName: '',
      timezone_format: '',
      role: '',
      userMobileNo: ''
    },
    profile: {
      id: 0,
      funding_source: '',
      hospitalGroupName: '',
      userId: 0,
      contactNo1: '',
      street1: '',
      street2: '',
      city: '',
      state: '',
      country: '',
      zipcode: '',
      hospitalLogo: null,
      hospital_group_status: '',
      accounts_payable_vendor_id: 0,
      created_at: '',
      updated_at: '',
      fianncial: '',
      confirmation_agreement: null,
      accountType: '',
      passenger_rate: 0,
      municipal: 0,
      self_pay: 0,
      sso: 0,
      sso_slug: '',
      broker: 0,
      emailRecipientsForNemtCancelledRides: '',
      sftp_lookup_dates: 0,
      past_booking_days: 0,
      future_booking_days: 0
    },
    cities: null,
    states: null,
    countries: null,
    timezones: null
  }
});

const profileSlice = createSlice({
  name: 'profile',
  initialState: initialState(),
  reducers: {
    resetProfile: () => initialState()
  },
  extraReducers: builder => {
    builder
      .addCase(getProfile.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.userProfile = action.payload.data;
        state.timestamp = Math.floor(Date.now() / 1000);

        if ('error' in state) delete state.error;
      })
      .addCase(getProfile.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          state.error = action.payload!.error;
          state.status = action.payload!.error.status;
        } else {
          state.status = 500;
          state.error = {
            message: 'An unknown error occurred.',
            status: 500
          };
        }

        state.timestamp = Math.floor(Date.now() / 1000);
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.timestamp = Math.floor(Date.now() / 1000);
        state.updatedProfile = {
          message: 'Profile updated successfully.',
          status: action.payload.status
        };

        if ('error' in state) delete state.error;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        if (action.meta.rejectedWithValue) {
          state.error = action.payload!.error;
          state.status = action.payload!.error.status;
        } else {
          state.status = 500;
          state.error = {
            message: 'An unknown error occurred.',
            status: 500
          };
        }

        state.timestamp = Math.floor(Date.now() / 1000);
      });
  }
});

export * from './thunks';
export const profileReducer = profileSlice.reducer;
export const { resetProfile } = profileSlice.actions;
