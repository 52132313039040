import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { getProfile, updateProfile } from '~/Modules/profile';
import PropTypes from 'prop-types';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import UserColumn from '~/Shared/Components/UserColumn';
import SvgPencil from '~/Shared/Components/Svgs/SvgPencil';
import RowPagination from '~/Shared/Components/RowPagination';
import Sort from '~/Shared/Components/Sort';
import Search from '~/Shared/Components/Search';
import _ from 'lodash-es';
import {
  getNetworkManagers,
  getNetworkManager,
  clearNetworkData,
  clearManagerData,
  updateManagerStatus,
  updateManager,
  UPDATE_NETWORK_MANAGER_SUCCESS,
  CREATE_NETWORK_MANAGER_SUCCESS,
  UPDATE_NETWORK_MANAGER_ERROR,
  UPDATE_MANAGER_STATUS_ERROR
} from '~/Modules/hospitalNetwork';
import Details from './Components/Details';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { statusNotifications } from '~/utilities/helperFunctions';
const DEFAULTS = {
  offset: 0,
  limit: 25,
  sort: 'firstName',
  sortType: 'asc'
};
const SORT_DEFAULTS = {
  start: 0,
  limit: 25,
  orderBy: 'firstName',
  orderByType: 'asc'
};

class HospitalNetwork extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      showDetails: false,
      manager: {},
      update: false,
      showSpinner: true,
      showRedirect: false
    };
  }

  componentDidMount() {
    const { user } = this.props;
    // ONLY HOSPITAL OWNERS CAN ACCESS THIS PAGE!
    if (user.role !== 'HospitalOwner') {
      this.setState({ showRedirect: true });
    } else {
      this.props.getProfile();
      this.props.getNetworkManagers(this.props.params);
    }
  }

  componentDidUpdate(prevProps) {
    const { networkManager, managersTimestamp, type } = this.props;

    if (!_.isEqual(prevProps.networkManager, networkManager)) {
      this.setState({
        manager: networkManager
      });
    }

    if (!_.isEqual(prevProps.managersTimestamp, managersTimestamp)) {
      this.setState({
        showSpinner: false
      });
    }
    if (
      type === UPDATE_MANAGER_STATUS_ERROR &&
      !_.isEqual(managersTimestamp, prevProps.managersTimestamp)
    ) {
      statusNotifications(this.parseError(this.props.error), 'error', 5000);
    }
  }

  componentWillUnmount() {
    this.props.clearNetworkData();
  }

  /**
   * get all network managers
   * @param {object} params - data for getting all network managers
   * @return {undefined} - return nothing
   */
  getManagers = params => {
    const updatedParams = {
      offset: params.start,
      limit: 25,
      sort: params.orderBy,
      sortType: params.orderType
    };
    this.props.getNetworkManagers(updatedParams);
  };

  /**
   * get all network managers
   * @param {object} params - data for getting all network managers
   * @return {undefined} - return nothing
   */
  getManagersPagination = params => {
    const updatedParams = _.assign({}, params);
    updatedParams.offset = params.startLimit;
    this.props.getNetworkManagers(updatedParams);
  };

  /**
   * network manager search
   * @param {object} params - data for getting all network managers
   * @return {undefined} - return nothing
   */
  getManagersSearch = params => {
    if (params?.query) {
      params.search = params.query.replace('+', '_');
    }
    params.offset = 0;
    this.props.getNetworkManagers(params);
  };

  /**
   * get network manager data and open modal
   * @param {integer} index - index in array of network managers
   * @param {object} row - network manager data
   * @return {undefined} - nothign returned
   */
  handleEditManager = (index, row) => {
    const state = {
      row,
      index,
      update: true
    };
    this.props.getNetworkManager(row.id);
    this.openModal(state);
  };

  /**
   * open modal
   * @return {undefined} - returns nothing
   */
  handleAddManager = () => {
    this.openModal({});
  };

  /**
   * network manager search
   * @param {object} params - data for getting all network managers
   * @param {array} assigned - assigned facilities
   * @param {array} unassigned - unassigned facilities
   * @return {undefined} - return nothing
   */
  updateManager = (params, assigned, unassigned) => {
    this.props.updateManager(params, assigned, unassigned);
  };

  /**
   * update status, separate api call from other update ride calls
   * @param {integer} i - array key for this network manager record
   * @param {object} row - actual network manager row
   * @return {undefined} - return nothing
   */
  updateStatus = (i, row) => {
    const params = {
      key: i,
      accountStatus: row.accountStatus === 0 ? 1 : 0,
      id: row.id
    };
    this.props.updateManagerStatus(params);
  };

  /**
   * close modal and clear manager data
   * @return {undefined} - nothing returned
   */
  closeModal = () => {
    this.setState(
      {
        showDetails: false,
        update: false,
        manager: {}
      },
      this.props.clearManagerData
    );
  };

  /**
   * open the modal
   * @param {object} state - state data passed in
   * @return {undefined} - returns nothing
   */
  openModal(state) {
    state.showDetails = true;
    this.setState(state);
  }

  /**
   * parse error messages
   * @param {array} error - error from redux
   * @return {string} return error message as a string
   */
  parseError(error) {
    const errors = error.map(err => {
      return err.errorMessage;
    });
    if (Array.isArray(errors)) {
      return errors.join('. ');
    }
    return errors;
  }

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const successTypes = {
      update: UPDATE_NETWORK_MANAGER_SUCCESS,
      create: CREATE_NETWORK_MANAGER_SUCCESS
    };

    return (
      <content className="UserProfile AdminPages HospitalNetwork">
        <PageFrame>
          {this.state.showRedirect ? (
            <Redirect
              to={{
                pathname: '/ride/scheduled'
              }}
            />
          ) : null}
          {this.state.showDetails ? (
            <Details
              closeModal={this.closeModal}
              isOpen={this.state.showDetails}
              manager={this.state.manager}
              update={this.state.update}
              updateManager={this.updateManager}
              type={this.props.type}
              successTypes={successTypes}
              errorType={UPDATE_NETWORK_MANAGER_ERROR}
              hospitals={this.props.hospitalData}
              timestamp={this.props.timestamp}
              error={this.props.error}
            />
          ) : null}
          <UserColumn
            user={this.props.user}
            profile={this.props.profile}
            hospitalData={this.props.hospitalData}
            showEdit={false}
          />
          {this.state.showSpinner ? (
            <LoadingModal
              label="Loading Managers"
              closeTimeout={10000}
              isOpen={this.state.showSpinner}
            />
          ) : (
            <section className="userColumnRight">
              <table className="adminTable" id="networkManagerTable">
                <thead>
                  <tr role="row">
                    <th className="sorting">
                      <Sort
                        title="ID"
                        getRows={this.getManagers}
                        defaultFields={this.props.sortParams}
                        orderByField="id"
                      />
                      ID
                    </th>
                    <th className="sorting">
                      <Sort
                        title="Name"
                        getRows={this.getManagers}
                        defaultFields={this.props.sortParams}
                        orderByField="firstName"
                      />
                      Name
                    </th>
                    <th className="sorting">
                      <Sort
                        title="Name"
                        getRows={this.getManagers}
                        defaultFields={this.props.sortParams}
                        orderByField="userMobileNo"
                      />
                      Mobile No.
                    </th>
                    <th className="emailHeader sorting_disabled">Email</th>
                    <th className="sorting">
                      <Sort
                        title="Status"
                        getRows={this.getManagers}
                        defaultFields={this.props.sortParams}
                        orderByField="accountStatus"
                      />
                      Status
                    </th>
                    <th className="actionTableHeader sorting_disabled">
                      <a onClick={this.handleAddManager} className="addButton">
                        +
                      </a>
                      <a onClick={this.handleAddManager} className="addButtonText">
                        Add
                        <br />
                        Network
                        <br />
                        Admin
                      </a>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.networkManagers.map((row, i) => {
                    const active = row.accountStatus === 1;
                    const buttonClass = active ? 'toggleBtn on' : 'toggleBtn';
                    const circleClass = active ? 'toggleCircle on' : 'toggleCircle';
                    const name = `${row.firstName} ${row.lastName}`;
                    return (
                      <tr role="row" key={i} className="odd">
                        <td>{row.id}</td>
                        <td>{name}</td>
                        <td>{row.mobileNo}</td>
                        <td>{row.email}</td>
                        <td>
                          <span
                            className={buttonClass}
                            onClick={() => this.updateStatus(i, row)}
                          >
                            <span className={circleClass}></span>
                          </span>
                        </td>
                        <td>
                          <a
                            className="edit"
                            onClick={() => this.handleEditManager(i, row)}
                          >
                            <SvgPencil className="editSvg" />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Search
                getRows={this.getManagersSearch}
                placeHolder="Search by name or email"
                defaultFields={this.props.params}
                charactersMin={3}
                className="Search"
              />
              <RowPagination
                totalCount={this.props.totalCount}
                rowsReturned={this.props.params.limit}
                begin={this.props.params.offset}
                rowsPerPage={this.props.networkManagers.length}
                defaultFields={this.props.params}
                getRows={this.getManagersPagination}
              />
            </section>
          )}
        </PageFrame>
      </content>
    );
  }
}

HospitalNetwork.propTypes = {
  user: PropTypes.object,
  getProfile: PropTypes.func,
  updateProfile: PropTypes.func,
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hospitalUsers: PropTypes.array,
  nodeUserType: PropTypes.string,
  profile: PropTypes.object,
  getNetworkManagers: PropTypes.func,
  getNetworkManager: PropTypes.func,
  clearNetworkData: PropTypes.func,
  clearManagerData: PropTypes.func,
  updateManager: PropTypes.func,
  networkManagers: PropTypes.array,
  networkManager: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  params: PropTypes.object,
  sortParams: PropTypes.object,
  updateManagerStatus: PropTypes.func,
  totalCount: PropTypes.number,
  type: PropTypes.string,
  timestamp: PropTypes.object,
  managersTimestamp: PropTypes.object
};

HospitalNetwork.defaultProps = {
  getProfile: () => {},
  updateProfile: () => {},
  user: {},
  hospitalData: {},
  hospitalUsers: [],
  nodeUserType: '',
  profile: {},
  getNetworkManagers: () => {},
  getNetworkManager: () => {},
  clearNetworkData: () => {},
  clearManagerData: () => {},
  updateManager: () => {},
  networkManagers: [],
  networkManager: {},
  error: {},
  params: DEFAULTS,
  sortParams: SORT_DEFAULTS,
  updateManagerStatus: () => {},
  totalCount: 0,
  type: '',
  timestamp: {},
  managersTimestamp: {}
};

const mapStateToProps = state => ({
  user: state.user.userData,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  nodeUserType: state.user.nodeUserType,
  profile: state.profile.userProfile,
  networkManagers: state.hospitalNetwork.networkManagers,
  networkManager: state.hospitalNetwork.networkManager,
  error: state.hospitalNetwork.error,
  params: state.hospitalNetwork.params,
  sortParams: state.hospitalNetwork.sortParams,
  totalCount: state.hospitalNetwork.totalCount,
  type: state.hospitalNetwork.type,
  timestamp: state.hospitalNetwork.timestamp,
  managersTimestamp: state.hospitalNetwork.managersTimestamp
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getNetworkManagers: data => getNetworkManagers(data),
      getNetworkManager: id => getNetworkManager(id),
      clearNetworkData: () => clearNetworkData(),
      clearManagerData: () => clearManagerData(),
      updateManagerStatus: data => updateManagerStatus(data),
      getProfile: () => getProfile(),
      updateProfile: data => updateProfile(data),
      updateManager: (data, assigned, unassigned) =>
        updateManager(data, assigned, unassigned)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(HospitalNetwork);
