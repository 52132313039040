import { isBoolean, isJsonObject, isNumber, isString } from '@SRHealth/frontend-lib';
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import type {
  MemberSavedAddress,
  MemberSavedAddressRaw
} from '~/services/savedAddress.service';

const initialState: Partial<MemberSavedAddress> = {
  id: undefined,
  address: '',
  zip: '',
  name: '',
  latitude: 0,
  longitude: 0,
  is_default: false
};

const savedAddressSlice = createSlice({
  name: 'savedAddress',
  initialState,
  reducers: {
    setPassengerId: (state, action: PayloadAction<number>) => {
      if (isNumber(action.payload)) {
        state.passenger_id = action.payload;
      }
    },
    setAddress: (state, action: PayloadAction<string>) => {
      if (isString(action.payload)) {
        state.address = action.payload;
      }
    },
    setZip: (state, action: PayloadAction<string>) => {
      if (isString(action.payload)) {
        state.zip = action.payload;
      }
    },
    setName: (state, action: PayloadAction<string>) => {
      if (isString(action.payload)) {
        state.name = action.payload;
      }
    },
    setLatitude: (state, action: PayloadAction<number>) => {
      if (isNumber(action.payload)) {
        state.latitude = action.payload;
      }
    },
    setLongitude: (state, action: PayloadAction<number>) => {
      if (isNumber(action.payload)) {
        state.longitude = action.payload;
      }
    },
    setIsDefault: (state, action: PayloadAction<boolean>) => {
      if (isBoolean(action.payload)) {
        state.is_default = action.payload;
      }
    },
    setId: (state, action: PayloadAction<number>) => {
      if (isNumber(action.payload)) {
        state.id = action.payload;
      }
    },
    set: (state, action: PayloadAction<MemberSavedAddressRaw>) => {
      if (isJsonObject(action.payload)) {
        Object.keys(initialState).forEach(
          key =>
            (state[key] = key in action.payload ? action.payload[key] : initialState[key])
        );
      }
    },
    reset: state => {
      const currentPassengerId = state.passenger_id;
      Object.assign(state, initialState);
      // we reset after each new saved address is saved, but we want to avoid
      // resetting the passenger_id since we may still have more addresses to save for them right now.
      if (currentPassengerId) {
        state.passenger_id = currentPassengerId;
      }
    }
  }
});

export const savedAddressReducer = savedAddressSlice.reducer;
export const SavedAddressActions = savedAddressSlice.actions;
