import { useEffect, useState } from 'react';
import { Observable } from '~/utilities/observable';
import { getMobilityForm } from '~/services/member.service';
import type { FormState } from '~/Shared/Components/Form/Form';
import type { App } from 'contracts/mod';

type IForm = App.Member.V1.Shared.Forms.IForm;
type FormType = App.Member.V1.Shared.Forms.FormType;

const store = new Observable({});

/** Generic response for any endpoints providing templates */
interface BaseTemplateResponse {
  data: IForm;
}

/** Factory that produces custom hooks given a key and the callback to load it */
function useTemplateFactory<T extends BaseTemplateResponse>(
  template: FormType,
  callback: () => Promise<T>
): [IForm['id'], FormState] | [undefined, undefined] {
  const [state, setState] = useState(store.get());

  useEffect(() => {
    const onDismount = store.subscribe(setState);

    if (!(template in state)) {
      callback()
        .then((res: T) => {
          if (res) store.set({ ...state, [template]: res.data });
        })
        // eslint-disable-next-line no-console
        .catch(() => console.error(`Failed to load ${template} template`));

      store.set({ ...state, [template]: undefined });
    }

    return onDismount;
  }, []);

  return [
    state[template]?.id ?? undefined,
    state[template]?.template
      ? (state[template].template.map(input => structuredClone(input)) as FormState)
      : undefined
  ];
}

/**
 * Custom React hook to simplify loading and caching form templates. On load
 * the hook will check the cache store for the template. If it can't find
 * a template matching the key then it will perform an api call to load it.
 * @param {string} template
 * @returns {object}
 */
function useFormTemplate(
  template: 'mobility_assessment'
): [IForm['id'], FormState] | [undefined, undefined];
// eslint-disable-next-line jsdoc/require-jsdoc
function useFormTemplate(template: FormType) {
  switch (template) {
    case 'mobility_assessment':
      return useTemplateFactory('mobility_assessment', getMobilityForm);
  }
}

export default useFormTemplate;
