import React, {useMemo} from 'react';
import AutoComplete from '../../AutoComplete/AutoComplete';
import SavedAddressSelect from '../../SavedAddressSelect/SavedAddressSelect';
import {useSavedAddress} from '../../SavedAddressSelect/useSavedAddress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const DefaultRouteContainer = ({
  props,
  state,
  selectAutoComplete,
  resetAutoComplete,
  handleChangePickup,
  handleChangeDropoff
}) => {
  const [savedAddressState] = useSavedAddress();

  const autoCompleteData = useMemo(
    () => props?.autoCompleteData?.features ?? [],
    [props]
  );

  /**
   * Generates the autocomplete element for Pickup Address
   * @param {bool} bool A boolean to indicate if AutoComplete should be displayed
   * @returns {JSX|null} Returns AutoComplete element
   */
  const pickupAddressAutoComplete = bool => {
    return bool ? (
      <AutoComplete
        data={autoCompleteData}
        select={row => selectAutoComplete(row, 'Pickup')}
        showModal={state.showPickupAutoComplete}
        right="0"
        location="pickup"
        resetParentProps={resetAutoComplete}
      />
    ) : null;
  };

  /**
   * Generates the autocomplete element for Dropoff Address
   * @param {bool} bool A boolean to indicate if AutoComplete should be displayed
   * @returns {JSX|null} Returns AutoComplete element
   */
  const dropoffAddressAutoComplete = bool => {
    return bool ? (
      <AutoComplete
        data={autoCompleteData}
        select={row => selectAutoComplete(row, 'Dropoff')}
        showModal={state.showDropoffAutoComplete}
        right="0"
        location="dropoff"
        resetParentProps={resetAutoComplete}
      />
    ) : null;
  };

  const detectZipError = invalidZip => {
    return invalidZip ? (
      <div className="zipErrorMessage">* Please select from the suggested addresses.</div>
    ) : null;
  };

  return (
    <div className="left">
      <div className="row venues">
        <div className="venueLabel">
          <label htmlFor="pickup">Depart</label>
        </div>
        {savedAddressState.pickup ? (
          <SavedAddressSelect
            type="pickup"
            value={
              props.bookingData.fromMemberSavedAddressId ?? 
              props.bookingData?.rides?.[props.legIndex].fromMemberSavedAddressId ?? 
              undefined
            }
            onChange={row => selectAutoComplete(row, 'pickup', 'savedAddress')}
            onReset={() => selectAutoComplete(null, 'pickup', 'reset')}
          />
        ) : (
          <div className="address-input-container">
            <input
              type="text"
              name="pickup"
              autoComplete="off"
              id="pickup"
              placeholder="Type pickup address..."
              onChange={handleChangePickup}
              className={state.pickupClassName}
              value={state.pickupAddress}
            />
            <IconButton
              onClick={() => selectAutoComplete(null, 'pickup', 'reset')}
              type="button"
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}
        {state.invalidPickupZipcode ? (
          <div className="zipErrorMessage">
            * Please select from the suggested addresses.
          </div>
        ) : null}
      </div>

      {pickupAddressAutoComplete(autoCompleteData.length > 0)}

      <div className="row venues" />

      <div className="row venues">
        <div className="venueLabel">
          <label htmlFor="dropoff">Arrive</label>
        </div>
        {savedAddressState.dropoff ? (
          <SavedAddressSelect
            type="dropoff"
            value={
              props.bookingData.toMemberSavedAddressId ?? 
              props.bookingData?.rides?.[props.legIndex].toMemberSavedAddressId ?? 
              undefined
            }
            onChange={row => selectAutoComplete(row, 'dropoff', 'savedAddress')}
            onReset={() => selectAutoComplete(null, 'dropoff', 'reset')}
          />
        ) : (
          <div className="address-input-container">
            <input
              type="text"
              name="dropoff"
              id="dropoff"
              autoComplete="off"
              placeholder="Type dropoff address..."
              onChange={handleChangeDropoff}
              className={state.dropoffClassName}
              value={state.dropoffAddress}
            />
            <IconButton
              onClick={() => selectAutoComplete(null, 'dropoff', 'reset')}
              type="button"
            >
              <CloseIcon />
            </IconButton>
          </div>
        )}

        {detectZipError(state.invalidDropoffZip)}
      </div>

      {dropoffAddressAutoComplete(autoCompleteData.length > 0)}

      <div className="row venues" />
    </div>
  );
};

export default DefaultRouteContainer;
