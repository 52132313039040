import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import SvgArrow from '~/Shared/Components/Svgs/SvgArrow.js';

class Sort extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      direction: 'asc'
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextState.direction !== this.state.direction ||
      !_.isEqual(this.props.defaultFields, nextProps.defaultFields)
    ) {
      return true;
    }
    return false;
  }

  sort = dir => {
    const params = _.clone(this.props.defaultFields);
    if (params.orderType !== dir || params.orderBy !== this.props.orderByField) {
      params.orderType = dir;
      params.orderBy = this.props.orderByField;
      params.startLimit = 0;
      this.props.getRows(params, true);
      let newDirection = 'asc';

      if (dir === 'asc') {
        newDirection = 'desc';
      }

      this.setState({ direction: newDirection });
    }
  };

  sortUp = () => {
    this.sort('asc');
  };

  sortDown = () => {
    this.sort('desc');
  };

  render() {
    return (
      <div
        className="Sorted"
        onClick={this.state.direction === 'asc' ? this.sortUp : this.sortDown}
      >
        <p className="title">{this.props.title}</p>
        <p className={this.state.direction === 'asc' ? 'sortDesc' : 'sortAsc'}>
          <SvgArrow className="svgArrow" />
        </p>
      </div>
    );
  }
}

Sort.propTypes = {
  orderByField: PropTypes.string,
  getRows: PropTypes.func,
  defaultFields: PropTypes.object,
  title: PropTypes.string
};

Sort.defaultProps = {
  orderByField: '',
  getRows: () => {},
  defaultFields: {},
  title: ''
};

export default Sort;
