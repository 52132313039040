export const isEligibleHealthPlans = members => {
  const fundingKey = members?.fundingOptions?.key ?? null;

  switch (fundingKey) {
    case 'SF':
      return true;

    case 'HP':
      return members?.healthPlans?.length > 0;

    default:
      return true;
  }
};
