import React, { useEffect, useState } from 'react';
import { logout } from '~/utilities/auth.helper';

interface LogoutWarningProps {
  extendSession: () => void;
  ttl?: number;
}

// Singleton instance of the log out warning timer
let timer: NodeJS.Timeout | null = null;

const LogoutWarning: React.FC<LogoutWarningProps> = ({ extendSession, ttl = 0 }) => {
  const [countdown, setCountdown] = useState(ttl);

  useEffect(() => {
    timer = setInterval(() => tick(), 1000);

    return () => {
      if (timer) clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (countdown <= 0 && timer) clearInterval(timer);
  }, [countdown]);

  /**
   * Countdown function called periodically (every 1s)
   * @return {undefined}
   */
  const tick = () => setCountdown(prevCountdown => prevCountdown - 1);

  /**
   * Calculates the hours, minutes and seconds to be displayed in the LogoutWarning modal based on the countdown state
   * from tick(). Only adds values that are necessary to accurately display the remaining time. This means that no
   * leading zeros are displayed if there are no hours or minutes left in the countdown. In this case only two digits
   * of seconds are displayed.
   * @return {string} String template literal in the form 00:00:00 (or less of hours or minutes are not necessary)
   */
  function calculateTimeUntilLogout() {
    const logoutCountdown = Math.max(countdown, 0);
    const hours = Math.floor(logoutCountdown / 3600);
    let minutes = Math.floor((logoutCountdown % 3600) / 60);
    let seconds = logoutCountdown % 60;

    if (!hours && !seconds && minutes === 1) {
      minutes = 0;
      seconds = 60;
    }

    const formatValue = value => (value < 10 ? '0' + value : value);

    let timeUntilLogout = '';

    if (hours !== 0) {
      timeUntilLogout += `${hours}:`;
    }
    if (hours !== 0 || minutes !== 0) {
      timeUntilLogout += `${formatValue(minutes)}:`;
    }
    timeUntilLogout += formatValue(seconds);

    return timeUntilLogout;
  }

  return (
    <div className="LogoutWarning">
      <section>
        <h5>Your session is about to expire</h5>
        <div>
          For security reasons, your session will expire in
          <span>{calculateTimeUntilLogout()}</span> seconds due to inactivity.
        </div>
        <div>To continue working, select Continue Session</div>
        <div className="logoutWarningButtons">
          <button onClick={() => logout('User Idle')}>Log Out</button>
          <button onClick={extendSession}>Continue Session</button>
        </div>
      </section>
    </div>
  );
};

export default LogoutWarning;
