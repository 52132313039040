import { type AppThunk } from '~/Modules';
import {
  ADDRESS_MAPBOX_DEFAULT,
  ADDRESS_SAVED_DEFAULT,
  addressFactory,
  addressMapboxFactory,
  addressSavedFactory,
  RIDE_DEFAULT,
  rideFactory,
  type AddressMapbox,
  type AddressSaved,
  type RideModel,
  type RideProps
} from '~/models';
import { setRides } from '../RideBooking.slice';
import { selectAllowProvider } from '../selectors';
import { selectAllowVenues } from '~/Modules/user';

export const initializeRidesThunk = (): AppThunk<RideModel[]> => {
  return function (dispatch, getState) {
    const patientStore = getState().patients;
    const memberProfileStore = getState().memberProfile;

    const rideOne: RideProps = RIDE_DEFAULT();

    const savedAddresses = patientStore?.savedAddresses ?? [];
    const defaultSavedAddress = savedAddresses.find(
      savedAddress => savedAddress.is_default
    );

    // Check for a default saved address first
    if (defaultSavedAddress) {
      const departAddress: AddressSaved = ADDRESS_SAVED_DEFAULT();
      departAddress.value = defaultSavedAddress.address;
      departAddress.id = defaultSavedAddress.id;
      departAddress.name = defaultSavedAddress.name;
      departAddress.latitude = defaultSavedAddress.latitude;
      departAddress.longitude = defaultSavedAddress.longitude;

      rideOne.departAddress = addressSavedFactory(departAddress);
    } else if (patientStore?.patientDetails?.rideData) {
      // Pre-populate the depart address using the member's home address.
      // I do not know why the key is called rideData...
      const defaultAddress = patientStore.patientDetails.rideData;
      const departAddress: AddressMapbox = ADDRESS_MAPBOX_DEFAULT();

      departAddress.value = defaultAddress.from;
      // Convert lat and lng strings to floats
      departAddress.latitude = +defaultAddress.fromLat;
      departAddress.longitude = +defaultAddress.fromLng;

      rideOne.departAddress = addressMapboxFactory(departAddress);
    }

    // Pre-define the arrive address type based on the user's features
    if (selectAllowProvider(getState())) {
      rideOne.arriveAddress = addressFactory({ type: 'approved-provider' });
    } else if (selectAllowVenues(getState())) {
      rideOne.arriveAddress = addressFactory({ type: 'venue' });
    }

    // Add the member's driver notes to the ride
    if (memberProfileStore?.formData?.personalInfo?.otherDetails) {
      rideOne.notes = memberProfileStore.formData.personalInfo.otherDetails;
    }

    const rides = [rideFactory(rideOne)];

    dispatch(setRides(rides));

    return rides;
  };
};
