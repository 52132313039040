import { createTypeGuard, Is, isJsonArray, isObject } from '@SRHealth/frontend-lib';
import type {
  DateModel,
  FundingSourceModel,
  PassengerInfoModel,
  RideModel
} from '~/models';

export type RideBookingSection = 'passenger-info' | 'funding-source' | 'date' | 'rides';

export interface RideBookingStore {
  meta: {
    /** An unfortunate requirement to indicate that the store's
     * initialize thunk has already been called and completed. Necessary
     * until we can refactor MemberProfile to use the new redux toolkit. */
    isInitialized: boolean;
    /** Track what part of the RBF the user has reached. All sections < active
     * should be considered complete and validated. */
    activeSection: number;
    /** Track the previous active section so that we can roll back to it if user cancels
     * editing a section. */
    previousActiveSection: number;
    /** Time picker restrictions for the rides section */
    timeRestrictions?: {
      arriveBy: TimeRestrictions;
      departAt: TimeRestrictions;
    };
  };
  passengerInfo: PassengerInfoModel;
  fundingSource: FundingSourceModel;
  date: DateModel;
  rides: RideModel[];
}

export const isIsoDate = createTypeGuard<IsoDate>(v =>
  Is.String(v) && /^\d{4}-\d{2}-\d{2}$/.test(v) ? (v as IsoDate) : null
);

export type RestrictedDateRange = [IsoDate, IsoDate];

export const isRestrictedDateRange = createTypeGuard<RestrictedDateRange>(v => {
  if (Array.isArray(v) && v.length === 2 && v.every(date => isIsoDate(date))) {
    return v as RestrictedDateRange;
  }

  return null;
});

export type RestrictedDate = IsoDate | RestrictedDateRange;

export const isRestrictedDate = createTypeGuard<RestrictedDate>(v =>
  isIsoDate(v) || isRestrictedDateRange(v) ? v : null
);

export interface DateRestrictions {
  /** The earliest date that the passenger can book. */
  earliestDate: IsoDate;
  /** The latest date that the passenger can book. */
  latestDate: IsoDate;
  /** Additional specific dates the passenger cannot book */
  restrictedDates: RestrictedDate[];
}

export const isDateRestrictions = createTypeGuard<DateRestrictions>((v, has) => {
  if (
    isObject(v) &&
    has(v, 'earliestDate', isIsoDate) &&
    has(v, 'latestDate', isIsoDate) &&
    has(v, 'restrictedDates', isJsonArray) &&
    v.restrictedDates.every(isRestrictedDate)
  ) {
    return v as DateRestrictions;
  }

  return null;
});

/** The various types of the PassengerInfoSection */
export type PassengerInfoSection =
  | 'TripType'
  | 'TripPhoneNumber'
  | 'AdditionalPassengers'
  | 'none';

export interface TimeRestrictions {
  flex: boolean;
  now: boolean;
  offset: number;
  interval: number;
}

export type BenefitType = 'limited' | 'unlimited' | 'none';

export type RideBenefitsRecord = {
  label: string;
  limit: number;
  usage: number;
  alertAfter: number;
  hardBlock: boolean;
};

export type RideBenefitsUnlimitedRecord = {
  label: string;
  limit: 'Unlimited';
};

export type BlockType = 'Hard' | 'Soft' | 'Past' | undefined;
