import { isEmpty, isNil } from 'lodash-es';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';
import { format } from 'date-fns';
import { setExpenseSlideout } from '~/Modules/memberExpenses/actions';
import Tooltip from '~/Shared/Components/Tooltip/Tooltip';
import TransportTypeTag from '~/Shared/Components/TransportTypeTag';
import MemberPortalStatus from '~/Shared/Components/MemberPortalStatus/MemberPortalStatus';
import OptInContainer from '../OptInContainer/OptInContainer';
import { getOptInValue } from '../OptInContainer/OptIn.utils';
import {
  ELIGIBILITY_API,
  EXPENSE_REIMBURSEMENT,
  CUSTOM_TEXT_MESSAGING,
  NEW_RIDE_BOOKING_FLOW
} from '~/constants';
import { getVehicleRecordById } from '~/utilities/vehicles';
import {
  showSoftBlockAlert,
  showHardBlockAlert,
  isMemberEligible
} from '~/utilities/memberProfile';
import {
  isHealthPlanRole,
  isNetworkRole,
  formatPhoneNumber,
  getSafe
} from '~/utilities/helperFunctions';
import { getSelectedDropdownValue } from '~/Pages/MemberProfiles/MemberProfiles.helper';
import SvgWarningYellow from '~/Shared/Components/Svgs/SvgWarningFillYellow';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      medicalId: null,
      hospitalId: null,
      healthPlanId: null,
      dateOfBirth: null,
      displayOptInDialog: false,
      hasPrompt: null,
      bookingPhone: null
    };

    this.showButton = !isHealthPlanRole(props.user);
    this.isNetworkRole = isNetworkRole(props.user.userData.role);
  }

  /**
   * redirect to ride booking
   * @return {undefined}
   */
  redirect = () => {
    const { personalInfo, allowRedirect, benefits, user } = this.props;
    const { healthSubPlanId } = benefits;
    const {
      phone,
      version,
      value: optOutValue
    } = getOptInValue(personalInfo, this.state.hasPrompt, {
      isCustomTextMessage: user.features[CUSTOM_TEXT_MESSAGING],
      isEligibilityAPI: user.features[ELIGIBILITY_API]
    });

    if (!allowRedirect[0]) return false;

    const hospitalId =
      !this.isNetworkRole &&
      (isNil(personalInfo.hospitalId) || personalInfo.hospitalId === 0)
        ? user.hospitalData.id
        : personalInfo.hospitalId;

    const state = { hospitalId, isRedirect: true };

    if (phone) {
      state.bookingPhone = phone;
    }

    if (personalInfo?.dateOfBirth) {
      state.dateOfBirth = personalInfo.dateOfBirth;
    }

    if (personalInfo.healthPlanId) {
      state.healthPlanId = personalInfo.healthPlanId;
    }

    if (healthSubPlanId) {
      state.healthSubPlanId = healthSubPlanId;
    }

    if (version && optOutValue) {
      state.displayOptInDialog = true;
    } else {
      state.medicalId = personalInfo.medicalId;
    }

    this.setState(state);
  };

  /**
   * calculate age and return string
   * @return {string} return age string
   */
  getAge() {
    const personalInfo = this.props.personalInfo;

    if (
      isEmpty(personalInfo) ||
      personalInfo.dateOfBirth === 'Invalid date' ||
      !personalInfo.dateOfBirth
    ) {
      return '';
    }

    const dob = moment(personalInfo.dateOfBirth, 'MM/DD/YYYY');
    const diff = moment().diff(dob, 'years');
    return `${personalInfo.dateOfBirth} (${diff} years old)`;
  }

  /**
   * Looks up the vehicle record for the member's preferred
   * transport type and fetches the nickname
   * @returns {string}
   */
  getTransportType = () => {
    const mobility = this.props.mobility;
    const vehicleId = mobility.memberData?.vehicle_type;

    const transport =
      vehicleId === undefined
        ? undefined
        : getVehicleRecordById(vehicleId, mobility.vehicle_types)?.nickName;

    return transport ? transport : 'undefined';
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const {
      user,
      selectedId,
      benefits,
      allowRedirect,
      memberPortalInfo,
      personalInfo,
      navOptions,
      mobility = {},
      setExpenseSlideout
    } = this.props;

    const age = this.getAge();
    const transportType = this.getTransportType();
    const warnings = showSoftBlockAlert(benefits.rideBenefit);
    const blocks = showHardBlockAlert(benefits.rideBenefit);
    const expenseReimbursementEnabled = user.features[EXPENSE_REIMBURSEMENT];
    const phoneContainerKey = getSafe(() =>
      personalInfo.mobileNo.concat(personalInfo.phone2)
    );

    const requestRideEnabled = allowRedirect[0];
    const requestRideReason = allowRedirect[1];

    const fundingSource = !isNil(benefits.healthSubPlanName)
      ? benefits.healthSubPlanName
      : personalInfo.hospitalName;

    const isMemberFlagged = mobility
      ? getSelectedDropdownValue('member_flag', mobility)?.value === 'Yes'
      : false;

    const isEligible = isMemberEligible(
      personalInfo.isEligible,
      benefits.eligibleEndDate
    );

    const eligibleEndDate = benefits.eligibleEndDate
      ? format(new Date(benefits.eligibleEndDate), 'MM/dd/yyyy')
      : null;

    return (
      <>
        <div className={`MemberHeader ${isMemberFlagged ? 'flagged' : ''}`}>
          <div className="headerFlexWrapper">
            <div className="passengerName">
              <p className="memberName">
                {personalInfo.firstName} {personalInfo.lastName}
              </p>
              <p className="memberId">
                {personalInfo.medicalId} <span>{fundingSource}</span>
              </p>
              <p className="memberDob">{age}</p>
            </div>

            {isMemberFlagged ? (
              <div className="memberFlagContainer">
                <SvgWarningYellow />
                <p>This member has been flagged. Please check FWA notes.</p>
              </div>
            ) : null}

            <div className="buttons-wrapper">
              {personalInfo.medicalId && (
                <div className="transport-type">
                  <div>Member Transport Type</div>
                  <TransportTypeTag nickName={transportType} />
                </div>
              )}

              {expenseReimbursementEnabled && (
                <div className="newExpense cursor">
                  <a
                    className={`requestRideButton ${allowRedirect ? '' : 'disable'}`}
                    style={{ backgroundColor: '#56b99d' }}
                    onClick={() =>
                      setExpenseSlideout({ show: true, mode: 'create', id: null })
                    }
                  >
                    <div>New Expense</div>
                  </a>
                </div>
              )}
              <div className="requestColumn">
                <div className="requestRide">
                  <Tooltip
                    text={requestRideReason && <div>{requestRideReason}</div>}
                    width="120px"
                  >
                    <RequestRideButton
                      enabled={requestRideEnabled}
                      showButton={this.showButton}
                      onClick={this.redirect}
                    />
                  </Tooltip>
                  <div>
                    <div className="eligible">
                      <span className={isEligible ? 'dot' : 'dot isInactive'} />
                      <span className="eligibleStatus">
                        {isEligible ? 'Eligible' : 'Ineligible'}
                      </span>
                      {eligibleEndDate && (
                        <span className="eligibleStatus">(Exp. {eligibleEndDate})</span>
                      )}
                    </div>
                  </div>

                  <div className="eligible">
                    <MemberPortalStatus
                      memberPortalInfo={memberPortalInfo}
                      personalInfo={personalInfo}
                      mobility={mobility}
                    />
                  </div>
                  <div>
                    {warnings.length > 0 && (
                      <Tooltip
                        text={
                          <div>
                            {warnings.map((warning, i) => (
                              <div key={i}>{warning}</div>
                            ))}
                          </div>
                        }
                        width="190px"
                      >
                        <div className="planWarning">⚠️ Nearing Benefit Balance</div>
                      </Tooltip>
                    )}
                    {blocks.length > 0 && (
                      <Tooltip
                        text={
                          <div>
                            {blocks.map((block, i) => (
                              <div key={i}>{block}</div>
                            ))}
                            {user.userData.fundingSource === 'SFHP' &&
                              benefits.healthSubPlanName !== null && (
                              <div style={{ color: '#A8A8A8', fontSize: '10px' }}>
                                  *To book, use Self Funded Source
                              </div>
                            )}
                          </div>
                        }
                        width="190px"
                      >
                        <div className="planBlocked">❌ Benefit Balance Reached</div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ul className="tabs">
            {navOptions.map((opt, i) => (
              <li className="tab" key={i}>
                <NavLink className="tab" data-tip={opt.dataTip} to={opt.route}>
                  {opt.label}
                </NavLink>
              </li>
            ))}
          </ul>

          {this.state.medicalId && (
            <Redirect
              to={{
                pathname: user.features[NEW_RIDE_BOOKING_FLOW]
                  ? `/ride/new/${personalInfo.id}`
                  : '/ride/scheduled',
                state: {
                  medicalId: this.state.medicalId,
                  hospitalId: this.state.hospitalId,
                  healthPlanId: this.state.healthPlanId,
                  dateOfBirth: this.state.dateOfBirth,
                  isRedirect: true,
                  healthSubPlanId: this.state.healthSubPlanId || null,
                  personalInfo: this.props.personalInfo,
                  bookingPhone: formatPhoneNumber(this.state.bookingPhone),
                  clientUniqueId: this.props.personalInfo.client_unique_id || null
                }
              }}
            />
          )}
        </div>

        <OptInContainer
          key={phoneContainerKey}
          isOpen={this.state.displayOptInDialog}
          selectedId={selectedId}
          personalInfo={personalInfo}
          makeSelection={({ bookingPhone }) =>
            this.setState({
              displayOptInDialog: false,
              medicalId: personalInfo.medicalId,
              bookingPhone
            })
          }
          cancel={() =>
            this.setState({
              displayOptInDialog: false
            })
          }
          continue={() =>
            this.setState({
              displayOptInDialog: false,
              medicalId: personalInfo.medicalId
            })
          }
        />
      </>
    );
  }
}

function RequestRideButton({
  showButton,
  enabled = true,
  className = '',
  onClick = () => {}
}) {
  const _className = enabled
    ? `requestRideButton ${className}`
    : `requestRideButton disable ${className}`;

  function handleClick() {
    if (enabled) onClick();
  }

  return showButton ? (
    <a data-testid="request-ride-btn" className={_className} onClick={handleClick}>
      Request Ride
    </a>
  ) : null;
}

Header.propTypes = {
  user: PropTypes.object,
  childForm: PropTypes.string,
  parentForm: PropTypes.string,
  selectedId: PropTypes.string,
  memberPortalInfo: PropTypes.object,
  personalInfo: PropTypes.object,
  mobility: PropTypes.object,
  benefits: PropTypes.object,
  allowRedirect: PropTypes.array,
  urlParams: PropTypes.string,
  navOptions: PropTypes.array,
  setExpenseSlideout: PropTypes.func
};

Header.defaultProps = {
  user: {},
  childForm: '',
  parentForm: '',
  selectedId: 0,
  memberPortalInfo: {},
  personalInfo: {},
  mobility: {},
  benefits: {},
  allowRedirect: [false, undefined],
  urlParams: '',
  navOptions: [],
  setExpenseSlideout: () => {}
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      setExpenseSlideout: data => setExpenseSlideout(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Header);
