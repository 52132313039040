import React, { useContext } from 'react';
import { MapboxMap } from '@SRHealth/frontend-lib';
import { MapContext, type MapMarker } from './RideBookingMap.context';
import useRideBookingMap from './RideBookingMap.hook';
import MapPlaceholder from './MapPlaceholder';

type RideBookingMapProps = {
  height?: string;
};

function RideBookingMap({ height = '760px' }: RideBookingMapProps) {
  const [markers] = useContext(MapContext);

  // Default type to pickup if not provided
  const [departMarkers, arriveMarkers] = markers.reduce(
    (p, c) => {
      p[c.type === 'arrive' ? 1 : 0].push(c);
      return p;
    },
    [[] as MapMarker[], [] as MapMarker[]]
  );

  if (!departMarkers.length && !arriveMarkers.length) {
    return <MapPlaceholder />;
  }

  return (
    <div
      className="relative w-full"
      style={{
        height,
        position: 'sticky',
        top: '60px'
      }}
    >
      <MapboxMap
        apiKey={process.env.REACT_APP_MAPBOX_TOKEN ?? ''}
        pickupMarkers={departMarkers}
        dropoffMarkers={arriveMarkers}
      />
      <div
        className="absolute top-0 left-0 w-full h-full pointer-events-none"
        style={{ boxShadow: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.25)' }}
      />
    </div>
  );
}

RideBookingMap.Provider = MapContext.Provider;

export default RideBookingMap;
export { useRideBookingMap };
export * from './RideBookingMap.context';
