import React from 'react';
import PropTypes from 'prop-types';
import LiveChatConversationListItem from './LiveChatConversationListItem';

const LiveChatConversationList = props => {
  const { user, chats, selectedRequestId, setSelectedRequest } = props;

  return (
    <div className="liveChatConversationList">
      <ul>
        {chats.map(chat => (
          <LiveChatConversationListItem
            key={chat.requestId}
            chat={chat}
            user={user}
            selectedRequestId={selectedRequestId}
            setSelectedRequest={setSelectedRequest}
          />
        ))}
      </ul>
    </div>
  );
};

LiveChatConversationList.propTypes = {
  user: PropTypes.object,
  chats: PropTypes.array,
  selectedRequestId: PropTypes.string,
  setSelectedRequest: PropTypes.func
};

LiveChatConversationList.defaultProps = {
  user: {},
  chats: [],
  selectedRequestId: '',
  setSelectedRequest: () => {}
};

export default LiveChatConversationList;
