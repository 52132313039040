import React from 'react';
import { resolveInputType } from './Form.helpers';
import type { FormInputRadioProps } from './FormInputRadio';
import type { FormInputSelectProps } from './FormInputSelect';
import type { FormInputCheckboxProps } from './FormInputCheckbox';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';
import useStyles from './Form.theme';
import { Divider } from '@material-ui/core';

export interface FormInputPropsBase {
  name: string;
  label: string;
  type: string;
  options: FormInputOption[];
  required?: boolean;
  dependencies?: FormDependency[];
  selection?: number | number[];
}

export type FormInputOption = {
  label: string;
  value: string | number | boolean | null | undefined;
  dependencies?: FormDependency[];
};

export type FormDependency = {
  inputId: number;
  valueIs?: (keyof FormInputPropsBase['options'])[];
  and?: Omit<FormDependency, 'and'>[];
};

export type FormInputProps =
  | FormInputRadioProps
  | FormInputSelectProps
  | FormInputCheckboxProps;

export type FormInputInternalProps = {
  inputId: number;
  disabled: boolean;
  error: boolean;
  divider: boolean;
  classes: ClassNameMap<
    'fieldset' | 'divider' | 'label' | 'required' | FormInputProps['type']
  >;
  onChange: (inputId: number, optionId: number | number[]) => void;
  onCheckDependencies?: (dependencies?: FormDependency[]) => boolean;
};

type FormInputHOCProps = FormInputProps &
  Partial<Omit<FormInputInternalProps, 'inputId'>> &
  Required<Pick<FormInputInternalProps, 'inputId'>>;

/**
 * This is a higher-order component that abstracts some of
 * the repeated logic used by the inputs. It also populates
 * default values of some of the optional attributes to
 * simplfiy the JSX signatures of some elements
 * @param {FormInputHOCProps} props
 * @returns {JSX.Element}
 */
function FormInput(props: FormInputHOCProps) {
  const _props = { ...props };

  // Merge in any missing default values to simplify input logic
  _props['label'] ??= '';
  _props['disabled'] ??= false;
  _props['error'] ??= false;
  _props['divider'] ??= true;
  _props['classes'] ??= useStyles();
  _props['onChange'] ??= () => {};
  _props['onCheckDependencies'] ??= () => true;

  const input = resolveInputType(_props as FormInputProps & FormInputInternalProps);

  return (
    <fieldset className={_props.classes.fieldset + ` ${_props['type']}`}>
      {_props.label && (
        <div className={_props.classes.label}>
          {_props.label}
          {_props.required && <i className={_props.classes.required}>{` * `}</i>}
        </div>
      )}
      {_props.divider && <Divider className={_props.classes.divider} />}
      {input}
    </fieldset>
  );
}

export default FormInput;
