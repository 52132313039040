import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _, { cloneDeep } from 'lodash-es';
import moment from 'moment';
import {
  LYFT_RIDES_ON_DEMAND_OPTIONS,
  PREAUTHORIZE_ERROR,
  PREAUTHORIZE_LYFT_RIDES_ON_DEMAND,
  LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL,
  LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR,
  lyftOnDemandWillCall,
  lyftRidesPreAuthorize,
  lyftRidesOnDemandOptions,
  availableCards,
  ridesPreAuthorize,
  reset
} from '~/Modules/scheduleRides';
import { errorParse, merge } from '~/utilities/helperFunctions';
import { mapRideBookingPayload } from '~/utilities/booking.helper';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import LyftOptions from '~/Shared/Components/BookingConfirmation/Components/TransportOptions/LyftOptions';
import Authorization from '~/Shared/Components/BookingConfirmation/Components/Authorization';
import Confirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation';
import { LYFT_VEHICLE_ID, LYFT_ERROR_MESSAGE } from '~/constants';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import { mapFlexRidePayload } from './LyftOnDemand.helpers';

// TODO:UBER — Generalize for all rideshare types
const WILL_CALL_FIELDS = {
  cost: 0,
  eta: 0,
  distance: 0,
  duration: 0,
  lyftRideType: '',
  updatedTime: '',
  selectedDate: '',
  rideTimeUTC: '',
  ride_id: '',
  rideType: 'ondemand'
};

class LyftOnDemand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
      showAuthorization: false,
      showLyftOptions: false,
      showSpinner: true,
      success: false,
      vehicles: {}
    };
    this.willCallFields = {};
    this.convertedRide = {};
  }

  componentDidMount() {
    const { availableCards, rideData } = this.props;
    const requestBody = mapRideBookingPayload(cloneDeep(rideData));
    availableCards({ ...requestBody, rideShareOnDemand: true });
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(this.props, 'type', '') === LYFT_RIDES_ON_DEMAND_OPTIONS &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      const vehicles = _.get(this.props, 'confirmationCardData.rides', []);
      this.setState({
        showConfirmation: false,
        showAuthorization: false,
        showLyftOptions: true,
        showSpinner: false,
        vehicles
      });
    }

    if (
      _.get(this.props, 'type', '') === PREAUTHORIZE_LYFT_RIDES_ON_DEMAND &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      this.setState({
        showConfirmation: false,
        showAuthorization: true,
        showLyftOptions: false,
        showSpinner: false
      });
      this.willCallFields = merge(WILL_CALL_FIELDS, this.props.lyftRideData);
      this.willCallFields.rideShareType = this.props.lyftRideData.rideShareType;
      this.willCallFields = merge(
        WILL_CALL_FIELDS,
        this.props.lyftRideData.submittedData
      );
      this.willCallFields.ride_id = this.props.rideData.id;
      this.willCallFields.updatedTime = `${this.convertedRide.selectedDate} ${this.convertedRide.time}`;
    }

    if (
      _.get(this.props, 'error.type', '') === PREAUTHORIZE_ERROR &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      this.setState({
        showConfirmation: false,
        showAuthorization: false,
        showLyftOptions: true,
        showSpinner: false
      });
    }
    if (
      _.get(this.props, 'error.type', '') ===
        LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL_ERROR &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      this.setState({
        showConfirmation: true,
        showAuthorization: false,
        showLyftOptions: false,
        showSpinner: false,
        success: false
      });
    }
    if (
      _.get(this.props, 'type', '') === LYFT_RIDES_ON_DEMAND_OPTIONS_WILL_CALL &&
      this.props.timestamp !== prevProps.timestamp
    ) {
      if (this.props.data.status === false) {
        this.setState({
          showConfirmation: true,
          showAuthorization: false,
          showLyftOptions: false,
          showSpinner: false,
          success: false
        });
      } else {
        this.setState(
          {
            showConfirmation: false,
            showAuthorization: false,
            showLyftOptions: false,
            showSpinner: false,
            success: true
          },
          () => {
            window.setTimeout(() => {
              this.props.closeModal();
            }, 5000);
          }
        );
      }
    }
  }

  componentWillUnmount() {
    this.props.reset();
  }

  /**
   * click event for choosing lyft vehicle
   * @param {object} lyftVehicleInfo - vehicle info for selected lyft ride
   * @return {undefined}
   */
  chooseLyftVehicleId = lyftVehicleInfo => {
    const submittedData = _.cloneDeep(this.props.lyftRideData.submittedData);
    const payload = {
      ..._.assign(submittedData, lyftVehicleInfo),
      transportTypeName: 'Uber',
      rides: submittedData.rides.map(ride => ({
        ...ride,
        cost: lyftVehicleInfo.cost,
        eta: lyftVehicleInfo.eta,
        duration: lyftVehicleInfo.duration,
        distance: lyftVehicleInfo.distance,
        transportTypeName: 'Uber',
        ...(lyftVehicleInfo.product_id && { product_id: lyftVehicleInfo.product_id }),
        ...(lyftVehicleInfo.fare_id && { fare_id: lyftVehicleInfo.fare_id }),
        ...(lyftVehicleInfo.cost_token && { cost_token: lyftVehicleInfo.cost_token }),
        ...(lyftVehicleInfo.run_id && { run_id: lyftVehicleInfo.run_id })
      }))
    };

    this.setState(
      {
        showConfirmation: false,
        showAuthorization: false,
        showLyftOptions: false,
        showSpinner: true
      },
      () => {
        this.props.ridesPreAuthorize(
          {
            ...payload,
            rideShareOnDemand: true
          },
          true
        );
      }
    );
  };

  /**
   * confirmation handler
   * @return {undefined}
   */
  confirm = () => {
    this.setState({
      showAuthorization: false,
      showConfirmation: true,
      showLyftOptions: false
    });
  };

  /**
   * confirmation handler
   * @return {undefined}
   */
  willCallSubmit = () => {
    const { rideData, lyftRideData } = this.props;
    const { submittedData } = lyftRideData;
    const payload = mapFlexRidePayload({ submittedData, rideData });

    this.setState(
      {
        showConfirmation: false,
        showAuthorization: false,
        showLyftOptions: false,
        showSpinner: true
      },
      () => {
        this.props.lyftOnDemandWillCall(payload);
      }
    );
  };

  /**
   * Take ride data and change it to a format for the booking endpoints to understand
   * @param {object} ride - ride data from scheduled rides api
   * @return {object} - returns object of converted ride data
   */
  convertRideData(ride) {
    const fiveMinutes = moment().add(5, 'minutes').format('h:mm a');
    const isFlexRide = Boolean(ride.lyft_flex_ride || ride.uber_flex_ride);
    const convertRide = {
      hospitalId: ride.hospitalId,
      filterby: 'time',
      confirm_booking: '0',
      filterOrder: 'asc',
      vehicleId: '',
      localDefaultTimezone: '',
      rideType: isFlexRide ? 'flex' : 'ondemand',
      benbotRequestId: 0,
      purpose: '',
      currentMode: '',
      estimatedTimeSavings: '',
      dateOfBirth: _.isNil(ride.dateOfBirth)
        ? ''
        : moment(ride.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      pickupLatitude: ride.fromLatitude,
      pickupLongitude: ride.fromLongitude,
      dropoffLongitude: ride.toLongitude,
      dropoffLatitude: ride.toLatitude,
      patientName: ride.passengerFirstname,
      patientLastName: ride.passengerLastname,
      phoneNo: ride.pmobileNo,
      medicalId: ride.medicalId,
      pickupAddress: ride.toAddress,
      dropoffAddress: ride.fromAddress,
      time: fiveMinutes,
      transport: LYFT_VEHICLE_ID,
      payWithCard: false,
      additionalNotes: ride.additionalNotes,
      selectedDate: moment().format('MM/DD/YYYY'),
      pickupVenueId: ride.pickup_venue_id,
      dropoffVenueId: ride.dropoff_venue_id,
      bookingType: 'ondemand',
      rideCustomFields: {},
      ride_id: ride.id,
      pastRide: false,
      additionalPassengers: ride.additionalPassengers,
      ...(ride && ride.health_plan_id && { healthPlanId: ride.health_plan_id }),
      ...(ride && ride.health_sub_plan_id && { healthSubPlanId: ride.health_sub_plan_id })
    };

    return convertRide;
  }

  generateTitle() {
    if (this.state.showLyftOptions) {
      return 'Ride Share Options';
    }

    if (this.state.showAuthorization || this.state.showConfirmation) {
      return 'Benefits';
    }

    return 'Ride Share Options';
  }

  render() {
    const { vehicles } = this.state;
    const {
      isOpen,
      error,
      auth,
      closeModal,
      lyftRideData,
      trip,
      changeTransport,
      bookingData,
      data
    } = this.props;
    const errorMessage = errorParse(error, LYFT_ERROR_MESSAGE);
    const title = this.generateTitle();
    let errorClass = 'errorList';

    if (errorMessage.length === 1) {
      errorClass = 'noAvailCompanies';
    }
    if (_.get(this.props, 'auth.bookingAlert', '') !== '') {
      errorMessage.push(auth.bookingAlert);
    }
    if (_.get(this.props, 'auth.alert', '') !== '') {
      errorMessage.push(auth.alert);
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={{
          base: 'newModalBaseClass newErrorModal cancelRide lyftOnDemand',
          afterOpen: 'modalBaseClassOpen',
          beforeClose: 'modalBaseClassClose'
        }}
        overlayClassName={{
          base: 'overlayBaseClass',
          afterOpen: 'overlayBaseClassOpen',
          beforeClose: 'overlayBaseClassClose'
        }}
        closeTimeoutMS={500}
      >
        <div className="modalHead">
          <p>{title}</p>
          <a onClick={closeModal} className="closeX">
            <SvgClose />
          </a>
        </div>
        <div className="modalBody clearfix">
          <div className="TransportOptions">
            {this.state.showLyftOptions ? (
              <LyftOptions
                handleVehicleClick={this.chooseLyftVehicleId}
                vehicles={vehicles}
                errorClass={errorClass}
                errorMessage={errorMessage}
                changeTransport={changeTransport}
              />
            ) : null}
            {this.state.showAuthorization ? (
              <Authorization
                confirm={this.confirm}
                ride={lyftRideData}
                auth={auth}
                error={error}
                closeRequestNewRide={closeModal}
                user={this.props.user}
              />
            ) : null}
            {this.state.showConfirmation ? (
              <Confirmation
                ride={lyftRideData}
                trip={trip}
                submitRide={this.willCall}
                error={error}
                bookingData={bookingData}
                data={data}
                closeRequestNewRide={closeModal}
                willCall={true}
                willCallSubmit={this.willCallSubmit}
              />
            ) : null}
            {this.state.showSpinner ? <LoadingComponent /> : null}
            {this.state.success ? (
              <div className="success">
                <p>Your ride has been sent to your ride share provider.</p>
              </div>
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }
}

LyftOnDemand.propTypes = {
  lyftRidesPreAuthorize: PropTypes.func,
  reset: PropTypes.func,
  lyftRidesOnDemandOptions: PropTypes.func,
  lyftOnDemandWillCall: PropTypes.func,
  error: PropTypes.object,
  errorClass: PropTypes.string,
  errorMessage: PropTypes.array,
  vehicles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  changeTransport: PropTypes.func,
  rideData: PropTypes.object,
  lyftRideData: PropTypes.object,
  closeModal: PropTypes.func,
  isOpen: PropTypes.bool,
  auth: PropTypes.object,
  timestamp: PropTypes.number,
  trip: PropTypes.object,
  data: PropTypes.object,
  bookingData: PropTypes.object,
  user: PropTypes.object,
  availableCards: PropTypes.func
};

LyftOnDemand.defaultProps = {
  lyftRidesPreAuthorize: () => {},
  reset: () => {},
  lyftRidesOnDemandOptions: () => {},
  lyftOnDemandWillCall: () => {},
  availableCards: () => {},
  error: {},
  errorClass: '',
  errorMessage: [],
  vehicles: [],
  changeTransport: () => {},
  rideData: {},
  lyftRideData: {},
  closeModal: () => {},
  isOpen: false,
  auth: {},
  timestamp: 0,
  trip: {},
  data: {},
  bookingData: {},
  user: {}
};

const mapStateToProps = state => ({
  // return from request booking
  auth: state.scheduleRides.auth,
  confirmationCardData: state.scheduleRides.confirmationCardData,
  // rideData: state.scheduleRides.ride,
  lyftRideData: state.scheduleRides.ride,
  trip: state.scheduleRides.trip,
  //response for final submission
  data: state.scheduleRides.data,
  //loading modal
  error: state.scheduleRides.errorObj,
  type: state.scheduleRides.type,
  timestamp: state.scheduleRides.timestamp,
  user: state.user
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      lyftRidesPreAuthorize: (data, onDemand) => lyftRidesPreAuthorize(data, onDemand),
      lyftRidesOnDemandOptions: data => lyftRidesOnDemandOptions(data),
      lyftOnDemandWillCall: data => lyftOnDemandWillCall(data),
      reset: () => reset(),
      availableCards: data => availableCards(data),
      ridesPreAuthorize: data => ridesPreAuthorize(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LyftOnDemand);
