/* eslint-disable dot-notation */
import React from 'react';
import type { FormInputInternalProps, FormInputPropsBase } from './FormInput';
import { getTestId } from './Form.helpers';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';

export interface FormInputRadioProps extends FormInputPropsBase {
  readonly type: 'radio';
  selection?: number;
}

const FormInputRadio: React.FC<FormInputRadioProps & FormInputInternalProps> = ({
  inputId,
  type,
  name,
  selection,
  options,
  required,
  error,
  disabled,
  classes,
  onChange,
  onCheckDependencies = () => true
}) => {
  const testId = getTestId({ type, name });
  const activeValue = selection !== undefined ? options[selection]['value'] : '';

  return (
    <FormControl
      variant="outlined"
      required={required}
      disabled={disabled}
      error={error}
      className={classes.radio}
    >
      <RadioGroup row name={name} value={`${activeValue}`}>
        {options.map((opt, optId) =>
          onCheckDependencies(opt.dependencies) ? (
            <FormControlLabel
              key={optId}
              label={opt.label}
              value={`${opt.value}`}
              labelPlacement="end"
              className={error ? 'Mui-error' : ''}
              control={
                <Radio
                  color="primary"
                  size="small"
                  data-testid={`${testId}-${opt.value}`}
                  onChange={() => onChange(inputId, optId)}
                />
              }
            />
          ) : null
        )}
      </RadioGroup>
    </FormControl>
  );
};

export default FormInputRadio;
