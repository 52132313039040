import React from 'react';
import {
  DateInput,
  TextField,
  HospitalDropdown,
  MobilityTypeInput,
  ComplianceFieldInput,
  SubMobilityTypeInput,
  ExpenseStatusDropdown,
  ReimbursementMethodDropdown,
  renderDependentFields,
  ExpenseReportRideDropdown
} from '../Components/Slideout/ExpenseInputs';

import PassengerDropdown from '~/Shared/Components/AdditionalPassengers/PassengerDropdown';

import { mapExpenseReportHealthPlanName } from '../helpers';

const ExpenseReportFields = ({
  mode,
  report,
  setField,
  options,
  user,
  ridesInWindow,
  setRideId
}) => {
  const {
    hospitalData,
    vehicle_types,
    complianceInfo,
    dependentFields,
    expenseSettings
  } = options;

  const {
    status,
    toDate,
    purpose,
    fromDate,
    lastName,
    medicalId,
    firstName,
    hospitalId,
    passengers,
    statusReason,
    mobilityType,
    fundingSource,
    subMobilityType,
    complianceFields,
    reimbursementMethod,
    ride_id: rideId,
    expenses
  } = report;

  try {
    return [
      [
        {
          name: 'ride_id',
          show: true,
          label: 'Ride ID',
          value: [],
          required: false,
          customClass: 'w-50',
          Component: () => (
            <ExpenseReportRideDropdown
              setField={setField}
              ridesInWindow={ridesInWindow}
              setRideId={setRideId}
              rideId={rideId}
            />
          )
        }
      ],
      [
        {
          name: 'status',
          show: mode === 'edit',
          label: 'Expense Report Status',
          value: status,
          required: true,
          Component: () => <ExpenseStatusDropdown status={status} setField={setField} />
        },
        {
          name: 'reimbursementMethod',
          show: status === 'Reimbursed',
          label: 'Reimbursement Method',
          value: reimbursementMethod,
          required: true,
          Component: () => (
            <ReimbursementMethodDropdown
              setField={setField}
              reimbursementMethod={reimbursementMethod}
              reimbursementMethods={expenseSettings.reimbursementMethod}
            />
          )
        }
      ],
      [
        {
          name: 'statusReason',
          show: status === 'Rejected' || status === 'Cancelled',
          label: `${
            status === 'Rejected' ? 'Rejection' : 'Cancellation'
          } Reason (250 Characters max.)`,
          value: statusReason,
          required: true,
          Component: [
            <textarea
              key="statusReason"
              value={statusReason}
              onChange={({ target: { value } }) =>
                setField({ name: 'statusReason', value })
              }
              maxLength="250"
            ></textarea>
          ]
        }
      ],
      [
        {
          name: 'fundingSource',
          show: true,
          label: 'Funding Source',
          value: fundingSource,
          required: false,
          Component: () => (
            <TextField
              name="fundingSource"
              value={mapExpenseReportHealthPlanName(report, options)}
            />
          )
        },
        {
          name: 'medicalId',
          show: true,
          label: 'Member ID',
          value: medicalId,
          required: false,
          Component: () => <TextField name="medicalId" value={medicalId} />
        }
      ],
      [
        {
          name: 'firstName',
          show: true,
          label: 'First Name',
          value: firstName,
          required: false,
          Component: () => <TextField name="firstName" value={firstName} />
        },
        {
          name: 'lastName',
          show: true,
          label: 'Last Name',
          value: lastName,
          required: false,
          Component: () => <TextField name="lastName" value={lastName} />
        }
      ],
      [
        {
          name: 'mobilityType',
          show: true,
          label: 'Mobility Type',
          value: mobilityType,
          required: false,
          Component: () => (
            <MobilityTypeInput
              setField={setField}
              mobilityType={mobilityType}
              vehicle_types={vehicle_types}
            />
          )
        },
        {
          name: 'subMobilityType',
          show: true,
          label: 'Sub Mobility Type',
          value: subMobilityType,
          required: false,
          Component: () => (
            <SubMobilityTypeInput
              setField={setField}
              mobilityType={mobilityType}
              vehicle_types={vehicle_types}
              subMobilityType={subMobilityType}
            />
          )
        }
      ],
      [
        {
          name: 'hospitalId',
          show: hospitalData.length > 0,
          label: 'Hospital',
          value: hospitalId,
          required: false,
          Component: () => (
            <HospitalDropdown
              disabled={hospitalData.length === 1}
              setField={setField}
              hospitalId={hospitalId}
              hospitalData={hospitalData}
            />
          )
        }
      ],
      ...complianceInfo.map(complianceField => {
        const inputs = ComplianceFieldInput({
          setField,
          complianceField,
          selectedValues: complianceFields
        });
        return inputs;
      }),
      renderDependentFields({
        dependentFields,
        selectedValues: complianceFields,
        setField,
        user
      }),
      [
        {
          name: 'passengers',
          show: true,
          label: 'Additional Passengers',
          value: passengers,
          required: false,
          Component: [
            <PassengerDropdown
              key="passengers"
              setField={setField}
              passengers={passengers}
              mobilityType={mobilityType}
              vehicle_types={vehicle_types}
              subMobilityType={subMobilityType}
            />
          ]
        }
      ],
      [
        {
          name: 'purpose',
          show: true,
          label: 'Purpose (250 characters max.)',
          value: purpose,
          required: false,
          Component: [
            <textarea
              key="purpose"
              value={purpose}
              onChange={({ target: { value } }) => setField({ name: 'purpose', value })}
              maxLength="250"
            ></textarea>
          ]
        }
      ],
      [
        {
          name: 'fromDate',
          show: true,
          label: 'From Date',
          value: fromDate,
          required: true,
          Component: () => (
            <DateInput
              name="fromDate"
              maxDate={toDate}
              value={fromDate}
              setField={setField}
              disabled={expenses?.length}
            />
          )
        },
        {
          name: 'toDate',
          show: true,
          label: 'To Date',
          value: toDate,
          required: true,
          Component: () => (
            <DateInput
              name="toDate"
              minDate={fromDate}
              value={toDate}
              setField={setField}
              disabled={expenses?.length}
            />
          )
        }
      ]
    ];
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error: ', error);
    return [];
  }
};

export default ExpenseReportFields;
