import React from 'react';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';
import _ from 'lodash-es';

const RowPagination = props => {
  const pageCount = Math.ceil(props.totalCount / props.rowsReturned);
  const startPoint = props.begin + 1;
  const currentPage = props.begin / props.rowsReturned || 0;
  const endPoint =
    props.totalCount < (currentPage + 1) * props.rowsReturned
      ? props.totalCount
      : (currentPage + 1) * props.rowsReturned;
  const fields = _.clone(props.defaultFields);
  const sendPageReq = page => {
    fields[props.offsetField] = page.selected * props.defaultFields.limit;
    fields.limit = props.defaultFields.limit;
    props.getRows(fields);
  };

  return (
    <div className="pagination">
      <p className="paginationMsg">
        {props.totalCount > 0
          ? `Showing ${startPoint} to ${endPoint} of ${props.totalCount.toLocaleString()} entries`
          : 'Showing 0 entries'}
      </p>
      <ReactPaginate
        pageCount={pageCount}
        pageRangeDisplayed={props.pageRangeDisplayed}
        marginPagesDisplayed={2}
        previousLabel="Previous"
        nextLabel="Next"
        initialPage={currentPage}
        forcePage={currentPage}
        containerClassName="paginationElement"
        onPageChange={sendPageReq}
        disableInitialCallback={true}
      />
    </div>
  );
};

RowPagination.propTypes = {
  getRows: PropTypes.func,
  reduxAction: PropTypes.string,
  defaultFields: PropTypes.object,
  totalCount: PropTypes.number,
  rowsReturned: PropTypes.number,
  begin: PropTypes.number,
  rowsPerPage: PropTypes.number,
  offsetField: PropTypes.string,
  pageRangeDisplayed: PropTypes.number
};

RowPagination.defaultProps = {
  defaultFields: {},
  getRows: () => {},
  reduxAction: 'GET_RIDES',
  totalCount: 0,
  rowsReturned: 0,
  begin: 0,
  rowsPerPage: 0,
  offsetField: 'startLimit',
  pageRangeDisplayed: 5
};

export default RowPagination;
