import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { has, isEqual, camelCase } from 'lodash-es';
import Button from '../Button';
import SvgTrash from '~/Shared/Components/Svgs/SvgTrash';
import SvgPencil from '~/Shared/Components/Svgs/SvgPencil';
import SvgAssign from '~/Shared/Components/Svgs/SvgAssign';
import ClipboardButton from '~/Shared/Components/ClipboardButton';
import ExpensesService from '~/services/expenses.service';
import Paper from '@material-ui/core/Paper';
import { useDispatch, useSelector } from 'react-redux';

import CreateRepeatingExpense from '../RepeatingExpenses/createRepeatingExpense/CreateRepeatingExpenseModal';
import { RepeatingExpenseModal } from '../RepeatingExpenses/EditRepeatingExpenseModal';
import {
  removeExpense,
  setExpenseModal,
  removeRepeatingExpenses
} from '~/Modules/memberExpenses/actions';

import { EditRepeatingExpensesEnum } from '../RepeatingExpenses/constants';

import { convertDate } from '../../helpers';

const { expenseTotals } = new ExpensesService();

const ExpensesList = ({
  report: { expenses = [] },
  hospitalGroupId,
  saveRepeatingExpense,
  disableAddExpense = true
}) => {
  const dispatch = useDispatch();
  const [displayExpenseDropdown, setExpenseDropdown] = useState({
    display: false,
    index: -1
  });
  const [displayRepeatingExpenseModal, setRepeatingExpenseModal] = useState(false);
  const [displayDeleteRepeatingExpense, setDeleteRepeatingExpense] = useState(false);
  const [selectedExpense, setSelectedExpense] = useState(null);
  const { report } = useSelector(state => state.memberExpenses);
  const lastEligibleDate = moment(new Date(convertDate(report.toDate)));

  const showModal = () =>
    dispatch(setExpenseModal({ mode: 'create', show: true, type: 'expense' }));

  const toggleExpense = index => {
    //set expense dropdown
    setExpenseDropdown(s => ({ display: !s.display, index }));
  };

  const showExpense = ({ expense, index, mode }) => {
    if (mode === 'edit') {
      dispatch(
        setExpenseModal({
          mode,
          show: true,
          type: 'expense',
          index,
          expense
        })
      );
    } else {
      setSelectedExpense(expense);
      setRepeatingExpenseModal(s => !s);
    }
  };

  const deleteRepeatingRepeatingExpenses = options => {
    const { expense, index } = selectedExpense;
    if (expense) {
      setRepeatingExpenseModal(false);

      const option = options.find(o => o.checked);
      if (option.id === EditRepeatingExpensesEnum.THIS_EXPENSE) {
        dispatch(removeExpense({ index }));
        setDeleteRepeatingExpense(false);
      }
      if (option.id === EditRepeatingExpensesEnum.THIS_AND_FOLLOWING) {
        const ids = expenses
          .filter(
            e =>
              e.repeatingExpenseId === expense.repeatingExpenseId &&
              moment(e.expenseDate, 'YYYY-MM-DD') >=
                moment(expense.expenseDate, 'YYYY-MM-DD')
          )
          .map(e => e.id);
        dispatch(removeRepeatingExpenses({ ids }));
        setDeleteRepeatingExpense(false);
      }
      if (option.id === EditRepeatingExpensesEnum.ALL_EXPENSES) {
        const ids = expenses
          .filter(e => e.repeatingExpenseId === expense.repeatingExpenseId)
          .map(e => e.id);
        dispatch(removeRepeatingExpenses({ ids }));
        setDeleteRepeatingExpense(false);
      }
    }
    setRepeatingExpenseModal(false);
  };

  const cancelEditModal = () => {
    setDeleteRepeatingExpense(false);
    setRepeatingExpenseModal(false);
  };

  const removeExpenseHandler = ({ index, expense }) => {
    if (!has(expense, 'repeatingExpenseId')) {
      dispatch(removeExpense({ index }));
    } else {
      setDeleteRepeatingExpense(s => !s);
      setSelectedExpense({ expense, index });
    }
  };

  const isRepeatingExpenseDisplay = expense => {
    const repeatingExpenseLength = expenses.filter(
      e => e.repeatingExpenseId === expense.repeatingExpenseId
    ).length;
    return expense && expense.repeatingExpenseId && repeatingExpenseLength > 1;
  };

  const List = ({ expenses }) =>
    expenses.map((expense, i) => {
      const {
        amount,
        paidBy,
        category,
        distance,
        merchant,
        toAddress,
        attachments = [],
        ratePerMile,
        description,
        expenseDate,
        fromAddress,
        reimbursable,
        merchantAddress,
        id,
        status
      } = expense;
      const wrapperRef = useRef(null);

      useEffect(() => {
        document.addEventListener('click', handleClickOutside, false);
        return () => {
          document.removeEventListener('click', handleClickOutside, false);
        };
      }, []);

      const handleClickOutside = event => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
          setExpenseDropdown({ display: false, index: -1 });
        }
      };

      return (
        <tr className="ExpenseListData" key={i}>
          <td>{moment(expenseDate, 'YYYY/MM/DD hh:mm:ss').format('MM/DD/YY')}</td>
          <td>{category}</td>
          <td>
            <div>
              {distance ? <div>Distance: {distance} Miles</div> : null}
              {merchant && !distance ? <div>{merchant}</div> : null}
              <div className="small-grey-text">
                <div>
                  <strong className="black-text">ID:</strong> {id}{' '}
                  <ClipboardButton textToCopy={id} svgclassname="expense-id-clipboard" />
                </div>
                {merchantAddress ? (
                  <div>
                    <strong className="black-text">Merchant Address:</strong>{' '}
                    {merchantAddress}
                  </div>
                ) : null}
              </div>
              <div className="small-grey-text">
                {fromAddress.address ? (
                  <>
                    <div>
                      <strong className="black-text">From:</strong> {fromAddress.address}
                    </div>
                    <div>
                      <strong className="black-text">To:</strong> {toAddress.address}
                    </div>
                  </>
                ) : null}
                <div>{description}</div>
              </div>
            </div>
          </td>
          <td className="tar">
            ${Number(amount).toFixed(2)}
            <div className="small-grey-text">
              {ratePerMile ? <div>${ratePerMile} / Mile </div> : null}
              {!reimbursable ? (
                <>
                  <div>Non-reimbursable</div>
                  <div>Paid by: {paidBy}</div>
                </>
              ) : null}
            </div>
          </td>
          <td className="tar">
            <SvgAssign className="assign-icon" /> X {attachments.length}
          </td>
          <td className={`tar tar-${camelCase(status)}`}>{status}</td>
          <td className="tar exp-actions">
            <div ref={wrapperRef} onClick={() => toggleExpense(i)}>
              <SvgPencil />
              {displayExpenseDropdown.index === i && displayExpenseDropdown.display && (
                <Paper elevation="3" className="tar exp-actions edit">
                  <span
                    className="tar exp-actions edit option"
                    onClick={() => showExpense({ mode: 'edit', expense, index: i })}
                  >
                    Edit Expense
                  </span>
                  {!isRepeatingExpenseDisplay(expense) && (
                    <span
                      className="tar exp-actions edit option"
                      onClick={() =>
                        showExpense({ mode: 'repeating', expense, index: i })
                      }
                    >
                      Repeat Expense
                    </span>
                  )}
                </Paper>
              )}
            </div>

            <div onClick={() => removeExpenseHandler({ index: i, expense })}>
              <SvgTrash />
            </div>
          </td>
        </tr>
      );
    });

  const ListSummary = ({ expenses }) => {
    const { totalSum, nonreimbursable, difference } = new expenseTotals({
      expenses
    });
    return (
      <tr>
        <td></td>
        <td></td>
        <td>
          Expense Amount
          <br /> Non-reimbursable Amount
          <br /> Total Reimbursable Amount
        </td>
        <td className="tar">
          ${totalSum}
          <br />
          $({nonreimbursable})
          <br />${difference}
        </td>
      </tr>
    );
  };

  const Table = ({ expenses }) => (
    <table>
      <thead>
        <tr>
          <th className="date-col">Date</th>
          <th className="category-col">Category</th>
          <th className="exp-col">Expense</th>
          <th className="tar">Amount</th>
          <th className="tar">Attachments</th>
          <th className="tar">Status</th>
          <th className="tar">Action</th>
        </tr>
      </thead>
      <tbody>
        <List expenses={expenses} />
        <ListSummary expenses={expenses} />
      </tbody>
    </table>
  );
  return (
    <div className="ExpensesList">
      <CreateRepeatingExpense
        displayModal={displayRepeatingExpenseModal}
        selectedExpense={selectedExpense}
        saveExpense={saveRepeatingExpense}
        cancelEditModal={cancelEditModal}
        hospitalGroupId={hospitalGroupId}
        lastEligibleDate={lastEligibleDate}
      />
      <RepeatingExpenseModal
        mode="delete"
        title="Delete Repeat Expense"
        displayModal={displayDeleteRepeatingExpense}
        removeExpenses={deleteRepeatingRepeatingExpenses}
        cancelEditModal={cancelEditModal}
      />
      <div className="button-container">
        <Button onClick={showModal} className="grey-btn" disabled={disableAddExpense}>
          + Add Expense
        </Button>
      </div>
      <Table expenses={expenses} />
    </div>
  );
};

const arePropsEqual = (prev, next) => {
  return isEqual(prev.report, next.report);
};

export default React.memo(ExpensesList, arePropsEqual);
