import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import SvgArrow from '~/Shared/Components/Svgs/SvgArrow';
import SvgInsertInvitation from '~/Shared/Components/Svgs/SvgInsertInvitation';
import SvgCheck from '~/Shared/Components/Svgs/SvgCheck';
import Modal from 'react-modal';
import { updateBookingData } from '~/Modules/bookingData';
import Calendar from '~/Shared/Components/Calendar/Calendar';
import { DATE_FORMAT_INPUT } from '~/constants';
import moment from 'moment';
import { addDays } from 'date-fns';
const BASE_SUMMARY_TEXT = [
  'Daily',
  'Weekly on weekdays',
  'Weekly on Monday, Wednesdays, Friday',
  'Weekly on Tuesday, Thursday, Saturday',
  'Weekly',
  'Monthly',
  'Annually'
];

class RepeatAppointments extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.refCheckbox = [];
    this.refSelectedDate = {};
    this.refOccurrences = {};
    this.timePeriodText = 'Weekly';
    this.daysText = moment().format('dddd');
    this.days = [this.daysText];
    this.checkedDays = moment.weekdays().map(day => {
      let checked = false;
      if (day === this.daysText) {
        checked = true;
      }
      const id = `id${day}`;
      return {
        day,
        checked,
        id
      };
    });
    this.state = {
      showCalendar: false,
      selectedDate: this.props.bookingData.pastRide
        ? moment().subtract(1, 'days').format('MM/DD/YYYY')
        : moment().add(30, 'days').format('MM/DD/YYYY'),
      fromMonth: this.props.bookingData.pastRide
        ? addDays(new Date().setHours(0, 0, 0, 0), -this.props.pastBookingDays)
        : addDays(new Date().setHours(0, 0, 0, 0), 0),
      interval: 1,
      dateRepeat: 4,
      repeatApptText: `Weekly on ${this.daysText}, 10 Times`,
      timePeriod: 'weeks',
      occurrences: 10,
      changeDateModal: false,
      changeRepeatModal: false,
      openDayOfWeek: false,
      dateRepeatText: 'Weekly',
      checkedDays: _.clone(this.checkedDays),
      disabledDays: []
    };
  }

  componentDidMount() {
    const { bookingData, pastBookingDays } = this.props;

    if (bookingData.pastRide === true) {
      this.setState({
        disabledDays: [
          {
            before: addDays(new Date().setHours(0, 0, 0, 0), -pastBookingDays),
            after: addDays(new Date().setHours(0, 0, 0, 0), -1)
          }
        ]
      });
    }

    if (
      _.has(bookingData, 'repeatAppointmentData') &&
      !_.isEmpty(bookingData.repeatAppointmentData)
    ) {
      const { repeatAppointmentData } = bookingData;
      this.setState(repeatAppointmentData, this.generateSummary());
    } else {
      this.generateSummary();
    }
  }

  /**
   * update summary based on date updating
   * @param {object} prevProps - previous props
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    if (prevProps.daySelected !== this.props.daySelected) {
      this.daysText = moment(this.props.daySelected).format('dddd');
      this.days = [this.daysText];
      this.generateSummary();
    }
  }

  /**
   * handle repeat modal submission
   * @return {undefined}
   */
  handleSubmit() {
    // e.preventDefault();
    const state = _.clone(this.state);
    const myDays = this.days.map(day => {
      return day.substr(0, 2).toUpperCase();
    });
    const stuffToSubmit = {
      ...state,
      days: myDays,
      begin: moment().format('MM/DD/YYYY')
    };

    this.props.updateBookingData({ repeatAppointmentData: stuffToSubmit });
  }

  /**
   * submit handler for calendar modal
   * @param {object} params - date values
   * @return {undefined}
   */
  handleDayClick = params => {
    this.setState(
      { selectedDate: moment(params.startDate).endOf('Day') },
      this.generateSummary
    );
  };

  /**
   * close calendar
   * @return {undefined}
   */
  closeCalendar = () => {
    this.setState({ showCalendar: false });
  };

  /**
   * open calendar
   * @return {undefined}
   */
  openCalendar = () => {
    this.setState({ showCalendar: true }, this.generateSummary);
  };

  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleChangeDateClick = () => {
    if (!this.state.changeDateModal) {
      document.addEventListener('click', this.handleOutsideChangeDateClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideChangeDateClick, false);
    }

    this.setState(prevState => ({
      changeDateModal: !prevState.changeDateModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {event} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideChangeDateClick = e => {
    if (this.changeDateModal === null) {
      return;
    }
    if (this.changeDateModal.contains(e.target)) {
      return;
    }

    this.handleChangeDateClick();
  };

  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleChangeRepeatClick = () => {
    if (!this.state.changeRepeatModal) {
      document.addEventListener('click', this.handleOutsideChangeRepeatClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideChangeRepeatClick, false);
    }

    this.setState(prevState => ({
      changeRepeatModal: !prevState.changeRepeatModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {event} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideChangeRepeatClick = e => {
    if (this.changeRepeatModal === null) {
      return;
    }
    if (this.changeRepeatModal.contains(e.target)) {
      return;
    }

    this.handleChangeRepeatClick();
  };

  /**
   * update how often rides repeat
   * @param {event} e - synthetic event
   * @return {undefined}
   */
  updateOccurrences = e => {
    this.setState({ occurrences: e.target.value }, this.generateSummary);
  };

  /**
   * changeDateRepeat update how rides repeat
   * @param {event} e - synthetic event
   * @return {undefined}
   */
  changeDateRepeat = e => {
    let timePeriod = '';
    const dateRepeatVal = e.target.id;
    const summaryText = BASE_SUMMARY_TEXT[parseInt(dateRepeatVal, 10)];
    switch (dateRepeatVal) {
      case '0': {
        timePeriod = 'days';
        break;
      }
      case '1': {
        this.days = ['MO', 'TU', 'WE', 'TH', 'FR'];
        // timePeriod = 'weeks';
        break;
      }
      case '2': {
        this.days = ['MO', 'WE', 'FR'];
        // timePeriod = 'weeks';
        break;
      }
      case '3': {
        this.days = ['TU', 'TH', 'SA'];
        // timePeriod = 'weeks';
        break;
      }
      case '4': {
        timePeriod = 'weeks';
        break;
      }
      case '5': {
        timePeriod = 'months';
        break;
      }
      case '6': {
        timePeriod = 'years';
        break;
      }
      default: {
        timePeriod = '';
        break;
      }
    }

    this.timePeriodText = summaryText;
    this.setState(
      { timePeriod: timePeriod, dateRepeat: dateRepeatVal, dateRepeatText: summaryText },
      this.generateSummary
    );
    this.handleChangeDateClick();
  };

  /**
   * handler for modal selecting days
   * @param {event} e - event
   * @return {undefined}
   */
  changeDailyRepeat = e => {
    const updatedCheckedDays = this.state.checkedDays.map(dayObject => {
      if (dayObject.id === e.target.id) {
        return {
          ...dayObject,
          checked: e.target.checked
        };
      }
      return dayObject;
    });
    this.setState({ checkedDays: updatedCheckedDays });
  };

  /**
   * submitting changing modal for update which day ride is repeated
   * @return {undefined}
   */
  submitChangeDailyRepeat = () => {
    this.days = [].filter
      .call(this.refCheckbox, day => {
        return day.checked;
      })
      .map(day => {
        return day.value;
      });
    this.daysText = this.days.join(', ');
    this.checkedDays = _.clone(this.state.checkedDays);
    this.setState({ openDayOfWeek: false }, this.generateSummary);
  };

  /**
   * update interval
   * @param {object} e - event
   * @return {undefined} - returns nothing
   */
  changeInterval = e => {
    this.setState({ interval: e.target.innerHTML }, this.generateSummary);
    this.handleChangeRepeatClick();
  };

  /**
   * open day of week modal
   * @return {undefined}
   */
  openDayOfWeek = () => {
    this.setState({ openDayOfWeek: true });
  };

  /**
   * close day of week modal
   * @return {undefined}
   */
  closeDayOfWeek = () => {
    const checkedDays = _.clone(this.checkedDays);

    this.setState({ openDayOfWeek: false, checkedDays });
  };

  /**
   * generate text for repeat appointments
   * @return {undefined}
   */
  generateSummary() {
    let text = '';
    let activeRadioText = '';
    let intervalText = '';

    if (this.state.timePeriod !== '') {
      if (this.state.interval > 1) {
        intervalText = `Every ${this.state.interval} ${this.state.timePeriod}`;
      } else {
        intervalText = this.timePeriodText;
      }
      if (this.daysText !== '' && this.state.timePeriod === 'weeks') {
        intervalText += ` on ${this.daysText}`;
      }
    } else {
      intervalText = this.timePeriodText;
    }

    activeRadioText = `, until ${moment(this.state.selectedDate, 'MM/DD/YYYY').format('MMMM D, YYYY')}`;

    text = `${intervalText}${activeRadioText}`;
    this.setState({ repeatApptText: text }, this.handleSubmit);
  }

  render() {
    const days = _.range(1, 31);
    const getDayWeek = _.clone(this.state.checkedDays);

    return (
      <div className="repeatAppointments">
        <div className="frequency">
          <div className="frequencyRow clearfix">
            <div className="freqLeftCol">Repeats</div>
            <div className="freqRightCol">
              <div className="selectDateRepeat" onClick={this.handleChangeDateClick}>
                <span className="selectDateRepeatContent">
                  {this.state.dateRepeatText}
                </span>
                <SvgArrow className="whiteDownArrow" />
              </div>
              {this.state.changeDateModal === true ? (
                <div
                  className="changeDateModal"
                  onBlur={this.handleOutsideChangeDateClick}
                  ref={ref => (this.changeDateModal = ref)}
                >
                  <ul>
                    <li>
                      <a id="0" onClick={this.changeDateRepeat}>
                        Daily
                      </a>
                    </li>
                    <li>
                      <a id="4" onClick={this.changeDateRepeat}>
                        Weekly
                      </a>
                    </li>
                    <li>
                      <a id="5" onClick={this.changeDateRepeat}>
                        Monthly
                      </a>
                    </li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={
              this.state.timePeriod !== ''
                ? 'frequencyRow clearfix show'
                : 'frequencyRow clearfix hide'
            }
          >
            <div className="freqLeftCol">Every</div>
            <div className="freqRightCol">
              <div className="selectInterval" onClick={this.handleChangeRepeatClick}>
                <span className="selectIntervalContent">
                  {this.state.interval} {this.state.timePeriod}
                </span>
                <SvgArrow className="whiteDownArrow" />
              </div>
              {this.state.changeRepeatModal === true ? (
                <div
                  className="selectIntervalModal"
                  onBlur={this.handleOutsideChangeRepeatClick}
                  ref={ref => (this.changeRepeatModal = ref)}
                >
                  <ul>
                    {days.map((day, i) => {
                      return (
                        <li key={i}>
                          <a onClick={this.changeInterval}>{day}</a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
          <div
            className={
              this.state.timePeriod === 'weeks'
                ? 'frequencyRow clearfix show'
                : 'frequencyRow clearfix hide'
            }
          >
            <div className="freqLeftCol">On</div>
            <div className="freqRightCol">
              <a className="selectDayOfWeek" onClick={this.openDayOfWeek}>
                {this.daysText}
              </a>
              <Modal
                isOpen={this.state.openDayOfWeek}
                onRequestClose={this.closeDayOfWeek}
                className={{
                  base: 'newModalBaseClass dayOfWeekModal',
                  afterOpen: 'modalBaseClassOpen',
                  beforeClose: 'modalBaseClassClose'
                }}
                overlayClassName={{
                  base: 'overlayBaseClass',
                  afterOpen: 'overlayBaseClassOpen',
                  beforeClose: 'overlayBaseClassClose'
                }}
                closeTimeoutMS={500}
              >
                <div className="heading">Select Days</div>
                {getDayWeek.map((dayObject, i) => {
                  const day = dayObject.day;
                  const checked = dayObject.checked;
                  return (
                    <label htmlFor={`id${day}`} className="dayOfWeek" key={i}>
                      <span className="day">{day}</span>
                      <input
                        type="checkbox"
                        id={dayObject.id}
                        value={day}
                        defaultChecked={checked}
                        ref={checkbox => (this.refCheckbox[i] = checkbox)}
                        onChange={this.changeDailyRepeat}
                        name="repeat_on[]"
                      />
                      {checked === true ? <SvgCheck className="green" /> : null}
                    </label>
                  );
                })}
                <div className="buttons">
                  <button className="cancelButton" onClick={this.closeDayOfWeek}>
                    Cancel
                  </button>
                  <button className="submitButton" onClick={this.submitChangeDailyRepeat}>
                    Continue
                  </button>
                </div>
              </Modal>
            </div>
          </div>
        </div>

        <div className="occurrences">
          <div className="occurrenceRow">
            <div className="occLeftCol">Start</div>
            <div className="occRightCol">
              <input
                type="text"
                className="begin"
                defaultValue={moment(this.props.daySelected, 'MM/DD/YYYY').format(
                  'ddd, MMM D, YYYY'
                )}
                disabled
              />
            </div>
          </div>
          <div className="occurrenceRow">
            <div className="occLeftCol">End</div>
            <div className="occRightCol">
              <div className="radioRow">
                <div className="radioRowForms">
                  <div className="onDateCont">
                    <input
                      type="text"
                      className="onDate"
                      id="onDate"
                      name="onDate"
                      value={moment(this.state.selectedDate, 'MM/DD/YYYY').format(
                        'ddd, MMM D, YYYY'
                      )}
                      onClick={this.openCalendar}
                      ref={input => (this.refSelectedDate = input)}
                      onChange={() => {}}
                    />
                    <SvgArrow className="whiteDownArrow" />
                    <SvgInsertInvitation className="svgCalendar" />
                  </div>
                </div>
              </div>
              <div className="datePickerModal">
                <Calendar
                  closeCalendar={this.closeCalendar}
                  openCalendar={this.state.showCalendar}
                  startDate={moment(this.state.selectedDate, 'MM/DD/YYYY').toDate()}
                  submitModal={this.handleDayClick}
                  disabledDays={this.state.disabledDays}
                  fromMonth={this.state.fromMonth}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

RepeatAppointments.defaultProps = {
  daySelected: moment().format(DATE_FORMAT_INPUT),
  isOpen: false,
  bookingData: {},
  updateBookingData: () => {}
};

RepeatAppointments.propTypes = {
  daySelected: PropTypes.string,
  isOpen: PropTypes.bool,
  bookingData: PropTypes.object,
  updateBookingData: PropTypes.func
};

const mapStateToProps = state => ({
  bookingData: state.bookingData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData: bookRideData => updateBookingData(bookRideData)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(RepeatAppointments);
