const routesMap = {
  '/member-profiles': 'Members',
  '/ride/scheduled': 'Schedule',
  // the different tabs of the Schedule page
  '/ride/active': 'Schedule',
  '/ride/todayswillcalls': 'Schedule',
  '/ride/all': 'Schedule',
  '/ride/ride-share-alerts': 'Schedule',
  '/ride/ride-nemt-alerts': 'Schedule',
  '/ride/reports': 'History',
  '/expenses': 'Expenses',
  '/ride/userprofile': 'Profile',
  '/hospitalnetworks/managers': 'Profile' // still technically the profile page
};

/**
 * Retrieves the human-readable name of a page based on its path (for mixpanel tracking).
 * Only works for the main pages of the app. If the path is not recognized, returns an empty string (falsy).
 */
export const getTrackedPageName = (path): string => {
  for (const route in routesMap) {
    if (path.startsWith(route)) {
      return routesMap[route];
    }
  }

  return '';
};
