import React from 'react';
import { isEqual } from 'lodash-es';
import cx from 'classnames';
import expenseReportFields from '../../models/expenseReportFields.components';

const ExpenseReportForm = ({
  mode,
  report,
  setField,
  options,
  user,
  ridesInWindow,
  setRideId
}) => {
  let gap = null;
  let width = null;
  return (
    <div className="ExpenseReportForm">
      {expenseReportFields({
        mode,
        report,
        setField,
        options,
        user,
        ridesInWindow,
        setRideId
      }).map((rows, j) => {
        const className =
          rows.length < 3 && !rows[0].name.match('compliance') ? 'flex' : '';
        return (
          <div key={j} className={className}>
            {rows.map(({ Component, required, name, label, show, customClass }, i) => {
              if (!show) return null;
              gap =
                rows.length === 2 && i === 0 && !name.match('compliance')
                  ? 'mr-12'
                  : null;
              width = rows.length === 2 && !name.match('compliance') ? '50%' : '100%';
              return (
                <div
                  key={name}
                  className={cx('input-section', {
                    /**
                     * Define a `customClass` in expense report field
                     * definitions in expenseReportFields.components.js.
                     */
                    [customClass]: Boolean(customClass)
                  })}
                  /**
                   * TODO - refactor to use `customClass`. We should
                   * be using `classnames` to exend styling
                   */
                  style={!customClass ? { width } : {}}
                >
                  {label ? (
                    <div>
                      <label>
                        {required ? <span className="fc-red">*</span> : null}
                        {label}
                      </label>
                    </div>
                  ) : null}
                  <div
                    className={cx(gap, {
                      [`mr-12`]: Boolean(customClass)
                    })}
                  >
                    {Array.isArray(Component) ? Component : <Component />}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

const arePropsEqual = (prev, next) => {
  return isEqual(prev.report, next.report);
};

export default React.memo(ExpenseReportForm, arePropsEqual);
