import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    justifyContent: 'space-evenly'
  },
  fieldset: {
    display: 'flex',
    flexFlow: 'column nowrap',
    marginBottom: '8px',
    width: '100%',
    height: '100%'
  },
  label: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    flexBasis: '20px',
    // Not the best way to do this but it beats refactoring
    // the Form engine to use grid
    [theme.breakpoints.down(1555)]: {
      flexBasis: '40px'
    }
  },
  divider: {marginBottom: '8px'},
  select: {
    '& .MuiInputBase-root': {minHeight: '31.5px', fontSize: '14px'}
  },
  radio: {
    display: 'flex',
    '& label': {flex: 1},
    '& .MuiFormGroup-root': {
      '& .MuiFormControlLabel-root': {
        '& .MuiButtonBase-root': {padding: 6},
        '& .MuiTypography-root': {userSelect: 'none', fontSize: '14px'}
      },
      '& .MuiFormControlLabel-root.Mui-error': {
        color: theme.palette.error.main,
        '& .MuiButtonBase-root': {
          padding: 6,
          '& .MuiIconButton-label': {
            '& svg': {color: theme.palette.error.main}
          }
        }
      }
    }
  },
  checkbox: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    '& .MuiFormControlLabel-root': {
      marginRight: '0',
      '& .MuiButtonBase-root': {padding: 6},
      '& .MuiTypography-root': {userSelect: 'none'}
    },
    '& .MuiFormControlLabel-label': {fontSize: '14px'}
  },
  disabled: {},
  required: {color: theme.palette.error.dark}
}));

export default useStyles;
