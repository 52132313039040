import type { AppStore } from '~/types';

/** Identify if the patient's subplan has treatments enabled and
 * return the list of treatments if so. */
export const selectTreatments = (s: AppStore) => {
  const showTreatments = s.patients?.patientDetails?.treatments;

  if (showTreatments) {
    return s.patients.patientDetails?.health_sub_plan_treatments ?? [];
  }

  return undefined;
};
