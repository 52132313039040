import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import {
  ridesPreAuthorize,
  confirm,
  reset,
  ridesPreAuthorizeVehicleMulti,
  PREAUTHORIZE_ERROR,
  AVAILABLE_CARDS_ERROR,
  PREAUTHORIZE_RIDES_VEHICLE_ERROR,
  CONFIRM_ERROR,
  SUBMIT_RIDE_ERROR
} from '~/Modules/scheduleRides';
import { updateBookingData, clearBookingRide } from '~/Modules/bookingData';
import _ from 'lodash-es';
import { updateRide, resetUpdatedCards } from '~/Modules/rideCards/rideCards.actions';
import {
  getSubmittedData,
  getBookingDataFromScheduledRide,
  getTransportationType
} from '~/utilities/editRide.helper';
import {
  mapMultiLegBooking,
  repeatApptBookingHelper,
  showTransportationPage,
  showBenefitsPage,
  showConfirmationPage,
  validateConfirmationData
} from '~/utilities/booking.helper';
import {
  isNetworkRole,
  getHospitalCoords,
  possessive
} from '~/utilities/helperFunctions';
import Authorization from '~/Shared/Components/BookingConfirmation/Components/Authorization';
import Confirmation from '~/Shared/Components/BookingConfirmation/Components/Confirmation';
import TransportOptions from '~/Shared/Components/BookingConfirmation/Components/TransportOptions';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import { Redirect } from 'react-router-dom';
import URL from 'url';

const ALLOWED_STATUS = ['allow', 'alert', 'block', 'stop'];

class ReassignNemt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentStepIndex: 98,
      errorMessage: ''
    };
    this.closeTimeout = null;
  }

  /**
   * lifecyle function triggers preauthorize call and sets booking data for booking confirmation components
   * @return {undefined}
   */
  componentDidMount() {
    const { rideData } = this.props;

    if (!_.isEmpty(rideData)) {
      this.getSubmittedData();
    }
  }

  componentDidUpdate(prevProps) {
    const validConfirmationCardData = validateConfirmationData(
      this.props.confirmationCardData
    );
    const showTransportReducers = [PREAUTHORIZE_ERROR, AVAILABLE_CARDS_ERROR];
    const showConfirmationReducers = [CONFIRM_ERROR, SUBMIT_RIDE_ERROR];

    if (this.props.assignmentSuccess) {
      this.props.closeModal();
    }

    if (
      showTransportationPage(
        this.props,
        prevProps,
        showTransportReducers,
        validConfirmationCardData
      )
    ) {
      this.setState({
        currentStepIndex: 0
      });
    }

    if (
      showBenefitsPage(
        this.props,
        prevProps,
        ALLOWED_STATUS,
        PREAUTHORIZE_RIDES_VEHICLE_ERROR
      )
    ) {
      this.setState({
        currentStepIndex: 1
      });
    }

    if (showConfirmationPage(this.props, prevProps, showConfirmationReducers)) {
      this.setState({
        currentStepIndex: 2
      });
    }
  }

  componentWillUnmount() {
    this.props.reset();
    this.props.clearBookingRide();
    this.props.resetUpdatedCards();
    if (this.closeTimeout !== null) {
      window.clearTimeout(this.closeTimeout);
    }
  }

  /**
   * for handling vehicle selection
   * @param {object} submittedData - data to be submitted
   * @return {undefined}
   */
  chooseVehiclesMultipleRides = submittedData => {
    this.setState(
      {
        currentStepIndex: 98
      },
      () => {
        this.props.ridesPreAuthorizeVehicleMulti(submittedData);
      }
    );
  };

  /**
   * confirmation handler
   * @return {undefined}
   */
  confirm = () => {
    const { ride, confirm } = this.props;

    this.setState(
      {
        currentStepIndex: 98
      },
      () => {
        confirm(ride.submittedData);
      }
    );
  };

  /**
   * adding parameter for sending vehicle owner id
   * @param {object} fields - fields for submission
   * @return {undefined}
   */
  confirmReassignment = fields => {
    const submittedData = getSubmittedData(
      this.props.bookingData,
      this.props.rideData,
      this.submitTimeDataWithEditRide,
      this.props.ride.recurring_rideId
    );

    // append the new NEMT company assigned
    submittedData.companyId = fields.rides[0].companyId;
    submittedData.reassignedToSameNemt = true;

    this.props.updateRide(submittedData);
  };

  /**
   * heading
   * @param {integer} step - step
   * @return {string} returns title
   */
  getHeading(step) {
    const { bookingData } = this.props;
    if (step === 0) {
      return 'Available NEMTs';
    }
    if (step === 1) {
      const patientNamePossessive = possessive(
        bookingData.firstName,
        bookingData.lastName
      );

      return `${patientNamePossessive} Benefits`;
    }
    if (step === 2) {
      return 'Ride Confirmation';
    }
    if (step === 97) {
      return 'Success';
    }
    if (step === 98) {
      return 'Loading';
    }
    if (step === 99) {
      return 'Error';
    }
    return 'Available NEMTs';
  }

  /**
   * format data for preauth submission
   * @param {object} bookingData - booking data
   * @param {string} role - user role
   * @return {object} - return submitted Ride object
   */
  getSubmittedData() {
    const { rideData, user, availableVehicleTypes, hospitalData } = this.props;
    const role = _.get(user.userData, 'role', '');
    const vehicleTypes = getTransportationType(
      role,
      rideData.hospitalId,
      availableVehicleTypes
    );
    const bookingData = getBookingDataFromScheduledRide(rideData, vehicleTypes, true);
    const isRole = isNetworkRole(role);
    bookingData.hospitalCoords = getHospitalCoords(
      rideData.hospitalId,
      hospitalData,
      isRole
    );
    let submittedData = mapMultiLegBooking(bookingData, role);
    const repeatAppointmentData = _.get(bookingData, 'repeatAppointmentData', {});
    let repeatApptParams = {};

    if (!_.isEmpty(repeatAppointmentData)) {
      repeatApptParams = repeatApptBookingHelper(repeatAppointmentData);
    }

    submittedData = _.merge(repeatApptParams, submittedData);
    submittedData.rides = bookingData.rides;
    this.props.updateBookingData(bookingData);
    this.props.ridesPreAuthorize(submittedData);
  }

  render() {
    const heading = this.getHeading(this.state.currentStepIndex);
    const { isOpen, closeModal } = this.props;
    if (this.state.redirect) {
      const redirectURL = URL.parse(this.props.data.redirectUrl);

      return (
        <Redirect
          to={{
            pathname: redirectURL.pathname,
            state: { rideId: this.state.redirectRideId }
          }}
        />
      );
    }

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        className={{
          base: 'newModalBaseClass newErrorModal cancelRide lyftOnDemand',
          afterOpen: 'modalBaseClassOpen',
          beforeClose: 'modalBaseClassClose'
        }}
        overlayClassName={{
          base: 'overlayBaseClass',
          afterOpen: 'overlayBaseClassOpen',
          beforeClose: 'overlayBaseClassClose'
        }}
        closeTimeoutMS={500}
      >
        <div className="modalHead">
          <p>{heading}</p>
          <a onClick={closeModal} className="closeX">
            <SvgClose />
          </a>
        </div>
        <div className="modalBody clearfix">
          <div className="reassignBlock">
            {
              {
                0: (
                  <TransportOptions
                    error={this.props.error}
                    lyft={false}
                    data={this.props.confirmationCardData}
                    bookingData={this.props.bookingData}
                    chooseVehiclesMultipleRides={this.chooseVehiclesMultipleRides}
                  />
                ),
                1: (
                  <Authorization
                    confirm={this.confirm}
                    ride={this.props.ride}
                    auth={this.props.auth}
                    error={this.props.error}
                    closeRequestNewRide={this.props.closeModal}
                    toggleLoading={this.toggleLoading}
                    bookingData={this.props.bookingData}
                    user={this.props.user}
                  />
                ),
                2: (
                  <Confirmation
                    ride={this.props.ride}
                    trip={this.props.trip}
                    submitRide={this.confirmReassignment}
                    error={this.props.error}
                    bookingData={this.props.bookingData}
                    data={this.props.data}
                    closeRequestNewRide={this.props.closeModal}
                  />
                ),
                97: (
                  <div className="reassignStep">
                    <div className="reassignError">
                      <p>Your ride has been scheduled for reassignment.</p>
                      <a onClick={this.props.closeModal}>Close this window</a>
                    </div>
                  </div>
                ),
                98: <LoadingComponent />,
                99: (
                  <div className="reassignStep">
                    <div className="reassignError">
                      {this.state.errorMessage}
                      <a onClick={this.props.closeModal}>Close this window</a>
                    </div>
                  </div>
                )
              }[this.state.currentStepIndex]
            }
          </div>
        </div>
      </Modal>
    );
  }
}

ReassignNemt.propTypes = {
  isOpen: PropTypes.bool,
  rideData: PropTypes.object,
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  toggleLoading: PropTypes.func,
  ridesPreAuthorize: PropTypes.func,
  confirm: PropTypes.func,
  reset: PropTypes.func,
  updateBookingData: PropTypes.func,
  ridesPreAuthorizeVehicleMulti: PropTypes.func,
  availableVehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  user: PropTypes.object,
  confirmationCardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bookingData: PropTypes.object,
  clearBookingRide: PropTypes.func,
  ride: PropTypes.object,
  auth: PropTypes.object,
  trip: PropTypes.object,
  error: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.string
};

ReassignNemt.defaultProps = {
  isOpen: false,
  rideData: {},
  handleSubmit: () => {},
  closeModal: () => {},
  toggleLoading: () => {},
  ridesPreAuthorize: () => {},
  confirm: () => {},
  reset: () => {},
  updateBookingData: () => {},
  ridesPreAuthorizeVehicleMulti: () => {},
  availableVehicleTypes: {},
  hospitalData: {},
  user: {},
  confirmationCardData: [],
  bookingData: {},
  clearBookingRide: () => {},
  ride: {},
  auth: {},
  trip: {},
  error: {},
  data: {},
  type: ''
};

const mapStateToProps = state => ({
  user: state.user,
  bookingData: state.bookingData,
  auth: state.scheduleRides.auth,
  confirmationCardData: state.scheduleRides.confirmationCardData,
  trip: state.scheduleRides.trip,
  ride: state.scheduleRides.ride,
  data: state.scheduleRides.data,
  error: state.scheduleRides.errorObj,
  type: state.scheduleRides.type,
  timestamp: state.scheduleRides.timestamp, // unused value, but needed to tell the component to re-render when this updates
  chats: state.chats,
  availableVehicleTypes: state.user.availableVehicleTypes,
  hospitalData: state.user.hospitalData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ridesPreAuthorize: (data, reassign) => ridesPreAuthorize(data, reassign),
      confirm: data => confirm(data),
      reset: () => reset(),
      updateRide: data => updateRide(data),
      resetUpdatedCards: () => resetUpdatedCards(),
      updateBookingData: data => updateBookingData(data),
      ridesPreAuthorizeVehicleMulti: params => ridesPreAuthorizeVehicleMulti(params),
      clearBookingRide: () => clearBookingRide()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ReassignNemt);
