import { all } from 'redux-saga/effects';
import { AuthSaga } from './user';
import { chatsSaga } from './chats';
import { plansSaga } from './plans';
import { membersSaga } from './members';
import { expenseSaga } from './expenses/saga';
import { settingsSaga } from './settings';
import { rideCardsSaga } from './rideCards/rideCards.reducer';
import { importerJobSaga } from './importerJob';
import { memberProfileSaga } from './memberProfile/saga';
import { memberExpensesSaga } from './memberExpenses/saga';
import { hospitalNetworkSaga } from './hospitalNetwork';
import { importerTemplateSaga } from './importerTemplate';
import { memberProfileUploadSaga } from './memberProfileUploads';

export default function* rootSaga() {
  yield all([
    AuthSaga(),
    chatsSaga(),
    plansSaga(),
    expenseSaga(),
    membersSaga(),
    settingsSaga(),
    rideCardsSaga(),
    importerJobSaga(),
    memberProfileSaga(),
    memberExpensesSaga(),
    hospitalNetworkSaga(),
    importerTemplateSaga(),
    memberProfileUploadSaga()
  ]);
}
