import { createAppAsyncThunk } from '~/Modules';
import { getPatient, type GetPatientRequestBody } from '~/services/patients.service';
import { getSavedAddresses } from '~/services/savedAddress.service';
import { getPassengerLastRide } from '~/services/member.service';
/**
 * dispatch for retrieving patient data during the ride booking process
 * @param {object} params - parameters for getting patient data
 * @return {dispatch} returns axios call which dispatches a reducer
 */
export const getPatientInfoThunk = createAppAsyncThunk(
  'patients/getPatientInfo',
  async (
    params: GetPatientRequestBody & { healthPlanId?: number },
    { rejectWithValue }
  ) =>
    getPatient(params)
      .then(r => ({
        data: r.data,
        params
      }))
      .catch(e => rejectWithValue({ params, error: e.response }))
);

/** Load the saved addresses for a patient. */
export const getSavedAddressesThunk = createAppAsyncThunk(
  'patients/getSavedAddresses',
  async (params: { patientId: number }, { rejectWithValue }) =>
    getSavedAddresses(params.patientId)
      .then(r => ({
        data: r.data,
        status: r.status
      }))
      .catch(e => rejectWithValue({ params, error: e.response }))
);

/** Retrieve the passenger's most recently booked ride. */
export const getLastRideThunk = createAppAsyncThunk(
  'patients/getLastRide',
  async (passengerId: string) => getPassengerLastRide(passengerId)
);
