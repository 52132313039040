import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { has, cloneDeep } from 'lodash-es';
import Button from '../Button';
import Loading from 'react-loading';
import SvgTrash from '~/Shared/Components/Svgs/SvgTrash';
import ReactModal from 'react-modal';
import SvgDownload from '~/Shared/Components/Svgs/SvgDownload';
import ExpenseForm from './ExpenseForm';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExpenseModal,
  uploadAttachment,
  downloadAttachment,
  setCurrentAttachmentUploadState
} from '~/Modules/memberExpenses/actions';
import ExpensesService from '~/services/expenses.service';

import { RepeatingExpenseModal } from '../RepeatingExpenses/EditRepeatingExpenseModal';

const {
  isTravelCategory,
  showAddressFields,
  showMerchantAddress,
  showDistanceMileageFields,
  showNumberOfNightsDropdown,
  showNumberOfDaysDropdown
} = new ExpensesService();

// required React Modal style object
const style = {
  overlay: {
    zIndex: 150
  },
  content: {
    zIndex: 100,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

/* Constructor fn for new expenses.
   Pass in default values if you want. */
const Expense = ({ expenseDate = null, ratePerMile = 0.25, paidBy = '' }) => {
  return {
    paidBy,
    amount: '0.00',
    category: '',
    merchant: 'SafeRide Health',
    distance: 0,
    toAddress: { address: null, lngLat: null },
    fromAddress: { address: null, lngLat: null },
    ratePerMile: !Number(ratePerMile) ? 0.25 : ratePerMile,
    attachments: [],
    description: '',
    expenseDate: expenseDate,
    paidDate: null,
    reimbursable: true,
    merchantAddress:
      '6125 Washington Boulevard, Culver City, California 90232, United States',
    numberOfDays: 1,
    numberOfNights: 1,
    status: 'To Be Reviewed'
  };
};

/**
 * @name ExpenseModal
 * @param {Object} expense Object with all the expense properties.
 * @param {Number} expenseId Pass in exp id and this component will fetch.
 * @param {Function} saveExpense fn to save to desired place.
 *
 */
const ExpenseModal = ({
  report = {},
  saveExpense = () => {},
  saveRepeatingExpense,
  ridesInWindow
}) => {
  const dispatch = useDispatch();
  const [displayEditModal, setEditModal] = useState(false);

  const { options, currentAttachmentUpload, modal } = useSelector(
    ({ memberExpenses }) => memberExpenses
  );
  const { expense, index } = modal;
  const mode = isNaN(index) ? 'create' : 'edit';

  const _newExpense = () =>
    new Expense({
      paidBy: options.expenseSettings.paidBy ? options.expenseSettings.paidBy[0] : '',
      expenseDate: report.fromDate,
      ratePerMile: Number(options.expenseSettings.defaultMileageRate).toFixed(3)
    });

  const [_expense, setExpense] = useState(expense || _newExpense());

  useEffect(() => {
    if (!_expense.attachments) {
      setExpense({ ..._expense, attachments: [] });
    }
  }, []);

  // Listener for success/error of uploading attachments
  useEffect(() => {
    const { status, file } = currentAttachmentUpload;
    if (file && status === 'success') {
      setExpense({ ..._expense, attachments: [file, ..._expense.attachments] });
      dispatch(setCurrentAttachmentUploadState({ file: null, status: null }));
    }
    if (status === 'error') {
      alert(
        'There was an error uploading your file. ' +
          'Please make sure it is less than 10mb in size.'
      );
      setTimeout(() => {
        dispatch(setCurrentAttachmentUploadState({ file: null, status: null }));
      }, 4000);
    }
  }, [currentAttachmentUpload.status]);

  // Must pass validation for the submit button to be enabled.
  const validate = () => {
    const { amount, ratePerMile, category, reimbursable, status } = _expense;
    const required = ['expenseDate', 'category', 'merchant', 'status'];

    try {
      if (showAddressFields({ category })) {
        required.push('fromAddress', 'toAddress');
      }
      if (showDistanceMileageFields({ category })) {
        required.push('distance', 'ratePerMile');
      }
      if (showMerchantAddress({ category })) {
        required.push('merchantAddress');
      }
      if (!reimbursable) {
        required.push('paidBy');
      }
      if (status === 'Cancelled') {
        required.push('cancelledReason');
      }
      if (status === 'Rejected') {
        required.push('rejectedReason');
      }

      if (
        amount > 999999.999 ||
        amount < 0 ||
        (!isTravelCategory({ category }) &&
          showAddressFields({ category }) &&
          ratePerMile < 0) ||
        ratePerMile > 999.999
      ) {
        throw new Error('Max/min price limit reached');
      }

      required.map(field => {
        if (field === 'fromAddress' || field === 'toAddress') {
          if (!_expense[field].address) {
            throw new Error('Missing Field: ', field);
          }
        }
        if (!_expense[field]) throw new Error('Missing Field: ', field);
      });
      return true;
    } catch (error) {
      return false;
    }
  };

  const closeModal = () =>
    dispatch(
      setExpenseModal({
        type: null,
        show: false,
        mode: null,
        index: undefined
      })
    );

  const saveExpenseHandler = async () => {
    if (!validate()) return;
    if (has(expense, 'repeatingExpenseId')) {
      const { length: repeatingExpenseLength } = report.expenses.filter(
        e => e.repeatingExpenseId === expense.repeatingExpenseId
      );
      if (repeatingExpenseLength > 1) {
        setEditModal(s => !s);
      } else {
        saveExpense({
          index,
          expense: formatExpense({ expense: _expense })
        });
        closeModal();
      }
    } else {
      saveExpense({
        index,
        expense: formatExpense({ expense: _expense })
      });
      closeModal();
    }
  };

  const saveRepeatingExpenseHandler = options => {
    saveRepeatingExpense({ options, expense: _expense, oldExpense: expense, index });
    closeModal();
  };

  const closeEditModal = () => {
    setEditModal(false);
  };

  const convertDate = d => {
    try {
      const date = moment(d, 'MM/DD/YYYY').format('YYYY-MM-DD');
      if (isNaN(Date.parse(date))) throw new Error();
      return `${date} 12:00:00`;
    } catch (error) {
      return d;
    }
  };

  const formatExpense = ({ expense }) => {
    const { category } = expense;
    const exp = cloneDeep(expense);
    exp.expenseDate = convertDate(exp.expenseDate);
    exp.paidDate = convertDate(exp.paidDate);

    if (!showMerchantAddress({ category })) {
      exp.merchantAddress = '';
    } else {
      exp.toAddress = { address: null, lngLat: null };
      exp.fromAddress = { address: null, lngLat: null };
    }
    if (!showDistanceMileageFields({ category })) {
      exp.distance = 0;
      exp.ratePerMile = 0;
    }
    if (!exp.amount) exp.amount = '0.00';

    if (!showNumberOfNightsDropdown({ category })) {
      exp.numberOfNights = 0;
    }
    if (!showNumberOfDaysDropdown({ category })) {
      exp.numberOfDays = 0;
    }
    if (!['Cancelled', 'Rejected'].includes(exp.status)) {
      delete exp.cancelledReason;
      delete exp.rejectedReason;
    }

    if (expense.status === 'Rejected') {
      delete exp.cancelledReason;
    }

    if (expense.status === 'Cancelled') {
      delete exp.rejectedReason;
    }

    return exp;
  };

  const deleteAttachment = index => {
    const a = cloneDeep(_expense.attachments);
    a.splice(index, 1);
    setExpense({ ..._expense, attachments: a });
  };

  // Save and Delete buttons
  //
  const ModalButtons = ({ mode }) => {
    return (
      <div className="modal-buttons flex">
        <Button onMouseDown={closeModal} className="grey-btn">
          Cancel
        </Button>
        <div className="spacer-8"></div>
        <Button
          disabled={!validate()}
          onMouseDown={saveExpenseHandler}
          className="green-btn"
        >
          {mode === 'edit' ? 'Save' : 'Add'}
        </Button>
      </div>
    );
  };

  const Divider = () => <div className="divider"></div>;

  const UploadsList = ({ attachments = [] }) => {
    return (
      <div className="UploadList">
        <table>
          <thead>
            <tr>
              <th>File Name (Up to 5 files)</th>
              <th className="tar">Action</th>
            </tr>
          </thead>
          <tbody>
            {currentAttachmentUpload.status === 'running' ? (
              <tr>
                <td>
                  <Loading
                    type="spin"
                    delay={0}
                    color="#aaa"
                    style={{ verticalAlign: 'sub' }}
                    className="loading inline "
                  />
                  <span>Uploading...</span>
                </td>
                <td></td>
              </tr>
            ) : null}

            {attachments.map(({ filename, _id }, i) => {
              return (
                <tr key={i}>
                  <td>{filename}</td>
                  <td className="tar exp-actions">
                    {mode === 'edit' ? (
                      <div
                        onClick={() =>
                          dispatch(downloadAttachment({ id: _id, filename }))
                        }
                      >
                        <SvgDownload />
                      </div>
                    ) : null}
                    &nbsp;&nbsp;
                    <div onClick={() => deleteAttachment(i)}>
                      <SvgTrash />
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        {attachments.length === 0 && currentAttachmentUpload.status !== 'running' ? (
          <div className="ta-center">
            <br />
            <strong>There are no files uploaded yet.</strong>
          </div>
        ) : null}
      </div>
    );
  };

  const UploadsTable = () => {
    return (
      <div className="right-col">
        <div className="modal-buttons flex">
          <Button className="grey-btn">
            <input
              type="file"
              name="file"
              className="file-input-hidden"
              onChange={({ target }) => {
                if (_expense.attachments.length === 5) {
                  alert('5 file limit. You must remove a file to upload another.');
                  return;
                }
                const formData = new FormData();
                if (Number(target.files[0].size / 1024 / 1024).toFixed(2) > 10) {
                  alert('Max file size 10 MB. Please upload smaller file.');
                  return;
                }
                formData.append('file', target.files[0]);

                dispatch(uploadAttachment(formData));
              }}
            />
            + Upload
          </Button>
        </div>
        <UploadsList attachments={_expense.attachments} />
      </div>
    );
  };

  const modalProps = {
    style: style,
    isOpen: true,
    ariaHideApp: false,
    overlayClassName: 'infoOverlay',
    shouldCloseOnOverlayClick: true
  };
  return (
    <ReactModal {...modalProps}>
      <div className="ExpenseModal">
        <div className="modal-header">
          <div>{mode === 'edit' ? 'Edit' : 'New'} Expense</div>
        </div>
        <Divider />
        <RepeatingExpenseModal
          mode="edit"
          title="Edit Repeat Expense"
          displayModal={displayEditModal}
          saveRepeatingExpenseHandler={saveRepeatingExpenseHandler}
          cancelEditModal={closeEditModal}
        />
        <div className="modal-body">
          <div className="left-col">
            <ExpenseForm
              report={report}
              expense={_expense}
              options={options}
              setExpense={setExpense}
              mode={mode}
              ridesInWindow={ridesInWindow}
            />
          </div>
          <UploadsTable />
        </div>
        <Divider />
        <ModalButtons mode={mode} />
      </div>
    </ReactModal>
  );
};

const arePropsEqual = (prevProps, nextProps) => {
  return cloneDeep(prevProps) !== cloneDeep(nextProps);
};

export default React.memo(ExpenseModal, arePropsEqual);
