import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { getHospitalCoords, isNetworkRole } from '~/utilities/helperFunctions';

/** Select the hospital coordinates based on the user's role and the hospital ID. */
export const selectHospitalCoords = createSelector(
  [
    (state: AppStore) => state.user.userData.role,
    (state: AppStore) => state.rideBooking.fundingSource.facilityId,
    (state: AppStore) => state.user.hospitalData
  ],
  (role, hospitalId, hospitalData) => {
    const isHospitalNetworkRole = isNetworkRole(role);
    return getHospitalCoords(hospitalId, hospitalData, isHospitalNetworkRole);
  }
);
