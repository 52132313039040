import React, { useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import moment from 'moment-timezone';
import ReactStars from 'react-stars';
import SvgDownload from '../svgs/SvgDownload';
import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';
import SubmitBenefitCreditModal from '../SubmitBenefitCreditModal/SubmitBenefitCreditModal';
import { useSelector } from 'react-redux';
import { TERMINAL_RIDE_STATUSES, ADJUSTMENT_USER_ROLES } from '~/constants';
import useModal from '~/hooks/useModal';

const RideActivity = props => {
  const { rideData, activities, downloadUrl, showColumnHeader, smsActivities } = props;
  const hasCancelled =
    ['Cancelled', 'DriverCancelled', 'Rejected'].indexOf(rideData.status) > -1;
  const cancelledByUser = rideData.cancelled_by_user || {};
  const passengerTimezone = rideData.passenger.timezone_format || '';
  const cancelledDate = moment.utc(rideData.cancelledAt, 'YYYY-MM-DD HH:mm:ss');
  const feedback = rideData.feedback || {};
  const customFields = rideData.customFields || {};
  const simplifiedCustomFields = [];
  const [benefitCreditModalOpen, setBenefitCreditModalOpen] = useState(false);
  const userRole = useSelector(state => state.user.userData.role);
  const rideTotalBenefitCost = useSelector(
    state => state.rideDetails.ride.data.billingData.totalBenefitCost
  );
  const [, Modal] = useModal();

  const canSubmitAdjustment = () => {
    const isRoleValid = ADJUSTMENT_USER_ROLES.includes(userRole);
    const isRideStatusValid = TERMINAL_RIDE_STATUSES.includes(rideData.status);
    return isRoleValid && isRideStatusValid;
  };

  const toggleBenefitCreditModal = () => {
    if (rideTotalBenefitCost === 0) {
      Modal.set({
        isOpen: true,
        title: 'Operation not permitted.',
        message: 'This ride does not affect the benefit utilization of the member.',
        cancelLabel: 'Cancel',
        onCancel: () => {}
      });
    } else {
      setBenefitCreditModalOpen(current => !current);
    }
  };

  // reduce custom fields to a simplified array
  Object.keys(customFields).map(key => {
    // only add if there is a non-null value
    if (customFields[key].value !== null) {
      simplifiedCustomFields.push({
        label: _.get(customFields[key], 'field.field_label', ''),
        value: _.get(customFields[key], 'value.field_value', '')
      });
    }
  });

  return (
    <div className="RideActivity">
      {showColumnHeader ? (
        <div className="rideActivityHeader">
          <h2>Ride Report</h2>
        </div>
      ) : null}
      <div className="innerRideActivity">
        {hasCancelled && cancelledByUser ? (
          <div className="cancelledActivity">
            <h4>Cancellation Details</h4>
            <ul>
              <li>
                <p className="left">Cancelled By</p>
                <p className="right">
                  {cancelledByUser.firstName} {cancelledByUser.lastName}
                </p>
              </li>
              <li>
                <p className="left">Organization</p>
                <p className="right">{cancelledByUser.organizationName}</p>
              </li>
              <li>
                <p className="left">Cancelled At</p>
                <p className="right">
                  {cancelledDate.tz(passengerTimezone).format('MM-DD-YYYY h:mm A z')}
                </p>
              </li>
              <li>
                <p className="left">Reason</p>
                <p className="right">
                  {rideData.reject_reason} {rideData.reject_reason_msg}
                </p>
              </li>
            </ul>
          </div>
        ) : null}
        {activities.length > 0 ? (
          <div className="activityLog">
            <h4>Activity</h4>
            <ul>
              {activities.map((activity, idx) => {
                return (
                  <>
                    <li key={idx}>
                      <p className="">{activity.activityTime}</p>
                      <br />
                      <p className="">
                        <span>
                          {activity.userName} {activity.message}
                        </span>
                      </p>
                    </li>
                    <div className="divider"></div>
                  </>
                );
              })}
            </ul>
          </div>
        ) : null}
        {smsActivities.length > 0 ? (
          <>
            <div className="smsActivities">
              <h4>SMS Activities</h4>
              <ul>
                {smsActivities.map((smsActivity, key) => {
                  const formattedTimestamp = moment(smsActivity.created_at)
                    .tz(passengerTimezone || 'UTC')
                    .format('M-D h:mm a z');
                  const textTime = formattedTimestamp ? `at ${formattedTimestamp}` : '';
                  return (
                    <>
                      <li key={key}>
                        <span>
                          Text Message sent to {smsActivity.smsTo} from{' '}
                          {smsActivity.smsFrom} {textTime}
                        </span>
                        <br />
                        <br />
                        <span>Message: {smsActivity.smsBody}</span>
                        <br />
                        <br />
                        <span>Status: {_.get(smsActivity, 'smsStatus', 'unknown')}</span>
                      </li>
                      <div className="divider"></div>
                    </>
                  );
                })}
              </ul>
            </div>
          </>
        ) : null}
        {!_.isEmpty(feedback) ? (
          <div className="feedback">
            <h4>Feedback</h4>
            <ul>
              <li>
                <p className="left">Rating</p>
                <p className="right">
                  <ReactStars
                    count={5}
                    color1={'gray'}
                    color2={'#FF931E'}
                    edit={false}
                    className="stars"
                    value={feedback.rating}
                  />
                </p>
              </li>
              <li>
                <p className="left">Extra Notes</p>
                <p className="right">{feedback.comments ? feedback.comments : 'None'}</p>
              </li>
              {rideData.patientSignature ? (
                <li>
                  <p className="left"></p>
                  <p className="right patientSignature">
                    <img src={rideData.patentSignature} />
                  </p>
                </li>
              ) : null}
            </ul>
          </div>
        ) : null}
        {rideData.driverSignature ? (
          <div className="driverSignature">
            <img src={rideData.driverSignature} />
          </div>
        ) : null}
        {simplifiedCustomFields.length > 0 ? (
          <div className="customFields">
            <h4>Extra Details</h4>
            <ul>
              {simplifiedCustomFields.map((field, idx) => {
                return (
                  <li key={idx}>
                    <p className="left">{field.label}</p>
                    <p className="right">{field.value}</p>
                  </li>
                );
              })}
            </ul>
          </div>
        ) : null}
      </div>
      {canSubmitAdjustment() && (
        <Button className="submitBenefit" onClick={toggleBenefitCreditModal}>
          <span>Submit Benefit Credit</span>
          <PublishIcon />
        </Button>
      )}
      {rideData.mode !== 'Public' ? (
        <div className="downloadNow">
          <a href={downloadUrl}>
            <span>Download Now</span>
            <SvgDownload />
          </a>
        </div>
      ) : null}
      <SubmitBenefitCreditModal
        isOpen={benefitCreditModalOpen}
        toggleOpen={toggleBenefitCreditModal}
      ></SubmitBenefitCreditModal>
    </div>
  );
};

RideActivity.propTypes = {
  rideData: PropTypes.object,
  activities: PropTypes.array,
  smsActivities: PropTypes.array,
  downloadUrl: PropTypes.string,
  showColumnHeader: PropTypes.bool
};

RideActivity.defaultProps = {
  rideData: {},
  activities: [],
  smsActivities: [],
  downloadUrl: '',
  showColumnHeader: false
};

export default RideActivity;
