import React, { useState, useEffect, useRef } from 'react';
import { isEqual } from 'lodash-es';
import PassengerForm from './PassengerForm';
import { PassengerSummary } from './PassengerSummary';

const AMB_ID = 5;

const RBFPassengerDropdown = ({
  isOpen = false,
  disabled = false,
  editMode = false,
  setField = () => {},
  passengers = [],
  // mobilityType = null,
  vehicle_types = []
}) => {
  // Passenger object constructor.
  const Passenger = function (mobilityType, subMobilityType = null) {
    return {
      ageGroup: 'adult',
      lastName: '',
      firstName: '',
      isAttendant: false,
      mobilityType,
      subMobilityType
    };
  };

  const [allPassengers, setPassengers] = useState(passengers);
  const [showDropdown, setShowDropdown] = useState(isOpen);
  const myRef = useRef();

  const passengerCount = allPassengers.length;

  const handleClickOutside = e => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  /**
   * Handles the click event to add a new passenger.
   * Also after a 150ms timeout, set the scroll position of
   * the elements with 'showRideBookingForm' and 'passenger-dropdown'
   * to their maximum scroll height
   * @returns {null} if passengerCount equals 5 or if in editMode.
   */
  const handleAddPassengerClick = () => {
    if (passengerCount === 5 || editMode) return null;

    setPassengers([...allPassengers, new Passenger(AMB_ID)]);
    setTimeout(() => {
      ['showRideBookingForm', 'passenger-dropdown'].map(e => {
        const elem = document.getElementsByClassName(e)[0];
        elem.scrollTop = elem.scrollHeight;
      });
    }, 150);
  };

  const getDropdownHeight = () => (allPassengers.length > 0 ? '300px' : '15px');

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });

  useEffect(() => {
    setField({ name: 'additionalPassengers', value: allPassengers });
  }, [allPassengers]);

  useEffect(() => {
    if (!isEqual(allPassengers, passengers)) {
      setField({ name: 'additionalPassengers', value: passengers });
      // setPassengers(passengers);
    }
  }, [passengers]);

  useEffect(() => setShowDropdown(false), [isOpen]);

  return (
    <div className="AdditionalPassengers PassengerDropdown" key="passengers">
      <div
        className={`passenger-input ${disabled ? 'bg-disabled pe-none' : ''}`}
        onClick={() => {
          setShowDropdown(!showDropdown);
          setTimeout(() => {
            ['showRideBookingForm'].map(e => {
              const elem = document.getElementsByClassName(e)[0];
              elem.scrollTop = 300;
            });
          }, 150);
        }}
      >
        <PassengerSummary passengers={allPassengers} disabled={editMode} />
      </div>
      {showDropdown ? (
        <div
          className="passenger-dropdown single-form-container"
          style={{ maxHeight: getDropdownHeight() }}
          ref={myRef}
          onClick={handleClickOutside}
        >
          {passengerCount > 0
            ? allPassengers.map((x, i) => (
              <div key={i}>
                <PassengerForm
                  index={i}
                  tooltipText={
                    'You cannot modify the name or mobility type of existing' +
                      ' passengers nor add new passengers to this ride. To modify' +
                      ' or add new passengers, please cancel this ride and book a new one'
                  }
                  disabled={editMode}
                  formType="single-row"
                  passengers={allPassengers}
                  setPassengers={setPassengers}
                  vehicle_types={vehicle_types}
                />
              </div>
            ))
            : null}
          <div>
            <div
              onClick={handleAddPassengerClick}
              id="AddPassengers"
              className="fc-blue cursor underline"
            >
              {passengerCount < 5 && !editMode ? '+ Add Passenger' : ''}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

const arePropsEqual = (prev, next) => {
  return isEqual(prev.passengers, next.passengers);
};
export default React.memo(RBFPassengerDropdown, arePropsEqual);
