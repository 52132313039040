import React, { Component } from 'react';
import _ from 'lodash-es';
import ReactLoading from 'react-loading';

class ScrollContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bottomActive: true,
      topActive: false
    };
  }

  componentDidUpdate(props) {
    if (!_.isEqual(props.children, this.props.children)) {
      //New contents, reset trigger
      this.setState({
        bottomActive: true,
        topActive: false
      });
    }
  }

  listenScrollEvent(e) {
    let threshold = 40;
    if (typeof this.props.threshold !== 'undefined') {
      threshold = this.props.threshold;
    }
    const scrollBottom = e.target.scrollTop + e.target.clientHeight;
    const targetBottom = e.target.scrollHeight - threshold;
    const targetTop = threshold;

    //Check for scroll bottom
    if (this.state.bottomActive === true) {
      if (scrollBottom > targetBottom && typeof this.props.onBottom !== 'undefined') {
        this.setState(
          {
            bottomActive: false
          },
          () => {
            if (this.props.endOfList === false) {
              _.debounce(this.props.onBottom, 150)();
            }
          }
        );
      }
    }

    //Check for scroll top
    if (this.state.topActive === true && typeof this.props.onTop !== 'undefined') {
      if (e.target.scrollTop < targetTop) {
        this.setState(
          {
            topActive: false
          },
          _.debounce(this.props.onTop, 150)
        );
      }
    }

    //Reset scroll top if scrolled away from top
    if (this.state.topActive === false && e.target.scrollTop > targetTop) {
      this.setState({
        topActive: true
      });
    }

    //Reset scroll bottom if s crolled away from bottom
    if (this.state.bottomActive === false && e.target.scrollBottom < targetBottom) {
      this.setState({
        bottomActive: true
      });
    }
  }

  render() {
    return (
      <div className={this.props.className} onScroll={this.listenScrollEvent.bind(this)}>
        {this.props.children}
        {this.props.endOfList === true ? null : (
          <ReactLoading type="spin" className="tabLoading" />
        )}
      </div>
    );
  }
}
export default ScrollContainer;
