import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { getClassName } from '~/utilities/helperFunctions';
import ReactLoading from 'react-loading';
import SvgArrowBack from '~/Shared/Components/Svgs/SvgArrowBack';
import SvgArrowForward from '~/Shared/Components/Svgs/SvgArrowForward';
import SvgLogoLarge from '~/Shared/Components/Svgs/SvgLogoLarge';
import SvgUpload from '~/Shared/Components/Svgs/SvgUpload';

class PlanLocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      pageSize: 100,
      count: 0,
      totalPages: 1
    };
  }

  componentDidMount() {
    this.setInitialState();

    // load locations if selected plan available (i.e. from another tab)
    const id = _.get(this.props, 'selectedPlan.id', 0);
    if (id > 0) {
      this.props.getLocationsByPage({ id, page: this.state.page });
    }
  }

  componentDidUpdate(prevProps) {
    const count = _.get(
      this.props,
      'selectedPlan.health_sub_plan_location.meta.totalCount',
      0
    );
    const prevCount = _.get(
      prevProps,
      'selectedPlan.health_sub_plan_location.meta.totalCount',
      0
    );

    if (count !== prevCount) {
      this.setInitialState();
    }

    // check if loaded tab directly and load first page of treatments
    const planId = _.get(this.props, 'selectedPlan.id', 0);
    const prevPlanId = _.get(prevProps, 'selectedPlan.id', 0);
    if (planId !== prevPlanId) {
      this.props.getLocationsByPage({
        id: planId,
        page: 1
      });
    }
  }

  /**
   * Handle prev page request
   * @return {undefined}
   */
  handlePrevPage() {
    if (this.state.page > 1) {
      this.setState({ page: this.state.page - 1 }, () => {
        this.props.getLocationsByPage({
          id: this.props.selectedPlan.id,
          page: this.state.page
        });
      });
    }
  }

  /**
   * Handle next page request
   * @return {undefined}
   */
  handleNextPage() {
    if (this.state.page < this.state.totalPages) {
      this.setState({ page: this.state.page + 1 }, () => {
        this.props.getLocationsByPage({
          id: this.props.selectedPlan.id,
          page: this.state.page
        });
      });
    }
  }

  /**
   * Set initial state
   * @returns {undefined}
   */
  setInitialState() {
    const count = _.get(
      this.props,
      'selectedPlan.health_sub_plan_location.meta.totalCount',
      0
    );
    const totalPages = Math.ceil(count / this.state.pageSize);
    this.setState({ count, totalPages });
  }

  /**
   * Get pagination counters
   * @returns {Object} Returns counterStart, counterEnd
   */
  getPaginationCounters() {
    let counterStart = 0;
    let counterEnd = 0;

    if (this.state.count > 0) {
      counterStart = this.state.page * this.state.pageSize - (this.state.pageSize - 1);
      counterEnd = this.state.page * this.state.pageSize;

      // if the last page is visible, adjust the end counter
      if (this.state.page === this.state.totalPages) {
        if (this.state.page === 1) {
          counterEnd = this.state.count;
        } else {
          const prevCounterEnd = (this.state.page - 1) * this.state.pageSize;
          counterEnd = prevCounterEnd + (this.state.count % prevCounterEnd);
        }
      }
    }

    return { counterStart, counterEnd };
  }

  render() {
    const { counterStart, counterEnd } = this.getPaginationCounters();
    return (
      <div className="PlanLocations">
        {!this.props.selectedPlan.health_sub_plan_location ? (
          <ReactLoading type="spin" className="tabLoading" />
        ) : (
          <div className="innerTabWrapper">
            {this.state.count === 0 ? (
              <div className="import" onClick={() => this.props.gotoImport()}>
                <SvgLogoLarge className="logo" />
                {this.props.isSuperAdmin ? (
                  <div>
                    <span>Import Locations</span>
                    <SvgUpload />
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <table>
                  <thead>
                    <tr>
                      <th>Location Name</th>
                      <th>Address</th>
                      <th>Address 2</th>
                      <th>Description</th>
                      <th>NPI</th>
                      <th>
                        {this.props.isSuperAdmin && this.state.count > 0 ? (
                          <button
                            className="import"
                            onClick={() => this.props.gotoImport()}
                          >
                            Import Locations
                          </button>
                        ) : null}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.selectedPlan.health_sub_plan_location.rows.map(
                      location => {
                        return (
                          <tr key={location.id}>
                            <td>{location.name}</td>
                            <td>{location.address}</td>
                            <td>{location.address2}</td>
                            <td>{location.description}</td>
                            <td>{location.code}</td>
                            <td></td>
                          </tr>
                        );
                      }
                    )}
                  </tbody>
                </table>
                <div className="paginationRoot">
                  <span className="counter">
                    {counterStart}-{counterEnd} of {this.state.count.toLocaleString('en')}
                  </span>
                  <span className="paginationButtonContainer">
                    <button
                      className={getClassName({
                        prevPage: true,
                        disabled: this.state.page === 1
                      })}
                      onClick={() => this.handlePrevPage()}
                    >
                      <SvgArrowBack />
                    </button>
                    <button
                      className={getClassName({
                        nextPage: true,
                        disabled: this.state.page === this.state.totalPages
                      })}
                      onClick={() => this.handleNextPage()}
                    >
                      <SvgArrowForward />
                    </button>
                  </span>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    );
  }
}

PlanLocations.propTypes = {
  selectedPlan: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  getLocationsByPage: PropTypes.func,
  gotoImport: PropTypes.func
};

PlanLocations.defaultProps = {
  selectedPlan: {},
  isSuperAdmin: false,
  getLocationsByPage: () => {},
  gotoImport: () => {}
};

export default PlanLocations;
