import React, {useEffect, useState} from 'react';

export type TextAreaFieldProps = {
  fieldName: string;
  value: string;
  onChangeCallback: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;

  label?: string;
  placeholder?: string;
  customClassName?: string;
  black?: boolean; // if this is true, dropDownField will be black instead of white
  error?: boolean;
  required?: boolean;
  disabled?: boolean;
  errorText?: string;
  showLabel?: boolean;
};

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  fieldName,
  value = '',
  onChangeCallback,
  label = '',
  placeholder = '',
  customClassName = '',
  black = false,
  error = false,
  required = false,
  disabled = false,
  errorText = '',
  showLabel = false
}) => {
  const [focus, setFocus] = useState(false);

  /**
   * Determine the component class name starting
   * with a base and evaluating the relevant properties
   * @returns
   */
  function getCustomClassName() {
    let _customClassName = 'CustomInputText textarea';

    if (black) _customClassName += ' black';

    if (customClassName) _customClassName += ` ${customClassName}`;

    if (error) _customClassName += ' error';

    return _customClassName;
  }

  useEffect(() => {
    if (value) setFocus(true);
  }, [value]);

  return (
    <div className={getCustomClassName()}>
      {focus || showLabel ? (
        <label htmlFor={fieldName} className="inputLabel">
          {required ? <span className="showAsterisk">*</span> : null}
          {label}
        </label>
      ) : null}

      <textarea
        name={fieldName}
        id={fieldName}
        value={value}
        disabled={disabled}
        placeholder={focus ? '' : placeholder}
        onChange={onChangeCallback}
        onFocus={() => setFocus(true)}
        onBlur={(e: React.FocusEvent<HTMLTextAreaElement>) =>
          setFocus(!e.target.value.length)
        }
      />

      {errorText && error ? <p className="errorText">{errorText}</p> : null}
    </div>
  );
};

export default TextAreaField;
