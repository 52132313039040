import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import _ from 'lodash-es';
import {
  cancelRide,
  resetCards,
  cancelRideCost,
  resetUpdatedCards
} from '~/Modules/rideCards/rideCards.actions';
import { statusNotifications, isPastPublicRide } from '~/utilities/helperFunctions';
import RepeatAppointments from '~/Shared/Components/EditRide/Components/RepeatAppointments';
import DropDown from '~/Shared/Components/DropDown';
import CheckBox from '~/Shared/Components/CheckBox';
import SvgRadioSelected from '~/Shared/Components/Svgs/SvgRadioSelected';
import SvgRadioUnselected from '~/Shared/Components/Svgs/SvgRadioUnselected';
import { LYFT_VEHICLE_ID } from '~/constants';
const PLACEHOLDER = 'Select a cancellation reason';

class CancelRide extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpenCancelRepeatAppt: false,
      roundTrip: false,
      cancellationReason: PLACEHOLDER,
      cancellationId: '',
      showStatus: false,
      dropDownError: false,
      errorMessage: '',
      checked: false,
      multiSelected: '',
      hasCompleted: false
    };
    this.hasSecondRideCost = false;
    this.cancelCount = 0;
    this.rides = [];
    this.ridesCost = [];
    this.cancelCount = 0;
  }

  componentDidMount() {
    const { rideData, cancelRideCost } = this.props;
    const rideType = rideData.modelName === 'Lyft' ? 'lyft' : 'rides';
    const tripId = _.get(rideData, 'trip_id', 0);
    const tripData = this.isRoundTrip(rideData);
    let hasCompleted = false;
    this.ridesCost.push(rideData.id);

    if (tripId > 0) {
      // treat as a round trip ride
      const tripRides = rideData.tripRides;

      for (let i = 0; i < tripRides.length; i++) {
        if (
          tripRides[i].status === 'Completed' &&
          !isPastPublicRide({ ride: rideData })
        ) {
          hasCompleted = true;
        } else {
          this.rides.push(tripRides[i].id);
        }
      }
    }
    this.setState({ hasCompleted }, () => {
      cancelRideCost({ rideId: rideData.id, rideType: rideType, leg: tripData.leg });
    });
  }

  /**
   * @param {object} prevProps - prevProps
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    if (
      _.has(this.props, 'error.errorStatus') &&
      this.props.error.timestampMicro !== prevProps.error.timestampMicro
    ) {
      const errorText = `Error ${this.props.error.errorStatus}: ${this.props.error.errorMessage}`;
      this.setState({
        showStatus: false,
        errorMessage: errorText
      });
    }
    if (
      _.get(this.props, 'cancelRideData.status', '') === true &&
      this.props.timestampMicro !== prevProps.timestampMicro
    ) {
      this.cancelCount++;

      const tripType = this.isRoundTrip(this.props.rideData);

      // call handleSubmit if not a round trip (cancel 1 ride) or if cancel count is 2
      if (!tripType.roundTrip || this.cancelCount === 2) {
        statusNotifications('Ride has been cancelled', 'success', 2000);
        this.props.handleSubmit();
        this.props.toggleLoading(false);
      }

      this.setState({
        showStatus: false
      });
    } else if (
      _.get(this.props, 'cancelRideData.status', '') === false &&
      this.props.timestampMicro !== prevProps.timestampMicro
    ) {
      this.setState({
        showStatus: false,
        errorMessage: this.props.updateRideData.message
      });
      this.props.toggleLoading(false);
    }
    if (
      _.has(this.props, 'error.errorMessage') &&
      this.props.timestampMicro !== prevProps.timestampMicro
    ) {
      this.setState({
        showStatus: false,
        errorMessage: this.props.error.errorMessage
      });
      this.props.toggleLoading(false);
    }
  }

  /**
   * opens modal for repeated appointments cancellation
   * @return {undefined} - returns nothing
   */
  openCancelRepeatAppt = () => {
    this.setState({
      isOpenCancelRepeatAppt: true
    });
  };

  /**
   * opens modal for repeated appointments cancellation
   * @param {string} ids - are we cancelling one or all rides in a repeat appointment series?
   * @return {undefined} - returns nothing
   */
  submitCancelRepeatAppt = ids => {
    if (ids === 'all') {
      this.paramsCancel.rideApplyOnAll = 1;
    }
    this.paramsCancel.roundTrip = this.state.roundTrip;
    this.setState({ showStatus: true });
    this.cancel(this.paramsCancel);
    this.closeCancelRepeatAppt();
    this.props.toggleLoading(true, 'Cancelling Rides...', 20);
  };

  /**
   * closes repeat appt modal
   * @return {undefined} - returns nothing
   */
  closeCancelRepeatAppt = () => {
    this.setState({
      isOpenCancelRepeatAppt: false,
      showStatus: false
    });
  };

  /**
   * toggle round trip flag
   * @return {undefined}
   */
  rideTypeChange = () => {
    this.setState(prevState => ({ roundTrip: !prevState.roundTrip }));
  };

  /**
   * submit
   * @param {object} e - event
   * @return {undefined} - returns nothing
   */
  handleSubmit = e => {
    e.preventDefault();
    const valid = this.checkFormsValid();
    if (valid === false) {
      return false;
    }
    this.setState({ showStatus: true });

    // const cancelReason = e.target.querySelector('select[name=cancelReason]').value;
    const cancelReason = this.state.cancellationId;
    const comments = e.target.querySelector('textarea[name=comments]').value;
    const recurringId = _.isNil(this.props.rideData.recurring_rideId)
      ? 0
      : this.props.rideData.recurring_rideId;

    this.paramsCancel = {
      rideId: this.props.rideData.id,
      reasonId: cancelReason,
      reasonComment: comments,
      recurringId: recurringId,
      rideApplyOnAll: 0
      // rideType: rideType,
      // roundTrip: this.state.roundTrip
    };

    if (!_.isNil(this.props.rideData.recurring_rideId)) {
      this.openCancelRepeatAppt();
    } else {
      this.cancel(this.paramsCancel);
      this.props.toggleLoading(true, 'Cancelling Rides...', 20);
    }
  };

  /**
   * handles cancellations
   * @param {object} paramsCancel - parameters for cancel endpoint
   * @return {undefined}
   */
  cancel(paramsCancel) {
    const { cancelRide, rideData, cancellationReasons } = this.props;
    const { multiSelected, checked } = this.state;
    let cancelledRides = [paramsCancel.rideId];

    // cancelRide(paramsCancel, page, start, limit);
    const tripType = this.isRoundTrip(rideData);

    // cancel b leg round trip not multi leg
    if (checked && multiSelected === '') {
      // const secondLegCancel = _.clone(paramsCancel);
      const rideId = tripType.leg === 'aleg' ? rideData.blegRideId : rideData.alegRideId;
      cancelledRides.push(rideId);
      // cancelRide(secondLegCancel, page, start, limit);
    }

    if (multiSelected !== '' && !checked) {
      const rides = this.rides;
      const index = rides.indexOf(paramsCancel.rideId);

      if (multiSelected === 'following') {
        // get legs in the future
        cancelledRides = rides.slice(index);
      } else if (multiSelected === 'remaining') {
        // get all legs
        cancelledRides = rides;
      }
    }
    paramsCancel.rides = cancelledRides;
    _.unset(paramsCancel, 'rideId');
    cancelRide(rideData.id, paramsCancel, cancellationReasons);
  }

  /**
   * select transportation from drop down modal
   * @param {event} e - event
   * @return {undefined} - returns nothing
   */
  selectCancellationReason = e => {
    const cancellationId = parseInt(e.target.getAttribute('id'), 10);
    const cancellationReason = e.target.innerHTML;

    this.setState(
      {
        cancellationId,
        cancellationReason
      },
      this.checkFormsValid
    );
  };

  /**
   * handler for checking cancel round trips
   * @return {undefined}
   */
  handleSelectRoundTrip = () => {
    const { rideData, cancelRideCost } = this.props;

    this.setState(prevState => {
      return { checked: !prevState.checked };
    });
    this.ridesCost = [rideData.id];
    if (!this.hasSecondRideCost) {
      const rideType = rideData.modelName === 'Lyft' ? 'lyft' : 'rides';
      const tripData = this.isRoundTrip(rideData);
      const rideId = tripData.leg === 'aleg' ? rideData.blegRideId : rideData.alegRideId;
      this.ridesCost.push(rideId);
      cancelRideCost({ rideId: rideId, rideType: rideType, leg: tripData.leg });
      this.hasSecondRideCost = true;
    }
  };

  /**
   * close the modal
   * @return {undefined}
   */
  closeModal = () => {
    this.props.resetCards();
    this.props.resetUpdatedCards();
    this.props.closeModal();
  };

  /**
   * select which for multi leg
   * @param {object} e - event
   * @return {undefined}
   */
  handleRadioClick = e => {
    const { rideData, cancelRideCost } = this.props;
    e.preventDefault();
    const multiVal = e.target.value;
    const state = { multiSelected: multiVal };

    this.setState(state);

    // get cancellation cost for multi leg
    let cancelledRides = _.clone(this.rides);
    const index = cancelledRides.indexOf(rideData.id);

    this.ridesCost = [rideData.id];

    if (multiVal === 'following') {
      // get legs in the future
      cancelledRides = cancelledRides.slice(index + 1);
    } else if (multiVal === 'remaining') {
      // get all legs
      cancelledRides.splice(index, 1);
    } else {
      cancelledRides = [];
    }

    // loop throuch cancelledRides and get cost
    for (let i = 0; i < cancelledRides.length; i++) {
      const tripRide = _.find(rideData.tripRides, { id: cancelledRides[i] });
      if (typeof tripRide !== 'undefined') {
        let rideType = 'rides';
        if (tripRide.reqVehicleType === LYFT_VEHICLE_ID) {
          rideType = 'lyft';
        }
        cancelRideCost({ rideId: cancelledRides[i], rideType: rideType, leg: '' });
        this.ridesCost.push(cancelledRides[i]);
      }
    }
  };

  /**
   * returns hard coded cancelled reasons array for dropdown
   * @return {array} - returns collection of cancellation reasons
   */
  generateDropDown() {
    const { cancellationReasons } = this.props;
    return cancellationReasons
      .filter(({ reason_desc }) => reason_desc !== 'Other')
      .map(reason => {
        return {
          id: reason.id,
          name: reason.reason_desc
        };
      });
  }

  /**
   * checks if form values are valid prior to submission
   * @return {boolean} - returns true or false if form is valid
   */
  checkFormsValid() {
    let status = true;
    const state = {
      errorMessage: '',
      dropDownError: false
    };

    if (this.state.cancellationId === '') {
      status = false;
      state.dropDownError = true;
      state.errorMessage = 'Please select a cancellation reason.';
    }

    state.isValidated = status;
    this.setState(state);

    return status;
  }

  /**
   * is it multi leg
   * @param {object} ride - ride object
   * @return {boolean} - returns whether its multi leg
   */
  isMultiLeg(ride) {
    let tripRides = _.get(ride, 'tripRides', []);

    if (_.isNil(tripRides)) {
      tripRides = [];
    }

    // trip with 1 ride is not a multi leg
    if (tripRides.length <= 1) {
      return false;
    }

    return true;
  }

  /**
   * figure out what type of ride it is
   * @param {object} ride - ride data
   * @return {object} - returns tripType information
   */
  isRoundTrip(ride) {
    // b leg round trip
    const tripType = {
      roundTrip: false,
      leg: '',
      returnLeg: ''
    };

    // make sure its not multileg
    if (this.isMultiLeg(ride)) {
      return tripType;
    }

    // b leg ride. that means additional cancelled ride would be a leg
    if (ride.appointmentTime === null) {
      tripType.returnLeg = 'aleg';
      tripType.leg = 'bleg';
      if (ride.alegRideId) {
        tripType.roundTrip = true;
      }
    }

    // a leg ride. that means additional cancelled ride would be b leg
    if (ride.appointmentTime !== null) {
      tripType.returnLeg = 'bleg';
      tripType.leg = 'aleg';
      if (ride.blegRideId) {
        tripType.roundTrip = true;
      }
    }

    return tripType;
  }

  /**
   * calculate cost and returned a formatted cost
   * @return {string} formatted cost
   */
  calculateCost() {
    const cost = this.ridesCost.reduce((total, c) => {
      const costVal = _.get(this.props, `cancelRideCostData.${c}.cost`, 0);
      return total + costVal;
    }, 0);

    return cost.toFixed(2);
  }

  /**
   * get modal title
   * @param {boolean} isMultiLeg - is it multileg or not
   * @param {object} tripType - is it round trip
   * @return {string} title - modal title
   */
  getTitle(isMultiLeg, tripType, mode) {
    mode = mode === 'Public' ? mode + ' ' : '';
    if (isMultiLeg) {
      return `Cancel ${mode}Multileg Trip`;
    }
    if (tripType.roundTrip && tripType.leg === 'aleg') {
      return `Cancel ${mode}Rountrips`;
    }
    return `Cancel ${mode}Ride`;
  }

  render() {
    const { rideData } = this.props;
    const tripType = this.isRoundTrip(rideData);
    const cost = this.calculateCost();
    const cancellationReasons = this.generateDropDown();
    const isMultiLeg = this.isMultiLeg(rideData);
    const title = this.getTitle(isMultiLeg, tripType, rideData.mode);

    return (
      <Modal
        isOpen={this.props.isOpen}
        onRequestClose={this.closeModal}
        className={{
          base: 'newModalBaseClass newErrorModal cancelRide',
          afterOpen: 'modalBaseClassOpen',
          beforeClose: 'modalBaseClassClose'
        }}
        overlayClassName={{
          base: 'overlayBaseClass',
          afterOpen: 'overlayBaseClassOpen',
          beforeClose: 'overlayBaseClassClose'
        }}
        closeTimeoutMS={500}
      >
        <RepeatAppointments
          heading="Cancel Recurring Ride"
          action="cancelled"
          isOpen={this.state.isOpenCancelRepeatAppt}
          closeModal={this.closeCancelRepeatAppt}
          cancelRide={this.submitCancelRepeatAppt}
          showRoundTrip={false}
          roundTrip={this.state.roundTrip}
          rideTypeChange={this.rideTypeChange}
        />
        <div className="modalHead">
          <p>{title}</p>
        </div>
        <form onSubmit={this.handleSubmit} method="post">
          <div className="modalBody clearfix">
            <div className="newTime clearfix">
              <label className="costLeft" htmlFor="cancelReason">
                Cancel Reason
              </label>
              <div className="selectComponent">
                <DropDown
                  items={cancellationReasons}
                  placeholder={this.state.cancellationReason}
                  black={true}
                  dropDownCallback={this.selectCancellationReason}
                  error={this.state.dropDownError}
                />
              </div>
            </div>
            <div className="newTime clearfix">
              <label className="costLeft" htmlFor="comments">
                Comments
              </label>
              <textarea
                id="comments"
                name="comments"
                placeholder="Additional comments"
              ></textarea>
            </div>
            {cost !== 0 ? (
              <div className="newTime clearfix">
                <p className="costLeft">Cost</p>
                <p className="cost">${cost}</p>
              </div>
            ) : null}
            {tripType.roundTrip && tripType.leg === 'aleg' ? (
              <div className="newTime clearfix">
                <p className="costLeft">{`Cancel ride for the return leg of this trip`}</p>
                <p className="cost">
                  <CheckBox
                    fieldName="roundTripCheck"
                    onChangeCallback={this.handleSelectRoundTrip}
                    checked={this.state.checked}
                    disabled={false}
                    required={false}
                    black={true}
                  />
                </p>
              </div>
            ) : null}
            {isMultiLeg ? (
              <div>
                <label className="newTime radioButton" htmlFor="thisLeg">
                  <p className="costLeft">
                    {this.state.multiSelected === 'thisLeg' ? (
                      <SvgRadioSelected className="radioButton" />
                    ) : (
                      <SvgRadioUnselected className="radioButton" />
                    )}
                    <input
                      type="radio"
                      id="thisLeg"
                      name="multi"
                      checked={this.state.multiSelected === 'thisLeg'}
                      onChange={this.handleRadioClick}
                      value="thisLeg"
                    />
                  </p>
                  <p className="cost">This leg.</p>
                </label>
                <label className="newTime radioButton" htmlFor="following">
                  <p className="costLeft">
                    {this.state.multiSelected === 'following' ? (
                      <SvgRadioSelected className="radioButton" />
                    ) : (
                      <SvgRadioUnselected className="radioButton" />
                    )}
                    <input
                      type="radio"
                      id="following"
                      name="multi"
                      checked={this.state.multiSelected === 'following'}
                      onChange={this.handleRadioClick}
                      value="following"
                    />
                  </p>
                  <p className="cost">This and following legs.</p>
                </label>
                <label className="newTime radioButton" htmlFor="remaining">
                  <p className="costLeft">
                    {this.state.multiSelected === 'remaining' ? (
                      <SvgRadioSelected className="radioButton" />
                    ) : (
                      <SvgRadioUnselected className="radioButton" />
                    )}
                    <input
                      type="radio"
                      id="remaining"
                      name="multi"
                      checked={this.state.multiSelected === 'remaining'}
                      onChange={this.handleRadioClick}
                      value="remaining"
                    />
                  </p>
                  <p className="cost">
                    All remaining legs.
                    <br />
                    {this.state.hasCompleted && (
                      <span className="sub">(Some legs have been completed.)</span>
                    )}
                  </p>
                </label>
              </div>
            ) : null}
          </div>
          {this.state.showStatus ? (
            <div className="modalStatus">
              <p>Submitting</p>
            </div>
          ) : null}
          {this.state.errorMessage !== '' ? (
            <div className="modalErrorStatus">
              <p>{this.state.errorMessage}</p>
            </div>
          ) : null}
          <div className="modalFoot">
            <input type="submit" value="Update" />
            <a onClick={this.closeModal} className="btnClose">
              Close
            </a>
          </div>
        </form>
      </Modal>
    );
  }
}

CancelRide.propTypes = {
  isOpen: PropTypes.bool,
  rideData: PropTypes.object,
  handleSubmit: PropTypes.func,
  closeModal: PropTypes.func,
  cancelRideCost: PropTypes.func,
  cancelRide: PropTypes.func,
  cancelRideCostData: PropTypes.object,
  cancelRideData: PropTypes.object,
  page: PropTypes.string,
  start: PropTypes.number,
  limit: PropTypes.number,
  resetCards: PropTypes.func,
  error: PropTypes.object,
  updateRideData: PropTypes.object,
  timestampMicro: PropTypes.number,
  resetUpdatedCards: PropTypes.func,
  toggleLoading: PropTypes.func
};

CancelRide.defaultProps = {
  isOpen: false,
  rideData: {},
  handleSubmit: () => {},
  closeModal: () => {},
  cancelRideCost: () => {},
  cancelRide: () => {},
  cancelRideCostData: {},
  cancelRideData: {},
  page: 'scheduled',
  start: 0,
  limit: 50,
  resetCards: () => {},
  error: {},
  updateRideData: {},
  timestampMicro: 0,
  resetUpdatedCards: () => {},
  toggleLoading: () => {}
};

const mapStateToProps = state => ({
  cancelRideData: state.rideCards.cancelRideData,
  cancelRideCostData: state.rideCards.cancelRideCostData,
  error: state.rideCards.error,
  timestampMicro: state.rideCards.timestampMicro
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      cancelRideCost: data => cancelRideCost(data),
      cancelRide: (data, page, start, limit) => cancelRide(data, page, start, limit),
      resetCards: () => resetCards(),
      resetUpdatedCards: () => resetUpdatedCards()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(CancelRide);
