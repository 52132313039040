import React from 'react';
import PropTypes from 'prop-types';
import SvgClose from '~/Shared/Components/Svgs/SvgClose';

const BenefitsModal = props => {
  const { showModal, handleCloseModal, children, title, showX } = props;

  return (
    <>
      {
        showModal ?
          <div
            className="BenefitsModal"
            onClick={e => handleCloseModal(e)}
          >
            <div
              className="innerWrapper"
              onClick={e => e.stopPropagation()}
            >
              <h4>
                {title}
                <span
                  className="closeModal"
                  onClick={handleCloseModal}
                >
                  {showX ?
                    <SvgClose />
                    :
                    null
                  }
                </span>
              </h4>
              {children}
            </div>
          </div>
          :
          null
      }
    </>
  );
};

BenefitsModal.propTypes = {
  showModal: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array
  ]),
  showX: PropTypes.bool
};

BenefitsModal.defaultProps = {
  showModal: false,
  handleCloseModal: () => {},
  title: '',
  children: {},
  showX: true
};

export default BenefitsModal;
