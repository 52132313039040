import React from 'react';
import PropTypes from 'prop-types';
import { LYFT_ERROR_MESSAGE, LYFT_VEHICLE_ID } from '~/constants';

const SEDAN_TITLE = 'Lyft Sedan (Max 4 people)';
const SUV_TITLE = 'Lyft SUV (Max 6 people)';

const LyftOptions = props => {
  const {
    vehicles,
    handleVehicleClick,
    errorClass,
    errorMessage,
    changeTransport,
    transportType
  } = props;

  const vehicleClick = vehicleInfo => {
    // TODO:UBER - ONDEMAND
    const info = {
      cost: vehicleInfo.max_cost,
      eta: vehicleInfo.eta,
      distance: vehicleInfo.distance,
      duration: vehicleInfo.duration,
      rideShareType: vehicleInfo.ride_type,
      ...(vehicleInfo.product_id && { product_id: vehicleInfo.product_id }),
      ...(vehicleInfo.cost_token && { cost_token: vehicleInfo.cost_token }),
      ...(vehicleInfo.fare_id && { fare_id: vehicleInfo.fare_id }),
      ...(vehicleInfo.run_id && { run_id: vehicleInfo.run_id })
    };
    handleVehicleClick(info);
  };

  const changeTransportHandler = e => {
    e.preventDefault();
    changeTransport();
  };

  let showNoSupplyMessage = false;
  if (errorMessage.length === 1 && errorMessage[0] === LYFT_ERROR_MESSAGE) {
    showNoSupplyMessage = true;
  }

  return (
    <div className="lyftOptions">
      {vehicles.length > 0 ? (
        vehicles.map((vehicle, key) => {
          {
            /* TODO:REMOVE */
          }
          let title = '';
          if (transportType === LYFT_VEHICLE_ID) {
            title = vehicle.ride_type === 'lyft' ? SEDAN_TITLE : SUV_TITLE;
          } else {
            title = vehicle.ride_type;
          }

          let buttonText = '';
          if (transportType === LYFT_VEHICLE_ID) {
            buttonText = vehicle.ride_type === 'lyft' ? 'Sedan' : 'SUV';
          } else {
            buttonText = vehicle.ride_type;
          }

          return (
            <div key={key} className="vehicleCont clearfix">
              <p className="title">{title}</p>
              {transportType === LYFT_VEHICLE_ID ? (
                <p className="nearby">{vehicle.nearbyDrivers} vehicles nearby</p>
              ) : null}
              <p className="eta">ETA: {Math.ceil(vehicle.eta / 60)} mins</p>
              <p className="price">${vehicle?.max_cost?.toFixed(2)}</p>
              <a onClick={() => vehicleClick(vehicle)} className="selectLyftVehicle">
                Select {buttonText}
              </a>
            </div>
          );
        })
      ) : (
        <div>
          {showNoSupplyMessage ? (
            <div className="lyftNoSupply">
              <p className="bold">No vehicles are currently available.</p>
              <p>Sorry, there are no vehicles available at this time.</p>
              <p>
                Please check back later or{' '}
                <a onClick={changeTransportHandler} className="selectDifferentTransport">
                  select a different transportation type
                </a>
                .
              </p>
            </div>
          ) : (
            <div className={errorClass}>
              {errorMessage.map((message, key) => {
                return <p key={key}>{message}</p>;
              })}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

LyftOptions.propTypes = {
  handleVehicleClick: PropTypes.func,
  errorClass: PropTypes.string,
  errorMessage: PropTypes.array,
  vehicles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  changeTransport: PropTypes.func
};

LyftOptions.defaultProps = {
  handleVehicleClick: () => {},
  errorClass: '',
  errorMessage: [],
  vehicles: [],
  changeTransport: () => {}
};

export default LyftOptions;
