import { createAppAsyncThunk } from '~/Modules';
import { addressFactory, rideFactory } from '~/models';
import { getRideRouteData } from '~/Pages/RideBooking/Components/Rides/Rides.utils';

/** A thunk used to add a return ride leg on the current ride
 * in the RBF. This is necessary because we need to access the
 * memberProfile store to get the member's notes and don't have
 * access to that inside of the rideBooking slice. */
export const addReturnRideThunk = createAppAsyncThunk(
  'rideBooking/addReturnRide',
  (_, { getState }) => {
    const rideOne = getState().rideBooking.rides[0];
    const date = getState().rideBooking.date.date;
    const memberNotes = getState().memberProfile.formData?.personalInfo?.otherDetails;

    return getRideRouteData(rideOne, date)
      .then(data => ([rideOne.distance, rideOne.duration] = [data?.[0], data?.[1]]))
      .then(rideOne.commit)
      .then(() =>
        rideFactory({
          ...rideOne.toJSON(),
          departAddress: addressFactory(rideOne.arriveAddress.toJSON()),
          arriveAddress: addressFactory(rideOne.departAddress.toJSON()),
          notes: memberNotes || '',
          type: 'departAt',
          time: undefined,
          distance: undefined
        })
      );
  }
);
