import _ from 'lodash-es';
import React from 'react';
import PropTypes from 'prop-types';
import SvgPeople from '~/Shared/Components/Svgs/SvgPeople';
import SvgChatBubble from '~/Shared/Components/Svgs/SvgChatBubble';
import SvgPassengerIcon from '~/Shared/Components/Svgs/SvgPassengerIcon';
import { HOSPITAL_NETWORK_ROLES } from '~/constants';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { PassengerSummary } from '~/Shared/Components/AdditionalPassengers/PassengerSummary';

const NOTES_LENGTH_TRUNCATE = 80;
const noEditStatus = ['Inbound', 'Started', 'Arrived'];

const PatientInfoComplete = props => {
  const {
    userRole,
    bookingData,
    toggleNotes,
    showAllNotes,
    complianceInfo,
    editPatientInfo,
    dontShowDownArrow,
    patientCustomFields
  } = props;

  let patientCustomData = patientCustomFields;

  if (HOSPITAL_NETWORK_ROLES.includes(userRole)) {
    if (!_.isEmpty(bookingData)) {
      patientCustomData = _.get(patientCustomFields, `${bookingData.hospitalId}`, []);
    } else {
      patientCustomData = [];
    }
  }

  const hasBookingData = !!bookingData?.patientCustomFields;
  const hasComplianceData = !!bookingData?.compliance;

  const {
    mode = '',
    phone = '',
    phone2 = '',
    lastName = '',
    pastRide = '',
    medicalId = '',
    firstName = '',
    dateOfBirth = '',
    additionalPassengers = [],
    compliance = {},
    treatmentName = ''
  } = bookingData;

  const internalNotes = bookingData.internalNotes || '';

  const dependentFields = compliance
    ? Object.keys(compliance).filter(c => {
      return !complianceInfo.map(c => c.input_name).includes(c);
    })
    : [];

  return (
    <div className="patientInfoComplete">
      <div className="left">
        <p className="name">
          <SvgPassengerIcon className="passengerIcon" />
          {firstName} {lastName}
        </p>
        <p className="patientId">ID: {medicalId}</p>
        <p className="phone">
          P: {formatPhoneNumber(phone)} | DOB: {dateOfBirth || '--'}
        </p>
        {!_.isNil(phone2) && phone2 !== '' ? (
          <p className="phone">Alternate: {formatPhoneNumber(phone2)}</p>
        ) : null}
        {!_.isNil(treatmentName) && treatmentName !== '' ? (
          <p className="phone">Treatment: {treatmentName}</p>
        ) : null}
        {additionalPassengers && additionalPassengers.length ? (
          <div className="passengerSummaryContainer">
            <SvgPeople className="va-bottom" />
            &nbsp;
            <PassengerSummary
              style={{ color: '#36474F', fontSize: '14px', marginTop: 'auto' }}
              hideAddBtn={true}
              passengers={additionalPassengers}
            />
          </div>
        ) : null}
        {patientCustomData.map((field, key) => {
          const val = _.get(bookingData, `patientCustomFields.${field.id}`, '');
          if (hasBookingData) {
            return (
              <p key={key} className="phone">
                {field.field_label_desc}: {val}
              </p>
            );
          } else {
            return null;
          }
        })}

        {complianceInfo.map((field, key) => {
          if (!hasComplianceData || field.input_type === 'hidden') {
            return null;
          }

          const complianceId = field.input_name;
          const complianceVal = bookingData?.compliance?.[complianceId] ?? '';

          return (
            <p key={key} className="phone">
              {complianceId}: {complianceVal}
            </p>
          );
        })}

        {dependentFields
          ? dependentFields.map((d, i) => (
            <p key={i} className="phone">
              {d}: {compliance[d]}
            </p>
          ))
          : null}
        <p className="phone">{pastRide ? 'Past Ride Booking' : null}</p>
        <p className="notes">
          {internalNotes.length ? <SvgChatBubble className="chatIcon" /> : null}
          {internalNotes.length > NOTES_LENGTH_TRUNCATE ? (
            <span>
              {showAllNotes === true ? (
                <span>
                  {internalNotes}{' '}
                  <span className="more" onClick={toggleNotes}>
                    less
                  </span>
                </span>
              ) : (
                <span>
                  {_.truncate(internalNotes, {
                    length: NOTES_LENGTH_TRUNCATE,
                    separator: ' '
                  })}{' '}
                  <span className="more" onClick={toggleNotes}>
                    more
                  </span>
                </span>
              )}
            </span>
          ) : (
            <span>{internalNotes}</span>
          )}
        </p>
      </div>
      {!props.disableEdit &&
      noEditStatus.indexOf(props.status) === -1 &&
      mode !== 'Public' ? (
          <div className="right" onClick={editPatientInfo}>
            <p className="edit">Edit</p>
          </div>
        ) : null}

      {!dontShowDownArrow ? <div className="arrowDownLong"></div> : null}
    </div>
  );
};

PatientInfoComplete.defaultProps = {
  bookingData: {},
  editPatientInfo: () => {},
  toggleNotes: () => {},
  showAllNotes: false,
  dontShowDownArrow: true,
  patientCustomFields: [],
  userRole: '',
  disableEdit: false,
  complianceInfo: [],
  dependentFields: {},
  status: 'Scheduled'
};

PatientInfoComplete.propTypes = {
  bookingData: PropTypes.object,
  editPatientInfo: PropTypes.func,
  toggleNotes: PropTypes.func,
  showAllNotes: PropTypes.bool,
  dontShowDownArrow: PropTypes.bool,
  patientCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  userRole: PropTypes.string,
  disableEdit: PropTypes.bool,
  complianceInfo: PropTypes.array,
  status: PropTypes.string
};

export default PatientInfoComplete;
