import React, { type ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import type { NavOption } from './MemberProfiles.helper';
import type { withSlotProps } from '~/utilities/slots';
import { withSlots } from '~/utilities/slots';

const SLOT_SCHEMA = {
  ErrorMsg: MemberNavErrorMsg,
  SuccessMsg: MemberNavSuccessMsg,
  EditButton: MemberNavEditButton
} as const;

export type MemberNavProps = {
  navOptions: NavOption[];
  handleChildSelection: (parentForm: string, childForm: string) => void;
  children?: ReactElement | ReactElement[];
};

function MemberNav({
  navOptions,
  slots,
  handleChildSelection
}: withSlotProps<MemberNavProps, typeof SLOT_SCHEMA>) {
  // Trick to determine if the slots are empty or not.
  // TODO: Refactor slots to remove need for this
  const successMsgIsNull = !(slots!.SuccessMsg! as ReactElement).props.msg;
  const errorMsgIsNull = !(slots!.ErrorMsg! as ReactElement).props.msg;
  const editButtonIsNull = !(slots!.EditButton! as ReactElement).props.children;

  /** Intercepts and prevents the default behavior of button click */
  function onChildSelect(e, parentForm, childForm) {
    e.preventDefault();
    handleChildSelection(parentForm, childForm);
  }

  // Don't display the component if we have nothing to display!
  if (!navOptions?.length && successMsgIsNull && errorMsgIsNull && editButtonIsNull) {
    return null;
  }

  return (
    <div className="subhead">
      <ul className="tabs">
        {navOptions.map(({ dataTip, label, route, parentForm, childForm }) => {
          return (
            <li key={label} className="tab">
              <NavLink
                to={route}
                exact
                onClick={e => onChildSelect(e, parentForm, childForm)}
                data-tip={dataTip}
              >
                {label}
              </NavLink>
            </li>
          );
        })}
      </ul>

      <div className="editSaveCancel">
        {slots.SuccessMsg}
        {slots.ErrorMsg}
        {slots.EditButton}
      </div>
    </div>
  );
}

type MemberNavErrorMsgProps = {
  msg?: string;
};

function MemberNavErrorMsg({ msg }: MemberNavErrorMsgProps) {
  return msg ? (
    <div>
      <span className="error">{msg}</span>
    </div>
  ) : null;
}

type MemberNavSuccessMsgProps = {
  msg?: string;
};

function MemberNavSuccessMsg({ msg }: MemberNavSuccessMsgProps) {
  return msg ? <span className="success">{msg}</span> : null;
}

function MemberNavEditButton({ children }: { children?: JSX.Element }) {
  return children ? <>{children}</> : null;
}

export default withSlots<MemberNavProps, typeof SLOT_SCHEMA>(MemberNav, SLOT_SCHEMA);
