/* eslint-disable no-unused-vars */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './Pages/App/App';
import { googleMapsInit } from './services/googleMaps.service';
import { rollbarInit } from './services/rollbar.service';
import { configurePusher } from './utilities/pusherRedux';
import type Rollbar from 'rollbar';
import type { StoreEnhancer } from 'redux';
import { reportHandler, reportWebVitals } from '~/services/reporting.service';
import './index.styl';
import '@SRHealth/frontend-lib/style.css';

declare global {
  interface Window {
    Rollbar?: Rollbar;
    store: typeof store;
    dataLayer: unknown[];
    __REDUX_DEVTOOLS_EXTENSION__: unknown;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: ({
      serialize,
      trace,
      traceLimit
    }: {
      serialize: boolean;
      trace: boolean;
      traceLimit: number;
    }) => (enhancer: StoreEnhancer) => void;
  }
}

rollbarInit();
googleMapsInit();

configurePusher(store); // Init pusher-redux

class Root extends React.PureComponent {
  render() {
    return (
      <Provider store={store}>
        <App />
      </Provider>
    );
  }
}

render(<Root />, document.getElementById('app_container'));
reportWebVitals(reportHandler);
