import _ from 'lodash-es';

const throttledActions = ['rideInfoUpdate', 'rideLocationUpdate'];

const createThrottledMiddleware = (throttleMilliseconds, actionsToThrottle) => {
  // Store for throttled actions
  const throttledActions = {};

  // Middleware function
  return _store => next => action => {
    if (actionsToThrottle.includes(action.type)) {
      // If the action should be throttled
      if (!throttledActions[action.type]) {
        // Create a throttled function if it doesn't exist
        throttledActions[action.type] = _.throttle(action => {
          next(action);
        }, throttleMilliseconds);
      }
      // Call the throttled function
      throttledActions[action.type](action);
    } else {
      // If the action should not be throttled, pass it directly to the next middleware
      next(action);
    }
  };
};

// Specify the actions to throttle and the throttle duration
export const throttledMiddleware = createThrottledMiddleware(500, throttledActions);
