import type { Moment } from 'moment-timezone';
import moment from 'moment-timezone';
import { USER_ROLES } from '~/constants';
import Features from '~/constants/features';
import type { UserStore } from '~/Modules/user';

export const calculateTzOffset = (date: any, userTimezone?: string) => {
  if (!userTimezone) return 0;

  const localTzOffset = moment
    .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
    .utcOffset();
  const targetTzOffset = moment.tz(date, userTimezone).utcOffset();

  return targetTzOffset - localTzOffset;
};

export const csrTimezoneAdjustedDate = (date: Moment, timezone: string) => {
  return moment
    .tz(date, Intl.DateTimeFormat().resolvedOptions().timeZone)
    .add(calculateTzOffset(date, timezone), 'minutes')
    .startOf('day')
    .toDate();
};

/**
 * Loops through the user hospitalGroupFeatures and generate
 * a standardized object of feature flags
 * @param hospitalGroupFeatures
 * @returns
 */
export const getFeatureFlags = (
  hospitalGroupFeatures: UserStore['hospitalGroupFeatures'] = []
) => {
  const features = Object.fromEntries(
    Object.values(Features).map(lbl => [lbl, false])
  ) as UserStore['features'];

  hospitalGroupFeatures.forEach(groupFeature => {
    const key = groupFeature.feature;

    if (key in features) {
      features[key] = groupFeature.feature_status === 1;
    }
  });

  // New RBF overrides several existing toggles
  if (features[Features['NEW_RIDE_BOOKING_FLOW']]) {
    features[Features['MOBILITY_ASSESSMENT_HARD_BLOCK']] = true;
    features[Features['HIDE_NEW_RIDE']] = true;
    features[Features['ASSISTANCE_NEEDS']] = true;
    features[Features['DISABLE_ROUND_TRIP']] = false;
    features[Features['SAVED_ADDRESS']] = true;
  }

  return Object.freeze(features);
};

/**
 * Retrieves the name of the entity associated with a user based on their role.
 *
 * @param {Object} user - The user object to determine the entity name for.
 * @returns {string} The name of the entity associated with the user, or an empty string if not applicable.
 */
export const getEntityName = user => {
  if (!user) return '';

  switch (user?.userData?.role) {
    case USER_ROLES.healthPlanOwner:
    case USER_ROLES.healthPlanAdmin:
      return user?.healthPlan?.health_plan_name;

    case USER_ROLES.hospitalOwner:
    case USER_ROLES.hospitalNetworkManager:
      return user?.userData?.hospitalGroupName;

    case USER_ROLES.hospitalManager:
    case USER_ROLES.hospitalAdmin:
    case USER_ROLES.caseManager:
      return user?.hospitalData?.hospitalName;

    default:
      return '';
  }
};
