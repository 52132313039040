const pickupSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12"><circle cx="6" cy="6" r="4" fill="#FFF" fill-rule="evenodd" stroke="#2962FF" stroke-width="4"/></svg>';
const dropoffSvg = '<svg xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17"><g fill="none" fill-rule="evenodd"><path fill="#FF1A00" d="M6 17c2 0 6-6.686 6-11A6 6 0 1 0 0 6c0 4.314 4 11 6 11z"/><circle cx="6" cy="6" r="4" fill="#FFF" stroke="#FF1A00" stroke-width="4"/></g></svg>';

/**
 * @param {string} pickupOrDropoff - is it a pickup or dropoff? Important for which pin to display
 * @param {string} location - Address to display inside the info window
 * @param {string} time - pickup or dropoff date
 * @return {HTML} - returns HTML. can't use jsx here becuase it's gonna be piped right into maps.
 */
const MapInfoWindow = (pickupOrDropoff, location, time) => {
  const icon = pickupOrDropoff === 'pickup' ? pickupSvg : dropoffSvg;
  const headingColor = pickupOrDropoff === 'pickup' ? 'pickupColor' : 'dropoffColor';
  const headingText = pickupOrDropoff === 'pickup' ? 'Pick Up' : 'Drop off';
  const html = `
    <div class='markerInfoWindow'>
      <div class='left'>${icon}</div>
      <div class='right'>
        <p class='heading'><span class='${headingColor}'>${headingText}</span> ${time}</p>
        <p class='address'>${location}</p>
      </div>
    </div>
  `;
  return html;
};

export {
  MapInfoWindow as default
};
