import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import {
  ridesPreAuthorize,
  ridesPreAuthorizePublic,
  reset,
  lyftRidesOnDemandOptions,
  availableCards
} from '~/Modules/scheduleRides';
import {
  clearBookingRide,
  updateBookingData,
  clearStateData
} from '~/Modules/bookingData';
import { reset as resetPatient } from '~/Modules/patients';
import { getVehicles } from '~/Modules/user';
import SvgClose from '../Svgs/SvgClose';
import PatientInfo from './FormComponents/PatientInfo';
import PatientInfoComplete from './DisplayComponents/PatientInfoComplete';
import RoundTripButton from './FormComponents/RoundTripButton';
import BookRidesMulti from './BookRidesMulti';
import BookRidesSingle from './BookRidesSingle';
import PublicRoute from 'Common/Components/BookingConfirmation/Components/TransportOptions/PublicRoute';
import {
  mapMultiLegBooking,
  repeatApptBookingHelper,
  mapPublicBooking
} from '~/utilities/booking.helper';
import { clearNewMemberForm } from '~/Modules/members/reducer';
import { isRideShareVehicleId } from '~/utilities/helperFunctions';
import { DISABLE_ROUND_TRIP, MULTI_LEG, PAST_BOOKING } from '~/constants';

class BookRides extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      patientParams: {},
      roundTrip: false,
      showRequestRideArrow: true,
      showComponents: false, // show BookRidesMulti or BookRidesSingle
      // show initial form components
      showPatientForm: true,

      // show completed form components
      showPatientInfoComplete: false,
      showTransportComplete: false,
      showTripComplete: false,

      // edit previously completed form components
      editPatientForm: false,

      showAllNotes: false,
      showRoundTripButton: false,
      patientCustomFields: [],

      missingFields: [],
      multiLeggedTrip: false,
      multi: {
        trip: false,
        showTransportComplete: false,
        editRideBooking: false
      },
      complianceInfo: _.cloneDeep(props.complianceInfo)
    };
    this.modalParams = {};
  }

  /**
   * lifecylce function, clear booking data and patient data
   * @returns {undefined} returns nothing
   */
  componentWillUnmount() {
    this.props.clearBookingRide();
    this.props.resetPatient();
  }

  /**
   * determines if One Way is the only ride type available
   * @return {boolean} true if One Way is the only option available
   */
  oneWayOnly() {
    return (
      !this.props.user.features[MULTI_LEG] && this.props.user.features[DISABLE_ROUND_TRIP]
    );
  }

  /**
   * @param {object} patientParams - params back from patient submission
   * @param {boolean} editBooked - editing a booked form for multi legged
   * @return {undefined}
   */
  handlePatientSubmit = (patientParams, editBooked = false) => {
    const state = _.cloneDeep(this.state);
    _.assign(state, patientParams);
    state.showPatientForm = false;
    state.showPatientInfoComplete = true;
    // no longer showing transportation form, show roundtrip form instead
    state.editPatientForm = false;
    let patientCustomFields = [];

    if (
      this.props.user.userData.role === 'HospitalOwner' ||
      this.props.user.userData.role === 'HospitalNetworkManager'
    ) {
      patientCustomFields = this.props.patientCustomFields[state.hospitalId];
    } else {
      patientCustomFields = this.props.patientCustomFields;
    }

    state.patientCustomFields = patientCustomFields;
    state.multi.editRideBooking = false;
    if (editBooked) {
      state.showRoundTripButton = false;
    }

    let doWeBook = editBooked;

    // if multi leg only send to handle request booking if trip is complete
    if (_.has(this.bookingData, 'currentRideIndex')) {
      const tripCompleted = _.get(this.bookingData, 'tripCompleted', false);
      if (!tripCompleted) {
        doWeBook = false;
      }
    }
    const canOnlyOneWay = this.oneWayOnly();
    state.showRoundTripButton = !canOnlyOneWay;

    //are we editing patient while still in Booking flow for first time of ride?
    if (this.props.bookingData.rides?.length > 0) {
      state.showRoundTripButton = false;
    } else {
      state.showRoundTripButton = true;
    }

    this.setState(state, () => {
      const userData = this.props.user.userData;

      if (
        [
          'HospitalOwner',
          'HospitalNetworkManager',
          'HospitalManager',
          'HospitalAdmin',
          'CaseManager'
        ].includes(userData.role)
      ) {
        const subPlanId = this.props?.patientDetails?.health_sub_plan_id ?? null;

        this.props.getVehicles(state.hospitalId, subPlanId);
      }

      if (doWeBook) {
        this.handleRequestBooking();
      }
      if (canOnlyOneWay) {
        this.oneWayTrip();
      }
    });
  };

  /**
   * displays edit patient form
   * @return {undefined}
   */
  editPatientInfo = () => {
    let state = _.cloneDeep(this.state);

    if ('currentRideIndex' in this.props.bookingData) {
      state.showPatientInfoComplete = false;
      state.multi.editRideBooking = true;
    } else {
      state = this.hideAllFormComponents();
      const displayState = this.hideAllDisplayComponents();
      state = _.merge(state, displayState);
      state.showComponents = false;
      this.props.reset();
    }
    this.props.hideMiddleColumn();
    state.showPatientForm = true;
    state.editPatientForm = true;
    this.setState(state, () => this.props.updateBookingData({ editPatientInfo: true }));
  };

  /**
   * toggles display of patient notes between more and less
   * @return {undefined}
   */
  toggleNotes = () =>
    this.setState(prevState => ({ showAllNotes: !prevState.showAllNotes }));

  /**
   * if one way ride, take you to ride notes and custom fields form
   * @return {undefined}
   */
  oneWayTrip = () => {
    const bookingData = { ...this.props.bookingData };
    bookingData.status = 'Incomplete';

    _.unset(bookingData, 'currentRideIndex');
    _.unset(bookingData, 'rides');

    if (bookingData.bookingType === 'roundtrip') {
      _.unset(bookingData, 'bookingType');
    }

    const removalData = [
      { name: 'currentRideIndex', value: undefined },
      { name: 'rides', value: undefined },
      { name: 'editPatientInfo', value: undefined }
    ];

    if (bookingData.bookingType === 'roundtrip') {
      removalData.push({ name: 'bookingType', value: undefined });
    }

    this.setState(
      { roundTrip: false, showRoundTripButton: false, showComponents: true },
      () => {
        this.props.updateBookingData(bookingData, true);
        this.props.clearStateData(removalData);
      }
    );
  };

  /**
   * if round trip, enter second leg info
   * @return {undefined}
   */
  roundTrip = () => {
    const bookingData = { ...this.props.bookingData };
    bookingData.bookingType = 'roundtrip';
    bookingData.status = 'Incomplete';
    _.unset(bookingData, 'currentRideIndex');
    _.unset(bookingData, 'rides');
    this.props.updateBookingData(bookingData, true);
    this.setState({
      roundTrip: true,
      showRoundTripButton: false,
      showComponents: true
    });
  };

  // toggles true/false if ride is in past based on checkbox
  setIsPastRide = ({ pastRide }) => {
    const { pastBookingDays = 0 } = this.props.user;
    const bookingData = _.cloneDeep(this.props.bookingData);
    bookingData.pastRide = pastRide;
    if (pastBookingDays) {
      bookingData.pastBookingDays = pastBookingDays;
    }
    this.props.updateBookingData(bookingData, true);
  };

  /**
   * make trip multiple legged
   * @return {undefined}
   */
  multiLeggedTrip = () => {
    const pastBookingDays = this.props.user?.pastBookingDays ?? 0;
    const { tripBookingData, rideBookingData } = this.clearMultiLeggedData();

    if (pastBookingDays) {
      tripBookingData.pastBookingDays = pastBookingDays;
    }

    tripBookingData.currentRideIndex = 0;
    tripBookingData.rides = [rideBookingData];
    tripBookingData.tripCompleted = false;
    tripBookingData.bookingType = 'multileg';
    tripBookingData.status = 'Incomplete';

    this.setState(
      {
        roundTrip: false,
        showComponents: true,
        showRoundTripButton: false,
        multiLeggedTrip: true
      },
      () => this.props.updateBookingData(tripBookingData)
    );
  };

  /**
   * handles ride request, submits to ride request endpoint through redux
   * @param {object} bookingData - booking data passed from component
   * @return {undefined}
   */
  handleRequestBooking = (bookingData = {}) => {
    const {
      originalRideId,
      user,
      availableCards,
      toggleLoading,
      ridesPreAuthorize,
      updateBookingData,
      addColumn
    } = this.props;

    const userRole = user?.role ?? '';

    if (_.isEmpty(bookingData)) {
      bookingData = _.cloneDeep(this.props.bookingData);
    }

    const { repeatAppointmentData, bookingType, currentRideIndex } = bookingData;

    const isOnDemand = bookingType === 'ondemand';
    const isRideShare = isRideShareVehicleId(parseInt(bookingData.transportType));
    const isRepeatAppointment =
      repeatAppointmentData && typeof repeatAppointmentData === 'object';

    let submittedData =
      bookingData.transportMode === 'Public'
        ? mapPublicBooking(bookingData, userRole)
        : mapMultiLegBooking(bookingData, userRole);

    if (isRepeatAppointment) {
      submittedData = _.merge(
        repeatApptBookingHelper(repeatAppointmentData),
        submittedData
      );
    }

    let loadingMessage = 'Requesting Ride...';
    let loadingTimeout = 80;

    if (isRideShare && isOnDemand) {
      loadingMessage = 'Searching For Drivers';
      loadingTimeout = 200;

      if (originalRideId) {
        submittedData.originalRideId = originalRideId;
      }
    }

    toggleLoading(true, loadingMessage, loadingTimeout);

    submittedData.rides = bookingData.rides = this.removeVenueId(bookingData.rides)
      // check for eta and duration and remove it
      .map(ride => {
        if (originalRideId) ride.originalRideId = originalRideId;

        _.unset(ride, 'estimatedCost');
        _.unset(ride, 'eta');

        return ride;
      });

    submittedData.rides = bookingData.rides;

    if (isRideShare && isOnDemand) {
      availableCards(submittedData);
    } else {
      if (currentRideIndex > -1) {
        bookingData.tripCompleted = true;
      } else {
        bookingData.currentRideIndex = bookingType === 'roundtrip' ? 1 : 0;
      }

      ridesPreAuthorize(submittedData);
    }

    updateBookingData(bookingData);
    addColumn();
  };

  /**
   * @param {boolean} toggle - turn loading module on or off
   * @param {string} statusMessage - status message
   * @return {undefined}
   */
  toggleLoading = (toggle, statusMessage = 'Requesting Ride...') => {
    this.props.toggleLoading(toggle, statusMessage);
  };

  /**
   * closes book new ride component and reinitializes map
   * @return {undefined}
   */
  closeRequestNewRide = () => {
    this.props.closeRequestNewRide(true);
    this.props.clearNewMemberForm();
  };

  /**
   * close a form compoennt during edit process
   * @param {string} formType - PatientInfo
   * @return {undefined} - returns nothing
   */
  closeFormComponents = formType => {
    const state = {};

    if (formType === 'PatientInfo') {
      state.showPatientInfoComplete = true;
      state.showPatientForm = false;
    }
    this.setState(state);
  };

  /**
   * show round trip button callback
   * @return {undefined}
   */
  showRoundTripButton = () => {
    this.setState({
      showRoundTripButton: true,
      showComponents: false
    });
  };

  addingRide = addingRide => this.setState({ addingRide });

  /**
   * helper function, turn off all active form elements, this makes editing portions of the form more intuitive
   * @return {object} - returns json of form parameters
   */
  hideAllFormComponents() {
    return {
      showPatientForm: false,
      showRoundTripButton: false
    };
  }

  /**
   * helper function, turn off all display elements
   * @return {object} - returns json of form parameters
   */
  hideAllDisplayComponents() {
    return {
      showPatientInfoComplete: false
    };
  }

  /**
   * for roundtrip or single ride booking, clear data in case of edit
   * @return {object} newBookingData
   */
  clearRoundTripData() {
    const bookingData = _.cloneDeep(this.props.bookingData);

    const newBookingData = _.pick(bookingData, [
      'firstName',
      'lastName',
      'medicalId',
      'phone',
      'phone2',
      'internalNotes',
      'additionalNotes',
      'hospitalId',
      'dateOfBirth',
      'patientCustomFields',
      'compliance',
      'pickupAddress',
      'additionalPassengers',
      'pastRide',
      'pastBookingDays',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'hospitalCoords',
      'showPickupEntrance',
      'showDropoffEntrance',
      'pickupEntranceName',
      'pickupVenueName',
      'dropoffEntranceName',
      'dropoffVenueName',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'transportType',
      'isModal',
      'rides',
      'currentRideIndex',
      'healthPlanId',
      'healthSubPlanId',
      'treatmentId',
      'treatmentName',
      'mobilityType',
      'mobilitySubType',
      'timezoneFormat',
      'memberDefaultMode',
      'pickupVenueId',
      'dropoffVenueId',
      'pickupProviderPhone',
      'dropoffProviderPhone',
      'pickupFacilityName',
      'dropoffFacilityName',
      'pickupProviderName',
      'dropoffProviderName',
      'pickupProviderAddr',
      'dropoffProviderAddr',
      'pickupAdditionalNotes',
      'dropoffAdditionalNotes',
      'pickupProviderNotFound',
      'dropoffProviderNotFound',
      'selectedDate',
      'apptTime',
      'apptEnds',
      'bookingType'
    ]);

    return newBookingData;
  }

  /**
   * for multilegged trip, clear data in case multiple legs is selected
   * @return {object} newBookingData
   */
  clearMultiLeggedData() {
    const bookingData = _.cloneDeep(this.props.bookingData);
    const tripBookingData = _.pick(bookingData, [
      'firstName',
      'lastName',
      'medicalId',
      'phone',
      'phone2',
      'internalNotes',
      'additionalNotes',
      'hospitalCoords',
      'additionalPassengers',
      'pastRide',
      'pastBookingDays',
      'hospitalId',
      'dateOfBirth',
      'patientCustomFields',
      'compliance',
      'isModal',
      'rides',
      'currentRideIndex',
      'healthPlanId',
      'healthSubPlanId',
      'treatmentId',
      'treatmentName',
      'mobilityType',
      'mobilitySubType',
      'timezoneFormat',
      'memberDefaultMode'
    ]);
    let rideData = bookingData;
    if (
      _.has(tripBookingData, 'rides') &&
      _.get(bookingData, 'currentRideIndex', -1) === 0
    ) {
      rideData = tripBookingData.rides[0];
    }
    const rideBookingData = _.pick(rideData, [
      'pickupAddress',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'showPickupEntrance',
      'showDropoffEntrance',
      'pickupEntranceName',
      'pickupVenueName',
      'dropoffEntranceName',
      'dropoffVenueName',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'transportType',
      'additionalNotes',
      'legCompleted',
      'transportTypeName',
      'memberDefaultMode',
      'distance',
      'pickupProviderPhone',
      'dropoffProviderPhone',
      'pickupFacilityName',
      'dropoffFacilityName',
      'pickupProviderName',
      'dropoffProviderName',
      'pickupProviderAddr',
      'dropoffProviderAddr',
      'pickupAdditionalNotes',
      'dropoffAdditionalNotes',
      'pickupProviderNotFound',
      'dropoffProviderNotFound',
      'selectedDate',
      'apptTime',
      'apptEnds',
      'bookingType',
      'pickupProviderId',
      'pickupProviderNpi',
      'dropoffProviderId',
      'dropoffProviderNpi'
    ]);

    return { tripBookingData, rideBookingData };
  }

  /**
   * server does not allow null values for venue mapping id
   * @param {array} rides - rides
   * @return {array} - return rides
   */
  removeVenueId(rides = []) {
    return rides.map(ride => {
      if (_.isNil(ride.pickupVenueId)) {
        _.unset(ride, 'pickupVenueId');
      }
      if (_.isNil(ride.dropoffVenueId)) {
        _.unset(ride, 'dropoffVenueId');
      }
      return ride;
    });
  }

  renderPublicRouteCard = () => {
    const { routeSelected = null } = _.get(this.props, 'scheduleRides', {});
    if (_.isEmpty(routeSelected)) return <div />;
    const publicRouteCards = () =>
      Object.keys(routeSelected).map((v, i) => {
        return (
          <div
            key={i}
            className="patientInfoComplete TransportOptions"
            style={{
              display: 'flex',
              alignItems: 'center',
              borderTop: '1px solid #e0e0e0',
              cursor: 'unset'
            }}
          >
            <PublicRoute
              leg={v}
              key={`rd-${v}`}
              routeData={routeSelected[v]}
              routeDataKey={v}
              hideLeg={true}
              defaultOpen={true}
              handler={() => {}}
              withoutDivider={true}
            />
            {i === 0 ? (
              <div
                className="right"
                style={{ marginLeft: 'auto' }}
                onClick={() => this.props.editPublicRouteOptions()}
              >
                <p className="edit">Edit</p>
              </div>
            ) : null}
          </div>
        );
      });
    return <div>{publicRouteCards()}</div>;
  };

  render() {
    const userRole = _.get(this.props, 'user.userData.role', '');
    let multiLegEnabled = false;
    if (this.props.user.features[MULTI_LEG]) {
      multiLegEnabled = true;
    }
    let roundTripEnabled = true;
    if (this.props.user.features[DISABLE_ROUND_TRIP]) {
      roundTripEnabled = false;
    }
    return (
      <div className="showRideBookingForm">
        <div className="bookingHead">
          <h1>Request a Ride</h1>
          <div className="close" onClick={this.closeRequestNewRide}>
            <SvgClose />
          </div>
          {this.state.showPatientInfoComplete === false ? (
            <div className="arrowDown"></div>
          ) : null}
        </div>
        {this.state.showPatientForm && (
          <PatientInfo
            isRedirect={this.props.isRedirect}
            bookingPhone={this.props.bookingPhone}
            clientUniqueId={this.props.clientUniqueId}
            personalInfo={this.state.personalInfo}
            handlePatientSubmit={this.handlePatientSubmit}
            editForm={this.state.editPatientForm}
            complianceInfo={this.props.complianceInfo}
            bookWithMedicalId={this.props.bookWithMedicalId}
            bookWithHospitalId={this.props.bookWithHospitalId}
            bookWithHealthPlanId={this.props.bookWithHealthPlanId}
            bookWithDateOfBirth={this.props.bookWithDateOfBirth}
            bookWithHealthSubPlanId={this.props.bookWithHealthSubPlanId}
            toggleLoading={this.toggleLoading}
            patientCustomFields={this.props.patientCustomFields}
            editBookedRide={false}
            closeComponent={() => this.closeFormComponents('PatientInfo')}
            editRideBooking={this.state.multi.editRideBooking}
            setComplianceInfo={this.props.setComplianceInfo}
            complianceSelect={this.props.complianceSelect}
          />
        )}
        {this.state.showPatientInfoComplete && (
          <PatientInfoComplete
            editPatientInfo={this.editPatientInfo}
            toggleNotes={this.toggleNotes}
            showAllNotes={this.state.showAllNotes}
            bookingData={this.props.bookingData}
            showDownArrow={this.state.showTransportComplete}
            patientCustomFields={this.props.patientCustomFields}
            complianceInfo={this.props.complianceInfo}
            userRole={userRole}
            disableEdit={this.state.addingRide}
          />
        )}
        {this.state.showRoundTripButton && (
          <RoundTripButton
            pastRideEnabled={this.props.user.features[PAST_BOOKING]}
            pastRide={this.props.bookingData.pastRide}
            oneWayTrip={this.oneWayTrip}
            roundTrip={this.roundTrip}
            setIsPastRide={this.setIsPastRide}
            multiLeggedTrip={this.multiLeggedTrip}
            multiLegEnabled={multiLegEnabled}
            roundTripEnabled={roundTripEnabled}
          />
        )}
        {this.state.showComponents ? (
          <div>
            {this.state.multiLeggedTrip ? (
              <BookRidesMulti
                toggle={this.toggle}
                bookingData={this.props.bookingData}
                handleRequestBooking={this.handleRequestBooking}
                hideMiddleColumn={this.props.hideMiddleColumn}
                addingRide={this.addingRide}
                toggleLoading={this.toggleLoading}
              />
            ) : (
              <BookRidesSingle
                toggle={this.toggle}
                bookingData={this.props.bookingData}
                handleRequestBooking={this.handleRequestBooking}
                hideMiddleColumn={this.props.hideMiddleColumn}
                roundTrip={this.state.roundTrip}
                showRoundTripButton={this.showRoundTripButton}
                toggleLoading={this.toggleLoading}
              />
            )}
          </div>
        ) : null}
        {this.renderPublicRouteCard()}
      </div>
    );
  }
}

BookRides.defaultProps = {
  page: '',
  closeRequestNewRide: () => {},
  closeAndReload: () => {},
  medicalId: '',
  bookWithMedicalId: '',
  bookWithHospitalId: null,
  bookWithHealthPlanId: null,
  bookWithHealthSubPlanId: null,
  bookWithDateOfBirth: null,
  user: {},
  availableVehicleTypes: [],
  updateMap: () => {},
  bookingData: {},
  clearBookingRide: () => {},
  updateRide: () => {},
  updateBookingData: () => {},
  ridesPreAuthorize: () => {},
  ridesPreAuthorizePublic: () => {},
  hospitalData: {},
  patientDetails: {},
  patientCustomFields: [],
  timeCustomFields: [],
  rideCustomFields: [],
  addColumn: () => {},
  resetPatient: () => {},
  complianceInfo: [],
  toggleLoading: () => {},
  hideMiddleColumn: () => {},
  reset: () => {},
  originalRideId: 0,
  complianceSelect: {},
  setComplianceInfo: () => {},
  getVehicles: () => {}
};

BookRides.propTypes = {
  page: PropTypes.string,
  closeRequestNewRide: PropTypes.func,
  closeAndReload: PropTypes.func,
  user: PropTypes.object,
  availableVehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  updateMap: PropTypes.func,
  bookingData: PropTypes.object,
  clearBookingRide: PropTypes.func,
  updateRide: PropTypes.func,
  updateBookingData: PropTypes.func,
  ridesPreAuthorize: PropTypes.func,
  ridesPreAuthorizePublic: PropTypes.func,
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  patientDetails: PropTypes.object,
  patientCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  timeCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  addColumn: PropTypes.func,
  resetPatient: PropTypes.func,
  complianceInfo: PropTypes.array,
  toggleLoading: PropTypes.func,
  medicalId: PropTypes.string,
  bookWithMedicalId: PropTypes.string,
  bookWithHospitalId: PropTypes.number,
  bookWithHealthPlanId: PropTypes.number,
  bookWithHealthSubPlanId: PropTypes.number,
  bookWithDateOfBirth: PropTypes.string,
  hideMiddleColumn: PropTypes.func,
  reset: PropTypes.func,
  originalRideId: PropTypes.number,
  complianceSelect: PropTypes.object,
  setComplianceInfo: PropTypes.func,
  getVehicles: PropTypes.func
};

const mapStateToProps = state => ({
  user: state.user,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  hospitalNames: state.user.hospitalNames,
  nodeUserType: state.user.nodeUserType,
  availableVehicleTypes: state.user.availableVehicleTypes,
  bookingData: state.bookingData,
  patientDetails: state.patients.patientDetails,
  patientCustomFields: state.user.patientCustomFields,
  timeCustomFields: state.user.timeCustomFields,
  rideCustomFields: state.user.rideCustomFields,
  scheduleRides: state.scheduleRides
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ridesPreAuthorize: data => ridesPreAuthorize(data),
      clearStateData: data => clearStateData(data),
      ridesPreAuthorizePublic: data => ridesPreAuthorizePublic(data),
      clearBookingRide: () => clearBookingRide(),
      updateBookingData: (data, replace) => updateBookingData(data, replace),
      resetPatient: () => resetPatient(),
      reset: () => reset(),
      // temporary, for on demand rides - this will be moved over to lumen eventually
      lyftRidesOnDemandOptions: data => lyftRidesOnDemandOptions(data),
      getVehicles: (hospitalId, subPlanId) => getVehicles(hospitalId, subPlanId),
      clearNewMemberForm: () => clearNewMemberForm(),
      availableCards: data => availableCards(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookRides);
