import {type NavOption} from '../../MemberProfiles.helper';

/**
 * Generates the navigational options based on selected Id
 * @param {number} selectedId 
 * @returns {NavOption[]}
 */
export function generateNavOptions(selectedId: number): NavOption[] {
  return [
    {
      dataTip: 'FreeForm',
      label: 'Free Form',
      parentForm: 'custom-fields',
      childForm: 'freeform',
      route: `/member-profiles/${selectedId}/custom-fields/freeform`
    },
    {
      dataTip: 'Validation',
      label: 'Validation',
      parentForm: 'custom-fields',
      childForm: 'validation',
      route: `/member-profiles/${selectedId}/custom-fields/validation`
    }
  ];
}
