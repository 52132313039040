import React, { useCallback, useEffect, useState } from 'react';
import { useAppSelector } from '~/Modules';
import type {
  AddressApprovedProviderModel,
  AddressApprovedProviderNotFoundModel
} from '~/models';
import { getApprovedProviders } from '../services/approvedProvider.service';
import type { ApprovedProviderRecord } from '~/types';

/** Convert the provider options into an array of JSX elements. */
function generateProviderOptions(options: ApprovedProviderRecord[]) {
  return options.map(opt => ({
    value: opt,
    label: (
      <div>
        <span className="font-bold">{opt.name}</span>
        <br />
        <div>{opt.address}</div>
        <span>
          {opt.phone} | NPI: {opt.npiId}
        </span>
      </div>
    )
  }));
}

/** Standardized mapping function used in both the useApprovedProviders hook and the
 * Search redux slice. Eventually the search slice will be removed and this function
 * will not need to be exported. */
export function mapProviderResponseBody(data: BE.ApprovedProvider[]) {
  return data.map(d => ({
    address: d.provider_address,
    name: d.provider_name,
    phone: d.provider_phone,
    npiId: d.provider_npi,
    id: d.id
  }));
}

export type ApprovedProvidersHook = [
  // Indicates if the search is currently in progress.
  boolean,
  // Array of provider options for the dropdown.
  ReturnType<typeof generateProviderOptions>,
  // Event handler for approved provider input or search.
  (_: number, search: string) => void,
  // Event handler for loading more approved providers.
  () => void
];

/** A hook to simplify adding approved provider search to components. This partially
 * duplicates data stored in the search slice of the store. Eventually that should be removed
 * altogether but legacy RBF still depends on it.*/
export default function useApprovedProviders(
  hospitalId: number,
  address?: AddressApprovedProviderModel | AddressApprovedProviderNotFoundModel
): ApprovedProvidersHook {
  const [page, setPage] = useState(0);
  const [search, setSearch] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const providerHistory = useAppSelector(s => s.patients.approvedProviderRideHistory);
  const [providerOptions, setProviderOptions] = useState(providerHistory);

  const hasMore = providerOptions.length % (20 * (page + 1)) === 0;

  /** Event handler for approved provider input search. */
  const handleProviderInput = useCallback(
    (_, search: string) => {
      // Minimum of 3 character search
      if (search.length < 3) return;

      setSearch(search);
      setIsSearching(true);
      getApprovedProviders(search, hospitalId, 0, 20)
        .then(({ data }) => setProviderOptions(mapProviderResponseBody(data)))
        .finally(() => {
          setPage(0);
          setIsSearching(false);
        });
    },
    [setIsSearching, setPage]
  );

  const handleLoadMore = useCallback(() => {
    if (!hasMore) return;

    setIsSearching(true);
    getApprovedProviders(search, hospitalId, (page + 1) * 20, 20)
      .then(({ data }) => {
        setPage(p => p + 1);
        setProviderOptions(providerOptions.concat(mapProviderResponseBody(data)));
      })
      .finally(() => setIsSearching(false));
  }, [search, hasMore, setIsSearching, setPage]);

  /** Determine if the currently selected value exists in the options. If it
   * doesn't then we return a single option from the current value. */
  useEffect(() => {
    if (!address?.value) return;

    if (!providerOptions?.some(p => p.id === address.id)) {
      const newOptions: Partial<ApprovedProviderRecord>[] = [
        {
          id: address.id,
          name: address.name,
          address: address.value,
          phone: address.phone
        }
      ];

      if ('npi' in address) newOptions[0].npiId = address.npi?.toString() ?? '';

      setProviderOptions(newOptions as ApprovedProviderRecord[]);
    }
  }, [address]);

  return [
    isSearching,
    generateProviderOptions(providerOptions),
    handleProviderInput,
    handleLoadMore
  ] as const;
}
