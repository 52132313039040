import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import PublicRoute from './PublicRoute';

class PublicOptions extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {};
  }

  continue = () => {
    const selectedLegs = {};
    this.props.confirmationCardData.forEach((confirmationCardData, leg) => {
      confirmationCardData.routes.routes.forEach((routeData, routeDataKey) => {
        const selected =
          typeof this.props.transit.legs[leg] === 'undefined'
            ? routeDataKey === 0
            : this.props.transit.legs[leg] === routeDataKey;
        if (selected) {
          selectedLegs[leg] = routeData;
        }
      });
    });
    this.props.submitData(selectedLegs);
  };

  render() {
    return (
      <div className="publicOptions">
        {_.get(this.props, 'confirmationCardData', []).length > 0
          ? this.props.confirmationCardData.map((confirmationCardData, leg) => {
            return confirmationCardData.routes.routes.map((routeData, routeDataKey) => {
              const selected =
                  typeof this.props.transit.legs[leg] === 'undefined'
                    ? routeDataKey === 0
                    : this.props.transit.legs[leg] === routeDataKey;
              return (
                <PublicRoute
                  selected={selected}
                  fare={_.get(routeData, 'fare.text', '')}
                  defaultOpen={this.props.transit.selectedLeg === leg}
                  handler={this.props.handleTransitSelect}
                  transit={
                    routeDataKey === _.get(this.props, 'transit.route', null)
                      ? this.props.transit
                      : null
                  }
                  routeDataKey={routeDataKey}
                  leg={leg}
                  routeData={routeData}
                  key={`rd-${routeDataKey}`}
                />
              );
            });
          })
          : null}
        <div className="bookRideButtons clearfix">
          <button className={`requestRideButton active`} onClick={this.continue}>
            Confirm option
          </button>
        </div>
      </div>
    );
  }
}

PublicOptions.propTypes = {
  handleVehicleClick: PropTypes.func,
  errorClass: PropTypes.string,
  errorMessage: PropTypes.array,
  vehicles: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  chooseVehicleIdPublic: PropTypes.func,
  toggleLoading: PropTypes.func,
  multileg: PropTypes.bool,
  submittedData: PropTypes.object,
  confirmationCardData: PropTypes.array,
  bookingData: PropTypes.object,
  handleTransitSelect: PropTypes.func,
  transit: PropTypes.object,
  submitData: PropTypes.func
};

PublicOptions.defaultProps = {
  handleVehicleClick: () => {},
  errorClass: '',
  errorMessage: [],
  vehicles: [],
  chooseVehicleIdPublic: () => {},
  toggleLoading: () => {},
  multileg: false,
  submittedData: {},
  confirmationCardData: [],
  bookingData: {},
  handleTransitSelect: () => {},
  submitData: () => {},
  transit: null
};

const mapStateToProps = state => ({
  submittedData: state.scheduleRides.ride,
  confirmationCardData: _.get(state, 'scheduleRides.confirmationCardData', [])
});

export default connect(mapStateToProps)(PublicOptions);
