import { Is } from '@SRHealth/frontend-lib';
import type { AppThunk } from '~/Modules';
import type { ComplianceRecord } from '~/types';
import {
  fundingSourceFactory,
  FUNDING_SOURCE_DEFAULT,
  type FundingSourceProps,
  type FundingSourcePropertyRule,
  TREATMENTS_RULE,
  type FundingSourceModel
} from '~/models';
import { mapHealthPlanCustomField } from '~/utilities/compliance';
import { setFundingSource } from '~/Modules/rideBooking';
import type { DependentField, RideCustomField, UserStore } from '~/Modules/user';
import { isEmpty } from '~/utilities/helperFunctions';

/** Retrieves funding source data from the memberProfile and user
 * store to prepopulate the funding source section. */
export const initializeFundingSourceThunk = (): AppThunk<FundingSourceModel> => {
  return function (dispatch, getState) {
    const { user, patients, memberProfile } = getState();

    const fundingSource: FundingSourceProps = FUNDING_SOURCE_DEFAULT();
    const rules: FundingSourcePropertyRule[] = [];

    let hospitals = user?.hospitalData || [];

    // For Case Managers, the endpoint returns a single hospital as opposed
    // to an array like it does for everyone else.
    if (!Array.isArray(hospitals)) hospitals = [hospitals];

    if (hospitals.length === 1) {
      fundingSource.facilityId = hospitals[0].id;
    } else if (patients.rideHistory.length) {
      fundingSource.facilityId = patients.rideHistory[0]?.hospitalId;
    }

    // The patient's health subplan has the treatments feature enabled
    // Add a rule for it.
    if (patients.patientDetails?.treatments) {
      rules.push(TREATMENTS_RULE);
    }

    // Map Dependent Fields
    if (!isEmpty(user?.dependentFields)) {
      rules.push(...mapDependentField(user.dependentFields));
    }

    // Map Compliance Fields
    if (Array.isArray(user.complianceInfo) && user.complianceInfo.length) {
      for (const field of user.complianceInfo) {
        /** A compliance type of hidden means that the value should
         * be mapped from the member's data during ride booking.
         * This is a way for us to capture a snapshot of member
         * profile data at the time of booking. */
        if (field.input_type === 'hidden') {
          const fieldVal = mapHealthPlanCustomField(
            field,
            memberProfile.formData.freeForm
          );
          fundingSource.complianceInfo[field.id] = fieldVal;
        }

        const rule = generateComplianceInfoRule(
          field,
          fundingSource?.complianceInfo?.[field.id]
        );
        rules.push(rule);
      }
    }

    // HOSPITAL RIDE Custom Fields
    rules.push(...genHospitalCustomFieldRules(user, user?.rideCustomFields));

    // HOSPITAL PATIENT Custom fields
    rules.push(...genHospitalCustomFieldRules(user, user?.patientCustomFields));

    const fundingSourceModel = fundingSourceFactory(fundingSource);
    for (const rule of rules) {
      fundingSourceModel.addRule(...rule);
    }

    dispatch(setFundingSource(fundingSourceModel));

    return fundingSourceModel;
  };
};

/** Recursively map dependent fields and generate rules for them. Does not
 * validate child option dependencies, only that the selection is a valid
 * option for the given field. */
function mapDependentField(
  field: DependentField,
  rules: FundingSourcePropertyRule[] = []
) {
  const validOptions = field.compliance_options.map(opt => opt.id);

  rules.push([
    `dependent-field-${field.id}`,
    'dependentFields',
    (v: FundingSourceProps['dependentFields'], _m, isCommit) => {
      // Allow partially populated dependent fields before throwing errors
      if (!isCommit) return true;

      if (!(field.id in v)) {
        throw new Error(`Missing dependent field for ${field.id}`);
      }

      if (!validOptions.includes(v[field.id] as number)) {
        throw new Error(`Invalid value for ${field.id}`);
      }

      return true;
    }
  ]);

  if (field?.dependent_field) {
    mapDependentField(field.dependent_field, rules);
  }

  return rules;
}

/** Iterates through each of the custom fields and generate an appropriate
 *  rule for it. Returns an array of model rules to be merged in to the
 * FundingSource model. */
function genHospitalCustomFieldRules(
  _user: UserStore,
  fields: Record<number, BE.HospitalCustomField[]> | undefined
) {
  const rules: FundingSourcePropertyRule[] = [];

  // For some reason this is being formatted differently if you log in as a
  // a hospital owner or hospital network manager so... standardize it here.
  // See `src/Modules/user/index.ts:564-577`
  if (Array.isArray(fields) && fields.length) {
    fields = { [fields[0].hospitalId]: fields };
  }

  if (fields && Object.keys(fields).length) {
    for (const hospitalId in fields!) {
      for (const field of fields[hospitalId]) {
        if (field.status !== 'Active') continue;

        const rule = genHospitalCustomFieldRule(hospitalId, field);
        rules.push(rule);
      }
    }
  }

  return rules;
}

/**
 * Generate a rule for a ride custom field. The value of the field
 * must be a string. Rules will only apply if the facility ID matches.
 */
function genHospitalCustomFieldRule(hospitalId: string, field: RideCustomField) {
  return [
    `${hospitalId}-custom-field-${field.id}`,
    'hospitalCustomFields',
    (v: FundingSourceProps['hospitalCustomFields'], m, isCommit) => {
      // Allow partially populated custom fields before throwing errors
      if (!isCommit || m.facilityId?.toString() !== hospitalId) return true;

      if (!(field.id in v) || Is.Empty(v[field.id])) {
        throw new Error(
          `Missing custom field for hospital ${hospitalId} and field id ${field.id}`
        );
      }

      Is.String.strict(v[field.id]);

      return true;
    }
  ] as FundingSourcePropertyRule;
}

/**
 * Generates a compliance rule for the given compliance record.
 */
export function generateComplianceInfoRule(
  record: ComplianceRecord,
  val?: unknown
): FundingSourcePropertyRule {
  switch (record.input_type) {
    case 'text':
      return genTextComplianceInfoRule(record);
    case 'select':
      return genSelectComplianceInfoRule(record);
    case 'hidden':
      return genHiddenComplianceInfoRule(record, val);
  }
}

/**
 * Generate a compliance info rule for a text field. The value
 * of the field must be a string.
 */
function genTextComplianceInfoRule(record: ComplianceRecord) {
  return [
    `compliance-field-${record.id}`,
    'complianceInfo',
    (v: FundingSourceProps['complianceInfo'], _m, isCommit) => {
      // Allow partially populated compliance Info before throwing errors
      if (!isCommit) return true;

      if (!(record.id in v)) {
        throw new Error(`Missing compliance info for ${record.id}`);
      }

      return !Is.Empty(v[record.id]) && Is.String.strict(v[record.id]);
    }
  ] as FundingSourcePropertyRule;
}

/**
 * Generate a compliance info rule for a select field. The value of
 * the field must exist in the array of possible options.
 */
function genSelectComplianceInfoRule(record: ComplianceRecord) {
  const possibleValues = record.compliance_options.map(o => o.id);

  return [
    `compliance-field-${record.id}`,
    'complianceInfo',
    (v: FundingSourceProps['complianceInfo'], _m, isCommit) => {
      // Allow partially populated compliance Info before throwing errors
      if (!isCommit) return true;

      if (!(record.id in v)) {
        throw new Error(`Missing compliance info for ${record.id}`);
      }

      if (!possibleValues.includes(v[record.id] as number)) {
        throw new Error(`Invalid value for ${record.id}`);
      }

      return true;
    }
  ] as FundingSourcePropertyRule;
}

/**
 * Generate a compliance info rule for a hidden field. The value
 * simply must be present in the compliance info object.
 */
function genHiddenComplianceInfoRule(record: ComplianceRecord, val: unknown) {
  return [
    `compliance-field-${record.id}`,
    'complianceInfo',
    (v: FundingSourceProps['complianceInfo'], _m, isCommit) => {
      // Allow partially populated compliance Info before throwing errors
      if (!isCommit) return true;

      if (!(record.id in v)) {
        throw new Error(`Missing compliance info for ${record.id}`);
      }

      if (v[record.id] !== val) {
        throw new TypeError(`Invalid value for ${record.id}`);
      }

      return true;
    }
  ] as FundingSourcePropertyRule;
}
