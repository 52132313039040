/**
 * Yes this is a "duplicate" of the GoogleMap.js component.
 *
 * Yes I know this is bad practice. If I wasn't afraid of how much would break
 * from rewriting the GoogleMap.js component, then I would've just done that.
 *
 * Instead this component is used specifically for the MemberSavedAddress feature.
 * I'll continue lying to myself that someday I'll rewrite them to use the same
 * component.
 * -Burt, 12/2022
 */
import React from 'react';
import { useDispatch, batch } from 'react-redux';
import { SavedAddressActions } from '~/Modules/savedAddress';
import { useAppSelector } from '~/Modules';

const CENTER_OF_US: google.maps.LatLngLiteral = { lat: 39.8283, lng: -98.5795 };

const GoogleMap: React.FC<google.maps.MapOptions> = () => {
  const dispatch = useDispatch();

  const savedAddress = useAppSelector(state => state.savedAddress);
  const addressList = useAppSelector(state => state.patients?.savedAddresses ?? []);

  const ref = React.useRef<HTMLDivElement>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [markers, setMarkers] = React.useState<google.maps.Marker[]>([]);

  /**
   * Unsets all existing markers from the map. Optionally replaces them
   * with a new set
   * @param replacement
   */
  const clearMarkers = (replacement: google.maps.Marker[] = []) => {
    markers.forEach(marker => marker.setMap(null));
    setMarkers(replacement);
  };

  /**
   * Updates the current state to use a new set of markers
   * @param markers
   */
  const updateMarkers = (markers: google.maps.Marker[]) => {
    clearMarkers(markers);
  };

  /**
   * Creates a draggable marker at the given position
   * @param position
   * @param draggable
   * @returns
   */
  const createMarker = (position: google.maps.LatLngLiteral, draggable = false) => {
    const marker = new google.maps.Marker({
      position,
      map,
      draggable
    });

    if (draggable) {
      // Add event listeners for newly created marker
      marker.addListener('dragend', (m: google.maps.MapMouseEvent) => {
        const lat = m.latLng?.lat() ?? 0;
        const lng = m.latLng?.lng() ?? 0;

        batch(() => {
          dispatch(SavedAddressActions.setLatitude(lat));
          dispatch(SavedAddressActions.setLongitude(lng));
        });
      });
    }

    return marker;
  };

  /**
   * Initiates the google map object
   */
  React.useEffect(() => {
    if (ref.current && !map) {
      const newMap = new window.google.maps.Map(ref.current, {
        zoom: 4,
        center: CENTER_OF_US,
        clickableIcons: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false
      });

      setMap(newMap);
    }
  }, [ref, map]);

  /**
   * Once the map has loaded, we will convert either the
   * addressList into a set of non-draggable markers or
   * will convert the savedAddress data into a single
   * draggable marker
   */
  React.useEffect(() => {
    const { latitude, longitude } = savedAddress;

    if (map) {
      // Display Saved Address with non-zero lat and long
      if (latitude && longitude) {
        const position = { lat: latitude, lng: longitude };

        map.setCenter(position);
        map.setZoom(18);

        const marker = createMarker(position, true);

        // Adds a pop-up window above the pin
        new google.maps.InfoWindow({
          content: '<span style="font-weight: bold">Drag the Pin</span>',
          pixelOffset: new google.maps.Size(0, -5)
        }).open({ anchor: marker });

        updateMarkers([marker]);
      } else if (addressList.length > 0) {
        // Display list of patient saved addresses
        const newMarkers = addressList.map(({ latitude, longitude }, index) => {
          const marker = createMarker({ lat: latitude, lng: longitude }, false);
          marker.setLabel(String.fromCharCode(index + 1 + 64));

          return marker;
        });

        updateMarkers(newMarkers);
      }
    }
  }, [map, savedAddress.address, addressList]);

  /**
   * If we're displaying multiple markers, attempt
   * to fit all of them in the map view
   */
  React.useEffect(() => {
    const bounds = new google.maps.LatLngBounds();

    if (map && markers.length > 1) {
      markers.forEach(marker => {
        const position = marker.getPosition();
        if (position) bounds.extend(position);
      });

      map.fitBounds(bounds);
    }
  }, [map, markers]);

  /**
   * Clean up markers on unmount
   */
  React.useEffect(() => {
    return () => {
      markers.forEach(marker => marker.setMap(null));
      setMarkers([]);
    };
  }, []);

  return <div ref={ref} id="google_map" style={{ height: '100%' }} />;
};

export default GoogleMap;
