import React from 'react';

const SvgGooglePin = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18.4806 1.78246C17.4623 1.46119 16.3712 1.2854 15.2437 1.2854C11.9522 1.2854 9.00619 2.77052 7.03613 5.11034L12.0977 9.36566L18.4806 1.78246Z" fill="#1A73E8" />
    <path d="M7.03592 5.11035C5.41316 7.03775 4.52425 9.47689 4.52637 11.9965C4.52637 14.0089 4.92644 15.6456 5.58717 17.1065L12.0974 9.36567L7.03592 5.11035Z" fill="#EA4335" />
    <path d="M15.2435 7.9046C16.0249 7.90531 16.7898 8.1293 17.4481 8.55017C18.1065 8.97104 18.6309 9.57127 18.9595 10.2802C19.2882 10.9891 19.4074 11.7772 19.3032 12.5516C19.199 13.326 18.8757 14.0545 18.3713 14.6513L24.7482 7.06808C23.4393 4.54625 21.1827 2.64723 18.4744 1.78833L12.1035 9.37153C12.4874 8.91182 12.9676 8.54212 13.5102 8.28862C14.0529 8.03512 14.6446 7.90402 15.2435 7.9046Z" fill="#4285F4" />
    <path d="M15.2432 16.1001C12.9822 16.1001 11.1455 14.2634 11.1455 12.0024C11.1429 11.0396 11.4823 10.1073 12.1033 9.37158L5.58691 17.1124C6.70227 19.5795 8.55109 21.5617 10.4545 24.0591L18.3711 14.6513C17.9864 15.1058 17.5073 15.4708 16.9671 15.7211C16.4269 15.9713 15.8386 16.1006 15.2432 16.1001Z" fill="#FBBC04" />
    <path d="M18.2139 26.6234C21.7903 21.0345 25.9547 18.4946 25.9547 11.9964C25.9547 10.2143 25.5182 8.53521 24.7484 7.05615L10.4609 24.0592C11.0671 24.8533 11.6793 25.6959 12.2734 26.6294C14.4435 29.9876 13.8434 32.0001 15.2436 32.0001C16.6439 32.0001 16.0438 29.9815 18.2139 26.6234Z" fill="#34A853" />
  </svg>
);

export default SvgGooglePin;
