import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { confirm, ridesPreAuthorizeVehicleMulti } from '~/Modules/scheduleRides';
import _ from 'lodash-es';
import NemtOptions from './NemtOptions';
import LyftOptions from './LyftOptions';
import PublicOptions from './PublicOptions';
import { errorParse } from '~/utilities/helperFunctions';
import {
  LYFT_ERROR_MESSAGE,
  NEMT_ERROR_MESSAGE,
  LYFT_VEHICLE_ID,
  UBER_VEHICLE_ID
} from '~/constants';

class TransportOptions extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      vehicles: [],
      vehiclesSelected: []
    };
    this.modalParams = {};
    this.bookedRides = [];
    this.submittedData = {};
  }

  componentDidMount() {
    const { bookingData, toggleLoading } = this.props;
    let vehicles = [];
    toggleLoading(false);

    // TODO:Uber — Oliver
    const { bookingType, transportType } = bookingData;
    const isRideShare =
      transportType === LYFT_VEHICLE_ID || transportType === UBER_VEHICLE_ID;
    // TODO:Uber — Oliver

    if (_.has(bookingData, 'currentRideIndex')) {
      this.bookedRides = _.get(this.props, 'submittedData.submittedData.rides', []);
      this.submittedData = this.props.submittedData.submittedData;
      vehicles = _.get(this.props, 'confirmationCardData', []);
    } else if (bookingType === 'ondemand' && isRideShare) {
      vehicles = _.get(this.props, 'confirmationCardData[0].rides', []);
    } else {
      vehicles = _.get(this.props, 'confirmationCardData.rides', []);
    }

    if (_.isNil(vehicles)) {
      vehicles = [];
    }
    this.setState({ vehicles });
  }

  componentDidUpdate(prevProps) {
    const { bookingData } = this.props;
    let vehicles = [];
    let prevVehicles = [];

    if (_.has(bookingData, 'currentRideIndex')) {
      vehicles = _.get(this.props, 'confirmationCardData', []);
      prevVehicles = _.get(prevProps, 'confirmationCardData', []);
    } else {
      vehicles = _.get(this.props, 'confirmationCardData.rides', []);
      prevVehicles = _.get(prevProps, 'confirmationCardData.rides', []);
    }
    if (!_.isEqual(vehicles, prevVehicles)) {
      if (_.isNil(vehicles)) {
        vehicles = [];
      }
      this.setState({ vehicles });
    }
  }

  /**
   * handles when you click on an nemt to assign a vehicle
   * @param {object} vehicle - vehicle info for lyft ride
   * @return {undefined}
   */
  handleVehicleClickLyft = vehicle => {
    /**
     * TODO:UBER — ONDEMAND
     */
    this.props.chooseLyftVehicleId(vehicle);
    this.props.toggleLoading(true);
  };

  render() {
    const { vehicles } = this.state;
    const { transportType } = this.props.bookingData;
    const standardErrorMessage = this.props.lyft
      ? LYFT_ERROR_MESSAGE
      : NEMT_ERROR_MESSAGE;

    const errorMessage = errorParse(this.props.error, standardErrorMessage);
    let errorClass = 'errorList';

    if (errorMessage.length === 1) {
      errorClass = 'noAvailCompanies';
    }
    if (_.get(this.props, 'auth.bookingAlert', '') !== '') {
      errorMessage.push(this.props.auth.bookingAlert);
    }
    if (_.get(this.props, 'auth.alert', '') !== '') {
      errorMessage.push(this.props.auth.alert);
    }
    let transportOptionsType = 'nemt';

    /**
     * TODO:UBER
     */
    if (this.props.lyft) {
      transportOptionsType = 'lyft';
    }
    if (this.props.bookingData.transportMode === 'Public') {
      transportOptionsType = 'public';
    }

    return (
      <div className="TransportOptions">
        {
          {
            nemt: (
              <NemtOptions
                vehicles={vehicles}
                errorClass={errorClass}
                errorMessage={errorMessage}
                chooseVehicleId={this.props.chooseVehicleId}
                vehiclesSelected={this.state.vehiclesSelected}
                bookingData={this.props.bookingData}
                toggleLoading={this.props.toggleLoading}
                chooseVehiclesMultipleRides={this.props.chooseVehiclesMultipleRides}
              />
            ),
            lyft: (
              <LyftOptions
                handleVehicleClick={this.handleVehicleClickLyft}
                vehicles={vehicles}
                errorClass={errorClass}
                errorMessage={errorMessage}
                changeTransport={this.props.changeTransport}
                transportType={transportType}
              />
            ),
            rideShare: {},
            public: (
              <PublicOptions
                vehicles={vehicles}
                errorClass={errorClass}
                errorMessage={errorMessage}
                handleTransitSelect={this.props.handleTransitSelect}
                submitData={this.props.chooseVehicleIdPublic}
                transit={this.props.transit}
                vehiclesSelected={this.state.vehiclesSelected}
                bookingData={this.props.bookingData}
                toggleLoading={this.props.toggleLoading}
              />
            )
          }[transportOptionsType]
        }
      </div>
    );
  }
}

TransportOptions.defaultProps = {
  auth: {},
  confirmationCardData: {},
  chooseVehicleId: () => {},
  chooseLyftVehicleId: () => {},
  error: {},
  toggleLoading: () => {},
  bookingData: {},
  chooseVehiclesMultipleRides: () => {},
  chooseVehicleIdPublic: () => {},
  submittedData: {},
  ridesPreAuthorizeVehicleMulti: () => {},
  lyft: false,
  changeTransport: () => {},
  transit: null,
  handleTransitSelect: () => {}
};

TransportOptions.propTypes = {
  auth: PropTypes.object,
  // below confirmationCardData - oneOfType is for backwards compatibility (lumen endpoint vs laravel endpoint). eventually they will always be arrays.
  // laravel endpoint the data is an object.
  // lumen it's an array.
  confirmationCardData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  chooseVehicleId: PropTypes.func,
  chooseLyftVehicleId: PropTypes.func,
  error: PropTypes.object,
  toggleLoading: PropTypes.func,
  bookingData: PropTypes.object,
  chooseVehiclesMultipleRides: PropTypes.func,
  chooseVehicleIdPublic: PropTypes.func,
  submittedData: PropTypes.object,
  ridesPreAuthorizeVehicleMulti: PropTypes.func,
  lyft: PropTypes.bool,
  changeTransport: PropTypes.func,
  handleTransitSelect: PropTypes.func,
  transit: PropTypes.object
};

const mapStateToProps = state => ({
  auth: state.scheduleRides.auth,
  submittedData: state.scheduleRides.ride,
  confirmationCardData: state.scheduleRides.confirmationCardData,
  timestamp: state.scheduleRides.timestamp // unused value, but needed to tell the component to re-render when this updates
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      confirm: data => confirm(data),
      ridesPreAuthorizeVehicleMulti: params => ridesPreAuthorizeVehicleMulti(params)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(TransportOptions);
