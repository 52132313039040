/* eslint-disable no-console */
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getSubPlanMobilityTypes,
  updateSubPlanMobilityTypes
} from '~/services/subplan.service';
import type { HealthSubPlanBenefit } from '~/types';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import { LYFT_VEHICLE_ID, UBER_VEHICLE_ID } from '~/constants';

interface Props {
  user: Record<string, unknown>;
  vehicleTypes: Record<string, BE.VehicleRecord>;
  selectedPlan: Record<string, string | number | boolean | HealthSubPlanBenefit>;
}

interface State {
  isLoading: boolean;
  isLocked: boolean;
  errors: string[];
  selection: Record<string, BE.VehicleRecord>;
}

class PlanMobilityTypes extends Component<Props, State> {
  static propTypes: Record<string | number | symbol, any>;
  static defaultProps: Record<string | number | symbol, any>;

  constructor(props: Props) {
    super(props);
    this.state = {
      errors: [],
      selection: {},
      isLocked: true,
      isLoading: true
    };
  }

  get subPlanId(): string {
    const selectedPlan = this.props.selectedPlan;

    if (!selectedPlan.id) {
      throw new Error('Could not located sub plan id');
    }

    const subPlanId = selectedPlan.id?.toString() ?? null;
    if (!subPlanId) {
      console.error('Invalid sub plan id was found.', subPlanId);
      throw new Error('Invalid sub plan id was found.');
    }

    return subPlanId;
  }

  componentDidMount(): void {
    const subPlanId = this.subPlanId;

    getSubPlanMobilityTypes(subPlanId)
      .then(({ data, message, httpCode }) => {
        const { vehicleTypes } = this.props;
        let selection = {};

        if (httpCode !== 200) {
          console.error(`Error encountered. ${message}`);
          return;
        }

        data.forEach(vehicleTypeId => {
          const record = vehicleTypes[vehicleTypeId.toString()] ?? null;

          if (!record) {
            console.warn(
              `Received sub plan vehicleTypeId that does not ` +
                `exist in the array of vehicle types. Found ${vehicleTypeId}`
            );
          } else {
            selection[vehicleTypeId] = vehicleTypes[vehicleTypeId];
          }
        });

        // If the sub plan does not have any mobility types selected then pre-fill them
        if (Object.keys(selection).length < 1) {
          selection = this.preSelectOptions();
        }

        this.setState({ selection, isLoading: false });
      })
      .catch(_e => {
        console.error('Failed to load vehicle sub plan types');
      });
  }

  /**
   * Event logic triggered when a vehicle record is clicked. Alternatively
   * adds or removes the vehicle record to the state selection key
   * @param e
   * @param vehicle
   */
  handleVehicleClick = (_e: React.SyntheticEvent, vehicle: BE.VehicleRecord) => {
    const selection = this.state.selection;
    const newSelection = { ...selection };

    if (selection[vehicle.id]) {
      delete newSelection[vehicle.id];
    } else {
      newSelection[vehicle.id] = vehicle;
    }

    this.setState({ selection: newSelection });
  };

  /**
   * Event handler for when the Edit/Save button is clicked
   */
  handleEditBtnClick = async () => {
    const errors: string[] = [];
    const { selection, isLocked } = this.state;

    // Only save if at least one option has been selected
    if (!isLocked) {
      if (Object.keys(selection).length < 1) {
        // You must select at least 1 Mobility Type.
        errors.push('You must select at least 1 Mobility Type.');
      } else {
        await updateSubPlanMobilityTypes(this.subPlanId, Object.keys(selection)).catch(
          err => console.error(err)
        );
      }
    }

    if (errors.length > 0) {
      this.setState({ errors });
      return;
    }

    this.setState({ isLocked: !isLocked, errors: errors });
  };

  /**
   * Computed property for the "checked" value for each
   * vehicle record.
   * @param id
   * @returns
   */
  isChecked = (id: string | number): boolean => {
    const selection = this.state.selection;

    return selection[id] ? true : false;
  };

  /**
   * Preselects the default options for a subplan if they haven't
   * yet chosen any mobility types. Changes the state automatically.
   * @returns
   */
  preSelectOptions = (): State['selection'] => {
    const selection = this.state.selection;

    if (Object.keys(selection).length > 0) {
      console.warn(
        'Incorrectly attempted to preselect options while selection has ' +
          'already been populated.'
      );

      return selection;
    }
    const vehicleTypes = { ...this.props.vehicleTypes };

    const _preselection = Object.entries(vehicleTypes).filter(([_key, val]) => {
      return ['AMB', 'WCV', 'WCX'].indexOf(val.nickName) > -1;
    });

    const preselection = Object.fromEntries(_preselection);
    if (vehicleTypes[LYFT_VEHICLE_ID]) {
      preselection[LYFT_VEHICLE_ID] = vehicleTypes[LYFT_VEHICLE_ID];
    }

    if (vehicleTypes[UBER_VEHICLE_ID]) {
      preselection[UBER_VEHICLE_ID] = vehicleTypes[UBER_VEHICLE_ID];
    }

    return preselection;
  };

  render() {
    const { errors, isLoading, isLocked } = this.state;
    const { vehicleTypes: _vehicleTypes = {} } = this.props;

    // Convert the Object to an array so we can iterate through
    // using map
    const vehicleTypes = Object.values(_vehicleTypes);

    const buttonLabel = isLocked ? 'Edit' : 'Save';
    const errorMsg = errors.join('\r\n');

    if (isLoading) {
      return <LoadingComponent />;
    }

    return (
      <div className="PlanMobilityTypes">
        <div className="subTabContent">
          <h1>Mobility Types</h1>
          <ul id="mobility-types">
            {Object.values(vehicleTypes).map((vehicle: BE.VehicleRecord) => {
              return (
                <PlanMobilityTypeOption
                  key={vehicle.id}
                  vehicle={vehicle}
                  isChecked={this.isChecked(vehicle.id)}
                  isDisabled={this.state.isLocked}
                  onClick={this.handleVehicleClick}
                />
              );
            })}
          </ul>

          <div id="edit-btn-wrapper">
            <button id="mobility-edit-btn" onMouseDown={this.handleEditBtnClick}>
              {buttonLabel}
            </button>
            <span id="mobility-error-msg">{errorMsg}</span>
          </div>
        </div>
      </div>
    );
  }
}

interface PlanMobilityTypeOptionProps {
  vehicle: BE.VehicleRecord;
  isChecked: boolean;
  isDisabled: boolean;
  onClick?: (e: React.SyntheticEvent, vehicle: BE.VehicleRecord) => void;
}

/**
 * @param props.vehicle
 * @param props.isChecked
 * @param props.isDisabled
 * @param prop.onClick
 * @returns
 */
const PlanMobilityTypeOption = ({
  vehicle,
  isChecked,
  isDisabled,
  onClick = () => {}
}: PlanMobilityTypeOptionProps) => {
  const id = vehicle.id.toString();
  const modelName = vehicle.modelName;

  const className = isDisabled ? 'disabled' : '';

  /**
   * Middleware to handle the click event
   * @param e
   * @returns
   */
  const handleClick = (e: React.SyntheticEvent) => {
    if (isDisabled) {
      return;
    }

    return onClick(e, vehicle);
  };

  return (
    <li className={className} title={vehicle.comments ?? ''} onMouseDown={handleClick}>
      <label htmlFor={`${id}-checkbox`}>{modelName}</label>
      <input
        id={`${id}-checkbox`}
        type="checkbox"
        checked={isChecked}
        disabled={isDisabled}
      />
    </li>
  );
};

PlanMobilityTypes.defaultProps = {
  user: {},
  vehicleTypes: {}
} as Props;

const mapStateToProps = state => ({
  user: state.user,
  vehicleTypes: state.user.vehicleTypes
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PlanMobilityTypes);
