import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Loading from 'react-loading';
const TIMEOUT = 10 * 1000; // 10 Second timeout, default

Modal.setAppElement('#app_container');

class LoadingModal extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      forceClose: false
    };
  }

  /**
   * begin timer
   * @return {undefined}
   */
  componentDidMount () {
    if (this.props.closeTimeout !== false) {
      this.timeoutId = window.setTimeout(
        () => {
          this.setState({forceClose: true});
        },
        this.props.closeTimeout
      );
    }
  }

  /**
   * no render unless isOpen or forceClose changes
   * @param {object} nextProps - next props
   * @param {object} nextState - next state
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.isOpen === this.props.isOpen && this.state.forceClose === nextState.forceClose) {
      return false;
    }
    return true;
  }

  /**
   * clear timeout
   * @return {undefined}
   */
  componentWillUnmount () {
    if (this.props.closeTimeout !== false) {
      window.clearTimeout(this.timeoutId);
    }
  }

  render () {
    return (
      <Modal
        ariaHideApp={false}
        isOpen={this.props.isOpen && !this.state.forceClose}
        style={
          {
            overlay : {
              zIndex: 100,
              backgroundColor: '#FFFFFF22'
            },
            content : {
              zIndex: 100,
              top : '50%',
              left : '50%',
              right : 'auto',
              bottom : 'auto',
              marginRight : '-50%',
              transform : 'translate(-50%, -50%)'
            }
          }
        }
      >
        <Loading type={'cubes'} delay={0} className='loading' color={'#aaa'}/>
        <span className='loadingText'>{this.props.label}</span>
      </Modal>
    );
  }

}

LoadingModal.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.array,
  closeTimeout: PropTypes.number,
  label: PropTypes.string
};

LoadingModal.defaultProps = {
  isOpen: false,
  children: [],
  closeTimeout: TIMEOUT,
  label: 'Loading Rides...'
};

export default LoadingModal;
