import { Icon, TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';

const MapPlaceholder = () => {
  return (
    <div
      className={`flex flex-col items-center justify-center h-full w-full bg-${TOKENS.SURFACE.PRIMARY.XLT}`}
      style={{ boxShadow: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.25)' }}
    >
      <Icon
        type="Location"
        className={`text-${TOKENS.ICON.ACTIVE} fill-current`}
        style={{ height: '80px', width: '80px', paddingBottom: '32px' }}
      />
      <span className={`text-${TOKENS.FONT.PRIMARY} text-center text-xl`}>
        Please enter an address to
        <br />
        view map
      </span>
    </div>
  );
};

export default MapPlaceholder;
