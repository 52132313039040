import React from 'react';
import PropTypes from 'prop-types';

const SvgMouse = props => {
  return (
    <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M13 1.07V9h7c0-4.08-3.05-7.44-7-7.93zM4 15c0 4.42 3.58 8 8 8s8-3.58 8-8v-4H4v4zm7-13.93C7.05 1.56 4 4.92 4 9h7V1.07z"/>
    </svg>
  );
};

SvgMouse.propTypes = {
  className: PropTypes.string
};

SvgMouse.defaultProps = {
  className: ''
};

export default SvgMouse;