import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { getPatientInfoThunk, reset as resetPatient } from '~/Modules/patients';
import {
  formatPhoneNumber,
  getHospitalCoords,
  getNationalNumber,
  isEmpty
} from '~/utilities/helperFunctions';
import { mapHealthPlanCustomField } from '~/utilities/compliance';
import {
  NEW_PATIENT_RESTRICT_BOOKING_MESSAGE,
  NEW_PATIENT_RESTRICT_PURCHASE_ISSUE_MESSAGE,
  PATIENT_INELIGIBLE_MESSAGE,
  NEW_PATIENT_HEALTH_PLAN_REQUEST,
  PAGES,
  HOSPITAL_NETWORK_ROLES
} from '~/constants';
import { updateBookingData, clearBookingRide } from '~/Modules/bookingData';
import DropDown from '../../../DropDown';
import InputText from '../../../InputText';
import TextAreaField from '../../../TextAreaField';
import SvgClose from '../../../Svgs/SvgClose';
import ErrorModal from '../../../ErrorModal';
import SearchDropdown from '../../../SearchDropdown/SearchDropdown';
import SvgInfo from '../../../Svgs/SvgInfo';
import ReactTooltip from 'react-tooltip';
import RBFPassengerDropdown from '~/Shared/Components/AdditionalPassengers/RBFPassengerDropdown';
import { getMemberProfile } from '~/Modules/memberProfile/actions';
import ComplianceFields from './ComplianceFields';
import DependentFieldsContainer from '~/Shared/Components/DependentFields/DependentFieldsContainer';
import AddNewMember from '~/Shared/Components/AddNewMember/AddNewMember';
import { getMemberPermissions } from '~/Modules/members/reducer';
import { ELIGIBILITY_API, PASSENGER_EDIT } from '~/constants/features';
import { handleOptIn, updateOptOutStatus } from '~/services/phone.service';
import OptInModal from '~/Pages/MemberProfiles/Components/OptInModal/OptInModal';

const SELF_FUNDED = 9999999;
const PHONE_2_TEXT = 'Alternate Phone only stored for this ride';

class PatientInfo extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);

    this.state = {
      showPatientIdError: false,
      showPatientInfoForm: false,
      showPatientInfoError: false,

      medicalId: '',
      firstName: '',
      lastName: '',
      internalNotes: '',
      additionalNotes: '',
      phone: '',
      phone2: '',
      dateOfBirth: '',
      hospitalId: '',
      hospitalName: '',
      treatmentId: '',
      treatmentName: '',
      additionalPassengers: props.bookingData.additionalPassengers,
      healthPlanId: '', //corresponds to funding source id
      healthSubPlanId: '', //corresponds to funding source id
      fundingSourceName: '',
      healthSubPlanName: '',
      patientCustomFields: {},
      complianceSelectError: {},
      patientCustomFieldsError: {},

      dependentFields: {},
      selectedDependentFields: {},
      dependentFieldNames: [],
      dependentFieldError: {},
      dependentFieldSelections: null,

      showModal: false,
      hospitalError: false,
      treatmentError: false,
      complianceError: false,
      patientErrorMessage: '',
      disableFields: false,
      disableSearchButton: true,
      disableAddPatient: false,
      showErrorModal: false,
      phoneError: false,
      phone2Error: false,
      medicalIdError: false,
      fundingSourceError: false,
      phoneErrorMessage: '',
      // focus to switch from label to placeholder
      phoneFocus: false,
      firstNameError: false,
      lastNameError: false,
      showHospitalSearch: false,
      showTreatmentSearch: false,
      fundingSource: [],
      healthSubPlans: [],
      complianceSelect: _.cloneDeep(props.complianceSelect),
      formTouched: false
    };

    this.addNewMemberCheck = false;
    this.triggerCheck = false;
    this.useFundingSource = false;
    this.useHealthSubPlan = false;
  }

  /**
   * in scenarios where we edit a ride (or are rebooking it) where we've already entered medical id and name, etc
   * we want to set the fields to the values the initially entered
   * @return {undefined} lifecycle function returns nothing
   */
  componentDidMount = () => {
    this.props.getMemberPermissions();

    const state = {};
    const props = _.cloneDeep(this.props);

    const {
      bookingData,
      members,
      user,
      editForm,
      hospitalData,
      editBookedRide,
      editRideBooking,
      bookWithHealthSubPlanId,
      bookWithMedicalId,
      bookWithHospitalId,
      bookWithHealthPlanId,
      bookWithDateOfBirth
    } = this.props;

    const userRole = this.props?.userData?.role ?? '';

    // if we have funding sources, get them and assign to state for render
    const fundingSourceVals = this.getFundingSource();
    state.fundingSource = fundingSourceVals.fundingSource;

    // Initialize the Compliance Fields
    Object.assign(state, this.initComplianceFields());

    if (editForm) {
      // sets the state for the edit ride form
      const additionalPassengers = this.state.additionalPassengers.length
        ? this.state.additionalPassengers
        : bookingData.additionalPassengers || [];

      state.firstName = bookingData.firstName;
      state.lastName = bookingData.lastName;
      state.internalNotes = bookingData.internalNotes;
      state.phone = getNationalNumber(bookingData.phone);
      state.phone2 = getNationalNumber(bookingData.phone2);
      state.medicalId = bookingData.medicalId;
      state.additionalPassengers = additionalPassengers;
      state.dateOfBirth = bookingData?.dateOfBirth ?? '';
      state.showPatientInfoForm = true;
      state.disableFields = true;

      if (members.success) {
        state.firstName = members.memberProfile.member_data.firstName;
        state.lastName = members.memberProfile.member_data.lastName;
        state.internalNotes = members.memberProfile.member_data.internalNotes;
        state.phone = bookingData.phone || members.memberProfile.member_data.mobileNo;

        const dob = moment(members.memberProfile.member_data.dateOfBirth).format(
          'MM/DD/YYYY'
        );
        state.dateOfBirth = dob === 'Invalid date' ? '' : dob;
      }

      if (state.fundingSource.length) {
        const selectedFundingSource = _.find(state.fundingSource, {
          id: state.healthPlanId
        });
        if (typeof selectedFundingSource !== 'undefined') {
          state.fundingSourceName = selectedFundingSource.name;
        } else {
          state.fundingSourceName = 'Self Funded';
        }
      }

      // check for health plan id
      if (bookingData?.healthPlanId) {
        state.healthPlanId = bookingData.healthPlanId;

        if (bookingData.healthPlanId !== SELF_FUNDED) {
          this.useFundingSource = true;
          this.useHealthSubPlan = true;
        }
      }

      if (!HOSPITAL_NETWORK_ROLES.includes(userRole)) {
        state.hospitalId = hospitalData.id;
        state.hospitalCoords = getHospitalCoords(state.hospitalId, hospitalData);
      } else {
        state.hospitalId = parseInt(bookingData.hospitalId, 10);

        let hospital = _.find(hospitalData, { id: state.hospitalId });
        if (!hospital && members.success) {
          hospital = _.find(hospitalData, {
            id: members.memberProfile.member_data.hospitalId
          });
        }

        state.hospitalCoords = getHospitalCoords(state.hospitalId, hospitalData, true);
        state.hospitalName = hospital?.name ?? '';
      }

      if (!editBookedRide && !editRideBooking) {
        this.resetBookingData();
      }
    } else if (!HOSPITAL_NETWORK_ROLES.includes(userRole)) {
      state.hospitalId = hospitalData.id;
      state.hospitalCoords = getHospitalCoords(state.hospitalId, hospitalData);
    }

    // Treatment
    if ('treatmentId' in bookingData) {
      state.treatmentId = bookingData.treatmentId;
      state.treatmentName = bookingData?.treatmentName ?? '';
    }

    let patientCustomFields = [];

    if (HOSPITAL_NETWORK_ROLES.includes(userRole)) {
      patientCustomFields = props.patientCustomFields[bookingData.hospitalId]
        ? props.patientCustomFields[bookingData.hospitalId]
        : [];
    } else {
      patientCustomFields = props.patientCustomFields;
    }

    if (
      bookingData?.patientCustomFields &&
      Object.keys(bookingData.patientCustomFields).length
    ) {
      state.patientCustomFields = {};

      for (let i = 0; i < patientCustomFields.length; i++) {
        const key = patientCustomFields[i].id;
        state.patientCustomFields[key] = bookingData?.patientCustomFields[key] ?? '';
      }
    }

    if (bookWithHealthSubPlanId) {
      state.healthSubPlanId = bookWithHealthSubPlanId;
    }

    if (bookWithMedicalId) {
      if (!HOSPITAL_NETWORK_ROLES.includes(userRole)) {
        state.hospitalId = parseInt(this.props.bookWithHospitalId, 10);
        state.hospitalCoords = getHospitalCoords(state.hospitalId, hospitalData);
      } else if (bookWithHospitalId) {
        const hospital = hospitalData.find(({ id }) => id === bookWithHospitalId);

        // hospital is undefined if the hospital id is not in the hospital network
        // some passengers booked under a different hospital in a different network
        // if that's the case then we need to clear out the hospital id and make them select a new hospital
        // once hospital is selected then the rest of the data will be available
        if (hospital) {
          state.hospitalId = bookWithHospitalId;
          state.hospitalName = hospital.hospitalName;
          state.hospitalCoords = getHospitalCoords(state.hospitalId, hospitalData, true);
        }
      }

      if (bookWithHealthPlanId !== null && fundingSourceVals.hasHP) {
        state.healthPlanId = bookWithHealthPlanId;

        // get health plan name out of funding source array and set it
        if (state.fundingSource.length) {
          const selectedFundingSource = _.find(state.fundingSource, {
            id: state.healthPlanId
          });

          const healthSubPlans = user?.healthPlans ?? [];

          const healthSubPlan = {};
          for (const plan of healthSubPlans) {
            if (plan.healthSubPlanId === bookWithHealthSubPlanId) {
              healthSubPlan.id = plan.healthSubPlanId;
              healthSubPlan.name = plan.healthSubPlanName;
            }
          }

          if (typeof selectedFundingSource !== 'undefined') {
            state.fundingSourceName = selectedFundingSource.name;
            state.healthSubPlans = [healthSubPlan];
            state.healthSubPlanName = healthSubPlan.name;
            this.useFundingSource = true;
            this.useHealthSubPlan = true;
          } else {
            state.fundingSourceName = 'Self Funded';
            this.useFundingSource = false;
            this.useHealthSubPlan = false;
          }
        }
      } else if (state.fundingSource.length) {
        // null health plan id but we do have funding sources
        // then default to self funded
        state.fundingSourceName = 'Self Funded';
      }

      if (bookWithDateOfBirth) state.dateOfBirth = bookWithDateOfBirth;

      this.setState(state, () => {
        if (!bookingData.medicalId) this.getPatientData(bookWithMedicalId, true);
      });
    } else {
      this.setState(state);
    }
  };

  /**
   * We are going to handle changes in state as a result of success and messaging
   * for all of the steps of the ride booking process.
   * @param {object} prevProps - previous props
   * @param {object} prevState - previous state
   * @return {void}
   *
   */
  componentDidUpdate(prevProps, prevState) {
    const { dependentFieldSelections, dependentFieldError, formTouched, fundingSource } =
      this.state;

    const {
      bookingData,
      complianceSelect,
      patientDetails,
      getPatientError,
      getPatientTimestamp,
      members
    } = this.props;

    const newState = {};

    if (!_.isEqual(complianceSelect, prevProps.complianceSelect)) {
      newState['complianceSelect'] = complianceSelect;
    }

    if (
      !_.isEqual(dependentFieldSelections, prevState.dependentFieldSelections) &&
      formTouched
    ) {
      this.checkPatientInfoValid();
    }

    if (
      !_.isEqual(dependentFieldError, prevState.dependentFieldError) &&
      !_.isEqual(dependentFieldError)
    ) {
      newState['showPatientInfoError'] = true;
    }

    if (
      !isEmpty(patientDetails) &&
      getPatientTimestamp !== prevProps.getPatientTimestamp
    ) {
      this.triggerCheck = false;

      if (this.addNewMemberCheck) {
        this.props.toggleLoading(false);
        this.addNewMemberCheck = false;
        if (!members.success) this.setErrorModal();
      } else {
        this.populatePassenger();
      }

      newState['patientErrorMessage'] = '';
    }

    // cleared as part of switching hospitals
    if (
      isEmpty(patientDetails) &&
      !isEmpty(prevProps.patientDetails) &&
      isEmpty(bookingData)
    ) {
      Object.assign(newState, this.resetFieldData());
    }

    if (
      getPatientError?.message &&
      getPatientTimestamp !== prevProps.getPatientTimestamp
    ) {
      const newPassengerCheck = this.newPassengerCheck(getPatientError);
      this.props.toggleLoading(false);
      this.triggerCheck = false;

      if ((this.addNewMemberCheck && newPassengerCheck) || members.success) {
        this.addNewMemberCheck = false;

        newState['showPatientInfoForm'] = true;
        newState['showPatientIdError'] = false;
        newState['patientErrorMessage'] = '';
      } else {
        Object.assign(newState, this.resetFieldData());

        if (members.success) {
          newState.firstName = members.memberProfile.member_data.firstName || '';
          newState.lastName = members.memberProfile.member_data.lastName || '';
          newState.internalNotes = members.memberProfile.member_data.internalNotes || '';
          newState.dateOfBirth = members.memberProfile.member_data.dateOfBirth || '';
          newState.phone =
            bookingData.phone || members.memberProfile.member_data.mobileNo || '';
        }

        let errorMessage = getPatientError.message;
        const reason = getPatientError.reason;

        newState.disableAddPatient = true;
        newState.showPatientInfoForm = false;

        this.addNewMemberCheck = false;
        if (newPassengerCheck) {
          newState.disableAddPatient = false;
        } else {
          switch (reason) {
            case 'Eligible':
              errorMessage = PATIENT_INELIGIBLE_MESSAGE;
              break;

            case 'RestrictBooking':
              errorMessage = fundingSource.length
                ? NEW_PATIENT_HEALTH_PLAN_REQUEST
                : NEW_PATIENT_RESTRICT_BOOKING_MESSAGE;
              break;

            case 'Purchase':
              errorMessage = NEW_PATIENT_RESTRICT_PURCHASE_ISSUE_MESSAGE;
          }
        }
        newState.showPatientIdError = true;
        newState.patientErrorMessage = errorMessage;
      }
    }

    if (Object.keys(newState).length) this.setState(newState);
  }

  /**
   * Initialize the compliance fields. Returns an object
   * to be either merged into a state update or passed to setState()
   * @returns {object}
   */
  initComplianceFields() {
    const { bookingData, complianceInfo } = this.props;

    const complianceSelect = {};

    // If we have compliance data in the bookingData redux state already, then get it into complianceSelect
    if (bookingData?.compliance) {
      const complianceBookingData = bookingData.compliance;

      for (const complianceField of complianceInfo) {
        const fieldName = complianceField.input_name;
        if (complianceField.input_type === 'text') {
          complianceSelect[complianceField.id] = complianceBookingData[fieldName];
        } else if (complianceField.input_type === 'select') {
          const option = complianceField.compliance_options.find(
            ({ option_value }) => complianceBookingData[fieldName] === option_value
          );
          if (option) {
            complianceSelect[complianceField.id] = option.option_value;
          }
        }
      }
    }

    if (this.props.freeForm) {
      for (const field of complianceInfo) {
        if (field.input_type === 'hidden') {
          const val = mapHealthPlanCustomField(field, this.props.freeForm);
          if (val !== undefined) {
            complianceSelect[field.id] = val;
          }
        }
      }
    }

    return { complianceSelect };
  }

  /**
   * Determines the appropriate message to display
   * @returns {string} ''
   */
  getErrorMsg = () => {
    const { showPatientIdError, showPatientInfoError, patientErrorMessage } = this.state;

    if (showPatientInfoError && !patientErrorMessage) {
      return 'Fix the highlighted fields.';
    }

    if (showPatientIdError || showPatientInfoError) {
      return patientErrorMessage.trim();
    }

    return '';
  };

  /**
   * Determines if the phone field should be disabled
   * @returns {boolean} T/F
   */
  getShouldDisablePhone = () => {
    const { userData, user } = this.props;
    const disableFields = this.state.disableFields;

    if (userData?.role !== 'CaseManager') {
      return false;
    }

    if (user.features[PASSENGER_EDIT]) {
      return false;
    }

    return disableFields;
  };

  /**
   * Retrieves the available hospitals for the active user
   * @returns {array} []
   */
  getHospitals = () => {
    const hospitalData = this.props?.hospitalData ?? [];

    if (!this.isNetwork()) return [];

    return hospitalData.map(({ hospitalName, id }) => ({
      label: hospitalName,
      value: id
    }));
  };

  /**
   * Lookup to determine if user is in network
   * @returns {boolean} T/F
   */
  isNetwork() {
    return HOSPITAL_NETWORK_ROLES.includes(this.props.userData.role ?? null);
  }

  /**
   * populates passenger data from the api
   * @return {undefined} - returns nothing
   */
  populatePassenger = () => {
    const { patientDetails, toggleLoading, members, bookingData } = this.props;

    const state = {
      medicalId: patientDetails.medicalId,
      highRisk: patientDetails.highRisk,
      firstName: patientDetails.first_name,
      lastName: patientDetails.last_name,
      internalNotes: patientDetails.internalNotes,
      phone: getNationalNumber(patientDetails.phone),
      phone2: getNationalNumber(patientDetails.phone2),
      additionalPassengers: patientDetails.additionalPassengers,
      timezoneFormat: patientDetails.timezoneFormat,
      showPatientIdError: false,
      showPatientInfoForm: true,
      disableFields: true,
      patientErrorMessage: '',
      patient_custom_fields: {},
      complianceSelectError: {},
      patientCustomFieldsError: {},
      showPatientInfoError: false,
      memberDefaultMode: patientDetails.member_default_mode,
      mobilityType: patientDetails.mobility_type,
      mobilitySubType: patientDetails.mobility_sub_type,
      disableSearchButton: true
    };

    if (members.success) {
      const memberData = members.memberProfile.member_data;

      state.firstName = memberData.firstName || '';
      state.lastName = memberData.lastName || '';
      state.internalNotes = memberData.internalNotes || '';
      state.phone = bookingData.phone || memberData.mobileNo || '';

      const dateOfBirth = moment(memberData.dateOfBirth).format('MM/DD/YYYY');
      state.dateOfBirth = dateOfBirth === 'Invalid date' ? '' : dateOfBirth;
    }

    state.dateOfBirth = patientDetails?.dateOfBirth;

    if (patientDetails?.rideData && patientDetails.rideData?.from) {
      const rideData = patientDetails.rideData;
      state.pickupAddress = rideData?.from || '';
      state.pickupLatitude = rideData?.fromLat || 0;
      state.pickupLongitude = rideData?.fromLng || 0;
      state.pickupZipcode = rideData?.fromZip || null;

      if (rideData?.transportType) {
        state.transportType = rideData.transportType;
      }
    }

    this.setState(state, () => toggleLoading(false));
  };

  /**
   * wrapper for form event handler
   * @param {event} e - synthetic event
   * @return {undefined}
   */
  handleFormSubmission = e => {
    e.preventDefault();
    this.handlePatientInfo();
  };

  /**
   * retrieves data if medical id for patient is in the system
   * @param {object} e - event object
   * @returns {undefined} returns nothing
   */
  checkMedicalId = e => {
    e?.preventDefault();

    const { members, patientDetails } = this.props;

    let getPatientData = false;

    if (isEmpty(patientDetails)) {
      getPatientData = true;
    }

    if (this.state.medicalId !== patientDetails.medicalId) {
      getPatientData = true;
    }

    getPatientData ||=
      this.useFundingSource && this.state.healthPlanId !== patientDetails.healthPlanId;

    getPatientData ||=
      this.useHealthSubPlan &&
      this.state.healthSubPlanId !== patientDetails.healthSubPlanId;

    // don't search if adding a new medical id
    if (this.addNewMemberCheck) {
      getPatientData = false;
      !members.success && this.setErrorModal();
      this.addNewMemberCheck = false;
    }

    if (members.success) {
      getPatientData = true;
      this.addNewMemberCheck = false;
    }

    if (getPatientData) {
      const mid = this.state.medicalId || members.data.medicalId;
      this.getPatientData(mid, false);
    }
  };

  /**
   * @param {string} medicalId - parameter value from field
   * @param {bool} rebook - if this is a ride rebook, assign medicalIdVal to param
   * @return {undefined}
   */
  getPatientData = async (medicalId, rebook) => {
    const { bookingData, patientDetails, members } = this.props;

    let val = this.state.medicalId || members?.data?.medicalId;
    if (rebook) val = medicalId;

    const params = { medicalId: val, hospitalId: this.state.hospitalId };

    const newState = {};

    if (members.success) {
      const { member_data: memberData } = members.memberProfile;

      newState.firstName = memberData.firstName;
      newState.lastName = memberData.lastName;
      newState.internalNotes = memberData.internalNotes;
      newState.medicalId = memberData.medicalId;
      newState.phone = getNationalNumber(bookingData.phone || memberData.mobileNo);
      newState.showPatientInfoForm = true;

      const dateOfBirth = moment(memberData.dateOfBirth).format('MM/DD/YYYY');
      newState.dateOfBirth = dateOfBirth === 'Invalid date' ? '' : dateOfBirth;

      if (memberData?.health_plan_id > 0) {
        newState.healthPlanId = memberData.health_plan_id;
        params.healthPlanId = memberData.health_plan_id;

        if (memberData?.health_sub_plan_id > 0) {
          newState.healthSubPlanId = memberData.health_sub_plan_id;
          params.healthSubPlanId = memberData.health_sub_plan_id;
        }
      } else {
        this.useFundingSource = false;
        this.useHealthSubPlan = false;
      }

      params.hospitalId = memberData.hospitalId;
      params.dateOfBirth = memberData.dateOfBirth;
    }

    if (this.useFundingSource && this.state.healthPlanId) {
      params.healthPlanId = this.state.healthPlanId;

      // in this case healthSubPlan comes from location redirect
      if (this.state.healthSubPlanId) {
        params.healthSubPlanId = this.state.healthSubPlanId;
      }

      // in this case dateOfBirth comes from location redirect
      if (this.state.dateOfBirth) {
        params.dateOfBirth = this.state.dateOfBirth;
      }
    }

    if (this.props.bookWithHealthPlanId) {
      params.healthPlanId = this.props.bookWithHealthPlanId;
    }

    // NOTE: It isn't obvious under what conditions are we overriding the results of
    // member.success?
    if (this.props.bookWithHealthSubPlanId) {
      params.healthSubPlanId = this.props.bookWithHealthSubPlanId;
    }

    if (patientDetails?.medicalId) {
      this.props.resetPatient();
    }

    if (medicalId) {
      this.props.toggleLoading(true, 'Getting Passenger Info...');
      await this.props.getPatientInfo(params);
      this.getMemberProfile();

      if (members.success) {
        const { member_data: memberData } = members.memberProfile;

        newState.firstName = memberData.firstName;
        newState.lastName = memberData.lastName;
        newState.internalNotes = memberData.internalNotes;
        newState.medicalId = memberData.medicalId;
        newState.phone = getNationalNumber(bookingData.phone || memberData.mobileNo);
        newState.showPatientInfoForm = true;

        const dateOfBirth = moment(memberData.dateOfBirth).format('MM/DD/YYYY');
        newState.dateOfBirth = dateOfBirth === 'Invalid date' ? '' : dateOfBirth;

        if (memberData?.health_plan_id > 0) {
          newState.healthPlanId = memberData.health_plan_id;
          params.healthPlanId = memberData.health_plan_id;

          if (memberData?.health_sub_plan_id > 0) {
            newState.healthSubPlanId = memberData.health_sub_plan_id;
            params.healthSubPlanId = memberData.health_sub_plan_id;
          }
        }
      }
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }
  };

  /**
   * Simple utility to correctly format the request to load
   * member data from either an eligibility api or internal DB
   */
  getMemberProfile = () => {
    const params = {};

    const useEligibilityApi = this.props.user.features[ELIGIBILITY_API];
    const clientUniqueId = this.props.personalInfo.client_unique_id;

    if (useEligibilityApi && clientUniqueId && clientUniqueId !== 'NULL') {
      params['passengerId'] = clientUniqueId;
      params['subPlanId'] = this.props.patientDetails.health_sub_plan_id;
      params['externalSubPlanId'] = this.props.personalInfo.external_sub_plan_id;
    } else if (this.props.patientDetails.id) {
      params['passengerId'] = this.props.patientDetails.id;
    } else if (this.props.patientDetails.medicalId) {
      params['passengerId'] = this.props.patientDetails.medicalId;
    }

    if ('passengerId' in params) {
      this.props.getMemberProfile(params);
    }
  };

  /**
   * click this link to add new patient ID and patient Info.
   * this function does not grab existing members. That happens on blur
   * in checkMedicalId function.
   * this function will show the patientInfo form.
   * @param {event} e - event
   * @return {undefined} - returns nothing
   */
  addNewMember = e => {
    if (this.state.medicalId === '' && !this.props.members.success) {
      this.setState({
        showPatientIdError: true,
        patientErrorMessage: 'Please enter an id.'
      });
    } else if (this.newPassengerCheck(this.props.getPatientError)) {
      this.setState({
        showPatientInfoForm: true,
        showPatientIdError: false
      });
    } else {
      this.addNewMemberCheck = true;
      this.checkMedicalId(e);
    }
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changeText = e => {
    const { patientDetails } = this.props;
    const id = e.target.id;
    const val = e.target.value;
    let stateObj = {};
    const focusIds = ['dateOfBirth', 'medicalId'];
    stateObj[id] = val;
    if (focusIds.indexOf(id) > -1) {
      stateObj.disableSearchButton = false;
    }
    const patientDetailsMedicalId = _.get(patientDetails, 'medicalId', '');

    // medical id was already searched and we have patient details.
    // if someone changes the medical id after a search has completed and resulted in a patient
    // we want to clear out that patient data and do another search.
    if (id === 'medicalId') {
      if (val !== patientDetailsMedicalId && patientDetailsMedicalId !== '') {
        stateObj = this.resetFieldData();
        stateObj[id] = val;
        stateObj.disableSearchButton = false;
        stateObj.showPatientInfoForm = false;
      } else {
        if (val === '') {
          stateObj.disableSearchButton = true;
        }
        if (this.state.fundingSource.length > 0 && this.state.healthPlanId === '') {
          stateObj.disableSearchButton = true;
        }
      }
    }

    /**
     * if the phone field is dirty, we want to check the opt out status
     * for that phone number. If the phone number is opted out, we want to
     * show the opt out message.
     */

    if (id === 'phone' || id === 'phone2') {
      stateObj.isPhoneFieldDirty = true;
    }

    if (this.triggerCheck && id !== 'medicalId') {
      this.setState(stateObj, this.checkPatientInfoValid);
    } else {
      this.setState(stateObj);
    }
  };

  /**
   * handler for date of birth, sets dob in state
   * @param {string} dateOfBirth - date of birth in 'MM/DD/YYYY' format
   * @param {boolean} error - invalid date of birth which turns dropdown gold
   * @return {undefined} - returns nothing
   */
  handleDateOfBirth = (dateOfBirth, error = false) => {
    const { patientDetails } = this.props;
    let disableSearchButton = false;
    let stateObj = {};
    if (dateOfBirth !== patientDetails.dateOfBirth) {
      stateObj = this.resetFieldData();
      stateObj.showPatientInfoForm = false;
    }
    if (this.state.medicalId === '') {
      disableSearchButton = true;
    }
    if (this.useFundingSource && this.state.healthPlanId === '') {
      disableSearchButton = true;
    }
    if (error) {
      disableSearchButton = true;
    }
    if (this.triggerCheck) {
      this.setState(
        {
          ...stateObj,
          dateOfBirth
        },
        this.checkPatientInfoValid
      );
    } else {
      this.setState({
        ...stateObj,
        dateOfBirth,
        disableSearchButton
      });
    }
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  handleComplianceText = e => {
    const complianceSelect = _.clone(this.state.complianceSelect);
    const complianceSelectError = _.clone(this.state.complianceSelectError);

    const idInt = e.target.id.split('_')[1];
    complianceSelect[idInt] = e.target.value;
    complianceSelectError[idInt] = e.target.value === '';

    this.setState(
      { complianceSelect, complianceSelectError },
      () => this.triggerCheck && this.checkPatientInfoValid()
    );
  };

  /**
   * handles text input
   * @param {object} e - event
   * @returns {undefined} returns nothing
   */
  changeTextCustom = e => {
    const id = e.target.id;
    const val = e.target.value;
    const patientCustomFields = _.clone(this.state.patientCustomFields);
    const patientCustomFieldsError = _.clone(this.state.patientCustomFieldsError);
    const idArr = id.split('_');
    const idInt = idArr[1];
    patientCustomFields[idInt] = val;
    if (val === '') {
      patientCustomFieldsError[idInt] = true;
    } else {
      patientCustomFieldsError[idInt] = false;
    }

    if (this.triggerCheck) {
      this.setState(
        {
          patientCustomFields,
          patientCustomFieldsError
        },
        this.checkPatientInfoValid
      );
    } else {
      this.setState({
        patientCustomFields,
        patientCustomFieldsError
      });
    }
  };

  /**
   * adding enter functionality for medical id search, retrieves data if medical id for patient is in the system
   * @param {object} e - event object
   * @returns {undefined} returns nothing
   */
  handleEnterMedical = e => {
    if (e.key === 'Enter' && e.target.value.length > 0) {
      this.checkMedicalId(e);
    }
  };

  /**
   * Get all the form data and patient data and add it to redux
   * @return {undefined}
   */
  handlePatientInfo = async () => {
    const valid = this.checkPatientInfoValid();

    if (!valid) {
      return this.setState({ showPatientInfoError: true });
    }

    let params = _.pick(this.state, [
      'status',
      'firstName',
      'lastName',
      'internalNotes',
      'phone',
      'phone2',
      'hospitalId',
      'hospitalCoords',
      'mobilityType',
      'mobilitySubType',
      'timezoneFormat',
      'memberDefaultMode',
      'additionalPassengers',
      'medicalId'
    ]);

    // If this isn't an edit or rebook and the user has a default home address
    // then use that as the default
    if (!('pickupAddress' in this.props.bookingData)) {
      const defaultAddress = _.pick(this.state, [
        'pickupAddress',
        'pickupLatitude',
        'pickupLongitude',
        'pickupZipcode'
      ]);

      Object.assign(params, defaultAddress);
    }

    params.isModal = false;
    params.editPatientInfo = false;
    params.status = params?.status ?? 'Incomplete';

    // prevent date of birth from being cleared out when editing booked ride
    if (!this.props.editRide) {
      params.dateOfBirth = this.state.dateOfBirth;
    }

    if (
      params.dropoffLatitude &&
      params.dropoffLongitude &&
      params.pickupLatitude &&
      params.pickupLongitude
    ) {
      params.pickupLatitude = parseFloat(params.pickupLatitude);
      params.pickupLongitude = parseFloat(params.pickupLongitude);
      params.dropoffLatitude = parseFloat(params.dropoffLatitude);
      params.dropoffLongitude = parseFloat(params.dropoffLongitude);
    }

    // patient custom fields
    const patientCustomFields = this.getPatientCustomFields();
    if (patientCustomFields.length > 0) {
      const patientCustom = {};

      for (let i = 0; i < patientCustomFields.length; i++) {
        const patientCustomField = patientCustomFields[i];
        const patientVal = this.state.patientCustomFields[patientCustomField.id];
        if (!_.isNil(patientVal) && patientVal !== '') {
          patientCustom[patientCustomField.id] = patientVal;
        }
      }

      params.patientCustomFields = patientCustom;

      if (params.hospitalId === '') {
        params.hospitalId = this.props.hospitalData.id;
      }
    }

    // compliance information
    if (this.props.complianceInfo.length > 0) {
      const complianceCustom = {};

      for (let i = 0; i < this.props.complianceInfo.length; i++) {
        const complianceCustomField = this.props.complianceInfo[i];
        const complianceVal = this.state.complianceSelect[complianceCustomField.id];
        const complianceId = complianceCustomField.input_name;
        if (!_.isNil(complianceVal) && complianceVal !== '') {
          complianceCustom[complianceId] = complianceVal;
        }
      }
      params.compliance = complianceCustom;
    }

    // dependent fields

    if (!isEmpty(this.state.dependentFields)) {
      params.compliance = {
        ...params.compliance,
        ...this.state.dependentFields
      };
    }

    // funding source
    if (this.state.fundingSource.length > 0 && this.state.healthPlanId !== SELF_FUNDED) {
      params.healthPlanId = this.state.healthPlanId;
      params.healthSubPlanId = this.props?.patientDetails?.health_sub_plan_id ?? null;
    }

    const treatments = this.props?.patientDetails?.health_sub_plan_treatments ?? [];
    if (treatments.length > 0) {
      params.treatmentId = this.state.treatmentId;
      params.treatmentName = this.state.treatmentName;
    }

    if (this.props.members.success) {
      params.hospitalId = this.state.hospitalId;
      params.medicalId = this.props.members.memberProfile.member_data.medicalId;
      params.firstName = this.props.members.memberProfile.member_data.firstName;
      params.lastName = this.props.members.memberProfile.member_data.lastName;
      params.internalNotes = this.props.members.memberProfile.member_data.internalNotes;
      params.phone =
        this.props.bookingData.phone ||
        this.props.members.memberProfile.member_data.mobileNo ||
        '';

      params = {
        ...params,
        ...(_.get(
          this.props.members,
          'memberProfile.member_data.health_plan_id',
          null
        ) && {
          healthPlanId: _.get(
            this.props.members,
            'memberProfile.member_data.health_plan_id',
            null
          )
        }),
        ...(_.get(
          this.props.members,
          'memberProfile.member_data.health_sub_plan_id',
          null
        ) && {
          healthPlanId: _.get(
            this.props.members,
            'memberProfile.member_data.health_sub_plan_id',
            null
          )
        }),
        ...(_.get(this.props.members, 'memberProfile.member_data.dateOfBirth', '') && {
          dateOfBirth: _.get(
            this.props.members,
            'memberProfile.member_data.dateOfBirth',
            ''
          )
        })
      };

      if (this.props.members.memberProfile.member_data.health_plan_id === 0) {
        delete params.healthPlanId;
      }
    }

    if (this.state.isPhoneFieldDirty) {
      /**
       * if the phone field is dirty, we want to check the opt out status
       * for that phone number. If the phone number is opted out, we want to
       * show the opt out message.
       */
      const shouldPromptOptOutModal = await handleOptIn({
        phone: params.phone,
        memberId: this.props.patientDetails.id
      });

      if (this.props.isCustomTextMessage && shouldPromptOptOutModal) {
        this.setState({
          showOptOutMessage: true,
          isPhoneFieldDirty: false,
          updatedPhoneNumber: {
            ...shouldPromptOptOutModal
          }
        });
        return;
      } else {
        this.setState({ isPhoneFieldDirty: false });
        this.props.updateBookingData(params, false);
      }
    } else {
      this.props.updateBookingData(params, false);
    }

    // Need to have a setTimeout because bookingData has not been set yet.
    setTimeout(() => {
      this.props.handlePatientSubmit(params, this.props.editRideBooking);
    }, 200);
  };

  /**
   * handles hospital selection
   * @param {event} hospitalData - object returned from react-select-search
   * @return {undefined}
   */
  selectHospital = hospitalData => {
    // clear any collected patient data if hospital is switched
    if (
      (!isEmpty(this.props.bookingData) || !isEmpty(this.props.patientDetails)) &&
      this.props.bookWithMedicalId === ''
    ) {
      this.props.clearBookingRide();
      this.props.resetPatient();
    }
    const hospitalId = parseInt(hospitalData.value, 10);
    const hospitalName = hospitalData.label;

    if (hospitalId === '') {
      this.setState({ patientCustomFields: [] });
    } else {
      const patientCustomFields = this.getPatientCustomFields();
      const hospitalCoords = getHospitalCoords(hospitalId, this.props.hospitalData, true);

      this.setState({
        showHospitalSearch: false,
        hospitalCoords,
        patientCustomFields,
        hospitalId,
        hospitalName,
        hospitalError: false
      });
    }
  };

  /**
   * handles treatment selection
   * @param {event} treatmentData - object returned from react-select-search
   * @return {undefined}
   */
  selectTreatment = treatmentData => {
    const treatmentId = parseInt(treatmentData.value, 10);
    const treatmentName = treatmentData.label;
    this.setState({
      showTreatmentSearch: false,
      treatmentId,
      treatmentName,
      treatmentError: false
    });
  };

  /**
   * open and close calendar modal
   * @return {undefined} returns nothing.
   */
  handleClick = () => {
    if (!this.state.showModal) {
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      showModal: !prevState.showModal
    }));
  };

  /**
   * close modal by clicking outside of modal
   * @param {object} e - event
   * @return {undefined} returns nothing.
   */
  handleOutsideClick = e => {
    if (this.node === null) {
      return;
    }
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  };

  /**
   * Compliance field change event handler. All changes to the compliance
   * fields should be routed through this function.
   * @param {string} type
   * @param {Event} e
   * @param {number} key
   * @param {number} fieldId
   * @returns
   */
  handleComplianceChange = (type, e, key, fieldId) => {
    switch (type) {
      case 'select':
        return this.handleComplianceSelect(e, key, fieldId);

      case 'text':
        return this.handleComplianceText(e, key, fieldId);

      case 'hidden':
      default:
    }
  };

  /**
   * Set selected value to compliance field
   * @param {object} e - events
   * @param {integer} key - key for array of compliance fields
   * @param {integer} fieldId - id for compliance field which gets saved to state
   * @return {undefined} - returns nothing
   */
  handleComplianceSelect = (e, key, fieldId) => {
    const complianceSelectError = this.state?.complianceSelectError;
    let complianceSelect = this.state?.complianceSelect;

    const { complianceInfo } = this.props;
    const value = e.target.innerHTML;
    const selectedComplianceInfo = _.cloneDeep(complianceInfo[key]);
    const option = _.find(selectedComplianceInfo.compliance_options, {
      option_value: value
    });
    let optionId = null;
    if (typeof option !== 'undefined') {
      optionId = option.id;
    }
    complianceSelect = this.props.setComplianceInfo(
      selectedComplianceInfo,
      complianceSelect,
      optionId,
      key,
      fieldId
    );

    if (value === '') {
      complianceSelectError[fieldId] = true;
    } else {
      complianceSelectError[fieldId] = false;
    }
    complianceSelect[fieldId] = value;
    if (this.triggerCheck) {
      this.setState(
        {
          complianceSelect: complianceSelect,
          complianceSelectError: complianceSelectError
        },
        this.checkPatientInfoValid
      );
    } else {
      this.setState({
        complianceSelect: complianceSelect,
        complianceSelectError: complianceSelectError
      });
    }
  };

  handleDependentFieldsSelect = ({ key, value, selectedItem }) => {
    this.setState({
      dependentFields: {
        ...this.state.dependentFields,
        [key]: value
      },
      selectedDependentFields: {
        key,
        list: [...selectedItem.child_options],
        selectedItem
      }
    });
  };

  getDependentFields = (fields, selections) => {
    const initialzedFields =
      selections && selections.length
        ? selections.map(s => ({ [s.name]: s.option_value }))
        : [{}];
    this.setState({
      dependentFieldNames: [...fields],
      dependentFields: Object.assign(...initialzedFields),
      dependentFieldSelections: selections
    });
  };

  updateDependentFieldsSelection = selections => {
    this.setState({
      dependentFieldSelections: selections
    });
  };

  /**
   * Select funding source if present
   * @param {object} e - events
   * @param {object} item - item selected
   * @return {undefined} - returns nothing
   */
  fundingSourceSelect = (e, item) => {
    const { patientDetails, editRide } = this.props;
    const healthSubPlans = _.get(this.props, 'user.healthPlans', []);
    if (editRide) {
      return false;
    }
    let disableSearchButton = false;
    let stateObj = {};
    if (item.id === SELF_FUNDED) {
      this.useFundingSource = false;
      this.useHealthSubPlan = false;
    } else {
      this.useHealthSubPlan = true;
      if (item.id !== patientDetails.healthPlanId && item.id !== '') {
        stateObj = this.resetFieldData();
        stateObj.showPatientInfoForm = false;
      }
      if (this.state.dateOfBirth === '') {
        disableSearchButton = true;
      }
    }

    if (this.state.medicalId === '') {
      disableSearchButton = true;
    }
    const subPlans = [];
    for (const x in healthSubPlans) {
      if (healthSubPlans[x].healthPlanId === item.id) {
        subPlans.push({
          id: healthSubPlans[x].healthSubPlanId,
          name: healthSubPlans[x].healthSubPlanName
        });
      }
    }

    this.setState({
      ...stateObj,
      healthPlanId: item.id,
      fundingSourceName: item.name,
      healthSubPlans: subPlans,
      fundingSourceError: false,
      disableSearchButton
    });
  };

  /**
   * Select health sub plan if present
   * @param {object} e - events
   * @param {object} item - item selected
   * @return {undefined} - returns nothing
   */
  healthSubPlanSelect = (e, item) => {
    const { patientDetails, editRide } = this.props;
    if (editRide) {
      return false;
    }
    let disableSearchButton = false;
    let stateObj = {};

    this.useFundingSource = true;
    if (item.id !== patientDetails.healthSubPlanId && item.id !== '') {
      stateObj = this.resetFieldData();
      stateObj.showPatientInfoForm = false;
    }

    if (this.state.medicalId === '') {
      disableSearchButton = true;
    }

    this.setState({
      ...stateObj,
      healthSubPlanId: item.id,
      healthSubPlanName: item.name,
      healthSubPlanError: false,
      disableSearchButton
    });
  };

  /**
   * activate search button when someone clicks on medicalId or dateOfBirth fields
   * @param {event} e - event
   * @return {undefined}
   */
  handleFocus = e => {
    e.preventDefault();
    this.setState({ disableSearchButton: false });
  };

  /**
   * close modal
   * @return {undefined}
   */
  closeErrorModal = () => {
    this.setState({ showErrorModal: false });
  };

  /**
   * handle click and blur event for search dropdown
   * @param {event} e - event
   * @param {boolean} stateVal - if false hide search
   * @return {undefined} - returns nothing
   */
  handleHospitalSearchClick = (e, stateVal) => {
    this.setState({ showHospitalSearch: stateVal });
  };

  /**
   * @param {event} e - event
   * @param {boolean} stateVal - if false hide search
   * @return {undefined} - returns nothing
   */
  handleTreatmentSearchClick = (e, stateVal = true) => {
    this.setState({ showTreatmentSearch: stateVal });
  };

  /**
   * Checks form to see if we have valid data in the form
   * @return {boolean} - returns true if valid, false if invalid
   */
  checkPatientInfoValid() {
    let valid = true;
    let patientErrorMessage = '';
    this.triggerCheck = true;
    const newState = { formTouched: true };

    /**
     * If condition, handles setting valid to false, as well as building error message
     * @param field field name that corresponds to the state variable ${field}Error
     * @param errorMsg message string to be added to the larger error message patientErrorMessage
     * @param isNotValid Boolean
     */
    const checkField = (field, errorMsg, isNotValid) => {
      newState[`${field}Error`] = isNotValid;
      if (isNotValid) {
        patientErrorMessage += ` ${errorMsg}`;
        valid = false;
      }
    };

    checkField(
      'firstName',
      'Please enter a first name.',
      !this.state.firstName && !this.props.members.success
    );
    checkField(
      'lastName',
      'Please enter a last name.',
      !this.state.lastName && !this.props.members.success
    );
    checkField('medicalId', 'Please enter an ID.', !this.state.medicalId);

    const phone1 = formatPhoneNumber(this.state?.phone ?? null);
    checkField(
      'phone',
      'Phone number format needs to be ###-###-####',
      !phone1 && !this.props.members.success
    );

    const phone2 = formatPhoneNumber(this.state.phone2 ?? null);
    checkField(
      'phone2',
      'Phone number format needs to be ###-###-####',
      this.state.phone2 && !phone2 && !this.props.members.success
    );

    checkField(
      'hospital',
      'Fix the highlighted fields.',
      !this.state.hospitalId && this.isNetwork()
    );
    checkField(
      'treatment',
      'Treatment Type is a required field.',
      this.props?.patientDetails?.health_sub_plan_treatments?.length &&
        (this.state.treatmentId === '' || !this.state.treatmentName)
    );

    const patientCustomFields = this.getPatientCustomFields();
    if (patientCustomFields.length) {
      const patientCustomFieldsError = { ...this.state.patientCustomFieldsError };
      for (let i = 0; i < patientCustomFields.length; i++) {
        const field = patientCustomFields[i];
        const val = this.state?.patientCustomFields?.[field.id];
        if (val === null || val === undefined || val === '') {
          patientErrorMessage += ` ${field.field_label} is a required field.`;
          patientCustomFieldsError[field.id] = true;
          valid = false;
        } else {
          // clear error msg
          patientCustomFieldsError[field.id] = false;
        }
      }

      newState.patientCustomFieldsError = patientCustomFieldsError;
    }

    const complianceInfo = this.props?.complianceInfo ?? [];
    if (complianceInfo.length) {
      const complianceSelect = this.state.complianceSelect;
      const complianceSelectError = { ...this.state.complianceSelectError };

      complianceInfo.map(({ id, input_name: inputName, input_type: inputType }) => {
        if ((!complianceSelect || !complianceSelect?.[id]) && inputType !== 'hidden') {
          complianceSelectError[id] = true;
          valid = false;
          patientErrorMessage += ` ${patientErrorMessage} ${inputName} is a required field.`;
        } else {
          complianceSelectError[id] = false;
        }
      });

      newState.complianceSelectError = complianceSelectError;
    }

    // dependent fields validation
    const {
      selectedDependentFields,
      dependentFieldSelections,
      dependentFieldNames,
      dependentFieldError
    } = this.state;
    if (
      dependentFieldSelections &&
      dependentFieldSelections.length !== dependentFieldNames.length
    ) {
      if (
        isEmpty(selectedDependentFields) &&
        dependentFieldSelections.length !== dependentFieldNames.length &&
        isEmpty(dependentFieldError.fieldLevel)
      ) {
        patientErrorMessage = ''; // This overwrites all previous error messages. Do we want to do this?
        newState.dependentFieldError = { fieldLevel: 0 };
        valid = false;
      }

      if (selectedDependentFields?.list?.length) {
        patientErrorMessage = ''; // This overwrites all previous error messages. Do we want to do this?
        newState.dependentFieldError = {
          fieldLevel: selectedDependentFields.selectedItem + 1
        };
        valid = false;
      }
    }

    checkField(
      'fundingSource',
      'Funding source is a required field.',
      this.useFundingSource &&
        (this.state.healthPlanId === '' || !this.state.fundingSourceName)
    );

    if (valid && this.state.showPatientInfoError) {
      newState.showPatientInfoError = false;
    }

    if (patientErrorMessage) {
      newState.patientErrorMessage = patientErrorMessage;
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }

    return valid;
  }

  /**
   * return patient custom fields
   * @return {array} - returns custom fields
   */
  getPatientCustomFields() {
    const hospitalId = this.state.hospitalId;
    const patientCustomFields = this.props.patientCustomFields ?? [];

    if (this.isNetwork()) {
      return patientCustomFields[hospitalId] || [];
    }
    return Array.isArray(patientCustomFields) ? patientCustomFields : [];
  }

  /**
   * return hospital user info
   * @param {integer} targetId - hospital id
   * @return {array} - returns custom fields
   */
  getHospitalUsers(targetId) {
    if (!this.isNetwork()) return this.props.hospitalUsers;

    const hospitalUser = this.props.hospitalUsers.find(
      ({ hospitalId }) => hospitalId === targetId
    );

    return hospitalUser ?? {};
  }

  /**
   * if ride is edited then remove any ride note data, booking time data
   * @return {undefined}
   */
  resetBookingData() {
    const newBookingData = _.pick(_.cloneDeep(this.props.bookingData), [
      'firstName',
      'lastName',
      'internalNotes',
      'medicalId',
      'phone',
      'phone2',
      'internalNotes',
      'additionalNotes',
      'hospitalId',
      'dateOfBirth',
      'patientCustomFields',
      'compliance',
      'pickupAddress',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'hospitalCoords',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'transportType',
      'currentRideIndex',
      'rides',
      'healthPlanId',
      'healthSubPlanId',
      'treatmentId',
      'treatmentName',
      'additionalPassengers'
    ]);

    this.props.updateBookingData(newBookingData, true);
  }

  /**
   * sets state for popup for showing modal for adding a new member
   * but an existing id is used
   * @return {undefined}
   */
  setErrorModal() {
    this.setState({
      showErrorModal: true,
      errorText: `The ID ${this.props.patientDetails.medicalId} is already in use. Do you still want to book a ride for this ID?`,
      errorTitle: 'Notice',
      errorCallback: this.populatePassenger,
      errorSubmitText: 'Book Anyway',
      errorShowSubmit: true,
      cancelButtonText: 'Enter a Different ID'
    });
  }

  /**
   * checks to see if someone entered a medical id not in our system
   * @param {object} error - error prop
   * @return {boolean} - returns true or false
   */
  newPassengerCheck(error) {
    return 'status' in error && error.status === 404 && error.reason === 'Error';
  }

  /**
   * retrieve funding sources, for health plans
   * @return {object} - returns funding funding sources and whether account can have health plans
   */
  getFundingSource() {
    const validFundingSourceValues = ['SFHP', 'HP'];
    const fundingHealthPlans = this.props?.user?.fundingHealthPlans ?? [];
    const userDataFundingSource = this.props?.userData?.fundingSource ?? '';

    const hasHP = this.validFundingSources(
      userDataFundingSource,
      validFundingSourceValues
    );

    let fundingSource = [];

    if (fundingHealthPlans.length && hasHP) {
      fundingSource = fundingHealthPlans.map(({ id, health_plan_name }) => ({
        id: id,
        name: health_plan_name
      }));

      if (userDataFundingSource === 'SFHP') {
        fundingSource.push({
          id: SELF_FUNDED,
          name: 'Self Funded'
        });
      }
    }

    return { fundingSource, hasHP };
  }

  /**
   * get treatments information from patient details
   * @return {array} - returns treatments or empty array if no treatments
   */
  getTreatments() {
    const treatmentsAllow = this.props?.patientDetails?.treatments ?? 0;

    if (!treatmentsAllow) return [];

    const treatments = this.props?.patientDetails?.health_sub_plan_treatments ?? [];

    return treatments.map(({ treatment_id, treatment_name }) => ({
      value: treatment_id,
      label: treatment_name
    }));
  }

  /**
   * remove fields from state, in case you add a new passenger after entering in
   * existing id or error for adding a different id
   * @return {undefined}
   */
  resetFieldData() {
    return {
      ..._.cloneDeep(this.state),
      firstName: '',
      lastName: '',
      internalNotes: '',
      phone: '',
      phone2: '',
      disableFields: false,
      complianceSelect: {},
      patient_custom_fields: {},
      complianceSelectError: {},
      patientCustomFieldsError: {},
      patientCustomFields: {},
      showPatientInfoError: false,
      firstNameError: false,
      additionalPassengers: [],
      lastNameError: false,
      phoneError: false
    };
  }

  /**
   * check to see if this account can have health plans (SFHP, HP)
   * @param {string} userFundingSource - funding source from user api
   * @param {array} fundingSourceValues - list of funding source values
   * @return {bool} - returns true if account type has health plans (SFHP, HP), false if not (SF)
   */
  validFundingSources(userFundingSource, fundingSourceValues) {
    return fundingSourceValues.indexOf(userFundingSource) > -1;
  }

  render() {
    const {
      healthSubPlans,
      healthSubPlanName,
      healthSubPlanError,
      hospitalId,
      hospitalName,
      hospitalError,
      showHospitalSearch,
      dependentFields,
      dependentFieldError,
      treatmentName: selectedTreamentName,
      treatmentId,
      treatmentError,
      showTreatmentSearch,
      medicalId,
      medicalIdError,
      _showErrorModal,
      disableFields,
      patientErrorMessage,
      fundingSource,
      fundingSourceName,
      fundingSourceError,
      showOptOutMessage,
      disableSearchButton,
      showPatientInfoForm,
      firstName,
      firstNameError,
      lastName,
      lastNameError,
      phone,
      phoneError,
      phone2,
      phone2Error,
      updatedPhoneNumber,
      additionalPassengers,
      formTouched,
      internalNotes
    } = this.state;

    const {
      complianceInfo,
      editBookedRide,
      editRide,
      editRideBooking,
      patientDetails,
      bookingData,
      members,
      user,
      isRedirect,
      personalInfo
    } = this.props;

    const selectedHospital = { name: hospitalName, id: hospitalId };
    const selectedTreatment = { name: selectedTreamentName, id: treatmentId };

    const treatmentName = patientDetails?.health_sup_plan_name ?? null;
    const memberProfile = members?.memberProfile?.member_data ?? {};
    const memberProfileMobileNo = memberProfile?.mobileNo
      ? getNationalNumber(memberProfile.mobileNo)
      : '';
    const patientIdClass = !editRide
      ? 'patientId showDOB clearfix'
      : 'patientId clearfix';

    const message = this.getErrorMsg();
    const hospitals = this.getHospitals();
    const isNetwork = this.isNetwork();
    const treatments = this.getTreatments();
    const shouldDisablePhone = this.getShouldDisablePhone();
    const patientCustomFields = this.getPatientCustomFields();
    const showErrorModal = _showErrorModal && patientErrorMessage;
    const showMedicalIdButton = !editRide && hospitalId;

    return (
      <form
        onSubmit={this.handleFormSubmission}
        autoComplete="off"
        className="patientInfoForm"
      >
        {showErrorModal && (
          <ErrorModal
            closeModal={this.closeErrorModal}
            isOpen={showErrorModal}
            modalContent={this.state.errorText}
            title={this.state.errorTitle}
            hasMarkup={this.state.hasMarkup}
            submitText={this.state.errorSubmitText}
            showSubmit={this.state.errorShowSubmit}
            callback={this.state.errorCallback}
            cancelButtonText={this.state.cancelButtonText}
          />
        )}

        <div className="patientIdCont">
          {editRide || editRideBooking ? (
            <div className="close" onClick={this.props.closeComponent}>
              <SvgClose />
            </div>
          ) : null}
          <div className={patientIdClass}>
            {hospitals?.length && !editBookedRide ? (
              <SearchDropdown
                showSearch={showHospitalSearch}
                value={hospitalId}
                name="hospitalSelect"
                customClassName="SearchDropdown"
                items={hospitals}
                placeholder={
                  selectedHospital.name ? selectedHospital.name : 'Select Facility'
                }
                error={hospitalError}
                handleClick={this.handleHospitalSearchClick}
                dropDownCallback={this.selectHospital}
              />
            ) : null}
            {!isRedirect && (hospitalId !== '' || members.success) ? (
              <>
                {fundingSource.length > 0 && !members.success ? (
                  <DropDown
                    items={fundingSource}
                    placeholder={
                      fundingSourceName
                        ? `Funded By: ${fundingSourceName}`
                        : 'Select Funding Source'
                    }
                    dropDownCallback={this.fundingSourceSelect}
                    error={fundingSourceError}
                    customClassName="patientDropDown"
                    dropDownHeading={
                      isNetwork ? user.hospitalGroupName : user.hospitalData.hospitalName
                    }
                    disabled={editRide}
                    sendBackObject
                  />
                ) : null}
                {healthSubPlans?.length && !members?.success ? (
                  <DropDown
                    items={healthSubPlans}
                    placeholder={
                      healthSubPlanName
                        ? `Sub Plan: ${healthSubPlanName}`
                        : 'Select Health Sub Plan'
                    }
                    dropDownCallback={this.healthSubPlanSelect}
                    error={healthSubPlanError}
                    customClassName="patientDropDown"
                    sendBackObject={true}
                    dropDownHeading={
                      isNetwork ? user.hospitalGroupName : user.hospitalData.hospitalName
                    }
                    disabled={editRide}
                  />
                ) : null}
                <InputText
                  onChange={this.changeText}
                  onKeyPress={this.handleEnterMedical}
                  fieldName="medicalId"
                  label=""
                  placeholder={
                    fundingSource?.length
                      ? 'Enter Medical Record Number'
                      : 'Add I.D. number'
                  }
                  value={medicalId || memberProfile?.medicalId || ''}
                  error={medicalIdError}
                  disabled={editRide || Boolean(memberProfile.medicalId)}
                  customClassName="medicalId"
                />
              </>
            ) : null}
            {!isRedirect && showMedicalIdButton && !members.success && (
              <button
                className="go"
                onClick={this.checkMedicalId}
                disabled={disableSearchButton}
              >
                Search Passengers
              </button>
            )}
          </div>

          {message && <div className="patientError">{message}</div>}

          {!showPatientInfoForm && (
            <div style={{ paddingRight: 24 }}>
              <AddNewMember addPatientInfo={this.addNewMember} page={'RIDES_SCHEDULED'} />{' '}
            </div>
          )}
        </div>

        {showPatientInfoForm ? (
          <div className="patientInfoCont">
            <div className="patientInfo">
              <div className="inputHolder clearfix">
                <div className="inputCont">
                  <InputText
                    onChange={this.changeText}
                    fieldName="firstName"
                    label="First Name"
                    placeholder="First Name"
                    value={firstName || memberProfile.firstName || ''}
                    error={firstNameError}
                    disabled={disableFields}
                  />
                </div>
                <div className="inputCont">
                  <InputText
                    onChange={this.changeText}
                    fieldName="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    value={lastName || memberProfile.lastName || ''}
                    error={lastNameError}
                    disabled={disableFields}
                  />
                </div>
              </div>
              <div className="inputHolder clearfix">
                <div className="inputCont">
                  <InputText
                    onChange={this.changeText}
                    fieldName="phone"
                    label="Primary Phone"
                    placeholder="Primary Phone"
                    value={
                      phone || memberProfileMobileNo
                        ? formatPhoneNumber(phone || memberProfileMobileNo)
                        : ''
                    }
                    error={phoneError}
                    disabled={shouldDisablePhone}
                    format="(###) ###-####"
                    mask="_"
                  />
                </div>
              </div>
              <div className="inputHolder clearfix">
                <div className="inputCont">
                  <div className="info" data-tip={PHONE_2_TEXT} data-for="phone2Tip">
                    <SvgInfo className="infoSvg" />
                    <ReactTooltip
                      className="overrideTooltip"
                      effect="solid"
                      id="phone2Tip"
                    />
                  </div>
                  <InputText
                    onChange={this.changeText}
                    fieldName="phone2"
                    label="Alternate Phone"
                    placeholder="Alternate Phone"
                    value={phone2 || ''}
                    error={phone2Error}
                    disabled={shouldDisablePhone}
                    format="(###) ###-####"
                    mask="_"
                  />
                </div>
              </div>

              {!_.isNil(treatmentName) ? (
                <div className="inputCont">
                  <InputText
                    fieldName="planName"
                    label="Plan"
                    disabled={true}
                    value={treatmentName}
                  />
                </div>
              ) : null}

              {treatments?.length > 0 && (
                <SearchDropdown
                  name="treatmentSelect"
                  customClassName="SearchDropdown"
                  items={treatments}
                  placeholder={selectedTreatment?.name || 'Treatment Type'}
                  value={treatmentId}
                  error={treatmentError}
                  dropDownCallback={this.selectTreatment}
                  handleClick={this.handleTreatmentSearchClick}
                  showSearch={showTreatmentSearch}
                />
              )}

              {patientCustomFields.map(({ id, field_label_desc }, key) => {
                const patientFieldVal = _.get(
                  this.state,
                  `patientCustomFields[${id}]`,
                  ''
                );

                const error = _.get(this.state, `patientCustomFieldsError[${id}]`, false);

                return (
                  <div className="inputHolder clearfix" key={key}>
                    <InputText
                      onChange={this.changeTextCustom}
                      fieldName={`patientCustomFields_${id}`}
                      label={field_label_desc}
                      placeholder={field_label_desc}
                      value={patientFieldVal}
                      error={error}
                    />
                  </div>
                );
              })}

              <ComplianceFields
                state={this.state}
                complianceInfo={complianceInfo}
                onComplianceChange={this.handleComplianceChange}
              />

              <div>
                <DependentFieldsContainer
                  complianceFields={{ ...complianceInfo, ...dependentFields }}
                  fields={bookingData?.compliance ?? {}}
                  touched={formTouched}
                  error={dependentFieldError}
                  getDependentFields={this.getDependentFields}
                  handleDependentFieldsSelect={this.handleDependentFieldsSelect}
                  updateDependentFieldsSelection={this.updateDependentFieldsSelection}
                />
              </div>

              <div className="inputHolder clearfix">
                <TextAreaField
                  fieldName="internalNotes"
                  label="Member Notes (Internal); Not visible to Transport."
                  placeholder="Member Notes (Internal); Not visible to Transport."
                  value={internalNotes || memberProfile.internalNotes || ''}
                  onChangeCallback={this.changeText}
                />
              </div>
              <div className="RBFAdditionalPassengers">
                <RBFPassengerDropdown
                  formType="single-row"
                  editMode={editRide}
                  setField={({ name, value }) => this.setState({ [name]: value })}
                  passengers={additionalPassengers}
                  mobilityType={patientDetails.mobility_type || 5}
                  vehicle_types={Object.values(user.vehicleTypes)}
                  subMobilityType={patientDetails.mobility_sub_type || null}
                />
              </div>
              <input
                type="submit"
                className="proceed-with-member-btn"
                value={!medicalId && !members.success ? 'Create New User' : 'Continue'}
              />
            </div>
          </div>
        ) : null}

        <OptInModal
          personalInfo={personalInfo?.personalInfo}
          isOpen={showOptOutMessage}
          page={PAGES.RIDE_BOOKING_FLOW}
          close={() => this.setState({ showOptOutMessage: false })}
          cancel={() => this.setState({ showOptOutMessage: false })}
          optOutField={'member_secondary_phone_opt_out'}
          optIn={this.optIn}
          bookingPhone={phone}
          makeSelection={async ({ opt_out, member_secondary_phone_opt_out }) => {
            await updateOptOutStatus({
              phone_number: phone,
              member_secondary_phone_opt_out,
              opt_out,
              opt_out_prompt_timestamp: new Date(),
              memberId: patientDetails.id,
              passenger_phone_number_id: updatedPhoneNumber.id
            }).then(() => this.setState({ showOptOutMessage: false }));
          }}
        />
      </form>
    );
  }
}

/* set default prop values */
PatientInfo.defaultProps = {
  page: '',
  google: {},
  getPatientInfo: () => {},
  user: {},
  userData: {},
  resetPatient: () => {},
  patientDetails: {},
  getPatientTimestamp: 0,
  getPatientError: {},
  handlePatientSubmit: () => {},
  editForm: false,
  updateBookingData: () => {},
  bookingData: {},
  patientCustomFields: [],
  hospitalData: {},
  editRide: false,
  editRideBooking: false,
  complianceInfo: [],
  bookWithMedicalId: '',
  bookWithHospitalId: null,
  bookWithHealthPlanId: null,
  bookWithDateOfBirth: null,
  hospitalUsers: [],
  toggleLoading: () => {},
  clearBookingRide: () => {},
  editBookedRide: false,
  closeComponent: () => {},
  setComplianceInfo: () => {},
  complianceSelect: {},
  members: {},
  getMemberPermissions: () => {},
  bookWithHealthSubPlanId: null,
  getMemberProfile: () => {}
};

PatientInfo.propTypes = {
  page: PropTypes.string,
  google: PropTypes.object,
  getPatientInfo: PropTypes.func,
  user: PropTypes.object,
  userData: PropTypes.object,
  resetPatient: PropTypes.func,
  patientDetails: PropTypes.object,
  getPatientTimestamp: PropTypes.number,
  getPatientError: PropTypes.object,
  handlePatientSubmit: PropTypes.func,
  editForm: PropTypes.bool,
  updateBookingData: PropTypes.func,
  bookingData: PropTypes.object,
  patientCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hospitalData: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  editRide: PropTypes.bool,
  editRideBooking: PropTypes.bool,
  complianceInfo: PropTypes.array,
  bookWithMedicalId: PropTypes.string,
  bookWithHospitalId: PropTypes.number,
  bookWithHealthPlanId: PropTypes.number,
  bookWithDateOfBirth: PropTypes.string,
  hospitalUsers: PropTypes.array,
  toggleLoading: PropTypes.func,
  clearBookingRide: PropTypes.func,
  editBookedRide: PropTypes.bool,
  closeComponent: PropTypes.func,
  setComplianceInfo: PropTypes.func,
  complianceSelect: PropTypes.object,
  members: PropTypes.object,
  getMemberPermissions: PropTypes.func,
  bookWithHealthSubPlanId: PropTypes.number
};

const mapStateToProps = state => ({
  user: state.user,
  userData: state.user.userData,
  hospitalData: state.user.hospitalData,
  hospitalUsers: state.user.hospitalUsers,
  highRisk: state.patients.highRisk,
  patientDetails: state.patients.patientDetails,
  getPatientTimestamp: state.patients.timestamp,
  getPatientError: state.patients.error,
  bookingData: state.bookingData,
  members: state.members,
  personalInfo: state?.memberProfile?.formData?.personalInfo ?? {},
  freeForm: state?.memberProfile?.formData?.freeForm ?? {},
  isCustomTextMessage: state?.user?.features?.custom_text_messaging
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getPatientInfo: data => getPatientInfoThunk(data),
      resetPatient: () => resetPatient(),
      updateBookingData: (data, replace) => updateBookingData(data, replace),
      clearBookingRide: () => clearBookingRide(),
      getMemberPermissions: () => getMemberPermissions(),
      getMemberProfile: data => getMemberProfile(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PatientInfo);
