import React from 'react';

const SvgDownload = props => {
  const {title, className} = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <title>{title}</title>
      <path d="M19 9h-4V3H9v6H5l7 7 7-7zM5 18v2h14v-2H5z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
};

export default SvgDownload;
