import React from 'react';
import { useAppSelector } from '~/Modules';
import { useDispatch } from 'react-redux';
import AutoComplete from '../AutoComplete/AutoComplete';
import type { ApprovedProvidersProps } from './ApprovedProviders';
import { fetchApprovedProviders } from '~/Modules/search';

export type AddressAutoCompleteProps = {
  type: ApprovedProvidersProps['type'];
  showAutoComplete: boolean;
  onSelect: (row, type: ApprovedProvidersProps['type'], source?: string) => void;
  onReset: ApprovedProvidersProps['onResetAutoComplete'];
  value?: string;
};

export const AddressAutoComplete: React.FC<AddressAutoCompleteProps> = ({
  type,
  showAutoComplete,
  onSelect,
  onReset,
  value
}) => {
  const dispatch = useDispatch();
  const autoCompleteData = useAppSelector(
    state => state.mapbox?.autoComplete?.features ?? []
  );

  const showModal = showAutoComplete && autoCompleteData.length > 0;

  return (
    <AutoComplete
      data={autoCompleteData}
      select={row => onSelect(row, type)}
      showModal={showModal}
      right="0"
      location={type}
      isApprovedProviders={false}
      resetParentProps={onReset}
      searchTerm={value}
      dispatch={dispatch}
      onLoadMore={fetchApprovedProviders}
    />
  );
};
