import React from 'react';
import PropTypes from 'prop-types';

const SvgDelete = props => {
  return (
    <svg className={props.className} width="24" height="24" viewBox="0 0 24 24">
      <title>Delete</title>
      <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/>
      <path d="M0 0h24v24H0z" fill="none"/>
    </svg>
  );
};

SvgDelete.propTypes = {
  className: PropTypes.string
};

SvgDelete.defaultProps = {
  className: ''
};

export default SvgDelete;