import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  addNewMemberRoot: {
    display: 'flex',
    justifyContent: 'flex-end',
    justifyItems: 'center',
    color: '#2962ff'
  },
  newMemberTitle: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  root: {
    fontFamily: 'Helvetica Neue',
    'label + &': {
      marginTop: theme.spacing(5)
    }
  },
  gridRoot: {
    padding: '20px 0px 20px 0px'
  },
  cancelModalRoot: {
    fontFamily: 'Helvetica Neue',
    padding: '0 12px',
    marginTop: 40,
    color: '#485C6F'
  },
  cancelModalHeader: {

    color: '#485C6F',
    fontSize: 22
  },
  cancelModalBody: {
    fontSize: 16,
    marginTop: 40,
    minHeight: 80
  },
  cancelModalButtonContainer: {
    display: 'flex'
  },
  buttonRed: {
    color: '#fff',
    background: '#de350b',
    marginRight: 20
  },
  errorText: {
    textAlign: 'end',
    paddingRight: 10,
    fontWeight: 'bold',
    marginLeft: 'auto',
    fontSize: 12,
    width: '50%',
    color: '#de350b'
  },
  closeIcon: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%'
  },
  buttonRoot: {
    display: 'flex',
    justifyContent: 'flex-end'

  },
  header: {
    whiteSpace: 'nowrap',
    fontSize: 22
  },
  header2: {
    fontSize: 15,
    fontFamily: 'Helvetica Neue',
    fontWeight: 900,
    color: '#2d3e50'

  },
  buttonCancel: {
    width: 100,
    backgroundColor: '#fff'
  },
  buttonAdd: {
    width: 100,
    color: '#fff',
    marginLeft: 30,
    backgroundColor: '#56b99d',
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#56b99d',
      color: '#fff',
      opacity: 0.5,
      pointerEvents: 'auto'

    }
  },
  addressField: {
    fontFamily: 'Helvetica Neue',
    fontWeight: 900,
    color: '#2d3e50',
    fontSize: 15,
    '& input': {
      height: 14,
      borderRadius: 4,
      width: '85%',
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #bebebe',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    },
    '& div .react-mapbox-ac-menu': {
      marginTop: 0
    },
    '& input::-webkit-input-placeholder': {
      content: ''
    }

  },
  textField: {
    width: 250,
    fontFamily: 'Helvetica Neue',
    fontWeight: 900,
    color: '#2d3e50',
    fontSize: 15,
    '& input': {
      height: 14,
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #bebebe',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
      }
    },
    '& .MuiFormLabel-asterisk': {
      color: 'red'
    },
    '& .MuiInputBase-input:invalid': {
      textTransform: 'uppercase',
      color: 'rgba(0, 0, 0, 0.26)'

    },
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '& .MuiFormLabel-root': {
      fontFamily: 'Helvetica Neue',
      fontWeight: 900,
      color: '#2d3e50',
      fontSize: 19

    },
    '& .MuiInput-underline:before': {
      borderRadius: 4,
      border: '1px solid #bebebe'

    },
    '& .MuiInput-underline:after': {
      border: 'none'
    },
    '& label + .MuiInput-formControl': {
      marginTop: 25
    }
  }
}));
