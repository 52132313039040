import { IMPORTER, IMPORTER_STEP } from '~/constants';

/**
 * Get configured importer with lists of c and DB fields
 * @param {Number} type Importer type from IMPORTER
 * @returns {Object} Importer configuration object
 */
export const getImporter = (type = IMPORTER.NONE) => {
  return {
    type,
    url: getApiEndpointUri(type, true),
    steps: getSteps(type),
    fields: getFields(type),
    fieldMapping: {}
  };
};

export const getApiEndpointUri = (type = IMPORTER.NONE, upload = false) => {
  let uri = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/`;
  switch (type) {
    case IMPORTER.EXPENSE:
    case IMPORTER.RIDESHARE:
    case IMPORTER.PUBLICTRANSIT: {
      uri += `hospital-network/import`;
      break;
    }
    case IMPORTER.TREATMENT:
    case IMPORTER.LOCATION:
    case IMPORTER.MEMBER: {
      uri += `health-plans/import`;
      break;
    }
    default: {
      uri = '';
    }
  }
  return upload ? `${uri}/csv/${type}s` : `${uri}/${type}s`;
};
/**
 * Get importer steps, in order, by importer type
 * Note: stored in state as this.state.steps
 * @param {String} type Importer type from constant IMPORTER
 * @returns {Array} List of importer steps
 */
const getSteps = type => {
  switch (type) {
    case IMPORTER.TREATMENT:
    case IMPORTER.LOCATION:
    case IMPORTER.MEMBER:
    case IMPORTER.EXPENSE:
    case IMPORTER.RIDESHARE:
    case IMPORTER.PUBLICTRANSIT: {
      return [
        getStep(IMPORTER_STEP.UPLOAD, 0),
        getStep(IMPORTER_STEP.VERIFY, 1),
        getStep(IMPORTER_STEP.PREVIEW, 2),
        getStep(IMPORTER_STEP.CONFIRM, 3)
      ];
    }
    default: {
      return [];
    }
  }
};

/**
 * Get importer step details
 * @param {Number} stepType Importer Step type from IMPORTER_STEP
 * @param {Number} index Step index in list of steps
 * @returns {Object} Importer step
 */
const getStep = (stepType, index) => {
  const step = {
    type: stepType,
    index,
    label: 'Unknown',
    data: {},
    isValid: false,
    isValidBack: true,
    hasSubmitted: false
  };

  switch (stepType) {
    case IMPORTER_STEP.UPLOAD: {
      step.label = 'Upload';
      break;
    }
    case IMPORTER_STEP.VERIFY: {
      step.label = 'Verify';
      break;
    }
    case IMPORTER_STEP.PREVIEW: {
      step.label = 'Preview';
      step.isValid = true;
      break;
    }
    case IMPORTER_STEP.CONFIRM: {
      step.label = 'Confirm';
      step.isValidBack = false;
      break;
    }
    default: {
      break;
    }
  }

  return step;
};

type ImporterField = {
  name: string;
  label: string;
  value: string;
  required?: boolean;
  multi?: boolean;
};

/**
 * Get list of DB mapped fields
 * @param {Number} type Importer type from IMPORTER
 * @returns {Array} List of DB fields
 */
const getFields = type => {
  let fields: ImporterField[] = [];
  const default_fields = [{ name: 'ignore', label: 'IGNORE', value: 'ignore' }];

  switch (type) {
    case IMPORTER.TREATMENT: {
      fields = [
        { name: 'treatmentId', label: 'Treatment ID', value: 'code' },
        {
          name: 'treatmentName',
          label: 'Treatment Name',
          value: 'type',
          required: true
        },
        {
          name: 'treatmentDescription',
          label: 'Treatment Description',
          value: 'desc'
        },
        {
          name: 'benefitDeduction',
          label: 'Benefit Deduction',
          value: 'deduction'
        },
        {
          name: 'benefitCategories',
          label: 'Benefit Categories',
          value: 'categories'
        }
      ];
      break;
    }
    case IMPORTER.LOCATION: {
      fields = [
        { name: 'locationName', label: 'Location Name', value: 'name' },
        {
          name: 'locationAddress',
          label: 'Location Address',
          value: 'fullAddress',
          required: true
        },
        {
          name: 'locationDescription',
          label: 'Location Description',
          value: 'desc'
        },
        {
          name: 'locationAddress2',
          label: 'Location Address 2',
          value: 'address2'
        },
        { name: 'npi', label: 'NPI', value: 'code' }
      ];
      break;
    }
    case IMPORTER.EXPENSE: {
      fields = [
        {
          name: 'expenseReportId',
          label: 'Expense Report ID',
          value: 'expense_report_id',
          required: true
        },
        {
          name: 'childExpenseId',
          label: 'Child Expense ID',
          value: 'child_expense_id',
          required: true
        },
        {
          name: 'childExpenseStatus',
          label: 'Child Expense Status',
          value: 'child_expense_status'
        },
        {
          name: 'cancellationReason',
          label: 'Cancellation Reason',
          value: 'cancellation_reason'
        },
        {
          name: 'rejectionReason',
          label: 'Rejection Reason',
          value: 'rejection_reason'
        },
        { name: 'amount', label: 'Amount', value: 'amount' }
      ];
      break;
    }
    case IMPORTER.RIDESHARE: {
      fields = [
        { name: 'rideId', label: 'Ride ID', value: 'ride_id', required: true },
        {
          name: 'estimatedCost',
          label: 'Estimated Cost',
          value: 'estimated_cost'
        },
        { name: 'rideCost', label: 'Ride Cost', value: 'ride_cost' },
        { name: 'claimStatus', label: 'Claim Status', value: 'claim_status' },
        {
          name: 'claimDenialCode',
          label: 'Claim Denial Code',
          value: 'claim_denial_code'
        },
        {
          name: 'invoiceStatus',
          label: 'Invoice Status',
          value: 'invoice_status'
        }
      ];
      break;
    }
    case IMPORTER.PUBLICTRANSIT: {
      fields = [
        { name: 'rideId', label: 'Ride ID', value: 'ride_id', required: true },
        { name: 'rideStatus', label: 'Ride Status', value: 'ride_status' },
        { name: 'rideCost', label: 'Ride Cost', value: 'total_cost' },
        {
          name: 'transitCardCost',
          label: 'Transit Card Cost',
          value: 'transit_card_cost'
        },
        {
          name: 'transitCardType',
          label: 'Transit Card Type',
          value: 'transit_card_type'
        },
        {
          name: 'claimStatus',
          label: 'Claim Status',
          value: 'claim_status',
          required: true
        },
        {
          name: 'claimDenialCode',
          label: 'Claim Denial Code',
          value: 'claim_denial_code'
        },
        {
          name: 'invoiceStatus',
          label: 'Invoice Status',
          value: 'invoice_status'
        },
        {
          name: 'transitCardStatus',
          label: 'Transit Card Status',
          value: 'transit_card_status'
        }
      ];
      break;
    }
    case IMPORTER.MEMBER: {
      fields = [
        {
          name: 'medicalId',
          label: 'Medical ID',
          value: 'medicalId',
          required: true
        },
        { name: 'memberId', label: 'Member ID', value: 'member_id' },
        { name: 'phone', label: 'Phone', value: 'mobileNo' },
        {
          name: 'canText',
          label: 'Can Receive Texts',
          value: 'can_receive_texts'
        },
        {
          name: 'canCall',
          label: 'Can Receive Calls',
          value: 'can_receive_calls'
        },
        { name: 'state', label: 'State', value: 'state' },
        { name: 'city', label: 'City', value: 'city' },
        { name: 'address', label: 'Address', value: 'street1' },
        { name: 'address2', label: 'Address2', value: 'street2' },
        { name: 'zipCode', label: 'Zip Code', value: 'zipcode' },
        { name: 'email', label: 'Email', value: 'email' },
        {
          name: 'canEmail',
          label: 'Can Receive Emails',
          value: 'can_receive_emails'
        },
        {
          name: 'firstName',
          label: 'First Name',
          value: 'firstName',
          required: true
        },
        {
          name: 'lastName',
          label: 'Last Name',
          value: 'lastName',
          required: true
        },
        { name: 'dateOfBirth', label: 'Date of Birth', value: 'dateOfBirth' },
        { name: 'hasAttendee', label: 'Has Attendee', value: 'attendee' },
        {
          name: 'hasServiceAnimal',
          label: 'Needs Service Animal',
          value: 'needs_service_animal'
        },
        {
          name: 'isEligible',
          label: 'Is Eligible',
          value: 'isEligible',
          required: true
        },
        {
          name: 'eligibleStartDate',
          label: 'Eligible Start Date',
          value: 'eligibleStartDate'
        },
        {
          name: 'eligibleEndDate',
          label: 'Eligible End Date',
          value: 'eligibleEndDate'
        },
        { name: 'isGroupable', label: 'Is Groupable', value: 'groupable' },
        {
          name: 'mimAcceptibleMode',
          label: 'Minimal Acceptable Mode',
          value: 'min_acceptable_mode'
        },
        { name: 'serviceLevel', label: 'Service Level', value: 'service_level' },
        {
          name: 'driverGender',
          label: 'Preferred Driver Gender',
          value: 'preferred_driver_gender'
        },
        {
          name: 'driverLanguage',
          label: 'Preferred Driver Language',
          value: 'preferred_driver_language'
        },
        {
          name: 'driverSkills',
          label: 'Preferred Driver Skills',
          value: 'preferred_driver_skills'
        },
        {
          name: 'vehicleCapability',
          label: 'Preferred Vehicle Capability',
          value: 'preferred_vehicle_capability'
        },
        { name: 'leaveAlone', label: 'Leave Alone', value: 'leave_alone' },
        {
          name: 'HealthSubPlan',
          label: 'HealthSubPlan',
          value: 'HealthSubPlan',
          multi: true
        }
      ];
      break;
    }
  }

  return [...fields, ...default_fields];
};
