/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { updateBookingData, updateRideData } from '~/Modules/bookingData';
import { useAppSelector } from '~/Modules';
import { cloneDeep, flipAddresses } from '~/utilities/helperFunctions';
import { validApprovedProviders } from '~/utilities/rideFormValidation';
import { useSavedAddress } from '../SavedAddressSelect/useSavedAddress';
import { MissingFieldsError, SwapContainer } from './SwapContainer';
import { canEnableSavedAddress } from './TransportType.utils';

export const TransportTypeHOC = (TransportType: any) => {
  return (props: any) => {
    const dispatch = useDispatch();
    const bookingData = useAppSelector(state => state?.bookingData);
    const [savedAddressState, savedAddressActions] = useSavedAddress();

    const showSavedAddress = canEnableSavedAddress(props);

    let rideDetails: BookingDataStore | RideData = bookingData;
    if (bookingData.bookingType === 'multileg') {
      rideDetails = bookingData.rides[bookingData?.currentRideIndex ?? 0];
    }

    /**Closes the TransportType component
     *
     * @returns {void}
     */
    const onCloseTransportTypeComponent = (): void => {
      const { closeComponent, transportTypeValidate } = props;

      if (bookingData.isApprovedProviders) {
        const errors = [];
        const errMsg = [];

        validApprovedProviders(bookingData, errors, errMsg);
        if (errors.length || errMsg.length) {
          return void transportTypeValidate(errors, errMsg.join(''));
        }

        transportTypeValidate([], '');
      }
      closeComponent();
    };

    /**
     * Switch addresses on book ride form, and switch map pin positions
     * update redux booking state so Map component can access new map data
     * @return {void} returns nothing
     */
    const onSwitchAddresses = (): void => {
      const legIndex = props?.legIndex ?? -1;

      const targetRide = legIndex > -1 ? bookingData.rides[legIndex] : bookingData;
      const rideData = cloneDeep(targetRide);

      const flippedData = flipAddresses(rideData);
      savedAddressActions.Flip();

      if (legIndex > -1) {
        dispatch(updateRideData(flippedData, legIndex));
      } else {
        dispatch(updateBookingData(flippedData));
      }
    };

    /**
     * Updates either ride or bookingData depending on if this
     * is a multileg ride or not
     * @param key
     * @param val
     */
    function updateRide(key: string | Partial<RideData>, val?: any) {
      const isMultileg = bookingData.bookingType === 'multileg';
      const update = typeof key === 'string' ? { [key]: val } : key;

      if (isMultileg && props?.legIndex > -1) {
        dispatch(updateRideData(update, props.legIndex));
      } else {
        dispatch(updateBookingData(update));
      }
    }

    /** Reset the saved address state on dismount */
    useEffect(() => savedAddressActions.Reset, []);

    return (
      <div className="showTransportForm clearfix">
        <MissingFieldsError error={props.missingFieldsError} />

        <div className="showTransportFormRelative clearfix">
          <TransportType
            savedAddress={{ state: savedAddressState, actions: savedAddressActions }}
            {...props}
          />

          <SwapContainer
            rideDetails={rideDetails}
            editMode={props.editRide}
            updateRide={updateRide}
            onSwitchAddresses={onSwitchAddresses}
            onClose={onCloseTransportTypeComponent}
            showSavedAddress={showSavedAddress}
          />
        </div>
      </div>
    );
  };
};
