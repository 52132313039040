import React, { useState } from 'react';
import ReactModal from 'react-modal';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash-es';
import OptInModal from '../OptInModal/OptInModal';
import { optInStyles } from './OptIn.styles';
import Button from '~/Shared/Components/Common/Button';
import { getOptInValue, optInPhoneType, modalDisplayType } from './OptIn.utils';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { useAppSelector } from '~/Modules';

/**
 * Per me si va ne la città dolente,
 * per me si va ne l’etterno dolore,
 * per me si va tra la perduta gente.
 */

const stateKey = {
  primaryCannotAcceptSMS: 'primary_cannot_accept_sms',
  addSecondaryNumber: 'add_secondary_phone',
  promptedSMSKey: 'prompted_textable_number',
  secondaryRidePhone: 'secondary_phone_as_ride'
};

const ModalState = {
  [stateKey.primaryCannotAcceptSMS]: {
    key: optInPhoneType.PRIMARY_PHONE_OPT_OUT,
    hasPrompt: false,
    body: 'It appears primary phone number cannot accept text messages. Does the member have a mobile number they can use?',
    buttonContainer: ({ props, setState }) => (
      <React.Fragment>
        <Button
          onClick={() => {
            props?.continue();
          }}
          className="modal-button red-btn"
          label="No"
        />
        <div className="spacer-8"></div>
        <Button
          onClick={() => {
            setState(stateKey.addSecondaryNumber);
          }}
          className="modal-button green-btn"
          label="YES"
        />
      </React.Fragment>
    )
  },
  [stateKey.addSecondaryNumber]: {
    key: optInPhoneType.SECONDARY_PHONE_OPT_OUT,
    body: 'Add this new number as the Secondary Phone Number in their profile',
    buttonContainer: ({ props, setState }) => (
      <React.Fragment>
        <Button
          onClick={() => {
            props?.cancel();
            setState(stateKey.primaryCannotAcceptSMS);
          }}
          className="modal-button"
          label="Close"
        />
      </React.Fragment>
    )
  },
  [stateKey.secondaryRidePhone]: {
    key: optInPhoneType.SECONDARY_PHONE_OPT_OUT,
    body: '',
    buttonContainer: ({ props }) => (
      <React.Fragment>
        <Button
          onClick={() => {
            props?.continue();
          }}
          className="modal-button"
          label="Ok"
        />
      </React.Fragment>
    )
  },
  [stateKey.promptedSMSKey]: {
    key: optInPhoneType.SECONDARY_PHONE_OPT_OUT,
    hasPrompt: true,
    body: '',
    buttonContainer: () => null
  }
};

export function SecondaryPhoneReminderModal({ isOpen, phone, ...props }) {
  return (
    <ReactModal isOpen={isOpen} style={optInStyles}>
      <div className="OptInModal">
        <div className="modal-body">
          Please inform the member that for this ride, we will send the SMS notification
          to {formatPhoneNumber(phone)}
        </div>
        <div className="modal-buttons flex">
          <React.Fragment>
            <Button
              onClick={() => {
                props.makeSelection({ bookingPhone: phone });
                props?.handleRedirect?.();
                props?.continue();
              }}
              className="modal-button green-btn"
              label="Ok"
            />
          </React.Fragment>
        </div>
      </div>
    </ReactModal>
  );
}

const OptInContainer = props => {
  const { personalInfo, isOpen } = props;
  const { primaryCannotAcceptSMS, secondaryRidePhone } = stateKey;

  const [localModalState, setState] = useState(primaryCannotAcceptSMS);

  const {
    custom_text_messaging: isCustomTextMessage,
    eligibility_api: isEligibilityAPI
  } = useAppSelector(state => state?.user?.features);
  const {
    version,
    value: optInValue,
    phone = '',
    type
  } = getOptInValue(personalInfo, ModalState?.[localModalState]?.hasPrompt, {
    isCustomTextMessage,
    isEligibilityAPI
  });

  const handleSecondaryPhone = () => {
    if (optInValue === optInPhoneType.SECONDARY_PHONE_OPT_OUT) {
      setState(secondaryRidePhone);
    } else {
      return false;
    }
  };

  if (isEmpty(optInValue)) return null;

  if (type === modalDisplayType.displayOptInModal) {
    return (
      <OptInModal
        {...props}
        isSecondary={
          optInValue === optInPhoneType.SECONDARY_PHONE_OPT_OUT && handleSecondaryPhone
        }
        bookingPhone={phone}
        optOutField={optInValue}
      />
    );
  }

  if (type === modalDisplayType.displaySecondaryPhoneReminderModal) {
    return <SecondaryPhoneReminderModal {...props} phone={phone} version={version} />;
  }

  return type === modalDisplayType.primaryPhoneCannotAcceptText &&
    !ModalState[primaryCannotAcceptSMS]?.hasPrompt ? (
      <ReactModal isOpen={isOpen} style={optInStyles}>
        <div className="OptInModal">
          <div className="modal-body">{ModalState[localModalState].body}</div>
          <div className="modal-buttons flex">
            {ModalState[localModalState].buttonContainer({ props, setState })}
          </div>
        </div>
      </ReactModal>
    ) : null;
};

const mapStateToProps = state => ({
  optInText: get(state.user, 'customTextConfig.opt_in_text', null)
});

export default connect(mapStateToProps)(OptInContainer);
