import React from 'react';
import { Card, Input, Link, TOKENS, useModel } from '@SRHealth/frontend-lib';
import { useAppSelector } from '~/Modules';
import {
  additionalPassengerFactory,
  type AdditionalPassengerModel,
  type PassengerInfoModel
} from '~/models';
import type { VehicleSubType, VehicleTypeRecord } from '~/types';
import { NOOP } from '~/utilities/helperFunctions';
import { MAX_ADDITIONAL_PASSENGERS } from '../PassengerInfo.constants';

const ADDITIONAL_PASSENGER_TYPES = [
  { label: 'Adult', value: 'adult' },
  { label: 'Child', value: 'child' }
];

type AdditionalPassengersProps = {
  model: PassengerInfoModel;
  onSave: () => void;
  onCancel: () => void;
};

export default function AdditionalPassengers({
  model,
  onSave,
  onCancel
}: AdditionalPassengersProps) {
  const transportTypes = useAppSelector(
    ({ memberProfile }) => memberProfile.transportTypes ?? {}
  );

  const _transportTypes = Object.values(transportTypes?.[model.tripType] ?? {}).filter(
    ({ label }) => label
  );

  const allowAddPassenger = model.additionalPassengers.length < MAX_ADDITIONAL_PASSENGERS;

  // If main passenger transport type is Lyft or Uber then we restrict the additional passengers
  // to be of the same transport type.
  const disablePassengerTripType =
    model.transportType === 'LFT' || model.transportType === 'UBR';

  const handleRemove = (index: number) => {
    const arr = [...model.additionalPassengers];
    arr.splice(index, 1);
    model.additionalPassengers = arr;
  };

  /** Required to allow editing existing additional passengers. */
  const handleSave = () => {
    model.additionalPassengers = [...model.additionalPassengers];
    onSave();
  };

  return (
    <Card
      id="additional-passengers"
      label="Additional Passengers"
      border={false}
      editable={true}
      onEdit={NOOP}
      onSave={handleSave}
      onCancel={onCancel}
      columns={3}
    >
      {model.additionalPassengers.map((passenger, i) => {
        return (
          <AdditionalPassenger
            key={i}
            index={i}
            passenger={passenger}
            transportTypes={_transportTypes}
            restrictToRideshare={disablePassengerTripType}
            handleRemove={handleRemove}
          />
        );
      })}
      {model.additionalPassengers.length && (
        <div
          className={`reset-div flex flex-row border-t-1 border-${TOKENS.BORDER.NEUTRAL_MD}`}
        />
      )}
      {allowAddPassenger && (
        <div className="flex flex-row" style={{ justifyContent: 'flex-end' }}>
          <Link
            iconLeft="AddCircleOutline"
            label="Passenger"
            onClick={() => {
              model.additionalPassengers = [
                ...model.additionalPassengers,
                additionalPassengerFactory({
                  transportType: model?.transportType
                })
              ];
            }}
          />
        </div>
      )}
    </Card>
  );
}

type AdditionalPassengerProps = {
  index: number;
  passenger: AdditionalPassengerModel;
  transportTypes: VehicleTypeRecord[];
  restrictToRideshare: boolean;
  handleRemove: (index: number) => void;
};

function AdditionalPassenger({
  index,
  passenger,
  transportTypes,
  restrictToRideshare,
  handleRemove
}: AdditionalPassengerProps) {
  const { model } = useModel(passenger);

  let subTransportTypes: VehicleSubType[] = [];

  if (!restrictToRideshare) {
    subTransportTypes =
      transportTypes.find(({ value }) => value === model.transportType)?.subTypes ?? [];
  }

  return (
    <Card.Content span={3}>
      <div
        className="grid gap-x-[32px] gap-y-[16px] grid-cols-3"
        style={{ overflow: 'unset', marginBottom: '12px' }}
      >
        <Card.Content span={3}>
          <div
            className={
              'reset-div flex flex-row py-[4px] items-center' +
              ` border-b-1 border-${TOKENS.BORDER.NEUTRAL_MD}`
            }
          >
            <p className="flex-1 heading-sm">Passenger {index + 1}</p>
            <Link
              label="Remove"
              iconRight="Close"
              size="sm"
              onClick={() => handleRemove(index)}
            />
          </div>
        </Card.Content>
        <Card.Content span={1}>
          <div className="flex flex-col gap-[8px]">
            <Input
              inputId={0}
              name="type"
              type="radio"
              className="py-[12px]"
              options={ADDITIONAL_PASSENGER_TYPES}
              value={passenger.type}
              error={'type' in passenger.ruleErrors}
              onChange={(_id, val: (typeof passenger)['type']) => {
                passenger.type = val;
              }}
            />
            <Input
              inputId={1}
              type="checkbox"
              name="isAttendant"
              label="Attendant (Must be an adult)"
              className="py-[12px]"
              options={[{ label: 'Yes', value: true }]}
              value={passenger.isAttendant ? [true] : []}
              error={'isAttendant' in passenger.ruleErrors}
              disabled={passenger.type === 'child'}
              onChange={(_id, val: boolean[]) => {
                passenger.isAttendant = val.length > 0;
              }}
            />
          </div>
        </Card.Content>
        <Card.Content span={1}>
          <div className="flex flex-col gap-[12px]">
            <Input
              inputId={2}
              type="single"
              name="firstName"
              label="First Name"
              value={passenger.firstName}
              placeholder="First Name"
              error={'firstName' in passenger.ruleErrors}
              onChange={(_id, val: (typeof passenger)['firstName']) => {
                passenger.firstName = val;
              }}
            />
            <Input
              inputId={3}
              type="single"
              name="lastName"
              label="Last Name"
              value={passenger.lastName}
              placeholder="Last Name"
              error={'lastName' in passenger.ruleErrors}
              onChange={(_id, val: (typeof passenger)['lastName']) => {
                passenger.lastName = val;
              }}
            />
          </div>
        </Card.Content>
        <Card.Content span={1}>
          <div className="flex flex-col gap-[12px]">
            <Input
              inputId={4}
              type="select"
              name="transportType"
              label="Transport Type"
              placeholder="Select One"
              options={transportTypes}
              value={passenger.transportType}
              error={'transportType' in passenger.ruleErrors}
              disabled={!!restrictToRideshare}
              onChange={(_id, v: (typeof model)['transportType']) => {
                passenger.transportType = v;
              }}
              required
            />
            {!!subTransportTypes?.length && (
              <Input
                inputId={5}
                type="select"
                name="subTransportType"
                label="Sub Transport Type"
                value={passenger.subTransportType}
                options={subTransportTypes}
                placeholder="Select One"
                error={'subTransportType' in passenger.ruleErrors}
                onChange={(_id, v: (typeof model)['subTransportType']) => {
                  passenger.subTransportType = v;
                }}
              />
            )}
          </div>
        </Card.Content>
      </div>
    </Card.Content>
  );
}
