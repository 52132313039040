import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import useModal from '~/hooks/useModal';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiTypography-h6': {
    textAlign: 'center'
  },

  '& .MuiDialogContent-root': {
    'display': 'flex',
    'flexDirection': 'column',
    'justifyContent': 'center',
    'alignItems': 'center',
    'padding': '16px 16px 0 16px',
    'position': 'relative',
    'borderBottom': 'unset',

    '& .MuiSvgIcon-colorError': {
      fill: '#cb2800'
    },

    '& #modal-close-btn': {
      'margin': 'unset',
      'padding': '5px',
      'position': 'absolute',
      'top': 0,
      'right': '7px',
      'color': 'white',

      '& .MuiSvgIcon-root': {
        fill: 'black'
      }
    },

    '& .MuiTypography-body1': {
      fontWeight: 500
    }
  },
  '& .MuiDialogActions-root': {
    'display': 'flex',
    'alignSelf': 'center',
    'padding': theme.spacing(1),
    'paddingBottom': '16px',

    '& .MuiButtonBase-root': {
      minWidth: '120px',
      minHeight: '28px',
      padding: 'unset',
      borderRadius: '15px',
      fontSize: '0.75rem',
      color: '#006DA3',
      border: '1px solid #006DA3',
      fontWeight: '400'
    },

    '& .MuiButtonBase-root:first-of-type': {
      color: 'white',
      backgroundColor: '#006DA3'
    }
  }
}));

export interface DialogTitleProps extends React.ComponentPropsWithoutRef<'div'> {
  children?: React.ReactNode;
}

const BootstrapDialogTitle: React.FC<DialogTitleProps> = ({ children }) => {
  return (
    <DialogTitle style={{ color: 'white', backgroundColor: '#36474F', minWidth: 360 }}>
      {children}
    </DialogTitle>
  );
};

type Callback = (any?) => void;

export type ModalProps = {
  /** Displayed at the top of the modal. */
  title: string;
  /** Optional string to display as the modal content. */
  message?: string;
  /** Forces the modal open/closed. */
  isOpen: boolean;
  /** Optional JSX to display as modal content. */
  content?: JSX.Element;
  /** Optional icon element to display next to the message. */
  icon?: JSX.Element;
  /** Allow users to automatically close the modal by clicking outside
   * of it. Defaults to true. */
  autoclose?: boolean;
  /** Displays the value on the "confirm" button. Requires an
   * onConfirm callback to render. */
  confirmLabel?: string;
  /** Displays the value on the "cancel" button. Requires an
   * onCancel callback to render. */
  cancelLabel?: string;
  /** The callback performed when the user selects the confirm button.
   * Causes the "confirm" button to render. */
  onConfirm?: () => void;
  /** The callback performed when the user selects the cancel button.
   * Causes the "cancel" button to render. */
  onCancel?: () => void;
  /** An optional callback to perform when the user selects the X icon
   * to close the modal. */
  onClose?: () => void;
};

const SRModal: React.FC<never> = () => {
  const [modal, ModalActions] = useModal();

  /**
   * A utility function that wraps a callback. Forces the modal to close
   * after the callback is executed.
   * @param callback
   * @returns
   */
  const wrapClosingCallback = (callback: Callback = () => {}) => {
    return (_event?: object, reason?: string) => {
      const autoclose = modal.autoclose || modal.autoclose === undefined;

      if (autoclose || reason !== 'backdropClick') {
        callback();
        ModalActions.setIsOpen(false);
        ModalActions.reset();
      }
    };
  };

  /** We wrap the callbacks in a function to close the modal when executing */
  const onConfirm = useCallback(wrapClosingCallback(modal.onConfirm), [modal.onConfirm]);
  const onCancel = useCallback(wrapClosingCallback(modal.onCancel), [modal.onCancel]);
  const onClose = useCallback(wrapClosingCallback(modal.onClose), [modal.onClose]);

  return (
    <div data-testid="SR-modal">
      <BootstrapDialog
        onClose={onClose}
        keepMounted
        aria-labelledby="customized-dialog-title"
        open={modal.isOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title">
          {modal.title}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          {modal.onClose && (
            <IconButton id="modal-close-btn" aria-label="close" onClick={onClose}>
              <CloseIcon />
            </IconButton>
          )}

          <Typography gutterBottom>{modal?.icon}</Typography>

          {modal?.message && <Typography gutterBottom>{modal.message}</Typography>}
          {modal?.content ?? null}
        </DialogContent>

        <DialogActions>
          <ModalButton
            label={modal?.confirmLabel}
            onClick={modal.onConfirm ? onConfirm : undefined}
          />

          <ModalButton
            label={modal?.cancelLabel}
            onClick={modal.onCancel ? onCancel : undefined}
          />
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

interface ModalButtonProps {
  label?: string;
  onClick?: Callback;
}

const ModalButton: React.FC<ModalButtonProps> = ({ label, onClick }) => {
  if (!label || !onClick) {
    return null;
  }

  return (
    <Button autoFocus disableRipple onClick={onClick}>
      {label}
    </Button>
  );
};

export default SRModal;
export { useModal };
