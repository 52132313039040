import { createContext } from 'react';
import { NOOP } from '~/utilities/helperFunctions';

export type MapMarker = {
  lat: number;
  lng: number;
  type?: 'depart' | 'arrive';
  popupContent?: string;
  preOpenedPopup?: boolean;
};

export type MapContextType = [
  MapMarker[],
  {
    addMarker: (marker: MapMarker) => void;
    removeMarker: (idx: number) => void;
    setMarkers: (markers: MapMarker[]) => void;
  }
];

export const MapContext = createContext<MapContextType>([
  [],
  { addMarker: NOOP, removeMarker: NOOP, setMarkers: NOOP }
]);
