import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import _ from 'lodash-es';
import Cookies from 'js-cookie';
import {
  postMessage,
  bookRide,
  cancelRequest,
  readMessages,
  selectRequest,
  reassignNemt,
  confirmSuggestion
} from '~/Modules/chats';
import LiveChatLauncher from './Components/LiveChatLauncher/';
import LiveChatConversationList from './Components/LiveChatConversationList/';
import LiveChatHeader from './Components/LiveChatHeader/';
import LiveChatConversation from './Components/LiveChatConversation/';
import LiveChatReassign from './Components/LiveChatReassign';

class LiveChat extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focus: true,
      isOpen: this.getIsOpen(),
      path: '',
      rideId: null,
      selectedRequestId: null,
      showReassignWindow: false,
      isCancelEnabled: true,
      isReassignEnabled: true,
      selectedVehicleOwnerId: 0,
      requestId: ''
    };
  }

  componentDidMount() {
    window.addEventListener('focus', this.onFocus);
    window.addEventListener('blur', this.onBlur);
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.onFocus);
    window.removeEventListener('blur', this.onBlur);
  }

  componentDidUpdate(prevProps = {}) {
    const selectedRequestId = _.find(
      Object.keys(this.props.chats),
      requestId => this.props.chats[requestId].selected
    );
    const prevSelectedRequestId = _.find(
      Object.keys(prevProps.chats),
      requestId => prevProps.chats[requestId].selected
    );
    const sessionRequestId = window.sessionStorage.getItem(
      `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`
    );
    const requestStatus = _.get(
      this.props,
      `chats.${selectedRequestId}.requestStatus`,
      ''
    );
    const prevRequestStatus = _.get(
      prevProps,
      `chats.${selectedRequestId}.requestStatus`,
      ''
    );

    // if the selected request ID has changed
    // typically due to user selection or receiving a new chat
    if (
      selectedRequestId &&
      (selectedRequestId !== prevSelectedRequestId ||
        selectedRequestId !== sessionRequestId)
    ) {
      // set selected request to session storage for later retrieval
      window.sessionStorage.setItem(
        `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`,
        selectedRequestId
      );
      // override user preference to show new chat
      this.setState({ isOpen: true }, () => this.setIsOpen(true));
    }

    // check if selected chat status changed
    if (requestStatus !== prevRequestStatus) {
      // if it's booked or cancelled, redirect to remove "Processing" ride card
      if (
        requestStatus === 'booked' ||
        (prevRequestStatus === 'open' && requestStatus === 'cancelled')
      ) {
        const path = '/ride/scheduled';
        const rideId = _.get(
          this.props,
          `chats.${selectedRequestId}.rideDetails.rideId`,
          0
        );

        // only redirect to scheduled ride tab if you're on cancelled, willcall or completed
        if (
          window.location.pathname.indexOf('/ride/scheduled') === -1 &&
          window.location.pathname.indexOf('/ride/all') === -1
        ) {
          this.setState({
            path,
            rideId,
            selectedRequestId
          });
        }
      }
    }
  }

  onFocus = () => {
    const selectedRequestId = this.getSelectedRequestId();
    _.debounce(() => {
      this.setMessagesRead(selectedRequestId);
    }, 500)();
    this.setState({
      focus: true
    });
  };

  onBlur = () => {
    this.setState({
      focus: false
    });
  };

  onNewMessage = () => {
    const selectedRequestId = this.getSelectedRequestId();
    if (this.state.focus && typeof selectedRequestId !== 'undefined') {
      this.setMessagesRead(selectedRequestId);
    }
  };

  /**
   * Handle window toggle (minimize/maximize)
   * @return {undefined}
   */
  handleWindowToggle = () => {
    this.setState({ isOpen: !this.state.isOpen }, () =>
      this.setIsOpen(this.state.isOpen)
    );
  };

  /**
   * Handle cancel request
   * @param  {String} requestId Request ID
   * @return {undefined}
   */
  handleCancelRequest = requestId => {
    this.setState(
      {
        isCancelEnabled: false,
        isReassignEnabled: false
      },
      () => {
        const hospitalUserId = _.get(this.props, `chats.${requestId}.hospitalUserId`, {});
        this.props.cancelChatRequest({ requestId, hospitalUserId });
      }
    );
  };

  /**
   * Handle request to reassign NEMT
   * @param {Number} requestId Request ID
   * @returns {undefined}
   */
  handleReassignRequest = requestId => {
    this.setState({
      isReassignEnabled: false,
      showReassignWindow: true,
      requestId
    });
  };

  /**
   * Handle request to close reassign NEMT window
   * @returns {undefined}
   */
  handleReassignWindowClose = () => {
    this.setState({
      isReassignEnabled: true,
      showReassignWindow: false
    });
  };

  /**
   * Handle request to reassign ride request to a different NEMT company
   * @param {integer} vehicleId - id for vehicle
   * @returns {undefined}
   */
  handleReassignmentConfirmation = vehicleId => {
    const selectedChat = this.getSelectedChat();

    this.setState(
      {
        showReassignWindow: false,
        isReassignEnabled: true
      },
      () => {
        this.props.reassignNemt({
          requestId: selectedChat.requestId,
          hospitalUserId: selectedChat.hospitalUserId,
          // vehicleOwnerId: this.state.selectedVehicleOwnerId,
          vehicleOwnerId: vehicleId
        });
      }
    );
  };

  /**
   * Handle confirm ride
   * @param  {String}     [requestId=''] Request ID
   * @return {undefined}
   */
  handleConfirmRide = (requestId = '') => {
    const chatRequest = _.get(this.props, `chats.${requestId}`, {});
    const hospitalUserId = _.get(chatRequest, 'hospitalUserId', 0);
    const careInitiatorId = _.get(chatRequest, 'careInitiatorId', 0);
    const careInitiator = _.get(chatRequest, `users.${careInitiatorId}`, {});
    const hospitalName = _.get(chatRequest, 'facilityDetails.hospitalName', '');
    const text = `Request suggestions accepted by ${careInitiator.firstName} ${careInitiator.lastName} at ${hospitalName}`;
    const textType = 'careAcceptSuggestion';
    const data = {
      requestId,
      hospitalUserId,
      confirmData: {
        acceptedAt: Date.now()
      }
    };

    // post message and book ride
    this.sendMessage(requestId, text, textType);
    this.props.confirmSuggestion({
      requestId
    });
    this.props.bookRideRequest(data);
  };

  /**
   * Handle reject ride
   * @param  {String}     [requestId=''] Request ID
   * @return {undefined}
   */
  handleRejectRide = (requestId = '') => {
    const chatRequest = _.get(this.props, `chats.${requestId}`, {});
    const careInitiatorId = _.get(chatRequest, 'careInitiatorId', 0);
    const careInitiator = _.get(chatRequest, `users.${careInitiatorId}`, {});
    const hospitalName = _.get(chatRequest, 'facilityDetails.hospitalName', '');
    const text = `Time suggestions rejected by ${careInitiator.firstName} ${careInitiator.lastName} at ${hospitalName}`;
    const textType = 'careRejectSuggestion';
    this.sendMessage(requestId, text, textType);
  };

  /**
   * Get value of isopen cookie
   * @return {Boolean}  true/false
   */
  getIsOpen = () => {
    const chatIsOpen = Cookies.get(`${process.env.REACT_APP_ENVIRONMENT}_chat_isopen`);
    return typeof chatIsOpen === 'undefined' || chatIsOpen === 'true';
  };

  /**
   * Set value of isopen cookie
   * @param {String} [value=''] Cookie value
   * @return {undefined}
   */
  setIsOpen = (value = '') => {
    Cookies.set(`${process.env.REACT_APP_ENVIRONMENT}_chat_isopen`, value.toString());
  };

  /**
   * Get sorted list of chats
   * @return {Array} Sorted list of chats
   */
  getChats = () => {
    const userId = _.get(this.props, 'user.userData.id', 0);
    const validStatus = ['open', 'active', 'booked', 'cancelled'];

    const chats = Object.keys(this.props.chats)
      .map(requestId => this.props.chats[requestId])
      .filter(chatRequest => chatRequest.careInitiatorId === userId)
      .filter(chatRequest => validStatus.indexOf(chatRequest.requestStatus) !== -1)
      .sort((a, b) => b.requestedAt - a.requestedAt);

    return chats;
  };

  /**
   * Get selected request ID
   * @return {String}            Request ID
   */
  getSelectedRequestId = () => {
    const chats = this.getChats();
    const selectedRequest = _.find(chats, chat => chat.selected);
    const sessionRequestId = window.sessionStorage.getItem(
      `${process.env.REACT_APP_ENVIRONMENT}_chat_selectedrequest`
    );
    const sessionRequest = _.find(chats, chat => chat.requestId === sessionRequestId);

    // check for value in props first
    if (selectedRequest) {
      return selectedRequest.requestId;
    } else if (sessionRequest) {
      return sessionRequest.requestId;
    } else if (chats.length > 0) {
      // get newest request
      return chats[0].requestId;
    } else {
      return '';
    }
  };

  /**
   * Get selected chat object
   * @return {Object} Chat object
   */
  getSelectedChat = () => {
    const chats = this.getChats();
    const selectedRequestId = this.getSelectedRequestId(chats);
    return _.find(chats, chat => chat.requestId === selectedRequestId);
  };

  /**
   * Set request messages as read
   * @param  {String}     [requestId=''] Request ID
   * @return {undefined}
   */
  setMessagesRead = (requestId = '') => {
    const readerId = _.get(this.props, 'user.userData.id', 0);
    const hospitalUserId = _.get(this.props, `chats.${requestId}.hospitalUserId`, 0);
    const data = {
      requestId,
      hospitalUserId,
      readerId
    };
    this.props.putMessagesRead(data);
  };

  /**
   * Send message
   * @param  {String} [requestId=''] Request ID
   * @param  {String} [text='']      Message text
   * @param  {String} type           Message type (optional)
   * @return {undefined}
   */
  sendMessage = (requestId = '', text = '', type) => {
    const senderId = _.get(this.props, 'user.userData.id', 0);
    const hospitalUserId = _.get(this.props, `chats.${requestId}.hospitalUserId`, 0);
    const data = {
      requestId,
      hospitalUserId,
      senderId,
      text,
      type
    };
    this.props.postChatMessage(data);
  };

  render() {
    const chats = this.getChats();
    const selectedChat = this.getSelectedChat();
    const selectedRequestId = this.getSelectedRequestId();

    // return early if no chats found
    if (chats.length === 0) {
      return null;
    }

    // show chat launcher if minimized
    if (!this.state.isOpen) {
      return (
        <LiveChatLauncher
          user={this.props.user}
          chats={this.props.chats}
          handleWindowToggle={this.handleWindowToggle}
        />
      );
    }

    return (
      <div className="liveChatWindow">
        <LiveChatHeader
          selectedChat={selectedChat}
          isCancelEnabled={this.state.isCancelEnabled}
          isReassignEnabled={this.state.isReassignEnabled}
          handleWindowToggle={this.handleWindowToggle}
          handleCancelRequest={this.handleCancelRequest}
          handleReassignRequest={this.handleReassignRequest}
        />
        <div className="liveChatBody">
          <LiveChatConversation
            user={this.props.user}
            onNewMessage={this.onNewMessage}
            selectedChat={selectedChat}
            sendMessage={this.sendMessage}
            handleConfirmRide={this.handleConfirmRide}
            handleRejectRide={this.handleRejectRide}
          />
          <LiveChatConversationList
            user={this.props.user}
            chats={chats}
            selectedRequestId={selectedRequestId}
            setSelectedRequest={this.props.setSelectedRequest}
          />
        </div>
        {this.state.path !== '' ? (
          <Redirect
            to={{
              pathname: this.state.path,
              state: {
                rideId: this.state.rideId,
                selectedRequestId: this.state.selectedRequestId
              }
            }}
          />
        ) : null}
        {this.state.showReassignWindow ? (
          <LiveChatReassign
            selectedChat={selectedChat}
            selectedVehicleOwnerId={this.state.selectedVehicleOwnerId}
            hideReassignWindow={() => this.handleReassignWindowClose()}
            confirmReassignment={this.handleReassignmentConfirmation}
            requestId={this.state.requestId}
            user={this.props.user}
          />
        ) : null}
      </div>
    );
  }
}

LiveChat.propTypes = {
  user: PropTypes.object,
  chats: PropTypes.object,
  postChatMessage: PropTypes.func,
  bookRideRequest: PropTypes.func,
  cancelChatRequest: PropTypes.func,
  putMessagesRead: PropTypes.func,
  setSelectedRequest: PropTypes.func,
  bookingData: PropTypes.object,
  reassignNemt: PropTypes.func,
  confirmSuggestion: PropTypes.func
};

LiveChat.defaultProps = {
  user: {},
  chats: {},
  postChatMessage: () => {},
  bookRideRequest: () => {},
  cancelChatRequest: () => {},
  putMessagesRead: () => {},
  setSelectedRequest: () => {},
  bookingData: {},
  reassignNemt: () => {},
  confirmSuggestion: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  chats: state.chats,
  bookingData: state.bookingData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      postChatMessage: data => postMessage(data),
      bookRideRequest: data => bookRide(data),
      cancelChatRequest: data => cancelRequest(data),
      putMessagesRead: data => readMessages(data),
      setSelectedRequest: data => selectRequest(data),
      reassignNemt: data => reassignNemt(data),
      confirmSuggestion: data => confirmSuggestion(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(LiveChat);
