import React from 'react';

const SvgTrash = props => {
  const {title, className} = props;

  return (
    <svg className={className}>
      <use xlinkHref="#si-awesome-trash">
        <svg viewBox="0 0 1408 1536" aria-labelledby="wasi-awesome-trash-title" id="si-awesome-trash">
          <title id="wasi-awesome-trash-title">{title}</title>
          <path d="M512 1248V544q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V544q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zm256 0V544q0-14-9-23t-23-9h-64q-14 0-23 9t-9 23v704q0 14 9 23t23 9h64q14 0 23-9t9-23zM480 256h448l-48-117q-7-9-17-11H546q-10 2-17 11zm928 32v64q0 14-9 23t-23 9h-96v948q0 83-47 143.5t-113 60.5H288q-66 0-113-58.5T128 1336V384H32q-14 0-23-9t-9-23v-64q0-14 9-23t23-9h309l70-167q15-37 54-63t79-26h320q40 0 79 26t54 63l70 167h309q14 0 23 9t9 23z"></path>
        </svg>
      </use>
    </svg>
  );
};

export default SvgTrash;
