import React, { useMemo } from 'react';
import type { AddressApprovedProviderModel, AddressModel } from '~/models';
import useApprovedProviders from '~/hooks/useApprovedProviders';
import useMapboxSearch from '~/hooks/useMapboxSearch';
import {
  genDefaultAddressOptions,
  useProviderAddressChangeHandler,
  useProviderCoordUpdateHandler
} from './Address.utils';
import { capitalize, Input, isEmpty, useModel } from '@SRHealth/frontend-lib';
import Divider from './Divider';

export type CareProviderFormProps = {
  type: 'depart' | 'arrive';
  address: AddressApprovedProviderModel;
  hospitalId: number;
  onAddressUpdate: (address: AddressModel) => void;
};

export default function CareProviderForm({
  type,
  address,
  hospitalId,
  onAddressUpdate
}: CareProviderFormProps) {
  const { model } = useModel(address);
  const [isSearching, providerOpts, onProviderInput, onLoadMore] = useApprovedProviders(
    hospitalId,
    address
  );

  const defaultMapOpt = useMemo(() => genDefaultAddressOptions(address), [address]);

  const [_, mapboxOptions, handleMapboxInput] = useMapboxSearch(defaultMapOpt);

  const currentProvider = providerOpts.find(({ value }) => value.id === address?.id);
  const currentMapbox = mapboxOptions.find(({ value }) => value.value === address?.value);

  /** Event handler for changes to the care provider input. */
  const handleProviderChange = useProviderAddressChangeHandler(onAddressUpdate);
  /** Event handler for changes to the mapbox input. */
  const handleMapboxChange = useProviderCoordUpdateHandler(address!, onAddressUpdate);

  return (
    <>
      <Input
        inputId={0}
        name="search"
        label={`${capitalize(type)} - Care Provider`}
        type="search-dropdown"
        loading={isSearching}
        options={providerOpts}
        value={currentProvider?.value}
        dropdownSize={6}
        onInput={onProviderInput}
        onSearch={onProviderInput}
        onChange={handleProviderChange}
        onLoadMore={onLoadMore}
        error={'name' in model.ruleErrors}
        required
      />
      {!isEmpty(model?.npi) && (
        <Input
          inputId={1}
          type="search-dropdown"
          name={`${type}-mapbox`}
          options={mapboxOptions}
          loading={false}
          value={currentMapbox?.value}
          debounce={650}
          icon={undefined}
          onSearch={handleMapboxInput}
          onInput={handleMapboxInput}
          onChange={handleMapboxChange}
          error={'value' in model.ruleErrors}
          required
        />
      )}
      <Divider />
    </>
  );
}
