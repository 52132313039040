import React from 'react';

export type PassengerSummaryProps = {
  hideAddBtn: boolean;
  passengers: any[];
  style?: Record<string, any>;
};

export const PassengerSummary: React.FC<PassengerSummaryProps> = ({
  style = {},
  hideAddBtn = false,
  passengers = []
}) => {
  const className = 'passenger-summary-item';

  let childCount = 0;
  let adultCount = 0;

  passengers.forEach(({ageGroup}) => {
    if (ageGroup === 'adult') adultCount++;
    if (ageGroup === 'child') childCount++;
  });

  return (
    <>
      {!hideAddBtn && !adultCount && !childCount ? (
        <span style={style} className={className}>
          + Add Passengers
        </span>
      ) : null}
      {adultCount ? (
        <span style={style} className={className}>
          {adultCount} Adult{adultCount > 1 ? 's' : ''} &nbsp;
        </span>
      ) : null}
      {childCount ? (
        <span style={style} className={className}>
          {childCount} Child{childCount > 1 ? 'ren' : ''}
        </span>
      ) : null}
    </>
  );
};
