/** Creates a unified interface for SharedWorker and Worker. */
export default class SafeRideWorker {
  /**
   * Clones message and transmits it to worker's global environment. transfer can be passed as a list of objects that are to be transferred rather than cloned.
   *
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/Worker/postMessage)
   */
  postMessage: {
    (message: any, transfer: Transferable[]): void;
    (message: any, options?: StructuredSerializeOptions): void;
  };

  /**
   * Begins dispatching messages received on the port.
   *
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/MessagePort/start)
   */
  start: () => void;

  /**
   * Aborts worker's associated global environment.
   *
   * [MDN Reference](https://developer.mozilla.org/docs/Web/API/Worker/terminate)
   */
  terminate: () => void;
  addEventListener:
    | {
        <K extends keyof WorkerEventMap>(
          type: K,
          listener: (this: Worker, ev: WorkerEventMap[K]) => any,
          options?: boolean | AddEventListenerOptions
        ): void;
        (
          type: string,
          listener: EventListenerOrEventListenerObject,
          options?: boolean | AddEventListenerOptions
        ): void;
      }
    | {
        <K extends keyof MessagePortEventMap>(
          type: K,
          listener: (this: MessagePort, ev: MessagePortEventMap[K]) => any,
          options?: boolean | AddEventListenerOptions
        ): void;
        (
          type: string,
          listener: EventListenerOrEventListenerObject,
          options?: boolean | AddEventListenerOptions
        ): void;
      };
  removeEventListener:
    | {
        <K extends keyof WorkerEventMap>(
          type: K,
          listener: (this: Worker, ev: WorkerEventMap[K]) => any,
          options?: boolean | EventListenerOptions
        ): void;
        (
          type: string,
          listener: EventListenerOrEventListenerObject,
          options?: boolean | EventListenerOptions
        ): void;
      }
    | {
        <K extends keyof MessagePortEventMap>(
          type: K,
          listener: (this: MessagePort, ev: MessagePortEventMap[K]) => any,
          options?: boolean | EventListenerOptions
        ): void;
        (
          type: string,
          listener: EventListenerOrEventListenerObject,
          options?: boolean | EventListenerOptions
        ): void;
      };

  private worker: SharedWorker | Worker;
  private target: Worker | MessagePort;

  /** Creates a common interface for SharedWorker and Worker. */
  constructor() {
    if ('SharedWorker' in window) {
      this.worker = new SharedWorker(
        new URL('~/workers/pusher/pusher-shared.ts', import.meta.url),
        {
          name: 'pusher-shared'
        }
      );

      this.target = this.worker.port;
    } else {
      this.worker = new Worker(new URL('~/workers/pusher/pusher.ts', import.meta.url), {
        name: 'pusher'
      });

      this.target = this.worker;
    }

    this.postMessage = this.target.postMessage.bind(this.target);
    this.addEventListener = this.target.addEventListener.bind(this.target);
    this.removeEventListener = this.target.removeEventListener.bind(this.target);
    this.start = 'start' in this.target ? this.target.start.bind(this.target) : () => {};
    this.terminate =
      'terminate' in this.target
        ? this.target.terminate.bind(this.target)
        : this.target.close.bind(this.target);
  }

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Worker/message_event) */
  set onmessage(
    fn:
      | ((this: Worker, ev: MessageEvent) => any)
      | ((this: MessagePort, ev: MessageEvent) => any)
      | null
  ) {
    this.target.onmessage = fn;
  }

  /** [MDN Reference](https://developer.mozilla.org/docs/Web/API/Worker/messageerror_event) */
  set onmessageerror(
    fn:
      | ((this: Worker, ev: MessageEvent) => any)
      | ((this: MessagePort, ev: MessageEvent) => any)
      | null
  ) {
    this.target.onmessageerror = fn;
  }
}
