import { hasBecomeActive, hasBeenAbandoned, rideBookingMiddleware } from '.';
import type { AppListenerEffectAPI } from '~/Modules';
import { completeDateThunk, initializeRbfNewThunk } from '../thunks';
import { analyticsService } from '~/analytics';
import type { AppStore } from '~/types';

const EVENT_NAME = 'RBF Date' as const;

let sectionTime = 0;
let startPoint = 0;

/** Reset the section time and start time to 0 when
 * loading new RBF. */
rideBookingMiddleware.startListening({
  actionCreator: initializeRbfNewThunk.fulfilled,
  effect: () => {
    sectionTime = 0;
    startPoint = 0;
  }
});

/** Starting State */
rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBecomeActive('date', currentState, oldState),
  effect: (_, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;

    startPoint = Date.now();
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Started',
      TimeOnSection: sectionTime,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Completed State */
rideBookingMiddleware.startListening({
  actionCreator: completeDateThunk.fulfilled,
  effect: (action, listenerApi: AppListenerEffectAPI) => {
    const user = listenerApi.getState().user;
    const date = listenerApi.getState().rideBooking.date;
    const duplicateRides = action.payload ?? [];

    sectionTime += Date.now() - startPoint;

    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Completed',
      DateSelected: date.date!,
      TimeOnSection: sectionTime,
      DuplicateFound: duplicateRides.length > 0,
      ...analyticsService.mapUserProps(user)
    });
  }
});

/** Abandoned ride. */
rideBookingMiddleware.startListening({
  predicate: (_, currentState: AppStore, oldState: AppStore) =>
    hasBeenAbandoned('date', currentState, oldState),
  effect: (_, { getState }: AppListenerEffectAPI) => {
    const user = getState().user;
    const model = getState().rideBooking.date;

    sectionTime += Date.now() - startPoint;

    // Dispatch RBF Passenger Info Abandoned event
    analyticsService.trackEvent(EVENT_NAME, {
      EditStatus: 'Abandoned',
      TimeOnSection: sectionTime,
      DateSelected: model.date ?? undefined,
      DuplicateFound: !!(model.isDirty && model.date),
      ...analyticsService.mapUserProps(user)
    });

    startPoint = 0;
  }
});
