import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';
import InputText from '~/Shared/Components/InputText';
import type { BenefitAddonMetadata, BenefitsForm } from '~/types';
import { isEmpty } from '@SRHealth/frontend-lib';
import LoadingModal from '~/Shared/Components/LoadingModal/LoadingModal';
import moment from 'moment';

interface BenefitAddonFieldProps {
  heading: string;
  children: React.ReactNode;
  className?: string;
}

const BenefitAddonField: React.FC<BenefitAddonFieldProps> = ({ heading, children }) => {
  return (
    <div className="CustomInputText halfColumn">
      <p className="benefitsHeading">{heading}</p>
      {children}
    </div>
  );
};

interface BenefitAddonInput {
  err: boolean;
  errorText: string;
  value: string;
}

interface BenefitAddonProps {
  benefits: BenefitsForm;
  editBenefits: boolean;
  onAddOnChange: (id: string, value: string, isError: boolean, errorText: string) => void;
  currentFormState: Record<string, BenefitAddonInput>;
}

const BenefitAddons: React.FC<BenefitAddonProps> = ({
  benefits,
  editBenefits,
  onAddOnChange,
  currentFormState
}) => {
  const [addonFormState, setAddonFormState] = useState({});
  const metadata = benefits?.addonsMetadata;

  useEffect(() => {
    if (metadata && isEmpty(addonFormState)) {
      const initialFormState = metadata.reduce(
        (acc, addon) => ({
          ...acc,
          [`addon-${addon.id}`]: {
            value: '',
            error: false,
            errorText: ''
          }
        }),
        {}
      );

      setAddonFormState(initialFormState);
    }
  }, [metadata]);

  if (isEmpty(metadata)) {
    return (
      <LoadingModal
        label="Searching for member benefit addons..."
        isOpen={isEmpty(metadata)}
      />
    );
  }

  const handleOnChange = ({ target }, id) => {
    setAddonFormState(prev => ({
      ...addonFormState,
      [id]: {
        ...prev[id],
        value: target.value
      }
    }));

    onAddOnChange(id, target.value, false, '');
  };

  const formatDate = (date: string) => {
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  };

  const getFormState = (
    addonId: number,
    formState: Record<string, BenefitAddonInput>
  ) => {
    const formKey = `addon-${addonId}`;
    return !isEmpty(formState) ? formState[formKey] : null;
  };

  const getBalance = (addon: BenefitAddonMetadata): string => {
    const usage = benefits?.addonsUsage[addon.id] ?? 0;
    const balance = addon.limit - usage;

    return `${balance} rides`;
  };

  const setStartDate = (addon: BenefitAddonMetadata) => {
    const memberAddon = benefits?.memberAddons[addon.id];
    const formState = getFormState(addon.id, currentFormState);

    if (memberAddon?.start_date && !editBenefits) {
      return formatDate(memberAddon?.start_date);
    }

    //Start date is edited,
    return (
      <InputText
        customClassName="fullColumn"
        placeholder="Enter value"
        mask="_"
        fieldName="startDate"
        error={formState?.err ?? false}
        errorText={formState?.errorText ?? ''}
        onChange={e => handleOnChange(e, `addon-${addon.id}`)}
        value={
          !isEmpty(addonFormState)
            ? (addonFormState[`addon-${addon.id}`]?.value ?? '')
            : ''
        }
        disabled={!editBenefits}
        format="##/##/####"
      />
    );
  };

  console.log(editBenefits, benefits?.memberAddons[1]);
  return (
    <>
      {benefits?.addonsMetadata.map(addon => (
        <div key={addon.id}>
          <Container className="parentTab benefits">
            <div className="memberRow">
              <div className="CustomInputText twoColumn">
                <p className="benefitsHeading">Health Plan</p>
                <p className="benefitsText">{benefits.healthPlanName || '--'}</p>
              </div>
              <div className="CustomInputText twoColumn">
                <p className="benefitsHeading">Benefit Add-On</p>
                <p className="benefitsText">{addon.name || '--'}</p>
              </div>
            </div>
          </Container>

          <Container className="parentTab benefits">
            <div className="memberRow">
              <BenefitAddonField heading="Member Ride Benefit">
                <p className="benefitsText">
                  {addon.limit} Rides / {addon.duration} {addon.duration_unit}
                </p>
              </BenefitAddonField>

              <BenefitAddonField heading="Start Date">
                {setStartDate(addon)}
              </BenefitAddonField>

              <BenefitAddonField heading="Usage (YTD)">
                <p>{benefits?.addonsUsage[addon.id] ?? 0} rides</p>
              </BenefitAddonField>

              <BenefitAddonField heading="End Date">
                <p>
                  {benefits?.memberAddons[addon.id]?.end_date
                    ? formatDate(benefits?.memberAddons[addon.id]?.end_date)
                    : ''}
                </p>
              </BenefitAddonField>

              <BenefitAddonField heading="Balance (YTD)">
                {getBalance(addon)}
              </BenefitAddonField>
            </div>
          </Container>
        </div>
      ))}
    </>
  );
};

export default BenefitAddons;
