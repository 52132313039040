import React, { useEffect, useMemo } from 'react';
import NumberFormat from 'react-number-format';

type InputParams = {
  name: string;
  id: string;
  type: React.HTMLInputTypeAttribute;
  value: string | number | readonly string[];
  required: boolean;
  disabled: boolean;
  placeholder: string;
  min?: string | number;
  max?: string | number;
  pattern?: string;
  onClick: (e: React.MouseEvent) => void;
  onChange: (e: React.ChangeEvent) => void;
  onKeyPress: (e: React.KeyboardEvent) => void;
  onFocus: () => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
};

export interface InputTextProps extends React.ComponentPropsWithoutRef<'input'> {
  fieldName: string;
  label?: string;
  mask?: string;
  black?: boolean;
  error?: boolean;
  errorText?: string;
  format?: string;
  refInput?: Record<string, any>;
  showLabel?: boolean;
  customClassName?: string;
  onKeyPress?: (e) => void;
  onClick?: (e: React.MouseEvent) => void;
  onBlur?: (e: React.FocusEvent) => void;
  onChange?: (e: React.ChangeEvent) => void;
}

const InputText: React.FC<InputTextProps> = props => {
  const [focus, setFocus] = React.useState(false);

  const {
    fieldName,
    label = '',
    error = false,
    format = '',
    mask = '',
    min = 0,
    max = 0,
    pattern = '',
    required = false,
    value = '',
    disabled = false,
    errorText = '',
    type = 'text',
    showLabel = false,
    placeholder = '',
    onKeyPress = () => {},
    onClick = () => {},
    onBlur = () => {},
    onChange = () => {}
  } = props;

  /** Setter logic for className */
  const className = useMemo(() => {
    let str = 'CustomInputText';

    if (props?.black) {
      str += ` black`;
    }

    if (props?.className) {
      str += ` ${props.className}`;
    }

    if (props?.customClassName) {
      str += ` ${props.customClassName}`;
    }

    if (props?.error) {
      str += ` error`;
    }

    return str;
  }, [props.black, props.customClassName, props.className, props.error]);

  /** Generate the params object for the input */
  const getParams = () => {
    const params: InputParams = {
      name: fieldName,
      id: fieldName,
      type,
      value,
      required,
      disabled,
      placeholder: !focus && placeholder ? placeholder : '',
      onClick,
      onChange,
      onKeyPress,
      onFocus: () => setFocus(true),
      onBlur: e => {
        if (e?.target?.value?.length === 0) setFocus(false);

        onBlur(e);
      }
    };

    if (type === 'number' && min !== max) {
      params.min = min;
      params.max = max;
    }

    if (pattern) {
      params.pattern = pattern;
    }

    return params;
  };

  /** Focus on input change */
  useEffect(() => {
    if (props?.value) {
      setFocus(true);
    }
  }, [props.value]);

  const params = getParams();

  return (
    <div className={className}>
      {(focus || showLabel) && (
        <label htmlFor={fieldName} className="inputLabel">
          {required && <span className="showAsterisk">*</span>}
          {label}
        </label>
      )}

      {format ? (
        <NumberFormat
          {...(params as Record<string, unknown>)}
          format={format}
          mask={mask}
        />
      ) : (
        <input {...params} autoComplete="off" />
      )}

      {errorText && error && <p className="errorText">{errorText}</p>}
    </div>
  );
};

export default InputText;
