import React from 'react';

const SvgSecondPlaceMedal = props => {
  const { className } = props;

  return (
    <svg className={className} height="30" viewBox="0 0 512 512" width="30" xmlns="http://www.w3.org/2000/svg">
      <g>
        <g>
          <path d="m235.468 382.791-82.53-28.542-18.209 1.855-39.353 113.066c-1.254 3.601 1.664 7.284 5.457 6.886l55.163-5.776c1.541-.161 3.073.372 4.181 1.455l39.655 38.779c2.726 2.666 7.3 1.591 8.553-2.01l39.611-113.805z" fill="#98db7c" />
          <path d="m247.996 394.699 8.004-22.995-113.29-38.526-7.98 22.927c32.17 22.955 71.122 37.007 113.266 38.594z" fill="#82d361" />
          <path d="m276.532 382.791 82.53-28.542 18.209 1.855 39.354 113.066c1.253 3.601-1.664 7.284-5.457 6.886l-55.163-5.776c-1.541-.161-3.073.372-4.18 1.455l-39.655 38.779c-2.726 2.666-7.3 1.591-8.553-2.01l-39.611-113.805z" fill="#98db7c" />
          <path d="m264.004 394.699-8.004-22.995 113.29-38.526 7.98 22.927c-32.17 22.955-71.122 37.007-113.266 38.594z" fill="#82d361" /><path d="m256 359.438c-185.62 0-185.774-179.85-185.62-182.953 4.879-98.291 86.118-176.485 185.62-176.485 99.436 0 180.633 78.09 185.61 176.288.161 3.168 0 183.15-185.61 183.15z" fill="#e0e0e0" />
          <path d="m256 352.969c-99.502 0-180.738-78.194-185.618-176.485-.154 3.103-.234 6.226-.234 9.367 0 102.643 83.209 185.852 185.852 185.852s185.852-83.209 185.852-185.852c0-3.142-.08-6.264-.234-9.367-4.88 98.291-86.116 176.485-185.618 176.485z" fill="#cecece" />
          <path d="m256 320.63c-146.412 0-146.313-141.048-146.117-144.146 4.831-76.496 68.403-137.044 146.117-137.044s141.286 60.548 146.117 137.044c.196 3.098 0 144.146-146.117 144.146z" fill="#9e9e9e" />
          <path d="m256 313.529c-77.713 0-141.274-60.55-146.105-137.044-.196 3.098-.307 6.22-.307 9.367 0 80.861 65.551 146.412 146.412 146.412s146.412-65.551 146.412-146.412c0-3.148-.111-6.269-.307-9.367-4.831 76.494-68.392 137.044-146.105 137.044z" fill="#898989" />
          <path d="m298.146 272.82h-83.922c-10.165 0-18.014-17.695-17.249-21.739 2.123-11.23 7.12-21.812 14.581-30.631l59.049-69.788c5.525-6.53 3.06-13.566 2.172-15.568-1.079-2.433-4.434-8.122-12.25-8.386-.226-.008-.461-.012-.696-.012-10.979 0-19.91 8.932-19.91 19.911 0 10.165-8.241 18.406-18.406 18.406s-18.406-8.24-18.406-18.406c0-31.276 25.445-56.723 56.722-56.723.647 0 1.291.011 1.932.033 19.57.66 36.682 12.254 44.665 30.255 8.102 18.27 5.143 39.064-7.722 54.268l-59.048 69.788c-.217.257-.43.517-.638.781h59.127c6.74 0 12.634 3.623 15.841 9.028 1.628 2.746-5.677 18.783-15.842 18.783z" fill="#e0e0e0" />
          <g>
            <path d="m298.146 263.085h-83.922c-7.91 0-14.635-4.999-17.238-12.002-.765 4.045-1.168 8.171-1.168 12.331 0 10.166 8.241 18.406 18.406 18.406h83.922c10.165 0 18.406-8.24 18.406-18.406 0-3.425-.953-6.621-2.582-9.367-3.207 5.404-9.084 9.038-15.824 9.038z" fill="#cecece" />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default SvgSecondPlaceMedal;