import { LOCATION_CHANGE } from 'connected-react-router';
import {
  CHANNEL_PREFIX_DRIVERS,
  CHANNEL_PREFIX_RIDES,
  MAX_HOSPITALS_PUSHER_AUTH
} from '~/constants';

import {
  rideCardActions,
  newRideThunk,
  rideCreatedThunk,
  rideInfoUpdateThunk
} from '~/Modules/rideCards/rideCards.actions';
import { NO_SUBSCRIPTION_PATH_SEGMENTS } from './Modules/user';
import { subscribe, unsubscribe } from '~/utilities/pusherRedux';

/**
 * Updates pusher subscriptions based on user location when navigating between pages
 * @param store
 * @return {function(*): function(*): *}
 */
const locationChangeMiddleware = store => next => action => {
  if (action.type === LOCATION_CHANGE) {
    const currentState = store.getState();

    const currentLocation = currentState.router.location.pathname;
    const newLocation = action.payload.location.pathname;

    const shouldSubscribeAtCurrentLocation = !NO_SUBSCRIPTION_PATH_SEGMENTS.some(
      pathSegment => currentLocation.includes(pathSegment)
    );

    /** If the new location doesn't match any path segments in the NO_SUBSCRIPTION_PATH_SEGMENTS array,
     * we should subscribe to the push service channels */
    const shouldSubscribeAtNewLocation = !NO_SUBSCRIPTION_PATH_SEGMENTS.some(
      pathSegment => newLocation.includes(pathSegment)
    );

    // Don't change the subscriptions if the page is just going to
    // subscribe (or unsubscribe) to the exact same as before
    if (shouldSubscribeAtCurrentLocation === shouldSubscribeAtNewLocation) {
      return next(action);
    }

    const hospitalUsers = currentState.user.hospitalUsers ?? [];

    if (shouldSubscribeAtNewLocation) {
      hospitalUsers.forEach(hospital => {
        const ridesChannel = `${CHANNEL_PREFIX_RIDES}-${hospital.userId}`;
        subscribe(ridesChannel, 'rideStatusUpdate', rideCardActions.RIDE_STATUS_UPDATE);
        subscribe(ridesChannel, 'newRide', 'newRideThunk', newRideThunk);
        subscribe(
          ridesChannel,
          'rideInfoUpdate',
          'rideInfoUpdateThunk',
          rideInfoUpdateThunk
        );
        subscribe(
          ridesChannel,
          'rideLocationUpdate',
          rideCardActions.RIDE_LOCATION_UPDATE
        );
        subscribe(
          ridesChannel,
          'rideAlertClaimStatusUpdate',
          rideCardActions.RIDE_CLAIM_STATUS_UPDATE
        );
        subscribe(ridesChannel, 'rideCreated', 'rideCreatedThunk', rideCreatedThunk);

        const driversChannel = `${CHANNEL_PREFIX_DRIVERS}-${hospital.userId}`;
        subscribe(
          driversChannel,
          'rideLocationUpdate',
          rideCardActions.RIDE_LOCATION_UPDATE
        );

        if (hospitalUsers.length <= MAX_HOSPITALS_PUSHER_AUTH) {
          hospitalUsers.forEach(({ userId }) => {
            // subscribe to bulk edit events on hospital channels
            const ridesChannel = `${CHANNEL_PREFIX_RIDES}-${userId}`;
            subscribe(ridesChannel, 'rideBulkUpdate', rideCardActions.BULK_EDIT_EVENT);
          });
        }
      });
    } else {
      const channelsEvents = {};
      hospitalUsers.forEach(hospital => {
        const ridesChannel = `${CHANNEL_PREFIX_RIDES}-${hospital.userId}`;
        channelsEvents[ridesChannel] = {
          events: {
            rideStatusUpdate: [rideCardActions.RIDE_STATUS_UPDATE],
            newRide: ['newRideThunk'],
            rideInfoUpdate: ['rideInfoUpdateThunk'],
            rideLocationUpdate: [rideCardActions.RIDE_LOCATION_UPDATE],
            rideAlertClaimStatusUpdate: [rideCardActions.RIDE_CLAIM_STATUS_UPDATE],
            rideBulkUpdate: [rideCardActions.BULK_EDIT_EVENT],
            rideCreated: ['rideCreatedThunk']
          }
        };
      });
      unsubscribe(channelsEvents);
    }
  }

  // Continue the action chain
  return next(action);
};

export default locationChangeMiddleware;
