import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactModal from 'react-modal';
import { generateTimestamp } from '~/utilities/timesAndDates';

import { bindActionCreators } from 'redux';
import { putMemberProfile } from '~/Modules/memberProfile/actions';
import Button from '~/Shared/Components/Common/Button';
import { get, isEmpty } from 'lodash-es';
import {
  formatPhoneNumber,
  formatPhoneNumberToE164Standard
} from '~/utilities/helperFunctions';
import { mapMemberPhoneNumberUpdateRequest } from '~/utilities/memberPhoneNumber.helper';
import { optInStyles } from '../OptInContainer/OptIn.styles';
import { PAGES } from '~/constants';
import { SecondaryPhoneReminderModal } from '../OptInContainer/OptInContainer';
import { processOptOutField } from '../OptInContainer/OptIn.utils';
const NONE_VALUE = 'None';
const ALL_VALUE = 'All';

const modalCopy = `
  As part of the SafeRide service, we would like to send you text messages 
  related to your ride bookings and status. These messages may contain personal health 
  information that will be sent unencrypted and may be at risk of interception. 
  Message and data rates may apply. Message frequency varies. 
  You can always opt out by texting STOP and get help by texting HELP. 
  Mobile terms of service can be found at https://saferidehealth.com/terms-of-service and our privacy policy can be found at https://saferidehealth.com/privacy-policy. 
  Please reply with a “yes” or “no” if you would like this service.
`;

class OptInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSecondary: false
    };
  }

  handleOptIn = ({
    bookingPhone,
    optOutField,
    noneId,
    memberPhoneNumber,
    personalInfo,
    mobileSchema
  }) => {
    if (this.props.isSecondary) {
      this.props.isSecondary?.();
      this.setState({ isSecondary: true });
    } else {
      this.handleRedirect({
        bookingPhone,
        optOutField,
        noneId,
        memberPhoneNumber,
        personalInfo,
        mobileSchema
      });
    }
  };

  handleRedirect = ({
    bookingPhone,
    optOutField,
    noneId,
    memberPhoneNumber,
    personalInfo,
    mobileSchema
  }) => {
    this.props.makeSelection({
      bookingPhone: bookingPhone
    });
    this.props.putMemberProfile({
      memberProfile: {
        [optOutField]: noneId,
        prompted_sms_opt_in: 1,
        passenger_phone_number_id: memberPhoneNumber?.id || null,
        member_phone_numbers: mapMemberPhoneNumberUpdateRequest({
          personalInfo,
          state: {
            ...mobileSchema,
            [`${optOutField}Id`]: noneId,
            opt_out_prompt_timestamp: generateTimestamp().date,
            [`${optOutField}Timestamp`]: generateTimestamp().date,
            opt_out: 0
          }
        })
      },
      passengerId: this.props.selectedId,
      formType: 'personalInfo'
    });
  };

  render() {
    const { user, isOpen, optOutField, personalInfo, bookingPhone = null } = this.props;
    const { memberPhoneNumbers = [] } = personalInfo;
    const optInText = get(user, 'customTextConfig.opt_in_text', null);
    const { optInTarget, currentOptOutValue, memberPhoneNumber } = processOptOutField({
      optOutField,
      personalInfo,
      memberPhoneNumbers
    });

    const optOutValues = get(personalInfo, `${optOutField}.values`, []);
    const noneObj = !isEmpty(optOutValues)
      ? optOutValues.find(x => x.value === NONE_VALUE)
      : null;
    const allObj = !isEmpty(optOutValues)
      ? optOutValues.find(x => x.value === ALL_VALUE)?.id
      : null;

    const mobileSchema = {
      mobileNo: formatPhoneNumberToE164Standard(personalInfo.mobileNo),
      phone2: formatPhoneNumberToE164Standard(personalInfo.phone2)
    };

    const noneId = noneObj?.id;

    if (this.state.isSecondary) {
      return (
        <SecondaryPhoneReminderModal
          {...this.props}
          handleRedirect={() =>
            this.handleRedirect({
              bookingPhone,
              optOutField,
              noneId,
              memberPhoneNumber,
              personalInfo,
              mobileSchema
            })
          }
          phone={bookingPhone}
        />
      );
    }

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel="This is the opt-in text"
        style={optInStyles}
      >
        <div className="OptInModal">
          <div className="modal-header">
            <div>
              Please read the following Opt-In text to secure permission to send text
              messages to {formatPhoneNumber(bookingPhone || optInTarget)}
            </div>
          </div>
          <div className="modal-body">
            <div>{optInText || modalCopy}</div>
          </div>
          <div className="modal-buttons flex">
            {this.props.page === PAGES.RIDE_BOOKING_FLOW ? null : (
              <Button
                onClick={() => {
                  this.props.cancel();
                }}
                className="modal-button grey-btn"
                label="Close"
              />
            )}
            <div className="spacer-48"></div>
            <div className="spacer-48"></div>
            <div className="spacer-48"></div>
            <Button
              onClick={() => {
                if (this.props.page === PAGES.MEMBER_PROFILE) {
                  this.props.makeSelection({
                    bookingPhone: null
                  });
                  this.props.putMemberProfile({
                    memberProfile: {
                      /** SMS 1.0 */
                      [optOutField]: allObj ?? currentOptOutValue ?? null,
                      prompted_sms_opt_in: 1,
                      /** SMS 2.0 */
                      passenger_phone_number_id: memberPhoneNumber?.id || null,
                      member_phone_numbers: mapMemberPhoneNumberUpdateRequest({
                        personalInfo,
                        state: {
                          ...mobileSchema,
                          [`${optOutField}Id`]: allObj ?? currentOptOutValue ?? null,
                          opt_out: 1,
                          opt_out_prompt_timestamp: generateTimestamp().date,
                          [`${optOutField}Timestamp`]: generateTimestamp().date
                        }
                      })
                    },
                    passengerId: this.props.selectedId,
                    formType: 'personalInfo'
                  });
                } else {
                  this.props.makeSelection({
                    bookingPhone: null,

                    [optOutField]: allObj ?? currentOptOutValue ?? null,
                    prompted_sms_opt_in: 1,
                    /** SMS 2.0 */
                    passenger_phone_number_id: memberPhoneNumber?.id || null,
                    opt_out: 1,
                    member_phone_numbers: mapMemberPhoneNumberUpdateRequest({
                      personalInfo,
                      state: {
                        ...mobileSchema,
                        [`${optOutField}Id`]: allObj ?? currentOptOutValue ?? null,
                        opt_out_prompt_timestamp: generateTimestamp().date,
                        [`${optOutField}Timestamp`]: generateTimestamp().date,
                        opt_out: 1
                      }
                    })
                  });
                }
              }}
              className="modal-button red-btn"
              label="NO, Opt-out of SMS"
            />
            <div className="spacer-8"></div>
            <Button
              onClick={() => {
                if (this.props.page === PAGES.MEMBER_PROFILE) {
                  this.handleOptIn({
                    bookingPhone,
                    optOutField,
                    noneId,
                    memberPhoneNumber,
                    personalInfo,
                    mobileSchema
                  });
                } else {
                  this.props.makeSelection({
                    bookingPhone: bookingPhone,
                    [optOutField]: noneId,
                    opt_out: 0,
                    opt_out_prompt_timestamp: generateTimestamp().date,
                    [`${optOutField}Timestamp`]: generateTimestamp().date
                  });
                }
              }}
              className="modal-button green-btn"
              label="YES, Opt-in to SMS"
            />
          </div>
        </div>
      </ReactModal>
    );
  }
}

OptInModal.propTypes = {
  user: PropTypes.object,
  personalInfo: PropTypes.object,
  isOpen: PropTypes.bool,
  selectedId: PropTypes.string,
  phoneNumber: PropTypes.string,
  optOutField: PropTypes.string,
  makeSelection: PropTypes.func,
  cancel: PropTypes.func
};

OptInModal.defaultProps = {
  user: {},
  personalInfo: [],
  isOpen: false,
  selectedId: 0,
  phoneNumber: '',
  optOutField: null,
  makeSelection: () => {},
  cancel: () => {},
  page: PAGES.MEMBER_PROFILE
};

const mapStateToProps = state => ({
  user: state.user,
  personalInfo: get(state, 'memberProfile.formData.personalInfo', [])
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(OptInModal);
