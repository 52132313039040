import { put, delay } from 'redux-saga/effects';
import axios from 'axios';
import { baseRequestConfig } from '~/utilities/auth.helper';
import {
  GET_MEMBER_PERMISSIONS_SUCCESS,
  GET_MEMBER_PERMISSIONS_ERROR,
  CREATE_NEW_MEMBER_SUCCESS,
  CREATE_NEW_MEMBER_ERROR
} from './constants';

import { mapCreateMemberBody } from './helpers';

const MEMBER_URL = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members`;

export function* fetchMemberPermissions(action) {
  try {
    const url = `${MEMBER_URL}/permissions`;
    const { data, error } = yield axios.get(url, baseRequestConfig());
    if (error) throw new Error(error);
    yield put({
      type: GET_MEMBER_PERMISSIONS_SUCCESS,
      payload: { success: true, ...data, ...action }
    });
  } catch (error) {
    yield put({
      type: GET_MEMBER_PERMISSIONS_ERROR,
      payload: { success: false, data: [] }
    });
  }
}

export function* createMember(params) {
  try {
    const url = `${MEMBER_URL}/create`;
    const body = mapCreateMemberBody(params.payload);

    const response = yield axios.post(url, body, baseRequestConfig());
    const data = response.data;
    yield delay(500);
    let memberProfile = {};
    if (data && data.data && data.data.id) {
      let getMemberUrl = `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/members/view/${data.data.id}`;
      if (data.data.health_sub_plan_id) {
        getMemberUrl += `?subPlanId=${data.data.health_sub_plan_id}`;
      }
      const profile = yield axios.get(getMemberUrl, baseRequestConfig());
      memberProfile = profile.data.data;
    }
    yield put({
      type: CREATE_NEW_MEMBER_SUCCESS,
      payload: { data, memberProfile }
    });
  } catch (error) {
    let message = 'Something went wrong';
    if (error.response.status === 409) {
      message =
        'The medical id and plan information provided matches an existing member. Please review or dismiss.';
    }
    yield put({
      type: CREATE_NEW_MEMBER_ERROR,
      payload: { success: false, error: message }
    });
  }
}
