/** The potential trip types users can select in the TripType
 * edit panel. */
export const TRIP_TYPE_OPTIONS = [
  { label: 'Private Transport', value: 'private' },
  { label: 'Public Transport', value: 'public' },
  { label: 'Alternative Services', value: 'alternate' },
  {
    label: 'Mileage Reimbursement',
    value: 'mileage-reimbursement'
  }
] as const;

/** Warning text if a user aims to change the phone number on a trip. Displayed
 * in the TripPhoneNumber component when opened on edit. */
export const TRIP_NUMBER_WARNING =
  'Note: This will change the member\'s phone number for ' +
  'only this trip. The number will not be saved to the members profile.';

/** The warning text if the passenger has landline as their main phone
 * contact. */
export const LANDLINE_WARNING =
  'The number detected cannot receive SMS messages. This member will not receive text ' +
  'communications about their reservation. Additionally, Flexible Ride Share pick ups ' +
  'cannot be booked for this member.';

/** User alert text that alternate numbers are not texted. */
export const ALTERNATE_NUMBERS_TXT_WARNING =
  'Alternate Numbers do not get text communications.';

/** Controls how many total additional passengers can be added to
 * a ride. */
export const MAX_ADDITIONAL_PASSENGERS = 5;
