import { baseRequestConfig } from '~/utilities/auth.helper';
import type { Binary } from '~/types';

export type GetPatientRequestBody = {
  id?: string;
  medicalId?: string;
  hospitalId?: number;
};

export type GetPatientResponseBody = {
  data: {
    highrisk: boolean;
    patient_details: {
      id: number;
      medicalId: string;
      first_name: string;
      last_name: string;
      phone: string;
      patient_notes: string | null;
      dateOfBirth: string;
      timezoneFormat: string;
      patient_assistance_needs: Record<string, Binary>;
      mobility_type: number;
      mobility_sub_type: string | null;
      phone2: string | null;
      additionalPassengers: unknown[];
      default_mobility_mode: string;
      phoneNumbers: {
        id: number;
        passenger_id: number;
        phone_number_id: number;
        is_primary: Binary;
        opt_out_prompt_timestamp: string;
        deleted_at: string | null;
        created_at: string;
        updated_at: string;
        phone_number: {
          id: number;
          phone_number: string;
          textable: Binary;
          phone_type: string;
          opt_out: Binary;
          deleted_at: string | null;
          created_at: string;
          updated_at: string;
        };
      }[];
      savedAddresses: {
        is_default: Binary;
        passenger_id: number;
        name: string;
        address: string;
        zip: string;
        latitude: number;
        longitude: number;
        id: number;
      }[];
      status: number;
    };
    assistance_needs: BE.AssistanceNeed[];
    provider_history: BE.ProviderHistory[];
  };
};

/** Load patient info from Laravel */
export async function getPatient(params: GetPatientRequestBody) {
  // Uses a different path parameter. Patient is singular here and
  // plural for other endpoints.
  return fetch(`${process.env.REACT_APP_API_URL}/patient/lookup`, {
    method: 'post',
    headers: baseRequestConfig().headers,
    body: JSON.stringify({ data: params })
  }).then(res => res.json() as Promise<GetPatientResponseBody>);
}
