import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { get, isEmpty, isEqual, cloneDeep, pick, debounce, clone } from 'lodash-es';
// Modules
import {
  updateBookingData,
  updateRideData,
  updateRideDetails
} from '~/Modules/bookingData';
import { autoComplete, checkLocation, getTimezoneFromLatLong } from '~/Modules/mapbox';
import { getVenues } from '~/Modules/venues';
// Constants
import type { IProps, IState } from './TransportType.constants';
import { initialState } from './TransportType.constants';

// Utilities
import { flipAddresses } from '~/utilities/helperFunctions';
import { validApprovedProviders } from '~/utilities/rideFormValidation';
import {
  canEnableApprovedProviders,
  extractAutoCompleteApprovedProvider,
  extractAutoComplete,
  extractSavedAddressAutoComplete,
  canEnableSavedAddress,
  canEnableVenues,
  extractVenueAutoComplete,
  extractEntranceAutoComplete,
  extractResetAutoComplete
} from './TransportType.utils';

// Components
import { VenuesContainer } from './components/VenuesContainer';
import { ApprovedProvidersContainer } from './components/ApprovedProvidersContainer';
import DefaultRouteContainer from './components/DefaultRouteContainer';
import { TransportTypeHOC } from './TransportTypeHOC';

export type AutoCompleteSource =
  | 'approvedProvider'
  | 'venue'
  | 'entrance'
  | 'savedAddress'
  | 'reset';

class TransportType extends React.Component<IProps, IState> {
  static propTypes;
  static defaultProps;

  refMedicalId: {};
  refDOB: {};
  currentRideIndex: number;
  // temp fake coords
  coords: { lng: number; lat: number };

  constructor(props: IProps) {
    super(props);
    this.state = { ...initialState };
  }

  /**
   * set location state based on bookingData
   * since transportation type name is not in bookingData yet add it
   * @return {undefined}
   */
  componentDidMount() {
    const { bookingData }: { bookingData: BookingDataStore } = this.props;

    if (!isEmpty(bookingData)) {
      this.setLocationState();
    }

    this.initializeSavedAddress();
    this.initializeApprovedProviders();
    this.initializeVenues();
  }

  /**
   * reset location state if bookingData props change
   * @param {object} prevProps - props
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    let dropoffAddressDidChange = false;
    let pickupAddressDidChange = false;
    let pickupLatitudeDidChange = false;
    let pickupLongitudeDidChange = false;
    if (this.currentRideIndex === -1) {
      const bookingData = this.props.bookingData;
      const prevBookingData = prevProps.bookingData;

      dropoffAddressDidChange =
        bookingData?.dropoffAddress !== prevBookingData?.dropoffAddress;
      pickupAddressDidChange =
        bookingData?.pickupAddress !== prevBookingData?.pickupAddress;
      pickupLatitudeDidChange =
        bookingData?.pickupLatitude !== prevBookingData?.pickupLatitude;
      pickupLongitudeDidChange =
        bookingData?.pickupLongitude !== prevBookingData?.pickupLongitude;
    } else {
      const rideData = this.props.bookingData.rides[this.currentRideIndex];
      const prevRideData = prevProps.bookingData.rides[this.currentRideIndex];

      dropoffAddressDidChange = rideData?.dropoffAddress !== prevRideData?.dropoffAddress;
      pickupAddressDidChange = rideData?.pickupAddress !== prevRideData?.pickupAddress;
      pickupLatitudeDidChange = rideData?.pickupLatitude !== prevRideData?.pickupLatitude;
      pickupLongitudeDidChange =
        rideData?.pickupLongitude !== prevRideData?.pickupLongitude;
    }

    if (
      dropoffAddressDidChange ||
      pickupAddressDidChange ||
      pickupLatitudeDidChange ||
      pickupLongitudeDidChange
    ) {
      this.setLocationState();
    }

    if (
      this.props.missingFields.length > 0 &&
      !isEqual(this.props.missingFields, prevProps.missingFields)
    ) {
      if (
        this.props.missingFields.indexOf('pickupAddress') > -1 ||
        this.props.missingFields.indexOf('pickupZipcode') > -1
      ) {
        if (this.state.showPickupVenue) {
          if (this.props.missingFields.indexOf('pickupAddress') > -1) {
            // venue mapping, show error state if address is missing
            this.setState({ selectedPickupError: true });
          }
        } else {
          this.setState({ pickupClassName: 'error' });
        }
      }
      if (
        this.props.missingFields.indexOf('dropoffAddress') > -1 ||
        this.props.missingFields.indexOf('dropoffZipcode') > -1
      ) {
        if (this.state.showDropoffVenue) {
          if (this.props.missingFields.indexOf('dropoffAddress') > -1) {
            // venue mapping, show error state if address is missing
            this.setState({ selectedDropoffError: true });
          }
        } else {
          this.setState({ dropoffClassName: 'error' });
        }
      }
    }
    if (
      !this.state.displayApprovedProviders &&
      this.props.missingFields.length === 0 &&
      prevProps.missingFields.length > 0
    ) {
      this.setState({
        invalidPickupZipcode: false,
        invalidDropoffZipcode: false,
        dropoffClassName: '',
        pickupClassName: ''
      });
    }
  }

  /**
   * Detects if we have a default saved address and automatically
   * sets it as the pickup address
   */
  initializeSavedAddress = () => {
    if (!canEnableSavedAddress(this.props)) return;

    const { savedAddresses, savedAddress, bookingData, legIndex } = this.props;

    const isMultileg = bookingData.bookingType === 'multileg';

    if (Array.isArray(savedAddresses)) {
      // We only check index 0 here because the saved addresses should be
      // sorted with the default address always occupying the first position
      if (
        !this.props.editRide &&
        savedAddresses[0]?.is_default &&
        (!isMultileg || legIndex <= 0)
      ) {
        this.selectAutoComplete(savedAddresses[0], 'pickup', 'savedAddress');
        savedAddress.actions.Toggle('pickup', true);
      }
    }
  };

  initializeApprovedProviders = () => {
    if (!canEnableApprovedProviders(this.props)) return;

    const patientDetails = this.props?.patientDetails ?? {};

    const approvedProvidersHardBlock =
      patientDetails?.approved_providers_hard_block === 1;

    const currentRide = this.getCurrentRide();

    const {
      pickupProviderId = 0,
      dropoffProviderId = 0,
      dropoffProviderName = '',
      pickupProviderName = '',
      pickupProviderNpi = 0,
      dropoffProviderNpi = 0,
      pickupProviderPhone = '',
      dropoffProviderPhone = '',
      pickupFacilityName = '',
      pickupProviderAddr = '',
      pickupAdditionalNotes = '',
      dropoffFacilityName = '',
      dropoffProviderAddr = '',
      dropoffAdditionalNotes = '',
      pickupProviderNotFound = false,
      dropoffProviderNotFound = false
    } = currentRide;

    const approvedProviderBookingData = {
      isApprovedProviders: true,
      displayApprovedProviders: true,
      showPickupApprovedProvider: !!pickupProviderId || !!pickupProviderNotFound,
      showDropoffApprovedProvider: !!dropoffProviderId || !!dropoffProviderNotFound,
      pickupProviderId,
      dropoffProviderId,
      dropoffProviderName,
      pickupProviderName,
      approvedProvidersHardBlock,
      pickupProviderNpi,
      dropoffProviderNpi,
      pickupProviderPhone,
      dropoffProviderPhone,
      pickupFacilityName,
      pickupProviderAddr,
      pickupAdditionalNotes,
      dropoffFacilityName,
      dropoffProviderAddr,
      dropoffAdditionalNotes
    };

    this.props.updateRideDetails(approvedProviderBookingData);
  };

  initializeVenues = () => {
    if (!canEnableVenues(this.props)) return;

    this.props.getVenues();

    const {
      pickupVenueName = '',
      pickupEntranceName = '',
      pickupVenueId = null,
      dropoffVenueName = '',
      dropoffEntranceName = '',
      dropoffVenueId = null
    } = this.props.bookingData;

    this.props.updateBookingData({
      showPickupVenue: !!pickupVenueId,
      showPickupEntrance: !!pickupEntranceName,
      showDropoffVenue: !!dropoffVenueId,
      showDropoffEntrance: !!dropoffEntranceName,
      pickupVenueName,
      pickupEntranceName,
      pickupVenueId,
      dropoffVenueName,
      dropoffEntranceName,
      dropoffVenueId
    });
  };

  /**
   * clone props into component state
   * @return {void}
   */
  setLocationState() {
    const bookingData: BookingDataStore = cloneDeep(this.props.bookingData);

    const ride = this.getCurrentRide();

    let state = pick(ride, [
      'pickupAddress',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'showPickupEntrance',
      'pickupEntranceName',
      'pickupVenueName',
      'pickupProviderNotFound',
      'showDropoffEntrance',
      'dropoffEntranceName',
      'dropoffVenueName',
      'dropoffProviderNotFound',
      'transportType',
      'transportTypeName'
    ]) as Partial<IState>;

    if (this.currentRideIndex === 0 && !this.props.editRide) {
      const {
        pickupAddress,
        pickupLatitude,
        pickupLongitude,
        pickupZipcode,
        dropoffAddress,
        dropoffLatitude,
        dropoffLongitude,
        dropoffZipcode
      } = ride;

      state = {
        ...state,
        pickupAddress,
        pickupLatitude,
        pickupLongitude,
        pickupZipcode,
        dropoffAddress,
        dropoffLatitude,
        dropoffLongitude,
        dropoffZipcode
      };

      if (canEnableApprovedProviders(this.props)) {
        state.isApprovedProviders = true;
        state.displayApprovedProviders = true;
        state.approvedProvidersHardBlock = bookingData.approvedProvidersHardBlock;
      }

      this.props.updateRideData(state, this.currentRideIndex);
    } else if (canEnableApprovedProviders(this.props)) {
      state.isApprovedProviders = true;
      state.displayApprovedProviders = true;
    }

    if (state.pickupAddress) {
      this.props.getTimezoneFromLatLong(
        state.pickupLatitude,
        state.pickupLongitude,
        false
      );
    }

    if (state.dropoffAddress) {
      this.props.getTimezoneFromLatLong(
        state.dropoffLatitude,
        state.dropoffLongitude,
        true
      );
    }

    if (get(ride, 'showPickupEntrance', false)) {
      state.showPickupVenue = true;
    }

    if (get(ride, 'showDropoffEntrance', false)) {
      state.showDropoffVenue = true;
    }
    this.setState(state, () => {
      if (ride.pickupAddress !== '' && ride.dropoffAddress !== '') {
        this.props.updateRide();
      }
    });
  }

  /**
   * Retrieves a copy of the current ride object from props.bookingData
   * @returns {object} A deep cloned copy of the current props.bookingData ride
   */
  getCurrentRide = () => {
    let ride: Partial<RideData> | Partial<BookingDataStore> = {};

    this.currentRideIndex = get(this.props.bookingData, 'currentRideIndex', -1);

    if (this.currentRideIndex > -1) {
      if (this.props.legIndex > -1) {
        this.currentRideIndex = this.props.legIndex;
      }
      ride = get(
        this.props.bookingData,
        `rides.${this.currentRideIndex}`,
        {}
      ) as Partial<RideData>;
    } else {
      ride = this.props.bookingData;
    }

    return cloneDeep(ride);
  };

  /**
   * takes care of input for address pickup
   * @param {object} e - event
   * @return {undefined} returns nothing
   */
  handleChangePickup = e => {
    this.handleChangeAddress(e.target.value, 'pickup');
  };

  /**
   * takes care of input for address dropoff
   * @param {object} e - event
   * @return {undefined} returns nothing
   */
  handleChangeDropoff = e => {
    this.handleChangeAddress(e.target.value, 'dropoff');
  };

  /**
   * takes care of input for address pickup and generates data for map
   * and modifies redux booking state
   * @param {string} addressVal - address
   * @param {string} whereTo - pickup or dropoff
   * @return {undefined} returns nothing
   */
  handleChangeAddress = (addressVal, whereTo) => {
    const wheretoCap = whereTo === 'pickup' ? 'Pickup' : 'Dropoff';
    const { bookingData } = this.props;
    const state = {};
    state[`${whereTo}Address`] = addressVal;
    state[`show${wheretoCap}AutoComplete`] = false;

    if ((whereTo !== 'pickup' && whereTo !== 'dropoff') || addressVal.length < 3) {
      return void this.setState(state);
    }

    state[`show${wheretoCap}AutoComplete`] = true;

    this.setState(state);
    this.debounceAutoComplete(addressVal, bookingData.hospitalCoords);
  };

  debounceAutoComplete = debounce((addressVal, hospitalCoords) => {
    this.props.autoComplete(addressVal, hospitalCoords);
  }, 650);

  resetAutoComplete = (showOrHide, location) => {
    if (showOrHide) {
      return;
    }
    const wheretoCap = location === 'pickup' ? 'Pickup' : 'Dropoff';

    const state = {};
    const isApprovedProviders = this.state[`show${wheretoCap}ApprovedProvider`];

    if (isApprovedProviders) {
      state[`show${wheretoCap}ApprovedProviderAutoComplete`] = showOrHide;
      this.props.clearApprovedProviderSearchResults();
    } else {
      state[`show${wheretoCap}AutoComplete`] = showOrHide;
    }

    this.setState(state);
  };

  /**
   * switch addresses on book ride form, and switch map pin positions
   * update redux booking state so Map component can access new map data
   * @return {void} returns nothing
   */
  onSwitchAddresses = () => {
    const { bookingData, savedAddress } = this.props;

    const state =
      this.currentRideIndex > -1
        ? clone(bookingData.rides[this.currentRideIndex])
        : clone(bookingData);

    const stateAddress = flipAddresses(state);
    savedAddress.actions.Flip();

    this.setState(stateAddress);
    if (this.currentRideIndex > -1) {
      this.props.updateRideData(stateAddress, this.currentRideIndex);
    } else {
      this.props.updateBookingData(stateAddress);
    }
  };

  /**
   * show either list of locations or list of entrances
   * @param {string} whereTo - Pickup or Dropoff
   * @param {string} locationType - Entrance or Venue
   * @return {void}
   */
  showVenue = (whereTo, locationType) => {
    const venue = `show${whereTo}${locationType}`;
    const { venues, getVenues } = this.props;

    if (isEmpty(venues) && locationType !== 'ApprovedProvider') {
      getVenues();
    }

    this.setState(
      prevState =>
        ({
          [venue]: !prevState[venue],
          [`${whereTo.toLowerCase()}ProviderName`]: '',
          [`${whereTo.toLowerCase()}ProviderId`]: 0,
          [`${whereTo.toLowerCase()}ProviderNotFound`]: false
        }) as unknown as IState
    );

    if (locationType === 'ApprovedProvider') {
      if (this.currentRideIndex > -1) {
        this.props.updateRideData(
          {
            [venue]: !this.state[venue],
            [`${whereTo.toLowerCase()}ProviderName`]: '',
            [`${whereTo.toLowerCase()}ProviderId`]: 0,
            [`${whereTo.toLowerCase()}ProviderNotFound`]: false
          },
          this.currentRideIndex
        );
      } else {
        this.props.updateBookingData({
          [venue]: !this.state[venue],
          [`${whereTo.toLowerCase()}ProviderName`]: '',
          [`${whereTo.toLowerCase()}ProviderId`]: 0,
          [`${whereTo.toLowerCase()}ProviderNotFound`]: false
        });
      }
    }
  };

  /**=
   * selecting an address through autocomplete
   * @param {object} row - row selected for autocomplete
   * @param {string} type - pickup or dropoff
   * @param {string} source
   * @return {undefined} - nothing returned
   */
  selectAutoComplete = async (
    row,
    type: 'pickup' | 'dropoff',
    source?: AutoCompleteSource
  ) => {
    const {
      editRide,
      checkLocationData,
      patientDetails,
      healthSubPlanLocationsRestriction,
      savedAddress,
      getTimezoneFromLatLong,
      updateRide,
      updateRideData,
      updateBookingData
    } = this.props;

    // Standardization! Ah-hah!
    const _type = type.toLowerCase() === 'pickup' ? 'pickup' : 'dropoff';
    const typeUpper = _type.charAt(0).toUpperCase() + _type.substring(1);
    const typeAlt = _type === 'pickup' ? 'from' : 'to';

    let state: Record<string, string | number | boolean | undefined | null> = {};

    switch (source) {
      case 'approvedProvider':
        state = await extractAutoCompleteApprovedProvider(row, _type);
        break;

      case 'savedAddress':
        state = extractSavedAddressAutoComplete(row, _type);
        break;

      case 'venue':
        state = extractVenueAutoComplete(row, _type);
        break;

      case 'entrance':
        state = extractEntranceAutoComplete(row, _type);
        break;

      case 'reset':
        state = extractResetAutoComplete(row, _type);
        break;

      default:
        state = extractAutoComplete(row, _type);
    }

    state[`show${typeUpper}AutoComplete`] = false;
    state[`show${typeUpper}ApprovedProviderAutoComplete`] = false;

    if (
      !state[`${typeAlt}MemberSavedAddressId`] &&
      ((!checkLocationData?.status && patientDetails?.locations === 1) ||
        (editRide && healthSubPlanLocationsRestriction === 1))
    ) {
      this.verifyAddresses(row, _type);
    }

    //fetch timezone
    if (row?.center?.length && row?.center[0] && row?.center[1]) {
      getTimezoneFromLatLong(row.center[1], row.center[0], typeUpper !== 'Pickup');
    }

    this.setState(state, () => {
      if (this.currentRideIndex > -1) {
        updateRideData(state, this.currentRideIndex);
      } else {
        updateBookingData(state);
      }

      if (state.pickupAddress && state.dropoffAddress) {
        updateRide();
      }

      // Update is for savedAddress but savedAddress isn't toggled on?
      // Then we better toggle it!
      if (source === 'savedAddress' && !savedAddress.state[_type]) {
        savedAddress.actions.Toggle(_type, true);
      }
    });
  };

  /**
   *
   * @param bookingData
   */
  verifyAddresses = (row, type) => {
    const { hospitalId, healthPlanId, healthSubPlanId, pickupAddress, dropoffAddress } =
      this.props.bookingData;

    const params = {
      hospitalId,
      healthPlanId,
      healthSubPlanId,
      pickupAddress,
      dropoffAddress
    };
    params[`${type}Address`] = row?.provider_address;
    params[`${type}Address`] ??= row?.matching_place_name;
    params[`${type}Address`] ??= row?.place_name;

    if (this.props.editRide) {
      params.healthSubPlanId = this.props.healthSubPlanId;
    }

    this.props.toggleLoading(true, 'Verifying addresses...');
    checkLocation(params);
  };

  onCloseTransportTypeComponent = () => {
    const { bookingData, closeComponent } = this.props;

    if (bookingData.isApprovedProviders) {
      const errors = [];
      const errMsg = [];

      validApprovedProviders(bookingData, errors, errMsg);

      if (errors.length || errMsg.length) {
        this.props.transportTypeValidate(errors, errMsg.join(''));
      } else {
        this.props.transportTypeValidate([], '');
        closeComponent();
      }
    } else {
      closeComponent();
    }
  };

  render = () => {
    const { bookingData, missingFields, toggleLoading } = this.props;

    const showSavedAddress = canEnableSavedAddress(this.props);

    if (this.state.displayApprovedProviders) {
      return (
        <ApprovedProvidersContainer
          missingFields={missingFields}
          showSavedAddress={showSavedAddress}
          selectAutoComplete={this.selectAutoComplete}
          resetAutoComplete={this.resetAutoComplete}
          onChange={this.handleChangeAddress}
          toggleLoading={toggleLoading}
          bookingData={bookingData}
          updateRideDetails={this.props.updateRideDetails}
        />
      );
    }

    if (canEnableVenues(this.props)) {
      return (
        <VenuesContainer
          showSavedAddress={showSavedAddress}
          selectAutoComplete={this.selectAutoComplete}
          resetAutoComplete={this.resetAutoComplete}
          onChange={this.handleChangeAddress}
          toggleLoading={toggleLoading}
          bookingData={bookingData}
          updateRideDetails={this.props.updateRideDetails}
        />
      );
    }

    return (
      <DefaultRouteContainer
        props={this.props}
        state={this.state}
        selectAutoComplete={this.selectAutoComplete}
        resetAutoComplete={this.resetAutoComplete}
        handleChangeDropoff={this.handleChangeDropoff}
        handleChangePickup={this.handleChangePickup}
      />
    );
  };
}

TransportType.defaultProps = {
  rideDetails: {},
  vehicleTypes: [],
  updateBookingData: () => {},
  bookingData: {},
  updateRide: () => {},
  isTransportFormBleg: false,
  user: {},
  userData: {},
  missingFields: [],
  missingFieldsError: '',
  editRide: false,
  closeComponent: () => {},
  getVenues: () => {},
  venues: {},
  venueList: [],
  updateRideData: () => {},
  legIndex: -1,
  autoComplete: () => {},
  autoCompleteData: {},
  toggleLoading: () => {},
  checkLocation: () => {},
  healthSubPlanLocationsRestriction: 0,
  clearApprovedProviderSearchResults: () => {},
  healthSubPlanApprovedProviders: 0,
  displayApprovedProviders: false,
  checkLocationData: {},
  updateRideDetails: () => {}
};

TransportType.propTypes = {
  rideDetails: PropTypes.object,
  vehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  updateBookingData: PropTypes.func,
  bookingData: PropTypes.object,
  updateRide: PropTypes.func,
  isTransportFormBleg: PropTypes.bool,
  user: PropTypes.object,
  userData: PropTypes.object,
  missingFields: PropTypes.array,
  missingFieldsError: PropTypes.string,
  editRide: PropTypes.bool,
  closeComponent: PropTypes.func,
  getVenues: PropTypes.func,
  venues: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  venueList: PropTypes.array,
  updateRideData: PropTypes.func,
  legIndex: PropTypes.number,
  autoComplete: PropTypes.func,
  autoCompleteData: PropTypes.object,
  toggleLoading: PropTypes.func,
  checkLocation: PropTypes.func,
  getTimezoneFromLatLong: PropTypes.func,
  healthSubPlanLocationsRestriction: PropTypes.number,
  healthSubPlanApprovedProviders: PropTypes.number,
  displayApprovedProviders: PropTypes.bool,
  clearApprovedProviderSearchResults: PropTypes.func,
  checkLocationData: PropTypes.object
};

const mapStateToProps = state => ({
  bookingData: state.bookingData,
  userData: state.user.userData,
  user: state.user,
  venues: state.venues.venues,
  venueList: state.venues.venueList,
  autoCompleteData: state.mapbox.autoComplete,
  patientDetails: state.patients.patientDetails,
  healthSubPlanLocationsRestriction: state.rideDetails.healthSubPlanLocationsRestriction,
  healthSubPlanApprovedProviders: state.rideDetails.healthSubPlanApprovedProviders,
  healthSubPlanId: state.rideDetails.healthSubPlanId,
  savedAddresses: state.patients.savedAddresses,
  search: state.search.approvedProviders
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData,
      updateRideDetails,
      updateRideData,
      getVenues,
      autoComplete,
      checkLocation,
      getTimezoneFromLatLong
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransportTypeHOC(TransportType));
