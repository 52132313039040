import {
  Is,
  createModelFactory,
  createTypeGuard,
  type ModelType
} from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';

export type AddressType =
  | 'mapbox'
  | 'venue'
  | 'saved'
  | 'approved-provider'
  | 'provider-not-found';

export const isAddressType = createTypeGuard((t: unknown): AddressType | null => {
  if (t === 'mapbox' || t === 'venue' || t === 'saved' || t === 'approved-provider') {
    return t;
  }

  return null;
});

export type AddressMapbox = {
  type: 'mapbox';
  value: string;
  latitude: number;
  longitude: number;
};

export type AddressMapboxModel = ModelType<AddressMapbox>;

export type AddressMapboxPropertyRule = PropertyRuleSignature<AddressMapbox>;

export const ADDRESS_MAPBOX_DEFAULT = (): AddressMapbox => ({
  type: 'mapbox',
  value: '',
  latitude: 0,
  longitude: 0
});

export const addressMapboxFactory = createModelFactory<AddressMapboxModel>(
  ADDRESS_MAPBOX_DEFAULT(),
  {
    properties: [
      ['is-address-type', 'type', v => v === 'mapbox'],
      [
        'is-string',
        'value',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Address cannot be empty.');

          return Is.String.strict(v);
        }
      ],
      [
        'is-number',
        'latitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Latitude cannot be empty.');

          return Is.Number.strict(v);
        }
      ],
      [
        'is-number',
        'longitude',
        (v, _, isCommit) => {
          if (isCommit && Is.Empty(v)) throw Error('Longitude cannot be empty.');

          return Is.Number.strict(v);
        }
      ]
    ],
    model: []
  }
);
