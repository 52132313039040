import type { FC } from 'react';
import React from 'react';
import { Typography } from '@material-ui/core';
import { Button, Icon } from '@SRHealth/frontend-lib';

interface UnsupportedVehicleModalContentProps {
  unsupportedVehicleModelName: string;
  onDismiss: () => void;
}

const UnsupportedVehicleModalContent: FC<UnsupportedVehicleModalContentProps> = ({
  unsupportedVehicleModelName,
  onDismiss
}) => {
  return (
    <div
      className="flex flex-col items-center justify-between"
      style={{ minHeight: '215px' }}
    >
      <Icon type="Error" className="fill-current text-primary-red-md" />
      <Typography
        variant="body1"
        color="error"
        align="center"
        style={{ maxWidth: '480px' }}
      >
        {`This health plan does not support ${unsupportedVehicleModelName} rides. Please re-evaluate the
      member’s mobility assessment to receive a supported mobility type.`}
      </Typography>
      <Button alt label="Dismiss" onClick={() => onDismiss()} />
    </div>
  );
};

export default UnsupportedVehicleModalContent;
