import { token } from '~/utilities/auth.helper';

export type GetClaimSubmissionTypeResponse = {
  data: [];
  status: boolean;
  message: string;
  httpCode: number;
  errors: string[];
  timestamp: string;
  environment: string;
};

export type PostClaimSubmissionTypeResponse = {
  data: [];
  status: boolean;
  message: string;
  httpCode: number;
  errors: string[];
  timestamp: string;
  environment: string;
};

/**
 * Get Claim Submission Types
 * @param
 * @returns Promise<GetClaimSubmissionTypeResponse>
 */
export const getClaimSubmissionTypes =
  async (): Promise<GetClaimSubmissionTypeResponse> => {
    return fetch(
      `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/claim-submission-type`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token()}`,
          'Content-Type': 'application/json'
        }
      }
    ).then(res => res.json());
  };

/**
 * Get Claim Submission Types
 * @param claimSubmissionTypeName,
 * @returns Promise<PostClaimSubmissionTypeResponse>
 */
export const postHealthPlanClaimSubmissionType = async (
  claimSubmissionTypeId: string | null
): Promise<PostClaimSubmissionTypeResponse> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/claim-submission-type`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ claimSubmissionTypeId })
    }
  ).then(res => res.json());
};
