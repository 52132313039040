import { createAppAsyncThunk } from '~/Modules';
import { updateSavedAddresses } from '~/Modules/patients';
import { type MemberSavedAddress } from '~/services/savedAddress.service';
import * as SavedAddressService from '~/services/savedAddress.service';

interface ChangeDefaultAddressParams {
  savedLocation: MemberSavedAddress;
}

/** Change the default status of a saved location for a member. */
export const changeDefaultAddressThunk = createAppAsyncThunk(
  'savedAddress/changeDefaultAddress',
  async ({ savedLocation }: ChangeDefaultAddressParams, { getState, dispatch }) => {
    const { savedAddresses } = getState().patients;

    // Find the passed in saved location and set its is_default to the opposite of its current value.
    // Set all other saved locations' is_default to false.
    const newAddressList: MemberSavedAddress[] = savedAddresses.map(savedAddress => ({
      ...savedAddress,
      is_default: savedAddress.id === savedLocation.id ? !savedAddress.is_default : false
    }));

    const newSavedLocation = newAddressList.find(
      address => address.id === savedLocation.id
    );

    try {
      if (!newSavedLocation) {
        throw 'Saved location not found. Cannot update default saved location.';
      }

      dispatch(updateSavedAddresses(newAddressList));
      await SavedAddressService.updateSavedAddress(newSavedLocation);
    } catch (error) {
      // Revert to previous address list on failure to update
      dispatch(updateSavedAddresses(savedAddresses));
      throw error;
    }
  }
);
