import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import DayPicker from 'react-day-picker';
import _ from 'lodash-es';
import moment from 'moment';
import { getSafe } from '~/utilities/helperFunctions';
import { DAY_END } from '~/constants';

class Calendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = { startDate: moment().toDate() };
  }

  /**
   * set initial state
   * @return {undefined}
   */
  componentDidMount() {
    const { startDate } = this.props;
    this.setState({
      startDate: _.clone(startDate)
    });
  }

  /**
   * update component state if props change
   * @param {object} prevProps - props
   * @return {undefined}
   */
  componentDidUpdate(prevProps) {
    const { startDate } = this.props;
    //using getTime() as comparing just Date Objects which are identical fails to defined as false
    if (getSafe(() => prevProps.startDate.getTime() !== startDate.getTime())) {
      this.setState({
        startDate: _.clone(startDate)
      });
    }
  }

  /**
   * event handler for clicking submit button
   * both component state and redux app state are updated
   * @return {undefined}
   */
  submitModal = () => {
    this.props.submitModal({ startDate: this.state.startDate });
    this.props.closeCalendar();
  };

  /**
   * Event handler for clicking on date
   * @param {date} day - date object from DayPicker when you select a date on the calendar
   * @param {object} modifiers - object sent as a DayPicker parameter that determines css classes
   * @return {undefined} - nothing returned but state updated with ranges from DateUtils
   */
  handleDayClick = (day, modifiers) => {
    if (modifiers.disabled) {
      return;
    }

    this.setState({ startDate: moment(day).startOf('day').toDate() });
  };

  render() {
    const { disabledDays, fromMonth } = this.props;
    const { startDate } = this.state;
    const modifiers = { selectedDate: startDate };
    const calendarProps = {
      numberOfMonths: 1,
      selectedDays: startDate,
      modifiers,
      initialMonth: startDate
    };
    if (fromMonth !== '') {
      calendarProps.fromMonth = fromMonth;
    }
    // if (toMonth !== '') {
    //   calendarProps.toMonth = toMonth;
    // }
    if (!_.isEmpty(disabledDays)) {
      calendarProps.disabledDays = disabledDays;
    }

    return (
      <Modal
        isOpen={this.props.openCalendar}
        onRequestClose={this.submitModal}
        className={{
          base: 'newModalBaseClass newCalendar calendarSingle',
          afterOpen: 'modalBaseClassOpen',
          beforeClose: 'modalBaseClassClose'
        }}
        overlayClassName={{
          base: 'overlayBaseClass',
          afterOpen: 'overlayBaseClassOpen',
          beforeClose: 'overlayBaseClassClose'
        }}
        closeTimeoutMS={500}
      >
        <div className="left">
          <p className="yearText">{moment(this.state.startDate).format('YYYY')}</p>
          <p className="dateText">{moment(this.state.startDate).format('ddd, MMM D')}</p>
        </div>
        <div className="right">
          <DayPicker {...calendarProps} onDayClick={this.handleDayClick} />
          <div className="buttons">
            <button onClick={this.props.closeCalendar}>Cancel</button>
            <button onClick={this.submitModal}>OK</button>
          </div>
        </div>
      </Modal>
    );
  }
}

Calendar.propTypes = {
  closeCalendar: PropTypes.func,
  submitModal: PropTypes.func,
  openCalendar: PropTypes.bool,
  selectedDays: PropTypes.object,
  startDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  disabledDays: PropTypes.array,
  fromMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  toMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)])
};

Calendar.defaultProps = {
  closeCalendar: () => {},
  submitModal: () => {},
  openCalendar: false,
  selectedDays: {},
  beforeDate: {},
  startDate: '',
  disabledDays: [{ before: DAY_END }],
  fromMonth: new Date(),
  toMonth: ''
};

export default Calendar;
