/**
 * Utility library for ride compliance fields related functions
 */
import {type ComplianceRecordHidden, type FreeForm} from '~/types';

/**
 * For Compliance Fields of type hidden, map the input_name to the corresponding
 * Health Plan Custom Field and return the health plan custom field value.
 * 
 * This is used when we need to track a member's health plan custom field at the
 * ride level.
 * @param field 
 * @param healthPlanCustomFields 
 * @returns 
 */
export function mapHealthPlanCustomField(
  field: ComplianceRecordHidden,
  healthPlanCustomFields: FreeForm
) {
  const customField = Object.values(healthPlanCustomFields).find(
    ({label}) => label === field.input_name
  );

  return customField ? customField.value : undefined;
}