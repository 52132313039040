import { Button, TOKENS } from '@SRHealth/frontend-lib';
import React from 'react';
import { SavedLocationCard } from './SavedLocationCard';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import type { AddressSavedModel } from '~/models';
import { useSavedLocations } from './SavedLocations.context';

interface SavedLocationsDrawerBodyProps {
  savedLocations: MemberSavedAddress[];
  onAddressChange: (type: 'depart' | 'arrive', address: AddressSavedModel) => void;
}

export default function SavedLocationsDrawerBody({
  savedLocations,
  onAddressChange
}: SavedLocationsDrawerBodyProps) {
  const { setSavedLocationsDrawerMode } = useSavedLocations();

  return (
    <div className="flex flex-col h-full">
      <div className="bg-white shadow-b" style={{ zIndex: 1, paddingTop: '32px' }}>
        <div className="p-4 flex flex-col items-center">
          <Button
            iconLeft="AddCircleOutline"
            label="New Location"
            size="sm"
            disabled={savedLocations.length >= 9}
            onClick={() => setSavedLocationsDrawerMode('create')}
          />
          <p className="pb-[16px] text-xsm" style={{ paddingTop: '4px' }}>
            For correcting and saving a location with Google Maps
          </p>
        </div>
      </div>
      <div
        className={`flex-1 bg-${TOKENS.SURFACE.PRIMARY.XLT} p-[16px]`}
        style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}
      >
        <div className="flex flex-col gap-[8px]">
          {savedLocations.map(savedLocation => (
            <SavedLocationCard
              key={savedLocation.id}
              savedLocation={savedLocation}
              onAddressChange={onAddressChange}
            />
          ))}
        </div>
        <div
          className={`flex justify-center text-sm font-bold bg-${TOKENS.SURFACE.PRIMARY.XLT}`}
          style={{ padding: '24px 0 8px 0' }}
        >
          {savedLocations.length} of 9
        </div>
      </div>
    </div>
  );
}
