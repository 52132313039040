import React, {useState} from 'react';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

interface CharacterLimitedTextFieldProps {
  init: string;
  maxCharacters: number;
  onChangeCallback?: (value: string) => void;
  disabled: boolean;
}

const CharacterLimitedTextField: React.FC<CharacterLimitedTextFieldProps> = ({
  init,
  maxCharacters,
  onChangeCallback = () => {},
  disabled
}) => {
  const [inputText, setInputText] = useState('');

  const handleInputChange = e => {
    const newText = e.target.value;

    if (newText.length <= maxCharacters) {
      setInputText(newText);
      onChangeCallback(newText);
    }
  };

  return (
    <div>
      <div>
        <TextField
          variant="outlined"
          multiline
          minRows={4}
          value={inputText ? inputText : init ? init : undefined}
          onChange={handleInputChange}
          style={{width: '100%'}}
          disabled={disabled}
        />
      </div>
      <div>
        <Typography variant="caption">
          {inputText.length}/{maxCharacters} characters
        </Typography>
      </div>
    </div>
  );
};

export default CharacterLimitedTextField;
