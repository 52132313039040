import _ from 'lodash-es';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isEmpty } from '@SRHealth/frontend-lib';

type ErrorFields = string[];
type ErrorMessages = string[];

/**
 * Client side validation for ride booking times
 * @param {object} rideData The ride data to validate
 * @param {array} errFields An array to gather the names of invalid fields
 * @param {array} errMsg An array to hold the error message
 * @returns {boolean} True if valid, false otherwise
 */
export default function validateRideForm(
  rideData: BookingDataStore | RideData,
  errFields: ErrorFields = [],
  errMsg: ErrorMessages = []
) {
  if (!validApprovedProviders(rideData, errFields, errMsg)) {
    return false;
  }

  if (!validAddress(rideData, errFields, errMsg)) {
    return false;
  }

  return true;
}

/**
 * Validates the approved providers settings
 * @param {object} rideData The ride data to validate
 * @param {array} errFields An array to gather the names of invalid fields
 * @param {array} errMsg An array to hold the error message
 * @returns {boolean} True if valid, false otherwise
 */
export function validApprovedProviders(
  rideData: BookingDataStore | RideData,
  errFields: ErrorFields = [],
  errMsg: ErrorMessages = []
) {
  // Approved providers doesn't apply
  if (!rideData.isApprovedProviders) {
    return true;
  }

  if (!rideData?.approvedProvidersHardBlock && rideData.healthPlanId) {
    return true;
  }

  if (!validApprovedProviderAdditionalNotes(rideData, errFields, errMsg)) {
    return false;
  }

  const foundProviderId = [rideData.pickupProviderId, rideData.dropoffProviderId].find(
    Boolean
  );

  if (
    !foundProviderId &&
    !rideData.dropoffProviderNotFound &&
    !rideData.pickupProviderNotFound
  ) {
    if (rideData?.approvedProvidersHardBlock || !rideData?.healthPlanId) {
      errMsg[0] =
        'At least one of the two destinations must be from the approved provider list. ';
      return false;
    }
  }

  return true;
}

/**
 * Validates the approved providers additional notes settings
 * @param {object} rideData The ride data to validate
 * @param {array} errFields An array to gather the names of invalid fields
 * @param {array} errMsg An array to hold the error message
 * @returns {boolean} True if valid, false otherwise
 */
export function validApprovedProviderAdditionalNotes(
  rideData: BookingDataStore | RideData,
  errFields: ErrorFields = [],
  errMsg: ErrorMessages = []
) {
  // Approved Provider Additional Notes don't apply
  if (!rideData.dropoffProviderNotFound && !rideData.pickupProviderNotFound) {
    return true;
  }

  const requiredNoteFields = ['ProviderName', 'FacilityName', 'Address', 'ProviderPhone'];

  const approvedProviderErrors = {
    ProviderName: { required: 'Provider Name is a required field' },
    FacilityName: { required: 'Facility Name is a required field' },
    Address: { required: 'Address is a required field' },
    ProviderPhone: {
      required: 'Provider Phone Number is a required field.',
      invalid: 'Provider Phone Number is invalid'
    }
  };

  let isValid = true;

  // Pickup
  if (rideData.pickupProviderNotFound) {
    requiredNoteFields.forEach(field => {
      const fieldVal = rideData[`pickup${field}`];
      const isProviderPhoneNumber = fieldVal && field === 'ProviderPhone';

      if (typeof fieldVal !== 'string' || fieldVal.length <= 0) {
        errFields.push(`pickup${field}`);
        errMsg.push(`Pickup ${approvedProviderErrors[field]['required']}`);
        isValid = false;
      }
      // Check for invalid pickup phone number
      if (isProviderPhoneNumber && !isValidPhoneNumber(fieldVal, 'US')) {
        errFields.push(`pickup${field}`);
        errMsg.push(`Pickup ${approvedProviderErrors[field]['invalid']}`);
        isValid = false;
      }
    });
  }

  // Dropoff
  if (rideData.dropoffProviderNotFound) {
    requiredNoteFields.forEach(field => {
      const fieldVal = rideData[`dropoff${field}`];
      const isProviderPhoneNumber = fieldVal && field === 'ProviderPhone';

      if (typeof fieldVal !== 'string' || fieldVal.length <= 0) {
        errFields.push(`dropoff${field}`);
        errMsg.push(`Dropoff ${approvedProviderErrors[field]['required']}`);
        isValid = false;
      }

      // Check for invalid dropoff phone number
      if (isProviderPhoneNumber && !isValidPhoneNumber(fieldVal, 'US')) {
        errFields.push(`dropoff${field}`);
        errMsg.push(`Dropoff ${approvedProviderErrors[field]['invalid']}`);
        isValid = false;
      }
    });
  }

  return isValid;
}

/**
 * Validates the address fields
 * @param {object} rideData The ride data to validate
 * @param {array} errFields An array to gather the names of invalid fields
 * @param {array} errMsg An array to hold the error message
 * @returns {boolean} True if valid, false otherwise
 */
export function validAddress(
  rideData: BookingDataStore | RideData,
  errFields: ErrorFields = [],
  errMsg: ErrorMessages = []
) {
  let isValid = true;

  if (isEmpty(rideData.pickupAddress)) {
    errFields.push('pickupAddress');
    errMsg[0] = 'All fields must be complete ';
    isValid = false;
  }

  if (isEmpty(rideData.dropoffAddress)) {
    errFields.push('dropoffAddress');
    errMsg[0] = 'All fields must be complete ';
    isValid = false;
  }

  return isValid;
}
