import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import moment from 'moment';

const LiveChatSuggestion = props => {
  let confirmButton = null;
  let rejectButton = null;

  /**
   * Handle confirm button click
   * @return {undefined}
   */
  const handleConfirmRideClick = () => {
    // disable buttons
    if (confirmButton) {
      confirmButton.disabled = true;
    }
    if (rejectButton) {
      rejectButton.disabled = true;
    }

    props.handleConfirmRide(props.selectedChat.requestId);
  };

  /**
   * Handle reject button click
   * @return {undefined}
   */
  const handleRejectRideClick = () => {
    // disable buttons
    if (confirmButton) {
      confirmButton.disabled = true;
    }
    if (rejectButton) {
      rejectButton.disabled = true;
    }

    props.handleRejectRide(props.selectedChat.requestId);
  };

  /**
   * Get suggested time
   * @param  {String} [suggestedAppointmentTime=''] Suggested appt time (by NEMT)
   * @param  {String} [suggestedPickupTime='']      Suggested pickup time (by NEMT)
   * @return {Object}                               Display fields
   */
  const getSuggestedTime = (suggestedAppointmentTime = '', suggestedPickupTime = '') => {
    const suggestedAppt = moment(suggestedAppointmentTime, 'hh:mm');
    const suggestedPickup = moment(suggestedPickupTime, 'hh:mm');
    let suggestedTime = '';

    if (suggestedAppointmentTime !== '') {
      if (suggestedPickupTime !== '') {
        suggestedTime = `- Appointment: ${suggestedAppt.format('h:mm a')}\n`;
        suggestedTime += `- Pickup: ${suggestedPickup.format('h:mm a')}\n`;
      } else {
        suggestedTime = `- Appointment: ${suggestedAppt.format('h:mm a')}\n`;
      }
    } else if (suggestedPickupTime !== '') {
      suggestedTime = `- Pickup: ${suggestedPickup.format('h:mm a')}\n`;
    }

    return suggestedTime;
  };

  const suggestedAppointmentTime = _.get(
    props,
    'selectedChat.rideDetails.suggestedAppointmentTime',
    ''
  );
  const suggestedPickupTime = _.get(
    props,
    'selectedChat.rideDetails.suggestedPickupTime',
    ''
  );
  const suggestedTime = getSuggestedTime(suggestedAppointmentTime, suggestedPickupTime);
  const sender = _.get(props, `selectedChat.users.${props.message.senderId}`, {});
  const sentAt = moment(props.message.sentAt).format('h:mm A');

  return (
    <div className="liveChatMessage received suggestion">
      <div className="liveChatMessageText">
        New ride times suggested:
        <br />
        {suggestedTime}
        <button
          ref={el => (confirmButton = el)}
          onClick={handleConfirmRideClick}
          disabled={props.selectedChat.confirmed}
        >
          Confirm
        </button>
        <button
          ref={el => (rejectButton = el)}
          onClick={handleRejectRideClick}
          disabled={props.selectedChat.requestStatus !== 'active'}
        >
          Reject
        </button>
      </div>
      <sub>
        <span>
          {sender.firstName} {sender.lastName} - {sentAt}
        </span>
      </sub>
    </div>
  );
};

LiveChatSuggestion.propTypes = {
  selectedChat: PropTypes.object,
  message: PropTypes.object,
  handleConfirmRide: PropTypes.func,
  handleRejectRide: PropTypes.func
};

LiveChatSuggestion.defaultProps = {
  selectedChat: {},
  message: {},
  handleConfirmRide: () => {},
  handleRejectRide: () => {}
};

export default LiveChatSuggestion;
