import { Drawer, Link } from '@SRHealth/frontend-lib';
import React from 'react';
import SavedLocationsDrawerBody from './SavedLocationsDrawerBody';
import SavedLocationCreationDrawerBody from './SavedLocationCreationDrawerBody';
import type { AddressSavedModel } from '~/models';
import type { MemberSavedAddress } from '~/services/savedAddress.service';
import { useSavedLocations } from './SavedLocations.context';

interface SavedLocationsDrawerProps {
  savedLocations: MemberSavedAddress[];
  onAddressChange: (type: 'depart' | 'arrive', address: AddressSavedModel) => void;
}

const SavedLocationsDrawer = ({
  savedLocations,
  onAddressChange
}: SavedLocationsDrawerProps) => {
  const { savedLocationsDrawerMode, setSavedLocationsDrawerMode } = useSavedLocations();

  return (
    <Drawer
      width={savedLocationsDrawerMode === 'create' ? '1200px' : '460px'}
      isOpen={savedLocationsDrawerMode !== 'closed'}
      onClose={() => setSavedLocationsDrawerMode('closed')}
      fixed
      persist
    >
      <Drawer.Header label="Saved Locations" icon="Bookmark">
        <Link
          alt
          label="Close"
          iconRight="Close"
          onClick={() => setSavedLocationsDrawerMode('closed')}
          preventDefault
        />
      </Drawer.Header>
      {savedLocationsDrawerMode === 'create' ? (
        <SavedLocationCreationDrawerBody savedLocations={savedLocations} />
      ) : (
        <SavedLocationsDrawerBody
          savedLocations={savedLocations}
          onAddressChange={onAddressChange}
        />
      )}
    </Drawer>
  );
};

export default SavedLocationsDrawer;
