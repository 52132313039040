/**
 * Convert a number of cents to a dollar amount
 * 
 * @param {number} cents - The number of cents to convert
 * @return {string} The dollar amount
 */
export function convertCentsToDollars(cents: number) {
  
  if(Math.floor(cents) !== cents) throw new Error('Cents must be a whole number');

  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  }).format(cents / 100);
} 

/**
 * Convert a number of dollars into cents
 * 
 * @param {number} dollars The dollar amount to convert
 * @returns {number} The number of cents
 */
export function convertDollarsToCents(dollars: number) {
  
  return Math.round(dollars * 100);
}
