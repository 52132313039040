import { createAppAsyncThunk } from '~/Modules';
import { getDuplicateRides } from '~/services/rideBooking.service';
import { getTimeRestrictionsThunk } from './getTimeRestrictions.thunk';

export const completeDateThunk = createAppAsyncThunk(
  'rideBooking/completeDate',
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  async (checkForDuplicates: boolean = true, { dispatch, getState }) => {
    const dateModel = getState().rideBooking.date;
    const passengerId = getState().rideBooking.passengerInfo.passengerId;
    const rides = getState().rideBooking.rides;

    if (!passengerId || !dateModel.date) {
      throw Error('passengerId and date are required to complete the Date section.');
    }

    if (dateModel.date && dateModel.propertyIsDirty('date') && checkForDuplicates) {
      const duplicateRides = (await getDuplicateRides(dateModel.date, passengerId))?.data;

      if (duplicateRides.length > 0) return duplicateRides;
    }

    const modelWasDirty = dateModel.propertyIsDirty('date');

    return (
      getState()
        .rideBooking.date.commit()
        .then(() => dispatch(getTimeRestrictionsThunk()))
        // Reset the ride times after the date has been updated
        .then(() => {
          if (modelWasDirty) rides.forEach(ride => (ride.time = undefined));
        })
    );
  }
);
