
/**
 * Simple utility to extract a zipcode from a Google Maps Geocoder response
 * @param {google.maps.GeocoderResult} result 
 * @returns {string}
 */
export function getZipCodeFromGeocoderResult(
  result: google.maps.GeocoderResult
) {
  const zipCodeComponent = result.address_components.find(component =>
    component.types.includes('postal_code')
  );

  if (!zipCodeComponent) return '';

  return zipCodeComponent.long_name ?? '';
}
