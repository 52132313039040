import React from 'react';
import type { Address, AddressApprovedProvider, AddressProviderNotFound } from '~/types';
import { LAT_LNG_DISPLAY_PRECISION } from '~/constants';
import {
  isAddressApprovedProvider,
  isAddressProviderNotFound,
  isAddressSavedAddress
} from '~/utilities/guards';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import './AddressDisplay.css';

const isEdited = (props: Address) =>
  props?.originalAddress && props.address !== props.originalAddress;

const EditLine = (props: Address) => (isEdited(props) ? <i>(Edited)</i> : null);

const AddressDisplay = (props: Address) => {
  if (isAddressApprovedProvider(props) && props.providerName) {
    return <AddressDisplayApprovedProvider {...props} />;
  } else if (isAddressProviderNotFound(props) && props.providerName) {
    return <AddressDisplayProviderNotFound {...props} />;
  } else if (isAddressSavedAddress(props) && props.memberSavedAddressId) {
    return <AddressDisplaySavedAddress {...props} />;
  }

  return (
    <>
      {props.address}
      <EditLine {...props} />
    </>
  );
};

const AddressDisplayApprovedProvider = (props: AddressApprovedProvider) => {
  const { address, providerName, providerPhone, providerNpi } = props;

  return (
    <>
      <AddressDisplaySavedAddress {...props} />
      <div>{providerName}</div>
      <div>{address}</div>
      {providerPhone && <div>{formatPhoneNumber(providerPhone)}</div>}
      <div>NPI: {providerNpi}</div>

      <EditLine {...props} />
    </>
  );
};

const AddressDisplayProviderNotFound = (props: AddressProviderNotFound) => {
  const { address, providerName, providerPhone, facilityName, additionalNotes } = props;

  return (
    <>
      <AddressDisplaySavedAddress {...props} />
      <div className="address-type">Provider Not Found Info:</div>
      <div>{providerName}</div>
      <div>{facilityName}</div>
      <div>{address}</div>
      {providerPhone && <div>{formatPhoneNumber(providerPhone)}</div>}
      <div>NPI: 0</div>
      {additionalNotes ? <div>Additional Notes: {additionalNotes}</div> : null}
      <EditLine {...props} />
    </>
  );
};

const AddressDisplaySavedAddress = (props: Address) => {
  if (!isAddressSavedAddress(props)) return null;

  const { address, addressName, latitude, longitude } = props;

  const _latitude = parseFloat(`${latitude}`).toFixed(LAT_LNG_DISPLAY_PRECISION);
  const _longitude = parseFloat(`${longitude}`).toFixed(LAT_LNG_DISPLAY_PRECISION);

  return (
    <>
      <div className="address-type">Saved Address:</div>
      <div>{addressName}</div>
      <div>{address}</div>
      <div>
        {_latitude}/{_longitude}
      </div>
      <EditLine {...props} />
    </>
  );
};

export default AddressDisplay;
