import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {getClassName} from '~/utilities/helperFunctions';
import ReactLoading from 'react-loading';
import SvgBackArrow from '~/Shared/Components/Svgs/SvgBackArrow';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';

class PlanInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      showLoader: false,
      planName: '',
      planId: '',
      isEnabled: false,
      memberPortalEnabled: false,
      dsnpEnabled: false
    };
  }

  getDefaultSelectedClaimSubmissionType = () =>
    this.props.selectedPlan?.claim_submission_types?.find(c => c.selected)
      ?.submission_type_id || null;

  componentDidMount() {
    const selectedPlan = this.props.selectedPlan;

    this.setState({
      planName: selectedPlan.planName,
      planId: selectedPlan.planId,
      isEnabled: selectedPlan.isEnabled,
      memberPortalEnabled: selectedPlan.memberPortalEnabled,
      dsnpEnabled: selectedPlan.d_snp,
      wrapEnabled: selectedPlan.supports_wrap,
      dsnpBenefitsProgram: selectedPlan?.benefits_program ?? null,
      treatmentRestrictionEnabled: selectedPlan.treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled: selectedPlan.treatmentsMemberPortalEnabled,
      locationRestrictionEnabled: selectedPlan.locationRestrictionEnabled,
      approvedProvidersEnabled: selectedPlan.approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled: selectedPlan.approvedProvidersHardBlockEnabled,
      waiveCancellations: !!selectedPlan?.waive_cancellations,
      selectedClaimSubmissionType: this.getDefaultSelectedClaimSubmissionType(),
      claimSubmissionTypes: selectedPlan.claim_submission_types
    });
  }

  componentDidUpdate(prevProps) {
    const selectedPlan = this.props.selectedPlan;
    const showLoader = this.state.showLoader;
    const prevSelectedPlan = prevProps.selectedPlan || {};

    if (selectedPlan.updatedAt !== prevSelectedPlan.updatedAt && showLoader) {
      this.setState({showLoader: false});
    }
  }

  handleEdit() {
    this.setState({showEdit: true});
  }

  handleCancel() {
    const selectedPlan = this.props.selectedPlan;

    this.setState({
      showEdit: false,
      planName: selectedPlan.planName,
      planId: selectedPlan.planId,
      isEnabled: selectedPlan.isEnabled,
      selectedClaimSubmissionType: this.getDefaultSelectedClaimSubmissionType()
    });
  }

  handleSave() {
    const {selectedPlan, updatePlan} = this.props;

    const {
      planName,
      planId,
      isEnabled,
      locationRestrictionEnabled,
      treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled,
      approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled,
      waiveCancellations,
      memberPortalEnabled,
      dsnpEnabled,
      wrapEnabled,
      dsnpBenefitsProgram,
      selectedClaimSubmissionType
    } = this.state;

    this.setState(
      {
        showEdit: false,
        showLoader: true
      },
      () => {
        updatePlan({
          id: selectedPlan.id,
          planName,
          planId,
          isEnabled,
          locationRestrictionEnabled,
          treatmentRestrictionEnabled,
          treatmentsMemberPortalEnabled,
          approvedProvidersEnabled,
          approvedProvidersHardBlockEnabled,
          waiveCancellations,
          memberPortal: memberPortalEnabled,
          dsnpEnabled,
          wrapEnabled,
          dsnpBenefitsProgram,
          claimSubmissionTypeId: selectedClaimSubmissionType
            ? selectedClaimSubmissionType
            : null
        });
      }
    );
  }

  render() {
    const {selectedPlan, showList, isSuperAdmin, healthPlan} = this.props;
    const {
      showEdit,
      showLoader,
      planName,
      planId,
      isEnabled,
      memberPortalEnabled,
      dsnpEnabled,
      wrapEnabled,
      dsnpBenefitsProgram,
      treatmentRestrictionEnabled,
      treatmentsMemberPortalEnabled,
      locationRestrictionEnabled,
      approvedProvidersEnabled,
      approvedProvidersHardBlockEnabled,
      waiveCancellations,
      selectedClaimSubmissionType,
      claimSubmissionTypes
    } = this.state;

    return (
      <div className="PlanInfo">
        <h2>
          {selectedPlan.planName}
          <span onClick={() => showList()}>
            <SvgBackArrow />
          </span>
        </h2>
        {showLoader ? (
          <ReactLoading type="spin" className="planLoading" />
        ) : (
          <div className="fields">
            {showEdit ? (
              <ul>
                <li className="toggleContainer">
                  <label>Status</label>
                  <div>
                    <span>{isEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: isEnabled
                      })}
                      onClick={() => this.setState({isEnabled: !isEnabled})}
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                </li>
                {healthPlan.member_portal ? (
                  <li className="toggleContainer">
                    <label>Member Portal</label>
                    <div>
                      <span>{memberPortalEnabled ? 'Enabled' : 'Disabled'}</span>
                      <div
                        className={getClassName({
                          toggle: true,
                          enabled: memberPortalEnabled
                        })}
                        onClick={() =>
                          this.setState({memberPortalEnabled: !memberPortalEnabled})
                        }
                      >
                        <div className="toggleSwitch"></div>
                      </div>
                    </div>
                  </li>
                ) : null}
                <li className="toggleContainer">
                  <label>D-SNP PROGRAM</label>
                  <div>
                    <span>{dsnpEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({toggle: true, enabled: dsnpEnabled})}
                      onClick={() => this.setState({dsnpEnabled: !dsnpEnabled})}
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                  {dsnpEnabled ? (
                    <div style={{display: 'flex'}}>
                      <div>
                        <label
                          className={`checkboxLabel ${
                            dsnpBenefitsProgram ? '' : 'disabled'
                          }`}
                          htmlFor="dsnpEnabledMedicare"
                        >
                          Medicare
                        </label>
                        <input
                          type="radio"
                          name="dsnpEnabledMedicare"
                          className="dsnpEnabledMedicare"
                          checked={dsnpBenefitsProgram === 'medicare'}
                          disabled={!dsnpEnabled}
                          onClick={() => this.setState({dsnpBenefitsProgram: 'medicare'})}
                        />
                      </div>
                      <div style={{paddingLeft: '25px'}}>
                        <label
                          className={`checkboxLabel ${
                            dsnpBenefitsProgram ? '' : 'disabled'
                          }`}
                          htmlFor="dsnpEnabledMedicaid"
                        >
                          Medicaid
                        </label>
                        <input
                          type="radio"
                          name="dsnpEnabledMedicaid"
                          className="dsnpEnabledMedicaid"
                          checked={dsnpBenefitsProgram === 'medicaid'}
                          disabled={!dsnpEnabled}
                          onClick={() => this.setState({dsnpBenefitsProgram: 'medicaid'})}
                        />
                      </div>
                    </div>
                  ) : null}
                </li>
                <li className="toggleContainer">
                  <label>Wrap Program</label>
                  <div>
                    <span>{wrapEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: wrapEnabled
                      })}
                      onClick={() => this.setState({wrapEnabled: !wrapEnabled})}
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                </li>
                <li className="toggleContainer">
                  <label>Treatments</label>
                  <div>
                    <span>{treatmentRestrictionEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: treatmentRestrictionEnabled
                      })}
                      onClick={() =>
                        this.setState({
                          treatmentRestrictionEnabled: !treatmentRestrictionEnabled
                        })
                      }
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                  {memberPortalEnabled ? (
                    <div>
                      <label
                        className={`checkboxLabel ${
                          treatmentRestrictionEnabled ? '' : 'disabled'
                        }`}
                        htmlFor="treatmentsMemberPortal"
                      >
                        Member Portal
                      </label>
                      <input
                        type="checkbox"
                        name="treatmentsMemberPortal"
                        className="approvedProvidersHardBlock"
                        checked={treatmentsMemberPortalEnabled}
                        disabled={!treatmentRestrictionEnabled}
                        onClick={() =>
                          this.setState({
                            treatmentsMemberPortalEnabled: !treatmentsMemberPortalEnabled
                          })
                        }
                      />
                    </div>
                  ) : null}
                </li>
                <li className="toggleContainer">
                  <label>Locations</label>
                  <div>
                    <span>{locationRestrictionEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: locationRestrictionEnabled
                      })}
                      onClick={() =>
                        this.setState({
                          locationRestrictionEnabled: !locationRestrictionEnabled
                        })
                      }
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                </li>
                <li className="toggleContainer">
                  <label>Approved Providers</label>
                  <div>
                    <span>{approvedProvidersEnabled ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: approvedProvidersEnabled
                      })}
                      onClick={() =>
                        this.setState({
                          approvedProvidersEnabled: !approvedProvidersEnabled
                        })
                      }
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                  <label
                    className={`checkboxLabel ${
                      approvedProvidersEnabled ? '' : 'disabled'
                    }`}
                    htmlFor="approvedProvidersHardBlock"
                  >
                    Hard Block
                  </label>
                  <input
                    type="checkbox"
                    name="approvedProvidersHardBlock"
                    className="checkboxInput"
                    checked={approvedProvidersHardBlockEnabled}
                    disabled={!approvedProvidersEnabled}
                    onClick={() =>
                      this.setState({
                        approvedProvidersHardBlockEnabled:
                          !approvedProvidersHardBlockEnabled
                      })
                    }
                  />
                </li>
                <li className="toggleContainer">
                  <label htmlFor="waiveCancellations">
                    Waive Benefit Deduction For Canceled Rides with Cost
                  </label>
                  <div>
                    <span>{waiveCancellations ? 'Enabled' : 'Disabled'}</span>
                    <div
                      className={getClassName({
                        toggle: true,
                        enabled: waiveCancellations
                      })}
                      onClick={() =>
                        this.setState({waiveCancellations: !waiveCancellations})
                      }
                    >
                      <div className="toggleSwitch"></div>
                    </div>
                  </div>
                </li>
                <li>
                  <label>Plan Name</label>
                  <div>
                    <input
                      type="text"
                      value={planName}
                      onChange={e => this.setState({planName: e.target.value})}
                    />
                  </div>
                </li>
                <li>
                  <label>Plan ID</label>
                  <div>
                    <input
                      type="text"
                      value={planId}
                      onChange={e => this.setState({planId: e.target.value})}
                    />
                  </div>
                </li>
                <li>
                  <label>Claims Submission Type</label>
                  <div>
                    <select
                      value={selectedClaimSubmissionType || 'None'}
                      onChange={e =>
                        this.setState({
                          selectedClaimSubmissionType:
                            e.target.value === 'None' ? null : +e.target.value
                        })
                      }
                    >
                      <option key={-1} value="None">
                        None
                      </option>
                      {claimSubmissionTypes?.map(c => (
                        <option key={c.submission_type_id} value={c.submission_type_id}>
                          {c.submission_type_name}
                        </option>
                      ))}
                    </select>
                  </div>
                </li>
              </ul>
            ) : (
              <ul>
                <li>
                  <label>Status</label>
                  <div>{selectedPlan.isEnabled ? 'Enabled' : 'Disabled'}</div>
                </li>
                {healthPlan.member_portal ? (
                  <li>
                    <label>Member Portal</label>
                    <div>{memberPortalEnabled ? 'Enabled' : 'Disabled'}</div>
                  </li>
                ) : null}
                <li>
                  <label>D-SNP Program</label>
                  <div>{dsnpEnabled ? 'Enabled' : 'Disabled'}</div>
                  {dsnpEnabled ? (
                    <div>
                      <label
                        className={`checkboxLabel ${
                          dsnpBenefitsProgram ? '' : 'disabled'
                        }`}
                        htmlFor="dsnpBenefitsProgram"
                      >
                        {dsnpBenefitsProgram}
                      </label>
                    </div>
                  ) : null}
                </li>
                <li>
                  <label>Wrap Program</label>
                  <div>{wrapEnabled ? 'Enabled' : 'Disabled'}</div>
                </li>
                <li>
                  <label>Treatments</label>
                  <div>
                    {selectedPlan.treatmentRestrictionEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                  {memberPortalEnabled ? (
                    <div>
                      <label
                        className={`checkboxLabel ${
                          treatmentRestrictionEnabled ? '' : 'disabled'
                        }`}
                        htmlFor="treatmentsMemberPortal"
                      >
                        Member Portal
                      </label>
                      <input
                        type="checkbox"
                        name="treatmentsMemberPortal"
                        className="checkboxInput"
                        checked={treatmentsMemberPortalEnabled}
                        disabled
                      />
                    </div>
                  ) : null}
                </li>
                <li>
                  <label>Locations</label>
                  <div>
                    {selectedPlan.locationRestrictionEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                </li>
                <li>
                  <label>Approved Providers</label>
                  <div>
                    {selectedPlan.approvedProvidersEnabled ? 'Enabled' : 'Disabled'}
                  </div>
                  <div>
                    <label
                      className={`checkboxLabel ${
                        approvedProvidersEnabled ? '' : 'disabled'
                      }`}
                      htmlFor="approvedProvidersHardBlock"
                    >
                      Hard Block
                    </label>
                    <input
                      type="checkbox"
                      name="approvedProvidersHardBlock"
                      className="checkboxInput"
                      checked={approvedProvidersHardBlockEnabled}
                      disabled
                    />
                  </div>
                </li>
                <li>
                  <label>Waive Benefit Deduction For Canceled Rides with Cost</label>
                  <div>{selectedPlan?.waive_cancellations ? 'Enabled' : 'Disabled'}</div>
                </li>
                <li>
                  <label>Plan Name</label>
                  <div>{selectedPlan.planName}</div>
                </li>
                <li>
                  <label>Plan ID</label>
                  <div>{selectedPlan.planId}</div>
                </li>
                <li>
                  <label>Claims Submission Type</label>
                  <div>
                    {selectedPlan?.claim_submission_types
                      ? selectedPlan.claim_submission_types.find(
                        c => c.submission_type_id === selectedClaimSubmissionType
                      )?.submission_type_name || ''
                      : ''}
                  </div>
                </li>
              </ul>
            )}
            {showEdit ? (
              <div className="actionButtons">
                <button className="cancel" onClick={() => this.handleCancel()}>
                  Cancel
                </button>
                <button className="save" onClick={() => this.handleSave()}>
                  Save
                </button>
              </div>
            ) : isSuperAdmin ? (
              <div className="actionButtons">
                <span className="edit" onClick={() => this.handleEdit()}>
                  <SvgEdit />
                </span>
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

PlanInfo.propTypes = {
  selectedPlan: PropTypes.object,
  showList: PropTypes.func,
  updatePlan: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  healthPlan: PropTypes.object
};

PlanInfo.defaultProps = {
  selectedPlan: {},
  showList: () => {},
  updatePlan: () => {},
  isSuperAdmin: false,
  healthPlan: {}
};

export default PlanInfo;
