import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { getClassName } from '~/utilities/helperFunctions';
import moment from 'moment-timezone';
import ScrollContainer from '~/Shared/Components/ScrollContainer';
import SvgMagnifyingGlass from '~/Shared/Components/Svgs/SvgMagnifyingGlass.js';
import MemberEligible from '../MemberEligible/MemberEligible';
import AddNewMember from '~/Shared/Components/AddNewMember/AddNewMember';
import { isMemberEligible } from '~/utilities/memberProfile';

class MembersList extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Handle form submit
   * @param {Object} e SyntheticEvent object
   * @returns {undefined}
   */
  handleSubmit(e) {
    e.preventDefault();
    this.props.searchAction({ refresh: true });
  }

  /**
   * Get last updated date as a moment object
   * @returns {Object} Moment date object
   */
  getLastUpdatedDate() {
    const { members, user } = this.props;
    if (members.meta && members.meta.lastUpdated) {
      const timezoneFormat = _.get(user, 'userData.timezone_format', '');
      return moment
        .utc(members.meta.lastUpdated, 'YYYY-MM-DD HH:mm:ss')
        .tz(timezoneFormat);
    } else {
      return null;
    }
  }

  render() {
    const {
      members,
      selectedId,
      setSelectedId,
      searchQuery,
      searchParams,
      setSearchQuery,
      showLoading
    } = this.props;
    const membersList = members.rows || [];

    let endOfList = this.props.endOfList;
    if (
      //Nothing to display,
      (membersList.length === 0 && !showLoading) ||
      //If we have an odd number of records loaded, assume we hit the end of our list
      membersList.length % 50 !== 0
    ) {
      endOfList = true; //Nothing to display,
    }

    const selectedSubPlanId = searchParams.get('subPlanId');

    return (
      <div className="MembersList">
        <div className="listWrapper">
          <form className="search" onSubmit={e => this.handleSubmit(e)}>
            <SvgMagnifyingGlass />
            <input
              type="text"
              placeholder="Search For Members"
              value={searchQuery}
              onChange={e => setSearchQuery(e.target.value)}
            />
            <AddNewMember page={'MEMBERS'} />
          </form>
          <ScrollContainer
            className="innerListWrapper"
            onBottom={this.props.incrementPage}
            endOfList={endOfList}
          >
            {!membersList?.length ? (
              <div className="noMembers">
                {(this.props?.members?.parsed ?? null) === null
                  ? 'Enter member\'s first name, last name, or a Member ID to begin search.'
                  : '0 Results'}
              </div>
            ) : (
              <ul>
                {membersList
                  .map((member, i) => {
                    const memberDOB = member?.dateOfBirth
                      ? moment(member.dateOfBirth).format('MM/DD/YYYY')
                      : '--';

                    const memberIsEligible = isMemberEligible(
                      member.isEligible,
                      member.eligibleEndDate
                    );

                    let isSelected = selectedId === member.id?.toString();
                    if (selectedSubPlanId) {
                      isSelected &&=
                        selectedSubPlanId === member?.healthSubPlan?.toString();
                    }

                    return (
                      <li
                        key={`${member.id}-${i}`}
                        className={getClassName({ selected: isSelected })}
                        onClick={() =>
                          setSelectedId(
                            member.id,
                            member?.healthSubPlan,
                            member?.external_sub_plan_id
                          )
                        }
                      >
                        <div className="memberName">{`${member.firstName} ${member.lastName}`}</div>
                        <div className="memberId">{member.medicalId}</div>
                        <div className="memberDOB">{memberDOB}</div>
                        <MemberPlanTag member={member} />
                        <MemberDSNPTag member={member} />
                        <MemberEligible eligible={memberIsEligible} />
                      </li>
                    );
                  })
                  .filter(Boolean)}
              </ul>
            )}
          </ScrollContainer>
        </div>
      </div>
    );
  }
}

function MemberPlanTag({ member }) {
  const planName = member?.healthPlanName ?? '--';
  const subPlanName = member?.healthSubPlanName ?? '--';
  const hospitalName = member?.hospitalName ?? '--';

  let planTag = hospitalName;
  let hoverTag = hospitalName;

  if (member?.healthSubPlanName) {
    planTag = `${planName} | ${subPlanName}`;
    hoverTag = `${planName}\n${subPlanName}`;
  }

  return (
    <div className="memberPlan" title={hoverTag}>
      {planTag}
    </div>
  );
}

function MemberDSNPTag({ member }) {
  if (!member.dsnp) return null;

  return <div className="memberDsnp">D-SNP {member.dsnpBenefitsProgram}</div>;
}

MembersList.propTypes = {
  user: PropTypes.object,
  members: PropTypes.object,
  selectedId: PropTypes.string,
  setSelectedId: PropTypes.func,
  searchQuery: PropTypes.string,
  searchParams: PropTypes.object,
  setSearchQuery: PropTypes.func,
  isSuperAdmin: PropTypes.bool,
  gotoMembersImport: PropTypes.func
};

MembersList.defaultProps = {
  user: {},
  members: {},
  selectedId: '',
  setSelectedId: () => {},
  searchQuery: '',
  searchParams: new URLSearchParams(),
  setSearchQuery: () => {},
  isSuperAdmin: false,
  gotoMembersImport: () => {}
};

export default MembersList;
