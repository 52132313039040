import type { ApiResponseExtended } from '~/types';

/**
 * Creates a default error message and allows for concatenating
 * additional strings.
 * @param serviceMsg
 * @returns
 */
export const getDefaultErrorMessage = (serviceMsg?: string) => {
  let msg = 'An error has occurred';

  if (serviceMsg) {
    msg += `while ${serviceMsg}.`;
  }

  return msg;
};

/**
 * Utility to parse SafeRide's generic api response
 * format for errors
 * @param res
 * @param serviceMsg
 * @returns
 */
export function parseSafeRideResponse<T>(
  res: ApiResponseExtended<T>,
  serviceMsg?: string
) {
  const isError = 'httpCode' in res && res.httpCode !== 200;

  let errorMsg;
  if ('errors' in res && Object.keys(res.errors).length > 0) {
    errorMsg =
      Object.entries(res.errors)[0][1]?.data ?? Object.entries(res.errors)[0][1]?.message;
  }

  if (isError && !errorMsg) {
    errorMsg = getDefaultErrorMessage(serviceMsg);
  }

  if (isError) {
    throw new Error(errorMsg);
  }

  return res;
}
