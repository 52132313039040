import React from 'react';
import PropTypes from 'prop-types';

class LiveChatTextInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textInput: ''
    };
  }

  /**
   * Handle input form submit
   * @param  {SyntheticEvent} e React event object
   * @return {undefined}
   */
  handleInputFormSubmit = e => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    this.props.sendMessage(this.props.selectedRequestId, this.state.textInput);
    this.setState({ textInput: '' });
  };

  /**
   * Handle text input value change
   * @param  {SyntheticEvent} e React event object
   * @return {undefined}
   */
  handleTextChange = e => {
    this.setState({ textInput: e.target.value });
  };

  render() {
    return (
      <form
        className="liveChatTextInput"
        onSubmit={this.handleInputFormSubmit}
      >
        <input
          type="text"
          placeholder="Type message..."
          autoFocus={true}
          value={this.state.textInput}
          onChange={this.handleTextChange}
        />
      </form>
    );
  }
}

LiveChatTextInput.propTypes = {
  selectedRequestId: PropTypes.string,
  sendMessage: PropTypes.func
};

LiveChatTextInput.defaultProps = {
  selectedRequestId: '',
  sendMessage: () => {}
};

export default LiveChatTextInput;
