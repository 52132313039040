import React from 'react';
import PropTypes from 'prop-types';

const SvgMapPinStart = props => {
  return (
    <svg className={props.className} width="12" height="12" viewBox="0 0 12 12">
      <circle cx="6" cy="6" r="4" fill="#FFF" fillRule="evenodd" stroke="#2962FF" strokeWidth="4"/>
    </svg>
  );
};

SvgMapPinStart.propTypes = {
  className: PropTypes.string
};

SvgMapPinStart.defaultProps = {
  className: ''
};

export default SvgMapPinStart;
