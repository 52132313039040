import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import SvgLiveChatIcon from '../../../Svgs/SvgLiveChatIcon';

const LiveChatLauncher = props => {
  /**
   * Get list of unread conversations (i.e. message threads) across all chat
   * requests
   * @return {Number} Total unread conversations
   */
  const getUnreadConversationsCount = () => {
    const userId = _.get(props, 'user.userData.id', 0);

    return Object.keys(props.chats).reduce((count, requestId) => {
      const messages = _.get(props, `chats.${requestId}.messages`, []);
      const requestStatus = _.get(props, `chats.${requestId}.requestStatus`, '');
      const unreadMessage = _.find(messages, message => {
        const reader = _.find(message.readers, reader => reader.id === userId);
        // if can't find the user as a reader AND the user wasn't the sender
        return !reader && message.senderId !== userId;
      });

      if (unreadMessage && requestStatus !== 'cancelled') {
        return count + 1;
      } else {
        return count;
      }
    }, 0);
  };

  const unreadConversationsCount = getUnreadConversationsCount();

  return (
    <div className="liveChatLauncher" onClick={props.handleWindowToggle}>
      <SvgLiveChatIcon className="liveChatIcon" />
      {unreadConversationsCount > 0 ? <sup>{unreadConversationsCount}</sup> : null}
    </div>
  );
};

LiveChatLauncher.propTypes = {
  user: PropTypes.object,
  chats: PropTypes.object,
  handleWindowToggle: PropTypes.func
};

LiveChatLauncher.defaultProps = {
  user: {},
  chats: {},
  handleWindowToggle: () => {}
};

export default LiveChatLauncher;
