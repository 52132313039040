import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import Button from '~/Shared/Components/Common/Button';
import { submitBenefitCreditStyles } from './SubmitBenefitCreditModal.styles';
import Select from 'react-dropdown-select';
import useModal from '~/hooks/useModal';
import { getRideAdjustment, submitRideAdjustment } from '~/services/billing.service';
import CharacterLimitedTextField from '~/Shared/Components/CharacterLimitedTextField/CharacterLimitedTextField';
import CloseIcon from '@material-ui/icons/Close';
import { convertCentsToDollars } from '~/utilities/currency';
import { useAppDispatch, useAppSelector } from '~/Modules';
import type { AdjustmentTypeOrParty } from '~/types';
import { updateExistingAdjustment } from '~/Modules/rideDetails';
import { statusNotifications } from '~/utilities/helperFunctions';

interface SubmitBenefitCreditModalProps {
  isOpen: boolean;
  toggleOpen: () => void;
}

const SubmitBenefitCreditModal: React.FC<SubmitBenefitCreditModalProps> = ({
  isOpen,
  toggleOpen = () => {}
}) => {
  const [, Modal] = useModal();
  const [selectedCreditReason, setSelectedCreditReason] = useState<
    AdjustmentTypeOrParty[]
  >([]);
  const [selectedRequestedBy, setSelectedRequestedBy] = useState<AdjustmentTypeOrParty[]>(
    []
  );
  const dispatch = useAppDispatch();
  const [creditDetails, setCreditDetails] = useState('');
  const [highlightEmptyFields, setHighlightEmptyFields] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const rideId = useAppSelector(state => state.rideDetails?.rideId);
  const billingData = useAppSelector(state => state.rideDetails?.ride.data.billingData);
  const creditReasons = useAppSelector(state => state.user.adjustmentSubTypes);
  const [errorMessage, setErrorMessage] = useState('');
  const requestingParties = useAppSelector(
    state => state.user.adjustmentRequestingParties
  );
  const existingAdjustment = billingData.openAdjustment;

  useEffect(() => {
    if (existingAdjustment) {
      const newRequestingParty = requestingParties.find(
        ({ id }) => id === existingAdjustment.requestingPartyId
      );
      const newCreditReason = creditReasons.find(
        ({ id }) => id === existingAdjustment.subTypeId
      );
      setSelectedRequestedBy(newRequestingParty ? [newRequestingParty] : []);
      setSelectedCreditReason(newCreditReason ? [newCreditReason] : []);

      setCreditDetails(existingAdjustment.notes);
      setErrorMessage(
        `This ride already has an open Benefit Credit Request, Adjustment ID# ${existingAdjustment.id}.
        You will be unable to create a new request until this request is approved or denied.`
      );
    }
  }, [existingAdjustment]);

  async function sendRideAdjustment() {
    const response = await submitRideAdjustment(
      rideId,
      selectedCreditReason[0].id,
      selectedRequestedBy[0].id,
      creditDetails,
      billingData.totalBenefitCost
    );

    if (response.status === true) {
      dispatch(updateExistingAdjustment(response.data));
      statusNotifications('Adjustment Submitted Successfully', 'success');
      toggleOpen();
    } else if (response.errors[0].code === 'too_many_open_adjustments') {
      getRideAdjustment(rideId).then(res =>
        dispatch(updateExistingAdjustment(res.data[0]))
      );
    } else {
      setErrorMessage(
        'An unknown error occurred.  If the issue persists, please contact your supervisor.'
      );
    }
  }

  function validateInput() {
    if (!selectedCreditReason.length || !selectedRequestedBy.length || !creditDetails) {
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    const check = validateInput();
    if (check) {
      setDisableSubmit(true);
      await sendRideAdjustment();
      setDisableSubmit(false);
    } else {
      Modal.set({
        isOpen: true,
        title: 'Form Incomplete',
        message: 'Please fill out all required fields.',
        confirmLabel: 'Continue',
        onConfirm: () => {
          setHighlightEmptyFields(true);
        }
      });
    }
  };

  const handleClose = () => {
    const ifEdited =
      selectedCreditReason.length || selectedRequestedBy.length || creditDetails;
    if (!existingAdjustment && ifEdited) {
      Modal.set({
        isOpen: true,
        title: 'Are you sure you want to leave?',
        message:
          'It looks like you\'re still working on this. You may lose the info you\'ve entered so far if you leave now.',
        confirmLabel: 'Leave',
        onConfirm: () => {
          toggleOpen();
        },
        cancelLabel: 'Keep Editing',
        onCancel: () => {}
      });
    } else {
      toggleOpen();
    }
  };

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="This is the submit benefit credit modal"
      style={submitBenefitCreditStyles}
    >
      <div className="SubmitBenefitCreditModal">
        <div className="close-button" onClick={handleClose}>
          <CloseIcon />
        </div>
        <div className="modal-header">
          <div>Request for Ride Benefit Credit</div>
        </div>
        <div className="modal-body">
          <h3>
            <span
              className={
                !selectedCreditReason.length && highlightEmptyFields ? 'required' : ''
              }
            >
              Credit Reason
            </span>
            {!existingAdjustment ? <span className="required">*</span> : ''}
          </h3>
          <Select
            disabled={existingAdjustment ? true : false}
            placeholder={
              existingAdjustment
                ? creditReasons?.find(
                  (element: AdjustmentTypeOrParty) =>
                    element.id === existingAdjustment.subTypeId
                )?.name
                : ''
            }
            values={selectedCreditReason}
            options={creditReasons}
            searchable={false}
            clearable={false}
            closeOnSelect={true}
            onChange={values => {
              setSelectedCreditReason(values[0] ? [values[0]] : []);
            }}
            valueField="id"
            labelField="name"
          />

          <h3>
            <span
              className={
                !selectedRequestedBy.length && highlightEmptyFields ? 'required' : ''
              }
            >
              Requested By
            </span>
            {!existingAdjustment ? <span className="required">*</span> : ''}
          </h3>
          <Select
            disabled={existingAdjustment ? true : false}
            placeholder={
              existingAdjustment
                ? requestingParties?.find(
                  (element: AdjustmentTypeOrParty) =>
                    element.id === existingAdjustment.requestingPartyId
                )?.name
                : ''
            }
            values={selectedRequestedBy}
            options={requestingParties}
            searchable={false}
            clearable={false}
            closeOnSelect={true}
            onChange={values => {
              setSelectedRequestedBy(values[0] ? [values[0]] : []);
            }}
            valueField="id"
            labelField="name"
          />

          <h3>
            <span className={!creditDetails && highlightEmptyFields ? 'required' : ''}>
              Credit Details
            </span>
            {!existingAdjustment ? <span className="required">*</span> : ''}
          </h3>
          <CharacterLimitedTextField
            init={existingAdjustment ? existingAdjustment.notes : ''}
            maxCharacters={250}
            onChangeCallback={newValue => setCreditDetails(newValue)}
            disabled={existingAdjustment ? true : false}
          ></CharacterLimitedTextField>
          <h3>
            Current Benefit Cost:{' '}
            {convertCentsToDollars(billingData.totalBenefitCost ?? 0)}
          </h3>
          <h3>
            Requested Credit Amount:{' '}
            <span className="required">
              -{convertCentsToDollars(billingData.totalBenefitCost ?? 0)}
            </span>
          </h3>
          <h3>Future Benefit Cost: $0</h3>
        </div>
        <div className="modal-buttons flex">
          {errorMessage ? <p>{errorMessage}</p> : ''}
          {!existingAdjustment && (
            <Button
              onClick={() => {
                handleSubmit();
              }}
              className="modal-button green-btn"
              label="Submit"
              disabled={disableSubmit}
            ></Button>
          )}
        </div>
      </div>
    </ReactModal>
  );
};

export default SubmitBenefitCreditModal;
