import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import Custom from './Custom';
import Program from './Program';
import { camelCase, capitalize } from '~/utilities/strings';
import { generateNavOptions } from './Uploads.helper';
import { Container } from '@material-ui/core';

const CHILD_COMPONENTS = {
  program: Program,
  custom: Custom
};

class Uploads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProgram: false,
      editCustom: false,
      showLoader: false
    };
  }

  componentDidMount() {
    if (!this.props.childForm) this.props.handleChildSelection('program');
  }

  componentDidUpdate() {
    if (!this.props.childForm) this.props.handleChildSelection('program');
  }

  /**
   * Edit handler returns a callback to update the state for
   * the specific tab
   * @param {string} edit - property for modifying state
   * @param {boolean} enable - T/F can edit
   * @return {Function}
   */
  handleEdit = (edit, enable) => {
    return (() => this.setState({ [edit]: enable })).bind(this);
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { childForm, selectedId } = this.props;

    const childTab = camelCase(childForm);
    const childEditKey = `edit${capitalize(childTab)}`;
    const ChildComponent = CHILD_COMPONENTS[childForm];

    return ChildComponent ? (
      <>
        {this.props.children}
        <Container className="parentTab uploads">
          <ChildComponent
            editable={this.state[childEditKey]}
            selectedId={selectedId}
            cancel={this.handleEdit(childEditKey, true)}
            edit={this.handleEdit(childEditKey, false)}
            permission={this.props.permission}
            setEditButton={this.props.setEditButton}
            setErrorMsg={this.props.setErrorMsg}
            setSelectionCallback={this.props.setSelectionCallback}
          />
        </Container>
      </>
    ) : null;
  }
}

Uploads.getNavOptions = selectedId => generateNavOptions(selectedId);

Uploads.propTypes = {
  user: PropTypes.object,
  childForm: PropTypes.string,
  selectedId: PropTypes.string,
  permission: PropTypes.string,
  toggleEnableRedirect: PropTypes.func,
  editCustom: PropTypes.bool,
  editProgram: PropTypes.bool,
  handleCancel: PropTypes.func,
  handleEdit: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

Uploads.defaultProps = {
  user: {},
  childForm: '',
  selectedId: 0,
  permission: 'view',
  toggleEnableRedirect: () => {},
  editCustom: false,
  editProgram: false,
  handleCancel: () => {},
  handleEdit: () => {},
  children: null,
  setEditButton: () => {},
  setErrorMsg: () => {}
};

export default Uploads;
