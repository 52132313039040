import { Icon, TOKENS, type IconType } from '@SRHealth/frontend-lib';
import React from 'react';

export type RidesSummarySectionHeaderProps = {
  label: string;
  icon: IconType;
};

export default function RidesSummarySectionHeader({
  label,
  icon
}: RidesSummarySectionHeaderProps) {
  return (
    <div
      className={
        'reset-div flex items-center justify-center py-[8px] ' +
        `gap-[4px] border-b border-${TOKENS.BORDER.NEUTRAL_MD}`
      }
    >
      <Icon type={icon} className="w-[24px] h-[24px] fill-current" />
      <h3 className="font-bold">{label}</h3>
    </div>
  );
}
