import React from 'react';

const styles = {
  title: {
    color: 'rgb(254, 140, 0)',
    fontSize: '14px',
    textTransform: 'uppercase'
  },
  container: {
    padding: '10px 20px',
    borderTop: '1px solid #e0e0e0'
  },
  content: {
    padding: '6px 0px 10px 0',
    fontSize: '14px'
  }
};

const AdditionalPassengersDetails = ({
  passengers = [],
  vehicleTypes = {}
}) => {
  if (!Array.isArray(passengers)) return null;
  if (passengers.length < 1) return null;

  return (
    <div className="AdditionalPassengerDetails" style={styles.container}>
      <h4 style={styles.title}>Additional Passengers</h4>
      <div>
        {passengers.map(
          (
            {
              ageGroup,
              lastName,
              uniqueId,
              firstName,
              isAttendant,
              mobilityType,
              subMobilityType
            },
            i
          ) => {
            let subType = {};
            if (subMobilityType !== null && vehicleTypes[mobilityType]) {
              
              subType =
                vehicleTypes[mobilityType].vehicle_sub_types.find(
                  ({ id }) => id === subMobilityType
                ) || {};
            }
            return (
              <div style={styles.content} key={uniqueId}>
                <div>
                  <span
                    className="bold"
                    style={{ fontFamily: 'avenir', fontSize: '16px' }}
                  >
                    Passenger {i + 1}
                  </span>{' '}
                  ({uniqueId})
                </div>
                <div>
                  <span style={{ textTransform: 'capitalize' }}>
                    {ageGroup}
                  </span>{' '}
                  {isAttendant ? '(Attendant)' : ''}
                </div>
                <div>
                  {firstName} {lastName}
                </div>
                <div>
                  {vehicleTypes[mobilityType] ? (
                    <>
                      {vehicleTypes[mobilityType].modelName}{' '}
                      {subType.name ? ` - ${subType.name}` : ''}
                    </>
                  ) : null}
                </div>
              </div>
            );
          }
        )}
      </div>
    </div>
  );
};

export default AdditionalPassengersDetails;
