import type BE from '~/be.d.ts';

/**
 *
 */
export function paidByOptions(options: { value: unknown }[] = []) {
  return options.map(({ value }) => ({ value, label: value }));
}

/**
 *
 */
export function complianceOptions(options: { option_value: unknown }[]) {
  return options.map(({ option_value: value }) => ({ value, label: value }));
}

/**
 *
 */
export function hospitalOptions(options: { id: number; hospitalName: string }[] = []) {
  return options.map(({ id, hospitalName }) => ({
    value: id,
    label: hospitalName
  }));
}

/**
 *
 */
export function mobilityTypeOptions(
  types: Record<string, { id: number; modelName: string }>[] = []
) {
  return Object.entries(types).map(([id, type]) => ({
    value: Number(types[id].id),
    label: type.modelName
  }));
}

/**
 *
 */
export function subMobilityTypeOptions(vehicleTypes: BE.VehicleRecord[], mobilityId) {
  if (!mobilityId) return [];

  const { vehicle_sub_types } = Object.values(vehicleTypes).filter(type => {
    return type.id === mobilityId;
  })[0];

  return vehicle_sub_types.map(({ name, id }) => ({
    value: id,
    label: name
  }));
}

/**
 *
 */
export function reimbursementMethodOptions(
  modes: Record<string, { value: unknown }>[] = []
) {
  return modes.map(({ value }) => ({ value, label: value }));
}

/**
 *
 */
export function numberOfDaysOrNightsOptions() {
  return Array.from({ length: 30 }, (_, i) => ({ value: i + 1, label: i + 1 }));
}
