import React from 'react';
import Button from '../Button';
import ReactModal from 'react-modal';
import { batch, useDispatch } from 'react-redux';
import {
  setExpenseModal,
  setExpenseSlideout,
  resetExpenseReport
} from '~/Modules/memberExpenses/actions';

// required React Modal style object
const style = {
  overlay: {
    zIndex: 150
  },
  content: {
    zIndex: 100,
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    padding: 'unset',
    maxWidth: '100%',
    background: '#fff',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

/**
 * @name ConfirmModal
 * @param {Object} modal Object with all the modal properties.
 *
 */
const ConfirmModal = ({ modal = {} }) => {
  const { type } = modal;
  if (type !== 'confirmReport') return null;

  const dispatch = useDispatch();

  const closeModal = () =>
    dispatch(
      setExpenseModal({
        type: null,
        show: false,
        mode: null,
        index: undefined
      })
    );

  const ModalButtons = () => {
    return (
      <div className="modal-buttons flex">
        <Button
          onClick={() => {
            closeModal();
            batch(() => {
              dispatch(resetExpenseReport());
              dispatch(
                setExpenseSlideout({ show: false, type: null, id: null })
              );
            });
          }}
          className="red-btn"
        >
          Yes
        </Button>
        <div className="spacer-8"></div>
        <Button
          disabled={false}
          onClick={() => {
            closeModal();
          }}
          className="grey-btn"
        >
          No
        </Button>
      </div>
    );
  };

  const Divider = () => <div className="divider"></div>;

  const modalProps = {
    style: style,
    isOpen: true,
    ariaHideApp: false,
    overlayClassName: 'infoOverlay',
    shouldCloseOnOverlayClick: true
  };

  return (
    <ReactModal {...modalProps}>
      <div className="ConfirmModal">
        <div className="modal-header">
          <div>Warning</div>
        </div>
        <Divider />
        <div className="modal-body">
          <div>
            Are you sure you want to cancel? All your updates will be discarded.
          </div>
        </div>
        <Divider />
        <ModalButtons />
      </div>
    </ReactModal>
  );
};

export default ConfirmModal;
