import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import TextAreaField from '~/Shared/Components/TextAreaField/TextAreaField';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { putMemberProfile, clearData } from '~/Modules/memberProfile/actions';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';

class Notes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editNotes: false,
      isDirty: false
    };
  }

  componentDidMount() {
    const state = this.initialize();
    this.setState(state, () => {
      if (Object.keys(this.props.notes).length) {
        this.props.setEditButton(
          <NotesEditButton
            permission={this.props.permission}
            editable={state.editNotes}
            handleEdit={this.handleEdit}
            handleCancel={this.handleCancel}
            handleSave={this.handleSave}
          />
        );
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { permission, notes } = this.props;

    const state = {};

    if (!_.isEqual(prevProps.notes, this.props.notes) && _.isEmpty(prevProps.notes)) {
      Object.assign(state, this.initialize, { showLoader: false });
    }

    if (!_.isEmpty(this.props.success) && _.isEmpty(prevProps.success)) {
      state.success =
        this.props.success.formType === 'notes' ? this.props.success.message : '';
      state.showLoader = false;

      this.props.toggleEnableRedirect(true);
    }

    if (!_.isEmpty(this.props.error) && _.isEmpty(prevProps.error)) {
      state.error = this.props.error;
      state.showLoader = false;

      // if it's an array then there are field validation
      if (Array.isArray(this.props.error)) {
        for (let i = 0; i < this.props.error.length; i++) {
          const errorObj = this.props.error[i];
          const key = Object.keys(errorObj)[0];
          state[`${key}Error`] = true;
          state[`${key}ErrorText`] = errorObj[key];
        }
      }
    }

    if (
      Object.keys(notes).length &&
      (permission !== prevProps.permission ||
        ('editable' in state && state.editable !== this.state.editable))
    ) {
      this.props.setEditButton(
        <NotesEditButton
          permission={permission}
          editable={state.editNotes}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (Object.keys(state).length) {
      this.setState(state);
    }
  }

  componentWillUnmount() {
    this.handleCancel();
  }

  updateEditButton = (state = this.state) => {
    this.props.setEditButton(
      <NotesEditButton
        permission={this.props.permission}
        editable={state.editNotes}
        handleEdit={this.handleEdit}
        handleCancel={this.handleCancel}
        handleSave={this.handleSave}
      />
    );
  };

  /**
   * Initializes an object to be used as a component state.
   * @returns {Object} components new state
   * @memberof Notes
   */
  initialize() {
    return Object.assign(_.cloneDeep(this.props.notes), {
      success: '',
      error: '',
      showLoader: false,
      editNotes: false,
      isDirty: false
    });
  }

  /**
   * Event handler for clicking the cancel link on the form - resets form to redux state
   * @returns {void}
   * @memberof Notes
   */
  handleCancel = () => {
    const state = { ...this.initialize(), showLoader: false };

    this.setState(state, () => {
      this.props.toggleEnableRedirect(true);
    });
  };

  /**
   * Event handler for clicking the edit link on the form
   * @returns {void}
   * @memberof Notes
   */
  handleEdit = () => {
    this.setState({ editNotes: true, success: '' }, () => {
      this.props.toggleEnableRedirect(false);
    });
  };

  /**
   * Handles changing of text in form. Marks form as dirty and changes component stats
   * @param {event} e The typing event
   * @param {string} id The identifier for the form element that is changing
   * @returns {void}
   * @memberof Notes
   */
  handleText = (e, id) => {
    this.setState({
      [id]: { value: e.target.value },
      isDirty: true
    });
  };

  /**
   * Validates then gathers all forms values and dispatches action to save to api
   * @returns {void}
   * @memberof Notes
   */
  handleSave = () => {
    const memberProfile = {};

    for (const key in this.state) {
      const stateProp = this.state[key];

      if (typeof stateProp === 'object' && 'value' in stateProp) {
        memberProfile[key] = stateProp.value;
      }
    }

    const newState = {
      success: '',
      error: '',
      showLoader: true,
      isDirty: false,
      editNotes: false
    };

    this.setState(newState, () => {
      this.props.clearData();
      this.props.putMemberProfile({
        memberProfile,
        passengerId: this.props.selectedId,
        formType: 'notes'
      });

      if (this.state.error !== newState.error) {
        this.props.setErrorMsg(newState.error);
      }
    });
  };

  /**
   * render Notes form
   * @return {jsx} returns jsx.
   */
  render() {
    const notes = this.props.notes;

    return (
      <>
        {this.props.children}
        <Container className="parentTab notes">
          <div className="childTab notes">
            {!Object.keys(notes).length ? (
              <div>There are no notes</div>
            ) : (
              Object.keys(notes).map(slug => {
                return (
                  <div className="memberRow" key={slug}>
                    <TextAreaField
                      customClassName="fullColumn"
                      placeholder=""
                      fieldName={slug}
                      label={notes[slug].label}
                      showLabel={true}
                      error={_.get(this.state, `${slug}Error`, false)}
                      errorText={_.get(this.state, `${slug}ErrorText`, '')}
                      disabled={!_.get(this.state, 'editNotes', false)}
                      value={_.get(this.state, `${slug}.value`, '')}
                      inputId={slug}
                      onChangeCallback={e => this.handleText(e, slug)}
                    />
                  </div>
                );
              })
            )}
          </div>

          {this.state.showLoader && (
            <LoadingModal label="Saving Member Notes..." isOpen={true} />
          )}
        </Container>
      </>
    );
  }
}

const NotesEditButton = ({ permission, handleEdit, handleSave, handleCancel }) => {
  const [isEditable, setIsEditable] = useState(false);

  const _handleEdit = e => {
    setIsEditable(true);
    return handleEdit(e);
  };

  const _handleSave = e => {
    setIsEditable(false);
    return handleSave(e);
  };

  const _handleCancel = e => {
    if (handleCancel(e) !== false) {
      return setIsEditable(false);
    }
  };

  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {isEditable ? (
            <>
              <a onClick={_handleCancel}>Cancel</a>
              <a onClick={_handleSave}>Save</a>
            </>
          ) : (
            <a onClick={_handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

Notes.getNavOptions = () => [];

Notes.propTypes = {
  user: PropTypes.object,
  notes: PropTypes.object,
  permission: PropTypes.string,
  selectedId: PropTypes.string,
  putMemberProfile: PropTypes.func,
  clearData: PropTypes.func,
  error: PropTypes.any,
  success: PropTypes.any,
  toggleEnableRedirect: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

Notes.defaultProps = {
  user: {},
  notes: {},
  permission: 'view',
  selectedId: null,
  error: '',
  success: '',
  children: null,
  putMemberProfile: () => {},
  clearData: () => {},
  toggleEnableRedirect: () => {},
  setEditButton: () => {},
  setErrorMsg: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  notes: _.get(state, 'memberProfile.formData.notes', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Notes);
