import { Is, createModelFactory, type ModelType } from '@SRHealth/frontend-lib';
import type { PropertyRuleSignature } from '~/models';
import {
  isDateRestrictions,
  type DateRestrictions
} from '~/Modules/rideBooking/RideBooking.types';

export type DateProps = {
  /** The date that the passenger is booking for. */
  date: IsoDate | null;
  dateRestrictions: DateRestrictions | null;
};

export type DateModel = ModelType<DateProps>;

export const DATE_DEFAULT = (props: Partial<DateProps> = {}): DateProps => ({
  date: null,
  dateRestrictions: null,
  ...props
});

export const dateFactory = createModelFactory<DateModel>(DATE_DEFAULT(), {
  properties: [
    ['is-string', 'date', (v, _m, isCommit) => isCommit && Is.String.strict(v)],
    ['is-date-restrictions', 'dateRestrictions', isDateRestrictions.strict]
  ],
  model: []
});

export type DatePropertyRule = PropertyRuleSignature<DateProps>;
