import React from 'react';
import _ from 'lodash-es';
import { NOOP } from '~/utilities/helperFunctions';

class GoogleMap extends React.Component {
  constructor() {
    super();
    this.map = null;
    this.directionsRenderer = null;
    this.directionsService = null;
  }

  componentDidMount() {
    this.drawMap();
    this.directionsRenderer = new window.google.maps.DirectionsRenderer();
    this.directionsService = new window.google.maps.DirectionsService();
    this.directionsRenderer.setMap(this.map);
    if (this.props.confirmationCardData.length > 0) {
      const mapData = this.addGoogleServiceSDKFields(
        this.props.confirmationCardData[this.props.transit.selectedLeg].routes,
        this.props.transit.legs[this.props.transit.selectedLeg]
      );
      this.directionsRenderer.setDirections(mapData);
    }
  }

  componentDidUpdate(prevProps, _prevState) {
    if (
      this.props.confirmationCardData.length > 0 &&
      (!_.isEqual(prevProps.confirmationCardData, this.props.confirmationCardData) ||
        !_.isEqual(prevProps.transit, this.props.transit))
    ) {
      const mapData = this.addGoogleServiceSDKFields(
        this.props.confirmationCardData[this.props.transit.selectedLeg].routes,
        this.props.transit.legs[this.props.transit.selectedLeg]
      );
      this.directionsRenderer.setDirections(mapData);
      //this.addGoogleServiceSDKFields(this.props.confirmationCardData[0].routes)
    }
    // this.drawMap();
  }

  // Google can not simply display converted JSON data. We have to comb through the data and bring it back to Google Maps Library compliant object types
  addGoogleServiceSDKFields = (inServerResponse = {}, selectedRoute) => {
    const serverResponse = _.cloneDeep(inServerResponse);
    if (!serverResponse.routes) return null;
    serverResponse.routes = _.compact(
      serverResponse.routes.map((response, responseKey) => {
        if (typeof selectedRoute !== 'undefined' && responseKey !== selectedRoute) {
          return null;
        }
        const bounds = new window.google.maps.LatLngBounds(
          response.bounds.southwest,
          response.bounds.northeast
        );
        response.bounds = bounds;
        response.overview_path = window.google.maps.geometry.encoding.decodePath(
          response.overview_polyline.points
        );

        response.legs = response.legs.map(leg => {
          leg.start_location = new window.google.maps.LatLng(
            leg.start_location.lat,
            leg.start_location.lng
          );
          leg.end_location = new window.google.maps.LatLng(
            leg.end_location.lat,
            leg.end_location.lng
          );
          leg.steps = leg.steps.map(step => {
            step.encoded_lat_lngs = step.polyline.points;
            step.path = window.google.maps.geometry.encoding.decodePath(
              step.polyline.points
            );
            step.lat_lngs = step.path;
            step.start_location = new window.google.maps.LatLng(
              step.start_location.lat,
              step.start_location.lng
            );
            step.end_location = new window.google.maps.LatLng(
              step.end_location.lat,
              step.end_location.lng
            );
            step.start_point = step.start_location;
            step.end_point = step.end_location;
            if (typeof step.transit_details !== 'undefined') {
              step.transit = step.transit_details;
              step.transit.departure_stop.location = window.google.maps.LatLng(
                parseFloat(step.transit.departure_stop.location.lat),
                parseFloat(step.transit.departure_stop.location.lng)
              );
              step.transit.arrival_stop.location = window.google.maps.LatLng(
                parseFloat(step.transit.arrival_stop.location.lat),
                parseFloat(step.transit.arrival_stop.location.lng)
              );
            }
            if (typeof step.steps !== 'undefined') {
              step.steps = step.steps.map(subStep => {
                subStep.encoded_lat_lngs = subStep.polyline.points;
                subStep.path = window.google.maps.geometry.encoding.decodePath(
                  subStep.polyline.points
                );
                subStep.lat_lngs = subStep.path;
                subStep.start_location = new window.google.maps.LatLng(
                  subStep.start_location.lat,
                  subStep.start_location.lng
                );
                step.end_location = new window.google.maps.LatLng(
                  subStep.end_location.lat,
                  subStep.end_location.lng
                );
                return subStep;
              });
            }
            return step;
          });
          return leg;
        });
        return response;
      })
    );
    serverResponse.request = {
      travelMode: window.google.maps.TravelMode.TRANSIT,
      destination: {
        location: new window.google.maps.LatLng({
          lat: parseFloat(
            _.get(this.props, `ridesData[${this.props.transit.route}].dropoffLatitude`, 0)
          ),
          lng: parseFloat(
            _.get(
              this.props,
              `ridesData[${this.props.transit.route}].dropoffLongitude`,
              0
            )
          )
        })
      },
      origin: {
        location: new window.google.maps.LatLng({
          lat: parseFloat(
            _.get(this.props, `ridesData[${this.props.transit.route}].pickupLatitude`, 0)
          ),
          lng: parseFloat(
            _.get(this.props, `ridesData[${this.props.transit.route}].pickupLongitude`, 0)
          )
        })
      }
    };
    return serverResponse;
  };

  drawMap() {
    this.map = new window.google.maps.Map(document.getElementById('googleMap'), {
      center: { lat: 38.5, lng: -97.75 },
      zoom: 4
    });
    const transitLayer = new window.google.maps.TransitLayer();
    transitLayer.setMap(this.map);
  }

  calculateAndDisplayRoute = () => {
    this.directionsService.route(
      {
        origin: {
          lat: 34.057242,
          lng: -118.394174
        },
        destination: {
          lat: 34.061534,
          lng: -118.410377
        },
        travelMode: window.google.maps.TravelMode.TRANSIT
      },
      NOOP
    );
  };

  render() {
    return (
      <div
        id={'googleMap'}
        style={{ height: '100%', width: 'calc(100% - 300px)', marginLeft: '300px' }}
      />
    );
  }
}

export { GoogleMap as default };
