import {
  addressApprovedProviderFactory,
  addressFactory,
  type AddressApprovedProvider,
  type AddressApprovedProviderModel,
  type AddressApprovedProviderNotFound,
  type AddressApprovedProviderNotFoundModel,
  type AddressMapbox,
  type AddressModel,
  type AddressProps
} from '~/models';
import type { AddressFieldType, AddressOption } from '../Rides.types';
import { useCallback } from 'react';
import { AddressParser, isNil } from '@SRHealth/frontend-lib';
import { formatProperName } from '~/utilities/strings';
import type { AddressRecord } from '@SRHealth/frontend-lib/dist/lib/addressParser/AddressParser';
import { getAddressGeocoding, type MapboxFeature } from '~/services/mapbox.service';
import type { ApprovedProviderRecord } from '~/types';

/** Generates the default address options for the ride address dropdowns. */
export function genDefaultAddressOptions(addressModel?: AddressModel): AddressOption[] {
  if (!addressModel?.value) return [];

  return [
    {
      label: addressModel.value,
      value: {
        value: addressModel.value,
        latitude: addressModel.latitude,
        longitude: addressModel.longitude,
        type: addressModel.type
      }
    }
  ] as AddressOption[];
}

/**
 * Generates the address options from the Mapbox auto complete data.
 */
export function genAddressMapboxOptions(
  mapboxAutoCompleteData: MapboxFeature[]
): AddressOption[] {
  return mapboxAutoCompleteData.map(feature => ({
    label: feature.place_name,
    value: {
      value: feature.place_name,
      latitude: feature.geometry.coordinates[1],
      longitude: feature.geometry.coordinates[0],
      type: 'mapbox'
    }
  }));
}

/** Utility hook to generate a handler for the address multi-select
 * used by AddressForms. The returned function should be assigned to
 * the onClick event. */
export function useAddressChange(
  source: AddressProps['type'],
  type: AddressFieldType,
  onAddressChange: (type: AddressFieldType, address: AddressProps) => void
) {
  const handler = useCallback(
    (addressType: AddressProps['type']) => {
      if (source === addressType) return;

      return onAddressChange(type, addressFactory({ type: addressType }));
    },
    [source, type, onAddressChange]
  );

  return handler;
}

/** Takes a parsed address record and attempts to generate a standardized
 * string. */
function standardizeStreet(address: AddressRecord) {
  const components: string[] = [];

  if (address?.prefix) components.push(address.prefix);

  if (!isNil(address?.number)) components.push(address.number);

  if (address?.street) components.push(formatProperName(address.street));

  if (address?.type) components.push(address.type);

  return components.join(' ');
}

/** Utility function to standardize how addresses are displayed and extract
 *  the street, city, state and zip code. */
export function standardizeAddress(
  address: string
): [string, string | undefined, string | undefined, string | undefined] {
  const parsedAddress = AddressParser.parse(address);

  if (!parsedAddress) return [address, undefined, undefined, undefined];

  const { sec_unit_num, sec_unit_type } = parsedAddress;

  const addressComponents: string[] = [standardizeStreet(parsedAddress)];

  if (sec_unit_num && sec_unit_type) {
    addressComponents.push(`${sec_unit_num} ${sec_unit_type}`);
  }

  return [
    addressComponents.join(', '),
    formatProperName(parsedAddress.city),
    parsedAddress.state.toUpperCase(),
    parsedAddress.zip
  ];
}

/** A hook used in the AddressFormApprovedProvider and CareProviderDrawerBody components
 * to keep the logic for approved provider address changes in one place.
 *
 * Handles generating a new approved provider address model from an ApprovedProvider
 * record. This is generally called when the user looks up a list of providers and
 * selects an option from the dropdown list. */
export function useProviderAddressChangeHandler(
  callback: (address: AddressApprovedProviderModel) => void
) {
  return useCallback(
    async (_, provider: ApprovedProviderRecord) => {
      const newModelProps: Partial<AddressApprovedProvider> = {
        type: 'approved-provider'
      };

      if (provider) {
        newModelProps.value = provider.address;
        newModelProps.id = provider.id;
        newModelProps.name = provider.name;
        newModelProps.phone = provider.phone;
        newModelProps.npi = provider.npiId;
        newModelProps.latitude = undefined;
        newModelProps.longitude = undefined;

        const addressLookup = await getAddressGeocoding({ address: provider.address });

        if (addressLookup?.features?.[0]) {
          newModelProps.longitude = addressLookup.features[0].center[0];
          newModelProps.latitude = addressLookup.features[0].center[1];
        }
      }

      callback(addressApprovedProviderFactory(newModelProps));
    },
    [callback]
  );
}

/** A hook used in the AddressFormApprovedProvider and CareProviderDrawerBody components
 * to keep the logic for approved provider address changes in one place.
 *
 * Handles updating an existing AddressApprovedProviderModel with new lat/lng coords
 * from the user selecting a new mapbox address. The "value" field on the model is
 * also updated. */
export function useProviderCoordUpdateHandler(
  address: AddressApprovedProviderModel | AddressApprovedProviderNotFoundModel,
  callback: (
    address: AddressApprovedProviderModel | AddressApprovedProviderNotFoundModel
  ) => void
) {
  return useCallback(
    (_, mapbox: AddressMapbox) => {
      const newModelProps: Partial<
        AddressApprovedProvider | AddressApprovedProviderNotFound
      > = {
        type: address.type
      };

      if (mapbox) {
        newModelProps.latitude = mapbox.latitude;
        newModelProps.longitude = mapbox.longitude;
        newModelProps.value = mapbox.value;
      }

      newModelProps.id = address?.id;
      newModelProps.name = address?.name;
      newModelProps.phone = address?.phone;

      if (address.type === 'approved-provider') {
        (newModelProps as Partial<AddressApprovedProvider>).npi = address.npi;
      } else {
        (newModelProps as Partial<AddressApprovedProviderNotFound>).facility =
          address?.facility;
        (newModelProps as Partial<AddressApprovedProviderNotFound>).notes =
          address?.notes;
      }

      callback(
        addressFactory(
          newModelProps as AddressApprovedProvider | AddressApprovedProviderNotFound
        ) as AddressApprovedProviderModel | AddressApprovedProviderNotFoundModel
      );
    },
    [address, callback]
  );
}
