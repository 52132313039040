/**
 * Parses raw results into JS standard objects
 * @param {Array} data Raw data
 * @returns {Array} Parsed data
 */
const parseMembers = data => {
  if (!data) return { parsed: null };

  return {
    rows: [...data.rows],
    meta: data.meta,
    parsed: Date.now()
  };
};

const parseAdditionalMemberData = data => data.rows[0];

export { parseMembers, parseAdditionalMemberData };
