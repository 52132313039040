import SvgMapPinBlueSmall from 'Common/Components/Svgs/SvgMapPinBlueSmall';
import SvgMapPinRedSmall from 'Common/Components/Svgs/SvgMapPinRedSmall';
import { has, isEmpty, isNil } from 'lodash-es';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { DISABLE_EDIT_STATUS } from '~/constants';
import AddressDisplay from '~/Shared/Components/AddressDisplay/AddressDisplay';
import { getBookingTypeLabel } from '~/utilities/booking.helper';
import {
  flipAddresses,
  getPickupTime,
  standardizeAddresses
} from '~/utilities/helperFunctions';

const TransportComplete = ({
  editTransportInfo,
  tripNumber,
  bookingData,
  ride,
  legIndex,
  removeLeg,
  disableEdit,
  status
}) => {
  const bookingDataKeys = [
    'firstName',
    'lastName',
    'medicalId',
    'phone',
    'phone2',
    'internalNotes',
    'additionalNotes',
    'hospitalCoords',
    'additionalPassengers',
    'hospitalId',
    'dateOfBirth',
    'compliance',
    'isModal',
    'healthSubPlanId',
    'treatmentId',
    'treatmentName',
    'mobilityType',
    'mobilitySubType',
    'timezoneFormat',
    'pastBookingDays',
    'currentRideIndex',
    'rides',
    'tripCompleted',
    'status',
    'repeatAppointmentData',
    'id',
    'recommendedPickupTz',
    'recommendedPickup',
    'apptTimeTz',
    'apptTime',
    'selectedDate',
    'bookingType',
    'originalAppointmentTime',
    'appointmentTime',
    'originalRideStartTime',
    'rideStartTime',
    'timeCustomFields',
    'apptEnds',
    'tripRides',
    'extraTime',
    'distance',
    'mode'
  ];

  let rideData = isEmpty(ride)
    ? bookingData
    : Object.assign(
      {},
      Object.fromEntries(
        Object.entries(bookingData).filter(
          entry => entry[1] && bookingDataKeys.includes(entry[0])
        )
      ),
      Object.fromEntries(Object.entries(ride).filter(entry => !isNil(entry[1])))
    );

  const isRoundTrip = () =>
    isEmpty(ride) && tripNumber === 2 && rideData.bookingType === 'roundtrip';

  const isEdited = () => {
    let ret = false;

    // booked ride
    if ('id' in rideData) {
      if (rideData?.bookingType === 'tocare') {
        ret =
          (!isNil(rideData.originalAppointmentTime) &&
            rideData.appointmentTime !== rideData.originalAppointmentTime) ||
          (!isNil(rideData.originalRideStartTime) &&
            rideData.rideStartTime !== rideData.originalRideStartTime);
      } else {
        ret =
          !isNil(rideData.originalRideStartTime) &&
          rideData.rideStartTime !== rideData.originalRideStartTime;
      }
    }

    return ret;
  };

  let showRemoveRide = false;
  if (
    legIndex > -1 &&
    rideData.currentRideIndex > 0 &&
    rideData?.rides?.[legIndex]?.legCompleted
  ) {
    showRemoveRide = true;
  }

  if (isRoundTrip()) {
    rideData = flipAddresses(rideData);
  }

  const addresses = standardizeAddresses(rideData);
  const timeCustomData = !isNil(rideData.timeCustomFields)
    ? Object.keys(rideData.timeCustomFields)
    : [];
  const rideNumber = isEmpty(ride) || legIndex === -1 ? tripNumber : legIndex + 1;
  const apptTime = isRoundTrip() ? rideData.apptEnds : rideData.apptTime;
  const appTimeDisplay = `${apptTime} ${
    rideData.timezoneFormat ? moment.tz(rideData.timezoneFormat).format('z') : ''
  } ${rideData.selectedDate}`;
  const repeatAppointments = rideData?.repeatAppointmentData?.repeatApptText ?? '';
  const tripRides = rideData?.tripRides ?? [];
  const totalRides = tripRides.length;
  const didWeEdit = isEdited();

  return (
    <div className="patientInfoComplete transportInfoComplete">
      <div className="left">
        <p className="trip">
          Leg {rideNumber}
          {totalRides > 0 ? (
            <span className="tripTotal">{` (of ${totalRides})`}</span>
          ) : (
            ''
          )}
          {rideData.bookingType && (
            <span className="tripBold">{` ${getBookingTypeLabel(
              rideData.bookingType
            )}`}</span>
          )}
        </p>
        <p className="appt">
          {appTimeDisplay}
          {didWeEdit ? <i> (Edited)</i> : null}
        </p>
        {rideData.extraTime > 0 ? (
          <p className="phone">Extra Time: {rideData.extraTime} minutes</p>
        ) : (
          <div>
            {timeCustomData.map((field, key) => {
              if (has(rideData, `timeCustomFields.${field}`)) {
                return (
                  <p key={key} className="phone">
                    Extra Time: {rideData.timeCustomFields[field]} minutes
                  </p>
                );
              } else {
                return null;
              }
            })}
          </div>
        )}
        <p className="extraTime"></p>
        {ride?.nemtRunPickup || ride?.rideStartTime ? (
          <p className="recommended">Recommended Pickup: {getPickupTime(ride)}</p>
        ) : null}
        <div className="pickupAddress">
          <span className="svgCont">
            <SvgMapPinBlueSmall className="bluePin" />
            <span className="dot"></span>
            <span className="dot"></span>
            <span className="dot"></span>
          </span>
          <span className="addressText">
            <AddressDisplay {...addresses.pickup} />
          </span>
        </div>
        <div className="dropoffAddress">
          <span className="svgCont">
            <SvgMapPinRedSmall className="redPin" />
          </span>
          <span className="addressText">
            <AddressDisplay {...addresses.dropoff} />
          </span>
        </div>
        {repeatAppointments !== '' ? <p className="phone">{repeatAppointments}</p> : null}
        <p className="distanceText">
          {rideData && rideData.distance && rideData.mode !== 'Public' ? (
            <span>Distance: {parseFloat(rideData.distance).toFixed(2)} miles</span>
          ) : null}
        </p>
      </div>
      {!disableEdit &&
      DISABLE_EDIT_STATUS.indexOf(status) === -1 &&
      rideData.mode !== 'Public' ? (
          <div className="right">
            <p className="edit" onClick={editTransportInfo}>
            Edit
            </p>
            {showRemoveRide ? (
              <p className="editRemove" onClick={removeLeg}>
              Remove
              </p>
            ) : null}
          </div>
        ) : null}
    </div>
  );
};

TransportComplete.defaultProps = {
  bookingData: {},
  editTransportInfo: () => {},
  tripNumber: 1,
  apptTime: '',
  timeCustomFields: [],
  disableEdit: false,
  extraTime: 0,
  status: 'Scheduled',
  ride: {},
  legIndex: -1,
  removeLeg: () => {}
};

TransportComplete.propTypes = {
  bookingData: PropTypes.object,
  editTransportInfo: PropTypes.func,
  tripNumber: PropTypes.number,
  apptTime: PropTypes.string,
  timeCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disableEdit: PropTypes.bool,
  extraTime: PropTypes.number,
  status: PropTypes.string,
  ride: PropTypes.object,
  legIndex: PropTypes.number,
  removeLeg: PropTypes.func
};

export default TransportComplete;
