import React from 'react';
import { type AddressModel, type AddressType } from '~/models';
import type { AddressFieldType } from '../Rides.types';
import AddressFormMapbox from './AddressFormMapbox';
import {
  useModel,
  type ButtonConfigWithValue,
  type IconType
} from '@SRHealth/frontend-lib';
import AddressFormApprovedProvider from './AddressFormApprovedProvider';
import AddressFormSavedLocation from './AddressFormSavedLocation';
import AddressFormVenues from './AddressFormVenues';
import AddressFormUnapprovedProvider from './AddressFormUnapprovedProvider';

export type AddressFormAddressType = ButtonConfigWithValue<string, IconType, AddressType>;

export type AddressFormProps = {
  type: AddressFieldType;
  address: AddressModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  /** Optional alternative address types available to the user. */
  addressTypes?: AddressFormAddressType[];
};

export default function AddressForm({ address, ...rest }: AddressFormProps) {
  const { model } = useModel(address);

  switch (model.type) {
    case 'mapbox':
      return <AddressFormMapbox address={model} {...rest} />;

    case 'approved-provider':
      return <AddressFormApprovedProvider address={model} {...rest} />;

    case 'provider-not-found':
      return <AddressFormUnapprovedProvider address={model} {...rest} />;

    case 'saved':
      return <AddressFormSavedLocation address={model} {...rest} />;

    case 'venue':
      return <AddressFormVenues address={model} {...rest} />;

    default:
      return <></>;
  }
}
