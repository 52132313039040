import React from 'react';
import PropTypes from 'prop-types';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';

class ErrorHandler extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      message: ''
    };
    this.prod = ['staging', 'production'];
  }

  componentDidCatch(error, info) {
    this.setState({
      error: true,
      message: info.componentStack.toString()
    });
  }

  render() {
    const { error, message } = this.state;

    if (error) {
      return (
        <div className="ErrorHandler">
          <PageFrame>
            <div className="ErrorHandlerBox">
              <p className="message">
                Oops! Something went wrong. Our technical team is looking into this issue.
              </p>
              <img
                src={`${process.env.REACT_APP_PROTOCOL}://${process.env.REACT_APP_API}/assets/henry404_1.gif`}
                width="400"
                title="Henry feels your pain"
              />
              {!this.prod.includes(process.env.REACT_APP_ENVIRONMENT) ? (
                <details className="detailsComponent">{message}</details>
              ) : null}
            </div>
          </PageFrame>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  user: PropTypes.object
};

ErrorHandler.defaultProps = {
  children: {},
  user: {}
};

export default ErrorHandler;
