import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { getClassName } from '~/utilities/helperFunctions';
import ReactLoading from 'react-loading';
import SvgMouse from '~/Shared/Components/Svgs/SvgMouse';
import SvgArrowForward from '~/Shared/Components/Svgs/SvgArrowForward';
import SvgArrowBack from '~/Shared/Components/Svgs/SvgArrowBack';

class ImporterPreview extends Component {
  constructor(props) {
    super(props);

    this.tableRef = null;
    this.state = {
      showHorizontalScroller: false,
      hasScrolled: false
    };
  }

  componentDidMount() {
    // show horizontal scroller if necessary
    if (this.tableRef && this.tableRef.scrollWidth > this.tableRef.offsetWidth) {
      this.setState({ showHorizontalScroller: true });
    }
  }

  /**
   * Handle table scroll events
   * @returns {undefined}
   */
  handleTableScroll() {
    if (!this.state.hasScrolled) {
      this.setState({ hasScrolled: true });
    }
  }

  /**
   * Parse sample data into a simple data structure matching field mappings
   * @param {Array} sampleData List of sample data
   * @returns {Array} Normalized data
   */
  getNormalizedData(sampleData) {
    const { fields, fieldMapping } = this.props;
    const rows = [];
    _.forOwn(sampleData, row => {
      const rowData = {};
      fields.forEach(field => {
        if (field.name === 'ignore') return;
        const sampleDataField = Object.keys(fieldMapping).find(
          key => fieldMapping[key] === field.value
        );
        if (sampleDataField !== undefined) {
          rowData[field.name] = row[sampleDataField];
        }
      });
      rows.push(rowData);
    });
    return rows;
  }

  render() {
    const { job, step, fields } = this.props;
    const { showHorizontalScroller, hasScrolled } = this.state;
    const sampleData = job.sampleData.rows || [];
    const normalizedData = this.getNormalizedData(sampleData);

    const filtered_fields = fields.filter(field => field.name !== 'ignore');
    return (
      <div className="ImporterPreview">
        {_.isEmpty(job) ? (
          <ReactLoading type="spin" className="importerLoading" />
        ) : (
          <div className="wrapper">
            <h2>{step.index + 1}. Preview Import</h2>
            <p>The following sample records have been found in the uploaded CSV file.</p>
            {showHorizontalScroller ? (
              <div
                className={getClassName({
                  horizontalScroller: true,
                  fade: hasScrolled
                })}
              >
                <h6>Scroll Horizontally</h6>
                <div>
                  <SvgArrowBack />
                  <SvgMouse />
                  <SvgArrowForward />
                </div>
              </div>
            ) : null}
            <table
              ref={el => (this.tableRef = el)}
              onScroll={() => this.handleTableScroll()}
            >
              <thead>
                <tr>
                  {_.compact(
                    filtered_fields.map(field => {
                      if (field.value === 'HealthSubPlan') {
                        return null;
                      }
                      // I don't like this but it's the easiest way since I don't need to loop the object.
                      if (Object.hasOwn(normalizedData[0], field.name)) {
                        return <th key={field.name}>{field.label}</th>;
                      }
                    })
                  )}
                </tr>
              </thead>
              <tbody>
                {normalizedData.map((datum, idx) => (
                  <tr key={idx}>
                    {_.compact(
                      filtered_fields.map(field => {
                        if (field.value === 'HealthSubPlan') {
                          return null;
                        }

                        if (datum[field.name] !== undefined) {
                          return <td key={field.name}>{datum[field.name]}</td>;
                        }
                      })
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  }
}

ImporterPreview.propTypes = {
  step: PropTypes.object,
  setStep: PropTypes.func,
  job: PropTypes.object,
  fields: PropTypes.array,
  fieldMapping: PropTypes.object
};

ImporterPreview.defaultProps = {
  step: {},
  setStep: () => {},
  job: {},
  fields: [],
  fieldMapping: {}
};

export default ImporterPreview;
