import React from 'react';
import type { ComplianceRecord } from '~/types';
import { Input } from '@SRHealth/frontend-lib';

type ComplianceFieldProps = {
  field: ComplianceRecord;
  value: string | number | boolean | undefined | null;
  error: boolean;
  onChange: (id: number, v: string) => void;
};

export default function ComplianceField({
  field,
  value,
  error,
  onChange
}: ComplianceFieldProps) {
  switch (field.input_type) {
    case 'text':
      return (
        <Input
          inputId={field.id}
          type="single"
          name={`complianceInfo-${field.id}`}
          label={field.input_name}
          placeholder={field.input_description}
          value={(value ?? '') as string}
          error={error}
          onChange={onChange}
          className="w-full"
          required
        />
      );

    case 'select':
      return (
        <Input
          inputId={field.id}
          type="select"
          name={`complianceInfo.${field.id}`}
          label={field.input_name}
          placeholder={field.input_description}
          value={value}
          options={field.compliance_options.map(o => ({
            label: o.option_value,
            value: o.id
          }))}
          error={error}
          onChange={onChange}
          className="w-full"
          required
        />
      );

    case 'hidden':
    default:
      return null;
  }
}
