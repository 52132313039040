import axios from '~/Modules/safeAxios';
import { baseRequestConfig } from '~/utilities/auth.helper';
import { statusNotifications } from '~/utilities/helperFunctions';
import { ANALYTICS_API } from '~/constants';

const updateRideClaimedStatus = async ({
  rideClaimStatus,
  rideId,
  reclaimation = false
}) => {
  try {
    const claimRideUrl = `${ANALYTICS_API}/api/v1/rides/${rideId}/alertClaimStatus`;
    const response = await axios.post(
      claimRideUrl,
      {
        status: rideClaimStatus,
        ...(reclaimation && { reclaimation })
      },
      baseRequestConfig()
    );
    if (response?.data?.status) {
      return { success: true, data: response.data };
    } else {
      throw new Error(response);
    }
  } catch (err) {
    let errorMessage = 'Something went wrong with updating this ride. Try again later';
    if (err.response?.data?.message) {
      errorMessage = err.response.data.message;
    }
    statusNotifications(errorMessage, 'error', 2000);
    return { success: false, error: err };
  }
};

export default updateRideClaimedStatus;
