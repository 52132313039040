import { Input } from '@SRHealth/frontend-lib';
import React, { useMemo, useState } from 'react';
import { isRideTimeString, type RideType } from '~/models';
import { useAppSelector } from '~/Modules';
import { useTimeOptions } from './RidesTimePicker.utils';
import { selectLastRideTime } from '~/Modules/rideBooking';
import { addRideTimeMinutes, timezoneDifference } from '~/utilities/timesAndDates';

export type RidesTimePickerProps = {
  /** Id for the input element. */
  inputId: number;
  /** Is the ride an arrive-by or depart-at ride? This impacts the label
   * of the time picker element. */
  type: RideType;
  /** The selected ride time. */
  time: string | undefined;
  /** The ride timezone. */
  timezone: string | undefined;
  error: boolean;
  /** The event handler for changes to the selected time. */
  onChange: (inputId: number, time: string) => void;
};

export default function RidesTimePicker({
  inputId,
  type,
  time,
  timezone,
  error,
  onChange
}: RidesTimePickerProps) {
  const [query, setQuery] = useState('');
  const lastRideTime = useAppSelector(selectLastRideTime);
  const lastRideTimezone = useAppSelector(s => s.rideBooking.rides.at(-2)?.timezone);
  const timeOptions = useTimeOptions(type, timezone);

  // Detect if the last ride time is in a different timezone than the current ride.
  const _lastRidetime = useMemo(() => {
    if (!timezone || timezone === lastRideTimezone) return lastRideTime;

    if (!isRideTimeString(lastRideTime)) return lastRideTime;

    const tzOffset = timezoneDifference(lastRideTimezone!, timezone);
    return addRideTimeMinutes(lastRideTime!, tzOffset);
  }, [timezone, lastRideTime, lastRideTimezone]);

  const _timeOptions = useMemo(() => {
    if (!_lastRidetime || !isRideTimeString(_lastRidetime)) return timeOptions;

    const optIndex = timeOptions.findIndex(({ value }) => value === _lastRidetime);
    const withFlex = timeOptions.findIndex(({ value }) => value === 'Flex Time');

    return withFlex > -1 && optIndex > -1
      ? [timeOptions[withFlex]].concat(timeOptions.slice(optIndex + 1))
      : timeOptions.slice(optIndex + 1);
  }, [_lastRidetime, timeOptions]);

  const label = type === 'arriveBy' ? 'Appointment Time' : 'Depart Pick Up Time';

  return (
    <Input
      inputId={inputId}
      type="search-dropdown"
      name="time"
      label={label}
      placeholder="Select a time"
      icon="Time"
      loading={!timezone}
      value={time}
      options={_timeOptions.filter(({ label }) => label.includes(query))}
      debounce={650}
      onInput={(_, query) => setQuery(query)}
      onSearch={(_, query) => setQuery(query)}
      onChange={onChange}
      disabled={!timezone}
      error={error}
      required
    />
  );
}
