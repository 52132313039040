import { token } from '~/utilities/auth.helper';
import type { ApiResponseGeneric } from '../types';

export type GetSubPlanMobilityTypeResponse = ApiResponseGeneric & {
  data: number[];
  message: string;
  errors: string[];
  timestamp: string;
  environment: string;
};

/**
 * Look up the mobility types for a given sub plan id
 * @param subPlanId
 * @returns Promise<GetSubPlanMobilityTypeResponse>
 */
export const getSubPlanMobilityTypes = async (
  subPlanId: number | string
): Promise<GetSubPlanMobilityTypeResponse> => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${subPlanId}/mobility-types`,
    {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      }
    }
  ).then(res => res.json());
};

/**
 * Update the mobility types for a given sub plan id
 * @param subPlanId
 * @param mobilityTypes
 * @returns
 */
export const updateSubPlanMobilityTypes = async (
  subPlanId: number | string,
  mobilityTypes: (string | number)[]
) => {
  return fetch(
    `//${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/health-plans/sub-plans/${subPlanId}/mobility-types`,
    {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token()}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ mobilityTypes: mobilityTypes })
    }
  ).then(res => res.json());
};
