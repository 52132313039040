import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import expenseFormInputs from './expenseFormInputs';
import ExpensesService from '~/services/expenses.service';

const { calcDistance, isTravelCategory, showAddressFields } = new ExpensesService();

const ExpenseForm = ({
  expense = null,
  setExpense,
  options,
  report,
  mode,
  ridesInWindow
}) => {
  const { category, distance, toAddress, fromAddress, ratePerMile, reimbursable } =
    expense;
  const { expenseStatuses = [] } = useSelector(state => state?.user?.expenseStatuses);

  const [didMount, setDidMount] = useState(false);

  useEffect(() => setDidMount(true), []);

  const setField = ({ name, value }) => {
    setExpense({ ...expense, [name]: value });
  };

  const setFields = fields => {
    setExpense({ ...expense, ...fields });
  };

  useEffect(() => {
    // If to/from address change get new distance.
    if (fromAddress.lngLat && toAddress.lngLat) {
      setDistance();
    } else if (distance !== 0) {
      setField({ name: 'distance', value: 0 });
    }
  }, [fromAddress.lngLat, toAddress.lngLat]);

  // Listen for changes in reimbursable, should clear paidBy
  useEffect(() => {
    const { paidBy = [] } = options.expenseSettings;
    if (reimbursable) {
      setField({ name: 'paidBy', value: '' });
    } else {
      setField({
        name: 'paidBy',
        value: paidBy[0] ? paidBy[0].value : ''
      });
    }
  }, [reimbursable]);

  // Calulate the price amount if distance and price are set
  useEffect(() => {
    if (
      !isNaN(distance) &&
      !isNaN(ratePerMile) &&
      showAddressFields({ category }) &&
      !isTravelCategory({ category })
    ) {
      setField({
        name: 'amount',
        value: Number(distance * ratePerMile).toFixed(3)
      });
    }
  }, [distance, ratePerMile]);

  // If category changes then reset some fields
  useEffect(() => {
    if (didMount) {
      const ride = ridesInWindow.find(r => r.id === report.ride_id);
      const categoriesToFill = [
        'Advanced Funds — Mileage',
        'Mileage',
        'Bus Ticket',
        'Facility Reimbursement',
        'Gas Card',
        'Travel - Airfare',
        'Travel - Commercial Bus',
        'Travel - Ferry or water',
        'Travel - Train'
      ];
      // if Mileage and ride is selected
      if (categoriesToFill.includes(category) && ride) {
        // set to and from address based on ride address

        setFields({
          toAddress: {
            address: ride.toAddress,
            lngLat: ride.toLongitude + ',' + ride.toLatitude
          },
          fromAddress: {
            address: ride.fromAddress,
            lngLat: ride.fromLongitude + ',' + ride.fromLatitude
          }
        });
      } else {
        setFields({
          amount: '0.00',
          distance: 0,
          toAddress: { address: null, lngLat: null },
          fromAddress: { address: null, lngLat: null }
        });
      }
    }
  }, [category]);

  const setDistance = async () => {
    const res = await calcDistance({
      from: fromAddress.lngLat,
      to: toAddress.lngLat
    });
    let miles = 0;

    // Convert meters to miles
    if (res?.success) {
      miles = res.distance;
    }

    setField({
      name: 'distance',
      value: miles
    });
  };
  const isDisabled = () => {
    if (expense && expense.repeatingExpenseId) {
      const repeatingExpenseLength = report.expenses.filter(
        e => e.repeatingExpenseId === expense.repeatingExpenseId
      );
      return repeatingExpenseLength.length > 1;
    }
    return false;
  };
  return (
    <div className="ExpenseForm">
      {expenseFormInputs(
        expense,
        setField,
        setFields,
        options,
        mode,
        expenseStatuses
      ).map(({ Component, required, name, label, show }) => {
        const isExpenseCategoryDisabled = isDisabled();
        if (!show) return null;
        return (
          <div key={name} className="input-section">
            <div>
              <label>
                {required ? <span className="fc-red">*</span> : null}
                {label}
              </label>
            </div>
            <div>
              {Array.isArray(Component) ? (
                Component
              ) : (
                <Component disabled={isExpenseCategoryDisabled} />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default ExpenseForm;
