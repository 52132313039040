import _ from 'lodash-es';
import moment from 'moment-timezone';
import { flipAddresses } from '~/utilities/helperFunctions';

/**
 * Parse data from chat request to object used for booking flow queries
 * for multi leg rides in lumen
 * @param {Object} chatRequest Chat request
 * @returns {Object} Ride details payload
 */
export const getRideDetailsPayloadMulti = chatRequest => {
  // base trip object
  const trip: { [x: string]: unknown } = {
    benbotRequestId: chatRequest.rideDetails.benbotRequestId,
    bookingType: chatRequest.rideDetails.booking_type,
    confirmBooking: chatRequest.rideDetails.confirm_booking,
    currentMode: '', // ???
    dateOfBirth: !_.isNil(chatRequest.rideDetails.dateOfBirth)
      ? chatRequest.rideDetails.dateOfBirth
      : '',
    estimatedTimeSavings: '', // ???
    filterOrder: 'asc', // ???
    filterby: 'time', // ???
    hospitalId: chatRequest.rideDetails.hospitalId,
    healthPlanId: _.get(chatRequest.rideDetails, 'health_plan_id', null),
    healthSubPlanId: _.get(chatRequest.rideDetails, 'health_sub_plan_id', null),
    localDefaultTimezone: chatRequest.rideDetails.local_default_timezone,
    medicalId: chatRequest.rideDetails.medicalId,
    patientLastName: chatRequest.rideDetails.patientLastName,
    patientName: chatRequest.rideDetails.patientName,
    additionalNotes: chatRequest.rideDetails.additionalNotes,
    payWithCard: false, // ???
    phoneNo: chatRequest.rideDetails.phoneNo,
    purpose: '', // ???
    rideType: 'ondemand', // ???
    vehicleId: '', // ???
    rides: [],
    pastRide: chatRequest.rideDetails.pastRide || false,
    additionalPassengers: chatRequest.rideDetails.additionalPassengers
  };

  // check for custom fields
  if (!_.isNil(chatRequest.rideDetails.patient_custom_fields)) {
    trip.patientCustomFields = chatRequest.rideDetails.patient_custom_fields;
  }
  if (!_.isNil(chatRequest.rideDetails.compliance)) {
    trip.compliance = chatRequest.rideDetails.compliance;
  }

  // base ride object
  const ride: Partial<RideData | BookingDataStore> = {
    bookingType: chatRequest.rideDetails.booking_type,
    dropoffAddress: chatRequest.rideDetails.dropoffLoc,
    dropoffLatitude: chatRequest.rideDetails.dropoff_latitude,
    dropoffLongitude: chatRequest.rideDetails.dropoff_longitude,
    pickupAddress: chatRequest.rideDetails.pickupLoc,
    pickupLatitude: chatRequest.rideDetails.pickup_latitude,
    pickupLongitude: chatRequest.rideDetails.pickup_longitude,
    selectedDate: chatRequest.rideDetails.date,
    additionalNotes: chatRequest.rideDetails.additionalNotes,
    transportType: chatRequest.rideDetails.transport,
    transportTypeName: chatRequest.rideDetails.transportModelName,
    apptTime: chatRequest.rideDetails.appointmentTime
  };

  // add custom fields, venue id, and adjust start time if necessary
  if (chatRequest.rideDetails.booking_type === 'fromcare') {
    ride.apptTime = chatRequest.rideDetails.patientsTimezone
      ? moment()
        .tz(chatRequest.rideDetails.patientsTimezone)
        .add(5, 'minutes')
        .format('HH:mm')
      : moment().add(5, 'minutes').format('HH:mm');
  }
  if (!_.isNil(chatRequest.rideDetails.dropoff_venue_id)) {
    ride.dropoffVenueId = chatRequest.rideDetails.pickup_venue_id;
  }
  if (!_.isNil(chatRequest.rideDetails.dropoff_venue_id)) {
    ride.pickupVenueId = chatRequest.rideDetails.pickup_venue_id;
  }
  if (!_.isNil(chatRequest.rideDetails.ride_custom_fields)) {
    ride.rideCustomFields = chatRequest.rideDetails.ride_custom_fields;
  }
  if (!_.isNil(chatRequest.rideDetails.time_custom_fields)) {
    ride.timeCustomFields = chatRequest.rideDetails.time_custom_fields;
  }

  // if round trip than create a second ride object, flip address data and add to rides array
  if (chatRequest.rideDetails.booking_type === 'roundtrip') {
    ride.bookingType = 'tocare';
    const blegRide = flipAddresses(_.clone(ride));
    blegRide.bookingType = 'fromcare';
    blegRide.apptTime = chatRequest.rideDetails.time_ends;
    (trip.rides as unknown[]).push(ride);
    (trip.rides as unknown[]).push(blegRide);
  } else {
    (trip.rides as unknown[]).push(ride);
  }

  return trip;
};
