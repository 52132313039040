/* eslint-disable @typescript-eslint/no-namespace */
namespace SavedAddress {
  // Users cannot create more than 9 saved addresses per member
  export const MAX_SAVED_ADDRESSES = 9;

  // Names cannot exceed 50 characters
  export const MAX_ADDRESS_NAME_LENGTH = 50;

  export namespace Errors {
    export const INVALID_ADDRESS_NAME =
      'An address name is required to save an updated address';
    export const INVALID_ADDRESS_INPUT =
      'The address field is required to save an updated address';
    export const DUPLICATE_ADDRESS =
      'The selected address already exists in your saved addresses';
    export const DUPLICATE_ADDRESS_NAME =
      'The selected address name already exists in your saved addresses';
    export const SAVED_ADDRESS_LIMIT = 'Members are limited to 9 total saved addresses';
    export const CONFIRM_CLOSE_UTILITY = 'Save changes before closing';
  }
}

export default SavedAddress;
