import React, { useMemo } from 'react';
import { Input, MultiSelectButton } from '@SRHealth/frontend-lib';
import {
  type AddressMapbox,
  type AddressMapboxModel,
  type AddressModel,
  addressFactory
} from '~/models';
import type { AddressFieldType } from '../Rides.types';
import { genDefaultAddressOptions, useAddressChange } from './Address.utils';
import type { AddressFormAddressType } from './AddressForm';
import useMapboxSearch from '~/hooks/useMapboxSearch';

export type AddressFormMapboxProps = {
  type: AddressFieldType;
  address: AddressMapboxModel;
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
  /** Optional alternative address types available to the user. */
  addressTypes?: AddressFormAddressType[];
};

export default function AddressFormMapbox({
  type,
  address,
  onAddressChange,
  addressTypes = []
}: AddressFormMapboxProps) {
  const handleAddressTypeChange = useAddressChange(address.type, type, onAddressChange);

  const defaultOptions = useMemo(() => genDefaultAddressOptions(address), [address]);
  const [isSearching, options, handleMapboxInput] = useMapboxSearch(defaultOptions);

  const label = type === 'depart' ? 'Depart' : 'Arrive';

  const hasAddressTypes = addressTypes.length > 1;
  const activeAddressType = addressTypes.findIndex(t => t.value === address.type);

  const currentValue = options.find(({ value }) => value.value === address.value)?.value;

  return (
    <>
      <div className="flex-1 min-w-0">
        <Input
          inputId={0}
          type="search-dropdown"
          name={type}
          label={label}
          options={options}
          loading={isSearching}
          placeholder="Search for an address"
          value={currentValue}
          debounce={650}
          onSearch={handleMapboxInput}
          onInput={handleMapboxInput}
          onChange={(_, addressObj: AddressMapbox) =>
            onAddressChange(type, addressFactory(addressObj))
          }
          error={!!Object.keys(address.ruleErrors).length}
          required
        />
      </div>
      {hasAddressTypes ? (
        <div className="flex items-center h-[40px]" style={{ paddingBottom: '16px' }}>
          <MultiSelectButton
            buttons={addressTypes}
            activeButton={activeAddressType}
            onClick={handleAddressTypeChange}
          />
        </div>
      ) : null}
    </>
  );
}
