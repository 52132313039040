import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { reset } from '~/Modules/scheduleRides';
import { updateBookingData } from '~/Modules/bookingData';
import { flipAddresses } from '~/utilities/helperFunctions';
import TransportType from './FormComponents/TransportType';
import AppointmentTime from './FormComponents/AppointmentTime';
import TransportComplete from './DisplayComponents/TransportComplete';
import RideNotes from './FormComponents/RideNotes';
import RideNotesComplete from './DisplayComponents/RideNotesComplete';

// handle single and round trip booking
class BookRidesSingle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTransportFormBleg: false,
      showAllNotes: false,

      // show initial form components
      showTransportForm: false,
      showAppointmentTime: false,
      showAppointmentTimeBleg: false,
      showRideNotes: false,

      // show completed form components
      showTransportComplete: false,
      showTransportCompleteBleg: false,
      showRideNotesComplete: false,

      // edit previously completed form components
      editRideNotesForm: false,
      editAppointmentForm: false,
      editAppointmentFormBleg: false,
      missingFieldsError: '',
      missingFields: []
    };
  }

  componentDidMount() {
    this.setState({ showTransportForm: true });
  }

  /**
   * toggles display of patient notes between more and less
   * @return {undefined}
   */
  toggleNotes = () => {
    this.setState(prevState => ({
      showAllNotes: !prevState.showAllNotes
    }));
  };

  /**
   * hide transport form and show appointment time form
   * @return {undefined} - nothing returned
   */
  handleTransportationUpdate = () => {
    this.setState({
      showAppointmentTime: true,
      showTransportForm: true
    });
  };

  /**
   * add appointment date and time
   * if bookingType is from care (bleg) do not show round trip button
   * instead show ride notes component
   * @return {undefined}
   */
  updateAppointmentInfo = () => {
    const state = {
      showAppointmentTime: false,
      showTransportForm: false,
      showRoundTripButton: false,
      editAppointmentInfo: false,
      showAppointmentTimeBleg: false,
      showTransportComplete: true
    };

    if (this.props.roundTrip === true) {
      state.showAppointmentTimeBleg = true;
    } else {
      state.showRideNotes = true;
    }
    this.setState(state);
  };

  /**
   * add appointment date and time
   * for bleg only
   * @return {undefined}
   */
  updateAppointmentInfoBleg = () => {
    const state = {
      showAppointmentTimeBleg: false,
      editAppointmentFormBleg: false,
      showTransportCompleteBleg: true,
      showRideNotes: true
    };

    this.setState(state);
  };

  /**
   * edit transportation info, pickup and dropoff
   * if you need to update it. takes you back to roundtrip button
   * @return {undefined}
   */
  editTransportInfo = () => {
    // this.props.reset();
    this.props.hideMiddleColumn();
    this.props.showRoundTripButton();
  };

  /**
   * edit transport info for round trip
   * @return {undefined}
   */
  editTransportInfoBleg = () => {
    const state = this.hideAllFormComponents();

    state.showAppointmentTimeBleg = true;
    state.editAppointmentFormBleg = true;
    state.showTransportCompleteBleg = false;
    state.showRideNotesComplete = false;
    this.props.reset();
    this.props.hideMiddleColumn();
    this.setState(state);
  };

  /**
   * displays edit patient form
   * @return {undefined}
   */
  editRideNotesInfo = () => {
    const state = this.hideAllFormComponents();

    state.showRideNotes = true;
    state.showRideNotesComplete = false;
    state.editRideNotesForm = true;

    this.props.reset();
    this.props.hideMiddleColumn();
    this.setState(state);
  };

  /**
   * @param {object} missingFields - missing fields
   * @param {string} missingFieldsError - error message
   * @return {undefined}
   */
  transportTypeValidate = (missingFields, missingFieldsError = '') => {
    this.setState({ missingFields, missingFieldsError });
  };

  /**
   * handles ride request for single and round trip rides, calls request booking callback
   * @param {object} bookingData - booking data passed from component
   * @return {undefined}
   */
  handleRequestBooking = bookingData => {
    bookingData = this.singleRoundTripData(bookingData);
    const state = {
      showRideNotesComplete: true,
      showRideNotes: false,
      editRideNotesForm: false
    };
    this.setState(state, () => {
      this.props.handleRequestBooking(bookingData);
    });
  };

  /**
   * helper function, turn off all active form elements, this makes editing portions of the form more intuitive
   * @return {object} - returns json of form parameters
   */
  hideAllFormComponents() {
    return {
      showTransportForm: false,
      showAppointmentTime: false,
      showAppointmentTimeBleg: false,
      showRideNotes: false
    };
  }

  /**
   * helper function, turn off all display elements
   * @return {object} - returns json of form parameters
   */
  hideAllDisplayComponents() {
    return {
      showTransportComplete: false,
      showTransportCompleteBleg: false,
      showRideNotesComplete: false
    };
  }

  /**
   * take single/round trip data and put in array for ride request
   * @param {object} bookingData - booking data from ride booking
   * @return {array} rides
   */
  singleRoundTripData(bookingData) {
    const rideBookingData = _.pick(bookingData, [
      'pickupAddress',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'showPickupEntrance',
      'showDropoffEntrance',
      'pickupEntranceName',
      'pickupVenueName',
      'dropoffEntranceName',
      'dropoffVenueName',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'transportType',
      'additionalNotes',
      'legCompleted',
      'transportTypeName',
      'rideCustomFields',
      'timeCustomFields',
      'apptTime',
      'apptEnds',
      'pickupVenueId',
      'dropoffVenueId',
      'selectedDate',
      'transportSubType',
      'transportSubTypeName',
      'assistanceNeeds',
      'additionalPassengers',
      'pastRide',
      'pastBookingDays',
      'pickupProviderId',
      'dropoffProviderId',
      'pickupProviderPhone',
      'pickupProviderName',
      'dropoffProviderName',
      'dropoffProviderPhone',
      'pickupFacilityName',
      'dropoffFacilityName',
      'pickupProviderAddr',
      'dropoffProviderAddr',
      'pickupAdditionalNotes',
      'dropoffAdditionalNotes',
      'pickupProviderNotFound',
      'dropoffProviderNotFound',
      'fromMemberSavedAddressId',
      'toMemberSavedAddressId'
    ]);

    rideBookingData.pickupProviderId ??= 0;
    rideBookingData.dropoffProviderId ??= 0;

    if (bookingData?.pickupProviderNotFound && bookingData?.pickupProviderAddr) {
      bookingData.pickupAddress ??= bookingData.pickupProviderAddr;
    }

    if (bookingData?.dropoffProviderNotFound && bookingData?.dropoffProviderAddr) {
      bookingData.dropoffAddress ??= bookingData.dropoffProviderAddr;
    }

    bookingData.rides = [];

    // separate ride booking data
    // get booking type
    if (bookingData.bookingType !== 'roundtrip') {
      rideBookingData.bookingType = bookingData.bookingType;
    } else {
      // flip addresses
      const secondRide = flipAddresses(rideBookingData);
      secondRide.bookingType = 'fromcare';
      secondRide.apptTime = rideBookingData.apptEnds;
      rideBookingData.bookingType = 'tocare';

      _.unset(rideBookingData, 'apptEnds');
      _.unset(secondRide, 'apptEnds');

      bookingData.rides[1] = secondRide;
    }

    bookingData.rides[0] = rideBookingData;

    return bookingData;
  }

  render() {
    const userRole = _.get(this.props, 'user.role', '');

    return (
      <div className="singleRoundTripRideBooking">
        {this.state.showTransportForm && (
          <TransportType
            rideDetails={this.state.rideData}
            updateRide={this.handleTransportationUpdate}
            isTransportFormBleg={this.state.isTransportFormBleg}
            missingFields={this.state.missingFields}
            missingFieldsError={this.state.missingFieldsError}
            toggleLoading={this.props.toggleLoading}
            transportTypeValidate={this.transportTypeValidate}
          />
        )}
        {this.state.showAppointmentTime && (
          <AppointmentTime
            bookingData={this.props.bookingData}
            updateAppointmentInfo={this.updateAppointmentInfo}
            isTransportFormBleg={this.state.isTransportFormBleg}
            editForm={this.state.editAppointmentForm}
            roundTrip={this.props.roundTrip}
            transportTypeValidate={this.transportTypeValidate}
            editBookedRide={false}
            toggleLoading={this.props.toggleLoading}
          />
        )}
        {this.state.showTransportComplete && (
          <TransportComplete
            bookingData={this.props.bookingData}
            editTransportInfo={this.editTransportInfo}
            tripNumber={1}
            userRole={userRole}
          />
        )}
        {this.state.showAppointmentTimeBleg && (
          <AppointmentTime
            bookingData={this.props.bookingData}
            updateAppointmentInfo={this.updateAppointmentInfoBleg}
            roundTrip={this.props.roundTrip}
            cancelRoundTrip={this.cancelRoundTrip}
            editForm={this.state.editAppointmentFormBleg}
            editBookedRide={false}
            isBleg={true}
          />
        )}
        {this.state.showTransportCompleteBleg && (
          <TransportComplete
            bookingData={this.props.bookingData}
            editTransportInfo={this.editTransportInfoBleg}
            tripNumber={2}
            apptTime={this.props.bookingData.apptEnds}
            selectedDate={this.props.bookingData.selectedDate}
            recommendedPickup={this.props.bookingData.apptEnds}
            pickupAddress={this.props.bookingData.dropoffAddress}
            dropoffAddress={this.props.bookingData.pickupAddress}
          />
        )}
        {this.state.showRideNotes && (
          <RideNotes
            bookingData={this.props.bookingData}
            requestRide={this.requestRide}
            hideArrow={this.state.showAppointmentTime}
            handleRequestBooking={this.handleRequestBooking}
            handleEditNotes={this.handleRequestBooking}
            editRide={false}
            editBookedRide={false}
            vehicleTypes={this.props.availableVehicleTypes}
            addAnotherTrip={this.addAnotherTrip}
            editRideNotesForm={this.state.editRideNotesForm}
          />
        )}
        {this.state.showRideNotesComplete && (
          <RideNotesComplete
            editRideNotesInfo={this.editRideNotesInfo}
            toggleNotes={this.toggleNotes}
            showAllNotes={this.state.showAllNotes}
            routeSelected={this.props.routeSelected}
            bookingData={this.props.bookingData}
            rideCustomFields={this.props.rideCustomFields}
            userRole={userRole}
            multiLeggedTrip={false}
          />
        )}
      </div>
    );
  }
}

BookRidesSingle.defaultProps = {
  handleRequestBooking: () => {},
  toggleLoading: () => {},
  hideMiddleColumn: () => {},
  reset: () => {},
  updateBookingData: () => {},
  roundTrip: false,
  availableVehicleTypes: [],
  bookingData: {},
  rideCustomFields: {},
  user: {},
  showRoundTripButton: () => {}
};

BookRidesSingle.propTypes = {
  handleRequestBooking: PropTypes.func,
  toggleLoading: PropTypes.func,
  hideMiddleColumn: PropTypes.func,
  reset: PropTypes.func,
  updateBookingData: PropTypes.func,
  roundTrip: PropTypes.bool,

  availableVehicleTypes: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  bookingData: PropTypes.object,
  rideCustomFields: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  user: PropTypes.object,
  showRoundTripButton: PropTypes.func
};

const mapStateToProps = state => ({
  availableVehicleTypes: state.user.availableVehicleTypesForSelection,
  bookingData: state.bookingData,
  rideCustomFields: state.user.rideCustomFields,
  user: state.user.userData,
  routeSelected: state.scheduleRides.routeSelected
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateBookingData: (data, replace) => updateBookingData(data, replace),
      reset: () => reset()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(BookRidesSingle);
