import rollbar from 'rollbar';

/**
 *
 */
export function rollbarInit() {
  if (process.env.REACT_APP_API !== 'local.saferidehealth.com') {
    window.Rollbar = new rollbar({
      enabled: true,
      logLevel: 'error',
      reportLevel: 'error',
      accessToken: process.env.REACT_APP_ROLLBAR_KEY,
      captureUncaught: true,
      captureUnhandledRejections: true,
      payload: {
        environment: process.env.REACT_APP_ENVIRONMENT,
        host: process.env.REACT_APP_HOST
      }
    });
  }
  return;
}

/** Manually trigger and send a debug rollbar.
 * Ideally used sparingly since instantiating many new rollbar instances isn't the best performance-wise.
 * */
export function manualRollbarDebug(message: string, data: Record<string, unknown>) {
  if (process.env.REACT_APP_API !== 'local.saferidehealth.com') {
    // We have to instantiate a new temporary rollbar object and can't use the global window.Rollbar
    // since that is set to only log "error" level and higher. And we don't want to lower that level globally
    // because then rollbar will likely get spammed with all sorts of "warning" level, etc log noise.
    const debugRollbar = new rollbar({
      enabled: true,
      logLevel: 'debug',
      reportLevel: 'debug',
      accessToken: process.env.REACT_APP_ROLLBAR_KEY,
      captureUncaught: false,
      captureUnhandledRejections: false,
      payload: {
        environment: process.env.REACT_APP_ENVIRONMENT,
        host: process.env.REACT_APP_HOST
      }
    });

    debugRollbar.debug(message, data);
  }
}
