import React from 'react';

const SvgMapPinRedSmall = props => {
  const {title, className} = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="17" viewBox="0 0 12 17">
      <title>{title}</title>
      <g fill="none" fillRule="evenodd">
        <path fill="#FF1A00" d="M6 17c2 0 6-6.686 6-11A6 6 0 1 0 0 6c0 4.314 4 11 6 11z"/>
        <circle cx="6" cy="6" r="4" fill="#FFF" stroke="#FF1A00" strokeWidth="4"/>
      </g>
    </svg>
  );
};

export default SvgMapPinRedSmall;
