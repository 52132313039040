import React from 'react';

const SvgMapPinBlueSmall = props => {
  const {title, className} = props;

  return (
    <svg className={className} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
      <title>{title}</title>
      <circle cx="6" cy="6" r="4" fill="#FFF" fillRule="evenodd" stroke="#2962FF" strokeWidth="4"/>
    </svg>

  );
};

export default SvgMapPinBlueSmall;
