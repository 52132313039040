import React from 'react';
import { Card, Input, TOKENS } from '@SRHealth/frontend-lib';
import type { PassengerInfoModel } from '~/models';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import {
  ALTERNATE_NUMBERS_TXT_WARNING,
  TRIP_NUMBER_WARNING
} from '../PassengerInfo.constants';

type TripPhoneNumberProps = {
  model: PassengerInfoModel;
  onSave: () => void;
  onCancel: () => void;
};

export default function TripPhoneNumber({
  model,
  onSave,
  onCancel
}: TripPhoneNumberProps) {
  return (
    <Card
      id="trip-phone-number"
      label="Trip Phone Number"
      border={false}
      editable={true}
      onEdit={() => {}}
      onSave={onSave}
      onCancel={onCancel}
      columns={3}
    >
      <Card.Content span={1}>
        <div className="flex flex-col gap-[8px]">
          <Input
            inputId={0}
            type="single"
            name="phone-number"
            label="Primary Number"
            icon="Call"
            placeholder="(___) ___-____"
            maxLength={14}
            error={'phoneNumber' in model.ruleErrors}
            value={formatPhoneNumber(model.phoneNumber || '')}
            onChange={(_id, val: (typeof model)['phoneNumber']) => {
              model.phoneNumber = val;
            }}
            required
          />
          <Input
            inputId={1}
            type="single"
            name="alt-phone-number"
            icon="Call"
            label="Add Alternate Number"
            placeholder="(___) ___-____"
            maxLength={14}
            error={'alternatePhoneNumber' in model.ruleErrors}
            value={formatPhoneNumber(model.alternatePhoneNumber || '')}
            onChange={(_id, val: (typeof model)['alternatePhoneNumber']) => {
              model.alternatePhoneNumber = val;
            }}
          />
        </div>
      </Card.Content>
      <Card.Content span={1}>
        <div className={`flex flex-col gap-[16px] text-${TOKENS.FONT.NEUTRAL}`}>
          <p className="text-md-italic">* {TRIP_NUMBER_WARNING}</p>
          {model.alternatePhoneNumber && (
            <p className="text-md-italic">** {ALTERNATE_NUMBERS_TXT_WARNING}</p>
          )}
        </div>
      </Card.Content>
    </Card>
  );
}
