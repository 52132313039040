import { createSelector } from '@reduxjs/toolkit';
import { selectEligibility } from '~/Modules/memberProfile';
import type { AppStore } from '~/types';
import { minutesUntilDate } from '~/utilities/timesAndDates';
import { selectRideBenefits } from './selectRideBenefits';
import { TXT } from '../RideBooking.constants';
import { selectActiveTreatment } from './selectActiveTreatment';
import type { BlockType } from '../RideBooking.types';

const selectDateRestrictions = (s: AppStore) => s.rideBooking.date.dateRestrictions;
const selectPastBookingDays = (s: AppStore) => s.user?.pastBookingDays ?? 0;

/**
 * Determines if the passenger is blocked from booking a ride based on their
 * current benefits and if the sub health plan has treatments enabled. This only
 * affects passengers with health sub plans that have a rides per year benefit
 * AND have treatments enabled.
 *
 * If a passenger is no longer eligible but WAS eligible then we have to check
 * the date of their latest eligibility. If it's within the past booking days
 * range then they should be allowed to book a past ride. The date lookup accounts
 * for member eligibility.
 */
export const selectIsBlocked = createSelector(
  [
    selectEligibility,
    selectPastBookingDays,
    selectDateRestrictions,
    selectRideBenefits,
    selectActiveTreatment
  ],
  (
    isEligible,
    pastBookingDays,
    dateRestrictions,
    benefitsRecord,
    treatment
  ): [boolean, BlockType] => {
    // If the user is no longer eligible but WAS eligible then we have to check
    // the date of their latest eligibility. If it's within the past booking days
    // range then they should be allowed to book a past ride. The date lookup accounts
    // for member eligibility.
    if (!isEligible) {
      if (!pastBookingDays || !dateRestrictions) return [true, 'Hard'];

      if (minutesUntilDate(dateRestrictions.latestDate) <= pastBookingDays * 24 * 60) {
        return [true, 'Past'];
      }
    }

    // Unlimited benefits don't block
    if (benefitsRecord?.limit === 'Unlimited') return [false, undefined];

    // Treatments is disabled by health sub plan or no benefits limit
    if (!treatment || !benefitsRecord) return [false, undefined];

    // Only block rides per year
    if (benefitsRecord.label !== TXT.RIDES_PER_YEAR_LABEL) return [false, undefined];

    // Non-deducting benefit doesn't count against limit
    if (treatment.benefit_deduction === 0) return [false, undefined];

    // Passenger is not medicaid and still has remaining benefits
    if (benefitsRecord.usage < benefitsRecord.limit) {
      return [false, undefined];
    }

    if (benefitsRecord.hardBlock) return [true, 'Hard'];

    return [true, 'Soft'];
  }
);
