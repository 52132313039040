import { createListenerMiddleware } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import type { RideBookingSection } from '../RideBooking.types';
import { RIDE_SECTION_ORDER } from '../RideBooking.constants';

/** The central listener for the ride booking middleware. */
export const rideBookingMiddleware = createListenerMiddleware();

/** A utility used to determine if an RBF section has just become
 * active. */
export const hasBecomeActive = (
  section: RideBookingSection,
  currentState: AppStore,
  oldState: AppStore
) => {
  return (
    currentState.rideBooking.meta.activeSection === RIDE_SECTION_ORDER[section] &&
    oldState.rideBooking.meta.activeSection !== RIDE_SECTION_ORDER[section] &&
    currentState.rideBooking.meta.activeSection !==
      oldState.rideBooking.meta.activeSection
  );
};

/** A utility used to determine if an RBF section has just become
 * abandoned. */
export const hasBeenAbandoned = (
  section: RideBookingSection,
  currentState: AppStore,
  oldState: AppStore
) => {
  return (
    currentState.rideBooking.meta.previousActiveSection !== RIDE_SECTION_ORDER[section] &&
    oldState.rideBooking.meta.activeSection === RIDE_SECTION_ORDER[section] &&
    currentState.rideBooking.meta.activeSection !==
      oldState.rideBooking.meta.activeSection
  );
};
