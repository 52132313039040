import React from 'react';
import {Observable} from '~/utilities/observable';

export interface UseSavedAddressStore {
  pickup: boolean;
  dropoff: boolean;
}
export interface UseSavedAddressActions {
  Toggle: (type: keyof UseSavedAddressStore, val?: boolean) => void;
  Flip: () => void;
  Reset: () => void;
}

const initialState = {
  pickup: false,
  dropoff: false
};

const store = new Observable<UseSavedAddressStore>({...initialState});

export const useSavedAddress = () => {
  const [state, setState] = React.useState(store.get());

  React.useEffect(() => {
    return store.subscribe(setState);
  }, []);

  const actions = React.useMemo(() => {
    return {
      Toggle: (type: keyof UseSavedAddressStore, val = !store.get()[type]) =>
        store.set({...state, [type]: val}),
      Flip: () => store.set({pickup: state.dropoff, dropoff: state.pickup}),
      Reset: () => store.set({...initialState})
    };
  }, [state]);

  return [state, actions] as [UseSavedAddressStore, UseSavedAddressActions];
};
