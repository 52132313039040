/* eslint-disable dot-notation */
import { FormControl, MenuItem, Select } from '@material-ui/core';
import React from 'react';
import { type FormInputInternalProps, type FormInputPropsBase } from './FormInput';
import { getTestId } from './Form.helpers';

export interface FormInputSelectProps extends FormInputPropsBase {
  readonly type: 'select';
  selection?: number;
  placeholder?: string;
}

const FormInputSelect: React.FC<FormInputSelectProps & FormInputInternalProps> = ({
  inputId,
  type,
  name,
  selection,
  options,
  required,
  error,
  disabled,
  classes,
  placeholder,
  onChange = () => {},
  onCheckDependencies = () => true
}) => {
  const testId = getTestId({ type, name });
  const activeValue = selection !== undefined ? options[selection]['value'] : '';

  return (
    <FormControl
      variant="outlined"
      required={required}
      error={error}
      className={classes.select}
    >
      <Select
        value={activeValue}
        disabled={disabled}
        displayEmpty={true}
        data-testid={testId}
      >
        {placeholder && (
          <MenuItem key="placeholder" value="" disabled>
            {placeholder}
          </MenuItem>
        )}

        {options.map((opt, i) =>
          onCheckDependencies(opt.dependencies) ? (
            <MenuItem
              key={i}
              value={`${opt.value}`}
              data-testid={`${testId}-${opt.value}`}
              onClick={() => onChange(inputId, i)}
            >
              {opt.label}
            </MenuItem>
          ) : null
        )}
      </Select>
    </FormControl>
  );
};

export default FormInputSelect;
