import React, { useEffect, useState } from 'react';
import AdditionalPassengers from './AdditionalPassengers';
import Mobility from './Mobility';
import MileageReimbursementDrivers from './MileageReimbursementDrivers';
import PersonalInfo from './PersonalInfo';
import LoadingModal from '~/Shared/Components/LoadingModal';
import MemberMobilityAssessment from './MemberMobilityAssessment';
import MemberPortalSupport from './MemberPortalSupport/MemberPortalSupport';
import { generateNavOptions } from './General.helper';
import type { PERMISSIONS } from '../../MemberProfiles.constants';
import { camelCase, capitalize } from '~/utilities/strings';
import type { MemberProfilesState } from '../../MemberProfiles';
import type { MemberProfileStore } from '~/types';
import type { UserStore } from '~/Modules/user';

const CHILD_COMPONENTS = {
  'personal-info': PersonalInfo,
  'mobility': Mobility,
  'attendant-info': AdditionalPassengers,
  'member-mobility-assessment': MemberMobilityAssessment,
  'mileage-reimbursement-drivers': MileageReimbursementDrivers,
  'member-portal-support': MemberPortalSupport
} as const;

const INITIAL_STATE = {
  editPersonalInfo: false,
  editMobility: false,
  editAttendantInfo: false
};

type StateProperties = keyof typeof INITIAL_STATE;

export type GeneralProps = JSX.ElementChildrenAttribute & {
  selectedId: string;
  passengerId: number;
  isLoading: boolean;
  childForm: keyof typeof CHILD_COMPONENTS;
  user: UserStore;
  memberProfile: MemberProfileStore;
  permission: (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
  toggleEnableRedirect: (bool: boolean) => void;
  updateMember: (params: Record<string, unknown>) => void;
  setEditButton: (v: MemberProfilesState['editButton']) => void;
  setErrorMsg: (v: MemberProfilesState['errorMsg']) => void;
  setSelectionCallback: (v: MemberProfilesState['handleSelectionCallback']) => void;
  handleChildSelection: (childForm: keyof typeof CHILD_COMPONENTS) => void;
};

function General({
  selectedId,
  passengerId,
  childForm,
  permission,
  isLoading,
  children,
  user,
  memberProfile,
  toggleEnableRedirect,
  updateMember,
  setEditButton,
  setErrorMsg,
  setSelectionCallback,
  handleChildSelection
}: GeneralProps) {
  const [state, setState] = useState(INITIAL_STATE);

  const childTab = camelCase(childForm) as keyof typeof CHILD_COMPONENTS;
  const childEditKey = `edit${capitalize(childTab)}` as StateProperties;
  const ChildComponent = CHILD_COMPONENTS[childForm];

  const vehicleCompanies = user.vehicleCompanies;
  const vehicleTypes: BE.VehicleRecord[] = Object.values(user.vehicleTypes || []);

  useEffect(() => {
    if (!childForm) handleChildSelection('personal-info');
  }, [childForm]);

  /**
   * Edit handler returns a callback to update the state for
   * the specific tab
   * @param {string} edit - property for modifying state
   * @param {boolean} enable - T/F can edit
   * @return {Function}
   */
  function handleEdit(edit: StateProperties, enable: boolean) {
    return () => {
      setState({ ...state, [edit]: enable });
      toggleEnableRedirect(!enable);
    };
  }

  if (isLoading) {
    return <LoadingModal label="Searching for member profile..." isOpen={isLoading} />;
  }

  return ChildComponent ? (
    <>
      {children}
      <ChildComponent
        editable={state[childEditKey]}
        selectedId={selectedId}
        passengerId={passengerId}
        memberProfile={memberProfile}
        vehicleCompanies={vehicleCompanies}
        vehicleTypes={vehicleTypes}
        cancel={handleEdit(childEditKey, false)}
        edit={handleEdit(childEditKey, true)}
        permission={permission}
        updateMember={updateMember}
        setEditButton={setEditButton}
        setErrorMsg={setErrorMsg}
        setSelectionCallback={setSelectionCallback}
      />
    </>
  ) : null;
}

General.getNavOptions = selectedId => generateNavOptions(selectedId);

export default General;
