import axios from 'axios';

export type LatLngString = `${number},${number}`;

export type CoordinatesRecord = {
  from: LatLngString;
  to: LatLngString;
}

/**
 * Query mapbox API to determine the distance between to lat/lng
 * coordinate pairs
 * @param locations
 * @returns 
 */
export async function calculateDistance({from, to}: CoordinatesRecord) {
  try {
    const mapboxUrl = 'https://api.mapbox.com/directions/v5/mapbox/driving';
    
    const res = await axios.get(
      `${mapboxUrl}/${from};${to}?overview=full&annotations=distance&access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`
    );
    
    if (res.status === 200) {
      return {
        success: true,
        distance: Number(
          Number(res.data.routes[0].distance / 1609.344).toFixed(2)
        )
      };
    }
  } catch (error) {
    return {success: false, distance: 0};
  }
}
