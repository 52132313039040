//actions file
import { generateCleanAddress } from '~/utilities/booking.helper';

//remove state
export const REMOVE_BOOKING_DATA_FIELDS = 'bookingData/REMOVE_BOOKING_DATA_FIELDS';

// create or update new ride
export const UPDATE_BOOKING_DATA = 'bookingData/UPDATE_BOOKING_DATA';

// multip leg trip ride data
export const UPDATE_MULTIPLE_RIDE_DATA = 'bookingData/UPDATE_MULTI_RIDE_DATA';

// clear ride that was in the process of being booked. this will be called
// if the ride was officially scheduled or if the ride was cancelled mid booking
export const CLEAR_RIDE = 'bookingData/CLEAR_RIDE';

export const CLEAR_ADDRESS_DATA = 'bookingData/CLEAR_ADDRESS_DATA';

// Updates either the bookingData or rides array based on bookingType
// and currentRideIndex
export const UPDATE_RIDE_DETAILS = 'bookingData/UPDATE_RIDE_DETAILS';

export const updateRideDetails = data => {
  return dispatch => {
    dispatch({
      type: UPDATE_RIDE_DETAILS,
      data
    });
  };
};

/**
 * removes booking data
 * @return {dispatch} - returns dispatch
 */
export const clearBookingRide = () => {
  return dispatch => {
    dispatch({
      type: CLEAR_RIDE
    });
  };
};

/**
 * Wipes out all address data for a specific address.
 * Accepts either pickup or dropoff
 * @param {string} type pickup or dropoff
 */
export const clearAddressData = type => {
  return dispatch =>
    dispatch({
      type: CLEAR_ADDRESS_DATA,
      data: type
    });
};

/**
 * update booking data for entire trip
 * @param {object} trip - trip data
 * @param {boolean} replace - replace or update trip data?
 * @return {dispatch} - returns dispatch
 */
export const updateBookingData = (trip, replace = false) => {
  return dispatch => {
    dispatch({
      type: UPDATE_BOOKING_DATA,
      data: trip,
      replace
    });
  };
};

/**
 * update booking data for entire trip
 * @param {array} paramsArray of key value object to remove
 * @return {dispatch} - returns dispatch
 */
export const clearStateData = paramsArray => {
  return dispatch => {
    dispatch({
      type: REMOVE_BOOKING_DATA_FIELDS,
      data: paramsArray
    });
  };
};

/**
 * add or update trip ride (leg). For multileg trips, a leg and ride are equivalent terms.
 * @param {object} ride - ride (leg) object
 * @param {integer} index - array key for array of legs. if index > -1 then update trip leg
 * @param {boolean} removeRide - remove the leg for trip, default false. If true then index must be 0 or greater.
 * @return {dispatch} - returns dispatch
 */
export const updateRideData = (ride, index = -1, removeRide = false) => {
  return dispatch => {
    const dispatchedObject = {
      type: UPDATE_MULTIPLE_RIDE_DATA,
      data: ride
    };
    if (index > -1) {
      dispatchedObject.index = index;
      dispatchedObject.removeRide = removeRide;
    }
    dispatch(dispatchedObject);
  };
};

export default (state = {}, action) => {
  switch (action.type) {
    case CLEAR_RIDE: {
      return {};
    }

    case UPDATE_RIDE_DETAILS: {
      const data = action.data;

      if (state.bookingType === 'multileg') {
        Object.assign(state.rides[state?.currentRideIndex ?? 0], data);
      }

      return Object.assign({}, state, data);
    }

    case UPDATE_BOOKING_DATA: {
      return action.replace ? action.data : Object.assign({}, state, action.data);
    }

    case REMOVE_BOOKING_DATA_FIELDS: {
      const newState = { ...state };

      action.data.map(({ name, value }) => {
        if (!value) {
          delete newState[name];
        }
      });

      return newState;
    }

    case UPDATE_MULTIPLE_RIDE_DATA: {
      // Spreading the rides state to avoid directly mutating the array (which would modify the old state as well).
      const newState = { ...state, rides: [...(state.rides ?? [])] };

      if ('index' in action && action.index > -1) {
        if (action.removeRide) {
          newState.rides = newState.rides.filter((_, index) => index !== action.index);
        } else {
          // Creating a new array with the updated ride (deeply copied).
          const updatedRides = newState.rides.map((ride, index) =>
            index === action.index ? { ...ride, ...action.data } : ride
          );
          newState.rides = updatedRides;
        }
      } else {
        // If it's a totally new ride, push it to the new rides array.
        newState.rides.push(action.data);
      }

      return newState;
    }

    case CLEAR_ADDRESS_DATA: {
      if (!action?.data) return state;

      const clearedAddressData = generateCleanAddress(action.data);

      const newState = { ...state };

      if (state.bookingType === 'multileg') {
        Object.assign(
          newState.rides[newState?.currentRideIndex ?? 0],
          clearedAddressData
        );
      } else {
        Object.assign(newState, clearedAddressData);
      }

      return newState;
    }

    default: {
      return state;
    }
  }
};
