import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { resetUpdatedCards, updateRide } from '~/Modules/rideCards/rideCards.actions';

import { get, isEmpty, differenceBy, cloneDeep, compact, forOwn, has } from 'lodash-es';

import { getSubmittedData } from '~/utilities/editRide.helper';
import { calculateDistance } from '~/utilities/map';

import SvgWarningFillOrange from 'Common/Components/Svgs/SvgWarningFillOrange';
import DropDown from 'Common/Components/DropDown';
import RepeatAppointments from 'Common/Components/EditRide/Components/RepeatAppointments';

class PublicConfirmation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      hideAddNew: false,
      clicked: false,
      miles: 0,
      cardData: {
        cost: 0.0,
        quantity: 1,
        type: undefined,
        status: undefined
      },
      isRepeatAppointmentOpen: false,
      publicCards: []
    };
  }

  componentDidMount = async () => {
    try {
      this.props.resetUpdate();
      if (Object.keys(this.props.rideDetails).length > 0) {
        this.setState({
          hideAddNew: true,
          cards: get(this.props, 'rideDetails.ride.data.ride.publicCards', []).map(
            publicCard => {
              return {
                cost: publicCard.cost,
                quantity: publicCard.qty,
                type: publicCard.type_id,
                status: publicCard.status_id
              };
            }
          )
        });
      }
      if (
        isEmpty(this.props.selectedLegs) &&
        !Object.keys(this.props.rideDetails).length
      ) {
        const { dropoffLatitude, dropoffLongitude, pickupLatitude, pickupLongitude } =
          this.props.bookingData;
        const { success, distance } = await calculateDistance({
          from: `${pickupLongitude},${pickupLatitude}`,
          to: `${dropoffLongitude},${dropoffLatitude}`
        });
        let miles = 0;
        if (success) {
          miles = distance;
        }
        this.setState({ miles });
      }
      /**
       * note: route-less rides api return a distance of 0.000.
       * we want to display the distance between two points
       * from mapbox should there be an empty distance.
       */
      if (this.isDistanceEmpty()) {
        const { fromLatitude, fromLongitude, toLatitude, toLongitude } = this.props.ride;
        const { success, distance } = await calculateDistance({
          from: `${fromLongitude},${fromLatitude}`,
          to: `${toLongitude},${toLatitude}`
        });
        let miles = 0;
        if (success) {
          miles = distance;
        }
        this.setState({ miles });
      }
    } catch (e) {
      /* empty */
    }
  };

  shouldCostCardsUpdate = ({ publicCards, stateCards }) => {
    const publicCardsList = publicCards.map(c => ({
      ...c,
      status: c.status_id,
      typeId: c.type_id,
      quantity: c.qty
    }));
    const stateCardsList = stateCards.map(c => ({ ...c, typeId: c.type }));
    const cardsKeys = ['cost', 'status', 'typeId', 'quantity'];
    const differenceByKeyList = key => differenceBy(publicCardsList, stateCardsList, key);
    const cardsAreNotEqual = cardsKeys.some(k => {
      const differenceList = differenceByKeyList(k);
      return differenceList && differenceList.length;
    });
    if (cardsAreNotEqual) return true;
    return publicCards.length !== stateCards.length;
  };

  componentDidUpdate = () => {
    const { publicCards } = this.props;

    if (publicCards && publicCards.length) {
      if (this.shouldCostCardsUpdate({ publicCards, stateCards: this.state.cards })) {
        this.setState({
          hideAddNew: true,
          cards: get(this.props, 'rideDetails.ride.data.ride.publicCards', []).map(
            publicCard => {
              return {
                cost: publicCard.cost,
                quantity: publicCard.qty,
                type: publicCard.type_id,
                status: publicCard.status_id
              };
            }
          )
        });
      }
    }
  };

  isDistanceEmpty = () => {
    return this.props.ride && parseFloat(this.props.ride.distance).toFixed(2) === '0.00';
  };

  addCard = cb => {
    const newCards = cloneDeep(this.state.cards);
    newCards.push(this.state.cardData);
    this.setState(
      {
        cards: newCards,
        cardData: {
          cost: 0.0,
          quantity: 1,
          type: undefined,
          status: undefined
        }
      },
      () => {
        if (typeof cb === 'function') {
          cb();
        }
      }
    );
  };

  removeCard = removeIndex => {
    return () => {
      const newCards = compact(
        cloneDeep(this.state.cards).map((card, cardIndex) => {
          if (cardIndex !== removeIndex) {
            return card;
          } else {
            return null;
          }
        })
      );
      this.setState({
        cards: newCards
      });
    };
  };

  continue = () => {
    const { updateRide, ride } = this.props;
    this.setState({ clicked: true });
    if (Object.keys(this.props.rideDetails).length > 0) {
      // Edit
      const allCards = this.state.cards.map(card => {
        return {
          cost: card.cost,
          typeId: card.type,
          statusId: card.status,
          qty: card.quantity
        };
      });

      if (this.newCardValidate()) {
        allCards.push({
          cost: this.state.cardData.cost,
          typeId: this.state.cardData.type,
          statusId: this.state.cardData.status,
          qty: this.state.cardData.quantity
        });
      }

      if (ride && ride.recurring_rideId) {
        this.setState({
          isRepeatAppointmentOpen: true,
          publicCards: allCards
        });
      } else {
        updateRide({
          ...getSubmittedData(
            this.props.bookingData,
            this.props.rideDetails.ride.data.ride
          ),
          mode: 'Public',
          rideType: 'rides',
          hospitalId: this.props.rideDetails.ride.data.ride.hospitalId,
          rideId: this.props.rideDetails.ride.data.ride.id,
          publicCards: allCards
        });
      }
    } else {
      const publicData = cloneDeep(this.props.submittedData.submittedData);
      publicData.rides = publicData.rides.map((ride, rideIndex) => {
        ride.pickupZipcode = ride.pickupZip;
        ride.dropoffZipcode = ride.dropoffZip;
        ride.routeData = this.props.selectedLegs[rideIndex];
        ride.publicCards = this.state.cards.map(card => {
          return {
            cost: card.cost,
            typeId: card.type,
            qty: card.quantity
          };
        });
        if (this.newCardValidate()) {
          ride.publicCards.push({
            cost: this.state.cardData.cost,
            typeId: this.state.cardData.type,
            qty: this.state.cardData.quantity
          });
        }
        return ride;
      });
      this.props.confirmPublic(publicData);
    }
  };

  updateCardData = (property, slot = null) => {
    return event => {
      if (slot === null) {
        const newCardData = cloneDeep(this.state.cardData);
        const newValue =
          typeof event.target.value !== 'undefined'
            ? event.target.value
            : parseInt(event.target.id, 10);
        if (property === 'cost' && (newValue < 0 || newValue > 999)) {
          return;
        }
        newCardData[property] = newValue;
        this.setState({
          cardData: newCardData
        });
      } else {
        const cards = cloneDeep(this.state.cards);
        let newValue =
          typeof event.target.value !== 'undefined'
            ? event.target.value
            : parseInt(event.target.id, 10);
        if (property === 'cost' && (newValue < 0 || newValue > 999)) {
          return;
        }

        if (
          property === 'cost' &&
          String(newValue).split('.')[1] &&
          String(newValue).split('.')[1].length > 2
        ) {
          newValue = Number(cards[slot][property]).toFixed(2);
        }
        cards[slot][property] = newValue;
        this.setState({
          cards
        });
      }
    };
  };

  cardTypeData = () => {
    return get(this.props, 'user.publicCardData.types', []).map(cardType => {
      return {
        name: (cardType || {}).name,
        id: parseInt(cardType.id, 10)
      };
    });
  };

  cardStatusData = () => {
    return get(this.props, 'user.publicCardData.statuses', []).map(cardStatus => {
      return {
        name: cardStatus.name,
        id: parseInt(cardStatus.id, 10)
      };
    });
  };

  addCardClick = () => {
    if (this.state.hideAddNew) {
      this.setState({
        hideAddNew: false
      });
    }
    if (this.newCardValidate()) {
      this.addCard();
    }
  };

  newCardValidate = () => {
    return (
      this.state.cardData.quantity > 0 && typeof this.state.cardData.type !== 'undefined'
    );
  };

  confirmOptionValidate = () => {
    return this.state.cards.length > 0 || this.newCardValidate();
  };

  calculateFares() {
    let viableFare = true;
    let fare = 0.0;
    forOwn(this.props.selectedLegs, selectedLeg => {
      if (viableFare && has(selectedLeg, 'fare')) {
        fare += selectedLeg.fare.value;
      } else {
        viableFare = false;
      }
    });
    if (viableFare && fare !== 0) {
      return fare;
    } else {
      return 'N/A';
    }
  }

  calculateDistance = () => {
    if (!this.props.selectedLegs) return this.state.miles || 0;
    let distanceMeters = 0;

    forOwn(this.props.selectedLegs, selectedLeg => {
      distanceMeters += selectedLeg.legs[0].distance.value;
    });
    return Math.round(distanceMeters * 0.062137) / 100;
  };

  getAuthStatusMap() {
    return {
      warning: {
        color: 'orange',
        SvgName: SvgWarningFillOrange
      }
    };
  }

  submitRepeat = editType => {
    const { publicCards } = this.state;
    const { updateRide, bookingData, rideDetails } = this.props;
    updateRide({
      ...getSubmittedData(bookingData, rideDetails.ride.data.ride),
      mode: 'Public',
      rideType: 'rides',
      hospitalId: rideDetails.ride.data.ride.hospitalId,
      rideId: rideDetails.ride.data.ride.id,
      ride_applyonall: editType === 'all' ? 1 : 0,
      publicCards
    });
    this.closeRepeatModal();
  };

  closeRepeatModal = () => {
    this.setState({
      isRepeatAppointmentOpen: false,
      publicCards: []
    });
  };

  render() {
    const cardTypes = this.cardTypeData();
    const cardStatuses = this.cardStatusData();
    const isEdit = Object.keys(this.props.rideDetails).length > 0;
    let fares = this.calculateFares();
    let distance = this.state.miles || this.calculateDistance();
    let rideDetails = {};
    if (isEdit) {
      rideDetails = this.props.rideDetails.ride.data.ride;
      fares = parseFloat(rideDetails.totalCost).toFixed(2);
      distance = this.state.miles || parseFloat(rideDetails.distance).toFixed(2);
    }

    // past booking
    const authStatusMap = this.getAuthStatusMap();
    const isPastRide = get(this.props, 'bookingData.pastRide', false);
    const warningColor = authStatusMap.warning.color;
    const SvgWarning = authStatusMap.warning.SvgName;

    return (
      <div className={`publicConfirmation ${isEdit ? 'edit' : ''}`}>
        <div className="tripBreakdown">
          {isEdit ? null : <h1>Trip Breakdown</h1>}
          <div className="cost">
            TRIP COST <br />
            TOTAL <p>${fares}</p>
          </div>
          <div className="distance">
            THIS TRIP <br />
            TOTAL <p>{distance}mi</p>
          </div>
        </div>
        <RepeatAppointments
          heading="Reschedule Recurring Ride"
          action="updated"
          isOpen={this.state.isRepeatAppointmentOpen}
          closeModal={this.closeRepeatModal}
          cancelRide={this.submitRepeat}
        />
        <div className="transportationCard">
          <div className="transportationCardHeader">
            <h1>Transportation Card</h1>
          </div>
          <div className="publicTableEdit">
            <div className={`publicRow ${isEdit ? 'edit' : ''}`}>
              <span className="publicColumn">Cost per Card</span>
              <span className="publicColumn">Qty</span>
              <span className="publicColumn">Card Type</span>
              <span className="publicColumn">Status</span>
              <span className="publicColumn"></span>
            </div>
            {this.state.cards.map((cardRecord, cardRecordIndex) => {
              return (
                <div
                  className={`publicRow ${isEdit ? 'edit' : ''}`}
                  key={cardRecordIndex}
                >
                  <span className="publicColumn">
                    <input
                      type="number"
                      name="cost"
                      value={cardRecord.cost}
                      step="0.01"
                      max="999.99"
                      min="0.00"
                      onChange={this.updateCardData('cost', cardRecordIndex)}
                      disabled={this.props.disabled}
                    />
                  </span>
                  <span className="publicColumn">
                    <DropDown
                      dropDownCallback={this.updateCardData('quantity', cardRecordIndex)}
                      className={'cardDropdown'}
                      items={[...new Array(10)].map((a, i) => ({
                        name: i + 1,
                        id: i + 1
                      }))}
                      placeholder={'0'}
                      id={cardRecord.quantity}
                      disabled={this.props.disabled}
                    />
                  </span>
                  <span className="publicColumn">
                    <DropDown
                      dropDownCallback={this.updateCardData('type', cardRecordIndex)}
                      className={'cardDropdown'}
                      items={cardTypes}
                      placeholder={'Select Type'}
                      id={cardRecord.type}
                      disabled={this.props.disabled}
                    />
                  </span>
                  <span className="publicColumn">
                    <DropDown
                      dropDownCallback={this.updateCardData('status', cardRecordIndex)}
                      className={'cardDropdown'}
                      items={cardStatuses}
                      placeholder={'Select Status'}
                      id={cardRecord.status}
                      disabled={this.props.disabled}
                    />
                  </span>
                  {!this.props.disabled && (
                    <span className="publicColumn x">
                      <a
                        onClick={this.removeCard(cardRecordIndex)}
                        style={{ cursor: 'pointer' }}
                      >
                        x
                      </a>
                    </span>
                  )}
                </div>
              );
            })}
            <div
              className={`publicRow ${isEdit ? 'edit' : ''}`}
              style={{ display: this.state.hideAddNew ? 'none' : 'block' }}
            >
              <span className="publicColumn">
                <input
                  type="number"
                  name="cost"
                  value={this.state.cardData.cost}
                  step="0.01"
                  max="999.99"
                  min="0.00"
                  onChange={this.updateCardData('cost')}
                />
              </span>
              <span className="publicColumn">
                <DropDown
                  dropDownCallback={this.updateCardData('quantity')}
                  className={'cardDropdown'}
                  items={[...new Array(10)].map((a, i) => ({ name: i + 1, id: i + 1 }))}
                  placeholder={'0'}
                  id={this.state.cardData.quantity}
                />
              </span>
              <span className="publicColumn">
                <DropDown
                  dropDownCallback={this.updateCardData('type')}
                  className={'cardDropdown'}
                  items={cardTypes}
                  placeholder={'Select Type'}
                  id={this.state.cardData.type}
                />
              </span>
              {isEdit ? (
                <span className="publicColumn">
                  <DropDown
                    dropDownCallback={this.updateCardData('status')}
                    className={'cardDropdown'}
                    items={cardStatuses}
                    placeholder={'Select Status'}
                    id={this.state.cardData.status}
                  />
                </span>
              ) : (
                <span className="publicColumn" />
              )}
              <span className="publicColumn"></span>
            </div>
            {!this.props.disabled && (
              <div className="addCard">
                <a
                  onClick={this.addCardClick}
                  className={this.newCardValidate() ? '' : 'inactive'}
                >
                  ADD CARD
                </a>
              </div>
            )}
            {isPastRide ? (
              <div className="warningBlock">
                <SvgWarning className={`${warningColor}Svg`} />
                You are about to book a past ride.
              </div>
            ) : null}
            <div className="bookRideButtons clearfix">
              {this.props.errorMessage}
              {!this.props.disabled && (
                <button
                  className={
                    this.confirmOptionValidate() && !this.state.clicked
                      ? `confirmationButton active`
                      : `confirmationButton disabled`
                  }
                  onClick={
                    this.confirmOptionValidate() && !this.state.clicked
                      ? this.continue
                      : () => {}
                  }
                >
                  {isEdit ? 'Update' : 'Confirm Ride'}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PublicConfirmation.defaultProps = {
  selectedLegs: {},
  confirmPublic: () => {},
  errorMessage: '',
  disabled: false,
  publicCards: [],
  rideDetails: {},
  bookingData: {},
  updateRide: () => {}
};

PublicConfirmation.propTypes = {
  selectedLegs: PropTypes.object,
  confirmPublic: PropTypes.func,
  errorMessage: PropTypes.any,
  disabled: PropTypes.bool,
  publicCards: PropTypes.array,
  bookingData: PropTypes.object,
  rideDetails: PropTypes.shape({
    ride: PropTypes.shape({
      data: PropTypes.shape({
        ride: PropTypes.shape({
          id: PropTypes.number,
          hospitalId: PropTypes.number
        })
      })
    })
  }),
  updateRide: PropTypes.func
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      updateRide: data => updateRide(data),
      resetUpdate: () => resetUpdatedCards()
    },
    dispatch
  );

const mapStateToProps = state => ({
  submittedData: state.scheduleRides.ride,
  confirmationCardData: get(state, 'scheduleRides.confirmationCardData', []),
  errorMessage: state.scheduleRides.errorMessage,
  rideDetails: state.rideDetails,
  bookingData: state.bookingData,
  user: get(state, 'user', {})
});

export default connect(mapStateToProps, mapDispatchToProps)(PublicConfirmation);
