import React, { useState } from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import { SRSwitch } from './SRSwitch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import updateRideClaimedStatus from '~/services/rideClaim.service';
import { CircularProgress } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import {
  UNCLAIMED,
  CLAIMED,
  RESOLVED,
  getAbbreviatedUserName
} from './ClaimedRideFooter.utils';
import { updateRideAlertClaimStatus } from '~/Modules/rideCards/rideCards.actions';

const ClaimedRideFooter = ({ ride = {} }) => {
  const user = useSelector(({ user }) => user?.userData);
  const currentUserId = user.id;
  const { id: rideId, alertClaimByUser, alertClaimStatus } = ride;
  const dispatch = useDispatch();

  const rideClaimStatus = alertClaimStatus || UNCLAIMED;

  const rideClaimedStatusLabel = {
    Unclaimed: 'Unclaimed',
    Claimed: `Claimed By: ${alertClaimByUser?.name ?? ''}`
  };

  // helpers
  const toggleClaimStatus = () => (rideClaimStatus === UNCLAIMED ? CLAIMED : UNCLAIMED);

  const isRideClaimedByCurrentUser = () => currentUserId === alertClaimByUser?.userId;

  const isRideClaimed = () => rideClaimStatus === CLAIMED;

  // handlers
  const handleClaimedRideCheck = () => {
    const newAlertClaimStatus = toggleClaimStatus();
    updateRideClaimedStatus({
      rideClaimStatus: newAlertClaimStatus,
      rideId
    })
      .then(({ success }) => {
        if (!success) return;

        let payloadUser;
        if (newAlertClaimStatus === CLAIMED) {
          payloadUser = {
            userId: user.id,
            name: getAbbreviatedUserName(user)
          };
        } else {
          payloadUser = {
            userId: null,
            name: null
          };
        }
        dispatch(
          updateRideAlertClaimStatus({
            rideId,
            status: newAlertClaimStatus,
            user: payloadUser
          })
        );
      })
      .catch(e => e);
  };

  const handleResolveRide = () => {
    return updateRideClaimedStatus({
      rideClaimStatus: RESOLVED,
      rideId
    });
  };

  const handleReClaimRide = () => {
    updateRideClaimedStatus({
      rideClaimStatus: CLAIMED,
      rideId,
      reclaimation: true
    })
      .then(({ success }) => {
        if (success) {
          dispatch(
            updateRideAlertClaimStatus({
              rideId,
              status: CLAIMED,
              user: { userId: user.id, name: getAbbreviatedUserName(user) }
            })
          );
        }
      })
      .catch(e => e);
  };

  return (
    <Box as="div" className="ClaimedRideFooter">
      <Box as="div" className="claimedRideToggleContainer">
        <FormGroup style={{ padding: 10 }}>
          <FormControlLabel
            control={
              <SRSwitch
                checked={isRideClaimed()}
                size="small"
                onChange={handleClaimedRideCheck}
                disabled={isRideClaimed() && !isRideClaimedByCurrentUser()}
              />
            }
            label={`${rideClaimedStatusLabel[rideClaimStatus]} `}
          />
        </FormGroup>

        <style>{'.MuiFormControlLabel-label.Mui-disabled { color: #fff}'}</style>
      </Box>
      <Box as="div" className="claimedRideOwnerContainer">
        <RideOwnerResolve
          isRideClaimed={isRideClaimed()}
          isClaimedByCurrentUser={isRideClaimedByCurrentUser()}
          onResolve={handleResolveRide}
          rideId={rideId}
        />

        <RideOwnerReclaim
          isRideClaimed={isRideClaimed()}
          isClaimedByCurrentUser={isRideClaimedByCurrentUser()}
          onReclaim={handleReClaimRide}
          alertClaimByUser={alertClaimByUser}
        />
      </Box>
    </Box>
  );
};

/**
 * Interactive element allowing users to resolve a claimed ride. Returns null
 * if isRideClaimed is false or isClaimedByCurrentUser is false.
 * @param {bool} props.isRideClaimed Ride is claimed
 * @param {bool} props.isClaimedByCurrentUser Ride is claimed by current user
 * @param {function} props.onResolve Callback to perform on click
 * @returns {JSX.Element|null} The element to render
 */
const RideOwnerResolve = ({
  isRideClaimed = false,
  isClaimedByCurrentUser = false,
  onResolve = () => {},
  rideId
}) => {
  const dispatch = useDispatch();
  const [isResolving, setIsResolving] = useState(false);

  const handleResolve = () => {
    setIsResolving(true);
    onResolve()
      .then(({ success }) => {
        if (!success) {
          setIsResolving(false);
        } else {
          dispatch(updateRideAlertClaimStatus({ rideId, status: RESOLVED }));
        }
      })
      .catch(() => setIsResolving(false));
  };

  if (!isRideClaimed || !isClaimedByCurrentUser) {
    if (isResolving) {
      setIsResolving(false);
    }

    return null;
  }

  if (isResolving) {
    return (
      <Box as="div" className="claimedRideAction">
        <CircularProgress size="1.5em" />
      </Box>
    );
  }

  return (
    <Box as="div" className="claimedRideAction" onClick={() => handleResolve()}>
      <Box>Resolve &amp; Clear</Box>
    </Box>
  );
};

/**
 * Interactive element allowing users to resolve reclaim ride. Returns null
 * if isRideClaimed is false or isClaimedByCurrentUser is true.
 * @param {bool} props.isRideClaimed Ride is claimed
 * @param {bool} props.isClaimedByCurrentUser Ride is claimed by current user
 * @param {function} props.onReclaim Callback to perform on click
 * @param {object} props.alertClaimByUser the claimed user object on the ride
 * @returns {JSX.Element|null} The element to render
 */
const RideOwnerReclaim = ({
  isRideClaimed = false,
  isClaimedByCurrentUser = false,
  onReclaim = () => {},
  alertClaimByUser = {}
}) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const onConfirm = () => {
    onReclaim();
    setShowConfirm(false);
  };

  if (!isRideClaimed || isClaimedByCurrentUser || !alertClaimByUser?.name) {
    return null;
  }

  if (showConfirm) {
    return (
      <ConfirmSelection onConfirm={onConfirm} onCancel={() => setShowConfirm(false)} />
    );
  }

  return (
    <Box as="div" className="claimedRideAction" onClick={() => setShowConfirm(true)}>
      <Box>&#60; Reclaim</Box>
    </Box>
  );
};

const ConfirmSelection = ({ onConfirm = () => {}, onCancel = () => {} }) => {
  return (
    <Box display="flex" className="claimedRideAction">
      <Box onClick={onConfirm}>Yes</Box>
      <Box>|</Box>
      <Box onClick={onCancel}>No</Box>
    </Box>
  );
};

ClaimedRideFooter.propTypes = {
  ride: PropTypes.object
};

ClaimedRideFooter.defaultProps = {
  ride: {}
};

export default ClaimedRideFooter;
