import _ from 'lodash-es';
import LoadingModal from '~/Shared/Components/LoadingModal';
import PassengerDropdown from '~/Shared/Components/AdditionalPassengers/PassengerDropdown';
import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { clearData, putMemberProfile } from '~/Modules/memberProfile/actions';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';
import type { MemberProfilesState } from '../../MemberProfiles';
import { useAppSelector } from '~/Modules';

const INITIAL_STATE = {
  isOpen: false,
  isDirty: false,
  showLoader: false,
  passengers: []
};

export type AdditionalPassengersProps = {
  selectedId: string;
  passengerId: number;
  editable: boolean;
  permission: (typeof PERMISSIONS)[keyof typeof PERMISSIONS];
  edit: () => void;
  cancel: () => void;
  setEditButton: (v: MemberProfilesState['editButton']) => void;
  setErrorMsg: (v: MemberProfilesState['errorMsg']) => void;
  setSelectionCallback: (v: MemberProfilesState['handleSelectionCallback']) => void;
};

function AdditionalPassengers({
  edit,
  cancel,
  editable,
  permission,
  setEditButton,
  setSelectionCallback
}: AdditionalPassengersProps) {
  const dispatch = useDispatch();

  const { memberProfile } = useAppSelector(state => state);

  const personalInfo = memberProfile?.formData?.personalInfo ?? {};
  const attendantInfo = memberProfile?.formData?.attendantInfo ?? {};
  const memberData = attendantInfo?.memberData ?? {};
  const vehicle_types = attendantInfo?.vehicle_types ?? [];
  const defaultPassenger: unknown[] = memberData?.additionalPassengers ?? [];

  const [state, setState] = useState({ ...INITIAL_STATE, passengers: defaultPassenger });

  function handleSave() {
    setState({ ...state, showLoader: true });

    dispatch(
      putMemberProfile({
        formType: 'attendantInfo',
        passengerId: personalInfo.id,
        memberProfile: { additionalPassengers: state.passengers }
      })
    );
  }

  useEffect(() => {
    setSelectionCallback(handleChildSelection);

    return () => {
      cancel();
      setState({
        ...state,
        isDirty: false,
        showLoader: false
      });
    };
  }, []);

  useEffect(() => {
    setEditButton(
      <AdditionalPassengersEditButton
        editable={editable}
        permission={permission}
        handleEdit={handleEdit}
        handleSave={handleSave}
        handleCancel={handleCancel}
      />
    );
  }, [editable, state]);

  useEffect(() => {
    if (memberProfile.success) {
      cancel();

      setState({
        ...state,
        isOpen: !state.isOpen,
        isDirty: false,
        showLoader: false
      });
    }
  }, [memberProfile.success]);

  useEffect(() => {
    if (memberData && memberData.additionalPassengers) {
      setState({ ...state, passengers: memberData.additionalPassengers });
    }
  }, [memberData]);

  useEffect(() => {
    const newState = { ...state, isDirty: editable };

    setState(newState);
  }, [editable]);

  function handleEdit() {
    edit();
    dispatch(clearData());
  }

  function handleCancel() {
    cancel();
    setState({
      ...state,
      isOpen: !state.isOpen,
      isDirty: false,
      passengers: defaultPassenger
    });
  }

  function handleChildSelection() {
    return !state.isDirty || confirm(`Are you sure you want to leave without saving?`);
  }

  if (!memberData) return null;

  return (
    <Container className="parentTab">
      <div className="childTab AttendantInfo">
        <br />
        <br />
        {editable || state.passengers.length ? (
          <PassengerDropdown
            disabled={!editable}
            setField={({ name, value }) => setState({ ...state, [name]: value })}
            passengers={state.passengers}
            vehicle_types={vehicle_types}
            subMobilityType={null}
          />
        ) : (
          <div className="bold ta-center" style={{ padding: '25px 0' }}>
            This member does not have any additional passengers.
            <br />
            You can add up to 5.
          </div>
        )}
        {state.showLoader ? (
          <LoadingModal label="Saving Member Info..." isOpen={state.showLoader} />
        ) : null}
      </div>
    </Container>
  );
}

const AdditionalPassengersEditButton = ({
  editable,
  permission,
  handleEdit,
  handleSave,
  handleCancel
}) => {
  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {editable ? (
            <>
              <a onClick={handleCancel}>Cancel</a>
              <a onClick={handleSave}>Save</a>
            </>
          ) : (
            <a onClick={handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

export default AdditionalPassengers;
