import { Tooltip } from '@material-ui/core';
import _ from 'lodash-es';
import React from 'react';
import { useAppSelector } from '~/Modules';
import passengerFormInputs from './passengerFormInputs';
import rbfPassengerFormInputs from './rbfPassengerFormInputs';

const PassengerForm = ({
  index,
  disabled = false,
  formType = 'single-row',
  passengers,
  tooltipText = '',
  vehicle_types,
  setPassengers
}) => {
  const shouldUseSubMobilityType = useAppSelector(
    state => state?.user?.features?.vehicle_sub_type
  );
  const inputSource =
    formType === 'single-row' ? rbfPassengerFormInputs : passengerFormInputs;

  const removePassenger = ({ index }) => {
    const _passengers = _.cloneDeep(passengers);
    _passengers.splice(index, 1);
    setPassengers(_passengers);
  };

  const setPassenger = ({ name, value }) => {
    const p = _.cloneDeep(passengers);
    p[index][name] = value;

    if (name === 'mobilityType') {
      p[index]['subMobilityType'] = null;
    }

    if (name === 'ageGroup' && value === 'child') {
      p[index]['isAttendant'] = false;
    }

    setPassengers(p);
  };

  const RemovePassenger = ({ index }) => {
    const removeColor = formType === 'single-row' ? 'fc-red underline ' : 'fc-blue ';
    const countColor = formType === 'single-row' ? 'fc-yellow bold ' : 'fc-lgrey bold ';
    return (
      <div className="pb-12">
        <div key={index}>
          <span className={`${countColor} cursor inline`}>Passenger {index + 1}</span>
          &nbsp;&nbsp;
          <div
            onClick={() => removePassenger({ index })}
            className={`${removeColor} cursor inline`}
          >
            Remove
          </div>
        </div>
      </div>
    );
  };

  const Label = ({ label, required }) => {
    return label ? (
      <label>
        {required ? <span className="fc-red">* </span> : null}
        {label}
      </label>
    ) : null;
  };

  return (
    <div className="passenger-form" key="pf">
      <RemovePassenger index={index} />
      {inputSource({
        np: passengers[index],
        disabled,
        setPassenger,
        vehicle_types,
        shouldUseSubMobilityType
      }).map((rows, j) => {
        return (
          <div key={j} className="flex">
            {rows.map(({ Component, required, name, label }, i) => {
              const width = rows.length === 2 ? '50%' : '100%';
              let gap = rows.length === 2 && i === 0 ? 'mr-12' : '';
              gap = rows.length === 3 && (i === 0 || i === 1) ? 'mr-12' : gap;
              return (
                <div key={name} className="input-section" style={{ width }}>
                  <div>
                    <Label label={label} required={required} />
                  </div>
                  <div className={gap}>
                    {disabled && tooltipText && name !== 'isAttendant' ? (
                      <Tooltip placement="top-start" title={<span>{tooltipText}</span>}>
                        <span>
                          {Array.isArray(Component) ? Component : <Component />}
                        </span>
                      </Tooltip>
                    ) : Array.isArray(Component) ? (
                      Component
                    ) : (
                      <Component />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default PassengerForm;
