import { TRANSPORT_TYPE_MAP } from '@SRHealth/frontend-lib';
import type { VehicleSubType, VehicleTypeRecord } from '~/types';
import { TRANSPORT_TYPE_CLASSIFICATIONS } from '~/constants/vehicles';

/**
 *
 */
export function getVehicleRecordById(id: number, vehicles: BE.VehicleRecord[]) {
  return vehicles.find(v => v.id === id);
}

/**
 * Looks up a vehicle from the vehicle list using the 3-letter
 * abbreviation/symbol/nickname
 * @param {string} symbol
 * @param {array} vehicles
 * @returns {object|undefined}
 */
export function getVehicleRecordByNickName(
  symbol: BE.VehicleTypeNickName,
  vehicles: BE.VehicleRecord[]
) {
  return vehicles.find(v => v.nickName === symbol);
}

/** Get the corresponding trip type for a given vehicle. Defaults to private
 * if the vehicle is not found in the classifications maps. */
export function getVehicleTripType(
  vehicle: BE.VehicleRecord['nickName'] | VehicleTypeRecord['abbreviation']
) {
  return TRANSPORT_TYPE_CLASSIFICATIONS[vehicle] ?? 'private';
}

/** Expand and format the incoming VehicleRecord from the BE. The returned
 * record will be a valid option for the frontend library Input components. */
function extendVehicleRecord(vehicle: BE.VehicleRecord): VehicleTypeRecord {
  return {
    id: vehicle.id,
    label: vehicle.modelName,
    description: vehicle.comments,
    abbreviation: vehicle.nickName,
    isActive: vehicle.isActive === 1,
    value: vehicle.nickName,
    icon: TRANSPORT_TYPE_MAP[vehicle.nickName]?.[0] ?? undefined,
    subTypes: vehicle.vehicle_sub_types?.map(
      subType =>
        ({
          id: subType.id,
          label: subType.name,
          description: subType.description,
          value: subType.id
        }) as VehicleSubType
    )
  };
}

/** Extends the vehicle records with additional properties used by the
 * frontend library Input components. */
export function extendVehicleRecords(
  vehicles: Record<number, BE.VehicleRecord> | BE.VehicleRecord[]
) {
  const values = Array.isArray(vehicles) ? vehicles : Object.values(vehicles);

  return Object.fromEntries(
    values.map(v => [v.nickName, extendVehicleRecord(v)])
  ) as Record<VehicleTypeRecord['abbreviation'], VehicleTypeRecord>;
}
