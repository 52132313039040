import { get, isEmpty, isNil } from 'lodash-es';
import type { IState } from './TransportType.constants';
import { APPROVED_PROVIDERS, SAVED_ADDRESS, VENUES } from '~/constants';
import { getZipcode } from '~/utilities/helperFunctions';
import { getAddressGeocoding } from '~/services/mapbox.service';

/**
 * Retrieve the current additional provider notes fields
 * @returns {object} The provider notes fields
 */
export const getAdditionalProviderNotes = transportLocalState => {
  let state: Partial<IState> = {
    pickupProviderNotFound:
      transportLocalState.showPickupApprovedProvider &&
      transportLocalState.pickupProviderNotFound,
    dropoffProviderNotFound:
      transportLocalState.showDropoffApprovedProvider &&
      transportLocalState.dropoffProviderNotFound
  };

  if (state.pickupProviderNotFound) {
    const {
      pickupProviderName,
      pickupFacilityName,
      pickupProviderAddr,
      pickupProviderPhone,
      pickupAdditionalNotes,
      pickupAddress
    } = transportLocalState;

    state = {
      ...state,
      pickupProviderName,
      pickupFacilityName,
      pickupProviderAddr: isEmpty(pickupProviderAddr)
        ? pickupAddress
        : pickupProviderAddr,
      pickupProviderPhone,
      pickupAdditionalNotes
    };
  } else {
    state = {
      ...state,
      pickupProviderName: undefined,
      pickupFacilityName: undefined,
      pickupProviderAddr: undefined,
      pickupProviderPhone: undefined,
      pickupAdditionalNotes: undefined
    };
  }

  if (state.dropoffProviderNotFound) {
    const {
      dropoffProviderName,
      dropoffFacilityName,
      dropoffProviderAddr,
      dropoffProviderPhone,
      dropoffAdditionalNotes,
      dropoffAddress
    } = transportLocalState;

    state = {
      ...state,
      dropoffProviderName,
      dropoffFacilityName,
      dropoffProviderAddr: isEmpty(dropoffProviderAddr)
        ? dropoffAddress
        : dropoffProviderAddr,
      dropoffProviderPhone,
      dropoffAdditionalNotes
    };
  } else {
    state = {
      ...state,
      dropoffProviderName: undefined,
      dropoffFacilityName: undefined,
      dropoffProviderAddr: undefined,
      dropoffProviderPhone: undefined,
      dropoffAdditionalNotes: undefined
    };
  }
  return state;
};

/**
 * format how venue data appears in drop down
 * @param {array} venueData - venue entrances
 * @return {array} - formatted venue entrances array
 */
export const formatVenueData = venueData => {
  if (isNil(venueData)) {
    return [];
  }
  return venueData.map(venue => {
    return {
      id: venue.id,
      name: venue.name
    };
  });
};

/**
 * Helper function to determine if approved providers can be displayed
 * @returns {boolean} - returns true if user can access Approved Providers
 */
export const canEnableApprovedProviders = props => {
  const { user, patientDetails } = props;
  const isSelfFunded = get(patientDetails, 'health_sub_plan_id', false) ? false : true;
  const subPlanApproved = patientDetails
    ? patientDetails.approved_providers
    : get(props, 'healthSubPlanApprovedProviders', false);
  return user.features[APPROVED_PROVIDERS] && (isSelfFunded || subPlanApproved);
};

export const extractAutoCompleteApprovedProvider = async (
  row,
  type: 'pickup' | 'dropoff'
) => {
  const response = await getAddressGeocoding({ address: row.address });

  const typeUpper = type.charAt(0).toUpperCase() + type.substring(1);

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}Address`] = row.address;
  state[`${type}ProviderName`] = row.name;
  state[`${type}ProviderId`] = row.id;
  state[`${type}ProviderNpi`] = row.npiId;
  state[`${type}ProviderPhone`] = row.phone;

  if (response.features?.[0]) {
    const data = response.features[0];
    state[`${type}Longitude`] = data.center[0];
    state[`${type}Latitude`] = data.center[1];
    state[`${type}Zipcode`] = data.context[1].text;
    state[`approvedProvider${typeUpper}`] = row?.matching_place_name ?? row?.place_name;
  }

  return state;
};

export const extractSavedAddressAutoComplete = (row, type: 'pickup' | 'dropoff') => {
  const typeAlt = type === 'pickup' ? 'from' : 'to';

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}Address`] = row.address;
  state[`${type}AddressName`] = row.name;
  state[`${type}Longitude`] = row.longitude;
  state[`${type}Latitude`] = row.latitude;
  state[`${type}Zipcode`] = row.zip;
  state[`${typeAlt}MemberSavedAddressId`] = row.id;

  state[`${type}VenueId`] = null;
  state[`${type}VenueName`] = null;
  state[`${type}EntranceName`] = '';

  return state;
};

export const extractVenueAutoComplete = (row, type: 'pickup' | 'dropoff') => {
  const typeAlt = type === 'pickup' ? 'from' : 'to';

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}VenueName`] = row;
  state[`${type}EntranceName`] = '';
  state[`${type}Zip`] = null;

  if (row[`${typeAlt}MemberSavedAddressId`]) {
    state[`${typeAlt}MemberSavedAddressId`] = row[`${typeAlt}MemberSavedAddressId`];
  }

  return state;
};

export const extractEntranceAutoComplete = (row, type: 'pickup' | 'dropoff') => {
  const typeUpper = type.charAt(0).toUpperCase() + type.substring(1);
  const typeAlt = type === 'pickup' ? 'from' : 'to';

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}Address`] = row.address;
  state[`${type}Latitude`] = row.latitude;
  state[`${type}Longitude`] = row.longitude;
  state[`${type}EntranceName`] = row.name;
  state[`selected${typeUpper}Error`] = false;
  state[`${type}VenueId`] = row.id; // pickupVenueId

  if (row[`${typeAlt}MemberSavedAddressId`]) {
    state[`${typeAlt}MemberSavedAddressId`] = row[`${typeAlt}MemberSavedAddressId`];
  }

  return state;
};

export const extractAutoComplete = (row, type: 'pickup' | 'dropoff') => {
  const typeAlt = type === 'pickup' ? 'from' : 'to';

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}Address`] = get(row, 'matching_place_name', row.place_name);
  state[`${type}Longitude`] = row.center[0];
  state[`${type}Latitude`] = row.center[1];
  state[`${type}Zipcode`] = getZipcode(row.context);

  // We are purposefully leaving this blank so that users can
  // override the approved providers address
  // state[`${type}ProviderId`] = undefined;
  // state[`${type}ProviderNpi`] = undefined;

  if (row[`${typeAlt}MemberSavedAddressId`]) {
    state[`${typeAlt}MemberSavedAddressId`] = row[`${typeAlt}MemberSavedAddressId`];
  } else {
    state[`${typeAlt}MemberSavedAddressId`] = undefined;
  }

  return state;
};

/**
 * Resets the autocomplete dropdown values
 * @param row
 * @param type 'pickup' | 'dropoff
 * @returns {Record}
 */
export const extractResetAutoComplete = (_row: null, type: 'pickup' | 'dropoff') => {
  const typeAlt = type === 'pickup' ? 'from' : 'to';

  const state: Record<string, string | number | boolean | undefined | null> = {};

  state[`${type}Address`] = '';
  state[`${type}AddressName`] = null;
  state[`${type}Longitude`] = 0;
  state[`${type}Latitude`] = 0;
  state[`${type}Zipcode`] = null;
  state[`${typeAlt}MemberSavedAddressId`] = null;

  state[`${type}VenueId`] = null;
  state[`${type}VenueName`] = null;
  state[`${type}EntranceName`] = null;

  return state;
};

/**
 * Simple Utility to determine if saved address
 * feature is enabled
 * @param props
 * @returns
 */
export const canEnableSavedAddress = props => {
  const user = props?.user ?? {};

  return user.features[SAVED_ADDRESS];
};

/**
 * Simple Utility to determine if venues
 * feature is enabled
 * @param props
 * @returns
 */
export const canEnableVenues = props => {
  const user = props?.user ?? {};

  return user.features[VENUES];
};
