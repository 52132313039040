import React from 'react';
import { Observable } from '~/utilities/observable';

const store = new Observable(false);

/**
 * A Custom hook used to toggle display of the SavedAddressDrawer
 * component.
 * @returns 
 */
export const useSavedAddressDrawer = () => {
  const [state, setState] = React.useState(store.get());

  React.useEffect(() => {
    return store.subscribe(setState);
  }, []);

  const actions = React.useMemo(() => {
    return {
      Toggle: () => store.set(!state),
      On: () => store.set(true),
      Off: () => store.set(false)
    };
  }, [state]);

  return [state, actions] as [boolean, typeof actions];
};