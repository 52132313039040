import React from 'react';
import Switch from '@material-ui/core/Switch';
import styled from '@material-ui/core/styles/styled';

export const SRSwitch = styled(props => <Switch {...props}/>)(() => ({
  '& .MuiFormControlLabel-label.Mui-disabled': {
    color: '#FFFFFF'
  },
  '& .MuiSwitch-switchBase + .MuiSwitch-track': {
    backgroundColor: '#A8A8A8'
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#FFFFFF'
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: '#0AB844;'
  }
}));