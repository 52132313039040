import user from './user';
import history from '~/history';
import rideCards from './rideCards/rideCards.reducer';
import bookingData from './bookingData';
import rideDetails from './rideDetails';
import rideHistory from './rideHistory';
import scheduleRides from './scheduleRides';
import mapbox, { locationCheck } from './mapbox';
import { chatsReducer as chats } from './chats';
import { plansReducer as plans } from './plans';
import { connectRouter } from 'connected-react-router';
import { membersReducer as members } from './members';
import { expenseReducer as expenses } from './expenses/reducer';
import { settingsReducer as settings } from './settings';
import { importerJobReducer as importerJob } from './importerJob';
import {
  memberProfileReducer as memberProfile,
  memberAuditLogsReducer as memberAuditLogs
} from './memberProfile/reducer';
import { memberExpensesReducer as memberExpenses } from './memberExpenses/reducer';
import { hospitalNetworkReducer as hospitalNetwork } from './hospitalNetwork';
import { importerTemplateReducer as importerTemplate } from './importerTemplate';
import { memberProfileUploadReducer as memberProfileUploads } from './memberProfileUploads';
import { patientsReducer as patients } from './patients';
import { profileReducer as profile } from './profile';
import { rideBookingReducer as rideBooking } from './rideBooking';
import { savedAddressReducer as savedAddress } from './savedAddress';
import { searchReducer as search } from './search';
import { analyticsReducer as analytics } from './analytics';
import { venuesReducer as venues } from './venues';

export const RESET_STORE = 'RESET_STORE';

const rootReducer = {
  analytics,
  bookingData,
  chats,
  expenses,
  hospitalNetwork,
  importerJob,
  importerTemplate,
  locationCheck,
  mapbox,
  memberAuditLogs,
  memberExpenses,
  memberProfile,
  memberProfileUploads,
  members,
  patients,
  plans,
  profile,
  rideBooking,
  rideCards,
  rideDetails,
  rideHistory,
  router: connectRouter(history),
  savedAddress,
  scheduleRides,
  search,
  settings,
  user,
  venues
};

export const reset = () => {
  return dispatch => dispatch({ type: RESET_STORE });
};

export default rootReducer;
