import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withRouter from '~/hooks/withRouter';
import PageFrame from '~/Shared/Components/PageFrame/PageFrame';
import UserColumn from '~/Shared/Components/UserColumn';
import CustomFields from './Components/CustomFields';

class Settings extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {}

  componentDidUpdate() {}

  componentWillUnmount() {}

  render() {
    return (
      <content className="HPSettings">
        <PageFrame>
          <UserColumn user={this.props.user.userData} />
          <section className="userColumnRight">
            <CustomFields />
          </section>
        </PageFrame>
      </content>
    );
  }
}

Settings.propTypes = {
  user: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object
};

Settings.defaultProps = {
  user: {},
  history: {},
  match: {}
};

export default withRouter(Settings);
