/**
 * This slice was created by converting a javascript file using the old reducer pattern.
 * The types are not correct and are being updated over time.
 */
import { createSlice } from '@reduxjs/toolkit';
import type { PatientsStore } from './Patients.types';
import { formatSavedAddresses } from '~/utilities/patients';
import { formatPhoneNumber } from '~/utilities/helperFunctions';
import { getLastRideThunk, getPatientInfoThunk, getSavedAddressesThunk } from './thunks';
import _ from 'lodash-es';
import moment from 'moment';
import { isNil } from '@SRHealth/frontend-lib';

const INITIAL_STATE: PatientsStore = {
  inHttpRequest: false,
  patientDetails: undefined,
  rideHistory: [],
  approvedProviderRideHistory: [],
  highRisk: undefined,
  medicalId: undefined,
  assistanceNeeds: [],
  assistanceNeedsChecked: {},
  savedAddresses: []
};

const patientsSlice = createSlice({
  name: 'patients',
  initialState: INITIAL_STATE,
  reducers: {
    reset: () => INITIAL_STATE,
    /** Updates the patients slice with a new set of saved addresses. */
    updateSavedAddresses: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.savedAddresses = formatSavedAddresses(action.payload);
      }
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getPatientInfoThunk.pending, state => {
        state.inHttpRequest = true;
      })
      .addCase(getPatientInfoThunk.rejected, (state, action) => {
        state.inHttpRequest = false;
        state.error = {
          message: action?.error?.data?.errors?.[0] ?? 'Error',
          reason: action?.error?.data?.errors?.[1] ?? 'Error',
          status: action?.error?.data?.status ?? 500
        };

        state.patientParams = action?.payload?.params ?? {};
        state.medicalId = action?.payload?.params.medicalId;
        state.timestamp = Math.floor(Date.now() / 1000);
        state.patientDetails = {};
      })
      .addCase(getPatientInfoThunk.fulfilled, (state, action) => {
        state.inHttpRequest = false;
        delete state.error;

        state.patientDetails = action.payload?.data?.patient_details ?? {};
        state.patientDetails.bookingPhoneNumber =
          action?.payload.data?.patient_details?.phoneNumbers?.find(
            p => p?.opt_out_prompt_timestamp
          );

        if (state.patientDetails?.bookingPhoneNumber) {
          state.patientDetails.bookingPhoneNumber = formatPhoneNumber(
            state.patientDetails.bookingPhoneNumber?.phone_number?.phone_number
          );
        }
        state.approvedProviderRideHistory = (
          action?.payload.data.provider_history ?? []
        ).map(r => ({
          name: r.providerName,
          address: r.providerAddress,
          phone: r.providerPhone,
          id: r.providerId,
          npiId: r.providerNpi
        }));

        if (!isNil(state.patientDetails.dateOfBirth)) {
          state.patientDetails.dateOfBirth = moment(
            state.patientDetails.dateOfBirth,
            'YYYY-MM-DD'
          ).format('MM/DD/YYYY');

          if (action?.payload.params?.healthPlanId) {
            state.patientDetails.healthPlanId = action.payload.params.healthPlanId;
          }
        }

        const medicalId =
          action?.payload.data?.patient_details?.medicalId ||
          action?.payload.params?.medicalId;

        state.highRisk = action.payload.data.highrisk;
        state.timestamp = Math.floor(Date.now() / 1000);
        state.medicalId = medicalId;
        state.assistanceNeeds = action?.payload.data.assistance_needs ?? [];
        state.patientDetails.medicalId = medicalId;
        state.patientDetails.default_mobility_mode =
          action?.payload.data?.patient_details?.default_mobility_mode ?? 'Private';

        state.assistanceNeeds ??= [];
        state.assistanceNeedsChecked = {};
        state.patientDetails.patient_assistance_needs ??= {};

        if (state.assistanceNeeds.length > 0) {
          state.assistanceNeeds = state.assistanceNeeds.map(need => {
            if (
              typeof state.patientDetails.patient_assistance_needs[need.id] !==
              'undefined'
            ) {
              state.patientDetails.patient_assistance_needs[need.id] = need.value;
            }
            need.name = need.value;
            return need;
          });

          state.assistanceNeedsChecked = state.patientDetails.patient_assistance_needs;
          _.unset(state, 'patientDetails.patient_assistance_needs');
        }

        if ('savedAddresses' in action.payload.data.patient_details) {
          const addressData = action.payload.data.patient_details.savedAddresses;

          state.savedAddresses = Array.isArray(addressData)
            ? formatSavedAddresses(addressData)
            : [];

          // Prevent redundant data storing
          delete state.patientDetails.savedAddresses;
        }

        if (state.error) delete state.error;
      })
      .addCase(getSavedAddressesThunk.fulfilled, (state, action) => {
        if (Array.isArray(action.payload.data)) {
          state.savedAddresses = formatSavedAddresses(action.payload.data);
        }
      })
      .addCase(getLastRideThunk.fulfilled, (state, action) => {
        state.rideHistory = [action.payload.data];
      })
      .addCase(getLastRideThunk.rejected, (state, action) => {
        state.errorMessage = 'Unable to retrieve ride history';
        state.errorReason = action.error.message;
      });
  }
});

export const patientsReducer = patientsSlice.reducer;
export const { reset, updateSavedAddresses } = patientsSlice.actions;
