import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import { LYFT_VEHICLE_ID, UBER_VEHICLE_ID, DATE_FORMAT_INPUT } from '~/constants';
import { errorParse, isRideShareVehicleId } from '~/utilities/helperFunctions';
import {
  mapMultiLegSubmit,
  mapRideShareOnDemandSubmit
} from '~/utilities/booking.helper';
import SvgCheckbox from 'Common/Components/Svgs/SvgCheckbox';
import SvgCheckboxChecked from 'Common/Components/Svgs/SvgCheckboxChecked';
import SvgWarningFillOrange from 'Common/Components/Svgs/SvgWarningFillOrange';
import { get, isNil, isEmpty, clone, has } from 'lodash-es';
import scriptLoader from 'react-async-script-loader';
import moment from 'moment-timezone';
import PublicConfirmation from './PublicConfirmation';
import { clearNewMemberForm } from '~/Modules/members/reducer';

class Confirmation extends React.Component {
  /**
   * constructor
   * @param {object} props, list of props passed down from RideReport.js
   * @return {undefined} returns nothing
   */
  constructor(props) {
    super(props);
    this.state = {
      selfPayChecked: false,
      validated: false,
      totalCost: 0,
      totalDistance: 0,
      agreement: null,
      clicked: false
    };
    this.modalParams = {};
    this.confirmationSubmit = {};
    this.isOnDemand = false;
  }

  componentDidMount() {
    this.props.toggleLoading(false);
    const rides = get(this.props, 'trip.rides', []);

    if (rides.length > 0) {
      this.generateSum(rides);
    }
    // TODO:UBER
    // if (!isEmpty(this.props.ride)) {
    //   this.generateSumLyftOnDemand();
    // }
  }

  componentDidUpdate(prevProps) {
    const rides = get(this.props, 'trip.rides', []);
    const prevRides = get(prevProps, 'trip.rides', []);

    if (rides.length > 0 && prevRides.length === 0) {
      this.generateSum(rides);
    }
    /**
     * TODO:UBER — ONDEMAND
     */
    if (!isEmpty(this.props.ride) && isEmpty(prevProps.ride)) {
      this.generateSumLyftOnDemand();
    }
    if (isEmpty(prevProps.chatRequestData) && !isEmpty(this.props.chatRequestData)) {
      this.props.toggleLoading(false);
    }
  }

  /**
   * Call to stripe to get token
   * @returns {undefined} - returns nothing
   */
  stripeHandler() {
    const callback = this.setStripeToken;
    const params = {
      key: process.env.REACT_APP_STRIPE_PUBLISHABLE,
      locale: 'auto',
      name: 'SafeRide Health',
      description: 'Book Ride',
      token: function (token) {
        callback(token.id, JSON.stringify(token.card));
      }
    };
    const stripeHandler = window.StripeCheckout.configure(params);

    stripeHandler.open({
      panelLabel: 'Continue'
    });
  }

  /**
   * Show repeat appointment component
   * @param {event} e - synthetic event
   * @return {undefined}
   */
  clickTerms = () => {
    this.setState({ termsChecked: this.refTerms.checked }, this.validate);
  };

  /**
   * ride booking submission
   * @param {object} e - target fro dropdown
   * @returns {undefined} returns nothing
   */
  handleSubmit = e => {
    e.preventDefault();
    this.validate();
    if (this.state.validated === false && this.state.agreement !== null) {
      return false;
    }
    if (this.props.willCall) {
      this.setState({ clicked: true }, () => {
        this.props.willCallSubmit();
      });
    } else {
      const { bookingData } = this.props;
      /**
       * TODO:UBER:ONDEMAND
       */
      const transportType = parseInt(bookingData.transportType, 10);
      const isLyft =
        transportType === LYFT_VEHICLE_ID || transportType === UBER_VEHICLE_ID;
      if (bookingData.bookingType === 'ondemand' && isLyft) {
        this.confirmationSubmit = mapRideShareOnDemandSubmit(this.props.ride);
        this.isOnDemand = true;
      } else {
        this.confirmationSubmit = mapMultiLegSubmit(this.props.trip);
      }

      if (this.confirmationSubmit.payWithCard === true) {
        this.stripeHandler();
      } else {
        this.props.toggleLoading(true, 'Booking Ride...', 60);
        this.submitRide();
      }
    }
    // clear member form
    this.props.clearNewMemberForm();
  };

  /**
   * validate form submission
   * @returns {undefined} returns nothing
   */
  validate() {
    //clone state to prevent directly mutating state
    const currentState = clone(this.state);

    //reset validation
    currentState.validated = true;
    if (this.state.agreement !== '' && this.state.agreement !== null) {
      if (this.state.termsChecked) {
        currentState.validated = true;
      } else {
        currentState.validated = false;
      }
    }

    this.setState(currentState);
  }

  /**
   * getTripdisplay - what type of trip is it
   * @param {object} ride - ride
   * @return {string} - returs type of trip
   */
  getTripDisplay(ride) {
    let bookingType = get(ride, 'bookingType', '');
    if (bookingType === '' || isNil(bookingType)) {
      bookingType = get(this.props, 'bookingData.bookingType', '');
    }
    if (bookingType.toLowerCase() === 'roundtrip') {
      return 'Two Ways';
    }

    if (
      bookingType.toLowerCase() === 'tocare' ||
      bookingType.toLowerCase() === 'fromcare'
    ) {
      return '';
    }
  }

  /**
   * sets stripe token and card info to the state
   * @param {string} token - token string from stripe
   * @param {object} card - card info object from stripe
   * @return {undefined} - returns nothing
   */
  setStripeToken = (token, card) => {
    this.confirmationSubmit.stripeToken = token;
    this.confirmationSubmit.stripeCard = card;
    this.submitRide();
  };

  /**
   * Submit ride
   * @returns {undefined} - returns nothing
   */
  submitRide() {
    this.setState({ clicked: true }, () => {
      this.props.submitRide(this.confirmationSubmit, this.isOnDemand);
    });
  }

  /**
   * calculate sums for distance and cost
   * @param {array} rides - all legs for trip
   * @return {undefined}
   */
  generateSum(rides) {
    const initialValue = {
      totalCost: 0,
      totalDistance: 0
    };
    const sums = rides.reduce((sum, ride) => {
      const distance = parseFloat(ride.distance);
      const cost = sum.totalCost + ride.cost;

      return {
        totalCost: cost,
        totalDistance: sum.totalDistance + distance
      };
    }, initialValue);
    sums.totalCost = sums.totalCost.toFixed(2);
    sums.totalDistance = sums.totalDistance.toFixed(2);
    sums.agreement = get(
      this.props,
      'trip.rides[0].hospital.hospital_group.confirmation_agreement',
      null
    );
    this.setState(sums);
  }

  /**
   * for lyft rides
   * calculate cost and distance. there is no trip rides array for on demand
   * @return {undefined} returns nothing, sets cost and distance
   */
  generateSumLyftOnDemand() {
    const totalCost = get(this.props, 'ride.cost', 0).toFixed(2);
    const totalDistance = get(this.props, 'ride.distance', 0).toFixed(2);
    const agreement = get(
      this.props,
      'ride.hospital.hospital_group.confirmation_agreement',
      null
    );
    this.setState({
      totalCost,
      totalDistance,
      agreement
    });
  }

  getAuthStatusMap() {
    return {
      warning: {
        color: 'orange',
        SvgName: SvgWarningFillOrange
      }
    };
  }

  /**
   * generate text for orange button
   *
   * @return {string} button text
   */
  generateButtonText() {
    const { bookingData } = this.props;
    const isPastRide = get(bookingData, 'pastRide', false);
    const transportType = get(bookingData, 'rides[0].transportType', '');
    const today = moment().tz(bookingData.timezoneFormat);
    const isSameDayRide = today
      ? bookingData.selectedDate === today.format(DATE_FORMAT_INPUT)
      : false;
    const notMultiLegRide = bookingData.bookingType !== 'multileg';

    if (isPastRide) return 'Confirm Ride';

    if (isSameDayRide && notMultiLegRide && !isRideShareVehicleId(transportType)) {
      return 'Contact Transport';
    }

    return 'Confirm Ride';
  }

  renderPublicConfirmation = () => {
    return (
      <PublicConfirmation
        confirmPublic={this.props.confirmPublic}
        trip={this.props.trip}
        bookingData={this.props.bookingData}
        selectedLegs={this.props.publicSelectedLegs}
      />
    );
  };

  isPublicRide = () => {
    if (this.props.isPublicRoutelessRides) return true;
    return (
      Object.keys(this.props.publicSelectedLegs).length > 0 ||
      get(this, 'props.bookingData.mode', 'Private') === 'Public'
    );
  };

  renderError(errorMessage, closeRequestNewRide) {
    return (
      <div className="errorList">
        {errorMessage.map((message, key) => {
          return <p key={key}>{message}</p>;
        })}
        <p className="cancelRidePara">
          <a onClick={closeRequestNewRide} className="cancelRide">
            Cancel Ride
          </a>
        </p>
      </div>
    );
  }

  render() {
    const displayPublicConfirmation = this.isPublicRide();
    if (displayPublicConfirmation) return this.renderPublicConfirmation();

    const { agreement, clicked, validated, totalCost, totalDistance, termsChecked } =
      this.state;
    const { error, data, trip, chatRequestData, closeRequestNewRide } = this.props;
    let errorMessage = [];
    if (!isEmpty(error)) {
      errorMessage = errorParse(
        error,
        'Something went wrong. Try again or contact support.'
      );
    }
    if (has(data, 'status') && data.status === false) {
      errorMessage = [data.message];
    }

    let rides = get(trip, 'rides', []);

    // just show total for 1 ride
    if (rides.length === 1) {
      rides = [];
    }

    const disabled =
      !((validated || agreement === null) && isEmpty(errorMessage)) || clicked;
    const buttonActiveClass = disabled ? 'bookButton inactive' : 'bookButton';
    const submitButtonText = this.generateButtonText();

    // past booking
    const authStatusMap = this.getAuthStatusMap();
    const isPastRide = get(this.props, 'bookingData.pastRide', false);
    const warningColor = authStatusMap.warning.color;
    const SvgWarning = authStatusMap.warning.SvgName;

    return (
      <div className="RideConfirmation confirm">
        <div className="title">Trip Breakdown</div>
        {rides.map((ride, key) => {
          let cost = get(ride, 'cost', '');
          if (cost !== '') {
            cost = cost.toFixed(2);
          }
          let passengerCost = get(ride, 'passengerCost', '');
          if (passengerCost !== '') {
            passengerCost = passengerCost.toFixed(2);
          }
          const tripDisplay = this.getTripDisplay(ride);

          return (
            <div key={key}>
              <div className="rideRestrictionsBlock">
                {ride.showPrice ? (
                  <div className="leftRestrictions">
                    <p className="row1">
                      Leg Cost
                      <br />
                      {tripDisplay !== '' ? `(${tripDisplay})` : ''}
                    </p>
                    <p className="numberGroup">
                      <sup className="confirmSup">$</sup>
                      <span className="numbers">{cost}</span>
                    </p>
                  </div>
                ) : null}
                <div className="rightRestrictions">
                  <p className="row1">
                    This Leg
                    <br />
                    Distance
                  </p>
                  <p className="numberGroup">
                    <span className="numbers">{get(ride, 'distance', '')}</span>
                    <span className="smallLetters">mi</span>
                  </p>
                </div>
              </div>
              {ride.showPrice && passengerCost !== '' ? (
                <div className="rideRestrictionsBlock">
                  <div className="leftRestrictions">
                    <p className="row1">
                      Passenger Cost
                      <br />
                      {tripDisplay !== '' ? `(${tripDisplay})` : ''}
                    </p>
                    <p className="numberGroup">
                      <sup className="confirmSup">$</sup>
                      <span className="numbers">{passengerCost}</span>
                    </p>
                  </div>
                  <div className="rightRestrictions"></div>
                </div>
              ) : null}
            </div>
          );
        })}
        {totalCost > 0 || totalDistance > 0 ? (
          <div className="rideRestrictionsBlock">
            <div className="leftRestrictions">
              <p className="row1">
                Trip Cost
                <br />
                Total
              </p>
              <p className="numberGroup">
                <sup className="confirmSup">$</sup>
                <span className="numbers">{totalCost}</span>
              </p>
            </div>
            <div className="rightRestrictions">
              <p className="row1">
                This Trip
                <br />
                Total
              </p>
              <p className="numberGroup">
                <span className="numbers">{totalDistance}</span>
                <span className="smallLetters">mi</span>
              </p>
            </div>
          </div>
        ) : null}
        {isPastRide ? (
          <div className="warningBlock">
            <SvgWarning className={`${warningColor}Svg`} />
            You are about to book a past ride.
          </div>
        ) : null}
        {isEmpty(chatRequestData) ? (
          <form onSubmit={this.handleSubmit}>
            {agreement !== null ? (
              <div className="terms">
                <label htmlFor="termsCond">
                  {termsChecked ? (
                    <SvgCheckboxChecked className="svgChecked" />
                  ) : (
                    <SvgCheckbox className="svgUnchecked" />
                  )}
                  <input
                    type="checkbox"
                    id="termsCond"
                    name="termsCond"
                    onChange={this.clickTerms}
                    ref={checkbox => (this.refTerms = checkbox)}
                  />
                  <span>{agreement}</span>
                </label>
                {submitButtonText === 'Contact Transport' ? (
                  <p className="sameDay">
                    Same day rides must be confirmed with transport company prior to
                    booking.
                  </p>
                ) : null}
              </div>
            ) : null}
            <button className={buttonActiveClass} disabled={disabled}>
              {submitButtonText}
            </button>
          </form>
        ) : (
          <p className="openChatWindow">Opening chat window</p>
        )}
        {!isEmpty(errorMessage)
          ? this.renderError(errorMessage, closeRequestNewRide)
          : null}
      </div>
    );
  }
}

Confirmation.defaultProps = {
  ride: {},
  trip: {},
  passenger: {},
  submitRide: () => {},
  chatRequestData: {},
  error: {},
  toggleLoading: () => {},
  bookingData: {},
  data: {},
  closeRequestNewRide: () => {},
  willCall: false,
  willCallSubmit: () => {},
  publicSelectedLegs: {}
};

Confirmation.propTypes = {
  ride: PropTypes.object,
  trip: PropTypes.object,
  passenger: PropTypes.object,
  submitRide: PropTypes.func,
  chatRequestData: PropTypes.object,
  error: PropTypes.object,
  toggleLoading: PropTypes.func,
  bookingData: PropTypes.object,
  data: PropTypes.object,
  closeRequestNewRide: PropTypes.func,
  willCall: PropTypes.bool,
  willCallSubmit: PropTypes.func,
  publicSelectedLegs: PropTypes.object
};

const mapStateToProps = state => ({
  passenger: state.scheduleRides.passenger,
  chatRequestData: state.scheduleRides.chatRequestData
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      clearNewMemberForm: () => clearNewMemberForm()
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(scriptLoader(['https://checkout.stripe.com/checkout.js'])(Confirmation));
