import _ from 'lodash-es';
import React from 'react';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { pdf, Page, View, Text, Document, StyleSheet } from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import { timeWithTimezone } from '~/utilities/helperFunctions';

import { mapExpenseReportHealthPlanName } from '../../helpers';

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 30,
    flexDirection: 'row',
    backgroundColor: '#fafafa'
  },
  section: {
    margin: 10,
    padding: 10,
    fontSize: 12,
    flexGrow: 1,
    display: 'block'
  },
  body: {
    fontSize: 12,
    flexGrow: 1
  },
  row: {
    flexGrow: 1,
    fontSize: 12,
    flexDirection: 'row'
  },
  subtext: {
    fontSize: 10,
    color: '#999'
  },
  activity: {
    margin: 10,
    fontSize: 12
  },
  expenseRow: {
    fontSize: 12,
    margin: 5
  },
  mb10: {
    marginBottom: 10
  },
  border: {
    borderBottomWidth: 2,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid'
  }
});

// Add/remove report fields here to be included in the report.
const fieldOrder = [
  '_id',
  'fundingSource',
  'hospitalName',
  'medicalId',
  'memberId',
  'firstName',
  'lastName',
  'mobilityType',
  'subMobilityType',
  'status',
  'prevStatus',
  'statusReason',
  'reimbursementMethod',
  'complianceFields',
  'purpose',
  'fromDate',
  'toDate',
  'totalExpenseAmt',
  'totalReimbursableAmt'
];

// Function to order fields based on the above order.
const orderFields = report => {
  const _report = Object.entries(report).reduce((acc, [label, value]) => {
    acc[label] = value;
    return acc;
  }, {});
  return fieldOrder.map(field => {
    return { label: field, value: _report[field] };
  });
};

// Create Document Component
export const ReportPdf = ({ report, activityLog: { data = [] }, options }) => {
  const { vehicleTypes } = useSelector(state => state.user);
  const { timezone_format } = useSelector(state => state.user.userData);

  const FILE_NAME = 'expense-report.pdf';

  const PDFReportDetails = () => {
    let mobilityType = null;
    const orderedFields = orderFields(report);
    return (
      <View style={styles.body}>
        <View style={styles.mb10}>
          <Text style={styles.border}>Report ID: {report._id}</Text>
        </View>
        {orderedFields.reduce((acc, { label, value }) => {
          if (
            label === '_id' ||
            label === 'duration' ||
            label === 'prevStatus' ||
            label === 'memberId' ||
            value === null
          ) {
            return acc;
          }
          if (label === 'mobilityType') {
            mobilityType = value;
            value = vehicleTypes[value].modelName;
          }
          if (mobilityType && label === 'subMobilityType') {
            const subType = value
              ? vehicleTypes[mobilityType].vehicle_sub_types.filter(
                ({ id }) => id === value
              )
              : null;
            if (subType && subType[0] && typeof subType[0] === 'object') {
              value = subType[0].name;
            }
          }
          if (label === 'medicalId') label = 'memberId';
          if (label === 'fundingSource') {
            value = mapExpenseReportHealthPlanName(report, options);
          }

          let _label =
            label === 'hospitalName' ? 'hospital' : label.replace(/([A-Z])/g, ' $1');
          _label = _label.charAt(0).toUpperCase() + _label.slice(1);
          acc.push(
            <View key={label} style={styles.row}>
              <Text>
                {_label.replace(/_/gi, ' ')}:{' '}
                {typeof value === 'object'
                  ? JSON.stringify(value).replace(/"/gi, '')
                  : value}
              </Text>
            </View>
          );
          return acc;
        }, [])}
      </View>
    );
  };

  const PDFExpenseList = () => {
    return report.expenses.map((expense, i) => {
      const {
        amount,
        paidBy,
        category,
        distance,
        merchant,
        toAddress,
        ratePerMile,
        description,
        expenseDate,
        fromAddress,
        reimbursable,
        merchantAddress
      } = expense;
      const subStyle = { ...styles.expenseRow, ...styles.subtext };
      return (
        <View key={i}>
          <View style={styles.expenseRow}>
            <Text>
              {moment(expenseDate, 'YYYY/MM/DD hh:mm:ss').format('MM/DD/YY')} -{' '}
              {merchant && !distance ? <Text>{merchant} - </Text> : null}
              {category} - ${Number(amount).toFixed(2)}
            </Text>
          </View>
          {distance ? (
            <View style={subStyle}>
              <Text>Distance: {distance} Miles</Text>
            </View>
          ) : null}
          {merchantAddress ? (
            <View style={subStyle}>
              <Text>{merchantAddress}</Text>
            </View>
          ) : null}
          {fromAddress.address ? (
            <View style={subStyle}>
              <Text>From: {fromAddress.address}</Text>
            </View>
          ) : null}
          {toAddress.address ? (
            <View style={subStyle}>
              <Text>To: {toAddress.address}</Text>
            </View>
          ) : null}
          <View style={subStyle}>
            <Text>{description}</Text>
          </View>
          {ratePerMile ? (
            <View style={subStyle}>
              <Text>${ratePerMile} / Mile</Text>
            </View>
          ) : null}
          {!reimbursable ? (
            <View style={subStyle}>
              <Text>Non-reimbursable - Paid by: {paidBy}</Text>
            </View>
          ) : null}
        </View>
      );
    });
  };

  const PDFActivityLog = () => {
    return (
      <View style={styles.body}>
        <View style={styles.mb10}>
          <Text style={styles.border}>Activity Log</Text>
        </View>
        {data.map(({ text = '', subText = '', time = null, details }, i) => {
          return (
            <View key={i} style={styles.activity}>
              <Text width="160">
                {timeWithTimezone(time, timezone_format, 'MM/DD/YY h:mm A')}
              </Text>

              <Text>{text}</Text>
              <Text style={styles.subtext}>{subText}</Text>
              {details && !Array.isArray(details) && typeof details === 'object'
                ? Object.entries(details).map(([eventType, descriptions], i) => {
                  if (!descriptions.length) return null;
                  return (
                    <View key={i}>
                      <View style={styles.subtext}>
                        <Text>{eventType.toLocaleUpperCase()}: </Text>
                        {descriptions.map((d, j) => {
                          return (
                            <View key={j}>
                              <Text>- {d}</Text>
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  );
                })
                : null}
              {Array.isArray(details)
                ? details.map((d, i) => (
                  <Text style={styles.subtext} key={i}>
                    {d}
                  </Text>
                ))
                : null}
            </View>
          );
        })}
      </View>
    );
  };

  const Doc = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <PDFReportDetails />
        </Page>
        <Page size="A4" style={styles.page}>
          <View style={styles.body}>
            <View style={styles.mb10}>
              <Text style={styles.border}>Expense List</Text>
            </View>
            <PDFExpenseList />
          </View>
        </Page>
        <Page size="A4" style={styles.page}>
          <PDFActivityLog />
        </Page>
      </Document>
    );
  };

  const generatePdf = async () => {
    const blob = await pdf(<Doc />).toBlob();
    saveAs(blob, FILE_NAME);
  };

  try {
    return (
      <div
        className="pdf-btn dl-btn"
        style={{ marginTop: '32px' }}
        onClick={() => generatePdf()}
      >
        {FILE_NAME}
      </div>
    );
  } catch (error) {
    return null;
  }
};
