import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from 'react-stars';
import _ from 'lodash-es';
import SvgCheck from '../../../Svgs/SvgCheck';
import ReactTooltip from 'react-tooltip';
import SvgInfo from '~/Shared/Components/Svgs/SvgInfo';
import SvgFirstPlaceMedal from 'Common/Components/Svgs/SvgFirstPlaceMedal';
import SvgSecondPlaceMedal from 'Common/Components/Svgs/SvgSecondPlaceMedal';
import { formatPhoneNumber, getStrOrIntDayAlias } from '~/utilities/helperFunctions';
import moment from 'moment';
import { TRANSPORT_TYPES_DICTIONARY } from '~/constants';

const { UBER, LYFT } = TRANSPORT_TYPES_DICTIONARY;

const VehicleCompany = props => {
  const {
    index,
    vehicleInfo,
    bookRideCallback,
    selected,
    makeClickable,
    isBlocked,
    relevantLeg,
    bookingData,
    rides
  } = props;
  const operatingHoursList = _.get(vehicleInfo, 'operatingHours', []);

  const isPrimaryNEMT = _.get(vehicleInfo, 'isPrimaryNEMT', null);
  const isSecondaryNEMT = _.get(vehicleInfo, 'isSecondaryNEMT', null);
  let highlightCard = false;

  if (isPrimaryNEMT) {
    highlightCard = true;
  }

  if (isSecondaryNEMT) {
    const v = rides.find(ride => ride.isPrimaryNEMT);
    if (!v) {
      highlightCard = true;
    }
  }
  /**
   * Operating hour data and days of operation for scheduled rides
   */
  const scheduledHours = operatingHoursList.find(
    operatingHours => operatingHours.ride_type === 'scheduled'
  );
  const scheduledDays =
    scheduledHours?.operating_days?.split('').filter(val => val) ?? [];

  /**
   * Operating hour data and days of operation for on-demand rides
   */
  const dispatchHours = operatingHoursList.find(
    operatingHours => operatingHours.ride_type === 'on-demand'
  );
  const dispatchDays = dispatchHours?.operating_days?.split('').filter(val => val) ?? [];

  /**
   * Determine the day of the scheduled ride, and whether the NEMT supports it
   */
  const dayOfTheRide =
    Object.keys(relevantLeg).length === 0
      ? moment(bookingData.selectedDate, 'MM/DD/YYYY').day()
      : moment(relevantLeg.selectedDate, 'MM/DD/YYYY').day();
  const stringDayOfTheRide = getStrOrIntDayAlias(dayOfTheRide);
  const nemtSchedulesOnDay = _.includes(scheduledDays, stringDayOfTheRide);
  /**
   * Determine the day of the
   */
  const dayOfToday = moment().day();
  const stringDayOfToday = getStrOrIntDayAlias(dayOfToday);
  const nemtDispatchesOnDay = _.includes(dispatchDays, stringDayOfToday);
  /**
   * Get the day of the ride booking
   */
  const humanFriendlyScheduledDayString = moment().day(dayOfTheRide).format('ddd');
  const humanFriendlyDispatchDayString = moment().day(dayOfToday).format('ddd');

  const phoneNumber = !_.isEmpty(vehicleInfo.contactNo1)
    ? formatPhoneNumber(vehicleInfo.contactNo1)
    : null;

  const bookRide = e => {
    if (!makeClickable) {
      return false;
    }
    e.preventDefault();
    const companyId = vehicleInfo.companyId;
    const vehicleType = vehicleInfo.vehicleType;
    if (!_.isNil(index)) {
      bookRideCallback(
        index,
        companyId,
        vehicleType,
        vehicleInfo.id,
        vehicleInfo.rideType
      );
    } else {
      bookRideCallback(companyId, vehicleType, vehicleInfo.id, vehicleInfo.rideType);
    }
  };

  let vehicleClass = selected ? 'vehicleCont selected' : 'vehicleCont';
  const companyName =
    vehicleInfo?.transportType === UBER ? UBER : _.get(vehicleInfo, 'companyName', LYFT);
  vehicleClass = [UBER, LYFT].includes(companyName) ? 'vehicleCont lyft' : vehicleClass;
  vehicleClass = isBlocked ? vehicleClass + ' unselectable' : vehicleClass;
  let estimatedCost = '';
  if (companyName === LYFT) {
    estimatedCost = _.get(vehicleInfo, 'cost', '');
  } else {
    estimatedCost = _.get(vehicleInfo, 'ride_estimated_cost', '');
  }

  if (!_.isNil(estimatedCost) && estimatedCost !== '') {
    estimatedCost = parseFloat(estimatedCost).toFixed(2);
  }

  return (
    <div
      className={vehicleClass}
      style={highlightCard ? { backgroundColor: 'rgb(255, 255, 0, .20)' } : {}}
      onClick={bookRide}
    >
      <div className="vehicle">
        {isBlocked && (
          <>
            <span
              data-for={'rejectReasonTip'}
              data-tip={_.get(
                vehicleInfo,
                'alert_msg',
                'Ride violation reason not found'
              )}
              data-background-color={'red'}
              data-color={'white'}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: 'white',
                backgroundColor: 'red',
                display: 'inline-block',
                paddingLeft: '8px',
                paddingRight: '8px',
                textAlign: 'center',
                borderRadius: '50%'
              }}
            >
              !
            </span>
            <ReactTooltip id="rejectReasonTip" place="right" />
          </>
        )}

        {typeof vehicleInfo.driver !== 'undefined' ? (
          <div className="driver">
            <div className="thumb">
              <img src={vehicleInfo.driver.avatar} className="avatar" alt="avatar" />
            </div>
            <div className="name">
              {vehicleInfo.driver.firstName} {vehicleInfo.driver.lastName}
            </div>
          </div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <p className="vehicleOwner">{companyName}</p>
          </div>
          <div>
            {!isBlocked && isPrimaryNEMT ? (
              <React.Fragment>
                <div
                  data-for={`preferredNEMT${index}`}
                  data-tip={'This is the passengers primary preferred NEMT.'}
                >
                  <SvgFirstPlaceMedal />
                </div>
                <ReactTooltip id={`preferredNEMT${index}`} place="right" />
              </React.Fragment>
            ) : null}
            {!isBlocked & isSecondaryNEMT ? (
              <React.Fragment>
                <div
                  data-for={`preferredNEMT${index}`}
                  data-tip={'This is the passengers secondary preferred NEMT.'}
                >
                  <SvgSecondPlaceMedal />
                </div>
                <ReactTooltip id={`preferredNEMT${index}`} place="right" />
              </React.Fragment>
            ) : null}
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between'
          }}
        >
          <div className="stars">
            <ReactStars
              count={5}
              color1={'gray'}
              color2={'#FF931E'}
              edit={false}
              className="stars"
              value={vehicleInfo.vehicleOwnerRating}
            />
          </div>
          {estimatedCost !== '' && !_.isNil(estimatedCost) ? (
            <p className="cost">${estimatedCost}</p>
          ) : null}
        </div>

        {isBlocked ? <p className="vehicleType">{phoneNumber}</p> : null}

        <p className="hours-header">
          Hours
          <span
            data-for="hoursExplanationTip"
            data-tip="The Operating Hours are the hours that the Provider is able to fulfill a ride. 
The Dispatch Hours are the hours that the Provider is available to respond and dispatch rides."
            className={'info'}
          >
            <SvgInfo className="infoSvg" />
          </span>
        </p>

        <p className="operating-dispatch">
          <span>Operating:</span>

          <span>
            {nemtSchedulesOnDay
              ? `${moment(scheduledHours.operating_hours_start, 'hh:mm:ss', false).format('h:mm a')} - ${moment(scheduledHours.operating_hours_end, 'hh:mm:ss', false).format('h:mm a ')} (${humanFriendlyScheduledDayString})`
              : `No Available Hours (${humanFriendlyScheduledDayString})`}
          </span>
        </p>
        <p className="operating-dispatch">
          <span>Dispatch:</span>

          {
            <span>
              {nemtDispatchesOnDay
                ? `${moment(dispatchHours.operating_hours_start, 'hh:mm:ss', false).format('h:mm a')} - ${!_.isUndefined(dispatchHours) && moment(dispatchHours.operating_hours_end, 'hh:mm:ss', false).format('h:mm a (ddd)')}`
                : `No Available Hours (${humanFriendlyDispatchDayString})`}
            </span>
          }
        </p>
        <ReactTooltip id="hoursExplanationTip" />
        {selected ? <SvgCheck className="checked" /> : null}
      </div>
    </div>
  );
};

VehicleCompany.propTypes = {
  vehicleInfo: PropTypes.object,
  bookRideCallback: PropTypes.func,
  multileg: PropTypes.bool,
  index: PropTypes.number,
  selected: PropTypes.bool,
  makeClickable: PropTypes.bool,
  isBLocked: PropTypes.bool,
  relevantLeg: PropTypes.object
};

VehicleCompany.defaultProps = {
  vehicleInfo: {},
  bookRideCallback: () => {},
  multileg: false,
  index: null,
  selected: false,
  makeClickable: true,
  isBLocked: false,
  relevantLeg: {}
};

export default VehicleCompany;
