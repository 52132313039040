import React from 'react';

import {
  InputField,
  AgeGroupInput,
  MobilityTypeInput,
  SubMobilityTypeInput
} from '~/Pages/ExpenseSlideout/Components/Slideout/ExpenseInputs';

/**
 * @name rbfPassengerFormInputs
 * @param {Object} np new passenger
 * @param {func} setPassenger state setter for passenger
 * @param {Array} vehicle_types vehicle data to populate vehicle type dropdowns
 * @param {boolean} shouldUseSubMobilityType
 * @returns {Array<Object>} structured collection of components for the the form
 */
const rbfPassengerFormInputs = ({
  np,
  disabled = false,
  setPassenger,
  vehicle_types,
  shouldUseSubMobilityType
}) => {
  const formInputsArr = [
    [
      {
        label: '',
        name: 'ageGroup',
        value: np.ageGroup,
        required: false,
        Component: () => (
          <AgeGroupInput
            disabled={disabled}
            ageGroup={np.ageGroup}
            setField={setPassenger}
          />
        )
      }
    ],
    [
      {
        label: '',
        name: 'firstName',
        value: np.firstName,
        required: false,
        Component: [
          <InputField
            key="firstName"
            name="firstName"
            maxLength={50}
            disabled={disabled}
            value={np.firstName || ''}
            setField={setPassenger}
            className="ellipsis-input"
            placeholder="First Name"
          />
        ]
      },
      {
        label: '',
        name: 'lastName',
        value: np.lastName,
        required: false,
        Component: [
          <InputField
            key="lastName"
            name="lastName"
            maxLength={50}
            disabled={disabled}
            value={np.lastName || ''}
            setField={setPassenger}
            className="last-name ellipsis-input"
            placeholder="Last Name"
          />
        ]
      }
    ],
    [
      {
        label: '',
        name: 'mobilityType',
        value: np.mobilityType,
        required: true,
        Component: () => (
          <MobilityTypeInput
            disabled={disabled}
            setField={setPassenger}
            vehicle_types={vehicle_types}
            mobilityType={np.mobilityType}
          />
        )
      }
    ],
    [
      {
        name: 'isAttendant',
        show: true,
        label: '',
        value: np.isAttendant,
        required: false,
        Component: [
          np.ageGroup === 'child' ? (
            <div key="isAttendant" style={{ minHeight: '32px' }}></div>
          ) : (
            <div key="isAttendant">
              <input
                type="checkbox"
                disabled={np.ageGroup === 'child'}
                checked={np.isAttendant}
                onChange={({ target: { checked } }) =>
                  setPassenger({ name: 'isAttendant', value: !!checked })
                }
              />{' '}
              <div className={`label ${np.ageGroup === 'child' ? 'disabled-text' : ''}`}>
                Attendant
              </div>
            </div>
          )
        ]
      }
    ]
  ];
  if (shouldUseSubMobilityType) {
    formInputsArr.splice(3, 0, [
      {
        label: '',
        name: 'subMobilityType',
        value: np.subMobilityType,
        required: false,
        Component: () => (
          <SubMobilityTypeInput
            disabled={disabled}
            setField={setPassenger}
            vehicle_types={vehicle_types}
            mobilityType={np.mobilityType}
            placeholder="Sub Mobility Type"
            subMobilityType={np.subMobilityType}
          />
        )
      }
    ]);
  }
  return formInputsArr;
};

export default rbfPassengerFormInputs;
