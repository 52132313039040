import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import SvgArrow from '../Svgs/SvgArrow.js';

const Sort = props => {
  const sort = dir => {
    const params = _.clone(props.defaultFields);

    //prevent unnecesary ajax calls
    if (params.orderType !== dir || params.orderBy !== props.orderByField) {
      params.orderType = dir;
      params.orderBy = props.orderByField;
      params.startLimit = 0;
      props.getRows(params);
    }
  };

  const sortUp = () => {
    sort('asc');
  };

  const sortDown = () => {
    sort('desc');
  };

  return (
    <p className="Sort">
      <a className="sortAsc" onClick={sortUp}>
        <SvgArrow title={`${props.orderByField} Sort Up`} className="svgArrow" />
      </a>
      <a className="sortDesc" onClick={sortDown}>
        <SvgArrow title={`${props.orderByField} Sort Down`} className="svgArrow" />
      </a>
    </p>
  );
};

Sort.propTypes = {
  orderByField: PropTypes.string,
  getRows: PropTypes.func,
  defaultFields: PropTypes.object
};

Sort.defaultProps = {
  orderByField: '',
  getRows: () => {},
  defaultFields: {}
};

export default Sort;
