import { Button, Drawer, Icon, Input, TOKENS } from '@SRHealth/frontend-lib';
import React, { useState } from 'react';
import Divider from './Divider';
import { useAppSelector } from '~/Modules';
import {
  addressFactory,
  type AddressApprovedProviderModel,
  type AddressApprovedProviderNotFoundModel,
  type AddressModel
} from '~/models';
import type { AddressFieldType } from '../Rides.types';
import googleBrand from '~/assets/brand-google.png';
import RideBookingMap, { useRideBookingMap } from './RideBookingMap';
import { addressToMarker } from '../Rides.utils';
import CareProviderForm from './ CareProviderForm';
import CareProviderUnapprovedForm from './CareProviderUnapprovedForm';
import { NOOP } from '~/utilities/helperFunctions';

type ProviderModelType =
  | AddressApprovedProviderModel
  | AddressApprovedProviderNotFoundModel;

export type CareProviderDrawerBodyProps = {
  onAddressChange: (type: AddressFieldType, address: AddressModel) => void;
};

export default function CareProviderDrawerBody({
  onAddressChange
}: CareProviderDrawerBodyProps) {
  const [searchIsOpen, setSearchIsOpen] = useState(true);
  const [markers, markerActions] = useRideBookingMap();
  const [address, setAddress] = useState<ProviderModelType>(
    addressFactory({ type: 'approved-provider' })
  );

  // We shouldn't be displaying this component if we don't have a facility ID.
  const hospitalId = useAppSelector(s => s.rideBooking.fundingSource.facilityId!);

  // If it's a return ride then default to 'depart', otherwise default to 'arrive'.
  const defaultType = useAppSelector(s =>
    s.rideBooking.rides.length > 1 ? 'depart' : 'arrive'
  );
  const [type, setType] = useState<'arrive' | 'depart'>(defaultType);

  /** Event handler for toggling between provider not found and approved provider. */
  const handleUnapprovedProviderToggle = (_, isToggledOn: boolean) => {
    const newAddressType = isToggledOn ? 'provider-not-found' : 'approved-provider';

    setAddress(addressFactory({ type: newAddressType }));
  };

  /** Event handler for swapping to a new address model. Used to swap between
   * approved provider and provider not found, as well as to update the markers
   * when lat/lng is changed on the addresses. */
  const handleAddressUpdate = (address: ProviderModelType) => {
    if (address.latitude && address.longitude) {
      markerActions.setMarkers([addressToMarker(address, type)]);
    }

    setAddress(address);

    if (searchIsOpen) setSearchIsOpen(false);
  };

  /** Event handler for applying the current address to the
   * ride booking form. */
  const handleApply = () => {
    if (!address) return;

    address
      .commit()
      .then(() => onAddressChange(type, address))
      .catch(NOOP);
  };

  return (
    <div className={`flex flex-row h-full`} style={{ overflow: 'auto' }}>
      <div
        className={`bg-${TOKENS.SURFACE.PRIMARY.XLT}`}
        style={{ boxShadow: '2px 2px 2px 0px rgba(0, 0, 0, 0.25)' }}
      >
        <section
          className={`reset-div flex flex-col gap-[12px] bg-${TOKENS.SURFACE.PRIMARY.WHITE}`}
          style={{ padding: '32px 32px 16px 32px', width: '460px' }}
        >
          {address?.type === 'provider-not-found' ? (
            <CareProviderUnapprovedForm
              address={address}
              onAddressUpdate={handleAddressUpdate}
            />
          ) : (
            <CareProviderForm
              type={type}
              address={address}
              hospitalId={hospitalId}
              onAddressUpdate={handleAddressUpdate}
            />
          )}

          <div className="flex justify-center">
            <Input
              inputId={1}
              name="type"
              type="radio"
              value={type}
              options={[
                { label: 'Depart', value: 'depart' },
                { label: 'Arrive', value: 'arrive' }
              ]}
              style={{ flex: '0.52' }}
              onChange={(_, v: 'arrive' | 'depart') => setType(v)}
            />
          </div>
          <Divider />
          <div className="flex justify-center py-[4px]" style={{ marginTop: '-12px' }}>
            <Button label="Apply" size="sm" minWidth="124px" onClick={handleApply} />
          </div>
          <div>
            <div className="flex flex-row items-center gap-[8px]">
              <Input
                inputId={2}
                type="toggle"
                size="sm"
                name="unapproved"
                onChange={handleUnapprovedProviderToggle}
              />
              <div className="flex-1 heading-base">
                Request An Unapproved Care Provider
              </div>
            </div>
            <div className="text-sm-italic">
              All <span style={{ fontWeight: '700' }}>Unapproved</span> care providers are
              subject to review and may be declined by the member&apos;s insurance
              provider.
            </div>
          </div>
        </section>
      </div>

      <div className="reset-div relative flex-1 overflow-hidden">
        <div className="relative h-full">
          <div
            onClick={() => setSearchIsOpen(true)}
            className={
              `absolute top-0 left-0 z-10 flex flex-row gap-[4px] shadow-b ` +
              ` cursor-pointer items-center bg-${TOKENS.SURFACE.PRIMARY.WHITE}`
            }
            style={{ borderRadius: '0 0 16px 0', zIndex: 10 }}
          >
            <img src={googleBrand} style={{ width: '72x', height: '24px' }} />
            <Icon
              type="ChevronRight"
              className={`w-[24px] fill-current text-${TOKENS.ICON.PRIMARY}`}
            />
          </div>
          <RideBookingMap.Provider value={[markers, markerActions]}>
            <RideBookingMap height="100%" />
          </RideBookingMap.Provider>
        </div>

        <Drawer
          isOpen={searchIsOpen}
          onClose={() => setSearchIsOpen(false)}
          side="left"
          width="640px"
          disableOverlay
        >
          <div className="flex flex-row py-[12px] px-[16px] shadow-b">
            <div className="cursor-pointer" onClick={() => setSearchIsOpen(false)}>
              <Icon type="ChevronLeft" className="w-[24px]" />
            </div>
            <div className="flex flex-col px-[16px] justify-center">
              <div className="heading-base">Search for a Care Provider</div>
              <div className="text-xsm">
                Doctor&apos;s name, followed by address of the facility.
              </div>
            </div>
          </div>

          <iframe
            className="h-full w-full"
            srcDoc='
              <script async src="https://cse.google.com/cse.js?cx=110accc7f259f4114"></script>
              <img src="/images/brand-google.png" alt="Google" style="width: 83.2px; height: 28px; padding-left: 21px; position: relative; bottom: -12px;">
              <div class="gcse-search"/>'
          >
            <img
              src={googleBrand}
              alt="Google"
              style={{ width: '83.2px', height: '28px', padding: '0 21px' }}
            />
          </iframe>
        </Drawer>
      </div>
    </div>
  );
}
