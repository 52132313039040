import _ from 'lodash-es';
import { fixUTCDate } from './timesAndDates';
import moment from 'moment';

/**
 * maps bookingData to the data format necessary for submitting to the api
 * @param {object} bookingData - bookingData from the form component
 * @param {object} rideData - rideData from scheduled rides
 * @param {boolean} submitTimeDataWithEditRide - do we or dont we submit date info
 * @param {int} recurringRideId - recurring ride id
 * @param {updateRide} updateRide - ride object returned form the editCheck endpoint
 * @return {object} - returns ride data to send to the edit ride
 */
export const getSubmittedData = (
  bookingData,
  rideData,
  submitTimeDataWithEditRide,
  recurringRideId,
  updateRide
) => {
  const submittedData = {
    medicalId: bookingData.medicalId,
    pPhoneno: bookingData.phone,
    phone2: bookingData.phone2,
    pFullName: bookingData.firstName,
    pLastName: bookingData.lastName,
    internalNotes: bookingData.internalNotes,
    additionalNotes: bookingData.additionalNotes,
    dateOfBirth: bookingData.dateOfBirth,
    transport: bookingData.transportType,
    rideEditPickupLoc: bookingData.pickupAddress,
    rideEditDropoffLoc: bookingData.dropoffAddress,
    rideId: rideData.id,
    companyId: rideData.vehiclOwnerId,
    editDatepick:
      rideData.appointmentTime === null
        ? rideData.rideStartTime
        : rideData.appointmentTime,
    bookingType: rideData.appointmentTime === null ? 'fromcare' : 'tocare',
    pickupLatitude:
      bookingData.pickupLatitude === ''
        ? rideData.fromLatitude
        : bookingData.pickupLatitude,
    pickupLongitude:
      bookingData.pickupLongitude === ''
        ? rideData.fromLongitude
        : bookingData.pickupLongitude,
    pickupZipcode: bookingData?.pickupZipcode,
    dropoffLatitude:
      bookingData.dropoffLatitude === ''
        ? rideData.toLatitude
        : bookingData.dropoffLatitude,
    dropoffLongitude:
      bookingData.dropoffLongitude === ''
        ? rideData.toLongitude
        : bookingData.dropoffLongitude,
    dropoffZipcode: bookingData?.dropoffZipcode,
    hospitalId: rideData.hospitalId,
    dropoffVenueId: bookingData.dropoff_venue_id,
    pickupVenueId: bookingData.pickup_venue_id,
    pastRide: bookingData.pastRide,
    additionalPassengers: bookingData.additionalPassengers,
    pickupProviderId: bookingData.pickupProviderId || 0,
    dropoffProviderId: bookingData.dropoffProviderId || 0,
    isPastRide: rideData.isPastRide,
    pickupProviderName: bookingData.pickupProviderName || '',
    dropoffProviderName: bookingData.dropoffProviderName || '',
    pickupProviderNpi: bookingData.pickupProviderNpi || 0,
    dropoffProviderNpi: bookingData.dropoffProviderNpi || 0,
    pickupProviderPhone: bookingData.pickupProviderPhone || '',
    dropoffProviderPhone: bookingData.dropoffProviderPhone || '',
    pickupProviderNotFound: bookingData.pickupProviderNotFound ?? false,
    dropoffProviderNotFound: bookingData.dropoffProviderNotFound ?? false,
    pickupFacilityName: bookingData.pickupFacilityName || '',
    dropoffFacilityName: bookingData.dropoffFacilityName || '',
    pickupProviderAddr: bookingData.pickupProviderAddr || '',
    dropoffProviderAddr: bookingData.dropoffProviderAddr || '',
    pickupAdditionalNotes: bookingData.pickupAdditionalNotes || '',
    dropoffAdditionalNotes: bookingData.dropoffAdditionalNotes || '',
    fromMemberSavedAddressId: bookingData.fromMemberSavedAddressId,
    toMemberSavedAddressId: bookingData.toMemberSavedAddressId,
    treatmentId: bookingData.treatmentId || 0,
    treatmentName: bookingData.treatmentName || '',
    transportType: bookingData.transportType || rideData.reqVehicleType,
    ...(updateRide?.run_id && { run_id: updateRide?.run_id }),
    ...(updateRide?.product_id && { product_id: updateRide?.product_id }),
    ...(updateRide?.fare_id && { fare_id: updateRide?.fare_id }),
    ...(updateRide?.cost_token && { cost_token: updateRide?.cost_token })
  };

  bookingData.pickupZipcode ??= bookingData?.pickupZip ?? rideData?.fromZipcode;
  bookingData.dropoffZipcode ??= bookingData?.dropoffZip ?? rideData?.toZipcode;

  if (bookingData?.apptTime) {
    if (bookingData.apptTime === 'Will Call') {
      submittedData.editDatepick = `${bookingData.selectedDate} 23:59`;
    } else {
      const subTime = moment(`${bookingData.selectedDate} ${bookingData.apptTime}`);
      submittedData.editDatepick = subTime.format('YYYY-MM-DD HH:mm:ss');
    }
  }

  if (bookingData?.dropoff_venue_id) {
    submittedData.dropoffVenueName = bookingData.dropoffVenueName;
    submittedData.dropoffEntranceName = bookingData.dropoffEntranceName;
  }

  if (bookingData?.pickup_venue_id) {
    submittedData.pickupVenueName = bookingData.pickupVenueName;
    submittedData.pickupEntranceName = bookingData.pickupEntranceName;
  }

  if (!_.isNil(bookingData.patientCustomFields)) {
    submittedData.patientCustomFields = bookingData.patientCustomFields;
  }

  if (!_.isNil(bookingData.rideCustomFields)) {
    submittedData.rideCustomFields = bookingData.rideCustomFields;
  }

  if (!_.isNil(bookingData.compliance)) {
    submittedData.compliance = bookingData.compliance;
  }

  if (submitTimeDataWithEditRide) {
    submittedData.recurringid = recurringRideId === null ? 0 : recurringRideId;
    submittedData.ride_applyonall = 0;
    submittedData.rideType = 'rides';
    submittedData.extraTime = bookingData.extraTime;
    submittedData.updatedTime = `${bookingData.selectedDate} ${bookingData.apptTime}`;
  }

  if (!_.isNil(bookingData.transportSubType)) {
    submittedData.transportSubType = bookingData.transportSubType;
  }

  if (!_.isNil(bookingData.assistanceNeeds)) {
    submittedData.assistanceNeeds = bookingData.assistanceNeeds;
  }

  return submittedData;
};

type FormattedRideTime = {
  date: string | moment.Moment;
  time: string | moment.Moment;
  timeTz: string | moment.Moment;
  bookingType: string;
  recommendedPickup?: string | moment.Moment;
  recommendedPickupTz?: string | moment.Moment;
};

/**
 * get date and format it
 * @param {object} ride - scheduled ride
 * @return {object} returns formatted date and time json object
 */
export const getRideTime = ride => {
  const appointmentTime = _.get(ride, 'appointmentTime', null);
  const rideStartTime = _.get(ride, 'rideStartTime', null);
  let timeZone = _.get(ride, 'passengerTimezone', '');
  if (timeZone === '') {
    timeZone = _.get(ride, 'passenger.timezone_format', 'UTC');
  }
  const timeToUse =
    appointmentTime === null || appointmentTime === '' ? rideStartTime : appointmentTime;
  const bookingType =
    appointmentTime === null || appointmentTime === '' ? 'fromcare' : 'tocare';
  const dateFormatted: FormattedRideTime = {
    date: fixUTCDate(timeToUse, timeZone, 'MM/DD/YYYY'),
    time: fixUTCDate(timeToUse, timeZone, 'hh:mm a'),
    timeTz: fixUTCDate(timeToUse, timeZone, 'hh:mm a z'),
    bookingType
  };

  if (appointmentTime !== null) {
    dateFormatted.recommendedPickup = fixUTCDate(rideStartTime, timeZone, 'hh:mm a');
    dateFormatted.recommendedPickupTz = fixUTCDate(rideStartTime, timeZone, 'hh:mm a z');
  }

  // check ride status so time is displayed accurately. if it's a will call ride display 'Will Call'
  const status = _.get(ride, 'status', '');
  if (status === 'WillCall') {
    dateFormatted.timeTz = 'Will Call';
    dateFormatted.time = 'Will Call';
  }

  return dateFormatted;
};

/**
 * return transportation type fields
 * @param {string} userRole - user role
 * @param {integer} hospitalId - hospital id
 * @param {object} availableVehicleTypes - vehicle types from user api
 * @return {array} - returns custom fields
 */
export const getTransportationType = (userRole, hospitalId, availableVehicleTypes) => {
  if (userRole === 'HospitalOwner' || userRole === 'HospitalNetworkManager') {
    if (!_.isNil(availableVehicleTypes[hospitalId])) {
      return availableVehicleTypes[hospitalId];
    }
    return [];
  }
  return availableVehicleTypes;
};

/**
 * get scheduled ride data and convert it to booking data form for editing rides
 * and reassigning unable to fulfill rides to another NEMT
 * @param {object} ride - ride
 * @param {object} selectedTransport - selected transportation for ride
 * @param {boolean} nemtReassign - is it a lyft reassign, if so split off ride into array
 * @return {object} return bookingData format
 */
export const getBookingDataFromScheduledRide = (
  ride,
  selectedTransport,
  nemtReassign = false
) => {
  const rideTime = getRideTime(ride);

  let driverName = !_.isNil(ride.driver)
    ? `${ride.driver.firstName} ${ride.driver.lastName}`
    : null;
  let driverPhone = !_.isNil(ride.driver) ? ride.driver.mobileNo : null;
  let avatar = _.get(ride, 'driver.avatar', null);
  let vehicleOwnerRating = ride.vehicleOwnerRating;
  if (_.has(ride, 'lyftDetails')) {
    driverName = _.get(ride, 'lyftDetails.ride_data.driver.first_name', null);
    driverPhone = _.get(ride, 'lyftDetails.ride_data.driver.phone_number', null);
    avatar = _.get(ride, 'lyftDetails.ride_data.driver.image_url', null);
    vehicleOwnerRating = _.get(ride, 'lyftDetails.ride_data.driver.rating', null);
  } else if (_.has(ride, 'uberDetails')) {
    driverName = _.get(ride, 'uberDetails.ride_data.driver.name', null);
    driverPhone = _.get(ride, 'uberDetails.ride_data.driver.phone_number', null);
    avatar = _.get(ride, 'uberDetails.ride_data.driver.picture_url', null);
    vehicleOwnerRating = _.get(ride, 'uberDetails.ride_data.driver.rating', null);
  }

  const bookData: Partial<BookingDataStore> = {
    id: ride.id,
    firstName: ride.passengerFirstname,
    lastName: ride.passengerLastname,
    internalNotes: ride.internalNotes,
    additionalNotes: ride.additionalNotes,
    phone: ride.pmobileNo,
    phone2: _.get(ride, 'ride_options.phone2', null),
    dateOfBirth: _.isNil(ride.dateOfBirth)
      ? ''
      : moment(ride.dateOfBirth, 'YYYY-MM-DD').format('MM/DD/YYYY'),
    pickupAddress: ride.fromAddress,
    pickupLatitude: ride.fromLatitude,
    pickupLongitude: ride.fromLongitude,
    pickupZipcode: ride.fromZipcode,
    dropoffAddress: ride.toAddress,
    dropoffLatitude: ride.toLatitude,
    dropoffLongitude: ride.toLongitude,
    dropoffZipcode: ride.toZipcode,
    transportType: ride.reqVehicleType,
    transportTypeName: selectedTransport?.name ?? '',
    hospitalId: ride.hospitalId,
    medicalId: ride.medicalId,
    apptTime: rideTime.time as string,
    apptTimeTz: rideTime.timeTz as string,
    selectedDate: rideTime.date as string,
    bookingType: rideTime.bookingType,
    avatar: ride.avatar ?? avatar,
    driverName: ride.driverName ?? driverName,
    driverPhone: ride.driverPhone ?? driverPhone,
    cost: ride.cost,
    distance: ride.distance,
    companyName: ride.companyname ?? _.get(ride, 'vehicle_owner.companyName', null),
    vehicleOwnerRating: ride.vehicleOwnerRating ?? vehicleOwnerRating,
    pastRide: ride.pastRide || false,
    isPastRide: ride.isPastRide || false,
    totalCost: ride.totalCost ?? null,
    dropoff_venue_id: ride.dropoff_venue_id,
    pickup_venue_id: ride.pickup_venue_id,
    pickupEntranceName: ride.pickupEntranceName,
    pickupVenueName: ride.pickupVenueName,
    dropoffEntranceName: ride.dropoffEntranceName,
    dropoffVenueName: ride.dropoffVenueName,
    dropoffProviderId: ride.dropoff_provider_id
      ? ride.dropoff_provider_id
      : ride.dropoffProviderId
        ? ride.dropoffProviderId
        : 0,
    pickupProviderId: ride.pickup_provider_id
      ? ride.pickup_provider_id
      : ride.pickupProviderId
        ? ride.pickupProviderId
        : 0,
    dropoffProviderName: ride.dropoffProviderName || '',
    dropoffProviderNpi: ride.dropoffProviderNpi || 0,
    pickupProviderName: ride.pickupProviderName || '',
    pickupProviderNpi: ride.pickupProviderNpi || 0,
    pickupProviderPhone: ride.pickupProviderPhone || '',
    dropoffProviderPhone: ride.dropoffProviderPhone || '',
    pickupFacilityName: ride.pickupFacilityName || '',
    dropoffFacilityName: ride.dropoffFacilityName || '',
    pickupAdditionalNotes: ride.pickupAdditionalNotes || '',
    dropoffAdditionalNotes: ride.dropoffAdditionalNotes || '',
    fromMemberSavedAddressId: ride?.fromMemberSavedAddressId ?? null,
    toMemberSavedAddressId: ride?.toMemberSavedAddressId ?? null,
    pickupAddressName: ride?.pickupAddressName ?? null,
    dropoffAddressName: ride?.dropoffAddressName ?? null,
    healthPlanId: _.get(ride, 'health_plan_id', null),
    healthSubPlanId: _.get(ride, 'health_sub_plan_id', null),
    additionalPassengers: ride.additionalPassengers || [],
    treatmentId: ride.treatment_id || '',
    treatmentName: ride.treatment_name || '',
    mode: ride.mode
  };

  if (ride.complianceValues) {
    bookData.compliance = ride.complianceValues;
  }

  if (ride.rideCustomFieldValues) {
    bookData.rideCustomFields = ride.rideCustomFieldValues;
  }

  if (ride.patientCustomFieldValues) {
    bookData.patientCustomFields = ride.patientCustomFieldValues;
  }

  if (ride.modelName) {
    bookData.modelName = ride.modelName;
  }

  if (ride.tripRides) {
    bookData.tripRides = ride.tripRides;
  }

  const transportSubType = _.get(ride, 'ride_options.vehicle_sub_type', null);
  if (!_.isNil(transportSubType)) {
    bookData.transportSubType = transportSubType;

    if (selectedTransport) {
      const transportSubTypeName = _.find(selectedTransport.subTypes, {
        id: transportSubType
      });
      bookData.transportSubTypeName = !_.isNil(transportSubTypeName)
        ? transportSubTypeName.name
        : '';
    }
  }

  const assistanceNeeds = _.get(ride, 'ride_options.assistance_needs', null);
  if (!_.isNil(assistanceNeeds)) {
    bookData.assistanceNeeds = assistanceNeeds;
  }

  if (rideTime?.recommendedPickup) {
    bookData.recommendedPickup = rideTime.recommendedPickup as string;
    bookData.recommendedPickupTz = rideTime.recommendedPickupTz as string;
  }

  if (ride.recurring_rideId !== null && ride.recurringsummary !== null) {
    bookData.repeatAppointmentData = {
      repeatApptText: ride.recurringsummary
    };
  }

  if (_.has(ride, 'extraTime')) {
    bookData.extraTime = ride.extraTime;
  }

  if (_.has(ride, 'status')) {
    bookData.status = ride.status;
  }

  if (ride.status === 'WillCall') {
    bookData.apptTime = 'Will Call';
  }

  // reassign nemt for unable to fulfill scenario
  if (nemtReassign) {
    const rideBookingData: Partial<BookingDataStore> = _.pick(bookData, [
      'pickupAddress',
      'pickupLatitude',
      'pickupLongitude',
      'pickupZipcode',
      'dropoffAddress',
      'showPickupEntrance',
      'showDropoffEntrance',
      'pickupEntranceName',
      'pickupVenueName',
      'dropoffEntranceName',
      'dropoffVenueName',
      'dropoffLatitude',
      'dropoffLongitude',
      'dropoffZipcode',
      'transportType',
      'additionalNotes',
      'legCompleted',
      'transportTypeName',
      'rideCustomFields',
      'timeCustomFields',
      'apptTime',
      'apptEnds',
      'pickupVenueId',
      'dropoffVenueId',
      'selectedDate',
      'bookingType',
      'assistanceNeeds',
      'transportSubType',
      'transportSubTypeName'
    ]);
    rideBookingData.rideId = ride.id;
    bookData.rides = [rideBookingData as RideData];
    bookData.currentRideIndex = 0;
  }

  if (bookData.dropoffFacilityName) {
    bookData.dropoffProviderAddr = bookData.dropoffAddress;
    bookData.showDropoffApprovedProvider = true;
    bookData.dropoffProviderNotFound = true;
  }

  if (bookData.pickupFacilityName) {
    bookData.pickupProviderAddr = bookData.pickupAddress;
    bookData.showPickupApprovedProvider = true;
    bookData.pickupProviderNotFound = true;
  }

  const passenger = ride?.passenger ?? false;
  if (passenger) {
    bookData.firstName = passenger.firstName;
    bookData.lastName = passenger.lastName;
    bookData.additionalNotes = ride.additionalNotes;
    bookData.phone = passenger.mobileNo;
    bookData.dob = passenger.dateOfBirth;
    bookData.medicalId = passenger.medicalId;
    bookData.internalNotes = passenger.internalNotes;

    if (!bookData.dateOfBirth && passenger.dateOfBirth) {
      bookData.dateOfBirth = moment(passenger.dateOfBirth, 'YYYY-MM-DD').format(
        'MM/DD/YYYY'
      );
    }
  }

  return bookData;
};

/**
 * get health plan id
 * @param {object} ride - ride object
 * @param {object} userData - user data
 * @return {any} - returns health plan id or null
 */
export const retrieveHealthPlanId = (ride, userData) => {
  const healthPlans = _.get(userData, 'healthPlans', []);
  const healthPlanData = _.find(healthPlans, {
    healthSubPlanId: ride.health_sub_plan_id
  });
  if (typeof healthPlanData !== 'undefined') {
    return healthPlanData.healthPlanId;
  }
  return null;
};

/**
 * Utility to compare ride booking data and determine if any address fields
 * have been updated and should be communicated
 * @param {object} oldData The Old ride booking data
 * @param {object} newData  The new ride booking data
 * @returns {bool} True if updated
 */
export const didBookingDataUpdate = (oldData, newData) => {
  const addressFields = [
    'dropoffAdditionalNotes',
    'dropoffAddress',
    'dropoffEntranceName',
    'dropoffFacilityName',
    'dropoffLatitude',
    'dropoffLongitude',
    'dropoffProviderId',
    'dropoffProviderName',
    'dropoffProviderNotFound',
    'dropoffProviderNpi',
    'dropoffProviderPhone',
    'dropoffShowApprovedProvider',
    'dropoffVenueName',
    'dropoffZipcode',
    'pickupAdditionalNotes',
    'pickupAddress',
    'pickupEntranceName',
    'pickupFacilityName',
    'pickupLatitude',
    'pickupLongitude',
    'pickupProviderId',
    'pickupProviderName',
    'pickupProviderNpi',
    'pickupProviderPhone',
    'pickupVenueName',
    'pickupZipcode',
    'showDropoffApprovedProvider',
    'showPickupApprovedProvider',
    'fromMemberSavedAddressId',
    'toMemberSavedAddressId'
  ];

  let addressUpdated = false;
  addressFields.every(field => {
    // eslint-disable-next-line eqeqeq
    if (oldData[field] != newData[field] && (oldData[field] || newData[field])) {
      addressUpdated = true;

      if (process.env.REACT_APP_ENVIRONMENT === 'local') {
        let msg = `Detected field [${field}] has been updated`;

        if (typeof oldData[field] !== 'object' && typeof newData[field] !== 'object') {
          msg += `. ${oldData[field]} => ${newData[field]}`;
        }

        // eslint-disable-next-line no-console
        console.debug(msg);
      }
    }

    return !addressUpdated;
  });

  return addressUpdated;
};

/**
 * Utility to compare ride booking data and determine if any date or time fields
 * have been updated and should be communicated
 * @param {object} oldData The Old ride booking data
 * @param {object} newData  The new ride booking data
 * @returns {bool} True if updated
 */
export const didBookingTimeUpdate = (oldData, newData) => {
  const timeFields = [
    'apptTime',
    'selectedDate',
    'apptEnds',
    'timeCustomFields',
    'extraTime'
  ];

  let timeUpdated = false;
  timeFields.every(field => {
    // eslint-disable-next-line eqeqeq
    if (oldData[field] != newData[field]) {
      timeUpdated = true;

      if (
        process.env.REACT_APP_ENVIRONMENT === 'local' ||
        process.env.REACT_APP_ENVIRONMENT === 'sandbox'
      ) {
        let msg = `Detected field [${field}] has been updated`;

        if (typeof oldData[field] !== 'object' && typeof newData[field] !== 'object') {
          msg += `. ${oldData[field]} => ${newData[field]}`;
        }

        // eslint-disable-next-line no-console
        console.debug(msg);
      }
    }

    return !timeUpdated;
  });

  return timeUpdated;
};

/**
 * Unfortunately necessary utility to standardize some of the field prefixes
 * on ride data because we jump back and forth
 * @param {object} rideData The ride data
 * @returns {object} the updated rideData
 */
export const standardizeRideAddressFieldPrefixes = rideData => {
  const fields = ['Address', 'Latitude', 'Longitude'];

  fields.forEach(key => {
    standardizeRideFields(rideData, `from${key}`, `pickup${key}`);
    standardizeRideFields(rideData, `to${key}`, `dropoff${key}`);
  });

  standardizeRideFields(rideData, 'dropoffZipcode', 'toZipcode');
  standardizeRideFields(rideData, 'pickupZipcode', 'fromZipcode');
  standardizeRideFields(rideData, 'dropoff_provider_id', 'dropoffProviderId');
  standardizeRideFields(rideData, 'pickup_provider_id', 'pickupProviderId');
  standardizeRideFields(
    rideData,
    'from_member_saved_address_id',
    'fromMemberSavedAddressId'
  );
  standardizeRideFields(rideData, 'to_member_saved_address_id', 'toMemberSavedAddressId');

  return rideData;
};

/**
 * Takes two field aliases for the same value and makes sure they are both
 * set and equivalent on the rideData array
 * @param {object} rideData The ride object
 * @param {string} aliasOne The first alias for the field. e.g. pickup_provider_id
 * @param {string} aliasTwo The second alias for the same field e.g. pickupProviderId
 * @returns {void}
 */
export const standardizeRideFields = (rideData, aliasOne, aliasTwo) => {
  if (rideData[aliasOne]) {
    rideData[aliasTwo] = rideData[aliasOne];
  } else if (rideData[aliasTwo]) {
    rideData[aliasOne] = rideData[aliasTwo];
  }
};

/**
 * Utility to check the bookingData for ride data at the given index.
 * @param bookingData
 * @param index
 * @returns
 */
export const getIndexedRideDataFromBookingData = (
  bookingData: BookingDataStore,
  index = -1
) => {
  if (bookingData?.rides && index > -1) {
    return bookingData.rides[index] ?? {};
  } else {
    return bookingData ?? {};
  }
};

/**
 * Utility to retrieve the ride data using the current ride index
 * in the booking data.
 * @param {*} bookingData
 * @returns
 */
export const getCurrentRideDataFromBookingData = (bookingData: BookingDataStore) => {
  const currentRideIndex = bookingData?.currentRideIndex ?? -1;

  return getIndexedRideDataFromBookingData(bookingData, currentRideIndex);
};
