import { createTheme } from '@material-ui/core';
import type { AlertClassKey } from '@material-ui/lab/Alert';
import type { AlertTitleClassKey } from '@material-ui/lab/AlertTitle';
import type { AutocompleteClassKey } from '@material-ui/lab/Autocomplete';
import type { AvatarGroupClassKey } from '@material-ui/lab/AvatarGroup';
import type { PaginationClassKey } from '@material-ui/lab/Pagination';
import type { PaginationItemClassKey } from '@material-ui/lab/PaginationItem';
import type { RatingClassKey } from '@material-ui/lab/Rating';
import type { SkeletonClassKey } from '@material-ui/lab/Skeleton';
import type { SpeedDialClassKey } from '@material-ui/lab/SpeedDial';
import type { SpeedDialActionClassKey } from '@material-ui/lab/SpeedDialAction';
import type { SpeedDialIconClassKey } from '@material-ui/lab/SpeedDialIcon';
import type { TabListClassKey } from '@material-ui/lab/TabList';
import type { TabPanelClassKey } from '@material-ui/lab/TabPanel';
import type { ToggleButtonClassKey } from '@material-ui/lab/ToggleButton';
import type { ToggleButtonGroupClassKey } from '@material-ui/lab/ToggleButtonGroup';
import type { TreeItemClassKey } from '@material-ui/lab/TreeItem';
import type { TreeViewClassKey } from '@material-ui/lab/TreeView';

/**
 * Material UI doesn't support overriding for lab components in
 * MUI v4. The easiest way is to just manually override it with
 * this approach (which closely parallels how v5 handles it)
 *
 * [GitHub Issue](https://github.com/mui/material-ui/issues/19427)
 */
declare module '@material-ui/core/styles/overrides' {
  export interface ComponentNameToClassKey {
    MuiAlert: AlertClassKey;
    MuiAlertTitle: AlertTitleClassKey;
    MuiAutocomplete: AutocompleteClassKey;
    MuiAvatarGroup: AvatarGroupClassKey;
    MuiPagination: PaginationClassKey;
    MuiPaginationItem: PaginationItemClassKey;
    MuiRating: RatingClassKey;
    MuiSkeleton: SkeletonClassKey;
    MuiSpeedDial: SpeedDialClassKey;
    MuiSpeedDialAction: SpeedDialActionClassKey;
    MuiSpeedDialIcon: SpeedDialIconClassKey;
    MuiTabList: TabListClassKey;
    MuiTabPanel: TabPanelClassKey;
    MuiToggleButton: ToggleButtonClassKey;
    MuiToggleButtonGroup: ToggleButtonGroupClassKey;
    MuiTreeItem: TreeItemClassKey;
    MuiTreeView: TreeViewClassKey;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0074D9'
    }
  },
  typography: {
    fontFamily: ['Roboto', 'Noto Sans', 'Arial', 'sans-serif'].join(','),
    button: {
      textTransform: 'none'
    }
  },
  overrides: {
    MuiDialog: { paper: { borderRadius: '12px' } },
    MuiDialogTitle: { root: { padding: '8px 12px' } },
    MuiOutlinedInput: {
      root: { '&&[class*="Mui-disabled"]': { background: '#ededed' } },
      input: { padding: '6px 0 7px 14px' }
    },
    MuiAutocomplete: {
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"]': {
          padding: '3px 0'
        }
      }
    }
  }
});

export default theme;
