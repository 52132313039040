import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash-es';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import { statusNotifications, getClassName } from '~/utilities/helperFunctions';
import { truncateElipse } from '~/utilities/strings';
import {
  getAccessRestrictions,
  getAccessRestriction,
  updateAccessRestriction,
  clearRestrictionsPlan,
  updateMemberPortalConfig,
  PLANS_RESTRICTIONS_PLAN_UPDATE_SUCCESS,
  PLANS_RESTRICTIONS_PLAN_UPDATE_ERROR
} from '~/Modules/plans';
import BenefitsModal from '../BenefitsModal';
import SvgArrowBack from '~/Shared/Components/Svgs/SvgArrowBack';
import SvgArrowForward from '~/Shared/Components/Svgs/SvgArrowForward';
import SvgRadioSelected from '~/Shared/Components/Svgs/SvgRadioSelected';
import SvgRadioUnselected from '~/Shared/Components/Svgs/SvgRadioUnselected';

const DEFAULTS = {
  offset: 0,
  limit: 25
};

class PlanRestrictions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      totalPages: 1,
      showModal: false,
      isValidForm: false,
      permission: '',
      healthNetworkName: '',
      loading: false,
      editError: false
    };
  }

  componentDidMount() {
    this.getAccessRestrictions(_.clone(DEFAULTS));
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.healthNetwork, prevProps.healthNetwork) &&
      this.state.showModal &&
      this.props.type === PLANS_RESTRICTIONS_PLAN_UPDATE_SUCCESS
    ) {
      statusNotifications('Access Restriction has been updated.', 'success', 3000);
      this.setState({
        showModal: false,
        loading: false
      });
      this.props.clearRestrictionsPlan();
    }

    if (
      this.props.timestamp !== prevProps.timestamp &&
      this.props.type === PLANS_RESTRICTIONS_PLAN_UPDATE_ERROR
    ) {
      this.setState({
        loading: false,
        editError: true
      });
    }
  }

  /**
   * edit health network
   * @param {object} healthNetwork - individual health network
   * @return {undefined}
   */
  handleEditNetwork = healthNetwork => {
    if (!this.state.showModal) {
      const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
      this.props.getAccessRestriction({ subPlanId, id: healthNetwork.id });
      this.setState({
        showModal: true,
        permission: healthNetwork.permission,
        healthNetworkName: healthNetwork.name,
        editError: false
      });
    }
  };

  /**
   * Handle request to close modal
   * @param {SyntheticEvent} e React event object
   * @returns {undefined}
   */
  handleCloseModal = e => {
    e.stopPropagation();
    this.setState({ showModal: false });
    this.props.clearRestrictionsPlan();
  };

  /**
   * handlePermissionClick
   * @param {string} permission - view, edit, hidden
   * @return {undefined}
   */
  handlePermissionClick = permission => {
    this.setState({
      permission,
      isValidForm: true
    });
  };

  /**
   * handleSubmitBenefit - submit update of permission
   * @return {undefined}
   */
  handleSubmitBenefit = () => {
    const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
    const healthNetwork = this.props.healthNetwork;
    const permission = this.state.permission;
    const params = {
      subPlanId: subPlanId,
      id: healthNetwork.hospitalGroupId,
      permission
    };
    this.setState(
      {
        loading: true,
        editError: false
      },
      () => {
        this.props.updateAccessRestriction(params);
      }
    );
  };

  handleMemberPortalRadioClick = healthNetwork => {
    const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
    const params = {
      subPlanId: subPlanId,
      id: healthNetwork.id,
      permission: healthNetwork.permission,
      member_portal_network: 1
    };

    this.setState(
      {
        editError: false
      },
      () => {
        this.props.updateMemberPortalConfig(params);
      }
    );
  };

  /**
   * get all access restrictions
   * @param {object} params - data for getting all network managers
   * @return {undefined} - return nothing
   */
  getAccessRestrictions = params => {
    const id = _.get(this.props, 'selectedPlan.id', 0);
    if (id > 0) {
      params.subPlanId = id;
      this.props.getAccessRestrictions(params);
    }
  };

  /**
   * get all access restrictions with pagination updates
   * @param {object} params - data for getting all network managers
   * @return {undefined} - return nothing
   */
  getAccessRestrictionsPagination = params => {
    const updatedParams = {
      offset: params.startLimit,
      limit: params.limit
    };
    this.getAccessRestrictions(updatedParams);
  };

  render() {
    return (
      <div className="PlanRestrictions">
        <div className="innerTabWrapper">
          {this.state.showModal ? (
            <BenefitsModal
              showModal={this.state.showModal}
              handleCloseModal={this.handleCloseModal}
              title="Edit Hospital Network Permissions"
              showX={false}
            >
              <form onSubmit={e => e.preventDefault()}>
                <div className="formElement">
                  <label className="networkInputLabel">Hospital Network</label>
                  <input
                    type="text"
                    value={truncateElipse(this.state.healthNetworkName, 40, '...')}
                    disabled={true}
                    className="networkNameInput"
                  />
                </div>
                <div className="formElement restrictionsCont">
                  <button
                    onClick={() => this.handlePermissionClick('hidden')}
                    className={
                      this.state.permission === 'hidden'
                        ? 'restrictionButtons clicked'
                        : 'restrictionButtons'
                    }
                  >
                    Hidden
                  </button>
                  <button
                    onClick={() => this.handlePermissionClick('view')}
                    className={
                      this.state.permission === 'view'
                        ? 'restrictionButtons clicked'
                        : 'restrictionButtons'
                    }
                  >
                    Viewable
                  </button>
                  <button
                    onClick={() => this.handlePermissionClick('edit')}
                    className={
                      this.state.permission === 'edit'
                        ? 'restrictionButtons clicked'
                        : 'restrictionButtons'
                    }
                  >
                    Editable
                  </button>
                  <button
                    onClick={() => this.handlePermissionClick('create-edit')}
                    className={
                      this.state.permission === 'create-edit'
                        ? 'restrictionButtons clicked'
                        : 'restrictionButtons'
                    }
                  >
                    Edit and Add New Members
                  </button>
                </div>
              </form>
              {this.state.loading ? (
                <div className="benefitsModalSpinner">
                  <LoadingComponent width="64px" height="64px" />
                </div>
              ) : null}
              {this.state.editError ? (
                <div className="errorBlock">
                  <p>There was an issue editing the health network permissions.</p>
                </div>
              ) : null}
              <div className="buttons">
                <button className="cancel" onClick={this.handleCloseModal}>
                  Close
                </button>
                <button
                  className="submit"
                  disabled={!this.state.isValidForm}
                  onClick={this.handleSubmitBenefit}
                >
                  Update
                </button>
              </div>
            </BenefitsModal>
          ) : null}
          <table>
            <thead>
              <tr>
                <th>Health Network</th>
                {this.props.user.healthPlan.member_portal ? <th>Member Portal</th> : null}
                <th>Permission</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.props.restrictions.length === 0 ? (
                <tr>
                  <td colSpan="3">
                    <p className="noData">No data available in table</p>
                  </td>
                </tr>
              ) : (
                this.props.restrictions.map((healthNetwork, key) => {
                  return (
                    <tr key={key}>
                      <td>{healthNetwork.name}</td>
                      {this.props.user.healthPlan.member_portal ? (
                        <td>
                          <label htmlFor={`memberPortalHNId${healthNetwork.id}`}>
                            {healthNetwork.member_portal_network === 1 ? (
                              <SvgRadioSelected className="svgRadioSelected" />
                            ) : (
                              <SvgRadioUnselected className="svgRadioUnselected" />
                            )}
                            <input
                              id={`memberPortalHNId${healthNetwork.id}`}
                              className="memberPortalInput"
                              type="radio"
                              onClick={() =>
                                this.handleMemberPortalRadioClick(healthNetwork)
                              }
                            />
                          </label>
                        </td>
                      ) : null}
                      <td>
                        <span className="permission">
                          {
                            {
                              'view': 'Viewable',
                              'hidden': 'Hidden',
                              'edit': 'Editable',
                              'create-edit': 'Edit and Add New Members'
                            }[healthNetwork.permission]
                          }
                        </span>
                      </td>
                      <td>
                        <a
                          className="editSvgLink"
                          onClick={() => this.handleEditNetwork(healthNetwork)}
                        >
                          <SvgEdit />
                        </a>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="paginationRoot">
            <span className="counter">
              {this.props.params.offset + 1}-
              {this.props.params.offset + this.props.params.limit > this.props.totalCount
                ? this.props.totalCount
                : this.props.params.offset + this.props.params.limit}{' '}
              of {this.props.totalCount}
            </span>
            <button
              className={getClassName({
                prevPage: true,
                disabled: this.state.page === 1
              })}
              onClick={() => this.this.getAccessRestrictionsPagination}
            >
              <SvgArrowBack />
            </button>
            <button
              className={getClassName({
                nextPage: true,
                disabled: this.state.page === this.state.totalPages
              })}
              onClick={() => this.this.getAccessRestrictionsPagination}
            >
              <SvgArrowForward />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

PlanRestrictions.propTypes = {
  selectedPlan: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  getRestrictionsByPage: PropTypes.func,
  gotoImport: PropTypes.func,
  getAccessRestrictions: PropTypes.func,
  getAccessRestriction: PropTypes.func,
  updateAccessRestriction: PropTypes.func,
  clearRestrictionsPlan: PropTypes.func,
  error: PropTypes.object,
  restrictions: PropTypes.array,
  healthNetwork: PropTypes.object,
  type: PropTypes.string,
  totalCount: PropTypes.number,
  params: PropTypes.object,
  timestamp: PropTypes.number
};

PlanRestrictions.defaultProps = {
  selectedPlan: {},
  isSuperAdmin: false,
  getRestrictionsByPage: () => {},
  gotoImport: () => {},
  getAccessRestrictions: () => {},
  getAccessRestriction: () => {},
  updateAccessRestriction: () => {},
  clearRestrictionsPlan: () => {},
  error: {},
  restrictions: [],
  healthNetwork: {},
  type: '',
  totalCount: 0,
  params: DEFAULTS,
  timestamp: 0
};

const mapStateToProps = state => ({
  user: state.user,
  restrictions: state.plans.restrictions,
  error: state.plans.error,
  healthNetwork: state.plans.healthNetwork,
  type: state.plans.type,
  totalCount: state.plans.totalCount,
  params: state.plans.params,
  timestamp: state.plans.timestamp
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getAccessRestrictions: data => getAccessRestrictions(data),
      getAccessRestriction: data => getAccessRestriction(data),
      updateAccessRestriction: data => updateAccessRestriction(data),
      clearRestrictionsPlan: () => clearRestrictionsPlan(),
      updateMemberPortalConfig: data => updateMemberPortalConfig(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanRestrictions);
