import _ from 'lodash-es';

/**
 * Parse API results for subplans
 * @param   {Object} data  Raw data returned from API
 * @returns {Object}      Parsed result
 */
export function parseSubPlans(data) {
  const rows = _.get(data, 'subPlans.rows', []);
  const parsedData = {};

  rows.forEach(datum => {
    parsedData[datum.id] = parseSubPlan(datum);
  });

  return parsedData;
}

/**
 * Parse a health subplan into a standard property names and values
 */
export function parseSubPlan(datum) {
  return {
    id: datum.id,
    planName: datum.name,
    planId: datum.external_sub_plan_id,
    isEnabled: datum.is_active === 1,
    memberPortalEnabled: datum.member_portal === 1,
    treatmentRestrictionEnabled: datum.treatments === 1,
    treatmentsMemberPortalEnabled: datum.member_portal_treatments === 1,
    locationRestrictionEnabled: datum.locations === 1,
    approvedProvidersEnabled: datum.approved_providers === 1,
    approvedProvidersHardBlockEnabled: datum.approved_providers_hard_block === 1,
    treatmentCount: datum.health_sub_plan_treatment_count,
    locationCount: datum.health_sub_plan_location_count,
    meta: datum.rowMeta ? datum.rowMeta.importerJobs : null,
    updatedAt: datum.updated_at,
    claim_submission_types: datum.claim_submission_types
  };
}

/**
 * Parse benefits data into standard property names and values
 * @param   {Array}  benefitsData  Benefits
 * @returns {Object}           Parsed data
 */
export function parseBenefits(benefitsData) {
  if (benefitsData.rows) {
    const rows = benefitsData.rows.map(benefit => {
      return {
        id: benefit.id,
        restrictionTypeId: benefit.restriction_type_id,
        alertLimit: benefit.alert_limit,
        blockLimit: benefit.block_limit,
        bookAfterBlock: benefit.book_after_block === 1
      };
    });
    return { rows, meta: { totalCount: rows.length } };
  } else {
    return { rows: [], meta: { totalCount: 0 } };
  }
}

/**
 * Parse benefit category data into standard property names and values
 * @param   {Array}  benefitsData  Benefits
 * @returns {Object}           Parsed data
 */
export function parseBenefitCategory(benefitCategoryData) {
  if (benefitCategoryData.rows) {
    const rows = benefitCategoryData.rows.map(benefit => {
      return {
        id: benefit.id,
        restrictionTypeId: benefit.restriction_type_id,
        benefitCategoryTypeId: benefit.benefit_category_id,
        alertLimit: benefit.alert_limit,
        blockLimit: benefit.block_limit,
        bookAfterBlock: benefit.book_after_block === 1
      };
    });
    return { rows, meta: { totalCount: rows.length } };
  } else {
    return { rows: [], meta: { totalCount: 0 } };
  }
}

/**
 * Parse a benefit category list into standard property names and values
 */
export function parseBenefitCategoryList(benefitCategoryListData) {
  return Object.keys(benefitCategoryListData).reduce(
    (acc: { id: number; label: unknown }[], key) => {
      acc.push({ id: Number(key), label: benefitCategoryListData[key] });
      return acc;
    },
    []
  );
}

/**
 * Parse treatments data
 * @param {Object} treatmentData Treatments data
 * @returns {Object} Parsed data
 */
export function parseTreatments(treatmentData) {
  if (!treatmentData.rows) return { rows: [], meta: { totalCount: 0 } };

  const rows = treatmentData.rows.map(treatment => {
    return {
      id: treatment.treatmentCode,
      name: treatment.treatmentName,
      description: treatment.treatmentDescription,
      benefit_deduction: treatment.benefit_deduction,
      categories: treatment.categories
    };
  });

  return { rows, meta: treatmentData.meta };
}

/**
 * Parse Locations data
 * @param {Array} locationData Locations data
 * @returns {Object} Parsed data
 */
export function parseLocations(locationData) {
  if (!locationData.rows) return { rows: [], meta: { totalCount: 0 } };

  const rows = locationData.rows.map(location => {
    return {
      id: location.id,
      code: location.code,
      name: location.name,
      address: location.address,
      address2: location.address2,
      description: location.description
    };
  });
  return { rows, meta: locationData.meta };
}
