import { createSelector } from '@reduxjs/toolkit';
import type { AppStore } from '~/types';
import { selectSubPlan } from '~/Modules/memberProfile';
import type {
  RideBenefitsRecord,
  RideBenefitsUnlimitedRecord
} from '../RideBooking.types';
import { TXT } from '../RideBooking.constants';

const selectMemberProfileRideBenefits = (s: AppStore) =>
  s.memberProfile.formData.benefits.rideBenefit;

/** Retrieves the rides benefits for the member. Prioritizes displaying
 * rides per year and falling back to rides per month. If the plan is
 * Medicaid then nothing is returned. */
export const selectRideBenefits = createSelector(
  [selectSubPlan, selectMemberProfileRideBenefits],
  (subPlan, rideBenefits) => {
    if (subPlan?.benefitsProgram === 'medicaid') {
      return {
        label: TXT.RIDES_PER_YEAR_LABEL,
        limit: 'Unlimited'
      } as RideBenefitsUnlimitedRecord;
    }

    // Why yes, this is a very silly way to do this but the alternative is rewriting
    // how we store benefits on the FE and adding the time to do that correctly, as well
    // as QA the member profile that uses that data is too expensive for RBF 4.0.
    const yearlyBenefit = rideBenefits.find(
      benefit =>
        'rideLimit' in benefit && benefit.usageYear.toLowerCase().includes('rides')
    );

    if (yearlyBenefit) {
      return {
        label: TXT.RIDES_PER_YEAR_LABEL,
        limit: yearlyBenefit.rideLimit,
        usage: yearlyBenefit.rideUsage,
        alertAfter: yearlyBenefit.alertAfter || 0,
        hardBlock: yearlyBenefit.hardBlock ?? false
      } as RideBenefitsRecord;
    }

    const monthlyBenefit = rideBenefits.find(
      benefit =>
        'rideLimit' in benefit && benefit.usageMonth.toLowerCase().includes('rides')
    );

    if (monthlyBenefit) {
      return {
        label: TXT.RIDES_PER_MONTH_LABEL,
        limit: monthlyBenefit.rideLimit,
        usage: monthlyBenefit.rideUsage,
        alertAfter: monthlyBenefit.alertAfter || 0,
        hardBlock: monthlyBenefit.hardBlock ?? false
      } as RideBenefitsRecord;
    }
  }
);
