import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _, { cloneDeep } from 'lodash-es';
import { getClassName } from '~/utilities/helperFunctions';
import LoadingComponent from '~/Pages/App/Components/LoadingComponent';
import BenefitsModal from '../BenefitsModal';
import SvgEdit from '~/Shared/Components/Svgs/SvgEdit';
import SvgDelete from '~/Shared/Components/Svgs/SvgDelete';
import { getCustomFields, postCustomField, deleteCustomField } from '~/Modules/plans';

const SUBTAB_FREEFORM = 'freeform';
const SUBTAB_VALIDATION = 'validation';
const SUBTAB_NOTES = 'notes';
const TYPE_MAPPING = {
  freeform: 'string',
  validation: 'select',
  notes: 'text'
};

const TYPE_NAMES = {
  freeform: 'Free Form',
  validation: 'Validation',
  notes: 'Notes'
};

class PlanCustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showDelete: false,
      loading: false,
      editError: false,
      formValid: false,
      subtab: SUBTAB_FREEFORM,
      formData: {}
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
      this.getCustomFields(subPlanId);
    });
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(this.props.customFieldsLastLoad, prevProps.customFieldsLastLoad)) {
      this.setState({ loading: false, formData: {} });
    }
  }

  /**
   * Handle request to close modal
   * @param {SyntheticEvent} e React event object
   * @returns {undefined}
   */
  handleCloseModal = e => {
    e.stopPropagation();
    this.setState({
      showModal: false,
      formData: {}
    });
  };

  /**
   *Handles the closing of the delete modal
   * @param {SyntheticEvent} e  the click event
   * @returns {undefined}
   */
  handleCloseDelete = e => {
    e.stopPropagation();
    this.setState({ showDelete: false });
  };

  /**
   * Returns the count of custom fields per type
   * @memberof PlanCustomFields
   * @param {undefined} - Nothing
   * @returns {Number} The count of fields for the current selected type
   */
  typeCount = () => {
    let currentCount = 0;
    this.props.customFields.forEach(customField => {
      if (customField.field_type === TYPE_MAPPING[this.state.subtab]) {
        currentCount++;
      }
    });
    return currentCount;
  };

  /**
   * Opens the edit custom field modal
   *
   * @memberof PlanCustomFields
   * @param {SyntheticEvent} e The click event
   * @returns {undefined} -
   */
  handleOpenModal = e => {
    e.stopPropagation();
    if (this.state.subtab === SUBTAB_VALIDATION) {
      this.setState({
        showModal: true,
        formData: {
          selectors: ['']
        }
      });
    } else {
      this.setState({
        showModal: true,
        formValid: false
      });
    }
  };

  /**
   * Handles submitting of the edit modal
   * @memberof PlanCustomFields
   * @returns {undefined} -
   */
  handleSubmitModal = () => {
    const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
    const newData = cloneDeep(this.state.formData);
    this.props.postCustomField({
      ...newData,
      field_type: TYPE_MAPPING[this.state.subtab], //string, select, text
      subPlanId
    });
    this.setState({
      formData: {},
      loading: true,
      showModal: false
    });
  };

  /**
   * Opens the delete confirmation modal
   * @param {Number} id - The custom field id to confirm deletion
   * @returns {Function} - a function to update state
   */
  showDelete = id => {
    return () => {
      this.setState({
        showDelete: id
      });
    };
  };

  /**
   * get all custom fields
   * @param {number} subPlanId - The SubPlan to load the data for
   * @return {undefined} - return nothing
   */
  getCustomFields = subPlanId => {
    this.props.getCustomFields({
      subPlanId
    });
  };

  /**
   * Triggers an action to delete a custom field
   * @param {Number} id The custom field to delete
   * @returns {Function} - a function to update state and kick off delete action
   */
  deleteCustomField = id => {
    return () => {
      const subPlanId = _.get(this.props, 'selectedPlan.id', 0);
      this.props.deleteCustomField({
        subPlanId,
        id
      });
      this.setState({ showDelete: false });
    };
  };

  /**
   * Triggers action to modify a custom field
   * @param {Number} fieldId - The id of the field to edit
   * @returns {Function} - A function that will update the state
   */
  editCustomField = fieldId => {
    return () => {
      const foundRecord = _.find(this.props.customFields, record => {
        return record.id === fieldId;
      });
      if (typeof foundRecord.selectors !== 'undefined') {
        foundRecord.selectors = foundRecord.selectors.map(selector => {
          return selector.label;
        });
      }
      this.setState({
        showModal: true,
        formData: foundRecord,
        formValid: true
      });
    };
  };

  /**
   * Handles switching tabs when a tab is clicked
   * @param {String} subtab The tab that was clicked
   * @returns {Function} - a function to change component state
   */
  handleSubTab = subtab => {
    return () => {
      this.setState({ subtab, formData: {} });
    };
  };

  /**
   * Dynamically updates component state with live edits from the modal
   * @param {string} element The element to update
   * @returns {Function} The function that updates the state
   */
  formUpdate = element => {
    return e => {
      const newFormData = cloneDeep(this.state.formData);
      _.set(newFormData, element, e.target.value);
      if (element === 'label') {
        if (e.target.value.length === 0) {
          this.setState({
            formValid: false
          });
        } else {
          this.setState({
            formValid: true
          });
        }
      }
      this.setState({
        formData: newFormData
      });
    };
  };

  /**
   * Adds a blank option to form data
   * @returns {undefined}
   */
  addOption = () => {
    const newFormData = cloneDeep(this.state.formData);
    newFormData.selectors.push('');
    this.setState({
      formData: newFormData
    });
  };

  /**
   * Removes an option from form data
   * @param {Number} index - the id to remove
   * @returns {Function} Function to update component state
   */
  removeOption = index => {
    return () => {
      const newFormData = cloneDeep(this.state.formData);
      if (typeof index === 'undefined') {
        newFormData.selectors.pop();
      } else if (index === 'all') {
        newFormData.selectors = [];
      } else {
        delete newFormData.selectors[index];
      }
      const newSelectors = cloneDeep(newFormData.selectors);
      newFormData.selectors = [];
      newSelectors.forEach(selector => {
        //Reindex arr
        if (typeof selector !== 'undefined') {
          newFormData.selectors.push(selector);
        }
      });
      this.setState({
        formData: newFormData
      });
    };
  };

  render() {
    let recordsListed = 0;
    if (this.state.loading || this.props.customFields === null) {
      return <LoadingComponent />;
    }
    return (
      <div className="PlanCustomFields">
        <ul className="subTabs">
          <li
            className={getClassName({
              planSubTab: true,
              selected: this.state.subtab === SUBTAB_FREEFORM
            })}
            onClick={this.handleSubTab(SUBTAB_FREEFORM)}
          >
            Free Form
          </li>
          <li
            className={getClassName({
              planSubTab: true,
              selected: this.state.subtab === SUBTAB_VALIDATION
            })}
            onClick={this.handleSubTab(SUBTAB_VALIDATION)}
          >
            Validation
          </li>
          <li
            className={getClassName({
              planSubTab: true,
              selected: this.state.subtab === SUBTAB_NOTES
            })}
            onClick={this.handleSubTab(SUBTAB_NOTES)}
          >
            Notes
          </li>
        </ul>
        <div className="subTabContent">
          <table className={'subTabTable'}>
            <thead>
              <tr>
                <th>Field Name</th>
                <th colSpan={this.typeCount() === 0 ? 1 : 2} className={'actionHeader'}>
                  <button
                    className="CTA"
                    disabled={this.typeCount() >= 20}
                    style={{ cursor: this.typeCount() >= 20 ? 'default' : 'pointer' }}
                    onClick={this.handleOpenModal}
                  >
                    Add New Field
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>
              {_.compact(_.get(this.props, 'customFields', [])).map(customField => {
                if (customField.field_type !== TYPE_MAPPING[this.state.subtab]) {
                  return null;
                }
                recordsListed++;
                if (customField.health_sub_plan_id === null) {
                  return (
                    <tr key={customField.id} className="disabled">
                      <td>{customField.label}</td>
                      <td className={'actionHalf edit'}>
                        <button disabled>
                          <SvgEdit className={'tableIcon disabled'} />
                        </button>
                      </td>
                      <td className={'actionHalf delete'}>
                        <button disabled>
                          <SvgDelete className={'tableIcon disabled'} />
                        </button>
                      </td>
                    </tr>
                  );
                } else {
                  return (
                    <tr key={customField.id}>
                      <td>{customField.label}</td>
                      <td className={'actionHalf edit'}>
                        <button onClick={this.editCustomField(customField.id)}>
                          <SvgEdit className={'tableIcon'} />
                        </button>
                      </td>
                      <td className={'actionHalf delete'}>
                        <button onClick={this.showDelete(customField.id)}>
                          <SvgDelete className={'tableIcon'} />
                        </button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </table>
          <>
            {recordsListed === 0 ? (
              <div className="belowContent">
                <div>
                  You don&apos;t have any {TYPE_NAMES[this.state.subtab]} Custom Fields.
                </div>
                <div>You can create up to 20.</div>
              </div>
            ) : null}
          </>

          {
            {
              [SUBTAB_FREEFORM]: (
                <>
                  {this.state.showModal ? (
                    <BenefitsModal
                      showModal={this.state.showModal}
                      handleCloseModal={this.handleCloseModal}
                      title="Add New Field"
                      showX={true}
                    >
                      <p>
                        <strong>NOTE: </strong>
                        Configurations made at the sub health plan level will only be
                        available for this sub health plan.
                      </p>
                      <form className={'modalForm'} onSubmit={e => e.preventDefault()}>
                        <div className="formElement" style={{ height: '150px' }}>
                          <label className="customFieldInputLabel">Field Name</label>
                          <input
                            type="text"
                            maxLength={30}
                            className="labelInput"
                            value={_.get(this.state, 'formData.label', '')}
                            onChange={this.formUpdate('label')}
                          />
                        </div>
                      </form>
                      <div className="buttons footer">
                        <button className="exit" onClick={this.handleCloseModal}>
                          Cancel
                        </button>
                        <button
                          className="CTA"
                          onClick={this.handleSubmitModal}
                          disabled={this.state.formValid === false}
                        >
                          Save
                        </button>
                      </div>
                    </BenefitsModal>
                  ) : null}
                </>
              ),
              [SUBTAB_VALIDATION]: (
                <>
                  {this.state.showModal ? (
                    <BenefitsModal
                      showModal={this.state.showModal}
                      handleCloseModal={this.handleCloseModal}
                      title="Add New Field"
                      showX={true}
                    >
                      <p>
                        <strong>NOTE: </strong>
                        Configurations made at the sub health plan level will only be
                        available for this sub health plan.
                      </p>
                      <form className={'modalForm'} onSubmit={e => e.preventDefault()}>
                        <div className="formElement">
                          <label className="customFieldInputLabel">Field Name</label>
                          <input
                            type="text"
                            className="labelInput"
                            maxLength={30}
                            value={_.get(this.state, 'formData.label', '')}
                            onChange={this.formUpdate('label')}
                          />
                        </div>
                        <div>&nbsp;</div>
                        <div>
                          <ul>
                            {_.get(this.state, 'formData.selectors', []).map(
                              (selector, selectorId) => {
                                return (
                                  <li key={selectorId}>
                                    <div className={'formElement'}>
                                      <label className="customFieldInputLabel">
                                        Field Option
                                      </label>
                                      <input
                                        type="text"
                                        className="labelInput"
                                        maxLength={30}
                                        onChange={this.formUpdate(
                                          `selectors.${selectorId}`
                                        )}
                                        value={_.get(
                                          this.state,
                                          `formData.selectors.${selectorId}`,
                                          ''
                                        )}
                                      />{' '}
                                      <button
                                        className={'optionsIcon'}
                                        onClick={this.removeOption(selectorId)}
                                      >
                                        <SvgDelete />
                                      </button>
                                    </div>
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                        <div className="buttons">
                          <button className={'primary'} onClick={this.addOption}>
                            Add Option
                          </button>
                          <button className={'warning'} onClick={this.removeOption()}>
                            Remove
                          </button>
                          <button className={'gray'} onClick={this.removeOption('all')}>
                            Remove All
                          </button>
                        </div>
                      </form>
                      <div className="buttons footer">
                        <button className="exit" onClick={this.handleCloseModal}>
                          Cancel
                        </button>
                        <button
                          className="CTA"
                          onClick={this.handleSubmitModal}
                          disabled={this.state.formValid === false}
                        >
                          Save
                        </button>
                      </div>
                    </BenefitsModal>
                  ) : null}
                </>
              ),
              [SUBTAB_NOTES]: (
                <>
                  {this.state.showModal ? (
                    <BenefitsModal
                      showModal={this.state.showModal}
                      handleCloseModal={this.handleCloseModal}
                      title="Add New Field"
                      showX={true}
                    >
                      <p>
                        <strong>NOTE: </strong>
                        Configurations made at the sub health plan level will only be
                        available for this sub health plan.
                      </p>

                      <form className={'modalForm'} onSubmit={e => e.preventDefault()}>
                        <div className="formElement" style={{ height: '150px' }}>
                          <label className="customFieldInputLabel">Field Name</label>
                          <input
                            type="text"
                            maxLength={30}
                            className="labelInput"
                            value={_.get(this.state, 'formData.label', '')}
                            onChange={this.formUpdate('label')}
                          />
                        </div>
                      </form>
                      <div className="buttons footer">
                        <button className="exit" onClick={this.handleCloseModal}>
                          Cancel
                        </button>
                        <button
                          className="CTA"
                          onClick={this.handleSubmitModal}
                          disabled={this.state.formValid === false}
                        >
                          Save
                        </button>
                      </div>
                    </BenefitsModal>
                  ) : null}
                </>
              )
            }[this.state.subtab]
          }
          <>
            {this.state.showDelete !== false ? (
              <BenefitsModal
                showModal={this.state.showDelete !== false}
                handleCloseModal={this.handleCloseDelete}
                title="Are you sure you want to delete?"
                showX={true}
              >
                <p>
                  <strong>NOTE: </strong>
                  Configurations made at the sub health plan level will only be available
                  for this sub health plan.
                </p>

                <form className={'modalForm'} onSubmit={e => e.preventDefault()}>
                  <div className="formElement" style={{ height: '120px' }}>
                    All data stored in this field for your members will be permanently
                    deleted.
                  </div>
                </form>
                <div className="buttons">
                  <button className="exit" onClick={this.handleCloseDelete}>
                    Cancel
                  </button>
                  <button
                    className="CTA"
                    onClick={this.deleteCustomField(this.state.showDelete)}
                  >
                    Delete
                  </button>
                </div>
              </BenefitsModal>
            ) : null}
          </>
        </div>
      </div>
    );
  }
}

PlanCustomFields.propTypes = {
  selectedPlan: PropTypes.object,
  isSuperAdmin: PropTypes.bool,
  getCustomFields: PropTypes.func,
  postCustomField: PropTypes.func,
  deleteCustomField: PropTypes.func,
  customFields: PropTypes.array,
  error: PropTypes.object,
  params: PropTypes.object,
  customFieldsLastLoad: PropTypes.number
};

PlanCustomFields.defaultProps = {
  selectedPlan: {},
  isSuperAdmin: false,
  getCustomFields: () => {},
  postCustomField: () => {},
  deleteCustomField: () => {},
  customFieldsLastLoad: null,
  customFields: null,
  error: {},
  params: {}
};

const mapStateToProps = state => ({
  user: state.user,
  customFields: _.get(state, 'plans.customFields', null),
  customFieldsLastLoad: _.get(state, 'plans.customFieldsLastLoad', null)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getCustomFields: data => getCustomFields(data),
      postCustomField: data => postCustomField(data),
      deleteCustomField: data => deleteCustomField(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(PlanCustomFields);
