import React from 'react';
import SvgCheckbox from '../../../Svgs/SvgCheckbox';
import SvgCheckboxChecked from '../../../Svgs/SvgCheckboxChecked';

const style = {
  container: {
    textAlign: 'left',
    paddingLeft: '33px'
  },
  input: {
    fill: '#fff',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  text: {
    color: '#fff',
    verticalAlign: 'super',
    marginBottom: '-1px',
    marginLeft: '4px'
  }
};

const PastRideInput = ({ pastRide = false, setIsPastRide = () => {} }) => {
  return (
    <div className="PastRideInput" style={style.container}>
      <a
        style={style.input}
        onClick={() => setIsPastRide({ pastRide: !pastRide })}
      >
        {pastRide ? (
          <SvgCheckboxChecked className="svgCheck" />
        ) : (
          <SvgCheckbox className="svgCheck" />
        )}{' '}
        <span style={style.text}>Past Ride Booking</span>
      </a>
    </div>
  );
};

export default PastRideInput;
