import type { App } from 'contracts/mod';
import type { TripType } from '~/models';
import { baseRequestConfig } from '~/utilities/auth.helper';

/** Get ride booking flow date restrictions for a passenger. */
export const getDateRestrictions = async (
  passengerId: number,
  tripType: TripType,
  transportType: string
) => {
  return await fetch(
    `${process.env.APP_LAYER_URL}/rbf/v1/date-restrictions?passengerId=${passengerId}&tripType=${tripType}&transportType=${transportType}`,
    baseRequestConfig()
  ).then(res => res.json() as Promise<App.RBF.V1.GetDateRestrictionsResponse>);
};

/** Get the duplicate rides for a passenger on a given date. */
export const getDuplicateRides = async (selectedDate: IsoDate, passengerId: number) => {
  return await fetch(
    `${process.env.APP_LAYER_URL}/rbf/v1/duplicate-rides?date=${selectedDate}&passengerId=${passengerId}`,
    baseRequestConfig()
  ).then(res => res.json() as Promise<App.RBF.V1.GetDuplicateRidesResponse>);
};

/** Retrieve the time restrictions for ride booking. */
export const getAvailableTimes = async (
  transportType: string,
  tripType: TripType,
  currentDate: IsoDate
) => {
  return await fetch(
    `${process.env.APP_LAYER_URL}/rbf/v1/available-times?transportType=${transportType}&tripType=${tripType}&currentDate=${currentDate}`,
    baseRequestConfig()
  ).then(res => res.json() as Promise<App.RBF.V1.GetAvailableTimesResponse>);
};
