import React from 'react';
import { Button } from '@SRHealth/frontend-lib';

/** Upper label on the SMS opt-out modal for the RBF Passenger Info. */
export const SMS_OPT_IN_LABEL =
  'Please read the following Opt-in agreement that allows the ' +
  'member to receive text notifications.';

/** Legal copy on the SMS opt-out modal for the RBF Passenger Info. */
export const SMS_OPT_IN_NOTIFICATION =
  'As part of the SafeRide service, we would like to send ' +
  'you text messages related to ride bookings and status, transportation benefits, ride and ' +
  'appointment confirmations, reminders and cancellations. These messages may contain personal ' +
  'health information that will be sent unencrypted and may be at risk of interception. Message ' +
  'and data rates may apply. Message frequency varies. You can always opt out by texting STOP ' +
  'and get help by texting HELP. Mobile terms of service can be found at https://saferidehealth.' +
  'com/terms-of-service/uhc and our privacy policy can be found at https://saferidehealth.com/' +
  'privacy-policy. Please reply with a "yes" or "no" if you would like this service.';

type OptOutModalContentsProps = {
  onOptOut: () => void;
  onOptIn: () => void;
};

export default function OptOutModalContents({
  onOptOut,
  onOptIn
}: OptOutModalContentsProps) {
  return (
    <div className="flex flex-col gap-[12px]">
      <div className="heading-lg py-[8px] px-[24px]">{SMS_OPT_IN_LABEL}</div>
      <div className="text-md py-[8px] px-[24px]">{SMS_OPT_IN_NOTIFICATION}</div>
      <div className="flex flex-row gap-[16px] justify-center">
        <Button id="opt-out" alt={true} label="No, Opt-out SMS" onClick={onOptOut} />
        <Button label="YES, Opt-in SMS" onClick={onOptIn} />
      </div>
    </div>
  );
}
