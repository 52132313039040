import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FreeForm from './FreeForm';
import Validation from './Validation';
import { generateNavOptions } from './CustomFields.helper';
import { camelCase, capitalize } from '~/utilities/strings';

const CHILD_COMPONENTS = {
  freeform: FreeForm,
  validation: Validation
};

class CustomFields extends Component {
  constructor(props) {
    super(props);
    this.state = { editFreeform: false, editValidation: false };
  }

  componentDidMount() {
    if (!this.props.childForm) this.props.handleChildSelection('freeform');
  }

  componentDidUpdate() {
    if (!this.props.childForm) this.props.handleChildSelection('freeform');
  }

  /**
   * Edit handler returns a callback to update the state for
   * the specific tab
   * @param {string} edit - property for modifying state
   * @param {boolean} enable - T/F can edit
   * @return {Function}
   */
  handleEdit = (edit, enable) => {
    return (() => this.setState({ [edit]: enable })).bind(this);
  };

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { childForm, selectedId } = this.props;

    const childTab = camelCase(childForm);
    const childEditKey = `edit${capitalize(childTab)}`;
    const ChildComponent = CHILD_COMPONENTS[childForm];

    return ChildComponent ? (
      <>
        {this.props.children}
        <ChildComponent
          editable={this.state[childEditKey]}
          selectedId={selectedId}
          cancel={this.handleEdit(childEditKey, false)}
          edit={this.handleEdit(childEditKey, true)}
          permission={this.props.permission}
          setEditButton={this.props.setEditButton}
          setErrorMsg={this.props.setErrorMsg}
          setSelectionCallback={this.props.setSelectionCallback}
        >
          {this.props.children}
        </ChildComponent>
      </>
    ) : null;
  }
}

CustomFields.getNavOptions = selectedId => generateNavOptions(selectedId);

CustomFields.propTypes = {
  user: PropTypes.object,
  childForm: PropTypes.string,
  selectedId: PropTypes.string,
  permission: PropTypes.string,
  toggleEnableRedirect: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
};

CustomFields.defaultProps = {
  user: {},
  childForm: '',
  selectedId: 0,
  permission: 'view',
  toggleEnableRedirect: () => {},
  setEditButton: () => {},
  setErrorMsg: () => {},
  children: null
};

export default CustomFields;
