import React, { PureComponent } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { AutoComplete } from 'mapbox-components';
import PropTypes from 'prop-types';
import moment from 'moment';

class PersonalInformation extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      dateOfBirthError: false,
      dateOfBirthErrorText: null
    };
  }

  /**
   * date of birth handler and validator
   * @param {event} e - event
   * @return {undefined}
   */
  handleDateOfBirth = e => {
    const { value } = e.target;
    if (value.indexOf('_') !== -1 && value.length !== 10) {
      return;
    }

    let dateOfBirthError = false;
    let dateOfBirthErrorText = null;
    const momentDate = moment(value);

    if (!momentDate.isValid()) {
      dateOfBirthError = true;
      dateOfBirthErrorText = 'Must enter a valid date.';
    }

    if (momentDate.isAfter(moment())) {
      dateOfBirthError = true;
      dateOfBirthErrorText = 'Cannot be future date.';
    }

    this.setState({
      dateOfBirthError,
      dateOfBirthErrorText
    });
    this.props.onChange(e);
  };

  render() {
    const { onChange, formData, classes, isSelfFunded } = this.props;
    return (
      <Grid container spacing={1} className={classes.gridRoot}>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="firstName"
              name="firstName"
              className={classes.textField}
              required
              label="First Name"
              InputLabelProps={{
                shrink: true
              }}
              onChange={onChange}
              value={formData.firstName} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              id="lastName"
              name="lastName"
              className={classes.textField}
              required
              label="Last Name"
              InputLabelProps={{
                shrink: true
              }}
              onChange={onChange}
              value={formData.lastName} />
          </Grid>
          <Grid item xs={4}>
            <TextField
              className={classes.textField}
              id="dateOfBirth"
              name="dateOfBirth"
              required={!isSelfFunded}
              label="Date of Birth"
              InputLabelProps={{
                shrink: true
              }}
              onChange={this.handleDateOfBirth}
              value={formData.dateOfBirth}
              error={this.state.dateOfBirthError}
              helperText={this.state.dateOfBirthErrorText}
              type="date"
            />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={8}>
            <div className={classes.addressField}>
              <span>
                <label className={classes.textField}>Home Address</label>
              </span>
              <AutoComplete
                query={formData.address}
                disabled={false}
                autocomplete="off"
                onSuggestionSelect={suggestion => {
                  const event = { target: { name: 'address', value: suggestion, key: 'address' } };
                  onChange(event);
                }}
                accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              />
            </div>

          </Grid>
          <Grid item xs={4}>
            <TextField
              id="address2"
              name="address2"
              className={classes.textField}
              label="Apt/Unite/Suite"
              InputLabelProps={{
                shrink: true
              }}
              onChange={onChange}
              value={formData.address2} />
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={4}>
            <TextField
              id="phone"
              name="phone"
              type="tel"
              className={classes.textField}
              required
              label="Phone"
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{ maxLength: 13 }}
              onChange={onChange}
              value={formData.phone.replace(/^(\d{3})(\d{3})(\d)+$/, '($1)$2-$3')} />
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

PersonalInformation.propTypes = {
  onChange: PropTypes.func,
  formData: PropTypes.object,
  classes: PropTypes.object,
  isSelfFunded: PropTypes.bool
};

PersonalInformation.defaultProps = {
  onChange: () => {},
  formData: {},
  classes: {},
  isSelfFunded: null
};

export default PersonalInformation;