import React from 'react';
import { Accordion, Card, Input } from '@SRHealth/frontend-lib';
import type { PassengerInfoModel, TripType } from '~/models';
import { useAppSelector } from '~/Modules';
import { VEHICLE_SUB_TYPE } from '~/constants/features';
import type { VehicleSubType } from '~/types';
import { getAllowedTripTypes } from '../PassengerInfo.utils';

type TripTypeProps = {
  model: PassengerInfoModel;
  onSave: () => void;
  onCancel: () => void;
};

export default function TripTypeComponent({ model, onSave, onCancel }: TripTypeProps) {
  const memberProfile = useAppSelector(({ memberProfile }) => memberProfile);
  const allowSubTransportType = useAppSelector(
    ({ user }) => user.features[VEHICLE_SUB_TYPE]
  );

  const tripTypeOptions = getAllowedTripTypes(memberProfile.transportTypes ?? {});
  const showTripTypeOptions = tripTypeOptions.length > 1;

  const transportTypes = memberProfile?.transportTypes?.[model.tripType] ?? {};

  const _transportTypes = Object.values(transportTypes).filter(({ label }) => label);

  let subTransportTypes: VehicleSubType[] = [];
  if (allowSubTransportType && transportTypes[model.transportType!]?.subTypes) {
    subTransportTypes = transportTypes[model.transportType!]?.subTypes;
  }

  // These are passed to the Cards depending on how many transport trip options
  // we display.
  const cardProps = { editable: true, onEdit: () => {}, onSave, onCancel };
  const formCardProps = showTripTypeOptions ? {} : cardProps;

  return (
    <>
      {showTripTypeOptions && (
        <Card id="trip-type" label="Trip Type" border={false} {...cardProps}>
          <Input
            inputId={0}
            type="radio"
            name="tripType"
            size="lg"
            columns={4}
            value={model.tripType}
            options={tripTypeOptions}
            error={'tripType' in model.ruleErrors}
            onChange={(_id, val: TripType) => {
              model.tripType = val;
            }}
          />
        </Card>
      )}
      {{
        'private': (
          <Card
            id="tripTransportType"
            label="Trip Transport Type"
            border={false}
            columns={3}
            {...formCardProps}
          >
            <Card.Content label="" span={2}>
              <div className="flex flex-col gap-[12px]">
                <div className="flex flex-row gap-[8px]">
                  <Input
                    inputId={0}
                    type="select"
                    name="mobilityDevice"
                    label="Does the member use any mobility devices?"
                    placeholder="Select One"
                    options={memberProfile.mobilityDevices}
                    value={model.mobilityDevice}
                    onChange={(_id, v: (typeof model)['mobilityDevice']) => {
                      model.mobilityDevice = v;
                    }}
                    error={'mobilityDevice' in model.ruleErrors}
                    required
                  />
                  <Input
                    inputId={1}
                    type="select"
                    name="specialAssistance"
                    label="Does the member need special driver assistance?"
                    options={memberProfile.serviceLevels}
                    value={model.serviceLevel}
                    onChange={(_id, v: (typeof model)['serviceLevel']) => {
                      model.serviceLevel = v;
                    }}
                    error={'serviceLevel' in model.ruleErrors}
                    placeholder="Select One"
                    required
                  />
                </div>

                <Accordion label="Does the member have any special circumstances?">
                  <Input
                    inputId={2}
                    columns={3}
                    size="lg"
                    type="checkbox"
                    name="specialCircumstances"
                    options={memberProfile.assistanceNeeds}
                    value={model.specialCircumstances}
                    onChange={(_id, v: (typeof model)['specialCircumstances']) => {
                      model.specialCircumstances = v;
                    }}
                    error={'specialCircumstances' in model.ruleErrors}
                  />
                </Accordion>
                <Accordion label="Does the driver need to supply any provisions?">
                  <Input
                    inputId={3}
                    columns={3}
                    size="lg"
                    type="checkbox"
                    name="provisions"
                    options={memberProfile.driverProvisions}
                    value={model.driverProvisions}
                    onChange={(_id, v: (typeof model)['driverProvisions']) => {
                      model.driverProvisions = v;
                    }}
                    error={'driverProvisions' in model.ruleErrors}
                  />
                </Accordion>
              </div>
            </Card.Content>
            <Card.Content label="" span={1}>
              <div className="flex flex-col gap-[8px]">
                <Input
                  inputId={5}
                  type="select"
                  name="transportType"
                  label="Transport Type"
                  placeholder="Select One"
                  options={_transportTypes}
                  value={model.transportType}
                  className="flex-1"
                  onChange={(_id, v: (typeof model)['transportType']) => {
                    model.transportType = v;
                  }}
                  error={'transportType' in model.ruleErrors}
                />
                {!!subTransportTypes?.length && (
                  <Input
                    inputId={6}
                    type="select"
                    name="subTransportType"
                    label="Sub Transport Type"
                    value={model.subTransportType}
                    options={subTransportTypes}
                    placeholder="Select One"
                    onChange={(_id, v: (typeof model)['subTransportType']) => {
                      model.subTransportType = v;
                    }}
                    error={'subTransportType' in model.ruleErrors}
                  />
                )}
              </div>
            </Card.Content>
          </Card>
        ),
        'mileage-reimbursement': (
          <Card
            id="mileageReimbursement"
            label="Mileage Reimbursement"
            border={false}
            columns={3}
            {...formCardProps}
          >
            <Card.Content span={2}>
              <div className="flex flex-row gap-[8px]">
                <Input
                  inputId={0}
                  type="select"
                  name="transportType"
                  label="Personal Transport Type"
                  placeholder="Select One"
                  className="flex-1"
                  onChange={(_id, val: (typeof model)['transportType']) => {
                    model.transportType = val;
                  }}
                  options={_transportTypes}
                  value={model.transportType}
                  required
                />
                <div className="flex-1" />
              </div>
            </Card.Content>
            {!!subTransportTypes?.length && (
              <Card.Content>
                <Input
                  inputId={1}
                  type="select"
                  name="subMileageType"
                  label="Sub Mileage Type"
                  placeholder="Select One"
                  onChange={(_id, val: (typeof model)['subTransportType']) => {
                    model.subTransportType = val;
                  }}
                  options={subTransportTypes}
                  value={model.subTransportType}
                  error={'subTransportType' in model.ruleErrors}
                />
              </Card.Content>
            )}
          </Card>
        ),
        'public': (
          <Card
            id="publicTransport"
            label="Public Transport"
            border={false}
            columns={3}
            {...formCardProps}
          >
            <Card.Content>
              <div className="flex flex-col gap-[8px]">
                <Input
                  inputId={0}
                  type="select"
                  name="transportType"
                  label="Public Transport Type"
                  placeholder="Select One"
                  value={model.transportType}
                  options={_transportTypes}
                  onChange={(_id, val: (typeof model)['transportType']) => {
                    model.transportType = val;
                  }}
                  error={'transportType' in model.ruleErrors}
                  required
                />
              </div>
            </Card.Content>
          </Card>
        ),
        'alternate': (
          <Card
            id="alternateServices"
            label="Alternate Services"
            border={false}
            columns={3}
            {...formCardProps}
          >
            <Card.Content span={2}>
              <div className="flex flex-row gap-[8px]">
                <Input
                  inputId={0}
                  type="select"
                  name="transportType"
                  label="Alternate Service Type"
                  placeholder="Select One"
                  className="flex-1"
                  onChange={(_id, val: (typeof model)['transportType']) => {
                    model.transportType = val;
                  }}
                  value={model.transportType}
                  options={_transportTypes}
                  error={'transportType' in model.ruleErrors}
                  required
                />
                <div className="flex-1" />
              </div>
            </Card.Content>
            {!!subTransportTypes?.length && (
              <Card.Content>
                <Input
                  inputId={1}
                  type="select"
                  name="subServiceType"
                  label="Sub Service Type"
                  placeholder="Select One"
                  className="flex-1"
                  options={subTransportTypes}
                  value={model.subTransportType}
                  onChange={(_id, val: (typeof model)['subTransportType']) => {
                    model.subTransportType = val;
                  }}
                />
              </Card.Content>
            )}
          </Card>
        )
      }[model.tripType] ?? null}
    </>
  );
}
