import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { getMemberAuditLogs } from '~/Modules/memberProfile/actions';
import { Container } from '@material-ui/core';

class MemberAudit extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { passengerId, getMemberAuditLogs, healthSubPlanId, externalSubPlanId } =
      this.props;

    if (passengerId !== 0) {
      getMemberAuditLogs({ passengerId, healthSubPlanId, externalSubPlanId });
    }
  }

  componentDidUpdate(prevProps) {
    const { healthSubPlanId, externalSubPlanId, passengerId, getMemberAuditLogs } =
      this.props;

    if (
      healthSubPlanId !== prevProps.healthSubPlanId ||
      externalSubPlanId !== prevProps.externalSubPlanId ||
      passengerId !== prevProps.passengerId
    ) {
      getMemberAuditLogs({ passengerId, healthSubPlanId, externalSubPlanId });
    }
  }

  /**
   * render modal and calendar
   * @return {jsx} returns jsx.
   */
  render() {
    const { events, isLoading } = this.props;
    const rows = events.map(
      ({ userName = '', activityTime = '', message = '', organizationName = '' }, i) => {
        const orgName = organizationName === '0' ? 'SafeRide Health' : organizationName;

        return (
          <tr key={i}>
            <td width="190">{activityTime}</td>
            <td>{`${userName} at ${orgName} ${message}`}</td>
          </tr>
        );
      }
    );

    if (isLoading) {
      return <LoadingModal label="Loading member's Audit Log..." isOpen={isLoading} />;
    }

    return (
      <Container className="parentTab audit">
        <div className="childTab audit">
          <table>
            <thead>
              <tr>
                <td className="bold">Activity</td>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </table>
        </div>
      </Container>
    );
  }
}

MemberAudit.getNavOptions = () => [];

MemberAudit.propTypes = {
  events: PropTypes.array,
  timezone_format: PropTypes.string,
  getMemberAuditLogs: PropTypes.func,
  setEditButton: PropTypes.func,
  setErrorMsg: PropTypes.func
};

MemberAudit.defaultProps = {
  events: [],
  timezone_format: '',
  isLoading: false,
  passengerId: 0,
  setEditButton: () => {},
  setErrorMsg: () => {},
  getMemberAuditLogs: () => {}
};

const mapStateToProps = state => ({
  events: state.memberAuditLogs.activities ?? [],
  healthSubPlanId: state.memberProfile?.formData?.benefits?.healthSubPlanId ?? 0,
  externalSubPlanId:
    state.memberProfile?.formData?.personalInfo?.external_sub_plan_id ?? 0,
  isLoading: state.memberAuditLogs.inHttpRequest ?? false,
  timezone_format: state.user.userData.timezone_format
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getMemberAuditLogs: data => getMemberAuditLogs(data)
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MemberAudit);
