import React from 'react';
import PropTypes from 'prop-types';
import { IMPORTER } from '~/constants';

const ImporterHeader = props => {
  const { importerType } = props;
  let headerLabel = 'n/a';

  switch (importerType) {
    case IMPORTER.TREATMENT: {
      headerLabel = 'Treatments Import';
      break;
    }
    case IMPORTER.LOCATION: {
      headerLabel = 'Locations Import';
      break;
    }
    case IMPORTER.MEMBER: {
      headerLabel = 'Members Import';
      break;
    }
    case IMPORTER.EXPENSE: {
      headerLabel = 'Expense Import';
      break;
    }
    case IMPORTER.RIDESHARE: {
      headerLabel = 'Rideshare Import';
      break;
    }
    case IMPORTER.PUBLICTRANSIT: {
      headerLabel = 'Public Transit Import';
      break;
    }
    default: {
      break;
    }
  }

  return (
    <header className="ImporterHeader">
      {headerLabel}
    </header>
  );
};

ImporterHeader.propTypes = {
  importerType: PropTypes.string
};

ImporterHeader.defaultProps = {
  importerType: IMPORTER.NONE
};

export default ImporterHeader;
