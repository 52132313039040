import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash-es';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DropDown from '~/Shared/Components/DropDown';
import { putMemberProfile, clearData } from '~/Modules/memberProfile/actions';
import LoadingModal from '~/Shared/Components/LoadingModal';
import { PERMISSIONS } from '~/Pages/MemberProfiles/MemberProfiles.constants';
import { Container } from '@material-ui/core';

class Validation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDirty: false
    };
  }

  componentDidUpdate(prevProps) {
    const { validation, editable, permission, success, error } = this.props;
    const state = {};

    if (!_.isEqual(prevProps.validation, validation) && _.isEmpty(prevProps.validation)) {
      Object.assign(state, this.initialize(), { showLoader: false });
    }

    if (!_.isEmpty(success) && _.isEmpty(prevProps.success)) {
      state.success = success.formType === 'validation' ? success.message : '';
      state.showLoader = false;
    }

    if (!_.isEmpty(error) && _.isEmpty(prevProps.error)) {
      state.error = error;
      state.showLoader = false;

      // if it's an array then there are field validation
      if (Array.isArray(this.props.error)) {
        for (let i = 0; i < this.props.error.length; i++) {
          const errorObj = this.props.error[i];
          const key = Object.keys(errorObj)[0];
          state[`${key}Error`] = true;
          state[`${key}ErrorText`] = errorObj[key];
        }
      }
    }

    if (editable !== prevProps.editable || permission !== prevProps.permission) {
      const permission = Object.keys(this.props.validation).length
        ? this.props.permission
        : 'view';

      this.props.setEditButton(
        <ValidationEditButton
          permission={permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    }

    if (Object.keys(state).length) {
      this.setState(state, () => {
        if (this.state.error !== state.error) {
          this.props.setErrorMsg(state.error);
        }
      });
    }
  }

  componentDidMount() {
    const state = this.initialize();

    this.setState(state, () => {
      const permission = Object.keys(this.props.validation).length
        ? this.props.permission
        : 'view';

      this.props.setEditButton(
        <ValidationEditButton
          permission={permission}
          editable={this.props.editable}
          handleEdit={this.handleEdit}
          handleCancel={this.handleCancel}
          handleSave={this.handleSave}
        />
      );
    });
  }

  componentWillUnmount() {
    this.props.clearData();
    this.props.cancel();
  }

  /**
   * The initial state object to use on the component
   * @return {object} the initial state object
   * @memberof Validation
   */
  initialize() {
    return Object.assign(_.cloneDeep(this.props.validation), {
      success: '',
      error: '',
      showLoader: false,
      isDirty: false
    });
  }

  /**
   * Handler for clicking cancel on the form
   * @param {Event} e The click event
   * @return {undefined} nothing
   * @memberof Validation
   */
  handleCancel = e => {
    const state = { ...this.initialize(), showLoader: false };

    this.setState(state, () => {
      this.props.cancel(e);
    });
  };

  /**
   * edit member profile
   * @return {undefined}
   */
  handleEdit = () => {
    const { edit } = this.props;
    this.setState({ success: '' }, edit);
  };

  /**
   * Validates and gathers component state information to be dispatched and saved to api
   * @return {undefined} nothing
   * @memberof Validation
   */
  handleSave = () => {
    const memberProfile = {};

    for (const key in this.state) {
      const stateProp = this.state[key];

      if (typeof stateProp === 'object' && 'value' in stateProp) {
        memberProfile[key] = stateProp.value;
      }
    }

    const newState = {
      success: '',
      error: '',
      showLoader: true,
      isDirty: false
    };

    this.setState(newState, () => {
      this.props.clearData();
      this.props.putMemberProfile({
        memberProfile,
        passengerId: this.props.selectedId,
        formType: 'validation'
      });
      this.props.cancel();

      if (this.state.error !== newState.error) {
        this.props.setErrorMsg(newState.error);
      }
    });
  };

  /**
   * On change handler for the drop down list boxes on the form
   * @param {Event} e The change event
   * @param {String} _item The item that was selected
   * @param {String} slug The form element that was changed
   * @return {undefined} nothing
   * @memberof Validation
   */
  handleDDSelect = (e, _item, slug) => {
    this.setState({
      [slug]: { value: e.target.id },
      isDirty: true
    });
  };

  /**
   * format drop down values
   * @param {object} values - personalInfo object
   * @param {string} key - namespace for form element
   * @return {array} - formatted data for drop down
   */
  formatValues(values) {
    if (!values.length) return [];

    return values.map(({ slug, label }) => ({ id: slug, name: label }));
  }

  /**
   * render the form
   * @return {jsx} returns jsx.
   */
  render() {
    const { validation, editable } = this.props;

    return (
      <Container className="parentTab customfields">
        <div className="childTab validation">
          <>
            {!Object.keys(validation).length ? (
              <div className="memberRow">
                <p>There are no validation custom fields</p>
              </div>
            ) : (
              Object.keys(validation).map(slug => {
                let placeholder = '';

                const placeholderItem = _.find(validation[slug].options, item => {
                  return item.slug === _.get(this.state, `${slug}.value`, '');
                });

                if (typeof placeholderItem !== 'undefined') {
                  placeholder = placeholderItem.label;
                }

                return (
                  <div className="memberRow" key={slug}>
                    <DropDown
                      customClassName="fullColumn"
                      items={this.formatValues(validation[slug].options)}
                      label={validation[slug].label}
                      showLabel={true}
                      placeholder={placeholder}
                      dropDownCallback={(e, item) => this.handleDDSelect(e, item, slug)}
                      disabled={!editable}
                      error={_.get(this.state, `${slug}Error`, false)}
                    />
                  </div>
                );
              })
            )}
          </>
          {this.state.showLoader ? (
            <LoadingModal label="Saving Member Validations..." isOpen={true} />
          ) : null}
        </div>
      </Container>
    );
  }
}

const ValidationEditButton = ({
  editable,
  permission,
  handleEdit,
  handleSave,
  handleCancel
}) => {
  return (
    <>
      {' '}
      {permission === PERMISSIONS.EDIT || permission === PERMISSIONS.CREATE_EDIT ? (
        <>
          {editable ? (
            <>
              <a onClick={handleCancel}>Cancel</a>
              <a onClick={handleSave}>Save</a>
            </>
          ) : (
            <a onClick={handleEdit}>Edit</a>
          )}
        </>
      ) : null}
    </>
  );
};

Validation.propTypes = {
  user: PropTypes.object,
  validation: PropTypes.object,
  editable: PropTypes.bool,
  selectedId: PropTypes.string,
  edit: PropTypes.func,
  cancel: PropTypes.func,
  success: PropTypes.any,
  error: PropTypes.any,
  permission: PropTypes.string,
  putMemberProfile: PropTypes.func,
  clearData: PropTypes.func
};

Validation.defaultProps = {
  user: {},
  validation: {},
  editable: false,
  selectedId: null,
  edit: () => {},
  cancel: () => {},
  success: '',
  error: '',
  permission: 'view',
  putMemberProfile: () => {},
  clearData: () => {}
};

const mapStateToProps = state => ({
  user: state.user,
  validation: _.get(state, 'memberProfile.formData.validation', {}),
  error: state.memberProfile.error,
  success: state.memberProfile.success
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      putMemberProfile: data => putMemberProfile(data),
      clearData: () => clearData()
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Validation);
