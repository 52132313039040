import { makeStyles, Theme } from '@material-ui/core/styles';

export const useMemberAccountStyles = makeStyles<Theme, { isActive: boolean }>(theme => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '24px',
    flexBasis: '33%',
    [theme.breakpoints.down(768)]: {
      width: '100%'
    }
  },
  detailsContainer: {
    display: 'flex',
    padding: '12px',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    gap: '24px',
    borderRadius: '12px',
    width: '100%',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column' as const
    }
  },
  header: {
    display: 'flex',
    width: '100%',
    padding: '8px 0px',
    justifyContent: 'space-between',
    gap: '24px',
    borderBottom: '1px solid rgb(204, 204, 204)'
  },
  text: {
    display: 'flex',
    flexDirection: 'column' as const,
    justifyContent: 'center',
    flex: 1,
    alignSelf: 'stretch'
  },
  icon: props => ({
    maxWidth: '24px',
    maxHeight: '24px',
    fill: props.isActive ? '#009946' : '#808080'
  }),
  details: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    gap: '8px'
  }
}));

export const useMemberPortalStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column' as const,
    alignItems: 'flex-start',
    gap: '24px'
  },
  accountContainer: {
    display: 'flex',
    width: '100%',
    gap: '24px',
    alignItems: 'flex-start',
    [theme.breakpoints.down(768)]: {
      flexDirection: 'column' as const
    }
  }
}));

export const memberPortalDescriptionStyles = {
  container: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    minWidth: 'calc(100% - 24px)',
    borderRadius: '12px',
    padding: '8px 10px 8px 8px',
    backgroundColor: '#F5F5F5',
    gap: '8px',
    color: '#000'
  },
  icon: {
    fill: '#0072D6',
    maxWidth: '24px',
    maxHeight: '24px',
    flexBasis: '10%'
  }
};

export const useMemberPortalManageStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexBasis: '64%',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    margin: '-10px',
    padding: '0px 12px 12px 12px'
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '8px',
    borderBottom: '1px solid #CCCCCC',
    padding: '8px 0px',
    width: '100%',
    [theme.breakpoints.down(900)]: {
      flexDirection: 'column' as const,
      alignItems: 'start'
    }
  },
  websiteLink: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '14px',
    color: '#0072D6'
  },
  toggleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    flex: '0 0 20%',
    minWidth: '175px'
  },
  toggleLabel: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: '16px',
    marginTop: '5px',
    color: '#000',
    minWidth: '110px'
  }
}));
