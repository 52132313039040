import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {getClassName} from '~/utilities/helperFunctions';
import { IMPORTER_NOTIFICATION_TYPE } from '~/constants';

class PlanListItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notifications: this.getNotifications(),
      showNotification: false
    };
  }

  handleNotificationClick(e) {
    e.stopPropagation();
    this.setState({ showNotification: !this.state.showNotification });
  }

  getNotifications() {
    const { plan } = this.props;
    const notifications = [];

    // check for empty treatment and location
    if (plan.treatmentCount === 0) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.ALERT,
        message: 'No treatments uploaded'
      });
    }
    if (plan.locationCount === 0) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.ALERT,
        message: 'No locations uploaded'
      });
    }

    // check for processing or pending jobs
    if (plan.meta && plan.meta.treatments.last.processing) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.INFO,
        message: 'Treatments upload in progress'
      });
    } else if (plan.meta && plan.meta.treatments.last.pending) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.INFO,
        message: 'Treatments upload in queue'
      });
    }

    if (plan.meta && plan.meta.locations.last.processing) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.INFO,
        message: 'Locations upload in progress'
      });
    } else if (plan.meta && plan.meta.locations.last.pending) {
      notifications.push({
        type: IMPORTER_NOTIFICATION_TYPE.INFO,
        message: 'Locations upload in queue'
      });
    }

    return notifications;
  }

  render() {
    const { plan, selectedId, setSelectedId } = this.props;
    const { notifications, showNotification } = this.state;

    return (
      <li
        className={getClassName({
          'PlanListItem': true,
          'selected': selectedId === plan.id,
          'disabled': !plan.isEnabled
        })}
        onClick={() => setSelectedId(plan.id)}
      >
        <div className="planName">{plan.planName}</div>
        <div className="planId">ID: {plan.planId}</div>
        {!plan.isEnabled ? (
          <div className="planDisabled">Disabled</div>
        ) : notifications.length > 0 ? (
          <div
            className="notificationCount"
            onClick={e => this.handleNotificationClick(e)}
          >
            {notifications.length}
          </div>
        ) : null}
        {showNotification ? (
          <ol className="notifications">
            {notifications.map((notification, idx) => (
              <li
                key={idx}
                className={notification.type}
              >
                {notification.message}
              </li>
            ))}
          </ol>
        ) : null}
      </li>
    );
  }
}

PlanListItem.propTypes = {
  plan: PropTypes.object,
  selectedId: PropTypes.number,
  setSelectedId: PropTypes.func,
  isSuperAdmin: PropTypes.bool
};

PlanListItem.defaultProps = {
  plan: {},
  selectedId: 0,
  setSelectedId: () => {},
  handlePlanDelete: () => {},
  isSuperAdmin: false
};

export default PlanListItem;