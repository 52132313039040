import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// actions
import {
  updateRideFundingSource,
  clearFundingSource,
  getRide
} from '~/Modules/rideDetails';
import { statusNotifications, getSafe } from '~/utilities/helperFunctions';
// components
import SvgClose from 'Common/Components/Svgs/SvgClose';
import DropDown from 'Common/Components/DropDown';
import ErrorModal from 'Common/Components/ErrorModal';
// library
import { isEmpty, hasIn } from 'lodash-es';
import { getMemberPermissions } from '~/Modules/members/reducer';
import { ACTIVE_RIDES } from '~/constants';

export const FundingSourceDropdown = ({ user, ride }) => {
  const dispatch = useDispatch();
  const { rideDetails, members } = useSelector(state => state);
  const [isEdit, setIsEdit] = useState(false);
  const [isErrorOpen, setError] = useState(false);
  const [onSubmitError, setOnSubmitError] = useState(false);
  const [hospitalGroupId, setHospitalGroupId] = useState(null);

  // health plans
  const { health_plan_id } = isEmpty(getSafe(() => rideDetails.ride.data.ride, {}))
    ? ride
    : getSafe(() => rideDetails.ride.data.ride, {});
  const [selectedHealthPlan, setSelectedHealthPlan] = useState({});
  const [selectedSubPlan, setSelectedSubPlan] = useState({});
  // health and subplan options
  const healthPlans = getSafe(() => members.allHealthPlans, []);
  const healthPlanOptions =
    user.userData.fundingSource === 'SFHP'
      ? getSafe(
        () => healthPlans.concat([{ id: 0, name: 'Self Funded', isSelfFunded: true }]),
        []
      )
      : healthPlans;
  const healthPlanFundingOptions = getSafe(
    () =>
      healthPlanOptions.filter(
        hp => user.fundingHealthPlans.map(hp => hp.id).includes(hp.id) || hp.isSelfFunded
      ),
    []
  );
  const subPlanOptions = getSafe(
    () => healthPlans.find(h => h.id === selectedHealthPlan.id).subplans,
    []
  );

  // isFeatureEnabled
  const isFeatureEnabled = user.hospitalGroupFeatures.find(
    f => f.feature === 'switch_funding_source'
  );
  if (!isFeatureEnabled) return <div />;
  let cardTitle = '';
  const { healthPlanName, healthSubPlanName } = getSafe(
    () => rideDetails.ride.data.ride,
    {}
  );
  if (healthPlanName && healthPlanName) {
    cardTitle = `${healthPlanName} | ${healthSubPlanName}`;
  } else {
    cardTitle = 'Self Funded';
  }

  useEffect(() => {
    dispatch(getMemberPermissions());
  }, [dispatch]);

  useEffect(() => {
    if (hasIn(rideDetails, 'updateRideFundingSource.error') && isEdit) {
      setError({ message: rideDetails.updateRideFundingSource.error.message });
    }
    if (hasIn(rideDetails, 'updateRideFundingSource.success')) {
      statusNotifications(
        'Funding source has been updated successfully',
        'success',
        2000
      );
      dispatch(getRide(ride.id));
      handleClose();
    }
  }, [rideDetails]);

  useEffect(() => {
    if (!isEdit && !hasIn(rideDetails, 'updateRideFundingSource.error')) {
      const {
        health_sub_plan_id,
        health_sub_plan_name,
        health_plan_name,
        health_plan_id: healthPlanId
      } = getSafe(() => rideDetails.ride.data.ride, {});

      if (healthPlanId && health_sub_plan_name) {
        setSelectedHealthPlan({ id: health_plan_id, name: health_plan_name });
        setSelectedSubPlan({ id: health_sub_plan_id, name: health_sub_plan_name });
      } else {
        setSelectedHealthPlan({ id: 0, name: 'Self Funded', isSelfFunded: true });
      }
    }
  }, [rideDetails]);

  useEffect(() => {
    setHospitalGroupId(rideDetails.hospitalGroupId);
  }, [rideDetails]);
  //handlers

  const onEditClick = () => {
    setIsEdit(s => !s);
  };

  const handleClose = () => {
    setError(false);
    setIsEdit(false);
    setOnSubmitError(false);
    dispatch(clearFundingSource());
  };

  const onHealthPlanSelect = (_, item) => {
    setSelectedHealthPlan(healthPlanFundingOptions.find(h => h.name === item));
    setSelectedSubPlan({ id: null, name: null });
    setOnSubmitError(false);
  };

  const onSubPlanSelect = (_, item) => {
    setSelectedSubPlan(subPlanOptions.find(h => h.name === item));
    setOnSubmitError(false);
  };

  const onUpdate = () => {
    if (!selectedSubPlan.id && !selectedHealthPlan.isSelfFunded) {
      setOnSubmitError(true);
    } else {
      dispatch(
        updateRideFundingSource({
          healthPlanId: selectedHealthPlan.id,
          healthSubPlanId: selectedSubPlan.id,
          rideId: ride.id,
          ...(selectedHealthPlan.isSelfFunded && { hospitalGroupId })
        })
      );
    }
  };

  // components
  const renderEditSection = () => {
    if (!isEdit) return <div />;
    const displaySubPlans = [
      selectedHealthPlan,
      Boolean(selectedHealthPlan.id),
      !selectedHealthPlan.isSelfFunded
    ].every(Boolean);
    return (
      <React.Fragment>
        <DropDown
          dropDownCallback={onHealthPlanSelect}
          items={healthPlanFundingOptions}
          placeholder={`Funded by ${cardTitle}`}
          black={!isEdit}
          error={false}
          customClassName={'vehicleDropDown'}
          id={selectedHealthPlan && selectedHealthPlan.id}
        />
        {displaySubPlans && (
          <DropDown
            dropDownCallback={onSubPlanSelect}
            items={subPlanOptions}
            placeholder={'Select Sub Plan'}
            black={!isEdit}
            customClassName={'vehicleDropDown'}
            id={selectedSubPlan && selectedSubPlan.id}
            error={onSubmitError}
          />
        )}
        <input type="submit" onClick={onUpdate} style={buttonStyle} value="Update" />
      </React.Fragment>
    );
  };

  const buttonStyle = {
    borderRadius: '2px',
    boxShadow: '0 2px 2px 0 rgb(0 0 0 / 24%), 0 0 2px 0 rgb(0 0 0 / 12%)',
    color: '#2962ff',
    backgroundColor: '#fff',
    textTransform: 'uppercase',
    letterSpacing: 0.5,
    textAlign: 'center',
    fontWeight: 500,
    padding: '10px 16px',
    fontSize: 14,
    cursor: 'pointer',
    float: 'right'
  };

  const renderEditButton = () => {
    return (
      !isEdit &&
      !ACTIVE_RIDES.includes(ride.status) && (
        <p className="edit" onClick={onEditClick}>
          Edit
        </p>
      )
    );
  };

  const renderErrorModal = () => {
    const handleErrorModalClose = () => {
      setError(false);
      dispatch(clearFundingSource());
    };
    return (
      <ErrorModal
        closeModal={handleErrorModalClose}
        isOpen={!isEmpty(isErrorOpen) && isEdit}
        modalContent={(isErrorOpen && isErrorOpen.message) || ''}
        title="Error"
        showSubmit={false}
      />
    );
  };

  const renderRequiredFieldError = () => {
    const style = {
      boxSizing: 'border-box',
      backgroundColor: '#fa0',
      width: '100%',
      textAlign: 'center',
      padding: '14px 24px',
      color: '#fff',
      fontSize: '14px'
    };
    return <div style={style}>Sub Plan is a required field</div>;
  };

  const style = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: '19px',
    margin: 0,
    flexDirection: isEdit ? 'column' : 'row',
    backgroundColor: isEdit ? '#0072D6' : 'white'
  };
  return (
    <div>
      {onSubmitError && renderRequiredFieldError()}

      <div className="patientInfoComplete transportInfoComplete" style={style}>
        {isEdit && (
          <div
            className="close"
            style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}
            onClick={handleClose}
          >
            <SvgClose
              customStyle={{
                fill: '#fff',
                opacity: 0.65
              }}
            />
          </div>
        )}
        <div className="left">
          <p style={{ fontSize: 18, color: '#364751' }}>
            {!isEdit && `Funded By: ${cardTitle}`}
          </p>

          {renderEditSection()}
        </div>
        <div className="right">
          {renderEditButton()}
          {renderErrorModal()}
        </div>
      </div>
    </div>
  );
};
