/* eslint-disable no-console */
import axios from '../safeAxios';
import { ActionTypes } from './constants';
import { all, put, takeEvery, debounce } from 'redux-saga/effects';
import { baseRequestConfig } from '~/utilities/auth.helper';

const API_URL = `${process.env.REACT_APP_ANALYTICS_API_HOST}/api/v1/expense`;
const DEBOUNCE_TIMEOUT = 1200;

/**
 * Get results of expense reports from Lumen API
 * @return {Generator}  Generator function
 */
function* getExpenseTableDataSaga(action = {}) {
  try {
    const { page, limit, query } = action.payload;
    const queries = Object.keys(query)
      .filter(key => query[key])
      .map(key => key + '=' + query[key])
      .join('&');
    const queryString = queries ? `&${queries}` : '';
    const payload = yield axios({
      ...baseRequestConfig(),
      method: 'GET',
      url: `//${API_URL}?page=${page}&limit=${limit}&orderBy=created_at${queryString}`
    });
    yield put({
      type: ActionTypes.GET_TABLE_DATA_SUCCESS,
      payload: payload.data,
      filterParams: query
    });
  } catch (error) {
    console.log('Error fetching table data: ', error);
    yield put({ type: ActionTypes.GET_TABLE_DATA_ERROR, error });
  }
}

function* filterExpenseTableDataSaga(action = {}) {
  try {
    const { query, limit } = action.payload;
    const queries = Object.keys(query)
      .filter(key => query[key])
      .map(key => key + '=' + query[key])
      .join('&');
    const queryString = queries ? `&${queries}` : '';
    const payload = yield axios({
      ...baseRequestConfig(),
      method: 'GET',
      url: `//${API_URL}?limit=${limit}&orderBy=created_at${queryString}`
    });
    yield put({
      type: ActionTypes.GET_TABLE_DATA_SUCCESS,
      payload: payload.data,
      filterParams: query
    });
  } catch (error) {
    console.log('Error filtering table data: ', error);
    yield put({ type: ActionTypes.FILTER_TABLE_DATA_ERROR, error });
  }
}

function* exportExpenseTableDataSaga(action = {}) {
  try {
    const { query, filename } = action.payload;
    const queries = Object.keys(query)
      .filter(key => query[key])
      .map(key => key + '=' + query[key])
      .join('&');
    console.log('Queries: ', queries);
    const payload = yield axios({
      ...baseRequestConfig(),
      method: 'GET',
      url: `//${API_URL}/export?&orderBy=created_at&${queries}`
    });
    yield put({
      type: ActionTypes.EXPORT_TABLE_DATA_SUCCESS,
      payload: {
        data: payload.data.data,
        filename
      }
    });
  } catch (error) {
    console.log('Error exporting table data: ', error);
    yield put({ type: ActionTypes.EXPORT_TABLE_DATA_ERROR, error });
  }
}

export function* expenseSaga() {
  yield all([
    takeEvery(ActionTypes.GET_TABLE_DATA, getExpenseTableDataSaga),
    takeEvery(ActionTypes.EXPORT_TABLE_DATA, exportExpenseTableDataSaga),
    debounce(DEBOUNCE_TIMEOUT, ActionTypes.FILTER_TABLE_DATA, filterExpenseTableDataSaga)
  ]);
}
