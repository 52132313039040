export const AGE_GROUP_OPTIONS = [
  { label: 'Child', value: 'child' },
  { label: 'Adult', value: 'adult' }
] as const;

export const EXPENSE_STATUS_OPTIONS = [
  { label: 'Approved', value: 'Approved' },
  { label: 'Cancelled', value: 'Cancelled' },
  { label: 'Paid By Broker', value: 'Paid By Broker' },
  { label: 'Reimbursed', value: 'Reimbursed' },
  { label: 'Rejected', value: 'Rejected' },
  { label: 'To Be Reviewed', value: 'To Be Reviewed' },
  { label: 'Out Of Compliance', value: 'Out Of Compliance' },
  { label: 'Pending Reimbursement', value: 'Pending Reimbursement' },
  { label: 'Invoiced', value: 'Invoiced' }
] as const;

export const CATEGORY_OPTIONS = [
  { label: 'Advanced Funds — Lodging', value: 'Advanced Funds — Lodging' },
  { label: 'Advanced Funds — Meals', value: 'Advanced Funds — Meals' },
  { label: 'Advanced Funds — Mileage', value: 'Advanced Funds — Mileage' },
  { label: 'Bus Ticket', value: 'Bus Ticket' },
  { label: 'Facility Reimbursement', value: 'Facility Reimbursement' },
  { label: 'Gas Card', value: 'Gas Card' },
  { label: 'IT & Internet', value: 'IT & Internet' },
  { label: 'Lodging', value: 'Lodging' },
  { label: 'Meals', value: 'Meals' },
  { label: 'Mileage', value: 'Mileage' },
  { label: 'Miscellaneous', value: 'Miscellaneous' },
  { label: 'Parking', value: 'Parking' },
  { label: 'Reimbursement - Lodging', value: 'Reimbursement - Lodging' },
  { label: 'Reimbursement Meals', value: 'Reimbursement - Meals' },
  { label: 'Telephone', value: 'Telephone' },
  { label: 'Toll Fees', value: 'Toll Fees' },
  { label: 'Travel - Airfare', value: 'Travel - Airfare' },
  { label: 'Travel - Commercial Bus', value: 'Travel - Commercial Bus' },
  { label: 'Travel - Ferry or water', value: 'Travel - Ferry or water' },
  { label: 'Travel - Train', value: 'Travel - Train' }
] as const;
