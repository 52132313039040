import type { MemberSavedAddress } from '~/services/savedAddress.service';
import type {
  UseSavedAddressActions,
  UseSavedAddressStore
} from '../../../BookRides/FormComponents/SavedAddressSelect/useSavedAddress';

export interface IProps {
  bookingData: BookingDataStore;
  rideDetails: Record<string, any>;
  vehicleTypes: any[];
  updateBookingData: (data: Partial<BookingDataStore>) => {};
  updateRide: () => {};
  updateRideDetails: (data: Record<string, any>) => void;
  isTransportFormBleg: boolean;
  user: {};
  userData: {};
  missingFields: string[];
  missingFieldsError: string;
  editRide: boolean;
  closeComponent: () => {};
  getVenues: () => {};
  venues: {};
  venueList: [];
  updateRideData: (ride: Record<string, any>, index: number) => {};
  legIndex: number;
  autoComplete: (address: string, coords: Record<string, number>) => {};
  autoCompleteData: {};
  toggleLoading: (toggle: boolean, text: string, timeout?: number) => {};
  checkLocation: (params: Record<string, any>) => {};
  healthSubPlanId?: number;
  healthSubPlanLocationsRestriction?: number;
  clearApprovedProviderSearchResults: () => {};
  healthSubPlanApprovedProviders?: number;
  displayApprovedProviders: boolean;
  patientDetails: Record<string, any>;
  getTimezoneFromLatLong: (lat: number, lng: number, dropoff: boolean) => {};
  fetchApprovedProviders: (params: {
    value: number;
    hospitalId: number;
    offset?: number;
    limit?: number;
    page?: number;
  }) => {};
  transportTypeValidate: (
    missingFields: Record<string, any>,
    missingFieldsError: string
  ) => {};
  savedAddress: { state: UseSavedAddressStore; actions: UseSavedAddressActions };
  savedAddresses: MemberSavedAddress[];
  checkLocationData: { status?: boolean };
}

export interface IState {
  [x: string]: any;
  showPatientIdForm: boolean;
  showPatientInfoForm: boolean;
  showPatientInfoComplete: boolean;
  showPatientIdError: boolean;
  showPatientInfoError: boolean;
  transportType: number;
  transportTypeName: string;
  pickupAddress: string;
  dropoffAddress: string;
  invalidPickupZipcode: boolean;
  invalidDropoffZipcode: boolean;
  /** START APPROVED PROVIDERS **/
  displayApprovedProviders: boolean;
  approvedProviderDropoff: string;
  approvedProviderPickup: string;
  pickupProviderName: string;
  dropoffProviderName: string;
  pickupProviderId: number;
  dropoffProviderId: number;
  pickupProviderNpi: number;
  dropoffProviderNpi: number;
  pickupProviderPhone: string;
  dropoffProviderPhone: string;
  showPickupApprovedProvider: boolean;
  showPickupApprovedProviderAutoComplete: boolean;
  showDropoffApprovedProvider: boolean;
  showDropoffApprovedProviderAutoComplete: boolean;
  pickupFacilityName: string;
  pickupProviderAddr: string;
  pickupAdditionalNotes: string;
  dropoffFacilityName: string;
  dropoffProviderAddr: string;
  dropoffAdditionalNotes: string;
  pickupProviderNotFound: boolean;
  dropoffProviderNotFound: boolean;
  pickupApprovedProviderErrors: string[];
  dropoffApprovedProviderErrors: string[];
  showPickupVenue: boolean;
  showPickupEntrance: boolean;
  showDropoffVenue: boolean;
  showDropoffEntrance: boolean;
  pickupVenueName?: string;
  pickupEntranceName?: string;
  dropoffVenueName?: string;
  dropoffEntranceName?: string;
  selectedPickupError: boolean;
  selectedDropoffError: boolean;
  autoCTop: string;
  autoCLeft: string;
  showDropoffAutoComplete: boolean;
  showPickupAutoComplete: boolean;
  dropoffClassName: string;
  pickupClassName: string;
  fromMemberSavedAddressId: number;
  toMemberSavedAddressId: number;
}

export const initialState: IState = {
  showPatientIdForm: true,
  showPatientInfoForm: false,

  showPatientInfoComplete: false,

  showPatientIdError: false,
  showPatientInfoError: false,
  transportType: 0,
  transportTypeName: '',
  pickupAddress: '',
  dropoffAddress: '',
  invalidPickupZipcode: false,
  invalidDropoffZipcode: false,

  /** START APPROVED PROVIDERS **/
  displayApprovedProviders: false,
  approvedProviderPickup: '',
  pickupProviderName: '',
  approvedProviderDropoff: '',
  dropoffProviderName: '',
  pickupProviderId: 0,
  dropoffProviderId: 0,
  pickupProviderNpi: 0,
  dropoffProviderNpi: 0,
  pickupProviderPhone: '',
  dropoffProviderPhone: '',
  // Approved Provider display controls
  showPickupApprovedProvider: false,
  showPickupApprovedProviderAutoComplete: false,
  showDropoffApprovedProvider: false,
  showDropoffApprovedProviderAutoComplete: false,
  // Approved Provider Notes
  pickupFacilityName: '',
  pickupProviderAddr: '',
  pickupAdditionalNotes: '',
  dropoffFacilityName: '',
  dropoffProviderAddr: '',
  dropoffAdditionalNotes: '',
  // Approved Provider Notes display controls
  pickupProviderNotFound: false,
  dropoffProviderNotFound: false,
  // Approved Provider Validation
  pickupApprovedProviderErrors: [],
  dropoffApprovedProviderErrors: [],
  /** END APPROVED PROVIDERS **/

  // saved location selection
  showPickupVenue: false,
  showPickupEntrance: false,
  showDropoffVenue: false,
  showDropoffEntrance: false,
  pickupVenueName: '',
  pickupEntranceName: '',
  dropoffVenueName: '',
  dropoffEntranceName: '',
  selectedPickupError: false,
  selectedDropoffError: false,
  autoCTop: '0px',
  autoCLeft: '0px',
  showDropoffAutoComplete: false,
  showPickupAutoComplete: false,

  dropoffClassName: '',
  pickupClassName: '',

  /**
   * Saved Address
   */
  fromMemberSavedAddressId: 0,
  toMemberSavedAddressId: 0
};
