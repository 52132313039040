import React from 'react';
import PropTypes from 'prop-types';
import Map from '~/Shared/Components/Map/Map';
import GoogleMap from '~/Shared/Components/GoogleMap';
import GoogleMapV2 from '~/Pages/RideDetails/components/GoogleMap';
import GoogleMapV3 from '~/Shared/Components/GoogleMap/GoogleMap';
import { isPublicRide } from '~/utilities/booking.helper';
import { useSavedAddressDrawer } from '~/Shared/Components/SavedAddressDrawer/SavedAddressDrawer';
import { useAppSelector } from '~/Modules';
import type { RideCardsRide } from '~/Modules/rideCards/rideCards.types';

type IProps = {
  activeRide?: RideCardsRide;
  rideDetails?: Record<string, any>;
  transit?: {
    selectedLeg: number;
    legs: Record<string, unknown>;
  };
};

const ActiveMap: React.FC<IProps> = ({ activeRide, transit }) => {
  const [drawerIsOpen] = useSavedAddressDrawer();

  const scheduleRides = useAppSelector(state => state?.scheduleRides);
  const rideDetails = useAppSelector(state => state?.rideDetails?.ride);

  if (drawerIsOpen) {
    return <GoogleMapV3 />;
  }

  // Render Google Map for all public rides
  if (isPublicRide()) {
    if (scheduleRides && Object.keys(scheduleRides).length > 0) {
      const { confirmationCardData = [], ride = {} } = scheduleRides;

      return (
        <GoogleMap
          transit={transit}
          confirmationCardData={confirmationCardData}
          ridesData={ride?.submittedData?.rides ?? []}
        />
      );
    } else {
      // No idea when this would be used
      const ridesData = rideDetails?.data?.ride ?? {};

      let cards = [];
      if (rideDetails?.data && Object.hasOwn(rideDetails.data, 'ride')) {
        cards = rideDetails.data.ride?.publicData;
      }

      return <GoogleMapV2 confirmationCardData={cards} ridesData={ridesData} />;
    }
  }

  // Render mapbox map for all other options
  return (
    <>
      <Map activeRide={activeRide} isPublicRoutelessRides={scheduleRides?.auth} />
      <div className="map" id="requestLoaderMap" />
    </>
  );
};

ActiveMap.defaultProps = {
  rideDetails: {},
  transit: {
    selectedLeg: 0,
    legs: {}
  }
};

ActiveMap.propTypes = {
  rideDetails: PropTypes.object
};

export default React.memo(ActiveMap);
